import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router";
import { browserHistory } from "react-router";
import { EditButton, ViewButton } from "../../../../common/inputs";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";
import Modal from "react-bootstrap/Modal";
import rate_services from "../../../../supply-chain/services/rate_services";
import useApi from "../../../../../hooks/useApi";
import authService from "../../../../../services/authService";
import { toast } from "react-toastify";
import EpProgram from "../../../../../img/icons/ep-program.svg";
import { confirmAlert } from "react-confirm-alert";
import Transactions from "./_transactionModal";
import Select from "react-select";

const AllSupplierList = () => {
  const {
    setData,
    setProgramId,
    setViewShow,
    setEditShow,
    list,
    setView,
    setModel,
    fetchList,
    showTransacionList,
    transactions,
    setTransactionModal,
    transactionModal,
    fetchSupplier,
    setList,
  } = useContext(SupplierProgramContext);

  const [showListPopout, setShowListPopout] = useState(false);
  const [supplierModal, setSupplierModal] = useState(false);
  const [supplierNames, setSupplierNames] = useState([]);
  const [searchlist, setSerachlist] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  const epProgramDelete = useApi(rate_services.epProgramDeleteion);
  const baseUrl = window.location.origin;
  const handleDeleteProgram = (program_id, transaction) => {
    if (transaction === true) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-delete-ui">
              <p style={{ color: "white" }}>
                You are unable to delete this program as it is linked to an
                ongoing transaction.
              </p>
              <button className="btn btn-white" onClick={onClose}>
                Close
              </button>
            </div>
          );
        },
        title: "Info!",
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-delete-ui">
              <h5>Are you sure ...?</h5>
              <p style={{ color: "white" }}>You want to delete this program</p>
              <button className="btn btn-white" onClick={onClose}>
                No
              </button>
              <button
                className="btn btn-primary ml-2"
                onClick={() => {
                  deleteProgram(program_id);
                  onClose();
                }}
              >
                Yes
              </button>
            </div>
          );
        },
        title: "Confirm to delete",
        message: "Are you sure to do this.",
      });
    }
  };

  const deleteProgram = async (program_id) => {
    const { data } = await epProgramDelete.request({
      program_id: program_id,
    });

    if (data.success === true) {
      fetchList();
      toast.success(data.message);
    } else {
      toast.error("Failed o delete the program...!");
    }
  };

  const handlePopoutClick = () => {
    setShowListPopout(true);
  };
  const closePopout = () => {
    setShowListPopout(false);
  };

  const setSearchKeys = () => {
    const formattedList = list.map((item) => ({
      label: item.program_name,
      value: item.program_name,
    }));
    setSerachlist(formattedList);
  };
  const getSuppliers = (sId) => {
    const supplierRow = list.find((item) => item.id === sId);
    const suppliers = supplierRow.supplierProgramMapping.map(
      (supplier) => `${supplier?.supplier?.organization}`
    );
    setSearchKeys();
    setSupplierNames((prevData) => ({
      ...prevData,
      suppliers,
    }));
  };
  useEffect(() => {
    setSearchKeys();
  }, []);
  const handleSearch = (option) => {
    setSearchKey(option.value);
  };
  useEffect(() => {
    const filteredList = list.filter((item) =>
      item.program_name.toLowerCase().includes(searchKey.toLowerCase())
    );
    setFilteredList(filteredList);
  }, [searchKey, list]);

  return (
    <>
      <div className="card overflow-visible" style={{ minHeight: "500px" }}>
        <div className="card-header">
          <div class="row mt-4">
            <div class="col">
              <h5 class="card-title ml-2 mt-2">
                Supplier Early Payment Program
              </h5>
            </div>
            <div class="col text-right">
              <Link
                title="Close and go to dashboard"
                className="ml-2 pull-right btn btn-light text-secondary"
                onClick={() => {
                  browserHistory.push("/client/ep-programme");
                  localStorage.setItem("view", true);
                  setView(true);
                }}
              >
                <i className="fa fa-close "></i>
              </Link>

              {showListPopout === false && (
                <a
                  className="btn btn-light pull-right text-secondary ml-2"
                  onClick={handlePopoutClick}
                >
                  <i className="fa fa-expand"></i>
                </a>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group ">
                <Select
                  options={searchlist}
                  placeholder="Search here"
                  //components={{ DropdownIndicator: () => null }}
                  onChange={handleSearch}
                //onKeyDown={this.searchKeyDown}
                //styles={customStyles}
                //value={this.state.selectedSearchTerm}
                //isClearable

                // formatOptionLabel={(option) => (
                //   <div style={{ fontWeight: option.bold ? 'bold' : 'normal' }}>
                //     {option.label}
                //   </div>
                // )}
                />
              </div>
            </div>
            <div className="col-md-8"></div>
          </div>
        </div>

        {showListPopout ? (
          <Modal
            className="fullscreen-modal"
            dialogClassName={` ${closePopout ? " closing" : " "
              } auto-height-modal`}
            show={showListPopout}
            onHide={closePopout}
            onExit={() => (document.body.style.overflow = "auto")} // Restore body overflow when modal exits
            onExiting={() => (document.body.style.overflow = "auto")}
            style={{ height: "auto" }}
          >
            <Modal.Header closeButton>
              <div className="d-block pt-3 text-center w-100 ml-3">
                <h3 className="modal-title">
                  <h5 class="card-title ml-2 mt-2">
                    Supplier Early Payment Program
                  </h5>
                </h3>
              </div>
              <a
                style={{ marginTop: "-7px" }}
                className="btn btn-light pull-right text-secondary ml-2"
                onClick={closePopout}
              >
                <i className="fa fa-compress"></i>
              </a>
            </Modal.Header>
            <Modal.Body>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <th>Program Name</th>
                    <th>Suppliers</th>
                    <th>Discount Rate Type</th>
                    <th>Days</th>
                    <th>Rate</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    {filteredList.length > 0 ? (
                      filteredList.map((item, index) => {
                        const supplierCount =
                          item.supplierProgramMapping.length;

                        const firstSupplier =
                          item.supplierProgramMapping[0]?.supplier
                            ?.organization;
                        return (
                          <tr style={{ borderTop: "1px solid #eff0f6" }} key={index}>
                            <td>{item.program_name}</td>
                            {/* <td>{supplierNames}</td> */}
                            <td>
                              {supplierCount === 1 ? (
                                <span>{firstSupplier} supplier</span>
                              ) : (
                                <span>
                                  {firstSupplier} and{" "}
                                  <Link
                                    onClick={() => {
                                      getSuppliers(item.id);
                                      setSupplierModal(true);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      color: "#58B07E",
                                    }}
                                  >
                                    {supplierCount - 1} other suppliers
                                  </Link>
                                </span>
                              )}
                            </td>
                            <td>{item.discount_rate_type}</td>
                            <td>{item.no_of_days}</td>
                            <td>
                              {parseFloat(item.discount_rate).toFixed(2)}%
                            </td>
                            <td>
                              <Link
                                className="action-icons"
                                style={{ color: "grey", cursor: "pointer" }}
                                onClick={() => {
                                  localStorage.setItem("view", true);
                                  setView(true);

                                  browserHistory.push("/client/ep-programme");
                                  setModel(true);
                                  localStorage.setItem(
                                    "viewShow",
                                    JSON.stringify(true)
                                  );
                                  setViewShow(true);

                                  localStorage.setItem(
                                    "editShow",
                                    JSON.stringify(false)
                                  );
                                  setEditShow(false);

                                  localStorage.setItem(
                                    "programId",
                                    item.program_id
                                  );
                                  setProgramId(item.program_id);
                                }}
                              >
                                <ViewButton></ViewButton>
                              </Link>{" "}
                              &nbsp;
                              <Link
                                className="action-icons"
                                style={{ color: "grey", cursor: "pointer" }}
                                onClick={() => {
                                  localStorage.setItem("view", true);
                                  setView(true);

                                  browserHistory.push("/client/ep-programme");
                                  localStorage.setItem(
                                    "viewShow",
                                    JSON.stringify(false)
                                  );
                                  setViewShow(false);
                                  localStorage.setItem(
                                    "editShow",
                                    JSON.stringify(true)
                                  );
                                  setEditShow(true);
                                  setModel(true);

                                  localStorage.setItem(
                                    "programId",
                                    item.program_id
                                  );
                                  setProgramId(item.program_id);
                                }}
                              >
                                <EditButton></EditButton>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div
                        className=" text-center py-20 my-10"
                        style={{ minHeight: 247 }}
                      >
                        <img src={EpProgram}></img>
                        <p>No active supplier program found!</p>
                        <button
                          onClick={() => {
                            // localStorage.setItem("view", false);
                            // setView(false);
                            setModel(true);
                            setData({});
                            localStorage.setItem(
                              "viewShow",
                              JSON.stringify(false)
                            );
                            setViewShow(false);
                            localStorage.setItem(
                              "editShow",
                              JSON.stringify(false)
                            );
                            setEditShow(false);
                            //browserHistory.push("/client/ep-programme/add-new");
                          }}
                          className="btn btn-primary mt-2"
                        >
                          {" "}
                          Add New
                        </button>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div className="card-body">
            <table className="table">
              <thead>
                <th>Program Name</th>
                <th>Suppliers</th>
                <th>Discount Rate Type</th>
                <th>Days</th>
                <th>Rate</th>
                <th className="text-right">Action</th>
              </thead>
              <tbody>
                {filteredList.length > 0 ? (
                  filteredList.map((item, index) => {
                    // const suppliers = item.supplierProgramMapping.map(
                    //   (supplier) =>
                    //     supplier.supplier.first_name +
                    //     " " +
                    //     supplier.supplier.last_name
                    // );
                    // const supplierNames = suppliers.join(", ");

                    const supplierCount = item.supplierProgramMapping.length;

                    const firstSupplier =
                      item.supplierProgramMapping[0]?.supplier.organization;

                    return (
                      <tr style={{ borderTop: "1px solid #eff0f6" }} key={index}>
                        <td>{item.program_name}</td>
                        {/* <td>{supplierNames}</td> */}
                        <td>
                          {supplierCount === 1 ? (
                            <span>{firstSupplier} supplier</span>
                          ) : (
                            <span>
                              {firstSupplier} and{" "}
                              <Link
                                onClick={() => {
                                  getSuppliers(item.id);
                                  setSupplierModal(true);
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "#58B07E",
                                }}
                              >
                                {supplierCount - 1} other suppliers
                              </Link>
                            </span>
                          )}
                        </td>
                        <td>
                          {item.discount_rate_type === "dynamic"
                            ? "Dynamic"
                            : item.discount_rate_type === "fixed"
                              ? "Fixed"
                              : item.discount_rate_type === "flexible"
                                ? "Flexible"
                                : ""}
                        </td>
                        <td>{item.no_of_days}</td>
                        <td>{parseFloat(item.discount_rate).toFixed(2)}%</td>
                        <td className="no-top-border pull-right">
                          <Link
                            className="action-icons"
                            style={{ color: "grey", cursor: "pointer" }}
                            onClick={() => {
                              localStorage.setItem("view", true);
                              setView(true);

                              browserHistory.push("/client/ep-programme");
                              setModel(true);
                              localStorage.setItem(
                                "viewShow",
                                JSON.stringify(true)
                              );
                              setViewShow(true);

                              localStorage.setItem(
                                "editShow",
                                JSON.stringify(false)
                              );
                              setEditShow(false);

                              localStorage.setItem(
                                "programId",
                                item.program_id
                              );
                              setProgramId(item.program_id);
                            }}
                          >
                            <ViewButton></ViewButton>
                          </Link>{" "}
                          <a
                            className="action-icons ml-2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            href="#"
                          >
                            <i className="fa fa-ellipsis-v mt-1 mb-1" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right shadow">
                            <Link
                              className="dropdown-item"
                              style={{ color: "grey", cursor: "pointer" }}
                              onClick={() => {
                                localStorage.setItem("view", true);
                                setView(true);

                                browserHistory.push("/client/ep-programme");
                                localStorage.setItem(
                                  "viewShow",
                                  JSON.stringify(false)
                                );
                                setViewShow(false);
                                localStorage.setItem(
                                  "editShow",
                                  JSON.stringify(true)
                                );
                                setEditShow(true);
                                setModel(true);

                                localStorage.setItem(
                                  "programId",
                                  item.program_id
                                );
                                setProgramId(item.program_id);
                                fetchSupplier();
                              }}
                            >
                              Update
                            </Link>

                            <Link
                              className="dropdown-item"
                              style={{ color: "grey", cursor: "pointer" }}
                              onClick={() => {
                                showTransacionList(item.program_id);
                              }}
                            >
                              Transactions
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link
                              className="dropdown-item text-danger"
                              style={{ color: "grey", cursor: "pointer" }}
                              onClick={() => {
                                handleDeleteProgram(
                                  item.program_id,
                                  item.transactions
                                );
                              }}
                            >
                              Delete
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div
                    className=" text-center py-20 my-10"
                    style={{ minHeight: 247 }}
                  >
                    <img src={EpProgram}></img>
                    <p>No active supplier program found!</p>
                    <button
                      onClick={() => {
                        // localStorage.setItem("view", false);
                        // setView(false);
                        setModel(true);
                        setData({});
                        localStorage.setItem("viewShow", JSON.stringify(false));
                        setViewShow(false);
                        localStorage.setItem("editShow", JSON.stringify(false));
                        setEditShow(false);
                        //browserHistory.push("/client/ep-programme/add-new");
                      }}
                      className="btn btn-primary mt-2"
                    >
                      {" "}
                      Add New
                    </button>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {supplierModal === true && (
        <div>
          <Modal
            show={supplierModal}
            onHide={(e) => {
              setSupplierModal(false);
            }}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <div className="">
                <h3 className="modal-title">Participating Suppliers</h3>
              </div>
            </Modal.Header>
            <Modal.Body>
              <table className="table ">
                <thead>
                  <th>#</th>
                  <th>Supplier Name</th>
                  <th>Invoices</th>
                  <th>Total</th>
                </thead>
                <tbody>
                  {supplierNames.suppliers.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item}</td>
                      <td>{0}</td>
                      <td>{"$0.00"}</td>
                    </tr>
                  ))}
                </tbody>
                <thead>
                  <th> </th>
                  <th> </th>
                  <th className="font-weight-bold">0</th>
                  <th className="font-weight-bold">$0.00</th>
                </thead>
              </table>
            </Modal.Body>
          </Modal>
        </div>
      )}
      {transactionModal === true && (
        <div>
          <Modal
            show={transactionModal}
            onHide={(e) => {
              setTransactionModal(false);
            }}
            size="xl"
          >
            <Modal.Header closeButton>
              <div className="">
                <h3 className="modal-title">Transaction details</h3>
              </div>
            </Modal.Header>
            <Modal.Body>
              <Transactions transactions={transactions} />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AllSupplierList;
