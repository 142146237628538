import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../common/loaders/loadingSpinner";
import { Picker, GeneralButton } from "../common/inputs";

import entityService from "../../services/entityService";
import authService from "../../services/authService";
import useApi from "../../hooks/useApi";
import NewTrader from "../entities/network/newTrader";

function EntityDebtorList({
  onSelectItem,
  selectedDebtor = null,
  client = null,
  defaultValue = { label: "Select Debtor", value: null },
  product = null,
  addNew = false,
  reference = null,
  getData = null,
  showAlLabel=false
}) {
  const { constants, category } = authService.getCurrentUser();
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const [openDebtor, setOpenDebtor] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { request, data, loading } = useApi(entityService.getEntityDebtorsList); // Custom hook for HTTP Requests

  const prevClient = useRef();
  const prevReload = useRef();

  useEffect(() => {
    if (client != null && client !== prevClient.current) {
      prevClient.current = client;
      request(client, product,showAlLabel); // Calling HTTP service
    }

    if (refresh != false && refresh !== prevReload.current) {
      prevReload.current = refresh;
      request(client, product,showAlLabel); // Calling HTTP service
    }

    if (selectedDebtor !== null && data.length > 0) {
      const debtorDetails = data.find((obj) => obj.value == selectedDebtor);
      if (debtorDetails)
        setSelectedItem({
          label: debtorDetails.label,
          value: debtorDetails.value,
        });
    } else if (selectedDebtor === null && !refresh)
      setSelectedItem(defaultValue);

    if (getData)
      getData(data);

  }, [client, selectedDebtor, data, refresh]);

  const onCloseDebtorModal = () => {
    setOpenDebtor(false);
  };
  const handleDebtorModal = () => {
    setOpenDebtor(true);
  };

  const handleSelectedItem = (rows) => {
    const debtorDetails = rows;
    
    setSelectedItem({
      label: debtorDetails.label,
      value: debtorDetails.value,
    });
    onSelectItem({
      label: debtorDetails.label,
      value: debtorDetails.value,
    });
  };

  return (
    <>

      {/*------------------Add New DebtorMmodal-------------------*/}
      <Modal
        show={openDebtor}
        onHide={onCloseDebtorModal}
        closeOnOverlayClick={false}
        centered
        className="animated bounceInRight"
        backdrop="static"
      >
        <NewTrader
          entity_id={client}
          role={constants.BUSINESS_TYPE.PURCHASE}
          title={constants.ROLES.DEBTOR}
          source="network"
          onCloseModal={() => {
            onCloseDebtorModal();
            addNew && setRefresh(true);
          }}
          handleSelectedItem={handleSelectedItem}
        ></NewTrader>
      </Modal>
      <>
        {loading == true && <LoadingSpinner />}
        {addNew ? (
          <div className="row mb-4">
            <div className="col-lg-10">
              <Picker
                items={data}
                selectedItem={selectedItem}
                onSelectItem={(selectedOption) => {
                  setSelectedItem(selectedOption);
                  onSelectItem(selectedOption);
                }}
                reference={reference}
              />
            </div>
            <div className="col-lg-2">

              <GeneralButton
                onClick={handleDebtorModal}
                className="btn btn-outline-primary pull-right"
                title="Create a new new Debtor from here"
                name={<i className="fa fa-plus"></i>}
                type={"button"}
              />
            </div>

          </div>
        ) : (
          <Picker
            items={data}
            selectedItem={selectedItem}
            onSelectItem={(selectedOption) => {
              setSelectedItem(selectedOption);
              onSelectItem(selectedOption);
            }}
            reference={reference}
          />
        )}


      </>
    </>
  );
}
export default EntityDebtorList;
