import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import ApprovalDecision from "./approveDecision";
import ApprovalOptions from "./approveOptions";
import Utility from "../../../../../utils/DateParser";
import Finish from "./finish";
import { MultiStepApproval } from "../../../tools/MultiStepApproval";

import auth from "../../../../../services/authService";
import useApi from "../../../../supply-chain/hooks/useApi";
import commonService from "../../../../supply-chain/services/commonService";
import ApproveContext from "../../../../supply-chain/context/bulkApproveContext"; // React Context API

function BulkApprove(props) {
  const [proceedNext, setProceedNext] = useState(false); // Enable or Disable 'Next' button within each component

  const [approval, setApproval] = useState("no");
  const { sc_invoice_status, category, constants } = auth.getCurrentUser();

  const [dueDateOption, setDueDateOption] = useState("");
  const [dueDateSelected, setDueDate] = useState();

  const [attention_count, setAttentionCount] = useState(0);
  const [attention_required, setAttention] = useState([]);

  const invoices = props.invoices;
  const [invoiceToProcess, setprocesInvoice] = useState([]);

  const [totalDue, setTotalDue] = useState(0);
  const [showAmount, setAmountToPayShow] = useState(false);
  const [attention, setAttentionRequired] = useState(false);
  const [acceptedInvoices, setAccepted] = useState([]);

  const fetchApi = useApi(commonService.getInvoiceAmount);
  const checkApi = useApi(commonService.checkTerms);

  const { ParseDate } = Utility();
  useEffect(() => { console.log("use ef");
    processInvoices();
  }, [dueDateOption, dueDateSelected, invoices]);

  const processInvoices = async () => {
console.log("pro fun");
    const { data } = await checkApi.request({
      invoices: invoices,
      revised_date_option: dueDateOption,
      revised_date:
        dueDateSelected === undefined ? "" : ParseDate(dueDateSelected),
      product: props.product ? props.product : constants.PRODUCTS.SUPPLYCHAIN
    });
    if (data["count"] > 0) {
      setAttentionCount(data["count"]);
      setAttention(data["attention_required"]);
      setAttentionRequired(true);
      setAmountToPayShow(false);
    } else {
      setAttentionCount(0);
      setAttention([]);
    }
    setprocesInvoice(data["process_invoices"]);
    if (data["process_invoices"].length > 0 && data["count"] <= 0 ) {
      const amount = await fetchApi.request({
        invoices: data["process_invoices"],
        customer: props.entity_id,
        revised_date_option: dueDateOption,
        revised_date: ParseDate(dueDateSelected),
        product: props.product ? props.product : constants.PRODUCTS.SUPPLYCHAIN
      });
      setTotalDue(amount["data"]["total_due"]);
      setAmountToPayShow(true);
      setAttentionRequired(false);
    }
  };
  const setAccept = async (trans_guid) => {

    let invoices = [...invoiceToProcess, trans_guid];
    setprocesInvoice(invoices);
    let acceptedTransactions = [...acceptedInvoices, trans_guid];
    setAccepted(acceptedTransactions);
    var removeInvoice = [...attention_required];
    var index = removeInvoice.indexOf(trans_guid);
    removeInvoice.splice(index, 1);
    setAttention(removeInvoice);

  };
  const onHandleReload = async () => {
    props.onHandleReload();
  };
  const componentsList = [
    // List of components to switch
    {
      headerText: "1. Bulk Approve",
      headerIcon: "fa fa-upload",
      component: <ApprovalDecision />,
    },
    {
      headerText: "2. Bulk Approve",
      headerIcon: "fa fa-th-list",
      component: <ApprovalOptions />,
    },
    {
      headerText: "3.Bulk Approve",
      headerIcon: "fa fa-link",
      component: <Finish />,
    },
  ];

  return (
    <>
      {(props.trans_status == sc_invoice_status["awaiting_approval"] ||
        props.trans_status == sc_invoice_status["awaiting_supplier_update"] ||
        props.trans_status == sc_invoice_status["approved_for_payment"] || props.trans_status == 'open' || props.trans_status == 'overdue')
        && (
          <ApproveContext.Provider
            value={{
              category,
              approval,
              dueDateOption,
              setDueDateOption,
              dueDateSelected,
              setDueDate,
              invoices,
              attention_count,
              attention_required,
              invoiceToProcess,
              totalDue,
              showAmount,
              attention,
              acceptedInvoices,
              setApproval,
              setProceedNext,
              setAccept,
              onHandleReload,
              product : props.product ? props.product : constants.PRODUCTS.SUPPLYCHAIN
            }}
          >
            <Modal
              show={props.open}
              onHide={props.onClose}
              centered
              size="lg"
              reload={props.onHandleReload}
            >
              <MultiStepApproval
                proceedNext={proceedNext}
                list={componentsList}
                displayProgressBar={true}
                noSteps="3"
                onClose={props.confirmClose}
                product={props.product ? props.product : constants.PRODUCTS.SUPPLYCHAIN}
                status={props.trans_status}
                type={props.type ? props.type : ""}
              />
            </Modal>
          </ApproveContext.Provider>
        )}
    </>
  );
}

export default BulkApprove;
