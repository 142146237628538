import React, { useEffect, useContext } from "react";

import authService from "../../../../services/authService";
import EntityContext from "../../../../context/entityContext";
import FifoTabs from "../../components/_fifoTabs";
import NoAccess from "../../widgets/noAccess";
import { Helmet } from "react-helmet";
import ProcessingFacility from "../../widgets/general/processingFacility";

export default function LoanLayout (props) {

    const { handleProductChange, selProduct, accountDetails } = useContext(EntityContext);
    const { constants } = authService.getCurrentUser();

    useEffect(() => {
        handleProductChange(constants.PRODUCTS.LOAN);
    }, [])

    const accountInfo = accountDetails.find(item => item.product == constants.PRODUCTS.LOAN);

    return(
        
        <div>
    
        {(!accountInfo) ? (
            <ProcessingFacility />
        )
        :
        selProduct === constants.PRODUCTS.LOAN ? 
            <>
                <Helmet>
                    <title>{accountInfo ? accountInfo.nick_name ? accountInfo.nick_name : accountInfo.bank_account_name : ''} | {global.config.title}</title>
                </Helmet>
                <FifoTabs 
                    activePath={props.location.pathname.substring(
                        props.location.pathname
                    )} 
                />
                {props.children}
            </> : 
            <NoAccess />
        }
    </div>
    )    
}