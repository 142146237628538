import React, { Component } from "react";
import { Helmet } from "react-helmet";

import InvoiceList from "./batchTransactions/InvoicesList";

import auth from "../../../services/authService";
import EntityContext from "../../../context/entityContext";

class BatchPurchaseInvoices extends Component {
  static contextType = EntityContext;
  constructor(props) {
    super(props);
  }

  state = {
    selectedTab: this.props.location.state ? this.props.location.state : null,
    ajaxRequest: false,
    selectedRows: [],
    categories: [],
    open: false,
    openCustomer: false,
    approvebuttonClass: "btn btn-primary  hidden",
    declinebuttonClass: "btn btn-danger  hidden",
    bulkapprove: false,
    bulkreject: false,
    selected: {},
    roleType: "Purchase",
    tabs: [],
    checkbox: true,
  };

  render() {
    const { constants } = auth.getCurrentUser();
    return (
      <>
        <Helmet>
          <title>Invoice List| {global.config.title}</title>
        </Helmet>
        {this.context && (
          <>

            {this.context.selProduct == constants.PRODUCTS.DEBTORFINANACE && (
              <InvoiceList type={this.props.location.query.type} />
            )}
          </>
        )}
      </>
    );
  }
}
export default BatchPurchaseInvoices;
