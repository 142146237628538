import React, { useState, useEffect, useContext } from "react";
import { FormRow } from "../../../../common/containers/formContainer";
// Importing Widgets
import { ApprovedForPayment, Rejected } from "../../../widgets/SCWidgets";
import { FundsAvailable, OverDueInvoices, ApprovedPaid, SupplierCustomerManagement, AwaitApproval, Rebate, TfInvoicesList } from "../../../widgets/TFWidgets";
import EntityContext from "../../../../../context/entityContext";
import useApi from '../../../../../hooks/useApi';
import authService from "../../../../../services/authService";
import { CurrencyLabel } from "../../../../common/inputs";
import CommonService from "../../../../supply-chain/services/commonService";
import PurchaseInvoiceList from "../../purchaseInvoices";
import ReactCardFlip from 'react-card-flip';

export default function TfPurchase(props) {

  const [appData, setAppData] = useState(null); //set data for approved for paymnet or scheduled for payment  invoice card
  const [awAppData, setAwAppData] = useState(null); // set data for awaiting approval  invoice card
  const [awSupUpData, setAwSupData] = useState(null); // set data for awaiting supplierapproval  invoice card
  const { sc_invoice_status, constants } = authService.getCurrentUser();
  const { selectedBusinessType, entityDetails, handleProductChange, handleRoleChange, selProduct, openInvoiceModal, terminology, specialProgramEnabledTF } = useContext(EntityContext);
  const [rejectedData, setRejectedData] = useState(null); // set data for Rejected invoice card
  const [rebateData, setRebateData] = useState(null);
  const CountApi = useApi(CommonService.getScInvoiceCount);
  const [flipped, setFlipped] = useState(false);

  const fetchData = async () => {

    const { data } = await CountApi.request({ "product_type": constants.PRODUCTS.TRADEFINANCE, "userId": entityDetails.a_guid, "selRole": selectedBusinessType });

    setAppData({
      topLabel: `Pending ${terminology && terminology.SUPPLIER} Payments`,
      topValue: <CurrencyLabel decimal={2} value={data.inv_apprd_payment_sum} />,
      leftLabel: data.isPrimary ? `${terminology && terminology.SUPPLIER}s` : "Customers",
      leftValue: data.isPrimary ? (data.inv_apprd_payment_supplierCount ? data.inv_apprd_payment_supplierCount : 0) : (data.inv_apprd_payment_customerCount ? data.inv_apprd_payment_customerCount : 0),
      rightLabel: "Invoices",
      rightValue: data.inv_apprd_payment_count ? data.inv_apprd_payment_count : 0,
      bottomLeftLabel: "Approved",
      bottomRightLabel: "Today's Balance",
      primaryBtnRoute: "/client/tf/purchase-invoices",
      secondaryBtnRoute: "/client/tf/bulk-payment",
      status: sc_invoice_status["approved_for_payment"],
      subTitle: "Approved and scheduled for payment",
      subIcon: "fa-clock-o"
    })

    setAwAppData({
      topLabel: "Pending Your Approval",
      topValue: <CurrencyLabel decimal={2} value={data.inv_awt_apprv_sum} />,
      leftLabel: data.isPrimary ? `${terminology && terminology.SUPPLIER}s` : "Customers",
      leftValue: data.isPrimary ? (data.inv_awt_apprv_supplierCount ? data.inv_awt_apprv_supplierCount : 0) : (data.inv_awt_apprv_customerCount ? data.inv_awt_apprv_customerCount : 0),
      rightLabel: "Invoices",
      rightValue: data.inv_awt_apprv_count ? data.inv_awt_apprv_count : 0,
      bottomLeftLabel: "Review",
      bottomRightLabel: "Today’s Balances",
      primaryBtnRoute: "/client/tf/purchase-invoices",
      status: sc_invoice_status["awaiting_approval"],
      subTitle: "Awaiting your approval"

    })

    setAwSupData({
      topLabel: "Awaiting Supplier Approval",
      topValue: <CurrencyLabel decimal={2} value={data.inv_awt_sup_update_sum} />,
      leftLabel: "Customers",
      leftValue: data.inv_awt_sup_update_customerCount ? data.inv_awt_sup_update_customerCount : 0,
      rightLabel: "Invoices",
      rightValue: data.inv_awt_sup_update_count ? data.inv_awt_sup_update_count : 0,
      bottomLeftLabel: "Review",
      bottomRightLabel: "Today’s Balances",
      primaryBtnRoute: "/client/tf/purchase-invoices",
      status: sc_invoice_status["awaiting_supplier_update"],
      subTitle: "Awaiting supplier update"
    })



    setRejectedData({
      topLabel: "Rejected",
      topSubLabel: "",
      topValue: <CurrencyLabel decimal={2} value={data.inv_rejected_sum} />,
      leftLabel: data.isPrimary ? `${terminology && terminology.SUPPLIER}s` : "Customers",
      leftValue: data.isPrimary ? (data.inv_rejected_supplierCount ? data.inv_rejected_supplierCount : 0) : (data.inv_rejected_customerCount ? data.inv_rejected_customerCount : 0),
      rightLabel: "Invoices",
      rightValue: data.inv_rejected_count ? data.inv_rejected_count : 0,
      primaryBtnRoute: "/client/tf/purchase-invoices",
      status: sc_invoice_status["rejected"],
      bottomLeftLabel: data.isPrimary ? `${terminology && terminology.SUPPLIER}s` : "Customers",
      bottomRightLabel: "Invoices",
      subTitle: "Rejected invoices",
      subIcon: "fa-thumbs-down"
    })
    setRebateData({
      topLabel: "Rebate",
      topValue: <CurrencyLabel decimal={2} value={data.rebate} />
    })

  }

  const setProductRoleType = () => {
    handleProductChange(constants.PRODUCTS.TRADEFINANCE);
    handleRoleChange(constants.BUSINESS_TYPE.PURCHASE)
  }

  const flipCard = () => setFlipped(!flipped);

  useEffect(() => {
    fetchData();
  }, [selectedBusinessType, entityDetails]);


  return (
    <>
      {selectedBusinessType == constants.BUSINESS_TYPE.PURCHASE && (

        specialProgramEnabledTF ? 
        <>
          <FormRow>
            <FundsAvailable />
            <div className="col-md-4 col-sm-4 col-xs-2 col-12">
              <AwaitApproval data={awAppData} flipCardCountTooltip={`${awSupUpData ? awSupUpData.rightValue : ''} invoice(s) awaiting supplier Approval`} flipCardCount={awSupUpData && awSupUpData.rightValue} loading={CountApi.loading} setProductRoleType={setProductRoleType} col={12} flip={false} noCard={true} />
            </div>

            <div className="col-md-4 col-sm-12 col-xs-12 col-12">
              <OverDueInvoices setProductRoleType={setProductRoleType} />
            </div>
          </FormRow>

          <FormRow>
            <div className={"col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12" }>
                <div className="card ">
                  {/* <PurchaseInvoiceList location={{state : null}} /> */}
                  <TfInvoicesList 
                    selectedTab={null} 
                    productType = {selProduct} 
                  />
                </div>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-2 col-12">
              <FormRow>
                <SupplierCustomerManagement />
              </FormRow>
            </div>
          </FormRow>
        </>
        :
        <>
          <FormRow>
            <FundsAvailable />
            <ApprovedForPayment data={appData} loading={CountApi.loading} setProductRoleType={setProductRoleType} />
            <div className="col-md-4 col-sm-4 col-xs-2 col-12">
              <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
                <div>
                  <AwaitApproval data={awAppData} flipCardCountTooltip={`${awSupUpData ? awSupUpData.rightValue : ''} invoice(s) awaiting supplier Approval`} flipCardCount={awSupUpData && awSupUpData.rightValue} loading={CountApi.loading} setProductRoleType={setProductRoleType} col={12} flip={flipCard} noCard={true} />
                </div>
                <div>
                  <AwaitApproval data={awSupUpData} flipCardCountTooltip={`${awAppData ? awAppData.rightValue : ''} invoice(s) awaiting your Approval`} flipCardCount={awAppData && awAppData.rightValue} loading={CountApi.loading} setProductRoleType={setProductRoleType} col={12} flip={flipCard} noCard={true} />
                </div>
              </ReactCardFlip>
            </div>
            {/* <AwaitApproval data={awAppData} loading={CountApi.loading} setProductRoleType={setProductRoleType} /> */}
            {/*  <AwaitSupplierUpdate data={awSupUpData} loading={CountApi.loading} setProductRoleType={setProductRoleType} />*/}

          </FormRow>
          <FormRow>
            <ApprovedPaid actionButtons={true} />
            <div className="col-md-4 col-sm-4 col-xs-2 col-12">
              <FormRow>
                <OverDueInvoices setProductRoleType={setProductRoleType} />
              </FormRow>
              <FormRow>
                <Rebate data={rebateData} loading={CountApi.loading} />
              </FormRow>
              <FormRow>
                <SupplierCustomerManagement />
              </FormRow>
              <FormRow>
                <Rejected type="sm" data={rejectedData} loading={CountApi.loading} top={true} setProductRoleType={setProductRoleType} />
              </FormRow>
              <FormRow></FormRow>
            </div>
          </FormRow>
        </>
      )}
    </>
  )
}