import React, { useEffect } from "react";
import useForm from "../../../../hooks/useForm";
import Modal from "react-bootstrap/Modal";
import { TextInput } from "../../../common/inputs";
import { SaveButton } from "../../../common/containers/button";
import {
    FormColumnMd,
    FormRow
} from "../../../common/containers/formContainer";
import { useUploader } from "../../../../context/secAccountsUploaderContext";
import Joi from "joi-browser";

function EditAccount({ data, onClose }) {

    const { updateAccount } = useUploader(); // Instance for Context API

    //------------------Validation Schema-----------------------------
    const schema = Joi.object() // Form validation schema object
        .keys({
            Organisation: Joi.string().required().error(() => { return { message: "Please enter a valid Organisation Name" }; }),
            First_Name: Joi.string().required().error(() => { return { message: "Please enter a valid First Name" }; }),
            Last_Name: Joi.string().required().error(() => { return { message: "Please enter a valid Last Name" }; }),
            Email: Joi.string().email().required().error(() => { return { message: "Please enter a valid Email" }; }),
            //Phone: Joi.string().required().error(() => { return { message: "Please enter a valid Phone Number" }; }),
        })
        .unknown(true);

    const saveChanges = () => {
        updateAccount(values);
        onClose();
    }

    const { values, setValues, handleChange, handleSubmit } = useForm(
        saveChanges,
        schema
    );
  
    useEffect(() => {
        setValues(data);
    }, []);

    return (
        <>
            <Modal.Header closeButton>
                <h4 className="card-title">Edit Account Information</h4>
            </Modal.Header>

            <Modal.Body>

                <form className="form-horizontal" onSubmit={handleSubmit}>
                    
                    <FormColumnMd col={12}>

                        <TextInput
                            name="ABN"
                            label="ABN"
                            value={values.ABN}
                            onChange={handleChange}
                            type="horizontal"
                            mandatory={true}
                        ></TextInput>

                        <TextInput
                            name="Organisation"
                            label="Organisation"
                            value={values.Organisation}
                            onChange={handleChange}
                            type="horizontal"
                            mandatory={true}
                        ></TextInput>

                        <TextInput
                            name="First_Name"
                            label="First Name"
                            value={values.First_Name}
                            onChange={handleChange}
                            type="horizontal"
                            mandatory={true}
                        ></TextInput>

                        <TextInput
                            name="Last_Name"
                            label="Last Name"
                            value={values.Last_Name}
                            onChange={handleChange}
                            type="horizontal"
                            mandatory={true}
                        ></TextInput>

                        <TextInput
                            name="Email"
                            label="Email"
                            value={values.Email}
                            onChange={handleChange}
                            type="horizontal"
                            mandatory={true}
                        ></TextInput>

                        <TextInput
                            name="Phone"
                            label="Phone"
                            value={values.Phone}
                            onChange={handleChange}
                            type="horizontal"
                        ></TextInput>

                        <TextInput
                            name="Address1"
                            label="Address 1"
                            value={values.Address1}
                            onChange={handleChange}
                            type="horizontal"
                        ></TextInput>

                        <TextInput
                            name="Address2"
                            label="Address 2"
                            value={values.Address2}
                            onChange={handleChange}
                            type="horizontal"
                        ></TextInput>

                        <TextInput
                            name="Address3"
                            label="Address 3"
                            value={values.Address3}
                            onChange={handleChange}
                            type="horizontal"
                        ></TextInput>

                        <TextInput
                            name="Post_Code"
                            label="Post Code"
                            value={values.Post_Code}
                            onChange={handleChange}
                            type="horizontal"
                        ></TextInput>

                        <TextInput
                            name="Alternate_Phone"
                            label="Alternate Phone"
                            value={values.Alternate_Phone}
                            onChange={handleChange}
                            type="horizontal"
                        ></TextInput>

                        <TextInput
                            name="Alternate_Email"
                            label="Alternate Email"
                            value={values.Alternate_Email}
                            onChange={handleChange}
                            type="horizontal"
                        ></TextInput>

                        <TextInput
                            name="Website"
                            label="Website"
                            value={values.Website}
                            onChange={handleChange}
                            type="horizontal"
                        ></TextInput>

                        <div className="text-right">
                            <SaveButton disabled={false} label="Submit" />
                        </div>

                    </FormColumnMd>

                </form>
                
            </Modal.Body>
        </>
    );
}

export default EditAccount;