import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";

import entityService from "../../services/entityService";
import authService from "../../services/authService";
import useApi from '../../hooks/useApi';

function OrganisationSearch({ handleChange, onConfirm, values }) {

    const { country_name } = authService.getCurrentUser();
    const [searchModal, setSearchModal] = useState(false);
    const [selectedNZBN, setSelectedNZBN] = useState(null);
    const [items, setItems] = useState([]);
    const [loaderMsg, setLoaderMsg] = useState("Connecting to external API Service...");

    //------------Api Services--------------------------------------------
    const companiesNamesListApi = useApi(entityService.fetchCompaniesListByEntityName);
    const companyDetailsApi = useApi(entityService.fetchCompanyDetailsByNzbn);

    const getCompaniesList = async () => {
        const data = await companiesNamesListApi.request(values.organization.replace(/ /g, "%20")); // Sending Async request to server
        if (data.message.totalItems == 0) {
            toast.error("Sorry, no data found. Please try again...");
            setSearchModal(false);
        }
        setItems(data.message.items);
    };

    const handleOrganisationSearch = () => {
        if (values.organization.length === 0) {
            toast.error("Invalid organisation name");
            return;
        } else {
            getCompaniesList();
            setSearchModal(true);
        }
    }

    const CapitalizeFirstLetter = text => text.length ? text.charAt(0).toUpperCase() + text.slice(1) : text

    const handleConfirm = async () => {

        setLoaderMsg("Fetching company details from the server... Please wait...");
        const externalData = await companyDetailsApi.request(selectedNZBN); // Sending Async request to server
        const { entityName, nzbn, tradingNames, websites, phoneNumbers, emailAddresses, roles, addresses, entityStatusDescription, sourceRegisterUniqueIdentifier } = externalData.message;

        const coreFields = { ...values };
        coreFields["organization"] = CapitalizeFirstLetter(entityName);
        coreFields["ab_number"] = nzbn;
        coreFields["organisation_number"] = sourceRegisterUniqueIdentifier;
        coreFields["organisation_status"] = entityStatusDescription;

        coreFields["trading_name"] = tradingNames.length > 0 ? tradingNames[0]["name"] : "";
        coreFields["website"] = websites.length > 0 ? websites[0]["url"] : "";
        coreFields["email"] = emailAddresses.length > 0 ? emailAddresses[0]["emailAddress"] : "";
        coreFields["phone_no"] = phoneNumbers.length > 0 ? phoneNumbers[0]["phoneCountryCode"] + " " + phoneNumbers[0]["phoneAreaCode"] + " " + phoneNumbers[0]["phoneNumber"] : "";

        /** Finding alternate email */
        const alternate_email = emailAddresses.filter(
            (emailData) => emailData.emailAddress != coreFields["email"]
        );

        /** Finding alternate phone number */
        const alternate_phone = phoneNumbers.filter(
            (phoneData) => phoneData.phoneNumber != coreFields["phone_no"]
        );

        coreFields["alternate_phone"] = alternate_phone.length > 0 ? alternate_phone[0]["phoneCountryCode"] + " " + alternate_phone[0]["phoneAreaCode"] + " " + alternate_phone[0]["phoneNumber"] : "";
        coreFields["alternate_email"] = alternate_email.length > 0 ? alternate_email[0]["emailAddress"] : "";

        /** ------------------ Getting shareholders information ---------------- */
        let shareholdersData = [];
        let shareAllocations = externalData.message["company-details"]["shareholding"]["shareAllocation"];
        shareAllocations.map((shareAllocation) => {

            if (shareAllocation.shareholder[0]["shareholderAddress"] != null) {
                const { individualShareholder: { firstName, middleNames, lastName }, shareholderAddress: { address1, address2, address3, address4, addressType, postCode, type } } = shareAllocation.shareholder[0];
                shareholdersData.push({
                    first_name: firstName != null ? CapitalizeFirstLetter(firstName) : null,
                    middle_name: middleNames != null ? CapitalizeFirstLetter(middleNames) : null,
                    last_name: lastName != null ? CapitalizeFirstLetter(lastName) : null,
                    address1: address1 != null ? CapitalizeFirstLetter(address1) : null,
                    address2: address2 != null ? CapitalizeFirstLetter(address2) : null,
                    address3: address3 != null ? CapitalizeFirstLetter(address3) : null,
                    address4: address4 != null ? CapitalizeFirstLetter(address4) : null,
                    address_type: addressType,
                    post_code: postCode,
                    entity_type: type,
                    role_type: 'shareholder'
                })
            }
        })
        /** ---------------------------------------------------------------- */

        /** ------------------ Getting directors information---------------- */
        let directorsData = [];
        directorsData = roles.filter(type => (type.roleType == "Director" && type.roleStatus == "ACTIVE")).map((role) => ({
            first_name: role.rolePerson.firstName != null ? CapitalizeFirstLetter(role.rolePerson.firstName) : null,
            middle_name: role.rolePerson.middleNames != null ? CapitalizeFirstLetter(role.rolePerson.middleNames) : null,
            last_name: role.rolePerson.lastName != null ? CapitalizeFirstLetter(role.rolePerson.lastName) : null,
            address1: role["roleAddress"][0]["address1"] != null ? CapitalizeFirstLetter(role["roleAddress"][0]["address1"]) : null,
            address2: role["roleAddress"][0]["address2"] != null ? CapitalizeFirstLetter(role["roleAddress"][0]["address2"]) : null,
            address3: role["roleAddress"][0]["address3"] != null ? CapitalizeFirstLetter(role["roleAddress"][0]["address3"]) : null,
            address4: role["roleAddress"][0]["address4"] != null ? CapitalizeFirstLetter(role["roleAddress"][0]["address4"]) : null,
            address_type: role["roleAddress"][0]["addressType"],
            post_code: role["roleAddress"][0]["postCode"],
            entity_type: role.roleType,
            role_type: 'director'
        }));

        // Populate with first director first and last name.
        if (directorsData.length > 0) {
            coreFields["first_name"] = CapitalizeFirstLetter(directorsData[0]["first_name"]);
            coreFields["last_name"] = CapitalizeFirstLetter(directorsData[0]["last_name"]);
        }
        /** ----------------------------------------------------------------------- */

        /** ------------------ Getting Service / Registered Address---------------- */
        let addressList = [];
        addressList = addresses.addressList.filter(type => (type.addressType == "REGISTERED" || type.addressType == "SERVICE")).map((address) => ({
            address1: address.address1,
            address2: address.address2,
            city: address.address3,
            post_code: address.postCode,
            address_type: address.addressType == "REGISTERED" ? "registered" : "service"
        }));

        /** ---------------------------------------------------------------- */

        /** ------------------ Getting Office Address---------------- */
        let officeAddress = addresses.addressList.filter(type => type.addressType == "OFFICE");
        if (officeAddress.length > 0) {
            coreFields["address1"] = officeAddress[0]["address1"];
            coreFields["address2"] = officeAddress[0]["address2"];
            coreFields["address3"] = officeAddress[0]["address3"];
            coreFields["post_code"] = officeAddress[0]["postCode"];
        }
        /** ---------------------------------------------------------------- */
        setSearchModal(false);
        onConfirm({ coreFields, shareholdersData: [...shareholdersData, ...directorsData], addressList })
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <>
                <Modal open={searchModal} onClose={() => setSearchModal(false)} >
                    <div className="modal-header">
                        <center>
                            {<i className="fa fa-book modal-icon"></i>}
                            <h3>Companies Office Register</h3>
                        </center>
                    </div>

                    <div style={{ width: 700 }}>
                        <div className="col-lg-12 modal-body">
                            {(companiesNamesListApi.loading == true || companyDetailsApi.loading == true) ?
                                (<center>
                                    <div>
                                        <h5>{loaderMsg}</h5>
                                    </div>
                                </center>
                                ) :
                                (items.map((item, index) => (
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-1"> <input onChange={({ target }) => setSelectedNZBN(target.value)} type="radio" id={item.nzbn} name="organisation" value={item.nzbn}></input></div>
                                            <div className="col-lg-6"><label className="text-primary" for={item.nzbn}>{" " + item.entityName}</label></div>
                                            <div className="col-lg-3">{item.nzbn}</div>
                                            <div className="col-lg-2">{item.entityStatusDescription}</div>
                                            <br></br>
                                        </div>
                                    </div>
                                )))}
                        </div>
                    </div>

                    <div className="modal-footer">
                        {(selectedNZBN != "" && companiesNamesListApi.loading == false && companyDetailsApi.loading == false) && (
                            <div>
                                <button onClick={handleConfirm} style={{ marginTop: "15px" }} type="button" className="btn btn-primary">
                                    <i className="fa fa-check"></i>&nbsp;Confirm
                                </button>
                            </div>
                        )}
                    </div>

                </Modal>

                <input
                    name="organization"
                    value={values.organization}
                    className="form-control input-sm"
                    placeholder="Organisation"
                    type="text"
                    onChange={handleChange}
                    style={{ display: "block", width: "100%" }}
                />

            </>
        </>
    )
};
export default OrganisationSearch;