import React from "react";
import TagsInput from 'react-tagsinput';

import 'react-tagsinput/react-tagsinput.css';

const KeyCodes = {
  ENTER: 13,
  TAB: 9,
  COMMA: 188,
};

const delimiters = [KeyCodes.COMMA, KeyCodes.ENTER, KeyCodes.TAB];

const TagInput = props => (
  <React.Fragment>
    {props.type === "horizontal" && (
      <div className={`form-group row ${props.mandatory ? "required" : ""} ${props.customFormGroup ? props.customFormGroup : ""}`}>
        {!props.hideLabel && (
          <label style={{ paddingTop: "7px" }} className={`col-lg-4 form-label ${props.mandatory ? "control-label" : ""}`}>
            {props.label}
          </label>
        )}
        <div className={props.fullWidth ? "col-lg-12" : "col-lg-8"}>
          <TagsInput
            value={props.tags}
            onChange={props.handleChange}
            addKeys={delimiters}
            maxTags={props.maxTags ? props.maxTags : '-1'}
            className={props.className ? props.className : 'react-tagsinput'}
            focusedClassName={props.focusedClassName ? props.focusedClassName : 'react-tagsinput--focused'}
            tagProps={props.tagProps ? props.tagProps : {
              className: 'react-tagsinput-tag',
              classNameRemove: 'react-tagsinput-remove'
            }}
            inputProps={props.inputProps ? props.inputProps : {
              className: 'react-tagsinput-input',
              placeholder: 'Add a tag'
            }}

          />
        </div>
      </div>
    )}

    {props.type === "vertical" && (
      <div className={`form-group ${props.mandatory ? "required" : ""} ${props.customFormGroup ? props.customFormGroup : ""}`}>
        {!props.hideLabel && (
          <label className={`${props.mandatory ? "control-label" : ""}`}> {props.label}</label>
        )}

        <TagsInput
          value={props.tags}
          onChange={props.handleChange}
          addKeys={delimiters}
          maxTags={props.maxTags ? props.maxTags : '-1'}
          className={props.className ? props.className : 'react-tagsinput'}
          focusedClassName={props.focusedClassName ? props.focusedClassName : 'react-tagsinput--focused'}
          tagProps={props.tagProps ? props.tagProps : {
            className: 'react-tagsinput-tag',
            classNameRemove: 'react-tagsinput-remove'
          }}
          inputProps={props.inputProps ? props.inputProps : {
            className: 'react-tagsinput-input',
            placeholder: 'Add a tag'
          }}

        />
      </div>
    )}

    {
      props.type === "normal" && (
        <TagsInput
          value={props.tags}
          onChange={props.handleChange}
          addKeys={delimiters}
          maxTags={props.maxTags ? props.maxTags : '-1'}
          className={props.className ? props.className : 'react-tagsinput'}
          focusedClassName={props.focusedClassName ? props.focusedClassName : 'react-tagsinput--focused'}
          tagProps={props.tagProps ? props.tagProps : {
            className: 'react-tagsinput-tag',
            classNameRemove: 'react-tagsinput-remove'
          }}
          inputProps={props.inputProps ? props.inputProps : {
            className: 'react-tagsinput-input',
            placeholder: 'Add a tag'
          }}

        />
      )
    }
    {
      props.type === "" && (
        <TagsInput
          value={props.tags}
          onChange={props.handleChange}
          addKeys={delimiters}
          maxTags={props.maxTags ? props.maxTags : '-1'}
          className={props.className ? props.className : 'react-tagsinput'}
          focusedClassName={props.focusedClassName ? props.focusedClassName : 'react-tagsinput--focused'}
          tagProps={props.tagProps ? props.tagProps : {
            className: 'react-tagsinput-tag',
            classNameRemove: 'react-tagsinput-remove'
          }}
          inputProps={props.inputProps ? props.inputProps : {
            className: 'react-tagsinput-input',
            placeholder: 'Add a tag'
          }}

        />
      )
    }

  </React.Fragment >
);

export { TagInput };