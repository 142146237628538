import React, { useState, useEffect, useContext } from "react";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";
import { Link } from "react-router";
import { browserHistory } from "react-router";
import Modal from "react-bootstrap/Modal";
import EpProgram from "../../../../../img/icons/ep-program.svg";
import MultiStepModal from "../../../../common/wizard/MultiStepModal";
import DiscountRates from "../newProgram/_discountRates";
import SupplierMapping from "../newProgram/_supplierMapping";
import Invite from "../newProgram/_invite";
import FinishPage from "../newProgram/_finish";
import { ViewButton } from "../../../../common/inputs";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import rate_services from "../../../../supply-chain/services/rate_services";
import useApi from "../../../../../hooks/useApi";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import Transactions from "./_transactionModal";

const SuppliersList = (props) => {
  const {
    data,
    setData,
    setProgramId,
    setViewShow,
    setEditShow,
    model,
    setView,
    handleChange,
    viewShow,
    editShow,
    setModel,
    list,
    proceedNext,
    programId,
    handleNextChange,
    sProgram,
    setSprogram,
    secStepSelect1,
    setSecSelectStep1,
    setSecShow,
    secShow,
    listLoading,
    fetchList,
    setSelectedFundedBy,
    setSelectedDicountRate,
    showSubmit,
    showTransacionList,
    transactions,
    setTransactionModal,
    transactionModal,
    fetchSupplier,
    fetchProgram
  } = useContext(SupplierProgramContext);

  const [nameDisable, setNameDisable] = useState(true);
  const [supplierModal, setSupplierModal] = useState(false);
  const [supplierNames, setSupplierNames] = useState([]);

  const epProgramDelete = useApi(rate_services.epProgramDeleteion);

  const handleDeleteProgram = (program_id, transaction) => {
    if (transaction === true) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-delete-ui">
              <p style={{ color: "white" }}>
                You are unable to delete this program as it is linked to an
                ongoing transaction.
              </p>
              <button className="btn btn-white" onClick={onClose}>
                Close
              </button>
            </div>
          );
        },
        title: "Info!",
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-delete-ui">
              <h5>Are you sure ...?</h5>
              <p style={{ color: "white" }}>You want to delete this program</p>
              <button className="btn btn-white" onClick={onClose}>
                No
              </button>
              <button
                className="btn btn-primary ml-2"
                onClick={() => {
                  deleteProgram(program_id);
                  onClose();
                }}
              >
                Yes
              </button>
            </div>
          );
        },
        title: "Confirm to delete",
        message: "Are you sure to do this.",
      });
    }
  };

  const deleteProgram = async (program_id) => {
    const { data } = await epProgramDelete.request({
      program_id: program_id,
    });

    if (data.success === true) {
      fetchList();
      toast.success(data.message);
    } else {
      toast.error("Failed o delete the program...!");
    }
  };

  useEffect(() => {
    if (data?.program_name) {
      setNameDisable(false);
    } else {
      setNameDisable(true);
    }
  }, [data?.program_name]);

  const handleProceed = () => {
    setModel(false);
    localStorage.setItem("view", false);
    setView(false);
    browserHistory.push("/client/ep-programme");
  };

  const getSuppliers = (sId) => {
    const supplierRow = list.find((item) => item.id === sId);
    const suppliers = supplierRow.supplierProgramMapping.map(
      (supplier) =>
        `${supplier?.supplier?.first_name} ${supplier?.supplier?.last_name}`
    );

    setSupplierNames((prevData) => ({
      ...prevData,
      suppliers,
    }));
  };

  const componentsList = [
    {
      headerTitle: "Create new supplier program",
      headerText: "1. Discount Rate",
      headerIcon: "fa fa-calculator",
      component: <DiscountRates />,
    },
    {
      headerTitle: "Select suppliers",
      headerText: "2. Supplier Mapping",
      headerIcon: "fa fa-link",
      component: <SupplierMapping />,
    },
    {
      headerTitle: "Invite your suppliers",
      headerText: "3. Invite Supplier",
      headerIcon: "fa fa-envelope",
      component: <Invite />,
    },
    {
      headerTitle: "Success",
      headerText: "7. Finish",
      headerIcon: "fa fa-flag-checkered",
      component: <FinishPage />,
    },
  ];

  const editcomponentsList = [
    {
      headerText: "1. Discount Rate",
      headerIcon: "fa fa-calculator",
      component: <DiscountRates />,
    },

    {
      headerText: "2. Supplier Mapping",
      headerIcon: "fa fa-link",
      component: <SupplierMapping />,
    },
    {
      headerText: "3. Finish",
      headerIcon: "fa fa-flag-checkered",
      component: <FinishPage />,
    },
  ];
  const viewComponentsList = [
    {
      headerTitle: "Supplier program",
      headerText: "1. Discount Rate",
      headerIcon: "fa fa-calculator",
      component: <DiscountRates />,
    },

    {
      headerTitle: "Selected suppliers",
      headerText: "2. Supplier Mapping",
      headerIcon: "fa fa-link",
      component: <SupplierMapping />,
    },
  ];

  return (
    <>
      <div>
        <div className="card overflow-visible  mr-3" style={{ minHeight: 490 }}>
          <div className="card-header d-flex justify-content-between align-items-center">
            {list.length > 0 ? (
              <>
                <h6 className="mb-0">Supplier Early Payment Program</h6>
                <a
                  onClick={() => {
                    // localStorage.setItem("view", false);
                    // setView(false);
                    setModel(true);
                    setData({});
                    setSelectedFundedBy({
                      label: "",
                      value: "",
                      name: "funded_by",
                    });
                    setSelectedDicountRate({
                      label: "",
                      value: "",
                      name: "discount_rate_type",
                    });
                    localStorage.setItem("viewShow", JSON.stringify(false));
                    setViewShow(false);
                    localStorage.setItem("editShow", JSON.stringify(false));
                    setEditShow(false);
                    fetchSupplier();
                    //browserHistory.push("/client/ep-programme/add-new");
                  }}
                  href="#"
                  className="btn btn-primary btn-sm mt-2  "
                >
                  Add New
                </a>
              </>
            ) : null}
          </div>
          {listLoading && <LoadingSpinner />}
          <div className="card-body">
            <div style={{ minHeight: 310, maxHeight: 310 }}>
              {list.length > 0 ? (
                !listLoading && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Program Name</th>
                        <th>Discount Rate Type</th>
                        <th>Rate</th>
                        <th className="text-right">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.slice(0, 6).map((item, index) => {
                        const supplierCount =
                          item.supplierProgramMapping.length;

                        const firstSupplier =
                          item.supplierProgramMapping[0]?.supplier.first_name +
                          " " +
                          item.supplierProgramMapping[0]?.supplier.last_name;

                        return (
                          <tr key={index} style={{ borderTop: "1px solid #eff0f6" }}>
                            <td>{item.program_name}</td>
                            <td>
                              <span>
                                {item.discount_rate_type === "dynamic"
                                  ? "Dynamic"
                                  : item.discount_rate_type === "fixed"
                                    ? "Fixed"
                                    : item.discount_rate_type === "flexible"
                                      ? "Flexible"
                                      : ""}
                              </span>
                            </td>
                            <td>
                              {parseFloat(item.discount_rate).toFixed(4)}%
                            </td>
                            <td className="no-top-border pull-right">
                              <Link
                                className="action-icons"
                                style={{ color: "grey", cursor: "pointer" }}
                                onClick={() => {
                                  setModel(true);
                                  localStorage.setItem(
                                    "viewShow",
                                    JSON.stringify(true)
                                  );
                                  setViewShow(true);

                                  localStorage.setItem(
                                    "editShow",
                                    JSON.stringify(false)
                                  );
                                  setEditShow(false);

                                  localStorage.setItem(
                                    "programId",
                                    item.program_id
                                  );
                                  setProgramId(item.program_id);
                                  fetchProgram();
                                }}
                              >
                                <ViewButton></ViewButton>
                              </Link>{" "}
                              <a
                                className="action-icons ml-2"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                href="#"
                                title="More options"
                              >
                                <i className="fa fa-ellipsis-v mt-1 mb-1" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-right shadow">
                                <Link
                                  className="dropdown-item"
                                  style={{ color: "grey", cursor: "pointer" }}
                                  onClick={() => {
                                    localStorage.setItem(
                                      "viewShow",
                                      JSON.stringify(false)
                                    );
                                    setViewShow(false);
                                    localStorage.setItem(
                                      "editShow",
                                      JSON.stringify(true)
                                    );
                                    setEditShow(true);
                                    setModel(true);

                                    localStorage.setItem(
                                      "programId",
                                      item.program_id
                                    );
                                    setProgramId(item.program_id);
                                    fetchSupplier();
                                    fetchProgram();
                                  }}
                                >
                                  Update
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  style={{ color: "grey", cursor: "pointer" }}
                                  onClick={() => {
                                    showTransacionList(item.program_id);
                                  }}
                                >
                                  Transactions
                                </Link>
                                <div className="dropdown-divider"></div>
                                <Link
                                  className="dropdown-item text-danger"
                                  style={{ color: "grey", cursor: "pointer" }}
                                  onClick={() => {
                                    handleDeleteProgram(
                                      item.program_id,
                                      item.transactions
                                    );
                                  }}
                                >
                                  Delete
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )
              ) : (
                !listLoading &&
                (
                  <div
                    className=" text-center py-20 my-10"
                    style={{ minHeight: 247 }}
                  >
                    <img src={EpProgram}></img>
                    <p>No active supplier program found!</p>
                    <button
                      onClick={() => {
                        // localStorage.setItem("view", false);
                        // setView(false);
                        setModel(true);
                        setData({});
                        setSelectedFundedBy({
                          label: "",
                          value: "",
                          name: "funded_by",
                        });
                        setSelectedDicountRate({
                          label: "",
                          value: "",
                          name: "discount_rate_type",
                        });
                        localStorage.setItem("viewShow", JSON.stringify(false));
                        setViewShow(false);
                        localStorage.setItem("editShow", JSON.stringify(false));
                        setEditShow(false);
                        fetchSupplier();
                        //browserHistory.push("/client/ep-programme/add-new");
                      }}
                      className="btn btn-primary mt-2"
                    >
                      {" "}
                      Add New
                    </button>
                  </div>
                )
              )}
            </div>
            {list.length > 0 ? (
              <button
                className="btn btn-outline-primary btn-block mt-4 mb-3"
                onClick={() => {
                  browserHistory.push("/client/ep-programme/allProgram");
                  localStorage.setItem("view", false);
                  setView(false);
                }}
              >
                <i className="fa fa-arrow-down" /> Show More
              </button>
            ) : null}
          </div>
        </div>
      </div>

      {model === true && (
        <MultiStepModal
          programId={programId}
          sProgram={sProgram}
          setSprogram={setSprogram}
          secStepSelect1={secStepSelect1}
          setSecSelectStep1={setSecSelectStep1}
          setSecShow={setSecShow}
          secShow={secShow}
          viewShow={viewShow}
          handleNextChange={handleNextChange}
          proceedNext={proceedNext}
          showSubmit={showSubmit}
          epProgram={
            editShow === true ? "edit" : viewShow === true ? "view" : null
          }
          data={data}
          handleChange={handleChange}
          list={
            editShow
              ? editcomponentsList
              : viewShow
                ? viewComponentsList
                : componentsList
          }
          displayProgressBar={true}
          handleCancel={() => {
            setModel(false);
          }}
          displaySteps={true}
          //pathname={props.location.pathname}
          setModel={setModel}
          model={model}
          headerTitle="Create new supplier program"
        />
      )}

      {supplierModal === true && (
        <div>
          <Modal
            show={supplierModal}
            onHide={(e) => {
              setSupplierModal(false);
            }}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <div className="">
                <h3 className="modal-title">Participating Suppliers</h3>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <th>#</th>
                      <th>Supplier Name</th>
                      <th>Invoices</th>
                      <th>Total</th>
                    </thead>
                    <tbody>
                      {supplierNames.suppliers &&
                        supplierNames.suppliers.length > 0
                        ? supplierNames.suppliers.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item}</td>
                            <td>{0}</td>
                            <td>{"$0.00"}</td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                    <thead>
                      <th> </th>
                      <th> </th>
                      <th className="font-weight-bold">0</th>
                      <th className="font-weight-bold">$0.00</th>
                    </thead>
                  </table>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}

      {transactionModal === true && (
        <div>
          <Modal
            show={transactionModal}
            onHide={(e) => {
              setTransactionModal(false);
            }}
            size="xl"
          >
            <Modal.Header closeButton>
              <div className="">
                <h3 className="modal-title">Transaction details</h3>
              </div>
            </Modal.Header>
            <Modal.Body>
              <Transactions transactions={transactions} />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default SuppliersList;
