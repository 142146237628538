import React, { useEffect, useState } from "react";
import { FormRow } from "../../../common/containers/formContainer";
import Modal from 'react-bootstrap/Modal';
export default function ListSupplierPopup({ list,title }) {
    return (
        <>

            <Modal.Header closeButton>
                <h4 className="modal-title">{ title }</h4>
            </Modal.Header>

            <Modal.Body>
                {list.length > 0 ? (
                    <FormRow style={{ paddingTop: "inherit" }}>
                        <table class="table table-sm table-bordered client-tble">
                            <tbody>
                                <tr>
                                    <th>Organisation</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>ABN</th>
                                    <th>Phone</th>
                                    <th>Comments</th>
                                </tr>
                                {list.map((item, index) => (
                                    <tr key={index}>
                                        <td> {item.Organisation} </td>
                                        <td> {item.First_Name} {item.Last_Name}</td>
                                        <td> {item.Email} </td>
                                        <td> {item.ABN} </td>
                                        <td> {item.Phone} </td>
                                        <td>
                                            <span className="text-danger">
                                            { item.error != undefined ? item.error : ''}
                                            </span>
                                        </td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    </FormRow>

                ) :
                    <div className="modal-header">
                        <span className="modal-title text-danger">No result found!</span>
                    </div>
                }
            </Modal.Body>
        </>
    )
}