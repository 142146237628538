import React, { useState, useEffect, useContext } from "react";
import * as moment from "moment";
import { toast } from "react-toastify";
import {
    Picker,
    CurrencyLabel,
} from "../../../common/inputs";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import authService from "../../../../services/authService";
import useApi from "../../../../hooks/useApi";
import externalApiService from "../../../../services/externalApiService";
import coreService from "../../../../services/coreService";
import disconnectIcon from "../../../../img/icons/disconnected.svg";
import { RadioButton } from "../../../common/inputs/radioButton/radioButton";
import { Link } from "react-router";
export default function Synchronize(props) {

    const { constants, entity_id } = authService.getCurrentUser();

    const [accData, setAccData] = useState(false);
    const [error, setError] = useState(false);
    const [syncError, setSyncError] = useState(false);
    const [userType, setUserType] = useState("account");
    const [clientDebtorList, setClientDebtorList] = useState("");
    const [dateRange, setDateRange] = useState([]);
    const [selectedclientDebtor, setSelectedclientDebtor] = useState([]);
    const [selectedTransactions, setSelectedTransactions] = useState([]);
    const [apiType, setApiType] = useState("");

    const xeroStatusCodes = [
        {label: "All", value: null},
        {label: "Authorised", value: "AUTHORISED"},
        {label: "Draft", value: "DRAFT"},
        {label: "Submitted", value: "SUBMITTED"},
        // {label: "Deleted", value: "DELETED"},
        // {label: "Voided", value: "VOIDED"},
    ];

    const [selectedXeroStatusCode, setSelectedXeroStatusCode] = useState({label: "Authorised", value: "AUTHORISED"});

    //------------Api Service--------------------------------------------
    const forwardaiInvoicesApi = useApi(externalApiService.getForwardaiInvoices);
    const forwardaiBillApi = useApi(externalApiService.getForwardaiBills);
    const mappedUsersApi = useApi(coreService.GetClientMappedUsers);
    const saveTransApi = useApi(externalApiService.saveTransaction);
    const getBillApi = useApi(externalApiService.GetBill);
    const getInvoiceApi = useApi(externalApiService.GetInvoices);

    const synchronizeData = async () => {

        let response = "";
        if (props.type == 'bill') {
            response = await getBillApi.request({
                entityId: entity_id,
                status: selectedXeroStatusCode.value
            });
        } else {
            response = await getInvoiceApi.request({
                entityId: entity_id,
                status: selectedXeroStatusCode.value
            });
        }

        if (response.data.success == true) {
            setAccData(response.data.data);
            setApiType(response.data.api_type);
            //setDateRange({ startDate: response.data.startDate, endDate: response.data.endDate })
        } else {
            setApiType("");
            setError({ errorType: response.data.errorType, message: response.data.message });
        }
    };

    const fetchMappedUser = async () => {
        let restrictProduct = []; // store product list id -- Only show clients mapped with these products
        if (props.type == 'bill') {
            restrictProduct = [constants.PRODUCTS.SUPPLYCHAIN, constants.PRODUCTS.TRADEFINANCE];
        } else {
            restrictProduct = [constants.PRODUCTS.INVOICE, constants.PRODUCTS.DEBTORFINANACE];
        }
        const { data } = await mappedUsersApi.request({ clientSide: true, type: userType, restrictProduct: restrictProduct });
        if (data.success === false) {
            toast.error('Failed to load customer/debtor');
        } else {
            setClientDebtorList(data.rows);
        }
    }

    const changeClientDebtor = (selectedOption) => {
        setSelectedclientDebtor({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    }

    const handleSelect = (checked, item_id) => {

        if (checked == true) {
            const selectedItem = accData.find((item) => item.ref_id === item_id);
            setSelectedTransactions((selectedTransaction) => [...selectedTransaction, selectedItem]);
        } else {
            setSelectedTransactions(selectedTransactions.filter((item) => item.ref_id !== item_id));
        }
    }

    const handleSynchronize = async () => {
        setSyncError(false);
        if (selectedTransactions.length == 0) {
            toast.error("Select atleast one transaction")
        }
        if (selectedclientDebtor.value == "") {
            toast.error("Select supplier/customer to continue.")
        }
        const { data } = await saveTransApi.request({
            transactions: selectedTransactions,
            supplier_id: selectedclientDebtor.value,
            type: props.type,
            initiated_user_type: 'customer',
            transType: apiType
        });

        if (data.success == true) {
            toast.success("Transactions synchronized successfully");
            props.closeModal();
            props.refreshModal();
        } else {
            setSyncError(data.errors);
            toast.error("Failed to synchronize.");
        }
    }

    useEffect(() => {
        fetchMappedUser();
        synchronizeData();
        setSelectedclientDebtor({
            label: props.type == 'bill' ? '--Select Supplier--' : '-- Select Customer --',
            value: "",
        });
    }, [selectedXeroStatusCode]);

    return (
        <>
            {
                (saveTransApi.loading == true || mappedUsersApi.loading == true) && <LoadingSpinner />
            }
            <div className="overflow-visible">
                {/* <div className="card-header">
                    <h5 className="card-title alert alert-danger">{props.type}</h5>
                </div> */}
                <div className="card-body">
                    {
                        (getBillApi.loading == true || getInvoiceApi.loading == true || forwardaiInvoicesApi.loading || forwardaiBillApi.loading) && (
                            <>

                                <div className="text-center">
                                    <LoadingSpinner normal="true" />
                                    <span className="text-primary">Connecting to accounting software ...</span>
                                </div>
                            </>
                        )
                    }
                    <div>
                        {
                            syncError && syncError.length > 0 && (
                                <>
                                    <div className="text-danger"><b>Following errors occured</b></div>

                                    <table class="table text-danger mb-2">
                                        <thead>
                                            <tr>
                                                <th>Ref.Id</th>
                                                <th>Invoice Number</th>
                                                <th>Message</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                syncError.map(itm => (
                                                    <>
                                                        <tr>
                                                            <td>{itm.id}</td>
                                                            <td>{itm.inv_number}</td>
                                                            <td>{itm.message}</td>
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                </>
                            )
                        }
                        {
                            error && (
                                error.errorType == "" ? (
                                    <>
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="">
                                                <img width={380} height={150} src={disconnectIcon} alt="alt" />
                                            </div>
                                            <div className="font-weight-bold text-danger mt-5">{error.message}</div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="alert alert-danger text-center">
                                            <b>{error.errorType}</b><br />
                                            <span className="text-danger font-weight-bold">{error.message}
                                                <p>Please go the      <Link
                                                    to="/client/accounting-software"
                                                >account settings</Link> and connect your accouting software.</p>
                                            </span>
                                        </div>
                                    </>
                                )

                            )
                        }
                        {
                            accData && !(getBillApi.loading == true || getInvoiceApi.loading == true || forwardaiInvoicesApi.loading || forwardaiBillApi.loading) && (
                                <>
                                    {
                                        accData.length > 0 && (
                                            <>
                                                <div class="dyn-height">
                                                    <table class="table text-secondary">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                {/*<th>Ref.Id</th>*/}
                                                                <th>Invoice Number</th>
                                                                <th>Date</th>
                                                                <th>Due date</th>
                                                                <th>Amount</th>
                                                                <th>Currency</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                accData.map(itm => (
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="custom-control d-flex custom-checkbox small">
                                                                                    <input
                                                                                        id={itm.ref_id}
                                                                                        type="checkbox" className="custom-control-input"
                                                                                        onChange={(e) => handleSelect(e.target.checked, itm.ref_id)}
                                                                                    />

                                                                                    <label className="custom-control-label" htmlFor={itm.ref_id}></label>
                                                                                </div>
                                                                            </td>
                                                                            <td>{itm.inv_number}</td>
                                                                            <td>{itm.date}</td>
                                                                            <td>{itm.dueDate}</td>
                                                                            <td><CurrencyLabel value={itm.amount}></CurrencyLabel></td>
                                                                            <td>{itm.currency}</td>
                                                                        </tr>
                                                                    </>
                                                                ))
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                                {
                                                    <>
                                                        <div className="row mt-2">

                                                            <div className="col-lg-6">
                                                                <label >
                                                                    {`The chosen organization will serve as the ${props.type == 'bill' ? 'supplier' : 'customer'} for these transactions`}
                                                                </label>
                                                                <Picker
                                                                    items={clientDebtorList}
                                                                    selectedItem={selectedclientDebtor}
                                                                    onSelectItem={changeClientDebtor}
                                                                    name="supplier"
                                                                />
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <label >
                                                                    Filter invoices by status below
                                                                </label>
                                                                <Picker
                                                                    items={xeroStatusCodes}
                                                                    selectedItem={selectedXeroStatusCode}
                                                                    onSelectItem={option => setSelectedXeroStatusCode(option)}
                                                                />
                                                            </div>

                                                            <div className="col-lg-2  pt-2">
                                                                <label> </label>
                                                                <button
                                                                    className="btn btn-primary mt-4 pull-right"
                                                                    onClick={() => { handleSynchronize() }}
                                                                    disabled={selectedTransactions.length === 0 || selectedclientDebtor.value == ""}
                                                                >Add</button>

                                                            </div>
                                                        </div>
                                                        {/* <tr>
                                                                        <td colSpan={7}><small>{`* Transactions listed between dates ${moment(dateRange.startDate).format("DD/MM/YYYY")} and ${moment(dateRange.endDate).format("DD/MM/YYYY")}`}</small></td>
                                                                    </tr> */}
                                                    </>
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        accData.length < 1 && (
                                            <>
                                                <div className="alert alert-default">
                                                    <span><strong>No invoice/bill found</strong></span>
                                                </div>

                                                <div className="col-lg-6">
                                                    <label>Please use the filter below to retrieve invoices based on their status.</label>
                                                    <Picker
                                                        items={xeroStatusCodes}
                                                        selectedItem={selectedXeroStatusCode}
                                                        onSelectItem={option => setSelectedXeroStatusCode(option)}
                                                    />
                                                </div>
                                            </>
                                        )
                                    }
                                </>

                            )
                        }
                    </div>

                </div>
            </div>
        </>
    );
}