import React, { useContext } from "react";
import TfPurchase from "./snapshots/TfPurchase";
import TfSale from "./snapshots/TfSale";
//import ScSale from "../scTransactions/snapshots/ScSale";
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";

export default function TradeFinanceSnapshot() {

  const {constants } = authService.getCurrentUser();
  const { selectedBusinessType } = useContext(EntityContext);

  return (
    <>
      <>

        {selectedBusinessType == constants.BUSINESS_TYPE.PURCHASE ? (
          <>
            <TfPurchase  />
          </>
        ) : (
          <>
            <TfSale  />
          </>
        )}

      </>
    </>
  )
}