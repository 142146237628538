import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ProductsList } from "../../slplusTools";
import { GeneralIconButton, TextInput } from "../../common/inputs";
import { toast } from "react-toastify";
import prospect from "../../../services/prospectService";
import useApi from '../../../hooks/useApi';
import LoadingSpinner from '../../common/loaders/loadingSpinner';
import { confirmAlert } from "react-confirm-alert";
import { browserHistory } from "react-router";
import externalApiService from '../../../services/externalApiService';
import StartTour from "../../tour/startTour";
import { Link } from "react-router";

export default function ConvertToAccount({ a_guid, onClose, from, type = "primary", reloadInfo = null, location = null }) {
  /* Defining Constants */
  const childRef = useRef();
  const [products, setProducts] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [buttonName, setButtonName] = useState("Save as Draft");
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const isFirstRender = useRef(true);
  const [abnNumber, setAbnNumber] = useState(null);
  const [entityConversionErrors, setEntityConversionErrors] = useState([]);

  /* Defining Api's */
  const moveToEntityApi = useApi(prospect.moveToEntity);
  const prospectDetailsApi = useApi(prospect.getProspectDetails);
  const prospectUpdateApi = useApi(prospect.saveProspectCoreFields);
  const bulkAbnValidationApi = useApi(externalApiService.bulkAbnValidation);

  /* Function to convert Prospects into Accounts */
  const handleMoveEntity = async () => {
    if (selectedRows[0]['ab_number'] == null || selectedRows[0]['ab_number'] == "") {
      setShow(true); setDisabled(true);
      return true;
    }

    let selectedCategories = [];
    setDisabled(true);
    const { success, message } = await moveToEntityApi.request(
      selectedCategories,
      selectedRows,
      products.filter(obj => obj.isChecked === true),
    );

    if (success === false)
      toast.error(message);

    else {
      // -------- Display a popup modal if saved as Draft --------------- //
      if (products.findIndex(obj => obj.isChecked === true) === -1) {
        onClose();
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-delete-ui">
                <h4>Converted to Draft Accounts</h4>
                <p>Your prospects(s) have been converted to Draft Accounts ready for your to continue setting up.</p>
                <button
                  className="btn btn-white ml-2"
                  onClick={() => {
                    browserHistory.push("/entities?status=pending_accounts");
                    onClose();
                  }}
                >
                  View in Draft Accounts
                </button>
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => {
                    browserHistory.push("/prospects");
                    onClose();
                  }}
                >
                  Close
                </button>
              </div>
            );
          },
          title: "",
          message: ``,
        });
      }
      // ---------------------------------------------------------------- //
      else {
        toast.success(message);
        if (from == "prospect")
          browserHistory.push("/entities");
        onClose();
      }
    }
    setDisabled(false);
  };

  // Function to update ABN Number
  const updateAbnNumber = async () => {
    let updatedList = { ...selectedRows[0] };
    updatedList["owner"] = updatedList.owner_org.a_guid;
    updatedList["ab_number"] = abnNumber;
    delete updatedList.created_at;
    delete updatedList.updated_at;
    updatedList['country'] = updatedList.country_id;

    const { data } = await prospectUpdateApi.request(updatedList);
    if (data.success === true) {
      setShow(false);
      setDisabled(false);
      toast.success(data.message);
      //setSelectedRows({ 0: updatedList });
      abnValidation({ 0: updatedList });

      if (reloadInfo)
        reloadInfo();
    }
    else
      toast.error(data.message);
  }

  const getProspectDetais = async () => {
    const { rows } = await prospectDetailsApi.request(a_guid);
    abnValidation({ 0: rows });
  }

  const abnValidation = async (Rows) => {
    if (Rows[0]['ab_number'] == null || Rows[0]['ab_number'] == "") {
      setShow(true); setDisabled(true);
      Rows[0]['abn_status'] = 'invalid';
    } else {
      const { data } = await bulkAbnValidationApi.request({
        selectedEntities: Rows
      });
      if (data.response.findIndex(obj => obj.error !== null) !== -1) {
        setEntityConversionErrors(data.response.filter(obj => obj.error !== null))
        Rows[0]['abn_status'] = 'invalid';
      } else {
        Rows[0]['abn_status'] = 'approved';
      }
    }
    setSelectedRows(Rows);
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false // setting flag false after firsr render
      getProspectDetais();
    } else {
      let activeProducts = products.filter(obj => obj.isChecked === true);
      if (activeProducts.length > 0) {
        if (entityConversionErrors.length > 0) {
          setButtonName("Save as Draft")
        } else {
          setButtonName("Save")
        }
      } else {
        setButtonName("Save as Draft")
      }
    }
  }, [products]);

  return (
    <>
      <Modal.Header closeButton>
        <h5 className="modal-title">Convert to an Account</h5>
        <Link
          className="action-icons ml-2"
          title="Help"
          onClick={e => {
            e.preventDefault();
            childRef.current.handleClick(e);
          }
          }
        >
          <i class="fa fa-support"></i>
        </Link>
      </Modal.Header>
      <Modal.Body>
        {(bulkAbnValidationApi.loading === true || moveToEntityApi.loading === true || prospectDetailsApi.loading === true || prospectUpdateApi.loading === true) && <LoadingSpinner />}

        <ProductsList
          guid={null}
          col={12}
          onSelectItem={items => setProducts(items)}
          type={type}
        />
        {
          entityConversionErrors && entityConversionErrors.length > 0 && (
            <>
              <div className="alert alert-danger my-2" role="alert">
                ABN {entityConversionErrors[0].error}. Do you want to create as a draft account.
              </div>

            </>
          )
        }

        {show &&
          <div className="row mt-5">
            <div className="col-lg-12">
              <div className="text-center">
                <div class="alert alert-info">This prospect does not have a valid ABN. Please add and click the Update ABN button below to proceed.</div>
                <TextInput
                  name="ab_number"
                  label="ABN"
                  value={abnNumber}
                  onChange={({ target }) => setAbnNumber(target.value)}
                  type="horizontal"
                ></TextInput>
              </div>
            </div>
          </div>
        }

        <div className="row ">
          <div className='col-lg-12'>


            {show && (
              <GeneralIconButton
                name={'Update ABN'}
                onClick={updateAbnNumber}
                disabled={abnNumber === null || abnNumber === ""}
                className={"btn pull-right btn-primary btn-sm ml-1"}
              />
            )}
            {" "}
            <GeneralIconButton
              name={buttonName}
              onClick={() => handleMoveEntity()}
              disabled={disabled}
              className={"btn btn-primary pull-right btn-sm mr-2"}
            />
          </div>

        </div>
      </Modal.Body>

      {/*-------- tour ----------- */}
      {(from == "prospect" && location !== null) && <StartTour ref={childRef} location={location} />}
    </>
  );
}