import React, { Component } from "react";
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import LoanSchedule from "./scheduleList";
import { ViewButton } from "../../../common/inputs";
import auth from "../../../../services/authService";
import { CurrencyLabel } from "../../../common/inputs";
import loanTransactionService from "../../../loan/services/loanTransactions";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";

class LoanIndex extends Component {

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  state = {
    selectedTab: 0, // default selected "open"
    loading: false,
    loanList: [],
    scheduleModal: false,
    openNewLoanModal: false,
  };

  componentDidMount = () => {
    this.fetchLoanList(0);
  }

  handleScheduleModal = (state = true) => {
    this.setState({ scheduleModal: state });
  }

  handleChangeTab = (key) => {
    this.setState({ selectedTab: key });
    this.fetchLoanList(key);
  }

  fetchLoanList = async (statusKey) => {
    this.setState({ loading: true });
    const loanStatus = this.getLoanStatus(statusKey);
    const { data } = await loanTransactionService.entityLoanDetails({
      additionalParams: {
        clientReportType: true
      }
    }, loanStatus);
    this.setState({ loanList: data.rows, loading: false });
  }

  getLoanStatus = (key) => {
    let status = "";
    switch (key) {
      case 0:
        status = 'open';
        break;
      case 1:
        status = 'draft';
        break;
      default:
        status = 'open';
        break;
    }
    return status;
  }

  render() {
    const { constants } = auth.getCurrentUser();

    const tabItems = ["Open", "Processing"];

    return (
      <>

        {(this.state.loading == true) && <LoadingSpinner />}
        <div className="row">
          <div className="col-lg-12">
            <div class="tabs-container">
              <ul class="nav nav-tabs mb-4">
                {
                  tabItems.map((val, key) => (
                    <li className="nav-item" key={key}>
                      <a className={`nav-link cursor-link ${this.state.selectedTab === key ? 'active' : ''}`} onClick={e => this.handleChangeTab(key)} >{val}</a>
                    </li>
                  ))
                }
              </ul>
            </div>
            <table className="table text-secondary">
              {this.state.loanList.length > 0 && (
                <thead>
                  <tr>
                    <th className="border-top-0">Loan Reference</th>
                    <th className="border-top-0">Loan Type</th>
                    {
                      this.state.selectedTab == "1" && (
                        <>
                          <th className="border-top-0">Application Date</th>
                        </>
                      )
                    }

                    <th className="border-top-0">Actual Loan Amount</th>

                    {
                      this.state.selectedTab == "0" && (
                        <>
                          <th className="border-top-0">Paid Principle</th>
                        </>
                      )
                    }
                    {
                      this.state.selectedTab == "0" && (
                        <>
                          <th className="border-top-0">Interest Paid</th>
                        </>
                      )
                    }

                    {
                      this.state.selectedTab == "0" && (
                        <>
                          <th className="border-top-0">Balance Amount</th>
                        </>
                      )
                    }
                    <th className="border-top-0">Start Date</th>
                    <th className="border-top-0">End Date</th>
                    {
                      this.state.selectedTab == "0" && (
                        <>
                          <th className="border-top-0">Action</th>
                        </>
                      )
                    }
                  </tr>
                </thead>
              )}
              <tbody>
                {
                  this.state.loanList && this.state.loanList.map(item => (
                    <>
                      <tr>
                        <td>
                          {item.loan_reference}
                          {
                            item.working_capital_loan == 'Yes' && (
                              <>
                                <span title="Drawn from Trade Finance facility" className="ml-2 badge badge-danger">TF</span>
                              </>
                            )
                          }

                        </td>
                        <td>{item.loan_type}</td>
                        {
                          this.state.selectedTab == "1" && (
                            <td>{item.loan_created_at}</td>
                          )
                        }
                        <td>
                          <CurrencyLabel value={item.loan_amount}></CurrencyLabel>
                        </td>
                        {
                          this.state.selectedTab == "0" && (
                            <td>
                              <CurrencyLabel value={item.paid_principal}></CurrencyLabel>
                            </td>
                          )
                        }
                        {
                          this.state.selectedTab == "0" && (
                            <td>
                              <CurrencyLabel value={item.paid_interest}></CurrencyLabel>
                            </td>
                          )
                        }
                        {
                          this.state.selectedTab == "0" && (
                            <td>
                              <CurrencyLabel value={(item.loan_amount - item.paid_principal)}></CurrencyLabel>
                            </td>
                          )
                        }

                        <td>{item.start_date}</td>
                        <td>{item.end_date}</td>
                        {
                          this.state.selectedTab == "0" && (
                            <>
                              <td><Link
                                className="action-icons text-secondary"
                                onClick={e => {
                                  this.setState({ selectedLoan: item.loan_uid }, () => this.handleScheduleModal())
                                }} >
                                <ViewButton></ViewButton>
                              </Link >
                              </td>
                            </>
                          )
                        }
                      </tr>
                    </>
                  ))
                }
                {
                  this.state.loanList.length < 1 && this.state.selectedTab == "0" && (
                    <>
                      <tr>
                        <td colSpan={7}>
                          No Loans Found
                        </td>
                      </tr>
                    </>
                  )
                }
                {
                  this.state.loanList.length < 1 && this.state.selectedTab == "1" && (
                    <>
                      <tr>
                        <td colSpan={5}>No Loans Found</td>
                      </tr>
                    </>
                  )
                }
              </tbody>
            </table>

          </div>
        </div>

        {/*------------------Entity Contacts-------------------*/}
        <Modal
          show={this.state.scheduleModal}
          onHide={e => {
            this.handleScheduleModal(false);
          }}
          size="xl">
          <Modal.Header closeButton>
            <h4 className="modal-title">Loan Schedule</h4>
          </Modal.Header>

          <Modal.Body>
            <LoanSchedule
              loanGuid={this.state.selectedLoan}
              handleScheduleModal={this.handleScheduleModal}
            />
          </Modal.Body>
        </Modal>

      </>
    );
  }
}
export default LoanIndex;