import React from "react";
import InvoiceUploader from "../../../invTransactions/layout/singleInvoice";
import authService from "../../../../services/authService";
import { Link } from "react-router";
import { browserHistory } from "react-router";

const BulkUpload = (props) => {
  
  const { entity_id } = authService.getCurrentUser();

  return (
    <div className="card">
      <div className="card-header">
        <div class="d-flex justify-content-between">
          <h4 className="card-title">
            INVOICE BULK UPLOAD
          </h4>

          <div class="ml-auto">
            <Link to='/client/if/dashboard' class="btn  btn-light   ml-2"><i alt="Notes" data-toggle="tooltip" data-placement="top" title="Close" className="fa fa-times  "></i></Link>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
            <InvoiceUploader 
              location={props.location}
              handleTransactionList={() => {browserHistory.push("/client/if/sale-invoices?status=draft")}}
              client={entity_id}
              onClose={() => {browserHistory.push("/client/if/dashboard")}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;