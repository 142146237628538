import React, { useContext } from "react";

import { CurrencyLabel } from "../../../../common/inputs/currencyInput/currencyInput";



import ApproveContext from "../../../../supply-chain/context/bulkApproveContext"; // React Context API

function AmountToPay(props) {
  // const approveApi = useApi(commonService.createBulkApproval);
  // const { ParseDate } = Utility();
  const bulkapproveOptions = useContext(ApproveContext);
  // const updateApproval = async () => {
  //   const { data } = await approveApi.request({
  //     invoices: bulkapproveOptions.invoiceToProcess,
  //     userType: "customer",
  //     revised_date_option: bulkapproveOptions.dueDateOption,
  //     revised_date: ParseDate(bulkapproveOptions.dueDateSelected),
  //   });
  //   bulkapproveOptions.onHandleReload();
  //   toast.success(data["message"]);
  // };

  return (
    <>
      {bulkapproveOptions.showAmount == true && (


        <div className="d-flex align-items-center">
        <div><h4 className="card-title">Total Amount To Be Approved</h4>
          <h2 className="font-weight-bold"><CurrencyLabel value={bulkapproveOptions.totalDue}></CurrencyLabel></h2>
          <p className="mt-4 mb-1 opacity-50"> Total invoices selected :</p>
          <div className="row"><div className="col d-flex text-muted"><span className="font-weight-bold text-xl">{bulkapproveOptions.invoiceToProcess.length}</span></div></div>
        </div>
        <div className="ml-auto ">
          <div className="avatar avatar-lg bg-warning-transparent mr-2 rounded-full"><i className="fa fa-money text-warning"></i></div>
        </div>
        </div>
      

      )}
    </>
  );
}

export default AmountToPay;
