import React from "react";
import CardSkelton from "../../components/cards/_cardSkelton";
import { Link } from "react-router";

function Rejected({ data, loading }) {
    return (
        <>
            {loading === true ? <CardSkelton col={12} /> : (
                <div className="col-lg-12" >
                    <div className="card">
                        <div className="card-body">
                            <span className="b-corner b-corner-r b-corner-success" />
                            <div className="row mt-2 mb-2">
                                <div className="col-lg-12 pb-1" >
                                   <Link className="text-secondary" to="/client/tf/rebates">
                                        <h6 className="card-title">
                                            {data ? data.topLabel : ""} <span className="pull-right">{data ? data.topValue : ""} </span>
                                        </h6>
                                    </Link>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default Rejected;