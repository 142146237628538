
import { FINANCE_ACTION_TYPES, RESET_BUSINESS } from './finance.types';
import { v4 as uuidv4 } from 'uuid';
import FMB_icon10 from '../../img/icons/FMB_icon10.svg'

const INITIAL_STATE = {
    answers: [],
    questions:
    {
        cashFlow: [

            {
                id: uuidv4(),
                questionNumber: 1,
                image: FMB_icon10,
                questionType: 'options',
                other: true,
                question: ' Which of the following reasons to use cash flow finance are of interest to you?',
                options: [
                    {
                        id: uuidv4(),
                        label: 'Accelerate invoice payments',
                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                    },
                    {
                        id: uuidv4(),
                        label: 'De-risk late payments',
                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]


                    },
                    {
                        id: uuidv4(),
                        label: 'Maximise inward cash flows',
                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]

                    },
                    {
                        id: uuidv4(),
                        label: 'Ensure I get paid from existing and new business customers',
                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]

                    },
                    {
                        id: uuidv4(),
                        label: 'Offer better terms to existing or new business customers',
                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]

                    },
                    {
                        id: uuidv4(),
                        label: 'Manage payment terms with bigger customers who need longer payment terms',
                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]

                    },
                    {
                        id: uuidv4(),
                        label: 'Kickstart new project or support business growth',
                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]

                    }

                ]

            },
            {
                id: uuidv4(),
                answer: null,
                questionNumber: 2,
                questionType: 'text',
                question: ' How much cash flow are you looking for?',
            },
            {
                id: uuidv4(),

                questionNumber: 3,
                other: false,
                questionType: 'options',
                question: ' What are your customers normal trading terms?  ',
                options: [
                    {
                        id: uuidv4(),
                        label: null,
                        options: [
                            { value: '30 Days', id: uuidv4(), selected: false },
                            { value: '60 Days', id: uuidv4(), selected: false },
                            { value: '90 days', selected: false, id: uuidv4() },
                            { value: 'Other', id: uuidv4(), selected: false }
                        ]
                    }
                ]
            },
            {
                id: uuidv4(),

                questionNumber: 4,
                questionType: 'options',
                other: false,
                question: 'Do your customers have terms or trade contracts?  ',
                options: [{
                    label: null,
                    id: uuidv4(),
                    options: [{ value: "Yes", selected: false, id: uuidv4(), }, { value: "No", selected: false, id: uuidv4(), }, { value: "Unknown", selected: false, id: uuidv4(), }]

                }
                ]
            },
            {
                id: uuidv4(),

                questionNumber: 5,
                questionType: 'multiple',
                other: false,

                question: 'Do you have arrangements with any of your customers to allow for any of the following? ',
                options: [{
                    id: uuidv4(),
                    label: null,
                    options: [{ value: 'Progress payments', selected: false, id: uuidv4(), }, { value: 'Retentions', selected: false, id: uuidv4(), }, { value: 'Common debtors/creditors', selected: false, id: uuidv4(), }, { value: 'Rebates', selected: false, id: uuidv4(), }, { value: 'Rights to set-off or discount ', selected: false, id: uuidv4(), }, { value: 'Sale or return', selected: false, id: uuidv4(), }, { value: 'Invoices generated prior to delivery of goods ', selected: false, id: uuidv4(), }, { value: 'Unknown/None', selected: false, id: uuidv4(), }]
                }
                ]
            },
            {
                id: uuidv4(),
                answer: null,

                questionNumber: 6,
                questionType: 'text',
                question: ' How much are you currently owed by your customers?  ',
            },
            {
                id: uuidv4(),
                answer: null,

                questionNumber: 7,
                questionType: 'text',
                question: '  How much is past 90 days?',
            },
            {
                id: uuidv4(),

                questionNumber: 8,
                questionType: 'select',
                answer: null,
                question: ' What is the largest percentage of your receivables ledger owed from one customer?  ',
                options: [

                    { label: '<5%', value: '<5%', selected: false, id: uuidv4() },
                    { label: '5% - 10%', value: '5% - 10%', selected: false, id: uuidv4() },
                    { label: '11% - 20%', value: '11% - 20%', selected: false, id: uuidv4() },
                    { label: '21% - 30%', value: '21% - 30%', selected: false, id: uuidv4() },
                    { label: '31% - 40%', value: '31% - 40%', selected: false, id: uuidv4() },
                    { label: '41% - 50%', value: '41% - 50%', selected: false, id: uuidv4() },
                    { label: '51% - 75%', value: '51% - 75%', selected: false, id: uuidv4() },
                    { label: '76% - 100%', value: '76% - 100%', selected: false, id: uuidv4() },
                    { label: 'Currently Unknown', value: 'Currently Unknown', selected: false, id: uuidv4() },


                ]
            },
        ],
        workingCapital: [
            {
                id: uuidv4(),

                questionNumber: 1,
                other: false,
                questionType: 'options',
                question: ' Which of the following reasons to use working capital finance are of interest to you?',
                options: [{
                    id: uuidv4(),
                    label: 'Extend payment terms you have with suppliers	',
                    options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                },
                {
                    id: uuidv4(),
                    label: 'Have funds available to capitalise on supplier discounts	',
                    options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                },
                {
                    id: uuidv4(),
                    label: 'Turn COD suppliers into 30, 60 or 90 day term suppliers',
                    options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                },
                {
                    id: uuidv4(),
                    label: 'Have funds to pay deposits, or pre-shipment payments',
                    options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                },
                {
                    id: uuidv4(),
                    label: 'Manage overseas supplier payments (including longer terms)	',
                    options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                }
                ]
            },
            {
                id: uuidv4(),

                questionNumber: 2,
                questionType: 'text',
                question: ' How much working capital are you looking for?',
                answer: null
            },
            {
                id: uuidv4(),

                questionNumber: 3,
                other: false,
                questionType: 'options',
                question: ' What are your supplier`s normal trading terms?  ',
                options: [{
                    id: uuidv4(),
                    label: null,
                    options: [{ value: 'Cash on delivery', selected: null, id: uuidv4() }, { value: '30 Days', selected: null, id: uuidv4() }, { value: '60 Days', selected: null, id: uuidv4() }, { value: '90 days', selected: null, id: uuidv4() }, { value: 'Over 90 days', selected: null, id: uuidv4() }, { value: 'Other', selected: null, id: uuidv4() }]
                }
                ]
            },
            {
                id: uuidv4(),

                questionNumber: 4,
                other: false,
                questionType: 'options',
                question: 'Do your suppliers have terms or trade contracts?  ',
                options: [{
                    id: uuidv4(),
                    label: null,
                    options: [{ value: 'Yes', selected: false, id: uuidv4() }, { value: 'No', selected: false, id: uuidv4() }, { value: 'Unknown', selected: false, id: uuidv4() }]
                }
                ]
            },
            {
                id: uuidv4(),

                questionNumber: 5,
                other: false,
                questionType: 'multiple',
                question: 'Do you have arrangements with any of your suppliers to allow for any of the following? ',
                options: [{
                    id: uuidv4(),

                    label: null,
                    options: [{ value: 'Progress payments', selected: null, id: uuidv4() }, { value: 'Retentions', selected: null, id: uuidv4() }, { value: 'Common debtors/creditors', selected: null, id: uuidv4() }, { value: 'Rebates', selected: null, id: uuidv4() }, { value: 'Rights to set-off or discount ', selected: null, id: uuidv4() }, { value: 'Sale or return', seleted: null, id: uuidv4() }, { value: 'Invoices generated prior to delivery of goods ', selected: null, id: uuidv4() }, { value: 'Unknown/None', selected: null, id: uuidv4() }]
                }]
            },
            {
                id: uuidv4(),
                answer: null,

                questionNumber: 6,
                questionType: 'text',
                question: ' How much do you currently owe to your suppliers?  ',
            },
            {
                id: uuidv4(),
                answer: null,

                questionNumber: 7,
                questionType: 'text',
                question: '  How much is past 90 days?',
            },
            {
                id: uuidv4(),
                answer: null,

                questionNumber: 8,
                questionType: 'select',
                question: ' What is the largest percentage of your payable ledger owed to one supplier?',
                options: [
                    { label: '<5%', value: '<5%', selected: false, id: uuidv4() },
                    { label: '5% - 10%', value: '5% - 10%', selected: false, id: uuidv4() },
                    { label: '11% - 20%', value: '11% - 20%', selected: false, id: uuidv4() },
                    { label: '21% - 30%', value: '21% - 30%', selected: false, id: uuidv4() },
                    { label: '31% - 40%', value: '31% - 40%', selected: false, id: uuidv4() },
                    { label: '41% - 50%', value: '41% - 50%', selected: false, id: uuidv4() },
                    { label: '51% - 75%', value: '51% - 75%', selected: false, id: uuidv4() },
                    { label: '76% - 100%', value: '76% - 100%', selected: false, id: uuidv4() },
                    { label: 'Currently Unknown', value: 'Currently Unknown', selected: false, id: uuidv4() },

                ]
            },

        ],
        finalQuestions: [
            {
                id: uuidv4(),
                answer: null,

                questionNumber: 1,
                questionType: 'text',
                question: ' What is your forecasted annual sales turnover? ',
            },
            {
                id: uuidv4(),
                answer: null,

                questionNumber: 2,
                questionType: 'text',
                question: ' What was last year`s turnover? ',
            },
            {
                id: uuidv4(),
                answer: null,

                questionNumber: 3,
                questionType: 'text',
                question: ' What was last year`s gross profit?',
            },
            {
                id: uuidv4(),
                answer: null,

                questionNumber: 4,
                questionType: 'text',
                question: 'What was last year`s net profit? ',
            },
            {
                id: uuidv4(),

                questionNumber: 5,
                other: false,
                questionType: 'options',
                question: 'What is the position of your taxes? ',
                options: [{
                    id: uuidv4(),

                    label: null,
                    options: [{ value: 'Up to date', selected: null, id: uuidv4() }, { value: 'Not up to date', selected: false, id: uuidv4() }, { value: 'Playment plan in place ', selected: null, id: uuidv4() }, { value: 'Unknown', selected: null, id: uuidv4() }]
                }
                ]
            },
            {
                id: uuidv4(),

                questionNumber: 6,
                other: false,
                questionType: 'multiple',
                question: 'What are your current financial arrangements?',
                options: [{
                    id: uuidv4(),

                    label: null,
                    options: [{ value: 'No finance ', selected: null, id: uuidv4() }, { value: ' Bank overdraft', selected: null, id: uuidv4() }, { value: ' Business loan(s)  ', selected: null, id: uuidv4() }, { value: 'Debtor finance', selected: null, id: uuidv4() }, { value: 'Directors loan(s)', selected: null, id: uuidv4() }, { value: ' Trade finance', selected: null, id: uuidv4() }, { value: ' Other', selected: null, id: uuidv4() }]
                }
                ]
            }
        ]
    },
    selectedtype: {
        workingCapital: null,
        cashFlowFinance: null
    },
    questions2: [

        {
            id: uuidv4(),
            answer: null,
            questionNumber: 1,
            questionType: 'text',
            question: ' What size facility are you looking for?',
        },
        {
            id: uuidv4(),

            questionNumber: 2,
            other: false,
            questionType: 'options',
            question: ' What are your customers normal trading terms?  ',
            options: [
                {
                    id: uuidv4(),
                    label: null,
                    options: [
                        { value: '30 Days', id: uuidv4(), selected: false },
                        { value: '60 Days', id: uuidv4(), selected: false },
                        { value: '90 days', selected: false, id: uuidv4() },
                        { value: 'Other', id: uuidv4(), selected: false }
                    ]
                }
            ]
        },

        {
            id: uuidv4(),

            questionNumber: 3,
            questionType: 'options',
            other: false,
            question: 'Do your customers have terms or trade contracts?  ',
            options: [{
                label: null,
                id: uuidv4(),
                options: [{ value: "Yes", selected: false, id: uuidv4(), }, { value: "No", selected: false, id: uuidv4(), }, { value: "Unknown", selected: false, id: uuidv4(), }]

            }
            ]
        },
        {
            id: uuidv4(),

            questionNumber: 4,
            questionType: 'multiple',
            other: false,

            question: 'Do you have arrangements with any of your customers to allow for any of the following? ',
            options: [{
                id: uuidv4(),
                label: null,
                options: [{ value: 'Progress payments', selected: false, id: uuidv4(), }, { value: 'Retentions', selected: false, id: uuidv4(), }, { value: 'Common debtors/creditors', selected: false, id: uuidv4(), }, { value: 'Rebates', selected: false, id: uuidv4(), }, { value: 'Rights to set-off or discount ', selected: false, id: uuidv4(), }, { value: 'Sale or return', selected: false, id: uuidv4(), }, { value: 'Invoices generated prior to delivery of goods ', selected: false, id: uuidv4(), }, { value: 'Unknown/None', selected: false, id: uuidv4(), }]
            }
            ]
        },
        {
            id: uuidv4(),
            answer: null,

            questionNumber: 5,
            questionType: 'text',
            question: ' How much are you currently owed from your customers?  ',
        },
        {
            id: uuidv4(),
            answer: null,

            questionNumber: 6,
            questionType: 'text',
            question: '  How much is past 90 days?',
        },
        {
            id: uuidv4(),

            questionNumber: 7,
            questionType: 'select',
            answer: null,
            question: '  What is the largest percentage of your receivables ledger owed from one customer?',
            options: [

                { label: '<5%', value: '<5%', selected: false, id: uuidv4() },
                { label: '5% - 10%', value: '5% - 10%', selected: false, id: uuidv4() },
                { label: '11% - 20%', value: '11% - 20%', selected: false, id: uuidv4() },
                { label: '21% - 30%', value: '21% - 30%', selected: false, id: uuidv4() },
                { label: '31% - 40%', value: '31% - 40%', selected: false, id: uuidv4() },
                { label: '41% - 50%', value: '41% - 50%', selected: false, id: uuidv4() },
                { label: '51% - 75%', value: '51% - 75%', selected: false, id: uuidv4() },
                { label: '76% - 100%', value: '76% - 100%', selected: false, id: uuidv4() },
                { label: 'Currently Unknown', value: 'Currently Unknown', selected: false, id: uuidv4() },


            ]
        },

        {
            id: uuidv4(),

            questionNumber: 8,
            other: false,
            questionType: 'options',
            question: ' What are your supplier`s normal trading terms?  ',
            options: [{
                id: uuidv4(),
                label: null,
                options: [{ value: 'Cash on delivery', selected: null, id: uuidv4() }, { value: '30 Days', selected: null, id: uuidv4() }, { value: '60 Days', selected: null, id: uuidv4() }, { value: '90 days', selected: null, id: uuidv4() }, { value: 'Over 90 days', selected: null, id: uuidv4() }, { value: 'Other', selected: null, id: uuidv4() }]
            }
            ]
        },
        {
            id: uuidv4(),

            questionNumber: 9,
            other: false,
            questionType: 'options',
            question: 'Do your suppliers have terms or trade contracts?  ',
            options: [{
                id: uuidv4(),
                label: null,
                options: [{ value: 'Yes', selected: false, id: uuidv4() }, { value: 'No', selected: false, id: uuidv4() }, { value: 'Unknown', selected: false, id: uuidv4() }]
            }
            ]
        },
        {
            id: uuidv4(),

            questionNumber: 10,
            other: false,
            questionType: 'multiple',
            question: 'Do you have arrangements with any of your suppliers to allow for any of the following? ',
            options: [{
                id: uuidv4(),

                label: null,
                options: [{ value: 'Progress payments', selected: null, id: uuidv4() }, { value: 'Retentions', selected: null, id: uuidv4() }, { value: 'Common debtors/creditors', selected: null, id: uuidv4() }, { value: 'Rebates', selected: null, id: uuidv4() }, { value: 'Rights to set-off or discount ', selected: null, id: uuidv4() }, { value: 'Sale or return', seleted: null, id: uuidv4() }, { value: 'Invoices generated prior to delivery of goods ', selected: null, id: uuidv4() }, { value: 'Unknown/None', selected: null, id: uuidv4() }]
            }]
        },
        {
            id: uuidv4(),
            answer: null,

            questionNumber: 11,
            questionType: 'text',
            question: ' How much are you currently owed from your suppliers?  ',
        },
        {
            id: uuidv4(),
            answer: null,

            questionNumber: 12,
            questionType: 'text',
            question: '  How much is past 90 days?',
        },
        {
            id: uuidv4(),

            questionNumber: 13,
            questionType: 'select',
            question: ' What is the largest percentage of your payable ledger owed to one supplier?',
            options: [
                { label: '<5%', value: '<5%', selected: false, id: uuidv4() },
                { label: '5% - 10%', value: '5% - 10%', selected: false, id: uuidv4() },
                { label: '11% - 20%', value: '11% - 20%', selected: false, id: uuidv4() },
                { label: '21% - 30%', value: '21% - 30%', selected: false, id: uuidv4() },
                { label: '31% - 40%', value: '31% - 40%', selected: false, id: uuidv4() },
                { label: '41% - 50%', value: '41% - 50%', selected: false, id: uuidv4() },
                { label: '51% - 75%', value: '51% - 75%', selected: false, id: uuidv4() },
                { label: '76% - 100%', value: '76% - 100%', selected: false, id: uuidv4() },
                { label: 'Currently Unknown', value: 'Currently Unknown', selected: false, id: uuidv4() },

            ]
        },
        {
            id: uuidv4(),
            answer: null,

            questionNumber: 14,
            questionType: 'text',
            question: ' What is your forecasted annual sales turnover? ',
        },
        {
            id: uuidv4(),
            answer: null,

            questionNumber: 15,
            questionType: 'text',
            question: ' hat was last year`s turnover? ',
        },
        {
            id: uuidv4(),
            answer: null,

            questionNumber: 16,
            questionType: 'text',
            question: ' What was last year`s gross profit?',
        },
        {
            id: uuidv4(),
            answer: null,

            questionNumber: 17,
            questionType: 'text',
            question: 'What was last year`s net profit? ',
        },
        {
            id: uuidv4(),

            questionNumber: 18,
            other: false,
            questionType: 'options',
            question: 'What is the position of your taxes? ',
            options: [{
                id: uuidv4(),

                label: null,
                options: [{ value: 'Up to date', selected: null, id: uuidv4() }, { value: 'Not up to date', selected: false, id: uuidv4() }, { value: 'Playment plan in place ', selected: null, id: uuidv4() }, { value: 'Unknown', selected: null, id: uuidv4() }]
            }
            ]
        },
        {
            id: uuidv4(),

            questionNumber: 19,
            other: false,
            questionType: 'options',
            question: 'What are your current financial arrangements?',
            options: [{
                id: uuidv4(),

                label: null,
                options: [{ value: 'No finance ', selected: null, id: uuidv4() }, { value: ' Bank overdraft', selected: null, id: uuidv4() }, { value: ' Business loan(s)  ', selected: null, id: uuidv4() }, { value: 'Debtor finance', selected: null, id: uuidv4() }, { value: 'Directors loan(s)', selected: null, id: uuidv4() }, { value: ' Trade finance', selected: null, id: uuidv4() }, { value: ' Other', selected: null, id: uuidv4() }]
            }
            ]
        },
        {
            id: uuidv4(),
            answer: [{
                data: null
            }
            ],

            questionNumber: 20,
            questionType: 'text',
            question: '  What are the full name and email address of each director',
        },
        {
            id: uuidv4(),
            answer: null,

            questionNumber: 21,
            questionType: 'address',
            question: '  Upload your financial information',
        },
    ]
};

const financeReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case FINANCE_ACTION_TYPES.SET_SELECTED_TYPE:

            return {
                ...state, selectedtype: payload
            }
        case FINANCE_ACTION_TYPES.HANDLE_CHANGE_ANSWERS:

            let findQuestion;
            switch (payload.questionSection) {
                case 'cashFlowQestions':
                    findQuestion = state.questions.cashFlow.filter(row => row.id === payload.questionId)
                    break;
                case 'workingCapitalQuestions':
                    findQuestion = state.questions.workingCapital.filter(row => row.id === payload.questionId)
                    break;
                case 'finalQuestions':
                    findQuestion = state.questions.finalQuestions.filter(row => row.id === payload.questionId)
                    break;
                default:
                    return state;
            }
            if (payload.questionType == "options") {
                let findOption = findQuestion[0].options.filter(row => row.id === payload.optionId)
                let toUnselect = findOption[0].options.filter(row => row.value !== payload.value)
                toUnselect.map(row => row.selected = false)
                let toSelect = findOption[0].options.filter(row => row.value === payload.value)
                toSelect.map(row => row.selected = true)
            } else if (payload.questionType == "text")
                findQuestion[0].answer = payload.value
            else if (payload.questionType == "multiple")
                findQuestion[0]['options'][0]['options'][payload.index].selected = payload.value
            else
                findQuestion[0].answer = payload.value
            findQuestion = state.questions
            return {
                ...state,
                questions: findQuestion
            }
            
            case FINANCE_ACTION_TYPES.HANDLE_CHANGE_ANSWERS_FINANCE:

                let findQuestion2;
        
                switch (payload.questionSection) {
                    case 'cashFlowQestions':
                        findQuestion2 = state.questions2.filter(row => row.id === payload.questionId)
                        break;
                    case 'finals':
                        findQuestion2 = state.questions2.filter(row => row.id === payload.questionId)
                        break;

                    default:
                        return state;
                }
                if (payload.questionType == "options") {
                    let findOption = findQuestion2[0].options.filter(row => row.id === payload.optionId)
                    let toUnselect = findOption[0].options.filter(row => row.value !== payload.value)
                    toUnselect.map(row => row.selected = false)
                    let toSelect = findOption[0].options.filter(row => row.value === payload.value)
                    toSelect.map(row => row.selected = true)
                } else if (payload.questionType == "text") {
                    if (payload.questionSection === "finals") {
                        findQuestion2[0].answer[payload.boxNumber].data = payload.value
                    } else {
                        findQuestion2[0].answer = payload.value
                    }
                }
                else if (payload.questionType == "multiple")
                    findQuestion2[0]['options'][0]['options'][payload.index].selected = payload.value
                else
                    findQuestion2[0].answer = payload.value

                findQuestion2 = state.questions2
                
                return {
                    ...state, questions2: findQuestion2
                }

            case FINANCE_ACTION_TYPES.HANDLE_ADD_MORE:
                let findQuest = state.questions2.filter(row => row.id === payload)
                debugger
                findQuest[0].answer.push({ data: null })
                findQuest = state.questions2
                return {
                    ...state, findQuestion2: findQuest
                }
            case FINANCE_ACTION_TYPES.RESET_BUSINESS:
                return {
                    answers: [],
                    questions:
                    {
                        cashFlow: [
                
                            {
                                id: uuidv4(),
                                questionNumber: 1,
                                image: FMB_icon10,
                                questionType: 'options',
                                other: true,
                                question: ' Which of the following reasons to use cash flow finance are of interest to you?',
                                options: [
                                    {
                                        id: uuidv4(),
                                        label: 'Accelerate invoice payments',
                                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                                    },
                                    {
                                        id: uuidv4(),
                                        label: 'De-risk late payments',
                                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                
                
                                    },
                                    {
                                        id: uuidv4(),
                                        label: 'Maximise inward cash flows',
                                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                
                                    },
                                    {
                                        id: uuidv4(),
                                        label: 'Ensure I get paid from existing and new business customers',
                                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                
                                    },
                                    {
                                        id: uuidv4(),
                                        label: 'Offer better terms to existing or new business customers',
                                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                
                                    },
                                    {
                                        id: uuidv4(),
                                        label: 'Manage payment terms with bigger customers who need longer payment terms',
                                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                
                                    },
                                    {
                                        id: uuidv4(),
                                        label: 'Kickstart new project or support business growth',
                                        options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                
                                    }
                
                                ]
                
                            },
                            {
                                id: uuidv4(),
                                answer: null,
                                questionNumber: 2,
                                questionType: 'text',
                                question: ' How much cash flow are you looking for?',
                            },
                            {
                                id: uuidv4(),
                
                                questionNumber: 3,
                                other: false,
                                questionType: 'options',
                                question: ' What are your customers normal trading terms?  ',
                                options: [
                                    {
                                        id: uuidv4(),
                                        label: null,
                                        options: [
                                            { value: '30 Days', id: uuidv4(), selected: false },
                                            { value: '60 Days', id: uuidv4(), selected: false },
                                            { value: '90 days', selected: false, id: uuidv4() },
                                            { value: 'Other', id: uuidv4(), selected: false }
                                        ]
                                    }
                                ]
                            },
                            {
                                id: uuidv4(),
                
                                questionNumber: 4,
                                questionType: 'options',
                                other: false,
                                question: 'Do your customers have terms or trade contracts?  ',
                                options: [{
                                    label: null,
                                    id: uuidv4(),
                                    options: [{ value: "Yes", selected: false, id: uuidv4(), }, { value: "No", selected: false, id: uuidv4(), }, { value: "Unknown", selected: false, id: uuidv4(), }]
                
                                }
                                ]
                            },
                            {
                                id: uuidv4(),
                
                                questionNumber: 5,
                                questionType: 'multiple',
                                other: false,
                
                                question: 'Do you have arrangements with any of your customers to allow for any of the following? ',
                                options: [{
                                    id: uuidv4(),
                                    label: null,
                                    options: [{ value: 'Progress payments', selected: false, id: uuidv4(), }, { value: 'Retentions', selected: false, id: uuidv4(), }, { value: 'Common debtors/creditors', selected: false, id: uuidv4(), }, { value: 'Rebates', selected: false, id: uuidv4(), }, { value: 'Rights to set-off or discount ', selected: false, id: uuidv4(), }, { value: 'Sale or return', selected: false, id: uuidv4(), }, { value: 'Invoices generated prior to delivery of goods ', selected: false, id: uuidv4(), }, { value: 'Unknown/None', selected: false, id: uuidv4(), }]
                                }
                                ]
                            },
                            {
                                id: uuidv4(),
                                answer: null,
                
                                questionNumber: 6,
                                questionType: 'text',
                                question: ' How much are you currently owed by your customers?  ',
                            },
                            {
                                id: uuidv4(),
                                answer: null,
                
                                questionNumber: 7,
                                questionType: 'text',
                                question: '  How much is past 90 days?',
                            },
                            {
                                id: uuidv4(),
                
                                questionNumber: 8,
                                questionType: 'select',
                                answer: null,
                                question: ' What is the largest percentage of your receivables ledger owed from one customer?  ',
                                options: [
                
                                    { label: '<5%', value: '<5%', selected: false, id: uuidv4() },
                                    { label: '5% - 10%', value: '5% - 10%', selected: false, id: uuidv4() },
                                    { label: '11% - 20%', value: '11% - 20%', selected: false, id: uuidv4() },
                                    { label: '21% - 30%', value: '21% - 30%', selected: false, id: uuidv4() },
                                    { label: '31% - 40%', value: '31% - 40%', selected: false, id: uuidv4() },
                                    { label: '41% - 50%', value: '41% - 50%', selected: false, id: uuidv4() },
                                    { label: '51% - 75%', value: '51% - 75%', selected: false, id: uuidv4() },
                                    { label: '76% - 100%', value: '76% - 100%', selected: false, id: uuidv4() },
                                    { label: 'Currently Unknown', value: 'Currently Unknown', selected: false, id: uuidv4() },
                
                
                                ]
                            },
                        ],
                        workingCapital: [
                            {
                                id: uuidv4(),
                
                                questionNumber: 1,
                                other: false,
                                questionType: 'options',
                                question: ' Which of the following reasons to use working capital finance are of interest to you?',
                                options: [{
                                    id: uuidv4(),
                                    label: 'Extend payment terms you have with suppliers	',
                                    options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                                },
                                {
                                    id: uuidv4(),
                                    label: 'Have funds available to capitalise on supplier discounts	',
                                    options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                                },
                                {
                                    id: uuidv4(),
                                    label: 'Turn COD suppliers into 30, 60 or 90 day term suppliers',
                                    options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                                },
                                {
                                    id: uuidv4(),
                                    label: 'Have funds to pay deposits, or pre-shipment payments',
                                    options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                                },
                                {
                                    id: uuidv4(),
                                    label: 'Manage overseas supplier payments (including longer terms)	',
                                    options: [{ value: "Yes", selected: false, id: uuidv4() }, { value: "No", selected: false, id: uuidv4() }]
                                }
                                ]
                            },
                            {
                                id: uuidv4(),
                
                                questionNumber: 2,
                                questionType: 'text',
                                question: ' How much working capital are you looking for?',
                                answer: null
                            },
                            {
                                id: uuidv4(),
                
                                questionNumber: 3,
                                other: false,
                                questionType: 'options',
                                question: ' What are your supplier`s normal trading terms?  ',
                                options: [{
                                    id: uuidv4(),
                                    label: null,
                                    options: [{ value: 'Cash on delivery', selected: null, id: uuidv4() }, { value: '30 Days', selected: null, id: uuidv4() }, { value: '60 Days', selected: null, id: uuidv4() }, { value: '90 days', selected: null, id: uuidv4() }, { value: 'Over 90 days', selected: null, id: uuidv4() }, { value: 'Other', selected: null, id: uuidv4() }]
                                }
                                ]
                            },
                            {
                                id: uuidv4(),
                
                                questionNumber: 4,
                                other: false,
                                questionType: 'options',
                                question: 'Do your suppliers have terms or trade contracts?  ',
                                options: [{
                                    id: uuidv4(),
                                    label: null,
                                    options: [{ value: 'Yes', selected: false, id: uuidv4() }, { value: 'No', selected: false, id: uuidv4() }, { value: 'Unknown', selected: false, id: uuidv4() }]
                                }
                                ]
                            },
                            {
                                id: uuidv4(),
                
                                questionNumber: 5,
                                other: false,
                                questionType: 'multiple',
                                question: 'Do you have arrangements with any of your suppliers to allow for any of the following? ',
                                options: [{
                                    id: uuidv4(),
                
                                    label: null,
                                    options: [{ value: 'Progress payments', selected: null, id: uuidv4() }, { value: 'Retentions', selected: null, id: uuidv4() }, { value: 'Common debtors/creditors', selected: null, id: uuidv4() }, { value: 'Rebates', selected: null, id: uuidv4() }, { value: 'Rights to set-off or discount ', selected: null, id: uuidv4() }, { value: 'Sale or return', seleted: null, id: uuidv4() }, { value: 'Invoices generated prior to delivery of goods ', selected: null, id: uuidv4() }, { value: 'Unknown/None', selected: null, id: uuidv4() }]
                                }]
                            },
                            {
                                id: uuidv4(),
                                answer: null,
                
                                questionNumber: 6,
                                questionType: 'text',
                                question: ' How much do you currently owe to your suppliers?  ',
                            },
                            {
                                id: uuidv4(),
                                answer: null,
                
                                questionNumber: 7,
                                questionType: 'text',
                                question: '  How much is past 90 days?',
                            },
                            {
                                id: uuidv4(),
                                answer: null,
                
                                questionNumber: 8,
                                questionType: 'select',
                                question: ' What is the largest percentage of your payable ledger owed to one supplier?',
                                options: [
                                    { label: '<5%', value: '<5%', selected: false, id: uuidv4() },
                                    { label: '5% - 10%', value: '5% - 10%', selected: false, id: uuidv4() },
                                    { label: '11% - 20%', value: '11% - 20%', selected: false, id: uuidv4() },
                                    { label: '21% - 30%', value: '21% - 30%', selected: false, id: uuidv4() },
                                    { label: '31% - 40%', value: '31% - 40%', selected: false, id: uuidv4() },
                                    { label: '41% - 50%', value: '41% - 50%', selected: false, id: uuidv4() },
                                    { label: '51% - 75%', value: '51% - 75%', selected: false, id: uuidv4() },
                                    { label: '76% - 100%', value: '76% - 100%', selected: false, id: uuidv4() },
                                    { label: 'Currently Unknown', value: 'Currently Unknown', selected: false, id: uuidv4() },
                
                                ]
                            },
                
                        ],
                        finalQuestions: [
                            {
                                id: uuidv4(),
                                answer: null,
                
                                questionNumber: 1,
                                questionType: 'text',
                                question: ' What is your forecasted annual sales turnover? ',
                            },
                            {
                                id: uuidv4(),
                                answer: null,
                
                                questionNumber: 2,
                                questionType: 'text',
                                question: ' What was last year`s turnover? ',
                            },
                            {
                                id: uuidv4(),
                                answer: null,
                
                                questionNumber: 3,
                                questionType: 'text',
                                question: ' What was last year`s gross profit?',
                            },
                            {
                                id: uuidv4(),
                                answer: null,
                
                                questionNumber: 4,
                                questionType: 'text',
                                question: 'What was last year`s net profit? ',
                            },
                            {
                                id: uuidv4(),
                
                                questionNumber: 5,
                                other: false,
                                questionType: 'options',
                                question: 'What is the position of your taxes? ',
                                options: [{
                                    id: uuidv4(),
                
                                    label: null,
                                    options: [{ value: 'Up to date', selected: null, id: uuidv4() }, { value: 'Not up to date', selected: false, id: uuidv4() }, { value: 'Playment plan in place ', selected: null, id: uuidv4() }, { value: 'Unknown', selected: null, id: uuidv4() }]
                                }
                                ]
                            },
                            {
                                id: uuidv4(),
                
                                questionNumber: 6,
                                other: false,
                                questionType: 'multiple',
                                question: 'What are your current financial arrangements?',
                                options: [{
                                    id: uuidv4(),
                
                                    label: null,
                                    options: [{ value: 'No finance ', selected: null, id: uuidv4() }, { value: ' Bank overdraft', selected: null, id: uuidv4() }, { value: ' Business loan(s)  ', selected: null, id: uuidv4() }, { value: 'Debtor finance', selected: null, id: uuidv4() }, { value: 'Directors loan(s)', selected: null, id: uuidv4() }, { value: ' Trade finance', selected: null, id: uuidv4() }, { value: ' Other', selected: null, id: uuidv4() }]
                                }
                                ]
                            }
                        ]
                    },
                    selectedtype: {
                        workingCapital: null,
                        cashFlowFinance: null
                    },
                    questions2: [
                
                        {
                            id: uuidv4(),
                            answer: null,
                            questionNumber: 1,
                            questionType: 'text',
                            question: ' What size facility are you looking for?',
                        },
                        {
                            id: uuidv4(),
                
                            questionNumber: 2,
                            other: false,
                            questionType: 'options',
                            question: ' What are your customers normal trading terms?  ',
                            options: [
                                {
                                    id: uuidv4(),
                                    label: null,
                                    options: [
                                        { value: '30 Days', id: uuidv4(), selected: false },
                                        { value: '60 Days', id: uuidv4(), selected: false },
                                        { value: '90 days', selected: false, id: uuidv4() },
                                        { value: 'Other', id: uuidv4(), selected: false }
                                    ]
                                }
                            ]
                        },
                
                        {
                            id: uuidv4(),
                
                            questionNumber: 3,
                            questionType: 'options',
                            other: false,
                            question: 'Do your customers have terms or trade contracts?  ',
                            options: [{
                                label: null,
                                id: uuidv4(),
                                options: [{ value: "Yes", selected: false, id: uuidv4(), }, { value: "No", selected: false, id: uuidv4(), }, { value: "Unknown", selected: false, id: uuidv4(), }]
                
                            }
                            ]
                        },
                        {
                            id: uuidv4(),
                
                            questionNumber: 4,
                            questionType: 'multiple',
                            other: false,
                
                            question: 'Do you have arrangements with any of your customers to allow for any of the following? ',
                            options: [{
                                id: uuidv4(),
                                label: null,
                                options: [{ value: 'Progress payments', selected: false, id: uuidv4(), }, { value: 'Retentions', selected: false, id: uuidv4(), }, { value: 'Common debtors/creditors', selected: false, id: uuidv4(), }, { value: 'Rebates', selected: false, id: uuidv4(), }, { value: 'Rights to set-off or discount ', selected: false, id: uuidv4(), }, { value: 'Sale or return', selected: false, id: uuidv4(), }, { value: 'Invoices generated prior to delivery of goods ', selected: false, id: uuidv4(), }, { value: 'Unknown/None', selected: false, id: uuidv4(), }]
                            }
                            ]
                        },
                        {
                            id: uuidv4(),
                            answer: null,
                
                            questionNumber: 5,
                            questionType: 'text',
                            question: ' How much are you currently owed from your customers?  ',
                        },
                        {
                            id: uuidv4(),
                            answer: null,
                
                            questionNumber: 6,
                            questionType: 'text',
                            question: '  How much is past 90 days?',
                        },
                        {
                            id: uuidv4(),
                
                            questionNumber: 7,
                            questionType: 'select',
                            answer: null,
                            question: '  What is the largest percentage of your receivables ledger owed from one customer?',
                            options: [
                
                                { label: '<5%', value: '<5%', selected: false, id: uuidv4() },
                                { label: '5% - 10%', value: '5% - 10%', selected: false, id: uuidv4() },
                                { label: '11% - 20%', value: '11% - 20%', selected: false, id: uuidv4() },
                                { label: '21% - 30%', value: '21% - 30%', selected: false, id: uuidv4() },
                                { label: '31% - 40%', value: '31% - 40%', selected: false, id: uuidv4() },
                                { label: '41% - 50%', value: '41% - 50%', selected: false, id: uuidv4() },
                                { label: '51% - 75%', value: '51% - 75%', selected: false, id: uuidv4() },
                                { label: '76% - 100%', value: '76% - 100%', selected: false, id: uuidv4() },
                                { label: 'Currently Unknown', value: 'Currently Unknown', selected: false, id: uuidv4() },
                
                
                            ]
                        },
                
                        {
                            id: uuidv4(),
                
                            questionNumber: 8,
                            other: false,
                            questionType: 'options',
                            question: ' What are your supplier`s normal trading terms?  ',
                            options: [{
                                id: uuidv4(),
                                label: null,
                                options: [{ value: 'Cash on delivery', selected: null, id: uuidv4() }, { value: '30 Days', selected: null, id: uuidv4() }, { value: '60 Days', selected: null, id: uuidv4() }, { value: '90 days', selected: null, id: uuidv4() }, { value: 'Over 90 days', selected: null, id: uuidv4() }, { value: 'Other', selected: null, id: uuidv4() }]
                            }
                            ]
                        },
                        {
                            id: uuidv4(),
                
                            questionNumber: 9,
                            other: false,
                            questionType: 'options',
                            question: 'Do your suppliers have terms or trade contracts?  ',
                            options: [{
                                id: uuidv4(),
                                label: null,
                                options: [{ value: 'Yes', selected: false, id: uuidv4() }, { value: 'No', selected: false, id: uuidv4() }, { value: 'Unknown', selected: false, id: uuidv4() }]
                            }
                            ]
                        },
                        {
                            id: uuidv4(),
                
                            questionNumber: 10,
                            other: false,
                            questionType: 'multiple',
                            question: 'Do you have arrangements with any of your suppliers to allow for any of the following? ',
                            options: [{
                                id: uuidv4(),
                
                                label: null,
                                options: [{ value: 'Progress payments', selected: null, id: uuidv4() }, { value: 'Retentions', selected: null, id: uuidv4() }, { value: 'Common debtors/creditors', selected: null, id: uuidv4() }, { value: 'Rebates', selected: null, id: uuidv4() }, { value: 'Rights to set-off or discount ', selected: null, id: uuidv4() }, { value: 'Sale or return', seleted: null, id: uuidv4() }, { value: 'Invoices generated prior to delivery of goods ', selected: null, id: uuidv4() }, { value: 'Unknown/None', selected: null, id: uuidv4() }]
                            }]
                        },
                        {
                            id: uuidv4(),
                            answer: null,
                
                            questionNumber: 11,
                            questionType: 'text',
                            question: ' How much are you currently owed from your suppliers?  ',
                        },
                        {
                            id: uuidv4(),
                            answer: null,
                
                            questionNumber: 12,
                            questionType: 'text',
                            question: '  How much is past 90 days?',
                        },
                        {
                            id: uuidv4(),
                
                            questionNumber: 13,
                            questionType: 'select',
                            question: ' What is the largest percentage of your payable ledger owed to one supplier?',
                            options: [
                                { label: '<5%', value: '<5%', selected: false, id: uuidv4() },
                                { label: '5% - 10%', value: '5% - 10%', selected: false, id: uuidv4() },
                                { label: '11% - 20%', value: '11% - 20%', selected: false, id: uuidv4() },
                                { label: '21% - 30%', value: '21% - 30%', selected: false, id: uuidv4() },
                                { label: '31% - 40%', value: '31% - 40%', selected: false, id: uuidv4() },
                                { label: '41% - 50%', value: '41% - 50%', selected: false, id: uuidv4() },
                                { label: '51% - 75%', value: '51% - 75%', selected: false, id: uuidv4() },
                                { label: '76% - 100%', value: '76% - 100%', selected: false, id: uuidv4() },
                                { label: 'Currently Unknown', value: 'Currently Unknown', selected: false, id: uuidv4() },
                
                            ]
                        },
                        {
                            id: uuidv4(),
                            answer: null,
                
                            questionNumber: 14,
                            questionType: 'text',
                            question: ' What is your forecasted annual sales turnover? ',
                        },
                        {
                            id: uuidv4(),
                            answer: null,
                
                            questionNumber: 15,
                            questionType: 'text',
                            question: ' hat was last year`s turnover? ',
                        },
                        {
                            id: uuidv4(),
                            answer: null,
                
                            questionNumber: 16,
                            questionType: 'text',
                            question: ' What was last year`s gross profit?',
                        },
                        {
                            id: uuidv4(),
                            answer: null,
                
                            questionNumber: 17,
                            questionType: 'text',
                            question: 'What was last year`s net profit? ',
                        },
                        {
                            id: uuidv4(),
                
                            questionNumber: 18,
                            other: false,
                            questionType: 'options',
                            question: 'What is the position of your taxes? ',
                            options: [{
                                id: uuidv4(),
                
                                label: null,
                                options: [{ value: 'Up to date', selected: null, id: uuidv4() }, { value: 'Not up to date', selected: false, id: uuidv4() }, { value: 'Playment plan in place ', selected: null, id: uuidv4() }, { value: 'Unknown', selected: null, id: uuidv4() }]
                            }
                            ]
                        },
                        {
                            id: uuidv4(),
                
                            questionNumber: 19,
                            other: false,
                            questionType: 'options',
                            question: 'What are your current financial arrangements?',
                            options: [{
                                id: uuidv4(),
                
                                label: null,
                                options: [{ value: 'No finance ', selected: null, id: uuidv4() }, { value: ' Bank overdraft', selected: null, id: uuidv4() }, { value: ' Business loan(s)  ', selected: null, id: uuidv4() }, { value: 'Debtor finance', selected: null, id: uuidv4() }, { value: 'Directors loan(s)', selected: null, id: uuidv4() }, { value: ' Trade finance', selected: null, id: uuidv4() }, { value: ' Other', selected: null, id: uuidv4() }]
                            }
                            ]
                        },
                        {
                            id: uuidv4(),
                            answer: [{
                                data: null
                            }
                            ],
                
                            questionNumber: 20,
                            questionType: 'text',
                            question: '  What are the full name and email address of each director',
                        },
                        {
                            id: uuidv4(),
                            answer: null,
                
                            questionNumber: 21,
                            questionType: 'address',
                            question: '  Upload your financial information',
                        },
                    ]
                };
            default:
                return state;
    }
};

export default financeReducer;
