import React from "react";
import { confirmAlert } from "react-confirm-alert";

const ValidateSheetFields = (sheetRow, fields) => {

    let missingFields = [];
    if(sheetRow && sheetRow[0] !== undefined){
        let sheetFirstRow = sheetRow[0];
        if(sheetFirstRow){
            fields.map(field => {
                if(!sheetFirstRow.hasOwnProperty(field))
                    missingFields.push(field)
            })
        }
    }

    // Display popup modal if mismatch found
    if(missingFields.length > 0){
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                      <h4>Invalid Sheet!</h4>
                      <p>Please make sure you have uploaded the correct template before proceeding!</p>
                      <button
                        className="btn btn-primary ml-2"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Close
                      </button>
                    </div>
                  );
            },
            title: "",
            message: "",
        });
    }
    
    return missingFields.length > 0 ? false : true;
};

export default ValidateSheetFields;