import React, { useState,useEffect } from "react";
import { resetBusinessStore } from '../../../../redux/financeBusiness/finance.actions'
import { useDispatch } from 'react-redux'
import Page4 from "./page4";
import Page15 from "./page15";

export default function FinanceBusinessLayout() {
    const dispatch = useDispatch()
    useEffect(() => {
        return () => {
           dispatch(resetBusinessStore())
        }
    }, [])

    const goNext = (page, selectedType = null) => {
        setCurrentPage(page)
        if (page === 'finals') {
            setCurrentSection(page)
        }
    };

    const goPrevious = (page) => {
        setCurrentPage(page)
    };

    const [currentSection, setCurrentSection] = useState('cashFlowQestions')
    const [currentPage, setCurrentPage] = useState('cashFlowQestions')
    const RenderQuestions = ({ page }) => {

        switch (true) {
            case (page === 'cashFlowQestions' || page === 'workingCapitalQuestions' || page === 'finals'):
                return <Page4 goNext={goNext} goPrevious={goPrevious} questionSection={currentSection} />;
            case (page === 'finished'):
                return <Page15 />;
            default:
                return 'comming soon !!!!!!!';
        }
    }
    return (
        <>
            <div id="animation_box" className="animated fadeInLeft">
                <RenderQuestions
                    page={currentPage}
                />
            </div>
        </>
    )
}