import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import authService from "../../../../../services/authService"
import * as moment from "moment";
import { CountriesList, AllCorporateBpList, CustomersList, SuppliersList } from '../../../../slplusTools';
import { ReportInputFieldContainer, FormRow, FormColumnLg } from "../../../../common/containers/formContainer";
import transactionService from "../../../services/transaction_service";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router";
import Utility from "../../../utils/DateParser";
import useApi from "../../../../../hooks/useApi";
import 'react-table/react-table.css';
import { confirmAlert } from "react-confirm-alert";
import { GridActionButton, GeneralButton, DateTimePicker, CurrencyLabel, Picker } from "../../../../common/inputs";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
const colorRed = { color: "red" };

export default function TfBatchApprovalPayment(props) {

    const tableRef = useRef();
    const { ParseDate } = Utility();

    // API calls for fetching dd invoices and performing actions

    const DDBatchApi = useApi(transactionService.directDebitBatchList);
    const listActionsApi = useApi(transactionService.directDebitListAction);
    const DownloadSettlement = useApi(transactionService.downloadPaymentSettlement);



    // State variables
    const [ddInvoiceList, setDdInvoiceList] = useState(null);
    const [ddIndividualInvoiceList, setDdIndividualInvoiceList] = useState(null);
    const { constants, category, products } = authService.getCurrentUser();
    const [country, setCountry] = useState(null);
    const [owner, setOwner] = useState(null);
    const [customer, setCustomer] = useState(props.entityGuid ? props.entityGuid : null);
    const [supplier, setSupplier] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [isModel, setIsModel] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [showButtons, setShowButtons] = useState(false);
    const [ddModal, setDdModal] = useState(false);
    const [paymentSettlementConfirmModal, setPaymentSettlementConfirmModal] = useState(false);
    const [paymentSummaryModal, setPaymentSummaryModal] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState(null);
    const [receivedDate, setReceivedDate] = useState(new Date());
    const [selectedDateTo, setSelectedDateTo] = useState(null);
    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [batchId, setBatchId] = useState(null);
    const [totalAllocatedAmount, setTotalAllocatedAmount] = useState(0);
    const [totalAllocatedCount, setTotalAllocatedCount] = useState(0);

    // Fetching dd batch list based on filters

    const getDDBatchList = async (owner, customer, supplier, selectedDateFrom, selectedDateTo) => {
        const response = await DDBatchApi.request({
            selectedDateFrom: selectedDateFrom,
            selectedDateTo: selectedDateTo,
            product_type: constants.PRODUCTS.TRADEFINANCE,
            country: country,
            owner: owner,
            customer: props.entityGuid ? props.entityGuid : customer !== undefined ? customer : null,
            supplier: supplier !== undefined ? supplier : null,
            paymentStatus: props.paymentStatus,
        }); // Sending Async request to server
        if (response) {
            setDdInvoiceList(response.data.rows);
        }
    }

    /* ------------------------------------------------------------------------------------- */


    // Fetch dd batch list on component load or when filter variables change

    useEffect(() => {
        getDDBatchList(owner, customer, supplier, selectedDateFrom, selectedDateTo, batchId, props.paymentStatus)
    }, [owner, customer, supplier, selectedDateFrom, selectedDateTo, batchId, props.paymentStatus])

    useEffect(() => {
        if (paymentResponse)
            setPaymentSettlementConfirmModal(true);
    }, [paymentResponse])

    // Function to handle checkbox selection for individual invoices

    const handleCheckboxChange = (id) => {
        if (ddInvoiceList) {
            const updatedInvoiceList = [...ddInvoiceList];
            updatedInvoiceList[id].checked = !updatedInvoiceList[id].checked;
            setDdInvoiceList(updatedInvoiceList);

            const updatedSelectedInvoices = updatedInvoiceList
                .filter((invoice) => invoice.checked)
                .map((invoice) => invoice.amount);

            // Calculate the total of total_requested_amount
            const totalAmount = updatedSelectedInvoices.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue);
            }, 0);

            setSelectedInvoices(updatedSelectedInvoices);
            setShowButtons(updatedSelectedInvoices.length > 0);
            setTotalAllocatedCount(updatedSelectedInvoices.length); // Set the total count
            setTotalAllocatedAmount(totalAmount); // Set the total amount
        }
    };

    // Function to handle select/deselect all invoices

    const handleSelectAll = () => {
        const allChecked = !selectAll;

        // Filter out rows with requested_status !== 'pending'
        const pendingRows = ddInvoiceList.filter((invoice) => invoice.requested_status === 'pending');

        const updatedInvoiceList = ddInvoiceList.map((invoice) => ({
            ...invoice,
            checked: pendingRows.includes(invoice) ? allChecked : invoice.checked,
        }));

        setDdInvoiceList(updatedInvoiceList);

        const updatedSelectedInvoices = allChecked
            ? pendingRows.map((invoice) => invoice.total_request_amount)
            : [];

        const totalAmount = updatedSelectedInvoices.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue);
        }, 0);

        const totalCount = allChecked ? updatedSelectedInvoices.length : 0;

        setSelectedInvoices(updatedSelectedInvoices);
        setShowButtons(updatedSelectedInvoices.length > 0);
        setTotalAllocatedAmount(totalAmount);
        setTotalAllocatedCount(updatedSelectedInvoices.length);
        setSelectAll(allChecked);
    };

    //function for export data
    const handleDownloadSummary = async () => {

        const response = await DownloadSettlement.request(
            {
                selectedAttributes: paymentResponse.closed_status,
                docType: "xlsx",
            });
        window.open(response.data, "_blank", "noreferrer");
    };
    const handleRejectedTransfer = async (transferRecord) => {
        const msg = 'This action will transfer the rejected payment to history!';
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>{msg}</h4>
                        <p>Do you want to continue ?</p>
                        <button
                            className="btn btn-white ml-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>

                        <button
                            className="btn btn-primary ml-2"
                            onClick={async () => {
                                onClose();
                                const { data } = await listActionsApi.request(
                                    {
                                        selectedDate: ParseDate(selectedDate),
                                        status: 'transfer-rejected',
                                        product_type: constants.PRODUCTS.TRADEFINANCE,
                                        selectedAttributes: transferRecord.batch_id,
                                        bulkUpdate: true,
                                        owner: owner,
                                        customer: customer !== undefined ? customer : null,
                                        isTransfer: true
                                    });

                                if (data.success) {
                                    toast.success(data.message);
                                    setShowButtons(false);
                                    getDDBatchList();
                                }
                                else
                                    toast.error(data.message);
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "",
            message: "",
        });
    }

    const columns = [

        {
            Header: (
                <div className="custom-control d-flex custom-checkbox small">
                    <input
                        id="customCheck"
                        className="custom-control-input"
                        type="checkbox"
                        checked={selectAll}
                        onChange={() => handleSelectAll()}
                    ></input>
                    <label
                        className="custom-control-label"
                        htmlFor="customCheck"
                    ></label>
                </div>
            ),
            id: 'bulk-checkbox',
            accessor: '',
            Cell: row => {

                return (
                    <div className="custom-control d-flex custom-checkbox small">
                        <input
                            id={`customCheck-${row.index}`}
                            className="custom-control-input"
                            type="checkbox"
                            checked={row.original.checked}
                            onChange={() => handleCheckboxChange(row.index)}
                            disabled={row.original.requested_status !== 'pending'}
                        ></input>
                        <label
                            className="custom-control-label"
                            htmlFor={`customCheck-${row.index}`}
                        ></label>
                    </div>
                );

            },
            width: 50,
            sortable: false,
            resizable: false,
        },
        {
            Header: "Customer",
            accessor: "organization",
            headerStyle: { textAlign: "left" },
        },
        {
            Header: "Requested Date",
            accessor: "requested_date",
            headerStyle: { textAlign: "left" },
            Cell: row => moment(row.original.requested_date, "YYYY-MM-DD").format("DD/MM/YYYY")
        },
        {
            Header: "Requested Count",
            accessor: "request_count",
            headerStyle: { textAlign: "left" },
            Cell: row => {
                return (
                    <>
                        <Link className="cursor-link"
                            onClick={async () => {
                                setIsModel(true);
                                const response = await DDBatchApi.request({
                                    product_type: constants.PRODUCTS.TRADEFINANCE,
                                    batch_id: row.original.batch_id,
                                    owner: owner,
                                    customer: customer !== undefined ? customer : null,
                                }); // Sending Async request to server
                                if (response) {
                                    setDdIndividualInvoiceList(response.data.rows);
                                }
                            }}
                        >
                            {row.original.request_count}
                        </Link>
                    </>
                )
            }
        },
        {
            Header: "Finance Cost",
            accessor: "total_request_amount",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <CurrencyLabel
                    value={
                        row.original.total_request_amount !== null ? row.original.total_request_amount : 0
                    }
                ></CurrencyLabel>
            ),
            Footer: (row) => {
                return (
                    <strong>
                        <CurrencyLabel decimal={2} value={_.sumBy(row.data, item => Number(item.total_request_amount))}></CurrencyLabel>
                    </strong>
                );
            },

        },
        {
            Header: "Non Utilisation Fee",
            accessor: "non_utilisation_fee",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <CurrencyLabel
                    value={
                        row.original.non_utilisation_fee !== null ? row.original.non_utilisation_fee : 0
                    }
                ></CurrencyLabel>
            ),
            Footer: (row) => {
                return (
                    <strong>
                        <CurrencyLabel decimal={2} value={_.sumBy(row.data, item => Number(item.non_utilisation_fee))}></CurrencyLabel>
                    </strong>
                );
            },

        },

        {
            Header: "Total DD",
            accessor: "amount",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <CurrencyLabel
                    value={
                        row.original.amount !== null ? row.original.amount : 0
                    }
                ></CurrencyLabel>
            ),
            Footer: (row) => {
                return (
                    <strong>
                        <CurrencyLabel decimal={2} value={_.sumBy(row.data, item => Number(item.amount))}></CurrencyLabel>
                    </strong>
                );
            },

        },
        {
            Header: "Payment Status",
            accessor: "requested_status",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <div>
                    <div className={row.value === 'pending' ? 'text-success' : 'text-danger badge badge-light p-2'}>
                    {row.value === 'pending' ? 'Success' : 'Rejected'}
                    </div>
                    {row.value === 'rejected' && (
                    <Link
                        className="fa fa-exchange text-success"
                        title="Transfer to history"
                        onClick={() => handleRejectedTransfer(row.original)}
                    ></Link>
                    )}
                </div>
            ),
        },

    ];


    return (
        <>
            <Helmet>
                <title>Tf Direct Debits | {global.config.title}</title>
            </Helmet>
            <div className="overflow-visible">
                <div className="row p-3">

                <ReportInputFieldContainer label={category == constants.CATEGORY.INTL_OFFICE ? "Country" : ""} show={category == constants.CATEGORY.INTL_OFFICE ? true : false}>
                        <CountriesList type="horizontal" selectedCountry={country} onSelectItem={({ id }) => { setCountry(id); setOwner(null); setCustomer(null); setSupplier(null); }} />
                    </ReportInputFieldContainer>

                    <ReportInputFieldContainer label={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? "Business Partner" : ""} show={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? true : false} >
                        <AllCorporateBpList selectedBp={owner} type="horizontal" country={country} onSelectItem={({ value }) => { setOwner(value); setCustomer(null); setSupplier(null); }} />
                    </ReportInputFieldContainer>

                    <ReportInputFieldContainer label="Customer" >
                        <CustomersList selectedCustomer={customer} bp={owner}
                            onSelectItem={option => { if (option.value == null) { setSupplier(null) } else { setCustomer(option.value) } }}
                            category={constants.CATEGORY.TFCUSTOMER}
                        />
                    </ReportInputFieldContainer>

                    <div className="col-sm-2">
                        <div className="form-group">
                            <label>Date From</label>
                            <DateTimePicker
                                name="requested_from_date"
                                label="Requested Date From"
                                selectedDate={selectedDateFrom}
                                onChange={({ target }) => setSelectedDateFrom(target.value)}
                                enableWeekDays={true}
                            />
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label>Date to</label>
                            <DateTimePicker
                                name="requested_to_date"
                                label="Requested Date To"
                                selectedDate={selectedDateTo}
                                onChange={({ target }) => setSelectedDateTo(target.value)}
                                enableWeekDays={true}
                            />
                        </div>
                    </div>

                </div>
                {/* </div>
            <div className="card"> */}
                <div className="card-body">


                    {ddInvoiceList && ddInvoiceList.length > 0 ? (
                        <div className="card-body">
                            <ReactTable
                                data={ddInvoiceList}
                                columns={columns}
                                defaultPageSize={10}
                                minRows={10}
                                className="-striped -highlight"
                                showPagination={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                            />
                        </div>
                    ) : (
                        <div className="card-body">
                            <div>
                                <p colSpan="15" className="text-center">
                                    No DD Requested
                                </p>
                            </div>
                        </div>
                    )}
                    {showButtons && (
                        <div className="row mt-2">
                            <div className="col-6"></div>
                            <div className="col-6">
                                <div className="  b-dashed-2 p-2 bg-light ml-3">
                                    <div className="row">
                                        <div className="col-4">
                                            <p className="mb-1">Total Payment Selected</p>
                                            <h5 className="font-weight-bold mb-1">
                                                <CurrencyLabel
                                                    label="make pay"
                                                    value={totalAllocatedAmount}
                                                ></CurrencyLabel>
                                            </h5>
                                        </div>
                                        <div className="col-4">
                                            <p className="mb-1">Total Clients</p>
                                            <h5 className="font-weight-bold mb-1">{totalAllocatedCount}</h5>
                                        </div>
                                        <div className="col-4">
                                            <GridActionButton
                                                label="Approve Payment"
                                                handleClick={(e) => setDdModal(true)}
                                                class="btn btn-primary mr-2"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </div>

            {/*------------  modal for showing dd invoices ---------- */}
            <Modal
                show={isModel}
                onHide={(e) => setIsModel(false)}
                size="lg" >
                <Modal.Header closeButton>
                    <h6> {`Direct Debit `} </h6>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {DDBatchApi.loading && <LoadingSpinner />}
                        {(ddIndividualInvoiceList && ddIndividualInvoiceList.length > 0) ? (
                            <div className="card-body">
                                <table className="table table-hover no-margins">
                                    <thead>
                                        <tr>
                                            <th>Customer</th>
                                            <th>Invoice Number</th>
                                            <th>Invoice Amount</th>
                                            <th>DD Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ddIndividualInvoiceList.map((item, index) => (
                                            <tr>
                                                <td>{item.organization}</td>
                                                <td>{item.inv_number}</td>
                                                <td><CurrencyLabel value={item.inv_amount}></CurrencyLabel></td>
                                                <td><CurrencyLabel value={item.dd_amount}></CurrencyLabel></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td> Total DD</td>
                                            <td><strong>
                                                <CurrencyLabel
                                                    value={_.sumBy(ddIndividualInvoiceList, (item) =>
                                                        Number(item.dd_amount)
                                                    )}
                                                ></CurrencyLabel>
                                            </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ) :
                            <></>
                        }
                    </>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            {/*------------  modal for confirm received date---------- */}
            <Modal
                show={ddModal}
                onHide={(e) => setDdModal(false)}
                centered
                backdrop='static'
            >
                <Modal.Header closeButton>
                    <h6> {`Select Date DD Collected`} </h6>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <DateTimePicker
                            name="date"
                            placeholder="Select Date"
                            label="Date"
                            // minDate={new Date(Date.parse(selectedDate))}
                            selectedDate={receivedDate}
                            onChange={({ target }) => setReceivedDate(target.value)}
                        />
                    </div>
                    <FormRow style={colorRed} className="ml-1 mt-1">
                        Selected date will be saved as received date for all selected invoices
                    </FormRow>
                </Modal.Body>
                <Modal.Footer>
                    <FormRow>
                        <GeneralButton
                            name={`Save`}
                            className={`btn btn-primary`}
                            onClick={async () => {
                                setDdModal(false);
                                const { data } = await listActionsApi.request(
                                    {
                                        selectedDate: ParseDate(receivedDate),
                                        status: 'received',
                                        product_type: constants.PRODUCTS.TRADEFINANCE,
                                        selectedAttributes: ddInvoiceList.filter(item => item.checked === true).map(item => item.batch_id),
                                        reference_guid: ddInvoiceList.filter(item => item.checked === true).map(item => item.reference_guid),
                                        bulkUpdate: true,
                                        owner: owner,
                                        customer: customer !== undefined ? customer : null,
                                    });

                                if (data.success) {
                                    setPaymentResponse(data);
                                    setShowButtons(false);
                                    getDDBatchList();
                                }
                                else
                                    toast.error(data.message);
                            }}>
                        </GeneralButton>
                    </FormRow>
                </Modal.Footer>
            </Modal>
            {/*------------ ------------------------------------- ---------- */}

            {/*------------ Payment settlement confirmation modal ---------- */}

            <Modal
                show={paymentSettlementConfirmModal}
                onHide={() => { setPaymentSettlementConfirmModal(false) }}
                centered
                contentClassName="bg-secondary text-white"
                backdropClassName="bg-white"
            >
                <Modal.Header closeButton />

                <Modal.Body>
                    <div className="text-center mb-4">
                        <div className="media-icon media-icon-lg bg-primary-transparent text-primary mx-auto mb-4">
                            <i className="fa fa-check-square"></i>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <h6 className="text-white mb-4">
                                    A payment of <CurrencyLabel value={paymentResponse?.total_payment_settled || 0} /> has been allocated to the selected invoices.
                                </h6>
                                {paymentResponse?.closed_status && paymentResponse.closed_status.find(item => item.error === true) && (
                                    <p className="text-white-50 mb-4 text-sm">
                                        However, certain invoices could not be closed due to some issues. Please click the button below to view more details.
                                    </p>
                                )}
                                <button className="btn btn-white ml-2" onClick={() => { setPaymentSettlementConfirmModal(false); }}>
                                    Close
                                </button>
                                <button
                                    className="btn btn-primary ml-2"
                                    onClick={() => {
                                        setPaymentSettlementConfirmModal(false);
                                        setPaymentSummaryModal(true);
                                    }}
                                >
                                    View Summary
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/*--------------------------------------------------------------*/}
            {/*------------------------Payments Summary----------------------*/}
            <Modal
                show={paymentSummaryModal}
                onHide={() => setPaymentSummaryModal(false)}
                backdrop="static"
                centered
                size="xl"
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Payments Settlement  Summary</h4>
                </Modal.Header>
                <Modal.Body>
                    {paymentResponse !== null && typeof paymentResponse !== 'undefined' && paymentResponse.closed_status.find(item => item.error === false) && (
                        <FormRow>
                            <FormColumnLg>
                                <table className="table text-secondary">
                                    <thead>
                                        <tr>
                                            <th>Client</th>
                                            <th>Supplier</th>
                                            <th>Invoice Number</th>
                                            <th>InvoiceAmount</th>
                                            <th>Principal Paid</th>
                                            <th>Fees Paid</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentResponse.closed_status.filter(item => item.error === false).map((item, key) => (
                                            <tr key={key}>
                                                <td>{item.client}</td>
                                                <td>{item.supplier}</td>
                                                <td>{item.inv_number}</td>
                                                <td><CurrencyLabel value={item.inv_amount} /></td>
                                                <td><CurrencyLabel value={item.principal_paid} /></td>
                                                <td><CurrencyLabel value={item.finance_cost_paid} /></td>
                                                <td>{item.message}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan="4">Total Paid</td>
                                            <td><strong>
                                                <CurrencyLabel
                                                    value={_.sumBy(paymentResponse.closed_status, (item) =>
                                                        Number(item.principal_paid)
                                                    )}
                                                ></CurrencyLabel>
                                            </strong>
                                            </td>
                                            <td><strong>
                                                <CurrencyLabel
                                                    value={_.sumBy(paymentResponse.closed_status, (item) =>
                                                        Number(item.finance_cost_paid)
                                                    )}
                                                ></CurrencyLabel>
                                            </strong>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </FormColumnLg>
                        </FormRow>
                    )}

                    {paymentResponse !== null && typeof paymentResponse !== 'undefined' && paymentResponse.closed_status.find(item => item.error === true) && (
                        <>
                            <FormRow>
                                <FormColumnLg>
                                    <div className="text-center">
                                        <div className="alert alert-info">Following invoices could not be closed due to the following issues.</div>
                                    </div>
                                </FormColumnLg>
                            </FormRow>

                            <FormRow>
                                <FormColumnLg>
                                    <table className="table text-secondary">
                                        <thead>
                                            <tr>
                                                <th>Client</th>
                                                <th>Supplier</th>
                                                <th>Invoice Number</th>
                                                <th>Invoice Amount</th>
                                                <th>Principal Paid</th>
                                                <th>Fees Paid</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentResponse.closed_status.filter(item => item.error === true).map((item, key) => (
                                                <tr key={key}>
                                                    <td>{item.client}</td>
                                                    <td>{item.supplier}</td>
                                                    <td>{item.inv_number}</td>
                                                    <td><CurrencyLabel value={item.inv_amount} /></td>
                                                    <td><CurrencyLabel value={item.principal_paid} /></td>
                                                    <td><CurrencyLabel value={item.finance_cost_paid} /></td>
                                                    <td className="text-danger">{item.message}</td>
                                                </tr>

                                            ))}

                                        </tbody>
                                    </table>
                                </FormColumnLg>
                            </FormRow>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => handleDownloadSummary()}>Download Settlement Report</button>
                    <button className="btn btn-white" onClick={() => setPaymentSummaryModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>
            {/*--------------------------------------------------------------*/}
        </>


    )

}