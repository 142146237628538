import React, { useState, useEffect } from "react";
import startIcon4 from "../../.././../img/icons/FMB_icon4.svg"
import fourHours from "../../.././../img/icons/4hr.jpg";
import { browserHistory } from "react-router";
import {
  AddButton,
} from "../../../common/inputs";
import Modal from 'react-bootstrap/Modal';
import { FormRow } from "../../../common/containers/formContainer";
import { ModalCloseButton } from "../../../common/containers/button";
import { useSelector, useDispatch } from 'react-redux'
import CoreService from '../../../../services/coreService';
import useApi from "../../../../hooks/useApi";
import { toast } from "react-toastify";
import { resetBusinessStore } from '../../../../redux/financeBusiness/finance.actions'

export default function Finish({ goNext, goPrevious }) {

  const dispatch = useDispatch()
  const [fastrackModal, setFastrackModal] = useState(false);
  const [fastrackModalConfirm, setFastrackModalConfirm] = useState(false);
  const createApiFMB = useApi(CoreService.createFmbRequest);
  const setCloseAllModels = () => {
    setFastrackModal(false);
    setFastrackModalConfirm(false);
  }
  const goToDashboard = () => {

    browserHistory.push('/client/home');
  }

  const questions = useSelector(({ financeReducer }) => financeReducer.questions)

  useEffect(() => {
    // async function fetchMyAPI() {
    //   await CoreService.createFmbRequest(questions)
    // }
    async function fetchMyAPI() {
      const { data } = await createApiFMB.request(questions);
      if (data.success === false) {
        // setLoader(false);
        toast.error(data.message);
        dispatch(resetBusinessStore())
      } else {
        // setLoader(false);
        toast.success(data.message);
        //   fetchInvoices(selectedDebtor);
        // }
      }
    }
    fetchMyAPI()

  }, [])

  return (
    <>
      <div id="animation_box" className="animated fadeIn">

        <div className="mb-0 ">
          <div className="card-body">
            <div className="row vh-70 justify-content-center align-items-center py-5">
              <div className="col-lg-4 col-sm-6 col-md-6 text-center">
                <img src={startIcon4} alt="images" className="mb-4" width={210} />
                <h2 className="font-weight-bold">You’re Done!
                </h2>
                <p className="my-3">One of our finance experts will review
                  your answers and come back to you
                  within 24 hours with the best solution for you</p>
                <div className="text-muted">Don’t want to wait?
                </div><div className="btn btn-secondary mt-3" onClick={() => setFastrackModalConfirm(true)}> Click here to fast-track an answer
                </div>
              </div>
              <div className="col-10"><div className="d-flex justify-content-center align-items-center mt-3">
                <AddButton label="Close" onClick={() => goToDashboard()} style={`btn btn-light align-center ml-1`}></AddButton>

              </div>
              </div>
            </div>
          </div>
        </div>



        {/*  <GeneralButton pullRight="right" type="button" onClick={goPrevious} style={{ marginRight: "10px" }} className="btn btn-default btn-outline pull-right " name="Back"></GeneralButton>*/}
      </div>



      {/*POPUP 1 */}
      <Modal
        show={fastrackModalConfirm}
        onHide={
          () => setFastrackModalConfirm(false)
        }
      >
        <Modal.Header closeButton className="justify-content-center">
          <div className="d-block pt-3 text-center w-100 ml-4">
            <img width={"50px"} src="" className="mb-2" />
            <h4 className="modal-title">Fast track your finance</h4>
            {/* <small class="">Please enter the invoice details below.</small> */}
          </div>
        </Modal.Header>
        <form onSubmit="">
          <Modal.Body>
            <FormRow >
              <div className="modal-body">
                {/* {ajaxRequest && <LoadingSpinner />} */}
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <div className="d-block pt-2 text-left w-100 ml-0">
                        <img width={"50px"} src={fourHours} className="mb-2" width={150} /><br />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <div className="d-block pt-3 text-left w-100 ml-0">
                        <label>Need a fast answer? Click below to fast track your finance request and we’ll aim to call you back in less than 4 hours*</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FormRow>
          </Modal.Body>
          <Modal.Footer>
              <AddButton disabled="" onClick={() => setFastrackModal(true)} label="Fast Track Your Finance" style="btn btn-primary  "></AddButton>
          </Modal.Footer>
        </form >
      </Modal>



      {/*POPUP 2 */}
      <Modal
        show={fastrackModal}
        onHide={
          () => setFastrackModal(false)
        }
      >
        <Modal.Header closeButton className="justify-content-center">
          <div className="d-block pt-3 text-center w-100 ml-4">
            <img width={"50px"} src="" className="mb-2" />
            <h4 className="modal-title">Fast track your finance</h4>
            {/* <small class="">Please enter the invoice details below.</small> */}
          </div>
        </Modal.Header>
        <form onSubmit="">
          <Modal.Body>
            <FormRow >
              <div className="modal-body">
                {/* {ajaxRequest && <LoadingSpinner />} */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div className="d-block pt-3 text-center w-100 ml-0">
                        <img width={"50px"} src={fourHours} className="mb-2" width={150} /><br />
                        <label>We are now processing your fast-track request</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FormRow>
          </Modal.Body>
          <Modal.Footer>
            <ModalCloseButton onClick={() => setCloseAllModels()} label="Close" className="btn btn-primary"></ModalCloseButton>
          </Modal.Footer>
        </form >
      </Modal>
    </>
  )
}

