import React, { useState } from "react";
import startIcon4 from "../../.././../img/icons/FMB_icon4.svg";
import startIcon6 from "../../.././../img/icons/FMB_icon6.svg";
import startIcon7 from "../../.././../img/icons/FMB_icon7.svg"
import { RadioButton } from "../../../common/inputs/radioButton/radioButton";


import CoreService from "../../../../services/coreService";
import useApi from "../../../../hooks/useApi";


export default function Page16({ goNext, goPrevious }) {

    // const  {letGetStarted, setLetGetStarted} = useContext(FinanceBusinessContext);
    // const [cashFlowFinanceR, setCashFlowFinanceR] = useState(letGetStarted.cashFlowFinance ? letGetStarted.cashFlowFinance :null);
    // const [workingCapital, setWorkingCapital] = useState(letGetStarted.workingCapital ? letGetStarted.workingCapital :null);
    // //
    // const {cashFlowFinance,setCashFlowFinance} = useContext(FinanceBusinessContext);
    // const {improveCashFlow, setImproveCashFlow} =  useContext(FinanceBusinessContext);
    // const {getStart, setGetStart} = useContext(FinanceBusinessContext);


    // useEffect(() => { 
    //     letGetStartedClick();
    // },[cashFlowFinanceR,workingCapital]);

    // const letGetStartedClick = () =>{
    //     setLetGetStarted({
    //         cashFlowFinanceR,
    //         workingCapital
    //     })
    // };

    // const reqData = {
    //     getStart : getStart.clearSolution,
    //     improveCashFlow : improveCashFlow.improveCashFlow,
    //     acceleratePayment: cashFlowFinance.acceleratePayment,
    //     deRiskLatePayment: cashFlowFinance.deRiskLatePayment,
    //     ensureGetPaid: cashFlowFinance.ensureGetPaid,
    //     howComplicated: cashFlowFinance.howComplicated,
    //     managePaymentTerms: cashFlowFinance.managePaymentTerms,
    //     maxInCashFlow: cashFlowFinance.maxInCashFlow,
    //     offerBetterTerms: cashFlowFinance.offerBetterTerms,
    //     cashFlowFinanceR : letGetStarted.cashFlowFinanceR,
    //     workingCapital : letGetStarted.workingCapital
    //   };

    //   console.log(reqData);


    const sendFinanceBusMail = useApi(CoreService.sendFinanceBusinessMail);

    const goNextPage = async () => {
        // const { data } = await sendFinanceBusMail.request(reqData);
        // if (data.success === true) {
        //     toast.success("Success");

        // if (selectedtype.invoiceFinance) {
        //     goNext('multiCard');
        // }
        // debugger
        switch (true) {
            case (selectedtype.cashFlowFinance === "invoiceFinance" && selectedtype.workingCapital === null):
                goNext('multiCard', selectedtype);
                break;
            case (selectedtype.cashFlowFinance === null && (selectedtype.workingCapital === "tradeFinance" || selectedtype.workingCapital === "supplyChainFinance")):
                goNext('multiCard2', selectedtype);

                break;
            case (selectedtype.cashFlowFinance === "invoiceFinance" && (selectedtype.workingCapital === "tradeFinance" || selectedtype.workingCapital === "supplyChainFinance")):
                goNext('multiCard', selectedtype);
                break;
            default:
                break;
        }

        // }
        // else
        //     toast.error(data.message);
    }

    // const { values, setValues, handleChange } = useForm(goNextPage, schema);

    // console.log(getStart + improveCashFlow + cashFlowFinance + letGetStarted + setLetGetStarted)

    // let selectedTypeRedux = useSelector(({ financeReducer }) => financeReducer.selectedtype,isEqual)

    const [selectedtype, setSelectedType] = useState({
        cashFlowFinance: null,
        workingCapital: null
    })
    // const dispatch = useDispatch()
    const handleSelected = (e) => {
        // debugger
        const { name, value } = e.target;
        setSelectedType({ ...selectedtype, [name]: value })
        console.log({ selectedtype });
        //  debugger
        //   let reduxValue={...selectedTypeRedux, [name]: value}
        //  dispatch(changeSelectedType(reduxValue))
    }

    return (
        <>
            <div id="animation_box" className="animated fadeIn">

                <div className="mb-0 "><div className="card-body">
                    <div className="row min-vh-70 justify-content-center align-items-center py-5">
                        <div className="col-lg-3  col-md-12">
                            <div className="operate-image">
                                <img src={startIcon4} alt="image" width className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1 col-md-12">
                            <div className>
                                <span className="text-success mb-3 font-medium">Let’s get started… </span>
                                <h4 className="mt-3">Cash Flow Finance
                                </h4>
                                <div className="col-lg-12 ">
                                    <div>
                                        <div className="d-sm-flex w-100  py-3 align-items-center ">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <RadioButton
                                                    name="cashFlowFinance"
                                                    value="invoiceFinance"
                                                    checked={selectedtype.cashFlowFinance === 'invoiceFinance'}
                                                    onChange={(e) => handleSelected(e)}

                                                    id="cashFlowFinanceR"
                                                    label=""
                                                    className="custom-control custom-radio mt-2"
                                                ></RadioButton>
                                                {/* <input type="radio" id="cashFlowFinance" name="cashFlowFinance" className="custom-control-input" />
                                                    <label className="custom-control-label" htmlFor="cashFlowFinance" /> */}
                                            </div>
                                            <img src={startIcon6} width={68} className />
                                            <div className="w-100 ml-sm-3">
                                                <p className="mb-2">Invoice finance , factoring or debtor finance to improve inward cash flows
                                                    into the business
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="mt-3">Working Capital
                                </h4>
                                <div className="col-lg-12 ">
                                    <div>
                                        <div className="d-sm-flex w-100  py-3 align-items-center ">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <RadioButton
                                                    name="workingCapital"
                                                    value="tradeFinance"
                                                    checked={selectedtype.workingCapital === 'tradeFinance'}
                                                    onChange={(e) => handleSelected(e)}


                                                    id="workingCapitalTDF"
                                                    label=""
                                                    className="custom-control custom-radio mt-2"
                                                ></RadioButton>
                                                {/* <input type="radio" id="workingCapitalTDF" name="workingCapital" className="custom-control-input" />
                                                    <label className="custom-control-label" htmlFor="workingCapitalTDF" /> */}
                                            </div>
                                            <img src={startIcon7} width={68} className />
                                            <div className="w-100 ml-sm-3">
                                                <p className="mb-2">Trade finance solution to use with local and overseas suppliers, no letters
                                                    of credit, and pre-shipment and deposit payment capabilities
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-12 ">
                                        <div>
                                            <div className="d-sm-flex w-100  py-3 align-items-center ">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <RadioButton
                                                        name="workingCapital"
                                                        value="supplyChainFinance"
                                                        checked={selectedtype.workingCapital === 'supplyChainFinance'}
                                                        onChange={(e) => handleSelected(e)}

                                                        id="workingCapitalSCF"
                                                        label=""
                                                        className="custom-control custom-radio mt-2"
                                                    ></RadioButton>
                                                   
                                                </div>
                                                <img src={startIcon8} c width={68} className />
                                                <div className="w-100 ml-sm-3">
                                                    <p className="mb-2">Supply chain finance solution to use to extend your credit terms to
                                                    suppliers while benefiting from discounts and rebates when they request early payment
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                        <div className="col-10">
                            <div className="d-flex justify-content-end align-items-center mt-3">
                                <a href="#" onClick={() => goPrevious('sure')} className="btn btn-light mr-1">Back</a><button disabled={!selectedtype.workingCapital && !selectedtype.cashFlowFinance} onClick={() => goNextPage()} className={`btn btn-${!selectedtype.workingCapital && !selectedtype.cashFlowFinance ? 'dark' : 'primary'} ml-1 `}>Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>



                {/*  <GeneralButton pullRight="right" type="button" onClick={goPrevious} style={{ marginRight: "10px" }} className="btn btn-default btn-outline pull-right " name="Back"></GeneralButton>*/}
            </div>
        </>
    )
}

