import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FormGroup } from "../../../common/containers/formContainer";
import LoadingSpinner from '../../../common/loaders/loadingSpinner';
import {
    GeneralButton,
    Picker
} from "../../../common/inputs";

import { toast } from "react-toastify";
import EntityContext from "../../../../context/entityContext";
import useApi from '../../../../hooks/useApi';
import entityService from "../../../../services/entityService";
import authService from "../../../../services/authService";

export default function SwitchOrganisation({ onClose }) {

    const authApi = useApi(authService.loginByToken);
    const { entityDetails, populateClientDetails } = useContext(EntityContext);
    const [selectedOrganisation, setSelectedOrganisation] = useState({ label: entityDetails.organization, value: entityDetails.a_guid });

    const [orgList, setOrgList] = useState([]);
    const [hideBtn, setHideBtn] = useState(true);
    const [validationMsg, setValidationMsg] = useState(null);
    const { email } = authService.getCurrentUser();

    //------------Api Services--------------------------------------------
    const getOrganisationsApi = useApi(entityService.getOrganisationsListByContactEmail);

    const fetchOrganisationsName = async () => {
        const response = await getOrganisationsApi.request({ email });
        if(response)
            setOrgList(response.data);
    }

    const handlePickerChange = organisation => {
        setSelectedOrganisation(organisation);
        setHideBtn(false);
    }

    const handleOrganisationSwitching = async () => {
        setValidationMsg('Validating Credentials... Please Wait...');
        const res = await authApi.request(selectedOrganisation.value);
        if (res.success === true) {
            await populateClientDetails(selectedOrganisation);
            setTimeout(() => { setValidationMsg('Switching Organisation...'); }, 1000);
            setTimeout(() => { setValidationMsg('Fetching Organisation data...'); }, 3000);
            setTimeout(() => { onClose(); }, 5000);
        }
        else {
            toast.error('Invalid Password!');
            setValidationMsg(null);
        }
    }

    useEffect(() => {
        fetchOrganisationsName();
    }, []);

    return (
        <>
            {(getOrganisationsApi.loading === true) && <LoadingSpinner />}


            <Modal.Header closeButton>
                <h4 className="modal-title">Switch Organisation</h4>
            </Modal.Header>

            <Modal.Body>

                <div className="row">
                    {/*-------------Offer to sell starts-------------------------------------*/}
                    <div className="col-lg-12">
                        <div className="ibox-content">
                            {(getOrganisationsApi.loading === false && authApi.loading === false && validationMsg === null) &&
                                <>
                                    <FormGroup
                                        label="Organisations"
                                        type="horizontal"
                                        input={
                                            <Picker
                                                items={orgList}
                                                selectedItem={selectedOrganisation}
                                                onSelectItem={handlePickerChange}
                                                isDisabled={orgList.length === 1}
                                            />
                                        }
                                    />
                                </>
                            }

                            {validationMsg !== null && <center><div className="text-danger" style={{ padding: 30 }}>{validationMsg}</div></center>}

                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="col-lg-12 ">
                    {hideBtn === false ? <GeneralButton type="button" disabled={true} onClick={() => handleOrganisationSwitching()} faIcon="fa fa-refresh" className="btn btn-sm btn-primary pull-right m-l-xs" name="Switch Organisation"></GeneralButton> : ''}
                </div>
            </Modal.Footer >


        </>
    )
}