import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Link } from "react-router";
import moment from "moment";
import _ from "lodash";


// Importing Widgets
import LoanSummary from "../../widgets/LoanWidgets/loanSummary";
import OverDue from "../../widgets/LoanWidgets/overDue";
import CreateLoan from "../../widgets/LoanWidgets/createLoan";
import LoanGraph from "../../widgets/LoanWidgets/loanGraph";
import LoanListView from "../../widgets/LoanWidgets/loanListView";
import loanService from "../../../loan/services/loanTransactions";
import useApi from "../../../../hooks/useApi";
import EmptyDefaultCard from "../../widgets/LoanWidgets/emptyDefaultCard";
import auth from "../../../../services/authService";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";

import {
	TextInput,
	Picker,
	GeneralButton,
	DateTimePicker,
	CurrencyLabel,
	CurrencyText
} from "../../../common/inputs";

function RequestSettlement() {

	const { constants, entity_id } = auth.getCurrentUser();
	const currentDate = moment().format('YYYY/MM/DD').toString();
	let renderButton = false;

	const [loanList, setLoanList] = useState("");
	const [selectedLoan, setSelectedLoan] = useState("");
	const [loanPaymentSchedule, setLoanPaymentSchedule] = useState("");
	const [selectedSchedule, setSelectedSchedule] = useState("");
	const [settlePaymentModal, setSettlePaymentModal] = useState(false);
	const [settlementInfo, setSettlementInfo] = useState("");

	const loanListApi = useApi(loanService.KeyWordAutoSuggestionsLoan);
	const loanScheduleApi = useApi(loanService.getLoanSchedule);
	const reqSettlementApi = useApi(loanService.requestSettlement);

	// useEffect(() => {
	//     if (reload) {
	//         setReload(false);
	//     }
	// }, [reload]);



	const listLoan = async () => {
		const { data } = await loanListApi.request({
			additionalParams: {
				a_guid: entity_id,
				loan_status: 'open'
			},
			search: false,
		});
		setLoanList(data);
	}

	const handleSelectLoan = (selectedOption) => {
		setSelectedLoan(selectedOption);
	}

	const handleLoadSchedule = async () => {
		let paymentsArr = [];

		const loanSchedule = await loanScheduleApi.request({
			loan_uid: selectedLoan.loan_uid
		});

		if (loanSchedule.data.success) {
			loanSchedule.data.schedule.map((item) => {
				paymentsArr.push({
					index: paymentsArr.length,
					checked: false,
					disabled: item.disabled,
					payment_status: _.startCase(item.payment_status),
					loan_uid: item.loan_uid,
					schedule_uid: item.schedule_uid,
					payment_date: item.payment_date,
					start_balance:
						item.start_balance !== null
							? parseFloat(item.start_balance)
							: 0,
					repayment_amount:
						item.repayment_amount !== null
							? parseFloat(item.repayment_amount)
							: 0,
					repayment_charge:
						item.repayment_charge !== null
							? parseFloat(item.repayment_charge)
							: 0,
					repayment_interest:
						item.repayment_interest !== null
							? parseFloat(item.repayment_interest)
							: 0,
					total_repayment: item.total_repayment !== null
						? parseFloat(item.total_repayment)
						: 0,
					balance_amount:
						item.balance_amount !== null
							? parseFloat(item.balance_amount)
							: 0,
					repay_balance:
						item.repay_balance !== null
							? parseFloat(item.repay_balance)
							: 0,
					lpf:
						item.lpf !== null
							? parseFloat(item.lpf)
							: 0,
					paid_amount:
						item.paid_amount !== null
							? parseFloat(item.paid_amount)
							: 0,
				});
			})
		} else {
			toast.error("Failed to fetch loan schedule.")
		}

		setLoanPaymentSchedule(paymentsArr);
	}

	const handleRequestSettlement = (selectedRow) => {
		setSelectedSchedule(selectedRow);
		setSettlePaymentModal(true);
		setSettlementInfo({
			date: currentDate,
			amount: parseFloat(selectedRow.repay_balance) + parseFloat(selectedRow.lpf),
			loan_uid: selectedRow.loan_uid,
			scheduleid: selectedRow.schedule_uid
		})
	}

	const handleSubmitRequest = async () => {
		if (settlementInfo.amount > (selectedSchedule.repay_balance + selectedSchedule.lpf)) {
			toast.error("You are not allowed to pay excess amount. Balance amount to pay is " + (selectedSchedule.repay_balance + selectedSchedule.lpf))
			return false;
		}
		const { data } = await reqSettlementApi.request({
			...settlementInfo
		});
		if (data.success == true) {
			toast.success(data.message);
			setSelectedSchedule("");
			setSettlePaymentModal(false);
			setSettlementInfo("")
		} else {
			toast.error(data.message);
		}
	}

	const handleChange = (e, name, value) => {
		const data = { ...settlementInfo };
		data[name] = value;
		setSettlementInfo(data);
	}

	const checkIsOverDue = (date1, date2, status) => {
		date2 = moment(date2, 'DD/MM/YYYY').format('DD/MM/YYYY').toString();
		if ((new Date(date1).getTime() > new Date(date2).getTime()) && status != 'Paid') {
			return true;
		} else {
			return false;
		}

	}

	useEffect(() => {
		listLoan();
		renderButton = false;
	}, []);

	return (
		<>

			{(loanListApi.loading == true || loanScheduleApi.loading == true || reqSettlementApi.loading == true) && <LoadingSpinner />}
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-sm-4">
							<label className="control-label">Choose Loan</label>
							<Picker
								items={loanList}
								selectedItem={selectedLoan}
								onSelectItem={handleSelectLoan}
								name="loan_id"
							/>
						</div>
						<div className="col-sm-4 mt-2">
							<GeneralButton
								onClick={() => {
									handleLoadSchedule();
								}}
								className="btn btn-primary btn-sm mt-4"
								name="Show Schedule"
								disabled={selectedLoan.length == 0 ? true : false}
							></GeneralButton>
						</div>
					</div>
					<hr />
					{
						loanPaymentSchedule ? (
							<>
								<h6>Loan Schedule</h6>
								<div className="table-responsive">
									<table class="table text-secondary">
										<tbody>
											<tr>
												<th>Due Date</th>
												<th>Total Principle Due</th>
												<th>Charges Due</th>
												<th>Interest Due</th>
												<th>Principle Due</th>
												<th>LPF Due</th>
												<th>Total Due</th>
												<th>Paid Amount</th>
												<th>Amount Outstanding</th>
												<th>Payment Status</th>
												<th>Action</th>
											</tr>
											{loanPaymentSchedule.map((item, index) => (
												<tr key={index} title="OverDue" className={checkIsOverDue(currentDate, item.payment_date, item.payment_status) == true ? "text-danger" : ""}>
													<td>{item.payment_date}</td>
													<td>
														<CurrencyLabel
															value={item.start_balance}
														></CurrencyLabel>
													</td>

													<td>
														<CurrencyLabel
															value={item.repayment_charge}
														></CurrencyLabel>
													</td>

													<td>
														<CurrencyLabel
															decimal={`4`}
															value={item.repayment_interest}
														></CurrencyLabel>
													</td>

													<td>
														<CurrencyLabel
															value={item.repayment_amount}
														></CurrencyLabel>
													</td>

													<td>
														<CurrencyLabel
															value={item.lpf}
														></CurrencyLabel>
													</td>

													<td>
														<CurrencyLabel
															value={item.total_repayment}
														></CurrencyLabel>
													</td>
													<td>
														<CurrencyLabel
															value={item.paid_amount}
														></CurrencyLabel>
													</td>
													<td>
														<CurrencyLabel
															value={item.repay_balance}
														></CurrencyLabel>
													</td>
													<td>
														<span className={item.payment_status == 'Paid' ? "text-success" : "text-danger"}>
															{
																item.payment_status
															}
														</span>
													</td>
													<td>

														{
															item.payment_status != 'Paid' && renderButton == false && (

																<>
																	{
																		renderButton = true
																	}
																	<Link
																		className="action-icons text-secondary cursor-link"

																		title="Request Settlement"
																		onClick={() => { handleRequestSettlement(item) }}
																	>
																		<i className="fa fa-money"></i>
																	</Link>

																</>
															)
														}
													</td>
												</tr>
											))}

										</tbody>
									</table>
								</div>
							</>
						) : (
							<>
								<div className="alert alert-default">
									<p>Please find your loan by using the provided search criteria</p>
								</div>
							</>
						)
					}
				</div>
			</div>

			{ /* ----- modal to show amout and payment date ---- */}
			<Modal
				show={settlePaymentModal}
				onHide={(e) => setSettlePaymentModal(false)}
			>
				<Modal.Header closeButton>
					<h4 className="card-title">Report Payment</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="row mb-3 rounded-lg mx-0 py-3 bg-light ">
						<div className="col-sm-12">
							<div className="row">
								<div class="col-sm-6 col-lg-4">
									<h6 class="card-title">Loan Reference</h6>
									<h6 class="mt-3 mb-1 font-weight-bold">
										<span>
											{selectedLoan && selectedLoan.value}
										</span>
									</h6>
								</div>
								<div class="col-sm-6 col-lg-4">
									<h6 class="card-title">Total Amount</h6>
									<h6 class="mt-3 mb-1 font-weight-bold">
										<span>
											<CurrencyLabel
												value={selectedSchedule ? selectedSchedule.total_repayment : 0}
											></CurrencyLabel>
										</span>
									</h6>
								</div>
								<div class="col-sm-6 col-lg-4">
									<h6 class="card-title">Balance To Pay</h6>
									<h6 class="mt-3 mb-1 font-weight-bold">
										<span>
											<CurrencyLabel
												value={selectedSchedule ? selectedSchedule.repay_balance + selectedSchedule.lpf : 0}
											></CurrencyLabel>
										</span>
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-5"><strong>Amount being paid</strong></div>
						<div className="col-sm-7">
							<CurrencyText
								value={settlementInfo.amount}
								onChangeEvent={(event, maskedvalue, floatvalue) => {
									handleChange(event, 'amount', floatvalue);
								}}
								placeholder="Amount being paid"
							/>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-sm-5"><strong>Date</strong></div>
						<div className="col-sm-7">
							<DateTimePicker
								name="date"
								selectedDate={settlementInfo.date}
								onChange={(e) => {
									handleChange(e, 'date', e.target.value);
								}
								}
								enableWeekDays="true"
							/>
						</div>
					</div>
					<div className="row mt-2">
						<div className="col-sm-5 mt-2"></div>
						<div className="col-sm-7 mt-2">
							<GeneralButton
								onClick={() => {
									handleSubmitRequest();
								}}
								className="btn btn-primary btn-sm mt-4"
								name="Submit"
							//disabled={selectedLoan.length == 0 ? true : false}
							></GeneralButton>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/*---------------------------------------------------------*/}
		</>
	)
}
export default RequestSettlement;