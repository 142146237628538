import React, { useState, useContext, useEffect } from 'react';
import Calculator from "../../../../img/icons/calculator.svg";
import { RadioButton } from "../../../common/inputs/radioButton/radioButton";
export default function DiscountRates(props) {
    return (
        <div >

            <div className='row'>
                <div className='col-lg-5'>
                    <div className="logo text-center mb-2 mt-2  ">
                        <img src={Calculator} alt="Fifo Calculator" />
                    </div>
                    <div className='text-center'>
                        <h6>Set up rates for your supplier</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. orem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                </div>
                <div className='col-lg-7'>

                    <div>
                        <div className="d-sm-flex w-100  py-3 align-items-center ">
                            <div className="custom-control custom-radio custom-control-inline"></div>
                            <div className='col-lg-10'>

                                <div className="border rounded-xl p-4 shadow-sm mb-3   font-weight-bold">
                                    <dl className="dl-horizontal ">
                                        <dt className="mb-4">Who is funding this program?</dt><dd className="my-3 ml-3"><div></div>
                                            <div className="mt-2 ml-3">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <RadioButton
                                                        name="customRadioInline1"
                                                        value="Yes"

                                                        id="Yes"
                                                        label="Myself"
                                                        className="custom-control custom-radio mt-2"
                                                    ></RadioButton>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <RadioButton
                                                        name="customRadioInline1"
                                                        value="No"

                                                        id="No"
                                                        label="FIFO"
                                                        className="custom-control custom-radio mt-2"
                                                    ></RadioButton>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl className="dl-horizontal ">
                                        <dt className="mb-4">Set Discount Rate</dt><dd className="my-3 ml-3"><div></div>
                                            <div className="mt-2 ml-3">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <RadioButton
                                                        name="customRadioInline1"
                                                        value="Yes"

                                                        id="Yes"
                                                        label="Flexible Discounts"
                                                        className="custom-control custom-radio mt-2"
                                                    ></RadioButton>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <RadioButton
                                                        name="customRadioInline1"
                                                        value="No"

                                                        id="No"
                                                        label="Dynamic Discounting "
                                                        className="custom-control custom-radio mt-2"
                                                    ></RadioButton>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <RadioButton
                                                        name="customRadioInline1"
                                                        value="No"

                                                        id="No"
                                                        label="Fixed Discount"
                                                        className="custom-control custom-radio mt-2"
                                                    ></RadioButton>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <div className="form-group p-2">
                                        <label>Enter Rate?</label>
                                        <div >  <input typeof='text' className='form-control' name="purchase_order_no" value="" label="Purchase Order Number" reference="" onChange="" type="" /></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}