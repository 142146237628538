import React, { useContext } from "react";
import { SecondaryCard } from "../../components/cards";


import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";
import RoleChecker from "../../../../utils/roleChecker";

function AwaitSupplierUpdate({ data ,loading,setProductRoleType}) {

  const { constants } = authService.getCurrentUser();  
  const { entityDetails } = useContext(EntityContext);

  return (
    <>
      {RoleChecker.check(entityDetails.products, constants.ROLES.TFSUPPLIER)  && (
        <SecondaryCard
          loading={loading}
          data={data}
          setProductRoleType ={ setProductRoleType}
        />
      )}
    </>
  )
}
export default AwaitSupplierUpdate;