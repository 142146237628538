import React, { useEffect, useContext, useState } from "react";
import { CurrencyLabel } from "../../../common/inputs";
import useApi from '../../../../hooks/useApi';
import useIsMounted from "../../../../hooks/useIsMounted";
import EntityContext from "../../../../context/entityContext";
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";
import "../../components/cards/css/card.css";
import { FormColumnMd } from "../../../common/containers/formContainer";
import CardSkelton from "../../components/cards/_cardSkelton";
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import RequestDisburse from "./_requestDisburse";

function UnDrawnAvailability({ invData = null }) {

    const isMounted = useIsMounted();
    const { selectedBusinessType, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);
    const [disburseModal, setDisburseModal] = useState(false);

    //------------Api Service--------------------------------------------
    const invoiceSummaryApi = useApi(dfTransactionService.InvoiceSummary);

    const fetchData = async () => {

        if (invData == null) {
            const response = await invoiceSummaryApi.request({
                trans_client_guid: entityDetails.a_guid,
                trans_type: "open",
                dateFilter: new Date(),
            });
            if (response && isMounted()) {
                invData = response.data;
            }
        }
        let undrawnAmount =  invData.invoiceSummary.undrawn_amount ? invData.invoiceSummary.undrawn_amount : 0 ;
        setData({
            topLabel: "UNDRAWN AVAILABILITY",
            topValue: <CurrencyLabel value={undrawnAmount} />,
        })
    }

    const closeDisburseRequestModal = () => {
        setDisburseModal(false);
    }

    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>
            {/* <DangerTypeTwoCard
            col={`12`}
                //loading={invoiceSummaryApi.loading}
                data={data}
                qrystring={true}
            /> */}
            {invoiceSummaryApi.loading === true ? <CardSkelton /> : (
                <FormColumnMd col={`12`}>

                    <div className="card">
                        <div className="card-body"><span className="b-corner b-corner-r b-corner-success" />
                            <div className="d-flex justify-content-between">
                                <h6 className="card-title  ">{data ? data.topLabel : ""}</h6>
                            </div>
                            <div className="d-flex mb-0">
                                <div>
                                    <Link className="text-secondary">
                                        <h4 className="mb-1 font-weight-bold">{data ? data.topValue : ""}</h4>
                                    </Link>
                                </div>
                                <div className="card-chart bg-warning-transparent brround ml-auto mt-0">
                                    <i className="typcn typcn-chart-line-outline text-warning tx-24" />
                                </div>
                            </div>
                            <div className=" h-5 mt-2 mb-3">
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm float-right"
                                        onClick={() => { setDisburseModal(true) }}
                                    >Request Drawdown</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormColumnMd>
            )}

            <Modal
                show={disburseModal}
                onHide={() => { closeDisburseRequestModal() }}
                center
            >
                {
                    data && (
                        <RequestDisburse
                            onClose={() => { closeDisburseRequestModal() }}
                            availableAmount={data.topValue}
                        />
                    )
                }

            </Modal>
        </>
    )
}
export default UnDrawnAvailability;