import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { CurrencyLabel } from "../../../../../common/inputs";
import commonService from "../../../../../supply-chain/services/commonService";
import authService from "../../../../../../services/authService";
import useApi from "../../../../../../hooks/useApi";
import moment from "moment";
import { toast } from "react-toastify";

const PaymentRequestHistory = (props) => {
  const deleteApi = useApi(commonService.removePayment);
  const { constants } = authService.getCurrentUser();

  const deleteRequest = (payment_requested_guid) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Are you sure ...?</h4>
            <p>{`Do you want to remove this scheduled payment?`}</p>
            <button
              className="btn btn-white"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                deletePaymentRequest(payment_requested_guid);
                onClose();
              }}
            >
              {"Yes"}
            </button>
          </div>
        );
      },
      title: "Delete Payment Request",
      message: `Are you sure want to delete `,
    });
  };
  const deletePaymentRequest = async (payment_requested_guid) => {
    const result = await deleteApi.request({
      payment_requested_guid: payment_requested_guid,
      product_type: props.product,
    });

    if (result.data.success === true) {
      toast.success(result.data.message);
      props.fetchAllDetails();
    } else {
      toast.error("Something went wrong...!");
    }
  };
  return (
    <div class="container">
      <div className="col-sm-12">
        {props.filteredPayments.length > 0 ? (
          <div className="dyn-height" style={{ minHeight: 400 }}>
            <table class="table text-secondary">
              <tbody>
                <tr>
                  <th>Supplier Name</th>
                  <th>Inv No.</th>
                  <th>Inv Date</th>
                  <th>Settlement Date</th>
                  <th>Payment Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                {props.filteredPayments
                  .sort((a, b) =>
                    moment(b.updated_at).diff(moment(a.updated_at))
                  )
                  .map((listValue, index) => {
                    const isPending = listValue.requested_status === "Pending";
                    const isClosedFundCollected = listValue.trans_status === "Closed Fund Collected";

                    if (isPending && isClosedFundCollected) return null;

                    return (
                      <tr key={index}>
                        <td>{listValue.organization}</td>
                        <td>{listValue.inv_number}</td>
                        <td>
                          {moment(listValue.trans_date).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {moment(listValue.requested_date).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>
                          <CurrencyLabel
                            value={listValue.amount_requested || "-"}
                          ></CurrencyLabel>
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              listValue.requested_status === "Received"
                                ? `bg-primary`
                                : listValue.requested_status === "Rejected"
                                ? `bg-danger`
                                : `bg-warning`
                            }`}
                          >
                            {listValue.requested_status}
                          </span>
                        </td>
                        <td>
                          {listValue.requested_status === "Pending" && (
                            <a
                              className="text-danger"
                              onClick={(e) => {
                                deleteRequest(listValue.payment_requested_guid);
                              }}
                              href="#"
                            >
                              <i
                                title="Remove request"
                                className="fa fa-trash m-l-xs"
                              ></i>
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}{" "}
                {/* Closing parenthesis for map function */}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center">
            <span className="text-center text-danger">
              No previous requests Found
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default PaymentRequestHistory;
