import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../common/loaders/loadingSpinner';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import Modal from 'react-bootstrap/Modal';
import Joi from "joi-browser";
import authService from "../../services/authService";
import { toast } from "react-toastify";
import { browserHistory } from "react-router";
import { TextInput, GeneralButton } from "../../components/common/inputs";

export default function PasswordReset({ onClose,clientEmail}) {

    //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      email: Joi.string().email().required().label("Email"),
    }).unknown(true);

  //------------Custom hooks for handling API Requests--------------
  const authApi = useApi(authService.sendPasswordResetInstructions);
  //----------------------Send Instructions---------------------------
  const sendResetInstructions = async () => {
    const res = await authApi.request(values.email);
    if (res.success === true) {
      toast.success(res.message);
      //browserHistory.push("/login");
      onClose();
    }
    else
      toast.error(res.message);
  };
  //---------------------Custom Hook to handle Form Validation------------------------//
  const { values,setValues, handleChange, handleSubmit } = useForm(sendResetInstructions, schema);

      useEffect(() => {
        setValues({email:clientEmail.email})

      }, []);
    return (
        <>

            <Modal.Header closeButton>

                <h4 className="modal-title">
                    Password Reset
                </h4>
            </Modal.Header>

            <Modal.Body>
            {authApi.loading === true && <LoadingSpinner />}
                <form role="form" onSubmit={handleSubmit}>
                    <TextInput name="email" value={values.email} placeholder="Enter your email address" onChange={handleChange} class="form-control mb-2" type="normal" />
                    <GeneralButton className="btn btn-primary block full-width m-b m-t-md mt-4" name={"Send Reset Link"}></GeneralButton>
                
                </form>

            </Modal.Body>
        </>
    )
}