import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { CurrencyLabel } from "../../../../../common/inputs";
import moment from "moment";
import _ from "lodash";
import LoadingSpinner from "../../../../../common/loaders/loadingSpinner";
export function TotalDueModal(props) {
  const totalDue = _.sumBy(props.data, (listValue) =>
    Number(listValue.total_due)
  );
  return (
    <Modal
      show={props.totalDueModal}
      onHide={() => props.setTotalDueModal(false)}
      backdrop="static"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <h4 className="modal-title"> Invoices</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table class="table text-secondary">
            <tbody>
              <tr className="stickyRow bg-light">
                <th>Supplier Name</th>
                <th>Inv No.</th>
                <th>Total Due</th>
              </tr>
              {props.modalLoader && <LoadingSpinner />}
              {props.data.length > 0 ? (
                props.data.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td>{listValue.organization}</td>
                      <td>{listValue.inv_number}</td>
                      <td>
                        <CurrencyLabel
                          value={listValue.total_due || "-"}
                        ></CurrencyLabel>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      No invoices found...!
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan="2"></td>
                <td>
                  <strong>
                    <CurrencyLabel value={totalDue}></CurrencyLabel>
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function LessAmountPaidModal(props) {
  const [paidModal, setPaidModal] = useState(false);
  const [paymentsData, setPayments] = useState([]);
  const [totDue, setTotDue] = useState();
  const totalPaid = _.sumBy(props.data, (listValue) =>
    Number(listValue.amount_paid)
  );
  const handlePaidModal = (id) => {
    setPaidModal(true);
    const rows = props.data.filter((row) => row.id === id);
    setPayments(rows[0]?.payments);
    setTotDue(rows[0]?.amount_paid);
  };

  return (
    <>
      <Modal
        show={props.amountPaidModal}
        onHide={() => props.setAmountPaidModal(false)}
        backdrop="static"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <h4 className="modal-title"> Invoices</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table class="table text-secondary">
              <tbody>
                <tr className="stickyRow bg-light">
                  <th>Supplier Name</th>
                  <th>Inv No.</th>
                  <th>Amount Paid</th>
                </tr>
                {props.modalLoader && <LoadingSpinner />}
                {props.data.length > 0 ? (
                  props.data.map((listValue, index) => {
                    if (listValue.amount_paid != 0) {
                      return (
                        <tr key={index}>
                          <td>{listValue.organization}</td>
                          <td>{listValue.inv_number}</td>
                          <td>
                            <span>
                              <CurrencyLabel
                                value={listValue.amount_paid || "-"}
                              ></CurrencyLabel>
                              <i
                                title="Click here to view the details"
                                className="fa fa-info-circle ml-2 text-primary mt-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handlePaidModal(listValue.id);
                                }}
                              ></i>
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan="3">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        No invoices found...!
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan="2"></td>
                  <td>
                    <strong>
                      <CurrencyLabel value={totalPaid}></CurrencyLabel>
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={paidModal}
        onHide={() => setPaidModal(false)}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header closeButton>Payments</Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table class="table text-secondary">
              <tbody>
                <tr className="stickyRow bg-light">
                  <th>Date</th>
                  <th>Payment</th>
                  <th>Type</th>
                </tr>
                {props.modalLoader && <LoadingSpinner />}
                {paymentsData.length > 0 ? (
                  paymentsData.map((listValue, index) => (
                    <tr key={index}>
                      <td>{listValue.paid_date}</td>
                      <td>{listValue.amount_paid}</td>
                      <td>{listValue.payment_type}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        No invoices found...!
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td></td>
                  <td>
                    <strong>
                      <CurrencyLabel value={totDue}></CurrencyLabel>
                    </strong>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function DDInvoiceModal(props) {
  const totaldd = _.sumBy(props.data, (listValue) =>
    Number(listValue.dd_requested)
  );

  return (
    <Modal
      show={props.ddModal}
      onHide={() => props.setDdModal(false)}
      backdrop="static"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <h4 className="modal-title"> Invoices Scheduled for Payment Direct on {props.selectedDate}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table class="table text-secondary">
            <tbody>
              <tr className="stickyRow bg-light">
                <th>Supplier Name</th>
                <th>Inv No.</th>
                <th>Direct Debit Amount</th>
                <th>Date Scheduled</th>
              </tr>
              {props.modalLoader && <LoadingSpinner />}
              {props.data.length > 0 ? (
                props.data.map((listValue, index) => {
                  if (listValue.dd_requested !== null) {
                    return (
                      <tr key={index}>
                        <td>{listValue.organization}</td>
                        <td>{listValue.inv_number}</td>

                        <td>
                          <CurrencyLabel
                            value={listValue.dd_requested || "-"}
                          ></CurrencyLabel>
                        </td>
                        <td>
                          {moment(listValue.dd_requested_date).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                      </tr>
                    );
                  }
                })
              ) : (
                <tr>
                  <td colSpan="4">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      No invoices found...!
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan="2"></td>
                <td>
                  <strong>
                    <CurrencyLabel value={totaldd}></CurrencyLabel>
                  </strong>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function LpfInvoiceModal(props) {
  // const totalLpf = _.sumBy(
  //   _.filter(props.data, (listValue) => listValue.is_overdue === 1),
  //   (listValue) => Number(listValue.lpf)
  // );
  const totalLpf = _.sumBy(props.data, (listValue) => Number(listValue.lpf));

  return (
    <Modal
      show={props.lpfModal}
      onHide={() => props.setLpfModal(false)}
      backdrop="static"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <h4 className="modal-title"> Invoices: Late Payment Fees</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table class="table text-secondary">
            <tbody>
              <tr className="stickyRow bg-light">
                <th>Supplier Name</th>
                <th>Inv No</th>
                <th>Inv Date</th>
                <th>Inv Due Date</th>
                <th>Revised Inv Due Date</th>
                <th>Days Overdue</th>
                <th>Late Payment Fee</th>
              </tr>
              {props.modalLoader && <LoadingSpinner />}
              {props.data.length > 0 ? (
                props.data.map((listValue, index) => {
                  // if (listValue.is_overdue === 1) {
                    return (
                      <tr key={index}>
                        <td>{listValue.organization}</td>
                        <td>{listValue.inv_number}</td>
                        <td>
                          {moment(listValue.inv_date).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {moment(listValue.inv_due_date).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {listValue.inv_revised_due_date !== null &&
                          moment(listValue.inv_revised_due_date).isValid()
                            ? moment(listValue.inv_revised_due_date).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </td>
                        <td>
                          {listValue.over_due_days
                            ? listValue.over_due_days
                            : "0"}{" "}
                          Days
                        </td>
                        <td>
                          <CurrencyLabel value={listValue.lpf}></CurrencyLabel>
                        </td>{" "}
                      </tr>
                    );
                  //}
                })
              ) : (
                <tr>
                  <td colSpan="7">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      No invoices found...!
                    </div>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan="6"></td>
                <td>
                  <strong>
                    <CurrencyLabel value={totalLpf}></CurrencyLabel>
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function FcInvoiceModal(props) {
  const totalfc = _.sumBy(props.data, (listValue) =>
    Number(listValue.finance_cost)
  );

  return (
    <Modal
      show={props.finModal}
      onHide={() => props.setFinModal(false)}
      backdrop="static"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <h4 className="modal-title"> Invoices: Total Finance Cost</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table class="table text-secondary">
            <tbody>
              <tr className="stickyRow bg-light">
                <th>Supplier Name</th>
                <th>Inv No</th>
                <th>Inv Date</th>
                <th>Inv Due Date</th>
                <th>Days Funded</th>
                <th>Finance Cost</th>
              </tr>
              {props.modalLoader && <LoadingSpinner />}
              {props.data.length > 0 ? (
                props.data.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td>{listValue.organization}</td>
                      <td>{listValue.inv_number}</td>
                      <td>{moment(listValue.inv_date).format("DD/MM/YYYY")}</td>
                      <td>
                        {moment(listValue.inv_due_date).format("DD/MM/YYYY")}
                      </td>
                      <td>{listValue.funding_days} days</td>
                      <td>
                        <CurrencyLabel
                          value={listValue.finance_cost}
                        ></CurrencyLabel>
                      </td>{" "}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      No invoices found...!
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan="5"></td>
                <td>
                  <strong>
                    <CurrencyLabel value={totalfc}></CurrencyLabel>
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function OpenModal(props) {
  const totalinv = _.sumBy(props.data, (listValue) =>
    Number(listValue.inv_amount)
  );

  return (
    <Modal
      show={props.openInvoiceModal}
      onHide={() => props.setOpenInvoiceModal(false)}
      backdrop="static"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <h4 className="modal-title">Open Invoices</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table class="table text-secondary">
            <tbody>
              <tr className="stickyRow bg-light">
                <th>Supplier Name</th>
                <th>Inv No</th>
                <th>Inv Date</th>
                <th>Revised Inv Due Date</th>
                <th>Inv Amount</th>
              </tr>
              {props.modalLoader && <LoadingSpinner />}
              {props.data.length > 0 ? (
                props.data.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td>{listValue.organization}</td>
                      <td>{listValue.inv_number}</td>
                      <td>
                        {moment(listValue.trans_date).format("DD/MM/YYYY")}
                      </td>

                      <td>
                        {listValue.inv_revised_due_date !== null &&
                        moment(listValue.inv_revised_due_date).isValid()
                          ? moment(listValue.inv_revised_due_date).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </td>
                      <td>
                        <CurrencyLabel
                          value={listValue.inv_amount}
                        ></CurrencyLabel>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      No invoices found...!
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan="4"></td>
                <td>
                  <strong>
                    <CurrencyLabel value={totalinv}></CurrencyLabel>
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}
