import React, { useContext, useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import externalApiService from "../../../services/externalApiService";
import authService from "../../../services/authService";
import useApi from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { GeneralButton, TextInput } from "../../common/inputs";
import { CountriesList, AllCorporateBpList, CustomersList } from '../../slplusTools';


export default function CreateManoovaToken(props) {

    const { constants, category } = authService.getCurrentUser();

    const [customer, setCustomer] = useState(props.customer ? props.customer : null);
    const [country, setCountry] = useState(null);
    const [owner, setOwner] = useState(null);

    const registerManoovaApi = useApi(externalApiService.registerManoova);

    //------------------Validation Schema-----------------------------
    const schema = Joi.object() // Form validation schema object
        .keys({
            bankAccountName: Joi.string().max(30).required().error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case "string.max":
                            err.message = `Bank Account Name should have at most 30 characters!`;
                            break;
                        default:
                            err.message = `Please enter valid account name`;
                            break;
                    }
                });
                return errors;
            }),
            bankAccountNumber: Joi.number().integer().required().error((errors) => { return { message: "Please enter valid account number" }; }),
            bsb: Joi.number().integer().required().error((errors) => { return { message: "Please enter valid bsb number" }; }),
        })
        .unknown(true);

    const registerToken = async () => {
        if (customer == null) {
            toast.error("PLease select a customer");
            return false;
        }
        const { data } = await registerManoovaApi.request({
            ...values,
            customer_guid: customer
        });
        if (data.success == true) {
            props.closeModal();
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">

                            <h4>Account registered successfully</h4>
                            <p>Account has been successfully registered in manoova. Now you can request for online payment.</p>

                            <button
                                className="btn btn-white ml-2 mt-2"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </button>

                        </div>
                    );
                },
                title: "Account registered successfully",
                message: "Account has been successfully registered in manoova. Now you can request online payments.",
            });
        } else {
            toast.error(data.message);
        }
    }


    const {
        values,
        setValues,
        handleChange,
        handleSubmit
    } = useForm(registerToken, schema);

    return (
        <>
            {
                registerManoovaApi.loading == true && <LoadingSpinner />
            }
            <form onSubmit={handleSubmit} className="form-horizontal">
                <div className="row">
                    {
                        customer == null && (
                            <>
                                {
                                    category == constants.CATEGORY.INTL_OFFICE && (
                                        <>
                                            <div className="col-sm-12 from-group">
                                                <div className="form-group">
                                                    <label>Country</label>
                                                    <CountriesList
                                                        type="horizontal"
                                                        selectedCountry={country}
                                                        onSelectItem={({ id }) => {
                                                            setCountry(id);
                                                            setOwner(null);
                                                            setCustomer(null);
                                                        }}
                                                    />
                                                </div>

                                            </div>

                                        </>
                                    )
                                }
                                {
                                    (category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) && (
                                        <>
                                            <div className="col-sm-12 from-group">
                                                <div className="form-group">
                                                    <label>Business Partner</label>
                                                    <AllCorporateBpList
                                                        selectedBp={owner}
                                                        type="horizontal"
                                                        country={country}
                                                        onSelectItem={({ value }) => {
                                                            setOwner(value);
                                                            setCustomer(null);
                                                        }}
                                                    />
                                                </div>

                                            </div>

                                        </>
                                    )
                                }
                                <div className="col-sm-12 from-group">
                                    <div className="form-group">
                                        <label>Customer</label>
                                        <CustomersList selectedCustomer={customer} bp={owner} category={
                                            props.product === constants.PRODUCTS.SUPPLYCHAIN ? constants.ROLES.CUSTOMER : 
                                            props.product === constants.PRODUCTS.TRADEFINANCE ? constants.ROLES.TFCUSTOMER :
                                            props.product === constants.PRODUCTS.LOAN ? constants.ROLES.LOANCLIENT : null
                                        }
                                            onSelectItem={option => setCustomer(option.value)}
                                        />
                                    </div>

                                </div>

                            </>
                        )
                    }

                    <div className="col-lg-12">
                        <TextInput
                            name="bankAccountName"
                            value={values.bankAccountName}
                            label={"Bank Account Name"}
                            onChange={handleChange}
                            type="vertical"
                        ></TextInput>
                    </div>
                    <div className="col-lg-12">
                        <TextInput
                            name="bankAccountNumber"
                            value={values.bankAccountNumber}
                            label="Bank Account Number"
                            onChange={handleChange}
                            type="vertical"
                        ></TextInput>
                    </div>
                    <div className="col-lg-12">
                        <TextInput
                            name="bsb"
                            value={values.bsb}
                            label={"BSB Number"}
                            onChange={handleChange}
                            type="vertical"
                        ></TextInput>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <GeneralButton
                            className="btn btn-primary  ml-2 pull-right"
                            name="Submit"
                        ></GeneralButton>
                    </div>
                </div>
            </form>
        </>
    );
}