import axios from "axios";
import { apiUrl } from "../../src/environment";

import AxiosTokens from "./axiosTokens";
import authService from "../services/authService";
import { browserHistory } from "react-router";
import { toast } from "react-toastify";

const axToken = AxiosTokens();
let cancelToken = axToken.generateCancellationToken();
//-------- Creating an Instance for Axios -------------
var axiosInstance = axios.create({
    baseURL: apiUrl,
    /* other custom settings */
});
// ------------------------------------------------

//----------- Add a request interceptor -----------
axiosInstance.interceptors.request.use(function (config) {
    config.cancelToken = cancelToken.token;
    config.withCredentials = true;
    return config;
});
// ------------------------------------------------

//-------- Axios response interceptor -------------
// axiosInstance.interceptors.response.use(null, error => {
//     return Promise.reject(error);
// });


axiosInstance.interceptors.response.use(null, error => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 500;
  
    // If access is forbidden
    if (error.response !== undefined && error.response.status === 403){
      if(authService.tokenExists())
        toast.error("Access forbidden. Please contact the administrator.");
      else{
        toast.error("Your request is invalid. Please try to login again.");
        authService.logout();
        browserHistory.push("/login");

        setTimeout(() => {
          window.location.reload();
        }, 2000); // Delay the reload by 2 seconds
      }
    }
    
    // If invalid credentials
    else if (error.response !== undefined && error.response.status === 401){

      toast.error("Your request is invalid. Please try to login again.");

      if(authService.tokenExists())
      {
        const userInfo = authService.getCurrentUser();
        authService.logout();
        cancelToken.cancel();
        
        if (userInfo.constants.CATEGORY.TRADERS == userInfo.category)
          browserHistory.push("/client/login");
        else
          browserHistory.push("/login");
      }
      else{
        authService.logout();
        browserHistory.push("/login");
      }

      setTimeout(() => {
        window.location.reload();
      }, 2000); // Delay the reload by 2 seconds
    }
  
    else if (expectedError !== undefined) {
      let errorMessage = "An unexpected error occurred.";
      if (error.response)
        errorMessage += ` Status code: ${error.response.status}`;
      toast.error(errorMessage);
    }
  
    return Promise.reject(error);
  });
// -----------------------------------------------------------------------------

axiosInstance.post = (reqUrl, reqParams) => {    
    //Save the cancel token for the current request
    cancelToken = axToken.generateCancellationToken();
    return axiosInstance.request({
        url: reqUrl,
        method: "POST",
        data: reqParams,
        //cancelToken: cancelToken.token
    })
}

const CancelAxiosRequest = () => {
    cancelToken.cancel("Operation cancelled due to new request");
}

export default {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    delete: axiosInstance.delete,
    cancel: CancelAxiosRequest
};