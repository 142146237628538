import React, { useEffect, useState, useContext } from "react";
import useForm from "../../../../../hooks/useForm";
import { TextInput } from "../../../../common/inputs";
import { SaveButton } from "../../../../common/containers/button";
import {
  FormRow,
} from "../../../../common/containers/formContainer";
import CommonService from "../../../../supply-chain/services/commonService";
import entityService from "../../../../../services/entityService";
import useApi from "../../../../../hooks/useApi";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import auth from "../../../../../services/authService";
import EntityContext from "../../../../../context/entityContext";

function CreateInstantEntity({
  onCloseModal,
  searchData,
  title,
  accountType,
  handleSelectedItem = null,
}) {

  const { entityDetails } = useContext(EntityContext);

  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      accType: Joi.string().required().valid("intl", "local"),
      organization: Joi.string().required().label("Organisation"),
      first_name: Joi.string().required().label("First Name"),
      email: Joi.string().email().required().label("Email"),
    })
    .unknown(true)
    .when(Joi.object({ accType: Joi.string().valid("local") }).unknown(true), {
      then: Joi.object({
        ab_number: Joi.string().required().label("ABN"),
      })
    });

  const { constants } = auth.getCurrentUser();
  const AddClientSecondaryApi = useApi(entityService.AddClientSecondary);
  const generateUndisclosedDetailsApi = useApi(CommonService.generateUndisclosedDetails);

  const [countryId, setCountryId] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [enabledProducts, setEnabledProducts] = useState([]);
  //------------------Save Entity Details-----------------------------
  const saveMapping = async () => {
    let reqValues = { ...values };

    reqValues['verify_status'] = 'processing';

    const { data: res } = await AddClientSecondaryApi.request(reqValues);

    if (res.success === true) {
      toast.success(res.message);
      onCloseModal();
    } else {
      toast.error(res.message);
    }
  };

  // const handleSelect = (selectedValue, value) => {
  //   setValues({
  //     ...values,
  //     [selectedValue]: value,
  //   });
  // };

  // const handleRoleChange = (items) => {
  //   setValues({ ...values, role_type: items });
  // };
  const { values, setValues, handleChange, handleSubmit } = useForm(
    saveMapping,
    schema
  );
  const handleCheckboxChange = async (e) => {
    if (e.target.value == 0) {
      const { data } = await generateUndisclosedDetailsApi.request();
      setValues({
        ...values,
        first_name: data.firstname,
        last_name: data.lastname,
        email: data.email,
        disclosure: 0
      });
    } else {
      setValues({
        ...values,
        first_name: "",
        last_name: "",
        email: "",
        disclosure: 1
      });
    }
  }
  const checkIsProductEnabled = () => {

    let enabledProducts = [];
    if (entityDetails !== undefined) {
      enabledProducts = entityDetails.products.filter(obj =>
      (obj.isChecked === true && (
        obj.id == constants.PRODUCTS.INVOICE ||
        obj.id == constants.PRODUCTS.DEBTORFINANACE ||
        obj.id == constants.PRODUCTS.SUPPLYCHAIN ||
        obj.id == constants.PRODUCTS.TRADEFINANCE
      )
      ));
      setEnabledProducts(enabledProducts)

    }

  }

  useEffect(() => {
    if (accountType == "intl") {
      setValues({
        ...values,
        readonly: false,
        //source: source,
        role_type: [],
        accType: "intl",
        disclosure: 1
      });
    } else {
      setValues({
        ...values,
        readonly: true,
        ab_number: searchData.abn,
        organization: searchData.organisation,
        //source: source,
        role_type: [],
        accType: "local",
        disclosure: 1
      });
    }
    checkIsProductEnabled();
  }, []);

  return (
    <>
      {(AddClientSecondaryApi.loading === true || generateUndisclosedDetailsApi.loading) && <LoadingSpinner />}
      <form className="form-horizontal" onSubmit={handleSubmit}>

        <FormRow className="row  m-b-md ">
          <label className="col-lg-4">Invite on platform</label>
          <div className="col-lg-8">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                onClick={handleCheckboxChange}
                checked={values.disclosure == "1" ? true : false}
                type="radio"
                id={`customCheckDisclosed`}
                name="Disclosed"
                value="1"
                class="custom-control-input"
              />
              <label class="custom-control-label" for={`customCheckDisclosed`}>Yes</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                onClick={handleCheckboxChange}
                checked={values.disclosure == "0" ? true : false}
                type="radio"
                id={`customCheckUnDisclosed`}
                name="Un Disclosed"
                value="0"
                class="custom-control-input"
              />
              <label class="custom-control-label" for={`customCheckUnDisclosed`}>No</label>
            </div>
          </div>
        </FormRow>
        {/* )} */}

        {/* <FormRow className="row  m-b-md ">

          <div className="col-lg-12 bg-light b-dashed-2 p-2">
            {
              enabledProducts.length > 0 && (
                <ProductsList
                  label="Map your facilities(Optional)"
                  col={6}
                  onSelectItem={(items) => handleRoleChange(items)}
                  type="secondary"
                />
              )
            }

          </div>
        </FormRow> */}


        <TextInput
          value={values.organization}
          readonly={values.readonly}
          onChange={handleChange}
          name="organization"
          placeholder="Organisation"
          type="vertical"
          label="Organisation"
          mandatory
        />

        {
          values.disclosure == "1" && (
            <>
              <TextInput
                name="first_name"
                readonly={values.disclosure == "0" ? true : false}
                value={values.first_name}
                onChange={handleChange}
                placeholder="First Name"
                type="vertical"
                label="First Name"
                mandatory
              />

              <TextInput
                name="last_name"
                readonly={values.disclosure == "0" ? true : false}
                value={values.last_name}
                onChange={handleChange}
                placeholder="Last Name"
                type="vertical"
                label="Last Name"
                mandatory
              />
            </>
          )
        }

        {accountType !== "intl" && (
          <TextInput
            value={values.ab_number}
            onChange={handleChange}
            name="ab_number"
            placeholder="ABN"
            readonly={values.readonly}
            type="vertical"
            label="ABN"
            mandatory
          />
        )}
        {
          values.disclosure == "1" && (
            <>
              <TextInput
                value={values.email}
                readonly={values.disclosure == "0" ? true : false}
                onChange={handleChange}
                name="email"
                mandatory
                type="vertical"
                label="Email"
              />
            </>
          )
        }

        <TextInput
          value={values.phone_no}
          onChange={handleChange}
          name="phone_no"
          placeholder="Phone"
          type="vertical"
          label="Phone"
        />

        <div className="text-right">
          <SaveButton disabled={AddClientSecondaryApi.loading} label="Submit" />
        </div>
      </form>
    </>
  );
}

export default CreateInstantEntity;
