import React, { useState, useContext } from 'react';
import ExcelFileUploader from "../common/inputs/fileUploader/ExcelUploader";
import TransactionContext from "../../context/invTransactionContext";
import {
    GeneralButton,
    CurrencyLabel
} from "../common/inputs";
import * as moment from "moment";

const Invoices = ({ handleDataSumbission }) => {

    const {
        invoice,
        invoiceFileAttachments,
        updateInvoiceFileAttachments
    } = useContext(TransactionContext); // Accessing Context API

    let obj = {
        queue: [],
        selected: [],
    };

    const handleDragStart = (e, fileName) => {
        e.dataTransfer.setData("selectedFile", fileName);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleOnDrop = (e, status, row) => {
        let fileName = e.dataTransfer.getData("selectedFile");

        let updatedList = invoiceFileAttachments.filter((item) => {
            if (item.name === fileName) {
                item.status = status;
                item.row = row;
            }
            return item;
        });

        updateInvoiceFileAttachments(updatedList);
    };

    // Method to handle uploading file items
    const handleUploadItems = async (excelData, filesList) => {
        let list = [...invoiceFileAttachments];
        filesList.map((fileItem) => {
            list.push(fileItem);
        });
        updateInvoiceFileAttachments(list);
    };

    invoiceFileAttachments.forEach((file) => {
        obj[file.status].push({
            row: file.row,
            content: (
                <div
                    onDragStart={(e) => {
                        handleDragStart(e, file.name);
                    }}
                    key={file.name}
                    draggable
                    className="draggable alert alert-info text-left"
                >
                    <i class="fa fa-file fa-2x mr-2" aria-hidden="true"></i> {file.name}{" "}
                </div>
            ),
        });
    });

    return (
        <>
            <div className='row'>

                <div className='col-lg-8'>

                    <div class="table-responsive">
                        <div className="dyn-height">
                            <table class="table  table-hover text-secondary">
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>INVOICE NO</strong>
                                        </td>
                                        <td>
                                            <strong>DEBTOR</strong>
                                        </td>
                                        <td>
                                            <strong>INVOICE AMOUNT($)</strong>
                                        </td>
                                        <td>
                                            <strong>UPLOADED FILE ATTACHMENT</strong>
                                        </td>
                                    </tr>

                                    {invoice.map((item, index) => {

                                        const file_item = obj.selected.filter((item) => item.row == index).map((selItem) => selItem.content)

                                        return (
                                            <>
                                                <tr
                                                    key={index}
                                                    onDragOver={(e) => handleDragOver(e)}
                                                    onDrop={(e) => handleOnDrop(e, "selected", index)}
                                                >
                                                    <td>{item.inv_number}</td>
                                                    <td>{item.debtor_name}</td>
                                                    <td><CurrencyLabel value={item.inv_amount}></CurrencyLabel></td>
                                                    <td>
                                                        {file_item.length > 0 ?
                                                            <div className="selected-container">
                                                                {file_item}
                                                            </div> : <span className="text-danger">Required</span>
                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <div className='col-lg-4'>
                    <ExcelFileUploader
                        label={"Select More Files"}
                        handleUploadItems={(excelData, filesList) => handleUploadItems(excelData, filesList)}
                    />
                    <div className='alert alert-info '>Upload invoice files using the above button and drag them into the matching invoice.</div>
                    <div
                        onDragOver={(e) => handleDragOver(e)}
                        onDrop={(e) => handleOnDrop(e, "queue", null)}
                        className="pending-container mt-2"
                    >

                        {obj.queue.map((item) => {
                            return item.content;
                        })}
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-lg-12 text-right">
                    <GeneralButton
                        onClick={() => handleDataSumbission()}
                        className="btn btn-primary btn-sm "
                        faIcon="fa fa-plus"
                        name="Save"
                        id="btn-submit"
                        style={{ visibility: "hidden" }}
                    ></GeneralButton>
                </div>
            </div>
        </>
    );
}

export default Invoices;