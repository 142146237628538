import {FINANCE_ACTION_TYPES} from './finance.types'


// export const saveQuestionAnswer = (url) => {
//     return dispatch => {
//        // fetchStaffBasedOnFilterService(dispatch, url);


//     };
// };

export const handleAddMore = (payload) => ({
    type: FINANCE_ACTION_TYPES.HANDLE_ADD_MORE,
    payload: payload
});
export const handleChangeAnswers = (payload) => ({
    type: FINANCE_ACTION_TYPES.HANDLE_CHANGE_ANSWERS,
    payload: payload
});
export const handleChangeAnswers2 = (payload) => ({
    type: FINANCE_ACTION_TYPES.HANDLE_CHANGE_ANSWERS_FINANCE,
    payload: payload
});
export const changeSelectedType = (payload) => ({
    type: FINANCE_ACTION_TYPES.SET_SELECTED_TYPE,
    payload: payload
});
export const resetBusinessStore = (payload) => ({
    type: FINANCE_ACTION_TYPES.RESET_BUSINESS
   
});
// export const postBusinessQuestions = (payload) => ({
//     type: FINANCE_ACTION_TYPES.SET_SELECTED_TYPE,
//     payload: payload
// });


