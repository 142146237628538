import React, { useContext } from "react";

import ScPurchase from "./snapshots/ScPurchase";
import ScSale from "./snapshots/ScSale";

import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";


function SupplyChainSnapshot() {

  const {constants } = authService.getCurrentUser();
  const { selectedBusinessType } = useContext(EntityContext);

  return (
    <>
      <>

        {selectedBusinessType == constants.BUSINESS_TYPE.PURCHASE ? (
          <>
            <ScPurchase  />
          </>
        ) : (
          <>
            <ScSale  />
          </>
        )}

      </>
    </>
  )
}
export default SupplyChainSnapshot;