import React, { useEffect } from 'react';
import useForm from '../../../../../hooks/useForm';
import { TextInput } from "../../../../../components/common/inputs";
import { SaveButton } from "../../../../common/containers/button";
import { InputGroup, InputGroupSplit } from '../../../../common/containers/formContainer';
import CommonService from "../../../../supply-chain/services/commonService";
import useApi from '../../../../../hooks/useApi';
import { toast } from "react-toastify";
import Joi from "joi-browser";
import LoadingSpinner from '../../../../common/loaders/loadingSpinner';
import auth from "../../../../../services/authService";

function CreateInstantEntity({ role, onCloseModal, searchData, source, product, setNewSupplierId = null }) {

    const { constants } = auth.getCurrentUser();
    //------------------Validation Schema-----------------------------
    const schema = Joi.object() // Form validation schema object
        .keys({

            organization: Joi.string().required().label("Organisation"),
            first_name: Joi.string().required().label("First Name"),
            ab_number: Joi.string().required().label("ABN"),
            email: Joi.string().email().required().label("Email"),
        }).unknown(true);

    const createMappingApi = useApi(CommonService.createUserWithMapping);
    const generateUndisclosedDetailsApi = useApi(CommonService.generateUndisclosedDetails);
    //------------------Save Entity Details-----------------------------
    const saveMapping = async () => {

        let reqValues = { ...values }
        reqValues['role'] = role;
        let reqArray = [];

        let productID = product;
        let roleID = product == constants.PRODUCTS.TRADEFINANCE ? constants.ROLES_IDS.TFSUPPLIER : constants.ROLES_IDS.SUPPLIER;
        let roleName = product == constants.PRODUCTS.TRADEFINANCE ? constants.ROLES.TFSUPPLIER : constants.ROLES.SUPPLIER;

        reqArray.push({
            product: productID,
            role: roleID,
            role_name: roleName,
            isChecked: true,
        });

        reqValues.products = reqArray;
        const { data: res } = await createMappingApi.request(reqValues);

        if (res.success === true) {
            toast.success(res.message);
            if(setNewSupplierId){
                setNewSupplierId(res.rows.value)
            }
            setTimeout(() => {
                onCloseModal();
            }, 1500);
        }
        else
            toast.error(res.message);
    }

    const { values, setValues, handleChange, handleSubmit } = useForm(saveMapping, schema);
    useEffect(() => {
        setValues({
            ...values,
            ab_number: searchData.abn,
            organization: searchData.organisation,
            source: source,
            accType: "local",
            disclosure: 1
        });
    }, []);

    const handleCheckboxChange = async (e) => {
        if (e.target.value == 0) {
            const { data } = await generateUndisclosedDetailsApi.request();
            setValues({
                ...values,
                first_name: data.firstname,
                last_name: data.lastname,
                email: data.email,
                disclosure: 0
            });
        } else {
            setValues({
                ...values,
                first_name: "",
                last_name: "",
                email: "",
                disclosure: 1
            });
        }
    }
    return (
        <>
            {(createMappingApi.loading === true || generateUndisclosedDetailsApi.loading === true) && <LoadingSpinner />}
            <div className='card-body'>
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <InputGroup label="Disclosure">
                    <div className="col-lg-8">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                onClick={handleCheckboxChange}
                                checked={values.disclosure == "1" ? true : false}
                                type="radio"
                                id={`customCheckDisclosed`}
                                name="Disclosed"
                                value="1"
                                class="custom-control-input"
                            />
                            <label class="custom-control-label" for={`customCheckDisclosed`}>Disclosed</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input
                                onClick={handleCheckboxChange}
                                checked={values.disclosure == "0" ? true : false}
                                type="radio"
                                id={`customCheckUnDisclosed`}
                                name="Un Disclosed"
                                value="0"
                                class="custom-control-input"
                            />
                            <label class="custom-control-label" for={`customCheckUnDisclosed`}>Undisclosed</label>
                        </div>
                    </div>
                </InputGroup>
                <InputGroup label="Organisation">
                    <TextInput /*readonly={true}*/ value={values.organization} onChange={handleChange} name="organization" placeholder="Organisation" type=""></TextInput>
                </InputGroup>
                {
                    values.disclosure == "0" ? (
                        ""
                    ) : (
                        <>
                            <InputGroup label="First Name">
                                <TextInput name="first_name" value={values.first_name} onChange={handleChange} placeholder="First Name" type="" ></TextInput>
                            </InputGroup>
                            <InputGroup label="Last Name">
                                <TextInput name="last_name" value={values.last_name} onChange={handleChange} placeholder="Last Name" type="" ></TextInput>
                            </InputGroup>
                        </>
                    )
                }
                <InputGroup label="ABN">
                    <TextInput readonly={true} value={values.ab_number} onChange={handleChange} name="ab_number" placeholder="ABN" type=""></TextInput>
                </InputGroup>
                {
                    values.disclosure == "0" ? (
                        ""
                    ) : (
                        <>
                            <InputGroup label="Email">
                                <TextInput value={values.email} onChange={handleChange} name="email" placeholder="Email" type=""></TextInput>
                            </InputGroup>
                        </>
                    )
                }
                <InputGroup label="Phone">
                    <TextInput value={values.phone_no} onChange={handleChange} name="phone_no" placeholder="Phone" type=""></TextInput>
                </InputGroup>
                <div className="row pull-right">
                    <SaveButton className="btn btn-primary mr-3" disabled={createMappingApi.loading} label="Submit" />
                </div>

            </form>
            </div>
        </>
    );
}

export default CreateInstantEntity;