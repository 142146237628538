import React, { Component } from "react";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImageCrop, FilePondPluginImageValidateSize);

class MultipleFileInput extends Component {
  state = {};

  render() {
    return (
      <FilePond
        ref={this.props.ref}
        files={this.props.files}
        allowMultiple={this.props.allowMultiple}
        maxFiles={10}
        onupdatefiles={this.props.onupdatefiles}
        credits={false}
        
        // File type validation
        allowFileTypeValidation={this.props.acceptedFileTypes ? true : false}
        acceptedFileTypes={this.props.acceptedFileTypes ? this.props.acceptedFileTypes : []}
        
        // File size validation
        allowFileSizeValidation={this.props.maxFileSize ? true : false}
        maxFileSize={this.props.maxFileSize ? this.props.maxFileSize : false}

        // Image Size
        allowImageValidateSize={this.props.imageValidateSizeMaxWidth ? true : false}
        imageValidateSizeMaxWidth={this.props.imageValidateSizeMaxWidth ? this.props.imageValidateSizeMaxWidth : null}
        imageValidateSizeMaxHeight={this.props.imageValidateSizeMaxHeight ? this.props.imageValidateSizeMaxHeight : null}

        onerror={this.props.onErrorCaught}       
        beforeRemoveFile={() => true}

        server={{
          load: (source, load, error, progress, abort, headers) => {
            var myRequest = new Request(source);
            fetch(myRequest).then(function(response) {
              response.blob().then(function(myBlob) {
                load(myBlob);
              });
            });
          }
        }}
      ></FilePond>
    );
  }
}

export default MultipleFileInput;
