import React, { useEffect, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { FormGroup } from "../common/containers/formContainer";
import { Picker } from '../common/inputs';

import coreService from "../../services/coreService";
import useApi from '../../hooks/useApi';

function MembersList({ onSelectItem, selectedMember = null, label = null, defaultValue = { label: "Select Member", value: null } }) {

    const [selectedItem, setSelectedItem] = useState(defaultValue);

    const { request, data, loading } = useApi(coreService.MembersList); // Custom hook for HTTP Requests

    const RenderPicker = () =>
        <Picker
            items={data}
            selectedItem={selectedItem}
            onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
        />

    const fetchCountriesList = async () => {
        await request(); // Calling HTTP service
    }

    useEffect(() => {
        fetchCountriesList();
    }, []);

    /**----------------Set selected country based on given Id-----------------*/
    if (selectedMember !== null && selectedItem.value === null && data.length > 0) {
        const member = data.find(obj => obj.value == selectedMember);
        setSelectedItem(member);
    }
    /**----------------------------------------------------------------------*/

    return (
        <>
            {loading == true && <LoadingSpinner />} {/** Activating the loading spinner */}
            {label !== null ?
                <FormGroup
                    label={label}
                    input={<RenderPicker />}
                /> :
                <RenderPicker />
            }
        </>
    )
};
export default MembersList;