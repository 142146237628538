import React, { useEffect, useState, useContext } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import entityService from "../../services/entityService";
import EntityContext from "../../context/entityContext";
import useApi from '../../hooks/useApi';
import { primaryRoles, secondaryRoles } from '../../config/predefinedValues.json'
import ArraySort from "../../utils/ArraySort";
import { RadioButton } from "../common/inputs/radioButton/radioButton";

function ProductsRadioList({ onSelectItem, guid = null, col = 6, defaultProduct = null, label, type = "primary",disableList=null }) {

    const contextValue = useContext(EntityContext);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [roles, setRoles] = useState([]);
    const [products, setProducts] = useState([]);
    const productsApi = useApi(entityService.entityProducts);

    const handleRoleChange = (event, roleIndex, productSelected = "", originalIndex) => {

        let selectedItem = productSelected ? productSelected : selectedProduct;
        let selectedPrdt = [];
        const items = [...products];
        const productIndex = items.findIndex(obj => obj.id == selectedItem);
        items[productIndex]['roles'][originalIndex]['isChecked'] = event.target.checked;
        items[productIndex]['isChecked'] = event.target.checked;

        setSelectedProduct(items[productIndex])
        onSelectItem(items[productIndex]);
    };

    const { request, loading } = useApi(entityService.getEntityCategories); // Custom hook for HTTP Requests

    const fetchData = async (guid) => {
        const { data } = await request(guid);
        setProducts(data.products);
    }

    const fetchProducts = async () => {
        const { data } = await productsApi.request({ entity: guid, contact: "", isPrimaryOnly: false });
        setProducts(data.result);
        if (defaultProduct !== null) {
            setSelectedProduct(defaultProduct);
            //const items = [...contextValue.entityDetails.products];
            setRoles(data.result.find(obj => obj.id === defaultProduct).roles);
        }

    }

    useEffect(() => {
        if (guid === null) {
            fetchData(guid);
        } else {
            if (contextValue.entityDetails !== null && typeof contextValue.entityDetails !== "undefined") {
                if (type == 'secondary')
                    fetchProducts();
                else {
                    const items = [...contextValue.entityDetails.products];
                    setProducts(items);
                    if (defaultProduct !== null) {
                        setSelectedProduct(defaultProduct);
                        const items = [...contextValue.entityDetails.products];
                        setRoles(items.find(obj => obj.id === defaultProduct).roles);
                    }
                }
            }
        }


    }, [guid, contextValue]);

    const roleFilter = (obj, index) => {
        const roles = (type == 'primary') ? primaryRoles : (type == 'secondary' ? secondaryRoles : [...primaryRoles, ...secondaryRoles]);
        if (roles.includes(obj.role_name)) {
            obj.originalIndex = index;
            return obj;
        }
    }

    const checkIsDisabled = (id) => {
        
        if(disableList !== null && disableList.length > 0){
            let returnStatus = "";
            if(disableList.some(item => item == id)){
                returnStatus = true;
            }
            return returnStatus;

        } else {
            return "";
        }
    }


    return (
        <>
            {loading == true && <LoadingSpinner />}
            <div className="   " >
                <h6>{label ? label : "Select Relevant Facilities"}</h6>
            </div>

            {defaultProduct == null && (
                <div className="">
                    {products.map((item, index) => {
                        return (
                            item['roles'].filter(roleFilter).map((itemRole, roleIndex) => (
                                <div key={roleIndex} className="row mt-4 ml-1">
                                    <RadioButton
                                        name="productName"
                                        value={item.product_name}
                                        label={item.product_name}
                                        id={`customCheck${index}`}
                                        checked={selectedProduct && selectedProduct.id === item.id}
                                        disable={checkIsDisabled(item.id)}
                                        onChange={e => handleRoleChange(e, roleIndex, item.id, itemRole.originalIndex)}
                                    />
                                </div>
                            )
                            )
                        )
                    })}
                </div >
            )}
        </>
    )
};
export default ProductsRadioList;
