import React from "react";
import { FormRow, FormColumnLg, InputGroupMd, InputGroupSplit } from "../../../../common/containers/formContainer";
import { TextInput, TextAreaInput } from "../../../../common/inputs/textInput/textInput";
import Picker from "../../../../common/inputs/picker/picker";
import Form from "../../../../common/Form";
import EntityContext from "../../../../../context/entityContext";
import config from "../../../../../services/configService";
class OrgAdditionalInfo extends Form {
  static contextType = EntityContext;

  constructor(props) {
    super(props);
  }

  state = {
    countries: [],
    ajaxRequest: false,
    disabled: false,
  };

  componentDidMount() {
    this.fetchCountriesArray();
  }

  fetchCountriesArray = async () => {
    try {
      const countries = await config.getCountriesArray();
      this.setState({ countries });
    } catch (error) { }
  };

  render() {
    const { countries } = this.state;
    const props = this.props;
    const data = props.data;
    const check = data.is_same_reg_address;
    const role = props.role;
    let addr = "";
    if (role != "CEO") {
      addr = data.reg_address;
      addr += (data.city && '\n' + data.city);
      addr += (data.state && '\n' + data.state);
      addr += (data.zip && '\n' + data.zip);
      addr += (data.country && '\n' + data.country.label);
    }
    return (
      <React.Fragment>
        {role == "CEO" &&
          <>
            <InputGroupMd label="Registered Address">
              <TextAreaInput value={data.reg_address} onChange={props.handleChange} name="reg_address" placeholder="Registered Address" rows="4" type="" ></TextAreaInput>
              <br></br>
              <TextInput value={data.city} onChange={props.handleChange} name="city" placeholder="City" type="" ></TextInput>
              <br></br>
              <FormRow >
                <FormColumnLg col="6">
                  <TextInput value={data.state} onChange={props.handleChange} name="state" placeholder="State" type="" ></TextInput>
                </FormColumnLg>
                <FormColumnLg col="6">
                  <TextInput value={data.zip} onChange={props.handleChange} name="zip" placeholder="Zip" type="" ></TextInput>
                </FormColumnLg>
              </FormRow>
              <br></br>
              <Picker
                items={countries}
                selectedItem={data.country}
                onSelectItem={e => props.handleCountrySelect(e, "country")}
              />
            </InputGroupMd>
            <InputGroupMd label="Postal Address">
              <input
                name="is_same_postal_address"
                className=""
                checked={check == "yes" ? true : false}
                onChange={props.handleCheckboxChange}
                type="checkbox"
              /> Same as Registred address *
            </InputGroupMd>
            {check == "no" &&
              <InputGroupMd label="Postal Address">
                <TextAreaInput value={data.postal_address} onChange={props.handleChange} name="postal_address" placeholder="Postal Address" rows="4" type="" ></TextAreaInput>
                <br></br>
                <TextInput value={data.p_city} onChange={props.handleChange} name="p_city" placeholder="City" type="" ></TextInput>
                <br></br>
                <FormRow >
                  <FormColumnLg col="6">
                    <TextInput value={data.p_state} onChange={props.handleChange} name="p_state" placeholder="State" type="" ></TextInput>
                  </FormColumnLg>
                  <FormColumnLg col="6">
                    <TextInput value={data.p_zip} onChange={props.handleChange} name="p_zip" placeholder="Zip" type="" ></TextInput>
                  </FormColumnLg>
                </FormRow>
                <br></br>
                <Picker
                  items={countries}
                  selectedItem={data.p_country}
                  onSelectItem={e => props.handleCountrySelect(e, "p_country")}
                />
              </InputGroupMd>
            }
            <InputGroupMd mandatory={true} label="Telephone Number">
              <TextInput name="code" value={data.code} onChange={props.handleChange} placeholder="Country Code" type="" ></TextInput>
              <TextInput name="org_telephone_no" reference={props.reference.org_telephone_no} class="form-control mt-3" value={data.org_telephone_no} onChange={props.handleChange} placeholder="Telephone Number" type="" ></TextInput>
            </InputGroupMd>

            <InputGroupMd mandatory={true} label="Generic Email Address">
              <TextInput name="org_email" reference={props.reference.org_email} value={data.org_email} onChange={props.handleChange} label="Generic Email Address" type="" ></TextInput>
            </InputGroupMd>
            <InputGroupMd label="Website">
              <TextInput name="website" value={data.website} onChange={props.handleChange} label="Website" type="" ></TextInput>
            </InputGroupMd>

            <InputGroupMd label="Your Name">
              <TextInput name="contact_first_name" value={data.contact_first_name} onChange={props.handleChange} placeholder="First Name" type="" ></TextInput>
              <TextInput name="contact_last_name" class="form-control mt-3" value={data.contact_last_name} onChange={props.handleChange} placeholder="Last Name" type="" ></TextInput>
            </InputGroupMd>
            <InputGroupMd label="Your Position">
              <TextInput name="contact_position" value={data.contact_position} onChange={props.handleChange} label="Your Position" type="" ></TextInput>
            </InputGroupMd>
            <InputGroupMd label="Your Email Address">
              <TextInput name="contact_email" value={data.contact_email} onChange={props.handleChange} label="Your Email Address" type="" ></TextInput>
            </InputGroupMd>
            <InputGroupMd label="Your Telephone">
              <TextInput name="contact_telephone_no" value={data.contact_telephone_no} onChange={props.handleChange} label="Your Telephone" type="" ></TextInput>
            </InputGroupMd>
            <InputGroupMd label="Your Mobile">
              <TextInput name="contact_mobile" value={data.contact_mobile} onChange={props.handleChange} label="Your Mobile" type="" ></TextInput>
            </InputGroupMd>
          </>
        }
        {role != "CEO" && (
          <>
            <InputGroupMd label="Registered Address">:&ensp;{addr}</InputGroupMd>
            {check == "yes" &&
              <InputGroupMd label="Postal Address">:&ensp;{addr}</InputGroupMd>
            }
            <InputGroupMd label="Telephone Number"><p>:&ensp;{data.code.label} {data.org_telephone_no}</p></InputGroupMd>
            <InputGroupMd label="Generic Email Address"><p>:&ensp;{data.org_email}</p></InputGroupMd>
            <InputGroupMd label="Website"><p>:&ensp;{data.website}</p></InputGroupMd>
            <InputGroupMd label="Your Name">:&ensp;{data.contact_first_name} {data.contact_last_name}</InputGroupMd>
            <InputGroupMd label="Your Position"><p>:&ensp;{data.contact_position}</p></InputGroupMd>
            <InputGroupMd label="Your Email Address"><p>:&ensp;{data.contact_email}</p></InputGroupMd>
            <InputGroupMd label="Your Telephone"><p>:&ensp;{data.contact_telephone_no}</p></InputGroupMd>
            <InputGroupMd label="Your Mobile"><p>:&ensp;{data.contact_mobile}</p></InputGroupMd>
          </>
        )}
      </React.Fragment>
    )
  }
}
OrgAdditionalInfo.contextType = EntityContext;
export default OrgAdditionalInfo;