import React, { useEffect, useState, useContext } from "react";
import useApi from "../../../../hooks/useApi";
import DFTransactionService from "../../../debtorTransactions/services/dfTransactions";
import EntityContext from "../../../../context/entityContext";

import CardSkelton from "../../components/cards/_cardSkelton";
import auth from "../../../../../src/services/authService";
import DoughnutCard from "../general/doughnutCard";

function BatchPurchaseCommon({ nickName, feciltyCount, menu = null, onClick = null, to = "/client/home" }) {

  const { constants } = auth.getCurrentUser();
  const { entityDetails, accountDetails } = useContext(EntityContext);
  const transactionApi = useApi(DFTransactionService.getClientGraph);
  const [limit, setLimit] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [bankDetail, setBankDetail] = useState(null);
  const [facilityDefaults, setFacilityDefaults] = useState(false);

  const fetchData = async () => {

    const { data } = await transactionApi.request({
      userId: entityDetails.a_guid,
      selRole: "Purchase",
    });

    if (data.success == true) {
      const graphValues = [
        {
          name: "Open",
          value: data.rows.open ? data.rows.open : 0,
          icon: "fa-thumbs-up",
          color: "secondary",
          border: "rgb(25, 69, 107)",
          bg: "rgb(25, 69, 107)",
        },
        {
          name: "Disapproved",
          value: data.rows.disapproved ? data.rows.disapproved : 0,
          icon: "fa-folder-open-o",
          color: "danger",
          border: "rgba(237, 85, 101, 1)",
          bg: "rgba(237, 85, 101, 1)",
        },
        {
          name: "Funds Drawn",
          value: data.rows.funds_drawn ? data.rows.funds_drawn : 0,
          icon: "fa-clock-o",
          color: "warning",
          border: "rgba(255, 200, 92, 1)",
          bg: "rgba(255, 200, 92, 1)",
        },
        {
          name: "Availability",
          value: data.rows.undrawn_amount ? data.rows.undrawn_amount : 0,
          icon: "fa-check-square-o",
          color: "info",
          border: "rgba(17, 105, 142, 1)",
          bg: "rgba(17, 105, 142, 1)"
        },
      ];

      setGraphData(graphValues);
      setLimit(data.rows.limit);
    }
  };
  //------------------------------------------------------------
  useEffect(() => {
    fetchData();
  }, []);

  //------------------------------------------------------------
  useEffect(() => {
    const accountInfo = accountDetails.find(item => item.product == constants.PRODUCTS.DEBTORFINANACE);
    setBankDetail(accountInfo ? accountInfo : null);
  }, [accountDetails]);

  useEffect(() => {
    const productSettings = entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.DEBTORFINANACE);
    setFacilityDefaults(productSettings ? productSettings.default_settings_available : false);
}, [entityDetails.productSettings]);

  return (
    <>
      <div className={`col-sm-6 col-lg-4 col-xl-4 align-center card-hover ${(!bankDetail || !facilityDefaults) ? 'disabled-card' : ''}`}>
        {transactionApi.loading === true ? (
          <CardSkelton col="12" type="graph"></CardSkelton>
        ) : graphData && limit ? (
          <>
            <DoughnutCard
              graphData={graphData}
              type={"show_bank_detail"}
              fecilityLimit={limit}
              bgColor="primary"
              empty={false}
              account_type={1}
              feciltyCount={feciltyCount}
              description={"Your Whole of book fecility"}
              menu={menu}
              onClick={onClick}
              to={to}
              bankDetail={bankDetail}
              nickName={nickName}
            ></DoughnutCard>
          </>
        ) : (
          <DoughnutCard
            graphData={[]}
            type={"show_bank_detail"}
            fecilityLimit={0}
            bgColor="primary"
            empty={true}
            account_type={1}
            feciltyCount={feciltyCount}
            description={"Your Whole of book fecility"}
            menu={menu}
            onClick={onClick}
            to={to}
            bankDetail={bankDetail}
            nickName={nickName}
          ></DoughnutCard>
        )}
      </div>
    </>
  );
}
export default BatchPurchaseCommon;
