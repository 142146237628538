import React, { useContext } from "react";
import Launch from "../../../img/icons/Welcome.svg"
import useApi from '../../../hooks/useApi';
import EntityService from '../../../services/entityService';
import LoadingSpinner from '../../common/loaders/loadingSpinner';
import { Link } from "react-router";
function NoAccess() {

    const activateInvFinanceApi = useApi(EntityService.activateInvFinance);

    return (
        <>
            <div className="white-card ">
                <div className="row min-vh-70 ">
                    <div style={{ zIndex: 1, position: "absolute", border: 1 }}>
                        {activateInvFinanceApi.loading && <LoadingSpinner />}
                    </div>
                    <div className="col-lg-6 p-5 text-center">
                        <img height={500} src={Launch} />
                    </div>
                    <div className="col-lg-6 align-items-center my-auto">
                        <div className="row">
                            <h4>Welcome to Fifopay{/*props.heading ? props.heading : "Invoice Finance Transactions"*/}</h4>

                        </div>
                        <div className="row">
                            <p>You have no facilities yet. {/*props.subHeading ? props.subHeading : "Sorry, you are not subscribed to use this fecility."*/}</p>
                        </div>
                        <div className="row mt-4">
                            <div className="">
                                <Link to="client/finance-business"> <button className="btn btn-primary pull-right align-items-end">Let's get started</button></Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default NoAccess;