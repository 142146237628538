import React from 'react';

export default function InitialLoader() {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mh-3">
                        <div className="card__title loading" />
                        <div className="card__description loading" />
                    </div>
                </div>
            </div>
        </>
    )
}