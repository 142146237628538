import client from "./serviceClient";
import _ from "lodash";

// Service to create a new invoice transaction
const createInvTransaction = (params) =>
  client.post(`/invtransactions/transactions/create-transaction`, {
    ...params,
  });

// Service to upload invoice details
const createInvoice = (params) =>
  client.post(`/invtransactions/transactions/create-invoices`, { ...params });

// Service to generate new system id for the transaction
const generateRefNum = (params) =>
  client.post(`/invtransactions/transactions/get-reference-number`, {
    ...params,
  });

// Service to create PA Funding
const createPaFunding = (params) =>
  client.post(`/invtransactions/transactions/create-pa-funding`, { ...params });

// Service to fetch Transaction details by guid
const getTransactionDetails = (params) =>
  client.post(`/invtransactions/transactions/get-transaction-details`, {
    ...params,
  });

// Service to fetch the list of invoices
const getInvoiceList = async (params, status) => {
  let response = await client.post(`/invtransactions/transactions/get-invoice-list?XDEBUG_SESSION_START=PHPSTORM`, { ...params, status: status === 'all' ? null : status });

  // Applying filteration (Removing 'Awaiting Disbursement' tab from the tabs list)
  if(params.download)
    return response;
  else{
    let filteredTabs = response.data.tabs.filter(obj => obj.value !== 'awaiting')
    response.data.tabs = filteredTabs;

    // Parsing monetary values from String -> Number
    response.data.rows = response.data.rows.map(item => {
      item.inv_amount = parseFloat(item.inv_amount);
      item.inv_chargeable_amt = parseFloat(item.inv_chargeable_amt);
      item.disb_amount = parseFloat(item.disb_amount);
      item.total_fee = parseFloat(item.total_fee);
      item.total_paid = parseFloat(item.total_paid);
      item.total_due = parseFloat(item.total_due);
      item.system_reference = item.system_reference ? `${item.client_reference}/${item.system_reference}` : null;
      item.client_reference = item.client_reference ? item.client_reference : null;
      return item;
    })
    return response;
  }
}
  
// Service to create payment info
const createPayments = (params) =>
  client.post(`/invtransactions/transactions/create-payment`, { ...params });

// Service to settle client payment requests
const settleClientPaymentRequest = (params) =>
  client.post(`/invtransactions/transactions/settle-client-payment-requests`, { ...params });

// Service to close selected invoices
const closeInvoices = (params) =>
  client.post(`/invtransactions/transactions/close-invoice`, { ...params });

// Service to delete selected invoices
const deleteInvoices = (params) =>
  client.post(`/invtransactions/transactions/delete-invoices`, { ...params });

// Service to fetch payment details
const fetchPayments = (params) =>
  client.post(`/invtransactions/transactions/get-payment-details`, {
    ...params,
  });

// Service to update a payment details
const updatePayment = (params) =>
  client.post(`/invtransactions/transactions/update-payment`, { ...params });

// Service to fetch the Transaction report
const fetchTransReport = (params) =>
  client.post(`/invtransactions/reports/get-transactions-report`, {
    ...params,
  });

// Service to fetch the Residual report
const fetchResidualReport = (params) =>
client.post(`/invtransactions/reports/get-residual-report`, {
  ...params,
});

// Service to fetch the Residual report
const fetchClientPaymentsReport = (params) =>
client.post(`/invtransactions/reports/get-client-payments-report`, {
  ...params,
});

// Service to fetch the Point in time report
  const fetchPointTimeReport = async(params) => {
    let response = await client.post(`/invtransactions/reports/point-time-report`, {
      ...params,
    });

    if(params.type)
      return response;

    // Finding Total amount paid as well Total Owing
    response.data.rows = response.data.rows.map(obj => {
      let agreementAmt = obj.invoices.inv_chargeable_amt;
      let totalPaid = _.sumBy(obj.payment, (item) => Number(item.amount_paid));
      obj.total_paid = totalPaid;
      obj.total_owing = agreementAmt - totalPaid;
      return obj;
    })

    // Grouping data by Client Organisation
    let result = response.data.rows.reduce(function (r, a) {
        r[a.client_organisation] = r[a.client_organisation] || [];
        r[a.client_organisation].push(a);
        return r;
    }, Object.create(null));

    response.data.groupeddata = result;
    
    return response;
  }

  // Service to fetch the Aged receivables report
  const fetchAgedReceivablesReport = (params) =>
  client.post(`/invtransactions/reports/aged-receivables-report`, {
    ...params,
  });


// Service to fetch default fee details
const getFeeDetails = (params) =>
  client.post(`/invtransactions/transactions/get-fee-details`, { ...params });

// Service to fetch fee details by Transaction Id
const GetFeeByTransId = params => client.post(`/invtransactions/transactions/get-fee-by-trans-id`, { ...params});

// Quick transaction search
const quickSearch = (params) =>
  client.post(`/invtransactions/transactions/quick-trans-search`, {
    ...params,
    selectedTab: params.selectedTab === 'all' ? null : params.selectedTab
  });
// Get discount fee schedule details
const getFeeSchedules = (params) =>
  client.post(`/invtransactions/transactions/get-disc-fee-schedules`, {
    ...params,
  });

// Service to fetch invoice comments
const getInvoiceComments = (params) =>
  client.post(`/invtransactions/transactions/fetch-invoice-comments`, {
    ...params,
  });

// Service to save invoice comments
const saveInvoiceComment = (params) =>
  client.post(`/invtransactions/transactions/save-invoice-comment`, {
    ...params,
  });

// -------------- Transaction Widgets -------------- //

// Transactions Availability
const getOpenTransactions = (params) =>
  client.post(`/invtransactions/widgets/open-transactions`, { ...params });

const getPendingTransactions = (params) =>
  client.post(`/invtransactions/widgets/pending-invoices`, { ...params });

const getApprovedScheduled = (params) =>
  client.post(`/invtransactions/widgets/approved-and-scheduled-invoices`, { ...params });

const getRejectedInvoices = (params) =>
  client.post(`/invtransactions/widgets/rejected-invoices`, { ...params });

// Due this month
const getDueThisMonth = (params) =>
  client.post(`/invtransactions/widgets/due-this-month`, { ...params });

// Overdue this month
const getOverdueThisMonth = (params) =>
  client.post(`/invtransactions/widgets/over-due-this-month`, { ...params });

  // Approved invoices
const approvedInvoices = (params) =>
  client.post(`/invtransactions/widgets/approved-invoices`, { ...params });
  

// Disbursements Graph
const getDisbursementsGraph = (params) =>
  client.post(`/invtransactions/widgets/disbursements-graph`, { ...params });

// Service to add disbursement to invoices
const createDisbursement = (params) =>
  client.post(`/invtransactions/transactions/create-disbursement`, {
    ...params,
  });

// Service to upload invoice details
const createSingleInvoice = (params) =>
  client.post(`/invtransactions/transactions/create-single-invoices`, {
    ...params,
  });

// Service to add disbursement to invoices
const createSingleInvoiceDisbursement = (params) =>
  client.post(
    `/invtransactions/transactions/create-single-invoice-disbursement`,
    { ...params }
  );

// Service to fetch data on create transaction
const disburseOnLoadData = (params) =>
  client.post(`/invtransactions/transactions/disburse-on-load-data`, {
    ...params,
  });

// Service to get disbursements&info in transaction
const getDisbursementDetail = (params) =>
  client.post(`/invtransactions/transactions/get-trans-disbursement-detail`, {
    ...params,
  });

// Service to create disbursemenmts from open invoices in transaction
const createOpenDisbursements = (params) =>
  client.post(`/invtransactions/transactions/create-open-disbursement`, {
    ...params,
  });

// Service to get participant details
const getParticipantDetails = async (params) => {
  let response = await client.post(`/invtransactions/transactions/get-participant-details`, { ...params });
  // Parsing monetary values from String -> Number
  response.data.rows = response.data.rows.map(item => {
    item.edit = false;
    return item;
  })
  return response;
}
  
// Service to get client graph
const getClientGraph = (params) =>
  client.post(`/invtransactions/transactions/get-client-graph`, { ...params });

// Service to get open transaction for payments
const getTransactionByDebtor = (params) =>
  client.post(`/invtransactions/transactions/get-invoices-by-debtor`, {
    ...params,
  });

// Service to change the status of an invoice
const updateInvoiceStatus = (params) =>
client.post(`/invtransactions/transactions/update-invoice-status`, {
  ...params,
});

// Service to fetch list of IF Templates
const fetchTemplatesList = () =>
  client.post(`/core/doc-template-group/fetch-if-templates-list`);

// Service to fetch list of IF Templates
const fetchTemplateDetails = (params) =>
  client.post(`/core/doc-template-group/get-template-details`, {
    ...params
  });

// Service to Send email template
const sendMailTemplate = (params) =>
  client.post(`/core/doc-template-group/send-document-template`, {
    ...params
  });

// Service to fetch transaction statement
const fetchTransactionStatement = (params) =>
  client.post(`/invtransactions/reports/client-statement`, {
    ...params
  });

// Service to fetch bp billing report
  const fetchBpBillingReport = (params) =>
  client.post(`/invtransactions/reports/bp-billing-report`, {
    ...params
  });

  // Service to fetch summary of the Transactions of a client
  const getClientTransactionSummary = (params) =>
  client.post(`/invtransactions/widgets/get-client-transaction-summary`, {
    ...params
  });

  // Service to view attachments on Clienr portal
  const viewInvoiceAttachment = (params) =>
  client.post(`/invtransactions/transactions/view-attachment`, {
    ...params
  });

  // Service to fetch list of residual amt of the selected invoices by closed date
  const getResidualAmountByClosedDate = (params) =>
  client.post(`/invtransactions/transactions/get-residual-amt-by-closed-date`, {
    ...params
  });

  // Service to delete selected invoice payment
  const deleteInvoicePayment = (params) =>
  client.post(`/invtransactions/transactions/delete-payment`, {
    ...params
  });

  // Service to fetch card information for client portal
  const facilityCardInfo = params => 
    client.post(`/invtransactions/widgets/get-client-facility-card-info`, {
      ...params
    });

  // Service to Send email template
  const fetchResidualLetter = (params) =>
  client.post(`/invtransactions/transactions/fetch-residual-payment-letter`, {
    ...params
  });

  // Service to fetch bp billing report
  const fetchAuditReport = (params) =>
  client.post(`/invtransactions/reports/audit-report`, {
    ...params
  });

  // Service to fetch bp billing report
  const consolidatedReport = (params) =>
  client.post(`/supplychain/reports/get-consolidated-report`, {
    ...params
  });

  const participantInvoices = (params) =>
    client.post(`/invtransactions/transactions/get-participant-invoices`, {
      ...params
    });
  

export default {
  createInvTransaction,
  createInvoice,
  generateRefNum,
  createPaFunding,
  getTransactionDetails,
  getInvoiceList,
  createPayments,
  settleClientPaymentRequest,
  closeInvoices,
  deleteInvoices,
  fetchPayments,
  getOpenTransactions,
  getPendingTransactions,
  getApprovedScheduled,
  getRejectedInvoices,
  getDueThisMonth,
  getOverdueThisMonth,
  approvedInvoices,
  fetchTransReport,
  fetchResidualReport,
  fetchClientPaymentsReport,
  getFeeDetails,
  GetFeeByTransId,
  quickSearch,
  getFeeSchedules,
  getDisbursementsGraph,
  createDisbursement,
  createSingleInvoice,
  createSingleInvoiceDisbursement,
  getDisbursementDetail,
  createOpenDisbursements,
  getParticipantDetails,
  updatePayment,
  getClientGraph,
  getTransactionByDebtor,
  getInvoiceComments,
  saveInvoiceComment,
  disburseOnLoadData,
  updateInvoiceStatus,
  fetchTemplatesList,
  fetchTemplateDetails,
  sendMailTemplate,
  fetchPointTimeReport,
  fetchAgedReceivablesReport,
  fetchTransactionStatement,
  fetchBpBillingReport,
  getClientTransactionSummary,
  viewInvoiceAttachment,
  getResidualAmountByClosedDate,
  deleteInvoicePayment,
  facilityCardInfo,
  fetchResidualLetter,
  fetchAuditReport,
  consolidatedReport,
  participantInvoices
};
