import React, { useState, useContext, useEffect } from "react";
import Calculator from "../../../../../img/icons/calculator.svg";
import { RadioButton } from "../../../../common/inputs/radioButton/radioButton";
import { TextInput, Picker, CurrencyText } from "../../../../common/inputs";
import { DateTimePicker } from "../../../../common/inputs/datePicker";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";
import ReactTooltip from "react-tooltip";

export default function DiscountRates(props) {
  const {
    handleChange,
    handlePickerChange,
    validateDiscountRate,
    data,
    viewShow,
    editShow,
    setData,
    handleDateChange,
    handleLiveDateChange,
    selectedFundedBy,
    selectedDiscountRate,
    handleInput,
    handleLimitChange,
    defaultRatearray,
    validateRate,
    validateLimit
  } = useContext(SupplierProgramContext);
  useEffect(() => {
    validateDiscountRate();
  }, [handleChange, handlePickerChange]);

  useEffect(() => {
    if (editShow) {
      const updatedData = { ...data, update: true };
      setData(updatedData);
    } else {
    }
  }, [editShow]);

  const parsedDate = data?.commencement_date
    ? new Date(data.commencement_date)
    : null;

  const parsedLiveDate = data?.program_date
    ? new Date(data.program_date)
    : null;

  const handleSelect = (e) => {
    if (e.target.value) {
      const updatedData = { ...data };
      updatedData["minimum_credit_score"] = e.target.value;
      setData(updatedData);
    }
  };
  return (
    <div>
      <div className="row">
        <div className="form-group required col-lg-4">
          <label className="control-label" style={{ fontWeight: "bold" }}>
            Program Name
          </label>
          <TextInput
            name="program_name"
            value={data.program_name}
            placeholder="Program name"
            onChange={handleChange}
            onBlur={validateDiscountRate}
            type="normal"
            class="form-control"
            readonly={viewShow === true}
          ></TextInput>
        </div>
        <div className="form-group required col-lg-4">
          <label className="control-label" style={{ fontWeight: "bold" }}>
            Commencement Date &nbsp;
            <i
              className="fa fa-info-circle text-secondary mr-1"
              data-tip="Select the commencement date"
              data-for="pay-info"
            ></i>
            <ReactTooltip id="pay-info" effect="solid" place="top" />
          </label>
          <div>
            {" "}
            <DateTimePicker
              name="commencement_date"
              type="normal"
              onChange={(date) => handleDateChange(date)}
              onBlur={validateDiscountRate}
              selected={parsedDate}
              format="dd/MM/y"
              readOnly={viewShow === true}
              enableWeekDays={true}
            ></DateTimePicker>
          </div>
        </div>
        <div className="form-group required col-lg-4">
          <label className="control-label" style={{ fontWeight: "bold" }}>
            Launch Date &nbsp;
            <i
              className="fa fa-info-circle text-secondary mr-1"
              data-tip="Select the launch date"
              data-for="pay-info"
            ></i>
            <ReactTooltip id="pay-info" effect="solid" place="top" />
          </label>
          <DateTimePicker
            name="program_date"
            type="normal"
            onChange={(date) => handleLiveDateChange(date)}
            onBlur={validateDiscountRate}
            selected={parsedLiveDate}
            format="dd/MM/y"
            readOnly={viewShow === true}
            enableWeekDays={true}
          ></DateTimePicker>
        </div>
      </div>

      <div className="row mt-4">
        <div className="form-group required col-lg-4 mt-2">
          <label className="control-label" style={{ fontWeight: "bold" }}>
            Select Funding Type
          </label>
          <Picker
            items={[
              { label: "Self Funding", value: "myself", name: "funded_by" },
              { label: "Fifo", value: "fifo", name: "funded_by" },
            ]}
            selectedItem={
              data?.funded_by
                ? {
                    label: data?.funded_by === "myself" ? "Self Funding" : "Fifo",
                    value: data?.funded_by,
                    name: "funded_by",
                  }
                : selectedFundedBy
            }
            onSelectItem={(option) => handlePickerChange(option)}
            onBlur={() => {
              validateDiscountRate();
              validateRate();
              validateLimit();
            }}
            isDisabled={viewShow === true}
          />
        </div>
        <div className="form-group required col-lg-4 mt-2">
          <label className="control-label" style={{ fontWeight: "bold" }}>
            Choose Discount Rate
          </label>
          <Picker
            items={[
              {
                label: "Flexible",
                value: "flexible",
                name: "discount_rate_type",
              },
              {
                label: "Dynamic",
                value: "dynamic",
                name: "discount_rate_type",
              },
              { label: "Fixed", value: "fixed", name: "discount_rate_type" },
            ]}
            selectedItem={
              data?.discount_rate_type
                ? {
                    label:
                      data?.discount_rate_type === "flexible"
                        ? "Flexible"
                        : data?.discount_rate_type === "dynamic"
                        ? "Dynamic"
                        : "Fixed",
                    value: data?.discount_rate_type,
                    name: "discount_rate_type",
                  }
                : selectedDiscountRate
            }
            onSelectItem={(option) => handlePickerChange(option)}
            onBlur={validateDiscountRate}
            isDisabled={viewShow === true}
          />
        </div>
        <div className="form-group required col-lg-4 mt-2">
          <label className="control-label" style={{ fontWeight: "bold" }}>
            Dicount Rate
          </label>
          <TextInput
            value={data.discount_rate}
            onChange={handleChange}
            onBlur={() => {
              validateDiscountRate();
              validateRate();
            }}
            placeholder="Enter dicount rate"
            name="discount_rate"
            type="normal"
            readonly={viewShow === true}
          ></TextInput>
        </div>
      </div>

      <div className="row mt-4">
        <div className="form-group required col-lg-4 mt-2">
          <label className="control-label" style={{ fontWeight: "bold" }}>
            Minimum Credit Score
          </label>
          <TextInput
            value={data?.minimum_credit_score}
            onChange={handleChange}
            onSelect={handleSelect}
            onBlur={validateDiscountRate}
            //onInput={handleInput}
            placeholder="Credit Score"
            name="minimum_credit_score"
            type="normal"
            readonly={viewShow === true}
          ></TextInput>
        </div>
        <div className="form-group required col-lg-4 mt-2">
          <label className="control-label" style={{ fontWeight: "bold" }}>
            Set Program Limit
          </label>
          <CurrencyText
            placeholder="Program Limit"
            name="program_limit"
            isDisabled={viewShow === true}
            onChangeEvent={(event, maskedvalue, floatvalue) => {
              handleLimitChange(event, "limit", floatvalue);
            }}
            onBlur={validateLimit}
            value={
              defaultRatearray?.primary1Account === null &&
              data.funded_by === "fifo"
                ? 0
                : data?.program_limit
            }
          />
        </div>
        {(data.discount_rate_type === "dynamic" ||
          data.discount_rate_type === "fixed") && (
          <div className="form-group required col-lg-4">
            <label className="control-label" style={{ fontWeight: "bold" }}>
              No of days
            </label>
            <div>
              {" "}
              <TextInput
                value={data?.no_of_days}
                onChange={handleChange}
                onBlur={validateDiscountRate}
                placeholder="No Of Days"
                name="no_of_days"
                type="normal"
                readonly={viewShow === true}
              ></TextInput>
            </div>
          </div>
        )}
      </div>
      <div className="row mt-4">
        {(data.discount_rate_type === "dynamic" ||
          data.discount_rate_type === "fixed") && (
          <div className="form-group required col-lg-4">
            <label className="control-label" style={{ fontWeight: "bold" }}>
              Discount percentage
            </label>
            <div>
              {" "}
              <TextInput
                value={data?.discount_percentage}
                onChange={handleChange}
                onBlur={validateDiscountRate}
                placeholder="Discount Percentage"
                name="discount_percentage"
                type="normal"
                readonly={viewShow === true}
              ></TextInput>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
