import React, { Component } from "react";
import * as moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router";
import { GeneralButton, CurrencyLabel, DateTimePicker } from "../../../common/inputs";
import Picker from "../../../common/inputs/picker/picker";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import Modal from "react-bootstrap/Modal";
import entityService from "../../../../services/entityService";
import coreService from "../../../../services/coreService";
import auth from "../../../../services/authService";
import CommonService from "../../../supply-chain/services/commonService";
import EntityContext from "../../../../context/entityContext";
import ReactTooltip from 'react-tooltip';
import { AddButton } from "../../../common/containers/button";
import Confirmation from "../../../supply-chain/components/transactions/customerUpload/_confirmation";
import TransactionService from "../../../supply-chain/services/transaction_service";
import Utility from "../../../supply-chain/utils/DateParser";
class AssignInvoice extends Component {
    static contextType = EntityContext;
    constructor(props) {
        super(props);
        this.confirmationRef = React.createRef();
        this.state = {
            loading: false,
            selectedProduct: {
                label: "Select Facility",
                value: "",
            },
            isSubmitDisabled: true,
            constants: [],
            enabledProducts: [],
            revisedDueDate: "",
            selectedInvoice: [],
            confirmation: false,
            feeLoader: false,
            revisedReadOnly: true,
        }
    }

    componentDidMount() {
        const { constants } = auth.getCurrentUser();
        this.setState({ constants: constants, selectedInvoice: this.props.selectedInvoice });
        this.checkIsProductEnabled();
    }

    checkIsProductEnabled = () => {
        let enabledProducts = [];
        const { constants } = auth.getCurrentUser();
        if (this.context.productTypes !== undefined && this.context.productTypes.length > 0) {

            const scfProductSettings = this.context.entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.SUPPLYCHAIN);
            const tfProductSettings = this.context.entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.TRADEFINANCE);
            const ifProductSettings = this.context.entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.INVOICE);
            const wobProductSettings = this.context.entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.DEBTORFINANACE);

            if (this.props.userType == 'addAsSupplier') {
                this.context.productTypes
                    .filter(obj => (obj.name == 'Supply Chain Finance' || obj.name == 'Trade Finance'))
                    .map((item) => {
                        enabledProducts.push({
                            label: item.name,
                            value: item.value,
                            disabled: item.name == 'Supply Chain Finance' ?
                                !scfProductSettings.default_settings_available : !tfProductSettings.default_settings_available
                        })
                    });
            } else {
                this.context.productTypes
                    .filter(obj => (obj.name == 'Invoice Finance' || obj.name == 'Debtor Finance Transactions'))
                    .map((item) => {
                        enabledProducts.push({
                            label: item.name,
                            value: item.value,
                            disabled: item.name == 'Invoice Finance' ?
                                !ifProductSettings.default_settings_available : !wobProductSettings.default_settings_available
                        })
                    });
            }
            this.setState({ enabledProducts: enabledProducts });
        } else {
            this.setState({ enabledProducts: [] });
        }
    }

    findMaxRevisedDueDate = async(invoices) => {
        const response = await TransactionService.findMaxRevisedDueDateBulkInvoices({ 
            invoices: invoices.map(item => {
                item.customer = item.trans_supplier_guid;
                item.supplier = item.trans_customer_guid;
                return item;
            }), 
            product: this.state.selectedProduct.value,
            selected_revised_date_date: this.state.revisedDueDate
        });

        this.setState({ 
            selectedInvoice: response.data.invoices.map(item => {
                item.inv_revised_due_date = item.max_revised_date_crossed_check ? this.state.revisedDueDate : item.max_revised_date.maxDate
                return item;
            }),
            isSubmitDisabled: response.data.invoices.some(item => item.max_revised_date_crossed_check === false) ? true : false
        })
    }

    handleProductChange = async (selectedItem) => {
        this.setState({ loading: true });
        let idList = this.props.selectedInvoice.map(function (item) {
            return {
                id: item.id,
                customer: item.trans_customer_guid,
                supplier: item.trans_supplier_guid
            };
        });
        let submitBtnDisable = false;

        const productResponse = await entityService.GetEnabledProductList({
            entity: idList,
            product: selectedItem.value
        });

        let selectedInvoice = [...this.props.selectedInvoice];

        productResponse.data.response.map((item) => {

            selectedInvoice = selectedInvoice.map((obj) => {
                if (obj.id == item.id) {
                    if (item.error === true) {
                        toast.error(item.product);
                        submitBtnDisable = true;
                        obj.error = item.product;
                        obj.hasProductEnabled = item.hasOwnProperty('product_enabled') ? item.product_enabled : false;
                    } else {
                        obj.hasProductEnabled = true;
                        obj.error = "";
                    }

                }
                return obj;

            });

        });

        let errorCount = productResponse.data.response.filter(itm => itm.error == true).length;

        this.setState({
            selectedInvoice: selectedInvoice,
            selectedProduct: {
                label: selectedItem.label,
                value: selectedItem.value
            },
            isSubmitDisabled: submitBtnDisable,
            loading: false,
            revisedReadOnly: errorCount > 0 ? true : false,
        });

        if(this.state.revisedDueDate)
            this.findMaxRevisedDueDate(selectedInvoice);
    }

    handleMoveTransaction = async () => {
        this.setState({ loading: true, isSubmitDisabled: true });
        let invArr = [];
        let flag = true;
        let link = "";
        let assignedFacility = '';
        let product = "";
        if (this.props.userType == 'addAsSupplier') {
            const confirmationRefState = this.confirmationRef.current;
            const errors = confirmationRefState.validate();
            if (errors) {
                this.setState({ loading: false, isSubmitDisabled: false });
                return false;
            }
        }

        if (this.state.selectedProduct.value == this.state.constants.PRODUCTS.INVOICE) {

            this.props.selectedInvoice.map((item) => {

                let attachment = [];
                if (item.attachment) {
                    let attachmentDetail = item.attachment.split('/');
                    let attachmentName = attachmentDetail.slice(-1).pop();
                    attachment = {
                        name: attachmentName,
                        filePath: item.attachment
                    };
                }
                invArr.push({
                    trans_id: item.trans_guid,
                    invoice: {
                        debtor_name: item.organization,
                        disburse_now: false,
                        files: attachment,
                        franchiseFunds: null,
                        inv_adv_amount: ((80 / 100) * item.inv_amount),
                        inv_adv_percent: "80.00",
                        inv_advance_date: null,
                        inv_amount: item.inv_amount,
                        inv_chargeable_amt: item.inv_amount,
                        inv_date: item.trans_date,
                        inv_debtor_id: item.trans_customer_guid,
                        inv_due_date: item.inv_due_date,
                        inv_insurance_percent: "0.0000",
                        inv_insured: "no",
                        inv_number: item.inv_number,
                        inv_royalty_percent: null,
                        pa: { label: "No", value: "no" },
                        paFunds: null,
                        trans_client_guid: item.trans_supplier_guid,
                        trans_owner: item.owner
                    }
                })
            })

            link = '/client/if/sale-invoices?status=draft';
            product = this.context.entityDetails.products.filter(itm => itm.id == this.state.constants.PRODUCTS.INVOICE);
            assignedFacility = product.length > 0 ? product[0].product_name : "";

        } else if (this.state.selectedProduct.value == this.state.constants.PRODUCTS.SUPPLYCHAIN) {
            this.state.selectedInvoice.map((item) => {

                if (item.attachment == null || item.attachment == "") {
                    flag = false;
                    toast.error("Please upload invoice file of" + item.inv_number + " to continue.")
                    this.setState({ loading: false });
                } else {
                    let attachmentDetail = item.attachment.split('/');
                    let attachmentName = attachmentDetail.slice(-1).pop();
                    invArr.push({
                        trans_id: item.trans_guid,
                        invoice: {
                            trans_guid: "",
                            supplier: item.trans_customer_guid,
                            customer: item.trans_supplier_guid,
                            trans_date: item.trans_date,
                            amount: item.inv_amount,
                            inv_due_date: item.inv_due_date,
                            pay_supplier_date: null,
                            invoice_number: item.inv_number,
                            purchase_order_no: item.inv_purchase_order_number,
                            //inv_revised_due_date: this.state.revisedDueDate,
                            inv_revised_due_date: item.inv_revised_due_date,
                            errors: {},
                            fee: "",
                            disabled: false,
                            confirmation: false,
                            rate_guid: "",
                            revisedLoading: false,
                            extension_field: '',
                            currencyStatus: "inactive",
                            currency: "USD",
                            exchangeRate: "",
                            fcyAmount: "",
                            pay_supplier: false,
                            product_type: this.state.constants.PRODUCTS.SUPPLYCHAIN,
                            userType: item.initiated_user_type,
                            document_name: attachmentName,
                            fileslist: item.attachment
                        }
                    })

                }

            })

            link = '/client/scf/purchase-invoices';
            product = this.context.entityDetails.products.filter(itm => itm.id == this.state.constants.PRODUCTS.SUPPLYCHAIN);
            assignedFacility = product.length > 0 ? product[0].product_name : "";

        } else if (this.state.selectedProduct.value == this.state.constants.PRODUCTS.TRADEFINANCE) {
            this.state.selectedInvoice.map((item) => {

                if (item.attachment == null || item.attachment == "") {
                    flag = false;
                    toast.error("Please upload invoice file of" + item.inv_number + " to continue.")
                    this.setState({ loading: false });
                } else {
                    let attachmentDetail = item.attachment.split('/');
                    let attachmentName = attachmentDetail.slice(-1).pop();
                    invArr.push({
                        trans_id: item.trans_guid,
                        invoice: {
                            trans_guid: "",
                            supplier: item.trans_customer_guid,
                            customer: item.trans_supplier_guid,
                            trans_date: item.trans_date,
                            amount: item.inv_amount,
                            inv_due_date: item.inv_due_date,
                            pay_supplier_date: null,
                            invoice_number: item.inv_number,
                            purchase_order_no: item.inv_purchase_order_number,
                            //inv_revised_due_date: this.state.revisedDueDate,
                            inv_revised_due_date: item.inv_revised_due_date,
                            errors: {},
                            fee: "",
                            disabled: false,
                            confirmation: false,
                            rate_guid: "",
                            revisedLoading: false,
                            extension_field: '',
                            currencyStatus: "inactive",
                            currency: "USD",
                            exchangeRate: "",
                            fcyAmount: "",
                            pay_supplier: false,
                            product_type: this.state.constants.PRODUCTS.TRADEFINANCE,
                            userType: item.initiated_user_type,
                            document_name: attachmentName,
                            fileslist: item.attachment
                        }
                    })
                }

            })

            link = '/client/tf/purchase-invoices';
            product = this.context.entityDetails.products.filter(itm => itm.id == this.state.constants.PRODUCTS.TRADEFINANCE);
            assignedFacility = product.length > 0 ? product[0].product_name : "";

        } else if (this.state.selectedProduct.value == this.state.constants.PRODUCTS.DEBTORFINANACE) {
            let invTrans = [];
            this.props.selectedInvoice.map((item) => {

                invTrans.push({
                    trans_id: item.trans_guid,
                    inv_amount: item.inv_amount,
                    inv_chargeable_amt: item.inv_amount,
                    inv_date: item.trans_date,
                    inv_debtor_id: item.trans_customer_guid,
                    inv_due_date: item.inv_due_date,
                    inv_number: item.inv_number,
                    trans_client_guid: item.trans_supplier_guid,
                    trans_owner: null,
                });
            });

            invArr.push({
                client_invoice_upload: true,
                bp: null,
                trans_client_guid: this.props.selectedInvoice[0].trans_supplier_guid,
                invoices: invTrans
            });

            link = '/client/wob/sale-invoices';
            product = this.context.entityDetails.products.filter(itm => itm.id == this.state.constants.PRODUCTS.DEBTORFINANACE);
            assignedFacility = product.length > 0 ? product[0].product_name : "";
        }

        if (flag) {
            const response = await coreService.moveGeneralTransaction({
                invoices: invArr,
                selectedProduct: this.state.selectedProduct.value
            });

            this.setState({ loading: false, isSubmitDisabled: false });
            if (response.data.success == true) {
                toast.success(response.data.message);
                this.props.handleRefreshCard();
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-delete-ui">
                                <h5>Your Invoices have been assigned</h5>
                                <p>Invoices where assigned to {assignedFacility}</p>
                                <button
                                    className="btn btn-white"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Close
                                </button>
                                <Link
                                    to="/client/finance-business"
                                >
                                    <a
                                        href={link}
                                        className="btn btn-primary ml-2"
                                    >
                                        {"View Invoice"}
                                    </a>
                                </Link>
                            </div>
                        );
                    },
                    //title: "Assign Facility",
                    message: `Invoices have been assigned`,
                });

            } else {
                this.setState({ confirmation: false });
                let responseinv = [];
                if (this.state.selectedProduct.value == this.state.constants.PRODUCTS.DEBTORFINANACE) {
                    const selectedInvoice = this.state.selectedInvoice.map(inv => {

                        responseinv = response.data.invoiceList.filter(respInv => respInv.trans_id == inv.trans_guid);
                        
                        if (responseinv[0].error) {
                            inv['error'] = responseinv[0].errorMsg;
                        } else {
                            inv['error'] = '';
                            
                        }
                        // if (responseinv[0].success) {
                        //     inv['success'] = responseinv[0].success;
                        // } else {
                        //     inv['success'] = "";
                        // }

                        return inv;
                    });
                    this.setState({ selectedInvoice: selectedInvoice });
                } else {
                    const selectedInvoice = this.state.selectedInvoice.map(inv => {

                        responseinv = response.data.invoiceList.filter(respInv => respInv.trans_id == inv.trans_guid);
                        
                        if (responseinv[0].invoice.error) {
                            inv['error'] = responseinv[0].invoice.error;
                        } else {
                            inv['error'] = '';
                        }
                        if (responseinv[0].invoice.success) {
                            inv['success'] = responseinv[0].invoice.success;
                        } else {
                            inv['success'] = "";
                        }

                        return inv;
                    });
                    this.setState({ selectedInvoice: selectedInvoice });
                }


                toast.error(response.data.message)

            }
        }
    }


    handleCheckInvoiceNumber = async () => {
        let invNumberList = [];
        this.setState({ loading: true });
        if (this.state.selectedProduct.value == this.state.constants.PRODUCTS.SUPPLYCHAIN || this.state.selectedProduct.value == this.state.constants.PRODUCTS.TRADEFINANCE) {
            this.props.selectedInvoice.map((item) => {
                invNumberList.push(item.inv_number);
            });

            const { data } = await CommonService.checkDuplicateInvNumber({ "inv_numbers": invNumberList, product_type: this.state.selectedProduct.value });
            if (data.success) {

                let selectedInvoice = [...this.props.selectedInvoice];

                selectedInvoice = selectedInvoice.map((obj) => {
                    if (data.result.includes(obj.inv_number)) {
                        obj.error = "Invoice Number has already exist in the System!";
                    } else {
                        obj.error = "";
                    }
                    return obj;

                });

                this.setState({
                    selectedInvoice: selectedInvoice,
                    loading: false
                });
            } else {
                this.setState({ confirmation: true });
            }
        } else {
            this.setState({ confirmation: true });
        }
        this.setState({ loading: false });


    }

    handleRevisedDueChange = (e) => {
        const { name, value } = e.target;
        this.setState({ revisedDueDate: value }, () => { this.calculatePaymentAmount(); });
    }

    calculatePaymentAmount = async () => {
        this.setState({
            feeLoader: true,
            isSubmitDisabled: true,
        });
        let selectedInvoices = this.state.selectedInvoice;
        let inputData = {
            invoices: selectedInvoices,
            product_type: this.state.selectedProduct.value,
            revised_due_date: this.state.revisedDueDate,
            userType: 'customer'
        };
        const { data } = await coreService.GenaralTransCalcuateFee(inputData);
        if (data.success == true) {
            selectedInvoices = data.invoices;
            this.findMaxRevisedDueDate(data.invoices);
        } else {
            toast.error(data.message);
        }
        this.setState({
            selectedInvoice: selectedInvoices,
            feeLoader: false,
            //isSubmitDisabled: false
        });
    }

    handleValidateInvoices = async () => {
        let balanceAvailable = 0;
        let current_balance = 0;
        let flag = false;
        this.setState({ loading: true });
        const { entity_id } = auth.getCurrentUser();

        if (this.state.selectedProduct.value == this.state.constants.PRODUCTS.SUPPLYCHAIN) {
            const { data } = await CommonService.getScFund();
            balanceAvailable = data.balanceAvailable;
        } else if (this.state.selectedProduct.value == this.state.constants.PRODUCTS.TRADEFINANCE) {
            const { data } = await CommonService.getTfFund({ guid: entity_id });
            balanceAvailable = data.balanceAvailable;
        } else {
            balanceAvailable = 'unlimited';
        }
        let inv_total = _.sumBy(this.props.selectedInvoice, function (inv) { return inv.inv_amount; });
        if (balanceAvailable == 'unlimited') {
            flag = true;
        } else {
            current_balance = parseFloat(balanceAvailable) - parseFloat(inv_total);
            if (current_balance < 0) {
                flag = false;
            } else {
                flag = true;
            }
        }
        this.setState({ loading: false });

        if (flag == false) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">
                            <h5>Facility limit low</h5>
                            <p>Are you sure want to continue process?</p>
                            <button
                                className="btn btn-white"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-primary ml-2"
                                onClick={() => {
                                    onClose();
                                    this.handleCheckInvoiceNumber();
                                }}
                            >
                                Yes
                            </button>
                        </div>
                    );
                },
                title: "Facility limit low",
                message: `Are you sure want to continue process`,
                overlayClassName: "zindex-2100"
            });
        } else {
            this.handleCheckInvoiceNumber();
            //alert();
        }
    }


    render() {
        const { constants, category, products } = auth.getCurrentUser();
        return (
            <>
                {this.state.loading && <LoadingSpinner></LoadingSpinner>}
                <Modal.Header closeButton>
                    <h4 className="modal-title">ASSIGN INVOICES TO YOUR FACILITY </h4>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.confirmation == false ? (
                            <>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-sm  text-secondary">
                                            <thead className="table text-secondary text-white">
                                                <tr>
                                                    <th>
                                                        {
                                                            this.props.userType == 'addAsSupplier' ? "Supplier" : "Customer"
                                                        }
                                                    </th>
                                                    <th>Inv No</th>
                                                    <th>Amount</th>
                                                    <th>Inv Date</th>
                                                    <th>Due Date</th>

                                                    {this.state.revisedDueDate &&
                                                        <th>Maximum Due Date</th>
                                                    }

                                                    {
                                                        this.props.userType == 'addAsSupplier' && (
                                                            <th>Amount to Pay</th>
                                                        )
                                                    }
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    this.state.selectedInvoice.map((item) => (
                                                        <>
                                                            <tr>
                                                                <td>{item.organization}</td>
                                                                <td>{item.inv_number}</td>
                                                                <td><CurrencyLabel value={item.inv_amount}></CurrencyLabel></td>
                                                                <td>{moment(item.trans_date, 'YYYY-MM-DD').format("DD/MM/YYYY").toString()}</td>
                                                                <td>{moment(item.inv_due_date, 'YYYY-MM-DD').format("DD/MM/YYYY").toString()}</td>

                                                                {this.state.revisedDueDate &&             
                                                                    <td className="text-danger">{item.max_revised_date ? moment(item.max_revised_date.maxDate, 'YYYY-MM-DD').format("DD/MM/YYYY").toString() : <LoadingSpinner normal="true" />}</td>
                                                                }

                                                                {
                                                                    this.props.userType == 'addAsSupplier' && (
                                                                        <td>
                                                                            {
                                                                                this.state.feeLoader == true ? (
                                                                                    <LoadingSpinner normal="true" />
                                                                                ) : (
                                                                                    item.pay_amount && item.pay_amount != "" && item.pay_amount > 0 ? (
                                                                                        <CurrencyLabel value={item.pay_amount}></CurrencyLabel>
                                                                                    ) : (
                                                                                        <CurrencyLabel value={item.inv_amount}></CurrencyLabel>

                                                                                    )
                                                                                )
                                                                            }

                                                                        </td>
                                                                    )
                                                                }

                                                                <td>
                                                                    {

                                                                        this.state.selectedProduct.value && item.hasProductEnabled === false && (
                                                                            <span className="text-danger">
                                                                                Please enable {this.state.selectedProduct.label} to {item.organization}
                                                                            </span>
                                                                        )

                                                                    }
                                                                    {
                                                                        item.error && item.error != "" && (
                                                                            <span className="text-danger">
                                                                                {item.error}
                                                                            </span>
                                                                        )
                                                                    }
                                                                    {
                                                                        item.success && item.success != "" && (
                                                                            <span className="text-success">
                                                                                {item.success}
                                                                            </span>
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        this.state.selectedProduct && this.state.selectedProduct.value == constants.PRODUCTS.DEBTORFINANACE && (
                                            <>
                                                <div className="alert alert-info">
                                                    Selected invoices will be treated as a single batch.
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        this.state.enabledProducts.length > 0 ? (
                                            <>
                                                <div className="row mt-4">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label className="mt-3">Assign to</label>

                                                            <Picker
                                                                items={this.state.enabledProducts}
                                                                selectedItem={this.state.selectedProduct}
                                                                onSelectItem={this.handleProductChange}
                                                                name="product"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        {
                                                            this.props.userType == 'addAsSupplier' && (
                                                                <>
                                                                    <div className="form-group">
                                                                        <label className="mt-3">
                                                                            Revised Due Date  <i data-tip="Choosen date will be applied against all selected invoices" class="fa fa-info-circle"></i>
                                                                        </label>
                                                                        <ReactTooltip backgroundColor="#11698e" />
                                                                        <DateTimePicker
                                                                            name="revisedDueDate"
                                                                            selectedDate={this.state.revisedDueDate}
                                                                            onChange={this.handleRevisedDueChange}
                                                                            placeholder="Select Revised Due Date"
                                                                            format="dd/MM/y"
                                                                            type="normal"
                                                                            readOnly={this.state.revisedReadOnly}
                                                                        />

                                                                    </div>
                                                                </>
                                                            )
                                                        }

                                                    </div>
                                                </div>

                                                {this.state.selectedInvoice.some(item => item.max_revised_date_crossed_check === false) && 
                                                    <>
                                                        <div className="custom-control d-flex custom-checkbox small">
                                                            <div className="col-lg-12">
                                                                <input
                                                                    id="maxduedatecheckacceptance"
                                                                    type="checkbox" className="custom-control-input"
                                                                    onChange={e => this.setState({ isSubmitDisabled: !e.target.checked })}
                                                                />
                                                                <label className="custom-control-label text-secondary text-sm ml-1 text-danger" for={`maxduedatecheckacceptance`}>
                                                                Click here to accept the maximum allowed date for the invoices that have exceeded the revised due date you selected.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                

                                            </>
                                        ) : (
                                            <>
                                                <p className="text-danger">You don't have any products enabled.</p>
                                            </>
                                        )
                                    }

                                </div>
                            </>
                        ) : (
                            <Confirmation
                                ref={this.confirmationRef}
                                constants={constants}
                                products={products}
                                category={category}
                            />
                        )
                    }

                    {
                        this.state.enabledProducts.length > 0 && (

                            <>
                                <div className="row ">

                                    <div className="col-sm-12">
                                        {
                                            (this.state.confirmation == true || this.props.userType == 'addAsCustomer') ? (
                                                <>
                                                    <GeneralButton
                                                        onClick={(e) => {
                                                            this.handleMoveTransaction();
                                                        }}
                                                        disabled={this.state.isSubmitDisabled}
                                                        //   faIcon="fa fa-save"
                                                        className={`btn btn-primary pull-right mb-2 ${this.props.userType == 'addAsSupplier' ? "mt-4" : ""}`}
                                                        name="Submit"
                                                    ></GeneralButton>
                                                    <button
                                                        onClick={() => {
                                                            this.setState({ confirmation: false });
                                                        }}
                                                        className="btn bg-light btn-default   mr-2 pull-right"
                                                    >
                                                        Back
                                                    </button>

                                                </>
                                            ) : (
                                                <AddButton
                                                    disabled={this.state.isSubmitDisabled}
                                                    onClick={() => {
                                                        //this.setState({ confirmation: true });
                                                        this.handleValidateInvoices();
                                                    }}
                                                    label="Next"
                                                    style="btn btn-primary pull-right"></AddButton>
                                            )
                                        }

                                    </div>
                                </div>
                            </>
                        )
                    }

                </Modal.Body>
            </>

        );
    }
}
export default AssignInvoice;