import React from "react";
import "./css/card.css";
import "./css/skelton.css";
import { FormColumnMd } from "../../../common/containers/formContainer";
function PrimaryCard({ col, type = "normal" }) {

    return (
        <>
            {type == "normal" ? (
                < FormColumnMd col={col ? col : 4}>
                    <div className="card">
                        <div className="card__image loading"></div>
                        <div className="card__title loading"></div>
                        <div className="card__description loading"></div>
                    </div>
                </FormColumnMd>
            ) : (
                < FormColumnMd col={col ? col : 4}>
                    <div className="card">
                        <div className="card__title loading"></div>
                        <div className="card__round loading mt-4 mb-4"></div>
                        <div className="title-round-sec">
                            <div className="small-round loading"></div><div className="card__title loading w-80"></div>
                        </div>

                        <div className="title-round-sec">
                            <div className="small-round loading"></div><div className="card__title loading w-80"></div>
                        </div>

                        <div className="title-round-sec">
                            <div className="small-round loading"></div><div className="card__title loading w-80"></div>
                        </div>

                        <div className="title-round-sec mb-5">
                            <div className="small-round loading"></div><div className="card__title loading w-80"></div>
                        </div>
                        <div className="mb-1"></div>
                    </div>
                </FormColumnMd>
            )}


        </>
    );
}

export default React.memo(PrimaryCard);