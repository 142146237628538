import client from "../../../services/serviceClient";
import http from "../../../services/httpService";
import { apiUrl, queryString } from "../../../../src/environment";

let accessToken = "";
const rateUrl = "/supplychain/fifo-sc-rates/";

function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
}

function getApiUrl(func) {
  let remote_call = apiUrl + rateUrl + func + queryString;
  return remote_call;
}
async function createResponse(url, postObject) {
  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return response.data;
  }
}

// export async function getRatesList(
//   page,
//   pageSize,
//   sorted,
//   filtered,
//   search,
//   additionalParams,
//   handleRetrievedData
// ) {
//   updateHeader();

//   let apiGeneralCall = getApiUrl("list-rates");
//   let postObject = {
//     page: page,
//     pageSize: pageSize,
//     sorted: sorted,
//     filtered: filtered,
//     search: search,
//     additionalParams,
//   };

//   try {
//     const response = await http.post(apiGeneralCall, postObject, accessToken);
//     return handleRetrievedData(response);
//   } catch (response) {
//     return console.log(response);
//   }
// }

/**************************************create rate***************** */
export async function createRate(rate_guid, rate_name, owner) {
  updateHeader();
  let apiGeneralCall = getApiUrl("create-rate");
  let postObject = {
    rate_guid,
    rate_name,
    owner,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

/************************************** create general rate ***************** */
export async function createGeneralRate(data) {
  updateHeader();
  let apiGeneralCall = getApiUrl("create-general-rate");
  let postObject = {
    data,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function fetchKeywordSuggestions(search, additionalParams) {
  updateHeader();
  let apiGeneralCall = getApiUrl("key-word-auto-suggestions");
  let postObject = {
    search: search,
    additionalParams,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function fetchRatesDetail(rate_guid) {
  // updateHeader();
  let apiGeneralCall = getApiUrl("rate-details");
  let postObject = {
    rate_guid: rate_guid,
  };
  const response = createResponse(apiGeneralCall, postObject);

  return response;
}

// ------ Service to create rate altogether--------//
export async function saveRates(
  customerdata,
  supplierdata,
  generaldata,
  bpData,
  headOfficedata
) {
  updateHeader();

  let apiGeneralCall = getApiUrl("rate-save");

  let postObject = {
    rate_guid: customerdata.rate_guid,
    rate_name: customerdata.rate_name,
    owner: customerdata.owner,
    customerRateSchedules: customerdata.customerRateSchedules,
    customerRateNoEpSchedules: customerdata.customerRateNoEpSchedules,
    day_rate: customerdata.day_rate,
    fix_rate: customerdata.fix_rate,
    rebate: customerdata.rebate ? customerdata.rebate:0,
    day_rate_guid: customerdata.day_rate_guid,
    fix_rate_guid: customerdata.fix_rate_guid,
    rebate_guid: customerdata.rebate_guid,
    day_rate_noEp: customerdata.day_rate_no_ep,
    fix_rate_noEp: customerdata.fix_rate_no_ep,
    rebate_noEp: customerdata.rebate_no_ep ? customerdata.rebate_no_ep :0,
    day_rate_noEp_guid: customerdata.day_rate_no_ep_guid,
    fix_rate_noEp_guid: customerdata.fix_rate_no_ep_guid,
    rebate_noEp_guid: customerdata.rebate_no_ep_guid,
    supplierRateSchedules: supplierdata.supplierRateSchedules,
    day_rate_supplier: supplierdata.day_rate,
    fix_rate_supplier: supplierdata.fix_rate,
    rebate_supplier: supplierdata.rebate ? supplierdata.rebate :0,
    day_rate_guid_supplier: supplierdata.day_rate_guid,
    fix_rate_guid_supplier: supplierdata.fix_rate_guid,
    rebate_guid_supplier: supplierdata.rebate_guid,
    generaldata: generaldata,
    bpData: bpData,
    headOfficedata: headOfficedata,
  };

  const response = createResponse(apiGeneralCall, postObject);

  return response;
}
// ------ Service to create rate altogether--------//
export async function saveTransactionRate( 
  TransactionDetails,rate_guid,customerdata,supplierdata,generaldata,bpData,headOfficedata
) {
  updateHeader();
  let apiGeneralCall = getApiUrl("create-transaction-rate");
  let postObject = {
        TransactionDetails:TransactionDetails,
        rate_guid: rate_guid,
        rate_name: customerdata.rate_name,
        customerRateSchedules: customerdata.customerRateSchedules,
        customerRateNoEpSchedules: customerdata.customerRateNoEpSchedules,
        day_rate: customerdata.day_rate,
        fix_rate: customerdata.fix_rate,
        rebate: customerdata.rebate,
        day_rate_guid: customerdata.day_rate_guid,
        fix_rate_guid: customerdata.fix_rate_guid,
        rebate_guid: customerdata.rebate_guid,
        day_rate_noEp: customerdata.day_rate_no_ep,
        fix_rate_noEp: customerdata.fix_rate_no_ep,
        rebate_noEp: customerdata.rebate_no_ep,
        day_rate_noEp_guid: customerdata.day_rate_no_ep_guid,
        fix_rate_noEp_guid: customerdata.fix_rate_no_ep_guid,
        rebate_noEp_guid: customerdata.rebate_no_ep_guid,
        supplierRateSchedules: supplierdata.supplierRateSchedules,
        day_rate_supplier: supplierdata.day_rate,
        fix_rate_supplier: supplierdata.fix_rate,
        rebate_supplier: supplierdata.rebate,
        day_rate_guid_supplier: supplierdata.day_rate_guid,
        fix_rate_guid_supplier: supplierdata.fix_rate_guid,
        rebate_guid_supplier: supplierdata.rebate_guid,
        generaldata: generaldata,
        bpData: bpData,
        headOfficedata: headOfficedata,
      };
  const response = createResponse(apiGeneralCall, postObject);

  return response;
}
// export async function createCustomerRate(data) {
//   updateHeader();

//   let apiGeneralCall = getApiUrl("customer-rate-save");

//   let postObject = {
//     rate_guid: data.rate_guid,
//     rate_name: data.rate_name,
//     customerRateSchedules: data.customerRateSchedules,
//     customerRateNoEpSchedules :data.customerRateNoEpSchedules,
//     day_rate: data.day_rate,
//     fix_rate: data.fix_rate,
//     day_rate_guid: data.day_rate_guid,
//     fix_rate_guid: data.fix_rate_guid,
//     day_rate_noEp:data.day_rate_no_ep,
//     fix_rate_noEp:data.fix_rate_no_ep,
//     day_rate_noEp_guid:data.day_rate_no_ep_guid,
//     fix_rate_noEp_guid:data.fix_rate_no_ep_guid

//   };
//   const response = createResponse(apiGeneralCall,postObject);
//   console.log(response);
//    return response;
// }

// export async function createSupplierRate(data) {
//   updateHeader();

//   let apiGeneralCall = getApiUrl("supplier-rate-save");

//   let postObject = {
//     rate_guid: data.rate_guid,
//     rate_name: data.rate_name,
//     supplierRateSchedules: data.supplierRateSchedules,
//     day_rate: data.day_rate,
//     fix_rate: data.fix_rate,
//     day_rate_guid: data.day_rate_guid,
//     fix_rate_guid: data.fix_rate_guid,

//   };
//   const response = createResponse(apiGeneralCall,postObject);
//   console.log(response);
//    return response;
// }

export async function deleteRate(rate_guid) {
  updateHeader();
  let apiGeneralCall = getApiUrl("delete-rate");
  let postObject = {
    rate_guid: rate_guid,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
export async function getRateDetails(rate_guid) {
  updateHeader();
  let apiGeneralCall = getApiUrl("get-rate-details");
  let postObject = {
    rate_guid: rate_guid,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
/**function to get rate and its values for a transaction */
const getTransactionRateDetails = (trans_guid) => client.post(`/supplychain/fifo-sc-rates/get-transaction-rate-details`, { trans_guid });
const getTransactionRate = (trans_guid) => client.post(`/supplychain/fifo-sc-rates/get-transaction-rate`, { trans_guid });
/**function to save rate and its values aganist a  transaction */

const reApplyRate = (trans_guid) => client.post(`/supplychain/fifo-sc-rates/re-apply-old-transaction-rate?XDEBUG_SESSION_START=PHPSTORM`, { trans_guid });
// ------ Service to create rate altogether--------//
const getRatesList = (params, status) => client.post(`/supplychain/fifo-sc-rates/list-rates?XDEBUG_SESSION_START=PHPSTORM`, { ...params,status});
const searchRates = params => client.post(`/supplychain/fifo-sc-rates/search-rates?XDEBUG_SESSION_START=PHPSTORM`, { ...params})
const streamTransactionMigration = (params) => client.post(`/supplychain/fifo-migration/migrate-stream-transactions?XDEBUG_SESSION_START=PHPSTORM`, { ...params});
const createClentRate = (params) => client.post(`/supplychain/fifo-rates/create-rate`, { ...params});
const clientRateView = (params) => client.post(`/supplychain/fifo-rates/get-rate`, { ...params});
const rateApprove = (params) => client.post(`/supplychain/fifo-rates/approve-rate`, { ...params});
const rateList = (params) => client.post(`/supplychain/fifo-rates/all-rate-list`, { ...params});
const clientdata = (params) => client.post(`/supplychain/fifo-rates/get-client-transaction`, { ...params}); 
const fetchAuditReport = params => client.post(`/supplychain/fifo-rates/audit-report`, { ...params });
const supplierList = params => client.post(`/supplychain/fifo-supplier-program/get-suppliers-list`, { ...params });
const createProgram = params => client.post(`/supplychain/fifo-supplier-program/create-program`, { ...params });
const listPrograms = params => client.post(`/supplychain/fifo-supplier-program/all-program-list
`, { ...params });
const rateDetail = params => client.post('supplychain/fifo-supplier-program/get-default-rate', { ...params })
const getProgram = params => client.post('supplychain/fifo-supplier-program/get-program', { ...params })
const inviteSupplierTemplate = params => client.post('supplychain/fifo-supplier-program/invite-supplier-template', { ...params })
const invite_supplier = params => client.post('supplychain/fifo-supplier-program/invite-supplier', { ...params })
const supplierDetails = params => client.post('supplychain/fifo-supplier-program/get-all-suppliers', { ...params })
const epTransactionList = params => client.post('supplychain/fifo-supplier-program/ep-transaction-list', { ...params })
const publicTransaction = params => client.post('core/ep-transactions/get-ep-transaction', { ...params })
const sendOtp = params => client.post('core/ep-transactions/send-otp-for-approval', { ...params })
const sendApproval = params => client.post('core/ep-transactions/supplier-approval', { ...params })
const invite_supplier_public_invoice = params => client.post('supplychain/fifo-supplier-program/invite-supplier-view-invoice', { ...params })
const rejectApproval = params => client.post('core/ep-transactions/reject-invoice', { ...params })
const invoiceAttachment = params => client.post('core/ep-transactions/view-invoice-attachment', { ...params })
const deleteAttachment = params => client.post('core/ep-transactions/delete-attachment', { ...params })
const fetchLogo = params => client.post('core/ep-transactions/fetch-logo', { ...params })
const epProgramDeleteion = params => client.post('supplychain/fifo-supplier-program/delete-program', { ...params })
const displayFee = params => client.post('core/ep-transactions/calculate-fee', { ...params })
const otpVerify = params => client.post('core/ep-transactions/otp-verify', { ...params })
const generateUrl = params => client.post('supplychain/fifo-supplier-program/generate-url', { ...params })
const decodeUrl = params => client.post('core/ep-transactions/decode-url', { ...params })

export default {
  getRatesList,
  createRate,
  createGeneralRate,
  fetchKeywordSuggestions,
  fetchRatesDetail,
  deleteRate,
  saveRates,
  getRateDetails,
  getTransactionRateDetails,
  saveTransactionRate ,
  reApplyRate,
  searchRates,
  getTransactionRate,
  streamTransactionMigration,
  supplierList,
  createProgram,
  listPrograms,
  rateDetail,
  getProgram,
  inviteSupplierTemplate,
  invite_supplier,
  supplierDetails,
  epTransactionList,
  publicTransaction,
  sendOtp,
  sendApproval,
  invite_supplier_public_invoice,
  rejectApproval,
  invoiceAttachment,
  deleteAttachment,
  fetchLogo,
  epProgramDeleteion,
  createClentRate,
  clientRateView,
  rateApprove,
  rateList,
  fetchAuditReport,
  clientdata,
  displayFee,
  otpVerify,
  generateUrl,
  decodeUrl
};
