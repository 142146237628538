import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div>
          <strong>&copy;</strong> {global.config.title} {new Date().getFullYear()}
        </div>
      </div>
    );
  }
}

export default Footer;
