import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Line, Bar } from 'react-chartjs-2'
import RateIcon from "../../../../img/icons/rating.svg";
export default function CashFlowLayout() {
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels,
        datasets: [
            {
                type: "bar",
                label: 'Payables',
                data: [1000, 2000, 3000, 3500, 2500, 1800, 4000],
                borderColor: 'rgb(25, 69, 107)',
                backgroundColor: 'rgba(25, 69, 107, 1)',
            },
            {
                type: "bar",
                label: 'Receivables',
                data: [2000, 3000, 4500, 3500, 2500, 3800, 2400],
                backgroundColor: 'rgb(22, 199, 154,0.6)',
                borderColor: 'rgba(0, 88, 122, 1)',
            },
            {
                type: "line",
                label: 'Cash Flow',
                data: [2000, 3000, 3000, 2500, 2000, 3000, 2400],
                backgroundColor: 'rgba(70, 79, 136, 0.2)',
                borderColor: 'rgba(70, 79, 136, 0.6)',
                tension: 0,
            },
        ],
    };
    const graph = {
        labels: [],
        datasets: [
            {
                label: 'Amount',
                data: [],
                backgroundColor: [
                    '#58B07E',
                ],

                borderWidth: 1,
                fill: true,
                backgroundColor: 'rgb(22, 199, 154,0.6)',
                borderColor: 'rgba(0, 88, 122, 1)',
                pointBackgroundColor: "#19456b"
            },
        ],
    }

    /*-----------------------------------------*/
    const monthsInYear = 2;
    const numMonths = monthsInYear * 1; // 1 years

    const rangeValues = Array.from({ length: numMonths }, (_, index) => index * 6 + 1);

    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() + rangeValues[0] - 1);

    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + rangeValues[rangeValues.length - 1] - 1);

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    };

    const [selectedDate, setSelectedDate] = useState(rangeValues[Math.floor(rangeValues.length / 2)]);
    const [formattedSelectedDate, setFormattedSelectedDate] = useState(formatDate(startDate));

    const handleSliderChange = (event) => {
        const value = parseInt(event.target.value);
        setSelectedDate(value);
    };

    useEffect(() => {
        const selected = new Date();
        selected.setMonth(selected.getMonth() + selectedDate - 1);
        setFormattedSelectedDate(formatDate(selected));
    }, [selectedDate]);
    return (
        <>

            <Helmet>
                <title>Cash Flow  | {global.config.title}</title>
            </Helmet>
            <div className="card" >

                <div className="card-body" >
                    <div className="card-title" ><h6>Manage my cash flow</h6></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card float-e-margins">

                                <div className="card-content">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="flot-chart">
                                                <div> <Bar data={data} options={[]} /></div>


                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="stat-list mr-2">
                                                <div className="mt-3">
                                                    <h6 className="no-margins">$2,346.00</h6>
                                                    <div>
                                                        <small>Total amount to be paid</small>
                                                        <div className="stat-percent pull-right">
                                                            48% <i className="fa fa-level-down text-danger" />
                                                        </div>
                                                        <div className="progress" style={{ height: "5px" }} >
                                                            <div style={{ width: "48%" }} className="progress-bar bg-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <h6 className="no-margins ">$4,422.00</h6>
                                                    <small>Total amount to receive</small>
                                                    <div className="stat-percent pull-right">
                                                        60% <i className="fa fa-level-up text-primary" />
                                                    </div>
                                                    <div className="progress " style={{ height: "5px" }}>
                                                        <div style={{ width: "60%" }} className="progress-bar" />
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-center align-items-center mt-4">
                                                    <div className="card text-center border-primary shadow bg-light" style={{ width: '22rem' }}>
                                                        <img src={RateIcon} style={{ width: "75px" }} alt="Icon" className="card-img-top mx-auto mt-3" />
                                                        <div className="card-body">
                                                            <div className=" d-flex align-items-center justify-content-center mx-auto" >
                                                                <h3 className="card-text text-secondary" >$25,000.00</h3>
                                                            </div>
                                                            <small>Your have saved</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card" >

                                <div className="card-body" >
                                    <h6>Invoice Payable</h6>
                                    <div className="bg-light p-3 b-dashed">

                                        <div><span>{formatDate(startDate)}</span>
                                            <span className="pull-right">{formatDate(endDate)}</span>
                                        </div>
                                        <input
                                            type="range"
                                            className="custom-range"
                                            min={rangeValues[0]}
                                            max={rangeValues[rangeValues.length - 1]}
                                            step="6"
                                            value={selectedDate}
                                            onChange={handleSliderChange}
                                        />

                                        <p>Selected Date: <span className="font-weight-bold">{formattedSelectedDate}</span></p>
                                    </div>
                                    <div className="list-group list-group-flush bg-light px-2 rounded-xl ">
                                        <div className="list-group-item list-group-item-action flex-column align-items-start rounded-xl border-0 mt-2 mb-2">
                                            <div className="d-sm-flex align-items-center">

                                                <div className="d-flex card-mobile-view justify-content-center">
                                                    <div className="avatar d-none d-sm-inline-flex avatar-md text-white bg-secondary mr-2  font-weight-bold rounded-full mob-avatar">
                                                        <span>102</span>
                                                    </div>
                                                    <div className="mb-3 mb-sm-0 mob-card-text  text-center text-sm-left">
                                                        <h6 className="mb-0 mob-card-title  text-center text-sm-left">
                                                            Invoices pending payment
                                                        </h6>
                                                        <small className="opacity-50">
                                                            <span
                                                                className="text-secondary mr-2 font-weight-bold"
                                                                title="Invoice Number"
                                                            >
                                                                By
                                                            </span>
                                                            September 17 2023
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="ml-auto text-sm-right text-center mg-t-5 mob-card-right">
                                                    <h6 className="mb-0 text-secondary font-weight-bold">
                                                        <span>$35,000.00</span>
                                                    </h6>
                                                    <small className="badge badge-warning  ">Pending</small>
                                                </div>
                                            </div>
                                            <div className=" d-flex align-items-center justify-content-center mx-auto mt-4">
                                                <button className="btn btn-small btn-outline-secondary">Load More Transactions </button>
                                            </div>
                                        </div>


                                    </div>

                                </div>


                            </div>


                        </div>
                        <div className="col-lg-6">
                            <div className="card" >

                                <div className="card-body" >
                                    <h6>Invoice Receivables</h6>

                                    <div className="bg-light p-3 b-dashed">

                                        <div><span>{formatDate(startDate)}</span>
                                            <span className="pull-right">{formatDate(endDate)}</span>
                                        </div>
                                        <input
                                            type="range"
                                            className="custom-range"
                                            min={rangeValues[0]}
                                            max={rangeValues[rangeValues.length - 1]}
                                            step="6"
                                            value={selectedDate}
                                            onChange={handleSliderChange}
                                        />

                                        <p>Selected Date: <span className="font-weight-bold">{formattedSelectedDate}</span></p>
                                    </div>
                                    <div className="list-group list-group-flush bg-light px-2 rounded-xl ">
                                        <div className="list-group-item list-group-item-action flex-column align-items-start rounded-xl border-0 mt-2 mb-2">
                                            <div className="d-sm-flex align-items-center">

                                                <div className="d-flex card-mobile-view justify-content-center">
                                                    <div className="avatar d-none d-sm-inline-flex avatar-md text-white bg-primary mr-2  font-weight-bold rounded-full mob-avatar">
                                                        <span>58</span>
                                                    </div>
                                                    <div className="mb-3 mb-sm-0 mob-card-text  text-center text-sm-left">
                                                        <h6 className="mb-0 mob-card-title  text-center text-sm-left">
                                                            Invoices awaiting payment
                                                        </h6>
                                                        <small className="opacity-50">
                                                            <span
                                                                className="text-secondary mr-2 font-weight-bold"
                                                                title="Invoice Number"
                                                            >
                                                                By
                                                            </span>
                                                            Auguest 28 2023
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="ml-auto text-sm-right text-center mg-t-5 mob-card-right">
                                                    <h6 className="mb-0 text-secondary font-weight-bold">
                                                        <span>$24,000.00</span>
                                                    </h6>
                                                    <small className="badge badge-warning  ">Pending</small>
                                                </div>
                                            </div>
                                            <div className=" d-flex align-items-center justify-content-center mx-auto mt-4">
                                                <button className="btn btn-small btn-outline-primary">Load More Transactions </button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
