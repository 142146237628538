import React, { lazy } from "react";
import { Route } from "react-router";
import routeURL from "./routeConstants";

/* Protected Route */
const ProtectedRoute = lazy(() => import("../../common/layout/protectedRoute"));
// -- Importing components from Dashboard widgets Module
const Main = lazy(() =>
  import("../../dashboardWidgets/components/widgets/main")
);
const CreateWidgets = lazy(() =>
  import("../../dashboardWidgets/components/widgets/createWidget")
);
const WidgetLIst = lazy(() =>
  import("../../dashboardWidgets/components/widgets/widgetList")
);

const AssignWidgets = lazy(() => import("../components/widgets/assignWidgets"));
const DashBoardList = lazy(() =>
  import("../../dashboardWidgets/components/dashboard/dashBoardList")
);
const DashBoardView = lazy(() =>
  import("../../dashboardWidgets/components/dashboard/dashboardView")
);
const WidgetAssignment = lazy(() =>
  import("../../dashboardWidgets/components/widgets/userDashboard/main")
);
// -- Implementing routes for SC Module
const widgetsRoutes = (
  <>
    <Route path={routeURL.CREATEWIDGETS} component={CreateWidgets}></Route>
    <Route path={routeURL.WIDGETS} component={WidgetLIst}></Route>
    <Route path={routeURL.ASSIGNWIDGETS} component={AssignWidgets}></Route>
    <Route path={routeURL.DASHBOARDCONFIG} component={Main}></Route>
    <Route path={routeURL.DASHBOARD} component={DashBoardList}></Route>
    <Route path={routeURL.USERDASHBOARD} component={WidgetAssignment}></Route>
    {/* <Route path={routeURL.DASHBOARDVIEW} component={DashBoardView}></Route> */}
  </>
);

export default widgetsRoutes;
