import React, { useEffect, useContext, useState } from "react";
import { PrimaryCard } from "../../components/cards";
import { CurrencyLabel } from "../../../common/inputs";
import Modal from "react-responsive-modal";
import CreateInvoice from "../../pages/ifTransactions/_createInvoice";

import useApi from '../../../../hooks/useApi';
import useIsMounted from "../../../../hooks/useIsMounted";
import EntityContext from "../../../../context/entityContext";
import widgetService from "../../../../services/invTransactions";
import productShortName from "../../../../utils/GetProductShortName";

function PendingTransactions() {

    const isMounted = useIsMounted();
    const [transModal, setTransModal] = useState(false);
    const { selectedBusinessType, selProduct, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);

    //------------Api Service--------------------------------------------
    const paymentsApi = useApi(widgetService.getPendingTransactions);

    const fetchData = async () => {
        
        const response = await paymentsApi.request({ entity_id: entityDetails.a_guid, selectedBusinessType });
        if(response && isMounted()){
            setData({
                topLabel: "Processing",
                topValue: <CurrencyLabel decimal={2} value={response.data.total_chargeable_amt} />,
                leftLabel: response.data.isPrimary ? "Debtors" : "Clients",
                leftValue: response.data.isPrimary ? response.data.debtor_count : response.data.client_count,
                rightLabel: "Invoices",
                rightValue: response.data.invoice_count,
                bottomLeftLabel: "Disbursed",
                bottomRightLabel: <button onClick={() => setTransModal(true)} className="btn card-btn btn-blue">Add New</button>,
                primaryBtnRoute: `/client/${productShortName(selProduct)}/sale-invoices?status=draft`
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>
            {/*------------------Bulk Payments Modal-------------------*/}
            <Modal open={transModal} onClose={() => setTransModal(false)} center>
                <div style={{ width: 450 }}>
                    <CreateInvoice onClose={() => { setTransModal(false); fetchData(); }} />
                </div>
            </Modal>
            {/*---------------------------------------------------------*/}

            <PrimaryCard
                loading={paymentsApi.loading}
                data={data}
                qrystring={true}
            />
        </>
    )
}
export default PendingTransactions;