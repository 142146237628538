import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import useApi from "../../../../../hooks/useApi";
import CreateInstantEntity from "../../scTransactions/tradersManagement/CreateInstantEntity";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import entityService from "../../../../../services/entityService";
import externalApiService from "../../../../../services/externalApiService";
import SearchComponents from "../../../../common/inputs/customSearch/searchComponent";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";
import BulkUploadSecondary from "../../../components/bulkupload";
import EntityContext from "../../../../../context/entityContext";

function NewSupplier({ entity_id, role, title, source, product }) {
  const { setData, data, fetchSupplier, supplierList } = useContext(
    SupplierProgramContext
  );
  const entityContext = useContext(EntityContext);

  //------------------Custom hook to handle form data-----------------------------
  const [searchBy, setSearchBy] = useState(0);
  const [entitySearchKey, setEntitySearchKey] = useState("");
  const [asicResponse, setAsicResponse] = useState(null);
  const [allOptions, setAllOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [newSupplierModal, setNewSupplierModal] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const [newSupplierId, setNewSupplierId] = useState("");
  // const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [loader, setLoader] = useState(false);
  //------------Api Services--------------------------------------------
  const entitySearchApi = useApi(entityService.searchByAbn);
  const externalSearchApi = useApi(externalApiService.abnSearchByOrgName);

  useEffect(() => {
    const fetchNewSupplier = async () => {
      await fetchSupplier();
    };

    fetchNewSupplier();

    const rows = supplierList.data.filter(
      (item) => item.supplier_id === newSupplierId
    );

    if (rows.length > 0) {
      const supplierProgramMapping = data?.supplierProgramMapping || [];

      const newItem = {
        ...rows[0],
        removed: false,
        supp_program: false,
        checked: true,
        added: true,
      };

      setData((prevData) => ({
        ...prevData,
        supplierProgramMapping: [...supplierProgramMapping, newItem],
      }));
    }
  }, [newSupplierModal, newSupplierId]);
  // const handleCloseBulkupload = () => {
  //   setShowBulkUpload(false);
  //   fetchSupplier();
  // };
  useEffect(() => {
    const fetchOptions = async () => {
      setLoader(true);
      try {
        const asicResponse = await externalSearchApi.request({
          organisation: entitySearchKey,
          from: "asic",
        });
        // if (asicResponse.data.organisations.length > 0) {
          setAllOptions(asicResponse.data.organisations);
          setLoader(false);
        // }
      } catch (error) {
        console.error("Error fetching options:", error);
        setLoader(true);
      }
    };

    // if (entitySearchKey) {
      fetchOptions();
    // } else {
    //   setAllOptions([]);
    // }
  }, [entitySearchKey]);

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };

  const renderOptionContent = (data) => (
    <dl className="dl-horizontal ">
      <ul class="list-group clear-list m-t">
        <li class="list-group-item fist-item" style={{ marginBlock: "-10px" }}>
          <div className="row">
            <div className="col-md-6">
              <label className="font-bold ml-4">
                {data.organisation}&nbsp;&nbsp;
              </label>
            </div>
            <div className="col-md-4">
              <span class="label">ABN: {data.abn}</span>
            </div>
            {data.status !== "Deregistered" ? (
              <div className="col-md-2">
                <button
                  className="btn btn-sm btn-white mr-2 mappingBtn"
                  onClick={() => {
                    setAsicResponse({
                      entityFound: true,
                      organisation: data.organisation,
                      abn: data.abn,
                    });
                    setNewSupplierModal(true);
                  }}
                >
                  Add
                </button>
              </div>
            ) : (
              <div className="col-md-2"></div>
            )}
          </div>
        </li>
      </ul>
    </dl>
  );

  return (
    <React.Fragment>
      <>
        {loader === true && <LoadingSpinner />}
        <>
          <div className="col-md-8">
            <h6>
              Search by: &nbsp;
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id="orgname"
                  type="radio"
                  value="orgname"
                  className="custom-control-input"
                  onClick={() => setSearchBy(0)}
                  checked={searchBy === 0 ? true : false}
                ></input>
                <label className="custom-control-label" htmlFor="orgname">
                  Name
                </label>
              </div>
              &nbsp;
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id="orgnum"
                  type="radio"
                  value="orgnum"
                  className="custom-control-input"
                  onClick={() => setSearchBy(1)}
                  checked={searchBy === 1 ? true : false}
                ></input>
                <label className="custom-control-label" htmlFor="orgnum">
                  ABN
                </label>
              </div>{" "}
              {/* or &nbsp;
              <button
                className="btn btn-sm btn-white mr-2 mappingBtn"
                onClick={() => {
                  setShowBulkUpload(true);
                }}
              >
                Bulk Upload
              </button> */}
            </h6>

            <SearchComponents
              ep={true}
              options={allOptions}
              userInteracted={userInteracted}
              renderOptionContent={renderOptionContent}
              handleChange={handleChange}
              searchKeyChange={(inputValue) => {
                setEntitySearchKey(inputValue);
                setUserInteracted(true);
              }}
              onMenuClose={() => setUserInteracted(false)}
              selectedSearchTerm={selectedOption}
            />
          </div>
        </>
      </>

      <Modal
        show={newSupplierModal}
        onHide={() => setNewSupplierModal(false)}
        size="md"
        centered
      >
        <Modal.Body>
          <CreateInstantEntity
            entity_id={entity_id}
            role={role}
            title={title}
            type=""
            onCloseModal={() => setNewSupplierModal(false)}
            searchData={{
              abn: asicResponse?.abn,
              organisation: asicResponse?.organisation,
              searchBy: searchBy,
            }}
            source={source}
            setNewSupplierId={setNewSupplierId}
            product={product}
          />
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={showBulkUpload}
        onHide={() => setShowBulkUpload(false)}
        size="lg"
        centered
      >
        <Modal.Body>
          <BulkUploadSecondary
            onClose={handleCloseBulkupload}
            contextValues={entityContext.entityDetails}
            type="epProgram"
          />
        </Modal.Body>
      </Modal> */}
    </React.Fragment>
  );
}

export default NewSupplier;
