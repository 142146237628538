import React, { useEffect, useRef, useState, lazy } from "react";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../common/loaders/loadingSpinner";
import { Picker, GeneralButton } from "../common/inputs";
import { FormGroup } from "../common/containers/formContainer";
import coreService from "../../services/coreService";
import authService from "../../services/authService";
import useApi from "../../hooks/useApi";
const CreateBusinessPartner = lazy(() =>
  import("../../components/businessPartners/createEntity")
);

const IntroducersMembersList = ({
  onSelectItem,
  selectedIntroducer = null,
  type = null,
  country = null,
  label = null,
  defaultValue = { label: "Introducer", value: null },
  addNew = false,
}) => {
  const { category, country_id, constants } = authService.getCurrentUser();
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const [addModal, setAddModal] = useState(false);
  const { request, data, loading } = useApi(coreService.getIntroducersList);

  const prevCountry = useRef();

  const RenderPicker = () => (
    <>
      {addNew ? (
        <div className="row">
          <div className="col-lg-10">
            <Picker
              items={data}
              selectedItem={selectedItem}
              onSelectItem={(selectedOption) => {
                setSelectedItem(selectedOption);
                onSelectItem(selectedOption);
              }}
              loading={loading}
            />
          </div>
          <div className="col-lg-2">
            <GeneralButton
              onClick={() => setAddModal(true)}
              className="btn btn-outline-primary pull-right"
              title="Create a new new Introducer from here"
              name={<i className="fa fa-plus"></i>}
              type={"button"}
            />
          </div>
        </div>
      ) : (
        <Picker
          items={data}
          selectedItem={selectedItem}
          onSelectItem={(selectedOption) => {
            setSelectedItem(selectedOption);
            onSelectItem(selectedOption);
          }}
          loading={loading}
        />
      )}
    </>
  );

  useEffect(() => {
    if (country !== prevCountry.current) {
      prevCountry.current = country;
      request(
        country === null && category !== "International Office"
          ? country_id
          : country,
        constants.CATEGORY.INTRODUCER
      );
    }

    if (selectedIntroducer !== null && data.length > 0) {
      const introDetails = data.find((obj) => obj.value == selectedIntroducer);
      
      if (introDetails)
        setSelectedItem({
          label: introDetails.label,
          value: introDetails.value,
        });
    } else if (selectedIntroducer === null) setSelectedItem(defaultValue);
  }, [country, selectedIntroducer, data]);

  return (
    <>
      {loading == true && <LoadingSpinner />}
      {label !== null && type !== "vertical" ? (
        <FormGroup type={type} label={label} input={<RenderPicker />} />
      ) : label !== null && type === "vertical" ? (
        <div className="form-group">
          <label>{label}</label>
          <RenderPicker />
        </div>
      ) : (
        <RenderPicker />
      )}

      {/*------------------Add New Introducer Modal-------------------*/}
      <Modal show={addModal} onHide={() => setAddModal(false)} size="lg">
        <Modal.Header closeButton={true}>
          <h4 className="card-title">Add New Introducer</h4>
        </Modal.Header>
        <Modal.Body>
          <CreateBusinessPartner
            location={{ state: 3, query: { a_guid: null } }}
            modal={true}
            onClose={(status, entityData = null) => {
              if (status) {
                request(
                  country === null && category !== "International Office"
                    ? country_id
                    : country,
                  constants.CATEGORY.INTRODUCER
                );
                setTimeout(() => {
                  setSelectedItem({
                    label: entityData.entityDetails.organization,
                    value: entityData.entityDetails.a_guid,
                  });
                  onSelectItem({
                    label: entityData.entityDetails.organization,
                    value: entityData.entityDetails.a_guid,
                  });
                }, 1000);
              }
              setAddModal(false);
            }}
          />
        </Modal.Body>
      </Modal>
      {/*-------------------------------------------------*/}
    </>
  );
};

export default IntroducersMembersList;
