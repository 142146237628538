import React, { useEffect, useState } from "react";
import * as moment from "moment";
import { Link } from "react-router";
import { FormRow } from "../../../common/containers/formContainer";
import { DateTimePicker, Picker, CurrencyLabel } from "../../../common/inputs";
import loanTransactionService from "../../../loan/services/loanTransactions";
import authService from "../../../../services/authService";
import useApi from "../../../../hooks/useApi";

const TransactionStatement = () => {
  const defaultValue = { label: "Select Loan Reference", value: null };
  const transactionApi = useApi(loanTransactionService.loanStatement);

  const { entity_id } = authService.getCurrentUser();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loanReference, setLoanReference] = useState(null);
  const [loans, setLoans] = useState(defaultValue);
  const [statement, setStatement] = useState([]);
  const [openingBalance, setOpeningBalance] = useState();
  const [closingBalance, setClosingBalance] = useState();
  const [showTable, setShowTable] = useState(false);

  useEffect(async () => {
    const { data } = await loanTransactionService.loansDropdown({
      client: entity_id,
    });
    setLoans(data);
  }, []);

  const fetchData = async (download = false, type = null) => {
    const response = await transactionApi.request({
      client: entity_id,
      from_date: fromDate,
      to_date: toDate,
      loan_uid: loanReference.value,
      download: download === true ? true : false,
      type: type,
    });
    if (response.data) {
      setStatement(response.data.rows);
      setOpeningBalance(response.data.opening_balance);
      setClosingBalance(response.data.closing_balance);
      setShowTable(true);
      if (download && response.data?.downloadPath) {
        downloadPdf(response.data?.downloadPath, type);
      }
    }
  };

  const downloadPdf = async (url, type) => {
    if (type === "xlsx") {
      window.open(url, "_blank", "noreferrer");
    } else {
      const newWindow = window.open(url);
      newWindow.opener = null;
    }
  };

  const calculateMinDate = () => {
    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 6);
    return sixMonthsAgo;
  };

  return (
    <>
      <div className="card overflow-visible">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-6">
              <h6>Statement</h6>
            </div>
          </div>
        </div>
        <div className="card-body">
          <FormRow className="row ">
            <div className="col-lg-2">
              <DateTimePicker
                name="from_date"
                selectedDate={fromDate}
                onChange={({ target }) => setFromDate(target.value)}
                enableWeekDays={true}
                placeholder="From"
                minDate={calculateMinDate()}
                maxDate={new Date()}
              />
            </div>
            <div className="col-lg-2">
              <DateTimePicker
                name="to_date"
                selectedDate={toDate}
                onChange={({ target }) => setToDate(target.value)}
                enableWeekDays={true}
                placeholder="To"
                minDate={calculateMinDate()}
                maxDate={new Date()}
              />
            </div>
            <div className="col-lg-3">
              <Picker
                items={loans}
                selectedItem={loanReference}
                onSelectItem={(option) => {
                  setLoanReference(option);
                }}
              />
            </div>

            <div className="col-lg-5">
              <div className="pull-right">
                <div className="dropdown-menu">
                  <Link
                    className="dropdown-item"
                    onClick={() => {
                      fetchData(true, "xlsx");
                    }}
                  >
                    Export as Excel
                  </Link>
                  <Link
                    className="dropdown-item"
                    onClick={() => {
                      fetchData(true, "pdf");
                    }}
                  >
                    Export as PDF
                  </Link>
                </div>
                <button
                  disabled={statement.length === 0}
                  title="More export actions"
                  className="btn btn-outline-secondary dropdown-toggle mr-2"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Export
                </button>

                <button
                  onClick={fetchData}
                  disabled={
                    !fromDate ||
                    !toDate ||
                    !loanReference ||
                    loanReference.value === null ||
                    loanReference.value === undefined ||
                    loanReference.value === ""
                  }
                  className="btn btn-primary mr-2"
                >
                  View Statement
                </button>
              </div>
            </div>
          </FormRow>
          {showTable && (
            <FormRow className="table-responsive m-t">
              <table className="table invoice-table text-secondary">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th className="text-left">Transaction Description</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> </td>
                    <td></td>
                    <td></td>

                    <td>
                      <strong>Opening Balance : </strong>
                    </td>
                    <td>
                      <strong>
                        {<CurrencyLabel value={openingBalance}></CurrencyLabel>}
                      </strong>
                    </td>
                  </tr>
                  {statement &&
                    statement.map((item, index) => {
                      let paid_date = moment(
                        item.paid_date,
                        "YYYY/MM/DD"
                      ).format("DD/MM/YYYY");
                      return (
                        <tr key={index}>
                          <td>
                            {(index === 0
                              ? paid_date
                              : statement[index - 1].paid_date !==
                                item.paid_date) && paid_date}
                          </td>
                          <td className="text-left">{item.narration}</td>
                          <td>
                            {item.debit > 0 ? (
                              <CurrencyLabel value={item.debit}></CurrencyLabel>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {item.credit > 0 ? (
                              <CurrencyLabel
                                value={item.credit}
                              ></CurrencyLabel>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {
                              <CurrencyLabel
                                value={item.balance}
                              ></CurrencyLabel>
                            }
                          </td>
                        </tr>
                      );
                    })}

                  {statement && statement.length === 0 && (
                    <tr>
                      <td colSpan="5" align="center">
                        {transactionApi.loading
                          ? "Fetching Statement... Please Wait..."
                          : "No Data Found"}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td> </td>
                    <td></td>
                    <td></td>

                    <td>
                      <strong>Closing Balance : </strong>
                    </td>
                    <td>
                      <strong>
                        {
                          <CurrencyLabel
                            value={
                              statement?.length > 0
                                ? statement[statement.length - 1].balance
                                : openingBalance
                            }
                          ></CurrencyLabel>
                        }
                      </strong>

                      {/* <strong>
                      {<CurrencyLabel value={closingBalance}></CurrencyLabel>}
                    </strong> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </FormRow>
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionStatement;
