import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router";

function ExportReport({ exportExcel,exportExcelStatement, copyTable = null, tableVisibility }) {
  return (

    <>
    { tableVisibility && (

        <div class="row mb-2">
        <div class="col-md-6">
          {/* <h4>Ket Ststistics for the Period - 1/10/2020 to 1/10/2021</h4> */}
        </div>

        <div class="col-md-6 text-right">

          {copyTable && (
            <button
              class="btn btn-white btn-xs"
              onClick={(e) => {
                copyTable();
              }}
            >
              {" "}
              <i class="fa fa-th"></i> &nbsp; Copy Table
            </button>
          )}


        {exportExcelStatement !== null? (
            <>
              <div className="dropdown-menu" >
                  <button 
                    className="dropdown-item btn btn-white btn-xs ml-2"
                    onClick={(e) => {
                      exportExcel('xlsx');
                    }}
                  >
                    <i class="fa fa-file-text-o"></i> &nbsp; Export Excel
                  </button>
                  <button 
                    className="dropdown-item btn btn-white btn-xs ml-2" 
                    onClick={(e) => {
                      exportExcelStatement('xlsx');
                    }}
                  >
                    <i class="fa fa-file-text-o"></i> &nbsp; Introducers Commision Statement 
                  </button>
              </div>
              <button
                  title="More actions"
                  className="btn btn-outline-secondary mr-2  pull-right m-l-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
              >More</button>
            </>
          ):(<button
            class="btn btn-white btn-xs ml-2 "
            onClick={(e) => {
              exportExcel('xlsx');
            }}
          >
            <i class="fa fa-file-text-o"></i> &nbsp; Export Excel
          </button>)}

          <button class="btn btn-white btn-xs ml-2 "  onClick={(e) => {
              exportExcel('csv');
            }}>
            <i class="fa fa-table"></i> &nbsp; Export CSV
          </button>
        </div>
        </div>

    )}
       
  

    </>
  );
}
export default ExportReport;
