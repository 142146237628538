import React, { Component } from "react";
import Form from "../../common/Form";
import { Link } from "react-router";
import { EditButton, DeleteButton } from "../../common/inputs";
import { toast } from "react-toastify";
class FeeSchedule extends Form {
  state = {
    data: {
      fee_schedule_id: this.props.data.fee_schedule_id,
      days_from: this.props.data.days_from,
      days_to: this.props.data.days_to,
      daily_percentage: this.props.data.daily_percentage,
      index: this.props.index,
      removed: false
    },
    edit: false
  };
  render() {
    const { data, edit } = this.state;
    return (
      <React.Fragment>
        {data.removed == false && (
          <div className="row mt-2 text-secondary">
            <div class="col-lg-3 col-sm-3 col-xs-3">
              {edit == false ? (
                data.days_from
              ) : (
                <input
                  name="days_from"
                  value={data.days_from}
                  onChange={this.handleChange}
                  className="form-control input-sm"
                  type="text"
                  style={{ width: "100%" }}
                  //disabled={true}
                />
              )}
            </div>

            <div class="col-lg-3 col-sm-3 col-xs-3">
              {edit == false ? (
                data.days_to
              ) : (
                <input
                  name="days_to"
                  value={data.days_to}
                  onChange={this.handleChange}
                  className="form-control input-sm"
                  type="text"
                  style={{ width: "100%" }}
                />
              )}
            </div>

            <div class="col-lg-3 col-sm-3 col-xs-3">
              {edit == false ? (
                data.daily_percentage
              ) : (
                <input
                  name="daily_percentage"
                  value={data.daily_percentage}
                  onChange={this.handleChange}
                  className="form-control input-sm"
                  type="number"
                  style={{ width: "100%" }}
                />
              )}
            </div>

            <div class="col-lg-3 col-sm-3 col-xs-3">
              {edit == true ? (

                <i
                  alt="Save"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Save"
                  className="fa fa-save"
                  onClick={() => {
                    if (parseFloat(data.days_to) < parseFloat(data.days_from))
                      toast.error(`'Days To' field must be greater than ${data.days_from}`)
                    else {
                      this.setState({ edit: false });
                      this.props.updateHandler(data);
                    }
                  }}
                ></i>

              ) : (

                <EditButton
                  onClick={e => {
                    this.setState({ edit: true });
                  }}
                ></EditButton>

              )}
              &nbsp;

              <DeleteButton
                onClick={e => {
                  this.setState({ remove: true });
                  this.props.removeHandler(data);
                }}
              ></DeleteButton>

            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default FeeSchedule;
