import React, { useEffect, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { FormGroup } from "../common/containers/formContainer";
import { Picker } from "../common/inputs";

import accountantService from "../../services/accountantServices";
import useApi from '../../hooks/useApi';

function ClientsList({ onSelectItem, label = null }) {

    const [selectedItem, setSelectedItem] = useState({ label: "Client", value: null });

    const { request, data, loading } = useApi(accountantService.getClients); // Custom hook for HTTP Requests

    const RenderPicker = () =>
        <Picker
            items={data.rows}
            selectedItem={selectedItem}
            onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
        />

    useEffect(() => {
        request(); // Calling HTTP service
    }, []);

    return (
        <>
            <>
                {loading == true && <LoadingSpinner />} {/** Activating the loading spinner */}
                {label !== null ?
                    <FormGroup
                        label={label}
                        input={<RenderPicker />}
                    /> :
                    <RenderPicker />
                }
            </>
        </>
    )
};
export default ClientsList;