import React, { useEffect, useState, useContext } from "react";
import { WhiteCard } from "../../components/cards";
import { Line } from 'react-chartjs-2'
import CommonService from "../../../supply-chain/services/commonService";
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";
import RoleChecker from "../../../../utils/roleChecker";

import useApi from '../../../../hooks/useApi';
import useIsMounted from "../../../../hooks/useIsMounted";
import { CurrencyLabel } from "../../../common/inputs";
import productShortName from "../../../../utils/GetProductShortName";
import { AddButton } from "../../../common/containers/button";
import { browserHistory } from "react-router";

function ApprovedPaid(props) {

  const isMounted = useIsMounted();
  const { constants, sc_invoice_status } = authService.getCurrentUser();
  const [apprvPaidData, setApPaidData] = useState(null); //set data for funds available
  const [graphData, setGraphData] = useState(null);
  const { selectedBusinessType, entityDetails, terminology, openInvoiceModal, selProduct } = useContext(EntityContext);
  const apprvDataApi = useApi(CommonService.getScApprovepaidCount);

  const Graph = () => (
    <>
      {RoleChecker.check(entityDetails.products, constants.ROLES.CUSTOMER) && (
        <div className="flot-chart-content" id="flot-line-chart"> <Line data={graph} options={options} /></div>
      )}
    </>
  )
  const fetchData = async () => {

    const { data } = await apprvDataApi.request({ "product_type": constants.PRODUCTS.SUPPLYCHAIN, "userId": entityDetails.a_guid, "selRole": selectedBusinessType });

    if (isMounted()) {
      setApPaidData({
        cardTitle: 'SUPPLIER PAYMENT AMOUNT - LAST 12 MONTHS',//`Approved and ${terminology && terminology.SUPPLIER} Paid`,
        leftLabel: "Availability",
        leftValue: <CurrencyLabel decimal={2} value={data.balanceAvailable} />,
        centerLabel: "Invoices",
        centerValue: data.inv_approved_paid_count ? data.inv_approved_paid_count : 0,
        rightLabel: "Open",
        rightValue: <CurrencyLabel decimal={2} value={data.inv_approved_paid_sum} />,
        status: "open",
        subTitle: "Approved and purchased",
        primaryBtnRoute: "/client/scf/purchase-invoices",
      });

      setGraphData(data.graphData);
    }

  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: true,
  }

  const graph = {
    labels: graphData ? graphData.labels : "",
    datasets: [
      {
        label: 'Amount',
        data: graphData ? graphData.data : "",
        backgroundColor: [
          '#58B07E',
        ],

        borderWidth: 1,
        fill: true,
        backgroundColor: 'rgb(22, 199, 154,0.6)',
        borderColor: 'rgba(0, 88, 122, 1)',
        pointBackgroundColor: "#19456b"
      },
    ],
  }

  useEffect(() => {
    fetchData();
  }, [selectedBusinessType, entityDetails]);

  return (
    <>
      {RoleChecker.check(entityDetails.products, constants.ROLES.CUSTOMER) && (

        <WhiteCard
          loading={apprvDataApi.loading}
          data={apprvPaidData}
          graph={<Graph />}
          actionButtons={
            props.actionButtons ?
              <>
                <AddButton style="btn btn-primary btn-sm pull-right" onClick={() => openInvoiceModal(selProduct, selectedBusinessType)} label="Add New"></AddButton>
                <AddButton style='btn btn-secondary btn-sm mr-2 pull-right' label="Bulk Upload" onClick={() => browserHistory.push(`/client/${productShortName(selProduct)}/bulk-upload`)}></AddButton>
              </> : ''
          }
        />

      )}
    </>
  )
}
export default React.memo(ApprovedPaid);