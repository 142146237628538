import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { FormRow, FormColumnMd } from "../../../../common/containers/formContainer";
import { TextInput } from "../../../../common/inputs";
import { GeneralButton } from "../../../../common/containers/button";
import useApi from "../../../../../hooks/useApi";
import CreateInstantEntity from "./CreateInstantEntity";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import AbnSearch from "./abnNameSearch";
import MapEntity from "./mapEntity";
import EntityContext from "../../../../../context/entityContext";
import entityService from "../../../../../services/entityService";
import auth from "../../../../../services/authService";

function NewTrader({ title, onCloseModal, source, handleSelectedItem = null, entityType = null,selectedProduct=null }) {

  const { terminology } = useContext(EntityContext);
  const { constants } = auth.getCurrentUser();

  //------------------Custom hook to handle form data-----------------------------
  const [searchBy, setSearchBy] = useState(0);
  const [entitySearchKey, setEntitySearchKey] = useState("");
  const [abnSearchModal, setAbnSearchModal] = useState(false);
  const [entityData, setEntityData] = useState(null);
  const [asicResponse, setAsicResponse] = useState(null);
  const [request, setRequest] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [accountType, setAccountType] = useState("local");
  const [goNext, setGoNext] = useState(false);

  //------------Api Services--------------------------------------------
  const entitySearchApi = useApi(entityService.searchByAbn);

  const searchEntity = async () => {
    if (entitySearchKey === "") {
      toast.error("Please enter search key");
      return;
    }
    setAbnSearchModal(true);
  };
  const handleAbnSearch = async (result, type) => {
    setRequest(true);
    if (type === "asic") {
      setAsicResponse({
        entityFound: true,
        organisation: result.organisation,
        abn: result.abn,
      });
      setEntityData({ entityFound: true, type: "asic" });
    } else {
      const { data } = await entitySearchApi.request({
        abn_number: result.a_guid,
      });
      let result1 = data.result;
      result1.entityContacts.filter(item => item.is_primary == "yes").map((val, key) => {
        result1["contact_id"] = val.contact_id;
        val.isChecked = true
        return val;
      });
      result1["type"] = "api";
      setAsicResponse({
        entityFound: true,
        organisation: result1.entityDetails.organisation,
        abn: result1.entityDetails.abn,
      });
      setEntityData(result1);
    }
  };

  /* AccountType change checkbox */
  const accountTypeChange = async (event) => {
    const type = (event.target.checked) ? "intl" : "local";
    setAccountType(type);
    if (event.target.checked)
      setReadonly(true);
    else
      setReadonly(false);
  }

  /* To return popup heading/title */
  const getPopupTitle = () => {
    let title = "Supplier";
    if(entityType != null && entityType != undefined){
      if(entityType == 'customer') {
        title = terminology ? terminology.CUSTOMER : title;
      } else {
        if(selectedProduct !== null && selectedProduct.id == constants.PRODUCTS.INVOICE){
          title = 'Debtor';
        } else {
          title = terminology ? terminology.SUPPLIER : title;
        }
      }
    } else {
      title = terminology ? terminology.SUPPLIER : title;
    }
    return title;
  }

  return (
    <React.Fragment>
      {/*------------------Abn/Name Search------------------*/}
      <Modal
        show={abnSearchModal}
        onHide={(e) => setAbnSearchModal(false)}
        size="lg"
      >
        <AbnSearch
          searchBy={searchBy}
          entitySearchKey={entitySearchKey.trim()}
          handleAbnSearch={handleAbnSearch}
          onClose={(e) => setAbnSearchModal(false)}
        />
      </Modal>
      {/*---------------------------------------------------------*/}

      <Modal.Header closeButton>
        <h4 className="modal-title">
          New { getPopupTitle() }
        </h4>
      </Modal.Header>

      <Modal.Body>
        {entityData == null && (
          <div className="row mb-4">
            <div class="col-lg-12">
              {entitySearchApi.loading === true && <LoadingSpinner />}
              {!request && (
                <>
                  <FormRow className="m-t-lg">
                    <h6 className="m-md">
                      Please search using &nbsp;
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          id="orgname"
                          type="radio"
                          value="orgname"
                          className="custom-control-input"
                          onClick={(e) => setSearchBy(0)}
                          checked={searchBy === 0 ? true : false}
                        ></input>
                        <label className="custom-control-label" for="orgname">
                          Name
                        </label>
                      </div>
                      &nbsp;
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          id="orgnum"
                          type="radio"
                          value="orgnum"
                          className="custom-control-input"
                          onClick={(e) => setSearchBy(1)}
                          checked={searchBy === 1 ? true : false}
                        ></input>
                        <label className="custom-control-label" for="orgnum">
                          ABN
                        </label>
                      </div>
                    </h6>
                  </FormRow>

                  <FormRow>
                    <FormColumnMd col={8}>
                      <TextInput
                        name="abn_number"
                        placeholder={`Search by ${searchBy === 0 ? "Organisation name" : "ABN"
                          }`}
                        class="form-control input-sm float-left m-l-md"
                        value={entitySearchKey}
                        onChange={({ target }) =>
                          setEntitySearchKey(target.value)
                        }
                        type="normal"
                        readonly={readonly}
                      ></TextInput>
                    </FormColumnMd>

                    <FormColumnMd col={4}>
                      <GeneralButton
                        onClick={(e) => searchEntity()}
                        className="btn btn-primary "
                        faIcon="fa fa-search"
                        name="Search"
                        disabled={accountType === "intl"}
                      ></GeneralButton>
                    </FormColumnMd>
                  </FormRow>
                  <FormRow className="mt-4">
                    <FormColumnMd col={12}>
                      <div className="custom-control custom-checkbox custom-control-inline">
                        <input
                          id={`intCustomer`}
                          type="checkbox"
                          className="custom-control-input"
                          name="intCustomer"
                          onChange={(e) => accountTypeChange(e)}
                          checked={accountType === "intl" ? true : false}
                        />
                        <label className="custom-control-label" for={`intCustomer`}>
                          Sole trader, trust or international
                        </label>
                      </div>
                    </FormColumnMd>
                  </FormRow>
                  {accountType == "intl" &&
                    <FormRow className="mt-4">
                      <FormColumnMd col={12}>
                        <button className="btn btn-primary mr-4 pull-right" onClick={() => { setRequest(true); setGoNext(true); }}>
                          Next
                        </button>
                      </FormColumnMd>
                    </FormRow>
                  }
                </>
              )}
            </div>
          </div>
        )}
        {entityData !== null && entityData.entityFound && (
          <>
            {entityData.type == "api" ? (
              <MapEntity
                contacts={entityData.entityContacts}
                selected_contact={entityData.contact_id}
                entityDetails={entityData.entityDetails}
                onCloseModal={() => {
                  onCloseModal();
                }}
                source={source}
              />
            ) : (
              <CreateInstantEntity
                onCloseModal={() => onCloseModal()}
                searchData={{
                  abn: asicResponse.abn,
                  organisation: asicResponse.organisation,
                  searchBy: searchBy,
                }}
                source={source}
                title={title}
                accountType={accountType}
                handleSelectedItem={handleSelectedItem}
              />
            )
            }
          </>
        )}
        {goNext &&
          <CreateInstantEntity
            onCloseModal={() => onCloseModal()}
            searchData={{}}
            source={source}
            title={title}
            accountType={accountType}
            handleSelectedItem={handleSelectedItem}
          />
        }
      </Modal.Body>
    </React.Fragment>
  );
}

export default NewTrader;
