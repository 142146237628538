import React, { useState } from "react";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";

import authService from "../../../../services/authService";
import useApi from "../../../../hooks/useApi";

import { toast } from "react-toastify";
import { Link, browserHistory } from "react-router";

import SetPassword from "./setPassword";

function ChangePassword() {
  //------------Api Services--------------------------------------------

  const passwordResetApi = useApi(authService.resetOldPassword);
  const [password, setPassword] = useState(null);

  //-----------------Calling Api to Reset new passowrd----------------//
  const resetPassword = async (oldPassword, password) => {
    const response = await passwordResetApi.request(oldPassword, password);
    if (response.success === false) {
      toast.error(response.message);
      return false;
    }
    toast.success(response.message);
    return true;
  };
  const handleClientSubmission = (oldPassword, password) => {
    resetPassword(oldPassword, password);
  };

  return (
    <>
      <Helmet>
        <title>Change Password | {global.config.title}</title>
      </Helmet>

      {passwordResetApi.loading && <LoadingSpinner />}

      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-6"><h6>Change Your Password</h6></div>
            <div className="col-lg-6">
              <Link className="btn btn-light pull-right text-secondary ml-2 cursor-link" onClick={() => browserHistory.goBack()}><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 p-4">

              <SetPassword
                handleClientSubmission={handleClientSubmission}
                setData={setPassword}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
