import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = props => {

  const isWeekday = (date) => {
    let day = date.getDay();
    if(props.enableWeekDays && props.enableWeekDays === true)
      return true;
    else
      return day !== 0 && day !== 6;
  }

  return(
    <>
      {props.type === "horizontal" && (
        <div className="form-group">
          {!props.hideLabel && (
            <label style={{ paddingTop: "7px" }} className="col-lg-4 form-label">
              {props.label}
            </label>
          )}
          <div className="col-lg-8">
            <DatePicker
              name="date"
              onChange={props.onChange}
              onBlur={props.onBlur}
              selected={props.selected}
              value={props.selected}
              className={props.class ? props.class : "form-control"}
              dateFormat="d/M/y"
              autoComplete="off"
              placeholderText={props.placeholder ? props.placeholder : ""}
              minDate={props.minDate}
              maxDate={props.maxDate}
              filterDate={isWeekday}
            />
          </div>
        </div>
      )}

      {props.type === "vertical" && (
        <div className="form-group">
          {!props.hideLabel && (
            <label style={{ paddingTop: "7px" }}>{props.label}</label>
          )}
          <DatePicker
            name="date"
            onChange={props.onChange}
            onBlur={props.onBlur}
            selected={props.selected}
            value={props.selected}
            className={props.class ? props.class : "form-control input-sm"}
            dateFormat="d/M/y"
            autoComplete="off"
            placeholderText={props.placeholder ? props.placeholder : ""}
            minDate={props.minDate}
            maxDate={props.maxDate}
            filterDate={isWeekday}
          />
        </div>
      )}

      {props.type === "normal" && (
        <div className={ props.className ? props.className:"customDatePickerWidth"}>
          <DatePicker
            name="date"
            onChange={props.onChange}
            onBlur={props.onBlur}
            selected={props.selected}
            // {... ( props.selected ? { selected: props.selected } : {})}
            value={props.selected}
            className={props.class ? props.class : "form-control input-sm splus-datepicker"}
            dateFormat={props.format ? props.format : "d/M/y"}
            autoComplete="off"
            placeholderText={props.placeholder ? props.placeholder : ""}
            minDate={props.minDate}
            maxDate={props.maxDate}
            readOnly={props.readOnly ? props.readOnly : false}
            filterDate={isWeekday}
          />
        </div>
      )}
    </>
  )};

export { DateTimePicker };