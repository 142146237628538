import React, { useEffect, useState, useContext } from "react"
import { WarningCard } from "../../components/cards";
import productShortName from "../../../../utils/GetProductShortName";
import useIsMounted from "../../../../hooks/useIsMounted";
import { CurrencyLabel } from "../../../common/inputs";
import useApi from '../../../../hooks/useApi';
import widgetService from "../../../../services/invTransactions";
import EntityContext from "../../../../context/entityContext";

export default function ApprovedScheduled(){

    const isMounted = useIsMounted();
    const { selectedBusinessType, selProduct, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);

    //------------Api Services-----------------------------------
    const approvedInvoicesApi = useApi(widgetService.getApprovedScheduled);

    const fetchApprovedInvoices = async () => {
        const response = await approvedInvoicesApi.request({ entity_id: entityDetails.a_guid, selectedBusinessType });
        if(response && isMounted()){
            setData({
                topLabel: "Approved And Scheduled",
                topValue: <CurrencyLabel decimal={2} value={response.data.total_inv_amt} />,
                leftLabel: response.data.isPrimary ? "Debtors" : "Clients",
                leftValue: response.data.isPrimary ? response.data.debtor_count : response.data.client_count,
                rightLabel: "Invoices",
                rightValue: response.data.invoice_count,
                bottomRightLabel: "",
                primaryBtnRoute: `/client/${productShortName(selProduct)}/sale-invoices?status=approved_for_payment`
            })
        }
    }

    useEffect(() => {
        fetchApprovedInvoices();
    }, [selectedBusinessType, entityDetails])

    return(
        <>
            <WarningCard
                loading={approvedInvoicesApi.loading}
                data={data}
                qrystring={true}
            />
        </>
    )
}