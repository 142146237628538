import React, { useEffect, useContext, useState } from "react"
import { Link } from "react-router";
import {
    FormColumnMd,
} from "../../../common/containers/formContainer";
import useApi from '../../../../hooks/useApi';
import RoleChecker from "../../../../utils/roleChecker";
import entityService from "../../../../services/entityService";
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";
import CardSkelton from "../../components/cards/_cardSkeltonTypeTwo";

export default function MappedDebtors(){

    const { constants, entity_id } = authService.getCurrentUser();
    const [debtorCount, setDebtorCount] = useState(0);
    const { entityDetails } = useContext(EntityContext);

    //------------Api Services-----------------------------------
    const debtorsApi = useApi(entityService.getEntityDebtorsList);

    const fetchDebtors = async () => {
        const response = await debtorsApi.request(entity_id, constants.PRODUCTS.INVOICE);
        if(response){
            setDebtorCount(response.data.length);
        }
        
    }

    useEffect(() => {
        fetchDebtors();
    }, [])

    return(
        <>
            <FormColumnMd col={12}>
                {debtorsApi.loading === true ? (
                    <CardSkelton col={12} top={true} /> 
                ) : 
                (
                    RoleChecker.check(entityDetails.products, constants.ROLES.CLIENT) && (
                        <div className="card">
                            <div className="card-body">
                            <h4 className="card-title mb-1">{"Debtor Management"}</h4>
                            <div className="mb-2">
                                <h6 className="d-flex justify-content-between text-sm mt-3 ">
                                    Debtors
                                    <Link to={'/client/if/mapped-debtors'} >
                                    View All
                                    </Link>
                                </h6>
                                <h4 className="mb-0 font-weight-bold">{debtorCount}</h4>
                            </div>

                            <div className="row ">
                                <div className="col">
                                    <div className="mb-1 d-flex align-items-center">
                                        <div className="w-10 h-10 bg-primary rounded-full mr-2 " />
                                            Active
                                        </div>
                                    </div>
                                    <div className="col col-auto">
                                        <h6 className="mb-0">{debtorCount}</h6>
                                    </div>
                            </div>
                            
                            <div className="row">
                                <div className="col">
                                    <div className="mb-1 d-flex align-items-center">
                                        <div className="w-10 h-10 rounded-full bg-danger  mr-2 " />
                                            Inactive
                                        </div>
                                    </div>
                                    <div className="col col-auto">
                                        <h6 className="mb-0">0</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </FormColumnMd>
        </>
    )
}