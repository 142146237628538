import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { Picker } from '../common/inputs';

import coreService from "../../services/coreService";
import authService from "../../services/authService";
import useApi from '../../hooks/useApi';

function DebtorsList({ onSelectItem, selectedDebtor = null, bp = null, defaultValue = { label: "Select Debtor (Secondary Account)", value: null } }) {
    const { constants } = authService.getCurrentUser();
    const [selectedItem, setSelectedItem] = useState(defaultValue);
    const { request, data, loading } = useApi(coreService.getDebtorsList); // Custom hook for HTTP Requests

    const prevBp = useRef();

    useEffect(() => {

        if (bp != null && bp !== prevBp.current) {
            prevBp.current = bp;
            request({ category: constants.CATEGORY.DEBTOR, bp }); // Calling HTTP service
        }

        if (selectedDebtor !== null && data.length > 0) {
            const debtorDetails = data.find(obj => obj.value == selectedDebtor);
            if (debtorDetails)
                setSelectedItem({ label: debtorDetails.label, value: debtorDetails.value })
        } else if (selectedDebtor === null)
            setSelectedItem(defaultValue);

    }, [bp, selectedDebtor, data]);

    return (
        <>
            <>
                {loading == true && <LoadingSpinner />}
                <Picker items={data}
                    selectedItem={selectedItem}
                    onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
                />
            </>
        </>
    )
};
export default DebtorsList;