import React, { useEffect, useContext, useState } from "react";
import { DangerCard } from "../../components/cards";
import useApi from '../../../../hooks/useApi';
import EntityContext from "../../../../context/entityContext";
import loanService from "../../../loan/services/loanTransactions";
import { CurrencyLabel } from "../../../common/inputs";
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import LoanCreation from "./_loanCreation";

function CreateLoan(props) {

    const { selectedBusinessType, entityDetails } = useContext(EntityContext);
    const [openNewLoanModal, setOpenNewLoanModal] = useState(false);

    //------------Api Service--------------------------------------------


    return (
        <>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body">
                        <div className="text-center ">
                            <Link onClick={() => {setOpenNewLoanModal(true)}}>
                                <div className="mr-100">
                                    <div className="add-round">
                                        <i className="fa fa-plus fa-2x"></i>
                                    </div>
                                    <h5 className="mt-2 text-muted font-weight-bold f-15">Add Loan</h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={openNewLoanModal}
                onHide={() => {setOpenNewLoanModal(false)}}
                center
                //className="animated bounceInRight "
            >
                <LoanCreation
                    onClose={() => {setOpenNewLoanModal(false); }}
                    reload={() => {setOpenNewLoanModal(false); props.reload(true)}}
                ></LoanCreation>
            </Modal>
        </>
    )
}
export default CreateLoan;