import React from "react";

const RadioButton = (props) => (
  <React.Fragment>
    <div className={props.className ? props.className : "custom-control custom-radio custom-control-inline"}>
      <input
        name={props.name}
        value={props.value}
        className={props.class ? props.class : "custom-control-input"}
        type="radio"
        checked={props.checked}
        onChange={(e) => props.onChange(e)}
        id={props.id ? props.id : "custom_radio_id"}
        disabled={props.disable ? props.disable : ""}
      />

      <label className="custom-control-label" htmlFor={props.id ? props.id : "custom_radio_id"}> {props.label}</label>
    </div>
  </React.Fragment>
);

const RadioImageButton = (props) => (
  <React.Fragment>
    <>
      <div
        className={props.class ? props.class : "custom-img-radio"}
      >
        <input
          name={props.name}
          value={props.value}
          className={props.inputClass ? props.inputClass : "radio-img-input"}
          type="radio"
          checked={props.checked}
          onChange={(e) => props.onChange(e)}
          id={props.id ? props.id : "custom_img_radio_id"}
          disabled={props.disable ? props.disable : ""}
        />
        <label
          for={props.id ? props.id : "custom_img_radio_id"}
          class={props.labelClass ? props.labelClass : "radio-img-label"}
        >
          <img
            src={props.imgsrc}
            alt={props.imgAlt ? props.imgAlt : "image1"}
            class={props.imgClass ? props.imgClass : "radio-image"}
          />
          <span
            class={props.labelImgClass ? props.labelImgClass : "radio-img-text"}
          >{props.label}</span>
        </label>
      </div>
    </>
  </React.Fragment>
);

export { RadioButton, RadioImageButton };
