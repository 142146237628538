import React, { Component } from "react";
import ErrorCard from "../../../img/icons/errorcard.svg"
class Error extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <>

            <div class="h-100">
                <div class="d-flex align-items-center h-100">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-8 offset-sm-2 text-center ">
                                <img src={ErrorCard} width="100" class="mb-4"></img>
                                <h5 class="">Sorry! Something went wrong</h5>
                                <p class="mb-4">The server encountered something unexpected that didn't allow it to complete the request. We apologize.
                                    You can go back to main page:</p>
                                <a class="btn btn-primary" href={this.props.from === "client" ? "/client/home" : "/dashboard"} title="home url">
                                    Go home
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default Error;