import React, { useEffect, useState, useRef } from "react";
import { FeePicker } from '../common/inputs';
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { FormGroup } from "../common/containers/formContainer";
import { GeneralButton } from "../common/inputs";
import ReactTooltip from 'react-tooltip';

import CreateFee from "../admin/fee/create";

import transactionService from "../../services/invTransactions";
import useApi from '../../hooks/useApi';

function FeeList({ onSelectItem, getDefaultFee = null, selectedFee = null, clientGuid = null, type = null, label = null, defaultValue = { label: "Select Fee", value: null }, transGuid = null, transRef = null, transOwner = null, handleFeeUpdation = null, disabled = false, addNewButton = false, editButton = false }) {

    const prevClient = useRef();
    const [feeModal, setFeeModal] = useState(false);
    const [data, setData] = useState([]);
    const [selectedFeeType, setSelectedFeeType] = useState([]);
    const [selectedItem, setSelectedItem] = useState(defaultValue);
    const feeApi = useApi(transactionService.getFeeDetails); // Custom hook for HTTP Requests
    const getTransFeeApi = useApi(transactionService.GetFeeByTransId); // Custom hook for HTTP Requests

    // ------------- Fetching the list of all the available fees ------------------ //
    const getFeeList = async () => {

        setSelectedItem(defaultValue);
        onSelectItem(defaultValue);

        if (clientGuid !== null) {
            const { data } = await feeApi.request({ entity_id: clientGuid, country: null }); // Calling HTTP service
            setData(data.feeList);

            // Callback function to pass other default charges to the Parent component
            if (getDefaultFee !== null)
                getDefaultFee(data.defaultFees);

            if (selectedFee === null) { // If selectedFee is null, set default client fee
                const filteredData = data.feeList.find(obj => obj.value === data.defaultFees.disc_fee);
                if (filteredData) {
                    setSelectedItem(filteredData);
                    onSelectItem(filteredData);
                }
            } else { // Set selected fee as default fee
                const filteredData = data.feeList.find(obj => obj.value === selectedFee);
                if (filteredData) {
                    setSelectedItem(filteredData);
                    onSelectItem(filteredData);
                }
            }
        }
    }
    // ---------------------------------------------------------------------------- //

    // ------------- Callback function to trigger after the Fee creaation --------- //
    const handleAddNewFee = async (feeGuid, feeName) => {
        await getFeeList();
        setSelectedItem({
            label: feeName,
            value: feeGuid
        });
        onSelectItem({ value: feeGuid });

        if(handleFeeUpdation)
            handleFeeUpdation(feeGuid);
    }
    // ---------------------------------------------------------------------------- //

    // ------------- Display/Hide the Fee creation modal --------- //
    const handleFeeModal = (state = true) => {
        setSelectedFeeType([]);
        setFeeModal(state);
    }
    // ------------------------------------------------------------- //

    // -------------------- Handle Fee Edit ------------------------ //
    const handleFeeEdit = async (state = true) => {
        if(transGuid){ // If 'transRef' is provided, Create a copy of the Fee and assign it to the transaction.
            const response = await getTransFeeApi.request({ trans_id: transGuid, selected_fee: selectedItem.value }); // Calling HTTP service
            if(!response.data.fee_exists){ // If fee details not found, create a new one.
                let newFee = data.find( obj => obj.fee_guid === selectedItem.value );
                if(newFee){
                    newFee['fee_guid'] = null;
                    //newFee['trans_id'] = transGuid;
                    //newFee['parent_fee_guid'] = selectedItem.value;
                    newFee['fee_name'] = `${selectedItem.label} - ${transRef}`;
                    newFee['feeSchedule'] = newFee.feeSchedule.map( schedule => {
                        schedule.id = null;
                        return schedule;
                    });
                    newFee['create_copy'] = true;
                    setSelectedFeeType(newFee);
                }
            }
            else
                setSelectedFeeType(response.data.fee_details);
        }
        setFeeModal(state);
    }

    // ------------------------------------------------------------- //

    const RenderPicker = () =>
        <>

            {((selectedFee === "" || selectedFee === null) && clientGuid !== null) && <>&nbsp; <i data-tip="The default fee is not set for the selected client." class="fa fa-info-circle"></i></>}

            <div className="input-group">
                <div className={`col-lg-10 p-0`}>
                    <FeePicker items={data}
                        selectedItem={selectedItem}
                        onSelectItem={(selectedOption) => {
                            setSelectedItem(selectedOption);
                            onSelectItem(selectedOption);
                        }}
                        isDisabled={disabled}
                    />
                </div>

                <div className="input-group-append">
                    {editButton && <GeneralButton disabled={clientGuid === null || disabled === true} onClick={() => handleFeeEdit(true)} className="btn btn-outline-secondary btn-sm fff" title="Edit selected fees here" name={<i className="fa fa-edit"></i>} /> }
                    {addNewButton && <GeneralButton disabled={clientGuid === null || disabled === true} onClick={() => handleFeeModal(true)} className="btn btn-outline-secondary btn-sm ffff" title="Create a new new fees here" name={<i className="fa fa-plus"></i>} />}
                </div>
                
            </div>

            <ReactTooltip backgroundColor="#11698e" />
        </>

    useEffect(() => {
        if (clientGuid !== prevClient.current) {
            prevClient.current = clientGuid;
            getFeeList();
        }
    }, [clientGuid, selectedFee]);

    return (
        <>
            { (feeApi.loading === true || getTransFeeApi.loading === true) && <LoadingSpinner />}
            {/*------------------Quick Comms Modal-------------------*/}
            <Modal show={feeModal} onHide={e => { handleFeeModal(false); getFeeList(); } } size="lg">
                <CreateFee
                    handleAddNewFee={handleAddNewFee}
                    selectedFeeType={selectedFeeType}
                    reloadData={e => handleFeeModal(false)}
                    entity={transOwner}
                />
                <div style={{ width: 500, height: 20 }}></div>
            </Modal>
            {/*---------------------------------------------------------*/}
            {label !== null ?
                <FormGroup
                    type={type}
                    label={label}
                    input={<RenderPicker />}
                /> :
                <RenderPicker />
            }
        </>
    )
};
export default FeeList;