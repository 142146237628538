/**Reject of supplier-intiated invoice by customer */
import React, { useState } from "react";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Modal from 'react-bootstrap/Modal';
import {
  FieldContainer,
  FormRow,
  FormColumnLg,
  ModalHeader,
} from "../../../../common/containers/formContainer";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import { TextInput,TextAreaInput } from "../../../../common/inputs/textInput/textInput";
import { AddButton } from "../../../../common/containers/button";

import Validation from "../../../../supply-chain/utils/validation";
import useApi from "../../../../supply-chain/hooks/useApi";
import commonService from "../../../../supply-chain/services/commonService"
import auth from "../../../../../services/authService";

function BulkReject(props) {
   
  const { sc_invoice_status } = auth.getCurrentUser();
  const [data, setData] = useState({});
  const createApi = useApi(commonService.rejectInvoices);
  const invoices = props.invoices;

  const handleValueChange = ({ currentTarget: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  const { validate } = Validation(); // Instance for validation library.
  const schema = Joi.object()
    .keys({
      reason: Joi.string().required().label("Reason"),
      signed_by: Joi.string().required().label("Digitaly signed By"),
    })
    .unknown(true);
  const save = async () => {
    const rejectData = {...data};
    const errors = validate(rejectData, schema);
    if (errors) return;
    rejectData['userType'] = props.userType ? props.userType : null
    const result = await createApi.request(rejectData, invoices, props.trans_status, props.product_type);
    props.onHandleReload();
    toast.success(result.data.message);
  };
  return (
    <>
      {/* Load approval by customer if the status is awaiting approval  */}
      {( props.trans_status == sc_invoice_status["awaiting_approval"] || props.trans_status == sc_invoice_status["approved_for_payment"] ) && (
          
        <Modal
          show={props.open}
          onHide={props.onClose}
          backdrop="static"
          centered
          reload={props.onHandleReload}
        >
          {createApi.loading === true && <LoadingSpinner />}

          <Modal.Header closeButton><h4 className="modal-title">Reject Invoice</h4></Modal.Header>
          <Modal.Body>
            <div className="row mx-0">
              <TextAreaInput 
                label="Reason" name="reason" value={data.reason} label="Reason" onChange={handleValueChange} rows={10} type="" />
            </div>
            <div className="row mx-0 mt-4">
              <TextInput name="signed_by" label="Signed By" value={data.signed_by} onChange={handleValueChange} type="normal" />
            </div>
            {/* <FormRow>
              {" "}
              <FieldContainer
                label="Reason"
                inputClass="col-lg-8"
                labelClass="col-lg-4"
                input={
                  <TextInput
                    name="reason"
                    value={data.reason}
                    label="Reason"
                    onChange={handleValueChange}
                    type=""
                  ></TextInput>
                }
              />
            </FormRow>
            <FormRow>
              {" "}
              <FieldContainer
                label="Signed By"
                inputClass="col-lg-8"
                labelClass="col-lg-4"
                input={
                  <TextInput
                    name="signed_by"
                    value={data.signed_by}
                    label="Signed By"
                    onChange={handleValueChange}
                    type=""
                  ></TextInput>
                }
              />
            </FormRow> */}
          </Modal.Body> 
          <Modal.Footer>
              <FormRow>
                <FormColumnLg>
                  <AddButton
                    onClick={save}
                    label="Save"
                  ></AddButton>
                </FormColumnLg>
              </FormRow>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default BulkReject;
