import React, { useState, useContext } from "react";
import ValidateSheetFields from "../utils/validateSheetFields";
import Joi from "joi-browser";

const UploaderContext = React.createContext()

export function useUploader() {
    return useContext(UploaderContext)
}

export function SupplierProvider({ children }) {

    const [suppliers, setSuppliers] = useState([]); // Context state to store the supplier rows
    const [uploadedFiles, setUploadedFiles] = useState([]); // Context state to store the list of suppliers
    const [uniqueSuppliers, setUniqueSuppliers] = useState([]); // After removing the duplicate prospects
    const [duplicateSuppliersInSheet, setDuplicateSuppliersInSheet] = useState([]); // store details of suppliers with duplicate email
    const [duplicateAbnSuppliersInSheet, setDuplicateAbnSuppliersInSheet] = useState([]); // store details of suppliers with duplicate abn
    const [proceedNext, setProceedNext] = useState(true); // Enable or Disable 'Next' button within each component
    const [readyToUpload, setReadyToUpload] = useState([]); // Context state to store the suppier rows after validation, which are free of error
    const [hideNextBtn, sethideNextBtn] = useState(false);
    const [hidePreviousBtn, sethidePreviousBtn] = useState(false);
    const [nextLabel, setnextLabel] = useState("Next");
    const [prevLabel, setprevLabel] = useState("Previous");

    //------------------Validation Schema-----------------------------
    const schema = Joi.object() // Form validation schema object
        .keys({

            Organisation: Joi.string()
                .required()
                .error(() => {
                    return { message: "Please enter a valid Organisation Name" };
                }),

            First_Name: Joi.string()
                .required()
                .error(() => {
                    return { message: "Please enter a valid First Name" };
                }),

            Last_Name: Joi.string()
                .required()
                .error(() => {
                    return { message: "Please enter a valid Last Name" };
                }),
            ABN: Joi.number()
                .integer()
                .required()
                .error(() => {
                    return { message: "Please enter a valid ABN" };
                }),

            Email: Joi.string()
                .required()
                .email()
                .error(() => {
                    return { message: "Please enter a valid Email" };
                }),

            // Phone: Joi.string()
            //     .regex(/^$|^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
            //     .error((errors) => {
            //         return { message: "Please enter a valid Phone Number" };
            //     }),

        })
        .unknown(true);

    //---------------------Handle Validation-------------------//
    const validate = (excelData) => {
        const options = { abortEarly: true };

        excelData.map(item => {
            const { error } = Joi.validate(item, schema, options);
            if (error) {
                for (let errobj of error.details)
                    item.error = errobj.message
            }

            return item;
        })
    };


    // Method to handle uploading file items
    const handleUploadItems = (excelData, filesList) => {

        const fieldsToValidate = ['Organisation', 'First_Name', 'Last_Name', 'ABN', 'Email'];
        const validateRes = ValidateSheetFields(excelData, fieldsToValidate);

        if (validateRes) {
            filterSupplier(excelData);
            setSuppliers(excelData);

            let list = [...uploadedFiles];
            filesList.map((fileItem) => {
                list.push(fileItem);
            });
            setUploadedFiles(list);
        }
    };

    // Filter duplicate suppliers and validate fields

    const filterSupplier = (excelData) => {
        // let suppliersList = [...suppliers];
        let duplicateCount = 0;
        let suppliersList = [];
        let duplicateSuppliers = [];

        excelData.map((supplierItem, index) => {
            supplierItem.index = index;
            supplierItem.error = supplierItem.error !== undefined ? supplierItem.error : null;

            if (supplierItem.Email) {
                if (suppliersList.findIndex(obj => obj.Email == supplierItem.Email.toLowerCase() || obj.Email == supplierItem.Email) === -1) {
                    if (supplierItem.ABN) {
                        if (suppliersList.findIndex(obj => obj.ABN == supplierItem.ABN) === -1) {
                            suppliersList.push({ ...supplierItem, Email: supplierItem.Email.toLowerCase() });
                        } else {
                            duplicateSuppliers.push({ ...supplierItem, ABN: supplierItem.ABN, Email: supplierItem.Email.toLowerCase() });
                        }
                    } else {
                        suppliersList.push({ ...supplierItem, Email: supplierItem.Email.toLowerCase() });
                    }
                } else {
                    duplicateSuppliers.push({ ...supplierItem, Email: supplierItem.Email.toLowerCase() });
                }
            } else {
                if (supplierItem.ABN) {
                    if (suppliersList.findIndex(obj => obj.ABN == supplierItem.ABN) === -1) {
                        suppliersList.push({ ...supplierItem, ABN: supplierItem.ABN });
                    } else {
                        duplicateSuppliers.push({ ...supplierItem, ABN: supplierItem.ABN });
                    }
                } else {
                    suppliersList.push(supplierItem);
                }
            }
        });

        setDuplicateSuppliersInSheet(duplicateSuppliers);
        setUniqueSuppliers(suppliersList);
        validate(excelData);

        //if (suppliers.length == 0) setSuppliers(excelData);
    }

    // This function will trigger from the Edit supplier Modal
    const updateSupplier = data => {
        let suppliersList = [...suppliers];
        data['error'] = null;
        suppliersList[data.index] = data;
        setSuppliers(suppliersList);
        filterSupplier(suppliersList);
    }

    // Function to delete supplier
    const deleteSupplier = index => {
        let suppliersList = suppliers.filter(obj => obj.index !== index);
        setSuppliers(suppliersList);
        filterSupplier(suppliersList);
    }

    const value = {
        suppliers,
        handleUploadItems,
        uploadedFiles,
        proceedNext,
        setProceedNext,
        hideNextBtn,
        sethideNextBtn,
        hidePreviousBtn,
        sethidePreviousBtn,
        nextLabel,
        setnextLabel,
        prevLabel,
        setprevLabel,
        uniqueSuppliers,
        duplicateSuppliersInSheet,
        updateSupplier,
        deleteSupplier,
        readyToUpload, 
        setReadyToUpload
    }

    return <UploaderContext.Provider value={value}>{children}</UploaderContext.Provider>
}

