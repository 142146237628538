import React, { useEffect, useState, useContext } from "react";
import UseApi from "../../../../hooks/useApi";
import useIsMounted from "../../../../hooks/useIsMounted";
import CommonService from "../../../supply-chain/services/commonService";
import EntityContext from "../../../../context/entityContext";
import CardSkelton from "../../components/cards/_cardSkelton";
import auth from "../../../../../src/services/authService";
import DoughnutCard from "../general/doughnutCard";

function ScPurchaseCommon({ nickName, feciltyCount, menu = null, onClick = null, to = "/client/home" }) {
    
    const isMounted = useIsMounted();
    const { constants } = auth.getCurrentUser();
    const { entityDetails, accountDetails } = useContext(EntityContext);
    const CountApi = UseApi(CommonService.getClientGraph);
    const [limit, setLimit] = useState(0);
    const [graphData, setGraphData] = useState({});
    const [bankDetail, setBankDetail] = useState(null);
    const [facilityDefaults, setFacilityDefaults] = useState(false);

    const fetchData = async () => {
        const { data } = await CountApi.request({ "userId": entityDetails.a_guid, "selRole": 'Purchase', 'product_type' : constants.PRODUCTS.SUPPLYCHAIN });

        if(isMounted()){
            const graphValues = [
                { "name": "Approved For Payment", "value": data.inv_apprd_payment_sum, "icon": "fa-thumbs-up", "color": "primary", "border": "rgba(56, 203, 137, 1)", "bg": "rgba(56, 203, 137, 1)" },
                { "name": "Open", "value": data.allotedSum, "icon": "fa-folder-open-o", "color": "warning", "border": "rgba(255, 200, 92, 1)", "bg": "rgba(255, 200, 92, 1)" },
                { "name": "Overdue", "value": data.totalOverdue, "icon": "fa-clock-o", "color": "danger", "border": "rgba(237, 85, 101, 1)", "bg": "rgba(237, 85, 101, 1)" },
                { "name": "Availability", "value": data.balanceAvailable, "icon": "fa-check-square-o", "color": "info", "border": "rgba(17, 105, 142, 1)", "bg": "rgba(17, 105, 142, 1)" }
            ];
            setGraphData(graphValues);
            setLimit(data.limit);
        }
    }
    //------------------------------------------------------------
    useEffect(() => {
        fetchData();
    }, []);

    //------------------------------------------------------------
    useEffect(() => {
        const accountInfo = accountDetails.find(item => item.product == constants.PRODUCTS.SUPPLYCHAIN);
        setBankDetail(accountInfo ? accountInfo : null);
    }, [accountDetails]);
    //------------------------------------------------------------
    useEffect(() => {
        const productSettings = entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.SUPPLYCHAIN);
        setFacilityDefaults(productSettings ? productSettings.default_settings_available : false);
    }, [entityDetails.productSettings]);

    return (
        <>
            <div className={`col-sm-6 col-lg-4 col-xl-4 align-center card-hover ${(!bankDetail || !facilityDefaults) ? 'disabled-card' : ''}`}>
                {CountApi.loading === true ? <CardSkelton col="12" type="graph"></CardSkelton> : (
                    (graphData && limit) ? (
                        <>
                            <DoughnutCard 
                                bgColor="secondary" 
                                graphData={graphData} 
                                fecilityLimit={limit} 
                                feciltyCount={feciltyCount} 
                                empty={false} 
                                menu={menu}
                                onClick={onClick}
                                to={to}
                                type={"show_bank_detail"}
                                bankDetail={bankDetail}
                                nickName={nickName}
                            />

                        </>
                    ) : (
                        <DoughnutCard 
                            bgColor="secondary" 
                            graphData={[]} 
                            fecilityLimit={0} 
                            empty={true} 
                            feciltyCount={feciltyCount}
                            menu={menu}
                            onClick={onClick}
                            to={to}
                            type={"show_bank_detail"}
                            bankDetail={bankDetail}
                            nickName={nickName}
                        />
                    )
                )}
            </div>
        </>
    )
}
export default ScPurchaseCommon;