import React from "react";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DateTimePicker({ onSelectDate, enableWeekDays = false, onBlur = () => { }, autoFocus = false, onKeyDown, name = "", onChange, selectedDate = "", placeholder = null, showTimeSelect = false, dateFormat = "YYYY-MM-DD", readOnly = false, type = "normal", hideLabel = true, label = null, reference = null, ...rest }) {

    if (selectedDate == "")
        selectedDate = placeholder !== null ? null : new Date();

    const handleDateChange = date => {
        if (dateFormat !== "default" && showTimeSelect === false) {
            const FormattedDate = date !== null ? moment(date)
                .format(dateFormat)
                .toString() : null;

            onChange ? onChange({ target: { name, value: FormattedDate } }) : onSelectDate(FormattedDate);
        }
        else
            onSelectDate(date);
    };

    const isWeekday = (date) => {
        let day = date.getDay();
        if(enableWeekDays)
            return true;
        else
            return day !== 0 && day !== 6;
    }

    const renderDatePicker = () =>
        <DatePicker
            readOnly={readOnly}
            name="date"
            id={name}
            onChange={handleDateChange}
            onKeyDown={onKeyDown}
            selected={
                typeof selectedDate !== "object"
                    ? new Date(Date.parse(selectedDate))
                    : selectedDate
            }
            className="form-control"
            showTimeSelect={showTimeSelect}
            dateFormat={showTimeSelect === false ? "d/M/y" : "dd/MM/yyyy h:mm aa"}
            timeFormat="hh:mm aa"
            timeIntervals={30}
            timeCaption="time"
            autoComplete="off"
            placeholderText={placeholder ? placeholder : ""}
            autoFocus={autoFocus}
            onBlur={onBlur}
            ref={reference}
            filterDate={isWeekday}

            minDate={rest.minDate}
            maxDate={rest.maxDate}
        />

    return (
        <>
            {/*------------Horizontal Variant--------------*/}
            {type === "horizontal" && (
                <div className="form-group">
                    {!hideLabel && label !== null && (
                        <label style={{ paddingTop: "7px" }} className="col-lg-4 form-label"> {label}</label>
                    )}
                    <div className="col-lg-8"> {renderDatePicker()} </div>
                </div>
            )}
            {/*--------------Vertical Variant----------------*/}
            {type === "vertical" && (
                <div className="form-group">
                    {!hideLabel && label !== null && (
                        <label  >{label}</label>
                    )}
                    {renderDatePicker()}
                </div>
            )}
            {/*----------------Normal Variant-----------------*/}
            {type === "normal" && (
                <div className="customDatePickerWidth">
                    {renderDatePicker()}
                </div>
            )}
        </>
    )
}