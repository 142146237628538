import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import ReactTable from "react-table";
import XLSX from "xlsx";
import { make_cols } from "../common/excelToJsonParser/MakeColumns";
import "../../styles/react-table.css";
import "react-table/react-table.css";
import "react-flags-select/css/react-flags-select.css";
import "react-confirm-alert/src/react-confirm-alert.css";
//import { labels } from "../../config/countryLabels.json";
import auth from "../../../src/services/authService";
import { labels } from "../../utils/companyLabel";
class Form extends Component {
  state = {
    data: {},
    user: {},
    userImage: "",
    ajaxRequest: false,
    errors: {},
    doNotShowPagination: false,
  };

  styles = {
    rightpanel: {
      width: "73.666667%",
    },
    submitBtnHorizontal: {
      width: "43%",
      marginLeft: "35%",
    },
    tableLink: {
      color: "grey",
    },
  };

  showAjaxLoader = () => {
    this.setState({ ajaxRequest: true });
  };

  hideAjaxLoader = () => {
    this.setState({ ajaxRequest: false });
  };

  getLabel = (field) => {
    const user = auth.getCurrentUser();
    /* if (user) return labels[user.country_iso3][field];
    else return null;*/
    return labels[user.country_iso3][field];
  };

  /** Excel to JSON conversion */
  convertExcelToJson = (file) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {
        //console.log(JSON.stringify(this.state.data, null, 2));
        this.setState({ items: data });
        //console.log(this.state.data);
      });
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  //*** Updating state of data when input changed ***/
  handleChange = ({ currentTarget: input }) => {

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  //*** Handling form validation ***/
  validate = () => {
    const options = { abortEarly: true };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
      this.focusInput(item.path[0]);
    }
    return errors;
  };
  focusInput = (inputName) => { };
  //*** Filter grid based on input value ***/
  handleFiltration = ({ currentTarget: input }) => {
    const search = input.value;
    this.setState({ search });

    try {
      const { page, pageSize, sorted, filtered, fetchMethod } = this.state;
      fetchMethod(page, pageSize, sorted, filtered, search, (res) => {
        this.setState({
          data: res.data.rows,
          pages: res.data.pages,
          loading: false,
        });
      });
    } catch (error) { }
  };

  //*** Delete item from table ***/
  handleDeletion = (Item) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteItem(Item),
        },
        {
          label: "No",
        },
      ],
    });
  };

  deleteItem = async (Item) => {
    let { deleteMethod, data } = this.state;
    try {
      const { success, message } = await deleteMethod(Item);
      if (success === false) {
        if(message){
          toast.error(message);
        } else {
          toast.error("Sorry something went wrong.");
        }
        
      } else {
        toast.success(message);
        if (data.filter((item) => item.a_guid === Item).length > 0)
          data = data.filter((item) => item.a_guid !== Item);
        else data = data.filter((item) => item.id !== Item);
        this.setState({ data });
        this.refreshGrid();
      }
    } catch (error) { }
  };

  /** ---------- Key word search with Auto suggestion ---------- */

  handleKeyWordSuggestions = (event) => {
    if (event.keyCode != 9) {
      this.fetchKeyWordSuggestions(event.target.value);
    } else {
      console.log(event.keyCode);
    }
  };

  fetchKeyWordSuggestions = async (keyword) => {
    try {
      this.setState({ ajaxRequest: true });
      const { list } = await this.state.keywordAutoSuggestMethod(
        keyword,
        this.state.additionalParams
      );
      this.setState({ searchList: list });
      this.setState({ ajaxRequest: false });

      const selectedKeyword = {
        label: "",
        value: keyword,
      };
      this.setState({ selectedKeyword });
    } catch (error) { }
  };

  //*** Filter grid based on input value ***/
  handleKeyWordSearch = (selectedOption) => {
    const selectedKeyword = {
      label: selectedOption.value,
      value: selectedOption.value,
    };
    this.setState({ selectedKeyword });
    this.refreshGrid(this.state.additionalParams, selectedOption.value);
  };

  /** ------------------------------------------------------------ */

  //*** React table with searching, sorting and pagination ***/
  renderGrid() {
    const {
      data,
      fetchMethod,
      columns,
      additionalParams,
      doNotShowPagination,
    } = this.state;
    return (
      <ReactTable
        data={data}
        pages={this.state.pages}
        columns={columns}
        defaultPageSize={10}
        className="-striped -highlight"
        loading={this.state.loading}
        showPagination={doNotShowPagination ? false : true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        manual // this would indicate that server side pagination has been enabled
        onFetchData={async (state) => {
          this.setState({ loading: true });
          {
            additionalParams &&
              (await fetchMethod(
                state.page,
                state.pageSize,
                state.sorted,
                state.filtered,
                this.state.search,
                additionalParams,
                (res) => {
                  this.setState({
                    data: res.data.rows,
                    pages: res.data.pages,
                    loading: false,

                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                  });
                }
              ));
          }
          {
            !additionalParams &&
              (await fetchMethod(
                state.page,
                state.pageSize,
                state.sorted,
                state.filtered,
                this.state.search,
                (res) => {
                  this.setState({
                    data: res.data.rows,
                    pages: res.data.pages,
                    loading: false,

                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                  });
                }
              ));
          }
        }}
      />
    );
  }

  getTrProps = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          fontWeight: rowInfo.row.read === false ? "bold" : "normal",
        },
      };
    } else {
      return {};
    }
  };

  renderMailGrid() {
    const { data, fetchMethod, columns, additionalParams, search } = this.state;
    const TheadComponent = (props) => null;
    return (
      <ReactTable
        //TheadComponent={TheadComponent}
        data={data}
        pages={this.state.pages}
        columns={columns}
        defaultPageSize={10}
        className="-striped -highlight"
        loading={this.state.loading}
        showPagination={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        getTrProps={this.getTrProps}
        manual // this would indicate that server side pagination has been enabled
        onFetchData={async (state) => {
          this.setState({ loading: true });
          {
            await fetchMethod(
              state.page,
              state.pageSize,
              state.sorted,
              state.filtered,
              search,
              additionalParams,
              (res) => {
                this.setState({
                  data: res.data.rows,
                  pages: res.data.pages,
                  loading: false,

                  page: state.page,
                  pageSize: state.pageSize,
                  sorted: state.sorted,
                  filtered: state.filtered,
                });
              }
            );
          }
        }}
      />
    );
  }

  /** Refresh react table contents */
  refreshGrid = (additionalParams = null, search = null) => {
    {
      this.setState({ ajaxRequest: true });
      const { page, pageSize, sorted, filtered, fetchMethod } = this.state;
      if(additionalParams){
        fetchMethod(page, pageSize, sorted, filtered, search, additionalParams,
          (res) => {
            this.setState({
              data: res.data.rows,
              pages: res.data.pages,
              loading: false,
            });
            this.setState({ ajaxRequest: false });
          }
        );
      }
      else{
        fetchMethod(page, pageSize, sorted, filtered, search, (res) => {
          this.setState({
            data: res.data.rows,
            pages: res.data.pages,
            loading: false,
          });
          this.setState({ ajaxRequest: false });
        });
      }
      
      // additionalParams &&
      //   fetchMethod(
      //     page,
      //     pageSize,
      //     sorted,
      //     filtered,
      //     search,
      //     additionalParams,
      //     (res) => {
      //       this.setState({
      //         data: res.data.rows,
      //         pages: res.data.pages,
      //         loading: false,
      //       });
      //     }
      //   );

      // !additionalParams &&
      //   fetchMethod(page, pageSize, sorted, filtered, search, (res) => {
      //     this.setState({
      //       data: res.data.rows,
      //       pages: res.data.pages,
      //       loading: false,
      //     });
      //   });

      // this.setState({ ajaxRequest: false });
    }
  };

  renderTitle(label) {
    return (
      <div className="card-header">
        <h4 className="card-title">{label}</h4>

      </div>
    );
  }

  CapitalizeFirstLetter(text) {
    return text.length ? text.charAt(0).toUpperCase() + text.slice(1) : text;
  }
  rowOnClick(rowData, rate_name, rate_guid) {
    this.setState({
      //rowData: rowData,
      rate_name: rate_name,
      rate_guid: rate_guid,
    });
  }
  getTrPropsRate = (state, rowInfo) => {
    const index = rowInfo ? rowInfo.index : -1;
    return {
      onClick: () => {
        this.setState({
          selectedRow: index,
        });

        this.rowOnClick(
          rowInfo.original,
          this.CapitalizeFirstLetter(rowInfo.original.rate_name),
          rowInfo.original.rate_guid
        );
      },
      style: {
        fontWeight: this.state.selectedRow === index ? "bold" : "normal",
        cursor: "pointer",
        color:"green"
      },
    };
  };

  /*** React table with searching, sorting and pagination ***/
  renderRateGrid() {
    const {
      data,
      fetchMethod,
      columns,
      additionalParams,
      doNotShowPagination,
      rowData,
    } = this.state;
    return (
      <ReactTable
        data={data}
        pages={this.state.pages}
        columns={columns}
        defaultPageSize={10}
        className="-striped -highlight"
        loading={this.state.loading}
        showPagination={doNotShowPagination ? false : true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        getTrProps={this.getTrPropsRate}
        manual // this would indicate that server side pagination has been enabled
        onFetchData={async (state) => {
          this.setState({ loading: true });
          {
            additionalParams &&
              (await fetchMethod(
                state.page,
                state.pageSize,
                state.sorted,
                state.filtered,
                this.state.search,
                additionalParams,
                (res) => {
                  this.setState({
                    data: res.data.rows,
                    pages: res.data.pages,
                    loading: false,

                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                  });
                }
              ));
          }
          {
            !additionalParams &&
              (await fetchMethod(
                state.page,
                state.pageSize,
                state.sorted,
                state.filtered,
                this.state.search,
                (res) => {
                  this.setState({
                    data: res.data.rows,
                    pages: res.data.pages,
                    loading: false,

                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                  });
                }
              ));
          }
        }}
      />
    );
  }
}

export default Form;
