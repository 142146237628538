import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router";
import Modal from 'react-bootstrap/Modal';
import * as moment from "moment";
import { browserHistory } from "react-router";
import { HistoryButton, AddButton ,GeneralButton} from "../../../../common/containers/button";
import TfCreateInvvoiceCustomer from "../../../../supply-chain/components/transactions/customerUpload/tfAddInvoice";
import { CurrencyLabel,GridActionButton } from "../../../../common/inputs";
import BulkReject from "../../scTransactions/bulkReject";
import BalanceView from "../../../../supply-chain/components/transactions/bulkApprove/balanceView";
import transaction_services from "../../../../supply-chain/services/transaction_service";
import auth from "../../../../../services/authService";
import EntityContext from "../../../../../context/entityContext";
import RoleChecker from "../../../../../utils/roleChecker";
import Table from '../../../components/table/index';
import NoAccess from "../../../widgets/noAccess";
import BulkApprove from "../../scTransactions/bulkApprove";
import  { GridActionContainer } from '../../../../common/containers/formContainer';
import { FpButton } from "../../../../common/containers/button";
import JoditEditor from "jodit-react";
class TfCustomerTransactionList extends Component {

  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }


  state = {
    selectedTab: this.props.selectedTab ? this.props.selectedTab : "all",
    ajaxRequest: false,
    selectedRows: [],
    categories: [],
    open: false,
    openCustomer: false,
    approvebuttonClass: "btn btn-success  hidden",
    declinebuttonClass: "btn btn-danger  hidden",
    bulkapprove: false,
    bulkreject: false,
    selected: {},
    roleType: "Purchase",
    tabs: [],
    hideCheckbox: false,
    productType: this.props.productType ? this.props.productType : "",
    showPopout: false,
    isModel : false,
    tempData : null,
    transactionId : null,
  };

  componentDidMount() {

    const { constants, sc_invoice_status } = auth.getCurrentUser();
    this.context.handleProductChange(constants.PRODUCTS.TRADEFINANCE);
    if (!this.props.selectedTab) {
      this.setState({ selectedTab: "all" })
    }
    if (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && ( this.state.selectedTab == sc_invoice_status["awaiting_approval"]
    || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"] || this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open' ) ){
      this.setState({ hideCheckbox: false })
    } else {
      this.setState({ hideCheckbox: true })
    }
  }

  //----Go back to prospect on cancel click-------------
  handleCancel = () => {
    browserHistory.push("/client/home");
  };

  // Callback function to the Action Table.
  handleRowSelection = selectedRows => {
    this.setState({ selectedRows })
    let newSelected = {};
    selectedRows.map(item => {
      newSelected[item.key] = item.checked;
    })
    this.setState({ selected: newSelected });
    const { constants, sc_invoice_status } = auth.getCurrentUser();
    if (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && (this.state.selectedTab == sc_invoice_status["awaiting_approval"]
    || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"] ) ){
      this.setState({
        approvebuttonClass: "btn btn-success btn-sm ml-2 text-white ",
        declinebuttonClass: "btn btn-danger btn-sm mr-2",
      });
    }
  }
  onCloseModalApprove = () => {
    this.setState({ bulkapprove: false });
  };
  onCloseModalReject = () => {
    this.setState({ bulkreject: false });
  };
  BulkApprove = async () => {
    this.setState({ bulkapprove: true });
  };
  BulkReject = async () => {
    this.setState({ bulkreject: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onOpenModalCustomer = () => {

    this.setState({ openCustomer: true });
  };
  onCloseModalCustomer = () => {
    this.setState({ openCustomer: false });
  };

  handleRoleTypeChange = roleType => {
    this.setState({ roleType },
      () => { this.tableRef.current.reloadData(); }
    );
  }

  setTabs = tabs => {
    const { sc_invoice_status } = auth.getCurrentUser();
    if(this.context.specialProgramEnabledTF)
      this.setState({ tabs: tabs.filter(item => (
        item.value !== sc_invoice_status.awaiting_supplier_update && 
        item.value !== sc_invoice_status.approved_for_payment
      ))});
    else
      this.setState({ tabs })
  }

  handleChange = (status) => {

    const { sc_invoice_status,constants } = auth.getCurrentUser();
    this.setState({ selectedTab: status }, () => {
    if (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.TFCUSTOMER) && ( this.state.selectedTab == sc_invoice_status["awaiting_approval"]
    || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"] || this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open' ) ){
        this.setState({ hideCheckbox: false })
      } else {
        this.setState({ hideCheckbox: true })
      }
    });
    this.setState({ selectedRows: [], selected: {} });
    this.tableRef.current.clearSelection();
  }
  handlePopoutClick = () => {
    this.setState({showPopout: true})
  };

  closePopout = () => {
    this.setState({showPopout: false})
  };

  render() {

    const { entityDetails } = this.context;
    const { constants, sc_invoice_status } = auth.getCurrentUser();

    const cols = [
      {
        Header: "Reference Number",
        accessor: "inv_reference",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        show: !this.context.specialProgramEnabledTF,
      },
      {
        Header: this.context.terminology.SUPPLIER && this.context.terminology.SUPPLIER,
        accessor: "supplier_name",
        headerStyle: { textAlign: "left" },
        minWidth: 60,
        sortable: false,
        show: (RoleChecker.check(entityDetails.products, constants.ROLES.TFCUSTOMER) && !this.context.specialProgramEnabledTF) ? true : false,
      },
      {
        Header: "Invoice Number",
        accessor: "inv_number",
        headerStyle: { textAlign: "left" },
        minWidth: 60,
      },
      {
        Header: "Amount",
        accessor: "inv_amount",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {<CurrencyLabel value={row.original.inv_amount}></CurrencyLabel>}</span>
      },
      {
        Header: "Date",
        accessor: "trans_date",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {moment(row.original.trans_date, "YYYY-MM-DD").format("DD/MM/YYYY")} </span>
      },
      {
        Header: "Due Date",
        accessor: "inv_due_date",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {moment(row.original.inv_due_date, "YYYY-MM-DD").format("DD/MM/YYYY")} </span>
      },
      {
        Header: "Revised Due Date",
        accessor: "inv_revised_due_date",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        show:  (this.state.selectedTab == sc_invoice_status["rejected"]) ? false : true,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {row.original.inv_revised_due_date ? moment(row.original.inv_revised_due_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}</span>
      },
      {
        Header: "Finance Cost",
        accessor: "finance_cost",
        minWidth: 50,
        sortable: false,
        headerStyle: { textAlign: "left" },
        show:  (this.state.selectedTab == "open" && !this.context.specialProgramEnabledTF) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.finance_cost} /></span>
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        minWidth: 50,
        sortable: false,
        headerStyle: { textAlign: "left" },
        show:  (this.state.selectedTab == "open" ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.amount_paid} /></span>
      },
      {
        Header: "Action",
        accessor: "action",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        sortable: false,
        Cell: row => {
          return (
            <>
              <Link className="action-icons" style={{ color: "grey" }} to={{ pathname: (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.SUPPLIER) || RoleChecker.check(this.context.entityDetails.products, constants.ROLES.TFCUSTOMER)) ? `/client/tf/invoice/details` : `/tf-invoice/details`, search: "?inv_guid=" + row.original.trans_guid +"&tab=" + this.state.selectedTab }} >
                <HistoryButton className="fa fa-file-text-o"></HistoryButton>
              </Link>
              {/* <Link className="action-icons" onClick={ async () => {
                  this.setState({ isModel: true });
                  this.setState({ transactionId: row.original.trans_guid });
                  const res = await transaction_services.getTfInvoiceDetails(row.original.trans_guid,false, null,false,true);
                  if(res){
                    this.setState({ tempData: res.data });
                  }
              }} label="Export" ><i className="fa fa-download" aria-hidden="true"></i></Link> */}
            </>
             
          )
        }
      }

    ];

    const redirectSettings = () => {
      browserHistory.push("/client/tf/bulk-upload");
    };

    const RightPanel = () => (
      <>
        <div className="dropdown-menu" >
          <Link className="dropdown-item" to={"/client/tf/bulk-upload"}>Bulk Upload</Link>
          <Link className="dropdown-item" onClick={downloadReport} label="Export" >Export</Link>
        </div>
        <button title="More actions" className="btn btn-outline-secondary mr-2   pr-sm-3 pr-2 pl-lg-2  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More</button>
        {/* <AddButton style='btn   btn-secondary mr-2' label="Bulk Upload" onClick={redirectSettings}></AddButton> */}
        {!this.context.specialProgramEnabledTF && 
        <AddButton style="btn btn-primary " onClick={this.onOpenModalCustomer} label="Add New"></AddButton>
        }
      </>
    )
    const downloadReport = async () => {
      const res = await transaction_services.getInvoice({
        type: 'xlsx',
        roleType: "Purchase",
        product: constants.PRODUCTS.TRADEFINANCE,
        "open": true
      },this.state.selectedTab);
      const newWindow = window.open(res.data);
      newWindow.opener = null;
    }
    const LeftPanel = () => (
      <>
        {((( this.state.selectedTab == sc_invoice_status["awaiting_approval"] || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"] 
        || this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open' ) &&
        RoleChecker.check(this.context.entityDetails.products, constants.ROLES.TFCUSTOMER) && this.state.roleType == constants.BUSINESS_TYPE.PURCHASE) && (this.state.selectedRows.find(rows => rows.checked == true) != undefined)) && (
          <>
            <div className="row my-3 rounded-lg mx-0 py-3 bg-light balance-box">
              {/* {Object.keys(this.state.selected).length > 0 &&
                RoleChecker.check(entityDetails.products, constants.ROLES.CUSTOMER) && ( this.state.selectedTab == sc_invoice_status["awaiting_approval"]
                || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"] ) && (
                  <BalanceView product={constants.PRODUCTS.TRADEFINANCE} invoices={this.state.selected} entity_id={entityDetails.a_guid}></BalanceView>)} */}
              {Object.keys(this.state.selected).length > 0 &&
                  RoleChecker.check(entityDetails.products, constants.ROLES.TFCUSTOMER) && (this.state.selectedTab == sc_invoice_status['awaiting_approval']
                    || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"]) ? (
                  <div className="col-sm-6 col-lg-8   ">
                    <BalanceView product={constants.PRODUCTS.TRADEFINANCE} invoices={this.state.selected} entity_id={entityDetails.a_guid} />
                  </div>
                ) : (
                  <div className="col-sm-6 col-lg-8   "></div>
              )}
              <div className="col-sm-6 col-lg-4 align-self-center text-lg-right">
                <GridActionContainer>
                  <>
                    {RoleChecker.check(this.context.entityDetails.products, constants.ROLES.TFCUSTOMER) && this.state.roleType == constants.BUSINESS_TYPE.PURCHASE && (this.state.selectedTab == sc_invoice_status['awaiting_approval'] || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"] ) && (
                        <GridActionButton icon="fa fa-thumbs-down" label="Reject" handleClick={() => this.BulkReject() }
                        class="btn btn-outline-danger mr-2" />
                    )}
                    {/* <GridActionButton icon="fa fa-thumbs-up" label= { (this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open') ? "Extend" : "Approve" } handleClick={this.BulkApprove} /> */}
                    <FpButton
                      userType="customer"
                      label="Options"
                      smallLabel="Pay"
                      className="btn btn-outline-primary mr-2"
                      onClick={this.BulkApprove}
                    />
                    {(this.state.selectedTab === "open" || this.state.selectedTab === "overdue") &&
                      <GridActionButton label="Settle Now"
                        extraHeight={true}
                        handleClick={() => {
                          let newSelected = [];
                          const checked = this.state.selectedRows.filter(item => item.checked === true).map(item => {
                            newSelected.push(item.trans_guid)
                          });
                          browserHistory.push({ pathname: '/client/tf/bulk-payment', state: { invoices: newSelected } });

                        }}
                        class="btn btn-outline-primary mr-2 ml-2 pull-right"
                      />
                    }
                  </>
                </GridActionContainer>
              </div>
            </div>
          </>
        )}
      </>
    )

    const Filters = () => (


      (this.state.tabs.length > 0 && this.state.selectedTab != 'overdue' ) && (
        <div className="row justify-content-left ">
          <div className="col-sm-12 col-lg-12">
            <div className="dropdown dash-dropdown">
              <button className="btn btn-outline-secondary dropdown-toggle pr-sm-4 pr-3 p-2 w-100 text-left  dropdown-toggle  pr-sm-4 pr-3 pl-lg-2 w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.state.tabs.map(obj => <>{this.state.selectedTab == obj.value ? obj.label : ""}</>)}
              </button>

              <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                {this.state.tabs.map(obj => <a className={`dropdown-item ${this.state.selectedTab == obj.value ? 'active' : ''}`} onClick={e => this.handleChange(obj.value)} href="#" >{obj.label}</a>)}
              </div>

            </div>
          </div>
        </div>
      )

    )
    return (
      <>
        <Helmet>
          <title>Invoice List | {global.config.title}</title>
        </Helmet>

        {RoleChecker.check(this.context.entityDetails.products, constants.ROLES.TFCUSTOMER) ? (
          <>

            <div className="card overflow-visible">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-6"><h6>{ this.state.selectedTab === 'overdue' ? 'Due / Overdue Invoices' : 'Invoices' }</h6></div>
                  <div className="col-lg-6">
                    
                    <Link className="btn btn-light pull-right text-secondary ml-2" to={'/client/tf/dashboard'}><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
                    {this.state.showPopout === false &&(
                      <a className="btn btn-light pull-right text-secondary ml-2" onClick={this.handlePopoutClick} >
                        <i className="fa fa-expand"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                  {this.state.showPopout === false ? (
                    <Table
                      ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
                      service={transaction_services.getInvoice}
                      searchService={transaction_services.searchTraderInvoice}
                      cols={cols}
                      defaultTab={this.state.selectedTab ? this.state.selectedTab : "all"}
                      onSelectTab={val => { this.setState({ selectedTab: val, selectedRows: [], selected: {} }, () => { this.handleChange() }); }}
                      uid={'trans_guid'}
                      onSelectRow={this.handleRowSelection}
                      reqParams={{ roleType: "Purchase", product: constants.PRODUCTS.TRADEFINANCE, "open": true }}
                      ibox={true}
                      headerTabs={true}
                      setTabs={this.setTabs}
                      checkbox={this.state.checkbox}
                      tableLeftPanel={<LeftPanel />}
                      tableRightPanel={<RightPanel />}
                      leftPanelColour={this.state.leftPanelColour}
                      hideCheckbox={this.state.hideCheckbox}
                      additionalFilter={<Filters />}
                      getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                          return {
                            style: {
                              color: rowInfo.row._original.is_overdue == 1 && 'red',
                            }
                          }
                        } else {
                          return {}
                        }
                      } }
                      tabs={this.state.tabs.length > 0 ? this.state.tabs : false}
                    />
                  ):(
                    <>
                      <Modal className="fullscreen-modal" show={this.state.showPopout} onHide={this.closePopout}>
                        <Modal.Header closeButton>
                          <div className="d-block pt-3 text-center w-100 ml-3">
                            
                            <h3 className="modal-title">Invoices</h3>
                          
                          </div>
                        </Modal.Header>
                        <Modal.Body>
                          <Table
                            ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
                            service={transaction_services.getInvoice}
                            searchService={transaction_services.searchTraderInvoice}
                            cols={cols}
                            defaultTab={this.state.selectedTab ? this.state.selectedTab : "all"}
                            onSelectTab={val => { this.setState({ selectedTab: val, selectedRows: [], selected: {} }, () => { this.handleChange() }); }}
                            uid={'trans_guid'}
                            onSelectRow={this.handleRowSelection}
                            reqParams={{ roleType: "Purchase", product: constants.PRODUCTS.TRADEFINANCE, "open": true }}
                            ibox={true}
                            headerTabs={true}
                            setTabs={this.setTabs}
                            checkbox={this.state.checkbox}
                            tableLeftPanel={<LeftPanel />}
                            tableRightPanel={<RightPanel />}
                            leftPanelColour={this.state.leftPanelColour}
                            hideCheckbox={this.state.hideCheckbox}
                            additionalFilter={<Filters />}
                            getTrProps={(state, rowInfo) => {
                              if (rowInfo && rowInfo.row) {
                                return {
                                  style: {
                                    color: rowInfo.row._original.is_overdue == 1 && 'red',
                                  }
                                }
                              } else {
                                return {}
                              }
                            } }
                            tabs={this.state.tabs.length > 0 ? this.state.tabs : false}
                          />
                        </Modal.Body>
                      </Modal>
                    </>
                  )}
                    
                  </div>
                </div>
              </div>

            </div>


          </>
        ) : <NoAccess heading="Supply Chain Transactions" subHeading=""></NoAccess>}

        <Modal
          show={this.state.openCustomer}
          onHide={this.onCloseModalCustomer}
          backdrop="static"
          centered
        >

          <TfCreateInvvoiceCustomer
            a_guid={entityDetails.a_guid}
            page="common"
            onCloseModal={() => {
              this.onCloseModalCustomer();
              this.tableRef.current.reloadData();

            }}
          />

        </Modal>
        {/* print remittance details */}
        <iframe
        name="print_frame"
        width="0"
        height="0"
        frameborder="0"
        src="about:blank"
      ></iframe>

      {this.state.bulkapprove &&
        <BulkApprove
          invoices={this.state.selected}
          onClose={this.onCloseModalApprove}
          onHandleReload={() => {
            this.onCloseModalApprove();
            this.tableRef.current.reloadData();
            this.tableRef.current.clearSelection();
            this.setState({ selectedRows: [], selected: {} });
          }}
          open={this.state.bulkapprove}
          trans_status={this.state.selectedTab}
          entity_id={entityDetails.a_guid}
          product={constants.PRODUCTS.TRADEFINANCE}
        />
      }    
        <BulkReject
          invoices={this.state.selected}
          onClose={this.onCloseModalReject}
          onHandleReload={() => {
            this.onCloseModalReject();
            this.tableRef.current.reloadData();
            this.tableRef.current.clearSelection();
            this.setState({ selectedRows: [], selected: {} });
          }}
          open={this.state.bulkreject}
          trans_status={this.state.selectedTab}
          product_type={constants.PRODUCTS.TRADEFINANCE}
          userType = "customer"
        />
          <Modal
          show={this.state.isModel}
          size="lg" // Set the size to "lg" for large, "md" for medium, "sm" for small, or leave empty for default size
          onHide={() => {
            this.setState({ isModel: false });
          }}
        >
        <Modal.Header closeButton> {/* Add closeButton prop to display close button */}
          <Modal.Title>Remittance Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.tempData && (
            <JoditEditor value={this.state.tempData}
            onChange={(value) => {
              this.setState({ tempData: value });
            }}
          />
          )}
        </Modal.Body>
        <Modal.Footer>
        <GeneralButton
              onClick={ async () => {

                  window.frames.print_frame.document.body.innerHTML = this.state.tempData;
                  window.frames.print_frame.window.focus();
                  window.frames.print_frame.window.print();
              }}
              name={"Print"}
        ></GeneralButton>
        <AddButton style="btn btn-primary " 
        onClick={ async () => {
          this.setState({ isModel: false });
                  const resp = await transaction_services.getTfInvoiceDetails(this.state.transactionId,false, null,true,false,this.state.tempData);
                  if(resp){
                    const newWindow = window.open(resp.data);
                    newWindow.opener = null;
                  }

              }} label="Download"></AddButton>
        </Modal.Footer>
       
      </Modal>
      </>
    );
  }
}
export default TfCustomerTransactionList;