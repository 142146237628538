import vdoConst from "./tourVideo.js";
const tour = {
    "entities": {
        "details": {
            "home": {
                "tour": [
                ],
                "video": [
                    {
                        "link": vdoConst.Accounts_Home_Page,
                        "description": "Accounts Home Page"
                    }
                ]
            },
            "ifinvoices": {
                "invoice": {
                    "tour": [
                        {
                            "target": "#ac-profile-header",
                            "content": "This is Accounts general Info Section",
                            "disableBeacon": true
                        },
                        {
                            "target": ".widget-control-holder",
                            "content": "These widgets control what section of the Invoice finance facility you are viewing.",
                        },
                        {
                            "target": ".btn-trans",
                            "content": "This icon takes you to the invoices and transactions for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account debtors (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".reconcile_session",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from a debtor."
                        },
                        {
                            "target": ".client_pay_btn",
                            "content": "This icon takes you to the client payment request section, when a client has access to the client portal and confirms a payment, it will show here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you give the debtors to make payment."
                        },
                        {
                            "target": ".btn_link_draft",
                            "content": "When an invoice is added to Fi fo pay, it will typically start with the status draft. This means you can then group draft invoices into a transaction. Invoices can be added by you, using the bulk upload button, the add new button, or by the client using the client portal."
                        },
                        {
                            "target": ".btn_link_approved",
                            "content": "These are invoices you have approved for payment, but you have not batched into a transaction. You may not be ready to create a transaction, but your client needs to know which invoices are approved, moving invoices from draft to here will show as approved in the client portal, giving your client a view of the position of invoices they want funded. "
                        },
                        {
                            "target": ".btn_link_approved_for_payment",
                            "content": "You can batch a group of invoices into a transaction and then schedule them for payment later. This gives you more control over how and when you process invoices."
                        },
                        {
                            "target": ".btn_link_open",
                            "content": "These are invoices and transactions where you have advanced funds to the client."
                        },
                        {
                            "target": ".btn_link_closed",
                            "content": "Your closed transactions and invoices are kept here."
                        },
                        {
                            "target": ".btn_link_rejected",
                            "content": "Here are all the rejected invoices."
                        },
                        {
                            "target": ".tbl-search-box",
                            "content": "You can search for a particular invoice or transaction here."
                        },
                        {
                            "target": ".if_bulk_upload_btn",
                            "content": "Use this button to make a bulk upload of invoices."
                        },
                        {
                            "target": ".if_add_nw_btn",
                            "content": "Add a single invoice using this button."
                        },
                        {
                            "target": ".ReactTable",
                            "content": "This is the table of invoices, and transactions. Both transactions and invoices have action icons that appear on the righthand side of the table, use your mouse and hover over these to find out more."
                        },
                        {
                            "target": ".ReactTable .header-checkbox",
                            "content": "Use this bulk tick box to apply actions to groups of invoices, for example batch into a transaction."
                        },
                        {
                            "target": ".pagination-bottom",
                            "content": "You control the table from here, selecting the page you want to see, the rows you want on each page of the table, and using the previous and next table to navigate between pages in the table."
                        }
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "ifdebtors": {
                    "tour": [
                        {
                            "target": ".btn-control-holder",
                            "content": "These widgets control what section of the Invoice finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn-trans",
                            "content": "This icon takes you to the invoices and transactions for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account debtors (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".reconcile_session",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from a debtor."
                        },
                        {
                            "target": ".client_pay_btn",
                            "content": "This icon takes you to the client payment request section, when a client has access to the client portal and confirms a payment, it will show here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you give the debtors to make payment."
                        },
                        // {
                        //     "target": ".if_add_debtor_btn",
                        //     "content": "Add a new debtor here. When you add a debtor here, it will appear below, alongside all other debtors associated with this account. The debtor will also be added to you Debtor client list (secondary accounts) in the main ‘All Accounts’ section of fi fo central."
                        // },
                        // {
                        //     "target": ".if_map_btn",
                        //     "content": "If a debtor(s) is already a secondary account (Debtor/Supplier Accounts, on the ‘All Accounts’ page, then you can link then here."
                        // },
                        // {
                        //     "target": ".if_bulk_upload_btn",
                        //     "content": "Bulk upload a list of debtors to be used with this facility here."
                        // },
                        {
                            "target": ".if_debtors_list",
                            "content": "All connected debtors will appear here, with basic information, and ability for you to remove from the facility."
                        }
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "ifsettings": {
                    "tour": [
                        {
                            "target": ".btn-control-holder",
                            "content": "These widgets control what section of the Invoice finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn-trans",
                            "content": "This icon takes you to the invoices and transactions for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account debtors (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".reconcile_session",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from a debtor."
                        },
                        {
                            "target": ".client_pay_btn",
                            "content": "This icon takes you to the client payment request section, when a client has access to the client portal and confirms a payment, it will show here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you give the debtors to make payment."
                        },
                        {
                            "target": ".if_discount_fee",
                            "content": "Select the fee and charges you wish to apply to the facility."
                        },
                        {
                            "target": ".if_add_discount",
                            "content": "Add a new fee and charge here, or first add from the Administrator main menu option."
                        },
                        {
                            "target": ".if_late_payment_fee",
                            "content": "Add a late payment fee here."
                        },
                        {
                            "target": ".if_advance_rate",
                            "content": "Set your general advance here."
                        },
                        {
                            "target": ".if_insured_fee",
                            "content": "Set your insurance fee here."
                        },
                        {
                            "target": ".if_introducers_fee",
                            "content": "Select if the introduce r fee is a fixed amount, percentage of the discount fee, or percentage of the transaction value."
                        },
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "ifallocatepayment": {
                    "tour": [
                        {
                            "target": ".btn-control-holder",
                            "content": "These widgets control what section of the Invoice finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn-trans",
                            "content": "This icon takes you to the invoices and transactions for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account debtors (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".reconcile_session",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from a debtor."
                        },
                        {
                            "target": ".client_pay_btn",
                            "content": "This icon takes you to the client payment request section, when a client has access to the client portal and confirms a payment, it will show here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you give the debtors to make payment."
                        },
                        {
                            "target": ".debtor_list-holder",
                            "content": "Select the debtor making the payment here."
                        },
                        {
                            "target": ".recvd_date_holder",
                            "content": "Select the date the payment received here."
                        },
                        {
                            "target": ".payment_amt_holder",
                            "content": "Add the amount here."
                        },
                        {
                            "target": ".avail_amnt_holder",
                            "content": "As you select invoices and transactions below, the available balance of the payment will be shown here."
                        },
                        {
                            "target": ".trans-list-holder",
                            "content": "All eligible transactions and invoices will appear here."
                        },
                        {
                            "target": ".btn_recv_paymnt",
                            "content": "Use this button to allocated the payment and close the transaction."
                        },
                        {
                            "target": ".allocate_not_close_btn",
                            "content": "Use this button to allocated the payment and not close the transaction."
                        },
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "ifbankdetail": {
                    "tour": [
                        {
                            "target": ".btn-control-holder",
                            "content": "These widgets control what section of the Invoice finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn-trans",
                            "content": "This icon takes you to the invoices and transactions for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account debtors (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".reconcile_session",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from a debtor."
                        },
                        {
                            "target": ".client_pay_btn",
                            "content": "This icon takes you to the client payment request section, when a client has access to the client portal and confirms a payment, it will show here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you give the debtors to make payment."
                        },
                        {
                            "target": ".bank-detail-holder",
                            "content": "Add or update the facility account details you are linking to this facility. This is not the client’s bank details, neither is this a debtor’s bank details. The details entered here are the account details, debtors are to pay into, or the client is to pay into, if they receive a payment on your behalf.",
                        },
                        {
                            "target": ".bank_verify_btn",
                            "content": "Verify the bank details using this button."
                        },
                        {
                            "target": ".bank_detail_save",
                            "content": "Save any amendments you have made using this button."
                        }
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
            },
            "sctransactions": {
                "invoice": {
                    "tour": [
                        {
                            "target": ".widget-control-holder",
                            "content": "These widgets control what section of the supply chain finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn_trans",
                            "content": "This icon takes you to the invoices (transactions) for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account suppliers (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".btn_cust_payment",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from the client."
                        },
                        {
                            "target": ".btn_client_req",
                            "content": "This icon takes you to the client payment request section, when a client added confirmation of a payment, it will appear here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you pay suppliers from and give the client to make payment."
                        },
                        {
                            "target": ".tbl-search-box",
                            "content": "Search from an invoice here."
                        },
                        {
                            "target": ".tbl-inv-status-holder",
                            "content": "Select which groups of invoices you want to see, based on invoice status."
                        },
                        {
                            "target": ".tbl-right-panel-holder button",
                            "content": "Add a new invoice here on behalf of a client."
                        },
                        {
                            "target": ".ReactTable",
                            "content": "This is the table of invoices, if you first sort the table using the status dropdown, then certain bulk features will be available, as well as applicable action icons."
                        },
                        {
                            "target": ".pagination-bottom",
                            "content": "You control the table from here, selecting the page you want to see, the rows you want on each page of the table, and using the previous and next table to navigate between pages in the table."
                        }
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "scfsupplier": {
                    "tour": [
                        {
                            "target": ".widget-control-holder",
                            "content": "These widgets control what section of the supply chain finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn_trans",
                            "content": "This icon takes you to the invoices (transactions) for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account suppliers (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".btn_cust_payment",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from the client."
                        },
                        {
                            "target": ".btn_client_req",
                            "content": "This icon takes you to the client payment request section, when a client added confirmation of a payment, it will appear here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you pay suppliers from and give the client to make payment."
                        },
                        // {
                        //     "target": ".scf-map-supplier",
                        //     "content": "Use this button to add a supplier that you have previously added, and which is under the ‘Debtor/Supplier Accounts’ section of All Accounts."
                        // },
                        // {
                        //     "target": ".scf-suplier-new",
                        //     "content": "Add a new supplier here"
                        // },
                        {
                            "target": ".ReactTable",
                            "content": "This table lists all the suppliers connected to the facility. You can remove them or edit the way they are connected to the facility, for instance, you can change the rates for a supplier, the contact, specific terms, and if the supplier is disclosed or non-disclosed."
                        },
                        {
                            "target": ".pagination-bottom",
                            "content": "You control the table from here, selecting the page you want to see, the rows you want on each page of the table, and using the previous and next table to navigate between pages in the table."
                        },

                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "scfsettings": {
                    "tour": [
                        {
                            "target": ".widget-control-holder",
                            "content": "These widgets control what section of the supply chain finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn_trans",
                            "content": "This icon takes you to the invoices (transactions) for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account suppliers (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".btn_cust_payment",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from the client."
                        },
                        {
                            "target": ".btn_client_req",
                            "content": "This icon takes you to the client payment request section, when a client added confirmation of a payment, it will appear here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you pay suppliers from and give the client to make payment."
                        },
                        {
                            "target": ".inc_limit_holder",
                            "content": "This dropdown will be set to yes if the client requests an increase to the facility."
                        },
                        {
                            "target": ".scf-limit-setting",
                            "content": "This is the total limit of the supply chain finance facility."
                        },
                        {
                            "target": ".scf-insure-limit-holder",
                            "content": "This is the total amount that is insured at any one time"
                        },
                        {
                            "target": ".scf-insurer-holder",
                            "content": "Select the insurer here."
                        },
                        {
                            "target": ".",
                            "content": "Use this ‘Save’ button to save changes to the facility limit."
                        },
                        {
                            "target": ".scf-rate-holder",
                            "content": "Select the default rate to use with the facility."
                        },
                        {
                            "target": ".btn_newrate",
                            "content": "Add a new set of rates here."
                        },

                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "scfbankdetail": {
                    "tour": [
                        {
                            "target": ".widget-control-holder",
                            "content": "These widgets control what section of the supply chain finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn_trans",
                            "content": "This icon takes you to the invoices (transactions) for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account suppliers (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".btn_cust_payment",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from the client."
                        },
                        {
                            "target": ".btn_client_req",
                            "content": "This icon takes you to the client payment request section, when a client added confirmation of a payment, it will appear here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you pay suppliers from and give the client to make payment."
                        },
                        {
                            "target": ".bank-detail-holder",
                            "content": "Add or update the facility account details you are linking to this facility. This is not the client’s bank details, neither is this a debtor’s bank details. The details entered here are the account details, debtors are to pay into, or the client is to pay into, if they receive a payment on your behalf.",
                        },
                        {
                            "target": ".bank_verify_btn",
                            "content": "Verify the bank details using this button."
                        },
                        {
                            "target": ".bank_detail_save",
                            "content": "Save any amendments you have made using this button."
                        }
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
            },
            "debtorfinances": {
                "invoice": {
                    "tour": [
                        {
                            "target": "#ac-profile-header",
                            "content": "This is Accounts general Info Section",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn-control-holder",
                            "content": "These widgets control what section of the Whole of Book finance facility you are viewing."
                        },
                        {
                            "target": "#df-trns-tab",
                            "content": "This icon takes you to the invoices and for the facility."
                        },
                        {
                            "target": "#df-network-tab",
                            "content": "This icon takes you to the list of the account debtors (Network) connected to the facility."
                        },
                        {
                            "target": "#df-setting-tab",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": "#df-reconsile-tab",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from a debtor."
                        },
                        {
                            "target": "#df-facility-tab",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you give the debtors to make payment."
                        },
                        {
                            "target": ".btn_link_draft",
                            "content": "Invoices are added in batches and start in the draft section. Invoices can be added by you, using the batch upload button or by the client using the client portal.",
                        },
                        {
                            "target": ".btn_link_open",
                            "content": "All open batches and invoices will be found here.",
                        },
                        {
                            "target": ".btn_link_closed",
                            "content": "All closed batches and invoices will be found here.",
                        },
                        {
                            "target": ".tbl-search-box",
                            "content": "Use search to find invoices, using the transaction number or invoice number.",
                        },
                        {
                            "target": ".df-batch-upload-btn",
                            "content": "Create a Batch upload here.",
                        },
                        {
                            "target": ".ReactTable",
                            "content": "Here you will fund the batches of invoices.",
                        },
                        {
                            "target": ".header-checkbox",
                            "content": "Use this button to run actions against the invoices.",
                        },
                        {
                            "target": ".pagination-bottom",
                            "content": "You control the table from here, selecting the page you want to see, the rows you want on each page of the table, and using the previous and next table to navigate between pages in the table.",
                        }
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "wobbankdetail": {
                    "tour": [
                        {
                            "target": ".btn-control-holder",
                            "content": "These widgets control what section of the Whole of Book finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": "#df-trns-tab",
                            "content": "This icon takes you to the invoices and for the facility."
                        },
                        {
                            "target": "#df-network-tab",
                            "content": "This icon takes you to the list of the account debtors (Network) connected to the facility."
                        },
                        {
                            "target": "#df-setting-tab",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": "#df-reconsile-tab",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from a debtor."
                        },
                        {
                            "target": "#df-facility-tab",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you give the debtors to make payment."
                        },
                        {
                            "target": ".bank-detail-holder",
                            "content": "Add or update the facility account details you are linking to this facility. This is not the client’s bank details, neither is this a debtor’s bank details. The details entered here are the account details, debtors are to pay into, or the client is to pay into, if they receive a payment on your behalf."
                        },
                        {
                            "target": ".bank_verify_btn",
                            "content": "Verify the bank details using this button."
                        },
                        {
                            "target": ".bank_detail_save",
                            "content": "Save any amendments you have made using this button."
                        }
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "wobbatchUpload": {
                    "tour": [
                    ],
                    "video": [
                    ]
                },
            },
            "tftransactions" : {
                "invoice" : {
                    "tour": [
                        {
                            "target": ".widget-control-holder",
                            "content": "These widgets control what section of the trade finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn_trans",
                            "content": "This icon takes you to the invoices (transactions) for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account suppliers (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".btn_tf_client_payment",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from the client."
                        },
                        {
                            "target": ".btn_client_pay_req",
                            "content": "This icon takes you to the client payment request section, when a client added confirmation of a payment, it will appear here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you pay suppliers from and give the client to make payment."
                        },
                        {
                            "target": ".tbl-search-box",
                            "content": "Search from an invoice here."
                        },
                        {
                            "target": ".tbl-inv-status-holder",
                            "content": ".Select which groups of invoices you want to see, based on invoice status."
                        },
                        {
                            "target": ".btn_tf_add_nw",
                            "content": "Add a new invoice here on behalf of a client."
                        },
                        {
                            "target": ".ReactTable",
                            "content": ".This is the table of invoices, if you first sort the table using the status dropdown, then certain bulk features will be available, as well as applicable action icons."
                        },
                        {
                            "target": ".pagination-bottom",
                            "content": "You control the table from here, selecting the page you want to see, the rows you want on each page of the table, and using the previous and next table to navigate between pages in the table."
                        },
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "tfsupplier" : {
                    "tour": [
                        {
                            "target": ".widget-control-holder",
                            "content": "These widgets control what section of the trade finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn_trans",
                            "content": "This icon takes you to the invoices (transactions) for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account suppliers (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".btn_tf_client_payment",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from the client."
                        },
                        {
                            "target": ".btn_client_pay_req",
                            "content": "This icon takes you to the client payment request section, when a client added confirmation of a payment, it will appear here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you pay suppliers from and give the client to make payment."
                        },
                        // {
                        //     "target": ".tf_map_exist",
                        //     "content": "Use this button to add a supplier that you have previously added, and which is under the ‘Debtor/Supplier Accounts’ section of All Accounts."
                        // },
                        // {
                        //     "target": ".tf_add_supplier",
                        //     "content": "Add a new supplier here"
                        // },
                        {
                            "target": ".ReactTable",
                            "content": "This table lists all the suppliers connected to the facility. You can remove them or edit the way they are connected to the facility, for instance, you can change the rates for a supplier, the contact, specific terms, and if the supplier is disclosed or non-disclosed."
                        },
                        {
                            "target": ".pagination-bottom",
                            "content": "You control the table from here, selecting the page you want to see, the rows you want on each page of the table, and using the previous and next table to navigate between pages in the table."
                        },
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "tfsettings" : {
                    "tour": [
                        {
                            "target": ".widget-control-holder",
                            "content": "These widgets control what section of the trade finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn_trans",
                            "content": "This icon takes you to the invoices (transactions) for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account suppliers (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".btn_tf_client_payment",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from the client."
                        },
                        {
                            "target": ".btn_client_pay_req",
                            "content": "This icon takes you to the client payment request section, when a client added confirmation of a payment, it will appear here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you pay suppliers from and give the client to make payment."
                        },
                        {
                            "target": ".tf_inc_limit_holder",
                            "content": "This dropdown will be set to yes if the client requests an increase to the facility."
                        },
                        {
                            "target": ".tf_limit_holder",
                            "content": "This is the total limit of the supply chain finance facility."
                        },
                        {
                            "target": ".tf_insur_limit",
                            "content": "This is the total amount that is insured at any one time."
                        },
                        {
                            "target": ".tf_insurer_holder",
                            "content": "Select the insurer here."
                        },
                        {
                            "target": ".tf_def_setting_sav_btn",
                            "content": "Use this ‘Save’ button to save changes to the facility limit."
                        },
                        {
                            "target": ".tf_rate_holder",
                            "content": "Select the default rate to use with the facility."
                        },
                        {
                            "target": ".tf_rate_new",
                            "content": "Add a new set of rates here."
                        }
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
                "tfbankdetail": {
                    "tour": [
                        {
                            "target": ".widget-control-holder",
                            "content": "These widgets control what section of the supply chain finance facility you are viewing.",
                            "disableBeacon": true
                        },
                        {
                            "target": ".btn_trans",
                            "content": "This icon takes you to the invoices (transactions) for the facility."
                        },
                        {
                            "target": ".connected-debtor-btn",
                            "content": "This icon takes you to the list of the account suppliers (Network) connected to the facility."
                        },
                        {
                            "target": ".settings_btn",
                            "content": "Use this icon to access the general settings for the facility."
                        },
                        {
                            "target": ".btn_cust_payment",
                            "content": "This icon takes you to the reconcile section, where you can apply a payment from the client."
                        },
                        {
                            "target": ".btn_client_req",
                            "content": "This icon takes you to the client payment request section, when a client added confirmation of a payment, it will appear here."
                        },
                        {
                            "target": ".facility_bank_detail_btn",
                            "content": "Use this icon to add a bank account name, number and BSB you are allocating to the facility. These are the account details you pay suppliers from and give the client to make payment."
                        },
                        {
                            "target": ".bank-detail-holder",
                            "content": "Add or update the facility account details you are linking to this facility. This is not the client’s bank details, neither is this a debtor’s bank details. The details entered here are the account details, debtors are to pay into, or the client is to pay into, if they receive a payment on your behalf.",
                        },
                        {
                            "target": ".bank_verify_btn",
                            "content": "Verify the bank details using this button."
                        },
                        {
                            "target": ".bank_detail_save",
                            "content": "Save any amendments you have made using this button."
                        }
                    ],
                    "video": [
                        {
                            "link": vdoConst.Take_the_tour_crank_this_baby_up,
                            "description": "Take the tour crank this baby up"
                        },
                        {
                            "link": vdoConst.Take_the_tour_General,
                            "description": "Take the tour General"
                        },
                        {
                            "link": vdoConst.Take_the_tour_lovely_to_see_you,
                            "description": "Take the tour lovely to see you"
                        },
                        {
                            "link": vdoConst.Take_the_tour_my_favourite_tour,
                            "description": "Take the tour my favourite tour"
                        },
                        {
                            "link": vdoConst.Take_the_tour_personal_tour_version,
                            "description": "Take the tour personal tour version"
                        },
                        {
                            "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                            "description": "Take the tour So you  looking for some help"
                        },
                        {
                            "link": vdoConst.Take_the_tour_Well_Well_Well,
                            "description": "Take the tour Well Well Well"
                        }
                    ]
                },
            },
            "loan": {
                "tour": [
                    {
                        "target": "#ac-profile-header",
                        "content": "This is Accounts general Info Section",
                        "disableBeacon": true
                    },
                    {
                        "target": "#ac-entity-menu",
                        "content": "This is Menu section, You can select and View next from here."
                    },
                    {
                        "target": "#tab_holder",
                        "content": "This tab is to select transaction based on status"
                    },
                    {
                        "target": ".tbl-right-panel-holder",
                        "content": "Button used to create new loan"
                    },
                    {
                        "target": "#loan-list-tab",
                        "content": "You can view loan list."
                    },
                    {
                        "target": "#loan-facility-tab",
                        "content": "You can update facility bank details"
                    }
                ],
                "video": [
                ]
            },
            "profile": {
                "tour": [
                    {
                        "target": ".entity_view_note_btn",
                        "content": "View and add notes here about the account here.",
                        "disableBeacon": true
                    },
                    {
                        "target": ".entity_expand_btn",
                        "content": "Use this icon to expand or contract the Account header, when expanded you’ll see more information about the account."
                    },
                    {
                        "target": ".entity_w_clos_btn",
                        "content": "Close the record here."
                    },
                    {
                        "target": ".ab_number-holder",
                        "content": "This is the company number of the account. For example, in Australia, this is the ABN."
                    },
                    {
                        "target": ".organization-holder",
                        "content": "The organisation name."
                    },
                    {
                        "target": ".trading_name-holder",
                        "content": "The trading name of the organisation goes here"
                    },
                    {
                        "target": ".first_name-holder",
                        "content": "Edit the first name of your main contact here."
                    },
                    {
                        "target": ".last_name-holder",
                        "content": "Edit the last name of your main contact here."
                    },
                    {
                        "target": ".email-holder",
                        "content": "Update your main contact’s email address here."
                    },
                    {
                        "target": ".phone_no-holder",
                        "content": "Update your main contact’s phone number."
                    },
                    {
                        "target": ".status-holder",
                        "content": "Change the status of the account here, either ‘Active’ or ‘Inactive’. "
                    },
                    {
                        "target": ".address-holder",
                        "content": "Add or amend the address of the organisation here."
                    },
                    {
                        "target": ".altr_data_holder",
                        "content": "Add an alternative phone number here and email address. For example, this could be a landline of the business or department you mainly deal with."
                    },
                    {
                        "target": ".website-holder",
                        "content": "Here is where you add the organisation’s website."
                    },
                    {
                        "target": ".client_number-holder",
                        "content": "A unique client reference number you can add to the profile."
                    },
                    {
                        "target": ".product-list-holder",
                        "content": "Here you can view and select which finance facilities the account has active or inactive."
                    },
                    {
                        "target": ".bank-detail-holder",
                        "content": "You can add the account bank details here and verify them."
                    },
                    {
                        "target": ".bank-hist-btn",
                        "content": "Here you can see the history of changes to the bank account details."
                    },

                ],
                "video": [
                    {
                        "link": vdoConst.Take_the_tour_crank_this_baby_up,
                        "description": "Take the tour crank this baby up"
                    },
                    {
                        "link": vdoConst.Take_the_tour_General,
                        "description": "Take the tour General"
                    },
                    {
                        "link": vdoConst.Take_the_tour_lovely_to_see_you,
                        "description": "Take the tour lovely to see you"
                    },
                    {
                        "link": vdoConst.Take_the_tour_my_favourite_tour,
                        "description": "Take the tour my favourite tour"
                    },
                    {
                        "link": vdoConst.Take_the_tour_personal_tour_version,
                        "description": "Take the tour personal tour version"
                    },
                    {
                        "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                        "description": "Take the tour So you  looking for some help"
                    },
                    {
                        "link": vdoConst.Take_the_tour_Well_Well_Well,
                        "description": "Take the tour Well Well Well"
                    }
                ]
            },
            "teammembers": {
                "tour": [
                ],
                "video": [
                    {
                        "link": vdoConst.Account_Team_Members,
                        "description": "Adding new Team Member"
                    }
                ]
            },
            "documents": {
                "tour": [
                    {
                        "target": ".doc-folder-holder",
                        "content": "Documents are stored within folders which are unique to each prospect. Each account has a number of folders setup automatically for you and you can add more folders and subfolders by clicking the + icon.",
                        "disableBeacon": true
                    },
                    {
                        "target": ".btn-uploadFile",
                        "content": "Upload files using this button, you can drag and drop files or browser your PC. When uploading, you can select the folder, a file name and description, as well as a reference number.",
                    },
                    {
                        "target": ".rt-table",
                        "content": "All uploaded documents are stored in this table. You can sort the table by clicking on a column heading.",
                    },
                    {
                        "target": ".pagination-bottom",
                        "content": "Here you can control the table layout, you can change the number of rows per page, and when you have move than one page of documents you can tab back and forth using the ‘Previous’ and ‘Next’ buttons, or by typing the page number in the page field.",
                    }
                ],
                "video": [
                    {
                        "link": vdoConst.Take_the_tour_crank_this_baby_up,
                        "description": "Take the tour crank this baby up"
                    },
                    {
                        "link": vdoConst.Take_the_tour_General,
                        "description": "Take the tour General"
                    },
                    {
                        "link": vdoConst.Take_the_tour_lovely_to_see_you,
                        "description": "Take the tour lovely to see you"
                    },
                    {
                        "link": vdoConst.Take_the_tour_my_favourite_tour,
                        "description": "Take the tour my favourite tour"
                    },
                    {
                        "link": vdoConst.Take_the_tour_personal_tour_version,
                        "description": "Take the tour personal tour version"
                    },
                    {
                        "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                        "description": "Take the tour So you  looking for some help"
                    },
                    {
                        "link": vdoConst.Take_the_tour_Well_Well_Well,
                        "description": "Take the tour Well Well Well"
                    }
                ]
            }
        },
        "list": {
            "tour": [
                // {
                //     "target": "#tab_holder",
                //     "content": "This tab is to select Entities based on category and status",
                //     "disableBeacon": true
                // },
                // {
                //     "target": ".tbl-search-box",
                //     "content": "Can filter entity according to name"
                // },
                // {
                //     "target": ".tbl-right-panel-holder",
                //     "content": "Button used to add new entity"
                // }
            ],
            "video": [
                {
                    "link": vdoConst.All_Accounts_video,
                    "description": "All Accounts"
                }
            ]
        },
        "multi-step": {
            "tour": [
            ],
            "video": [
                {
                    "link": vdoConst.Adding_New_Account,
                    "description": "Adding new Accounts"
                }
            ]
        }
    },
    "dashboard": {
        "tour": [
        ],
        "video": [
            {
                "link": vdoConst.Dashboard_Tour_Video,
                "description": "Dashboard Tour"
            },
            {
                "link": vdoConst.Welcome_to_Fifo_Central,
                "description": "Welcome to Fifo Central"
            }
        ]
    },
    "dftransactions": {
        "details": {
            "tour": [
                {
                    "target": ".df-header-holder",
                    "content": "Whole of Book invoice summary header",
                    "disableBeacon": true
                },
                {
                    "target": ".wob-inv-summary",
                    "content": "Invoice summary"
                },
                {
                    "target": ".wob-debtor-list",
                    "content": "This session list all debtors who has pending invoices"
                }
            ],
            "video": [
            ]
        },
    },
    "administrator": {
        "transaction": {
            "fee": {
                "tour": [
                    // {
                    //     "target": ".tbl-search-box",
                    //     "content": "You can search fee by name from here",
                    //     "disableBeacon": true
                    // },
                    // {
                    //     "target": ".tbl-right-panel-holder",
                    //     "content": "You can add new fee from here"
                    // }
                ],
                "video": [
                    {
                        "link": vdoConst.Adding_IF_fees_and_charges_V1,
                        "description": "Adding IF fees and charges"
                    }
                ]
            }
        },
        "rates": {
            "tour": [
                // {
                //     "target": ".sticky-top",
                //     "content": "Select and add new rates from here",
                //     "disableBeacon": true
                // }
            ],
            "video": [
                {
                    "link": vdoConst.Adding_SCF_and_TF,
                    "description": "Adding SCF and TF"
                }
            ]
        }
    },
    "prospects": {
        "list": {
            "prospectconversion":{
                "video": [
                    {
                        "link": vdoConst.Prospect_to_Acc_Conversion,
                        "description": "Converting a Prospect to an Account"
                    },
                ],
                "tour": [],
            },
            "tour": [
                {
                    "target": "#tab_holder",
                    "content": "These are categories of prospects, you can view all prospects or only those that are Hot, Warm or Cold. You also have expired prospects here too. A prospect becomes expired if the expiry date is reached. You will be warned of expiring prospects, and you can reactivate a prospect up to 3 times. Following this you will have to request support.",
                    "disableBeacon": true
                },
                {
                    "target": ".tbl-search-box",
                    "content": "You can search for a prospect here, just start entering the business name, to commence searching."
                },
                {
                    "target": ".prospect_dropdown",
                    "content": "This dropdown box lets you change what type of prospects you can see onscreen. You can view all prospects, or prospects you’ve added by entering their details manually, or added through a bulk upload."
                },
                {
                    "target": ".prospect_status_drpwn",
                    "content": "Adjust the view to show only active or inactive prospects here."
                },
                {
                    "target": ".btn-prospect-more",
                    "content": "Access more options here, such as connecting your marketing or sales CRM."
                },
                {
                    "target": ".btn-prospect-bulkupload",
                    "content": "Perform bulk uploads here. Bulk uploads contain 4 simple steps, and there’s a handy template you can use to ensure you capture the right information"
                },
                {
                    "target": ".btn-prospect-add-new",
                    "content": "Add a single prospect using this button. You’ll need basic information such as Organisation Name, First Name and Last Name, Email and Phone number of a person working for the organisation"
                },
                {
                    "target": ".prospect-tbl-session1",
                    "content": "This column is the organisation name"
                },
                {
                    "target": ".prospect-tbl-session2",
                    "content": "These columns show the primary contact details for the prospects."
                },
                {
                    "target": ".prospect-tbl-session3",
                    "content": "Your prospects have a finite time they are exclusive to you and expire on the Expiry Date. You’ll be warned of prospects about to expire, and you can reactivate a prospect up to 3 times. Following this you’ll have to request support."
                },
                {
                    "target": ".prospect-tbl-session4",
                    "content": "The column shows you the date your prospect were created."
                },
                {
                    "target": ".prospect-tbl-session5",
                    "content": "The current status of a prospect is here."
                },
                {
                    "target": ".prospect-tbl-session6",
                    "content": "Actions are things you can do, for instance, open the prospects profile or connect their accounts."
                },
                {
                    "target": ".rt-tbody",
                    "content": "This is where you see your prospects. Clicking on many of the column heading sorts the table, click once for A – Z and click again for Z – A. When there are prospects in the table you will see tick boxes at the start of each row, these are for bulk functions, so you can select many prospects at once and apply a function. To the right, in the Action column, you’ll find edit and connect accounting software buttons."
                },
                {
                    "target": ".pagination-bottom",
                    "content": "This is the table navigation. You can select how many rows you want to show at a time on the table and if you have more than 1 page, then moving between pages can be done with the previous and next buttons."
                }
            ],
            "video": [
                {
                    "link": vdoConst.Take_the_tour_crank_this_baby_up,
                    "description": "Take the tour crank this baby up"
                },
                {
                    "link": vdoConst.Take_the_tour_General,
                    "description": "Take the tour General"
                },
                {
                    "link": vdoConst.Take_the_tour_lovely_to_see_you,
                    "description": "Take the tour lovely to see you"
                },
                {
                    "link": vdoConst.Take_the_tour_my_favourite_tour,
                    "description": "Take the tour my favourite tour"
                },
                {
                    "link": vdoConst.Take_the_tour_personal_tour_version,
                    "description": "Take the tour personal tour version"
                },
                {
                    "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                    "description": "Take the tour So you  looking for some help"
                },
                {
                    "link": vdoConst.Take_the_tour_Well_Well_Well,
                    "description": "Take the tour Well Well Well"
                }
            ]
        },
        "create": {
            "tour": [
                {
                    "target": ".organization-holder",
                    "content": "Enter the full name of the organization.",
                    "disableBeacon": true
                },
                {
                    "target": ".ab_number-holder",
                    "content": "The ABN is entered here."
                },
                {
                    "target": ".first_name-holder",
                    "content": "Enter the first name of your contact here."
                },
                {
                    "target": ".last_name-holder",
                    "content": "Here is where you enter the surname of your contact."
                },
                {
                    "target": ".email-holder",
                    "content": "Add your contact’s email address here."
                },
                {
                    "target": ".phone_no-holder",
                    "content": "Add your contact’s phone number."
                },
                {
                    "target": ".prospect-status-holder",
                    "content": "The default status is ‘Active’, you can select ‘Inactive’ if you are not actively pursuing this prospect."
                },
                {
                    "target": ".expiry_date-holder",
                    "content": "The expiry date of a prospect is set for you, if this date is reached and the record is still a prospect, you will be able to reactivate. This is to protects prospects from not being properly managed."
                },
                {
                    "target": ".prospect-category-holder",
                    "content": "Select the category for your lead, based on likelihood of becoming a client, Hot is very likely, Warm is a good work in progress, and Cold is either a prospect at the start their engagement with you, or not progressing quickly."
                }
            ],
            "video": [
                {
                    "link": vdoConst.Take_the_tour_crank_this_baby_up,
                    "description": "Take the tour crank this baby up"
                },
                {
                    "link": vdoConst.Take_the_tour_General,
                    "description": "Take the tour General"
                },
                {
                    "link": vdoConst.Take_the_tour_lovely_to_see_you,
                    "description": "Take the tour lovely to see you"
                },
                {
                    "link": vdoConst.Take_the_tour_my_favourite_tour,
                    "description": "Take the tour my favourite tour"
                },
                {
                    "link": vdoConst.Take_the_tour_personal_tour_version,
                    "description": "Take the tour personal tour version"
                },
                {
                    "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                    "description": "Take the tour So you  looking for some help"
                },
                {
                    "link": vdoConst.Take_the_tour_Well_Well_Well,
                    "description": "Take the tour Well Well Well"
                }
            ]
        },
        "bulk-upload": {
            "tour": [
                // {
                //     "target": ".bulk_upload_btn",
                //     "content": "You can browse and upload a file here.",
                //     "disableBeacon": true
                // },
                // {
                //     "target": ".prospect_bulk_template",
                //     "content": "You can download a template sheet here."
                // }
            ],
            "video": [
                {
                    "link": vdoConst.Prospect_Bulk_Uploads,
                    "description": "Prospect Bulk Uploads"
                }
            ]
        },
        "details": {
            "profile": {
                "prospectconversion":{
                    "video": [
                        {
                            "link": vdoConst.Prospect_to_Acc_Conversion,
                            "description": "Converting a Prospect to an Account"
                        },
                    ],
                    "tour": [],
                },
                "tour": [
                    {
                        "target": ".btn-convert-acc",
                        "content": "Convert the prospect to an account with this button",
                        "disableBeacon": true
                    },
                    {
                        "target": ".btn-pnotes",
                        "content": "Add notes here.",
                    },
                    {
                        "target": ".btn-managetask",
                        "content": "Add or edit tasks here.",
                    },
                    {
                        "target": ".btn-closeHeader",
                        "content": "Close the record here.",
                    },
                    {
                        "target": ".organization-holder",
                        "content": "Enter the full name of the organization."
                    },
                    {
                        "target": ".ab_number-holder",
                        "content": "The ABN is entered here."
                    },
                    {
                        "target": ".first_name-holder",
                        "content": "Enter the first name of your contact here."
                    },
                    {
                        "target": ".last_name-holder",
                        "content": "Here is where you enter the surname of your contact."
                    },
                    {
                        "target": ".email-holder",
                        "content": "Add your contact’s email address here."
                    },
                    {
                        "target": ".phone_no-holder",
                        "content": "Add your contact’s phone number."
                    },
                    {
                        "target": ".prospect-status-holder",
                        "content": "The default status is ‘Active’, you can select ‘Inactive’ if you are not actively pursuing this prospect."
                    },
                    {
                        "target": ".expiry_date-holder",
                        "content": "The expiry date of a prospect is set for you, if this date is reached and the record is still a prospect, you will be able to reactivate. This is to protects prospects from not being properly managed."
                    },
                    {
                        "target": ".prospect-category-holder",
                        "content": "Select the category for your lead, based on likelihood of becoming a client, Hot is very likely, Warm is a good work in progress, and Cold is either a prospect at the start their engagement with you, or not progressing quickly."
                    },
                    {
                        "target": ".hubspot-holder",
                        "content": "If you have HubSpot activated, you can link the prospect to HubSpot and keep the records sync’d."
                    }
                ],
                "video": [
                    {
                        "link": vdoConst.Take_the_tour_crank_this_baby_up,
                        "description": "Take the tour crank this baby up"
                    },
                    {
                        "link": vdoConst.Take_the_tour_General,
                        "description": "Take the tour General"
                    },
                    {
                        "link": vdoConst.Take_the_tour_lovely_to_see_you,
                        "description": "Take the tour lovely to see you"
                    },
                    {
                        "link": vdoConst.Take_the_tour_my_favourite_tour,
                        "description": "Take the tour my favourite tour"
                    },
                    {
                        "link": vdoConst.Take_the_tour_personal_tour_version,
                        "description": "Take the tour personal tour version"
                    },
                    {
                        "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                        "description": "Take the tour So you  looking for some help"
                    },
                    {
                        "link": vdoConst.Take_the_tour_Well_Well_Well,
                        "description": "Take the tour Well Well Well"
                    }
                ]
            },
            "documents": {
                "tour": [
                    {
                        "target": ".doc-folder-holder",
                        "content": "Documents are stored within folders which are unique to each prospect. You can add more folders and subfolders by clicking the + icon.",
                        "disableBeacon": true
                    },
                    {
                        "target": ".btn-uploadFile",
                        "content": "Upload files using this button, you can drag and drop files or browser your PC. When uploading, you can select the folder, a file name and description, as well as a reference number.",
                    },
                    {
                        "target": ".rt-table",
                        "content": "All uploaded documents are stored in this table. You can sort the table by clicking on a column heading.",
                    },
                    {
                        "target": ".pagination-bottom",
                        "content": "Here you can control the table layout, you can change the number of rows per page, and when you have move than one page of documents you can tab back and forth using the ‘Previous’ and ‘Next’ buttons, or by typing the page number in the page field.",
                    }
                ],
                "video": [
                    {
                        "link": vdoConst.Take_the_tour_crank_this_baby_up,
                        "description": "Take the tour crank this baby up"
                    },
                    {
                        "link": vdoConst.Take_the_tour_General,
                        "description": "Take the tour General"
                    },
                    {
                        "link": vdoConst.Take_the_tour_lovely_to_see_you,
                        "description": "Take the tour lovely to see you"
                    },
                    {
                        "link": vdoConst.Take_the_tour_my_favourite_tour,
                        "description": "Take the tour my favourite tour"
                    },
                    {
                        "link": vdoConst.Take_the_tour_personal_tour_version,
                        "description": "Take the tour personal tour version"
                    },
                    {
                        "link": vdoConst.Take_the_tour_So_you__looking_for_some_help,
                        "description": "Take the tour So you  looking for some help"
                    },
                    {
                        "link": vdoConst.Take_the_tour_Well_Well_Well,
                        "description": "Take the tour Well Well Well"
                    }
                ]
            }
        }
    },
    "introducer": {
        "list": {
            "tour": [
            ],
            "video": [
                {
                    "link": vdoConst.The_Introducer_Section,
                    "description": "The Introducer Section"
                }
            ]
        },
    },
    "scinvoice": {
        "details": {
            "tour": [
            ],
            "video": [
                {
                    "link": vdoConst.scf_and_tf_invoice_overview,
                    "description": "SCF And TF invoices overview"
                }
            ]
        },
    },
    "tfinvoice": {
        "details": {
            "tour": [
            ],
            "video": [
                {
                    "link": vdoConst.scf_and_tf_invoice_overview,
                    "description": "SCF And TF invoices overview"
                }
            ]
        },
    }
    
}

export default tour;