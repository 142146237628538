import React from "react"
import { FormRow } from "../../../common/containers/formContainer";
import { CurrencyLabel } from "../../../common/inputs/currencyInput/currencyInput";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import { TextInput } from "../../../common/inputs";
import { GeneralButton } from "../../../common/inputs";
import { DateTimePicker } from "../../../common/inputs/datePicker";
import { Link } from "react-router";
import moment from "moment";
import _ from "lodash";

export default function SettlementTable({ 
    tabs,
    selectedDate,
    selectedTab,
    handleChange,
    payments,
    balAvailable,
    chooseArray,
    handleBulkFullPayCheck,
    data,
    colorRed,
    handleBulkAmtFullPayment,
    handleBulkAmtPartlyPayment,
    finCostLoader,
    handleLabelToText,
    partPayBlur,
    textAmout,
    handleValueChange,
    handleOnBlur,
    loader,
    paginationLimit,
    setPaginationLimit,
    defaultPageSize,
    limitAvailable,
    handleInvDateChange,
    saveChanges
    }){
    return(
    <>
        <FormRow className="row mx-0 mt-4">
            <div className="col-sm-3 pl-0 ">
            {tabs && tabs.length > 0 && selectedDate && (
                <div className="dropdown dash-dropdown">
                <div
                    className="dropdown-menu w-100"
                    aria-labelledby="dropdownMenuButton"
                >
                    {tabs.map((obj, key) => (
                    <a
                        key={key}
                        className={`dropdown-item ${
                        selectedTab == obj.value ? "active" : ""
                        }`}
                        onClick={(e) => handleChange(obj.value)}
                        href="#"
                    >
                        {obj.label}
                    </a>
                    ))}
                </div>
                <button
                    className="btn btn-outline-secondary dropdown-toggle pr-sm-4 pr-3 p-2 w-100 text-left btn  dropdown-toggle  pr-sm-4 pr-3 pl-lg-2 w-100"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {tabs.map((obj) => (
                    <>{selectedTab == obj.value ? obj.label : ""}</>
                    ))}
                </button>
                </div>
            )}
            </div>

            {/* <div className="col-sm-3 my-3"></div> */}
            {selectedDate && (
            <>
                <div className="col-sm-2  "></div>
                <div className="col-sm-3 ">
                <div className="b-dashed-2 py-2 px-2 bg-light">
                    <p className="mb-1">Total Payment Allocated</p>
                    <h6 className="font-weight-bold  mb-1">
                    <CurrencyLabel
                        label="make pay"
                        value={_.sumBy(payments, (item) => Number(item.amount))}
                    ></CurrencyLabel>
                    </h6>
                </div>
                </div>

                <div className="col-sm-4 pr-0">
                <div className="b-dashed-2 py-2 px-2 bg-light">
                    <p className="mb-1">Facility Balance</p>
                    <h6 className="font-weight-bold  mb-1">
                    <CurrencyLabel
                        label="total amount"
                        value={balAvailable}
                    ></CurrencyLabel>
                    </h6>
                </div>
                </div>
            </>
            )}
        </FormRow>
        <FormRow className="row mx-0 mt-2">
            <div className="col-sm-12">
            {tabs && tabs.length > 0 && (
                <div className="row justify-content-left mb-4">
                <div className="col-sm-6 col-lg-5"></div>
                </div>
            )}

            {data.length > 0 ? (
                <>
                <FormRow>
                    <div id="scrollDiv" className="scrollDiv dyn-height">
                    <table className="table text-secondary  ">
                        {/* {paymentInfo.length > 0 && ( */}
                        <thead>
                        <tr>
                            <th>
                            Full
                            <div className="custom-control d-flex custom-checkbox small">
                                <input
                                id={"bulkFullPayCheck"}
                                className="custom-control-input"
                                type="checkbox"
                                checked={
                                    chooseArray.includes("bulkFullPayCheck") || false
                                } //s
                                onClick={(e) => handleBulkFullPayCheck(e)}
                                ></input>
                                <label
                                className="custom-control-label"
                                htmlFor={"bulkFullPayCheck"}
                                ></label>
                            </div>
                            </th>
                            <th>Part</th>
                            <th className="sticky-col">Supplier</th>
                            <th>Inv No.</th>
                            <th>Inv Date</th>

                            <th>Revised Due Date</th>
                            <th>Inv Amount</th>
                            <th>Due Date</th>
                            <th>Late Payment Fee</th>
                            <th>Finance Cost</th>
                            <th>Total Due</th>
                            <th>Recieved</th>
                            <th>DD Requested</th>
                            <th>Balance Outstanding</th>
                            <th>Payment Pending</th>
                            <th style={{ minWidth: 150 }}>Amount You Are Paying</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((listValue, index) => {
                            return (
                            <tr
                                key={index}
                                style={listValue.is_overdue === 1 ? colorRed : {}}
                            >
                                <td className="sticky-col">
                                {listValue.amount_client_req == null && (
                                    <div className="custom-control d-flex custom-checkbox small">
                                    <input
                                        id={"fullPayCheck" + listValue.id}
                                        className="custom-control-input"
                                        type="checkbox"
                                        checked={
                                        chooseArray.includes(
                                            "fullPayCheck" + listValue.id,
                                        ) || false
                                        }
                                        onClick={(e) =>
                                        handleBulkAmtFullPayment(
                                            e,
                                            listValue.total_due -
                                            listValue.dd_requested || "0",
                                        )
                                        }
                                        // onChange={onSelectedChange(index)}
                                        value={listValue.id} //id
                                        // size={listValue.total_due} //amount
                                        name={listValue.amount_paid || "0"} //paid amount
                                    ></input>
                                    <label
                                        className="custom-control-label"
                                        htmlFor={"fullPayCheck" + listValue.id}
                                    ></label>
                                    </div>
                                )}
                                </td>
                                <td className="sticky-col">
                                {listValue.amount_client_req == null && (
                                    <div className="custom-control d-flex custom-checkbox small">
                                    <input
                                        id={"partPayCheck" + listValue.id}
                                        className="custom-control-input"
                                        type="checkbox"
                                        checked={
                                        chooseArray.includes(
                                            "partPayCheck" + listValue.id,
                                        ) || false
                                        }
                                        onClick={(e) =>
                                        handleBulkAmtPartlyPayment(e.target)
                                        }
                                        value={listValue.id}
                                        // size={listValue.inv_amount} //amount
                                    ></input>
                                    <label
                                        className="custom-control-label"
                                        htmlFor={"partPayCheck" + listValue.id}
                                    ></label>
                                    </div>
                                )}
                                </td>
                                <td className="sticky-col">{listValue.organization}</td>
                                <td>{listValue.inv_number}</td>
                                <td>
                                {moment(listValue.trans_date).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                {moment(listValue.inv_revised_due_date).format(
                                    "DD/MM/YYYY",
                                )}
                                </td>
                                <td>
                                <CurrencyLabel
                                    value={listValue.inv_amount}
                                ></CurrencyLabel>
                                </td>
                                <td>
                                {moment(listValue.inv_due_date).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                <CurrencyLabel
                                    value={listValue.inv_late_payment_fee || "-"}
                                ></CurrencyLabel>
                                </td>
                                <td>
                                {finCostLoader == true ? (
                                    <LoadingSpinner normal={true}></LoadingSpinner>
                                ) : (
                                    <CurrencyLabel
                                    value={listValue.finance_cost || "-"}
                                    ></CurrencyLabel>
                                )}
                                </td>{" "}
                                {/*Finance Cost */}
                                <td>
                                <CurrencyLabel
                                    value={listValue.total_due || "-"}
                                ></CurrencyLabel>
                                </td>
                                <td>
                                <CurrencyLabel
                                    value={listValue.amount_paid || "-"}
                                ></CurrencyLabel>
                                </td>
                                <td>
                                <CurrencyLabel
                                    value={listValue.dd_requested || "-"}
                                ></CurrencyLabel>
                                </td>
                                <td>
                                <CurrencyLabel
                                    value={
                                    listValue.total_due -
                                    listValue.amount_paid -
                                    listValue.dd_requested
                                    }
                                ></CurrencyLabel>
                                </td>
                                <td>
                                <span className="text-danger">
                                    <CurrencyLabel
                                    value={listValue.amount_client_req || "-"}
                                    ></CurrencyLabel>
                                </span>
                                </td>
                                <td style={{ minWidth: 150 }}>
                                {chooseArray.includes(
                                    "fullPayCheck" + listValue.id,
                                ) && (
                                    <>
                                    <span
                                        onClick={(e) =>
                                        handleLabelToText(
                                            "partPayCheck" + listValue.id,
                                            listValue.id,
                                        )
                                        }
                                        className="font-weight-bold"
                                    >
                                        {" "}
                                        <CurrencyLabel
                                        value={
                                            listValue.total_due -
                                            listValue.amount_paid -
                                            listValue.dd_requested
                                        }
                                        ></CurrencyLabel>
                                    </span>
                                    </>
                                )}
                                {chooseArray.includes(
                                    "partPayCheck" + listValue.id,
                                ) && (
                                    <>
                                    {/* /////// partPayBlur.filter(row => row.textBox == 'partPayCheck' + listValue.id).lenght>-1*/}
                                    {/* partPayBlur.includes('partPayCheck' + listValue.id) */}
                                    {partPayBlur.some(
                                        (code) =>
                                        code.textBox ===
                                        "partPayCheck" + listValue.id,
                                    ) ? (
                                        <>
                                        {partPayBlur.map((pay, index) => {
                                            return (
                                            <>
                                                {pay.textBox ==
                                                "partPayCheck" + listValue.id && (
                                                <>
                                                    <span
                                                    key={index}
                                                    onClick={(e) =>
                                                        handleLabelToText(
                                                        "partPayCheck" + listValue.id,
                                                        listValue.id,
                                                        )
                                                    }
                                                    className="font-weight-bold"
                                                    >
                                                    {" "}
                                                    <CurrencyLabel
                                                        value={pay.value}
                                                    ></CurrencyLabel>
                                                    </span>
                                                </>
                                                )}
                                            </>
                                            );
                                        })}
                                        </>
                                    ) : (
                                        <>
                                        <TextInput
                                            name={listValue.id}
                                            value={
                                            payments.includes(listValue.id) ||
                                            textAmout
                                            }
                                            label="Amount"
                                            onChange={(e) =>
                                            handleValueChange(
                                                e,
                                                listValue.total_due -
                                                listValue.amount_paid -
                                                listValue.dd_requested,
                                            )
                                            }
                                            type="normal"
                                            size={listValue.amount_paid} //balance to pay
                                            onBlur={(e) =>
                                            handleOnBlur(
                                                e,
                                                "partPayCheck" + listValue.id,
                                            )
                                            }
                                            className="form-control input-xs"
                                            autoFocus={true}
                                        ></TextInput>
                                        {/* <CurrencyText
                                                    placeholder="Amount + Tax" 
                                                    name="amount" 
                                                    //  onBlur={this.handleAmount} 
                                                    onChangeEvent={(e) => handleValueChange(e,10)}
                                                    value={payments.includes(listValue.id) || textAmout}
                                                    //  readonly={this.state.readOnly} 
                                                    title="Please choose the supplier "/> */}
                                        </>
                                    )}
                                    </>
                                )}
                                </td>
                            </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    </div>
                    <div className="mt-2">
                    <span className="text-danger text-xs">
                        *Please note that the selected payment amount may not include
                        all accrued interest, late payment fees or additional charges
                        that may be applicable at this time of settlement.
                    </span>
                    </div>
                </FormRow>
                </>
            ) : (
                <>
                <div
                    className="row bg-white b-dashed-2 p-2 mt-2"
                    style={{ minHeight: 80 }}
                >
                    {data.length === 0 && selectedDate ? (
                    <div className="col-lg-12 text-center mt-3">
                        <div className="alert alert-info">
                        {loader ? "Loading Data..." : "You have no payment pending!"}
                        </div>
                    </div>
                    ) : (
                    <>
                        <div className="col-lg-12 text-center mt-3">
                        <h6>
                            Please select the date on which you would like to make your
                            payment.
                        </h6>
                        </div>
                    </>
                    )}
                </div>
                </>
            )}

            {data.length >= paginationLimit && (
                <div className="row mt-2">
                <div className="col-lg-12 text-center">
                    <strong>
                    <span className="text-primary">
                        <Link
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                            setPaginationLimit(data.length + defaultPageSize)
                        }
                        >
                        Load More...
                        </Link>
                    </span>
                    </strong>
                </div>
                </div>
            )}

            <div className="row  mt-3">
                <div
                className={`col-md-3 col-sm-12 pt-3 pb-2  ${
                    limitAvailable ? "b-dashed-2 bg-light" : ""
                }`}
                >
                {limitAvailable && (
                    <div>
                    <p className="mb-1">Availability after payment</p>
                    <h4 className="font-weight-bold  mb-1">
                        <CurrencyLabel
                        label="make pay"
                        value={
                            _.sumBy(payments, (item) => Number(item.amount)) +
                            balAvailable >
                            limitAvailable
                            ? limitAvailable
                            : _.sumBy(payments, (item) => Number(item.amount)) +
                                balAvailable
                        }
                        ></CurrencyLabel>
                    </h4>
                    <span className="text-danger text-xs">
                        * Subject to the payment approval
                    </span>
                    </div>
                )}
                </div>

                <div className="col-md-3 d-md-block d-sm-none"></div>

                <div className="col-md-6 col-sm-12">
                <div className="row">
                    <div className="col-md-8 ">
                    <DateTimePicker
                        name="date"
                        type="normal"
                        onChange={handleInvDateChange}
                        selected={
                        typeof selectedDate !== "object"
                            ? new Date(Date.parse(selectedDate))
                            : selectedDate
                        }
                        // selected={new Date(Date.parse(payDate))}
                        format="dd/MM/y"
                        placeholder="Pay Date"
                        //maxDate={new Date(Date.parse(lockDate))}
                    ></DateTimePicker>
                    </div>
                    <div className="col-md-4">
                    <GeneralButton
                        onClick={saveChanges}
                        disabled={(!payments.length && true) || finCostLoader}
                        className="btn btn-primary pull-right ml-2"
                        name="Confirm Payment"
                    ></GeneralButton>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </FormRow>
    </>
    )
}