import React, { useState, useEffect } from "react";
import { DateTimePicker } from "../../../../../../common/inputs/datePicker";
import commonService from "../../../../../../supply-chain/services/commonService";
import useApi from "../../../../../../../hooks/useApi";
import { CurrencyLabel } from "../../../../../../common/inputs";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import authService from "../../../../../../../services/authService";
import Utility from "../../../../../../../utils/DateParser";
import LoadingSpinner from "../../../../../../common/loaders/loadingSpinner";
import { GeneralButton, Picker } from "../../../../../../common/inputs";
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import {
  DDInvoiceModal,
  FcInvoiceModal,
  LessAmountPaidModal,
  LpfInvoiceModal,
  OpenModal,
  TotalDueModal,
} from "../_totatlDueinvoiceModal";
import PartPayment from "../partPayment";
import PaymentConfirmation from "../_paymentConfirmationModal";
import PaymentRequestHistory from "../paymentRequestedHistory";
import OtherPayments from "../_otherPayments";

const ReconcileClientScf = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [overdueInvoices, setOverdueInvoices] = useState([]);
  const [payments, setPayments] = useState([]);
  const [totalValues, setTotalValues] = useState([]);
  const [loader, setLoader] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [type, setType] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [finModal, setFinModal] = useState(false);
  const [lpfModal, setLpfModal] = useState(false);
  const [ddModal, setDdModal] = useState(false);
  const [amountPaidModal, setAmountPaidModal] = useState(false);
  const [totalDueModal, setTotalDueModal] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [paymentSettlementConfirmModal, setPaymentSettlementConfirmModal] =
    useState(false);
  const [partPayModal, setPartPayModal] = useState(false);
  const [OtherPayModal, setOtherPayModal] = useState(false);
  const [allPayments, setAllPayments] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    label: "Pending",
    value: "Pending",
  });
  const [filteredPayments, setFilteredPayments] = useState([]);
  const { entity_id, constants } = authService.getCurrentUser();

  const fetchApiDateChange = useApi(commonService.getSCFBulkPayDateBase);
  const fetchApiTotalFees = useApi(commonService.getTotalFees);
  const clientPaymentsUpdateApi = useApi(commonService.clientBulkPaymentUpdate);
  const fetchApi = useApi(commonService.getClientPaymentAll);

  const { ParseDateDMY, ParseDate } = Utility();
  const fetchSummary = async (date) => {
    if (date !== null) {
      setLoader(true);
      const result = await fetchApiTotalFees.request({
        guid: entity_id,
        date_selected: ParseDate(date),
        product_id: constants.PRODUCTS.SUPPLYCHAIN,
      });

      setTotalValues(result.data.total);
      setLoader(false);
    }
  };

  const fetchAllData = async (date) => {
    setModalLoader(true);
    const { data } = await fetchApiDateChange.request({
      guid: entity_id,
      date_selected: ParseDate(date),
      product_type: constants.PRODUCTS.SUPPLYCHAIN,
    });
    setTimeout(() => {
      setData(data.data);
    }, 1000);

    setModalLoader(false);
  };
  useEffect(() => {
    const currentDate = new Date();
    if (data.length > 0) {
      if (new Date(selectedDate) > currentDate) {
        const filteredInvoices = data.filter((invoice) => {
          const compareDate = invoice.inv_revised_due_date
            ? new Date(invoice.inv_revised_due_date)
            : new Date(invoice.inv_due_date);

          return (
            compareDate.setHours(0, 0, 0, 0) < selectedDate.setHours(0, 0, 0, 0)
          );
        });

        setOverdueInvoices(filteredInvoices);
      } else {
        const overdueRows = data.filter((invoice) => invoice.is_overdue === 1);
        setOverdueInvoices(overdueRows);
      }
    }
  }, [data]);

  const handleDateChange = async (date) => {
    setSelectedDate(date);
  };
  const submit = () => {
    setShowPayment(true);
    fetchSummary(selectedDate);
    setChangeDate(false);
    if (data.length === 0) {
      fetchAllData(selectedDate);
    }
  };
  // useEffect(() => {
  //   fetchSummary(selectedDate);
  // }, []);

  const fullPayment = async () => {
    const newPayments = [];
    if (data.length > 0) {
      data.map((listValue, index) => {
        let amount =
          listValue.total_due - listValue.amount_paid - listValue.dd_requested;
        let id = listValue.id;

        let interest = 0;

        if (listValue.finance_cost_paying > 0) {
          interest =
            parseFloat(amount) >= listValue.finance_cost_paying
              ? listValue.finance_cost_paying
              : parseFloat(amount);
        }

        let principle = parseFloat(amount) - interest;
        let payment = {
          interest: interest.toFixed(2),
          principle: principle.toFixed(2),
        };

        newPayments.push({
          id,
          amount,
          dd_requested: listValue.dd_requested,
          inv_number: listValue.inv_number,
          payment,
        });
      });
      setTimeout(() => {
        setPayments((prevPayments) => [...prevPayments, ...newPayments]);
        setType("full");
      }, 1000);

      // if (payments.length > 0) {
      confirmMethod();
    }

    // }
  };

  useEffect(() => {
    if (payments.length > 0) {
      confirmMethod();
    }
  }, [type]);

  const confirmMethod = () => {
    confirmAlert({
      container: document.body,
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-delete-ui"
            style={{ zIndex: 1100, position: "relative" }}
          >
            <h6>
              You are requesting a settlement of{" "}
              {
                <CurrencyLabel
                  value={
                    totalValues.total_amount_pending -
                    totalValues.ddAmountRequested
                  }
                />
              }{" "}
              against {totalValues.openinvoicescount} invoices
            </h6>
            <p>Do you want to continue with the payment request?</p>
            <button
              className="btn btn-white"
              onClick={() => {
                onClose();
                setPayments([]);
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={async () => {
                onClose();
                handleSave();
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  };

  const handleSave = async () => {
    setLoader(true);
    if (payments.length > 0) {
      const { data } = await clientPaymentsUpdateApi.request({
        payments: payments,
        product_type: constants.PRODUCTS.SUPPLYCHAIN,
        date: ParseDate(selectedDate),
      });
      if (data.success === false) {
        setLoader(false);
        toast.error(data.message);
      } else {
        setPayments([]);
        setLoader(false);
        fetchSummary();
        fetchAllDetails();
        setPaymentResponse(data);
        setData([]);
      }
    }
  };

  useEffect(() => {
    if (paymentResponse) setPaymentSettlementConfirmModal(true);
  }, [paymentResponse]);
  useEffect(() => {
    fetchAllDetails();
  }, []);
  const fetchAllDetails = async () => {
    setLoader(true);
    const allData = await fetchApi.request({
      guid: entity_id,
      type: "request",
      page: 1,
      pageSize: 100,
      product: constants.PRODUCTS.SUPPLYCHAIN,
    });

    const cilentReqAll = allData.data.rows.filter(
      (row) => row.amount_requested != undefined
    );
    setAllPayments(cilentReqAll);
    setLoader(false);
  };
  const filters = [
    {
      label: "All requests",
      value: "all",
    },
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Received",
      value: "Received",
    },
    {
      label: "Rejected",
      value: "Rejected",
    },
  ];

  useEffect(() => {
    if (selectedItem.value === "all") {
      setFilteredPayments(allPayments);
    } else {
      const filtered = allPayments.filter(
        (payment) => payment.requested_status === selectedItem.value
      );

      setFilteredPayments(filtered);
    }
  }, [selectedItem, allPayments]);
  const history = useHistory();

  // const handleRedirect = () => {
  //   history.push("/client/home");
  //   window.location.reload();
  // };
  let ddCount = 0;
  if (data.length > 0) {
    data.forEach((listValue) => {
      if (listValue.dd_requested !== null) {
        ddCount += 1;
      }
    });
  }
  return (
    <>
      {/* <div className="card-header">
          <h6>New settlement request</h6>
          {showPayment && (
            <div className="pull-right" style={{ marginTop: "-15px" }}>
              <Link
                className="btn btn-light pull-right text-secondary ml-2"
                onClick={() => {
                  setShowPayment(false);
                  setData([]);
                }}
              >
                {" "}
                <i className="fa fa-times "></i>
              </Link>
            </div>
          )}
          {!showPayment && (
            <div className="pull-right" style={{ marginTop: "-15px" }}>
              <button
                className="btn btn-light pull-right text-secondary ml-2"
                onClick={handleRedirect}
              >
                {" "}
                <i className="fa fa-times "></i>
              </button>
            </div>
          )}
        </div> */}
      <div className="card overflow-visible">
        {
          showPayment ? (
            loader ? (
              <LoadingSpinner />
            ) : (
              <div className="card-body">
                <h6>Settlement request</h6>
                <div className="pull-right" style={{ marginTop: "-15px" }}>
                  <Link
                    className="btn btn-light pull-right text-secondary ml-2"
                    onClick={() => {
                      setShowPayment(false);
                      setData([]);
                    }}
                  >
                    {" "}
                    <i className="fa fa-times "></i>
                  </Link>
                </div>
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-md-6 b-dashed text-center p-3">
                      <h2>
                        <CurrencyLabel
                          value={
                            totalValues.total_amount_pending -
                            totalValues.ddAmountRequested
                          }
                        />
                      </h2>
                      <p
                        className="text-muted mt-2"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        Proposed Settlement Date &nbsp;
                        <b className="text-secondary">
                          {ParseDateDMY(selectedDate)}
                          <i
                            title="Change payment date"
                            className="fa fa-edit ml-2 text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setChangeDate(true);
                              setData([]);
                            }}
                          ></i>
                        </b>
                      </p>
                      <p className="label bg-success-transparent text-secondary p-2 mt-2">
                        <CurrencyLabel
                          value={
                            parseFloat(totalValues.fundAvailable) +
                            parseFloat(totalValues.pricipleOwing)
                          }
                        />{" "}
                        will be available to draw after this payment
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-md-8     p-3">
                      <div class="list-item">
                        <span>Total Invoice Value</span>
                        <i
                          title="Click here to view the details"
                          className="fa fa-info-circle ml-2 text-primary float-right mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenInvoiceModal(true);
                            if (data.length === 0) {
                              fetchAllData(selectedDate);
                            }
                          }}
                        ></i>
                        <span class="float-right  text-secondary">
                          <CurrencyLabel value={totalValues.total_inv_amount} />
                        </span>
                        <span className="badge bg-secondary-transparent ml-2">
                          {totalValues.openinvoicescount} Open invoices
                        </span>
                      </div>
                      <div class="list-item mt-3">
                        <span>Total Finance Cost</span>{" "}
                        <span className="badge bg-secondary-transparent ml-2">
                          Up to {ParseDateDMY(selectedDate)}
                        </span>
                        <i
                          title="Click here to view the details"
                          className="fa fa-info-circle ml-2 text-primary float-right mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setFinModal(true);
                            if (data.length === 0) {
                              fetchAllData(selectedDate);
                            }
                          }}
                        ></i>
                        <span class="float-right text-secondary">
                          <CurrencyLabel value={totalValues.finance_cost} />
                        </span>
                      </div>
                      <div class="list-item mt-3">
                        <span>Late Payment Fees</span>
                        <i
                          title="Click here to view the details"
                          className="fa fa-info-circle ml-2 text-primary float-right mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setLpfModal(true);
                            if (data.length === 0) {
                              fetchAllData(selectedDate);
                            }
                          }}
                        ></i>
                        <span class="float-right text-secondary">
                          <CurrencyLabel value={totalValues.lpf} />
                        </span>{" "}
                        <span className="badge bg-danger-transparent text-danger ml-2">
                          {totalValues.overDueCount} Invoices Overdue{" "}
                          {new Date(Date.parse(selectedDate)) > new Date()
                            ? "by " + ParseDateDMY(selectedDate)
                            : null}
                        </span>
                      </div>
                      <hr />
                      <div class="mt-3">
                        <span>Total Amount Due</span>
                        <i
                          title="Click here to view the details"
                          className="fa fa-info-circle ml-2 text-primary float-right mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setTotalDueModal(true);
                            if (data.length === 0) {
                              fetchAllData(selectedDate);
                            }
                          }}
                        ></i>
                        <span class="float-right text-secondary">
                          <CurrencyLabel value={totalValues.total_due_amount} />
                        </span>
                      </div>
                      <hr />
                      <div class="list-item mt-3">
                        <span>Less Direct Payments Received</span>
                        <i
                          title="Click here to view the details"
                          className="fa fa-info-circle ml-2 text-primary float-right mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setAmountPaidModal(true);
                            if (data.length === 0) {
                              fetchAllData(selectedDate);
                            }
                          }}
                        ></i>
                        <span class="float-right text-secondary">
                          -<CurrencyLabel value={totalValues.amount_paid} />
                        </span>{" "}
                        <span className="badge bg-primary-transparent text-primary ml-2">
                          {totalValues.lessAmountPaidCount} Invoices
                        </span>
                      </div>
                      <div class="list-item mt-3">
                        <span>Less Direct Debit</span>
                        <i
                          title="Click here to view the details"
                          className="fa fa-info-circle ml-2 text-primary float-right mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setDdModal(true);
                            if (data.length === 0) {
                              fetchAllData(selectedDate);
                            }
                          }}
                        ></i>
                        <span class="float-right text-secondary">
                          -
                          <CurrencyLabel
                            value={totalValues.ddAmountRequested}
                          />
                        </span>{" "}
                        <span className="badge bg-warning ml-2">
                          {ddCount} Pending
                        </span>
                      </div>
                      <hr />
                      <div class="list-item mt-3">
                        <span>Total Settlement Amount</span>

                        <span class="float-right font-weight-bold text-secondary">
                          {" "}
                          <CurrencyLabel
                            value={
                              totalValues.total_amount_pending -
                              totalValues.ddAmountRequested
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center mt-2">
                    <div class="col-md-8  text-center   p-3">
                      <button
                        className="btn  btn-outline-primary mr-2"
                        disabled={
                          totalValues.otherAmount === true ||
                          data.some(
                            (item) =>
                              item.amount_client_req !== null &&
                              data.some(
                                (item) =>
                                  item.trans_status !== "Closed Fund Collected"
                              )
                          )
                        }
                        onClick={(e) => {
                          setOtherPayModal(true);
                        }}
                      >
                        Other Amount
                      </button>

                      <button
                        className="btn   btn-secondary"
                        disabled={
                          totalValues.otherAmount === true ||
                          data.some(
                            (item) =>
                              item.amount_client_req !== null &&
                              data.some(
                                (item) =>
                                  item.trans_status !== "Closed Fund Collected"
                              )
                          )
                        }
                        onClick={() => {
                          setPartPayModal(true);
                          if (data.length === 0) {
                            fetchAllData(selectedDate);
                          }
                        }}
                      >
                        Pay Part Invoices
                      </button>
                      <button
                        className="btn  btn-primary ml-2"
                        disabled={
                          totalValues.otherAmount === true ||
                          data.some(
                            (item) =>
                              item.amount_client_req !== null &&
                              data.some(
                                (item) =>
                                  item.trans_status !== "Closed Fund Collected"
                              )
                          )
                        }
                        onClick={(e) => {
                          if (data.length === 0) {
                            fetchAllData(selectedDate);
                          }
                          fullPayment(e);
                        }}
                      >
                        Pay Full Amount Pending
                      </button>
                    </div>
                    <span className="text-danger text-xs">
                      *Please note that the selected payment amount may not
                      include all accrued interest, late payment fees or
                      additional charges that may be applicable at this time of
                      settlement.
                    </span>
                  </div>
                </div>
              </div>
            )
          ) : null
          // <div class="container">
          //   <div className="row">
          //     <div
          //       className="col-lg-12"
          //       style={{ display: "flex", justifyContent: "center" }}
          //     >
          //       <div className="form-group mb-1" style={{ width: "200px" }}>
          //         <DateTimePicker
          //           name="date"
          //           type="normal"
          //           onChange={handleDateChange}
          //           selected={
          //             typeof selectedDate !== "object"
          //               ? new Date(Date.parse(selectedDate))
          //               : selectedDate
          //           }
          //           minDate={new Date()}
          //           format="dd/MM/y"
          //           placeholder="Select Date"
          //         ></DateTimePicker>
          //       </div>
          //       <div className="form-group mb-1">
          //         <GeneralButton
          //           onClick={submit}
          //           className="btn btn-primary ml-2 pull-right"
          //           disabled={selectedDate === null}
          //           name="Continue"
          //         />
          //       </div>
          //     </div>
          //     <div
          //       className="col-lg-12"
          //       style={{ display: "flex", justifyContent: "center" }}
          //     >
          //       <span class="mt-2 text-muted">
          //         Kindly pick your preferred payment date, then proceed by
          //         clicking the "Continue" button.
          //       </span>
          //     </div>
          //   </div>
          // </div>
        }
      </div>
      {showPayment === false ? (
        loader ? (
          <LoadingSpinner />
        ) : (
          <div className="card overflow-visible">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-6">
                  <h6>Previous settlement</h6>
                </div>
                <div className="col-lg-6 mb-4">
                  <GeneralButton
                    onClick={submit}
                    style={{ width: "200px" }}
                    className="btn btn-primary pull-right"
                    name="New Settlement Request"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                  <div
                    className="float-right"
                    style={{ width: "200px", marginRight: "40px" }}
                  >
                    <Picker
                      items={filters}
                      selectedItem={selectedItem}
                      onSelectItem={(option) => {
                        setSelectedItem(option);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <PaymentRequestHistory
                filteredPayments={filteredPayments}
                fetchAllDetails={fetchAllDetails}
                product={constants.PRODUCTS.SUPPLYCHAIN}
              />
            </div>
          </div>
        )
      ) : null}

      {/* Open invoices modal */}
      <OpenModal
        openInvoiceModal={openInvoiceModal}
        setOpenInvoiceModal={setOpenInvoiceModal}
        data={data}
        modalLoader={modalLoader}
      />

      {/* Finance cost invoices modal */}
      <FcInvoiceModal
        finModal={finModal}
        setFinModal={setFinModal}
        data={data}
        modalLoader={modalLoader}
      />

      {/* LPF invoices modal */}
      <LpfInvoiceModal
        lpfModal={lpfModal}
        setLpfModal={setLpfModal}
        data={overdueInvoices}
        modalLoader={modalLoader}
      />

      {/* DD invoices modal */}
      <DDInvoiceModal
        ddModal={ddModal}
        setDdModal={setDdModal}
        data={data}
        modalLoader={modalLoader}
        selectedDate={ParseDateDMY(selectedDate)}
      />

      {/* Leass amount paid invoices modal */}
      <LessAmountPaidModal
        amountPaidModal={amountPaidModal}
        setAmountPaidModal={setAmountPaidModal}
        data={data}
        modalLoader={modalLoader}
      />

      {/* Total due invoices modal */}
      <TotalDueModal
        totalDueModal={totalDueModal}
        setTotalDueModal={setTotalDueModal}
        data={data}
        modalLoader={modalLoader}
      />

      {/*------------ Payment settlement confirmation modal ---------- */}
      <PaymentConfirmation
        paymentSettlementConfirmModal={paymentSettlementConfirmModal}
        setPaymentSettlementConfirmModal={setPaymentSettlementConfirmModal}
        paymentResponse={paymentResponse}
        setShowPayment={setShowPayment}
        setData={setData}
        fetchAllDetails={fetchAllDetails}
      />

      {/*--------------------------------------------------------------*/}
      {/*------------ Partial payment Modal ---------- */}

      <PartPayment
        partPayModal={partPayModal}
        setPartPayModal={setPartPayModal}
        data={data}
        loader={loader}
        payments={payments}
        setPayments={setPayments}
        handleSave={handleSave}
        selectedDate={selectedDate}
        modalLoader={modalLoader}
      />
      {/*------------ Other payment Modal ---------- */}
      <OtherPayments
        OtherPayModal={OtherPayModal}
        setOtherPayModal={setOtherPayModal}
        product={constants.PRODUCTS.SUPPLYCHAIN}
        entity_id={entity_id}
        fetchSummary={fetchSummary}
        fetchAllDetails={fetchAllDetails}
        setShowPayment={setShowPayment}
      />
      {/*--------------------------------------------------------------*/}
      <Modal
        show={changeDate}
        onHide={() => setChangeDate(false)}
        backdrop="static"
        centered
        size="sm"
      >
        <Modal.Header closeButton>Change settlement date</Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-8">
              <div className="form-group mb-1" style={{ width: "200px" }}>
                <DateTimePicker
                  name="date"
                  type="normal"
                  onChange={handleDateChange}
                  selected={
                    typeof selectedDate !== "object"
                      ? new Date(Date.parse(selectedDate))
                      : selectedDate
                  }
                  minDate={new Date()}
                  format="dd/MM/y"
                  placeholder="Select Date"
                  style={{ marginLeft: "6px" }}
                ></DateTimePicker>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group mb-1">
                <GeneralButton
                  onClick={submit}
                  className="btn btn-primary ml-2 pull-right"
                  disabled={selectedDate === null}
                  name="Continue"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ReconcileClientScf;
