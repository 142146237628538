import React from "react";
import { Link } from "react-router";
import PayNowIconLight from "../../../img/icons/fp-icon-light.svg"
import PayNowIconPrimary from "../../../img/icons/fp-icon-primary.svg"
import PayNowIconSecondary from "../../../img/icons/fp-icon-secondary.svg"
import "./css/payMe.css";
const SubmitButton = (props) => (
  <React.Fragment>
    <button className="btn btn-primary block full-width m-b" type="submit">
      {props.label}
    </button>
  </React.Fragment>
);

const AddButton = (props) => (
  <React.Fragment>
    <Link to={props.route}>
      <button
        type="button"
        id={props.id}
        disabled={props.disabled}
        className={
          props.style ? props.style : "btn btn-primary btn-sm "
        }
        onClick={props.onClick && props.onClick}
      >
        {props.faIcon && (<i className={props.faIcon}></i>)}{props.label && (<>{props.label}</>)}
      </button>
    </Link>
  </React.Fragment>
);
//---------------FP Button-------------------------------------
const FpButton = (props) => (
  <React.Fragment>
    <button type="button" disabled={props.disabled} onClick={props.onClick && props.onClick} className={`payme-${props.type ? props.type : "secondary"}`}>
      <div className="d-flex">
        <img src={props.type == "primary" ? PayNowIconSecondary : PayNowIconPrimary} alt="..." />
        <h6 className="mb-0 text-left"><span class="text-sm">{props.smallLabel ? props.smallLabel : "Pay Me"}  </span>{props.label ? props.label : "Now"} </h6>
      </div>
    </button>
    {/* <button type="button" id={props.id} onClick={props.onClick && props.onClick} class={`btn btn-${props.type ? props.type : "secondary"}  ml-2 rounded-full  align-items-center px-3 py-3`}>
      <div class="d-flex">
        <img src={props.type == "primary" ? PayNowIconSecondary : PayNowIconPrimary} class=" mr-2" alt="..." width="16"></img>
        <h6 class="mb-0 text-left"><span class="text-sm">{props.smallLabel ? props.smallLabel : "Pay Me"}  </span>{props.label ? props.label : "Now"} </h6>
      </div>
    </button>
   <Link to={props.route}>
      <button
        type="button"
        id={props.id}
        disabled={props.disabled}
        className={
          props.style ? props.style : "btn btn-primary btn-sm "
        }
        onClick={props.onClick && props.onClick}
      >
        {props.faIcon && (<i className={props.faIcon}></i>)}{props.label && (<>{props.label}</>)}
      </button>
  </Link>*/}
  </React.Fragment >
);


const CloseButton = (props) => (

  <React.Fragment>
    <Link to={props.route}>
      <button
        type="button"
        id={props.id}
        className={
          props.style ? props.style : "btn btn-light"
        }
        onClick={props.onClick && props.onClick}
      >
        {props.label ? props.label : "Close"}
      </button>
    </Link>
  </React.Fragment>

);
const EditButton = (props) => (
  <React.Fragment>
    <i
      alt="Edit"
      data-toggle="tooltip"
      data-placement="left"
      title="Edit"
      className={props.className ? props.className : "fa fa-edit"}
      onClick={props.onClick}

    ></i>
  </React.Fragment>
);

const RightEditButton = (props) => (
  <React.Fragment>
    <i
      alt="Edit"
      data-toggle="tooltip"
      data-placement="left"
      title={props.title ? props.title : "Edit"}
      className="fa fa-edit "
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);

const ViewButton = (props) => (
  <React.Fragment>
    <i
      alt="View"
      data-toggle="tooltip"
      data-placement="top"
      title="View"
      className="fa fa-eye"
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);

const RightViewButton = (props) => (
  <React.Fragment>
    <i
      alt="Edit"
      data-toggle="tooltip"
      data-placement="left"
      title="View"
      className="fa fa-eye  "
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);

const DeleteButton = (props) => (
  <React.Fragment>
    <i
      alt="Delete"
      data-toggle="tooltip"
      data-placement="top"
      title="Delete"
      className={props.className ? props.className : "fa fa-trash"}
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);
const CancelButton = (props) => (
  <React.Fragment>
    <i
      alt="Cancel"
      data-toggle="tooltip"
      data-placement="top"
      title="Cancel"
      className={props.className ? props.className : "fa fa-times"}
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);
const PasswordReset = (props) => (
  <React.Fragment>
    <i
      alt="Reset Password"
      data-toggle="tooltip"
      data-placement="top"
      title="Reset Password"
      className="fa  fa-key"
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);
const RightDeleteButton = (props) => (
  <React.Fragment>
    <i
      alt="Delete"
      data-toggle="tooltip"
      data-placement="top"
      title="Delete"
      className="fa fa-trash"
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);
const GeneralButton = (props) => (
  <React.Fragment>
    <button
      className={
        props.className ? props.className : "btn btn-primary btn-sm float-right"
      }
      type={props.type ? props.type : "submit"}
      style={props.style ? props.style : {}}
      disabled={props.disabled}
      title={props.title ? props.title : ""}
      onClick={props.onClick}
    >
      {props.name}
      {props.icon && (<><i className={props.icon}></i> </>)}
    </button>
  </React.Fragment>
);

const RightGeneralButton = (props) => (
  <React.Fragment>
    <button
      className={
        props.className ? props.className : "btn btn-primary btn-sm float-right"
      }
      type={props.type ? props.type : "submit"}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.name}&nbsp;{" "}
      {props.icon ? <i className={props.icon}></i> : ""}
    </button>
  </React.Fragment>
);

const ModalAddButton = (props) => (
  <React.Fragment>
    <button
      className={
        props.className
          ? props.className
          : "btn  btn-primary btn-sm float-right "
      }
      type={props.type ? props.type : "button"}
      onClick={props.onclick}
    >


      {props.label ? props.label : ""}

    </button>
  </React.Fragment>
);
const GeneralOutlineButton = (props) => (
  <React.Fragment>
    <button
      className={
        props.className
          ? props.className
          : "btn btn-xs btn-outline btn-primary pull-right "
      }
      type={props.type ? props.type : "submit"}
      onClick={props.onClick}
    >
      {props.icon && (<i className={props.icon ? props.icon : "fa fa-plus-circle"}></i>)}{props.label ? props.label : ""}
    </button>
  </React.Fragment>
);

const SaveButton = ({ label, className, disabled }) => (
  <React.Fragment>
    <button className={className ? className : "btn   btn-primary  "} disabled={disabled} type="submit">
      {label}
    </button>
  </React.Fragment>
);
const AcceptButton = (props) => (
  <React.Fragment>
    <i
      alt="View"
      data-toggle="tooltip"
      data-placement="top"
      title={props.title ? props.title : "View"}
      className="fa fa-check-circle-o"
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);
const HistoryButton = (props) => (
  <React.Fragment>
    <i
      alt="Log"
      data-toggle="tooltip"
      data-placement="top"
      title={props.title ? props.title : "View"}
      className={props.className ? props.className : "fa fa-history"}
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);
//-----------------------------Modal Close Button-------------------------------//
const ModalCloseButton = (props) => (
  <React.Fragment>
    <button
      className={props.className ? props.className : "btn btn-white "}
      type="button"
      onClick={props.onClick}
      data-dismiss="modal"
      disabled={props.disabled}
    >
      {props.label ? props.label : "Close"}
    </button>
  </React.Fragment>
);

export {
  AddButton,
  FpButton,
  EditButton,
  RightEditButton,
  ViewButton,
  RightViewButton,
  DeleteButton,
  RightDeleteButton,
  SubmitButton,
  GeneralButton,
  RightGeneralButton,
  ModalAddButton,
  GeneralOutlineButton,
  SaveButton,
  PasswordReset,
  AcceptButton,
  HistoryButton,
  ModalCloseButton,
  CloseButton,
  CancelButton
};
