import React, { useEffect, useState, useContext } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import entityService from "../../services/entityService";
import EntityContext from "../../context/entityContext";
import useApi from '../../hooks/useApi';
import { primaryRoles, secondaryRoles } from '../../config/predefinedValues.json'
import ArraySort from "../../utils/ArraySort";

function ProductsList({ onSelectItem, guid = null, col = 6, defaultProduct = null, label, type = "primary" }) {

    const contextValue = useContext(EntityContext);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [roles, setRoles] = useState([]);
    const [products, setProducts] = useState([]);
    const productsApi = useApi(entityService.entityProducts);

    const handleRoleChange = (event, roleIndex, productSelected = "", originalIndex) => {

        let selectedItem = productSelected ? productSelected : selectedProduct;
        const items = [...products];
        const productIndex = items.findIndex(obj => obj.id == selectedItem);
        items[productIndex]['roles'][originalIndex]['isChecked'] = event.target.checked;

        if (event.target.checked === true)
            items[productIndex]['isChecked'] = true;
        else if (items[productIndex]['roles'].findIndex(obj => obj.isChecked === true) === -1)
            items[productIndex]['isChecked'] = false;
        setProducts(items);
        onSelectItem(items);
    };

    const { request, loading } = useApi(entityService.getEntityCategories); // Custom hook for HTTP Requests

    const fetchData = async (guid) => {
        const { data } = await request(guid);
        setProducts(data.products);
    }

    const fetchProducts = async () => {
        const { data } = await productsApi.request({ entity: guid, contact: "", isPrimaryOnly : false });
        setProducts(data.result);
        if (defaultProduct !== null) {
            setSelectedProduct(defaultProduct);
            //const items = [...contextValue.entityDetails.products];
            setRoles(data.result.find(obj => obj.id === defaultProduct).roles);
        }

    }

    useEffect(() => {
        if (guid === null)
            fetchData(guid);
        else {
            if (contextValue.entityDetails !== null && typeof contextValue.entityDetails !== "undefined") {
                if(type == 'secondary')
                  fetchProducts();
                else{  
                    const items = [...contextValue.entityDetails.products];
                    setProducts(items);
                    if (defaultProduct !== null) {
                        setSelectedProduct(defaultProduct);
                        const items = [...contextValue.entityDetails.products];
                        setRoles(items.find(obj => obj.id === defaultProduct).roles);
                    }
                }    
            }
        }


    }, [guid, contextValue]);

    const roleFilter = (obj, index) => {
        const roles = (type == 'primary') ? primaryRoles : (type == 'secondary' ? secondaryRoles : [...primaryRoles, ...secondaryRoles]);
        if (roles.includes(obj.role_name)) {
            obj.originalIndex = index;
            return obj;
        }
    }


    return (
        <>
            {loading == true && <LoadingSpinner />}
            <div className="   " >
                <h6>{label ? label : "Select Relevant Facilities"}</h6>
            </div>

            {defaultProduct == null && (
                <div className="">
                    {products.map((item, index) => {
                        return (
                            item['roles'].filter(roleFilter).map((itemRole, roleIndex) =>

                                <div className=" mt-3  " key={index}>

                                    <div class="custom-control d-flex custom-checkbox">
                                        <input
                                            id={`customCheck${index}`}
                                            type="checkbox"
                                            className="custom-control-input"
                                            name={itemRole.role_name}
                                            onChange={e => handleRoleChange(e, roleIndex, item.id, itemRole.originalIndex)}
                                            checked={itemRole.isChecked ? true : false}
                                            disabled={itemRole.isDisabled ? itemRole.isDisabled : false}
                                        />
                                        <label class="custom-control-label" for={`customCheck${index}`}>
                                            {item.product_name}
                                        </label>

                                    </div>

                                </div>
                            )
                        )
                    })}
                </div >
            )}
        </>
    )
};
export default ProductsList;
