import React, { useState, useEffect, useContext } from "react";
import { resetBusinessStore } from '../../../../redux/financeBusiness/finance.actions'
import EntityContext from "../../../../context/entityContext";
import { browserHistory } from "react-router";
import { useDispatch } from 'react-redux'
import { Link } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import Page1 from "./page1";
import Page2 from "./page2";
import Page3 from "./page3";
import Page4 from "./page4";
import Page8 from "./page8";
import Page15 from "./page15";
import Page16 from './page16'
import Page17 from './page17'
import Page18 from './page18'
// import { useSelector } from 'react-redux'
export default function FinanceBusinessLayout() {

    const {
        handleRoleChange,
        handleProductChange
    } = useContext(EntityContext);

    const dispatch = useDispatch()
    useEffect(() => {
        handleRoleChange(null);
        handleProductChange(null);
        // effect
        return () => {
            dispatch(resetBusinessStore())
        }
    }, [])
    const goNext = (page, selectedType = null) => {
        //setSelectedTypes(selectedType)
        // debugger
        setCurrentPage(page)
        if (page === 'cashFlowQestions' || page === 'workingCapitalQuestions' || page === 'finalQuestions') {
            setCurrentSection(page)
        }
    };
    const goPrevious = (page) => {
        setCurrentPage(page)
    };
    const goToDashboard = () => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Are you sure</h4>
                        <p>You wish to quit?</p>
                        <button
                            className="btn btn-white"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>

                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => { 
                                dispatch(resetBusinessStore());
                                browserHistory.push('/client/home'); 
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div >
                );
            },
        });
    };
    //  const selectedTypeRedux = useSelector(({ financeReducer }) => financeReducer.selectedtype)
    // const [selectedTypes, setSelectedTypes] = useState()
    const [currentSection, setCurrentSection] = useState(null)
    const [currentPage, setCurrentPage] = useState('start')
    const RenderQuestions = ({ page }) => {
        //  debugger
        switch (true) {
            case (page === 'start'):
                return <Page1 goNext={goNext} goPrevious={goPrevious} />;
            case (page === 'sure'):
                return <Page2 goNext={goNext} goPrevious={goPrevious} />;
            case (page === 'cashFlowChoose'):
                return <Page3 goNext={goNext} goPrevious={goPrevious} />;
            case (page === 'cashFlowQestions' || page === 'workingCapitalQuestions' || page === 'finalQuestions'):
                return <Page4 goNext={goNext} goPrevious={goPrevious} questionSection={currentSection} />;
            case (page === 'workingCapitalChoose'):
                return <Page8 goNext={goNext} goPrevious={goPrevious} />;
            case (page === 'finished'):
                return <Page15 />;
            case (page === 'chooseBoth'):
                return <Page16 goNext={goNext} goPrevious={goPrevious} />;
            case (page === 'multiCard'):
                return <Page17 goNext={goNext} />;
            case (page === 'multiCard2'):
                return <Page18 goNext={goNext} />;

            default:
                return 'comming soon !!!!!!!';
        }
    }
    return (
        <>
            <Helmet>
                <title>Finance My Business | {global.config.title}</title>
            </Helmet>
            <div id="animation_box" className="animated fadeIn">
                <div id="animation_box" className="animated fadeIn">
                    <div className="card">
                        <div className="row">
                            <div className="col-lg-12">
                                <Link className="btn btn-light pull-right text-secondary mr-2 mt-2" onClick={() => goToDashboard()}><i alt=" Dashboard" data-toggle="tooltip" data-placement="top" title="Close" className="fa fa-close"></i></Link>
                            </div>
                        </div>
                        <RenderQuestions page={currentPage} />
                    </div>
                </div>
            </div>
        </>
    )
}