import React, { useContext } from "react";
import { Link } from "react-router";
import EntityContext from "../../../../context/entityContext";
import terminologyStart from "../../../../img/icons/terminology-start.svg"
import comingSoon from "../../../../img/icons/coming-soon.svg"

function GeneralSettings() {

    const { terminology } = useContext(EntityContext);

    return (
        <>

            <div className="col-md-6">
                <div className="card">
                    <div className="card-header">


                        <div className="row">
                            <div className="col-lg-6"><h6>General</h6></div>
                            <div className="col-lg-6">
                                <Link className="btn btn-light pull-right text-secondary ml-2" to={{ pathname: "/client/home" }}><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="forum-item active">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link className="forum-item-title" to={{ pathname: "/client/settings/organization-details" }} >
                                        Organisation Details
                                    </Link>
                                    <div className="forum-sub-title">Change, update or modify your address, logo, and business information settings.</div>
                                </div>
                            </div>
                        </div>
                        <div className="forum-item active">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link className="forum-item-title" to={{ pathname: "/client/team-members" }} >
                                        Team Members
                                    </Link>
                                    <div className="forum-sub-title">Add, remove or modify your system users</div>
                                </div>
                            </div>
                        </div>
                        <div className="forum-item active">
                            <div className="row">
                                <div className="col-md-12">
                                    {terminology.exists ?
                                        <Link className="forum-item-title" to={{ pathname: "/client/client-terminologies" }} >
                                            User Terminologies
                                        </Link> :
                                        <Link className="forum-item-title" to={{
                                            pathname: "/client/general/get-started",
                                            state: {
                                                img: terminologyStart,
                                                header: 'User Terminologies',
                                                content: 'Change the terminology used to match that of your company, for instance if you think of purchase invoices as bills, then change it, if you think customers as clients or debtors, then change it',
                                                primaryBtn: { btnLabel: "Let's Get Started", path: '/client/client-terminologies/get-started' },
                                                //secondaryBtn: { btnLabel: 'Learn More', path: '#' }
                                            }
                                        }}>
                                            User Terminologies
                                        </Link>
                                    }
                                    <div className="forum-sub-title">Add, remove or customise your user terminologies</div>
                                </div>
                            </div>
                        </div>
                        <div className="forum-item active">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link className="forum-item-title" to={{ pathname: "/client/accounting-software" }} >
                                        Connected Accounts Software
                                    </Link>
                                    <div className="forum-sub-title">Add and manage your accounting software connection to {global.config.title}</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="forum-item active">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link className="forum-item-title" to={{ pathname: "/client/bank-details" }} >
                                        Connected Bank Accounts
                                    </Link>
                                    <div className="forum-sub-title">Add and manage your bank account connections to {global.config.title}</div>
                                </div>
                            </div>
                        </div> */}
                        <div className="forum-item active">
                            <div className="row">
                                <div className="col-md-12">

                                    <Link className="forum-item-title" to={{
                                        pathname: "/client/general/get-started",
                                        state: {
                                            img: comingSoon,
                                            header: 'Coming Soon!',
                                            content: 'We are currently working on this.',
                                            content2: 'We will let you know once it is ready.',
                                            //primaryBtn: { btnLabel: "Let's Get Started", path: '/client/client-terminologies/get-started' },
                                            //secondaryBtn: { btnLabel: 'Learn More', path: '#' }
                                        }
                                    }}>
                                        Subscription and Billing
                                    </Link>

                                    <div className="forum-sub-title">Change plan and update credit card details </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default GeneralSettings;