import React from "react";
import { Link } from "react-router";

class BreadCrumbs extends React.Component {
  render() {
    const {
      Header,
      activeModule,
      Label1,
      Route1,
      Label2,
      Route2,
      Label3,
      Route3
    } = this.props;
    return (
      <React.Fragment>

        {Header && (
          <div className="row mb-2 mb-sm-3">
            <div className="col-sm-auto ">
              <h5 className="my-0">{Header}</h5>
            </div>
            <div className="col-sm-auto ml-sm-auto">
              <ol className="breadcrumb">
                {Label1 && (
                  <li className="breadcrumb-item"><Link to={Route1}>{Label1}</Link></li>
                )}
                {Label2 && (
                  <li className="breadcrumb-item"><Link to={Route2}>{Label2}</Link></li>
                )}
                {Label3 && (
                  <li className="breadcrumb-item"><Link to={Route3}>{Label3}</Link></li>
                )}
                {activeModule && (
                  <li className="breadcrumb-item active">
                    <strong>{activeModule}</strong>
                  </li>
                )}
              </ol>
            </div>
          </div>

        )}
      </React.Fragment>
    );
  }
}

export default BreadCrumbs;
