import React from "react";
import { Link } from "react-router";
import "./css/card.css";
import { FormColumnMd } from "../../../common/containers/formContainer";
import CardSkelton from "./_cardSkelton";

function PrimaryTypeOne({ col, loading, data }) {

    return (
        <>
            {loading === true ? <CardSkelton /> : (
                <FormColumnMd col={col ? col : 4}>

                    <div className="card">
                        <div className="card-body"><span className="b-corner b-corner-r b-corner-success" />
                            <div className="d-flex justify-content-between">
                                <h6 className="card-title  ">{data ? data.topLabel : ""}</h6>
                                <div className="ml-auto"><a href={ (data && data.primaryBtnRoute) ? data.primaryBtnRoute : "/client/settings"} />
                                    <div className="btn-group ml-5 mb-0">
                                        <a href= { (data && data.primaryBtnRoute) ? data.primaryBtnRoute : "/client/settings"} ></a>
                                        <a className="btn-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#"><i className="fa fa-ellipsis-v opacity-25" /></a>
                                        <div className="dropdown-menu dropdown-menu-right shadow">
                                            <Link className="dropdown-item" to={ (data && data.btnRoute ) ? data.btnRoute : "/client/scf/purchase-invoices" } ><i className="fa fa-eye mr-2" />View all</Link>
                                            <Link className="dropdown-item" to={ (data && data.secondaryBtnRoute ) ? data.secondaryBtnRoute : "/client/scf/bulk-payment" } ><i className="fa fa-file-text-o mr-2" />Settle Invoices</Link>
                                            <Link className="dropdown-item" to={ (data && data.tertiaryBtnRoute ) ? data.tertiaryBtnRoute : "/client/scf/direct-debit" } ><i className="fa fa-money mr-2" />Direct Debit</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mb-0">
                                <div>
                                    <Link className="text-secondary" to={{ pathname: data ? data.btnRoute : '', state: data ? data.status : "" }} >
                                       <h4 className="mb-1 font-weight-bold">{data ? data.topValue : ""}</h4>
                                    </Link>
                                    <p className="mb-2 text-xs text-muted"><span className="opacity-75">
                                        Current Availability</span></p>
                                </div>
                                <div className="card-chart bg-warning-transparent brround ml-auto mt-0">
                                    <i className="typcn typcn-chart-line-outline text-warning tx-24" />
                                </div>
                            </div>
                            <div>
                                <div className="progress progress-sm h-5 mt-2 mb-3">
                                    <div className="progress-bar bg-success w-100 " role="progressbar" />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12">
                                    <h6 className="  p-0"><span>{data ? data.centerValueHeading : " "}{data ? (data.centerValue ? data.centerValue : "") : ""}</span><small className="text-muted text-xs ml-1"></small> </h6>
                                    <p className="card-title mb-0 opacity-50">Working Capital</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormColumnMd>
            )}
        </>
    );
}

export default React.memo(PrimaryTypeOne);