import React, { useEffect } from 'react';
import { FormRow, FormColumnMd } from '../../../../common/containers/formContainer';
import useForm from '../../../../../hooks/useForm';
import { SaveButton } from "../../../../common/containers/button";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import useApi from '../../../../../hooks/useApi';
import CommonService from "../../../../supply-chain/services/commonService";
import LoadingSpinner from '../../../../common/loaders/loadingSpinner';
import auth from "../../../../../services/authService";

function MapEntity({ contacts, entity_id, entityDetails, role, source, onCloseModal, selected_contact, product }) {
    //------------------Validation Schema-----------------------------
    const schema = Joi.object() // Form validation schema object
    const mapClientApi = useApi(CommonService.mapClient);
    const { constants } = auth.getCurrentUser();

    const handleSelectContact = (event, contact_id) => {
        setValues({ ...values, contact_id: contact_id });
    }
    //------------------Map Client-----------------------------
    const saveMapping = async () => {
        let reqValues = { ...values }

        if (!reqValues.contact_id) {
            toast.error('Contact required.');
            return;
        }
        let reqArray = [];
        let productID = product;
        let roleID = product == constants.PRODUCTS.TRADEFINANCE ? constants.ROLES_IDS.TFSUPPLIER : constants.ROLES_IDS.SUPPLIER;
        let roleName = product == constants.PRODUCTS.TRADEFINANCE ? constants.ROLES.TFSUPPLIER : constants.ROLES.SUPPLIER;
        reqArray.push({
            contact_id: reqValues.contact_id,
            from_contact: reqValues.from_contact,
            product: productID,
            role: roleID,
            role_name: roleName,
            isChecked: true
        })
        reqValues.products = reqArray;
        const { data: res } = await mapClientApi.request(reqValues);

        if (res.success === true) {
            toast.success(res.message);
            setTimeout(() => {
                onCloseModal();
            }, 1500);
        }
        else
            toast.error(res.message);
    }

    const { values, setValues, handleSubmit } = useForm(saveMapping, schema);
    useEffect(() => {
        const contact_id = values.contact_id ? values.contact_id : selected_contact;
        setValues({
            ...values,
            current_entity_id: entity_id,
            entity_id: entityDetails.a_guid,
            from_contact: entityDetails.bpPrimaryContact,
            bp: entityDetails.owner,
            role: role,
            source: source,
            contact_id: contact_id
        });
    }, []);

    return (
        <div>
            {(mapClientApi.loading === true) && <LoadingSpinner />}
            <FormRow className="row m-t-sm">
                <FormColumnMd col={12}>
                    <div className="text-center">
                        <div className="alert alert-info">{`Entity already exists in our database.
                        Please find the details below`}</div>
                    </div>
                </FormColumnMd>
                <FormColumnMd col={12}>
                    <h6 className="m-t-sm m-b-md text-center">Choose a Contact</h6>
                </FormColumnMd>
                <FormColumnMd col={12}>
                    <form onSubmit={handleSubmit} className="form-horizontal">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Email</th>


                                </tr>
                            </thead>
                            <tbody>
                                {contacts.map((obj, index) => {
                                    return (
                                        <tr className={obj.is_primary === "yes" ? "fp-info-text" : ""} key={index}>
                                            <td>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input onChange={(e) => handleSelectContact(e, obj.contact_id)}
                                                        type="radio"
                                                        id={`customRadioInline${obj.contact_id}`}
                                                        name="contact" className="custom-control-input" />
                                                    <label className="custom-control-label" htmlFor={`customRadioInline${obj.contact_id}`} />
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    obj.email.includes('undisclosed') ? (
                                                        <>
                                                            {obj.organization}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {obj.first_name} {obj.last_name}
                                                        </>
                                                    )
                                                }

                                            </td>
                                            <td>
                                                {
                                                    obj.email.includes('undisclosed') ? (
                                                        <>
                                                            {`***`}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {obj.email}
                                                        </>
                                                    )
                                                }
                                            </td>


                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan="3">
                                        <div className="row pull-right">
                                            <SaveButton disabled={mapClientApi.loading} className="btn btn-primary mr-1" label="Submit" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </form>

                </FormColumnMd>
            </FormRow>
        </div>
    );
}

export default MapEntity;