import React, { useEffect, useState, useRef } from "react";
import { Picker } from '../common/inputs';
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { FormGroup } from "../common/containers/formContainer";

import authService from "../../services/authService";
import coreService from "../../services/coreService";
import useApi from '../../hooks/useApi';

function CorporateBPList({ onSelectItem, selectAll = true, selectedBp = null, isDisabled=false, type = null, country = null, label = null, defaultValue = { label: "Business Partner", value: null } , isRequired = null }) {

    const { category, country_id, entity_id } = authService.getCurrentUser();
    const [selectedItem, setSelectedItem] = useState(defaultValue);
    const { request, data, loading } = useApi(coreService.getCorporateEntitiesList); // Custom hook for HTTP Requests

    const prevCountry = useRef();

    const RenderPicker = () =>
        <Picker
            items={ selectAll === false ? data.filter(obj => obj.value !== null) : data }
            selectedItem={selectedItem}
            onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
            loading={loading}
            isDisabled={isDisabled}
        />

    useEffect(() => {
        
        if (category === "Business Partner") {
            onSelectItem({ value: entity_id });
          } else if (country !== prevCountry.current) {
            prevCountry.current = country;
            const payload = {
              country_id: country === null && category !== "International Office" ? country_id : country,
              business_type: 'Corporate',
            };
            request(payload);
          }

        if (selectedBp !== null && data.length > 0) {
            const bpDetails = data.find(obj => obj.value == selectedBp);
            if (bpDetails)
                setSelectedItem({ label: bpDetails.label, value: bpDetails.value });
        } else if (selectedBp === null)
            setSelectedItem(defaultValue);

    }, [country, selectedBp, data]);

    return (
        <>
            {category != "Business Partner" && (
                <>
                    {loading == true && <LoadingSpinner />}
                    {(label !== null && type !== "vertical") ?
                        <FormGroup
                            type={type}
                            label={label}
                            input={<RenderPicker />}
                        /> :
                        (label !== null && type === "vertical") ?
                            <div className={ isRequired !== null && isRequired == "true" ? "form-group required" : "form-group"}>
                                <label className={ isRequired !== null && isRequired == "true" ? "control-label" : ""}>{label}</label>
                                <RenderPicker />
                            </div>
                            :
                            <RenderPicker />
                    }
                </>
            )}
        </>
    )
};
export default CorporateBPList;