import React from "react";
import "./css/card.css";
import "./css/skelton.css";
import { FormColumnMd } from "../../../common/containers/formContainer";
function PrimaryCard({ col,top }) {

    return (
        <>
            <FormColumnMd col={col ? col : 4} class={top ? "card-top-margin" : ""}>
                    <div className="card">
                        <div className="card__title loading"></div>
                        <div className="card__description loading"></div>
                        <div className="card__image loading"></div>
                    </div>
            </FormColumnMd>

        </>
    );
}

export default React.memo(PrimaryCard);