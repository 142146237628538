import React, { useEffect, useContext, useState } from "react";
// import { DangerTypeTwoCard } from "../../components/cards";
import { Rejected } from "../../widgets/SCWidgets";
import { CurrencyLabel } from "../../../common/inputs";

import useApi from '../../../../hooks/useApi';
import useIsMounted from "../../../../hooks/useIsMounted";
import EntityContext from "../../../../context/entityContext";
import widgetService from "../../../../services/invTransactions";
import productShortName from "../../../../utils/GetProductShortName";

function DueMonth() {

    const isMounted = useIsMounted();
    const { selectedBusinessType, selProduct, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);

    //------------Api Service--------------------------------------------
    const paymentsApi = useApi(widgetService.getDueThisMonth);

    const fetchData = async () => {
        const response = await paymentsApi.request({ entity_id: entityDetails.a_guid, selectedBusinessType });
        if(response && isMounted()){
            setData({
                topLabel: "Due This Month",
                topValue: <CurrencyLabel decimal={2} value={response.data.due_amt} />,
                leftLabel: response.data.isPrimary ? "Debtors" : "Clients",
                leftValue: response.data.isPrimary ? response.data.debtor_count : response.data.client_count,
                rightLabel: "Invoices",
                rightValue: response.data.invoice_count,
                bottomRightLabel: "Invoices due this month",
                primaryBtnRoute: `/client/${productShortName(selProduct)}/sale-invoices?status=open&type=due`,
                //subTitle: "Please settle these invoices"
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>
            {/* <DangerTypeTwoCard
                loading={paymentsApi.loading}
                data={data}
                col={12}
                qrystring={true}
            /> */}
            <Rejected
                type="sm"
                data={data}
                top={true}
                loading={paymentsApi.loading}
                qrystring={true}
            />
        </>
    )
}
export default DueMonth;