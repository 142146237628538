import React, { useEffect, useState, useContext } from "react";
import useApi from "../../../../hooks/useApi";
import TransactionService from "../../../../services/invTransactions";
import EntityContext from "../../../../context/entityContext";

import CardSkelton from "../../components/cards/_cardSkelton";
import auth from "../../../../../src/services/authService";
import DoughnutCard from "../general/doughnutCard";

function IFPurchaseCommon({ nickName, feciltyCount, menu = null, onClick = null, to = "/client/home" }) {

  const { constants } = auth.getCurrentUser();
  const { entityDetails, accountDetails } = useContext(EntityContext);
  const cardInfoApi = useApi(TransactionService.facilityCardInfo);

  const [limit, setLimit] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [bankDetail, setBankDetail] = useState(null);
  const [facilityDefaults, setFacilityDefaults] = useState(false);

  const fetchCardInfo = async () => {
    const response = await cardInfoApi.request({ entity_id: entityDetails.a_guid, selectedBusinessType: constants.BUSINESS_TYPE.SALE });
    const graphValues = [
      { "name": "Approved", "value": response.data.approved, "icon": "fa-thumbs-up", "color": "secondary", "border": "rgb(25, 69, 107)", "bg": "rgb(25, 69, 107)" },
      { "name": "Open", "value": response.data.open, "icon": "fa-folder-open-o", "color": "warning", "border": "rgba(255, 200, 92, 1)", "bg": "rgba(255, 200, 92, 1)" },
      { "name": "Overdue", "value": response.data.overdue, "icon": "fa-clock-o", "color": "danger", "border": "rgba(237, 85, 101, 1)", "bg": "rgba(237, 85, 101, 1)" },
      { "name": "Processing", "value": response.data.pending, "icon": "fa-check-square-o", "color": "info", "border": "rgba(17, 105, 142, 1)", "bg": "rgba(17, 105, 142, 1)" }
    ];
    setGraphData(graphValues);
    setLimit(parseFloat(response.data.approved) + parseFloat(response.data.open) + parseFloat(response.data.pending));
  }

  //------------------------------------------------------------
  useEffect(() => {
    fetchCardInfo();
  }, []);

  //------------------------------------------------------------
  useEffect(() => {
    const accountInfo = accountDetails.find(item => item.product == constants.PRODUCTS.INVOICE);
    setBankDetail(accountInfo ? accountInfo : null);
  }, [accountDetails]);
  //------------------------------------------------------------
  useEffect(() => {
    const productSettings = entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.INVOICE);
    setFacilityDefaults(productSettings ? productSettings.default_settings_available : false);
  }, [entityDetails.productSettings]);

  return (
    <>
      <div className={`col-sm-6 col-lg-4 col-xl-4 align-center card-hover ${ (!bankDetail || !facilityDefaults) ? 'disabled-card' : ''}`}>
        {cardInfoApi.loading === true ? (
          <CardSkelton col="12" type="graph"></CardSkelton>
        ) : graphData && limit ? (
          <>
            <DoughnutCard
              graphData={graphData}
              type={"show_bank_detail"}
              fecilityLimit={limit}
              bgColor="primary"
              empty={false}
              account_type={1}
              feciltyCount={feciltyCount}
              description={"Your Single Invoice fecility"}
              menu={menu}
              onClick={onClick}
              to={to}
              bankDetail={bankDetail}
              nickName={nickName}
            ></DoughnutCard>
          </>
        ) : (
          <DoughnutCard
            graphData={[]}
            type={"show_bank_detail"}
            fecilityLimit={0}
            bgColor="primary"
            empty={true}
            account_type={1}
            feciltyCount={feciltyCount}
            description={"Your Single Invoice fecility"}
            menu={menu}
            onClick={onClick}
            to={to}
            bankDetail={bankDetail}
            nickName={nickName}
          ></DoughnutCard>
        )}
      </div>
    </>
  );
}
export default IFPurchaseCommon;
