import React, { Component } from "react";
import { Checkbox } from "react-icheck";
import { TextInput, CurrencyText, ReactSelect } from "../../common/inputs";
import { feeTypes } from "../../../config/predefinedValues.json";
import config from "../../../services/configService";
import { InputGroup } from "../../common/containers/formContainer";
import Form from "../../common/Form";
import FeeSchedule from "./fee_schedule";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import auth from "../../../services/authService";
import Modal from "react-bootstrap/Modal";
import { CountriesList, BPList, CustomersList } from "../../slplusTools/index";
import Switch from "react-switch";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import ReactTooltip from 'react-tooltip';
import SharedIntroducers from "../../supply-chain/components/widgets/rates/rateDetails/_sharedIntroducers";

class CreateFee extends Form {
  constructor(props) {
    super(props);
    this.fee_name = React.createRef();
    this.fee_description = React.createRef();
    this.country = React.createRef();
    this.fee_type = React.createRef();
    this.fee_daily_percentage = React.createRef();
    this.lpf = React.createRef();
  }

  state = {
    data: {
      fee_guid: "",
      fee_name: "",
      fee_description: "",
      country: "",
      fee_type: "",
      fee_schedules: [],
      fee_daily_percentage: "",
      fixed_value: "",
      fixed_fee_type: 'amount',
      apply_grace_period: 0,
      grace_period_commence_from: 'invoice_due_date',
      grace_period_days: null,
      lpf: "",
      accumulation: "",
      fees_days_extension: "active",
      trans_id: null,
      parent_fee_guid: null
    },
    countryId: null,
    owner: null,
    entity_id: null,
    ajaxRequest: false,
    countries: [],
    showFeeSchedule: false,
    showCharges: false,
    applyDailyPercentage: false,
    accumulation: false,
    feeschedule: {
      fee_schedule_id: "",
      days_from: 1,
      days_to: "",
      daily_percentage: "",
      index: 0,
      removed: false,
    },
    selectedTab:"iffee",
    firstButton: true,
    secButton: false
  };

  schema = Joi.object()
    .keys({
      fee_name: Joi.string().min(3).max(150).required().label("Fee Name"),
      // country: Joi.string().min(1).max(50).required().label("Country"),
      fee_type: Joi.string().required().label("Fee Type"),
      lpf: Joi.number().label("LPF").allow(null, ""),
      fee_daily_percentage: Joi.number().label("Daily percentage fee").allow(null, ""),
    })
    .unknown(true);

  handleFeeTypeChange = (selectedOption) => {
    const data = { ...this.state.data };
    data["fee_type"] = selectedOption.value;
    this.setState({ data });
    const selectedFeeType = {
      label: selectedOption.label,
      value: selectedOption.value,
    };

    if (selectedOption.value == "fee")
      this.setState({ showFeeSchedule: true, showCharges: false });
    else this.setState({ showFeeSchedule: false, showCharges: true });

    this.setState({ selectedFeeType });
  };

  handleFixedFeeTypeChange = (selectedOption) => {
    const data = { ...this.state.data };
    data["fixed_fee_type"] = selectedOption.value;
    this.setState({ data });
  };

  handleCommenceFromChange = (selectedOption) => {
    const data = { ...this.state.data };
    data["grace_period_commence_from"] = selectedOption.value;
    this.setState({ data });
  };

  handleFlagChange = (selectedOption) => {
    const data = { ...this.state.data };
    const country = this.state.countries.find(
      (country) => country.name === selectedOption.value
    );

    if (country.id && country) {
      data["country"] = country.id;
      const selectedCountry = {
        label: selectedOption.label,
        value: selectedOption.value,
      };
      this.setState({ data, selectedCountry });
    }
  };

  handleCheckboxChange = (event) => {
    event.target.name == "applyDailyPercentage"
      ? this.setState({ applyDailyPercentage: !event.target.checked })
      : this.setState({ accumulation: !event.target.checked });
    const data = { ...this.state.data };
    if (!event.target.checked == false) {
      if (event.target.name == "applyDailyPercentage")
        data["fee_daily_percentage"] = null;
      if (event.target.name == "accumulation") data["accumulation"] = 0;
    } else {
      if (event.target.name == "accumulation") data["accumulation"] = 1;
    }
    this.setState({ data });
  };

  updateSubsequentDayFrom = (last_day) => {
    const feeschedule = { ...this.state.feeschedule };
    feeschedule["fee_schedule_id"] = "";
    feeschedule["days_from"] = parseFloat(last_day) + 1;
    feeschedule["days_to"] = "";
    feeschedule["daily_percentage"] = "";
    feeschedule["index"] = 0;
    feeschedule["removed"] = false;
    this.setState({ feeschedule });
  };

  componentDidMount() {
    const {
      country_id,
      constants,
      category,
      entity_id,
    } = auth.getCurrentUser();

    if (constants.CATEGORY.BP === category)
      this.setState({ owner: entity_id });

    if (country_id && this.props.selectedFeeType.length === 0)
      this.setState({ countryId: country_id });

    this.fetchCountriesArray();

    const data = { ...this.state.data };
    data["fee_type"] = "fee";
    this.setState({
      data,
      selectedFeeType: {
        label: "Discount Fee",
        value: "fee",
      },
    });

    this.setState({ showFeeSchedule: true, showCharges: false });
  }

  fetchCountriesArray = async () => {
    try {
      this.setState({ ajaxRequest: true });
      const countries = await config.getCountriesArray();
      let last_days_to = 0;

      this.setState({ countries }, () => {
        const user = auth.getCurrentUser();

        const selectedCountry = {
          label: user.country_name,
          value: user.country_name,
        };

        const country = this.state.countries.find(
          (country) => country.name === user.country_name
        );
        const data = { ...this.state.data };
        data["country"] = country.id;
        this.setState({ selectedCountry, data });
        //this.refs.country.updateSelected(user.country_flag.toUpperCase());

        if (this.props.selectedFeeType.length != 0) {

          //** Populating data of selected fee type */
          const { selectedFeeType } = this.props;
          const data = { ...this.state.data };
          data["fee_guid"] = selectedFeeType.fee_guid;
          data["fee_name"] = selectedFeeType.fee_name;
          data["fee_description"] = selectedFeeType.fee_description;
          data["country"] = selectedFeeType.country["id"];
          data["fee_type"] = selectedFeeType.fee_type;
          data["fee_daily_percentage"] = selectedFeeType.fee_daily_percentage;
          data["fixed_value"] = selectedFeeType.fixed_value;
          data["fixed_fee_type"] = selectedFeeType.fixed_fee_type;
          data["apply_grace_period"] = parseInt(selectedFeeType.apply_grace_period);

          if(selectedFeeType.grace_period_commence_from)
            data["grace_period_commence_from"] = selectedFeeType.grace_period_commence_from;

          data["grace_period_days"] = selectedFeeType.grace_period_days;
          data["lpf"] = selectedFeeType.lpf;
          data["trans_id"] = selectedFeeType.trans_id;
          data["parent_fee_guid"] = selectedFeeType.parent_fee_guid;
          data["fees_days_extension"] = selectedFeeType.fees_days_extension;

          var feeScheduleArray = [];
          //** Adding 'index' field to the fee schedule array */
          selectedFeeType.feeSchedule.map((item, index) => {
            last_days_to = item.days_to;
            return feeScheduleArray.push({
              fee_schedule_id: item.id,
              days_from: item.days_from,
              days_to: item.days_to,
              daily_percentage: item.daily_percentage,
              index: index,
              removed: false,
            });
          });

          data["fee_schedules"] = feeScheduleArray;

          const selectedCountry = {
            label: selectedFeeType.country["name"],
            value: selectedFeeType.country["name"],
          };

          //** Populating dropdown list for selected fee type */
          let feeType = feeTypes.filter(
            (type) => type.value == selectedFeeType.fee_type
          );

          if (selectedFeeType.fee_type == "fee")
            this.setState({ showFeeSchedule: true, showCharges: false });
          else this.setState({ showFeeSchedule: false, showCharges: true });

          if (selectedFeeType.fee_daily_percentage != null)
            this.setState({ applyDailyPercentage: true });

          data["accumulation"] = selectedFeeType.accumulation;

          this.setState({
            selectedFeeType: {
              label: feeType[0].label,
              value: feeType[0].value,
            },
            data,
            selectedCountry,
            accumulation: selectedFeeType.accumulation == "1" ? true : false,
            owner: selectedFeeType.entity_id,
            countryId: selectedFeeType.country.id
          });

          this.updateSubsequentDayFrom(last_days_to);
        }
      });
      this.setState({ ajaxRequest: false });
    } catch (error) { }
  };

  //*** Updating state of data when input changed ***/
  handleFeeScheduleChange = ({ currentTarget: input }) => {
    const feeschedule = { ...this.state.feeschedule };
    feeschedule[input.name] = input.value;
    this.setState({ feeschedule });
  };

  // Handle add
  addHandler = () => {
    const data = { ...this.state.data };
    const { feeschedule } = this.state;

    if (parseFloat(feeschedule.days_to) < parseFloat(feeschedule.days_from))
      toast.error(`'Days To' field must be greater than ${feeschedule.days_from}`)
    else {
      feeschedule["index"] = this.state.data.fee_schedules.length;
      this.setState({ feeschedule });
      const fee_schedules = [...this.state.data.fee_schedules, feeschedule];
      data["fee_schedules"] = fee_schedules;
      this.updateSubsequentDayFrom(feeschedule["days_to"]);
      this.setState({ data });
    }
  };

  // Handle remove
  removeHandler = (row) => {
    const data = { ...this.state.data };
    row["removed"] = true;
    data["fee_schedules"][row.index] = row;
    this.setState({ data });
  };

  updateHandler = (updatedData) => {
    let data = { ...this.state.data };
    data["fee_schedules"][updatedData.index] = updatedData;
    this.setState({ data });
  };

  focusInput = (inputName) => { };

  saveFee = async () => {

    const {
      entity_id,
    } = auth.getCurrentUser();

    const { data } = this.state;

    // Do not allow to save if BP is not selected.
    if (!this.state.owner && !this.props.entity) {
      toast.error("Please select Business Partner from the list.");
      return;
    }

    // Do not allow to save data if apply grace period option is checked and grace period field is empty
    if(data.apply_grace_period === 1 && ( data.grace_period_days <= 0 || data.grace_period_days === null || data.grace_period_days === "")){
      toast.error("Please enter the grace period.");
      return;
    }
    
    data["entity_id"] = this.props.entity ? this.props.entity : this.state.owner ? this.state.owner : entity_id;
    data["grace_period_commence_from"] = data.apply_grace_period === 1 ? data.grace_period_commence_from : null;
    data["grace_period_days"] = data.apply_grace_period === 1 ? data.grace_period_days : null;

    this.setState({ ajaxRequest: true });
    const { success, message, feeGuid, feeName } = await config.createTransactionFee(data);
    this.setState({ ajaxRequest: false });

    if (success === false)
      toast.error(message);
    else {
      toast.success(message);
      if (this.props.reloadData)
        this.props.reloadData();
      if (this.props.handleAddNewFee)
        this.props.handleAddNewFee(feeGuid, feeName);
    }

  }

  doSubmit = async () => {
    if (
      (this.state.data.fee_schedules.findIndex(item => item.removed !== true) !== -1) ||
      this.state.data.fee_daily_percentage > 0 ||
      this.state.data.fixed_value > 0 ||
      this.state.data.lpf > 0
    )
      this.saveFee();
    else
      toast.error('Please fill any of the required fields to proceed.')
  };

  onCloseModal = () => {
    this.setState({ open: false, selectedFeeType: [] });
  };

  render() {

    const {
      showFeeSchedule,
      showCharges,
      applyDailyPercentage,
      data,
      feeschedule,
    } = this.state;


    const { category, constants } = auth.getCurrentUser();

    return (
      <form className="form-horizontal" onSubmit={this.handleSubmit}>
        {this.state.ajaxRequest && <LoadingSpinner />}
        <Modal.Header closeButton>
          {/*
          <Modal.Title id="example-custom-modal-styling-title">
            <div className="tabs-container">
              <ul className="nav nav-tabs" role="tablist">
                <li className={this.state.firstButton ? "active false  btn_link_accounts" : "false  btn_link_accounts"}>
                  <a
                    className="nav-link  rounded-xl "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({selectedTab:"iffee"});
                      this.setState({ firstButton: true})
                      this.setState({ secButton: false})
                    }}
                  >
                    &nbsp;&nbsp;Create/Update Fee{" "}
                  </a>
                </li>
                <li className={this.state.secButton ? "active false  btn_link_accounts" : "false  btn_link_accounts"}>
                  <a
                    className="nav-link  rounded-xl "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({selectedTab:"introducers"});
                      this.setState({ secButton: true})
                      this.setState({ firstButton: false})

                    }}
                    
                  > 
                    &nbsp;&nbsp;Introducers{" "}
                  </a>
                </li>
              </ul>
            </div>
          </Modal.Title>
          */}
          <h4 className="modal-title">Create/Update Fee</h4>
        </Modal.Header>
        {this.state.selectedTab === 'iffee'&& (
          <>
        <Modal.Body>
          <div>
            <TextInput
              name="fee_name"
              label="Fee Name"
              onChange={this.handleChange}
              type="vertical"
              value={data.fee_name}
              reference={this.fee_name}
            ></TextInput>

            {!this.props.entity && (
              <>
                {constants.CATEGORY.INTL_OFFICE === category && (
                  <InputGroup
                    label={
                      category == constants.CATEGORY.INTL_OFFICE
                        ? "Country"
                        : ""
                    }
                  >
                    <CountriesList
                      selectedCountry={this.state.countryId}
                      onSelectItem={({ id }) =>
                        this.setState({ countryId: id })
                      }
                    />
                  </InputGroup>
                )}
                {(constants.CATEGORY.INTL_OFFICE === category ||
                  constants.CATEGORY.COUNTRY_OFFICE === category) && this.state.countryId !== null && (
                    <>
                      <div className="form-group">
                        <label>
                          Business Partner
                        </label>

                        <div>
                          <BPList
                            selectedBp={this.state.owner}
                            type="horizontal"
                            country={this.state.countryId}
                            onSelectItem={({ value }) => {
                              this.setState({ owner: value });
                            }}
                            selectAll={false}
                          />
                        </div>
                      </div>


                    </>
                  )}
              </>
            )}
            {/**------------------- Fee Schedule --------------------*/}
            {showFeeSchedule && (
              <div className="mt-4">

                <div className="row">
                  <div class="col-lg-3 col-sm-3 col-xs-3">
                    <label className="text-secondary"  >Days From</label>
                  </div>
                  <div class="col-lg-3 col-sm-3 col-xs-3">
                    <label className="text-secondary" >Days To</label>
                  </div>
                  <div class="col-lg-3 col-sm-3 col-xs-3">
                    <label className="text-secondary" >Fee %</label>
                  </div>
                  <div class="col-lg-3 col-sm-3 col-xs-3">
                    <label className="text-secondary"  >Action</label>
                  </div>
                </div>
                {/** Embedding Fee Schedule Component */}
                {/*childs*/}

                {data.fee_schedules.length != 0 &&
                  data.fee_schedules.map((fee, index) => {
                    return (
                      <FeeSchedule
                        key={index}
                        index={index}
                        data={fee}
                        addHandler={this.addHandler}
                        removeHandler={this.removeHandler}
                        updateHandler={this.updateHandler}
                      />
                    );
                  })}



                <div className="row mt-2">
                  <div class="col-lg-3 col-sm-3 col-xs-3">
                    <input
                      name="days_from"
                      value={feeschedule.days_from}
                      onChange={this.handleFeeScheduleChange}
                      className="form-control input-sm"
                      type="text"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div class="col-lg-3 col-sm-3 col-xs-3">
                    <input
                      name="days_to"
                      value={feeschedule.days_to}
                      onChange={this.handleFeeScheduleChange}
                      className="form-control input-sm"
                      type="text"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div class="col-lg-3 col-sm-3 col-xs-3">
                    <input
                      name="daily_percentage"
                      value={feeschedule.daily_percentage}
                      onChange={this.handleFeeScheduleChange}
                      className="form-control input-sm"
                      type="number"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div class="col-lg-3 col-sm-3 col-xs-3">
                    <button
                      onClick={(e) => this.addHandler()}
                      class="btn btn-outline-secondary "
                      type="button"
                      title="Save Fee"
                      disabled={
                        !(
                          feeschedule.days_from > 0 &&
                          feeschedule.days_to > 0 &&
                          feeschedule.daily_percentage > 0
                        )
                      }
                    >
                      Add
                    </button>
                  </div>
                </div>
                {/** Embedding Fee Schedule Component */}
                {/*childs*/}

                <div className="form-group mt-2">
                  <label>

                    Apply a Daily Percentage Fee after the last fee step
                    &nbsp;&nbsp;
                    <Checkbox
                      checkboxClass="icheckbox_minimal-grey"
                      name="applyDailyPercentage"
                      increaseArea="20%"
                      onChange={this.handleCheckboxChange}
                      checked={this.state.applyDailyPercentage ? true : false}
                    />

                  </label>
                  {applyDailyPercentage && (



                    <div className="mt-2" >
                      <TextInput
                        name="fee_daily_percentage"
                        value={data.fee_daily_percentage}
                        placeholder="%"
                        label="Daily Percentage Fee"
                        type="normal"
                        onChange={this.handleChange}
                        /*number={true}*/
                      ></TextInput>
                    </div>


                  )}
                </div>

              </div>
            )}

            {/**------------------- Charges --------------------*/}
            {showCharges && (
              <div className="form-group mt-2">
                <label>Fixed Amount</label>
                <div >

                  <CurrencyText
                    placeholder="Amount"
                    name="fixed_value"
                    onChangeEvent={
                      (event, maskedvalue, floatvalue) => {
                        const data = { ...this.state.data };
                        data["fixed_value"] = floatvalue;
                        this.setState({ data });
                      }
                    }
                    value={data.fixed_value}
                    reference={this.fixed_value}
                  />

                  {/* <TextInput
                    name="fixed_value"
                    value={data.fixed_value}
                    placeholder="Amount"
                    label="Charges:"
                    onChange={this.handleChange}
                    type="normal"
                  ></TextInput> */}

                </div>
              </div>
            )}
            {/**---------------------------------------------------*/}

            <div className="row">
              <div className="col-lg-6">
                  <div className="form-group mt-2">
                    <label>
                      Fixed Fee Type
                    </label>
                    <div>
                      <ReactSelect
                        options={[{ label: 'Fixed Amount', value: 'amount' }, { label: 'Percentage', value: 'percentage' }]}
                        onChange={this.handleFixedFeeTypeChange}
                        value={data.fixed_fee_type === 'amount' ? { label: 'Fixed Amount', value: 'amount' } : { label: 'Percentage', value: 'percentage' } }
                        label="Fixed Fee Type"
                        placeholder="Select fixed fee type"
                        type="normal"
                      ></ReactSelect>
                    </div>
                  </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group mt-2">
                  <label>
                    Fixed Fee
                  </label>
                  <div>
                    <TextInput
                        name="fixed_value"
                        label="Fixed Fee"
                        onChange={this.handleChange}
                        value={data.fixed_value}
                        type="normal"
                        number={true}
                    ></TextInput>
                  </div>
                </div>
              </div>
            </div>

            {/* --------------------------- Grace Period --------------------------- */}
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mt-2">
                  <label>
                    Apply Grace Period
                  </label>
                  <div>
                    <Switch
                      onChange={checked => { 
                        const data = { ...this.state.data };
                        data["apply_grace_period"] = checked ? 1 : 0;
                        this.setState({ data });
                      }}
                      onColor="#16C79A"
                      checked={(data.apply_grace_period && parseInt(data.apply_grace_period)) ? true : false}
                    />
                  </div>
                </div>              
              </div>
            </div>

            {(data.apply_grace_period && parseInt(data.apply_grace_period)) ?
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mt-2">
                    <label>
                      Commence From
                    </label>
                    <div>
                      <ReactSelect
                        options={[{ label: 'Invoice Date', value: 'invoice_date' }, { label: 'Invoice Due Date', value: 'invoice_due_date' }]}
                        onChange={this.handleCommenceFromChange}
                        value={data.grace_period_commence_from === 'invoice_date' ? { label: 'Invoice Date', value: 'invoice_date' } : { label: 'Invoice Due Date', value: 'invoice_due_date' } }
                        label="Commence From"
                        placeholder="Commence From"
                        type="normal"
                      ></ReactSelect>
                    </div>
                  </div> 
                </div>

                <div className="col-lg-6">
                  <div className="form-group mt-2">
                    <label>
                      Grace Period
                    </label>
                    <div>
                      <TextInput
                        name="grace_period_days"
                        label="Grace Period"
                        onChange={this.handleChange}
                        value={data.grace_period_days}
                        type="normal"
                        number={true}
                      ></TextInput>
                    </div>
                  </div> 
                </div>
              </div>
            : ''}
            {/* -------------------------------------------------------------------- */}
    
            <div className="form-group mt-2">
              <label>
                Late Payment Fee %
              </label>

              <div  >
                <TextInput
                  name="lpf"
                  label="Late Payment Fee %"
                  onChange={this.handleChange}
                  value={data.lpf}
                  reference={this.lpf}
                  type="normal"
                  number={true}
                ></TextInput>
              </div>
            </div>

            <div className="form-group mt-4">
              <i data-tip="Accumulative adds each fee increment over the funding term." class="fa fa-info-circle"></i>&nbsp;
              Fee Calculation Accumulative &nbsp;&nbsp;
              <Checkbox
                checkboxClass="icheckbox_minimal-grey"
                name="accumulation"
                increaseArea="20%"
                onChange={this.handleCheckboxChange}
                checked={this.state.accumulation ? true : false}
              />
            </div>

            <div className="form-group mt-4">
              <i data-tip="Add an extra day to the extended payment days" class="fa fa-info-circle"></i>&nbsp;
              Fees days extension &nbsp;&nbsp;
              <Checkbox
                checkboxClass="icheckbox_minimal-grey"
                name="fees_days_extension"
                increaseArea="20%"
                onChange={e => {
                  const data = {...this.state.data};
                  data['fees_days_extension'] = data['fees_days_extension'] === "active" ? "inactive" : "active";
                  this.setState({ data });
                }}
                checked={this.state.data.fees_days_extension === "active"}
              />
            </div>


          </div>
          <ReactTooltip backgroundColor="#11698e" />

          {this.props.selectedFeeType.create_copy && (
            <div className="alert alert-info text-secondary">
              This will create a copy of the Fee.
            </div>
          )}

        </Modal.Body>

        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            Save
          </button>

          {/*<button
              type="button"
              className="btn btn-white"
              data-dismiss="modal"
              onClick={e => this.props.onClose()}
            >
              Close
            </button>*/}
        </Modal.Footer>
        </>
        )}
        {this.state.selectedTab === 'introducers'&& (
          <>
          
          <SharedIntroducers
            feeGuid={this.state.data.fee_guid}
            reloadSharedCount={'0'}
            product={constants.PRODUCTS.INVOICE}
            introType={"IF"}
          />
          </>
        )}
      </form>
    );
  }
}

export default CreateFee;
