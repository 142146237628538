import React from 'react';
import ReactTable from "react-table";
import Select from "react-select";
import Form from "../../../common/Form";
class ActionTable extends Form {
  state = {
    data: [],
    pages: 1,
    loading: false,
    tabs: [],
    selectedTab: "",
    columns: [],
    checkAll: false,
    noDataText: "",
    filter: {
      page: 1,
      pageSize: 1,
      sorted: [],
      filtered: []
    },
    selectedRows: [],
    selectedSearchTerm: { label: "Search", value: "" },
    reqParams: this.props.reqParams
  }
  componentDidUpdate(prevProps) {

    if (this.props.defaultTab != prevProps.defaultTab) {

      this.setState({ selectedTab: this.props.defaultTab }, () => this.reloadData());
    }
    if (this.props.reqParams != this.state.reqParams) {
      this.setState({ reqParams: this.props.reqParams });
      //this.reloadData()
    }
    if (this.props.cols != this.state.columns) {
      let column = {};
      if (this.props.onSelectRow) {
        column = this.props.cols;
        if (!this.props.hideCheckbox) {
          column = this.handleCheckboxMount(column);
        }

      } else column = this.props.cols;
      this.setState({ columns: column });
    }
  }
  componentDidMount() {

    //if (this.props.searchService) this.populateSearchList();

    if (this.props.onSelectRow) {
      let columns = [...this.props.cols];

      if (!this.props.hideCheckbox) {
        columns = this.handleCheckboxMount(columns);
      }
      this.setState({ columns });
    } else this.setState({ columns: this.props.cols });

    if (this.props.pivot) {
      this.setState({ pivot: [this.props.pivot] });
    }
  }

  handleCheckboxMount = (columns) => {
    columns.unshift({
      accessor: "rowIndex",
      minWidth: 40,
      headerStyle: { textAlign: "left" },
      sortable: false,


      Header: () => {
        return (
          <div className="custom-control d-flex custom-checkbox small">
            <input
              id="customCheck"
              type="checkbox"
              checked={this.state.checkAll}
              className="custom-control-input"
              onClick={(e) => {
                const selectedRows = [...this.state.selectedRows];
                this.state.data.map((item) => {
                  if (selectedRows.length > 0) {
                    var rowIndex = selectedRows.findIndex(
                      (obj) => obj.key == item[this.props.uid]
                    );
                    if (rowIndex != -1) {
                      if (e.target.checked == true)
                        selectedRows[rowIndex]["checked"] = true;
                      else
                        selectedRows[rowIndex]["checked"] = false;
                    }
                    else
                      selectedRows.push({
                        key: item[this.props.uid],
                        checked: e.target.checked,
                        ...item,
                      });
                  } else
                    selectedRows.push({
                      key: item[this.props.uid],
                      checked: e.target.checked,
                      ...item,
                    });
                });
                this.setState({ selectedRows, checkAll: !this.state.checkAll });
                this.props.onSelectRow && this.props.onSelectRow(selectedRows);
              }}
            />
            <label className="custom-control-label" htmlFor="customCheck"></label>
          </div>
        );
      },

      Cell: (row) => {
        let checked = false;
        let rowIndex = this.state.selectedRows.findIndex(
          (obj) => obj.key == row.original[this.props.uid]
        );

        if (rowIndex != -1)
          checked = this.state.selectedRows[rowIndex]["checked"];

        return !row.original.disabled ||
          (row.original.disabled && row.original.disabled === "0") ? (
          <div className="custom-control d-flex custom-checkbox small">
            <input
              id={row.original[this.props.uid]}
              type="checkbox"
              checked={checked}
              className="custom-control-input"
              onClick={(e) => this.handleCheckboxChange(e, row)}
            />
            <label
              className="custom-control-label"
              htmlFor={row.original[this.props.uid]}
            ></label>
          </div>
        ) : (
          ""
        );
      },
    });
    return columns;
  };

  //---------------Handle checkbox change-----------------//
  handleCheckboxChange = (e, row) => {

    const selectedRows = [...this.state.selectedRows];
    var rowIndex = selectedRows.findIndex(obj => obj.key == row.original[this.props.uid]);
    if (rowIndex != -1)
      selectedRows[rowIndex]['checked'] = e.target.checked;
    else
      selectedRows.push({ key: row.original[this.props.uid], checked: e.target.checked, ...row.original });

    this.setState({ selectedRows });
    this.props.onSelectRow && this.props.onSelectRow(selectedRows);

  }

  //---------------Method to Fetch data to the Table-----------------//
  fetchData = async params => {

    if (this.props.service) {
      this.setState({ data: [], loading: true, noDataText: "" });
      await this.props.service(params, this.state.selectedTab)
        .then(res => {
          this.setState({ data: res.data.rows, tabs: res.data.tabs, pages: res.data.pages, loading: false });
          this.props.setTabs && this.props.setTabs(res.data.tabs);
          if (this.props.headerSelect) {
            const result = res.data.tabs.find(item => item.value == this.props.headerSelect);
            this.setState({ tabOption: result })
          }

          if (this.props.onDataLoad)
            this.props.onDataLoad(res.data, params);

          if (res.data.rows.length === 0)
            this.setState({ noDataText: "No data found." })
        });
    }
  }
  //----------------------------------------------------------------//

  //---------------Method to Fetch data to the Table-----------------//
  populateSearchList = async searchTerm => {
    this.setState({ loading: true });
    await this.props.searchService({ searchTerm, selectedTab: this.state.selectedTab, ...this.props.reqParams })
      .then(res => this.setState({ searchList: res.data }));
    this.setState({ loading: false });
  }
  //----------------------------------------------------------------//

  searchKeyChange = async ({ label, value }) => {
    const filterData = { ...this.state.filter, ...this.props.reqParams }
    filterData["search"] = value;
    this.fetchData(filterData);
    this.setState({ selectedSearchTerm: { label, value } });
  }

  //---------------Handle search key down-----------------//
  searchKeyDown = async e => {
    if (e.keyCode == 46 || e.keyCode == 8) {
      const filterData = { ...this.state.filter }
      filterData["search"] = null;
      this.fetchData(filterData);
      this.setState({ selectedSearchTerm: { label: "Search", value: "" } });
    } //else
    //  this.populateSearchList(e.target.value);
  }

  //--------This method can be called from the Parent Component-----//
  reloadData = () => {
    this.fetchData({ ...this.state.filter, ...this.props.reqParams }); // Use this method If you want to reload the data forcefully from the parent component.
    if (this.props.searchService) {
      this.populateSearchList();
    }

    this.setState({ checkAll: false });
  }
  //----------------------------------------------------------------//

  //--------This method can be called from the Parent Component-----//
  getTableData = () => {
    return this.state.data;
  }
  //----------------------------------------------------------------//

  //--------This method can be called from the Parent Component-----//
  clearSelection = () => {
    this.setState({ selectedRows: [] });
  }
  //----------------------------------------------------------------//

  render() {

    const customStyles = {
      menu: provided => ({ ...provided, zIndex: 999 })
    };
    
    return (
      <>

        <>

          {/*-----------------------------------------------------------------------*/}

          <div className="row ">
            {/*-----------------------------Search List-----------------------------*/}
            {this.props.searchService && (
              <div className={`col-lg-4 col-sm-6`}>
                <div className="form-group">
                  <Select
                    options={this.state.searchList}
                    placeholder="Search here"
                    components={{ DropdownIndicator: () => null }}
                    onChange={this.searchKeyChange}
                    onKeyDown={this.searchKeyDown}
                    styles={customStyles}
                    value={this.state.selectedSearchTerm}
                    theme={theme => ({
                      ...theme,
                      borderRadius: '.375rem',
                      border: "1px solid #b0b9c1",
                      colors: {
                        ...theme.colors,
                        text: "black",
                        primary25: "white",
                        primary: "#1ab394",
                        neutral0: "white"
                      }
                    })}
                  />
                </div>
              </div>
            )}
            {/* {this.props.headerSelect && this.state.tabs.length > 0 && (
                                    <div className={`col-lg-${this.props.searchService ? 2 : 4}`}>
                                        <Select
                                            options={this.state.tabs}
                                            placeholder="Search here"
                                            components={{ DropdownIndicator: () => null }}
                                            onChange={(selectedOption) => {
                                                this.setState({ selectedTab: selectedOption.value, tabOption: { "label": selectedOption.label, "value": selectedOption.value } }, () => { this.reloadData(); if (this.state.searchService) { this.populateSearchList(); } this.props.onSelectTab && this.props.onSelectTab(selectedOption.value) })
                                            }}
                                          
                                            value={this.state.tabOption}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: '.375rem',
                                                border: "1px solid #b0b9c1",
                                                colors: {
                                                    ...theme.colors,
                                                    text: "black",
                                                    primary25: "white",
                                                    primary: "#1ab394",
                                                    neutral0: "white"
                                                }
                                            })}
                                        />
                                    </div>
                                )} */}
            {this.props.additionalFilter && (
              <div className="col-lg-4 col-sm-6 text-sm-right my-2 my-sm-0 pull-left">{this.props.additionalFilter}</div>
            )}
            <div className="col-lg-4 col-sm-12 text-sm-right my-2 my-sm-0">
              {this.props.tableRightPanel}
            </div>
            {/*-----------------------------------------------------------------------*/}
          </div>

          {this.props.tableLeftPanel && (

            this.props.tableLeftPanel

          )}



          <ReactTable
            data={this.state.data}
            minRows={this.state.data.length === 0 ? 3 : 1}
            pages={this.state.pages}
            columns={this.state.columns}
            loading={this.state.loading}
            pageSizeOptions={[5, 10, 20, 25, 50, 100, 250]}
            defaultPageSize={10}
            className="-striped -highlight mt-3"
            showPagination={true}
            showPaginationTop={false}
            manual
            noDataText={this.state.noDataText}
            onFetchData={async (state) => {

              //---------------Saving table default props to the component state----------------------//
              const { page, pageSize, sorted, filtered } = state;
              this.setState({ filter: { page, pageSize, sorted, filtered, ...this.props.reqParams } });
              //--------------------------------------------------------------------------------------//

              //--------------Set Tabs List----------------//
              if (this.props.tabs)
                this.setState({ tabs: this.props.tabs });


              //-------------------------------------------//

              //--------------Set Default Tab--------------//
              if (this.props.defaultTab)
                this.setState({ selectedTab: this.props.defaultTab }, () => this.reloadData());
              //-------------------------------------------//
              else
                this.fetchData({ page, pageSize, sorted, filtered, ...this.props.reqParams });
            }}
            getTrProps={this.props.getTrProps}
          />
          {/* <div style={{ minHeight: '200px' }}></div> */}
        </>

      </>
    )
  }
}
export default ActionTable;