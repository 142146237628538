import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import authService from "../../../../../services/authService";
import externalApiService from "../../../../../services/externalApiService";
import { CountriesList, AllCorporateBpList, CustomersList, SuppliersList } from '../../../../slplusTools';
import { ReportInputFieldContainer, FormRow, FormColumnLg } from "../../../../common/containers/formContainer";
import transactionService from "../../../services/transaction_service";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router";
import Utility from "../../../utils/DateParser";
import useApi from "../../../../../hooks/useApi";
import 'react-table/react-table.css';
import { confirmAlert } from "react-confirm-alert";
import { GridActionButton, GeneralButton, DateTimePicker, CurrencyLabel, Picker } from "../../../../common/inputs";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import CreateManoovaToken from "../../../../externalapis/components/createManoovaToken";
 
const colorRed = { color: "red" };

export default function TfBatchPendingDD(props) {

    const tableRef = useRef();
    const { ParseDate } = Utility();
    const currentDate = new Date();
    // API calls for fetching dd invoices and performing actions
    const DDallBatchApi = useApi(transactionService.directDebitBatchList);
    const DDBatchApi = useApi(transactionService.directDebitPendingBatchList);
    const listActionsApi = useApi(transactionService.directDebitListAction);
    const DDPendingInvoiceApi = useApi(transactionService.directDebitList);
    const manoovaCustCheckApi = useApi(externalApiService.checkManoovaCustRegistered);
    const usersListApi = useApi(externalApiService.getUsersListCount);

    /*-------------------------------------------------------------------------- */

    // State variables
    const [ddInvoiceList, setDdInvoiceList] = useState(null);
    const [ddIndividualInvoiceList, setDdIndividualInvoiceList] = useState(null);
    const { constants, category, products } = authService.getCurrentUser();
    const [country, setCountry] = useState(null);
    const [owner, setOwner] = useState(null);
    const [customer, setCustomer] = useState(props.entityGuid ? props.entityGuid : null);
    const [supplier, setSupplier] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isModel, setIsModel] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [totalAllocatedAmount, setTotalAllocatedAmount] = useState(0);
    const [totalAllocatedCount, setTotalAllocatedCount] = useState(0);
    const [corporate, setCorporate] = useState(true);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [regTokenPopup, setRegTokenPopup] = useState(false);
    const [manoovaCustomer, setManoovaCustomer] = useState(null);
    const [totalRequested, setTotalRequested] = useState(0);
    const [totalSuccess, setTotalSuccess] = useState(0);
    const [totalRequestedClients, setTotalRequestedClients] = useState(0);
    const [totalSuccessClients, setTotalSuccessClients] = useState(0);
    const [totalCancelled, setTotalCancelled] = useState(0);
    const [totalRegistredUsers, setTotalRegistredUsers] = useState(0);
    const [totalInactiveUsers, setTotalInactiveUsers] = useState(0);
    const [totalCancelledClients, setTotalCancelledClients] = useState(0);
    const [deletedItems, setDeletedItems] = useState([]);
    const [customerRequestCounts, setCustomerRequestCounts] = useState([]);
    const [manoovaScheduleDate, setManoovaScheduleDate] = useState(ParseDate(currentDate));
    const [confirmModalActive, setConfirmModalActive] = useState(false);


    const getUsersListCount = async () => {
        const { data } = await usersListApi.request();
        if (data.success == true) {
            setTotalRegistredUsers(data.usersList.activeUsers);
            setTotalInactiveUsers(data.usersList.inactiveUsers)
        } else {
            toast.error("Failed to fetch result");
        }
    }


    // Fetching dd batch list based on filters

    const getDDBatchList = async (owner, customer, supplier, selectedDate, corporate) => {
        const response = await DDBatchApi.request({
            selectedDate: selectedDate,
            product_type: constants.PRODUCTS.TRADEFINANCE,
            country: country,
            owner: owner,
            customer: customer !== undefined ? customer : null,
            supplier: supplier !== undefined ? supplier : null,
            corporate,
        }); // Sending Async request to server
        if (response) {
            setDeletedItems([])
            setDdInvoiceList(response.data.rows);
            const updatedCounts = response.data.rows.reduce((counts, invoice) => {
                const customerGuid = invoice.trans_customer_guid;
                counts[customerGuid] = (counts[customerGuid] || 0) + invoice.request_count;
                return counts;
            }, {});

            setCustomerRequestCounts(updatedCounts);
        }
    }

    // Fetching all dd list 
    const getDdAllBatchList = async (corporate) => {
        const ddResponse = await DDallBatchApi.request({
            product_type: constants.PRODUCTS.TRADEFINANCE,
            totals: true,
        });
        if (ddResponse && ddResponse.data.rows) {
            const rows = ddResponse.data.rows;
            const totalRequestedClients = _.sumBy(rows, (row) => (row.requested_status === 'requested' ? Number(row.total_clients) : 0));
            const totalRequested = _.sumBy(rows, (row) => (row.requested_status === 'requested' ? Number(row.total_request_amount) : 0));
            const totalSuccess = _.sumBy(rows, (row) => (row.requested_status === 'pending' ? Number(row.total_request_amount) : 0));
            const totalSuccessClients = _.sumBy(rows, (row) => (row.requested_status === 'pending' ? Number(row.total_clients) : 0));
            const totalCancelledClients = _.sumBy(rows, (row) => (row.requested_status === 'rejected' ? Number(row.total_clients) : 0));
            const totalCancelled = _.sumBy(rows, (row) => (row.requested_status === 'rejected' ? Number(row.total_request_amount) : 0));
            // const totalRegisteredUsers = sumBy(rows, 'total_clients'); 

            // Set the calculated totals to the state or wherever needed
            setTotalRequestedClients(totalRequestedClients);
            setTotalRequested(totalRequested);
            setTotalSuccess(totalSuccess);
            setTotalSuccessClients(totalSuccessClients);
            setTotalCancelledClients(totalCancelledClients);
            setTotalCancelled(totalCancelled);
        }
    }

    /* ------------------------------------------------------------------------------------- */
    //handle deletion of invoices from bulk list
    const handleDeleteClick = (itemToDelete) => {
        setDeletedItems((prevItems) => [...prevItems, itemToDelete.trans_guid]);
        const updatedList = ddIndividualInvoiceList.filter(
            (item) => item !== itemToDelete
        );
        setDdIndividualInvoiceList(updatedList);
    };
    const handleConfirmDelete = async () => {
        const response = await DDBatchApi.request({
            selectedDate: ParseDate(selectedDate),
            product_type: constants.PRODUCTS.TRADEFINANCE,
            country: country,
            owner: owner,
            customer: customer !== undefined ? customer : null,
            supplier: supplier !== undefined ? supplier : null,
            corporate,
            removed_invoices: deletedItems,
        }); // Sending Async request to server
        if (response) {
            setDdInvoiceList(response.data.rows);
            setIsModel(false);
        }

    };

    // Fetch dd batch list on component load or when filter variables change

    useEffect(() => {
        getDdAllBatchList(corporate)
    }, [corporate])

    useEffect(() => {
        getUsersListCount()
    }, [])

    useEffect(() => {
        if(confirmModalActive)
            handleRequest('requested');
    }, [manoovaScheduleDate])


    // Function to handle checkbox selection for individual invoices
    const handleCheckboxChange = (id) => {
        if (ddInvoiceList) {
            const updatedInvoiceList = [...ddInvoiceList];
            updatedInvoiceList[id].checked = !updatedInvoiceList[id].checked;
            setDdInvoiceList(updatedInvoiceList);

            const updatedSelectedInvoices = updatedInvoiceList
                .filter((invoice) => invoice.checked)
                .map((invoice) => invoice.final_amount);

            // Calculate the total of total_requested_amount
            const totalAmount = updatedSelectedInvoices.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue);
            }, 0);

            setSelectedInvoices(updatedSelectedInvoices);
            setShowButtons(updatedSelectedInvoices.length > 0);
            setTotalAllocatedCount(updatedSelectedInvoices.length); // Set the total count
            setTotalAllocatedAmount(totalAmount); // Set the total amount
        }
    };

    // Function to handle select/deselect all invoices
    const handleSelectAll = () => {
        const allChecked = !selectAll;
        const updatedInvoiceList = ddInvoiceList.map((invoice) => ({
            ...invoice,
            checked: allChecked,
        }));
        setDdInvoiceList(updatedInvoiceList);

        const updatedSelectedInvoices = allChecked
            ? updatedInvoiceList.map((invoice) => invoice.final_amount)
            : [];

        // Calculate the total of total_requested_amount
        const totalAmount = updatedSelectedInvoices.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue);
        }, 0);
        const totalCount = allChecked ? updatedSelectedInvoices.length : 0;

        setSelectedInvoices(updatedSelectedInvoices);
        setShowButtons(updatedSelectedInvoices.length > 0);
        setTotalAllocatedAmount(totalAmount); // Set the total amount
        setTotalAllocatedCount(updatedSelectedInvoices.length); // Set the total count
        setSelectAll(allChecked);
    };

    //function for export data
    const handleExport = async () => {
        const response = await DDBatchApi.request({
            selectedDate: selectedDate,
            product_type: constants.PRODUCTS.TRADEFINANCE,
            country: country,
            owner: owner,
            customer: customer !== undefined ? customer : null,
            docType: "xlsx",
            removed_invoices: deletedItems,
        });
        window.open(response.data, "_blank", "noreferrer");
    };

    // Function to trigger actions for selected invoices
    const handleRequest = status => {
        const msg = 'Are you sure ...?';
        setConfirmModalActive(true);
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">

                        <h4>{msg}</h4>
                        <div className="form-group">
                            <label>Would you like to schedule this for a different date?</label>
                            <DateTimePicker
                                name="scheduled_date"
                                label="Scheduled Date"
                                selectedDate={manoovaScheduleDate}
                                onChange={({ target }) => setManoovaScheduleDate(target.value)}
                                enableWeekDays={true}
                                minDate={new Date(ParseDate(selectedDate))}
                                maxDate={new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)}
                            />
                        </div>
                        {/* <p className="text-danger">This action will initiate a collection request to the payment gateway.</p>*/}
                        <p className="text-primary">Total Payment Selected: <span >{totalAllocatedAmount} from {totalAllocatedCount} client/s</span></p>

                        <button
                            className="btn btn-white ml-2 mt-2"
                            onClick={() => {
                                setConfirmModalActive(false);
                                onClose();
                            }}
                        >
                            No
                        </button>

                        <button
                            className="btn btn-primary ml-2 mt-2"
                            onClick={async () => {
                                setConfirmModalActive(false);
                                onClose();
                                handleReceivePayment(status);
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "",
            message: "",
        });
    }

    // Function to trigger when "save" button in "select date DD colledted" popup is clicked
    const handleReceivePayment = async (status) => {
        let success = false;
        const selectedCustomers = ddInvoiceList.filter(item => item.checked === true).map(item => {
            return item.trans_customer_guid;
        });

        // check if customers registered with manoova and have token
        const manoovaResponse = await manoovaCustCheckApi.request({
            customers: selectedCustomers
        });

        if (manoovaResponse) {
            if (manoovaResponse.data.success) {
                //Create a request to process in manoova
                const { data } = await listActionsApi.request(
                    {
                        selectedDate: ParseDate(selectedDate),
                        status,
                        product_type: constants.PRODUCTS.TRADEFINANCE,
                        selectedAttributes: ddInvoiceList
                        .filter(item => item.checked === true)
                        .map(item => ({
                            customers_id: item.trans_customer_guid,
                            total_non_utilisation_fee: item.total_non_utilisation_fee,
                        })),
                        // selectedAttributes: ddInvoiceList.filter(item => item.checked === true).map(item => item.trans_customer_guid),
                        bulkPendingUpdate: true,
                        owner: owner,
                        is_online: true,
                        removed_invoices: deletedItems,
                        scheduledDate:ParseDate(manoovaScheduleDate),
                    });

                if (data.success) {
                    if (data.errors.length > 0) {
                        data.errors.map(itm => {
                            toast.error(itm);
                        })
                    } else {
                        toast.success(data.message);
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <div className="custom-delete-ui">

                                        <p>The payment has been scheduled successfully. Please verify the status of your transaction.</p>

                                        <button
                                            className="btn btn-white ml-2 mt-2"
                                            onClick={() => {
                                                onClose();
                                                getDDBatchList();
                                            }}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="btn btn-primary ml-2 mt-2"
                                            onClick={() => {
                                                onClose();
                                                props.changeTab('PendingRequest');
                                            }}
                                        >
                                            Go to Awaiting Payments
                                        </button>

                                    </div>
                                );
                            },
                            title: "Account registered successfully",
                            message: "Account has been successfully registered in manoova. Now you can request online payments.",
                        });

                    }
                    setShowButtons(false);

                } else {
                    toast.error(data.message);
                }

            } else {

                let newDDlist = ddInvoiceList.map(item => {
                    if (item.checked == true && manoovaResponse.data.customerList.includes(item.trans_customer_guid)) {
                        item.invalidManoova = true;
                    }
                    return item;
                });
                setDdInvoiceList(newDDlist);
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-delete-ui">
                                <h4>Bank details missing</h4>
                                <p  >
                                    To proceed with this request, kindly update the banking information for the listed clients by clicking the button next to their names. Thank you.</p>
                                <ul>
                                    {
                                        newDDlist.filter(itm => itm.invalidManoova == true).map(itm => (
                                            <>
                                                <li className="text-danger text-left font-weight-bold">{itm.organization}</li>
                                            </>
                                        ))
                                    }
                                </ul>
                                <div class="clear"></div>

                                <button
                                    className="btn btn-white "
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    close
                                </button>

                            </div>
                        );
                    },
                    title: "",
                    message: "",
                });
            }
        } else {
            toast.error("Something went wrong. Contact your administrator.");
        }
    }

    // modal to add bank details to request for manoova token
    const handleOpenTokenModal = (customerGuid) => {
        setRegTokenPopup(true);
        setManoovaCustomer(customerGuid);
        let newDDlist = ddInvoiceList.map(item => {
            if (item.checked == true && customerGuid == item.trans_customer_guid) {
                item.invalidManoova = false;
            }
            return item;
        });
        setDdInvoiceList(newDDlist);
    }

    const closeRegTokenPopup = () => {
        setRegTokenPopup(false);
        setManoovaCustomer(null);

    }
    const columns = [
        {
            Header: (
                <div className="custom-control d-flex custom-checkbox small">
                    <input
                        id="customCheck"
                        className="custom-control-input"
                        type="checkbox"
                        checked={selectAll}
                        onChange={() => handleSelectAll()}
                    ></input>
                    <label
                        className="custom-control-label"
                        htmlFor="customCheck"
                    ></label>
                </div>
            ),
            id: 'bulk-checkbox',
            accessor: '',
            Cell: row => {
                return (
                    <div className="custom-control d-flex custom-checkbox small">
                        <input
                            id={`customCheck-${row.index}`}
                            className="custom-control-input"
                            type="checkbox"
                            checked={row.original.checked}
                            onChange={() => handleCheckboxChange(row.index)}
                        ></input>
                        <label
                            className="custom-control-label"
                            htmlFor={`customCheck-${row.index}`}
                        ></label>
                    </div>
                );
            },
            width: 50,
            sortable: false,
            resizable: false,
        },
        {
            Header: "Customer",
            accessor: "organization",
            headerStyle: { textAlign: "left" },
            Cell: row => {
                return (
                    <>
                        <span>{row.original.organization}</span>
                        {
                            row.original.invalidManoova && (
                                <>
                                    <span
                                        className="ml-2 label label-danger rounded-full cursor-link"
                                        title="Click to Register in payment gateway"
                                        onClick={() => handleOpenTokenModal(row.original.trans_customer_guid)}
                                    >
                                        Click to Register
                                    </span>
                                </>
                            )
                        }
                    </>
                );
            }
        },
        {
            Header: "Invoice Count",
            accessor: "request_count",
            headerStyle: { textAlign: "left" },
            Cell: row => {
                return (
                    <>
                        <Link className="cursor-link" title="View the invoice list"
                            onClick={async () => {
                                setIsModel(true);
                                const response = await DDPendingInvoiceApi.request({
                                    selectedDate: selectedDate,
                                    product_type: constants.PRODUCTS.TRADEFINANCE,
                                    country: country,
                                    owner: owner,
                                    customer: row.original.trans_customer_guid,
                                    supplier: supplier !== undefined ? supplier : null,
                                    removed_invoices: deletedItems,
                                }, 'pending'); // Sending Async request to server
                                if (response) {
                                    setDdIndividualInvoiceList(response.data.rows);
                                }
                            }
                            }
                        >

                            <span className="label label-light text-secondary"  >
                                {row.original.request_count} of the {customerRequestCounts[row.original.trans_customer_guid] || 0} invoices
                            </span>
                        </Link>
                    </>
                )
            }
        },
        {
            Header: "Finance Cost",
            accessor: "total_dd_amount",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <CurrencyLabel
                    value={
                        row.original.total_dd_amount !== null ? row.original.total_dd_amount : 0
                    }
                ></CurrencyLabel>
            ),
            Footer: (row) => {
                return (
                    <strong>
                        <CurrencyLabel decimal={2} value={_.sumBy(row.data, item => Number(item.total_dd_amount))}></CurrencyLabel>
                    </strong>
                );
            },
        },
        {
            Header: "Non Utilisation Fee",
            accessor: "total_non_utilisation_fee",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <CurrencyLabel
                    value={
                        row.original.total_non_utilisation_fee !== null ? row.original.total_non_utilisation_fee : 0
                    }
                ></CurrencyLabel>
            ),
            Footer: (row) => {
                return (
                    <strong>
                        <CurrencyLabel decimal={2} value={_.sumBy(row.data, item => Number(item.total_non_utilisation_fee))}></CurrencyLabel>
                    </strong>
                );
            },
        },
        {
            Header: "Total DD",
            accessor: "final_amount",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <CurrencyLabel
                    value={
                        row.original.final_amount !== null ? row.original.final_amount : 0
                    }
                ></CurrencyLabel>
            ),
            Footer: (row) => {
                return (
                    <strong>
                        <CurrencyLabel decimal={2} value={_.sumBy(row.data, item => Number(item.final_amount))}></CurrencyLabel>
                    </strong>
                );
            },
        },
        {
            Header: "",
            accessor: "request_count",
            headerStyle: { textAlign: "right" },
            Cell: row => {
                return (
                    <>
                        <Link className="cursor-link pull-right mr-2" title="View invoices"
                            onClick={async () => {
                                setIsModel(true);
                                const response = await DDPendingInvoiceApi.request({
                                    selectedDate: selectedDate,
                                    product_type: constants.PRODUCTS.TRADEFINANCE,
                                    country: country,
                                    owner: owner,
                                    customer: row.original.trans_customer_guid,
                                    supplier: supplier !== undefined ? supplier : null,
                                    removed_invoices: deletedItems,
                                }, 'pending'); // Sending Async request to server
                                if (response) {
                                    setDdIndividualInvoiceList(response.data.rows);
                                }
                            }
                            }
                        >
                            <i className="fa fa-eye"></i>
                        </Link>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <div className="overflow-visible">
                <div className="row ">
                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-body">

                                <h6 className="card-title   mb-2">Direct Debit Summary</h6>
                                <div className="d-flex justify-content-between mb-4 mt-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon mr-2 bg-purple-transparent text-purple">
                                            <i className="fa fa-money" />
                                        </div>
                                        <div>
                                            <p className="mb-0">Funds Requested</p>
                                            <span className="text-xs text-muted">
                                                Avaiting payment gateway response
                                            </span>
                                        </div>
                                    </div>
                                    <span className="text-right">
                                        <span className="font-bold  ">
                                            <span><CurrencyLabel value={totalRequested} ></CurrencyLabel></span>
                                        </span>
                                        <p className="mb-0 text-xs" style={{ color: "rgb(142, 68, 173)" }}>
                                            {totalRequestedClients} Clients
                                        </p>
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between mb-4 mt-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon mr-2 bg-warning-transparent text-warning">
                                            <i className="fa fa-file-text-o" />
                                        </div>
                                        <div>
                                            <p className="mb-0">Awaiting Reconcilation</p>
                                            <span className="text-xs text-muted">
                                                Funds received but not reconciled

                                            </span>
                                        </div>
                                    </div>
                                    <span className="text-right">
                                        <span className="font-bold  ">
                                            <span><CurrencyLabel value={totalSuccess} ></CurrencyLabel></span>
                                        </span>
                                        <p className="mb-0 text-xs text-warning"  >
                                            {totalSuccessClients} Clients
                                        </p>
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between mb-4 mt-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon mr-2 bg-danger-transparent text-danger">
                                            <i className="fa fa-thumbs-down" />
                                        </div>
                                        <div>
                                            <p className="mb-0">Rejected</p>
                                            <span className="text-xs text-muted">
                                                Rejected by payment gateway

                                            </span>
                                        </div>
                                    </div>
                                    <span className="text-right">
                                        <span className="font-bold  ">
                                            <span><span><CurrencyLabel value={totalCancelled} ></CurrencyLabel></span></span>
                                        </span>
                                        <p className="mb-0 text-xs text-danger"  >
                                            {totalCancelledClients} Clients
                                        </p>
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between mb-4 mt-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon mr-2 bg-primary-transparent text-primary">
                                            <i className="fa fa-users" />
                                        </div>
                                        <div>
                                            <p className="mb-0">Registered Users</p>
                                            <span className="text-xs text-muted">
                                                Active Registered Users

                                            </span>
                                        </div>
                                    </div>
                                    <span className="text-right">
                                        <span className="font-bold  ">
                                            <span>{totalRegistredUsers}</span>
                                        </span>
                                        <p className="mb-0 text-xs text-danger"  >
                                            {totalInactiveUsers} Inactive
                                        </p>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="card overflow-visible">
                            <div className="card-body">
                                <h6 className="card-title -mt-2 mb-2">New Direct Debit Request</h6>
                                <div className="row p-2 mt-2">

                                <ReportInputFieldContainer label={category == constants.CATEGORY.INTL_OFFICE ? "Country" : ""} show={category == constants.CATEGORY.INTL_OFFICE ? true : false}>
                                    <CountriesList type="horizontal" selectedCountry={country} onSelectItem={({ id }) => { setCountry(id); setOwner(null); setCustomer(null); setSupplier(null); }} />
                                </ReportInputFieldContainer>

                                <ReportInputFieldContainer isMandatory={true} label={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? "Business Partner" : ""} show={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? true : false} >
                                    <AllCorporateBpList selectedBp={owner} type="horizontal" country={country} onSelectItem={({ value }) => { setOwner(value); setCustomer(null); setSupplier(null); }} />
                                </ReportInputFieldContainer>

                                <ReportInputFieldContainer label="Customer" >
                                    <CustomersList selectedCustomer={customer} bp={owner}
                                        onSelectItem={option => { if (option.value == null) { setSupplier(null) } else { setCustomer(option.value) } }}
                                        category={constants.CATEGORY.TFCUSTOMER}
                                    />
                                </ReportInputFieldContainer>

                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>Date<span className="text-danger">*</span></label>
                                            <DateTimePicker
                                                name="requested_from_date"
                                                label="Requested Date From"
                                                selectedDate={selectedDate}
                                                onChange={({ target }) => setSelectedDate(target.value)}
                                                enableWeekDays={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="form-group">
                                            <label></label>
                                            <div>
                                                <GeneralButton className="btn btn-primary mt-2 pull-right"
                                                    name="Continue"
                                                    onClick={async () => {
                                                        if (!owner) {
                                                            toast.error("Please select a Business Partner before continuing.");
                                                        }
                                                        else if (!selectedDate) {
                                                            toast.error("Please select a date before continuing.");
                                                        } else {
                                                            getDDBatchList(owner, customer, supplier, selectedDate, corporate);

                                                        }
                                                    }}
                                                    disabled={ddInvoiceList && ddInvoiceList.length > 0 ? true : false}
                                                >
                                                    Continue
                                                </GeneralButton>
                                                <GeneralButton
                                                    className="btn btn-light mr-2 mt-2 pull-right"
                                                    name="Reset"
                                                    onClick={() => {
                                                        setDdInvoiceList(null);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="mt-3" style={{ minHeight: 165 }}>

                                    {ddInvoiceList && ddInvoiceList.length > 0 && (
                                        <div className="row mb-2">
                                            <div className="col-lg-6"><h6>Pending Direct Debits </h6></div>
                                            <div className="col-lg-6">
                                                <GeneralButton
                                                    className="btn btn-outline-secondary btn-sm pull-right"
                                                    name="Export"
                                                    onClick={handleExport}
                                                ></GeneralButton>
                                            </div>
                                        </div>
                                    )}

                                    {
                                        (
                                            DDBatchApi.loading == true ||
                                            listActionsApi.loading == true ||
                                            DDPendingInvoiceApi.loading == true ||
                                            manoovaCustCheckApi.loading == true
                                        ) && <LoadingSpinner />
                                    }
                                    {ddInvoiceList && ddInvoiceList.length > 0 ? (
                                        <div className="">
                                            <ReactTable
                                                data={ddInvoiceList}
                                                columns={columns}
                                                defaultPageSize={10}
                                                minRows={ddInvoiceList.length}
                                                className="-striped -highlight"
                                                showPagination={true}
                                                showPaginationTop={false}
                                                showPaginationBottom={true}
                                            />
                                        </div>
                                    ) : (
                                        <div className="card-body">
                                            <div>
                                                <p colSpan="15" className="text-center">
                                                    Choose Your Date and Begin
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {showButtons && (
                                        <div className="row mt-2">

                                            <div className="col-12">
                                                <div className="  b-dashed-2 p-2 bg-light ml-3">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <p className="mb-1">Total Payment Selected</p>
                                                            <h5 className="font-weight-bold mb-1">
                                                                <CurrencyLabel
                                                                    label="make pay"
                                                                    value={totalAllocatedAmount}
                                                                ></CurrencyLabel>
                                                            </h5>
                                                        </div>
                                                        <div className="col-4">
                                                            <p className="mb-1">Total Clients</p>
                                                            <h5 className="font-weight-bold mb-1">{totalAllocatedCount}</h5>
                                                        </div>
                                                        <div className="col-4">
                                                            <GridActionButton
                                                                class="btn btn-primary mr-2 mt-2 pull-right"
                                                                label="Request Online Payment"
                                                                handleClick={(e) => handleRequest('requested')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*------------  modal for showing dd invoices ---------- */}
            <Modal

                show={isModel}
                onHide={(e) => setIsModel(false)}
                size="lg" >
                <Modal.Header closeButton>
                    <h6> {`Direct Debit Invoice List `} </h6>
                </Modal.Header>

                <Modal.Body>
                    <>
                        {DDPendingInvoiceApi.loading && <LoadingSpinner />}
                        {(ddIndividualInvoiceList && ddIndividualInvoiceList.length > 0) ? (
                            <div className="card-body">
                                <table className="table table-hover no-margins">
                                    <thead>
                                        <tr>

                                            <th>Supplier</th>
                                            <th>Invoice Number</th>
                                            <th>Invoice Amount</th>
                                            <th>DD Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ddIndividualInvoiceList.map((item, index) => (
                                            <tr key={index}>

                                                <td>{item.supplier_org}</td>
                                                <td>{item.inv_number}</td>
                                                <td><CurrencyLabel value={item.inv_amount}></CurrencyLabel></td>
                                                <td><CurrencyLabel value={item.dd_amount}></CurrencyLabel></td>
                                                <td>
                                                    <i
                                                        className="fa fa-trash text-danger cursor-pointer"
                                                        title="Remove from DD request"
                                                        onClick={() => handleDeleteClick(item)}
                                                    ></i>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>

                                            <td></td>
                                            <td></td>
                                            <td> Total DD</td>
                                            <td><strong>
                                                <CurrencyLabel
                                                    value={_.sumBy(ddIndividualInvoiceList, (item) =>
                                                        Number(item.dd_amount)
                                                    )}
                                                ></CurrencyLabel>
                                            </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ) :
                            <></>
                        }
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Modal.Footer>
                        {/* <GeneralButton className="btn btn-light mt-2 pull-right"
                name="Cancel"
                onClick={() => {
                    setIsModel(false);
                }}>
                    Cancel
                </GeneralButton> */}
                        <GeneralButton className="btn btn-primary mt-2 pull-right"
                            name="Confirm"
                            onClick={() => handleConfirmDelete()}>
                            Confirm
                        </GeneralButton>
                    </Modal.Footer>
                </Modal.Footer>
            </Modal>

            {/*------------------------Manoova Token request modal----------------------*/}
            <Modal
                show={regTokenPopup}
                onHide={() => setRegTokenPopup(false)}
                centered
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Register in manoova</h4>
                </Modal.Header>
                <Modal.Body>
                    <CreateManoovaToken
                        customer={manoovaCustomer}
                        closeModal={closeRegTokenPopup}
                    />
                </Modal.Body>
            </Modal>
            {/*--------------------------------------------------------------*/}
        </>


    )

}