import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../common/loaders/loadingSpinner';
import useApi from '../../hooks/useApi';
import Modal from 'react-bootstrap/Modal';
import Switch from "react-switch";
import entityService from "../../services/entityService";
import prospectService from "../../services/prospectService";
import { toast } from "react-toastify";
import { GeneralButton, Picker } from "../../components/common/inputs";
import authService from "../../services/authService";
import { useLocation } from 'react-router-dom';

export default function SettingsContact({ data, onClose, onHandleReload, type="entity" }) {

    const { constants, category } = authService.getCurrentUser();
    const [receiveEmailNotification, setReceiveEmailNotification] = useState(data.send_newsletter);
    const [enableNewsletter, setEnableNewsLetter] = useState(data.enable_newsletter_subscription);
    const [managingFifoContact, setManagingFifoContact] = useState(data.managing_bp_contact);
    const [enablePaymentModule, setEnablePaymentModule] = useState(data.enable_payments_module);

    const [bpContacts, setBpContacts] = useState([]);

    const updateApi = useApi(type === "entity" ? 
        entityService.createEntityContact : prospectService.createProspectContact);

    const loadParentContactsApi = useApi(entityService.getEntityContactsList);

    const handleSubmit = async () => {
        const res = await updateApi.request({ 
            contact_id: data.contact_id, 
            send_newsletter: receiveEmailNotification ? 'yes' : 'no',
            enable_newsletter_subscription: enableNewsletter ? 'yes' : 'no',
            managing_bp_contact: managingFifoContact,
            enable_payments_module: enablePaymentModule ? 'yes' : 'no',
        });
        if (res.data.success === true) {
            toast.success(res.data.message);
            onClose();
            onHandleReload();
        }
        else
            toast.error(res.data.message);
    }

    const location = useLocation()

    useEffect(() => {
        const fetchParentTeamMembers = async() => {
            const response = await loadParentContactsApi.request(data.owner);
            let parentEntityContacts = [];
            response.data.rows.map((item, index) => {
                parentEntityContacts[index] = {
                    label: `${item.first_name} ${item.last_name}`,
                    value: item.contact_id
                };
            });
            setBpContacts(parentEntityContacts)
        }
        fetchParentTeamMembers();
    },[])

    return (
        <>
            <Modal.Header closeButton>
                <h4 className="modal-title">
                    Settings
                </h4>
            </Modal.Header>

            <Modal.Body>
            {(updateApi.loading || loadParentContactsApi.loading) && <LoadingSpinner />}

                <div className="row">
                    <div className="col-sm-8">
                        <label>
                            <p>Receive email notification</p>
                        </label>
                    </div>
                    <div className="col-sm-4">
                        <Switch
                            checked={receiveEmailNotification}
                            onChange ={checked => setReceiveEmailNotification(checked)}
                            onColor="#16C79A"
                            className="pull-right"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-8">
                        <label>
                            <p>Subscribe Newsletter</p>
                        </label>
                    </div>
                    <div className="col-sm-4">
                        <Switch
                            checked={enableNewsletter}
                            onChange ={checked => setEnableNewsLetter(checked)}
                            onColor="#16C79A"
                            className="pull-right"
                        />
                    </div>
                </div>

                {
                    (location.pathname === '/country-office/details/teammembers') &&
                        <>
                            <div className="row">
                                <div className="col-sm-8">
                                    <label>
                                        <p>Enable Payment Module</p>
                                    </label>
                                </div>
                                <div className="col-sm-4">
                                    <Switch
                                        checked={enablePaymentModule}
                                        onChange={checked => setEnablePaymentModule(checked)}
                                        onColor="#16C79A"
                                        className="pull-right"
                                    />
                                </div>
                            </div>
                        </>
                }

                <div className="row">
                    <div className="col-sm-8">
                        <label>
                            <p>Managing Fifo Contact</p>
                        </label>
                    </div>
                    <div className="col-sm-4">
                        <Picker
                            items={bpContacts}
                            selectedItem={managingFifoContact ? bpContacts.filter(item => item.value === managingFifoContact) : {label: 'Select managing fifo contact', value: null}}
                            onSelectItem={option => { setManagingFifoContact(option.value) }}
                        />
                    </div>
                </div>


                <GeneralButton className="btn btn-primary block pull-right  m-b m-t-md mt-4" name={"Save"} onClick={() => handleSubmit()} ></GeneralButton>

            </Modal.Body>
        </>
    )
}