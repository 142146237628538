import * as moment from "moment";

const DateParser = () => {

  const ParseDate = (date, dateFormat = "YYYY-MM-DD") => {
    return moment(date).format(dateFormat).toString();
  };

  const ParseDateDMY = (date, dateFormat = "DD/MM/YYYY") => {
    return moment(date).format(dateFormat).toString();
  };

  return { ParseDate,ParseDateDMY };
};

export default DateParser;
