import React, { useEffect, useContext, useState } from "react";
import { CurrencyLabel } from "../../../common/inputs";
import useApi from '../../../../hooks/useApi';
import useIsMounted from "../../../../hooks/useIsMounted";
import EntityContext from "../../../../context/entityContext";
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";

function InvoiceSummaryCard({ invData }) {

    const isMounted = useIsMounted();
    const { selectedBusinessType, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);

    //------------Api Service--------------------------------------------
    const invoiceSummaryApi = useApi(dfTransactionService.InvoiceSummary);

    const fetchData = async () => {

        if (invData == null) {
            const response = await invoiceSummaryApi.request({
                trans_client_guid: entityDetails.a_guid,
                trans_type: "open",
                dateFilter: new Date(),
            });
            if (response && isMounted()) {
                invData = response.data;
            }
        }

        setData(invData);
    }

    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);


    return (
        <>
            <div className="card">
                <div className="card-header ">
                    <h4 className="card-title mb-2">Invoice Summary</h4>
                </div>
                {
                    data && (
                        <>
                            <div className="card-body profile-card mt-2">
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon  text-white bg-warning mr-2">
                                            <i className="fa fa-money" />
                                        </div>
                                        <div>
                                            <h6 className="mb-0 text-sm font-weight-normal">
                                                Total Invoices Outstanding
                                            </h6>
                                            <span className="text-xs  text-warning">
                                                Invoices outstanding
                                            </span>
                                        </div>
                                    </div>
                                    <span className="font-bold  text-right">
                                        <span>
                                            <CurrencyLabel
                                                value={data.invoiceSummary.inv_outstanding}
                                            ></CurrencyLabel>
                                        </span>
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon  text-white bg-danger mr-2 ">
                                            <i className="fa fa-thumbs-down " />
                                        </div>
                                        <div>
                                            <h6 className="mb-0 text-sm font-weight-normal">
                                                Total Invoices Disapproved
                                            </h6>
                                            <span className="text-xs  text-danger">
                                                Total disapproved amount
                                            </span>
                                        </div>
                                    </div>
                                    <span className="font-bold  text-right">
                                        <span>
                                            <CurrencyLabel
                                                value={data.invoiceSummary.disapprovals}
                                            ></CurrencyLabel>
                                        </span>
                                    </span>
                                </div>

                                <div className="dropdown-divider"></div>
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon  text-white bg-primary mr-2 ">
                                            <i className="fa fa-thumbs-up" />
                                        </div>
                                        <div>
                                            <h6 className="mb-0 text-sm font-weight-normal">
                                                Total Invoices Approved
                                            </h6>
                                            <span className="text-xs  text-primary">Invoices approved</span>
                                        </div>
                                    </div>
                                    <span className="font-bold  text-right">
                                        <span>
                                            <CurrencyLabel
                                                value={data.invoiceSummary.approved_total}
                                            ></CurrencyLabel>
                                        </span>
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon  text-white bg-purple mr-2 ">
                                            <i className="fa fa-calendar" />
                                        </div>
                                        <div>
                                            <h6 className="mb-0 text-sm font-weight-normal">
                                                Early Payment @{" "}
                                                <span className=" ">
                                                    {data.invoiceSummary.advance_percentage}%{" "}
                                                </span>
                                            </h6>
                                            <span className="text-xs text-indigo  ">
                                                Early payment @ {data.invoiceSummary.advance_percentage}%
                                            </span>
                                        </div>
                                    </div>
                                    <span className="font-bold  text-right">
                                        <span>
                                            <CurrencyLabel
                                                value={data.invoiceSummary.finance_advance_rate}
                                            ></CurrencyLabel>
                                        </span>
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon  text-white bg-secondary mr-2 ">
                                            <i className="fa fa-file" />
                                        </div>
                                        <div>
                                            <h6 className="mb-0 text-sm font-weight-normal">Balance Owing</h6>
                                            <span className="text-xs  text-secondary">
                                                Total balance owing
                                            </span>
                                        </div>
                                    </div>
                                    <span className="font-bold  text-right">
                                        <span>
                                            <CurrencyLabel
                                                value={data.invoiceSummary.funds_drawn}
                                            ></CurrencyLabel>
                                        </span>
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon  text-white bg-info mr-2 ">
                                            <i className="fa fa-database" />
                                        </div>
                                        <div>
                                            <h6 className="mb-0 text-sm font-weight-normal">
                                                Facility Limit
                                            </h6>
                                            <span className="text-xs  text-info">Total facility limit</span>
                                        </div>
                                    </div>
                                    <span className="font-bold  text-right">
                                        <span>
                                            <CurrencyLabel
                                                value={data.invoiceSummary.funding_limit}
                                            ></CurrencyLabel>
                                        </span>
                                    </span>
                                </div>
                                <div className="dropdown-divider text-primary"></div>
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <div className="media-icon  text-white bg-primary mr-2 ">
                                            <i className="fa fa-cube" />
                                        </div>
                                        <div>
                                            <h6 className="mb-0 text-sm font-weight-normal">
                                                Undrawn Availability
                                            </h6>
                                            <span className="text-xs  text-primary">
                                                Undrawn availability
                                            </span>
                                        </div>
                                    </div>
                                    <span className="font-bold  text-right">
                                        <span>
                                            <CurrencyLabel
                                                value={data.invoiceSummary.undrawn_amount}
                                            ></CurrencyLabel>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    );
}

export default InvoiceSummaryCard;