import React, { useState, useContext, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Picker } from "../../../common/inputs";
import useApi from "../../../../hooks/useApi";
import transactionService from "../../../../services/invTransactions";
import transaction_service from "../../../supply-chain/services/commonService";
import entityService from "../../../../services/entityService";
import prospectService from "../../../../services/prospectService";
import EntityContext from "../../../../context/entityContext";
import RoleChecker from "../../../../utils/roleChecker";
import Select from "react-select";
import { browserHistory } from "react-router";
import auth from "../../../../services/authService";

export default function SearchTransactions({
  onClose,
  a_guid
}) {
  //------------Api Services--------------------------------------------
  const ifTransSearchApi = useApi(transactionService.quickSearch);
  const scTransSearchApi = useApi(transaction_service.quickSearch);
  const accountSearchApi = useApi(entityService.quickSearch);
  const prospectSearchApi = useApi(prospectService.quickSearch);

  const commonSearchApi = useApi(transaction_service.commonSearch);

  const [items, setItems] = useState([]);
  const { constants, products } = auth.getCurrentUser();
  const { accountDetails, salesRoles, purchaseRoles } = useContext(EntityContext);

  const [transProductTypes, setTransProductTypes] = useState([]);

  const accountProductTypes = [
    { label: "Accounts", value: "accounts" },
    { label: "Prospects", value: "prospects" },
  ];

  const [selectedItem, setSelectedItem] = useState({
    label: "Search",
    value: null,
  });

  const [selectedProductType, setSelectedProductType] = useState(null);

  const fetchSuggestions = async (keyword) => {

    const scfaccountInfo = purchaseRoles.find(item => item.product == constants.PRODUCTS.SUPPLYCHAIN);
    const ifaccountInfo = salesRoles.find(item => item.product == constants.PRODUCTS.INVOICE);
    const tfaccountInfo = purchaseRoles.find(item => item.product == constants.PRODUCTS.TRADEFINANCE);

    if (keyword !== null && keyword.length >= 2) {
      const { data } = await commonSearchApi.request({ 
        searchkey: keyword, 
        keyword: keyword, 
        a_guid: a_guid, 
        selectedBusinessType: constants.BUSINESS_TYPE.SALE, 
        productsEnabled: {
          [constants.PRODUCTS.SUPPLYCHAIN]: products.findIndex(item => item.id == constants.PRODUCTS.SUPPLYCHAIN) !== -1 ? true : false,
          [constants.PRODUCTS.TRADEFINANCE]: products.findIndex(item => item.id == constants.PRODUCTS.TRADEFINANCE) !== -1 ? true : false,
          [constants.PRODUCTS.INVOICE]: products.findIndex(item => item.id == constants.PRODUCTS.INVOICE) !== -1 ? true : false
        }
      });

      setItems(data.map(obj => {
        if(obj.product === constants.PRODUCTS.INVOICE)
          obj.label = `${obj.label} - ${ifaccountInfo.nick_name} (Facility ${ifaccountInfo.facilityNumber})`

        else if(obj.product === constants.PRODUCTS.SUPPLYCHAIN)
          obj.label = `${obj.label} - ${scfaccountInfo.nick_name} (Facility ${scfaccountInfo.facilityNumber})`

        else if(obj.product === constants.PRODUCTS.TRADEFINANCE)
          obj.label = `${obj.label} - ${tfaccountInfo.nick_name} (Facility ${tfaccountInfo.facilityNumber})`

        return obj;
      }));
    }
  };

  const handleSelectItem = (transaction) => {
    setSelectedItem(transaction);
    onClose();
    if (transaction.product == constants.PRODUCTS.INVOICE)
      browserHistory.push(`client/if/invoice-details?trans_guid=${transaction.value}`);
    else if (transaction.product == constants.PRODUCTS.SUPPLYCHAIN)
      browserHistory.push(`/client/scf/invoice/details?inv_guid=${transaction.value}`);
    else if(transaction.product == constants.PRODUCTS.TRADEFINANCE)
      browserHistory.push(`/client/tf/invoice/details?inv_guid=${transaction.value}`);
  };

  const handleInputChange = (inputVal) => {
    fetchSuggestions(inputVal);
  };

  //---------------Handle search key down-----------------//

  const customStyles = {
    menu: provided => ({ ...provided, zIndex: 999 })
  };

  return (
    <>

      <Modal.Header closeButton >
        <h3 className="card-title">Search</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="overflow-visible">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <Select
                  options={items}
                  placeholder="Type invoice no."
                  noOptionsMessage={() => "Start type your search"}
                  onInputChange={handleInputChange}
                  styles={customStyles}
                  value={selectedItem}
                  onChange={(selectedOption) => {
                    handleSelectItem(selectedOption);
                  }}
                  onBlur={(e) => setItems([])}
                  theme={theme => ({
                    ...theme,
                    borderRadius: ".375rem",
                    colors: {
                      ...theme.colors,
                      text: "black",
                      primary25: "white",
                      primary: "#1ab394",
                      neutral0: "white"
                    }
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}
