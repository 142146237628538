import React, { Component } from "react";
import * as moment from "moment";
import queryString from 'query-string';
import { toast } from "react-toastify";
import { browserHistory } from "react-router";
import { CurrencyLabel } from "../../../common/inputs";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import Modal from "react-bootstrap/Modal";
import coreService from "../../../../services/coreService";
import EntityContext from "../../../../context/entityContext";
import auth from "../../../../services/authService";
import { Link } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import transactionService from "../../../supply-chain/services/transaction_service";
import ifTransactionService from "../../../../services/invTransactions";
import CreateTransaction from "./_createTransaction";
import AssignInvoice from "./_assignInvoice";
import PayableCard from "./_payablecard";
import ReceivableCard from "./_receivableCard";

class InvoiceViewAll extends Component {
    static contextType = EntityContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initiated_user_type: "",
            invoiceList: [],
            selectedInvoice: [],
            assignInvBtn: false,
            assignInvModal: false,
            fundData: null,
            ifFundData: null,
            dropDownHeading: "",
            user_type: "",
            selectUserType: "",
            createInvModalOpen: false,
            editTransaction: false,
            selectedTrans: [],
            card: {
                scf: false,
                tf: false,
                IF: false,
                cardRowSiz: 12,
            }

        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchTransactionList();
        this.fetchFundData();
        this.handleCardDisplay();

        let paramList = queryString.parse(this.props.location.search);
        if (paramList.invtype === 'addAsCustomer')
            this.fetchIfFundsData();
    }
    fetchFundData = async () => {
        const { data } = await transactionService.payableFund();
        if (data) {
            this.setState({ fundData: data });
        }
    }
    fetchIfFundsData = async () => {
        const { entity_id, constants } = auth.getCurrentUser();
        const { data } = await ifTransactionService.facilityCardInfo({
            entity_id,
            selectedBusinessType: constants.BUSINESS_TYPE.SALE
        });
        if (data) {
            this.setState({ ifFundData: parseFloat(data.approved) + parseFloat(data.open) + parseFloat(data.pending) });
        }
    }
    fetchTransactionList = async () => {
        this.setState({ loading: true });
        let paramList = queryString.parse(this.props.location.search);
        if (paramList.invtype == undefined) {
            toast.error('Initiated user type missing');
        } else {
            this.setState({ initiated_user_type: paramList.invtype })
            const response = await coreService.ViewGeneralTransaction({ "userType": paramList.invtype, clientSide: true });
            if (response) {
                this.setState({ invoiceList: response.data.transactionList });
            }
        }
        this.setState({ loading: false });
    }

    handleSelect = (item) => {
        if (this.state.selectedInvoice.some(e => e.trans_guid === item.trans_guid)) {
            const filtered = this.state.selectedInvoice.filter((obj) => obj.trans_guid !== item.trans_guid);
            this.setState({ selectedInvoice: filtered });
            if (filtered.length > 0) {
                this.setState({ assignInvBtn: true });
            } else {
                this.setState({ assignInvBtn: false });
            }
        } else {
            let seltedInv = {
                attachment: item.attachment,
                organization: item.contact.organization,
                id: item.id,
                initiated_user_type: item.initiated_user_type,
                inv_amount: item.inv_amount,
                inv_due_date: item.inv_due_date,
                inv_number: item.inv_number,
                inv_purchase_order_number: item.inv_purchase_order_number,
                owner: item.owner,
                trans_customer_guid: item.trans_customer_guid,
                trans_date: item.trans_date,
                trans_guid: item.trans_guid,
                trans_status: item.trans_status,
                trans_supplier_guid: item.trans_supplier_guid,
                trans_intiated_by: item.trans_intiated_by,
                verified: item.verified,
                hasProductEnabled: false,
            }
            this.setState({
                selectedInvoice: [...this.state.selectedInvoice, seltedInv],
                assignInvBtn: true
            });
        }

    }

    handleAssignInvoice = () => {
        this.setState({ assignInvModal: true });
    }

    onCloseAssignModal = () => {
        this.setState({
            assignInvModal: false
        });

    }

    handleRefreshCard = () => {
        this.setState({
            selectedInvoice: [],
            assignInvBtn: false,
            invoiceList: [],
        });
        this.onCloseAssignModal();
        this.fetchTransactionList();
        this.fetchFundData();
    }

    handleAddNew = () => {
        if (this.state.initiated_user_type == 'addAsSupplier') {
            this.setState({
                dropDownHeading: "Select Supplier",
                user_type: "customer",
                selectUserType: "account",
                createInvModalOpen: true
            });

        } else if (this.state.initiated_user_type == 'addAsCustomer') {
            this.setState({
                dropDownHeading: "Select Customer",
                user_type: "customer",
                selectUserType: "account",
                createInvModalOpen: true
            });
        }
    }

    onHide = () => {
        this.setState({
            createInvModalOpen: !this.state.createInvModalOpen,
            selectedTrans: [],
        });
    }

    handleDeleteInvoice = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Are you sure ...?</h4>
                        <p>{`Are you sure want to delete invoice `}</p>
                        <button
                            className="btn btn-white"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => {
                                this.deleteGenInvoice();
                                onClose();
                            }}
                        >
                            {"Yes"}
                        </button>
                    </div>
                );
            },
            title: "Delete General Invoice",
            message: `Are you sure want to delete invoice`,
        });
    }

    deleteGenInvoice = async () => {
        this.setState({ loading: true });
        let invArr = [];
        this.state.selectedInvoice.map((item) => {
            invArr.push({
                trans_id: item.id,
                trans_guid: item.trans_guid,
                inv_number: item.inv_number,

            })
        })

        const response = await coreService.DeleteGeneralTransaction({
            invoices: invArr,
        })
        this.setState({ loading: false });
        if (response.data.success == true) {
            toast.success('Invoices deleted successfully');
            this.handleRefreshCard();

        } else {
            toast.error(response.data.message)
            response.data.response.map(itm => {
                toast.error(itm.error)
            })

        }

    }

    handleCardDisplay = () => {
        const { constants } = auth.getCurrentUser();
        let scf = false;
        let tf = false;
        let IF = false;
        let cardRowSiz = 12;
        let paramList = queryString.parse(this.props.location.search);
        if (paramList.invtype !== undefined) {
            if (paramList.invtype == 'addAsSupplier') {
                this.context.entityDetails.products.filter(itm => itm.isChecked === true).map((itm) => {
                    if (itm.id == constants.PRODUCTS.SUPPLYCHAIN) {
                        scf = true;
                    }
                    if (itm.id == constants.PRODUCTS.TRADEFINANCE) {
                        tf = true;
                    }

                })

                if (scf == true && tf == true) {
                    cardRowSiz = 6;
                }
            } else if (paramList.invtype == 'addAsCustomer') {

                this.context.entityDetails.products.filter(itm => itm.isChecked === true).map((itm) => {
                    if (itm.id == constants.PRODUCTS.INVOICE) {
                        IF = true;
                    }
                })
            }
            this.setState({
                card: {
                    scf: scf,
                    tf: tf,
                    IF: IF,
                    cardRowSiz: cardRowSiz,
                }
            })
        }
    }

    handleEditTransaction = (item) => {

        if (this.state.initiated_user_type == 'addAsSupplier') {
            this.setState({
                invoice_type: "payable",
            });

        } else if (this.state.initiated_user_type == 'addAsCustomer') {
            this.setState({
                invoice_type: "receivable",
            });
        }

        this.setState({
            user_type: "customer",
            selectUserType: "account",
            editTransaction: true,
            selectedTrans: item,
            createInvModalOpen: true
        });
    }

    render() {
        const { constants } = auth.getCurrentUser();
        const { fundData, ifFundData } = this.state;
        let fecilityPrimary = 1;
        const ifAccountInfo = this.context.accountDetails.find(item => item.product == constants.PRODUCTS.INVOICE);
        const tfAccountInfo = this.context.accountDetails.find(item => item.product == constants.PRODUCTS.TRADEFINANCE)
        const scfAccountInfo = this.context.accountDetails.find(item => item.product == constants.PRODUCTS.SUPPLYCHAIN)
        return (
            <>
                {this.state.loading && <LoadingSpinner></LoadingSpinner>}

                {/* -------- Modal to assign invoice start --------- */}
                <Modal
                    show={this.state.assignInvModal}
                    onHide={this.onCloseAssignModal}
                    //className={"animated bounceInRight "}
                    size="lg"
                >
                    <AssignInvoice
                        selectedInvoice={this.state.selectedInvoice}
                        handleRefreshCard={this.handleRefreshCard}
                        userType={this.state.initiated_user_type}
                    />

                </Modal>
                {/* -------- Modal to assign invoice end --------- */}

                {/* -------- Modal to add new transaction start --------- */}
                <Modal
                    show={this.state.createInvModalOpen}
                    onHide={this.onHide}
                    //className={"animated bounceInRight "}
                    //titleIcon={InvIcon}
                    title={"Create Invoice"}
                >
                    <CreateTransaction
                        onCloseModal={this.onHide}
                        selectUserType={this.state.selectUserType}
                        dropDownHeading={this.state.dropDownHeading}
                        initiated_user_type={this.state.user_type}
                        invoice_type={queryString.parse(this.props.location.search).invtype == 'addAsSupplier' ? 'payable' : 'receivable'}
                        refreshCard={this.handleRefreshCard}
                        editTransaction={this.state.editTransaction}
                        selectedTrans={this.state.selectedTrans}
                    />
                </Modal>
                {/* -------- Modal to add new transaction end --------- */}


                <>
                    <div className="row justify-content-center">
                        {
                            this.state.card.scf === true && (
                                <>
                                    <div className={"col-sm-4"}>
                                        <PayableCard
                                            facilityname={`Facility ${fecilityPrimary++}`}
                                            bankDetail={{
                                                account_name: scfAccountInfo ? scfAccountInfo.nick_name ? scfAccountInfo.nick_name : scfAccountInfo.bank_account_name : null,
                                                account_number:scfAccountInfo ? scfAccountInfo.account_number:"",
                                                bsb_number: scfAccountInfo ? scfAccountInfo.bsb_number:"",
                                            }}
                                            availableValue={(fundData && fundData.scfAvailable) ? fundData.scfAvailable : "0.00"}
                                        />
                                    </div>
                                </>
                            )
                        }

                        {
                            this.state.card.tf === true && (
                                <>
                                    <div className={"col-sm-4"}>
                                        <PayableCard
                                            facilityname={`Facility ${fecilityPrimary++}`}
                                            bankDetail={{
                                                account_name: tfAccountInfo ? tfAccountInfo.nick_name ? tfAccountInfo.nick_name : tfAccountInfo.bank_account_name : null,
                                                account_number: tfAccountInfo ? tfAccountInfo.account_number:"",
                                                bsb_number: tfAccountInfo ? tfAccountInfo.bsb_number:"",
                                            }}
                                            availableValue={(fundData && fundData.tfAvailable) ? fundData.tfAvailable : "0.00"}
                                        />
                                    </div>
                                </>
                            )
                        }

                        {
                            this.state.card.IF === true && (
                                <>
                                    <div className={"col-sm-4"}>
                                        <Link to='/client/if/dashboard'>
                                            <ReceivableCard
                                                facilityname={`Facility ${fecilityPrimary++}`}
                                                bankDetail={{
                                                    account_name: ifAccountInfo ? ifAccountInfo.nick_name ? ifAccountInfo.nick_name : ifAccountInfo.bank_account_name : null,
                                                    account_number: ifAccountInfo ? ifAccountInfo.account_number : null,
                                                    bsb_number: ifAccountInfo ? ifAccountInfo.bsb_number : null,
                                                }}
                                                availableValue={ifFundData ? ifFundData : "0.00"}
                                            />
                                        </Link>
                                    </div>
                                </>
                            )
                        }

                    </div>


                </>

                <div className="card overflow-visible">
                    <div className="card-header" style={{ height: 50 }}>
                        <div className="row">
                            <div className="col-lg-8">
                                {
                                    this.state.initiated_user_type == 'addAsSupplier' && (
                                        <h5 className="card-title text-secondary">UNASSIGNED ACCOUNTS PAYABLE INVOICES</h5>
                                    )
                                }
                                {
                                    this.state.initiated_user_type == 'addAsCustomer' && (
                                        <h5 className="card-title text-secondary">UNASSIGNED ACCOUNTS RECEIVABLE INVOICES</h5>
                                    )
                                }
                            </div>
                            <div className="col-lg-4">
                                {
                                    this.state.assignInvBtn === true ? (
                                        <>
                                            <div className="btn-group pull-right dropleft" role="group" >

                                                <div className="btn-group" role="group">
                                                    <button id="btnGroupDrop1" type="button" className="btn btn-secondary ml-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span>Assign/Delete Invoice</span> <i className="fa fa-angle-down"></i>
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <a onClick={() => this.handleAssignInvoice()} className="dropdown-item" href="#">Assign invoice</a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item" onClick={() => this.handleDeleteInvoice()}>Delete</a>
                                                    </div>
                                                </div>
                                            </div>


                                        </>
                                    ) : (
                                        <>
                                            <div className="pull-right">

                                                <button
                                                    className="btn btn-primary btn-sm mr-2"
                                                    onClick={() => this.handleAddNew()}
                                                >Add New
                                                </button>
                                                <Link
                                                    onClick={() => browserHistory.push("/client/home")}
                                                    class="btn btn-light ml-2"
                                                >
                                                    <i alt="Close" data-toggle="tooltip" data-placement="top" title="Close" className="fa fa-times  "></i>
                                                </Link>
                                            </div>

                                        </>
                                    )
                                }
                            </div>
                        </div>


                    </div>
                    <div className="card-body ">

                        <div className="list-group list-group-flush bg-light px-2 rounded-xl card-scroll">
                            {this.state.invoiceList != undefined && this.state.invoiceList.map((obj, key) =>

                                <div key={key} className="list-group-item list-group-item-action flex-column align-items-start rounded-xl border-0 mt-2 mb-2 ">
                                    <div className="row text-secondary">
                                        <div className="col-sm-1">
                                            <div className="custom-control d-flex custom-checkbox small">
                                                <input
                                                    id={obj.trans_guid}
                                                    type="checkbox" className="custom-control-input"
                                                    onChange={() => this.handleSelect(obj)}
                                                />

                                                <label className="custom-control-label" htmlFor={obj.trans_guid}></label>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="ml-auto mg-t-5 cursor-link" onClick={() => {
                                                this.handleEditTransaction(obj)
                                            }}>
                                                <h6 className="mb-0 font-weight-bold">{obj.contact && (obj.contact.organization)}</h6>
                                            </div>

                                        </div>
                                        <div className="col-sm-2">
                                            <div className="ml-auto   mg-t-5">
                                                <h6 className="mb-0 font-weight-bold">{obj.inv_number}</h6>
                                                <small className="opacity-50">Inv No: </small>

                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="ml-auto  mg-t-5">
                                                <h6 className="mb-0 font-weight-bold"><CurrencyLabel value={obj.inv_amount}></CurrencyLabel></h6>
                                                <small className="opacity-50 text-primary">Inv Date: {moment(obj.trans_date).format("MMM DD")}
                                                    <span className="ml-1">
                                                    </span>
                                                </small>

                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="ml-auto  mg-t-5">
                                                <h6 className="mb-0 font-weight-bold">
                                                    {
                                                        obj.initiatedBy && (
                                                            obj.initiatedBy.first_name + ' ' + obj.initiatedBy.last_name
                                                        )
                                                    }
                                                </h6>
                                                <small className="opacity-50">Initiated by: </small>
                                            </div>


                                        </div>
                                        <div className="col-sm-2">
                                            <div className="ml-auto text-right mg-t-5">

                                                {obj.status == "Inactive" ?
                                                    <h6 className="mb-0 font-weight-bold text-warning text-uppercase">Pending Approval</h6>
                                                    : <h6 className="mb-0 font-weight-bold ">Awaiting Assignment</h6>
                                                }
                                                <small className="opacity-50 text-danger">Due On: {moment(obj.inv_due_date).format("MMM DD")}
                                                    <span className="ml-1">
                                                    </span>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="d-flex align-items-center">

                                        <div className="d-flex" >
                                            <div className="avatar avatar-md text-white bg-success mr-2  font-weight-bold rounded-full">
                                                <span>{obj.contact && obj.contact.first_name.charAt(0)}</span>
                                            </div>
                                            <div className="text-sm-left text-center mb-3 mb-sm-0">
                                                <h6 className="mb-0 truncate"> {obj.contact && obj.contact.first_name}</h6>
                                                <small className="opacity-50">{moment(obj.updated_at).format("MMM DD")}
                                                    <span className="ml-1">{moment(obj.updated_at).format("hh:mm A")}
                                                    </span>
                                                </small>
                                            </div>
                                        </div>
                                        <div className="ml-auto text-right mg-t-5">
                                            <h6 className="mb-0 font-weight-bold"><CurrencyLabel value={obj.inv_amount}></CurrencyLabel></h6>
                                            {obj.status == "Inactive" ?
                                                <small className="badge text-warning text-uppercase">Pending Approval</small>
                                                : <small className="badge text-success  ">Pending</small>}
                                        </div>

                                    </div> */}
                                </div>

                            )}

                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default InvoiceViewAll;