import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router";
import * as moment from "moment";
import Modal from 'react-bootstrap/Modal';
import { browserHistory } from "react-router";
import { HistoryButton, AddButton ,GeneralButton} from "../../../../common/containers/button";
import CreateInvvoiceCustomer from "../../../../supply-chain/components/transactions/customerUpload/index";
import { CurrencyLabel, GridActionButton } from "../../../../common/inputs";
import BulkApprove from "../bulkApprove";
import BulkReject from "../bulkReject/index";
import BalanceView from "../../../../supply-chain/components/transactions/bulkApprove/balanceView";
import transaction_services from "../../../../supply-chain/services/transaction_service";
import auth from "../../../../../services/authService";
import EntityContext from "../../../../../context/entityContext";
import RoleChecker from "../../../../../utils/roleChecker";
import EditableField from '../../../../common/tables/EditableField';
import Table from '../../../components/table/index';
import NoAccess from "../../../widgets/noAccess";
import { FpButton } from "../../../../common/containers/button";
import JoditEditor from "jodit-react";
class CustomerTransactionList extends Component {


  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }


  state = {
    selectedTab: this.props.selectedTab ? this.props.selectedTab : "all",
    //selectedTab: null,
    ajaxRequest: false,
    selectedRows: [],
    categories: [],
    open: false,
    openCustomer: false,
    approvebuttonClass: "btn btn-success  hidden",
    declinebuttonClass: "btn btn-danger  hidden",
    bulkapprove: false,
    bulkreject: false,
    selected: {},
    roleType: "Purchase",
    tabs: [],
    hideCheckbox: false,
    productType: this.props.productType ? this.props.productType : "",
    showPopout: false,
    isModel : false,
    tempData : null,
    transactionId : null,
  };

  componentDidMount() {
    const { constants, sc_invoice_status } = auth.getCurrentUser();
    this.context.handleProductChange(constants.PRODUCTS.SUPPLYCHAIN);
    if (!this.props.selectedTab) {
      this.setState({ selectedTab: "all" })
    }
    if (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && (this.state.selectedTab == sc_invoice_status["awaiting_approval"]
      || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"] || this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open')) {
      this.setState({ hideCheckbox: false })
    } else {
      this.setState({ hideCheckbox: true })
    }
  }

  //----Go back to prospect on cancel click-------------
  handleCancel = () => {
    browserHistory.push("/client/home");
  };

  // Callback function to the Action Table.
  handleRowSelection = selectedRows => {
    this.setState({ selectedRows })
    let newSelected = {};
    selectedRows.map(item => {
      newSelected[item.key] = item.checked;
    })

    this.setState({ selected: newSelected });
    const { constants, sc_invoice_status } = auth.getCurrentUser();

    if (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && (this.state.selectedTab == sc_invoice_status["awaiting_approval"] ||
      this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"])) {
      this.setState({
        approvebuttonClass: "btn btn-success btn-sm ml-2 text-white ",
        declinebuttonClass: "btn btn-danger btn-sm mr-2",

      });
    }

  }
  onCloseModalApprove = () => {
    this.setState({ bulkapprove: false });
  };
  onCloseModalReject = () => {
    this.setState({ bulkreject: false });
  };
  BulkApprove = async () => {
    this.setState({ bulkapprove: true });
  };
  BulkReject = async () => {
    this.setState({ bulkreject: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onOpenModalCustomer = () => {

    this.setState({ openCustomer: true });
  };
  onCloseModalCustomer = () => {
    this.setState({ openCustomer: false });
  };

  handleRoleTypeChange = roleType => {
    this.setState({ roleType },
      () => { this.tableRef.current.reloadData(); }
    );
  }

  setTabs = tabs => {
    const { sc_invoice_status } = auth.getCurrentUser();
    if(this.context.specialProgramEnabledSCF)
      this.setState({ tabs: tabs.filter(item => (
        item.value !== sc_invoice_status.awaiting_supplier_update && 
        item.value !== sc_invoice_status.approved_for_payment
      ))});
    else
      this.setState({ tabs })
  }

  handleChange = (status) => {

    const { sc_invoice_status, constants } = auth.getCurrentUser();
    this.setState({ selectedTab: status }, () => {
      if (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && (this.state.selectedTab == sc_invoice_status["awaiting_approval"] ||
        this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"] || this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open')) {
        this.setState({ hideCheckbox: false })
      } else {
        this.setState({ hideCheckbox: true })
      }
    });
    this.setState({ selectedRows: [], selected: {} });
    this.tableRef.current.clearSelection();
  }

  handlePopoutClick = () => {
    this.setState({showPopout: true})
  };

  closePopout = () => {
    this.setState({showPopout: false})
  };
  

  render() {

    // Instance for Date parser utility function.
    // @params => service, key
    const { entityDetails } = this.context;
    const { TextField, DateField } = EditableField(transaction_services.getInvoice, 'a_guid');
    const { constants, sc_invoice_status } = auth.getCurrentUser();

    const cols = [
      {
        Header: "Reference Number",
        accessor: "inv_reference",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        show: !this.context.specialProgramEnabledSCF,
      },
      {
        Header: this.context.terminology.SUPPLIER && this.context.terminology.SUPPLIER,
        accessor: "supplier_name",
        headerStyle: { textAlign: "left" },
        sortable: false,
        minWidth: 100,
        show: (RoleChecker.check(entityDetails.products, constants.ROLES.CUSTOMER) && !this.context.specialProgramEnabledSCF) ? true : false,
      },
      {
        Header: "Status",
        accessor: "status_name",
        headerStyle: { textAlign: "left" },
        minWidth: 70,
        show: (this.state.selectedTab == "open" || this.state.selectedTab == "all" ) ? true : false,
      },
      {
        Header: "Invoice Number",
        accessor: "inv_number",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
      },
      {
        Header: "Amount",
        accessor: "inv_amount",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {<CurrencyLabel value={row.original.inv_amount}></CurrencyLabel>}</span>
      },
      {
        Header: "Date",
        accessor: "trans_date",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {moment(row.original.trans_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
      },
      {
        Header: "Due Date",
        accessor: "inv_due_date",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {moment(row.original.inv_due_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
      },
      {
        Header: "Early Payment Date",
        accessor: "inv_early_payment_date",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        show: !this.context.specialProgramEnabledSCF,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > { row.original.inv_early_payment_date ? moment(row.original.inv_early_payment_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ''}</span>
      },
      {
        Header: "Revised Due Date",
        accessor: "inv_revised_due_date",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        show: ( this.state.selectedTab == sc_invoice_status["rejected"] || this.state.selectedTab == sc_invoice_status["awaiting_approval"] ) ? false : true,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {row.original.inv_revised_due_date ? moment(row.original.inv_revised_due_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}</span>
      },
      {
        Header: "Finance Cost",
        accessor: "finance_cost",
        headerStyle: { textAlign: "left" },
        sortable: false,
        show: ((this.state.selectedTab == "open" || this.state.selectedTab == "all" || this.state.selectedTab == sc_invoice_status["closed_funds_collected"]) && !this.context.specialProgramEnabledSCF) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.finance_cost} /></span>
      },
      {
        Header: "MANUAL ADJUSTMENT",
        accessor: "inv_manual_overdue_adjustment",
        headerStyle: { textAlign: "left" },
        show: ((this.state.selectedTab == "open" || this.state.selectedTab == "all" || this.state.selectedTab == sc_invoice_status["closed_funds_collected"]) && !this.context.specialProgramEnabledSCF) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.inv_manual_overdue_adjustment} /></span>
      },
      {
        Header: "LATE PAYMENT FEE",
        accessor: "inv_late_payment_fee	",
        headerStyle: { textAlign: "left" },
        sortable: false,
        show: (this.state.selectedTab == "open" || this.state.selectedTab == "all" || this.state.selectedTab == sc_invoice_status["closed_funds_collected"] ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.inv_late_payment_fee} /></span>
      },
      {
        Header: "TOTAL DUE",
        accessor: "inv_total_due",
        headerStyle: { textAlign: "left" },
        show: (this.state.selectedTab == "open" || this.state.selectedTab == "all" || this.state.selectedTab == sc_invoice_status["closed_funds_collected"] ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.inv_total_due} /></span>
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        headerStyle: { textAlign: "left" },
        sortable: false,
        show: (this.state.selectedTab == "open" || this.state.selectedTab == "all" || this.state.selectedTab == sc_invoice_status["closed_funds_collected"] ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.amount_paid} /></span>
      },
      {
        Header: "DD Requested",
        accessor: "dd_requested",
        headerStyle: { textAlign: "left" },
        sortable: false,
        show: (this.state.selectedTab == "open" || this.state.selectedTab == "all" || this.state.selectedTab == sc_invoice_status["closed_funds_collected"] ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.dd_requested} /></span>
      },
      {
        Header: "Balance",
        accessor: "balance",
        headerStyle: { textAlign: "left" },
        sortable: false,
        show: (this.state.selectedTab == "open" || this.state.selectedTab == "all" || this.state.selectedTab == sc_invoice_status["closed_funds_collected"] ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.balance} /></span>
      },
      {
        Header: "Rejected By",
        accessor: "rejectedBy",
        headerStyle: { textAlign: "left" },
        show: ( this.state.selectedTab == sc_invoice_status["rejected"] ) ? true : false,
      },
      {
        Header: "Rejected Date",
        accessor: "rejectedTime",
        headerStyle: { textAlign: "left" },
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {moment(row.original.rejectedTime, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>,
        show: ( this.state.selectedTab == sc_invoice_status["rejected"] ) ? true : false,
      },
      {
        Header: "Action",
        accessor: "action",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        sortable: false,
        //show: (!RoleChecker.check(products, constants.ROLES.CUSTOMER)) ? true : false,
        Cell: row => {
          return (
            <>
              <Link className="action-icons" style={{ color: "grey" }} to={{ pathname: (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.SUPPLIER) || RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER)) ? `/client/scf/invoice/details` : `/scinvoice/details`, search: "?inv_guid=" + row.original.trans_guid + "&tab=" + this.state.selectedTab }} >
                <HistoryButton className="fa fa-file-text-o"></HistoryButton>
              </Link>
              {/* <Link className="action-icons" onClick={ async () => {
                  this.setState({ isModel: true });
                  this.setState({ transactionId: row.original.trans_guid });
                  const res = await transaction_services.getInvoiceDetails(row.original.trans_guid,false, null,true,false);
                  if(res){
                    this.setState({ tempData: res.data });
                  }
              }} label="Export" ><i className="fa fa-download" aria-hidden="true"></i></Link> */}
               
              
            </>
          ) 
        }
      }
    ];

    const redirectSettings = () => {
      browserHistory.push("/client/scf/bulk-upload");
    };
    const downloadReport = async () => {
      const res = await transaction_services.getInvoice({
        type: 'xlsx',
        roleType: "Purchase",
        product: constants.PRODUCTS.SUPPLYCHAIN,
        "open": true
      },this.state.selectedTab);
      const newWindow = window.open(res.data);
      newWindow.opener = null;
    }

    const RightPanel = () => (
      <>

        <div className="dropdown-menu" >
          <Link className="dropdown-item" to={"/client/scf/bulk-upload"}>Bulk Upload</Link>
          <Link className="dropdown-item" onClick={downloadReport} label="Export" >Export</Link>
        </div>
        <button title="More actions" className="btn btn-outline-secondary mr-2   pr-sm-3 pr-2 pl-lg-2  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More</button>
        {!this.context.specialProgramEnabledSCF && 
          <AddButton style="btn btn-primary " onClick={this.onOpenModalCustomer} label="Add New"></AddButton>
        }
      </>
    )
    const LeftPanel = () => (
      <>

        {((((this.state.selectedTab == sc_invoice_status['awaiting_approval'] || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"] || this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open')
          && RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && this.state.roleType == constants.BUSINESS_TYPE.PURCHASE)) && (this.state.selectedRows.find(rows => rows.checked == true) != undefined)) && (
            <>
              <div className="row my-3 rounded-lg mx-0 py-3 bg-light balance-box">
                {Object.keys(this.state.selected).length > 0 &&
                  RoleChecker.check(entityDetails.products, constants.ROLES.CUSTOMER) && (this.state.selectedTab == sc_invoice_status['awaiting_approval']
                    || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"]) ? (
                  <div className="col-sm-6 col-lg-8   ">
                    <BalanceView product={constants.PRODUCTS.SUPPLYCHAIN} invoices={this.state.selected} entity_id={entityDetails.a_guid} />
                  </div>
                ) : (
                  <div className="col-sm-6 col-lg-8   "></div>
                )}
                <div className="col-sm-12 col-lg-4 align-self-center text-lg-right">

                  <>

                    {(RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && this.state.roleType == constants.BUSINESS_TYPE.PURCHASE && (this.state.selectedTab == sc_invoice_status['awaiting_approval'] || this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"] || this.state.selectedTab == sc_invoice_status["approved_for_payment"])) && (
                      <GridActionButton extraHeight={true} class="btn btn-outline-danger ml-2 mr-2 pull-right" label="Reject" handleClick={() => this.BulkReject()}

                      />
                    )}
                    <FpButton
                      userType="customer"
                      label={(this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open') ? "Options" : "Options"}
                      smallLabel={(this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open') ? "Pay" : "Pay"}
                      className={"btn btn-outline-primary mr-2"}
                      onClick={

                        this.BulkApprove
                      }
                    ></FpButton>
                    {/* <GridActionButton label={(this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open') ? "Extend" : "Approve"} handleClick={this.BulkApprove} />*/}

                    {/* <AddButton style="btn btn-primary btn-sm ml-2" label="Approve" onClick={this.BulkApprove} ></AddButton> */}
                    {(this.state.selectedTab === "open" || this.state.selectedTab === "overdue") &&
                      <GridActionButton label="Settle Now"
                        extraHeight={true}
                        handleClick={() => {
                          let newSelected = [];
                          const checked = this.state.selectedRows.filter(item => item.checked === true).map(item => {
                            newSelected.push(item.trans_guid)
                          });
                          browserHistory.push({ pathname: '/client/scf/bulk-payment', state: { invoices: newSelected } });

                        }}
                        class="btn btn-outline-primary mr-2 ml-2 pull-right"
                      />
                    }
                  </>

                </div>
              </div>
            </>
          )
        }
      </>
    )
    const AdditionalFilter = () => (
      <>
        {

          (this.state.tabs.length > 0 && this.state.selectedTab != 'overdue') && (

            <div className="dropdown dash-dropdown">
              <button className="btn dropdown-toggle pr-sm-4 pr-3 p-2 w-100 text-left  btn-outline-secondary  dropdown-toggle  pr-sm-4 pr-3 pl-lg-2 w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.state.tabs.map(obj => <>{this.state.selectedTab == obj.value ? obj.label : ""}</>)}
              </button>

              <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                {this.state.tabs.map(obj => <a className={`dropdown-item ${this.state.selectedTab == obj.value ? 'active' : ''}`} onClick={e => this.handleChange(obj.value)} href="#" >{obj.label}</a>)}
              </div>

            </div>

          )

        }
      </>
    )


    return (
      <>
        <Helmet>
          <title>Invoice List | {global.config.title}</title>
        </Helmet>



        {
          RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) ? (
            <>

              <div className="card overflow-visible">
                <div className="card-header">
                  <div className="row">
                    <div className="col-lg-6"><h6>{this.state.selectedTab === 'overdue' ? 'Due / Overdue Invoices' : 'Invoices'}</h6></div>
                    <div className="col-lg-6">
                      <Link className="btn btn-light pull-right text-secondary ml-2" to={'/client/scf/dashboard'}><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
                      {this.state.showPopout === false &&(
                        <a className="btn btn-light pull-right text-secondary ml-2" onClick={this.handlePopoutClick} >
                          <i className="fa fa-expand"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-lg-12">
                    {this.state.showPopout === false ? (
                      <Table
                        ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
                        service={transaction_services.getInvoice}
                        searchService={transaction_services.searchTraderInvoice}
                        cols={cols}
                        defaultTab={this.state.selectedTab ? this.state.selectedTab : "all"}
                        onSelectTab={val => { this.setState({ selectedTab: val, selectedRows: [], selected: {} }, () => { this.handleChange() }); }}
                        uid={'trans_guid'}
                        onSelectRow={this.handleRowSelection}
                        reqParams={{ roleType: "Purchase", product_type: constants.PRODUCTS.SUPPLYCHAIN, "open": true }}
                        ibox={true}
                        headerTabs={true}
                        setTabs={this.setTabs}
                        checkbox={this.state.checkbox}
                        tableLeftPanel={<LeftPanel />}
                        tableRightPanel={<RightPanel />}
                        leftPanelColour={this.state.leftPanelColour}
                        hideCheckbox={this.state.hideCheckbox}
                        additionalFilter={<AdditionalFilter />}
                        getTrProps={(state, rowInfo) => {
                          if (rowInfo && rowInfo.row) {
                            return {
                              style: {
                                color: rowInfo.row._original.is_overdue == 1 && 'red'
                              }
                            }
                          } else {
                            return {}
                          }
                        }}
                        tabs={this.state.tabs.length > 0 ? this.state.tabs : false}
                    />
                    ):(
                      <>
                        <Modal className="fullscreen-modal" show={this.state.showPopout} onHide={this.closePopout}>
                          <Modal.Header closeButton>
                            <div className="d-block pt-3 text-center w-100 ml-3">
                              
                              <h3 className="modal-title">Invoices</h3>
                            
                            </div>
                          </Modal.Header>
                          <Modal.Body>
                          <Table
                            ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
                            service={transaction_services.getInvoice}
                            searchService={transaction_services.searchTraderInvoice}
                            cols={cols}
                            defaultTab={this.state.selectedTab ? this.state.selectedTab : "all"}
                            onSelectTab={val => { this.setState({ selectedTab: val, selectedRows: [], selected: {} }, () => { this.handleChange() }); }}
                            uid={'trans_guid'}
                            onSelectRow={this.handleRowSelection}
                            reqParams={{ roleType: "Purchase", product_type: constants.PRODUCTS.SUPPLYCHAIN, "open": true }}
                            ibox={true}
                            headerTabs={true}
                            setTabs={this.setTabs}
                            checkbox={this.state.checkbox}
                            tableLeftPanel={<LeftPanel />}
                            tableRightPanel={<RightPanel />}
                            leftPanelColour={this.state.leftPanelColour}
                            hideCheckbox={this.state.hideCheckbox}
                            additionalFilter={<AdditionalFilter />}
                            getTrProps={(state, rowInfo) => {
                              if (rowInfo && rowInfo.row) {
                                return {
                                  style: {
                                    color: rowInfo.row._original.is_overdue == 1 && 'red'
                                  }
                                }
                              } else {
                                return {}
                              }
                            }}
                            tabs={this.state.tabs.length > 0 ? this.state.tabs : false}
                        />
                          </Modal.Body>
                      </Modal>
                      </>
                    )}
                      
                    </div>
                  </div>
                </div>

              </div>


            </>
          ) : <NoAccess heading="Supply Chain Transactions" subHeading=""></NoAccess>
        }




        <Modal
          show={this.state.openCustomer}
          onHide={this.onCloseModalCustomer}
          backdrop="static"
          centered
        >

          <CreateInvvoiceCustomer
            a_guid={entityDetails.a_guid}
            page="common"
            onCloseModal={() => {
              this.onCloseModalCustomer();
              this.tableRef.current.reloadData();

            }}
          ></CreateInvvoiceCustomer>

        </Modal>
        <Modal
          show={this.state.isModel}
          size="lg" // Set the size to "lg" for large, "md" for medium, "sm" for small, or leave empty for default size
          onHide={() => {
            this.setState({ isModel: false });
          }}
        >
        <Modal.Header closeButton> {/* Add closeButton prop to display close button */}
          <Modal.Title>Remittance Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {this.state.tempData && (
            <JoditEditor value={this.state.tempData}
            onChange={(value) => {
              this.setState({ tempData: value });
            }}
          />
          )}
        </Modal.Body>
        <Modal.Footer>
        <GeneralButton
              onClick={ async () => {
                  window.frames.print_frame.document.body.innerHTML = this.state.tempData;
                  window.frames.print_frame.window.focus();
                  window.frames.print_frame.window.print();
              }}
              name={"Print"}
        ></GeneralButton>
        <AddButton style="btn btn-primary " 
        onClick={ async () => {
          this.setState({ isModel: false });
                  const resp = await transaction_services.getInvoiceDetails(this.state.transactionId,false, null,false,true,this.state.tempData);
                  if(resp){
                    const newWindow = window.open(resp.data);
                    newWindow.opener = null;
                  }
                  transaction_services.getInvoice();

              }} label="Download"></AddButton>
        </Modal.Footer>
       
      </Modal>
       {/* print remittance details */}
      <iframe
        name="print_frame"
        width="0"
        height="0"
        frameborder="0"
        src="about:blank"
      ></iframe>
      { this.state.bulkapprove &&
        <BulkApprove
          invoices={this.state.selected}
          selectedRows={this.state.selectedRows}
          onClose={this.onCloseModalApprove}
          onHandleReload={() => {
            this.onCloseModalApprove();
            this.tableRef.current.reloadData();
            this.tableRef.current.clearSelection();
            this.setState({ selectedRows: [], selected: {} });
          }}
          open={this.state.bulkapprove}
          trans_status={this.state.selectedTab}
          entity_id={entityDetails.a_guid}
          product={constants.PRODUCTS.SUPPLYCHAIN}
          type={(this.state.selectedTab == 'overdue' || this.state.selectedTab == 'open') ? "Extend further" : "Approve Invoices"}
        />
      }
        <BulkReject
          invoices={this.state.selected}
          onClose={this.onCloseModalReject}
          onHandleReload={() => {
            this.onCloseModalReject();
            this.tableRef.current.reloadData();
            this.tableRef.current.clearSelection();
            this.setState({ selectedRows: [], selected: {} });
          }}
          userType="customer"
          open={this.state.bulkreject}
          trans_status={this.state.selectedTab}
          product_type={constants.PRODUCTS.SUPPLYCHAIN}
        />

      </>
    );
  }
}
export default CustomerTransactionList;