import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { Picker } from '../common/inputs';

import coreService from "../../services/coreService";
import authService from "../../services/authService";
import useApi from '../../hooks/useApi';

function SupplierList({ onSelectItem, selectedSupplier = null, bp = null, defaultValue = { label: "Select Supplier (Secondary Account)", value: null },bpSelected = true }) {
    const { constants } = authService.getCurrentUser();
    const [selectedItem, setSelectedItem] = useState(defaultValue);
    //const { request, data, loading } = useApi(coreService.getSuppliersList); // Custom hook for HTTP Requests
    const { request, data, loading } = useApi(coreService.getSecondaryUsersList);
    const prevBp = useRef();

    useEffect(() => {

        if ((bp != null && bp !== prevBp.current) || (bpSelected == false && bp !== prevBp.current) ) {
            prevBp.current = bp;
            request({ category: constants.CATEGORY.SUPPLIER, bp }); // Calling HTTP service
        }

        if (selectedSupplier !== null && data.length > 0) {
            const supplierDetails = data.find(obj => obj.value == selectedSupplier);
            if (supplierDetails)
                setSelectedItem({ label: supplierDetails.label, value: supplierDetails.value })
        } else if (selectedSupplier === null)
            setSelectedItem(defaultValue);
    }, [bp, selectedSupplier, data]);

    return (
        <>
            <>
                {loading == true && <LoadingSpinner />}
                <Picker items={data}
                    selectedItem={selectedItem}
                    onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
                />
            </>
        </>
    )
};
export default SupplierList;