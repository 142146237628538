import React, { lazy, Suspense } from "react";
import { Route, Router, browserHistory, withRouter } from "react-router";
import LoadingLabel from "../components/common/loaders/loadingLabel";
/* Importing components from widgets module */
import widgetsRoutes from "../components/dashboardWidgets/config/routes";
import { createBrowserHistory } from "history";
/* Importing components from Supply chain module */
import supplyChainRoutes from "../components/supply-chain/config/routes";

/* Client Portal */
import ClientPortalLayout from "../components/client/layout/main";
import ClientPortalOcrLayout from "../components/client/layout/ocr";
import ClientPortalIFLayout from "../components/client/layout/if/index";
import ClientPortalSCFLayout from "../components/client/layout/scf";
import ClientPortalTFLayout from "../components/client/layout/tf";
import ClientPortalLoanLayout from "../components/client/layout/loan";
import ClientPortalWobLayout from "../components/client/layout/wob";
import CustomerProgramsLayout from "../components/client/layout/customer-programs";
import ClientEpProgramLayout from "../components/client/layout/epprogram";
import PublicInvoiceClientLayout from "../components/client/layout/public/publicInvoiceClientLayout";

import ClientPortalIfDashboard from "../components/client/pages/ifTransactions";
import ClientPortalTFDashboard from "../components/client/pages/tfTransactions";
import ClientPortalWobDashboard from "../components/client/pages/wobTransactions"
import ClientPortalSCFDashboard from "../components/client/pages/scTransactions";
import ClientPortalLoanDashboard from "../components/client/pages/loanTransaction/loanSnapshot";
import LoanRequestSettlement from "../components/client/pages/loanTransaction/requestSettlement";
import LoanStatement from "../components/client/pages/loanTransaction/loanStatement";
import CustomerProgramsDashboard from "../components/client/pages/customer-programs";

import ClientBatchUpload from "../components/client/pages/wobTransactions/clientBatchUpload";
import ClientWobInvoiceList from "../components/client/pages/wobTransactions/InvoicesList";
import DfTransactionStatement from "../components/client/pages/wobTransactions/transactionStatement";
import DfAppliedFeeList from "../components/client/pages/wobTransactions/dfAppliedFeeList";

import ClientPortalHome from "../components/client/pages/home";
import ClientScInvoices from "../components/client/pages/scTransactions/invoiceList/traderTransactionList";
import ClientGeneralTransView from "../components/client/components/generalTransaction/InvoiceViewAll";
import ClientUserNetworkSupplier from "../components/client/pages/network/supplier";
import ClientUserNetworkCustomer from "../components/client/pages/network/customer";
import ClientNetworkSupplierStart from "../components/client/pages/network/getStartedSupplier";
import ClientNetworkCustomerStart from "../components/client/pages/network/getStartedCustomer";
import ClientTerminologies from "../components/client/pages/clientTerminologies";
import ClientTerminologiesGetStarted from "../components/client/pages/clientTerminologies/getStarted";
import GetStartedGeneral from "../components/client/widgets/general/getStartedGeneral";
/* Tf Transactions */
import TfPurchaseInvoices from "../components/client/pages/tfTransactions/invoiceList/customerTransactionList";
import TfSalesInvoices from "../components/client/pages/tfTransactions/invoiceList/supplierTransactionList";
/* End Tf Transactions */

import PurchaseInvoiceList from "../components/client/pages/purchaseInvoices";
import ReconcileClientScf from "../components/client/pages/scTransactions/tradersManagement/payments/scfReconcile";
import ReconcileClientTf from "../components/client/pages/scTransactions/tradersManagement/payments/tfReconcile";

import SaleInvoiceList from "../components/client/pages/salesInvoices";
import BatchSaleInvoiceList from "../components/client/pages/batchSalesInvoices";
import BatchPurchaseInvoiceList from "../components/client/pages/batchPurchaseInvoices";

// import CustomerScInvoices from "../components/client/pages/scTransactions/invoiceList/customerTransactionList";
// import SupplierScInvoices from "../components/client/pages/scTransactions/invoiceList/supplierTransactionList";
import ClientIfInvoices from "../components/client/pages/ifTransactions/invoiceList";
import IFTransactionStatement from "../components/client/pages/ifTransactions/transactionStatement";
import ClientPortalManageContacts from "../components/client/pages/manageContacts";
import ClientTeamMembers from "../components/client/pages/teamMembers";
import ClientAccountingSoftware from "../components/client/widgets/accountingSoftware";
import ClientAccountingSoftwareTokenEndPoint from "../components/client/widgets/accountingSofwareTokenEndPoint";
import FinanceBusiness from "../components/client/pages/financeBusiness";
import CashFlow from "../components/client/pages/cashFlow";
import EpAddNew from "../components/client/pages/epProgramme/addNew"
import EpDashboard from "../components/client/pages/epProgramme/programView"
import EpAllList from "../components/client/pages/epProgramme/dashboard/allList"
import FinanceBusiness2 from "../components/client/pages/financeBusiness2";
import ClientBulkPayment from "../components/client/pages/clientBulkPayment";
import IFClientBulkPayment from "../components/client/pages/ifTransactions/bulkPayment";
import TfClientBulkPayment from "../components/client/pages/clientBulkPayment/tfBulkPayment";
import ClientSettings from "../components/client/pages/settings";
import OrgDetails from "../components/client/pages/settings/orgDetails";
import MappedUsers from "../components/client/pages/scTransactions/mappedUsers";
import TfMappedUsers from "../components/client/pages/tfTransactions/mappedUsers";
import MappedDebtors from "../components/client/pages/ifTransactions/mappedDebtorsList";
import WobMappedDebtors from "../components/client/pages/wobTransactions/mappedDebtorsList";
import ClientBulkUpload from "../components/client/pages/ifTransactions/bulkUpload";
import EditAccount from "../components/client/pages/account/editAccount";
import ChangePassword from "../components/client/pages/account/changePassword";
import SCFTransactionStatement from "../components/client/pages/scTransactions/transactionStatement";
import ClientBankDetails from "../components/client/widgets/bankDetails";
import GetStarted from "../components/client/widgets/getStarted";
// import ClientSideNotification from "../components/notifications/list";
import ScfClientSupplierBulkUpload from "../components/client/pages/scTransactions/scfSupplierBulkUpload";
import TfClientSupplierBulkUpload from "../components/client/pages/tfTransactions/tfSupplierBulkUpload";
import ClientReconsileInvoices from "../components/client/pages/batchTransactions/invoiceReconsile";

import ViewTemplates from "../components/viewTemplates/viewTemplates";
import MailSchedulerList from "../components/viewTemplates/mailSchedulerList";
import PaymentRemainderMailList from "../components/viewTemplates/paymentRemainderMailList";
import TfBatchDD from "../components/supply-chain/components/transactions/directDebit/tfBatchDirectDebit";
import ScBatchDD from "../components/supply-chain/components/transactions/directDebit/scBatchDirectDebit";
import TfBatchPendingDD from "../components/supply-chain/components/transactions/directDebit/tfBatchPendingDd";
import ScBatchPendingDD from "../components/supply-chain/components/transactions/directDebit/scBatchPendingDd";
import ManoovaIndex from "../components/supply-chain/components/transactions/directDebit/manoovaIndex";
import ManoovaTfIndex from "../components/supply-chain/components/transactions/directDebit/manoovaTfIndex";
import LoanDirectDebit from "../components/loan/components/directDebit/index";
/* Loan Client side start */
import LoanList from "../components/client/pages/loanTransaction";
import EpProgrammeDemo from "../components/client/pages/epProgrammeDemo";
import EpProgrammeDemoAddNew from "../components/client/pages/epProgrammeDemo/addNew";

/* Loan Client side end*/

import { ToastContainer } from "react-toastify";

/* Main Layout */
const Main = lazy(() => import("../components/common/layout/Main"));

/* Protected Route */
const ProtectedRoute = lazy(() =>
  import("../components/common/layout/protectedRoute")
);

/* Authentication module */
const Login = lazy(() => import("../components/authentication/loginForm"));
const EmailSubscription = lazy(() => import("../components/authentication/subscribe"));
const ClientLogin = lazy(() =>
  import("../components/client/pages/authentication/clientLogin")
);
const ClientRedirection = lazy(() =>
  import("../components/client/pages/authentication/redirection")
);
const ClientPasswordReset = lazy(() =>
  import("../components/client/pages/authentication/resetPassword")
);
const ClientPasswordRecovery = lazy(() =>
  import("../components/client/pages/authentication/accountRecovery")
);
const ResetPassword = lazy(() =>
  import("../components/authentication/resetPassword")
);
const AccountRecovery = lazy(() =>
  import("../components/authentication/verifyToken")
);
const ClientLogout = lazy(() =>
  import("../components/client/pages/authentication/clientLogout")
);
const Logout = lazy(() => import("../components/authentication/logout"));
const Profile = lazy(() => import("../components/profile/main"));
const Landing = lazy(() => import("../components/landing/index"));
const Welcome = lazy(() => import("../components/welcome"));
const WebsiteSignUp = lazy(() => import("../components/welcome/website_signup"));
const WebsiteSignUpTwo = lazy(() => import("../components/welcome/website-signup2"));
/* Dashboard */
const Dashboard = lazy(() => import("../components/dashboard"));
const OCR = lazy(() => import("../components/ocr/extractor/invoiceParser"));
const TrainOcr = lazy(() => import("../components/ocr/training"));
const PdfAnnotation = lazy(() => import("../components/ocr/pdfAnnotation"));
const NotificationDashboard = lazy(() => import("../components/dashboard/notification-dashboard"));
const MigrateTransactions = lazy(() => import("../components/streamMigration/transactions"));
const NotificationDashboardAll = lazy(() => import("../components/dashboard/notification-dashboard-all"));
const ArrearsDashboard = lazy(() => import("../components/dashboard/arrears/index"));
const TfArrearsDashboard = lazy(() => import("../components/dashboard/arrears/tfArrears"));
/* Tasks */
const Calendar = lazy(() => import("../components/tasks/main"));

/* Administrator */
const AdministratorMenu = lazy(() =>
  import("../components/admin/administratorMenu")
);
const Countries = lazy(() => import("../components/admin/countries/main"));
const ForexRate = lazy(() => import("../components/admin/countries/ForexRate"))
const ForexRateHistory = lazy(() => import("../components/admin/countries/ForexRatesHistory"))
const CreateCountry = lazy(() =>
  import("../components/admin/countries/createCountry")
);
const UserTypes = lazy(() => import("../components/admin/userTypes/main"));
const CreateUserType = lazy(() =>
  import("../components/admin/userTypes/createUserTypes")
);
const UserHeirarchy = lazy(() =>
  import("../components/admin/userHeirarchy/main")
);
const MailTemplates = lazy(() =>
  import("../components/admin/mailTemplates/quickMail")
);
// const MailTemplates = lazy(() =>
//   import("../components/admin/mailTemplates/main")
// );
const ExternalApis = lazy(() =>
  import("../components/admin/externalApis/main")
);
const CreateMailTemplate = lazy(() =>
  import("../components/admin/mailTemplates/createTemplate")
);
const MailSettings = lazy(() =>
  import("../components/admin/mailSettings/main")
);
const CreateMailSettings = lazy(() =>
  import("../components/admin/mailSettings/createMailSetting")
);
const DocumentTemplates = lazy(() =>
  import("../components/admin/templates/main")
);
const CreateTemplate = lazy(() =>
  import("../components/admin/templates/create")
);
const WidgetCreate = lazy(() =>
  import("../components/common/widgets/createWidget")
);

const LoginLogs = lazy(() =>
  import("../components/admin/loginLogs")
);

const MarketPlace = lazy(() =>
  import("../components/admin/marketPlace")
);

const MarketPlaceAdd = lazy(() =>
  import("../components/admin/marketPlace/addNew")
);

const subscribeList = lazy(() =>
  import("../components/admin/marketPlace/subscribe")
);

const subscribedUsersList = lazy(() =>
  import("../components/admin/marketPlace/subscribedUsersList")
);

const SpecialFeePayment = lazy(() =>
  import("../components/specialFee/receivePayment")
);

const SpecialFeeHistory = lazy(() =>
  import("../components/specialFee/history")
);

/* Transaction Settings */
const FeeSettings = lazy(() => import("../components/admin/fee/main"));
const CreateFee = lazy(() => import("../components/admin/fee/create"));
/* client alert top header */
const TopHeaderAlert = lazy(() =>
  import("../components/admin/topHeaderAlert/main")
);
const ManageTeams = lazy(() =>
  import("../components/admin/ManageTeams/index")
);

const ListLimitRequest = lazy(() =>
  import("../components/admin/limitRequest/index")
);

const ListFmbRequest = lazy(() =>
  import("../components/admin/fmbRequest/index")
);

/** Supply chain transactions */

const ScTransaction = lazy(() =>
  import(
    "../components/supply-chain/components/transactions/invoiceLists/bpTransactionList"
  )
);

const RequestLimit = lazy(() =>
  import(
    "../components/entities/home/widgets/requestLimit"
  )
);
const TfTransaction = lazy(() =>
  import(
    "../components/supply-chain/components/transactions/invoiceLists/tfTransactionList"
  )
);
const BpScTransactions = lazy(() =>
  import(
    "../components/supply-chain/components/transactions/invoiceLists/bpTransactions"
  )
);

//const ScTransaction = lazy(() => import("../components/supply-chain/components/transactions/list"));
const LinkedSupplier = lazy(() =>
  import("../components/entities/network/registeredCustomers")
);
/* Manage Prospects */
const Prospects = lazy(() => import("../components/prospects/list"));
const ProspectInvitaion = lazy(() =>
  import("../components/prospects/prospectInvitaion")
);
const ProspectLayout = lazy(() =>
  import("../components/prospects/layout/index")
);
const ProspectHome = lazy(() => import("../components/prospects/home"));
const ProspectSettings = lazy(() => import("../components/prospects/settings"));
const CreateProspectNew = lazy(() => import("../components/prospects/profile"));
const BulkUpload = lazy(() =>
  import("../components/prospects/bulkUpload/main")
);
const ProspectsMigration = lazy(() =>
  import("../components/prospects/prospectsMigration/main")
);
/* Manage Entities */
const EntityLayout = lazy(() => import("../components/entities/layout/index"));
const Entities = lazy(() => import("../components/entities/list"));
const Introducers = lazy(() => import("../components/introducers"));
const IntroducersContactList = lazy(() => import("../components/introducers/teamMembers"));
const Providers = lazy(() => import("../components/entities/forex/forexProviders"));
const MappedIntroducers = lazy(() =>
  import("../components/introducers/mappedIntroducers")
);
const CreateEntity = lazy(() => import("../components/entities/profile"));
const EntitySnapshot = lazy(() =>
  import("../components/entities/profile/entitySnapshot")
);
const EntityTeammembers = lazy(() =>
  import("../components/entities/entityTeamMembers")
);
const ProspectTeammembers = lazy(() =>
  import("../components/prospects/prospectTeamMembers")
);
const EntityHome = lazy(() => import("../components/entities/home"));
const EntitySettings = lazy(() => import("../components/entities/settings"));
const EntityCreationMultiStep = lazy(() =>
  import("../components/entities/multistep")
);
const EntityNewDraftAccount = lazy(() =>
  import("../components/entities/multistep/addDraftAccount")
);
const EntityBulkUpload = lazy(() =>
  import("../components/entities/bulkUpload/main")
);
const EntityUserNetwork = lazy(() => import("../components/entities/userNetwork"));
const EntityUserNetworkHeirarchy = lazy(() => import("../components/entities/userNetwork/networkHeirarchy"));

/* Manage Business Partners */
const BusinessPartnerLayout = lazy(() =>
  import("../components/businessPartners/layout")
);
const BusinessPartners = lazy(() =>
  import("../components/businessPartners/list")
);
const CreateBusinessPartner = lazy(() =>
  import("../components/businessPartners/createEntity")
);
const BusinessPartnerSnapshot = lazy(() =>
  import("../components/businessPartners/entitySnapshot")
);
const BPTeamMembers = lazy(() =>
  import("../components/businessPartners/teamMembers")
);

const BPIntroducers = lazy(() =>
  import("../components/businessPartners/introducers")
);

const IntroducerHome = lazy(() => import("../components/introducers/home"));
const PartnerSettings = lazy(() =>
  import("../components/businessPartners/settings")
);
const InvitationMain = lazy(() =>
  import("../components/businessPartners/invitationMain")
);
const EntityMain = lazy(() =>
  import("../components/businessPartners/entityMain")
);
const ListAccounts = lazy(() =>
  import("../components/introducers/introducersAccount")
);
const ListProspects = lazy(() =>
  import("../components/introducers/introducersProspect")
);
// const ListIntroducerProspect = lazy(() =>
//   import("../components/introducers/introducersProspect")
// );

const BPScfTransactions = lazy(() =>
  import("../components/businessPartners/facility/scfTransactionList")
);
const BPTfTransactions = lazy(() =>
  import("../components/businessPartners/facility/tfTransactionList")
);
const BPIfTransactions = lazy(() =>
  import("../components/businessPartners/facility/ifTransactionList")
);
const BPWobTransactions = lazy(() =>
  import("../components/businessPartners/facility/wobTransactionList")
);

const BPLoanList = lazy(() =>
  import("../components/businessPartners/facility/loanList")
);


/* Manage Introducers */

const IntroducerLayout = lazy(() => import("../components/introducers/layout/index"));
const IntroducerTeammembers = lazy(() =>
  import("../components/introducers/introducerTeammembers")
);
const IntroducersBulkUpload = lazy(() =>
  import("../components/introducers/bulkUpload/main")
);
const BulkUploadAttachment = lazy(() =>
  import("../components/bulkUploadAttachment/bulkUploadAttachment")
);

const SctransactionStatement = lazy(() =>
  import("../components/bulkUploadAttachment/TransactionStatement")
);
const prospectsAttachments = lazy(() =>
  import("../components/bulkUploadAttachment/prospectsAttachments")
);

const IntroducersMigration = lazy(() =>
  import("../components/introducers/introducersMigration/main")
);

// const AccumulatedFees = lazy(() =>
//   import("../components/accumulatedFees/accumulatedFees")
// );
/* End Introducers */

/* Country Office */
const CountryOfficeLayout = lazy(() =>
  import("../components/countryOffice/layout")
);
const CountryOffice = lazy(() => import("../components/countryOffice/main"));
const CreateCountryOffice = lazy(() =>
  import("../components/countryOffice/createEntity")
);
const CountryOfficeSnapshot = lazy(() =>
  import("../components/countryOffice/entitySnapshot")
);

/* Investment Partner Portal */
const InvestmentPartnerTransactions = lazy(() =>
  import("../components/investmentPartner/invoiceTransactions")
);
const InvestmentPartnerLoans = lazy(() =>
  import("../components/investmentPartner/loanTransactions")
);
//clientside team members
const ListClientTeamMembers = lazy(() =>
  import("../components/admin/teamMembers")
);

/* Mail Module (Old) */
const MailLayout = lazy(() => import("../components/mail/layout"));
const MailBox = lazy(() => import("../components/mail/mailbox"));
const ReadMail = lazy(() => import("../components/mail/readMail"));
const ComposeMail = lazy(() => import("../components/mail/compose"));

/* Gmail Module */
const GmailLayout = lazy(() => import("../components/gmail/layout"));
const GmailMessagesList = lazy(() =>
  import("../components/gmail/messagesList")
);
const GmailReadMail = lazy(() => import("../components/gmail/readMail"));
const GmailComposeMail = lazy(() => import("../components/gmail/compose-mail"));

/* Documents Module */
const Documents = lazy(() => import("../components/documents/main"));

/* Assessments Module */
const Assessments = lazy(() => import("../components/assessments/main"));
const Sections = lazy(() => import("../components/assessments/sections"));
const AssessmentAnswers = lazy(() =>
  import("../components/assessments/assessmentAnswers")
);
const Analysis = lazy(() =>
  import("../components/analysis/index")
);

/* Loans */
const Loans = lazy(() => import("../components/loans/main"));
const LoanDetail = lazy(() => import("../components/loans/detail"));
const NewLoan = lazy(() => import("../components/loans/newLoan"));
const LoanView = lazy(() => import("../components/loans/viewLoan"));
const EntityLoan = lazy(() => import("../components/loans/entityLoanList"));


const LoanLayout = lazy(() => import("../components/loan/layout/index"));
const LoanIndex = lazy(() => import("../components/loan/pages/loanIndex"));

/** Transactions */
const Transactions = lazy(() => import("../components/transactions/list"));
const TransactionSnapshot = lazy(() =>
  import("../components/transactions/transaction_snapshot")
);
const TransactionLog = lazy(() =>
  import("../components/auditTrail/transactionLog")
);
const BatchTransactionLog = lazy(() =>
  import("../components/auditTrail/dfTrandactionLog")
);
const TransactionSnapshotView = lazy(() =>
  import("../components/transactions_view/transaction_snapshot")
);
const NotesSnapshot = lazy(() => import("../components/prospects/noteViews"));
const InvoiceList = lazy(() =>
  import("../components/invTransactions/invoiceList")
);
const ScInvoiceList = lazy(() =>
  import("../components/supply-chain/components/invoices")
);
const SharedInvoiceList = lazy(() =>
  import("../components/supply-chain/components/invoices/sharedInvoices")
);
const TfSharedInvoiceList = lazy(() =>
  import("../components/supply-chain/components/invoices/tfSharedInvoices")
);
const IfSharedInvoiceList = lazy(() =>
  import("../components/invTransactions/ifSharedInvoices")
);
const IfParticipantInvoiceList = lazy(() =>
  import("../components/invTransactions/invoice/invoiceParticipations")
);
const TfInvoiceList = lazy(() =>
  import("../components/supply-chain/components/invoices/tfInvoices")
);
const InvTransactionSnapshot = lazy(() =>
  import("../components/invTransactions/snapshot")
);
const InvSharedTransactionSnapshot = lazy(() =>
  import("../components/invTransactions/sharedInvoices")
);
const InvParticipantTransactionSnapshot = lazy(() =>
  import("../components/invTransactions/invoice/participantInvoiceView")
);

/** New Transactions */
const InvTransactionsLayout = lazy(() =>
  import("../components/invTransactions/layout")
);
const InvSingleTransactionsLayout = lazy(() =>
  import("../components/invTransactions/layout/singleInvoice")
);

const InvTransactions = lazy(() =>
  import("../components/invTransactions/createTransaction")
);

const InvSingleTransactions = lazy(() =>
  import("../components/invTransactions/singleUpload")
);
const BulkPayment = lazy(() =>
  import("../components/invTransactions/invoice/bulkPayment")
);

/** DF Transactions */

const InvoicesList = lazy(() =>
  import("../components/debtorTransactions/pages/InvoicesList")
);
const BatchUpload = lazy(() =>
  import("../components/debtorTransactions/components/BatchUpload")
);

const WobAddInterest = lazy(() =>
  import("../components/debtorTransactions/pages/WobAddInterest")
);

/** General Transaction **/
/*const GeneralInvoicesList = lazy(() =>
  import("../components/generalTransactions/pages/InvoicesList")
);*/


/* Reports */
const TransactionsReport = lazy(() =>
  import("../components/reports/transactionsReport")
);
const TransReport = lazy(() =>
  import("../components/reports/transReport1/main")
);
const TransReport1 = lazy(() =>
  import("../components/reports/transReport/main")
);
const AgedReceivablesReport = lazy(() =>
  import("../components/reports/agedReceivablesReport")
);
const BusinessPartnerSalesReport = lazy(() =>
  import("../components/reports/businessPartnerSalesReport")
);
const BusinessPartnerBillingReport = lazy(() =>
  import("../components/reports/bpBilling/main")
);
const PerformanceReport = lazy(() =>
  import("../components/reports/bpPerformance/main")
);
const UsersReport = lazy(() => import("../components/reports/users/main"));
const ProspectsReport = lazy(() =>
  import("../components/reports/prospects/main")
);
const DueDaysReports = lazy(() =>
  import("../components/reports/dueDaysReports")
);
const InvestorAvailablityReport = lazy(() =>
  import("../components/reports/investorAvailablityReport")
);
const ContactReports = lazy(() =>
  import("../components/reports/contactReport")
);
const DebtorStatement = lazy(() =>
  import("../components/reports/debtorStatement")
);
const PointTimeReport = lazy(() =>
  import("../components/reports/pointTimeReport/main")
);
const IpBilling = lazy(() =>
  import("../components/reports/ipTransactions/main")
);
const EntityReports = lazy(() => import("../components/reports/entityReport"));
const TransactionReconciliationReport = lazy(() =>
  import("../components/reports/transactionReconciliation1/main")
);
const TransactionReconciliationReport1 = lazy(() =>
  import("../components/reports/transactionReconciliation/main")
);

const GeneralReportLayout = lazy(() =>
  import("../components/reports/layout/generalReport")
);

/** Invoice Finance Reports **/
const SIFReportsLayout = lazy(() =>
  import("../components/reports/layout/sifLayout")
);
const ScfClientrequest = lazy(() =>
  import("../components/reports/paymentRequest/ScfPaymentRequest")
);
const TfClientrequest = lazy(() =>
  import("../components/reports/paymentRequest/TfPaymentRequest")
);
const IFTransactionsReportAdvanced = lazy(() =>
  import("../components/reports/ifTransactions/transactionsReportAdvanced")
);
const ConsolidatedReport = lazy(() =>
  import("../components/reports/consolidatedReport")
);

/** Trade Finance Reports **/
const TFReportsLayout = lazy(() =>
  import("../components/reports/layout/tfLayout")
);
const IfClientTransactionsReport = lazy(() =>
  import("../components/client/pages/reports/ifTransactions/transactionsReport")
);
const IfTransactionsReport = lazy(() =>
  import("../components/reports/ifTransactions/transactionsReport")
);
const PointInTimeReport = lazy(() =>
  import("../components/reports/ifTransactions/pointTimeReport")
);
/** supply chain reports */
const SCFReportsLayout = lazy(() =>
  import("../components/reports/layout/scfLayout")
);
const ScTransactionReport = lazy(() =>
  import("../components/reports/scReports/index")
);
const ScInvoiceReport = lazy(() =>
  import("../components/reports/scReports/index")
);
const ScCustomerReport = lazy(() =>
  import("../components/reports/scCustomerReport")
);

/**bp billing reports */
const BpBillingReport = lazy(() =>
  import("../components/reports/scReports/bpBillingReports/index")
);

/** Debtor Finance Reports **/
const DfReportsLayout = lazy(() =>
  import("../components/reports/layout/dfLayout")
);


/** cron Log Reports **/
const CronLogLayout = lazy(() =>
  import("../components/reports/layout/cronLogLayout")
);

/* loan transaction report **/
const LoanReportsLayout = lazy(() =>
  import("../components/reports/layout/loanLayout")
);

const ScLayout = lazy(() =>
  import("../components/supply-chain/components/transactions/invoiceview/index")
);

const ScSharedTransactionsLayout = lazy(() =>
  import("../components/supply-chain/components/sharedTransactions/layout")
);

const TfSharedTransactionsLayout = lazy(() =>
  import("../components/supply-chain/components/sharedTransactions/tfLayout")
);

const ScSharedTransactionDetails = lazy(() =>
  import("../components/supply-chain/components/sharedTransactions")
);

const TfSharedTransactionDetails = lazy(() =>
  import("../components/supply-chain/components/sharedTransactions")
);

const TfLayout = lazy(() =>
  import("../components/supply-chain/components/transactions/invoiceview/tfInvoiceLayout")
);

const ScTransactionView = lazy(() =>
  import(
    "../components/supply-chain/components/transactions/invoiceview/invoiceDetail"
  )
);
const TfTransactionView = lazy(() =>
  import(
    "../components/supply-chain/components/transactions/invoiceview/tfInvoiceDetail"
  )
);
const ScTraderView = lazy(() =>
  import("../components/client/pages/scTransactions/invoiceView")
);

const TfTraderView = lazy(() =>
  import("../components/client/pages/tfTransactions/invoiceView")
);
const ScTraderViewDetail = lazy(() =>
  import("../components/client/pages/scTransactions/invoiceView/invoiceDetail")
);
const TfTraderViewDetail = lazy(() =>
  import("../components/client/pages/tfTransactions/invoiceView/invoiceDetail")
);
const ScTraderReport = lazy(() =>
  import("../components/client/pages/scReports/index")
);

const ClientInvoiceDetail = lazy(() =>
  import("../components/client/pages/ifTransactions/invoiceView/invoiceDetail")
);

const ClientScBulkUpload = lazy(() =>
  import("../components/client/pages/scTransactions/bulkUpload")
);

const GeneralTransactionBulkUpload = lazy(() =>
  import("../components/client/components/generalTransaction/bulkUpload")
);

const ClientTfBulkUpload = lazy(() =>
  import("../components/client/pages/scTransactions/bulkUpload/tf_bulk_upload_main")
);
const SupplierBulkUpload = lazy(() =>
  import("../components/client/components/generalTransaction/bulkUpload/supplier_bulk_upload_main")
);
const ScfSupplierBulkUpload = lazy(() =>
  import("../components/client/pages/scTransactions/bulkUpload/supplier_scf_bulk_upload_main")
);
const TfSupplierBulkUpload = lazy(() =>
  import("../components/client/pages/scTransactions/bulkUpload/supplier_tf_bulk_upload_main")
);

const ClientInvoicePage = lazy(() =>
  import("../components/client/pages/home/_invoiceList")
);

const NoFacilitiesGetStarted = lazy(() =>
  import("../components/client/widgets/general/noFacilitiesGetStarted")
);

/* Newsletters (Old) */
const NewsLetterTemplates = lazy(() =>
  import("../components/newsletters/newsLetterMain")
);
const NewsLetterCreate = lazy(() =>
  import("../components/newsletters/newsLetterCreate")
);

/* Manage Newsletters */
const NewsLetters = lazy(() => import("../components/news-letter/main"));
const CreateNewsLetters = lazy(() =>
  import("../components/news-letter/create")
);
const NLApproval = lazy(() => import("../components/news-letter/approval"));
const NLReview = lazy(() => import("../components/news-letter/review"));
const NLList = lazy(() => import("../components/news-letter/list"));
const NLPublish = lazy(() => import("../components/news-letter/publish"));
const NLBanners = lazy(() => import("../components/news-letter/banners"));
const NLHelp = lazy(() => import("../components/news-letter/help"));
const NLPublishQueue = lazy(() =>
  import("../components/news-letter/createPublishQueue")
);
const NLUnsubscribedList = lazy(() =>
  import("../components/news-letter/unsubscribeList")
);

/* Dashboard Widgets */
const WidgetMain = lazy(() => import("../components/common/widgets/main"));

const CreateAccountantClient = lazy(() =>
  import("../components/accountant/create")
);
const ListAccountantClients = lazy(() =>
  import("../components/accountant/list")
);
const AccountantClientsBulkUpload = lazy(() =>
  import("../components/accountant/clientBulkUpload")
);
const GoogleSettings = lazy(() =>
  import("../components/accountant/googleSettings")
);

/* Additional Fields */
const AdditionalFields = lazy(() =>
  import("../components/admin/additionalFields/main")
);

/* Wiki */
const WikiMenu = lazy(() => import("../components/wiki/wikiMenu"));
const CreateWiki = lazy(() => import("../components/wiki/wikiCreate"));
const ListWikiMenu = lazy(() => import("../components/wiki/listWikiMenu"));
const WikiTreeView = lazy(() => import("../components/wiki/wikiTreeView"));
const ListGCalendarEvents = lazy(() =>
  import("../components/googleCalendar/main")
);

/* Manage Debtors Pending Payments  */
const DebtorPaymentList = lazy(() =>
  import("../components/transactions/debtor_payment_list")
);

/* Test Component */
const Test = lazy(() => import("../components/common/test"));
const FpWallet = lazy(() => import("../components/_wallet"));
const FpWalletHome = lazy(() => import("../components/_walletHome"));

/* Client Onboarding */
const ClientOnboarding = lazy(() => import("../components/onboarding"));
const PublicInvoice = lazy(() => import("../components/publicInvoice/index"));
const TinyUrlComponent = lazy(() => import("../components/publicInvoice/tinyComponent"));
const UploadSecondaryAccounts = lazy(() => import("../components/entities/uploadSecondaryAccounts"));
const accountsAttachments = lazy(() => import("../components/bulkUploadAttachment/accountsAttachments"));

{
  /*---------------Notification List -----------------*/
}
const NotificationList = lazy(() => import("../components/notifications/list"));
const NotificationNew = lazy(() => import("../components/notifications"));
const NotificationView = lazy(() => import("../components/notifications/_notificationView"));
const NewNotifications = lazy(() => import("../components/dashboardNew/index"));
const allNotification = lazy(() =>
  import("../components/dashboardNew/AllNotifications")
);
const NotificationData = lazy(() => import("../components/dashboardNew/notifications/index"));
/* Secondary Account Details */
const SecondaryAccountLayout = lazy(() => import("../components/entities/secondary/_layout"));
const SecAccountHome = lazy(() => import("../components/entities/secondary/index"));

/* Messages */
const Messages = lazy(() => import("../components/fpMessages"));

const SCRates = lazy(() => import("../components/supply-chain/components/widgets/rates"));
const ClientRatesDemo = lazy(() => import("../components/new-rate/demoindex"));
const ClientRates = lazy(() => import("../components/new-rate/index"));
// const ClientRates = lazy(() => import("../components/rates"));
const BusinessContacts = lazy(() => import("../components/businessContacts/businessContacts"));

const directDebitList = lazy(() => import("../components/supply-chain/components/transactions/directDebit"));
const DdHistory = lazy(() => import("../components/client/pages/directDebit/history"));
const TfDirectDebitList = lazy(() => import("../components/supply-chain/components/transactions/directDebit/tfDirectDebit"));
const DdTfHistory = lazy(() => import("../components/client/pages/directDebit/tfHistory"));
const ScPayments = lazy(() => import("../components/supply-chain/components/invoices/payments"));
const TfPayments = lazy(() => import("../components/supply-chain/components/invoices/tfPayments"));
const TfTransactionStatement = lazy(() => import("../components/client/pages/tfTransactions/invoiceList/transactionStatement"));
const DirectDebitReport = lazy(() => import("../components/supply-chain/components/transactions/directDebit/directDebitReport"));
const AppSettings = lazy(() => import("../components/admin/appSettings"));
const RebateList = lazy(() => import("../components/client/pages/scTransactions/rebate"));
const TfRebateList = lazy(() => import("../components/client/pages/tfTransactions/rebate"));
const CustomerProgramInvoices = lazy(() => import("../components/client/pages/customer-programs/invoiceList"));
const CustomerProgramGeneralInvoices = lazy(() => import("../components/client/components/generalTransaction/generalTransList"));
const BBSWRate = lazy(() => import("../components/admin/bbswRate"));
const StreamDataLineMigration = lazy(() => import("../components/streamlineMigration/migrateTransactions"));
const InvoiceFinanceCalculator = lazy(() => import("../components/calculators/invoiceFinance"));
const DdForecastList = lazy(() => import("../components/client/pages/directDebit/directDebitForecast"));
const TfDdForecastList = lazy(() => import("../components/client/pages/directDebit/directDebitForecast"));

/* Client Portal Ocr */
const ListingPage = lazy(() => import("../components/client/pages/ocr/listingPage"));
const FraudCheckDashboard = lazy(() => import("../components/client/pages/ocr/dashboard"));

/* Apply Now New Page */
const ApplyNowLayout = lazy(() => import("../components/applynow/layout"));
const ApplyNowGetStarted = lazy(() => import("../components/applynow/getStarted"));
const ApplyNowStep1 = lazy(() => import("../components/applynow/step1"));
const ApplyNowStep2 = lazy(() => import("../components/applynow/step2"));
const ApplyNowStep3 = lazy(() => import("../components/applynow/step3"));
const ApplyNowStep4 = lazy(() => import("../components/applynow/step4"));
const ApplyNowStep5 = lazy(() => import("../components/applynow/step5"));
const ApplyNowThankYou = lazy(() => import("../components/applynow/thankyou"));

const ReceivableAcc = lazy(() =>
  import("../components/externalapis/components/manoovaReceivableAccounts")
);

const HubSpotSyncListing = lazy(() =>
  import("../components/hubspotSync")
);

const HubSpotSyncIntroducersListing = lazy(() =>
  import("../components/hubspotSync/introducersList")
);

/* ---------- Banking ---------- */
const BankingAuthGateway = lazy(() => import("../components/banking/pages/auth/authGateway"));
const BankingLayout = lazy(() => import("../components/banking/pages/layout/layout"));
const BankingDashboard = lazy(() => import("../components/banking/pages/dashboard/home"));
const BankingClientAccounts = lazy(() => import("../components/banking/pages/dashboard/clientAccounts"));
const BankingClientWallets = lazy(() => import("../components/banking/pages/dashboard/clientWallets"));
const BankingSuppliers = lazy(() => import("../components/banking/pages/dashboard/suppliers"));
const MoneyTransfer = lazy(() => import("../components/banking/pages/dashboard/moneyTransfer"));
const RequestApproval = lazy(() => import("../components/banking/pages/dashboard/transferRequest"));
const SupplierAccountBulkUpload = lazy(() => import("../components/banking/widgets/suppliers/bulkUpload/main"));
const TransactionReport = lazy(() => import("../components/banking/pages/dashboard/transactionReport"));

const PaymentsTodayReport = lazy(() => import("../components/banking/pages/paymentsReport/report"));
/* ----------------------------- */

export default (
  <Suspense fallback={<LoadingLabel></LoadingLabel>}>
    <Router history={browserHistory}>
      <ToastContainer />
      {/*------------- Client Onboarding -------------*/}
      <Route path="/onboarding" component={ClientOnboarding} />
      <Route path="/client/login" component={ClientLogin}></Route>
      <Route path="/client/redirect" component={ClientRedirection}></Route>
      <Route path="/client/reset-password" component={ClientPasswordReset}></Route>
      <Route path="/client/account-recovery" component={ClientPasswordRecovery}></Route>
      <Route path="/landing" component={Landing}></Route>
      <Route path="/welcome" component={Welcome}></Route>
      <Route path="/business-contacts/view" component={BusinessContacts}></Route>
      <Route path="/product/single-invoice-finance" component={InvoiceFinanceCalculator}></Route>

      {/*------------------Public Invoice Client Portal ------------------*/}
      <Route path="/client/" component={PublicInvoiceClientLayout} >
        <Route path="/client/tu/:param" component={TinyUrlComponent}></Route>
        <Route path="/client/public/view-invoice" component={PublicInvoice}></Route>
      </Route>

      {/*------------------ Client Portal ------------------*/}
      <Route path="/get-started" component={ApplyNowLayout}>
        <Route path="/get-started/init" component={ApplyNowGetStarted} />
        <Route path="/get-started/step1" component={ApplyNowStep1} />
        <Route path="/get-started/step2" component={ApplyNowStep2} />
        <Route path="/get-started/step3" component={ApplyNowStep3} />
        <Route path="/get-started/step4" component={ApplyNowStep4} />
        <Route path="/get-started/step5" component={ApplyNowStep5} />
        <Route path="/get-started/thank-you" component={ApplyNowThankYou} />
      </Route>


      {/* <Route path="/get-started" component={WebsiteSignUp}></Route>
      <Route path="/fast-track/apply" fastrack={true} component={WebsiteSignUpTwo}></Route>
      <Route path="/apply" fastrack={false} component={WebsiteSignUpTwo}></Route>
      <Route path="/thank-you" component={WebsiteSignUpTwo}></Route>
      <Route path="/thank-you-fast-track" component={WebsiteSignUpTwo}></Route> */}

      {/*---------------------------------------------*/}

      <Route
        path="/client/token-endpoint"
        component={ClientAccountingSoftwareTokenEndPoint}
      ></Route>
      {/*------------------ Client Portal ------------------*/}
      <Route path="/client" component={ClientPortalLayout}>

        {/*----------- IF Layout : Client Portal -----------*/}
        <Route path="/client/if/" component={ClientPortalIFLayout}>
          <ProtectedRoute
            exact
            path="/client/if/dashboard"
            component={ClientPortalIfDashboard}
          />

          <ProtectedRoute
            path="/client/if/bulk-upload"
            component={ClientBulkUpload}
          ></ProtectedRoute>

          <ProtectedRoute
            exact
            path="/client/if/sale-invoices"
            component={SaleInvoiceList}
          />

          <ProtectedRoute
            exact
            path="/client/if/purchase-invoices"
            component={PurchaseInvoiceList}
          />

          <ProtectedRoute
            path="/client/if/transaction-statement"
            component={IFTransactionStatement}
          ></ProtectedRoute>

          <ProtectedRoute
            path="/client/if/bulk-payment"
            component={IFClientBulkPayment}
          />

          <ProtectedRoute
            path="/client/if/mapped-debtors"
            component={MappedDebtors}
          ></ProtectedRoute>

          <ProtectedRoute
            path="/client/if/invoice-details"
            component={ClientInvoiceDetail}
          ></ProtectedRoute>

        </Route>
        {/*-----------------------------------------------*/}
        <Route path="/client/ep-programme" component={ClientEpProgramLayout}>

          <ProtectedRoute
            path="/client/ep-programme"
            component={EpDashboard}>
          </ProtectedRoute>

          <ProtectedRoute
            path="/client/ep-programme/add-new"
            component={EpAddNew}>
          </ProtectedRoute>
          <ProtectedRoute
            path="/client/ep-programme/allProgram"
            component={EpAllList}>
          </ProtectedRoute>

          {/* ---------------------- Add new Ep program ---------------------- */}
          <ProtectedRoute path="/client/ep-programme/add-new/step1" exact component={EpAddNew} />
          <ProtectedRoute path="/client/ep-programme/add-new/step2" exact component={EpAddNew} />
          <ProtectedRoute path="/client/ep-programme/add-new/step3" exact component={EpAddNew} />
          <ProtectedRoute path="/client/ep-programme/add-new/step4" exact component={EpAddNew} />
          <ProtectedRoute path="/client/ep-programme/add-new/step5" exact component={EpAddNew} />
          <ProtectedRoute path="/client/ep-programme/add-new/step6" exact component={EpAddNew} />
          {/* ------------------------------------------------------------- */}


        </Route>
        {/*----------- OCR Layout : Client Portal -----------*/}
        <Route path="/client/ocr/" component={ClientPortalOcrLayout}>
          <ProtectedRoute
            exact
            path="/client/ocr/documents-list"
            component={ListingPage}
          />

          <ProtectedRoute
            exact
            path="/client/ocr/dashboard"
            component={FraudCheckDashboard}
          />
        </Route>
        {/*-----------------------------------------------*/}

        {/*--------- Customer SCF/TF Programs Layout : Client Portal ----------*/}
        <Route path="/client/customer-programs/" component={CustomerProgramsLayout}>
          <ProtectedRoute
            exact
            path="/client/customer-programs/dashboard"
            component={CustomerProgramsDashboard}
          />
          <ProtectedRoute
            exact
            path="/client/customer-programs/sale-invoices"
            component={CustomerProgramInvoices}
          />
          <ProtectedRoute
            path="/client/customer-programs/supplier-bulk-upload"
            exact
            component={SupplierBulkUpload}
          />

          <ProtectedRoute path="/client/customer-programs/supplier-bulk-upload/step1" exact component={SupplierBulkUpload} />
          <ProtectedRoute path="/client/customer-programs/supplier-bulk-upload/step2" exact component={SupplierBulkUpload} />
          <ProtectedRoute path="/client/customer-programs/supplier-bulk-upload/step3" exact component={SupplierBulkUpload} />
          <ProtectedRoute path="/client/customer-programs/supplier-bulk-upload/step4" exact component={SupplierBulkUpload} />
          <ProtectedRoute path="/client/customer-programs/supplier-bulk-upload/step5" exact component={SupplierBulkUpload} />
          <ProtectedRoute path="/client/customer-programs/supplier-bulk-upload/step6" exact component={SupplierBulkUpload} />
          <ProtectedRoute path="/client/customer-programs/supplier-bulk-upload/step7" exact component={SupplierBulkUpload} />



        </Route>
        {/*--------------------------------------------------------------------*/}
        <ProtectedRoute
          exact
          path="/client/customer-programs/general-invoice/list"
          component={CustomerProgramGeneralInvoices}
        />
        {/*--------- SCF Layout : Client Portal ----------*/}
        <Route path="/client/scf/" component={ClientPortalSCFLayout}>

          <ProtectedRoute
            exact
            path="/client/scf/dashboard"
            component={ClientPortalSCFDashboard}
          />

          <ProtectedRoute
            exact
            path="/client/scf/sale-invoices"
            component={SaleInvoiceList}
          />

          <ProtectedRoute
            exact
            path="/client/scf/purchase-invoices"
            component={PurchaseInvoiceList}
          />

          <ProtectedRoute
            path="/client/scf/direct-debit"
            component={DdHistory}
          />

          <ProtectedRoute
            path="/client/scf/bulk-payment"
            component={ClientBulkPayment}
          />

          <ProtectedRoute
            path="/client/scf/supplier-bulk-upload"
            component={ScfClientSupplierBulkUpload}
          />

          <ProtectedRoute
            path="/client/scf/mapped-users"
            component={MappedUsers}
          />
          <ProtectedRoute
            path="/client/scf/rebates"
            component={RebateList}
          />
          <ProtectedRoute
            path="/client/scf/transaction-statement"
            component={SCFTransactionStatement}
          />
          <ProtectedRoute
            path="/client/scf/direct-debit-list"
            component={DdForecastList}
          />
          {/* ---------------------- SCF Bulk Upload ---------------------- */}
          <ProtectedRoute
            path="/client/scf/bulk-upload"
            exact
            component={ClientScBulkUpload}
          />

          <ProtectedRoute path="/client/scf/bulk-upload/step1" exact component={ClientScBulkUpload} />
          <ProtectedRoute path="/client/scf/bulk-upload/step2" exact component={ClientScBulkUpload} />
          <ProtectedRoute path="/client/scf/bulk-upload/step3" exact component={ClientScBulkUpload} />
          <ProtectedRoute path="/client/scf/bulk-upload/step4" exact component={ClientScBulkUpload} />
          <ProtectedRoute path="/client/scf/bulk-upload/step5" exact component={ClientScBulkUpload} />
          <ProtectedRoute path="/client/scf/bulk-upload/step6" exact component={ClientScBulkUpload} />
          <ProtectedRoute path="/client/scf/bulk-upload/step7" exact component={ClientScBulkUpload} />
          {/* ------------------------------------------------------------- */}

          <ProtectedRoute
            path="/client/supplier-scf/bulk-upload"
            component={ScfSupplierBulkUpload}
          />

          <ProtectedRoute path="/client/supplier-scf/bulk-upload/step1" exact component={ScfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-scf/bulk-upload/step2" exact component={ScfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-scf/bulk-upload/step3" exact component={ScfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-scf/bulk-upload/step4" exact component={ScfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-scf/bulk-upload/step5" exact component={ScfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-scf/bulk-upload/step6" exact component={ScfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-scf/bulk-upload/step7" exact component={ScfSupplierBulkUpload} />

          <Route path="/client/scf/invoice" component={ScTraderView}>
            <ProtectedRoute
              exact
              path="/client/scf/invoice/details"
              component={ScTraderViewDetail}
            />
          </Route>
          <ProtectedRoute
            exact
            path="/client/scf/purchase-invoices/reconcileSCF"
            component={ReconcileClientScf}
          />
        </Route>
        {/*-----------------------------------------------*/}

        {/* ---------------------- General Transaction Bulk Upload ---------------------- */}
        <ProtectedRoute
          path="/client/general-transaction/bulk-upload"
          exact
          component={GeneralTransactionBulkUpload}
        />
        <ProtectedRoute path="/client/general-transaction/bulk-upload/step1" exact component={GeneralTransactionBulkUpload} />
        <ProtectedRoute path="/client/general-transaction/bulk-upload/step2" exact component={GeneralTransactionBulkUpload} />
        <ProtectedRoute path="/client/general-transaction/bulk-upload/step3" exact component={GeneralTransactionBulkUpload} />
        <ProtectedRoute path="/client/general-transaction/bulk-upload/step4" exact component={GeneralTransactionBulkUpload} />
        <ProtectedRoute path="/client/general-transaction/bulk-upload/step5" exact component={GeneralTransactionBulkUpload} />
        <ProtectedRoute path="/client/general-transaction/bulk-upload/step6" exact component={GeneralTransactionBulkUpload} />
        <ProtectedRoute path="/client/general-transaction/bulk-upload/step7" exact component={GeneralTransactionBulkUpload} />
        {/* ----------------------------------------------------------------------------- */}

        {/*---------- TF Layout : Client Portal ----------*/}
        <Route path="/client/tf/" component={ClientPortalTFLayout}>
          <ProtectedRoute
            exact
            path="/client/tf/dashboard"
            component={ClientPortalTFDashboard}
          />
          <ProtectedRoute
            exact
            path="/client/tf/sale-invoices"
            component={SaleInvoiceList}
          />
          <ProtectedRoute
            exact
            path="/client/tf/purchase-invoices"
            component={PurchaseInvoiceList}
          />
          
          <ProtectedRoute
            path="/client/tf/direct-debit"
            component={DdTfHistory}
          />
          <ProtectedRoute
            path="/client/tf/bulk-payment"
            component={TfClientBulkPayment}
          />

          <ProtectedRoute
            path="/client/tf/bulk-upload"
            exact
            component={ClientTfBulkUpload}
          />
          <ProtectedRoute
            path="/client/tf/direct-debit-list"
            component={TfDdForecastList}
          />

          <ProtectedRoute path="/client/tf/bulk-upload/step1" exact component={ClientTfBulkUpload} />
          <ProtectedRoute path="/client/tf/bulk-upload/step2" exact component={ClientTfBulkUpload} />
          <ProtectedRoute path="/client/tf/bulk-upload/step3" exact component={ClientTfBulkUpload} />
          <ProtectedRoute path="/client/tf/bulk-upload/step4" exact component={ClientTfBulkUpload} />
          <ProtectedRoute path="/client/tf/bulk-upload/step5" exact component={ClientTfBulkUpload} />
          <ProtectedRoute path="/client/tf/bulk-upload/step6" exact component={ClientTfBulkUpload} />
          <ProtectedRoute path="/client/tf/bulk-upload/step7" exact component={ClientTfBulkUpload} />

          <ProtectedRoute
            path="/client/supplier-tf/bulk-upload"
            component={TfSupplierBulkUpload}
          />

          <ProtectedRoute path="/client/supplier-tf/bulk-upload/step1" exact component={TfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-tf/bulk-upload/step2" exact component={TfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-tf/bulk-upload/step3" exact component={TfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-tf/bulk-upload/step4" exact component={TfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-tf/bulk-upload/step5" exact component={TfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-tf/bulk-upload/step6" exact component={TfSupplierBulkUpload} />
          <ProtectedRoute path="/client/supplier-tf/bulk-upload/step7" exact component={TfSupplierBulkUpload} />
          <Route path="/client/tf/invoice" component={TfTraderView}>
            <ProtectedRoute
              exact
              path="/client/tf/invoice/details"
              component={TfTraderViewDetail}
            />
          </Route>
          <ProtectedRoute
            path="/client/tf/transaction-statement"
            component={TfTransactionStatement}
          />
          <ProtectedRoute
            path="/client/tf/mapped-users"
            component={TfMappedUsers}
          />
          <ProtectedRoute
            path="/client/tf/rebates"
            component={TfRebateList}
          />
          <ProtectedRoute
            path="/client/tf/supplier-bulk-upload"
            component={TfClientSupplierBulkUpload}
          />

          <ProtectedRoute
            exact
            path="/client/tf/purchase-invoices/reconcileTF"
            component={ReconcileClientTf}
          />

        </Route>
        {/*-----------------------------------------------*/}

        {/*--------- Loan Layout : Client Portal ---------*/}
        <Route path="/client/loan/" component={ClientPortalLoanLayout}>
          <ProtectedRoute
            exact
            path="/client/loan/dashboard"
            component={ClientPortalLoanDashboard}
          />
          <ProtectedRoute
            exact
            path="/client/loan/request-settlement"
            component={LoanRequestSettlement}
          />
          <ProtectedRoute
            exact
            path="/client/loan/statement"
            component={LoanStatement}
          />
        </Route>
        {/*-----------------------------------------------*/}

        {/*---------- WOB Layout : Client Portal ----------*/}
        <Route path="/client/wob/" component={ClientPortalWobLayout}>
          <ProtectedRoute
            exact
            path="/client/wob/dashboard"
            component={ClientPortalWobDashboard}
          />
          <ProtectedRoute
            path="/client/wob/batch-upload"
            component={ClientBatchUpload}
          ></ProtectedRoute>

          <ProtectedRoute
            path="/client/wob/sale-invoices"
            component={ClientWobInvoiceList}
          ></ProtectedRoute>

          <ProtectedRoute
            path="/client/wob/transaction-statement"
            component={DfTransactionStatement}
          ></ProtectedRoute>

          <ProtectedRoute
            path="/client/wob/applied-fee-list"
            component={DfAppliedFeeList}
          ></ProtectedRoute>

          <ProtectedRoute
            path="/client/wob/mapped-debtors"
            component={WobMappedDebtors}
          ></ProtectedRoute>
        </Route>
        {/*------------------------------------------------*/}

        <ProtectedRoute
          path="/client/home"
          exact
          component={ClientPortalHome}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/home/AR"
          exact
          component={ClientPortalHome}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/home/AP"
          exact
          component={ClientPortalHome}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/home/APAR"
          exact
          component={ClientPortalHome}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/invoice"
          exact
          component={ClientInvoicePage}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/get-started"
          component={GetStarted}>
        </ProtectedRoute>

        <ProtectedRoute
          path="/client/home/setup"
          component={GetStarted}>
        </ProtectedRoute>

        <ProtectedRoute
          path="/client/client-terminologies"
          component={ClientTerminologies}>
        </ProtectedRoute>

        <ProtectedRoute
          path="/client/client-terminologies/get-started"
          component={ClientTerminologiesGetStarted}>
        </ProtectedRoute>

        <ProtectedRoute
          path="/client/scinvoices"
          component={ClientScInvoices}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/tf-purchase-invoices"
          component={TfPurchaseInvoices}
        />

        <ProtectedRoute
          path="/client/tf-sales-invoices"
          component={TfSalesInvoices}
        />

        <ProtectedRoute
          path="/client/batch-sale-invoices"
          component={BatchSaleInvoiceList}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/batch-purchase-invoices"
          component={BatchPurchaseInvoiceList}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/screport"
          component={ScTraderReport}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/manage-contacts"
          component={ClientPortalManageContacts}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/team-members"
          component={ClientTeamMembers}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/client/accounting-software"
          component={ClientAccountingSoftware}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/client/bank-details"
          component={ClientBankDetails}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/finance-business"
          component={FinanceBusiness}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/client/cash-flow"
          component={CashFlow}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/finance-business2"
          component={FinanceBusiness2}>
        </ProtectedRoute>

        <ProtectedRoute
          path="/client/settings"
          component={ClientSettings}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/settings/organization-details"
          component={OrgDetails}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/general-trans/view-all"
          exact
          component={ClientGeneralTransView}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/users-network/supplier"
          exact
          component={ClientUserNetworkSupplier}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/client/users-network/customer"
          exact
          component={ClientUserNetworkCustomer}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/client/users-network/start-suppliers"
          exact
          component={ClientNetworkSupplierStart}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/client/users-network/start-customers"
          exact
          component={ClientNetworkCustomerStart}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/client/general/get-started"
          exact
          component={GetStartedGeneral}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/start-facility-setup"
          component={NoFacilitiesGetStarted}
        ></ProtectedRoute>



        {/* <Route path="/client/scinvoice" component={ScTraderView}>
          <ProtectedRoute
            exact
            path="/client/scinvoice/details"
            component={ScTraderViewDetail}
          />
        </Route>
       
        <Route path="/client/tf-invoice" component={TfTraderView}>
          <ProtectedRoute
            exact
            path="/client/tf-invoice/details"
            component={TfTraderViewDetail}
          />
        </Route>
      */}

        <ProtectedRoute
          path="/client/reports/iftransactions"
          component={IfClientTransactionsReport}
        ></ProtectedRoute>



        <ProtectedRoute
          path="/client/reconsile"
          component={ClientReconsileInvoices}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/profile"
          component={EditAccount}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/changepassword"
          component={ChangePassword}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/loan-list"
          exact
          component={LoanList}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/client/client-side-notifications"
          exact
          component={NotificationNew/*NotificationList*/}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/client/notification/view"
          exact
          component={NotificationView}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/ep-programme-demo"
          component={EpProgrammeDemo}
        ></ProtectedRoute>

        <ProtectedRoute
          path="/client/ep-programme-demo/add-new"
          component={EpProgrammeDemoAddNew}
        ></ProtectedRoute>

      </Route>

      <Route path="/" component={Main}>
        {widgetsRoutes}
        {supplyChainRoutes}
        <ProtectedRoute
          exact
          path="/administrator/sc-rates"
          component={SCRates}
        />
        <ProtectedRoute
          exact
          path="/administrator/client-rates"
          component={ClientRates}
        />

        {/** ===================== Authentication ========================= */}
        <Route path="test" component={Test}></Route>
        <Route path="login" component={Login}></Route>
        <Route path="reset-password" component={ResetPassword}></Route>
        <Route path="logout" component={Logout}></Route>
        <Route path="/client/logout" component={ClientLogout}></Route>
        <Route path="/subscribe" component={EmailSubscription}></Route>
        <Route path="account-recovery" component={AccountRecovery}></Route>
        <ProtectedRoute path="/profile" component={Profile} />
        <ProtectedRoute path="/password" component={Profile} />
        {/** ===================== Dashboard ============================== */}
        <ProtectedRoute path="dashboard" component={Dashboard} />
        <ProtectedRoute path="ocr" component={OCR} />
        <ProtectedRoute path="ocr/train" component={TrainOcr} />
        <ProtectedRoute path="ocr/pdf-annotation" component={PdfAnnotation} />
        <ProtectedRoute path="/co/dashboard" component={Dashboard} />
        <ProtectedRoute path="dashboard/demo" component={NotificationDashboard} />
        <ProtectedRoute path="dashboard/demo/all" component={NotificationDashboardAll} />
        <ProtectedRoute path="dashboard/ar-dashboard" component={ArrearsDashboard} />
        <ProtectedRoute path="dashboard/tf-ar-dashboard" component={TfArrearsDashboard} />
        <ProtectedRoute path="dashboard/fp-wallet" component={FpWallet} />
        <ProtectedRoute path="dashboard/fp-wallet-home" component={FpWalletHome} />

        <ProtectedRoute path="dashboard/banking" component={BankingAuthGateway} />
        <ProtectedRoute path="dashboard/banking/auth" component={BankingAuthGateway} />

        <ProtectedRoute path="dashboard/payments-today" component={PaymentsTodayReport} />
        <Route path="/dashboard/banking/" component={BankingLayout}>
          
          <ProtectedRoute path="/dashboard/banking/home" component={BankingDashboard} />
          <ProtectedRoute path="/dashboard/banking/home/funding-today" defaultPage={'funding-today'} component={BankingDashboard} />
          <ProtectedRoute path="/dashboard/banking/home/payments/request/:customer" defaultPage={'request-supplier-transfer'} component={BankingDashboard} />
          <ProtectedRoute path="/dashboard/banking/home/awaiting-approval" defaultPage={'awaiting-approval'} component={BankingDashboard} />

          <ProtectedRoute path="/dashboard/banking/accounts" component={BankingClientAccounts} />
          <ProtectedRoute path="/dashboard/banking/wallets" component={BankingClientWallets} />
          <ProtectedRoute path="/dashboard/banking/suppliers" component={BankingSuppliers} />  
          <ProtectedRoute path="/dashboard/banking/money-transfer" component={MoneyTransfer} />
          <ProtectedRoute path="/dashboard/banking/suppliers/bulk-upload" component={SupplierAccountBulkUpload} />
          <ProtectedRoute path="/dashboard/banking/report" component={TransactionReport} />
        </Route>

        <ProtectedRoute path="streamline-data-migration/transactions" component={StreamDataLineMigration} />
        <ProtectedRoute path="calendar" component={Calendar} />
        <ProtectedRoute path="messages" component={Messages} />
        <ProtectedRoute path="/documents" component={Documents} />
        <ProtectedRoute path="dashboard/new" component={NewNotifications} />
        <ProtectedRoute path="allNotifications" component={allNotification} />
        <ProtectedRoute path="dashboard/new/Details" component={NotificationData} />
        <ProtectedRoute path="dashboard/tf-dd-batch-report" component={TfBatchDD} />
        <ProtectedRoute path="dashboard/sc-dd-batch-report" component={ScBatchDD} />
        <ProtectedRoute path="dashboard/tf-dd-batch-pending-report" component={TfBatchPendingDD} />
        <ProtectedRoute path="dashboard/sc-dd-batch-pending-report" component={ScBatchPendingDD} />
        <ProtectedRoute path="dashboard/manoova-payment" component={ManoovaIndex} />
        <ProtectedRoute path="dashboard/tf-manoova-payment" component={ManoovaTfIndex} />
        <ProtectedRoute path="payments/bank-account-receivables" component={ReceivableAcc} />
        <ProtectedRoute
          path="/google-calendar/events"
          component={ListGCalendarEvents}
        />
        <ProtectedRoute path="dashboard/migrate-transactions" component={MigrateTransactions} />

        <ProtectedRoute
          path="loan/direct-debit"
          component={LoanDirectDebit}
        />

        <ProtectedRoute
          path="hubspot/list"
          component={HubSpotSyncListing}
        />
        <ProtectedRoute
          path="hubspot/introducers"
          component={HubSpotSyncIntroducersListing}
        />
        {/** ===================== Administrator Module ============================== */}
        <Route path="/administrator" component={AdministratorMenu}>
          <ProtectedRoute
            path="/administrator/countries"
            component={Countries}
          />
          <ProtectedRoute
            path="/administrator/forex-rates"
            component={ForexRate}
          />
          <ProtectedRoute
            path="/administrator/forex-rates-history"
            component={ForexRateHistory}
          />
          <ProtectedRoute
            exact
            path="/business-partner/invitations"
            component={InvitationMain}
          />

          <ProtectedRoute
            path="/administrator/countries/create"
            component={CreateCountry}
          />
          <ProtectedRoute
            exact
            path="/administrator/countries/edit"
            component={CreateCountry}
          />
          <ProtectedRoute
            path="/administrator/user-types"
            component={UserTypes}
          />
          <ProtectedRoute
            exact
            path="/administrator/user-types/create"
            component={CreateUserType}
          />
          <ProtectedRoute
            exact
            path="/administrator/user-types/edit"
            component={CreateUserType}
          />
          <ProtectedRoute
            path="/administrator/user-heirarchy"
            component={UserHeirarchy}
          />
          <ProtectedRoute
            path="/bp-market-place"
            component={ExternalApis}
          />
          <ProtectedRoute
            path="/administrator/mail-templates"
            component={MailTemplates}
          />
          <ProtectedRoute
            path="/administrator/mail-templates/create"
            component={CreateMailTemplate}
          />
          <ProtectedRoute
            path="/administrator/mail-templates/edit"
            component={CreateMailTemplate}
          />
          <ProtectedRoute
            path="/administrator/mail-settings"
            component={MailSettings}
          />
          <ProtectedRoute
            exact
            path="/administrator/mail-settings/create"
            component={CreateMailSettings}
          />
          <ProtectedRoute
            exact
            path="/administrator/mail-settings/edit"
            component={CreateMailSettings}
          />

          <ProtectedRoute
            exact
            path="/administrator/document-templates"
            component={DocumentTemplates}
          />

          <ProtectedRoute
            exact
            path="/administrator/document-templates/create"
            component={CreateTemplate}
          />

          <ProtectedRoute
            exact
            path="/administrator/document-templates/edit"
            component={CreateTemplate}
          />

          <ProtectedRoute
            exact
            path="/administrator/widgets"
            component={WidgetMain}
          />
          <ProtectedRoute
            exact
            path="/administrator/widgets/create"
            component={WidgetCreate}
          />

          <ProtectedRoute
            exact
            path="/administrator/widgets/edit"
            component={WidgetCreate}
          />

          <ProtectedRoute
            path="/administrator/transaction/fee"
            component={FeeSettings}
          />
          <ProtectedRoute
            path="/administrator/transaction/fee/create"
            component={CreateFee}
          />
          <ProtectedRoute
            path="/administrator/transaction/fee/edit"
            component={CreateFee}
          />

          <ProtectedRoute
            path="/administrator/login-logs"
            component={LoginLogs}
          />
          <ProtectedRoute
            path="/administrator/list-teammembers"
            component={ListClientTeamMembers}
          ></ProtectedRoute>

          <ProtectedRoute
            path="/administrator/market-place"
            component={MarketPlace}
          />

          <ProtectedRoute
            path="/administrator/market-place/add-new"
            component={MarketPlaceAdd}
          />

          <ProtectedRoute
            path="/administrator/market-place/edit"
            component={MarketPlaceAdd}
          />

          <ProtectedRoute
            path="/administrator/market-place/subscribe"
            component={subscribeList}
          />

          <ProtectedRoute
            path="/administrator/market-place/show-subscribed-users"
            component={subscribedUsersList}
          />


          {/** ==================NewsLetter messages ============== */}

          <ProtectedRoute
            path="/newsletter/manage-newsletter"
            component={NewsLetterTemplates}
          />

          <ProtectedRoute
            path="/newsletter/create-newsletter"
            component={NewsLetterCreate}
          />
          <ProtectedRoute
            path="/newsletter/edit-newsletter"
            component={NewsLetterCreate}
          />

          {/** ===================== Assessments Module ============================== */}
          <ProtectedRoute
            exact
            path="/administrator/assessments"
            component={Assessments}
          />
          <ProtectedRoute
            exact
            path="/administrator/assessments/sections"
            component={Sections}
          />

          <ProtectedRoute
            exact
            path="/administrator/client-alert"
            component={TopHeaderAlert}
          />
          <ProtectedRoute
            exact
            path="/administrator/team-members"
            component={ManageTeams}
          />
          <ProtectedRoute
            exact
            path="/administrator/limit-request"
            component={ListLimitRequest}
          />
          <ProtectedRoute
            exact
            path="/administrator/fmb-request"
            component={ListFmbRequest}
          />
          <ProtectedRoute
            exact
            path="/administrator/bbsw-rate"
            component={BBSWRate}
          />

          {/** ===================== Facilty Special fee ============================== */}
          <ProtectedRoute
            exact
            path="/administrator/special-fee/receive-payment"
            component={SpecialFeePayment}
          />

          <ProtectedRoute
            exact
            path="/administrator/special-fee/history"
            component={SpecialFeeHistory}
          />

        </Route>

        {/** ===================== Prospect Management ============================== */}
        <Route path="/prospects/details/" component={ProspectLayout}>
          <ProtectedRoute
            exact
            path="/prospects/details/home"
            component={ProspectHome}
          />
          <ProtectedRoute
            exact
            path="/prospects/details/settings"
            component={ProspectSettings}
          />
          <ProtectedRoute
            exact
            path="/prospects/details/profile"
            component={CreateProspectNew}
          />
          <ProtectedRoute
            exact
            path="/prospects/details/teammembers"
            component={ProspectTeammembers}
          />
          {/** ===================== Task Calendar ================================== */}
          <ProtectedRoute
            path="/prospects/details/calendar"
            component={Calendar}
          />
          {/** ===================== Mail Module ============================== */}
          <Route path="/prospects/details/mail" component={MailLayout}>
            <ProtectedRoute
              exact
              path="/prospects/details/mail/inbox"
              component={MailBox}
            />
            <ProtectedRoute
              exact
              path="/prospects/details/mail/read"
              component={ReadMail}
            />
            <ProtectedRoute
              exact
              path="/prospects/details/mail/compose"
              component={ComposeMail}
            />
          </Route>

          {/** ===================== GMail Module ============================== */}
          <Route path="/prospects/details/gmail" component={GmailLayout}>
            <ProtectedRoute
              path="/prospects/details/gmail/messages"
              component={GmailMessagesList}
            />
            <ProtectedRoute
              path="/prospects/details/gmail/read-mail"
              component={GmailReadMail}
            />
            <ProtectedRoute
              path="/prospects/details/gmail/compose-mail"
              component={GmailComposeMail}
            />
          </Route>

          {/** ===================== Documents Module ============================== */}
          <ProtectedRoute
            path="/prospects/details/documents"
            component={Documents}
          />
        </Route>

        <ProtectedRoute exact path="/prospects" component={Prospects} />
        <ProtectedRoute
          exact
          path="/prospects/invitations"
          component={ProspectInvitaion}
        />
        <ProtectedRoute
          exact
          path="/prospects/create"
          component={CreateProspectNew}
        />
        <ProtectedRoute
          exact
          path="/prospects/bulk-upload"
          component={BulkUpload}
        />
        <ProtectedRoute
          exact
          path="/prospects/migration"
          component={ProspectsMigration}
        />
        <ProtectedRoute
          exact
          path="/prospects/bulk-upload-attachments"
          component={prospectsAttachments}
        />
        <ProtectedRoute exact path="/introductions" component={Prospects} />
        <ProtectedRoute
          exact
          path="/introductions/invitations"
          component={ProspectInvitaion}
        />
        <ProtectedRoute
          exact
          path="/introductions/create"
          component={CreateProspectNew}
        />
        <ProtectedRoute
          exact
          path="/introductions/bulk-upload"
          component={BulkUpload}
        />

        {/** ===================== Entity Management ============================== */}
        <Route path="/entities/details/" component={EntityLayout}>
          <ProtectedRoute
            exact
            path="/entities/details/home"
            component={EntityHome}
          />
          <ProtectedRoute
            exact
            path="/entities/details/snapshot"
            component={EntitySnapshot}
          />
          <ProtectedRoute
            exact
            path="/entities/details/teammembers"
            component={EntityTeammembers}
          />
          <ProtectedRoute
            exact
            path="/entities/details/profile"
            component={CreateEntity}
          />
          <ProtectedRoute
            exact
            path="/entities/details/sctransactions"
            component={ScTransaction}
          />
          <ProtectedRoute
            exact
            path="/entities/details/user-network"
            component={EntityUserNetwork}
          />
          <ProtectedRoute
            exact
            path="/entities/details/user-network-heirarchy"
            component={EntityUserNetworkHeirarchy}
          />
          <ProtectedRoute
            exact
            path="/entities/details/tftransactions"
            component={TfTransaction}
          />
          <ProtectedRoute
            exact
            path="/entities/details/request-limit"
            component={RequestLimit}
          />
          {/*
          <ProtectedRoute
            exact
            path="/entities/details/linkedSuppliers"
            component={LinkedSupplier}
          />
          <ProtectedRoute
            exact
            path="/entities/details/settings"
            component={EntitySettings}
          />
          {/*---------------New Transaction Module-----------------*/}
          <Route
            path="/entities/details/inv-transactions"
            component={InvTransactionsLayout}
          >
            <ProtectedRoute
              exact
              path="/entities/details/inv-transactions/create-transaction"
              component={InvTransactions} // {InvTransactions}
            />
          </Route>

          {/*---------------New Single Transaction Module-----------------*/}
          <Route
            path="/entities/details/inv-single-transactions"
            component={InvSingleTransactionsLayout}
          >
            <ProtectedRoute
              exact
              path="/entities/details/inv-single-transactions/create-transaction"
              component={InvSingleTransactions} // {InvTransactions}
            />
          </Route>

          {/** ===================== Mail Module ============================== */}
          <Route path="/entities/details/mail" component={MailLayout}>
            <ProtectedRoute
              exact
              path="/entities/details/mail/inbox"
              component={MailBox}
            />
            <ProtectedRoute
              exact
              path="/entities/details/mail/read"
              component={ReadMail}
            />
            <ProtectedRoute
              exact
              path="/entities/details/mail/compose"
              component={ComposeMail}
            />
          </Route>

          {/** ===================== GMail Module ============================== */}
          <Route path="/entities/details/gmail" component={GmailLayout}>
            <ProtectedRoute
              path="/entities/details/gmail/messages"
              component={GmailMessagesList}
            />
            <ProtectedRoute
              path="/entities/details/gmail/read-mail"
              component={GmailReadMail}
            />
            <ProtectedRoute
              path="/entities/details/gmail/compose-mail"
              component={GmailComposeMail}
            />
          </Route>

          {/** ===================== Documents Module ============================== */}
          <ProtectedRoute
            path="/entities/details/documents"
            component={Documents}
          />
          {/** ===================== Assessment Answers ============================= */}
          <ProtectedRoute
            path="/entities/details/assessment"
            component={AssessmentAnswers}
          />
          {/** ===================== Asnalysis ============================= */}
          <ProtectedRoute
            path="/entities/details/analysis"
            component={Analysis}
          />
          {/** ===================== Task Calendar ================================== */}
          <ProtectedRoute
            path="/entities/details/calendar"
            component={Calendar}
          />

          {/** ===================== DF Transactions Module ================================== */}

          <ProtectedRoute
            path="/entities/details/debtorfinances"
            component={InvoicesList}
            exact
          />
          <ProtectedRoute
            exact
            path="/entities/details/debtorfinances/create-transaction"
            component={BatchUpload}
          />

          <ProtectedRoute
            path="/entities/wob/add-interest"
            component={WobAddInterest}
            exact
          />

          {/** ===================== Transactions Module ================================== */}

          <ProtectedRoute
            path="/entities/details/transactions"
            component={Transactions}
            exact
          />

          <ProtectedRoute
            path="/entities/details/ifinvoices"
            component={InvoiceList}
            exact
          />

          <ProtectedRoute
            path="/entities/details/notes"
            component={NotesSnapshot}
            exact
          />



          {/** ===================== Loan Module ================================== */}

          <ProtectedRoute
            path="/entities/details/loans"
            component={EntityLoan}
          />

          <ProtectedRoute path="/loans/newLoan" component={NewLoan} />

          <ProtectedRoute
            path="/entities/details/loan"
            component={LoanLayout}
          />

          <ProtectedRoute
            path="/loan/loan-view"
            component={LoanLayout}
          />



          {/** ===================== Genaral Transactions Module ================================== */}

          {/* <ProtectedRoute
            path="/entities/details/genaral-transaction"
            component={GeneralInvoicesList}
            exact
          /> */}
        </Route>

        {/** ===================== Secondary Account Single Page ============================== */}
        <Route path="/seconadry-account/details/" component={SecondaryAccountLayout}>
          <ProtectedRoute
            path="/seconadry-account/details/home"
            component={SecAccountHome}
            exact
          />
        </Route>

        {/** ===================== DF Transactions Module ================================== */}


        <ProtectedRoute path="/loans/viewLoan" component={LoanView} />



        {/** ===================== List all transactions ================================== */}

        <ProtectedRoute
          path="/transactions/details/snapshot"
          component={InvTransactionSnapshot}
          exact
        />

        <ProtectedRoute
          path="/transactions/shared_details/snapshot"
          component={InvSharedTransactionSnapshot}
          exact
        />
        <ProtectedRoute
          path="/transactions/participant_invoice_details/snapshot"
          component={InvParticipantTransactionSnapshot}
          exact
        />
        <ProtectedRoute path="/transactions" component={Transactions} exact />
        <ProtectedRoute
          path="/entities/details/transactions/snapshot"
          component={TransactionSnapshot}
          exact
        />
        <ProtectedRoute
          path="/entities/details/transactions/audit-report"
          component={TransactionLog}
          exact
        />
        <ProtectedRoute
          path="/batchTtransactions/audit-report"
          component={BatchTransactionLog}
          exact
        />
        <Route
          path="/entities/details/transactions/view/snapshot"
          component={TransactionSnapshotView}
          exact
        />
        <ProtectedRoute exact path="/entities" component={Entities} />
        <ProtectedRoute exact path="/introducer" component={Introducers} />
        <ProtectedRoute exact path="/introducer/contact-list" component={IntroducersContactList} />
        <ProtectedRoute exact path="/providers" component={Providers} />
        <ProtectedRoute
          exact
          path="/introducer/create"
          component={CreateBusinessPartner}
        />
        <ProtectedRoute
          exact
          path="/country-office/create"
          component={CreateCountryOffice}
        />
        <ProtectedRoute
          exact
          path="/mapped-introducers"
          component={MappedIntroducers}
        />

        <ProtectedRoute
          exact
          path="/introducer/migration"
          component={IntroducersMigration}
        />

        <ProtectedRoute
          exact
          path="/entities/create"
          component={CreateEntity}
        />
        <ProtectedRoute
          exact
          path="/entities/bulk-upload"
          component={EntityBulkUpload}
        />
        <ProtectedRoute
          exact
          path="/introducer/bulk-upload"
          component={IntroducersBulkUpload}
        />
        <ProtectedRoute
          exact
          path="/entities/multi-step"
          component={EntityCreationMultiStep}
        />
        <ProtectedRoute
          exact
          path="/entities/add-draft-account"
          component={EntityNewDraftAccount}
        />

        {/** =====================Country Office Module ============================== */}
        <ProtectedRoute
          exact
          path="/country-office"
          component={CountryOffice}
        />

        <Route path="/country-office/details/" component={CountryOfficeLayout}>
          <ProtectedRoute
            exact
            path="/country-office/details/snapshot"
            component={CountryOfficeSnapshot}
          />

          <ProtectedRoute
            exact
            path="/country-office/details/profile"
            component={CreateCountryOffice}
          />

          <ProtectedRoute
            exact
            path="/country-office/details/teammembers"
            component={EntityTeammembers}
          />

          <ProtectedRoute
            exact
            path="/country-office/entities-listing"
            component={EntityMain}
          />

          {/** ===================== GMail Module ============================== */}
          <Route path="/country-office/details/gmail" component={GmailLayout}>
            <ProtectedRoute
              path="/country-office/details/gmail/messages"
              component={GmailMessagesList}
            />
            <ProtectedRoute
              path="/country-office/details/gmail/read-mail"
              component={GmailReadMail}
            />
            <ProtectedRoute
              path="/country-office/details/gmail/compose-mail"
              component={GmailComposeMail}
            />
          </Route>

          {/** ===================== Documents Module ============================== */}
          <ProtectedRoute
            path="/country-office/details/documents"
            component={Documents}
          />
        </Route>

        {/** ===================== Business Partners Module ============================== */}
        <Route
          path="/business-partner/details/"
          component={BusinessPartnerLayout}
        >
          <ProtectedRoute
            exact
            path="/business-partner/details/snapshot"
            component={BusinessPartnerSnapshot}
          />

          <ProtectedRoute
            exact
            path="/business-partner/details/profile"
            component={CreateBusinessPartner}
          />

          <ProtectedRoute
            exact
            path="/business-partner/entities-listing"
            component={EntityMain}
          />

          <ProtectedRoute
            path="/business-partner/details/bp-transactions"
            component={Transactions}
            exact
          />

          <ProtectedRoute
            path="/business-partner/details/loans"
            component={EntityLoan}
            exact
          />

          <ProtectedRoute
            path="/business-partner/details/team-members"
            component={BPTeamMembers}
            exact
          />

          <ProtectedRoute
            path="/business-partner/details/introducers"
            component={BPIntroducers}
            exact
          />
          <ProtectedRoute
            path="/business-partner/details/scf-transactions"
            component={BPScfTransactions}
            exact
          />

          <ProtectedRoute
            path="/business-partner/details/tf-transactions"
            component={BPTfTransactions}
            exact
          />
          <ProtectedRoute
            path="/business-partner/details/ifinvoices"
            component={BPIfTransactions}
            exact
          />
          <ProtectedRoute
            path="/business-partner/details/debtorfinances"
            component={BPWobTransactions}
            exact
          />
          <ProtectedRoute
            path="/business-partner/details/loan"
            component={BPLoanList}
            exact
          />

          {/** ===================== Mail Module ============================== */}
          <Route path="/business-partner/details/mail" component={MailLayout}>
            <ProtectedRoute
              exact
              path="/business-partner/details/mail/inbox"
              component={MailBox}
            />
            <ProtectedRoute
              exact
              path="/business-partner/details/mail/read"
              component={ReadMail}
            />
            <ProtectedRoute
              exact
              path="/business-partner/details/mail/compose"
              component={ComposeMail}
            />
          </Route>

          {/** ===================== GMail Module ============================== */}
          <Route path="/business-partner/details/gmail" component={GmailLayout}>
            <ProtectedRoute
              path="/business-partner/details/gmail/messages"
              component={GmailMessagesList}
            />
            <ProtectedRoute
              path="/business-partner/details/gmail/read-mail"
              component={GmailReadMail}
            />
            <ProtectedRoute
              path="/business-partner/details/gmail/compose-mail"
              component={GmailComposeMail}
            />
          </Route>

          {/** ===================== Documents Module ============================== */}
          <ProtectedRoute
            path="/business-partner/details/documents"
            component={Documents}
          />
          <ProtectedRoute
            path="/business-partner/details/prospect"
            component={Prospects}
          />
          <ProtectedRoute
            path="/business-partner/details/accounts"
            component={Entities}
          />
          <ProtectedRoute
            path="/business-partner/details/sctransactions"
            component={BpScTransactions}
          />
          <ProtectedRoute
            path="/business-partner/details/settings"
            component={PartnerSettings}
          />
        </Route>
        {/** ===================== End Business Partners Module ============================== */}
        {/** ================================ Introducer Module ============================== */}
        <Route path="/introducer/details/" component={IntroducerLayout}>
          <ProtectedRoute
            exact
            path="/introducer/details/home"
            component={IntroducerHome}
          />

          <ProtectedRoute
            exact
            path="/introducer/details/profile"
            component={CreateBusinessPartner}
          />

          <ProtectedRoute
            exact
            path="/introducer/details/accounts"
            component={ListAccounts}
          />
          <ProtectedRoute
            exact
            path="/introducer/details/prospects"
            component={ListProspects}
          />
          <ProtectedRoute
            exact
            path="/Introducer/details/teammembers"
            component={IntroducerTeammembers}
          />

          {/* <ProtectedRoute
            exact
            path="/introducer/details/introducersProspect"
            component={ListIntroducerProspect}
          />
          <ProtectedRoute
            exact
            path="/introducer/details/introducersAccount"
            component={ListIntroducerAccount}
          /> */}
        </Route>
        <ProtectedRoute
          exact
          path="/bulkupload-attachments"
          component={BulkUploadAttachment}
        />
        <ProtectedRoute
          exact
          path="/transaction-statement"
          component={SctransactionStatement}
        />
        {/* <ProtectedRoute
          exact
          path="/accumulated-fees"
          component={AccumulatedFees}
        /> */}
        {/** ============================ End Introducer Module ============================== */}
        <ProtectedRoute
          exact
          path="/business-partner"
          component={BusinessPartners}
        />
        <ProtectedRoute
          exact
          path="/business-partner/create"
          component={CreateBusinessPartner}
        />
        <ProtectedRoute
          exact
          path="/business-partner/edit"
          component={CreateBusinessPartner}
        />
        {/** ===================== Loans ============================== */}
        <ProtectedRoute path="/loans" component={Loans} />
        <ProtectedRoute path="/loans/details" component={LoanDetail} />

        {/** ===================== Reports ============================ */}
        <ProtectedRoute
          path="/reports/sif-reports"
          component={SIFReportsLayout}
        />
        <ProtectedRoute
          path="/reports/scf-clientrequest"
          component={ScfClientrequest}
        />
        <ProtectedRoute
          path="/reports/tf-clientrequest"
          component={TfClientrequest}
        />
        <ProtectedRoute
          path="/reports/if-report-advanced"
          component={IFTransactionsReportAdvanced}
        />
        <ProtectedRoute
          path="/reports/consolidated-report"
          component={ConsolidatedReport}
        />

        <ProtectedRoute
          path="/reports/if-transactions-report"
          component={IfTransactionsReport}
        />
        <ProtectedRoute
          path="/reports/point-time-report"
          component={PointInTimeReport}
        />
        <ProtectedRoute
          path="/reports/transactions-report"
          component={TransactionsReport}
        />
        <ProtectedRoute
          path="/reports/scf-reports"
          component={SCFReportsLayout}
        />
        <ProtectedRoute
          path="/reports/sc-invoice-report"
          component={ScInvoiceReport}
        />
        <ProtectedRoute
          path="/reports/sc-transactions-report"
          component={ScTransactionReport}
        />
        <ProtectedRoute
          path="/reports/tf-transactions-report"
          component={TFReportsLayout}
        />
        <ProtectedRoute
          path="/reports/bp-billing-report"
          component={BpBillingReport}
        />
        <ProtectedRoute
          path="/reports/sc-customer-report"
          component={ScCustomerReport}
        />
        <ProtectedRoute path="/reports/trans-report" component={TransReport} />
        <ProtectedRoute
          path="/reports/trans-report1"
          component={TransReport1}
        />
        <ProtectedRoute
          path="/reports/aged-receivables-report"
          component={AgedReceivablesReport}
        />
        <ProtectedRoute
          path="/reports/business-partner-sales-report"
          component={BusinessPartnerSalesReport}
        />
        <ProtectedRoute
          path="/reports/business-partner-billing-report"
          component={BusinessPartnerBillingReport}
        />
        <ProtectedRoute
          path="/reports/business-partner-performance"
          component={PerformanceReport}
        />
        <ProtectedRoute path="/reports/users-report" component={UsersReport} />
        <ProtectedRoute
          path="/reports/prospects-report"
          component={ProspectsReport}
        />
        <ProtectedRoute
          path="/reports/due-days-report"
          component={DueDaysReports}
        />
        <ProtectedRoute
          path="/reports/investor-availability-report"
          component={InvestorAvailablityReport}
        />
        <ProtectedRoute
          path="/reports/contact-report"
          component={ContactReports}
        />
        <ProtectedRoute
          path="/reports/debtor-statement-report"
          component={DebtorStatement}
        />
        <ProtectedRoute
          path="/reports/point-time-report"
          component={PointTimeReport}
        />
        <ProtectedRoute
          path="/reports/entity-report"
          component={EntityReports}
        />
        <ProtectedRoute
          path="/reports/ip-billing-report"
          component={IpBilling}
        />
        <ProtectedRoute
          path="/reports/transaction-reconciliation"
          component={TransactionReconciliationReport}
        />
        <ProtectedRoute
          path="/reports/transaction-reconciliation1"
          component={TransactionReconciliationReport1}
        />

        <ProtectedRoute
          path="/reports/df-reports"
          component={DfReportsLayout}
        />
        <ProtectedRoute
          path="/reports/cron-log-summary"
          component={CronLogLayout}
        />

        <ProtectedRoute
          path="/reports/loan-reports"
          component={LoanReportsLayout}
        />

        <ProtectedRoute
          path="/reports/general-report"
          component={GeneralReportLayout}
        />

        {/***************** Loan **************** */}
        <ProtectedRoute path="/loan" component={LoanIndex} exact />


        {/***************** Batch Invoice**************** */}
        <ProtectedRoute path="/batch-invoice" component={InvoicesList} exact />

        {/***************** INVOICE LIST**************** */}
        <ProtectedRoute path="/invoices" component={InvoiceList} exact />

        <ProtectedRoute path="/upload-secondary-accounts" component={UploadSecondaryAccounts} exact />
        <ProtectedRoute path="/upload-secondary-accounts/step1" component={UploadSecondaryAccounts} exact />
        <ProtectedRoute path="/upload-secondary-accounts/step2" component={UploadSecondaryAccounts} exact />
        <ProtectedRoute path="/upload-secondary-accounts/step3" component={UploadSecondaryAccounts} exact />
        <ProtectedRoute path="/upload-secondary-accounts/step4" component={UploadSecondaryAccounts} exact />

        <ProtectedRoute path="/bulk-upload-accounts" component={accountsAttachments} exact />

        <ProtectedRoute
          exact
          path="/iftransaction/payments"
          component={BulkPayment} // {InvTransactions}
        />

        {/***************** SCF INVOICE LIST**************** */}
        <ProtectedRoute path="/scinvoice" component={ScInvoiceList} exact />
        <ProtectedRoute path="/scinvoice/shared" component={SharedInvoiceList} exact />
        <ProtectedRoute path="/tfinvoice/shared" component={TfSharedInvoiceList} exact />
        <ProtectedRoute path="/ifinvoice/shared" component={IfSharedInvoiceList} exact />
        <ProtectedRoute path="/ifinvoice/participations" component={IfParticipantInvoiceList} exact />
        <ProtectedRoute path="/direct-debit" component={directDebitList} exact />
        <ProtectedRoute path="/scf-payments" component={ScPayments} exact />
        <ProtectedRoute path="/direct-debit-report" component={DirectDebitReport} exact />
        {/***************** TF INVOICE LIST**************** */}
        <ProtectedRoute path="/tf-invoices" component={TfInvoiceList} exact />
        <ProtectedRoute path="/tf-direct-debit" component={TfDirectDebitList} exact />
        <ProtectedRoute path="/tf-payments" component={TfPayments} exact />
        {/***************** Loan List **************** */}
        <ProtectedRoute path="/loans-list" component={EntityLoan} exact />

        {/*----------------News Letter---------------------*/}
        <Route path="/news-letters" component={NewsLetters}>
          <ProtectedRoute
            path="/news-letters/create"
            component={CreateNewsLetters}
          />
          <ProtectedRoute
            path="/news-letters/edit"
            component={CreateNewsLetters}
          />
          <ProtectedRoute
            path="/news-letters/create/publish-queue"
            component={NLPublishQueue}
          />
          <ProtectedRoute
            path="/news-letters/approval"
            component={NLApproval}
          />
          <ProtectedRoute path="/news-letters/review" component={NLReview} />
          <ProtectedRoute path="/news-letters/list" component={NLList} />
          <ProtectedRoute path="/news-letters/publish" component={NLPublish} />
          <ProtectedRoute path="/news-letters/banners" component={NLBanners} />
          <ProtectedRoute path="/news-letters/help" component={NLHelp} />
          <ProtectedRoute
            path="/news-letters/unsubscribed-users"
            component={NLUnsubscribedList}
          />
        </Route>
        {/*---------------Additional Fields-----------------*/}
        <ProtectedRoute
          path="/view-templates"
          component={ViewTemplates}
        ></ProtectedRoute>
        <ProtectedRoute
          path="/mail-scheduler-list"
          component={MailSchedulerList}
        ></ProtectedRoute>
        <ProtectedRoute
          path="administrator/additional-fields"
          component={AdditionalFields}
        />
        <ProtectedRoute
          path="/scheduler/payment-remainder-mail"
          component={PaymentRemainderMailList}
        ></ProtectedRoute>

        {/*----------------Accountant---------------------*/}
        <ProtectedRoute path="/myclients" component={ListAccountantClients} />
        <ProtectedRoute
          path="/myclients/create"
          component={CreateAccountantClient}
        />
        <ProtectedRoute
          path="/myclients/bulk-upload"
          component={AccountantClientsBulkUpload}
        />
        <ProtectedRoute path="/google-settings" component={GoogleSettings} />

        {/***************** Investment Partner Portal **************** */}
        <ProtectedRoute
          path="/investment-partner/transactions"
          component={InvestmentPartnerTransactions}
          exact
        />

        <ProtectedRoute
          path="/investment-partner/loans"
          component={InvestmentPartnerLoans}
          exact
        />
        <ProtectedRoute
          path="administrator/app-settings"
          component={AppSettings}
        />
        {/**========================================Wiki Component==========================* */}
        <Route path="/wikimenu" component={WikiMenu}>
          <ProtectedRoute path="/wikimenu/create" component={CreateWiki} />
          <ProtectedRoute path="/wikimenu/menus" component={ListWikiMenu} />
          <ProtectedRoute exact path="/wikimenu/edit" component={CreateWiki} />
        </Route>
        <Route path="/wiki" component={WikiTreeView}></Route>
        {/**========================================Debtors Pending Payments Component==========================* */}
        <ProtectedRoute
          path="/debtor-payment-list"
          component={DebtorPaymentList}
          exact
        />

        {/**=================================================Supply chain shared invoices===========================================**/}
        <Route path="/scinvoice/shared" component={ScSharedTransactionsLayout}>
          <ProtectedRoute
            exact
            path="/scinvoice/shared/details"
            component={ScSharedTransactionDetails}
          />
        </Route>

        {/**=================================================TF shared invoices===========================================**/}
        <Route path="/tfinvoice/shared" component={TfSharedTransactionsLayout}>
          <ProtectedRoute
            exact
            path="/tfinvoice/shared/details"
            component={TfSharedTransactionDetails}
          />
        </Route>


        {/* /**=================================================Supply chain invoice section=========================================== **/}
        <Route path="/scinvoice" component={ScLayout}>
          <ProtectedRoute
            exact
            path="/scinvoice/details"
            component={ScTransactionView}
          />
        </Route>
        {/**=================================================Trade Finance Invoice Section=========================================== **/}
        <Route path="/tfinvoice" component={TfLayout}>
          <ProtectedRoute
            exact
            path="/tfinvoice/details"
            component={TfTransactionView}
          />
        </Route>
        {/*---------------New Transaction Module-----------------*/}
        <Route path="/inv-transactions" component={InvTransactionsLayout}>
          <ProtectedRoute
            exact
            path="/inv-transactions/create-transaction"
            component={InvTransactions}
          />
        </Route>

        {/*---------------Notification List -----------------*/}
        <ProtectedRoute
          path="/notifications"
          component={NotificationNew}
          exact
        />
        <ProtectedRoute
          path="/notifications/new"
          component={NotificationNew}
          exact
        />
        <ProtectedRoute
          path="/notification/view"
          exact
          component={NotificationView}
        ></ProtectedRoute>
      </Route>

      {/****************************** */}
    </Router>
  </Suspense>
);
