import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import { CurrencyLabel } from "../../common/inputs";
import * as moment from "moment";
import { Link } from "react-router";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";
import dfTransactionService from "../services/dfTransactions";
import LoadingSpinner from "../../common/loaders/loadingSpinner";

export default function InvoiceErrors({ deleteByInvNumber,onClose, errors, validationErrors,transactionGuid,newInvoice }) {
  const invoiceReopenApi = useApi(dfTransactionService.ReopenInvoice);
  const [invoices, setInvoices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const handleReopenInvoice = async () => {
    const { data } = await invoiceReopenApi.request({
      invoices: [...invoices],
      transGuid:transactionGuid,
    });
    if (data.success === false) toast.error(data.message);
    else {
      if(!_.isEmpty(data.invoice)){
        let inv_numlist = [];
        for (let [key, value] of Object.entries(data.invoice)) {
          inv_numlist.push(value['inv_number']);
        }
        deleteByInvNumber(inv_numlist);
      }
      
      toast.success(data.message);
      onClose();
    }
  };

  // function to enable button , only enabled when atleast one checkbox is checked

  const enableButton = (invList) => {

    let flag = true;
    
    for (const key of Object.keys(invList)) {
      if(invList[key]['checked'] == true){
        flag = false;
      }
      
    }
    
    setDisableButton(flag);
    
  }

  // ----------- bulk reopen for invoice --------------- //
  const handleBulkReopen = ({ target }) => {
    let invList = [...invoices];

    let updatedList = invList.map((item) => {

      if((item.inv_greater_amount !== true) && (item.exist === true)){
        item.checked = !item.checked;
      }
      
      return item;
    });
    
    setInvoices(updatedList);

    enableButton(updatedList);

    setSelectAll(target.checked);

    
  };
  // ------------------------------------------------------------- //

  // -------- individaul reopen for invoice ------------ //
  const handleIndividualReopen = (checked, invNum) => {

    let invList = [...invoices];

    let updatedList = invList
      .filter((obj) => obj.inv_number === invNum)
      .map((item) => {
        item.checked = checked;
        return item;
      });
    invList[invNum] = updatedList[0];

    enableButton(invList);

    setInvoices(invList);
  };
  useEffect(() => {
    let invArr = [];
    
    newInvoice.map((new_inv) => {
      errors.filter(item => item.inv_number == new_inv.inv_number).map((item) => {
        invArr.push({
        index: invArr.length,
        checked: false,
        exist: item.exist,
        inv_number: new_inv.inv_number,
        inv_date: new_inv.inv_date,
        inv_due_date: new_inv.inv_due_date,
        inv_amount: new_inv.inv_amount !== null ? parseFloat(new_inv.inv_amount) : 0,
        inv_amount_prev: item.inv_amount !== null ? parseFloat(item.inv_amount) : 0,
        inv_greater_amount:item.inv_greater_amount,
      });
      });
    });

    setInvoices(invArr);
  }, []);
  return (
    <>
      <Modal.Header closeButton>
        <div className="modal-header">
          <h4 className="modal-title">Validation Errors</h4>
        </div>
      </Modal.Header>

      <Modal.Body>
      {(invoiceReopenApi.loading === true) && <LoadingSpinner />}

        <div className="table-responsive dyn-height">
          {validationErrors.length == 0 && invoices.length > 0 && (
            <div className="alert alert-danger">
              Following Invoice numbers are already exist in our database.
            </div>
          )}
          <table class="table table-sm client-tble">
            {validationErrors && validationErrors.length > 0 ? (
              <tbody>
                <tr>
                  <th>Invoice No</th>
                  <th>Error</th>
                </tr>
                {validationErrors.map((item, index) => (
                  <tr>
                    <td>{item.inv_number}</td>
                    <td>{item.message}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <th>
                    <div className="custom-control d-flex custom-checkbox small">
                      <input
                        id="customCheck1"
                        className="custom-control-input"
                        type="checkbox"
                        checked={selectAll}
                        onClick={handleBulkReopen}
                      ></input>
                      <label
                        className="custom-control-label"
                        for="customCheck1"
                      ></label>
                    </div>
                  </th>
                  <th>Invoice No</th>
                  <th>Invoice Amount</th>
                  <th>Invoice Date</th>
                  <th>Invoice Due Date</th>
                </tr>
                {invoices.map((item, index) => (
                  <tr className={ item.inv_greater_amount === true ? 'text-danger': ''}>
                    {item.exist === true ? (
                      <td>
                        {
                          item.inv_greater_amount === true ? (
                            ""
                          ) : (
                            <>
                              <div className="custom-control d-flex custom-checkbox small">
                                <input
                                  id={item.inv_number}
                                  className="custom-control-input"
                                  type="checkbox"
                                  onClick={(e) =>
                                    handleIndividualReopen(
                                      e.target.checked,
                                      item.inv_number
                                    )
                                  }
                                  checked={item.checked}
                                ></input>
                                <label
                                  className="custom-control-label"
                                  for={item.inv_number}
                                ></label>
                              </div>
                            </>
                          )
                        }
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td>{item.inv_number}</td>
                    <td>
                      <CurrencyLabel value={item.inv_amount}></CurrencyLabel>
                    </td>
                    <td>
                      {moment(item.inv_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </td>
                    {/*   <td>{item.message}</td>*/}
                    <td>
                      {moment(item.inv_due_date, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <Modal.Footer>
          {errors && errors.length > 0 && (
            <Link className="action-icons">
              <button
                onClick={(e) => handleReopenInvoice()}
                className="btn btn-primary "
                disabled={disableButton}
              >
                Re-open
              </button>
            </Link>
          )}

          {/* <button className="btn btn-primary ">Re-open</button> */}
        </Modal.Footer>
      </Modal.Body>
    </>
  );
}
