import React, { useContext, useState, useEffect, useRef } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import InvIcon from "../../../img/invoice.svg";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router";
import {
  TextInput,
  GeneralButton,
  CurrencyText,
  Picker,
  DateTimePicker,
} from "../../common/inputs";
import { FormRow, FormColumnLg } from "../../common/containers/formContainer";
import useApi from "../../../hooks/useApi";
import { transStatus } from "../../../config/predefinedValues.json";
import TransactionContext from "../../../context/invTransactionContext";
import EntityContext from "../../../context/entityContext";
import transactionService from "../../../services/invTransactions";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import { EntityDebtorList } from "../../slplusTools";
import authService from "../../../services/authService";
import CustomConfirmation from "../invoice/_customConfirmation";
import MultipleFileInput from "../../common/inputs/multipleFileInput/multipleFileInput";

export default function InvoiceCreation({
  bp = null,
  a_guid = null,
  onClose,
  selectedRow,
  bulkUpload = true,
  handleTab,
  handleReloadData,
  edit = false,
  action = false,
  trans_guid = null,
  showFileUpload=true,
  showAddNewBtn=true
}) {
  //------------Api Services--------------------------------------------
  const transactionApi = useApi(transactionService.createSingleInvoice);
  const feeApi = useApi(transactionService.getFeeDetails); // Custom hook for HTTP Requests

  const entityContext = useContext(EntityContext);
  const { constants } = authService.getCurrentUser();

  const [customMessage, setCustomMessage] = useState(null);
  const [count, setCount] = useState(1);
  const [openDisburse, setDisburseModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [values, setValues] = useState({});
  const [files, setFiles] = useState({ data: [], document_name: "" });
  const [openCustomer, setInvModal] = useState(true);
  const [isStateChange, setStateChnage] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isSubmited, setDisableSave] = useState(false);
  const [reloadDataOnClose, setReloadDataOnClose] = useState(false);
  const [viewAttachment, setViewAttachment] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [loading, setLoading] = useState(false);

  //Creating references for Input fields
  const debtorRef = useRef();
  const invoiceNumRef = useRef();
  const purchseOrderRef = useRef();
  const invAmtRef = useRef();
  const chargeableAmtRef = useRef();
  const invDateRef = useRef();
  const dueDateRef = useRef();
  const advPctgRef = useRef();
  const advAmtRef = useRef();

  let transaction = {};
  let invoice = [];
  let setInvoice;
  let updateInvoice;
  let defaultFees = {};
  let savedInvoices = [];

  let contextObj = useContext(TransactionContext);

  if (bulkUpload === true) {
    transaction = contextObj.transaction;
    defaultFees = contextObj.defaultFees;
    invoice = contextObj.invoice;
    setInvoice = contextObj.setInvoice;
    updateInvoice = contextObj.updateInvoice;
  }

  // ---- Set BP default values from Context API (if applicable) ---- //
  if (entityContext && typeof entityContext.entityDetails !== "undefined") {
    transaction = {
      bp: entityContext.entityDetails.owner.a_guid,
      client: entityContext.entityDetails.a_guid
        ? entityContext.entityDetails.a_guid
        : a_guid,
    };
  } else {
    let client = "";
    if (selectedRow) {
      client = selectedRow.trans_client_guid
        ? selectedRow.trans_client_guid
        : selectedRow.client_guid;
    }
    transaction = {
      bp: bp ? bp : selectedRow.trans_owner,
      client: a_guid ? a_guid : client,
    };
  }

  //----------------------------------------------------------------------//

  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      inv_debtor_id: Joi.string()
        .required()
        .error(() => {
          debtorRef.current.focus();
          return { message: "Please select the Debtor from the list" };
        }),

      inv_number: Joi.string()
        .required()
        .error(() => {
          invoiceNumRef.current.focus();
          return { message: "Please enter a valid Invoice No" };
        }),

      inv_amount: Joi.number()
        .required()
        .error(() => {
          invAmtRef.current.focus();
          return { message: "Please enter a valid Invoice Value" };
        }),

      inv_chargeable_amt: Joi.number()
        .max(Joi.ref("inv_amount"))
        .required()
        .error((errors) => {
          chargeableAmtRef.current.focus();
          return {
            message: errors[0]["type"] === "number.max" ? "Agreement Amt cannot be greater than Invoice Value" : "Please enter a valid Agreement Amt"
          };
        }),

      inv_date: Joi.date()
        .required()
        .error(() => {
          document.getElementById('inv_date').focus();
          return {
            message: "Invoice date cannot be blank",
          };
        }),

      inv_due_date: Joi.date()
        .min(Joi.ref("inv_date"))
        .required()
        .error((errors) => {
          document.getElementById('inv_due_date').focus();
          return {
            message: errors[0]["type"] === "date.min" ? "Due Date cannot be lesser than Invoice Date" : "Due Date cannot be blank"
          };
        }),

      inv_adv_percent: Joi.number()
        .label("Advance Pctg")
        .max(100)
        .error(() => {
          advPctgRef.current.focus();
          return {
            message: "Advance Pctg must be less than or equal to 100.",
          };
        }).allow(null, ""),

      inv_adv_amount: Joi.number()
        .max(Joi.ref("inv_chargeable_amt"))
        .label("Advance Amt"),

      inv_royalty_percent: Joi.number()
        .label("Royalty")
        .allow(null, ""),

      inv_insurance_percent: Joi.number()
        .label("Insurance Fee")
        .allow(null, ""),
    })
    .unknown(true);

  const validate = () => {
    const options = { abortEarly: true };
    const { error } = Joi.validate(values, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
      //this.focusInput(item.path[0]);
    }
    setDisableSave(false);
    setErrors(true);
    return errors;
  };

  //---------------------Handle Input Change----------------------//
  const handleChange = (e) => {
    setStateChnage(true);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  //---------------------Handle Picker Change----------------------//
  const handlePickerChange = (property, selectedOpt) => {
    setStateChnage(true);
    // if (values[property] != selectedOpt) {
    // setValues({ ...values, [property]: selectedOpt });
    setValues({
      ...values,
      [property]: { label: selectedOpt.label, value: selectedOpt.value },
    });
    // }
  };
  // Handle input change in Agreement Amt field
  const handleAgreementAmtChange = (event, maskedvalue, floatvalue) => {
    let inv_adv_amount = (values.inv_adv_percent / 100) * floatvalue;
    setValues({ ...values, inv_chargeable_amt: floatvalue, inv_adv_amount });
  };

  // Handle input change in Adv Percentage field
  const handleAdvPctgChange = (event) => {
    const { value } = event.target;
    let pctg = isNaN(value) ? 0 : value;
    let amount =
      values.inv_chargeable_amt !== null ? values.inv_chargeable_amt : 0;
    let inv_adv_amount = (pctg / 100) * amount;
    setValues({
      ...values,
      inv_adv_percent: pctg,
      inv_adv_amount: inv_adv_amount,
    });
  };

  const handleFileChange = (fileItems) => {
    if (fileItems[0]) {
      setFiles({
        ...files,
        document_name: fileItems[0].file.name,
        data: fileItems.map((fileItem) => fileItem.file),
      });
    }
  };
  //----------------- add invoice to context api ------------------//
  const save = async () => {
    const errors = validate();
    if (errors) return;
    // else setDisableSave(true);

    let fileslist = await handleFileReader();

    let invData = { ...values };
    invData = {
      ...invData,
      files: fileslist,
      inv_insured: invData.inv_insured.value,
      inv_closed_date: null
    };

    if (selectedRow === null) {

      if(trans_guid !== null && (values.adv_date === "" || values.adv_date === null))
      {
        toast.error("Advance date cannot be empty.");
        return;
      }

      if (bulkUpload === true) {
        let invoiceArr = [...invoice];
        let newInvoice = values;
        newInvoice.index = invoiceArr.length;
        newInvoice.trans_owner = transaction.bp;
        newInvoice.trans_client_guid = transaction.client;
        newInvoice.files = fileslist;
        newInvoice.inv_insured = newInvoice.inv_insured.value
          ? newInvoice.inv_insured.value
          : "no";
        invoiceArr.push(newInvoice);
        setInvoice(invoiceArr);
        onClose();
      } else {
        const reqData = {
          trans_guid,
          action: action,
          invoices: [{ ...invData, invoice_status: trans_guid ? "open" : "draft" }],
          files: fileslist,
        };

        setTimeout(async () => {
          const { data } = await transactionApi.request(reqData);

          if (data.success === false) {
            data.errors &&
              data.errors.map((item) => {
                toast.error(item.message);
              });
          } else {
            handleReloadData();
            setSelectedRows((prevData) => [...prevData, ...data.invoices]);
            !errors && setInvModal(false);
            (!errors && trans_guid === null) && setDisburseModal(true);
          }
        }, 100);
      }
    } else {

      if(selectedRow.invoice_status === "open" && (values.adv_date === "" || values.adv_date === null))
      {
        toast.error("Advance date cannot be empty.");
        return;
      }

      if (bulkUpload === true) {
        values.trans_owner = transaction.bp;
        values.trans_client_guid = transaction.client;
        values.files = fileslist;
        values.inv_insured = values.inv_insured.value
          ? values.inv_insured.value
          : "no";
        updateInvoice(selectedRow.index, values);
        updateInvoice(selectedRow.index, values);
        onClose();
      } else {
        const reqData = {
          trans_guid: selectedRow.inv_trans_guid,
          action: action,
          invoices: [{ ...invData }],
          files: [{ ...files }],
        };
        setTimeout(async () => {
          const { data } = await transactionApi.request(reqData);

          if (data.success === false) {
            data.errors &&
              data.errors.map((item) => {
                toast.error(item.message);
              });
          } else {
            handleReloadData();
            onClose();
            if (action) handleCopyInvoice();
          }
        }, 100);
      }
    }
  };
  //---------------------------------------------------------------//

  const handleAddInvoiceAgian = async () => {
    const errors = validate();
    if (errors) return;

    let fileslist = await handleFileReader();

    let invData = { ...values };
    invData = {
      ...invData,
      files: fileslist,
      inv_insured: invData.inv_insured.value,
    };

    if (selectedRow === null) {

      if(trans_guid !== null && (values.adv_date === "" || values.adv_date === null))
      {
        toast.error("Advance date cannot be empty.");
        return;
      }

      if (bulkUpload === true) {
        let invoiceArr = [...invoice];
        let newInvoice = values;
        newInvoice.index = invoiceArr.length;
        newInvoice.trans_owner = transaction.bp;
        newInvoice.trans_client_guid = transaction.client;
        newInvoice.inv_insured = newInvoice.inv_insured.value
          ? newInvoice.inv_insured.value
          : "no";
        invoiceArr.push(newInvoice);
        setInvoice(invoiceArr);

        setCount((prevCount) => prevCount + 1);
        // custom message section
        setCustomMessage(count + " invoice added");
        setValues({
          ...values,
          inv_number: "",
          inv_date: null,
          adv_date: null,
          inv_due_date: null,
          inv_insured: { label: "No", value: "no" },
          inv_amount: "",
          inv_chargeable_amt: "",
          inv_purchase_order: "",
        });
        // onClose();
      } else {
        const reqData = {
          trans_guid,
          action: action,
          invoices: [{ ...values, inv_insured: values.inv_insured.value, invoice_status: trans_guid ? "open" : "draft" }],
        };

        const { data } = await transactionApi.request(reqData);

        if (data.success === false) {
          data.errors &&
            data.errors.map((item) => {
              toast.error(item.message);
            });
        } else {
          setReloadDataOnClose(true);
          setSelectedRows((prevData) => [...prevData, ...data.invoices]);
          setCount((prevCount) => prevCount + 1);
          // custom message section
          setCustomMessage(count + " invoice added");
          setValues({
            ...values,
            inv_number: "",
            inv_date: null,
            adv_date: null,
            inv_due_date: null,
            inv_insured: { label: "No", value: "no" },
            inv_amount: "",
            inv_chargeable_amt: "",
            inv_purchase_order: "",
            inv_adv_amount: "",
          });
        }
      }
    }
  };

  const startValues = async () => {
    const { data } = await feeApi.request({
      entity_id: transaction.client,
      country: null,
    }); // Calling HTTP service

    if (selectedRow === null) {
      // Create New Invoice

      setValues({
        ...values,
        inv_date: null,
        adv_date: null,
        inv_due_date: null,
        inv_advance_date: null,
        inv_insured: { label: "No", value: "no" },
        pa: { label: "No", value: "no" },
        franchiseFunds: null,
        paFunds: null,
        inv_amount: null,
        inv_chargeable_amt: null,
        inv_number: null,
        inv_royalty_percent: null,
        inv_adv_percent:
          typeof data.defaultFees.advance_rate !== "undefined"
            ? data.defaultFees.advance_rate
            : 100,
        inv_insurance_percent: typeof data.defaultFees.insured_fee !== "undefined"
          ? data.defaultFees.insured_fee
          : null,
        disburse_now: false,
        trans_owner: entityContext
          ? entityContext.entityDetails.owner.a_guid
          : transaction.bp,
        trans_client_guid: entityContext
          ? entityContext.entityDetails.a_guid
          : transaction.client,
        files: [],
        attachements: []
      });
    } else {
      let invoiceData = { ...selectedRow };

      let insured = {};
      if (invoiceData.inv_insured === "no")
        insured = { label: "No", value: "no" };
      else if (invoiceData.inv_insured === "yes")
        insured = { label: "Yes", value: "yes" };
      else if (invoiceData.inv_insured === "partially")
        insured = { label: "Partially", value: "partially" };

      // if (bulkUpload === true)
      // Assigning values from Invoice list API
      invoiceData = {
        ...invoiceData,
        inv_insured: insured,
        inv_debtor_id: invoiceData.inv_debtor_id,
        inv_adv_amount: invoiceData.disb_amount,
        inv_advance_date: invoiceData.disb_date,
        inv_adv_percent:
          invoiceData.inv_adv_percent !== null
            ? invoiceData.inv_adv_percent
            : 100,
        trans_client_guid: invoiceData.client_guid,
        attachements: invoiceData.attachements
      };

      setValues({ ...values, ...invoiceData }); // Update selected invoice
    }
  };

  //------------------------------------------------------------------------------
  useEffect(() => {
    startValues();
  }, []);

  const handleCopyInvoice = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Created Invoice</h4>
            <p>Successfuly created invoice. Inv No./ : {values.inv_number}</p>

            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                handleTab(transStatus[0]["DRAFT"]);
                onClose();
              }}
            >
              Return to Draft Invoices
            </button>
          </div>
        );
      },
      title: "Created Invoice",
      message: `Successfuly created invoice.Inv No./ : ${values.inv_number}`,
    });
  };

  const handleFileReader = async () => {
    /** --------------- Reading local files in JavaScript -------------- */
    let file = files.data;
    let fileslist = [];
    file.map((fileItem) => {
      let reader = new FileReader();
      reader.readAsDataURL(fileItem);
      reader.onload = (e) => {
        fileslist.push({
          name: fileItem.name,
          file: e.target.result,
          type: fileItem.type,
          size: fileItem.size,
        });
      };
    });
    return fileslist;
    /** ----------------------------------------------------------------- */
  };

  const fetchAttachment = async (attachment_guid, operation, filename = "") => {
    try {
        setLoading(true);
        const { data } = await transactionService.viewInvoiceAttachment({ attachment_guid });
        setLoading(false);
        
        if (operation == "download") {
            const link = document.createElement("a");
            link.href = data;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        }else {
            setViewAttachment(true);
            setAttachments(data);
        }
    } catch (error) { }
  };

  const onCloseModal = () => {
    setViewAttachment(false);
    setAttachments(null);
  };


  return (
    <>
      {/*------------------Open Disburse Modal-------------------*/}

      {openDisburse && (
        <CustomConfirmation
          selectedRow={selectedRows}
          onClose={(e) => {
            onClose();
            setDisburseModal(false);
          }}
          extraInfo={"Would you like to create to a transaction?"}
          des={selectedRows.length + " draft invoice added"}
          page={"disburse"}
          handleTab={handleTab}
          handleReloadData={handleReloadData}
          title={selectedRows.length + " draft invoice added"}
        ></CustomConfirmation>
      )}
      {/* </Modal> */}
      {/*---------------------------------------------------------*/}
      <Modal
        show={openCustomer}
        onHide={() => {
          if (reloadDataOnClose)
            handleReloadData();
          onClose();
        }}
        closeOnOverlayClick={false}
        center
        backdrop="static"
        className="animated bounceInRight"
      >
        {(transactionApi.loading === true || loading) && <LoadingSpinner />}
        <Modal.Header closeButton>
          <div class="d-block pt-3 text-center w-100 ml-4">
            <img width={"50px"} src={InvIcon} className="mb-2" />
            <h4 className="modal-title">
              {" "}
              {selectedRow !== null
                ? action
                  ? "Copy "
                  : "Update "
                : trans_guid ? "Create " : "Create Draft "}
              Invoice
            </h4>
            <small class="">Please enter the invoice details below.</small>
          </div>
        </Modal.Header>

        <div  >
          <form className="form-horizontal">
            <div className="row ">
              {/*-------------Offer to sell starts-------------------------------------*/}
              <div className="col-lg-12">
                <div class="px-4">
                  <h6 className="">Offer to Sell Details</h6>

                  <div className="form-group">
                    <EntityDebtorList
                      selectedDebtor={values.inv_debtor_id}
                      client={transaction.client ? transaction.client : ""}
                      onSelectItem={(option) =>
                        setValues({
                          ...values,
                          debtor_name: option.label,
                          inv_debtor_id: option.value,
                        })
                      }
                      product={constants.PRODUCTS.INVOICE}
                      addNew={selectedRow !== null ? false : true}
                      reference={debtorRef}
                    />
                  </div>

                  {/*--------Inv Numbers----------------------------*/}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group required">
                        <label className="control-label">Invoice No</label>
                        <div>
                          <TextInput
                            name="inv_number"
                            label="Invoice No"
                            value={values.inv_number}
                            onChange={handleChange}
                            type=""
                            reference={invoiceNumRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Purchase Order</label>
                        <div>
                          <TextInput
                            name="inv_purchase_order"
                            label="Purchase Order"
                            value={values.inv_purchase_order}
                            onChange={handleChange}
                            type=""
                            reference={purchseOrderRef}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*------------------------------------------------------------------*/}

                  {/*--------Inv Amount----------------------------*/}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group required">
                        <label className="control-label">Invoice Value</label>
                        <div>
                          <CurrencyText
                            value={values.inv_amount}
                            onChangeEvent={(event, maskedvalue, floatvalue) => {
                              setValues({
                                ...values,
                                inv_amount: floatvalue,
                                inv_chargeable_amt: floatvalue,
                              });
                            }}
                            placeholder=" "
                            reference={invAmtRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group required">
                        <label className="control-label">Agreement Amt</label>
                        <div>
                          <CurrencyText
                            value={values.inv_chargeable_amt}
                            onChangeEvent={handleAgreementAmtChange}
                            placeholder=" "
                            reference={chargeableAmtRef}
                            maxValue={values.inv_amount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*------------------------------------------------------------------*/}
                  {/*--------Inv Dates----------------------------*/}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group required">
                        <label className="control-label">Invoice Date</label>
                        <div>
                          <DateTimePicker
                            name="inv_date"
                            label="Invoice Date"
                            selectedDate={values.inv_date}
                            onChange={(value) => handleChange(value)}
                            reference={invDateRef}
                            enableWeekDays={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group required">
                        <label className="control-label">Due Date</label>
                        <div>
                          <DateTimePicker
                            name="inv_due_date"
                            selectedDate={values.inv_due_date}
                            onChange={(value) => handleChange(value)}
                            reference={dueDateRef}
                            enableWeekDays={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*------------------------------------------------------------------*/}

                  {/*--------Agreement Amount----------------------------*/}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Advance Pctg</label>
                        <div>
                          <TextInput
                            name="inv_adv_percent"
                            label="Advance Pctg"
                            placeholder="Advance Pctg"
                            value={values.inv_adv_percent}
                            type=""
                            onChange={handleAdvPctgChange}
                            reference={advPctgRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Advance Amt</label>
                        <div>
                          <CurrencyText
                            value={values.inv_adv_amount}
                            onChangeEvent={(event, maskedvalue, floatvalue) => {
                              setValues({
                                ...values,
                                inv_adv_amount: floatvalue,
                              });
                            }}
                            onBlur={(e) => {
                              let amount =
                                values.inv_chargeable_amt !== null
                                  ? values.inv_chargeable_amt
                                  : 0;

                              let percentage =
                                parseFloat(
                                  e.target.value.slice(1).replace(/,/g, "")
                                ) !== null
                                  ? parseFloat(
                                    e.target.value.slice(1).replace(/,/g, "")
                                  )
                                  : 0;
                              let adv_percent = (
                                (percentage / amount) *
                                100
                              ).toFixed(2);

                              setValues({
                                ...values,
                                inv_adv_percent: isNaN(adv_percent)
                                  ? 0
                                  : adv_percent,
                              });
                            }}
                            placeholder="Advance Amt"
                            reference={advAmtRef}
                            maxValue={values.inv_chargeable_amt}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*------------------------------------------------------------------*/}

                  {/*--------Inv Amount----------------------------*/}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Insured</label>
                        <div>
                          <Picker
                            items={[
                              { label: "Yes", value: "yes" },
                              { label: "No", value: "no" },
                              //{ label: "Partially", value: "partially" },
                            ]}
                            selectedItem={values.inv_insured}
                            onSelectItem={(option) =>
                              handlePickerChange("inv_insured", option)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {values.inv_insured !== undefined && values.inv_insured.value === 'yes' && (
                      <div className="col-lg-6">
                        <div className="form-group">
                          <div>
                            <TextInput
                              name="inv_insurance_percent"
                              label="Insurance %"
                              placeholder="Insurance %"
                              value={values.inv_insurance_percent}
                              type="vertical"
                              onChange={handleChange}
                              number={true}
                            />
                          </div>
                        </div>
                      </div>                      
                    )}

                    {/*-------------------Advance Date---------------*/}
                    {((selectedRow && selectedRow.invoice_status === "open") || trans_guid !== null) &&
                        <div className="col-lg-6">
                          <div className="form-group required">
                            <label className="control-label">Advance Date</label>
                            <div>
                              <DateTimePicker
                                name="adv_date"
                                label="Advance Date"
                                selectedDate={values.adv_date}
                                onChange={(value) => handleChange(value)}
                                enableWeekDays={true}
                                minDate={new Date(Date.parse(values.inv_date))}
                                maxDate={new Date(Date.parse(values.inv_due_date))}
                              />
                            </div>
                          </div>
                        </div>
                    }
                  </div>

                  {customMessage && (
                    <div className="row">
                      <div className="col-lg-12">
                          <div className="row m-t-sm justify-content-center">
                            <div
                              class="alert alert-info align-center  fade show "
                              role="alert"
                            >
                              {customMessage}
                            </div>
                          </div>
                      </div>
                    </div>
                  )}
                  
                  {showFileUpload &&
                    <FormRow className="row">
                      
                      <FormColumnLg col="12">
                        <label>Invoice Files</label>
                        <MultipleFileInput
                          files={files.data}
                          allowMultiple={true}
                          onupdatefiles={(fileItems) => {
                            handleFileChange(fileItems);
                          }}
                        ></MultipleFileInput>
                      </FormColumnLg>

                      {selectedRow && selectedRow.attachements !== undefined && selectedRow.attachements.map((attachment, index) => (
                        <FormColumnLg key={index} col="12">
                          <div className=" alert alert-info">
                              <div className="row">
                                  <div className="col-sm-8">{attachment.inv_file_name}</div>
                                  <div className="col-sm-4">
                                      <Link
                                          title="View attachment"
                                          className="pull-right cursor-link"
                                          onClick={() => { fetchAttachment(attachment.attachment_guid, "view") }}
                                        ><i className="fa fa-file-o"></i>
                                      </Link>
                                      <Link
                                          title="Download attachment"
                                          className="pull-right cursor-link mr-2"
                                          onClick={() => { fetchAttachment(attachment.attachment_guid, "download", attachment.inv_file_name) }}
                                        ><i className="fa fa-download"></i>
                                      </Link>
                                  </div>
                              </div>
                          </div>
                        </FormColumnLg>
                      ))}
                    </FormRow>
                  }
                </div>
              </div>
            </div>

            <div className="row mb-4 px-4">
              <div className="col-lg-12 ">
                <GeneralButton
                  type="button"
                  disabled={isSubmited ? true : false}
                  onClick={(e) => save()}
                  faIcon="fa fa-save"
                  className="btn btn-primary pull-right ml-2"
                  name="Save"
                ></GeneralButton>
                {!edit && showAddNewBtn && (
                  <GeneralButton
                    type="button"
                    onClick={(e) => handleAddInvoiceAgian()}
                    faIcon="fa fa-save"
                    className="btn btn-secondary pull-right mr-2"
                    name="Save & Add New"
                  ></GeneralButton>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>

      {/*------------------ Attachments model ------------------*/}
      <Modal
        show={viewAttachment}
        onHide={onCloseModal}
        backdrop="static"
        center
        size="lg"
      >
        <Modal.Header closeButton> 
            <div class="d-block pt-3 text-center w-100 ml-4">
                <h4 className="modal-title">View Invoice File</h4>
            </div>
        </Modal.Header>

        {!attachments && (
            <LoadingSpinner
                style={{ position: "fixed", top: "10%", left: "50%" }}
            />
        )}

        <center>
            <iframe
                style={{ width: "90%", height: 600 }}
                src={attachments}
            ></iframe>
        </center>
        <div style={{ width: 2000 }}></div>
      </Modal>
    </>
  );
}
