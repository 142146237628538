import React, { useContext, useState, useEffect } from "react";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import externalApiService from "../../../services/externalApiService";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-google";
import authService from "../../../services/authService";
import { confirmAlert } from "react-confirm-alert";
import useApi from "../../../hooks/useApi";
import * as moment from "moment";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router";
import CreateManoovaToken from "./createManoovaToken";
import UpdateBankDetail from "./updateBankDetail";

export default function UsersList(props) {

    const [usersList, setUsersList] = useState(null);
    const [regTokenPopup, setRegTokenPopup] = useState(false);
    const [tokenDetailModel, setTokenDetailModel] = useState(false);
    const [tokenDetail, setTokenDetail] = useState(null);
    const [showCaptcha, setShowCaptcha] = useState(true);
    const [captchaLoading, setCaptchaLoading] = useState(false);
    const [selectedUser,setSelectedUser] = useState(null);
    const [updateBankDetailModal, setUpdateBankDetailModal] = useState(false);
    const { constants } = authService.getCurrentUser();

    const usersListApi = useApi(externalApiService.getUsersList);
    const tokenDetailApi = useApi(externalApiService.getManoovaTokenDetails);
    const deleteTokenApi = useApi(externalApiService.deleteManoovaToken);

    const getUsersList = async () => {
        const { data } = await usersListApi.request();
        if (data.success == true) {
            setUsersList(data.usersList);
        } else {
            toast.error("Failed to fetch result");
        }
    }

    const handleViewTokenDetail = (guid) => {
        setCaptchaLoading(true);
        loadReCaptcha();
        setShowCaptcha(true);
        setSelectedUser(guid);
        setTokenDetailModel(true);
    }

    const viewTokenDetail = async () => {
        const { data } = await tokenDetailApi.request({
            guid: selectedUser
        });
        setShowCaptcha(false);
        if (data.success == true) {
            setTokenDetail(data.result);
        } else {
            toast.error(data.message);
        }
    }
    const onLoadRecaptcha = () => {
        setCaptchaLoading(false);
    }

    const verifyCallBack = () => {
        viewTokenDetail(props.selectedAccGuid);
    }

    const onCloseTokenDetailModel = () => {
        setSelectedUser(null);
        setTokenDetailModel(false);
        setTokenDetail(null);
    }

    const handleUpdateBankDetail = (guid) => {
        setCaptchaLoading(true);
        loadReCaptcha();
        setShowCaptcha(true);
        setSelectedUser(guid);
        setUpdateBankDetailModal(true);
    }

    const onCloseBankUpdateModel = () => {
        setSelectedUser(null);
        setUpdateBankDetailModal(false);
    }

    const handledeleteToken = (guid) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Confirm</h4>
                        <p>Are you sure want to delete?</p>
                        <button
                            className="btn btn-white ml-2 mt-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary ml-2 mt-2"
                            onClick={() => {
                                deleteToken(guid);
                                onClose();
                            }}
                        >
                            Yes
                        </button>

                    </div>
                );
            },
            title: "Confirmation",
            message: "Are you sure want to delete?",
        });
    }

    const deleteToken = async(guid) => {
        const {data} = await deleteTokenApi.request({
            guid:guid
        });
        if(data.success == true){
            toast.success(data.message);
            getUsersList();
        } else {
            toast.error(data.message);
        }
    }

    useEffect(() => {
        getUsersList();
    }, []);

    return (
        <>
            {
                (deleteTokenApi.loading ==true || usersListApi.loading == true || tokenDetailApi.loading == true || captchaLoading == true) && <LoadingSpinner />
            }
            <div className="row">
                <div className="col-sm-6">
                    <div className="table-responsive">
                        <table className="table table-sm text-secondary">
                            <thead>
                                <tr>
                                    <th>Organization</th>
                                    {/* <th>Total DD</th> */}
                                    <th>Created on</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    usersList && usersList.map((itm, index) => (
                                        <>
                                            <tr key={index}>
                                                <td>{itm.organization}</td>
                                                {/* <td>$4,525.50</td> */}
                                                <td>{moment(itm.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()}</td>
                                                <td><span className="label label-success">Active</span></td>
                                                <td>
                                                    <Link
                                                        className="cursor-link text-secondary"
                                                        onClick={() => {
                                                            handleViewTokenDetail(itm.guid)
                                                        }}
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                    <Link
                                                        className="cursor-link ml-2 text-secondary"
                                                        onClick={() => {
                                                            handleUpdateBankDetail(itm.guid)
                                                        }}
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                    <Link
                                                        className="cursor-link ml-2 text-secondary"
                                                        onClick={() => {
                                                            handledeleteToken(itm.guid)
                                                        }}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        </>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-sm-6 m-auto text-center py-4">
                    <div className="bg-light b-dashed p-4">
                        <h6 className="mt-2">Register a new payee bank details with the payment gateway.</h6>
                        <p>Please be aware that once you register your bank account details, they will be permanently associated with the payment gateway. It's crucial to review and ensure the accuracy of all entered information before submission</p>
                        <p>
                            <button
                                className="btn btn-sm btn-primary mt-4"
                                onClick={() => setRegTokenPopup(true)}
                            >
                                Register a new account
                            </button>
                        </p>
                    </div>

                </div>
            </div>

            {/*------------------------Manoova Token request modal----------------------*/}
            <Modal
                show={regTokenPopup}
                onHide={() => setRegTokenPopup(false)}
                centered
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Register bank account with payment gateway</h4>
                </Modal.Header>
                <Modal.Body>
                    <CreateManoovaToken
                        closeModal={() => { setRegTokenPopup(false) }}
                        product={props.product ? props.product : constants.PRODUCTS.SUPPLYCHAIN}
                    />
                </Modal.Body>
            </Modal>
            {/*--------------------------------------------------------------*/}

            {/*------------------------Manoova Token detail modal----------------------*/}
            <Modal
                show={tokenDetailModel}
                onHide={() => onCloseTokenDetailModel()}
                centered
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Register bank account Details</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        {
                            showCaptcha == true ? (
                                <>
                                    <ReCaptcha
                                        render="explicit"
                                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                                        onloadCallback={onLoadRecaptcha}
                                        verifyCallback={e => { verifyCallBack() }}
                                    />
                                </>
                            ) : (
                                <>
                                    <table className="table text-secondary">
                                        {
                                            tokenDetail && (
                                                tokenDetail.map((itm, index) => (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{itm}</td>
                                                        </tr>
                                                    </>
                                                ))
                                            )
                                        }
                                    </table>
                                </>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/*--------------------------------------------------------------*/}

            {/*------------------------Manoova update bank detail modal----------------------*/}
            <Modal
                show={updateBankDetailModal}
                onHide={() => onCloseBankUpdateModel()}
                centered
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Update Bank Details</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        {
                            showCaptcha == true ? (
                                <>
                                    <ReCaptcha
                                        render="explicit"
                                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                                        onloadCallback={onLoadRecaptcha}
                                        verifyCallback={e => { verifyCallBack() }}
                                    />
                                </>
                            ) : (
                                <>
                                    <UpdateBankDetail
                                        tokenGuid = {selectedUser}
                                        closeModal={() => onCloseBankUpdateModel()}
                                    />
                                </>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/*--------------------------------------------------------------*/}

        </>
    );
}