import React, { useContext, useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import externalApiService from "../../../services/externalApiService";
import useApi from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { GeneralButton, TextInput } from "../../common/inputs";


export default function UpdateBankDetail(props) {

    const updateManoovaApi = useApi(externalApiService.updateBankDetails);

    //------------------Validation Schema-----------------------------
    const schema = Joi.object() // Form validation schema object
        .keys({
            bankAccountName: Joi.string().max(30).required().error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case "string.max":
                            err.message = `Bank Account Name should have at most 30 characters!`;
                            break;
                        default:
                            err.message = `Please enter valid account name`;
                            break;
                    }
                });
                return errors;
            }),
            bankAccountNumber: Joi.number().integer().required().error((errors) => { return { message: "Please enter valid account number" }; }),
            bsb: Joi.number().integer().required().error((errors) => { return { message: "Please enter valid bsb number" }; }),
        })
        .unknown(true);

    const updateTokenBankDetail = async () => {
        if (props.tokenGuid == null || props.tokenGuid == "") {
            toast.error("Guid missing.");
            return false;
        }
        const { data } = await updateManoovaApi.request({
            ...values,
            guid: props.tokenGuid
        });
        if (data.success == true) {
            props.closeModal();
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">

                            <h4>Account Account details updated successfully</h4>
                            <p>Account Details has been successfully updated in manoova. Now you can request for online payment with new account details.</p>

                            <button
                                className="btn btn-white ml-2 mt-2"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </button>

                        </div>
                    );
                },
                title: "Account Account details updated successfully",
                message: "Account Details has been successfully updated in manoova. Now you can request for online payment with new account details.",
            });
        } else {
            toast.error(data.message);
        }
    }


    const {
        values,
        setValues,
        handleChange,
        handleSubmit
    } = useForm(updateTokenBankDetail, schema);

    return (
        <>
            {
                updateManoovaApi.loading == true && <LoadingSpinner />
            }
            <form onSubmit={handleSubmit} className="form-horizontal">
                <div className="row">
                    
                    <div className="col-lg-12">
                        <TextInput
                            name="bankAccountName"
                            value={values.bankAccountName}
                            label={"Bank Account Name"}
                            onChange={handleChange}
                            type="vertical"
                        ></TextInput>
                    </div>
                    <div className="col-lg-12">
                        <TextInput
                            name="bankAccountNumber"
                            value={values.bankAccountNumber}
                            label="Bank Account Number"
                            onChange={handleChange}
                            type="vertical"
                        ></TextInput>
                    </div>
                    <div className="col-lg-12">
                        <TextInput
                            name="bsb"
                            value={values.bsb}
                            label={"BSB Number"}
                            onChange={handleChange}
                            type="vertical"
                        ></TextInput>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <GeneralButton
                            className="btn btn-primary  ml-2 pull-right"
                            name="Submit"
                        ></GeneralButton>
                    </div>
                </div>
            </form>
        </>
    );
}