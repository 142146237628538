import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import SupplierProgramContext from "../../../../context/supplierProgramContext";
import SupplierDetails from "./dashboard/supplierDetails";
import SuppliersList from "./dashboard/supplierList";
import TopSuppliers from "./dashboard/topSuppliers";
import Opportunities from "./dashboard/opportunity";
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";

const ProgramView = () => {
  const { data, setData, setShowPopout, showPopout, setView } = useContext(
    SupplierProgramContext
  );

  const handlePopoutClick = () => {
    setShowPopout(true);
  };

  const closePopout = () => {
    setShowPopout(false);
  };
  useEffect(() => {
    setView(true);
    localStorage.setItem("view", JSON.stringify(true));
  }, []);
  return (
    <>
      <Helmet>
        <title>Supplier Program | {global.config.title}</title>
      </Helmet>

      {showPopout ? (
        <Modal
          className="fullscreen-modal"
          dialogClassName={` ${closePopout ? " closing" : " "
            } auto-height-modal`}
          show={showPopout}
          onHide={closePopout}
          onExit={() => (document.body.style.overflow = "auto")} // Restore body overflow when modal exits
          onExiting={() => (document.body.style.overflow = "auto")}
          style={{ height: "auto" }}
        >
          <Modal.Header closeButton>
            <div className="d-block pt-3 text-center w-100 ml-3">
              <h3 className="modal-title"></h3>
            </div>
            <a
              style={{ marginTop: "-7px" }}
              className="btn btn-light pull-right text-secondary ml-2"
              onClick={closePopout}
            >
              <i className="fa fa-compress"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-5">
                <div className="card ml-3">
                  <div className="card-body">
                    <SupplierDetails />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <SuppliersList />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <TopSuppliers />
              </div>
              <div className="col-lg-6">
                <Opportunities />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-10">
                  <h6>Supplier Network Dashboard</h6>
                </div>
                <div className="col-lg-2">
                  <Link
                    title="Close and go to dashboard"
                    className="ml-2 pull-right btn btn-light text-secondary"
                    to="/client/home"
                  >
                    <i className="fa fa-close "></i>
                  </Link>
                  {/*showPopout === false && (
                    <a
                      className="btn btn-light pull-right text-secondary ml-2"
                      onClick={handlePopoutClick}
                    >
                      <i className="fa fa-expand"></i>
                    </a>
                  )*/}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="card ml-3">
                  <div className="card-body">
                    <SupplierDetails />
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <SuppliersList />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-6">
                <TopSuppliers />
              </div>
              <div className="col-lg-6">
                <Opportunities />
              </div>
                  </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProgramView;
