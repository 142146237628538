export const FINANCE_ACTION_TYPES = {
    HANDLE_CHANGE_ANSWERS:'HANDLE_CHANGE_ANSWERS',
    HANDLE_ADD_MORE:'HANDLE_ADD_MORE',
    HANDLE_CHANGE_ANSWERS_FINANCE:'HANDLE_CHANGE_ANSWERS_FINANCE',
    SAVE_QUESTION_ANSWER_START: 'SAVE_QUESTION_ANSWER_START',
    SAVE_QUESTION_ANSWER_SUCCESS:'SAVE_QUESTION_ANSWER_SUCCESS',
    SAVE_QUESTION_ANSWER_FAILURE:'SAVE_QUESTION_ANSWER_FAILURE',
    SET_SELECTED_TYPE:'SET_SELECTED_TYPE',
    RESET_BUSINESS:'RESET_BUSINESS'

};