import React, { useEffect, useContext } from 'react';
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import LoadingSpinner from '../../../common/loaders/loadingSpinner';
import {
    TextInput,
    GeneralButton
} from "../../../common/inputs";
import EntityContext from "../../../../context/entityContext";
import useForm from '../../../../hooks/useForm';
import useApi from '../../../../hooks/useApi';
import entityService from "../../../../services/entityService";
import authService from "../../../../../src/services/authService";

export default function UpdateAccountDetails({ product, onClose }) {

    const { accountDetails, entityDetails, UpdateFacilityAccountName } = useContext(EntityContext);
    const { constants } = authService.getCurrentUser();
    //------------------Validation Schema--------------------------------
    const schema = Joi.object() // Form validation schema object
        .keys({
            //nick_name: Joi.string().required().error(() => { return { message: "Bank Account Name should not be empty" }; }),
        }).unknown(true);

    //------------Api Services--------------------------------------------
    const updateAccountApi = useApi(entityService.AddClientBankDetail);

    const updateNickName = async () => {

        const response = await updateAccountApi.request({
            ...values,
            entity_guid: entityDetails.a_guid
        });

        if (response) {
            if (response.data.success) {
                toast.success(response.data.message);
                UpdateFacilityAccountName(values);
                onClose();
            }
            else
                toast.error(response.data.message);
        }

    }

    useEffect(() => {
        setValues(accountDetails.find(obj => obj.product == product));
    }, []);

    const { values, setValues, handleChange, handleSubmit } = useForm(updateNickName, schema); // Custom Hook to handle Form Validation

    return (
        <>
            {(updateAccountApi.loading === true) && <LoadingSpinner />}

            <Modal.Header closeButton>
                <h4 className="modal-title">Update Nickname</h4>
            </Modal.Header>

            <form onSubmit={handleSubmit} className="form-horizontal" id="tr-prospect-profile">
                <Modal.Body>
                    <div className="row">
                        {/*-------------Offer to sell starts-------------------------------------*/}
                        <div className="col-lg-12">
                            <div className="ibox-content">
                                {accountDetails.find(obj => obj.product == product) ?
                                    <TextInput
                                        name="nick_name"
                                        label="Nickname"
                                        type="horizontal"
                                        mandatory={true}
                                        value={values.nick_name}
                                        onChange={handleChange}
                                    ></TextInput>
                                    : <span className='text-danger'>Account details not found.</span>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="col-lg-12 ">
                        <GeneralButton
                            type="submit"
                            faIcon="fa fa-refresh"
                            className="btn btn-sm btn-primary pull-right m-l-xs"
                            name="Update"
                            disabled={accountDetails.find(obj => obj.product == product) ? false : true}
                        >
                        </GeneralButton>
                    </div>
                </Modal.Footer>
            </form>
        </>
    )
}