import React from "react";
import Form from "../../../../common/Form";
import {
  TabGridContainer,
  TabContent,
  BulkApproveTabList,
} from "../../../../common/containers/formContainer";
import { GeneralButton } from "../../../../common/containers/button";
import ApproveContext from "../../../../supply-chain/context/bulkApproveContext";
import transaction_services from "../../../../supply-chain/services/transaction_service";
import Utility from "../../../../supply-chain/utils/DateParser";

class InvoiceTabViewNew extends Form {
  
  constructor(props) {
    super(props);
  }
  componentWillReceiveProps(nextProps) {
    const { invoiceToProcess, attentionrequired } = this.props;
    if (nextProps.invoiceToProcess !== invoiceToProcess) {
      let additionalParams = { ...this.state.additionalParams };
      additionalParams["invoice_process"] = nextProps.invoiceToProcess;
      additionalParams["attention_required"] = nextProps.attentionrequired;
      let tabs = [
        {
          type: "processed",
          type_name: "Processed Invoices",
          count: nextProps.invoiceToProcess.length,
        },
        {
          type: "attention",
          type_name: "Attention Required",
          count: nextProps.attentionrequired.length,
        },
      ];

      this.setState({ additionalParams: additionalParams, tabs: tabs }, ()=> this.fetchData());
    }
  }
  async fetchData(){
      const {data} = await transaction_services.getBulkApproveList(  
      this.state.page,
      this.state.pageSize,
      this.state.sorted,
      this.state.filtered,
      this.state.search,this.state.additionalParams,
      );
      this.setState({ invoices: data.rows });
  }

  state = {
    tabs: [
      {
        type: "processed",
        type_name: "Processed Invoices",
        count: this.props.invoiceToProcess.length,
      },
      {
        type: "attention",
        type_name: "Attention Required",
        count: this.props.attentionrequired.length,
      },
    ],
    ajaxRequest: false,
    invoices : [],
    loading: false,
    pages: 0,
    search: "",
    selectedKeyword: { label: "Keyword Search", value: "" },
    page: 0,
    pageSize: 10,
    sorted: "",
    filtered: "",
    doNotShowPagination: false,
    additionalParams: {
      type: this.props.approvalType ? this.props.approvalType : "attention",
      invoice_process: this.props.invoiceToProcess,
      attention_required: this.props.attentionrequired,
      product : this.props.product
    },
    selected: {},
    selectAll: 0,
    approvebuttonClass: "btn btn-xs btn-primary hidden",
    declinebuttonClass: "btn btn-xs btn-danger hidden",
    creatingInvoice: false,
    errors: {},
    creatinginvoice: false,
  };
  componentDidMount(){
    this.fetchData();
  }
  handleTabChange = async (type) => {
    this.setState({ selected: {} });
    this.setState({ ajaxRequest: true });
    let additionalParams = { ...this.state.additionalParams };
    additionalParams["type"] = type;
    const selectedKeyword = {
      label: "",
      value: "",
    };
    this.setState({ additionalParams,searchList: [],
      selectedKeyword,search: "" },()=>{
        this.fetchData();
      });
    this.setState({ ajaxRequest: false });
  };
  acceptInvoice = async (trans_guid) => {
    this.props.accept(trans_guid);
  };
  render() {
    const { tabs,invoices } = this.state;
    const { ParseDateDMY } = Utility();
    return (
      <React.Fragment>
        <TabGridContainer className="col-lg-12">
          <div className="tabs-container">
            <BulkApproveTabList
              list={tabs}
              onClick={this.handleTabChange}
              selectedTab={"attention"}
            ></BulkApproveTabList>
            <TabContent>
              <table className="table text-secondary">
                <thead>
                    <tr>
                      <th>Invoice Number</th>
                      <th>Amount</th>
                      <th>Supplier</th>
                      <th>Invoice Date</th>
                      <th>Due Date</th>
                      <th>Maximum Due Date</th>
                      <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                 {invoices && invoices.length > 0 && invoices.map((item, key) => {
                    return (
                        <tr key={key}>
                            <td>{item.inv_number}</td>
                            <td>{item.inv_amount}</td>
                            <td>{item.supplier}</td>
                            <td>{item.trans_date}</td>
                            <td>{item.inv_due_date}</td>
                            <td>{ParseDateDMY(item.max_due_date)}</td>
                            <td>
                            {item.type == "attention" &&
                              <GeneralButton
                                onClick={() => this.acceptInvoice(item.trans_guid)}
                                type="button" name="Accept"
                              />
                            }  
                            </td>
                        </tr>
                    );
                 })}
                </tbody>
              </table>
            </TabContent>
          </div>
        </TabGridContainer>
      </React.Fragment>
    );
  }
}

InvoiceTabViewNew.contextType = ApproveContext;
export default InvoiceTabViewNew;
