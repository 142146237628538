/**
 * White card compoenet
 * ==============================
 * Props 
 * ==============================
 * cardTitle,leftLabel,leftValue,centerValue,centerLabel,rightValue,rightLabel
 * 
 */
import React from "react";
import "./css/card.css";
import {
  FormRow,
} from "../../../common/containers/formContainer";
import CardSkelton from "./_cardSkeltonTypeOne";
import { Link } from "react-router";

function WhiteCard({ loading, data, graph, ...restProps }) {
  return (
    <>

      {loading === true ? (
        <CardSkelton />
      ) : (
        <div className={restProps.cardWidthClass ? restProps.cardWidthClass : "col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12" }>
          <div className="card ">
            <div className="card-header">

              <div className="row">
                <div className="col-lg-6 col-sm-12 col-xs-12">
                  <h4 className="p-0 m-0 card-title">
                    <Link className="text-secondary" to={{ pathname: data ? data.primaryBtnRoute : '', state: data ? data.status : "" }} >
                      {data ? data.cardTitle : " "}
                    </Link>
                  </h4>
                </div>
                <div className="col-lg-6 col-sm-12 col-xs-12">
                  {restProps.actionButtons && restProps.actionButtons}
                </div>
              </div>

            </div>
            <div className="card-body">
              <FormRow>
                <div className="col-md-12">
                  {graph}
                </div>
              </FormRow>
              {/* <div className="row justify-content-center">
                <div class="col-lg-5 col-md-6 col-xs-6 col-sm-6 col-6">
                  <label class="p-0 m-0">{data ? data.leftLabel : ""}</label>
                  <h6 class=" green-highlight p-0 m-0">{data ? data.leftValue : ""}</h6>
                </div>
                <div class="col-lg-3 col-md-6 col-xs-6 col-sm-3 col-6">
                  <label>{data ? data.centerLabel : ""}</label>
                  <h6 class=" green-highlight p-0 m-0">{data ? data.centerValue : ""} </h6>

                </div>
                <div class="col-lg-4 col-md-6 col-xs-12 col-sm-4 col-12">
                  <h6 class=" green-highlight p-0 m-0">{data ? data.rightValue : ""} </h6>
                  <label>{data ? data.rightLabel : ""}</label>
                </div>
      </div>*/}

              <div className="row justify-content-center mt-4 mb-4">
                <div className="col-lg-8 ">
                  <div className="progress mb-4 mt-3 rounded-md">
                    <div className="progress-bar  w-50 bg-primary " role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar  w-50 bg-secondary" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div className="row mb-0">
                    <div className="col-4 text-center">

                      <h5 className="   mb-1 ">{data ? data.leftValue : ""}</h5><span>{/*<strong class="text-primary">10.5%</strong>*/}<span className="text-muted   ml-1">{data ? data.leftLabel : ""}</span></span>

                    </div>
                    <div className="col-4 text-center">


                      <h5 className="   mb-1 ">{data ? data.centerValue : ""}</h5><span><span className="text-muted   ml-1">{data ? data.centerLabel : ""}</span></span>


                    </div>
                    <div className="col-4 text-center">

                      <h5 className="   mb-1">{data ? data.rightValue : ""}</h5><span className="text-xs">
                        {data ? data.rightLabel : ""}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

      )}
    </>
  );
}

export default React.memo(WhiteCard);
