import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import networkStart from "../../images/network-start.svg"
import { Link } from "react-router";
function GetStartedCustomer({ location }) {


    return (

        <React.Fragment>

            <Helmet>
                <title>Customers Get Started | {global.config.title}</title>
            </Helmet>

            <div className="col-lg-12  m-auto">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-10">

                            </div>
                            <div className="col-lg-2">
                                <Link title="Close and go to dashboard" className="text-secondary" to="/client/home"><i className="fa fa-close pull-right"></i></Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 col-lg-12 m-auto text-center pt-3">
                                <img src={networkStart} className="img-fluid"></img>
                            </div>

                            <div className="col-lg-8 m-auto text-center py-4">
                                <h3 className="font-weight-bold ">Network</h3>
                                <p>Connect your network of suppliers and customers. Add to your facility </p>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-4">
                            {/* <button type="button" className="btn btn-sm btn-secondary">Learn More</button> */}
                            <Link
                                className="btn btn-sm btn-primary ml-2"
                                to={{
                                    pathname: `/client/users-network/customer`,
                                    state: { getStart: true }
                                }}
                            >
                                Get Started
                            </Link>
                        </div>

                    </div>
                </div>
            </div>


        </React.Fragment>
    );
}
export default GetStartedCustomer;