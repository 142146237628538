import React, { useState, useContext } from "react";
import Joi from "joi-browser";
import ValidateSheetFields from "../utils/validateSheetFields";
import { confirmAlert } from "react-confirm-alert";

// Create a new context for the Supplier Account
const UploaderContext = React.createContext();

export function useUploader() {
    return useContext(UploaderContext);
}

export function SupplierAccountProvider({ children }) {
    const [supplierAccounts, setSupplierAccounts] = useState([]);
    const [uniqueSupplierAccounts, setUniqueSupplierAccounts] = useState([]);
    const [duplicateAccountsInSheet, setDuplicateAccountsInSheet] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [proceedNext, setProceedNext] = useState(true);
    const [uploadedFileData, setUploadedFileData] = useState([]);
    const [uploading, setUploading] = useState(0);

    const [hideNextBtn, sethideNextBtn] = useState(false);
    const [hidePreviousBtn, sethidePreviousBtn] = useState(false);
    const [nextLabel, setnextLabel] = useState("Next");
    const [prevLabel, setprevLabel] = useState("Previous");

    const [country, setCountry] = useState();
    const [owner, setOwner] = useState();

    const schema = Joi.object().keys({
        Account_Number: Joi.number()
            .integer()
            .min(1)
            .max(999999999)
            .required()
            .error(() => ({ message: "Please enter a valid Account Number (1-999999999)" })),
        Account_Name: Joi.string()
            .min(2)
            .required()
            .error(() => ({ message: "Please enter a valid Account Name (at least 2 characters)" })),
        BSB_Number: Joi.string()
            .regex(/^\d{3}-\d{3}$/)
            .required()
            .error(() => ({ message: "Please enter a valid BSB Number (format: 000-000)" })),
        Description: Joi.string()
            .max(256)
            .error(() => ({ message: "Description must be less than or equal to 256 characters" })),
        Nick_Name: Joi.string()
            .required()
            .error(() => ({ message: "Please enter a valid Nick Name" })),
    }).unknown(true);

    const validate = (excelData) => {
        const options = { abortEarly: false };

        excelData.map((item) => {
            const { error } = Joi.validate(item, schema, options);
            item.error = error ? error.details.map(detail => detail.message).join(", ") : null;
            return item;
        });
    };

    const handleUploadItems = (excelData, filesList = null) => {
        const spreadSheetFileTypes = ["xls", "xlsx", "ods"];

        // Check for invalid file types
        const invalidFiles = filesList?.filter(
            (fileItem) => !spreadSheetFileTypes.includes(fileItem.name.split(".").pop())
        );

        if (invalidFiles && invalidFiles.length > 0) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">
                            <h4>Invalid File Format!</h4>
                            <p>Please make sure you have uploaded the correct template before proceeding!</p>
                            <button
                                className="btn btn-primary ml-2"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </button>
                        </div>
                    );
                },
                title: "",
                message: "",
            });
            return;
        }

        const fieldsToValidate = ['Account_Number', 'Account_Name', 'BSB_Number', 'Description', 'Nick_Name'];
        const validateRes = ValidateSheetFields(excelData, fieldsToValidate);

        if (validateRes) {
            // Add supplier_guid key with null value to each account
            const accountsWithSupplierGuid = excelData.map(account => ({
                ...account,
                supplier_guid: null,
                customer_guid: null

            }));

            filterSupplierAccounts(accountsWithSupplierGuid);
            setSupplierAccounts(accountsWithSupplierGuid);

            if (filesList) {
                let list = [...uploadedFiles];
                filesList.forEach(fileItem => list.push(fileItem));
                setUploadedFiles(list);
                handleFileReader(list);
            }
        }
    };

    const handleFileReader = (inputFiles) => {
        let files = inputFiles.map(fileItem => fileItem.obj);
        let fileslist = [];
        files.forEach(fileItem => {
            let reader = new FileReader();
            reader.readAsDataURL(fileItem);
            reader.onload = e => {
                fileslist.push({
                    name: fileItem.name,
                    file: e.target.result,
                    type: fileItem.type,
                    size: fileItem.size
                });
            };
        });
        setUploadedFileData(fileslist);
        return fileslist;
    };

    const filterSupplierAccounts = (rows) => {
        let accountList = [];
        let duplicateAccounts = [];

        rows.forEach((accountItem, index) => {
            accountItem.index = index;
            accountItem.error = accountItem.error !== undefined ? accountItem.error : null;

            if (accountItem.Account_Number) {
                if (accountList.findIndex(obj => obj.Account_Number === accountItem.Account_Number) === -1) {
                    accountList.push({ ...accountItem });
                } else {
                    duplicateAccounts.push({ ...accountItem });
                }
            } else {
                accountList.push(accountItem);
            }
        });

        setDuplicateAccountsInSheet(duplicateAccounts);
        setUniqueSupplierAccounts(accountList);
        validate(rows);
    };
    // const updateSupplierAccount = (data) => {
        //     setSupplierAccounts(data);
        //     filterSupplierAccounts(data);
        // };

        // const updateSupplierAccount = (updatedAccount) => {
        //     // Find the index of the updated account in the existing supplierAccounts array
        //     const updatedAccounts = supplierAccounts.map(account =>
        //         account.index === updatedAccount.index ? updatedAccount : account
        //     );
        //      console.log(updatedAccounts);
        //     setSupplierAccounts(updatedAccounts);
        //     filterSupplierAccounts(updatedAccounts);
        // };
    const updateSupplierAccount = (updatedAccount) => {
        let updatedAccounts;
        
        if (Array.isArray(updatedAccount)) {
            updatedAccounts = supplierAccounts.map(account => {
                const update = updatedAccount.find(ua => ua.index === account.index);
                return update ? { ...account, ...update } : account;
            });
        } else {
            updatedAccounts = supplierAccounts.map(account =>
                account.index === updatedAccount.index ? updatedAccount : account
            );
        }
        setSupplierAccounts(updatedAccounts);
        filterSupplierAccounts(updatedAccounts);
    };
    
    const deleteSupplierAccount = (index) => {
        let accountsList = supplierAccounts.filter(obj => obj.index !== index);
        setSupplierAccounts(accountsList);
        filterSupplierAccounts(accountsList);
    };

    const removeUploadedSheet = (fileName) => {
        setUploadedFiles([]);
        setSupplierAccounts([]);
        setUniqueSupplierAccounts([]);
        setDuplicateAccountsInSheet(0);
        setProceedNext(false); // Ensure next button is disabled
    };

    const value = {
        supplierAccounts,
        handleUploadItems,
        updateSupplierAccount,
        deleteSupplierAccount,
        uploadedFiles,
        proceedNext,
        setProceedNext,
        hideNextBtn,
        sethideNextBtn,
        hidePreviousBtn,
        sethidePreviousBtn,
        nextLabel,
        setnextLabel,
        prevLabel,
        setprevLabel,
        uniqueSupplierAccounts,
        setUniqueSupplierAccounts,
        duplicateAccountsInSheet,
        uploading,
        setUploading,
        country,
        setCountry,
        owner,
        setOwner,
        removeUploadedSheet,
        uploadedFileData,
        setUploadedFileData
    };

    return <UploaderContext.Provider value={value}>{children}</UploaderContext.Provider>;
}
