import React, { useState, useContext, useEffect } from "react";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";
import {
  FormRow,
  FormColumnMd,
} from "../../../../common/containers/formContainer";
import { Link } from "react-router";
import SearchComponents from "../../../../common/inputs/customSearch/searchComponent";
import AddNewSupplier from "./addNewSuppliers";

export default function SupplierMapping(props) {
  const {
    supplierList,
    editShow,
    setData,
    data,
    setInviteSupplier,
    setProceedNext,
    setSelectedSuppliers,
    selectedSuppliers,
    viewShow,
    setSprogram,
    entity_id,
  } = useContext(SupplierProgramContext);

  const [select, setSelect] = useState(true);
  const [addNew, setAddnew] = useState(false);
  const [selectedSearchTerm, setSelectedSearchTerm] = useState({
    label: "Search",
    value: "",
  });
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    if (editShow) {
      const updatedData = { ...data, update: true };
      setData(updatedData);
    } else {
    }
  }, [editShow]);

  if (
    Array.isArray(data?.supplierProgramMapping) &&
    data.supplierProgramMapping.some(
      (item) =>
        item.removed === false ||
        data.supplierProgramMapping.some((item) => item.checked === true)
    )
  ) {
    setProceedNext(true);
    if ("program_id" in data) {
      if (
        data.supplierProgramMapping.some(
          (item) =>
            item.supplier_program !== null &&
            !item.removed &&
            item.checked &&
            "supp_program" in item &&
            !item.supp_program &&
            item.supplier_program_id !== data.program_id
        )
      ) {
        setSprogram(true);
      } else {
        setSprogram(false);
      }
    } else {
      if (
        data.supplierProgramMapping.some(
          (item) =>
            item.supplier_program !== null &&
            !item.removed &&
            item.checked &&
            "supp_program" in item &&
            !item.supp_program
        )
      ) {
        setSprogram(true);
      } else {
        setSprogram(false);
      }
    }
  } else {
    setProceedNext(false);
  }

  const handleCheckboxChange = (newdata, supplier_id, checked) => {
    const supplierProgramMapping = data?.supplierProgramMapping || [];

    const index = supplierProgramMapping.findIndex(
      (item) => item.supplier_id === supplier_id
    );

    if (index !== -1) {
      const updatedSupplierMapping = [...supplierProgramMapping];
      if (!checked) {
        if ("id" in updatedSupplierMapping[index]) {
          updatedSupplierMapping[index] = {
            ...updatedSupplierMapping[index],
            removed: true,
            checked: false,
            supp_program: true,
            added: false,
            customer_id: entity_id,
          };
        } else {
          updatedSupplierMapping.splice(index, 1);
        }
      } else {
        updatedSupplierMapping[index] = {
          ...updatedSupplierMapping[index],
          ...newdata,
          removed: false,
          supp_program: false,
          added: false,
          customer_id: entity_id,
          checked:
            checked !== undefined
              ? checked
              : !updatedSupplierMapping[index].checked,
        };
      }

      setData((prevData) => ({
        ...prevData,
        supplierProgramMapping: updatedSupplierMapping,
      }));
      setInviteSupplier(updatedSupplierMapping);
    } else {
      const newItem = {
        ...newdata,
        removed: false,
        supp_program: false,
        added: false,
        customer_id: entity_id,
        checked: checked !== undefined ? checked : false,
      };

      if (!checked) {
        newItem.removed = true;
        newItem.supp_program = true;
        newItem.checked = false;
        newItem.added = false;
      }

      setData((prevData) => ({
        ...prevData,
        supplierProgramMapping: [...supplierProgramMapping, newItem],
      }));
      setInviteSupplier([...supplierProgramMapping, newItem]);
    }
  };

  useEffect(() => {
    const rows = data?.supplierProgramMapping?.filter(
      (item) => item.checked === true
    );
    setSelectedSuppliers(rows);
  }, [data]);

  const allOptions = Array.isArray(supplierList.data)
    ? supplierList.data.map((item, index) => ({
        item: item,
        value: item.supplier_name,
        label: item.supplier_name,
        credit_score: item.credit_score,
        supplierMapping: data.supplierProgramMapping,
      }))
    : [];

  const renderOptionContent = (data) => (
    <dl className="dl-horizontal ">
      <ul class="list-group clear-list m-t">
        <li class="list-group-item fist-item" style={{ marginBlock: "-10px" }}>
          <div className="row">
            <div className="col-md-6">
              <label className="font-bold ml-4">{data.label}&nbsp;&nbsp;</label>
            </div>
            <div className="col-md-3">
              <span
                class="label text-white"
                style={{
                  background: "#16C79A",
                  borderRadius: "50%",
                }}
              >
                {data.credit_score}
              </span>
            </div>
            <div className="col-md-3">
              {data.supplierMapping &&
              data.supplierMapping.some((supplier) => {
                return (
                  data.item &&
                  data.item.supplier_id === supplier?.supplier_id &&
                  supplier.checked === true
                );
              }) ? (
                <button
                  className="btn btn-sm btn-secondary mr-2 mappingBtn"
                  onClick={(e) => {
                    handleCheckboxChange(
                      data.item,
                      data.item.supplier_id,
                      false
                    );
                  }}
                >
                  Selected
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-white mr-2 mappingBtn"
                  onClick={(e) => {
                    handleCheckboxChange(
                      data.item,
                      data.item.supplier_id,
                      true
                    );
                  }}
                >
                  Select
                </button>
              )}
            </div>
          </div>
        </li>
      </ul>
    </dl>
  );
  console.log("selectedSuppliers", selectedSuppliers);
  return (
    <div>
      <div className="bg-light bg-dashed p-1 mb-2">
        <p className="mt-2" style={{ textAlign: "center" }}>
          Select the suppliers you wish to invite to thi dicount program. Only
          suppliers that have an equal or higher minimum credit score that you
          set in step 1.
        </p>
      </div>

      {select === true ? (
        <>
          <FormRow>
            <FormColumnMd col={12}>
              <SearchComponents
                isDisabled={viewShow === true}
                options={allOptions}
                renderOptionContent={renderOptionContent}
                userInteracted={userInteracted}
                searchKeyChange={(inputValue) => {
                  setSelectedSearchTerm(inputValue);
                  setUserInteracted(true);
                }}
                onMenuClose={() => setUserInteracted(false)}
                selectedSearchTerm={selectedSearchTerm}
              />
            </FormColumnMd>
          </FormRow>
          <FormRow>
            {/*  <div className="col-md-4">
              <div
                className=""
                onClick={(e) => {
                  e.stopPropagation();
                  setSelect(true);
                  setAddnew(false);
                }}
              >
                <Link
                  className={`btn suppliersBtn float-right btn-sm mt-2 mr-2 ${select ? "highlightedBtn" : ""
                    } ${viewShow ? "disabled" : ""}`}
                >
                  Select suppliers
                </Link>
              </div>

              <div
                className=""
                onClick={(e) => {
                  e.stopPropagation();
                  setAddnew(true);
                  setSelect(false);
                }}
              >
                <Link
                  className={`btn suppliersBtn float-right btn-sm mt-2 mr-2 ${addNew ? "highlightedBtn" : ""
                    } ${viewShow ? "disabled" : ""}`}
                >
                  Add new suppliers
                </Link>
              </div>
            </div> */}
            <div className="col-md-12" style={{ marginTop: "15px" }}>
              <div
                style={{
                  border: "1px solid #ced4da",
                  width: "100%",
                  minHeight: "200px",
                }}
              >
                <dl className="dl-horizontal ">
                  <ul class="list-group clear-list m-t">
                    {selectedSuppliers?.map((item, index) => {
                      if (item.program_id !== null || item.added === false) {
                        return (
                          <li class="list-group-item fist-item" key={index}>
                            <div className="row">
                              <div className="col-md-6">
                                <label className="font-bold ml-4">
                                  {item.supplier_name}&nbsp;&nbsp;
                                </label>
                              </div>
                              <div className="col-md-3">
                                <span
                                  class="label text-white"
                                  style={{
                                    background: "#16C79A",
                                    borderRadius: "50%",
                                  }}
                                >
                                  {parseFloat(item.credit_score).toFixed(0)}
                                </span>
                              </div>
                              <div className="col-md-3">
                                <button
                                  className={`btn btn-sm btn-outline-danger mr-2 mappingBtn ${
                                    viewShow ? "disabled" : ""
                                  } `}
                                  onClick={(e) => {
                                    if (!viewShow) {
                                      handleCheckboxChange(
                                        item,
                                        item.supplier_id,
                                        false,
                                        index
                                      );
                                    }
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </dl>
              </div>
            </div>
          </FormRow>
        </>
      ) : (
        <AddNewSupplier
          setSelect={setSelect}
          setAddnew={setAddnew}
          select={select}
          addNew={addNew}
          selectedSuppliers={selectedSuppliers}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
    </div>
  );
}
