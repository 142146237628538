import React, { useState } from "react";
import {
  AddButton,
  EditButton,
  DeleteButton,
  CurrencyLabel,
  TextInput,
} from "../../common/inputs";
import Select from "react-select";
import { prospectPriorityClass } from "../../../config/predefinedValues.json";
import { userTypes } from "../../../config/predefinedValues.json";
import { GetToday } from "../../../utils/GetToday";
import { Link } from "react-router";
import { CancelButton, GeneralButton } from "./button";
import { Checkbox } from "react-icheck";
import PayNowIcon from "../../../img/pay_now.svg";
import { Modal } from "react-bootstrap";

const Panel = (props) => (
  <React.Fragment>
    <div style={props.style} className="row">
      <div className={props.outerClass}>
        <div className="row">
          <div id={props.id} className={props.innerClass}>
            <div className="wrapper wrapper-content">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

const FormContainer = (props) => (
  <React.Fragment>
    {props.hide ? (
      props.children
    ) : (
      <div className={props.className}>
        <div className="card overflow-visible">
          <div className="card-header">
            <h5 className="card-title">
              <i className={props.icon}></i>&nbsp;{props.title}
            </h5>
            {props.route && (
              <AddButton
                label="Add New"
                faIcon="fa fa-plus-circle"
                route={props.route}
              ></AddButton>
            )}
            {props.addButtonPopupModal && (
              <AddButton
                label="Add New"
                faIcon="fa fa-plus-circle"
                onClick={props.addButtonPopupModal}
              ></AddButton>
            )}
            {props.button && props.button}
          </div>
          <div className="card-body">{props.children}</div>
        </div>
      </div>
    )}
  </React.Fragment>
);
const FormContainerWithLink = (props) => (
  <React.Fragment>
    <div className={props.className}>
      <div className="card">
        <div className="card-header">
          <h5>
            <i className={props.icon}></i>&nbsp;{props.title}
          </h5>
          {props.route && (
            <AddButton
              label="Add New"
              faIcon="fa fa-plus-circle"
              route={props.route}
            ></AddButton>
          )}

          <Link
            target="_blank"
            className="float-right"
            to={{
              pathname: props.routes,
              search: "?" + props.search_param + "=" + props.search_key,
            }}
            /*to={{
            pathname: props.routes,
            search: "?" + props.search_param + "=" + props.search_key,
          }}*/
          >
            View All
          </Link>

          {props.button && props.button}
        </div>
        <div className="card-body">{props.children}</div>
      </div>
    </div>
  </React.Fragment>
);
const GridContainer = (props) => (
  <React.Fragment>
    <div className={props.className}>
      <div className="card float-e-margins">
        <div className="card-header">
          {props.route && (
            <AddButton
              label="Add New"
              faIcon="fa fa-plus-circle"
              route={props.route}
            ></AddButton>
          )}
        </div>
        <div className="card-body">{props.children}</div>
      </div>
    </div>
  </React.Fragment>
);

const TabGridContainer = (props) => (
  <React.Fragment>
    <div
      style={props.style}
      className={
        props.hideTopDiv ? "" : "wrapper wrapper-content animated fadeIn"
      }
    >
      <div className="row">
        <div className={props.className}>{props.children}</div>
      </div>
    </div>
  </React.Fragment>
);

const EntityTabList = (props) => (
  <React.Fragment>
    <ul className="nav nav-tabs" role="tablist">
      {props.list.map((item) => {
        return (
          <li className={item.id == 79 && "active"}>
            <a
              className="nav-link"
              data-toggle="tab"
              onClick={() => props.onClick(item.id)}
            >
              {item.name}&nbsp;&nbsp;
              <span className="label label-secondary text-white">
                {props.disableCount ? "" : item.count}
              </span>
            </a>
          </li>
        );
      })}
      <li>
        <a
          className="nav-link active"
          data-toggle="tab"
          onClick={() => props.onClick("")}
        >
          All&nbsp;&nbsp;
          <span className="label label-default">
            {props.disableCount ? "" : props.total}
          </span>
        </a>
      </li>
    </ul>
  </React.Fragment>
);
const BpTabList = (props) => (
  <React.Fragment>
    <ul className="nav nav-tabs" role="tablist">
      {props.list.map((item) => {
        return (
          <li>
            <a
              className="nav-link"
              data-toggle="tab"
              onClick={() => props.onClick(item.id)}
            >
              {item.name}&nbsp;&nbsp;
              <span className="label label-default">
                {props.disableCount ? "" : item.count}
              </span>
            </a>
          </li>
        );
      })}
      <li className={"active"}>
        <a
          className="nav-link active"
          data-toggle="tab"
          onClick={() => props.onClick("")}
        >
          All&nbsp;&nbsp;
          <span className="label label-default">
            {props.disableCount ? "" : props.total}
          </span>
        </a>
      </li>
    </ul>
  </React.Fragment>
);
const TransactionTabList = (props) => (
  <React.Fragment>
    <ul className="nav nav-tabs" role="tablist">
      {props.list.map((item) => {
        return (
          <li
            className={
              item.id == props.additionalParams.trans_status && "active"
            }
          >
            <a
              className="nav-link"
              data-toggle="tab"
              onClick={() => props.onClick(item.id)}
            >
              {item.name}&nbsp;&nbsp;
              <span className="label label-default">
                {props.disableCount ? "" : item.count}
              </span>
            </a>
          </li>
        );
      })}
      <li className={props.additionalParams.trans_status == "" && "active"}>
        <a
          className="nav-link active"
          data-toggle="tab"
          onClick={() => props.onClick("")}
        >
          All&nbsp;&nbsp;
          <span className="label label-default">
            {props.disableCount ? "" : props.total}
          </span>
        </a>
      </li>
    </ul>
  </React.Fragment>
);
const ClientInvoiceTabList = (props) => (
  <React.Fragment>
    <ul className="nav nav-tabs" role="tablist">
      <li>
        <a
          className="nav-link"
          data-toggle="tab"
          onClick={() => props.onClick("")}
        >
          All&nbsp;&nbsp;
          <span className="label label-default">
            {props.disableCount ? "" : props.total}
          </span>
        </a>
      </li>
      {props.list.map((item) => {
        return (
          <li className={item.name == "Live" && "active"}>
            <a
              className="nav-link active"
              data-toggle="tab"
              onClick={() => props.onClick(item.id)}
            >
              {item.name}&nbsp;&nbsp;
              <span className="label label-default">
                {props.disableCount ? "" : item.count}
              </span>
            </a>
          </li>
        );
      })}
    </ul>
  </React.Fragment>
);

const UserTypesTabList = (props) => (
  <React.Fragment>
    <ul className="nav nav-tabs" role="tablist">
      <li className="active">
        <a
          className="nav-link active"
          data-toggle="tab"
          onClick={() => props.onClick("")}
        >
          All&nbsp;
          <span className="label label-info">{props.total}</span>
        </a>
      </li>
      {props.list.map((item) => {
        let selectedType = userTypes.filter(
          (type) => type.value == item.category_type
        );
        return (
          <li>
            <a
              className="nav-link"
              data-toggle="tab"
              onClick={() => props.onClick(item.category_type)}
            >
              {selectedType[0].label}&nbsp;
              <span className="label label-info">{item.count}</span>
            </a>
          </li>
        );
      })}
    </ul>
  </React.Fragment>
);

const ProspectTabList = (props) => (
  <React.Fragment>
    <ul className="nav nav-tabs" role="tablist">
      {props.list.map((item) => {
        return (
          <li className={item.name == "" && "active"}>
            <a
              className="nav-link"
              data-toggle="tab"
              onClick={() => props.onClick(item.name)}
            >
              {item.name}&nbsp;&nbsp;
              <span className="label label-default pull-right">
                {/*prospectPriorityClass[item.name]*/}
                {item.count}
              </span>
            </a>
          </li>
        );
      })}
      <li className="active">
        <a
          className="nav-link active"
          data-toggle="tab"
          onClick={() => props.onClick("")}
        >
          All&nbsp;&nbsp;
          <span className="label label-default">{props.total}</span>
        </a>
      </li>
    </ul>
  </React.Fragment>
);

const InvestmentPartnerTransTabList = (props) => (
  <React.Fragment>
    <ul className="nav nav-tabs" role="tablist">
      {props.list.map((item) => {
        return (
          <li className={item.name == "Pending" && "active"}>
            <a
              className="nav-link"
              data-toggle="tab"
              onClick={() => props.onClick(item.name)}
            >
              {item.name == "Cancelled" ? "Declined" : item.name}&nbsp;&nbsp;
              <span className="label label-default pull-right">
                {/*prospectPriorityClass[item.name]*/}
                {item.count}
              </span>
            </a>
          </li>
        );
      })}
      <li>
        <a
          className="nav-link active"
          data-toggle="tab"
          onClick={() => props.onClick("")}
        >
          All&nbsp;&nbsp;
          <span className="label label-default">{props.total}</span>
        </a>
      </li>
    </ul>
  </React.Fragment>
);

const TabList = (props) => (
  <React.Fragment>
    <ul className="nav nav-tabs" role="tablist">
      {props.list.map((item) => {
        return (
          <li className={item.value == 1 ? "active" : ""}>
            <a
              className="nav-link"
              data-toggle="tab"
              onClick={() => props.onClick(item.value)}
            >
              {item.name}&nbsp;
              <span className={prospectPriorityClass[item.name]}></span>
            </a>
          </li>
        );
      })}
    </ul>
  </React.Fragment>
);

const customStyles = {
  control: (base) => ({
    ...base,
    border: "1px solid #d1d3e2",
    borderRadius: " .375rem",
  }),
  option: (provided) => ({
    ...provided,
    color: "#676a6c !important",
  }),
};

const TabContent = (props) => (
  <React.Fragment>
    <div className="tab-content">
      <div role="tabpanel" id="tab-1" className="tab-pane active">
        <div className="" style={props.panelStyle}>
          <div className="row">
            <div className="col-lg-4">
              {props.searchList && (
                <Select
                  options={props.searchList}
                  placeholder="Select Country"
                  components={{ DropdownIndicator: () => null }}
                  onKeyDown={props.onKeyDown}
                  onChange={props.onChange}
                  onBlur={
                    props.handleKeyWordOnBlur && props.handleKeyWordOnBlur
                  }
                  value={props.keyValue}
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      text: "black",
                      primary25: "white",
                      primary: "#1ab394",
                      neutral0: "white",
                    },
                  })}
                />
              )}
            </div>

            {props.statusFilter && (
              <div className="col-lg-2">
                {props.statusFilter && props.statusFilter}
              </div>
            )}

            <div className="col-lg-4">
              {props.countryFilter && props.countryFilter}
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-1">{props.button2 && props.button2}</div>
            <div className="col-lg-1">{props.button && props.button1}</div>
            <div className="col-lg-1 float-right">
              {props.button && props.button}
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  </React.Fragment>
);
//-------Display a Wrapper-------------------------
const FormWrapper = (props) => (
  <React.Fragment>
    <div className="wrapper wrapper-content">{props.children}</div>
  </React.Fragment>
);
//------Booststrap Row-----------------------------------
const FormRow = (props) => (
  <React.Fragment>
    <div
      className={props.className ? props.className : "row"}
      style={props.style}
    >
      {props.children}
    </div>
  </React.Fragment>
);
//------Booststrap Column-----------------------------------
const FormColumn = (props) => (
  <React.Fragment>
    <div className={props.col ? props.col : "col-md-12"} style={props.style}>
      {props.children}
    </div>
  </React.Fragment>
);
//-------Display a circle-------------------------
const CircleShape = (props) => (
  <React.Fragment>
    <div className="profile-info">
      <div className="circle-badge">
        <strong>{props.ref_num}</strong>
      </div>
    </div>
  </React.Fragment>
);
//----------------------------------------------

const ReportGridContainer = (props) => (
  <React.Fragment>
    <div className="row">
      <div className="col-lg-12">
        <div className="card float-e-margins">
          <div className="card-header">
            <h5>{props.label}</h5>
            <div className="card-tools">
              <a className="collapse-link">
                <i className="fa fa-chevron-up"></i>
              </a>
            </div>
          </div>

          <div className="card-body">{props.children}</div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

//-------Reports Filter Box-------------------------
const ReportsGrid = (props) => (
  <React.Fragment>
    <div class="row">
      <div class="col-lg-12">
        <div class="card float-e-margins">
          <div class="card-header">
            <h5>
              <i class="fa fa-file-text-o"></i>&nbsp;{props.reportTitle}
            </h5>
            <div class="card-tools">
              <a class="collapse-link">
                <i class="fa fa-chevron-up"></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <small>
                    Report generated on:&nbsp;
                    <strong>
                      <GetToday></GetToday>
                    </strong>
                  </small>
                </div>
                <div class="col-md-4"></div>
              </div>

              <br></br>
              {props.children}

              <div class="row">
                <div class="col-md-6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);
//----------------------------------------------

//-------Report Download Buttons----------------
const ReportDownloadButtons = (props) => (
  <React.Fragment>
    <br></br>
    <div class="row ">
      <div class="col-md-6 float-right">
        <div
          style={{
            width: 120,
            marginRight: 4,
            float: "right",
          }}
        >
          <button
            id="animation_select"
            class="btn btn-primary btn-outline btn-xs float-right"
            data-animation="rollOut"
            type="submit"
            onClick={(e) => {
              props.handleReportDownload("pdf");
            }}
          >
            Download PDF&nbsp;
            <i class="fa fa-file-pdf-o"></i>
          </button>
        </div>

        <div
          style={{
            width: 130,
            marginRight: 4,
            float: "right",
          }}
        >
          <button
            id="animation_select"
            class="btn btn-primary btn-outline btn-xs float-right"
            data-animation="rollOut"
            type="submit"
            onClick={(e) => {
              props.handleReportDownload("excel");
            }}
          >
            Download Excel&nbsp;
            <i class="fa fa-file-excel-o "></i>
          </button>
        </div>

        <div
          style={{
            width: 100,
            marginRight: 4,
            float: "right",
          }}
        ></div>
      </div>
    </div>
  </React.Fragment>
);
//-----------------------------------------------

//-------Reports Filter Box-------------------------
const ReportButtons = (props) =>
  props.generateDocReport ? (
    <React.Fragment>
      <div class="row ">
        <div class="col-md-6 float-right">
          <div
            style={{
              width: 120,
              marginRight: 4,
              float: "right",
            }}
          >
            <button
              id="animation_select"
              class="btn btn-primary btn-outline btn-xs float-right"
              data-animation="rollOut"
              type="submit"
              onClick={(e) => {
                props.generateDocReport("pdf");
              }}
            >
              Download PDF&nbsp;
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
          <div
            style={{
              width: 130,
              marginRight: 4,
              float: "right",
            }}
          >
            <button
              id="animation_select"
              class="btn btn-primary btn-outline btn-xs float-right"
              data-animation="rollOut"
              type="submit"
              onClick={(e) => {
                props.generateDocReport("Excel");
              }}
            >
              Download Excel&nbsp;
              <i class="fa fa-file-excel-o "></i>
            </button>
          </div>
          <div
            style={{
              width: 100,
              marginRight: 4,
              float: "right",
            }}
          >
            {/* <button
            id="animation_select"
            class="btn btn-primary btn-outline btn-xs float-right"
            data-animation="rollOut"
            type="submit"
          >
            Print&nbsp;<i class="fa fa-print"></i>
          </button> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div class="row ">
        <div class="col-md-6 float-right">
          <div
            style={{
              width: 120,
              marginRight: 4,
              float: "right",
            }}
          >
            <Link to={props.pdfdownloadPath} target="_blank" download>
              <button
                id="animation_select"
                class="btn btn-primary btn-outline btn-xs float-right"
                data-animation="rollOut"
                type="submit"
              >
                Download PDF&nbsp;
                <i class="fa fa-file-pdf-o"></i>
              </button>
            </Link>
          </div>
          <div
            style={{
              width: 130,
              marginRight: 4,
              float: "right",
            }}
          >
            <Link to={props.downloadPath} target="_blank" download>
              <button
                id="animation_select"
                class="btn btn-primary btn-outline btn-xs float-right"
                data-animation="rollOut"
                type="submit"
              >
                Download Excel&nbsp;
                <i class="fa fa-file-excel-o "></i>
              </button>
            </Link>
          </div>
          <div
            style={{
              width: 100,
              marginRight: 4,
              float: "right",
            }}
          ></div>
        </div>
      </div>
    </React.Fragment>
  );
//----------------------------------------------
/*const WidgetBox = props => (
  <React.Fragment>
    <div className={props.box_size}>
      <div class="card ">
        <div class="card-header">
          <h3>
            <span class={props.head_class}>{props.head}</span>
          </h3>
          <small>{props.sub_head}</small>
        </div>
        <div class="card-body">
          <h3 class="no-margins">
            <CurrencyLabel decimal={0} value={props.value}></CurrencyLabel>
          </h3>

          <small>{props.label}</small>
        </div>
      </div>
    </div>
  </React.Fragment>
);*/
const FormGroup = (props) => (
  <React.Fragment>
    {props.type && props.type === "horizontal" ? (
      <div className={`form-group row ${props.mandatory ? "required" : ""}`}>
        {props.label && (
          <label
            style={{ paddingTop: "7px" }}
            className={`col-lg-4 form-label ${
              props.mandatory ? "control-label" : ""
            }`}
          >
            {props.label}
          </label>
        )}
        {props.header && props.header}
        <div className="col-lg-8">{props.input}</div>
      </div>
    ) : (
      <div>
        <div class="form-group ">
          {props.label && (
            <label
              dangerouslySetInnerHTML={{
                __html: props.label ? props.label : "",
              }}
              class={
                props.labelClass
                  ? props.labelClass + " control-label"
                  : " control-label"
              }
            >
              {}
            </label>
          )}
          <div
            class={props.inputClass ? props.inputClass + " control-label" : " "}
          >
            {props.input ? props.input : ""}
          </div>
        </div>
      </div>
    )}
  </React.Fragment>
);

//-----------------------------------------------------------------------------
const WidgetBox = (props) => (
  <React.Fragment>
    <div className={props.box_size}>
      <div class="card ">
        <div class="card-header">
          <h3>
            <span class={props.head_class}>{props.head}</span>
            <small class="pull-right">{props.sub_head}</small>
          </h3>
        </div>
        <div class="card-body">
          <h2 class="no-margins">
            <CurrencyLabel decimal={0} value={props.value}></CurrencyLabel>
          </h2>

          <small>{props.label}</small>
        </div>
      </div>
    </div>
  </React.Fragment>
);
const WidgetBox2 = (props) => (
  <React.Fragment>
    <div className={props.box_size}>
      <div class="card ">
        <div class="card-header">
          <h3>
            <span class={props.head_class}>{props.head}</span>
            <small class="pull-right">{props.sub_head}</small>
          </h3>
        </div>
        <div class="card-body">
          <table class="table table-hover margin bottom">
            <tr>
              <td>
                <small>Invoice Transactions</small>
              </td>
              <td>
                <h4 className="pull-right">
                  {" "}
                  <CurrencyLabel
                    decimal={0}
                    value={props.value}
                  ></CurrencyLabel>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <small>Loan Transactions</small>
              </td>
              <td>
                <h6 className="pull-right">
                  <CurrencyLabel
                    decimal={0}
                    value={props.loans}
                  ></CurrencyLabel>
                </h6>
              </td>
            </tr>
            <tr>
              <td>
                <small>Total Transactions</small>
              </td>
              <td>
                <h4 className="pull-right">
                  <CurrencyLabel
                    decimal={0}
                    value={
                      props.value + props.loans > 0
                        ? props.value + props.loans
                        : 0
                    }
                  ></CurrencyLabel>
                </h4>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </React.Fragment>
);

const TimelineContainer = (props) => (
  <>
    <div className="wrapper wrapper-content">
      <div className="row">
        <div className="col-lg-12">
          <div className="card float-e-margins">
            <div className="card-body" id="ibox-content">
              <div
                id="vertical-timeline"
                className="vertical-container dark-timeline center-orientation"
              >
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const CommonContainer = (props) => (
  <React.Fragment>
    <div
      className={
        props.Extraeffects
          ? "wrapper wrapper-content " + props.Extraeffects
          : "wrapper wrapper-content "
      }
    >
      <div
        className={
          props.ExtraChildEffects ? "row " + props.ExtraChildEffects : "row "
        }
      >
        {props.children}
      </div>
    </div>
  </React.Fragment>
);

const DashboardContainer = (props) => (
  <React.Fragment>
    <div className="wrapper wrapper-content animated fadeInRight">
      {props.children}
    </div>
  </React.Fragment>
);

const FieldContainer = ({ labelClass, inputClass, input, label }) => (
  <React.Fragment>
    <div className="row m-sm">
      {label != "" && (
        <div className={labelClass ? labelClass : "col-lg-4"}>{label}</div>
      )}
      <div className={inputClass ? inputClass : "col-lg-8"}>
        {input ? input : ""}
      </div>
    </div>
  </React.Fragment>
);

const BoxContainer = ({
  className,
  boxTitle,
  titleButton,
  contentClassname,
  searchList,
  onKeyDown,
  handleKeyWordOnBlur,
  keyValue,
  onChange,
  children,
  onclick,
  icon,
  modalLabel,
  modalClassName,
  iboxExtra,
  headTag,
  settings,
  settingsOnclick,
  settingsLabel,
  header = true,
  handleCancel = null,
  handleSet = null,
  showCard = true,
  epProgram = null,
  data = null,
  handleChange = null,
}) => {
  const [edit, setEdit] = useState(false);
  return (
    <React.Fragment>
      {showCard === true ? (
        <div className={className}>
          <div
            className={
              iboxExtra
                ? "card overflow-visible" + iboxExtra
                : "card overflow-visible"
            }
          >
            {header != false && (
              <div className="card-header">
                <div class="d-flex justify-content-between">
                  {headTag && headTag}
                  <h4 className="card-title">
                    {icon && <i class={icon}></i>} {boxTitle}
                  </h4>
                  {epProgram !== null && (
                    <h4 className="card-title" style={{ display: "flex" }}>
                      {edit === true && epProgram === "edit" ? (
                        <form className="form-horizontal">
                          <div className="form-group">
                            <TextInput
                              name="program_name"
                              value={data.program_name}
                              placeholder="Please enter program name here"
                              onChange={handleChange}
                              type="normal"
                              className="form-control"
                            ></TextInput>
                          </div>
                        </form>
                      ) : (
                        data?.program_name
                      )}

                      {edit === true ? (
                        <React.Fragment>
                          <Link
                            className="ml-2 mt-2"
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              alt="Save"
                              data-toggle="tooltip"
                              data-placement="left"
                              title="Save"
                              className="fa fa-save mr-2 text-primary"
                              onClick={(e) => {
                                setEdit(false);
                              }}
                            ></i>
                          </Link>

                          <Link className="mt-2" style={{ cursor: "pointer" }}>
                            <CancelButton
                              className="fa fa-times ml-2 text-secondary"
                              onClick={(e) => {
                                setEdit(false);
                              }}
                            />
                          </Link>
                        </React.Fragment>
                      ) : (
                        epProgram === "edit" && (
                          <Link
                            style={{ cursor: "pointer" }}
                            className="mr-2 ml-2 text-secondary"
                          >
                            <EditButton
                              className="fa fa-edit"
                              onClick={(e) => {
                                setEdit(true);
                              }}
                            ></EditButton>
                          </Link>
                        )
                      )}
                    </h4>
                  )}

                  {handleCancel !== null && (
                    <div class="ml-auto">
                      <Link
                        onClick={() => {
                          handleCancel();
                          handleSet();
                        }}
                        class="btn  btn-light   ml-2"
                      >
                        <i
                          alt="Notes"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Close"
                          className="fa fa-times  "
                        ></i>
                      </Link>
                    </div>
                  )}

                  {titleButton && (
                    <div class="ml-auto">
                      <AddButton
                        onClick={onclick}
                        label={modalLabel}
                        modalClassName={modalClassName}
                        faIcon="fa fa-plus"
                        style="btn btn-xs btn-primary pull-right"
                      ></AddButton>
                    </div>
                  )}
                  {settings && (
                    <AddButton
                      onClick={settingsOnclick}
                      label={settingsLabel}
                      style="btn btn-primary btn-xs  m-r-sm"
                    ></AddButton>
                  )}
                </div>
              </div>
            )}

            <div
              className={
                contentClassname
                  ? "card-body " + contentClassname
                  : "card-body "
              }
            >
              {searchList && (
                <Select
                  options={searchList}
                  placeholder="Search rate here"
                  components={{ DropdownIndicator: () => null }}
                  onKeyDown={onKeyDown}
                  onChange={onChange}
                  onBlur={handleKeyWordOnBlur && handleKeyWordOnBlur}
                  value={keyValue}
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      text: "black",
                      primary25: "white",
                      primary: "#1ab394",
                      neutral0: "white",
                    },
                  })}
                />
              )}
              <div className="row">
                <div className="col-lg-12 rates-table">{children}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </React.Fragment>
  );
};
//------------------------------------------------------------------------

const WidgetContainer = (props) => (
  <React.Fragment>
    <div className={props.box_size}>
      <div class="card ">
        <div class="card-body">
          <div
            style={{
              border: "1px dotted #1ab394",
              margin: 10,
              paddingBottom: 15,
            }}
          >
            <div style={{ textAlign: "center" }} className="row">
              <h5>
                <span className="m-l-xs">{props.label}</span>
              </h5>
              {props.icon && <i class={props.icon + " fa-2x"}></i>}
              <h6>
                <strong>
                  {props.tagline} : {props.count}
                </strong>
              </h6>
            </div>
            <br />
          </div>
          <div className="row">
            {props.dashboard === true && (
              <>
                <DeleteButton
                  className="fa fa-trash float-right text-danger m-t-m"
                  onClick={props.onDeleteClick}
                ></DeleteButton>
                <EditButton
                  className="fa fa-edit float-right m-r-xs m-t-m text-success"
                  onClick={props.onClick}
                ></EditButton>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

//--------------------------------------------Modal Header  -------------------------------------//
const ModalHeader = (props) => (
  <React.Fragment>
    <center>
      <div className="modal-header">
        <i className={props.favIcon}></i>
        <h5 className="modal-title">{props.title}</h5>
        <small class="font-bold">{props.label}</small>
      </div>
    </center>
  </React.Fragment>
);

const PaymentBox = (props) => (
  <>
    <div
      style={{
        border: "1px solid #e7eaec",
        backgroundColor: "#ffffff",
      }}
      className="widget lazur-outline p-md text-center"
    >
      <div class="m-b-md">
        <i class={props.icon}></i>
        <h4 class="m-xs">{props.title}</h4>
        <h4 class="font-bold no-margins">{props.h2}</h4>
        {props.children}
      </div>
    </div>
  </>
);

//------Booststrap Column col-lg -----------------------------------
const FormColumnLg = ({
  col = null,
  style = null,
  visible = true,
  children,
}) => (
  <>
    {visible === true && (
      <div
        className={col !== null ? "col-lg-" + col : "col-lg-12"}
        style={style}
      >
        {children}
      </div>
    )}
  </>
);
//------Booststrap Column col-sm -----------------------------------
const FormColumnSm = (props) => (
  <React.Fragment>
    <div
      className={props.col ? "col-sm-" + props.col : "col-sm-12"}
      style={props.style}
    >
      {props.children}
    </div>
  </React.Fragment>
);
//------Grid action container-------//
const GridActionContainer = (props) => (
  <div className="row mx-0 my-3 ">{props.children}</div>
);

//------Booststrap Column col-md -----------------------------------
const FormColumnMd = (props) => (
  <React.Fragment>
    {props.noCard ? (
      props.children
    ) : (
      <div
        className={
          props.col
            ? "col-md-" + props.col + " " + (props.class ? props.class : "")
            : "col-md-12"
        }
        style={props.style}
      >
        {props.children}
      </div>
    )}
  </React.Fragment>
);

const RateTabContent = ({
  activeClass,
  bpRateVisible = false,
  headOfficeRateVisible = false,
}) => (
  <React.Fragment>
    <ul className="nav nav-tabs mb-3" role="tablist">
      <li>
        <a className="nav-link active" data-toggle="tab" href="#rtab-1">
          Customer Rates&nbsp;
        </a>
      </li>
      <li>
        <a className="nav-link" data-toggle="tab" href="#rtab-2" id="supTab">
          Supplier Rates&nbsp;
        </a>
      </li>
      <li>
        <a className="nav-link" data-toggle="tab" href="#rtab-3">
          General Settings
        </a>
      </li>
      {bpRateVisible && (
        <li>
          <a className="nav-link" data-toggle="tab" href="#rtab-4">
            BP Rates
          </a>
        </li>
      )}
      {headOfficeRateVisible && (
        <li>
          <a className="nav-link" data-toggle="tab" href="#rtab-5">
            Head Office Settings
          </a>
        </li>
      )}
    </ul>
  </React.Fragment>
);
const TabPanel = ({ tabId, children, activeClass }) => (
  <React.Fragment>
    <div
      role="tabpanel"
      id={tabId}
      className={activeClass ? "tab-pane " + activeClass : "tab-pane "}
    >
      <div className="">{children}</div>
    </div>
  </React.Fragment>
);

const RatePercentHeadings = ({ ep, showAction }) => (
  <React.Fragment>
    <thead>
      <tr>
        <th width="20%">From</th>
        <th width="20%"> To</th>
        <th width="40%">Rate</th>
        {showAction && <th width="20%">Action</th>}
      </tr>
    </thead>
  </React.Fragment>
);
const RatePercentContent = ({ ep }) => (
  <React.Fragment>
    <tr>
      <td>
        <a className="text-primary" href="#">
          1
        </a>
      </td>
      <td>10</td>
      <td>2.5</td>
      <td>
        <DeleteButton></DeleteButton>
      </td>
    </tr>
  </React.Fragment>
);

const RateFeeRows = ({ heading, placeHolder, textName, checked, ep }) => (
  <React.Fragment>
    <tr>
      <td>{heading}</td>
      <td>
        {" "}
        <TextInput placeholder={placeHolder} name={textName}></TextInput>{" "}
      </td>

      <td style={{ visibility: ep }}>
        {" "}
        <div className="checkbox checkbox-default">
          <Checkbox
            checkboxClass="icheckbox_minimal-grey"
            increaseArea="20%"
            checked={checked ? "checked" : ""}
          />
          <label for="checkbox8">EP</label>
        </div>
      </td>
    </tr>
  </React.Fragment>
);

const DottedLine = ({}) => (
  <React.Fragment>
    <div
      style={{ borderTop: "1px dotted #ccc", paddingTop: 25, marginBottom: 10 }}
      className="row"
    >
      <div className="col-sm-8"></div>
    </div>
  </React.Fragment>
);

const dottedStyle = {
  control: (base) => ({
    ...base,
    borderTop: "1px dotted #ccc",
    paddingTop: 25,
  }),
};
//-------------------------------------------Invoice Approval button for customer/supplier ------------------------------//

const ApprovalButton = ({
  className,
  add_new,
  label,
  disabled = false,
  style = {},
}) => (
  <React.Fragment>
    <GeneralButton
      name={label ? label : "Approve"}
      className={className ? className : "btn btn-outline-primary mr-2"}
      disabled={disabled}
      onClick={add_new}
      style={style}
    ></GeneralButton>
  </React.Fragment>
);
//-------------------------------------------Invoice Approval button for customer/supplier ------------------------------//

const RejectButton = ({ userType, add_new, label, disabled = false }) => (
  <React.Fragment>
    <GeneralButton
      name={label ? label : "Reject"}
      className="btn btn-outline-danger  mr-2"
      disabled={disabled}
      onClick={add_new}
    ></GeneralButton>
  </React.Fragment>
);

//-------------------------------------------Invoice status update button for Business Partner ------------------------------//

const StatusUpdateButton = ({ update_status, category, status }) => (
  <React.Fragment>
    {/* {category == "Business Partner" &&
      status == "Invoice approved for payment" && ( */}
    {/* {
        status == "Invoice approved for payment" && ( */}
    <GeneralButton
      name="Update Invoice Status"
      className="btn btn-primary btn-outline "
      icon="fa fa-sync"
      onClick={update_status}
    ></GeneralButton>
    {/* )} */}
  </React.Fragment>
);

const IBox = (props) => (
  <React.Fragment>
    {/* <div className={props.class == "" ? "card" : props.class}>*/}
    <div className="card overflow-visible">
      <div className="card-header">
        <h6> {props.heading}
        {props.subHeading && props.subHeading}
        </h6>
        {props.icon && (
          <div class="ibox-tools">
            <a class="collapse-link">
              <i class={props.icon}></i>
            </a>
          </div>
        )}
      </div>
      <div className="card-body overflow-visible">
        {!props.division && (
          <div className="col-lg-12">
            <div className="row">
              <div className="col-sm-12">{props.children}</div>
            </div>
          </div>
        )}
        {props.division && <>{props.children}</>}
      </div>
    </div>
  </React.Fragment>
);
const CustomerApprovalBox = (props) => (
  <React.Fragment>
    <div className={props.box_size}>
      <div class="card ">
        <div class="card-header">
          <div
            style={{
              border: "1px dotted #1ab394",
              margin: 10,
              paddingBottom: 15,
            }}
          >
            <div style={{ textAlign: "center" }} className="row">
              <h1 className="no-margin text-success">
                {" "}
                <Link
                  to={{
                    pathname: "/supply-chain/invoices",
                    state: props.status,
                  }}
                >
                  {props.value}
                </Link>
              </h1>
              <h5>
                {" "}
                {props.icon && <i class={props.icon}></i>} {props.label}
              </h5>
            </div>
            <br />
            <div className="row">
              <div
                className={props.for == "Supplier" ? "col-lg-7" : "col-lg-6"}
              >
                {(props.role == props.category ||
                  props.role == "Business Partner") && (
                  <GeneralButton
                    name="Add New"
                    className="btn  btn-primary btn-sm float-right"
                    icon="fa fa-plus"
                    onClick={props.add_new}
                  ></GeneralButton>
                )}
              </div>
              {props.category == "Customer" && (
                <div className="col-lg-6">
                  <Link to={{ pathname: "/supply-chain/bulk-upload" }}>
                    <GeneralButton
                      name="Bulk Upload"
                      className="btn btn-primary  btn-sm float-left"
                      icon="fa fa-upload"
                      onClick={props.handleUpload}
                    ></GeneralButton>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            {props.dashboard === true && (
              <>
                <DeleteButton
                  className="fa fa-trash float-right text-danger m-t-m"
                  onClick={props.onDeleteClick}
                ></DeleteButton>
                <EditButton
                  className="fa fa-edit float-right m-r-xs m-t-m text-success"
                  onClick={props.onClick}
                ></EditButton>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

//------------------------------------------------------------------------

const StatusCountBox = (props) => (
  <React.Fragment>
    <div className={props.box_size}>
      <div class="card ">
        {/* <div className="card-header">
          <h5> {props.icon && <i class={props.icon}></i>}</h5>
        </div> */}
        <div class="card-body">
          <div
            style={{
              border: "1px dotted #1ab394",
              margin: 10,
              paddingBottom: 15,
            }}
          >
            <div style={{ textAlign: "center" }} className="row">
              <h1 className="no-margin text-success">
                <Link
                  to={{
                    pathname: "/supply-chain/invoices",
                    state: props.status,
                  }}
                >
                  {props.value}
                </Link>
              </h1>
              <h5> {props.label}</h5>
              {props.icon && <i class={props.icon + " fa-2x"}></i>}
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <center>
                  <Link
                    to={{
                      pathname: "/supply-chain/invoices",
                      state: props.status,
                    }}
                  >
                    <GeneralButton
                      name="View All"
                      className="btn btn-primary btn-sm float-left"
                      icon="fa fa-list"
                      onClick={props.handleUpload}
                    ></GeneralButton>
                  </Link>
                </center>
              </div>
            </div>
          </div>
          <div className="row">
            {props.dashboard === true && (
              <>
                <DeleteButton
                  className="fa fa-trash float-right text-danger m-t-m"
                  onClick={props.onDeleteClick}
                ></DeleteButton>
                <EditButton
                  className="fa fa-edit float-right m-r-xs m-t-m text-success"
                  onClick={props.onClick}
                ></EditButton>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);
//--------------------------------------------------------------------------------//

const StatusSumBox = (props) => (
  <React.Fragment>
    <div className={props.box_size}>
      <div class="card ">
        {/* <div className="card-header">
          <h5> {props.icon && <i class={props.icon}></i>}</h5>
        </div> */}
        <div class="card-body">
          <div
            style={{
              border: "1px dotted #1ab394",
              margin: 10,
              paddingBottom: 15,
            }}
          >
            <div style={{ textAlign: "center" }} className="row">
              <h1 className="no-margin text-success">
                <Link
                  to={{
                    pathname: "/supply-chain/invoices",
                    state: props.status,
                  }}
                >
                  {props.value}
                </Link>
              </h1>
              <h5>
                {" "}
                <span className="m-l-xs">{props.label}</span>
              </h5>
              {props.icon && <i class={props.icon + " fa-2x"}></i>}
              <h6>
                <strong>
                  {props.tagline} : {props.count}
                </strong>
              </h6>
            </div>
            <br />
          </div>
          <div className="row">
            {props.dashboard === true && (
              <>
                <DeleteButton
                  className="fa fa-trash float-right text-danger m-t-m"
                  onClick={props.onDeleteClick}
                ></DeleteButton>
                <EditButton
                  className="fa fa-edit float-right m-r-xs m-t-m text-success"
                  onClick={props.onClick}
                ></EditButton>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);
//------------------------------------------------------------------------------//
const CustomerPaymentBox = (props) => (
  <React.Fragment>
    <div className={props.box_size}>
      <div class="card ">
        <div class="card-body">
          <div
            style={{
              border: "1px dotted #1ab394",
              margin: 10,
              paddingBottom: 15,
            }}
          >
            <div style={{ textAlign: "center" }} className="row">
              <h1 className="no-margin text-success">{props.value}</h1>
              <h5>{props.label}</h5>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <center>
                  <Link to={{ pathname: "/supply-chain/invoices" }}>
                    <GeneralButton
                      name="View All"
                      className="btn btn-primary btn-sm float-left"
                      icon="fa fa-list"
                      onClick={props.handleUpload}
                    ></GeneralButton>
                  </Link>
                </center>
              </div>
            </div>
          </div>

          <div className="row">
            {props.dashboard === true && (
              <>
                <DeleteButton
                  className="fa fa-trash float-right text-danger m-t-m"
                  onClick={props.onDeleteClick}
                ></DeleteButton>
                <EditButton
                  className="fa fa-edit float-right m-r-xs m-t-m text-success"
                  onClick={props.onClick}
                ></EditButton>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

const UL = (props) => (
  <>
    <ul className={props.className}>{props.children}</ul>
  </>
);
const LI = (props) => (
  <>
    <li className={props.className ? props.className : "list-group-item"}>
      {props.children}
    </li>
  </>
);

const InvoiceTabList = (props) => (
  <React.Fragment>
    <ul className="nav nav-tabs" role="tablist">
      {props.list.map((item) => {
        return (
          <li
            className={item.trans_status_guid == props.selectedTab && "active"}
          >
            <a
              className="nav-link"
              data-toggle="tab"
              onClick={() => props.onClick(item.trans_status_guid)}
            >
              {item.status_name}&nbsp;&nbsp;
              <span className="label label-default">
                {props.disableCount ? "" : item.count}
              </span>
            </a>
          </li>
        );
      })}
      <li className={props.selectedTab == "" && "active"}>
        <a
          className="nav-link active"
          data-toggle="tab"
          onClick={() => props.onClick("")}
        >
          All&nbsp;&nbsp;
          <span className="label label-default">
            {props.disableCount ? "" : props.total}
          </span>
        </a>
      </li>
    </ul>
  </React.Fragment>
);
//*******************************************Invoice bulk approval tab************************************************************** */
const BulkApproveTabList = (props) => (
  <React.Fragment>
    <ul className="nav nav-tabs" role="tablist">
      {props.list.map((item) => {
        return (
          <li className={item.type == props.selectedTab && "active "}>
            <a
              className="nav-link"
              data-toggle="tab"
              onClick={() => props.onClick(item.type)}
            >
              <span
                className={`font-weight-bold text-secondary ${
                  item.type == props.selectedTab &&
                  "active border-bottom border-primary p-3"
                }`}
              >
                {item.type_name}&nbsp;&nbsp;
                <label
                  className={`label label-${item.color ? item.color : "info"}`}
                >
                  {props.disableCount ? "" : item.count}
                </label>
              </span>
            </a>
          </li>
        );
      })}
    </ul>
  </React.Fragment>
);

const ClientportalBox = (props) => (
  <React.Fragment>
    <div
      className={props.box_size}
      style={{ backgroundColor: "#1ab394", paddingTop: "1%" }}
    >
      <div class="card float-e-margins">
        <div className="card-header">
          <Link to={{ pathname: props.linkTo, state: props.status }}>
            <span className="label label-success pull-right">
              {props.count} Invoices
            </span>
          </Link>
          <h5>{props.heading}</h5>
        </div>
        <div className="card-body">
          <Link to={{ pathname: props.linkTo, state: props.status }}>
            <h1 className="no-margins">{props.sum}</h1>
          </Link>
          <div className="stat-percent font-bold text-info">
            <AddButton
              className="btn btn-primary btn-outline btn-xs"
              onClick={props.onClick}
              label="Add New"
            ></AddButton>
          </div>
          <small>Total amount</small>
        </div>
      </div>
    </div>
  </React.Fragment>
);

const ClientBoxes = (props) => (
  <React.Fragment>
    <h2 className="mb-3">
      {" "}
      <CurrencyLabel value={props.sum}></CurrencyLabel>
    </h2>
    <p className="mb-1">
      <Link to={{ pathname: props.linkTo, state: props.status }}>
        <strong>{props.heading}</strong>
      </Link>
    </p>
    <p className="small-txt">
      Total Invoices:{" "}
      <Link to={{ pathname: props.linkTo, state: props.status }}>
        {props.count}
      </Link>
    </p>
  </React.Fragment>
);
const ClientSupplierBoxes = (props) => (
  <React.Fragment>
    <h2 className="mb-3">
      <Link
        className="color-white"
        to={{ pathname: props.linkTo, state: props.status }}
      >
        {props.count}
      </Link>
    </h2>
    <p className="mb-1">
      <strong>
        <Link to={{ pathname: props.linkTo, state: props.status }}>
          {props.heading}
        </Link>
      </strong>
    </p>
  </React.Fragment>
);
const ActionTableContainer = (props) => (
  <>
    <div className="panel blank-panel">
      <div className="panel-body">
        <div className="tab-content">
          <div className="col-lg-12">
            <div className="card float-e-margins">
              <div className="card-header">
                <span className="pull-right">
                  {props.actionButtons && props.actionButtons}
                </span>
                <h5>{props.title && props.title}</h5>
              </div>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const InfoBox = (props) => (
  <>
    <FormColumnMd col={props.col}>
      <FormRow className="widget style1">
        <FormRow>
          <FormRow
            className={
              props.childClass ? "text-left " + props.childClass : "text-left"
            }
          >
            <span class="quick-label">{props.heading}</span>
            <h3 class={props.hclass ? props.hclass : ""}>
              {props.label ? (
                props.value
              ) : (
                <CurrencyLabel value={props.value}></CurrencyLabel>
              )}
            </h3>
          </FormRow>
        </FormRow>
      </FormRow>
    </FormColumnMd>
  </>
);

const InputGroup = (props) => (
  <>
    <FormRow
      className={props.outerRow ? props.outerRow : "form-group row m-t-sm"}
    >
      <label
        className={`col-sm-${
          props.labelCol ? props.labelCol : 4
        } col-form-label`}
      >
        {props.label}
      </label>

      <FormColumnLg col={props.inputCol ? props.inputCol : 8}>
        {props.children}
      </FormColumnLg>
    </FormRow>
  </>
);

const InputGroupMd = (props) => (
  <>
    <FormRow
      className={props.outerRow ? props.outerRow : "form-group row m-t-sm"}
    >
      <label
        className={`col-sm-${
          props.labelCol ? props.labelCol : 4
        } col-form-label`}
      >
        {props.mandatory ? (
          <span className="form-group required">
            <span className="control-label">{props.label}</span>
          </span>
        ) : (
          props.label
        )}
      </label>
      <FormColumnLg col={props.inputCol ? props.inputCol : 6}>
        {props.children}
      </FormColumnLg>
    </FormRow>
  </>
);
const InputGroupSplit = (props) => (
  <>
    <div className="row">
      <div className="col-lg-6">
        <div className="form-group">
          <label>{props.label}</label>
          <div> {props.left}</div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="form-group">
          <label>{props.rightLabel}</label>
          <div>{props.right}</div>
        </div>
      </div>
    </div>
    {/*  <FormRow className={props.outerRow ? props.outerRow : "form-group  row m-t-sm"}>
      <label className={`col-sm-${props.labelCol ? props.labelCol : 4} col-form-label`}>{props.label}</label>
      <FormColumnLg col={props.inputColLeft ? props.inputColLeft : 3}>
        {props.left}
      </FormColumnLg>
      <FormColumnLg col={props.inputColRight ? props.inputColRight : 3}>
        {props.right}
      </FormColumnLg>
</FormRow>*/}
  </>
);

const TermsConditions = (props) => (
  <>
    <div className="row">
      <div className="col-lg-12 text-center">
        <small className=" text-muted">
          Subject to Fifopay{" "}
          <a className="text-primary font-medium" href="#">
            Terms and Conditions
          </a>
        </small>
      </div>
    </div>
  </>
);
const FifoPay = ({ payNow, disable }) => (
  <React.Fragment>
    {/*  <button className={disable ? " btn btn-sm btn-secondary ml-3 disabled" : "btn btn-sm btn-secondary ml-3 "} onClick={payNow}>&nbsp; Pay Me Now
 <button className={disable ? " btn fifo-global-btn fifo-global-btn-disabled" : "btn fifo-global-btn"} onClick={payNow}><img src={PayNowIcon} width="18px" />&nbsp;Pay Me Now 
    </button>*/}
    <button
      type="button"
      onClick={payNow}
      class=" btn btn-secondary ml-2 rounded-full  align-items-center px-3 py-2"
    >
      <div class="d-flex">
        <img
          src="http://fifodev.ndz.co.in/Plus/logo/brand-new/fifopay-logo-new-icon-LIGHT.svg"
          class=" mr-2"
          alt="..."
          width="16"
        ></img>
        <h6 class="mb-0 text-left">
          <span class="text-sm">Pay Me Now</span>{" "}
        </h6>
      </div>
    </button>
  </React.Fragment>
);

const ReportInputFieldContainer = ({
  className = "col-md-2 col-lg-2",
  label = "",
  children,
  show = true,
}) => (
  <>
    {show ? (
      <>
        <div class="col-md-2 col-lg-2">
          <div class="form-group">
            <label>{label}</label>
            {children}
          </div>
        </div>
      </>
    ) : (
      <>
        <label>{label}</label>
        {children}
      </>
    )}
  </>
);
const ModalContainer = (props) => (
  <>
    <Modal.Body></Modal.Body>
  </>
);

export {
  Panel,
  FormContainer,
  GridContainer,
  TabGridContainer,
  EntityTabList,
  TransactionTabList,
  ClientInvoiceTabList,
  TabContent,
  ProspectTabList,
  UserTypesTabList,
  TabList,
  FormRow,
  FormGroup,
  FormColumn,
  FormWrapper,
  CircleShape,
  ReportGridContainer,
  ReportsGrid,
  ReportButtons,
  ReportDownloadButtons,
  BpTabList,
  WidgetBox,
  WidgetBox2,
  FormContainerWithLink,
  InvestmentPartnerTransTabList,
  TimelineContainer,
  CommonContainer,
  DashboardContainer,
  FieldContainer,
  BoxContainer,
  WidgetContainer,
  ModalHeader,
  PaymentBox,
  FormColumnLg,
  FormColumnSm,
  RateTabContent,
  TabPanel,
  RatePercentHeadings,
  RatePercentContent,
  RateFeeRows,
  DottedLine,
  BulkApproveTabList,
  InvoiceTabList,
  StatusUpdateButton,
  ApprovalButton,
  RejectButton,
  IBox,
  CustomerApprovalBox,
  CustomerPaymentBox,
  StatusCountBox,
  StatusSumBox,
  UL,
  LI,
  FormColumnMd,
  GridActionContainer,
  ClientportalBox,
  ClientBoxes,
  ClientSupplierBoxes,
  ActionTableContainer,
  InfoBox,
  InputGroup,
  TermsConditions,
  FifoPay,
  InputGroupMd,
  InputGroupSplit,
  ReportInputFieldContainer,
  ModalContainer,
};
