import React, { useEffect, useState, useContext } from "react";
import useApi from "../../../../hooks/useApi";
import LoanService from "../../../loan/services/loanTransactions";
import useIsMounted from "../../../../hooks/useIsMounted";
import CardSkelton from "../../components/cards/_cardSkelton";
import DoughnutCard from "../general/doughnutCard";
import auth from "../../../../services/authService";
import EntityContext from "../../../../context/entityContext";

function LoanCommon({ nickName, feciltyCount, menu = null, onClick = null, to = "/client/home" }) {

  const isMounted = useIsMounted();
  const { constants } = auth.getCurrentUser();
  const { accountDetails } = useContext(EntityContext);
  const amountOverviewApi = useApi(LoanService.getLoanAmountOverview);
  const [graphData, setGraphData] = useState([]);
  const [bankDetail, setBankDetail] = useState(null);
  //const [loanLimit, setLoanLimit] = useState(0);
  const [openLoanAmnt, setOpenLoanAmnt] = useState(0);
  const [paymentPending, setPaymentPending] = useState(0);

  const fetchData = async () => {

    const { data } = await amountOverviewApi.request({
      clientReportType: "true",
    });

    if (isMounted()) {
      const graphValues = [
        {
          name: "Open Loan",
          value: data.response.total_loan ? data.response.total_loan : 0,
          icon: "fa-folder-open-o",
          color: "secondary",
          border: "rgb(25, 69, 107)",
          bg: "rgb(25, 69, 107)",
        },
        {
          name: "Principle Paid",
          value: data.response.paid_amount ? data.response.paid_amount : 0,
          icon: "fa-check-square-o",
          color: "warning",
          border: "rgba(255, 200, 92, 1)",
          bg: "rgba(255, 200, 92, 1)"
        },
      ];

      setGraphData(graphValues);
      //setLoanLimit(data.response.limit);
      setOpenLoanAmnt(data.response.total_loan);
      setPaymentPending(data.response.pending_payment);
    }
  };
  //------------------------------------------------------------
  useEffect(() => {
    fetchData();
  }, []);

  //------------------------------------------------------------
  useEffect(() => {
    const accountInfo = accountDetails.find(item => item.product == constants.PRODUCTS.LOAN);
    setBankDetail(accountInfo ? accountInfo : null);
  }, [accountDetails]);

  return (
    <>
      <div className={`col-sm-6 col-lg-4 col-xl-4 align-center card-hover ${!bankDetail ? 'disabled-card' : ''}`}>
        {amountOverviewApi.loading === true ? (
          <CardSkelton col="12" type="graph"></CardSkelton>
        ) : graphData && paymentPending ? (
          <>
            <DoughnutCard
              graphData={graphData}
              type={"show_bank_detail"}
              fecilityLimit={paymentPending}
              bgColor="primary"
              empty={false}
              account_type={1}
              feciltyCount={feciltyCount}
              description={"Your Loan fecility"}
              menu={menu}
              //onClick={onClick}
              to={to}
              bankDetail={bankDetail}
              nickName={nickName}
            ></DoughnutCard>
          </>
        ) : (
          <DoughnutCard
            graphData={[]}
            type={"show_bank_detail"}
            bankDetail={bankDetail}
            bgColor="primary"
            empty={true}
            account_type={1}
            feciltyCount={feciltyCount}
            //onClick={onClick}
            to={to}
            description={"Your Loan fecility"}
            menu={menu}
            nickName={nickName}
          ></DoughnutCard>
        )}
      </div>
    </>
  );
}
export default LoanCommon;
