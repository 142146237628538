import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import { GeneralButton, TextInput } from "../../common/inputs";
import entityContext from "../../../context/entityContext";
import entityService from "../../../services/entityService";
import authService from "../../../services/authService";
import useApi from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { Link } from "react-router";
function BankDetails() {
  //------------Api Services--------------------------------------------
  const contactApi = useApi(entityService.getEntityContactsList);
  const { entityDetails } = useContext(entityContext);
  const { entity_id } = authService.getCurrentUser();

  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      bank_account_name: Joi.string()
        .required()
        .error(() => {
          return { message: "Please enter a Account" };
        }),
      account_number: Joi.string()
        .required()
        .error(() => {
          return { message: "Please enter a Account Number" };
        }),
      bsb_number: Joi.string()
        .required()
        .label("Phone No.")
        .error(() => {
          return { message: "Please enter a BSB number." };
        }),
    })
    .unknown(true);

  //------------Api Services--------------------------------------------
  const saveEntityCoreFields = useApi(entityService.saveEntityCoreFields);

  //------------------Save Entity Details-----------------------------
  const saveEntityContact = async () => {
    let reqValues = { ...values, a_guid: entity_id };
    const { data: res } = await saveEntityCoreFields.request(reqValues); // Sending Async request to server
    if (res.success === true) {
      toast.success("Bank Account Details Updated");
    } else toast.error(res.message);
  };


  //------------------Custom hook to handle form data-----------------------------
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
  } = useForm(saveEntityContact, schema); // Custom Hook to handle Form Validation

  const fetchPopulateData = async () => {
    const { bank_account_name, account_number, bsb_number } = entityDetails.additionalAddress;

    const verified_status = entityDetails.verified_status;
    const authorised_status = entityDetails.authorised_status;
    setValues({ ...values, bank_account_name, account_number, bsb_number, verified_status, authorised_status });
  };
  useEffect(() => {
    fetchPopulateData();
  }, []);
  return (
    <>
      <Helmet>
        <title>Bank Account | Fifopay</title>
      </Helmet>
      {contactApi.loading == true && <LoadingSpinner />}

      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-6"><h6>Update Your Bank Account</h6></div>
            <div className="col-lg-6">
              <Link className="btn btn-light pull-right text-secondary ml-2 cursor-link" to="/client/settings"><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 p-4">

              <form onSubmit={handleSubmit} className="form-horizontal">
                <TextInput
                  name="bank_account_name"
                  label="Account Name"
                  value={values.bank_account_name}
                  onChange={handleChange}
                  type="horizontal"
                ></TextInput>

                <TextInput
                  name="account_number"
                  label="Account Number"
                  value={values.account_number}
                  onChange={handleChange}
                  type="horizontal"
                ></TextInput>

                <TextInput
                  name="bsb_number"
                  label="BSB Number"
                  value={values.bsb_number}
                  onChange={handleChange}
                  type="horizontal"
                ></TextInput>
                <div className="form-group row mt-4">
                  <div className="col-lg-4 form-label">
                    <p>Status</p>
                  </div>
                  <div className="col-lg-8">
                    <span className="font-weight-bold "> {`Bank Account ${values.authorised_status === true ? 'Authorised' :
                      values.verified_status === true ? 'Not Authorised' : 'Not Verified'
                      } `}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <GeneralButton
                      className="btn btn-primary btn-sm float-right  "

                      name="Update"
                    >
                    </GeneralButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BankDetails;