import React from "react";
import SetupIcon from "../../../../img/icons/set-up.svg"
import { Helmet } from "react-helmet";
import { Link } from "react-router";
export default function ProcessingFacility() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Facility Processing | {global.config.title}</title>
            </Helmet>

            <div className="col-lg-12  m-auto">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-10">

                            </div>
                            <div className="col-lg-2">
                                <Link title="Close and go to dashboard" className="btn btn-light pull-right text-secondary" to="/client/home"><i className="fa fa-close pull-right"></i></Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 col-lg-12 m-auto text-center pt-3">
                                <img src={SetupIcon} className="img-fluid"></img>
                            </div>

                            <div className="col-lg-8 m-auto text-center py-4">
                                <h3 className="font-weight-bold ">Processing Facility Setup</h3>
                                <p>We will notify you when it is ready.</p>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-4">
                            <a href="https://documents.fifopay.com/en/articles/6513287-processing-facility-setup" target="_blank">
                                <button type="button" className="btn btn-sm btn-secondary">Learn More</button>
                            </a>
                        </div>

                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}