import React from "react";
import startIcon4 from "../../.././../img/icons/FMB_icon4.svg";

export default function Page18({ goNext }) {

    return (
        <>
            <div id="animation_box" className="animated fadeIn">
                <div id="animation_box" className="animated fadeIn">
                    <div className="mb-0 "><div className="card-body">
                        <div className="row min-vh-70 justify-content-center align-items-center py-5">
                            <div className="col-lg-2  col-md-12">
                                <div className="operate-image">
                                    <img src={startIcon4} alt="image" width className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-12">
                                <div className>

                                    {/* <span className="text-success mb-3 font-medium">Let’s get started…
                                    </span> */}
                                    <h4 className="mt-3">Intuitive working capital solutions… It can’t get any easier
                                    </h4>
                                    <small>With our flexible working capital solutions you can meet your financial needs. Select one or more facilities to apply, and we’ll help you find the best solution for your business   </small>

                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <div className="d-block pt-3 text-center w-100 ml-0">
                                                    <div className="card  b-card aligin-center">
                                                        <div className="card-body">
                                                            <span className="b-corner b-corner-r b-corner-secondary"></span>
                                                            <div className=" justify-content-between">
                                                                <div>
                                                                    <h5 className="card-title-new">Trade Finance</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row text-center bg-white">
                                                                <div className="col-md-12">
                                                                    <div className="align-center relative my-2 mb-4">
                                                                        <p className="card-new-sub">Use to pay your local and overseas suppliers </p>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="align-center relative my-1">
                                                                        <p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i>Make Deposit Payment </p>
                                                                    </div>
                                                                    <div className="align-center relative my-1">
                                                                        <p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i>Use For Pre-shipment Payments</p>
                                                                    </div>
                                                                    <div className="align-center relative my-1">
                                                                        <p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i>Purchase Goods and Services</p>
                                                                    </div>
                                                                    <div className="align-center relative my-1"><p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i>Enjoy up to 150 day terms</p>
                                                                    </div>
                                                                    <hr />


                                                                </div>

                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="  mb-2  m-auto"><a><button onClick={() => goNext('workingCapitalChoose')} type="button" className="btn btn-secondary align-center mt-3">Apply</button></a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4"><div className="form-group"><div className="d-block pt-3 text-center w-100 ml-0"><div className="card b-card  aligin-center"><div className="card-body">
                                            <span className="b-corner b-corner-r b-corner-success"></span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h5 className=" card-title-new text-primary">Supply Chain Finance</h5>
                                                </div>
                                            </div>
                                            <div className="row text-center bg-white">
                                                <div className="col-md-12">
                                                    <div className="align-center relative my-2 mb-4">
                                                        <p className="card-new-sub2">Use with your local and overseas suppliers </p>
                                                    </div>
                                                    <hr />
                                                    <div className="align-center relative my-1">
                                                        <p className="mb-3 carde-new-p">
                                                            <i className="fa fa-check" aria-hidden="true"></i> Use for Supplier Invoices
                                                        </p>
                                                    </div>
                                                    <div className="align-center relative my-1">
                                                        <p className="mb-3 carde-new-p">
                                                            <i className="fa fa-check" aria-hidden="true"></i> Fuels Significant Purchase Discounts
                                                        </p>
                                                    </div>
                                                    <div className="align-center relative my-1">
                                                        <p className="mb-3 carde-new-p">
                                                            <i className="fa fa-check" aria-hidden="true"></i> Earn Rebates and Cash Back
                                                        </p>
                                                    </div>
                                                    <div className="align-center relative my-1">
                                                        <p className="mb-3 carde-new-p">
                                                            <i className="fa fa-check" aria-hidden="true"></i> Enjoy up to 90 day terms
                                                        </p>
                                                    </div>
                                                    <hr />
                                                </div>

                                            </div>
                                            <div className="row mt-4">
                                                <div className="  mb-2  m-auto">
                                                    <a>
                                                        <button onClick={() => goNext('workingCapitalChoose')} type="button" className="btn btn-primary align-center mt-3">Apply</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        <div className="col-lg-4"><div className="form-group"><div className="d-block pt-3 text-center w-100 ml-0"><div className="card b-card  aligin-center"><div className="card-body">
                                            <span className="b-corner b-corner-r b-corner-warning"></span>
                                            <div className="justify-content-between">
                                                <div>
                                                    <h5 className=" card-title-new text-warning">Business Loan</h5>
                                                </div>
                                            </div>
                                            <div className="row text-center bg-white">
                                                <div className="col-md-12">
                                                    <div className="align-center relative my-2 mb-4">
                                                        <p className="card-new-sub3">Use with your local and overseas suppliers </p>
                                                    </div>
                                                    <hr />
                                                    <div className="align-center relative my-1">
                                                        <p className="mb-3 carde-new-p">
                                                            <i className="fa fa-check" aria-hidden="true"></i> Enjoy All Benefits and Features
                                                        </p>
                                                    </div>
                                                    <div className="align-center relative my-1">
                                                        <p className="mb-3 carde-new-p">
                                                            <i className="fa fa-check" aria-hidden="true"></i> All Purchases Covered
                                                        </p>
                                                    </div>
                                                    <div className="align-center relative my-1">
                                                        <p className="mb-3 carde-new-p">
                                                            <i className="fa fa-check" aria-hidden="true"></i> Optimise How You Pay Suppliers
                                                        </p>
                                                    </div>
                                                    <div className="align-center relative my-1">
                                                        <p className="mb-3 carde-new-p">
                                                            <i className="fa fa-check" aria-hidden="true"></i> Terms to Suit Your Requirements
                                                        </p>
                                                    </div>
                                                    <hr />
                                                </div>

                                            </div>
                                            <div className="row mt-1">
                                                <div className="  mb-2  m-auto">
                                                    <a>
                                                        <button type="button" onClick={() => goNext('workingCapitalChoose')} className="btn btn-warning align-center mt-3">Apply</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="col-10">
                                <div className="d-flex justify-content-end align-items-center mt-3">
                                    <a href="#" onClick={goPrevious} className="btn btn-light mr-1">Back</a><a href="#" onClick={() => goNext()} className="btn btn-primary ml-1">Continue</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    </div>
                </div>


                {/*  <GeneralButton pullRight="right" type="button" onClick={goPrevious} style={{ marginRight: "10px" }} className="btn btn-default btn-outline pull-right " name="Back"></GeneralButton>*/}
            </div >
        </>
    )
}

