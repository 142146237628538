import React from "react";
import "./css/card.css";
import { FormColumnMd } from "../../../common/containers/formContainer";
import { Link } from "react-router";
import CardSkelton from "./_cardSkelton";

function PrimaryWithButton({ loading, data, qrystring = false, addBtn = false,bulkUploadBtn=false,dropDownList=false }) {

    return (
        <>
            {loading === true ? <CardSkelton /> : (
                <FormColumnMd col={4}>
                    {/*Primary card*/}

                    <div className="card">
                        <div className="card-body">
                            <span className="b-corner b-corner-r b-corner-success" />
                            <div className="d-flex justify-content-between">
                                <h6 className="card-title ">{data ? data.topLabel : ""}</h6>
                                <div className="ml-auto">
                                {addBtn} 
                                {bulkUploadBtn}
                                {
                                    dropDownList && (
                                        <>
                                                <button className="btn btn-light m-l-sm" type="button" id="primry_btn_more_action" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                    <i className="fa fa-ellipsis-v pull-right  "></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    {dropDownList}
                                                </div>
                                        </>
                                    )
                                }
                                </div>
                            </div>
                            <div className="d-flex mb-0">
                                <div>
                                    <h4 className="mb-1 font-weight-bold">
                                        {qrystring === true ?
                                            <Link className="text-secondary" to={data ? data.primaryBtnRoute : ''}>{data ? data.topValue : ""}</Link> :
                                            <Link className="text-secondary" to={{ pathname: data ? data.primaryBtnRoute : '', state: data ? data.status : "" }}>{data ? data.topValue : ""}</Link>
                                        }

                                    </h4>
                                    <p className="mb-2 text-xs text-muted"><i className="mr-1" />{/*<span className="opacity-75">Pending your approval</span>*/}</p>
                                </div>
                                <div className="card-chart bg-warning-transparent brround ml-auto mt-0">
                                    <i className="typcn typcn-chart-line-outline text-warning tx-24 " />
                                </div>
                            </div>
                            <div>
                                <div className="progress progress-sm h-5 mt-2 mb-3">
                                    <div className="progress-bar bg-primary w-100 " role="progressbar" />

                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-6">
                                    <div className="d-flex align-items-end">

                                        <div>
                                            <h6>{data ? data.leftValue : ""}</h6>
                                            <p className="font-weight-semibold opacity-50 mb-0">{data ? data.leftLabel : ""}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-end">

                                        <div>
                                            <h6>{data ? data.rightValue : ""}</h6>
                                            <p className="font-weight-semibold opacity-50 mb-0">{data ? data.rightLabel : ""}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </FormColumnMd>
            )}
        </>
    );
}

export default React.memo(PrimaryWithButton);