import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { browserHistory } from 'react-router';

import auth from "../../../../services/authService";
import BatchUpload from "../../../debtorTransactions/components/BatchUpload";

class ClientBatchUpload extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    tableTitle: "Batch Upload",
  }

  handleTab = (tab, title = null) => {
    // this.setState({
    //   selectedTab: tab,
    //   tableTitle: title ? title : "Transactions",
    //   selectedRows: [],
    // });
    // if (this.tableRef.current) this.tableRef.current.reloadData();
  };

  handleCloseBulkUpload = () => {
    //const { history } = this.props;
    browserHistory.push("/client/wob/dashboard");
  }

  render() {

    return (
      <>
        <Helmet>
          <title>WoB Transactions | {global.config.title}</title>
        </Helmet>


        <div className="card overflow-visible">
          <div className="row align-items-center mx-0 px-4 pt-4">
            <div>
              <h4 className="card-title">{this.state.tableTitle}</h4>
            </div>
          </div>
          <div className="card-body">
            <BatchUpload 
            handleTab={this.handleTab}
            onUploadClose={this.handleCloseBulkUpload}
            ></BatchUpload>
          </div>
        </div>
      </>
    );
  }
}
export default ClientBatchUpload;
