import React, { useContext } from "react";
import {
  FormRow,
} from "../../../../common/containers/formContainer";
import { RadioButton } from "../../../../common/inputs/radioButton/radioButton";
import ApproveContext from "../../../../supply-chain/context/bulkApproveContext"; // React Context API

function ApprovalDecision() {

  const bulkapproveOptions = useContext(ApproveContext);
  const handleRadioButtonChange = ({ target: { name, value } }) => {
    bulkapproveOptions.setApproval(value);
    bulkapproveOptions.setProceedNext(true);
  };

  return (
    <>
      <FormRow
        className="widget lazur-outline p-lg text-center"
      >
        <FormRow className="row m-b-sm">
          <div className="col-lg-12">
            <label className="font-weight-bold">
              DO YOU WISH TO PAY THESE INVOICES LATER (EXTEND YOUR PAYMENT TERMS)
              ?*
            </label>
          </div>
        </FormRow>
        <FormRow>
          <div className="col-lg-3"></div>
          <div className="col-lg-3">
            <RadioButton
              name="extend_due_date"
              value="yes"
              checked={bulkapproveOptions.approval == "yes"}
              onChange={(e) => {
                handleRadioButtonChange(e);
              }}
              label="Yes"
              id="yes"
            ></RadioButton>
          </div>
          <div className="col-lg-3">
            <RadioButton
              name="extend_due_date"
              value="no"
              checked={bulkapproveOptions.approval == "no"}
              onChange={(e) => {
                handleRadioButtonChange(e);
              }}
              label="No"
              id="no"
            ></RadioButton>
          </div>

        </FormRow>
      </FormRow>



    </>
  );
}

export default ApprovalDecision;
