/**Approval of supplier-intiated invoice by customer */
import React, { useEffect } from "react";
import ReactTooltip from 'react-tooltip';
import { CurrencyLabel } from "../../../../common/inputs/currencyInput/currencyInput";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import useApi from "../../../hooks/useApi";
import commonService from "../../../services/commonService";

function BalanceView(props) {

  const invoices = props.invoices;
  const {
    data: { data },
    loading,
    request,
  } = useApi(commonService.getApprovalBalance);

  useEffect(() => {
    request({ invoices: invoices, entity_id: props.entity_id, product: props.product });
  }, [invoices]);

  return (
    <>
      {loading && <div className="col-lg-9 text-center" ><LoadingSpinner normal="false" /></div>}
      {data && (
        <>
          <div className="row">
            <div className="col-sm-6 col-lg-4"><h6 className="card-title">Account Balance</h6>
              <h4 className="mt-3 mb-1 font-weight-bold"><span><CurrencyLabel value={data.balanceAvailable}></CurrencyLabel></span></h4>
              <p data-tip="(Primary1 Account+Primary2 Account)" className="opacity-75"> ( <CurrencyLabel value={data.primary1}></CurrencyLabel> + <CurrencyLabel value={data.primary2}></CurrencyLabel>)  </p>
            </div>

            <div className="col-sm-6 col-lg-4"><h6 className="card-title">Total Transaction Value </h6>
              <h4 className="mt-3 mb-1 font-weight-bold">  <CurrencyLabel value={data["sum"]}></CurrencyLabel>  <span data-tip="Total Invoices Selected" className="text-sm text-muted">({data["count"]} )</span> </h4>
              <p className="opacity-75"> Total Transaction Value </p>
            </div>


            <div className="col-sm-6 col-lg-4"><h6 className="card-title">EST. NEW ACCOUNT BALANCE</h6>
              <h4 className="mt-3 mb-1 font-weight-bold"><span><CurrencyLabel value={data.balanceAvailable - data["sum"]} ></CurrencyLabel></span></h4>
              <p className="opacity-75">Total Transaction Value  </p>
            </div>
            <ReactTooltip backgroundColor="#11698e" />
          </div>
        </>
      )}

    </>
  );
}

export default BalanceView;
