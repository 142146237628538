import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import ExcelFileUploader from "../../../common/inputs/fileUploader/ExcelUploader";
import { FormGroup, FormRow } from "../../../common/containers/formContainer";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import dateParser from "../../../../utils/excelDateParser";
import {
  TextAreaInput,
  GeneralButton,
  CurrencyText,
  CurrencyLabel,
  DateTimePicker,
} from "../../../common/inputs";

import {
  AddButton,
} from "../../../common/containers/button";

import EntityContext from "../../../../context/entityContext";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import useApi from "../../../../hooks/useApi";
import { confirmAlert } from "react-confirm-alert";
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";
import authService from "../../../../services/authService";
import { Link } from "react-router";
import DFDebtorMapping from "../../../debtorTransactions/components/_debtorMapping";
import InvoiceErrors from "../../../debtorTransactions/components/_invoiceErrors";

export default function InvoiceReconsile() {
  const { entityDetails } = useContext(EntityContext);
  const [reconsileInvoice, setInvoices] = useState([]);
  const [batches, setBatch] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [dataDebtor, setDebtorDetail] = useState([]);
  const [values, setValue] = useState({});
  const [commentModal, setCommentModal] = useState(false);
  const [comment, setComment] = useState(null);
  const [indexVal, setIndexVal] = useState(null);

  const [totalVal, setTotalVal] = useState(null);
  const [commentLabel, setCommentLabel] = useState(null);

  const [newInvoice, setNewInvoice] = useState([]);
  const [debtors, setDebtors] = useState([]);
  const [refNo, setRefNumber] = useState("");
  const [transactionGuid, setTransactionGuid] = useState(null);

  const [customer, setCustomer] = useState(null);
  const [owner, setOwner] = useState(null);

  const [debtorModal, setDebtorModal] = useState(false);
  const [validationModal, setValidationModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [errors, setErrors] = useState([]);
  const [otherErrors, setOtherErrors] = useState([]);
  const [showInvoice, setShowInvoice] = useState(false);
  const [reconsileComplete, setReconsileComplete] = useState(false);

  const transactionApi = useApi(dfTransactionService.getInvoicesByStatus);
  const transactionBatchApi = useApi(dfTransactionService.TransactionByBatch);
  const transactionReconsileApi = useApi(
    dfTransactionService.reconsileInvoices
  );
  const transactionDebtorApi = useApi(
    dfTransactionService.getDebtorInvoiceByClient
  );
  const transactionDetailsApi = useApi(dfTransactionService.getTransactionList);
  const referenceApi = useApi(dfTransactionService.generateRefNum);
  const transactionInvApi = useApi(dfTransactionService.createDFTransaction);
  const createInvoiceApi = useApi(dfTransactionService.createInvoice);

  const fetchDebtorData = async (filter) => {
    const { data } = await transactionDebtorApi.request({
      entity_id: entityDetails.a_guid,
      dateFilter: filter,
    });

    let reconsilesArr = [];
    data.rows.map((item) => {
      reconsilesArr.push({
        index: reconsilesArr.length,
        entity_guid: item.debtor_guid,
        client_guid: item.client_guid,
        debtor_organisation: item.debtor_organisation,
        date: new Date(),
        inv_total: item.inv_total,
        reference: item.client_reference,
      });
    });
    setDebtorDetail(reconsilesArr);
  };

  const fetchBatch = async (filter) => {

    const { data } = await transactionDetailsApi.request({
      trans_client_guid: entityDetails.a_guid,
      trans_type: "open",
      dateFilter: filter,
    });

    let reconsilesArr = [];
    data.customers.map((item) => {
      reconsilesArr.push({
        index: reconsilesArr.length,
        checked: false,
        inv_total: Number(item.inv_total),
        trans_date: item.trans_date,
        credit_total: item.credit_total ? item.credit_total : 0,
        payment: item.payment_total,
        adjustment: null,
        comment: null,
        debtor_guid: item.inv_debtor_id,
        sub_total: Number(item.total),
        debtor_organisation: item.debtor_organisation,
        inv_guid: item.inv_guid,
        total: Number(item.total)
      });
    });

    setBatch(reconsilesArr);
  };

  const fetchInvoices = async (filter) => {
    const { data } = await transactionApi.request({
      entity_id: entityDetails.a_guid,
      dateFilter: filter,
    });

    let reconsilesArr = [];
    data.rows.map((item) => {
      reconsilesArr.push({
        index: reconsilesArr.length,
        checked: false,
        inv_guid: item.inv_guid,
        trans_guid: item.inv_trans_guid,
        entity_guid: null,
        inv_number: item.inv_number,
        debtor_organisation: item.debtor_organisation,
        inv_amount: item.inv_amount,
        inv_date: item.inv_date,
        pending: item.pending,
        date: new Date(),
        amount_recieved: item.allocated,
        received: item.received,
        reference: item.client_reference,
      });
    });

    setInvoices(reconsilesArr);
  };

  //--------------------- Submit invoice to server ------------------//
  const submitReconsile = async () => {

    const reqData = {
      client_guid: entityDetails.a_guid,
      debtors: [...dataDebtor],
      invoices: [...reconsileInvoice],
      batches: [...batches],
    };

    const { data } = await transactionReconsileApi.request(reqData);
    if (data.success === false) toast.error(data.message);
    else {
      toast.success(data.message);
      submitInvoice();
    }
  };
  //----------------------------------------------------------------//

  const handleReconsile = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Are you sure ...?</h4>
            <p>{`Reconciling invoices until ${moment(values.date).format("DD/MM/YYYY").toString()}.You want to continue?`}</p>
            <button
              className="btn btn-white"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                submitReconsile();

                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      },
      title: "Reconcilation",
      message: `Reconciling invoices until ${values.date}.You want to continue?`,
    });
  };

  useEffect(() => {
    fetchBatch(new Date());
    fetchDebtorData(new Date());
    fetchInvoices(new Date());
    setValue({
      ...values,
      date: moment(new Date()).format("YYYY-MM-DD").toString(),
    });
  }, []);

  //---------------------Handle Input Change----------------------//
  const handleChange = (e) =>
    setValue({ ...values, [e.target.name]: e.target.value });


  const handleReconcileChange = (e, index, type, valu = null) => {
    const batchList = [...batches];

    if (type == 'adjustment') {
      if (!isNaN(Number(valu))) {
        batchList[index]['total'] = Number(batchList[index]['sub_total']) + Number(valu);
      }

    } else {
      let valu = e.target.value;
    }

    batchList[index][type] = valu;

    //console.log(batchList);

    setBatch(batchList);

  }

  const handleOpenComentPopup = (e, index, type = 'click') => {
    const batchList = [...batches];
    let flag = true;

    if (type == 'currency_text' && e.target.value == '') {
      flag = false;
    }
    setIndexVal(index);
    setComment(batchList[index]['comment']);
    if (flag) {
      setCommentLabel(batchList[index]['debtor_organisation']);
      setCommentModal(true);
    }

  }

  const saveComment = () => {
    const batchList = [...batches];
    batchList[indexVal]['comment'] = comment;
    setBatch(batchList);
    toast.success('Comment updated successfully');
    setCommentModal(false);

  }

  const handleUploadItems = (excelData, filesList) => {
    const { ParseDate } = dateParser(); // Instance for utility library.

    let invoiceData = [];

    excelData.map(
      ({
        Invoice_Number,
        Debtor,
        Invoice_Date,
        Invoice_Value,
        Due_Date,
        Insured,
        Advance_Pctg,
        Advance_Amt,
        Advance_Date,
        Royalty_Pctg,
        Insurance_Pctg,
      }) => {
        let debtorData = debtors.find((obj) =>
          obj.label == Debtor ? obj.value : false
        );
        let debtorID = debtorData ? debtorData.value : false;

        invoiceData.push({
          index: newInvoice.length++,
          inv_number: Invoice_Number,
          inv_date: ParseDate(Invoice_Date),
          inv_amount: Invoice_Value,
          inv_due_date: ParseDate(Due_Date),
          insured: Insured,
          inv_chargeable_amt: Invoice_Value,
          inv_adv_percent: Advance_Pctg,
          inv_adv_amount: Advance_Amt,
          inv_advance_date: ParseDate(Advance_Date),
          inv_royalty_percent: Royalty_Pctg,
          inv_insurance_percent: Insurance_Pctg,
          debtor_name: debtors.find((obj) => obj.label == Debtor)
            ? Debtor
            : false,
          debtor_name_in_sheet: Debtor,
          inv_debtor_id: debtorID, //"b925c222-00b1-11ec-b8de-18cf5e2bff07",
          disburse_now:
            !Advance_Amt > 0 || Advance_Date === null || Advance_Date === ""
              ? false
              : true,
          credit_notes: Invoice_Value < 0 ? 0 : 1,
        });
      }
    );

    let currentTotal = _.sumBy(batches, (item) => Number(item.total));

    let new_totalAmount = _.sumBy(invoiceData, (item) => Number(item.inv_amount));

    setTotalVal(new_totalAmount);

    setNewInvoice(invoiceData);

    if (currentTotal !== new_totalAmount) {
      toast.error("Missmatch in total invoice amount between old ledger and newly uploaded sheet");
    } else {
      setIsDisabled(false);

      const { constants, category, entity_id } = authService.getCurrentUser();
      if (constants.CATEGORY.BP === category) setOwner(entity_id);

      setCustomer(entityDetails.a_guid);
      getRefNumber(entityDetails.a_guid);

      if (invoiceData.filter((obj) => obj.debtor_name === false).length > 0) {
        toast.error("Debtor mismatch.");
        setDebtorModal(true);
      }

    }

  };

  const updateInvoice = (index, invoiceData) => {
    let updatedInv = [...newInvoice];
    updatedInv[index] = { ...invoiceData };
    updatedInv[index]["error"] = false;
    updatedInv[index]["errorMsg"] = "";
    setNewInvoice(updatedInv);
  };

  //-----------------------Generate and assign the Trans Ref number-----------------------//
  const getRefNumber = async (customer_guid) => {
    const { data } = await referenceApi.request({
      client_guid: customer_guid,
    });

    setRefNumber(data.ref_number);
    return data.ref_number;
  };

  const submitInvoice = async () => {
    //setVisible(true);
    if (newInvoice.filter((obj) => obj.debtor_name === false).length > 0) {
      toast.error("Debtor mismatch.");
      //setVisible(false);
      return;
    }

    // Create Transaction
    let transGuid = null;

    const reqstData = {
      trans_guid: null,
      trans_client_reference: refNo,
      trans_system_reference: refNo,
      trans_client_guid: customer,
      trans_date: moment(new Date()).format("YYYY-MM-DD").toString(),
      trans_fee_id: null,
      bp: owner,
    };

    let trans_create = true;
    let transdata = [];

    if (transactionGuid === null) {
      transdata = await transactionInvApi.request(reqstData);
      trans_create = transdata.data.success;
    }


    if (trans_create === false) toast.error(transdata.data.message);
    else {
      // Create Invoices
      if (transactionGuid) {
        transGuid = transactionGuid;

      } else {
        transGuid = transdata.data.trans_details.trans_guid;
        setTransactionGuid(transGuid);
      }

      const reqData = {
        trans_client_guid: customer,
        client_invoice_upload: true,
        trans_guid: transGuid,
        invoices: [...newInvoice],
      };

      const { data } = await createInvoiceApi.request(reqData);
      if (data.success === false) {
        //setVisible(false);
        setErrors(data.errors);
        setOtherErrors(data.otherErrors);
        setValidationModal(true);

        if (data.invoices.length > 0) setNewInvoice(data.invoices);

        setShowInvoice(true);
      } else {
        toast.success(data.message);
        //setInvoice([]);
        setTransactionGuid(null);
        //handleReconsileHistory(false);
        setShowInvoice(false);
        setReconsileComplete(true);
      }
    }
  };

  const handleDeleteByInvNumber = (inv_number) => {

    let invoiceData = [...newInvoice];
    invoiceData = invoiceData.filter((obj) => !inv_number.includes(obj.inv_number));

    // Resetting Index value on each rows
    let updatedList = invoiceData.map((item, index) => {
      item.index = index;
      return item;
    });

    setNewInvoice(updatedList);
  };

  return (
    <>

      {/*---------------------------Model to get comment------------------------------------*/}
      <Modal show={commentModal} onHide={() => setCommentModal(false)} size="lg">
        <Modal.Header closeButton >
          <h4 className="modal-title">Add Comment for {commentLabel}</h4>
        </Modal.Header>

        <Modal.Body>
          <TextAreaInput
            name="comment"
            value={comment}
            label="Comment"
            rows={10}
            onChange={(e) => { setComment(e.target.value) }}

          ></TextAreaInput>
          <AddButton
            onClick={() => saveComment()}
            label="Save"
            style="btn btn-primary   pull-right mt-2"
          ></AddButton>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>

      </Modal>
      {/*---------------------------------------------------------*/}

      <Modal
        show={debtorModal}
        onHide={() => {
          setDebtorModal(false);
        }}
        size="lg"
      >
        <DFDebtorMapping
          onClose={() => {
            setDebtorModal(false);
          }}
          context={false}
          invoiceSet={newInvoice}
          handleUpdateInvoice={updateInvoice}
          selectedRow={selectedRow}
        />
      </Modal>

      <Modal
        show={validationModal}
        onHide={() => {
          setValidationModal(false);
        }}
        size="lg"
      >
        <InvoiceErrors
          onClose={() => {
            setValidationModal(false);
          }}
          errors={errors}
          validationErrors={otherErrors}
          transactionGuid={transactionGuid}
          deleteByInvNumber={handleDeleteByInvNumber}
        />
      </Modal>


      {(transactionApi.loading === true ||
        transactionBatchApi.loading === true ||
        transactionReconsileApi.loading === true ||
        transactionInvApi.loading === true ||
        createInvoiceApi.loading === true ||
        referenceApi.loading === true) && <LoadingSpinner />}

      <div className="card overflow-visible">
        <div className="card-header">
          <h4 className="card-title">
            Reconcile Invoice
            {/*<AddButton
              onClick={(e) => handleReconsileHistory(false)}
              label="Reconcile History"
              style="btn btn-success   pull-right mr-4"
            ></AddButton>*/}
          </h4>
        </div>

        <div className="card-body">
          {
            !showInvoice ? (
              reconsileComplete ? (
                <>
                  <div className="alert alert-success mt-2 text-center">
                    Reconciled Successfully
                  </div>
                </>
              ) : (
                <>
                  <FormRow>
                    <div className="col-lg-3">
                      <FormGroup
                        label="Reconciliation Date"
                        type=" "
                        input={
                          <DateTimePicker
                            name="date"
                            selectedDate={values.date}
                            onChange={(e) => {
                              handleChange(e);
                              fetchBatch(e.target.value);
                              fetchDebtorData(e.target.value);
                              fetchInvoices(e.target.value);
                              setTotalVal(null);
                              setIsDisabled(true);
                            }}
                          />
                        }
                      />
                    </div>
                  </FormRow>
                  {/*  -------------------------------- Reconcile list -------------------------------- */}
                  <FormRow>
                    <div className="col-lg-12">
                      {
                        batches.length > 0 ? (
                          <>
                            <table className="table table-sm mt-4 ">
                              <tbody>
                                <tr>
                                  <th>Debtor</th>
                                  <th>Invoice Amount</th>
                                  <th>Credit Notes</th>
                                  <th>Payment</th>
                                  <th>Sub Total</th>
                                  <th>Adjustments</th>
                                  <th></th>
                                  <th>Current Total</th>
                                </tr>
                                {
                                  batches &&
                                  batches.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        {item.debtor_organisation}
                                      </td>
                                      <td>
                                        <CurrencyLabel value={item.inv_total}></CurrencyLabel>
                                      </td>
                                      <td>
                                        <CurrencyLabel
                                          value={item.credit_total}
                                        ></CurrencyLabel>
                                      </td>

                                      <td>
                                        <CurrencyLabel value={item.payment}></CurrencyLabel>
                                      </td>
                                      <td>
                                        <CurrencyLabel value={item.sub_total}></CurrencyLabel>
                                      </td>
                                      <td>
                                        <CurrencyText
                                          value={item.adjustment}
                                          onChangeEvent={(event, maskedvalue, floatvalue) => {
                                            handleReconcileChange(event, index, 'adjustment', floatvalue);
                                          }}
                                          onBlur={(event) => { handleOpenComentPopup(event, index, 'currency_text'); }}
                                          placeholder="Adjustment"
                                        />
                                      </td>
                                      <td>
                                        {
                                          item.comment !== null
                                            ? <Link
                                              onClick={(event) => { handleOpenComentPopup(event, index); }}
                                            >
                                              <i className="fa fa-file-text-o"></i>
                                            </Link>
                                            : ''
                                        }
                                      </td>
                                      <td>
                                        <CurrencyLabel value={item.total}></CurrencyLabel>
                                      </td>
                                    </tr>
                                  ))
                                }
                                <tr>
                                  <td colSpan="1"></td>
                                  <td>
                                    {
                                      <strong>
                                        <CurrencyLabel
                                          value={_.sumBy(batches, (item) =>
                                            Number(item.inv_total)
                                          )}
                                        ></CurrencyLabel>
                                      </strong>
                                    }
                                  </td>
                                  <td>
                                    {
                                      <strong>
                                        <CurrencyLabel
                                          value={_.sumBy(batches, (item) =>
                                            Number(item.credit_total)
                                          )}
                                        ></CurrencyLabel>
                                      </strong>
                                    }
                                  </td>
                                  <td>
                                    {
                                      <strong>
                                        <CurrencyLabel
                                          value={_.sumBy(batches, (item) =>
                                            Number(item.payment)
                                          )}
                                        ></CurrencyLabel>
                                      </strong>
                                    }
                                  </td>
                                  <td>
                                    {
                                      <strong>
                                        <CurrencyLabel
                                          value={_.sumBy(batches, (item) =>
                                            Number(item.sub_total)
                                          )}
                                        ></CurrencyLabel>
                                      </strong>
                                    }
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <strong>
                                      <CurrencyLabel
                                        value={_.sumBy(batches, (item) =>
                                          Number(item.total)
                                        )}
                                      ></CurrencyLabel>
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <p className="text-secondary mt-2">
                            No transaction found for the Reconsilation
                          </p>
                        )
                      }
                    </div>
                  </FormRow>
                  {
                    batches.length > 0 && (
                      <div className="row m-t-sm">
                        <div className="col-lg-12   ">
                          <div className="row bg-light b-dashed-2 pt-2">
                            <div className="col-lg-4">
                              <ExcelFileUploader
                                label="Please upload file to reconcile"
                                handleUploadItems={handleUploadItems}
                                customButton={true}
                              />
                            </div>
                            <div className="col-lg-4">
                              <div className="row m-t-sm">
                                <div className="col-lg-6   ">
                                  {
                                    totalVal ? (
                                      <div className={isDisabled ? 'd-flex justify-content-between align-items-center text-danger' : 'd-flex justify-content-between align-items-center text-success'}>
                                        <div>
                                          <h4 className="card-title mb-2">New Ledger Total</h4>
                                          <h4 className="font-weight-bold  mb-1"><span><CurrencyLabel
                                            value={totalVal}
                                          ></CurrencyLabel></span></h4>
                                        </div>
                                        <div className="ml-auto mr-0">&nbsp;</div>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                </div>
                                <div className="col-lg-6   ">
                                  {
                                    totalVal ? (
                                      <div className={isDisabled ? 'd-flex justify-content-between align-items-center ' : 'd-flex justify-content-between align-items-center text-success'}>
                                        <div>
                                          <h4 className="card-title mb-2">Reconciliation Total</h4>
                                          <h4 className="font-weight-bold  mb-1">
                                            <span>
                                              <CurrencyLabel
                                                value={_.sumBy(batches, (item) =>
                                                  Number(item.total)
                                                )}
                                              ></CurrencyLabel>
                                            </span>
                                          </h4>
                                        </div>
                                        <div className="ml-auto mr-0">&nbsp;</div>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                </div>


                              </div>


                            </div>
                            <div className="col-lg-4">
                              <GeneralButton
                                onClick={() => {
                                  handleReconsile();
                                }}
                                disabled={isDisabled}
                                faIcon="fa fa-save"
                                className="btn btn-sm btn-primary  pull-right"
                                name="Reconcile"
                              ></GeneralButton>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 text-left">
                          {totalVal && (
                            <>
                              <div className={isDisabled ? "alert alert-danger mt-2 text-center" : "alert alert-success mt-2 text-center"}>
                                {
                                  isDisabled ?
                                    'Uploaded ledger is not matching with final reconcilation value. Please fix before proceeding'
                                    : 'Please click on reconcile button to reconcile invoices'
                                }
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )
                  }
                </>
              )
              
            ) : (
              <>
                <table className="table table-sm table-stripped client-tble">
                  <tbody>
                    <tr>
                      <th>Invoice No</th>
                      <th>Invoice Date</th>
                      <th>Debtor</th>
                      <th>Invoice Value</th>
                      <th>Due Date</th>
                      <th>Credit Notes</th>
                    </tr>
                    {newInvoice.map((item, index) => (
                      <tr key={index}
                        className={
                          item.error && item.error === true && "text-danger"
                        }
                        title={item.error && item.error === true && item.errorMsg}
                      >
                        <td>
                          {item.inv_number}
                        </td>

                        <td>
                          {moment(item.inv_date, "YYYY/MM/DD").format("DD/MM/YYYY")}
                        </td>

                        <td>
                          {item.debtor_name}
                        </td>

                        <td>
                          <CurrencyLabel value={item.inv_amount}></CurrencyLabel>
                        </td>

                        <td>
                          {moment(item.inv_due_date, "YYYY/MM/DD").format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>
                          <span
                            className={`badge ${item.inv_amount < 0
                              ? "badge-light text-danger"
                              : "badge-light text-secondary"
                              }  `}
                          >
                            {item.inv_amount < 0 ? "CR Notes" : "Invoice"}
                          </span>
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {
                          <strong> Invoice Total :
                            <CurrencyLabel
                              value={_.sumBy(newInvoice, (item) =>
                                Number(item.inv_amount)
                              )}
                            ></CurrencyLabel>
                          </strong>
                        }
                      </td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td colSpan="6">&nbsp;&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row mt-2">
                  <div className="col-lg-6 text-left">
                    <div className="alert alert-success mt-2 text-center">
                      Please click on <strong>Upload Invoices</strong> button
                    </div>
                  </div>
                  <div className="col-lg-6 text-right pt-4">
                    <GeneralButton
                      onClick={() => {
                        submitInvoice();
                      }}
                      className="btn btn-primary btn-sm "
                      faIcon="fa fa-plus"
                      name="Upload Invoices"
                    ></GeneralButton>
                  </div>
                </div>
              </>
            )
          }

        </div>
      </div>
    </>
  );
}
