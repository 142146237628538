import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import authService from "../../../../../services/authService"
import * as moment from "moment";
import { CountriesList, AllCorporateBpList, CustomersList, SuppliersList } from '../../../../slplusTools';
import { ReportInputFieldContainer, FormRow, FormColumnLg } from "../../../../common/containers/formContainer";
import transactionService from "../../../services/transaction_service";
import ExternalApiService from "../../../../../services/externalApiService";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router";
import Utility from "../../../utils/DateParser";
import useApi from "../../../../../hooks/useApi";
import 'react-table/react-table.css';
import { confirmAlert } from "react-confirm-alert";
import { GridActionButton, GeneralButton, DateTimePicker, CurrencyLabel, Picker } from "../../../../common/inputs";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
const colorRed = { color: "red" };

export default function TfBatchDirectDebit(props) {

    const tableRef = useRef();
    const { ParseDate } = Utility();

    // API calls for fetching dd invoices and performing actions

    const DDBatchApi = useApi(transactionService.directDebitBatchList);
    const listActionsApi = useApi(transactionService.directDebitListAction);
    const getPaymentStatusApi = useApi(ExternalApiService.getManoovaPaymentStatus);
    const processPaymentApi = useApi(ExternalApiService.manoovaProcessPayment);

    /*-------------------------------------------------------------------------- */
    const paymentStatuses = [
        { label: "Select Status", value: "all" },
        { label: "Requested", value: "requested" },
        { label: "Received", value: "received" },
        { label: "Cancelled", value: "cancelled" },
    ];
    // State variables
    const [ddInvoiceList, setDdInvoiceList] = useState(null);
    const [ddIndividualInvoiceList, setDdIndividualInvoiceList] = useState(null);
    const { constants, category, products } = authService.getCurrentUser();
    const [country, setCountry] = useState(null);
    const [owner, setOwner] = useState(null);
    const [customer, setCustomer] = useState(props.entityGuid ? props.entityGuid : null);
    const [supplier, setSupplier] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [isModel, setIsModel] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [showButtons, setShowButtons] = useState(false);
    const [ddModal, setDdModal] = useState(false);
    const [paymentSettlementConfirmModal, setPaymentSettlementConfirmModal] = useState(false);
    const [paymentSummaryModal, setPaymentSummaryModal] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState(null);
    const [receivedDate, setReceivedDate] = useState(new Date());
    const [selectedDateTo, setSelectedDateTo] = useState(null);
    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [batchId, setBatchId] = useState(null);
    const [totalAllocatedAmount, setTotalAllocatedAmount] = useState(0);
    const [totalAllocatedCount, setTotalAllocatedCount] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(paymentStatuses[0]);

    // Fetching dd batch list based on filters

    const getDDBatchList = async (owner, customer, supplier, selectedDateFrom, selectedDateTo) => {
        const response = await DDBatchApi.request({
            selectedDateFrom: selectedDateFrom,
            selectedDateTo: selectedDateTo,
            product_type: constants.PRODUCTS.TRADEFINANCE,
            country: country,
            owner: owner,
            customer: props.entityGuid ? props.entityGuid : customer !== undefined ? customer : null,
            supplier: supplier !== undefined ? supplier : null,
            status: selectedStatus,
            paymentStatus: props.paymentStatus,
        }); // Sending Async request to server
        if (response) {
            setDdInvoiceList(response.data.rows);
        }
    }

    /* ------------------------------------------------------------------------------------- */


    // Fetch dd batch list on component load or when filter variables change

    useEffect(() => {
        getDDBatchList(owner, customer, supplier, selectedDateFrom, selectedDateTo, batchId, selectedStatus, props.paymentStatus)
    }, [owner, customer, supplier, selectedDateFrom, selectedDateTo, batchId, selectedStatus, props.paymentStatus])

    useEffect(() => {
        if (paymentResponse)
            setPaymentSettlementConfirmModal(true);
    }, [paymentResponse])

    // Function to handle checkbox selection for individual invoices

    const handleCheckboxChange = (id) => {
        if (ddInvoiceList) {
            const updatedInvoiceList = [...ddInvoiceList];
            updatedInvoiceList[id].checked = !updatedInvoiceList[id].checked;
            setDdInvoiceList(updatedInvoiceList);

            const updatedSelectedInvoices = updatedInvoiceList
                .filter((invoice) => invoice.checked)
                .map((invoice) => invoice.total_request_amount);

            // Calculate the total of total_requested_amount
            const totalAmount = updatedSelectedInvoices.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue);
            }, 0);

            setSelectedInvoices(updatedSelectedInvoices);
            setShowButtons(updatedSelectedInvoices.length > 0);
            setTotalAllocatedCount(updatedSelectedInvoices.length); // Set the total count
            setTotalAllocatedAmount(totalAmount); // Set the total amount
        }
    };

    // Function to handle select/deselect all invoices

    const handleSelectAll = () => {
        const allChecked = !selectAll;
        const updatedInvoiceList = ddInvoiceList.map((invoice) => ({
            ...invoice,
            checked: allChecked,
        }));
        setDdInvoiceList(updatedInvoiceList);

        const updatedSelectedInvoices = allChecked
            ? updatedInvoiceList.map((invoice) => invoice.total_request_amount)
            : [];

        // Calculate the total of total_requested_amount
        const totalAmount = updatedSelectedInvoices.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue);
        }, 0);
        const totalCount = allChecked ? updatedSelectedInvoices.length : 0;

        setSelectedInvoices(updatedSelectedInvoices);
        setShowButtons(updatedSelectedInvoices.length > 0);
        setTotalAllocatedAmount(totalAmount); // Set the total amount
        setTotalAllocatedCount(updatedSelectedInvoices.length); // Set the total count
        setSelectAll(allChecked);
    };

    //function for export data
    const handleExport = async () => {
        const response = await DDBatchApi.request({
            selectedDateFrom: selectedDateFrom,
            selectedDateTo: selectedDateTo,
            product_type: constants.PRODUCTS.TRADEFINANCE,
            country: country,
            owner: owner,
            customer: props.entityGuid ? props.entityGuid : customer !== undefined ? customer : null,
            supplier: supplier !== undefined ? supplier : null,
            status: selectedStatus,
            paymentStatus: props.paymentStatus,
            docType: "xlsx",
        });
        window.open(response.data, "_blank", "noreferrer");
    };

    // Function to trigger actions for selected invoices
    const handleRequest = async status => {
        const msg = (status == 'cancelled') ? 'Cancelled payments will be pending again!' : 'Are you sure ...?';
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>{msg}</h4>
                        <p>Do you want to continue ?</p>
                        <button
                            className="btn btn-white ml-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>

                        <button
                            className="btn btn-primary ml-2"
                            onClick={async () => {
                                onClose();
                                const { data } = await listActionsApi.request(
                                    {
                                        selectedDate: ParseDate(selectedDate),
                                        status: 'cancelled',
                                        product_type: constants.PRODUCTS.TRADEFINANCE,
                                        selectedAttributes: ddInvoiceList.filter(item => item.checked === true).map(item => item.batch_id),
                                        bulkUpdate: true,
                                        owner: owner,
                                        customer: customer !== undefined ? customer : null,
                                    });

                                if (data.success) {
                                    toast.success(data.message);
                                    setShowButtons(false);
                                    getDDBatchList();
                                }
                                else
                                    toast.error(data.message);
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "",
            message: "",
        });
    }

    const handleGetPaymentStatus = async (batchId, entityId) => {
        const { data } = await getPaymentStatusApi.request({
            refGuid: batchId,
            entityGuid: entityId,
            product_type: constants.PRODUCTS.TRADEFINANCE
        });
        if (data.success) {
            toast.success(data.message);
            getDDBatchList(owner, customer, supplier, selectedDateFrom, selectedDateTo, batchId, selectedStatus, props.paymentStatus);
        } else {
            toast.error(data.message);
        }
    }


    const handleDeleteScheduled = async (deletedRecord) => {
        const msg = 'This action will cancel the scheduled payment request!';
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>{msg}</h4>
                        <p>Do you want to continue ?</p>
                        <button
                            className="btn btn-white ml-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>

                        <button
                            className="btn btn-primary ml-2"
                            onClick={async () => {
                                onClose();
                                const { data } = await listActionsApi.request(
                                    {
                                        selectedDate: ParseDate(selectedDate),
                                        status: 'cancelled',
                                        product_type: constants.PRODUCTS.TRADEFINANCE,
                                        selectedAttributes: deletedRecord.batch_id,
                                        bulkUpdate: true,
                                        owner: owner,
                                        customer: customer !== undefined ? customer : null,
                                        isRemove:true
                                    });

                                if (data.success) {
                                    toast.success(data.message);
                                    setShowButtons(false);
                                    getDDBatchList();
                                }
                                else
                                    toast.error(data.message);
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "",
            message: "",
        });
    }

    const handleProcessPayment = (ref_guid,sch_guid,cust_name,amount) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Confirm process payament</h4>
                        <p>Are you sure want to process an amount <CurrencyLabel value={amount}/> for the customer {cust_name}?</p>
                        <button
                            className="btn btn-white ml-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>

                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => {processPayment(ref_guid,sch_guid); onClose()}}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "",
            message: "",
        });
    }

    const processPayment =async(ref_guid,sch_guid) => {
        const response = await processPaymentApi.request({
            sch_guid:sch_guid,
            ref_guid:ref_guid
        })
        if(response){
            if(response.data.success == true){
                toast.success(response.data.message);
                getDDBatchList(owner, customer, supplier, selectedDateFrom, selectedDateTo, batchId, selectedStatus, props.paymentStatus)
            } else {
                toast.error(response.data.message);
            }
        } else {
            toast.error("Failed to process payment.");
        }
    }

    const columns = [
        {
            Header: "Customer",
            accessor: "organization",
            headerStyle: { textAlign: "left" },
        },
        {
            Header: "Requested Date",
            accessor: "requested_date",
            headerStyle: { textAlign: "left" },
            Cell: row => moment(row.original.requested_date, "YYYY-MM-DD").format("DD/MM/YYYY")
        },
        {
            Header: "Scheduled Date",
            accessor: "schedule_date",
            headerStyle: { textAlign: "left" },
            Cell: row => moment(row.original.schedule_date, "YYYY-MM-DD").format("DD/MM/YYYY")
        },
        {
            Header: "Requested Count",
            accessor: "request_count",
            headerStyle: { textAlign: "left" },
            Cell: row => {
                return (
                    <>
                        <Link className="cursor-link"
                            onClick={async () => {
                                setIsModel(true);
                                const response = await DDBatchApi.request({
                                    product_type: constants.PRODUCTS.TRADEFINANCE,
                                    batch_id: row.original.batch_id,
                                    owner: owner,
                                    customer: customer !== undefined ? customer : null,
                                }); // Sending Async request to server
                                if (response) {
                                    setDdIndividualInvoiceList(response.data.rows);
                                }
                            }}
                        >
                            {row.original.request_count}
                        </Link>
                    </>
                )
            }
        },

        {
            Header: "Finance Cost",
            accessor: "total_request_amount",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <CurrencyLabel
                    value={
                        row.original.total_request_amount !== null ? row.original.total_request_amount : 0
                    }
                ></CurrencyLabel>
            ),
            Footer: (row) => {
                return (
                    <strong>
                        <CurrencyLabel decimal={2} value={_.sumBy(row.data, item => Number(item.total_request_amount))}></CurrencyLabel>
                    </strong>
                );
            },
        },
        {
            Header: "Non Utilisation Fee",
            accessor: "non_utilisation_fee",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <CurrencyLabel
                    value={
                        row.original.non_utilisation_fee !== null ? row.original.non_utilisation_fee : 0
                    }
                ></CurrencyLabel>
            ),
            Footer: (row) => {
                return (
                    <strong>
                        <CurrencyLabel decimal={2} value={_.sumBy(row.data, item => Number(item.non_utilisation_fee))}></CurrencyLabel>
                    </strong>
                );
            },
        },

        {
            Header: "Total DD",
            accessor: "amount",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <CurrencyLabel
                    value={
                        row.original.amount !== null ? row.original.amount : 0
                    }
                ></CurrencyLabel>
            ),
            Footer: (row) => {
                return (
                    <strong>
                        <CurrencyLabel decimal={2} value={_.sumBy(row.data, item => Number(item.amount))}></CurrencyLabel>
                    </strong>
                );
            },

        },
        {
            Header: "Payment Status",
            accessor: "requested_status",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <div>
                {row.value === 'requested' && (
                <div className="badge badge-light p-2 text-warning">
                    {row.value.charAt(0).toUpperCase() + row.value.slice(1)}
                </div>
                )}
                {row.value === 'rejected' && (
                    <div className="badge badge-light p-2 text-danger">
                        {row.value.charAt(0).toUpperCase() + row.value.slice(1)}
                    </div>
                )}
                {row.value === 'pending' && (
                    <div className="badge badge-light p-2 text-success">
                        Success
                    </div>
                )}
                {row.value === 'received' && (
                    <div className="badge badge-light p-2 text-success">
                        {row.value.charAt(0).toUpperCase() + row.value.slice(1)}
                    </div>
                )}
                {props.paymentStatus === 'requested' && row.original.schedule_status === 'pending' && (
                    <Link
                    className="fa fa-trash text-danger"
                    title="Remove DD request"
                    onClick={() => handleDeleteScheduled(row.original)}
                ></Link>
                )}
                </div>
            ),
        },
        {
            Header: "Payment Schedule Status",
            accessor: "schedule_status",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <div>
                    {row.value === 'pending' && (
                    <div className="badge badge-light p-2 text-warning">
                        {row.value.charAt(0).toUpperCase() + row.value.slice(1)}
                    </div>
                    )}
                    {row.value === 'completed' && (
                    <div className="badge badge-light p-2 text-success">
                        {row.value.charAt(0).toUpperCase() + row.value.slice(1)}
                    </div>
                    )}
                </div>
                
            ),
        },
        
        {
            Header: "",
            accessor: "",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <>
                {
                    props.paymentStatus === 'requested' && (
                        <>
                        
                            <Link
                                className="cursor-link action-icon"
                                title="View current Payment status"
                                onClick={() => {
                                    handleGetPaymentStatus(row.original.batch_id, row.original.client_id);
                                }}
                            >
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            </Link>
                        </>
                        )
                }
                {
                        props.paymentStatus === 'requested' && processPaymentApi.loading == false && category == constants.CATEGORY.INTL_OFFICE && row.original.schedule_status === 'pending' && (
                            <>
                                <Link
                                    className="cursor-link action-icon ml-3"
                                    title="Process Payment"
                                    onClick={() => {
                                        handleProcessPayment(row.original.batch_id, row.original.payment_sch_guid,row.original.organization,row.original.amount);
                                    }}
                                    
                                >
                                    <i class="fa fa-money" aria-hidden="true"></i>
                                </Link>
                            </>
                        )
                    }
                </>
                
            ),
        }

    ];


    return (
        <>
            <Helmet>
                <title>TF Direct Debits | {global.config.title}</title>
            </Helmet>
            {(getPaymentStatusApi.loading == true || processPaymentApi.loading == true) && <LoadingSpinner />}
            <div className="overflow-visible">
                <div className="row p-3">

                    <ReportInputFieldContainer label={category == constants.CATEGORY.INTL_OFFICE ? "Country" : ""} show={category == constants.CATEGORY.INTL_OFFICE ? true : false}>
                        <CountriesList type="horizontal" selectedCountry={country} onSelectItem={({ id }) => { setCountry(id); setOwner(null); setCustomer(null); setSupplier(null); }} />
                    </ReportInputFieldContainer>

                    <ReportInputFieldContainer label={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? "Business Partner" : ""} show={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? true : false} >
                        <AllCorporateBpList selectedBp={owner} type="horizontal" country={country} onSelectItem={({ value }) => { setOwner(value); setCustomer(null); setSupplier(null); }} />
                    </ReportInputFieldContainer>

                    <ReportInputFieldContainer label="Customer" >
                        <CustomersList selectedCustomer={customer} bp={owner}
                            onSelectItem={option => { if (option.value == null) { setSupplier(null) } else { setCustomer(option.value) } }}
                            category={constants.CATEGORY.TFCUSTOMER}
                        />
                    </ReportInputFieldContainer>
                    {props.paymentStatus == 'all' && (
                        <div className="col-lg-2">
                            <div className="form-group">
                                <label>Payment Status</label>
                                <div>
                                    <Picker
                                        items={paymentStatuses}
                                        selectedItem={selectedStatus}
                                        onSelectItem={option => setSelectedStatus(option)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label>Date From</label>
                            <DateTimePicker
                                name="requested_from_date"
                                label="Requested Date From"
                                selectedDate={selectedDateFrom}
                                onChange={({ target }) => setSelectedDateFrom(target.value)}
                                enableWeekDays={true}
                            />
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label>Date to</label>
                            <DateTimePicker
                                name="requested_to_date"
                                label="Requested Date To"
                                selectedDate={selectedDateTo}
                                onChange={({ target }) => setSelectedDateTo(target.value)}
                                enableWeekDays={true}
                            />
                        </div>
                    </div>

                </div>
                {/* </div>
            <div className="card"> */}
                <div className="card-body">
                    {showButtons && (
                        <div className="row">

                            <div className="col-4">
                                <div className="  b-dashed-2 p-2 bg-light">
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="mb-1">Total Payment Selected</p>
                                            <h5 className="font-weight-bold mb-1">
                                                <CurrencyLabel
                                                    label="make pay"
                                                    value={totalAllocatedAmount}
                                                ></CurrencyLabel>
                                            </h5>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-1">Total Clients</p>
                                            <h5 className="font-weight-bold mb-1">{totalAllocatedCount}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4  ">
                                <GridActionButton

                                    label="Receive Payment"
                                    handleClick={(e) => setDdModal(true)}
                                    class="btn btn-primary mr-2"
                                />
                                <GridActionButton

                                    label="Cancell"
                                    handleClick={(e) => handleRequest('cancelled')}
                                    class="btn btn-outline-danger mr-2"
                                />
                            </div>
                        </div>
                    )}
                    {ddInvoiceList && ddInvoiceList.length > 0 && (
                        <div className="row">
                            <div className="col-lg-12">
                                <GeneralButton
                                    className="btn btn-outline-secondary pull-right mt-2"
                                    name="Export"
                                    onClick={handleExport}
                                ></GeneralButton>
                            </div>
                        </div>
                    )}
                    {ddInvoiceList && ddInvoiceList.length > 0 ? (
                        <div className="card-body">
                            <ReactTable
                                data={ddInvoiceList}
                                columns={columns}
                                defaultPageSize={10}
                                minRows={10}
                                className="-striped -highlight"
                                showPagination={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                            />
                        </div>
                    ) : (
                        <div className="card-body">
                            <div>
                                <p colSpan="15" className="text-center">
                                    No DD Requested
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/*------------  modal for showing dd invoices ---------- */}
            <Modal
                show={isModel}
                onHide={(e) => setIsModel(false)}
                size="lg" >
                <Modal.Header closeButton>
                    <h6> {`Direct Debit `} </h6>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {(DDBatchApi.loading) && <LoadingSpinner />}
                        {(ddIndividualInvoiceList && ddIndividualInvoiceList.length > 0) ? (
                            <div className="card-body">
                                <table className="table table-hover no-margins">
                                    <thead>
                                        <tr>
                                            <th>Customer</th>
                                            <th>Invoice Number</th>
                                            <th>Invoice Amount</th>
                                            <th>DD Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ddIndividualInvoiceList.map((item, index) => (
                                            <tr>
                                                <td>{item.organization}</td>
                                                <td>{item.inv_number}</td>
                                                <td><CurrencyLabel value={item.inv_amount}></CurrencyLabel></td>
                                                <td><CurrencyLabel value={item.dd_amount}></CurrencyLabel></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td> Total DD</td>
                                            <td><strong>
                                                <CurrencyLabel
                                                    value={_.sumBy(ddIndividualInvoiceList, (item) =>
                                                        Number(item.dd_amount)
                                                    )}
                                                ></CurrencyLabel>
                                            </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ) :
                            <></>
                        }
                    </>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            {/*------------  modal for confirm received date---------- */}
            <Modal
                show={ddModal}
                onHide={(e) => setDdModal(false)}
                centered
                backdrop='static'
            >
                <Modal.Header closeButton>
                    <h6> {`Select Date DD Collected`} </h6>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <DateTimePicker
                            name="date"
                            placeholder="Select Date"
                            label="Date"
                            // minDate={new Date(Date.parse(selectedDate))}
                            selectedDate={receivedDate}
                            onChange={({ target }) => setReceivedDate(target.value)}
                        />
                    </div>
                    <FormRow style={colorRed} className="ml-1 mt-1">
                        Selected date will be saved as received date for all selected invoices
                    </FormRow>
                </Modal.Body>
                <Modal.Footer>
                    <FormRow>
                        <GeneralButton
                            name={`Save`}
                            className={`btn btn-primary`}
                            onClick={async () => {
                                setDdModal(false);
                                const { data } = await listActionsApi.request(
                                    {
                                        selectedDate: ParseDate(receivedDate),
                                        status: 'received',
                                        product_type: constants.PRODUCTS.TRADEFINANCE,
                                        selectedAttributes: ddInvoiceList.filter(item => item.checked === true).map(item => item.batch_id),
                                        bulkUpdate: true,
                                        owner: owner,
                                        customer: customer !== undefined ? customer : null,
                                    });

                                if (data.success) {
                                    setPaymentResponse(data);
                                    setShowButtons(false);
                                    getDDBatchList();
                                }
                                else
                                    toast.error(data.message);
                            }}>
                        </GeneralButton>
                    </FormRow>
                </Modal.Footer>
            </Modal>
            {/*------------ ------------------------------------- ---------- */}

            {/*------------ Payment settlement confirmation modal ---------- */}

            <Modal
                show={paymentSettlementConfirmModal}
                onHide={() => { setPaymentSettlementConfirmModal(false) }}
                centered
                contentClassName="bg-secondary text-white"
                backdropClassName="bg-white"
            >
                <Modal.Header closeButton />

                <Modal.Body>
                    <div className="text-center mb-4">
                        <div className="media-icon media-icon-lg bg-primary-transparent text-primary mx-auto mb-4">
                            <i className="fa fa-check-square"></i>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <h6 className="text-white mb-4">
                                    A payment of <CurrencyLabel value={paymentResponse?.total_payment_settled || 0} /> has been allocated to the selected invoices.
                                </h6>
                                {paymentResponse?.closed_status && paymentResponse.closed_status.find(item => item.error === true) && (
                                    <p className="text-white-50 mb-4 text-sm">
                                        However, certain invoices could not be closed due to some issues. Please click the button below to view more details.
                                    </p>
                                )}
                                <button className="btn btn-white ml-2" onClick={() => { setPaymentSettlementConfirmModal(false); }}>
                                    Close
                                </button>
                                <button
                                    className="btn btn-primary ml-2"
                                    onClick={() => {
                                        setPaymentSettlementConfirmModal(false);
                                        setPaymentSummaryModal(true);
                                    }}
                                >
                                    View Summary
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/*--------------------------------------------------------------*/}
            {/*------------------------Payments Summary----------------------*/}
            <Modal
                show={paymentSummaryModal}
                onHide={() => setPaymentSummaryModal(false)}
                backdrop="static"
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Payments Settlement Summary</h4>
                </Modal.Header>
                <Modal.Body>
                    {paymentResponse !== null && typeof paymentResponse !== 'undefined' && paymentResponse.closed_status.find(item => item.error === false) && (
                        <FormRow>
                            <FormColumnLg>
                                <table className="table text-secondary">
                                    <thead>
                                        <tr>
                                            <th>Invoice Number</th>
                                            <th>Amount</th>
                                            <th>Principal Paid</th>
                                            <th>Fees Paid</th>
                                            <th>Closed Date</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentResponse.closed_status.filter(item => item.error === false).map((item, key) => (
                                            <tr key={key}>
                                                <td>{item.inv_number}</td>
                                                <td><CurrencyLabel value={item.inv_amount} /></td>
                                                <td><CurrencyLabel value={item.principal_paid} /></td>
                                                <td><CurrencyLabel value={item.finance_cost_paid} /></td>
                                                <td>{item.closed_date}</td>
                                                <td>{item.message}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </FormColumnLg>
                        </FormRow>
                    )}

                    {paymentResponse !== null && typeof paymentResponse !== 'undefined' && paymentResponse.closed_status.find(item => item.error === true) && (
                        <>
                            <FormRow>
                                <FormColumnLg>
                                    <div className="text-center">
                                        <div className="alert alert-info">Following invoices could not be closed due to the following issues.</div>
                                    </div>
                                </FormColumnLg>
                            </FormRow>

                            <FormRow>
                                <FormColumnLg>
                                    <table className="table text-secondary">
                                        <thead>
                                            <tr>
                                                <th>Invoice Number</th>
                                                <th>Amount</th>
                                                <th>Principal Paid</th>
                                                <th>Fees Paid</th>
                                                <th>Outstanding Fees</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentResponse.closed_status.filter(item => item.error === true).map((item, key) => (
                                                <tr key={key}>
                                                    <td>{item.inv_number}</td>
                                                    <td><CurrencyLabel value={item.inv_amount} /></td>
                                                    <td><CurrencyLabel value={item.principal_paid} /></td>
                                                    <td><CurrencyLabel value={item.finance_cost_paid} /></td>
                                                    <td><CurrencyLabel value={item.finance_cost_outstanding} /></td>
                                                    <td className="text-danger">{item.message}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </FormColumnLg>
                            </FormRow>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-white" onClick={() => setPaymentSummaryModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>
            {/*--------------------------------------------------------------*/}
        </>


    )

}