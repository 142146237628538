import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router";
import { FormRow } from "../../../../common/containers/formContainer";
// Importing Widgets
import { ApprovedForPayment,Rejected,AwaitApproval } from "../../../widgets/SCWidgets";
import { SupplierCustomerManagement,AwaitSupplierUpdate,SupplierPaid,Rebate } from "../../../widgets/TFWidgets";
import EntityContext from "../../../../../context/entityContext";
import useApi from '../../../../../hooks/useApi';
import authService from "../../../../../services/authService";
import { CurrencyLabel } from "../../../../common/inputs";
import CommonService from "../../../../supply-chain/services/commonService";
import Modal from 'react-bootstrap/Modal';
import TfCreateInvoice from "../../../../supply-chain/components/transactions/supplierUpload/tfAddSupplierInvoice";

function ScSale() {

    const [appData, setAppData] = useState(null); //set data for approved for paymnet or scheduled for payment  invoice card
    const [awSupUpData, setAwSupData] = useState(null); // set data for awaiting supplierapproval  invoice card
    const [awAppData, setAwAppData] = useState(null); // set data for awaiting supplierapproval  invoice card
    const { sc_invoice_status, constants } = authService.getCurrentUser();
    const { selectedBusinessType, entityDetails, handleProductChange, handleRoleChange, terminology } = useContext(EntityContext);
    const [rejectedData, setRejectedData] = useState(null); // set data for Rejected invoice card
    const [modal, setModal ] = useState(false);
    const CountApi = useApi(CommonService.getScInvoiceCount);
    const [rebateData, setRebateData] = useState(null);

    const fetchData = async () => {

        const { data } = await CountApi.request({ "product_type" : constants.PRODUCTS.TRADEFINANCE,"userId": entityDetails.a_guid, "selRole": selectedBusinessType });

        setAppData({
            topLabel: "Scheduled For Payment",
            topValue: <CurrencyLabel value={data.inv_apprd_payment_sum} />,
            leftLabel: data.isPrimary ? "Suppliers" : "Customers",
            leftValue: data.isPrimary ? (data.inv_apprd_payment_supplierCount ? data.inv_apprd_payment_supplierCount : 0) : (data.inv_apprd_payment_customerCount ? data.inv_apprd_payment_customerCount : 0),
            rightLabel: "Invoices",
            rightValue: data.inv_apprd_payment_count ? data.inv_apprd_payment_count : 0,
            bottomLeftLabel: "Approved",
            bottomRightLabel: "Today's Balance",
            primaryBtnRoute: "/client/tf/sale-invoices",
            status: sc_invoice_status["approved_for_payment"],
            subTitle: "Approved and awaiting payment",
            subIcon: "fa-clock-o",
            secondaryBtnRoute : "/client/tf/bulk-payment"
        })
        setAwAppData({
            topLabel: "Pending Customer Approval",
            topValue: <CurrencyLabel value={data.inv_awt_apprv_sum} />,
            leftLabel: data.isPrimary ? `${terminology && terminology.SUPPLIER}s` : `${terminology && terminology.CUSTOMER}s`,
            leftValue: data.isPrimary ? (data.inv_awt_apprv_supplierCount ? data.inv_awt_apprv_supplierCount : 0) : (data.inv_awt_apprv_customerCount ? data.inv_awt_apprv_customerCount : 0),
            rightLabel: "Invoices",
            rightValue: data.inv_awt_apprv_count ? data.inv_awt_apprv_count : 0,
            bottomLeftLabel: "Review",
            bottomRightLabel: "Today’s Balances",
            primaryBtnRoute: "/client/tf/sale-invoices",
            status: sc_invoice_status["awaiting_approval"],
            subTitle: "Awaiting customer approval"
    
        })
        setAwSupData({
            topLabel: "Available For Early Payment",
            topValue: <CurrencyLabel value={data.inv_awt_sup_update_sum} />,
            leftLabel: `${terminology && terminology.CUSTOMER}s`,
            leftValue: data.inv_awt_sup_update_customerCount ? data.inv_awt_sup_update_customerCount : 0,
            rightLabel: "Invoices",
            rightValue: data.inv_awt_sup_update_count ? data.inv_awt_sup_update_count : 0,
            bottomLeftLabel: "Review",
            bottomRightLabel: "Today’s Balances",
            primaryBtnRoute: "/client/tf/sale-invoices",
            status: sc_invoice_status["awaiting_supplier_update"],
            subTitle: "Receive early payment on these invoices",
        })



        setRejectedData({
            topLabel: "Rejected",
            topSubLabel: "",
            topValue: <CurrencyLabel value={data.inv_rejected_sum} />,
            leftLabel: data.isPrimary ? "Suppliers" : "Customers",
            leftValue: data.isPrimary ? (data.inv_rejected_supplierCount ? data.inv_rejected_supplierCount : 0) : (data.inv_rejected_customerCount ? data.inv_rejected_customerCount : 0),
            rightLabel: "Invoices",
            rightValue: data.inv_rejected_count ? data.inv_rejected_count : 0,
            primaryBtnRoute: "/client/tf/sale-invoices",
            status: sc_invoice_status["rejected"],
            bottomLeftLabel: data.isPrimary ? "Suppliers" : "Customers",
            bottomRightLabel: "Invoices",
            subTitle: "Rejected invoices",
            subIcon: "fa-thumbs-down"
        })
        setRebateData({
            topLabel: "Rebate",
            topValue: <CurrencyLabel value={data.rebate} />
        })

    }

    const setProductRoleType = () => {
        handleProductChange(constants.PRODUCTS.TRADEFINANCE);
        handleRoleChange(constants.BUSINESS_TYPE.SALE)
    }
    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);


    return (
        <>

            {selectedBusinessType == constants.BUSINESS_TYPE.SALE && (
                <>
                    <FormRow>
                        <div className="col-md-4 ">
                            <div className="card">
                                <div className="card-body">
                                    <div className="m-4">
                                        <div className="invoice-round"><a href="#" onClick={e => { setModal(true) }} ><i className="fa fa-plus fa-2x" /></a></div><h5 className="mt-3  text-muted  text-center">Add Invoice</h5></div>
                                    <div />
                                    <div className="row " /></div>
                            </div>
                        </div>
                        <AwaitSupplierUpdate data={awSupUpData} loading={CountApi.loading} setProductRoleType={setProductRoleType} />
                        <ApprovedForPayment data={appData} loading={CountApi.loading} setProductRoleType={setProductRoleType} />

                    </FormRow>
                    <FormRow>
                        <SupplierPaid />
                        <div className="col-md-4 col-sm-4 col-xs-2 col-12">
                            <FormRow>
                              <AwaitApproval data={awAppData} loading={CountApi.loading} setProductRoleType={setProductRoleType} col={12} />
                            </FormRow>
                            <FormRow>
                              <SupplierCustomerManagement/>
                            </FormRow>
                            <FormRow>
                              <Rejected type="sm" data={rejectedData} loading={CountApi.loading} top={true} setProductRoleType={setProductRoleType} />
                            </FormRow>
                        </div>
                    </FormRow>
                    <FormRow>
                        <Rebate data={rebateData} loading={CountApi.loading} />
                    </FormRow>
                    <Modal
                        show={modal}
                        onHide={() => setModal(false) }
                        backdrop="static"
                        centered
                    >
                        <TfCreateInvoice
                            a_guid={entityDetails ? entityDetails.a_guid : ""}
                            page="common"
                            onCloseModal={() => {setModal(false);fetchData()} }
                        />
                    </Modal>
                </>
            )
            }
        </>
    )
}
export default ScSale;