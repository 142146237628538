import React, { Component } from "react";
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import coreService from "../../../../services/coreService";
import entityService from "../../../../services/entityService";
import { getInitials } from "../../../../utils/NameInitials";
import ArraySort from "../../../../utils/ArraySort";
import auth from "../../../../services/authService";
import { GeneralButton } from "../../../common/containers/button";
import { TextInput, Picker } from "../../../common/inputs";
import { toast } from "react-toastify";
import { Interaction } from "../../../supply-chain/config/predefinedValues.json";
import { confirmAlert } from "react-confirm-alert";
import EntityContext from "../../../../context/entityContext";
import CommonService from "../../../supply-chain/services/commonService";
import MappedUsersTable from "./mappedUsersTable";

class MappedUsers extends Component {
    static contextType = EntityContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            outerLoading: false,
            secondaryMapped: [],
            bankDetail: [],
            unassignFacilityModal: false,
            activeFacility: [],
            facilityList: [],
            selectedEntityGuid: "",
            selectedOrg: "",
            values: {
                search: '',
                group: '',
            },
            selectedGroup: {
                label: "Interaction",
                value: ""
            },
            groupList: [],
            allowedFacilityList: [],
            selectedFacility: {
                label: "Facility",
                value: ""
            },
            contactDetail: {
                contact_id: "",
                first_name: "",
                last_name: "",
                phone_no: ""
            },
            contactOtherOwned: false,
        }
    }

    componentDidMount() {
        this.fetchBankDetails();
        if (this.props.user_type == 'customer') {
            this.setState({
                groupList: [{
                    "label": 'All Customer',
                    "value": 'all_suppliers'
                },
                {
                    "label": 'All Disclosed Customers',
                    "value": 'all_disclosed_suppliers'
                },
                {
                    "label": 'All Non-disclosed Customers',
                    "value": 'all_non_disclosed_suppliers'
                }]
            })
        } else {
            this.setState({
                groupList: [{
                    "label": 'All Suppliers',
                    "value": 'all_suppliers'
                },
                {
                    "label": 'All Disclosed Suppliers',
                    "value": 'all_disclosed_suppliers'
                },
                {
                    "label": 'All Non-disclosed Suppliers',
                    "value": 'all_non_disclosed_suppliers'
                }]
            })
        }

    }

    fetchSecondaryUsers = async () => {
        this.setState({ loading: true })
        const { constants } = auth.getCurrentUser();
        let sortedArray = [];
        let restrictProduct = []; // store product list id -- Only show clients mapped with these products
        if (this.state.selectedFacility.value == "") {
            if (this.props.user_type == 'customer') {
                //restrictProduct = [constants.PRODUCTS.INVOICE, constants.PRODUCTS.DEBTORFINANACE];
                restrictProduct = [constants.PRODUCTS.INVOICE];
            } else {
                restrictProduct = [constants.PRODUCTS.SUPPLYCHAIN, constants.PRODUCTS.TRADEFINANCE];
            }
        } else {
            restrictProduct = [this.state.selectedFacility.value];
        }

        const { data } = await coreService.GetClientMappedUsers({
            ...this.state.values,
            clientSide: true,
            type: this.props.accountType,
            productList: true,
            restrictProduct: restrictProduct
        })
        if (data.rows && data.rows.length > 0) {
            sortedArray = ArraySort(data.rows, 'updated_at', 'DESC');
        } else {
            sortedArray = data.rows;
        }
        this.setState({ secondaryMapped: sortedArray, loading: false });
    }

    fetchBankDetails = async () => {
        this.setState({ outerLoading: true });
        const { data } = await entityService.FetchAllproductBankdetail({ clientSide: true })
        this.setState({ outerLoading: false });
        this.setState({ bankDetail: data.response }, () => { this.loadFacilityFilter() });
    }

    fetchContactDetails = async (item) => {
        const { entity_id } = auth.getCurrentUser();
        this.setState({ outerLoading: true });
        let reqParam = {
            entity: entity_id,
            supplier: item.value,
        }
        const response = await entityService.GetEntityContactDetails(reqParam);
        if (response.data) {
            if (response.data.success == true) {
                this.setState({
                    contactDetail: {
                        contact_id: response.data.contactDetail.contact_id,
                        first_name: response.data.contactDetail.first_name,
                        last_name: response.data.contactDetail.last_name,
                        phone_no: response.data.contactDetail.phone_no
                    },
                    contactOtherOwned: false
                });
            } else {
                if (response.data.entity_other_owned) {
                    this.setState({ contactOtherOwned: true });
                } else {
                    toast.error(response.data.message);
                }
            }

        }
        this.setState({ outerLoading: false });
    }

    loadFacilityFilter = () => {
        let bankAccDetail = "";
        let nickname = "";
        const { constants } = auth.getCurrentUser();
        if (this.props.user_type == 'customer') {

            // bankAccDetail = this.state.bankDetail.filter((acc) => (acc.product == constants.PRODUCTS.INVOICE
            //     || acc.product == constants.PRODUCTS.DEBTORFINANACE)).map(acc => {
            bankAccDetail = this.state.bankDetail.filter((acc) => (acc.product == constants.PRODUCTS.INVOICE)).map(acc => {

                if (acc.product == constants.PRODUCTS.INVOICE) {

                    nickname = this.context.salesRoles.length > 0 ? this.context.salesRoles.find(obj => obj.product == constants.PRODUCTS.INVOICE) ? this.context.salesRoles.find(obj => obj.product == constants.PRODUCTS.INVOICE).nick_name : '' : '';
                } else if (acc.product == constants.PRODUCTS.DEBTORFINANACE) {

                    nickname = this.context.salesRoles.length > 0 ? this.context.salesRoles.find(obj => obj.product == constants.PRODUCTS.DEBTORFINANACE) ? this.context.salesRoles.find(obj => obj.product == constants.PRODUCTS.DEBTORFINANACE).nick_name : "" : ''
                }
                return {
                    'label': nickname,
                    'value': acc.product
                }
            });
        } else {

            bankAccDetail = this.state.bankDetail.filter((acc) => (acc.product == constants.PRODUCTS.SUPPLYCHAIN
                || acc.product == constants.PRODUCTS.TRADEFINANCE)).map(acc => {

                    if (acc.product == constants.PRODUCTS.SUPPLYCHAIN) {
                        nickname = this.context.purchaseRoles.length > 0 ? this.context.purchaseRoles.find(obj => obj.product == constants.PRODUCTS.SUPPLYCHAIN) ? this.context.purchaseRoles.find(obj => obj.product == constants.PRODUCTS.SUPPLYCHAIN).nick_name : "" : '';
                    } else if (acc.product == constants.PRODUCTS.TRADEFINANCE) {
                        nickname = this.context.purchaseRoles.length > 0 ? this.context.purchaseRoles.find(obj => obj.product == constants.PRODUCTS.TRADEFINANCE) ? this.context.purchaseRoles.find(obj => obj.product == constants.PRODUCTS.TRADEFINANCE).nick_name : "" : ''
                    }

                    return {
                        'label': nickname,
                        'value': acc.product
                    }
                });
        }
        if (bankAccDetail.length > 0) {
            this.setState({
                selectedFacility: bankAccDetail['0'],
            })
        }
        this.setState({ allowedFacilityList: bankAccDetail }, () => { this.fetchSecondaryUsers() });
    }


    productList = (products) => {

        let productShort = "";
        let bankAccDetail = "";

        const items = products.map(item => {
            productShort = getInitials(item.product_name, "", 3);

            bankAccDetail = this.state.bankDetail.filter((acc) => acc.product == item.product_id);
            return (
                <>
                    <div className="">
                        <span className={`badge badge-secondary p-1 text-white mr-2`} title={productShort}>
                            {
                                bankAccDetail == "" ? (
                                    productShort
                                ) : (
                                    bankAccDetail['0']['bank_account_name']
                                )
                            }
                        </span>
                    </div>

                </>
            );
        })
        return items;
    }

    /*
    * check if the name or email is a disclosed or not. if yes show four star '****'
    */
    showEmailName = (item) => {
        if (item.toLowerCase().includes('undisclosed')) {
            return "****"
        } else {
            return item;
        }
    }

    /*
    * Api to fetch allowed falicilies with intrction
    */
    fetchFacility = async (item) => {
        const { constants, entity_id } = auth.getCurrentUser();
        this.setState({ outerLoading: true });
        let allowedProducts = [];
        if (this.props.user_type == 'customer') {
            allowedProducts = [constants.PRODUCTS.INVOICE, constants.PRODUCTS.DEBTORFINANACE];
        } else {
            allowedProducts = [constants.PRODUCTS.SUPPLYCHAIN, constants.PRODUCTS.TRADEFINANCE];
        }
        const { data } = await entityService.GetProductsInteraction({
            entity: entity_id,
            supplier: item.value,
            activeProduct: item.product.map((obj) => obj.product_id),
            allowedProducts: allowedProducts
        });
        this.setState({ facilityList: data.result, outerLoading: false });
    }

    editMapping = (item) => {
        this.fetchFacility(item);
        this.fetchContactDetails(item);
        this.setState({
            selectedOrg: item.label,
            selectedEntityGuid: item.value,
            unassignFacilityModal: true
        });
    }

    onCloseUnassignFacilityModal = () => {
        this.setState({
            unassignFacilityModal: false,
            selectedEntityGuid: "",
            selectedOrg: ""
        });
    }

    unssignProduct = async (item) => {

        this.setState({ outerLoading: true });
        const { data } = await entityService.UnlinkAssignedFacility({
            sec_acc_guid: this.state.selectedEntityGuid,
            product_id: item.id,
            clientSide: true
        });
        this.setState({ outerLoading: false });
        if (data.success == true) {
            toast.success("Facility unlinked successfully");
            this.onCloseUnassignFacilityModal();
            this.props.reloadAll !== undefined && (
                this.props.reloadAll()
            )
        } else {
            toast.error(data.response.error)
        }
    }

    assignFacility = async (item) => {
        this.setState({ outerLoading: true });

        let selectedRoles = item["roles"].filter((roleFilter) => roleFilter.isChecked === false);

        let reqParam = {
            bp: this.context.entityDetails.owner.a_guid,
            category: selectedRoles[0].role_name,
            entity: this.context.entityDetails.a_guid,
            from_contact: this.context.entityDetails.owner.owner_primary_guid,
            product: item.id,
            role: selectedRoles[0].id,
            role_name: selectedRoles[0].role_name,
            mapping_entity: [
                {
                    contact_id: this.context.entityDetails.entity_primary_contact_guid,
                    debtor_id: "",
                    disclosure: "1",
                    guid: this.state.selectedEntityGuid,
                }
            ]
        }

        const { data: res } = await CommonService.multipleUserMapping(reqParam);
        this.setState({ outerLoading: false });
        if (res.success === true) {
            toast.success(res.message);
            this.onCloseUnassignFacilityModal();
            this.props.reloadAll !== undefined && (
                this.props.reloadAll()
            )
        } else toast.error(res.message);
    }

    handleInteractionChange = (index, selectedInteraction) => {
        let facilityList = [...this.state.facilityList];
        facilityList[index]['interaction'] = selectedInteraction.value;
        facilityList[index]['edit'] = true;
        this.setState({ facilityList });
    }

    handleFacilityActivation = (checked, index) => {
        let facilityList = [...this.state.facilityList];

        if (checked === true) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">
                            <h4>Are you sure ...?</h4>
                            <p>{`want to enable this facility?`}</p>
                            <button
                                className="btn btn-white"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-primary ml-2"
                                onClick={() => {
                                    facilityList[index]['isChecked'] = checked;
                                    this.assignFacility(facilityList[index]);
                                    this.setState({ facilityList });
                                    onClose();
                                }}
                            >
                                Yes
                            </button>
                        </div>
                    );
                },
                title: "Confirmation",
                message: `Are you sure, want to enable this facility?`,
                overlayClassName: "zindex-2100"
            });
        } else if (checked === false) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">
                            <h4>Are you sure ...?</h4>
                            <p>{`want to disable this facility?`}</p>
                            <button
                                className="btn btn-white"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-primary ml-2"
                                onClick={() => {
                                    this.unssignProduct(facilityList[index]);
                                    facilityList[index]['isChecked'] = checked;
                                    this.setState({ facilityList });
                                    onClose();
                                }}
                            >
                                Yes
                            </button>
                        </div>
                    );
                },
                title: "Confirmation",
                message: `Are you sure, want to disable this facility?`,
                overlayClassName: "zindex-2100"
            });
        }

    }

    UpdateInteraction = async () => {
        this.setState({ outerLoading: true });
        const { data } = await entityService.UpdateInteraction({
            sec_acc_guid: this.state.selectedEntityGuid,
            product: this.state.facilityList,
            clientSide: true
        });
        this.setState({ outerLoading: false });
        if (data.success == true) {
            toast.success("Updated successfully");
        } else {
            toast.error(data.response.error)
        }
    }

    handlePickerChange = (field, option) => {
        if (field == 'group') {
            const values = { ...this.state.values };
            values['group'] = option.value;
            const selectedGroup = { ... this.state.selectedGroup };
            selectedGroup['label'] = option.label;
            selectedGroup['value'] = option.value;
            this.setState({ values, selectedGroup });
        } else {
            const selectedFacility = { ... this.state.selectedFacility };
            selectedFacility['label'] = option.label;
            selectedFacility['value'] = option.value;
            this.setState({ selectedFacility });
        }

    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const values = { ...this.state.values };
        values[name] = value;
        this.setState({ values });
    }

    showSummary = () => {
        this.fetchSecondaryUsers();
    }

    showDisclosure = (selectedItem) => {
        const items = selectedItem.product.map(item => {
            return (
                item.interaction == "disclosed" ? (
                    <div style={{ minWidth: 52 }} className="badge badge-primary p-1 ml-2">Yes</div>
                ) : item.interaction == "undisclosed" ? (
                    <div style={{ minWidth: 52 }} className="badge badge-danger p-1  ml-2">No</div>
                ) : (
                    <div style={{ minWidth: 52 }} className="badge badge-warning p-1 ml-2">
                        <Link className="cursor-link text-white" onClick={() => { this.editMapping(selectedItem) }}>
                            Activate
                        </Link>
                    </div>
                )
            );
        })
        return items;

    }

    showVerificationStatus = (status) => {
        return (
            status == 'verified' ? (
                <div className="badge badge-primary p-1 ml-2">Verified</div>
            ) : status == 'declined' ? (
                <div className="badge badge-warning p-1  ml-2">Declined</div>
            ) : (
                <div className="badge badge-warning p-1  ml-2">Processing</div>
            )
        );
    }

    handleChangeContact = (e) => {
        const { name, value } = e.target;
        const contactDetail = { ...this.state.contactDetail };
        contactDetail[name] = value;
        this.setState({ contactDetail });
    }

    updateContactDetails = async () => {
        this.setState({ outerLoading: true });
        const { data } = await entityService.UpdateContactDetails({
            ...this.state.contactDetail,
            supplier: this.state.selectedEntityGuid,
        });
        this.setState({ outerLoading: false });
        if (data.success == true) {
            toast.success(data.message);
        } else {
            toast.error(data.message)
        }
    }
    closePopoutTable = () => {
        setTimeout(() => {
            this.props.closePopout(false);
        }, 300);

    };

    columnMapping = {
        Organisation: 'label',
        Name: 'name',
        Email: 'email',
        AllowearlyPayments: 'Allow early Payments',
        VerificationStatus: 'verification_status',
    };
    columns = ['Organisation', 'Name', 'Email', 'Allow early Payments', 'Verification Status', 'Action',];
    columnsMap = Object.values(this.columnMapping);

    actions = [
        { label: 'Edit', handler: this.editMapping },
    ];

    render() {

        const { constants } = auth.getCurrentUser();
        return (
            <>
                {this.state.outerLoading && <LoadingSpinner />}
                <div className="">
                    {this.state.loading && <LoadingSpinner normal="true" />}
                    <div className="row">
                        <div className="col-sm-4">
                            <TextInput
                                value={this.state.values.search}
                                onChange={(e) => this.handleChange(e)}
                                name="search"
                                placeholder="Search"
                                type="normal"
                            />
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group">
                                <Picker
                                    name="group"
                                    items={this.state.groupList}
                                    selectedItem={this.state.selectedGroup}
                                    onSelectItem={option => this.handlePickerChange('group', option)}
                                />
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group">
                                <Picker
                                    name="facility"
                                    items={this.state.allowedFacilityList}
                                    selectedItem={this.state.selectedFacility}
                                    onSelectItem={option => this.handlePickerChange('facility', option)}
                                />
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <GeneralButton
                                name="Search"
                                onClick={() => { this.showSummary() }}
                            ></GeneralButton>
                        </div>
                    </div>
                    {
                        this.state.secondaryMapped && this.state.secondaryMapped.length > 0 ? (
                            <table className="scrol_table table text-secondary ">

                                <MappedUsersTable
                                    usertType={this.props.user_type}
                                    data={this.state.secondaryMapped}
                                    showEmailName={this.showEmailName}
                                    onAllowEarlyPayments={this.showDisclosure}
                                    onVerificationStatus={this.showVerificationStatus}
                                    editMapping={this.editMapping}
                                    type="regular"
                                />
                            </table>

                        ) : (
                            <p>Linked entities not found</p>
                        )
                    }
                    {
                        this.state.secondaryMapped && this.state.secondaryMapped.length > 0 ? (
                            <>
                                <Modal
                                    className="fullscreen-modal"
                                    dialogClassName={` ${this.closePopoutTable ? ' closing' : ''}`}
                                    show={this.props.showPopout}
                                    onHide={this.closePopoutTable}
                                    onExit={() => document.body.style.overflow = 'auto'} // Restore body overflow when modal exits
                                    onExiting={() => document.body.style.overflow = 'auto'}
                                >
                                    <Modal.Header closeButton>
                                        <div className="d-block pt-3 text-center w-100 ml-3">

                                            <h3 className="modal-title"></h3>

                                        </div>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <TextInput
                                                        value={this.state.values.search}
                                                        onChange={(e) => this.handleChange(e)}
                                                        name="search"
                                                        placeholder="Search"
                                                        type="normal"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <Picker
                                                            name="group"
                                                            items={this.state.groupList}
                                                            selectedItem={this.state.selectedGroup}
                                                            onSelectItem={option => this.handlePickerChange('group', option)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <Picker
                                                            name="facility"
                                                            items={this.state.allowedFacilityList}
                                                            selectedItem={this.state.selectedFacility}
                                                            onSelectItem={option => this.handlePickerChange('facility', option)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <GeneralButton
                                                        name="Search"
                                                        onClick={() => { this.showSummary() }}
                                                    ></GeneralButton>
                                                </div>
                                            </div>
                                            <table className="table text-secondary ">
                                                <MappedUsersTable
                                                    usertType={this.props.user_type}
                                                    data={this.state.secondaryMapped}
                                                    showEmailName={this.showEmailName}
                                                    onAllowEarlyPayments={this.showDisclosure}
                                                    onVerificationStatus={this.showVerificationStatus}
                                                    editMapping={this.editMapping}
                                                />
                                            </table>

                                        </>
                                    </Modal.Body>
                                </Modal>
                            </>
                        ) : null}


                </div>
                {/*------------------UnAssign linked Product modal-----------------*/}
                <Modal
                    show={this.state.unassignFacilityModal}
                    onHide={() => { this.onCloseUnassignFacilityModal() }}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">
                            Update Contact Details
                        </h4>
                    </Modal.Header>

                    <Modal.Body className="modal-body pt-0">
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                    this.state.contactOtherOwned == true ? (
                                        <p className="text-danger mb-3">
                                            Please note, to update the Customer details please contact your Fifo Capital representative. Thank you.
                                        </p>
                                    ) : (
                                        <>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <TextInput
                                                        value={this.state.contactDetail.first_name}
                                                        onChange={(e) => this.handleChangeContact(e)}
                                                        name="first_name"
                                                        placeholder="First Name"
                                                        label="First Name"
                                                        type="vertical"
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <TextInput
                                                        value={this.state.contactDetail.last_name}
                                                        onChange={(e) => this.handleChangeContact(e)}
                                                        name="last_name"
                                                        placeholder="Last Name"
                                                        label="Last Name"
                                                        type="vertical"
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <TextInput
                                                        value={this.state.contactDetail.phone_no}
                                                        onChange={(e) => this.handleChangeContact(e)}
                                                        name="phone_no"
                                                        placeholder="Phone Number"
                                                        label="Phone Number"
                                                        type="vertical"
                                                    />
                                                </div>
                                                <div className="col-sm-6 pt-1">
                                                    <GeneralButton
                                                        className="btn btn-primary btn-sm mt-4"
                                                        name="Submit"
                                                        onClick={() => { this.updateContactDetails() }}
                                                    ></GeneralButton>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                            </div>
                            <div className="col-lg-12">
                                <h4 className="modal-title">
                                    {`Edit/Unlink ${this.state.selectedOrg}'s Assigned Facility`}
                                </h4>
                                <table className="table text-secondary">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Interaction</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            this.state.facilityList.length > 0 && this.state.facilityList.map((prdt, index) => (

                                                prdt.id != constants.PRODUCTS.DEBTORFINANACE && (
                                                    <>
                                                        <tr>
                                                            <td>{prdt.product_name}</td>
                                                            <td>
                                                                {
                                                                    prdt.interaction !== "" && (
                                                                        <Picker
                                                                            selectedItem={Interaction.filter(itm => itm.value == prdt.interaction)}
                                                                            items={Interaction}
                                                                            onSelectItem={(selectedInteraction) => {
                                                                                this.handleInteractionChange(index, selectedInteraction);
                                                                            }}
                                                                        />
                                                                    )
                                                                }

                                                            </td>
                                                            <td>
                                                                {
                                                                    prdt.roles.filter(itm => ((constants.ROLES_IDS.TFCUSTOMER == itm.id || constants.ROLES_IDS.CUSTOMER == itm.id || constants.ROLES_IDS.CLIENT == itm.id || constants.ROLES_IDS.DFCUSTOMER == itm.id) && itm.isChecked == true)).length > 0 && (
                                                                        <>

                                                                            <div className="custom-control d-flex custom-checkbox small">
                                                                                <input
                                                                                    id={`facility_${index}`}
                                                                                    name={`facility_${index}`}
                                                                                    className="custom-control-input"
                                                                                    type="checkbox"
                                                                                    checked={prdt.isChecked}
                                                                                    onClick={(e) =>
                                                                                        this.handleFacilityActivation(
                                                                                            e.target.checked,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                ></input>
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    for={`facility_${index}`}
                                                                                >Enable</label>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }

                                                            </td>
                                                        </tr>
                                                    </>
                                                )

                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>

                            <div className="col-lg-12">
                                <GeneralButton
                                    className="btn btn-primary float-right"
                                    name="Submit"
                                    onClick={this.UpdateInteraction}
                                    disabled={this.state.disableAssignBtn}
                                ></GeneralButton>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
                {/*---------------------------------------------------------*/}

            </>
        );
    }
}
export default MappedUsers;