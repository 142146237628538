import React, { useState, useEffect } from 'react';
import SearchIcon from "../../../../../img/search.gif";
import _ from "lodash";

import {
    FormRow,
} from "../../../../common/containers/formContainer";
import Modal from 'react-bootstrap/Modal';
import {
    GeneralButton, GridActionButton
} from "../../../../common/inputs";

import externalApiService from "../../../../../services/externalApiService";
import useApi from '../../../../../hooks/useApi';
import entityService from "../../../../../services/entityService";
import { toast } from "react-toastify";
import LoadingSpinner from '../../../../common/loaders/loadingSpinner';
//import ConvertToAccount from "../widgets/_convertToAccount";
import auth from "../../../../../services/authService";

export default function AbnNameSearch({ searchBy, entitySearchKey, handleAbnSearch, onClose }) {

    const [abnNumbers, setAbnNumbers] = useState([]);
    const [selectedAbn, setSelectedAbn] = useState({ abn: null });
    const [validationMsg, setValidationMsg] = useState(null);
    const [visibleSubmit, setVisibleSubmit] = useState(false);
    const [searchType, setSearchType] = useState(null);
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [selectedProspect, setSelectedProspect] = useState(null);
    const { constants, category } = auth.getCurrentUser();
    const [showConvertBtn, setShowConvertBtn] = useState(false);

    //------------Api Services--------------------------------------------

    const sendAlertApi = useApi(entityService.sendAlertOnAccountSearch);
    const externalSearchApi = useApi(externalApiService.abnSearchByOrgName);

    const handleOrganisationSearch = async organisation => {

        setValidationMsg(`Connecting to ${global.config.title} database `);

        const dbResponse = await externalSearchApi.request({ organisation, type: searchBy, from: "api" });

        if (dbResponse.data.organisations.length === 0) {
            console.log("haii");
            setValidationMsg(`Unable to locate the keyword on ${global.config.title} database.`);
            setValidationMsg("Connecting to ASIC Server...");
            if (searchBy == 1) {
                const asicResponse = await externalSearchApi.request({ organisation, type: searchBy, from: "asic" });
                if (asicResponse.data.organisations.length > 0) {
                    setValidationMsg(null);
                    setAbnNumbers(asicResponse.data.organisations);
                    setSearchType("asic");
                } else {
                    setShow(true);
                    setValidationMsg(`Unable to locate the keyword on ${global.config.title} database and ASIC Server.`);
                }
            }
            else {
                const asicResponse = await externalSearchApi.request({ organisation, from: "asic" });
                if (asicResponse.data.organisations.length > 0) {
                    setValidationMsg(null);
                    setAbnNumbers(asicResponse.data.organisations);
                    setSearchType("asic");
                } else {
                    setShow(true);
                    setValidationMsg(`Unable to locate the keyword on ${global.config.title} database and ASIC Server.`);
                }
            }

        }
        else {
            setValidationMsg(null);
            setAbnNumbers(dbResponse.data.organisations);
            setSearchType("api");
        }
    }

    const handleAsicApiSearch = async () => {
        setValidationMsg("Connecting to ASIC Server...");
        const asicResponse = await externalSearchApi.request({ organisation: entitySearchKey, from: "asic" });
        if (asicResponse.data.organisations.length > 0) {
            setValidationMsg(null);
            setAbnNumbers(asicResponse.data.organisations);
            setSearchType("asic");
        } else {
            setShow(true);
            setValidationMsg(`Unable to locate the keyword on ${global.config.title} database and ASIC Server.`);
        }
    }

    const sendAlert = async () => {
        const { data } = await sendAlertApi.request({ entitySearchKey, searchBy });
        if (data.success)
            toast.success(data.message);
        else
            toast.error(data.message);
        onClose();
    }

    const handleSubmit = () => {
        onClose();
        handleAbnSearch(selectedAbn, searchType);
    }

    useEffect(() => {
        handleOrganisationSearch(entitySearchKey);
    }, [entitySearchKey]);

    // Open/Close 'Move to Accounts' Modal.
    const handleEntityConversionModal = (a_guid) => {
        setModalShow(true);
    };

    const handleAccountSelect = (obj) => {
        setSelectedProspect(obj.a_guid); setSelectedAbn(obj);
        if (obj.type == 1) {
            setShowConvertBtn(true);
            setVisibleSubmit(false);
        }
        else {
            setVisibleSubmit(true);
            setShowConvertBtn(false);
        }

    }

    const modalBodyStyle = (abnNumbers.length > 0) ? { width: 700, maxHeight: 329, overflow: "auto" } : { width: 700 }

    return (
        <>

            <Modal.Header closeButton>
                <h4 className="card-title">Search Results</h4>
            </Modal.Header>

            <Modal.Body>
                {sendAlertApi.loading === true && <LoadingSpinner />}
                <FormRow className=" text-center">
                    {externalSearchApi.loading === true && <img src={SearchIcon} width="60" height="60" />}
                </FormRow>

                {validationMsg !== null &&
                    <FormRow className="m-t-lg text-center">
                        <h6 className="m-md text-primary">{validationMsg}</h6>
                        {show &&
                            <GridActionButton handleClick={sendAlert} label="Notify Head Office Team" icon="fa fa-bell"></GridActionButton>
                        }
                    </FormRow>
                }

                {abnNumbers.length > 0 && validationMsg === null &&
                    <div class="table-responsive dyn-height">
                        <h6 className="mb-3">{` ${searchType === 'asic' ? 'ASIC server' : `${global.config.title} database`}.`}</h6>
                        <table class="table table-hover">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Organisation</th>
                                    <th>ABN</th>
                                    {searchType === 'asic' && <th>Status</th>}
                                    {searchType === 'api' && <th>Role</th>}
                                </tr>
                                {abnNumbers.map((obj, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>
                                                    {searchType === 'api' &&
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input onChange={(e) => handleAccountSelect({ ...obj, id: index })}
                                                                type="radio"
                                                                id={`customRadioInline${obj.abn + '_' + index}`}
                                                                checked={(obj.a_guid === selectedProspect) ? true : false}
                                                                class="custom-control-input" />
                                                            <label className="custom-control-label" htmlFor={`customRadioInline${obj.abn + '_' + index}`} />
                                                        </div>
                                                    }
                                                    {(searchType === 'asic' && obj.status == "Registered") &&
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input onChange={(e) => handleAccountSelect({ ...obj, id: index })}
                                                                type="radio"
                                                                value="orgnum"
                                                                id={`customRadioInline${obj.abn + '_' + index}`}
                                                                checked={(index === selectedAbn.id) ? true : false}
                                                                class="custom-control-input" />
                                                            <label className="custom-control-label" htmlFor={`customRadioInline${obj.abn + '_' + index}`} />
                                                        </div>
                                                    }
                                                </td>
                                                <td> {obj.organisation} </td>
                                                <td> {obj.abn} </td>
                                                {searchType === 'asic' &&
                                                    <td> <span className={obj.status === 'Registered' ? 'text-success' : 'text-danger'}>{obj.status}</span> </td>
                                                }
                                                {searchType === 'api' &&
                                                    <td>{obj.category == constants['CATEGORY']['TRADERS'] ? "Account" : obj.category}</td>
                                                }
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                }
            </Modal.Body>

            <Modal.Footer>

                {searchType === "api" && validationMsg === null &&
                    <>
                        <span className="text-danger">Not found what you are looking for ? </span> &nbsp; <a href="#" onClick={handleAsicApiSearch}> Search on ASIC server</a>
                    </>
                }

                <FormRow>
                    <GeneralButton pullRight="right" type="button" onClick={onClose} style={{ marginRight: "10px" }} className="btn btn-light pull-right " name="Cancel"></GeneralButton>&nbsp;
                    {showConvertBtn === true &&
                        <button onClick={() => handleEntityConversionModal()} className="btn btn-default  btn-sm m-r-lg btn-outline grid-action-btn float-right"><i className="fa fa-random"></i> Convert to an Account</button>
                    }
                    {visibleSubmit === true &&
                        <GeneralButton onClick={handleSubmit} className="btn btn-primary pull-right m-l-xs" name="Submit"></GeneralButton>
                    }
                </FormRow>
            </Modal.Footer>

            {/*------------------Move to Accounts Modal-------------------*/}
            {/* <Modal
                show={modalShow}
                onHide={(e) => setModalShow(false)}
                size="sm"
            >
                <ConvertToAccount a_guid={selectedProspect} onClose={onClose} from="account" type="secondary" />
            </Modal> */}
            {/*---------------------------------------------------------*/}
        </>
    )
}