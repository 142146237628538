import React from "react";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import Form from "../../../common/Form";
import { Link } from "react-router";
import {
    TextInput,
    Picker,
    CurrencyText,
    DateTimePicker
} from "../../../common/inputs";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { FormRow, FormColumnLg } from "../../../common/containers/formContainer";
import MultipleFileInput from "../../../common/inputs/multipleFileInput/multipleFileInput";
import Modal from "react-bootstrap/Modal";
import Joi from "joi-browser";
import coreService from "../../../../services/coreService";
import InvIcon from "../../../../img/invoice.svg";
import auth from "../../../../services/authService";
import EntityContext from "../../../../context/entityContext";
class CreateTransaction extends Form {

    static contextType = EntityContext;

    InitialState = {
        FormDetails: {
            supplier_id: '',
            supplier: '',
            purchaseOrder: '',
            invoiceNumber: '',
            amount: '',
            invoiceDate: null,
            invoiceDueDate: null,
            files: null,
            trans_guid: null,
            attachmentFile: "",
        },
        files: {
            data: [],
            document_name: ""
        },
        loading: false,
        clientDebtor: [],
        selectedclientDebtor: {
            label: "--Select--",
            value: "",
        },
    };
    constructor(props) {
        super(props);
        this.state = this.InitialState;
        this.supplierRef = React.createRef();
        this.invoiceNumberRef = React.createRef();
        this.amountRef = React.createRef();
        this.invoiceDateRef = React.createRef();
        this.invoiceDueDateRef = React.createRef();
    }

    componentDidMount() {
        this.fetchMappedUser();
        if (this.props.editTransaction === true) {
            let itemDetail = this.props.selectedTrans;
            let attachment_name = [];
            if (itemDetail.attachment) {
                attachment_name = itemDetail.attachment.split("/");
            }
            this.setState({
                FormDetails: {
                    supplier_id: '',
                    supplier: '',
                    purchaseOrder: itemDetail.inv_purchase_order_number,
                    invoiceNumber: itemDetail.inv_number,
                    amount: itemDetail.inv_amount,
                    invoiceDate: itemDetail.trans_date,
                    invoiceDueDate: itemDetail.inv_due_date,
                    files: null,
                    trans_guid: itemDetail.trans_guid,
                    attachmentFile: attachment_name.length > 0 ? attachment_name[attachment_name.length - 1] : "",
                },
                files: {
                    data: [],
                    document_name: ""
                },
                loading: false,
                clientDebtor: [],
                selectedclientDebtor: {
                    label: itemDetail.contact.organization,
                    value: itemDetail.contact.a_guid,
                },
            });
        } else {
            this.setState(this.InitialState);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectUserType !== prevProps.selectUserType) {
            this.setState(this.InitialState);
            this.fetchMappedUser();

        }
    }

    schema = Joi.object()
        .keys({
            supplier: Joi.required().label("Supplier").error(() => { return { message: "Please select a Supplier" }; }),
            //purchaseOrder: Joi.string().label("Purchase Order").error(() => { return { message: "Please enter the Purchase Order" }; }),
            invoiceNumber: Joi.string().required().label("Invoice Number").error(() => { return { message: "Please enter the Invoice Number" }; }),
            amount: Joi.number().min(1).required().label("Amount").error(() => { return { message: "Please enter the Amount" }; }),
            invoiceDate: Joi.date().required().label("Invoice Date").error(() => { return { message: "Please enter Invoice Date" }; }),
            invoiceDueDate: Joi.date().min(Joi.ref("invoiceDate")).required().label("Invoice Due Date").error(() => {
                return {
                    message: "Due date must be a date greater than Invoice date.",
                };
            })

        })
        .unknown(true);

    validate = () => {
        const options = { abortEarly: true };
        const { error } = Joi.validate(this.state.FormDetails, this.schema, options);

        if (!error) return null;

        const errors = {};
        for (let item of error.details) {
            errors[item.path[0]] = item.message;
            toast.error(item.message);
            this.focusInput(item.path[0]);
        }
        return errors;
    };

    focusInput = (fieldName) => {
        if (fieldName === "invoiceNumber") this.invoiceNumberRef.current.focus();
        else if (fieldName === "supplier") this.supplierRef.current.focus();
        else if (fieldName === "amount") this.amountRef.current.focus();
        else if (fieldName === "invoiceDate") this.invoiceDateRef.current.setFocus(true);
        else if (fieldName === "invoiceDueDate") this.invoiceDueDateRef.current.setFocus(true);
    }

    doSubmit = async () => {
        this.setState({ loading: true });
        try {

            let fileslist = [];
            fileslist = await this.handleFileReader();

            const FormDetails = { ...this.state.FormDetails };


            FormDetails['files'] = fileslist;
            FormDetails['initiated_user_type'] = this.props.initiated_user_type;
            FormDetails['invoice_type'] = this.props.invoice_type;

            setTimeout(async () => {
                const { data } = await coreService.createGeneralInvoice(FormDetails, fileslist, "customer");


                if (data.success === false) {
                    toast.error(data.message);
                } else {
                    this.props.refreshCard();
                    toast.success(data.message);
                    this.setState(this.InitialState);
                    this.props.onCloseModal();
                }
                this.setState({ loading: false });
            }, 100);


        } catch (error) {
            console.log(error);
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        const FormDetails = { ...this.state.FormDetails };
        FormDetails[name] = value;
        this.setState({ FormDetails });
    }

    handleAmount = (val1, val2, amount) => {
        const FormDetails = { ...this.state.FormDetails };
        FormDetails['amount'] = amount;
        this.setState({ FormDetails });
    }

    changeClientDebtor = (selectedOption) => {
        this.setState({
            selectedclientDebtor: {
                label: selectedOption.label,
                value: selectedOption.value,
            }
        });
        const FormDetails = { ...this.state.FormDetails };
        FormDetails['supplier_id'] = selectedOption.value;
        FormDetails['supplier'] = selectedOption.label;
        this.setState({ FormDetails });
    }

    handleFileChange = (fileItems) => {
        if (fileItems[0]) {
            const files = { ...this.state.files };
            files['document_name'] = fileItems[0].file.name;
            files['data'] = fileItems.map((fileItem) => fileItem.file);
            this.setState({ files });
        }
    };

    handleFileReader = async () => {
        /** --------------- Reading local files in JavaScript -------------- */
        let file = this.state.files.data;
        let fileslist = [];
        if (file.length < 0) {
            return fileslist
        }
        file.map((fileItem) => {
            let reader = new FileReader();
            reader.readAsDataURL(fileItem);
            reader.onload = (e) => {
                fileslist.push({
                    name: fileItem.name,
                    file: e.target.result,
                    type: fileItem.type,
                    size: fileItem.size,
                });
            };
        });

        return fileslist;
        /** ----------------------------------------------------------------- */
    };

    fetchMappedUser = async () => {
        this.setState({ loading: true });
        const { constants } = auth.getCurrentUser();

        let general = this.context.purchaseRoles.some(item => 
            item.product === constants.PRODUCTS.SUPPLYCHAIN || item.product === constants.PRODUCTS.TRADEFINANCE);
        
        let restrictProduct = []; // store product list id -- Only show clients mapped with these products
        if (this.props.invoice_type == 'receivable') {
            restrictProduct = [constants.PRODUCTS.INVOICE, constants.PRODUCTS.DEBTORFINANACE];
        } else {
            restrictProduct = [constants.PRODUCTS.SUPPLYCHAIN, constants.PRODUCTS.TRADEFINANCE];
        }
        const { data } = await coreService.GetClientMappedUsers({ clientSide: true, type: this.props.selectUserType, restrictProduct: restrictProduct, general });
        if (data.success === false) {
            toast.error('Failed to load customer/debtor');
        } else {
            this.setState({ clientDebtor: data.rows });
        }
        this.setState({ loading: false });
    }

    handleRemoveAttachment = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Are you sure ...?</h4>
                        <p>{`want to remove attachment?`}</p>
                        <button
                            className="btn btn-white"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => {
                                this.removeAttachment();
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "Confirmation",
            message: `Are you sure, want to remove attachment?`,
            overlayClassName: "zindex-2100"
        });
    }

    removeAttachment = async () => {
        this.setState({ loading: true });
        const { data } = await coreService.RemoveAttachment({ trans_guid: this.state.FormDetails.trans_guid });
        if (data.success) {
            toast.success(data.message);
            const FormDetails = { ...this.state.FormDetails };
            FormDetails['attachmentFile'] = "";
            this.setState({ loading: false, FormDetails: FormDetails });
        } else {
            toast.error(data.message);
        }

    }

    render() {
        const { FormDetails, loading } = this.state;
        return (
            <>


                <Modal.Header closeButton className="justify-content-center">
                    <div className="d-block pt-3 text-center w-100 ml-4">
                        <img width={"50px"} src={InvIcon} className="mb-2" />
                        <h4 className="modal-title">{
                            this.props.invoice_type == 'receivable' ? (
                                "ADD NEW ACCOUNTS RECEIVABLE INVOICE"
                            ) : (
                                "ADD NEW ACCOUNTS PAYABLE INVOICE"
                            )
                        }</h4>
                        <small className="">Please enter the invoice details below.</small>
                    </div>

                </Modal.Header>
                <Modal.Body>
                    {loading && <LoadingSpinner />}
                    <form onSubmit={this.handleSubmit}>
                        <div className="px-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group required">
                                        <label title={`Can’t find the ${this.props.initiated_user_type}? Select Network from the main menu to add the ${this.props.initiated_user_type}.`}>{this.props.dropDownHeading}</label>
                                        {
                                            this.props.editTransaction === true ? (
                                                <TextInput
                                                    name="suplr"
                                                    value={this.state.selectedclientDebtor.label}
                                                    //label="Purchase Order Number"
                                                    type=""
                                                    //readonly="true"
                                                    disabled
                                                ></TextInput>
                                            ) : (
                                                <Picker
                                                    items={this.state.clientDebtor}
                                                    selectedItem={this.state.selectedclientDebtor}
                                                    onSelectItem={this.changeClientDebtor}
                                                    name="supplier"
                                                    reference={this.supplierRef}
                                                />
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Purchase Order</label>
                                        <TextInput
                                            name="purchaseOrder"
                                            value={FormDetails.purchaseOrder}
                                            label="Purchase Order Number"
                                            // reference={this.purchase_order_no} 
                                            onChange={this.handleChange}
                                            type=""
                                        // readonly={this.state.readOnly}
                                        //  title={this.state.readOnly ? "Please choose the supplier " : ""} 
                                        ></TextInput>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group required">
                                        <label className="control-label">Invoice Number</label>
                                        <TextInput
                                            name="invoiceNumber"
                                            value={FormDetails.invoiceNumber}
                                            label="Invoice Number"
                                            onChange={this.handleChange}
                                            type=""
                                            reference={this.invoiceNumberRef}
                                        ></TextInput>
                                    </div>
                                </div>
                            </div>
                            {/*------------------------------------------------------------------*/}
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group required">
                                        <label className="control-label">Amount</label>

                                        <CurrencyText
                                            placeholder="Amount + Tax"
                                            name="amount"
                                            onChangeEvent={this.handleAmount}
                                            reference={this.amountRef}
                                            value={FormDetails.amount > 0 ? FormDetails.amount : null}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group required">
                                        <label className="control-label">Invoice Date</label>
                                        <DateTimePicker
                                            name="invoiceDate"
                                            selectedDate={FormDetails.invoiceDate}
                                            onChange={this.handleChange}
                                            placeholder="Select Invoice Date"
                                            format="dd/MM/y"
                                            type="normal"
                                            reference={this.invoiceDateRef}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group required">
                                        <label className="control-label">Invoice Due Date</label>

                                        <DateTimePicker
                                            name="invoiceDueDate"
                                            selectedDate={FormDetails.invoiceDueDate}
                                            onChange={this.handleChange}
                                            placeholder="Select Invoice Due Date"
                                            format="dd/MM/y"
                                            type="normal"
                                            reference={this.invoiceDueDateRef}
                                        />

                                    </div>
                                </div>
                            </div>


                            <FormRow className="row m-t-md"> <FormColumnLg col="4"> <label>Invoice Files</label></FormColumnLg></FormRow>

                            <FormRow className="row">
                                <FormColumnLg col="12">
                                    <MultipleFileInput
                                        files={this.state.files.data}
                                        allowMultiple={false}
                                        // onupdatefiles={(fileItems) => {
                                        // //   const data = { ...this.state.data };
                                        //   if (fileItems[0])
                                        //     // data["document_name"] = fileItems[0].file.name;
                                        //     setDocName(fileItems[0].file.name)
                                        //   // Set current file objects to this.state
                                        // //   this.setState({
                                        // //     files: fileItems.map((fileItem) => fileItem.file),
                                        // //     data,
                                        // //   });
                                        // }}
                                        onupdatefiles={(fileItems) => {
                                            this.handleFileChange(fileItems);
                                        }}
                                    ></MultipleFileInput>
                                </FormColumnLg>
                                {
                                    this.props.editTransaction == true && this.state.FormDetails.attachmentFile !== "" && (
                                        <>
                                            <FormColumnLg col="12">
                                                <div className=" alert alert-info">
                                                    <div className="row">
                                                        <div className="col-sm-8">{this.state.FormDetails.attachmentFile}</div>
                                                        <div className="col-sm-4">
                                                            <Link
                                                                title="Remove attachment"
                                                                className="pull-right cursor-link"
                                                                onClick={() => { this.handleRemoveAttachment() }}
                                                            ><i className="fa fa-trash text-danger"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </FormColumnLg>
                                        </>
                                    )
                                }

                            </FormRow>

                            <div className="row mt-2 mb-2">
                                <div className="col-lg-12">
                                    <button
                                        className="btn btn-primary btn-sm pull-right px-4"
                                        type="submit"
                                    >
                                        {this.props.editTransaction === true ? 'Update' : 'Add'}
                                    </button>
                                </div>
                                <div className="col-lg-12 mt-2">
                                    {
                                        this.props.editTransaction === true && this.props.selectedTrans.transaction_type != 'fifopay' && (
                                            <>
                                                <div className='alert alert-info'>Note: The same changes will be affected in your accounting software too.</div>
                                            </>
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                    </form>
                </Modal.Body>

            </>
        );
    }
}

export default CreateTransaction;
