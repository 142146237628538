import React, { useContext, useState, useCallback  } from "react";
import Joi from "joi-browser";

import { EntityDebtorList } from "../../slplusTools";
import { GeneralButton } from "../../common/inputs";

import TransactionContext from "../../../context/invTransactionContext";
import EntityContext from "../../../context/entityContext";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import authService from "../../../services/authService";
import entityService from "../../../services/entityService";
import useApi from "../../../hooks/useApi";

export default function DFDebtorMapping({
  onClose,
  selectedRow,
  handleUpdateInvoice = null,
  context = true,
  invoiceSet = null,
  debtorList = null,
}) {
  let transaction = {};
  let invoice = [];
  let updateInvoice;

  let contextObj = useContext(TransactionContext);
  const entityContext = useContext(EntityContext);
  const { constants } = authService.getCurrentUser();

  const entityServiceApi = useApi(entityService.getEntityDebtorsList);

  if (context === true) {
    transaction = contextObj.transaction;
    invoice = contextObj.invoice;
    updateInvoice = contextObj.updateInvoice;
    if (!transaction) {
      // ---- Set BP/Client default values from Context API (if applicable) ---- //
      if (entityContext && typeof entityContext.entityDetails !== "undefined") {
        transaction = {
          bp: entityContext.entityDetails.owner.a_guid,
          client: entityContext.entityDetails.a_guid,
        };
      } else {
        transaction = {
          bp: selectedRow.trans_owner,
          client: selectedRow.trans_client_guid,
        };
      }
      //----------------------------------------------------------------------//
    }
  } else {
    invoice = [...invoiceSet];

    if (entityContext && typeof entityContext.entityDetails !== "undefined") {
      transaction = {
        bp: entityContext.entityDetails.owner.a_guid,
        client: entityContext.entityDetails.a_guid,
      };
    } else {
      transaction = {
        bp: selectedRow.trans_owner,
        client: selectedRow.trans_client_guid,
      };
    }
  }


  const [selectedDebtor, setSelectedDebtor] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  //----------------- add invoice to context api ------------------//
  const handleDebtorChange = (index, debtor) => {
    let updatedInv = invoice.find((obj) => obj.index == index);
    updatedInv["debtor_name"] = debtor.label;
    updatedInv["inv_debtor_id"] = debtor.value;
    if (context == true) updateInvoice(index, updatedInv);
    else {
      handleUpdateInvoice(index, updatedInv);
    }

    if (invoice.filter((obj) => obj.debtor_name === false).length === 0)
      onClose();
  };
  //---------------------------------------------------------------//

  const handleDebtorBulkAssign = () => {
    
    if (selectedDebtor === null) {
      toast.error("Please select debtor from the list.");
      return false;
    }

    let checkedList = invoice.filter((obj) => ( obj.checked === true && obj.debtor_name === false));
    if(checkedList.length < 1){
      toast.error("Please select invoice from the list.");
      return false;
    }

    invoice
      .filter((obj) => ( obj.checked === true && obj.debtor_name === false))
      .map((item) => {
        item["debtor_name"] = selectedDebtor.label;
        item["inv_debtor_id"] = selectedDebtor.value;
        if (context == true) {
          updateInvoice(item["index"], item);
        } else handleUpdateInvoice(item["index"], item);
      });

      setTimeout(() => {
        if(invoice.filter((obj) => (obj.debtor_name === false)).length < 1){
          onClose();
        }
      }, 1000);
    
  };

  const handleBulkSelect =(checked) => {
    invoice
    .filter((obj) => obj.debtor_name === false)
      .map((item) => {
        item.checked = checked;
        if (context == true) {
          updateInvoice(item["index"], item);
        } else {
          handleUpdateInvoice(item["index"], item)
        };
      });
      setSelectAll(checked);
  }

  const handleSelection = (checked, index) => {

    invoice
      .filter((obj) => obj.index === index)
      .map((item) => {
        item.checked = checked;
        if (context == true) {
          updateInvoice(item["index"], item);
        } else {
          handleUpdateInvoice(item["index"], item)
        };
      });
    let checkedItem = invoice.filter((obj) => (obj.checked === true && obj.debtor_name === false));
    if (checkedItem.length < 1) {
      setSelectAll(false);
    }
    if(checkedItem.length == invoice.length){
      setSelectAll(true);
    }else {
      setSelectAll(false);
    }
  }


  return (
    <>
      <Modal.Header closeButton>
        <h4 className="modal-title">Invoice - Debtor Mapping</h4>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-lg-6">
            <EntityDebtorList
              selectedDebtor={selectedDebtor}
              client={transaction.client}
              onSelectItem={(debtor) => setSelectedDebtor(debtor)}
              product={constants.PRODUCTS.DEBTORFINANACE}
              debtorList={debtorList}
            />
          </div>
          <div className="col-lg-6">
            <GeneralButton
              onClick={handleDebtorBulkAssign}
              className="btn btn-primary pull-right btn-sm"
              name="Allocate debtor"
            ></GeneralButton>
          </div>
        </div>

        <br></br>

        <table class="table table-sm client-tble">
          <tbody>
            <tr>
              <th>
                <div className="custom-control d-flex custom-checkbox small">
                  <input
                    id="debtor_select_all"
                    className="custom-control-input"
                    type="checkbox"
                    checked={selectAll}
                    onClick={(e) =>
                      handleBulkSelect(e.target.checked)
                    }
                  ></input>
                  <label
                    className="custom-control-label"
                    for="debtor_select_all"
                  ></label>
                </div>
              </th>
              <th>Invoice No</th>
              <th>Name in the sheet</th>
              <th>Name in the system</th>
            </tr>
            {invoice
              .filter((obj) => obj.debtor_name === false)
              .map((item, index) => (
                <tr>
                  <td>
                      <div className="custom-control d-flex custom-checkbox small">
                        <input
                          id={index}
                          className="custom-control-input"
                          type="checkbox"
                          onClick={(e) =>
                            handleSelection(
                              e.target.checked,
                              item.index
                            )
                          }
                          checked={item.checked}
                        ></input>
                        <label
                          className="custom-control-label"
                          for={index}
                        ></label>
                      </div>
                    </td>
                  <td>{item.inv_number}</td>

                  <td>{item.debtor_name_in_sheet}</td>

                  <td>
                    <EntityDebtorList
                      selectedDebtor={item.inv_debtor_id}
                      client={transaction.client ? transaction.client : ""}
                      onSelectItem={(debtor) =>
                        handleDebtorChange(item.index, debtor)
                      }
                      product={constants.PRODUCTS.DEBTORFINANACE}
                      debtorList={debtorList}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal.Body>
    </>
  );
}
