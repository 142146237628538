import React from 'react';
import { TextInput } from "../../../../common/inputs/textInput/textInput";
import {currencies} from "../../../config/predefinedValues.json";
import Picker from "../../../../common/inputs/picker/picker";
import { CurrencyText } from "../../../../common/inputs/currencyInput/currencyInput";

export default function CurrencyDetails({data,handleChange,handleCurrencyChange,setFcy}) {
    return (
        <div className="row">

            <div className="col-lg-4">
                <div className="form-group">
                    <label>Currency</label>
                    <div >
                    <Picker
                        name = "currency"
                        items={currencies}
                        selectedItem={data.currency}
                        onSelectItem={handleCurrencyChange}
                    />
                    </div>
                </div>
            </div>


            <div class="col-lg-4">
                <div class="form-group ">
                    <label class="">Exchange Rate</label>
                    <div>
                       <TextInput name="exchangeRate" value={data.exchangeRate} label="Exchange Rate" onChange={handleChange} type="normal" number={true}></TextInput>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="form-group ">
                    <label class="">FCY Amount</label>
                    <div>
                       <CurrencyText currencySymbol ={data.currency.value} placeholder="FCY Amount" name="fcyAmount" onChangeEvent={setFcy} value={data.amount > 0 ? data.amount * data.exchangeRate : null} />
                    </div>
                </div>
            </div>
        </div>
    );
}