import React from "react";
import GeneralSettings from './generalSettings';
import { Helmet } from "react-helmet";
function ClientSettings() {
    return (
        <>
            <Helmet>
                <title>Settings | {global.config.title}</title>
            </Helmet>


            <div className="row justify-content-center">

                <GeneralSettings />
                {/* <AdvancedSettings />*/}
            </div>


        </>
    )
}

export default ClientSettings;