import { toast } from "react-toastify";
import Joi from "joi-browser";

const Validation = () => {
  const validate = (data, schema) => {
    const options = { abortEarly: true };
    console.log(data);
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
    }
    return errors;
  };
  return { validate };
};

export default Validation;
