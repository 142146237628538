import React from "react";
import Modal from "react-bootstrap/Modal";
import { CurrencyLabel } from "../../../../../common/inputs";

const PaymentConfirmation = (props) => {
  return (
    <Modal
      show={props.paymentSettlementConfirmModal}
      onHide={() => {
        props.setPaymentSettlementConfirmModal(false);
      }}
      centered
      contentClassName="bg-secondary text-white"
      backdropClassName="bg-white"
    >
      <Modal.Header closeButton />

      <Modal.Body>
        <div className="text-center mb-4">
          <div className="media-icon media-icon-lg bg-primary-transparent text-primary mx-auto mb-4">
            <i className="fa fa-check-square"></i>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <h6 className="text-white mb-4">
              You have confirmed a settlement amount of{" "}
                <CurrencyLabel value={props.paymentResponse?.reqAmount || 0} />
                
              </h6>
              <button
                className="btn btn-white ml-2"
                onClick={() => {
                  props.setPaymentSettlementConfirmModal(false);
                  props.setShowPayment(false);
                  props.setData([]);
                  // props.fetchAllDetails()
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PaymentConfirmation;
