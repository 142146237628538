import React, { useState, useContext, useEffect } from 'react';
import { Line } from 'react-chartjs-2'
import useApi from '../../../../hooks/useApi';
import { CurrencyLabel } from "../../../common/inputs";
import WidgetService from "../../../../services/invTransactions";
import { AddButton } from "../../../common/containers/button";
import clientRequest from "../../../../services/serviceClient";
import CardSkelton from "../../components/cards/_cardSkeltonTypeOne";
import EntityContext from "../../../../context/entityContext";
import { FormRow } from "../../../common/containers/formContainer";
import { browserHistory } from "react-router";
import authService from "../../../../services/authService";

function ClientCard() {

    //------------Api Services--------------------------------------------
    const graphApi = useApi(WidgetService.getDisbursementsGraph);
    const [graphData, setGraphData] = useState(null);
    const { selectedBusinessType, entityDetails, selProduct, openInvoiceModal } = useContext(EntityContext);
    const { entity_id, constants } = authService.getCurrentUser();

    const fetchGraphData = async () => {
        const response = await graphApi.request({ selectedBusinessType, entity_id: entityDetails.a_guid, });
        if(response)
            setGraphData(response.data);
    }

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        responsive: true,
        maintainAspectRatio: true,
    }

    const graph = {
        labels: graphData ? graphData.labels : "",
        datasets: [
            {
                label: 'Amount',
                data: graphData ? graphData.data : "",
                backgroundColor: [
                    '#16C79A',
                ],

                borderWidth: 1,
                fill: true,
                backgroundColor: '#16C79A',
                borderColor: 'rgba(0, 88, 122, 1)',
                pointBackgroundColor: "#19456b"
            },
        ],
    }

    useEffect(() => {
        fetchGraphData();

        return () => {
            clientRequest.cancel();
        }
    }, [])

    return (
            graphApi.loading === true ? (
                <CardSkelton />
            ) : (
                <>
                    <div className="col-md-8 col-sm-8 col-xs-6 col-12">
                        <div className="card">

                            {/*----------- Disbursement Graph -----------*/}
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h4 className="p-0 m-0 card-title">Open Transactions</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        {selectedBusinessType == constants.BUSINESS_TYPE.SALE && <>
                                            <AddButton style="btn btn-primary btn-sm pull-right" onClick={() => openInvoiceModal(selProduct)} label="Add New"></AddButton>
                                            <AddButton style='btn btn-secondary btn-sm mr-2 pull-right' label="Bulk Upload" onClick={() => browserHistory.push(`/client/if/bulk-upload?a_guid=${entity_id}`)}></AddButton>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <FormRow>
                                    <div className="col-md-12">
                                        <div className="flot-chart-content" id="flot-line-chart"> <Line data={graph} options={options} /></div>
                                    </div>
                                </FormRow>
                            </div>
                            {/*------------------------------------------*/}

                            {/*----------- Open Invoices info------------*/}
                            <div className="row justify-content-center mt-2 mb-3">
                                <div className="col-lg-8 ">
                                    <div className="progress mb-4 mt-3 rounded-md">
                                        <div
                                            className="progress-bar  w-50 bg-primary "
                                            role="progressbar"
                                            aria-valuenow="50"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                        <div
                                            className="progress-bar  w-50 bg-secondary"
                                            role="progressbar"
                                            aria-valuenow="50"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div>

                                    <div className="row mb-0">
                                        <div className="col-4 text-center">
                                            <h5 className="mb-1">{<CurrencyLabel decimal={2} value={graphData ? graphData.invoices.total_inv_amt : 0} />}</h5>
                                            <span>
                                                <span className="text-muted   ml-1">
                                                    Open (Inc. Overdues)
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-4 text-center">
                                            <h5 className="mb-1">{graphData ? graphData.invoices.invoice_count : 0}</h5>
                                            <span>
                                                <span className="text-muted   ml-1">
                                                    Invoices
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-4 text-center">
                                            <h5 className="mb-1 ">{graphData ? graphData.invoices.debtor_count : 0}</h5>
                                            <span>
                                                <span className="text-muted   ml-1">
                                                    Debtors
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*------------------------------------------*/}
                        </div>
                    </div>
                </>
            )
    );
}

export default ClientCard;