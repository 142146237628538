import React, { Component } from "react";
import { CurrencyLabel } from "../../../common/inputs";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";

class ReceivableCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    render() {
        return (
            <>
                <div className="card bg-primary aligin-center">
                    <div className="text-center">
                        <div className="card-body py-4">
                            <div className="row justify-content-center">
                                <div className="col-xl-10">
                                    <h6 className="card-title text-white">{this.props.facilityname} : Accounts Receivable</h6>
                                    <div className="text-xs text-light">
                                    <div className="row">
                                            <div className="col-lg-12">Account Name: {this.props.bankDetail.account_name}</div>
                                            <div className="col-lg-12">Account Number: {this.props.bankDetail.account_number} &nbsp;BSB: {this.props.bankDetail.bsb_number}</div>
                                        </div>
                                    </div>
                                    <h4 className="text-white mt-2 text-center p-3"  >
                                    <CurrencyLabel value={this.props.availableValue ? this.props.availableValue : "0.00"} />
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
export default ReceivableCard;