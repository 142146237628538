import http from "./httpService";
import client from "./serviceClient";
import { apiUrl, queryString } from "../../src/environment";

let accessToken = "";

function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  };
}

export async function getBusinessPartnersByCountry(country_id, categ_type = null) {
  updateHeader();

  let url =
    apiUrl +
    "/reports/reports/get-partners-with-country-code?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    country_id,
    categ_type
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function getClientDebtorFromPartner(businessPartner) {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/fetch-client-debtor-from-partner?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    business_partner_guid: businessPartner
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to fetch Categories
export async function getCategory() {
  updateHeader();

  let url =
    apiUrl + "/reports/reports/get-categories?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {};

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

/************************************************ */

export async function fetchTransactionsReport(data, type = "") {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/transaction-reports?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    business_partner: data.business_partner,
    client_guid: data.client_guid,
    date_from: data.date_from,
    date_to: data.date_to,
    debtor_guid: data.debtor_guid,
    invoice_number: data.invoice_number,
    reference_number: data.reference_number,
    report_type: data.report_type,
    product_type: data.product_type,
    report_format: type,
    country_id: data.country_id,
    trans_status: data.trans_status,
    client_login: data.client_login
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
/************************************************ */

/** API for Transaction summary report */

export async function fetchTransactionSummaryReport(data) {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/transaction-summary-report?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    data
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
/************************************************ */

/** API for Loan summary report */

export async function fetchLoanSummaryReport(data) {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/loan-summary-report?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    data
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
/************************************************ */

/** API for Invoice summary report */

export async function fetchInvoiceSummaryReport(data) {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/invoice-summary-report?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    data
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
/************************************************ */

/** API for Entity Reports */

export async function fetchEntityReports(data, type = "", sorted = null) {
  updateHeader();
  let url =
    apiUrl + "/reports/reports/entity-reports?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    business_partner: data.business_partner,
    client_guid: data.client_guid,
    report_type: data.report_type,
    report_format: type,
    country_id: data.country_id,
    category: data.category,
    entity_status: data.entity_status,
    sorted
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

/**************************** */

export async function fetchPointTimeReport(data, type = "") {
  updateHeader();
  let url =
    apiUrl + "/reports/reports/point-time-report?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    business_partner: data.business_partner,
    client_guid: data.client_guid,
    date_from: data.date_from,
    date_to: data.date_to,
    debtor_guid: data.debtor_guid,
    invoice_number: data.invoice_number,
    reference_number: data.reference_number,
    report_type: data.report_type,
    product_type: data.product_type,
    report_format: type,
    country_id: data.country_id,
    trans_status: data.trans_status,
    client_login: data.client_login,
    date_type: data.date_type
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function generateExcelReport(data) {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/transaction-report-excel?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    client_guid: data.client_guid,
    date_from: data.date_from,
    date_to: data.date_to,
    debtor_guid: data.debtor_guid,
    invoice_number: data.invoice_number,
    reference_number: data.reference_number,
    report_type: data.report_type
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

// Aged Receivable Report Created at - 03/02/2019
export async function fetchAgedReceivableReport(
  sorted,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/aged-receivable-reports?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    additionalParams,
    sorted: sorted
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    if (additionalParams.data_format == "document") return response;
    else return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}
// ********  fetchAgedReceivableReport end here *********************

// BUsiness Sales Report Created at - 07/02/2019

export async function fetchBusinessSalesReport(
  sorted,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/business-partner-sales-reports?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    additionalParams,
    sorted: sorted
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    if (additionalParams.data_format == "document") return response;
    else return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}
// ********  business sales report end  here *********************

// Due Days Report Created at - 07/02/2019

export async function fetchDueDaysReport(
  sorted,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();
  let url =
    apiUrl + "/reports/reports/due-days-reports?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    additionalParams,
    sorted: sorted
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    if (additionalParams.data_format == "document") return response;
    else return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}
// ********  Due Days Report end  here *********************

// Investor Availability Report Created at - 07/02/2019

export async function fetchInvestorAvailable(
  sorted,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/investor-available-report?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    additionalParams,
    sorted: sorted
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    if (additionalParams.data_format == "document") return response;
    else return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

// ********  Investor Availability Report end  here *********************

// contact Report Created at - 07/02/2019
export async function fetchContactReports(data, type = "") {
  updateHeader();

  let url =
    apiUrl + "/reports/reports/contact-reports?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    business_partner: data.business_partner,
    country: data.country,
    category: data.category,
    date_from: data.date_from,
    date_to: data.date_to,
    report_format: type,
    country_id: data.country_id,
    entity_status: data.entity_status
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
// ********  contact Report end  here *********************

// Investor Availability Report Created at - 07/02/2019
export async function fetchDebtorStatement(data, type = "") {
  updateHeader();

  let url =
    apiUrl +
    "/reports/reports/debtor-statement-report?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    business_partner: data.business_partner,
    client_guid: data.client_guid,
    debtor_guid: data.debtor_guid,
    report_format: type,
    country_id: data.country_id,
    report_type: data.report_type
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

/***   BP  Billing Report  Created at - 26/03/2019*/
export async function fetchBillingData(data, type = "") {
  updateHeader();

  let url =
    apiUrl +
    "/reports/reports/get-billing-report?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    business_partner: data.business_partner,
    client_guid: data.client_guid,
    debtor_guid: data.debtor_guid,
    report_format: type,
    country_id: data.country_id,
    report_type: data.report_type,
    reference_number: data.reference_number,
    date_from: data.date_from,
    date_to: data.date_to,
    date_type: data.date_type,
    product_type: data.product_type,
    report_data: data.reportData
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

/*** Fetch Token URL for old streamline application   */
export async function getStreamlineTokenURL() {
  updateHeader();

  let url =
    apiUrl +
    "/reports/reports/get-streamline-login-token?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {};

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function fetchTransReport(
  sorted,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/transaction-reports?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    additionalParams,
    sorted: sorted
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    if (additionalParams.data_format == "document") return response;
    else return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

export async function generateTransDocs(additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/reports/reports/trans-generat-docs?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
/*********************************************** */

export async function fetchTransLoanReport(
  sorted,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl +
    "/reports/reports/transaction-loan-reports?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    sorted: sorted,
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

/******************************************** */
export async function fetchIpBilling(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl +
    "/reports/reports/get-ip-billing-reports?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

export async function generateIpBillingDocs(additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/reports/reports/ip-billing-generat-docs?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function getReferenceNumber(country_name) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/get-reference-number?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    country: country_name
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
/************************************************* */

/* --------- Service to fetch users report. ----------- */
export async function fetchUsersReport(data, type) {
  updateHeader();

  let url =
    apiUrl +
    "/reports/reports/get-users-report?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    business_partner: data.business_partner,
    country_id: data.country_id,
    category_id: data.category_id,
    report_data: data.reportData,
    report_format: type,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
/** ------------------------------------------------------ */

/* --------- Service to fetch prospects report. ----------- */
export async function fetchProspectsReport(data, type) {
  updateHeader();

  let url =
    apiUrl +
    "/reports/reports/get-prospects-report?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    business_partner: data.business_partner,
    country_id: data.country_id,
    prospect_status: data.prospectStatus,
    report_data: data.reportData,
    report_format: type,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
/** ------------------------------------------------------ */

/* --------- Service to fetch Transaction reconciliation report. ----------- */
export async function fetchTransactionReconcilationReport(data, type = "") {
  updateHeader();
  let url =
    apiUrl +
    "/reports/reports/transaction-reconciliation-reports?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    business_partner: data.business_partner,
    client_guid: data.client_guid,
    date_from: data.date_from,
    date_to: data.date_to,
    debtor_guid: data.debtor_guid,
    invoice_number: data.invoice_number,
    reference_number: data.reference_number,
    report_type: data.report_type,
    product_type: data.product_type,
    report_format: type,
    country_id: data.country_id,
    trans_status: data.trans_status,
    client_login: data.client_login,
    date_type: data.date_type
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

// Service to transactions report
const fetchTransReportData = (sorted, additionalParams) => client.post(`/reports/reports/transaction-reports?XDEBUG_SESSION_START=PHPSTORM`, { sorted, additionalParams });
const fetchTransReportData1 = (sorted, additionalParams) => client.post(`/reports/reports/transaction-reports1?XDEBUG_SESSION_START=PHPSTORM`, { sorted, additionalParams });
const fetchTransactionReconcilationReport1 = (sorted, additionalParams) => client.post(`/reports/reports/transaction-reconciliation-reports1?XDEBUG_SESSION_START=PHPSTORM`, { sorted, additionalParams });

// Service to fetch performance report
const fetchPerformanceData = data => client.post(`/reports/reports/get-performance-report?XDEBUG_SESSION_START=PHPSTORM`, { data });
// Service to fetch IP Transactions Report
const fetchIpTransactionsReport = additionalParams => client.post(`/reports/reports/get-ip-billing-reports`, { additionalParams });
// Service to generate Excel/PDF Reports
const fetchIpTransactionsReportURL = additionalParams => client.post(`/reports/reports/ip-billing-generat-docs`, { additionalParams });

//Service to fetch cron log
const FetchCronLog = params => client.post(`/reports/cron-log/get-summary`, { ...params });

// Service to fetch bp consolidated corporate report
const consolidatedCorporateReport = (params) =>
client.post(`/supplychain/reports/get-corporate-report`, {
  ...params
});

// Service to download consolidated corporate report
const downloadCorporateReport = (params) =>
client.post(`/supplychain/reports/download-corporate-report`, {
  ...params
});

// Service to request a consolidated report
const requestCorporateReport  = (params) =>
client.post(`/supplychain/reports/request-corporate-report`, {
  ...params
});

// Service to get general user report
const generalUserReport  = (params) =>
client.post(`/reports/reports/general-user-report`, {
  ...params
});

// Service to request a overdue report
const overdueReport  = (params) =>
client.post(`/supplychain/reports/overdue-report`, {
  ...params
});

export default {
  getBusinessPartnersByCountry,
  getClientDebtorFromPartner,
  getCategory,
  fetchTransactionsReport,
  fetchPointTimeReport,
  fetchAgedReceivableReport,
  fetchBusinessSalesReport,
  fetchDueDaysReport,
  generateExcelReport,
  fetchInvestorAvailable,
  fetchContactReports,
  fetchDebtorStatement,
  fetchEntityReports,
  fetchBillingData,
  fetchTransactionSummaryReport,
  fetchLoanSummaryReport,
  fetchInvoiceSummaryReport,
  getStreamlineTokenURL,
  fetchIpBilling,
  fetchTransReport,
  generateTransDocs,
  fetchTransLoanReport,
  generateIpBillingDocs,
  fetchUsersReport,
  fetchProspectsReport,
  fetchTransactionReconcilationReport,
  fetchTransactionReconcilationReport1,
  fetchTransReportData,
  fetchTransReportData1,
  fetchPerformanceData,
  fetchIpTransactionsReport,
  fetchIpTransactionsReportURL,
  FetchCronLog,
  consolidatedCorporateReport,
  downloadCorporateReport,
  requestCorporateReport,
  generalUserReport,
  overdueReport
};
