export const POPULATE_LOAN = "loan:populateLoan";
export const GENERATE_SCH = "loan:generateSchedule";
export const ADD_FIX_FEES = "loan:addFixFee";
export const ADD_CHARGES = "loan:addCharge";
export const ADD_DISBURSEMENT = "loan:addDisbursement";
export const ADD_CUSTOM_LOAN = "loan:addCustomSchedule";
export const EDIT_CUSTOM_LOAN = "loan:updateCustomSchedule";

export function populateLoan(loanData) {
  return {
    type: POPULATE_LOAN,
    payload: {
      details: loanData,
    },
  };
}
export function generateSchedule(loanData) {
  return {
    type: GENERATE_SCH,
    payload: {
      details: loanData,
    },
  };
}
export function addFixFee(loanData) {
  return {
    type: ADD_FIX_FEES,
    payload: {
      details: loanData,
    },
  };
}
export function addCharge(loanData) {
  return {
    type: ADD_FIX_FEES,
    payload: {
      details: loanData,
    },
  };
}
export function addDisbursement(loanData) {
  return {
    type: ADD_DISBURSEMENT,
    payload: {
      details: loanData,
    },
  };
}
export function addCustomSchedule(loanStore, customSchedule) {
  return {
    type: ADD_CUSTOM_LOAN,
    payload: {
      loan_store: loanStore,
      custom_schedule: customSchedule,
    },
  };
}
export function updateCustomSchedule(loanStore) {
  return {
    type: EDIT_CUSTOM_LOAN,
    payload: {
      loan_store: loanStore,
    },
  };
}
