import React, { useState } from "react";
import ReactTable from "react-table";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import { useUploader } from "../../../../context/secAccountsUploaderContext";
import EditAccount from "./_editAccount";
import {
    EditButton,
    DeleteButton
} from "../../../common/inputs";
import { Link } from "react-router";

import "../../../../styles/react-table.css";
import "react-table/react-table.css";

function ListAccount() {

    const [editModal, setEditModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const { accounts, setProceedNext, setnextLabel, sethidePreviousBtn, deleteAccount } = useUploader(); // Instance for Context API

    // Delete selected accounts from the list
    const handleAccountDeletion = index => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-delete-ui">
                  <h4>Are you sure ...?</h4>
                  <p>Delete Account</p>
                  <button
                    className="btn btn-white"
                    onClick={() => {
                        onClose();
                    }}
                  >
                    No
                  </button>

                  <button
                    className="btn btn-primary ml-2"
                    onClick={ async() => {
                        onClose();
                        deleteAccount(index)
                    }}
                  >
                    Yes
                  </button>
                </div>
              );
            },
        });
    };

    setnextLabel("Next");
    sethidePreviousBtn(false);
    setProceedNext(true);

    const columns = [
        {
            Header: "ABN",
            accessor: "ABN",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.ABN}</span>,
        },

        {
            Header: "Debtor ID",
            accessor: "Debtor_ID",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.Debtor_ID}</span>,
        },

        {
            Header: "Organisation",
            accessor: "Organisation",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.Organisation}</span>,
        },
        {
            Header: "First Name",
            accessor: "First_Name",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.First_Name}</span>,
        },

        {
            Header: "Last Name",
            accessor: "Last_Name",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.Last_Name}</span>,
        },

        {
            Header: "Email",
            accessor: "Email",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.Email}</span>,
        },

        {
            Header: "Phone",
            accessor: "Phone",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.Phone}</span>,
        },

        {
            Header: "Address1",
            accessor: "Address1",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.Address1}</span>,
        },

        {
            Header: "Address2",
            accessor: "Address2",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.Address2}</span>,
        },

        {
            Header: "Address3",
            accessor: "Address3",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.Address3}</span>,
        },

        {
            Header: "Post Code",
            accessor: "Post_Code",
            headerStyle: { textAlign: "left" },
            Cell: row => <span title={row.original.error}>{row.original.Post_Code}</span>,
        },

        {
            Header: "Action",
            accessor: "action",
            headerStyle: { textAlign: "left" },
            minWidth: 50,
            Cell: row => {
                return (
                    <>
                        <Link className="action-icons" 
                            onClick={e => {
                                setSelectedRow(row.original);
                                setEditModal(true);
                            }}
                        >
                            <EditButton></EditButton>
                        </Link>
                       &nbsp;
                       <Link className="action-icons" onClick={e => { handleAccountDeletion(row.original.index) }} >
                            <DeleteButton></DeleteButton>
                        </Link>
                    </>
                )
            }
        }
    ];

    return (
        <>
            {/*------------------Edit Australia Modal-------------------*/}
            <Modal
                show={editModal}
                onHide={() => setEditModal(false)}
                size="lg"
            >
                <EditAccount 
                    data = {selectedRow} 
                    onClose = {() => setEditModal(false)}
                />
            </Modal>
            {/*---------------------------------------------------------*/}

            <ReactTable
                defaultPageSize={10}
                data={accounts}
                columns={columns}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            style: {
                                color: rowInfo.row._original.error === null ? 'black' : 'red',
                            }
                        }
                    } else {
                        return {}
                    }
                }}
                
            />
        </>
    )
}

export default ListAccount;