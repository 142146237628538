import React, { Component } from "react";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import Modal from "react-bootstrap/Modal";
import entityService from "../../../../services/entityService";
import { confirmAlert } from "react-confirm-alert";
import NewTrader from "./AddEntity/newTrader";
import { Link } from "react-router";
import { ProductsRadioList } from "../../../slplusTools";
import { GeneralButton } from "../../../common/containers/button";
import BulkUploadSecondary from "../../components/bulkupload";
import EntityContext from "../../../../context/entityContext";
import auth from "../../../../services/authService";
import { toast } from "react-toastify";
import { Picker } from "../../../common/inputs";
import { Interaction } from "../../../supply-chain/config/predefinedValues.json";

class DraftAccounts extends Component {
    static contextType = EntityContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            outerLoading: false,
            accountList: [],
            secondaryAccModal: false,
            role_type: [],
            assignFacilityModal: false,
            assignFacilityModalTitle: "Assign Facility",
            selectedRows: [],
            choosedSecAccount: [],
            bulkCheck: false,
            showBulkUpload: false,
            disableAssignBtn: true,
            showAssignBtn: false,
            mappingResponse: [],
            selectedInteraction: {
                label: "Allow Early Payment",
                value: "disclosed"
            },
        }
    }

    componentDidMount() {
        this.fetchDraftAccounts();

        if (this.props.action === 'create')
            this.onOpenSecAccModal();
    }

    fetchDraftAccounts = async () => {
        this.setState({ loading: true })
        const { data } = await entityService.GetDraftSecondaryAccount({ clientSide: true })
        this.setState({ accountList: data.list, loading: false });
    }

    onCloseSecAccModal = () => {
        this.setState({ secondaryAccModal: false });
    };
    onOpenSecAccModal = () => {
        this.setState({ secondaryAccModal: true });
    };

    //-- Function called when try to assign facility to multiple accounts ( using checkbox) --//

    handleAssignFacility = () => {
        if (this.props.selectedProduct === undefined) {
            this.setState({
                assignFacilityModal: true,
                assignFacilityModalTitle: "Assign Facility"
            });
        } else {
            this.setState({
                assignFacilityModalTitle: "Loading....."
            });
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">
                            <h4>Are you sure ...?</h4>
                            <p>{`Are you sure want to assign facility `}</p>
                            <button
                                className="btn btn-white"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-primary ml-2"
                                onClick={() => {
                                    this.handleConfirmAssignFacility();
                                    onClose();
                                }}
                            >
                                {"Yes"}
                            </button>
                        </div>
                    );
                },
                title: "Assign Facility",
                message: `Are you sure want to assign facility`,
            });
        }
    }

    //-- Function called when try to assign facility individually with out checkbox --//

    onOpenAssignFacility = (item) => {
        let selectedItem = [];
        selectedItem.push({
            guid: item.a_guid,
            mapping_guid: item.mapping_guid,
            organization: item.organization,
            status: item.status
        });

        if (this.props.selectedProduct === undefined) {
            this.setState({
                assignFacilityModal: true,
                selectedRows: selectedItem,
                assignFacilityModalTitle: "Assign Facility"
            });
        } else {

            this.setState({
                assignFacilityModalTitle: "Loading....."
            });

            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">
                            <h4>Are you sure ...?</h4>
                            <p>{`Are you sure want to assign facility `}</p>
                            <button
                                className="btn btn-white"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-primary ml-2"
                                onClick={() => {
                                    this.handleConfirmAssignFacility(selectedItem);
                                    onClose();
                                }}
                            >
                                {"Yes"}
                            </button>
                        </div>
                    );
                },
                title: "Assign Facility",
                message: `Are you sure want to assign facility`,
            });
        }
    }

    onCloseAssignFacilityModal = () => {
        this.setState({
            assignFacilityModal: false,
            selectedRows: [],
            choosedSecAccount: [],
            disableAssignBtn: true,
            showAssignBtn: false,
            mappingResponse: [],
            role_type: [],
        });
    };

    handleConfirmAssignFacility = (selectedItem = null) => {
        if (selectedItem == null) {
            this.setState({
                role_type: this.props.selectedProduct,
            },
                () => {
                    this.setState({ assignFacilityModal: true });
                    this.saveAssignProduct();
                });
        } else {
            this.setState({
                role_type: this.props.selectedProduct,
                selectedRows: selectedItem,
            },
                () => {
                    this.setState({ assignFacilityModal: true });
                    this.saveAssignProduct();
                });
        }

    }

    handleProductChange = (items) => {
        this.setState({ role_type: items, disableAssignBtn: false });
    };

    handleCloseBulkupload = () => {
        this.setState({ showBulkUpload: false, accountList: [] }, () => { this.fetchDraftAccounts() });
    }

    saveAssignProduct = async () => {
        let mappingErrors = [];
        if (this.state.selectedRows.length < 1) {
            toast.error("Please select entity to map.");
            return false;
        }

        this.setState({ outerLoading: true });
        const { data } = await entityService.DraftAssignFacility({
            account: this.state.selectedRows,
            product: this.state.role_type,
            accType: this.props.type,
            clientSide: true,
            interaction:this.state.selectedInteraction.value,
        });
        data.response.map((resp) => {
            if (resp.error !== undefined) {
                if (resp.map_result == undefined || resp.map_result.success !== true) {
                    mappingErrors.push(resp.error)
                } else {
                    toast.success(resp.error)
                }

            }
        })

        if (mappingErrors.length < 1) {
            toast.success("Facility assigned successfully");
            this.onCloseAssignFacilityModal()
            this.setState({ accountList: [],bulkCheck:false }, () => { this.fetchDraftAccounts() });
        } else {
            this.setState({ mappingResponse: mappingErrors });
        }
        this.setState({ outerLoading: false });
        this.props.reloadChild !== undefined && (
            this.props.reloadChild()
        )

    }

    handleBulkSelect = () => {

        if (this.state.bulkCheck) {
            this.setState({
                bulkCheck: false,
                selectedRows: [],
                choosedSecAccount: [],
                showAssignBtn: false
            });
        }
        else {
            let bulkSeectedAcc = [];
            let chooseList = [];

            this.state.accountList.map((listValue, index) => {
                chooseList.push(listValue.mapping_guid);
                bulkSeectedAcc.push({
                    guid: listValue.a_guid,
                    mapping_guid: listValue.mapping_guid,
                    organization: listValue.organization,
                    status: listValue.status
                });
            })
            this.setState({
                bulkCheck: true,
                selectedRows: bulkSeectedAcc,
                choosedSecAccount: chooseList,
                showAssignBtn: true
            });

        }

    }

    handleSingleSelect = (checked, mapping_guid, guid, organization, status) => {

        if (checked) {
            if (this.state.showAssignBtn === false) {
                this.setState({ showAssignBtn: true });
            }
            this.setState({
                selectedRows: [
                    ...this.state.selectedRows,
                    {
                        guid: guid,
                        mapping_guid: mapping_guid,
                        organization: organization,
                        status: status
                    },
                ]
            });

            this.setState({ choosedSecAccount: [...this.state.choosedSecAccount, mapping_guid] });
        } else {
            // remove from list
            this.setState({
                selectedRows: this.state.selectedRows.filter((acc) => acc.mapping_guid !== mapping_guid),
            });

            this.setState({
                choosedSecAccount: this.state.choosedSecAccount.filter((choose) => choose !== mapping_guid),
            }, () => {
                if (this.state.choosedSecAccount.length < 1) {
                    this.setState({ showAssignBtn: false });
                }
            });

        }

    };

    getDisabledList = () => {
        const { constants, products } = auth.getCurrentUser();
        let restrictProduct = []; // store product list id -- Only show clients mapped with these products

        if (this.props.type == 'customer') {
            restrictProduct = [constants.PRODUCTS.SUPPLYCHAIN, constants.PRODUCTS.TRADEFINANCE];
            
            /*-- Add products which are not enabled to restrict product list --*/
            products.filter(itm => itm.isChecked == false).map(itm => {
                if (itm.id == constants.PRODUCTS.INVOICE) {
                    restrictProduct.push(constants.PRODUCTS.INVOICE)
                }
                if (itm.id == constants.PRODUCTS.DEBTORFINANACE) {
                    restrictProduct.push(constants.PRODUCTS.DEBTORFINANACE)
                }
            });
            /* Disable product if default settings is not yet added */
            const wobProductSettings = this.context.entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.DEBTORFINANACE);
            if(wobProductSettings.default_settings_available == false && !restrictProduct.includes(constants.PRODUCTS.DEBTORFINANACE)){
                restrictProduct.push(constants.PRODUCTS.DEBTORFINANACE);
            }

            const ifProductSettings = this.context.entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.INVOICE);
            if(ifProductSettings.default_settings_available == false && !restrictProduct.includes(constants.PRODUCTS.INVOICE)){
                restrictProduct.push(constants.PRODUCTS.INVOICE);
            }
            /** ------------------------------------------------------ */

            /* Disable product if bank account details not yet added */
            const ifBankDetails = this.context.accountDetails.find(item => item.product == constants.PRODUCTS.INVOICE);
            if(!ifBankDetails && !restrictProduct.includes(constants.PRODUCTS.INVOICE)){
                restrictProduct.push(constants.PRODUCTS.INVOICE);
            }

            const wobBankDetails = this.context.accountDetails.find(item => item.product == constants.PRODUCTS.DEBTORFINANACE);
            if(!wobBankDetails && !restrictProduct.includes(constants.PRODUCTS.DEBTORFINANACE)){
                restrictProduct.push(constants.PRODUCTS.DEBTORFINANACE);
            }
            /** ------------------------------------------------------ */

        } else {
            restrictProduct = [constants.PRODUCTS.INVOICE, constants.PRODUCTS.DEBTORFINANACE];
            
            /*-- Add products which are not enabled to restrict product list --*/
            products.filter(itm => itm.isChecked == false).map(itm => {
                if (itm.id == constants.PRODUCTS.SUPPLYCHAIN) {
                    restrictProduct.push(constants.PRODUCTS.SUPPLYCHAIN)
                }
                if (itm.id == constants.PRODUCTS.TRADEFINANCE) {
                    restrictProduct.push(constants.PRODUCTS.TRADEFINANCE)
                }
            })

            /* Disable product if default settings is not yet added */
            const scfProductSettings = this.context.entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.SUPPLYCHAIN);
            const tfProductSettings = this.context.entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.TRADEFINANCE);

            if(scfProductSettings.default_settings_available == false && !restrictProduct.includes(constants.PRODUCTS.SUPPLYCHAIN)){
                restrictProduct.push(constants.PRODUCTS.SUPPLYCHAIN);
            }
            if(tfProductSettings.default_settings_available == false && !restrictProduct.includes(constants.PRODUCTS.TRADEFINANCE)){
                restrictProduct.push(constants.PRODUCTS.TRADEFINANCE);
            }
            /** ------------------------------------------------------- */

            /* Disable product if bank account details not yet added */
            const scfBankDetails = this.context.accountDetails.find(item => item.product == constants.PRODUCTS.SUPPLYCHAIN);
            const tfBankDetails = this.context.accountDetails.find(item => item.product == constants.PRODUCTS.TRADEFINANCE);
            if(!scfBankDetails && !restrictProduct.includes(constants.PRODUCTS.SUPPLYCHAIN)){
                restrictProduct.push(constants.PRODUCTS.SUPPLYCHAIN);
            }
            if(!tfBankDetails && !restrictProduct.includes(constants.PRODUCTS.TRADEFINANCE)){
                restrictProduct.push(constants.PRODUCTS.TRADEFINANCE);
            }
            /** ------------------------------------------------------ */
            
        }
        return restrictProduct;
    }

    /*
    * check if the name or email is a disclosed or not. if yes show four star '****'
    */
    showEmailName = (item) => {
        if (item.toLowerCase().includes('undisclosed')) {
            return "****"
        } else {
            return item;
        }
    }

    handlePickerChange = (field, option) => {
        if (field == 'interaction') {
            const selectedInteraction = { ... this.state.selectedInteraction };
            selectedInteraction['label'] = option.label;
            selectedInteraction['value'] = option.value;
            this.setState({ selectedInteraction });
        }

    }

    render() {
        const { constants } = auth.getCurrentUser();
        return (
            <>

                {
                    this.state.showBulkUpload === false ? (
                        <>
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-6"><h6>Awaiting Mapping</h6></div>
                                        <div className="col-lg-6">
                                            <div className="float-right">
                                                {
                                                    this.state.showAssignBtn === true ? (
                                                        (this.props.showAssign == undefined || this.props.showAssign == true) ? (
                                                            <button className="btn btn-primary btn-sm mr-1" onClick={() => { this.handleAssignFacility() }}>Assign Facility</button>
                                                        ) : (
                                                            <div className="alert alert-danger text-danger">You dont have any facility yet. <Link to="/client/finance-business" className="text-danger font-weight-bold"> Add New</Link></div>
                                                        )

                                                    ) : (
                                                        <>
                                                            <button className="btn btn-secondary btn-sm mr-1" onClick={() => { this.setState({ showBulkUpload: true }) }}>Bulk Upload</button>
                                                            <button className="btn btn-primary btn-sm" onClick={this.onOpenSecAccModal}>Add New</button>
                                                        </>
                                                    )
                                                }


                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div className="card-body">
                                    <div className="dyn-height">
                                        {this.state.outerLoading && <LoadingSpinner />}
                                        {this.state.loading && <LoadingSpinner normal="true" />}
                                        <table className="table table-stripped mt-2">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className="custom-control d-flex custom-checkbox small">
                                                            <input
                                                                id="item_value"
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                onClick={(e) =>
                                                                    this.handleBulkSelect()
                                                                }
                                                                checked={this.state.bulkCheck}
                                                            ></input>
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="item_value"
                                                            ></label>
                                                        </div>
                                                    </th>
                                                    <th>Organisation</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.accountList && this.state.accountList.map((item, index) => (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <div className="custom-control d-flex custom-checkbox small">

                                                                        <input
                                                                            id={`chkbx-` + index}
                                                                            className="custom-control-input"
                                                                            type="checkbox"
                                                                            onClick={(e) =>
                                                                                this.handleSingleSelect(
                                                                                    e.target.checked,
                                                                                    item.mapping_guid,
                                                                                    item.a_guid,
                                                                                    item.organization,
                                                                                    item.status
                                                                                )
                                                                            }
                                                                            checked={this.state.choosedSecAccount.includes(item.mapping_guid) || false}
                                                                        ></input>
                                                                        <label
                                                                            className="custom-control-label"
                                                                            htmlFor={`chkbx-` + index}
                                                                        ></label>
                                                                    </div>
                                                                </td>
                                                                <td>{item.organization}</td>
                                                                <td>{this.showEmailName(item.first_name + ' ' + item.last_name)}</td>
                                                                <td>{this.showEmailName(item.email)}</td>

                                                                <td>  <Link className="action-icons cursor-link"
                                                                    onClick={() => { this.onOpenAssignFacility(item) }}
                                                                >
                                                                    <i title="Assign Facility to account" className="fa fa-external-link text-secondary"></i>
                                                                </Link></td>
                                                            </tr>

                                                        </>
                                                    ))

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {
                                    this.state.accountList.length < 1 && (
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="text-sm-left text-center ml-4">
                                                        <p > No result found</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            <BulkUploadSecondary
                                onClose={this.handleCloseBulkupload}
                                contextValues={this.context.entityDetails}
                            />
                        </>
                    )
                }

                <Modal
                    show={this.state.secondaryAccModal}
                    onHide={this.onCloseSecAccModal}

                >
                    <NewTrader
                        title="Secondary Account"
                        productDetails={{}}
                        onCloseModal={() => {
                            this.onCloseSecAccModal();
                            this.setState({ accountList: [] }, () => { this.fetchDraftAccounts() });
                        }}
                        entityType={this.props.type !== undefined ? this.props.type : ""}
                        selectedProduct={this.props.selectedProduct !== undefined ? this.props.selectedProduct : ""}
                    ></NewTrader>

                </Modal>

                {/*------------------Product Assign modal-----------------*/}
                <Modal
                    show={this.state.assignFacilityModal}
                    onHide={() => { this.onCloseAssignFacilityModal() }}
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">
                            {this.state.assignFacilityModalTitle}
                        </h4>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row">
                            {
                                this.props.selectedProduct === undefined && (
                                    (this.props.showAssign == undefined || this.props.showAssign == true) ? (
                                        <>
                                            <div className="col-lg-12 bg-light b-dashed-2 p-2">
                                                <ProductsRadioList
                                                    col={6}
                                                    onSelectItem={(items) => this.handleProductChange(items)}
                                                    type="secondary"
                                                    disableList={this.getDisabledList()}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div className="col-lg-12">
                                            <div className="alert alert-danger text-danger">
                                                You dont have any facility yet.
                                                <Link to="/client/finance-business" className="text-danger font-weight-bold"> Add New</Link>
                                            </div>
                                        </div>
                                    )

                                )
                            }

                            <div className="col-lg-12">
                                {
                                    this.state.mappingResponse &&
                                    this.state.mappingResponse.length > 0 &&
                                    this.state.mappingResponse.map((res) => (
                                        <p className="text-danger">{res}</p>
                                    ))
                                }
                            </div>
                            <div className="col-lg-12 mt-2">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        {
                                            this.state.role_type && (this.state.role_type.id == constants.PRODUCTS.SUPPLYCHAIN || this.state.role_type.id == constants.PRODUCTS.TRADEFINANCE) && (
                                                <>
                                                    <div className="form-group">
                                                        <Picker
                                                            name="interaction"
                                                            items={Interaction}
                                                            selectedItem={this.state.selectedInteraction}
                                                            onSelectItem={option => this.handlePickerChange('interaction', option)}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="float-right">
                                            {
                                                (this.props.showAssign == undefined || this.props.showAssign == true) && (
                                                    <GeneralButton
                                                        className="btn btn-primary "
                                                        name="Submit"
                                                        onClick={this.saveAssignProduct}
                                                        disabled={this.state.disableAssignBtn}
                                                    ></GeneralButton>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/*---------------------------------------------------------*/}


            </>
        );
    }
}
export default DraftAccounts;