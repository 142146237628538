import React, { useState, useEffect } from "react";
import { Picker } from '../../common/inputs';
import authService from "../../../services/authService";

function TransStatus({ onSelectItem, selectedStatus, statusList = null }) {
  const { constants } = authService.getCurrentUser();
  const transactionStatus = [
    { value: "", label: "All Invoices" },
    // {
    //   label: "Approved and Purchased",
    //   value: constants.SC_STATUS_CONSTANTS.AP
    // },
    {
      label: "Approved for Payment",
      value: constants.SC_STATUS_CONSTANTS.AFP
    },
    {
      label: "Awaiting Customer Approval",
      value: constants.SC_STATUS_CONSTANTS.AWA
    },
    {
      label: "Awaiting Supplier Update",
      value: constants.SC_STATUS_CONSTANTS.AWSU
    },
    {
      label: "Closed Fund Collected",
      value: constants.SC_STATUS_CONSTANTS.CFC
    },
    // {
    //   label: "Funds Overdue",
    //   value: constants.SC_STATUS_CONSTANTS.FO
    // },
    // {
    //   label: "Funds Partially Collected",
    //   value: constants.SC_STATUS_CONSTANTS.FPC
    // },    
    // {
    //   label: "Overdue Funds partly Collected",
    //   value: constants.SC_STATUS_CONSTANTS.OFPC
    // },
    {
      label: "Open (Including Overdue)",
      value: "open"
    },
    {
      label: "Other",
      value: "other"
    },
    {
      label: "Rejected",
      value: constants.SC_STATUS_CONSTANTS.RT
    },
  ];

  const [selectedItem, setSelectedItem] = useState(selectedStatus);

  useEffect(() => {
    setSelectedItem(selectedStatus)
  }, [selectedStatus]);

  return (
    <>
      <>
        <Picker items={statusList ? statusList : transactionStatus}
          selectedItem={selectedItem}
          onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
        />
      </>
    </>
  )
};
export default TransStatus;