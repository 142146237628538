import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { Picker } from '../common/inputs';

import coreService from "../../services/coreService";
import authService from "../../services/authService";
import useApi from '../../hooks/useApi';

function ClientsList({ selectedClient = null, onSelectItem, bp = null, defaultValue = { label: "Select Client (Account)", value: null }, isDisabled=false, }) {
    const { constants } = authService.getCurrentUser();
    const [selectedItem, setSelectedItem] = useState(defaultValue);
    const [sortedData, setSortedData] = useState([]);
    const { request, data, loading } = useApi(coreService.getCorporateClientsList); // Custom hook for HTTP Requests

    useEffect(() => {
        request(); // Calling HTTP service
    }, []);

    useEffect(() => {
        if (data && data.length > 0) {
            // Sort data alphabetically by label
            const sorted = [...data].sort((a, b) => a.label.localeCompare(b.label));
            setSortedData(sorted);
            if (selectedClient !== null) {
                setSelectedItem(data.find(obj => obj.value == selectedClient))
            } else if (selectedClient === null)
                setSelectedItem(defaultValue);
        }

    }, [selectedClient, data]);

    return (
        <>
            <>
                {loading == true && <LoadingSpinner />}
                <Picker
                    items={sortedData}
                    selectedItem={selectedItem}
                    onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
                    loading={loading}
                    isDisabled={isDisabled}
                />
            </>
        </>
    )
};
export default ClientsList;