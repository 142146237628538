/**Approval of supplier-intiated invoice by customer */
import React from "react";
import { toast } from "react-toastify";
import * as moment from "moment";
import Modal from 'react-bootstrap/Modal';
import Form from "../../../../common/Form";
import { DateTimePicker } from "../../../../common/inputs/datePicker";
import {
  FormRow,
  FormColumnLg
} from "../../../../common/containers/formContainer";
import {
  SaveButton,
} from "../../../../common/containers/button";
import Transaction from "../../../services/transaction_service";
import auth from "../../../../../services/authService";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import Utility from "../../../utils/DateParser";
import { confirmAlert } from "react-confirm-alert";

class BulkApproveSupplier extends Form {
  constructor(props) {
    super(props);
  }

  state = {
    data: {
      when_paid: null,
      fee: "",
    },
    rows : null,
    ajaxRequest: false,
    disabled : false
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.rows !== nextProps.rows) {
      return { rows: nextProps.rows };
    }

    return null;
}
  // componentDidMount(){
  //   console.log(this.props);
  //   this.setState({ rows : this.props.rows });
  // };
  doSubmit = async () => {
    try {
      const { data,rows } = this.state;
      this.setState({ ajaxRequest: true });
      if(data.when_paid != null){
        const { ParseDate } = Utility();
        const rows = [...this.state.rows ];
        rows.map( item => item['when_paid'] = ParseDate(item['when_paid']));
        // const formatedWhenPaidString = moment(data.when_paid)
        //   .format("YYYY-MM-DD")
        //   .toString();
        // data["when_paid"] = formatedWhenPaidString;
      }  
      setTimeout(async () => {
        //let invoices = this.props.invoices;
        const { success, message } = await Transaction.createBulkApproval(
          rows,
          "supplier",
          data,
          this.props.product
        );
        if (success === false) {this.setState({ ajaxRequest: false }); toast.error(message);}
        else {

          this.setState({ ajaxRequest: false });

          if(!this.props.handleTabChange){
            this.handleWhenPaidChange(null);
            toast.success(message);
            this.props.onHandleReload();
          }
          else{

            this.props.onClose();
            this.handleWhenPaidChange(null);

            confirmAlert({
              customUI: ({ onClose: onCloseWarning }) => {
                  return (
    
                    <div className="custom-delete-ui">
                      <h4>Selected invoice(s) have been successfully approved.</h4>
                      <p>Invoice(s) have been moved to Awaiting customer approval.</p>
                      <button
                          className="btn btn-white ml-2"
                          onClick={() => {
                            this.props.onHandleReload();
                            onCloseWarning();
                          }}
                      >
                        Close
                      </button>
                      <button
                          className="btn btn-primary ml-2"
                          onClick={() => { 
                            const { sc_invoice_status } = auth.getCurrentUser();
                            this.props.handleTabChange(sc_invoice_status['awaiting_approval'])
                            onCloseWarning();
                          }}
                      >
                          View Awaiting Customer Approval List
                      </button>
                    </div>
                    );
              },
              title: "",
              message: "",
            });
        }
        }
      }, 100);
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit();
  };
  onCloseModal = () => {
    this.setState({ open: false, selectedFeeType: [] });
  };

  handleWhenPaidChange = (date) => { console.log("object1");
    const data = { ...this.state.data };
    const rows = [...this.state.rows ];
    rows.map( item => item['when_paid'] = date);
    data["when_paid"] = date;
    this.setState({ rows,data }, () => this.checkDisable());
  };
  handleEpChange = (date, item, key) => {
    const rows = [...this.state.rows ];
    rows[key]['when_paid'] = date;
    this.setState({ rows }, () => this.checkDisable());
  }
  checkDisable = () => {
    const { ParseDate } = Utility();
    const rows = [...this.state.rows ];
    let flag = false;
    const temp = rows.filter( obj => obj.checked === true);
    for (const item of temp){
      if( moment(ParseDate( item.when_paid )).isBetween( ParseDate(item.trans_date), ParseDate(item.inv_due_date), 'days', '[]') === false ){
        flag = true; break;
      } 
    }  
    this.setState({ disabled: flag });
  }
  focusInput = (inputName) => { };

  render() { console.log("rendered");
    const { data, disabled } = this.state;
    const { sc_invoice_status } = auth.getCurrentUser();
    const { ParseDateDMY ,ParseDate } = Utility();
    return (
      <>
        {this.props.trans_status == sc_invoice_status["awaiting_supplier_update"] && (

          <Modal
            show={this.props.open}
            onHide={this.props.onClose}
            centered
            size = "lg"
            className="animated bounceInRight"
            reload={this.props.onHandleReload}
          >
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
              <Modal.Header closeButton className="">
                <h5 className="card-title" >Approve Invoice/s</h5>
              </Modal.Header>
              <Modal.Body>
                { this.state.ajaxRequest === true && <LoadingSpinner />}
                <div className="row p-4 justify-content-center">
                  <div class="form-group">
                    <label>
                      <strong>WHEN WOULD DO YOU LIKE TO PAY </strong>
                    </label>

                    {" "}
                    <DateTimePicker
                      name="when_paid"
                      type="normal"
                      onChange={this.handleWhenPaidChange}
                      selected={
                        typeof data.when_paid !== "object"
                          ? new Date(Date.parse(data.when_paid))
                          : data.when_paid
                      }
                    ></DateTimePicker>
                  </div>
                  <div className="alert alert-info">
                      <i className="fa fa-info mr-2"></i> The chosen date will be added as a payment date for all the selected invoices.
                  </div>
                </div>
                { (data.when_paid != null && this.state.rows != null  ) &&
                  <>
                    <FormRow>
                      <FormColumnLg>
                        <table className="table text-secondary">
                            <thead>
                                <tr>
                                  <th>Invoice Number</th>
                                  <th>Supplier</th>
                                  <th>Customer</th>
                                  <th>Invoice Date</th>
                                  <th>Due Date</th>
                                  <th>Early Payment Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.rows.length > 0 && this.state.rows.filter(obj => obj.checked === true).map((item, key) => {
                                    let early_data;
                                    if(data.when_paid){
                                        if( moment(ParseDate( item.when_paid )).isBetween( ParseDate(item.trans_date), ParseDate(item.inv_due_date), 'days', '[]') )
                                            early_data = ParseDateDMY(item.when_paid); 
                                        else{
                                            early_data =  
                                                        <>
                                                          <DateTimePicker
                                                            name="when_paid"
                                                            type="normal"
                                                            onChange={e => this.handleEpChange(e,item,key)}
                                                            selected={
                                                              typeof item.when_paid !== "object"
                                                                ? new Date(Date.parse(item.when_paid))
                                                                : item.when_paid
                                                            }
                                                            minDate={typeof item.trans_date !== "object" ? new Date(Date.parse(item.trans_date)) : item.trans_date } 
                                                            maxDate={typeof item.inv_due_date !== "object" ? new Date(Date.parse(item.inv_due_date)) : item.inv_due_date } 
                                                          />
                                                          <span className="text-danger">Limit exceeds</span>
                                                        </>  
                                        }                      
                                    }else early_data = null;

                                    return (
                                        <tr key={key}>
                                            <td>{item.inv_number}</td>
                                            <td>{item.supplier_name}</td>
                                            <td>{item.customer_name}</td>
                                            <td>{ParseDateDMY(item.trans_date)}</td>
                                            <td>{ParseDateDMY(item.inv_due_date)}</td>
                                            <td>{early_data}</td>
                                        </tr>
                                    );
                                })
                                }
                      
                            </tbody>

                        </table>
                      </FormColumnLg>
                     </FormRow>
                  </>
                }
              </Modal.Body>

              <Modal.Footer>
                <FormRow>
                  <FormColumnLg>
                    {/* <FormRow>
                      <FormColumnLg> */}

                        <SaveButton className="btn btn-primary pull-right ml-2" label="Save" disabled = {this.state.disabled} />
                      {/* </FormColumnLg>
                    </FormRow> */}
                  </FormColumnLg>
                </FormRow>
              </Modal.Footer>

            </form>

          </Modal>
        )
        }

      </>
    );
  }
}

export default BulkApproveSupplier;
