import React, { useState, useContext } from "react";
import Modal from "react-responsive-modal";
import { GeneralButton } from "../../common/inputs";
import AssignRoles from '../../entities/home/widgets/_assignRoles';

import EntityContext from "../../../context/entityContext";
import authService from "../../../services/authService";

function Products() {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productModal, setProductModal] = useState(false);
    const { constants } = authService.getCurrentUser();

    const {
        entityDetails,
        populateClientDetails,
        handleProductChange,
        selProduct
    } = useContext(EntityContext);

    const handleAssignRoleModal = (product = null) => {
        if (product !== null) // Event triggered from 'Enabled' btn click
            setSelectedProduct(product);
        else // Event triggered from 'Entities'
            populateClientDetails(entityDetails.a_guid)

        setProductModal(!productModal);
    }

    const handleCheckboxChange = event => {
        handleProductChange(event.target.value);
    }

    return (
        <>
            {/*------------------Assign Roles Modal-------------------*/}
            <Modal open={productModal} onClose={() => handleAssignRoleModal()} center>
                <AssignRoles onClose={() => handleAssignRoleModal()} guid={entityDetails.a_guid} selectedProduct={selectedProduct} />
                <div style={{ width: 500, height: 20 }}></div>
            </Modal>
            {/*---------------------------------------------------------*/}

            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="ibox float-e-margins">

                            <div className="ibox-title">
                                <span className="label label-success pull-right"></span>
                                <h5>Products Assigned</h5>
                            </div>

                            <div className="ibox-content">
                                <ul className="list-group clear-list m-t">
                                    {entityDetails.products.map((obj, index) => {
                                        return (
                                            <li key={index} className="list-group-item">
                                                <span className={`pull-right font-bold badge badge-${obj.isChecked === true ? 'primary' : 'danger'} price-badge`}>
                                                    {obj.isChecked === true ? "Active" : "Inactive"}
                                                </span> {obj.product_name} &nbsp;
                                                {obj.isChecked === false &&
                                                    <GeneralButton name='Enable'
                                                        onClick={
                                                            () => { handleAssignRoleModal(obj.id) }
                                                        }
                                                        faIcon='fa fa-thumbs-up' className='btn btn-xs btn-primary btn-outline'
                                                    />
                                                }
                                            </li>
                                        )
                                    })}
                                </ul>

                                <hr></hr>
                                <input onChange={handleCheckboxChange} checked={selProduct === constants.PRODUCTS.INVOICE ? true : false} type="radio" name="gender" value={constants.PRODUCTS.INVOICE}></input> Invoice Finance&nbsp;
                                <input onChange={handleCheckboxChange} checked={selProduct === constants.PRODUCTS.SUPPLYCHAIN ? true : false} type="radio" name="gender" value={constants.PRODUCTS.SUPPLYCHAIN}></input> Supply Chain
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Products;