import client from "./serviceClient";
import http from "./httpService";
import { apiUrl } from "../../src/environment";

const abnSearchByOrgName = params => http.post(`${apiUrl}/externalapis/asic-api/search-by-organisation-name`, { ...params });
const abnSearchByNumber = params => client.post(`/externalapis/asic-api/search-by-abn-number`, { ...params });
// Service to validate abn of each entity
const bulkAbnValidation = params => http.post(`${apiUrl}/externalapis/asic-api/bulk-abn-validate`, { ...params });

// Api to get all business from forwardAi
const ForwardAiAllBusiness = params => client.post(`/externalapis/forward-ai/get-all-business`, { ...params });

// Api to get all business from forwardAi
const getForwardaiInvoices = params => client.post(`/externalapis/forward-ai/get-invoices`, { ...params });

// Api to get all business from forwardAi
const getForwardaiBills = params => client.post(`/externalapis/forward-ai/get-bills`, { ...params });

// Api to save transaction from forwardAi, can save multiple transaction at a time
const saveTransaction = params => client.post(`/externalapis/forward-ai/save-transaction`, { ...params });

// Api to check if customer registered with manoova. list of customers
const checkManoovaCustRegistered = params => client.post(`/externalapis/manoova/check-customer-registered`, { ...params });

// Api to register in manoova
const registerManoova = params => client.post(`/externalapis/manoova/create-token`, { ...params });

// Api to list registered users in manoova
const getUsersList = params => client.post(`/externalapis/manoova/get-users-list`, { ...params });

// Api to get status of an api payment
const getManoovaPaymentStatus = params => client.post(`/externalapis/manoova/update-payment-status`, { ...params });

// Api to list registered users count in manoova
const getUsersListCount = params => client.post(`/externalapis/manoova/get-users-list-counts`, { ...params });
const FetchConnectionAccSwStatus = params => client.post(`/externalapis/accounting-sw/fetch-connection-status`, { ...params });
const accSwConnect = params => client.post(`/externalapis/accounting-sw/connect-acc-software`, { ...params });
const GetXeroAccessToken = params => client.post(`/externalapis/accounting-sw/get-xero-access-token`, { ...params });
const GetBill = params => client.post(`/externalapis/accounting-sw/get-bill`, { ...params });
const GetInvoices = params => client.post(`/externalapis/accounting-sw/get-invoices`, { ...params });
const XeroReconnect = params => client.post(`/externalapis/accounting-sw/resend-xero-authenticate`, { ...params });
const GetMyobAccessToken = params => client.post(`/externalapis/accounting-sw/get-myob-access-token`, { ...params });
const GetMyobCompanyFile = params => client.post(`/externalapis/accounting-sw/get-myob-company-file`, { ...params });

const requestSwConnect = params => client.post(`/externalapis/accounting-sw/request-acc-software`, { ...params });

const getReceivableAccounts = params => client.post(`/externalapis/manoova/get-receivable-accounts`, { ...params });
const createReceivableAccounts = params => client.post(`/externalapis/manoova/create-receivable-account`, { ...params });
const GetReceivableAccountDetail = params => client.post(`/externalapis/manoova/get-receivable-account-detail`, { ...params });
const CheckReceivableAccStatus = params => client.post(`/externalapis/manoova/check-receivable-acc-status`, { ...params });
const UpdateReceivableAccStatus = params => client.post(`/externalapis/manoova/update-receivable-acc-status`, { ...params });
const manoovaProcessPayment = params => client.post(`/externalapis/manoova/process-payment`, { ...params });
const getManoovaTokenDetails = params => client.post(`/externalapis/manoova/get-token-detail`, { ...params });
const updateBankDetails = params => client.post(`/externalapis/manoova/update-bank-detail`, { ...params });
const deleteManoovaToken = params => client.post(`/externalapis/manoova/delete-manoova-token`, { ...params });


export default {
  abnSearchByOrgName,
  abnSearchByNumber,
  bulkAbnValidation,
  ForwardAiAllBusiness,
  getForwardaiInvoices,
  getForwardaiBills,
  saveTransaction,
  checkManoovaCustRegistered,
  registerManoova,
  getUsersList,
  getManoovaPaymentStatus,
  getUsersListCount,
  FetchConnectionAccSwStatus,
  accSwConnect,
  GetXeroAccessToken,
  GetBill,
  GetInvoices,
  XeroReconnect,
  GetMyobAccessToken,
  GetMyobCompanyFile,
  requestSwConnect,
  getReceivableAccounts,
  createReceivableAccounts,
  GetReceivableAccountDetail,
  CheckReceivableAccStatus,
  UpdateReceivableAccStatus,
  manoovaProcessPayment,
  getManoovaTokenDetails,
  updateBankDetails,
  deleteManoovaToken
};
