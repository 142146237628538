
import React, { useState, useEffect, useContext } from "react";
import Connect from "../../../img/icons/FMB_icon6.svg"
import Modal from "react-bootstrap/Modal";
//lets-connect.png"
import Connected from "../../../img/icons/FMB_icon3.svg"
import connectedApps from "../../../img/connected-apps.png";
import useApi from "../../../hooks/useApi";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useHistory, useLocation } from 'react-router-dom';
import accountServices from "../../../components/supply-chain/services/commonService";
import externalApiService from "../../../services/externalApiService";
import entityContext from "../../../context/entityContext";
import { Link } from "react-router";
import LoadingSpinner from '../../common/loaders/loadingSpinner';
import { confirmAlert } from "react-confirm-alert";
import { TextInput, GeneralButton } from "../../common/inputs";

import { RadioButton, RadioImageButton } from "../../common/inputs/radioButton/radioButton";
import { browserHistory } from "react-router";
import other from "../../../img/icons/forwardai_logo.png"
import myob from "../../../img/icons/myob_logo.png"
import xero from "../../../img/icons/xero_logo.png"
import { async } from "q";
function AccountingSoftware() {

    const location = useLocation()
    const history = useHistory()

    const [loading, setLoading] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState(false);
    const { entityDetails } = useContext(entityContext);
    const [bpSubscribed, setBpSubscribed] = useState(false);
    const [accSwListModal, setAccSwListModal] = useState(false);
    const [selectedAccSw, setSelectedAccSw] = useState("");
    const [connectedAccSw, setConnectedAccSw] = useState("");
    const [pageLoaded, setPageLoaded] = useState(false);
    const [myobFileName, setMyobFileName] = useState("");
    const [fileNameModal, setFileNameModal] = useState(false);

    const cancellationApi = useApi(accountServices.CancelConnectionRequest);
    const statusApi = useApi(externalApiService.FetchConnectionAccSwStatus);
    const connectApi = useApi(accountServices.connectForwardAi);
    const accSwConnectApi = useApi(externalApiService.accSwConnect);
    const xeroReconnectApi = useApi(externalApiService.XeroReconnect);
    const myobCompanyFileApi = useApi(externalApiService.GetMyobCompanyFile);
    const accSwRequestApi = useApi(externalApiService.requestSwConnect);

    const statusConstants = {
        PENDING: 'Pending',
        CONNECTED: 'Connected',
        NOT_CONNECTED: 'Not Connected',
        REQUESTED: 'Requested'
    }

    const accountingSoftware = [
        {
            "value": "Xero",
            "label": "Xero",
            "img_link": xero
        },
        {
            "value": "Myob",
            "label": "Myob",
            "img_link": myob
        },
        {
            "value": "ForwardAi",
            "label": "Other",
            "img_link": other
        }
    ];
    const fetchConnectionStatus = async () => {
        setLoading(true)
        const response = await statusApi.request({ entityId: entityDetails.a_guid })

        if (response)
            setBpSubscribed(response.data.subscribed)

        if (response.data.success === true) {
            setConnectionStatus(response.data.result.connection_status);
            setConnectedAccSw(response.data.result.api_type);
        } else {
            setConnectionStatus(statusConstants.NOT_CONNECTED);
        }

        setLoading(false);
    }

    // confirm connect to accounting software
    // Show acc sw list to select one
    const confirmAccSw = () => {
        setSelectedAccSw("");
        setAccSwListModal(true);
    }

    const ConnectToForwardAi = async () => {
        await connectApi.request({
            entityId: entityDetails.a_guid,
            organization: entityDetails.organization,
            email: entityDetails.email,
            category: 'Entity',
            api_type: selectedAccSw //'ForwardAi'
        });
        setLoading(false)
        setAccSwListModal(false);
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>{bpSubscribed ? 'We have sent you a confirmation email.' : 'We are processing your connection request.'}</h4>
                        <p>{bpSubscribed ? 'Please open the email and follow the confirmation steps.' : 'One of the team will be in contact to discuss connecting to your accounting software platform.'}</p>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                onClose();
                                fetchConnectionStatus();
                            }}
                        >
                            Close
                        </button>
                    </div >
                );
            },
        });
    }

    const connectWithAccSw = async () => {
        const response = await accSwConnectApi.request({
            entityId: entityDetails.a_guid,
            api_type: selectedAccSw
        })
        if (response) {
            if (response.data.success == true) {
                //setAccSwListModal(false);
                window.location.href = response.data.authUrl;
            } else {
                toast.error("Failed to authenticate with " + selectedAccSw)
            }
        }
    }

    const xeroReconnect = async () => {
        const response = await xeroReconnectApi.request({
            entityId: entityDetails.a_guid,
            api_type: connectedAccSw //'Xero'
        })
        if (response) {
            if (response.data.success == true) {
                if (response.data.authUrl) {
                    window.location.href = response.data.authUrl;
                } else {
                    toast.success(response.data.message);
                    fetchConnectionStatus();
                }
            } else {
                toast.error("Failed to authenticate with xero")
            }
        }
    }

    /* Confirm connection */
    const confirmConnection = () => {
        let msgHead = "Confirmation";
        let msgBody = "Are you sure want to connect?"
        if (selectedAccSw == 'Xero' || selectedAccSw == 'Myob') {
            msgBody = "You will be redirected to a thirdparty website to complete the set up, Are you sure want to continue?"
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>{msgHead}</h4>
                        <p>{msgBody}</p>
                        <button
                            className="btn btn-white mr-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                onClose();
                                connectToAccSw();
                            }}
                        >
                            Yes
                        </button>
                    </div >
                );
            },
            overlayClassName: "zindex-2100"
        });

    }

    // connect to accounting software
    const connectToAccSw = () => {
        setLoading(true);
        if (selectedAccSw == 'ForwardAi') {
            ConnectToForwardAi();
        } else if (selectedAccSw == 'Xero') {
            connectWithAccSw();
        } else if (selectedAccSw == 'Myob') {
            connectWithAccSw();
        }

    }

    const handleConfirmResend = () => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Are you sure?</h4>
                        <p>{`Click yes to resend the invitation email`}</p>
                        <button
                            className="btn btn-white"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => {
                                ConnectToForwardAi();
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "Resend Invitation",
            message: `Are you sure want to resend the invitation`,
        });
    }

    const handleConfirmConnection = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Are you sure?</h4>
                        <p>{`Are you sure want to continue ...?`}</p>
                        <button
                            className="btn btn-white"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => {
                                xeroReconnect();
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "Are you sure?",
            message: `Are you sure want to continue ...?`,
        });
    }

    const handleCancellation = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Request Cancellation</h4>
                        <p>Are You Sure You Wish To Cancel?</p>
                        <button
                            className="btn btn-white"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={async () => {
                                const response = await cancellationApi.request({ entity_id: entityDetails.a_guid })
                                if (response.data.success === true) {
                                    toast.success(response.data.message)
                                    fetchConnectionStatus()
                                }
                                else
                                    toast.error(response.data.message)
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "",
            message: ``,
        });
    }

    const handleDisconnection = async () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Disconnection Request</h4>
                        <p>Are You Sure You Wish To Disconnect?</p>
                        <button
                            className="btn btn-white"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={async () => {
                                const response = await cancellationApi.request({ entity_id: entityDetails.a_guid })
                                if (response.data.success === true) {
                                    toast.success('You have been successfully disconnected.')
                                    fetchConnectionStatus()
                                }
                                else
                                    toast.error(response.data.message)
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "",
            message: ``,
        });
    }

    const handleChangeRadio = (e) => {
        setSelectedAccSw(e.target.value);
    }

    const myobGetCompanyFiles = async () => {
        const { data } = await myobCompanyFileApi.request({
            entityId: entityDetails.a_guid,
            fileName: myobFileName
        });
        if (data.success) {
            toast.success(data.message);
            setFileNameModal(false);
            setMyobFileName("");
            fetchConnectionStatus();
        } else {
            toast.error(data.message);
        }
    }

    const requestBpConnect = async () => {
        const response = await accSwRequestApi.request({
            entityId: entityDetails.a_guid
        });
        if (response) {

            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">
                            <h4>Connect to Accounting Software</h4>
                            <p>{response.data.message}</p>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </button>
                        </div >
                    );
                },
            });
        } else {
            toast.error("Failed to connect with accounting software. Please contact your administrator.")
        }
    }

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');
        const xeroState = urlParams.get('state');

        if (authCode) {
            const entityId = entityDetails.a_guid;
            // Use query parameters for navigation
            const queryParams = new URLSearchParams();
            queryParams.set('authCode', authCode);
            queryParams.set('xeroState', xeroState);
            queryParams.set('entityId', entityId);

            // Construct the new URL
            const newUrl = `/client/token-endpoint?${queryParams.toString()}`;
            // Use browserHistory.push for navigation
            browserHistory.push(newUrl);
        } else
            fetchConnectionStatus();
    }, [browserHistory, entityDetails.a_guid]);

    return (
        <>
            <Helmet>
                <title>Accounting Software | {global.config.title}</title>
            </Helmet>

            {
                (
                    cancellationApi.loading == true ||
                    statusApi.loading == true ||
                    connectApi.loading == true ||
                    accSwConnectApi.loading == true ||
                    xeroReconnectApi.loading == true ||
                    myobCompanyFileApi.loading == true ||
                    accSwRequestApi.loading == true
                ) && (<LoadingSpinner />)
            }
            {loading ? <LoadingSpinner /> :
                <div className="col-lg-12 m-auto"><div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-lg-6"><h6>Connected Accounts Software</h6></div>
                            <div className="col-lg-6">
                                <Link className="btn btn-light pull-right text-secondary ml-2" to="/client/settings"><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" className="fa fa-close"></i></Link>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        {
                            bpSubscribed ? (
                                <>
                                    <div className="row">
                                        <div className="col-8 col-lg-12 m-auto text-center ">
                                            <img height={"400px"} src={`${connectionStatus === statusConstants.CONNECTED ? Connected : Connect}`} className="" />
                                        </div>

                                        <div className="col-lg-12 m-auto text-center ">
                                            {connectionStatus === statusConstants.REQUESTED &&
                                                <>

                                                    <h3 className="font-weight-bold ">You are not Connected</h3>
                                                    {
                                                        connectedAccSw == 'ForwardAi' ? (
                                                            <>
                                                                <p>Please follow the steps in the confirmation email we have sent you.</p>
                                                                <Link style={{ cursor: 'pointer' }} onClick={handleConfirmResend}>
                                                                    <GeneralButton
                                                                        className="btn btn-primary  ml-3"
                                                                        name="Resend Email"
                                                                    ></GeneralButton>
                                                                </Link>
                                                                <h3 className="font-weight-bold text-danger mt-2">Processing</h3>
                                                            </>
                                                        ) : connectedAccSw == 'Myob' ? (
                                                        <>
                                                            <p>Your connection request is incomplete. Please cancel and reconnect.</p>
                                                        </>
                                                        ) : (
                                                            <>
                                                                <p>Please click on below button to complete your connection.</p>
                                                                <Link style={{ cursor: 'pointer' }} onClick={handleConfirmConnection}>
                                                                    <GeneralButton
                                                                        className="btn btn-primary  ml-3"
                                                                        name="Complete"
                                                                    ></GeneralButton>
                                                                </Link>
                                                                <h3 className="font-weight-bold text-danger mt-2">Processing</h3>
                                                            </>
                                                        )
                                                    }
                                                    
                                                </>
                                            }

                                            {connectionStatus === statusConstants.PENDING &&
                                                <>
                                                    <h3 className="font-weight-bold ">You are not Connected</h3>

                                                    {
                                                        connectedAccSw == 'ForwardAi' ? (
                                                            <>
                                                                <p>We are currently processing your request to connect to your accounting software platform</p>
                                                                <h3 className="font-weight-bold text-danger">Processing</h3>
                                                            </>
                                                        ) : connectedAccSw == 'Myob' ? (
                                                            <>
                                                                <p>You are one step away from connection. Click below Finish connection button to complete connection</p>
                                                                <Link style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setFileNameModal(true);
                                                                    setMyobFileName("");
                                                                }}>
                                                                    <GeneralButton
                                                                        className="btn btn-primary  ml-3"
                                                                        name="Finish connection"
                                                                    ></GeneralButton>
                                                                </Link>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p>You are one step away from connection. Click below Finish connection button to complete connection</p>
                                                                <Link style={{ cursor: 'pointer' }} onClick={handleConfirmConnection}>
                                                                    <GeneralButton
                                                                        className="btn btn-primary  ml-3"
                                                                        name="Finish connection"
                                                                    ></GeneralButton>
                                                                </Link>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            }

                                            {connectionStatus === statusConstants.CONNECTED &&
                                                <>
                                                    <h5 className="font-weight-bold text-secondary">You are Connected</h5>
                                                    <div className="bg-light text-secondary p-4 mt-2 mb-2">


                                                        Welcome to seamless accounting integration! You are now connected to your accounting software. Begin syncing your invoices effortlessly by simply clicking the sync button located on the UNASSIGNED ACCOUNTS PAYABLE/RECEIVABLE widget on your dashboard. For your convenience, you can disconnect at any time using the Disconnect button below. Streamline your financial processes with ease and efficiency.
                                                    </div>
                                                    <button onClick={handleDisconnection} className={`btn btn-outline-danger mt-2 mb-2`}>Disconnect</button>
                                                </>
                                            }

                                            {connectionStatus === statusConstants.NOT_CONNECTED &&
                                                <>
                                                    <h3 className="font-weight-bold ">You are not Connected</h3>
                                                    <p>Make data entry and bulk uploads a thing of the past. When you connect to your accounting software, all invoices will appear in your dashboard</p>
                                                    <div className="align-center">
                                                        <img src={connectedApps}></img>
                                                    </div>
                                                    <p>Click the 'Let's Connect' button below to request to connect to your accounting software platform</p>
                                                    <button onClick={confirmAccSw} className={`btn btn-primary btl-lg`}>Let's Connect</button>
                                                </>
                                            }
                                        </div>

                                    </div>

                                    {(connectionStatus === statusConstants.REQUESTED || connectionStatus === statusConstants.PENDING) &&
                                        <div className="row mt-3">
                                            <div className="col-lg-12 text-right ">
                                                <GeneralButton
                                                    type="button"
                                                    onClick={handleCancellation}
                                                    className="btn btn-light mr-2"
                                                    name="Cancel"
                                                ></GeneralButton>
                                            </div>
                                        </div>
                                    }

                                </>
                            ) : (
                                <>
                                    <div className="row">
                                        <div className="col-8 col-lg-12 m-auto text-center ">
                                            <img height={"400px"} src={`${Connect}`} className="" />
                                        </div>

                                        <div className="col-lg-12 m-auto text-center ">
                                            <h3 className="font-weight-bold ">You are not Connected</h3>
                                            <p>Make data entry and bulk uploads a thing of the past. When you connect to your accounting software, all invoices will appear in your dashboard</p>
                                            <div className="align-center">
                                                <img src={connectedApps}></img>
                                            </div>
                                            <p>Click the 'Let's Connect' button below to request to connect to your accounting software platform</p>
                                            <button onClick={requestBpConnect} className={`btn btn-primary btl-lg`}>Let's Connect</button>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
                </div>
            }

            {/*------------- accounting sw confirm modal ------------------------*/}
            <Modal
                show={accSwListModal}

                onHide={(e) => {
                    setAccSwListModal(false);
                }}
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Let's Connect</h4>
                </Modal.Header>

                <Modal.Body>
                    <div className="ml-3">
                        <label className="control-label mb-2">Please select an accounting software and connect</label>
                        {
                            accountingSoftware.map((itm, index) => (
                                <>
                                    <RadioImageButton
                                        name="acc_sw_type"
                                        value={itm.value}
                                        id={`${itm.value}_${index}`}
                                        imgsrc={itm.img_link}
                                        label={itm.label}
                                        onChange={(e) => { handleChangeRadio(e); }}
                                    />

                                    {/* <RadioButton
                                        name="acc_sw_type"
                                        value={itm.value}
                                        className="custom-radio mb-2"
                                        class="radio-innpt"
                                        id={`${itm.value}_${index}`}
                                        //label={`<img src="${cancelPng}"></img> ${itm.label}`}
                                        label={<img src={cancelPng}></img>+itm.label}
                                        onChange={(e) => { handleChangeRadio(e); }}
                                    /> */}
                                </>
                            ))
                        }
                        <button
                            className="btn btn-primary float-right mt-1"
                            onClick={() => { confirmConnection(); }}
                            disabled={selectedAccSw === null || selectedAccSw == ""}
                        >Connect</button>

                    </div>
                </Modal.Body>

            </Modal>
            {/*----------------------------------------------------------------*/}

            {/*------------- Get company file name for Myob subscriber ------------------------*/}
            <Modal
                show={fileNameModal}
                size="lg"
                onHide={(e) => {
                    setFileNameModal(false);
                }}
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Myob Company Name</h4>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-11">
                            <label className="control-label"><b>Please enter the company name as shown in Myob account</b></label>
                            <TextInput
                                name="myobFileName"
                                value={myobFileName}
                                onChange={(e) => {
                                    setMyobFileName(e.target.value);
                                }
                                }
                                type="normal"
                            ></TextInput>
                        </div>
                        <div className="col-lg-1">
                            <button
                                className="btn btn-primary mt-1 pull-right"
                                onClick={() => { myobGetCompanyFiles(); }}
                                disabled={myobFileName === null || myobFileName == ""}
                            >Submit</button>

                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            {/*----------------------------------------------------------------*/}

        </>
    )
}

export default AccountingSoftware;