import { useState } from 'react';
import { confirmAlert } from "react-confirm-alert";

const ConfirmModal = (callbackFunc) => {

    const [message, setMessage] = useState("Are you sure to do this ?");
    const [title, setTitle] = useState("Confirm to submit");
    const [labelYes, setLabelYes] = useState("Yes");
    const [labelNo, setLabelNo] = useState("No");

    const ask = (...args) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: labelYes,
                    onClick: () => callbackFunc(...args),
                },
                {
                    label: labelNo,
                },
            ],
        });
    }

    return {
        ask,
        setMessage,
        setTitle,
        setLabelYes,
        setLabelNo
    }

}

export default ConfirmModal;