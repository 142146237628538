import React from "react";

function ProgressBar({ list, selectedIndex, handleSelectIndex }) {
    return (
        <div className="row" >
            <div className="container" >
                <div className="l-wizard-h">
                    <ul className="wizard-h">
                        {list.map((item, index) => {

                            let progressStatus = "";

                            if (selectedIndex == list.length - 1)
                                progressStatus = "--previous"
                            else if (index < selectedIndex)
                                progressStatus = "--previous"
                            else if (index == selectedIndex)
                                progressStatus = "--current"

                            return (
                                <li className={"l-wizard-h-step wizard-h__step" + progressStatus}>
                                    <a onClick={e => {
                                        if (progressStatus === "--previous")
                                            handleSelectIndex(index)
                                    }
                                    }>
                                        <i title={item.headerText} className={item.headerIcon}></i>
                                        <span className="multi-step-progressbar-label">{item.headerText}</span></a>
                                </li>
                            )
                        })

                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar;