import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import Common from "../../widgets/common";
import productShortName from "../../../../utils/GetProductShortName";
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";
import RoleChecker from "../../../../utils/roleChecker";

import { browserHistory } from "react-router";

function ClientHome() {

    const {
        handleRoleChange,
        handleProductChange
    } = useContext(EntityContext);

    useEffect(() => {
        const { constants, products } = authService.getCurrentUser();
        const activeRoles = RoleChecker.checkRoleCategory(products, constants.PRIMARY_ROLES, constants.SECONDARY_ROLES);
        handleRoleChange(null); handleProductChange(null);

        if(!activeRoles.primary && !activeRoles.secondary)
            browserHistory.push(`/client/get-started`);
            
        // If there is no primary facility, redirect user to the dashboard.
        else if (!activeRoles.primary)
        {
            if (RoleChecker.check(products, constants.ROLES.SUPPLIER)){
                handleRoleChange(constants.BUSINESS_TYPE.SALE)
                browserHistory.push(`/client/${/*productShortName*/(constants.PRODUCTS.CUSTOMERPROGRAMS)}/dashboard`);
            }
            else if(RoleChecker.check(products, constants.ROLES.TFSUPPLIER)){
                handleRoleChange(constants.BUSINESS_TYPE.SALE)
                browserHistory.push(`/client/${/*productShortName*/(constants.PRODUCTS.CUSTOMERPROGRAMS)}/dashboard`);
            }
            else if(RoleChecker.check(products, constants.ROLES.DEBTOR)){
                handleRoleChange(constants.BUSINESS_TYPE.PURCHASE)
                browserHistory.push(`/client/${productShortName(constants.PRODUCTS.INVOICE)}/dashboard`);
            }
        }else{
            if ( RoleChecker.check(products, constants.ROLES.CLIENT) && 
                (RoleChecker.check(products, constants.ROLES.CUSTOMER) || RoleChecker.check(products, constants.ROLES.TFCUSTOMER)) )
                browserHistory.push("/client/home/APAR");

            else if(RoleChecker.check(products, constants.ROLES.CUSTOMER) || RoleChecker.check(products, constants.ROLES.TFCUSTOMER))
                browserHistory.push("/client/home/AP");

            else if (RoleChecker.check(products, constants.ROLES.CLIENT))
                browserHistory.push("/client/home/AR");
        }
        
    }, [])
    
    return (
        <>
            <Helmet>
                <title>Home | {global.config.title}</title>
            </Helmet>

            <Common />
        </>
    )
}

export default ClientHome;