import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  AddButton,
} from "../../../common/containers/button";
import { CurrencyLabel } from "../../../common/inputs";
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";
import auth from "../../../../services/authService";
import EntityContext from "../../../../context/entityContext";
import Table from "../../../common/tables/ActionTable";
import { transStatus } from "../../../../config/predefinedValues.json";
import BatchUpload from "../../../debtorTransactions/components/BatchUpload";

class InvoicesList extends Component {
  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  state = {
    openCustomer: false,
    invoice: [],
    selectedTab: "draft",
    ajaxRequest: false,
    selectedRows: [],
    addBtn: false,
    paymentsModal: false,
    closeInvModal: false,
    paymentSummaryModal: false,
    editInvoiceModal: false,
    feeScheduleModal: false,
    feeSplitupModal: false,
    selectedRow: null,
    transId: null,
    clientId: null,
    selectedRole: { label: "Select category", value: null },
    selectedCountry: null,
    selectedBp: null,
    selectedDebtor: null,
    selectedClient: null,
    showResetBtn: false,
    selectedInvoice: null,
    openDisburse: false,
    approval: false,
    decline: false,
    openDFCustomer: false,
    tableTitle: "Transactions",
    action: "",
    paymentInfo: [],
    reload: false,
  };

  //---------------------------------------------//
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onOpenModalCustomer = () => {
    this.setState({ openCustomer: true });
  };

  // Callback function to the Action Table.
  handleRowSelection = (selectedRows) => {
    this.setState({ selectedRows });
  };

  handleReloadData = () => {
    this.tableRef.current.reloadData();
    this.tableRef.current.clearSelection();
  };

  componentDidMount = () => {
    const { constants } = auth.getCurrentUser();

    if (this.context.entityDetails.a_guid)
      this.setState({
        selectedRole: {
          label: constants.CATEGORY.DFDEBTOR,
          value: constants.CATEGORY.DFDEBTOR,
        },
      });
  };

  handleTab = (tab, title = null) => {
    this.setState({
      selectedTab: tab,
      tableTitle: title ? title : "Transactions",
      selectedRows: [],
    });
    if (this.tableRef.current) this.tableRef.current.reloadData();
  };

  
  render() {

    const cols = [
      {
        Header: "Invoice Number",
        accessor: "inv_number",
        headerStyle: { textAlign: "left" },
        minWidth: 150,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Aggregated: (row) => {
          // You can even render the cell differently if it's an aggregated cell
          return <span>{row.value}</span>;
        },
        Cell: (row) => <span>{row.original.inv_number}</span>,
      },
      {
        Header: "Trans Ref #",
        accessor: "client_reference",
        headerStyle: { textAlign: "left" },
        minWidth: 150,
        resizable: false,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        PivotValue: (cellInfo) => {
          // console.log(cellInfo.row._pivotVal);
          return (
            <span className="badge badge-light  p-2 text-secondary">
              <strong>
                {"Ref# "}
                {cellInfo.row._pivotVal}
              </strong>
            </span>
          );
        },
        // getProps: () => ({ onClick: (e) => null }),
      },
      {
        Header: "Debtor",
        accessor: "inv_debtor_name",
        headerStyle: { textAlign: "left" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Cell: (row) => <span>{row.original.debtor_organisation}</span>,
      },
      {
        Header: "Invoice Amt",
        accessor: "inv_amount",
        headerStyle: { textAlign: "left" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Aggregated: (row) => {
          let sum = 0;
          let count = 0;
          row.subRows.map((item) => {
            // credit notes sum
            if (item.credit_notes !== 0) {
              sum += parseInt(item.inv_amount);
              count++;
            }
          });
          return (
            <span className="badge badge-light p-2 text-secondary ">
              {count + " Invoices : " + " "}
              <strong>
                <CurrencyLabel value={sum !== null ? sum : 0}></CurrencyLabel>
              </strong>
            </span>
          );
        },
        Cell: (row) => (
          <span>
            <CurrencyLabel
              value={
                row.original.inv_amount !== null ? row.original.inv_amount : 0
              }
            ></CurrencyLabel>
          </span>
        ),
      },
      {
        Header: "Date",
        accessor: "inv_date",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Aggregated: (row) => {
          // You can even render the cell differently if it's an aggregated cell
          return <span>{row.inv_date}</span>;
        },
        Cell: (row) => (
          <span style={{ color: row.original.status == "inactive" && "red" }}>
            {" "}
            {row.original.inv_date}
          </span>
        ),
      },
      {
        Header: "Due Date",
        accessor: "inv_due_date",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Aggregated: (row) => {
          // You can even render the cell differently if it's an aggregated cell
          return <span>{row.inv_due_date}</span>;
        },
        Cell: (row) => (
          <span style={{ color: row.original.status == "inactive" && "red" }}>
            {" "}
            {row.original.inv_due_date}
          </span>
        ),
      },
      {
        Header: "Days",
        accessor: "days",
        headerStyle: { textAlign: "left" },
        Aggregated: (row) => {
          // You can even render the cell differently if it's an aggregated cell
          return <span>{row.days}</span>;
        },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
      },
      {
        Header: "Type",
        accessor: "credit_notes",
        headerStyle: { textAlign: "left" },
        Aggregated: (row) => {
          // You can even render the cell differently if it's an aggregated cell
          return <span>{row.credit_notes}</span>;
        },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Cell: (row) => (
          <span
            className={`badge ${
              row.original.credit_notes == "0"
                ? "badge-light text-danger"
                : "badge-light text-secondary"
            }     p-2 `}
          >
            {row.original.credit_notes == "0" ? "CR Notes" : "Invoice"}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "action",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },

        Cell: (row) => {
          return (
            <>
              {row &&
              row.original &&
              row.original.trans_approved_status == "declined" ? (
                <span className="text-danger font-weight-bold">
                  {"Disapproved"}
                </span>
              ) : null}
            </>
          );
        },
      },
    ];

    const ActionButtons = () => (
      <>
        <AddButton
          style="btn   btn-primary pull-right m-l-sm"
          label="Batch Upload"
          onClick={() => this.setState({ tableTitle: "Batch Upload" })}
        ></AddButton>
      </>
    );

    const ActionPanel = () => <></>;
    const RightPanel = () => <></>;
    const LeftPanel = () => <></>;

    return (
      <>
        <Helmet>
          <title>DF Transactions | {global.config.title}</title>
        </Helmet>


        <div className="card overflow-visible">
          <div className="row align-items-center mx-0 px-4 pt-4">
            <div>
              <h4 className="card-title">{this.state.tableTitle}</h4>
            </div>
                <div className="ml-auto mb-3">
              <button
                onClick={() => {
                  this.setState({ tableTitle: "Transactions" }, () => {
                    this.setState({ selectedTab: transStatus[0]["DRAFT"] });
                  });
                }}
                className={`btn ${this.state.tableTitle === "Transactions" ||
                  this.state.tableTitle === "Batch Upload"
                  ? "btn-outline-primary"
                  : "btn-light"
                  }    mr-2`}
                title="Transactions"
              >
                <i className="fa fa-money  text-sm "></i>
              </button>
            </div>
          </div>
          <div className="card-body">
            {this.state.tableTitle === "Transactions" && (
              <Table
                ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
                service={dfTransactionService.getInvoiceList}
                cols={cols}
                defaultTab={
                  this.state.selectedTab ? this.state.selectedTab : "draft"
                }
                onSelectTab={(val) => {
                  this.setState({ selectedTab: val });
                  this.setState({ selectedRows: [] });
                }}
                uid={"inv_guid"}
                onSelectRow={this.handleRowSelection}
                tableRightPanel={<ActionButtons />}
                tableLeftPanel={<LeftPanel />}
                actionPanel={<ActionPanel />}
                reqParams={{
                  clientReportType: true,
                  selectedBusinessType:this.context.selectedBusinessType,
                }}
                headerTabs={[]}
                rightPanel={<RightPanel />}
                pivot={"client_reference"}
                setBackgroundcolor={true}
                dangerRow={"declined"}
                source={"batch"}
              />
            )}
            {this.state.tableTitle === "Batch Upload" && (
              <BatchUpload handleTab={this.handleTab}></BatchUpload>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default InvoicesList;
