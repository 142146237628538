import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AddButton } from '../../../../common/containers/button';
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
// import { AddButton } from '../../../../common/inputs';
export default function TermsPartB({ terms_b, onCheckboxChange, customClose }) {

    const [bottom, setBottom] = useState(false);
    const [checkBoxChange, setCheckBoxChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleScroll = (e) => {
        //-------Checking scroll reached bottom--------------------
        const reached_h = e.target.scrollHeight - e.target.scrollTop;
        var cl_min_h = e.target.clientHeight - 2;
        var cl_max_h = e.target.clientHeight + 2;
        if ((reached_h > cl_min_h) && (reached_h < cl_max_h)) {
            setBottom(true);
        }
        //---------------------------------------------------------
    }

    const submit = () => {
        setLoading(true);
        onCheckboxChange();
        setLoading(false);
    }


    return (
        <>

            <Modal.Body >
                <div  >
                    {loading && <LoadingSpinner />}
                </div>
                <div className=" ">
                    <div className=" mb-0 "><div class="  bg-secondary">
                        <div className="row">
                            <div className="col-md-11 text-white mt-2">
                                <h5>We've noticed this is the first time you've requested to be paid early</h5>
                                <p >In order to activate early payments please view and accept these terms and conditions.</p>
                                <h6 className="mt-1 mb-1">TERMS OF SERVICE <span className="text-danger">*</span></h6>

                            </div>
                            <div className="col-md-1 text-white mt-2 cursor-link">
                                {customClose && (
                                    <i onClick={customClose} className="fa fa-close pull-right"></i>
                                )}


                            </div>
                            <div id="terms" onScroll={handleScroll} className="col-md-12 bg-white mt-3 pt-4 scrollable-div"    >
                                <div  >
                                    <p  >
                                        The person who clicks 'I AGREE' below represents and warrants that he or she is the "Authorised Person"; specified above, is an "Authorised Person" as defined in the Customer Terms and Conditions and has the Customer's authority to bind the Customer.
                                    </p><p  >
                                        If the "Revised Due Date"3 is equal to the "Invoice Due Date"2 specified above the Customer will pay the "Approved Amount"1 above to Fifo on the 'Invoice Due Date"2 specified above.
                                        If the "Revised Due Date"3 is different to the "Invoice Due Date"2 specified above the Customer requests that it be allowed to pay on the "Revised Due Date"3 instead of the "Invoice Due Date"2 specified above. If Fifo agrees to that request the Customer will pay the "Total Amount to Pay"4 to Fifo. This amount is the "Approved Amount"1 specified above plus any "Late Payment Fee". Fifo's agreement will be shown by the status of the invoice changing on STREAM from "Awaiting Approval" to "Approved and Purchased".
                                        The Customer will pay the applicable amount to Fifo in full and without any counterclaim or set-off. The Customer must comply with the Customer Terms and Conditions and has the Customer's authority to bind the Customer and, in particular, confirms each of the items listed in clause 2.3, subject only to the above.
                                    </p><h6>
                                        I understand and agree to Fifo Capital's Terms and Conditions and Fifo Capital's Privacy Policy.</h6>
                                    <hr />
                                    <ul  >
                                        <li>1. This is the "gross value of the Purchased Debt" specified in the Customer Terms and Conditions. It is the "Due Date Amount" specified in the Supplier Terms and Conditions.</li>
                                        <li>2. This is the "Invoice Due Date" specified in the Customer Terms and Conditions and the Supplier Terms and Conditions.</li>
                                        <li>3. This is the "Agreed Settlement Date" specified in the Customer Terms and Conditions.</li>
                                        <li>4. This is the "gross value of the Purchased Debt" plus the "Late Payment Fee" in the Customer Terms and Conditions.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-8 text-white">
                                <div className="custom-control d-flex custom-checkbox mt-4">
                                    <input
                                        id={`customCheck2`}
                                        type="checkbox"
                                        className="custom-control-input"
                                        name="checktermsconfirm"
                                        disabled={bottom == true ? false : true}
                                        onChange={() => { setCheckBoxChange(true) }}
                                        checked={checkBoxChange ? true : false}
                                    />
                                    <label className="custom-control-label text-primary" for={`customCheck2`}>
                                        I agree to the Terms and Conditions
                                    </label>

                                </div>
                                <p className="mt-2">( scroll to the bottom of the box to activate tick box )</p>

                            </div>
                            <div className="col-md-4 text-white">
                                {checkBoxChange == true &&
                                    <AddButton onClick={() => submit()} label="Submit" style="btn btn-primary pull-right mt-4"></AddButton>
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Modal.Body>
        </>
    );
}