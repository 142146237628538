import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { Picker } from '../common/inputs';

import coreService from "../../services/coreService";
import authService from "../../services/authService";
import useApi from '../../hooks/useApi';

function ClientsList({ selectedClient = null, onSelectItem, bp = null, defaultValue = { label: "Select Client (Account)", value: null } }) {
    const { constants } = authService.getCurrentUser();
    const [selectedItem, setSelectedItem] = useState(defaultValue);
    const { request, data, loading } = useApi(coreService.getClientsList); // Custom hook for HTTP Requests

    const prevBp = useRef();

    useEffect(() => {

        if (bp != null && bp !== prevBp.current) {
            prevBp.current = bp;
            request({ category: constants.CATEGORY.CLIENT, bp }); // Calling HTTP service
        }

        if (selectedClient !== null && data.length > 0) {
            const clientDetails = data.filter(obj => obj.value == selectedClient)[0];
            if (clientDetails)
                setSelectedItem({ label: clientDetails.label, value: clientDetails.value })
        } else if (selectedClient === null)
            setSelectedItem(defaultValue);

    }, [bp, selectedClient, data]);



    return (
        <>
            <>
                {loading == true && <LoadingSpinner />}
                <Picker
                    items={data}
                    selectedItem={selectedItem}
                    onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
                    loading={loading}
                />
            </>
        </>
    )
};
export default ClientsList;