import React, { useEffect, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { Picker } from '../common/inputs';

import authService from "../../services/authService";
import coreService from "../../services/coreService";
import useApi from '../../hooks/useApi';

function IpList({ onSelectItem, country = null }) {

    const { category, country_id } = authService.getCurrentUser();
    const [selectedItem, setSelectedItem] = useState({ label: "Investment Partner", value: null });

    const { request, data, loading } = useApi(coreService.getEntitiesList); // Custom hook for HTTP Requests

    if (country === null && category !== "International Office") // Set default country if logged in user not belongs to intl office
        country = country_id;

    useEffect(() => {
        request(country, "Investment Partner"); // Calling HTTP service
    }, [country]);

    return (
        <>
            <>
                {loading == true && <LoadingSpinner />}
                <Picker items={data}
                    selectedItem={selectedItem}
                    onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
                />
            </>
        </>
    )
};
export default IpList;