import $ from "jquery";
import React, { Component } from "react";
import { Link } from "react-router";
import auth from "../../../../services/authService";
import reportService from "../../../../services/reportService";
import LoadingSpinner from "../../loaders/loadingSpinner";
import fpLogo from "../../../../img/FC-logo.png";
import fpIcon from "../../../../img/fp-icon.svg";
import { browserHistory } from "react-router";
// Importing menu from json file
import {
  IntlOfficeMenu,
  BPMenu,
  CountryMenu,
} from "../../../../config/menu.json";

class Navigation extends Component {

  constructor() {
    super();
    this.state = {
      activeChildMenu: '',
      activeIndex: null
    }
  }

  componentWillMount() {
    const user = auth.getCurrentUser();
    if (!user) {
      let destinationPath = this.props.location.pathname + this.props.location.search;
      auth.logout();
      browserHistory.push({ pathname: '/login', state: { from: destinationPath }});
    }
  }

  componentDidMount() {
    const { menu } = this.refs;
    //const picture = auth.getUserProfilePicture();
    // this.setState({ picture });
    $(menu).metisMenu();
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  secondLevelActive(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
      ? "nav nav-second-level collapse in"
      : "nav nav-second-level collapse";
  }

  getOldReports = async () => {
    try {
      this.setState({ ajaxRequest: true });
      const {
        streamlineTokenUrl,
      } = await reportService.getStreamlineTokenURL();
      window.open(streamlineTokenUrl, "_newtab");
      this.setState({ ajaxRequest: false });
    } catch (error) { }
  };

  collapseMenu = () => {
    $("#side-menu li.active").not($(this)).removeClass('active');
     $('#side-menu li.active').not($(this)).removeClass('active');
    $('#side-menu li ul').removeClass('in');
    $('#side-menu li ul li').removeClass('active');
    this.setState({ activeChildMenu: null });
  }

  render() {

    const user = auth.getCurrentUser();
    let menuList = [];
    if (user !== null) {
      const { category } = user;
      if (category == "International Office") menuList = IntlOfficeMenu;
      else if (category == "Business Partner") menuList = BPMenu;
      else if (category == "Country Office") menuList = CountryMenu;
    }

    return (
      <React.Fragment>
        {this.state.ajaxRequest && <LoadingSpinner />}
        <nav className="navbar-default navbar-static-side" role="navigation">
          <ul className="nav metismenu" id="side-menu" ref="menu">
            <li className="nav-header">
              {/*--------------------------------------------------------------------------------------------*/}
              <div className="dropdown profile-element">
                <Link to="/dashboard">
                  <img alt="" src={fpLogo != "null" ? fpLogo : fpLogo} />
                </Link>

              </div>

              <div className="logo-element">
                <img alt="" src={fpIcon != "null" ? fpIcon : fpIcon} />
              </div>
              {/*--------------------------------------------------------------------------------------------*/}
            </li>

            {/** Main Menu */}
            {menuList.map((i, index) => {
              return (
                menuList[index].children ? (
                  <li
                    key={index}
                    className={
                      (
                        menuList[index].children.findIndex(obj => obj.route == this.props.location.pathname) !== -1 ||
                        this.props.location.pathname.indexOf(menuList[index].route) > -1
                      ) ? 'active' : ''
                    }
                    id={`cta_${menuList[index].name.replace(/ /g,"_").toLowerCase()}`}
                  >
                    <a 
                      onClick={() => this.setState({ activeIndex: index })} 
                      style={{ 
                        pointerEvents: 
                          !this.state.activeIndex ? menuList[index].children.findIndex(obj => obj.route == this.props.location.pathname) !== -1 ? 'none' : 'all' :
                          (menuList[index].children.findIndex(obj => obj.route == this.props.location.pathname) !== -1 && this.state.activeIndex == index) ? 'none' : 'all'
                        }}
                      >
                     
                      <i className={menuList[index].icon}></i>
                      <span className="nav-label">{menuList[index].name}</span>
                      <span className="fa arrow"></span>
                    </a>

                    <ul
                      className="nav nav-second-level collapse"
                      aria-expanded="false"
                    >
                      {menuList[index].children.map((j, i) => {
                        if (
                          user &&
                          menuList[index].children[i].country &&
                          menuList[index].children[i].country !=
                          user.country_name
                        )
                          return;

                        return (
                          <li className={this.state.activeChildMenu == menuList[index].children[i].name ? "active" : ""} key={i} id={`cta_${menuList[index].children[i].name.replace(/ /g,"_").replace(/\//g, "_").toLowerCase()}`}>
                            <Link
                              className="nav-item nav-link"
                              to={menuList[index].children[i].route}
                              onClick={e => this.setState({ activeChildMenu: menuList[index].children[i].name })}
                            >
                              {menuList[index].children[i].name}
                            </Link>
                          </li>
                        );
                        // }
                      })}
                    </ul>
                  </li>
                ) : (

                  <li
                    key={index}
                    className={this.props.location.pathname.indexOf(menuList[index].route) > -1 ? "active" : ""}
                    id={`cta_${menuList[index].name.replace(/ /g,"_").toLowerCase()}`}
                  //--
                  //className={this.activeRoute(menuList[index].route)}
                  >
                    <Link onClick={this.collapseMenu} to={menuList[index].route}>
                      <i className={menuList[index].icon}></i>
                      <span className="nav-label">{menuList[index].name}</span>
                    </Link>
                  </li>
                )
              )
            }

            )}
          </ul>
        </nav>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

//export default connect(mapStateToProps)(Navigation);
export default Navigation;
