import React, { useState, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { AddButton } from "../../common/containers/button";
import { NavigationButtonsApproval } from "./NavigationButtonsApproval";

import ApproveContext from "../../supply-chain/context/bulkApproveContext"; // React Context API
import useApi from "../../supply-chain/hooks/useApi";
import commonService from "../../supply-chain/services/commonService";
import Utility from "../../supply-chain/utils/DateParser";

function MultiStepApproval({
  list,
  displayProgressBar,
  proceedNext = true,
  noSteps,
  product,
  status,
  type
}) {
  // Responsible to handle Multi-Step Forms

  const [selectedIndex, setSelectedIndex] = useState(0);

  const goNext = () => {
    if (selectedIndex != list.length - 1) setSelectedIndex(selectedIndex + 1);
  };

  const goPrevious = () => {
    if (selectedIndex != 0) setSelectedIndex(selectedIndex - 1);
  };
  const goBack = () => {
    if (selectedIndex == 0) window.location.reload();
  };
  const approveApi = useApi(commonService.createBulkApproval);
  const { ParseDate } = Utility();
  const bulkapproveOptions = useContext(ApproveContext);
  const updateApproval = async () => {
    const { data } = await approveApi.request({
      invoices: bulkapproveOptions.invoiceToProcess,
      userType: "customer",
      revised_date_option: bulkapproveOptions.dueDateOption,
      revised_date: ParseDate(bulkapproveOptions.dueDateSelected),
      acceptedInvoices: bulkapproveOptions.acceptedInvoices,
      approval: bulkapproveOptions.approval,
      product: product,
      status: status
    });
    bulkapproveOptions.onHandleReload();
    toast.success(data["message"]);
  };

  return (
    <>

      <Modal.Header closeButton>  <h4 className="modal-title">{type ? type : "Approve Invoices"}  </h4></Modal.Header>


      <Modal.Body>{list[selectedIndex].component}</Modal.Body>



      {/** ---------------- Navigation buttons ---------------- */}
      <Modal.Footer>

        {selectedIndex == noSteps && (
          <button
            style={{ float: "left" }}
            onClick={goNext}
            className="btn btn-primary"
          >
            Skip
          </button>
        )}


        <NavigationButtonsApproval
          goNext={goNext}
          goPrevious={goPrevious}
          goBack={goBack}
          selectedIndex={selectedIndex}
          list={list}
          proceedNext={proceedNext}
          noSteps={noSteps}
          approval={bulkapproveOptions.approval}
        />
        {(
          ((bulkapproveOptions.invoiceToProcess.length > 0) && (selectedIndex == noSteps - 1)) ||
          ((bulkapproveOptions.showAmount == true) && (bulkapproveOptions.invoiceToProcess.length > 0) && (selectedIndex == noSteps - 1)) ||
          (bulkapproveOptions.invoiceToProcess.length > 0 && bulkapproveOptions.approval === "no")
        ) && (
            <AddButton style="btn btn-primary" onClick={updateApproval} label="Approve" ></AddButton>
          )}
      </Modal.Footer>




    </>
  );
}

export { MultiStepApproval };
