/**
 * Danger Type One  card compoenet
 * ==============================
 * Props 
 * ==============================
 * topLabel,topSubLabel,topValue,bottomLeftValue,bottomLeftLabel,bottomRightValue,bottomRightLabel
 * 
 */
import React from "react";
import "./css/card.css";
import {
  FormColumnMd,
} from "../../../common/containers/formContainer";
import { Link } from "react-router";
import CardSkelton from "./_cardSkeltonTypeTwo";

function DangerTypeOneCard({ col, loading, data, top }) {
  return (
    <>
      {loading === true ? (
        <CardSkelton col={col} top={top ? top : true} />
      ) : (
        <FormColumnMd col={col ? col : 4}>
          <div className="danger-card2">
            <div className="p-3">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-xs-7 col-sm-7 col-7">
                  <label className="p-0 m-0 bold-txt">
                    {data ? data.topLabel : ""}
                  </label>
                  <small>{data ? data.topSubLabel : ""}</small>
                  <h3 className="p-0 mb-2 amount text-lg">
                    {data ? data.topValue : ""}
                  </h3>
                  <br />
                </div>
                <div className="col-lg-5 col-md-5 col-xs-5 col-sm-5 col-5 text-right">
                  {data && data.status ?
                    <Link to={{ pathname: data ? data.primaryBtnRoute : '', state: data ? data.status : "" }} >
                      <button className="btn card-btn btn-red">View</button>
                    </Link> :
                    <Link to={data ? data.primaryBtnRoute : ''} >
                      <button className="btn card-btn btn-red">View</button>
                    </Link>
                  }

                </div>
              </div>
            </div>
            <div className="danger-card2-ftr p-3">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-6 col-sm-6 col-6">
                  <h4 className="  p-0 m-0">{data ? data.bottomLeftValue : " "}</h4>
                  <label className="p-0 m-0">{data ? data.bottomLeftLabel : " "}</label>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-6 col-sm-6 col-6">
                  <h4 className=" p-0 m-0">{data ? data.bottomRightValue : " "}</h4>
                  <label className="p-0 m-0">{data ? data.bottomRightLabel : " "}</label>
                </div>
                <div className="col-md-4">
                  <h4 className="card-name m-0 p-0">{data ? data.bottomSubLabel : ""}</h4>
                </div>
              </div>
            </div>
          </div>
        </FormColumnMd>
      )}
    </>
  );
}
export default React.memo(DangerTypeOneCard);
