import React, { useEffect } from 'react';
import { toast } from "react-toastify";
import LoadingSpinner from '../../common/loaders/loadingSpinner';
import {
    FormContainer,
    TabGridContainer,
    FormGroup
} from "../../common/containers/formContainer";
import Joi from "joi-browser";
import {
    TextInput,
    GeneralButton,
    Picker
} from "../../common/inputs";
import { contactDesignations } from "../../../config/predefinedValues.json";
import Modal from 'react-bootstrap/Modal';
import authService from "../../../services/authService";
import entityService from "../../../services/entityService";
import useForm from '../../../hooks/useForm';
import useApi from '../../../hooks/useApi';
import AddNew from "../../../img/icons/team.svg";
export default function QuickEntityContactCreation({ handleEntityContactCreation, entityId = null }) {

    //------------------Validation Schema-----------------------------
    const schema = Joi.object() // Form validation schema object
        .keys({
            // organization: Joi.string().required().error((errors) => { return { message: "Please enter a Organisation Name" }; }),
            first_name: Joi.string().required().error((errors) => { return { message: "Please enter a First Name" }; }),
            //designation: Joi.string().required().label("Designation"),
            email: Joi.string().email().required().label("Contact Email"),
            //phone_no: Joi.string().required().label("Contact Phone"),
            //entity_id: Joi.string().required().label("Entity")
        }).unknown(true);

    //------------Api Services--------------------------------------------
    const entityContactApi = useApi(entityService.createEntityContact);

    //------------------Save Entity Details-----------------------------
    const saveEntityContact = async () => {
        let reqValues = { ...values }

        if (!reqValues.designation) {
            toast.error('Designation required.');
            return;
        } else
            reqValues['designation'] = reqValues.designation.value;

        const { data: res } = await entityContactApi.request(reqValues); // Sending Async request to server

        if (res.success === true) {
            handleEntityContactCreation(res);
        }
        else
            toast.error(res.message);
    }
    //------------------Custom hook to handle form data-----------------------------
    const { values, setValues, handleChange, handlePickerChange, handleSubmit } = useForm(saveEntityContact, schema); // Custom Hook to handle Form Validation
    //-----------------------------------------------------------------
    const handleDesignationChangeOnEdit = ({ value }) => {
        setValues({ ...values, designation: { label: value, value } });
    }

    useEffect(() => {
        //const { entity_id } = authService.getCurrentUser();
        setValues({ ...values, entity_id: entityId });
    }, []);
    //-----------------------------------------------------------------

    return (
        <>
            {entityContactApi.loading === true && <LoadingSpinner />}


            <Modal.Header closeButton>
                <h4 className="modal-title">Add a Team member</h4>
            </Modal.Header>

            <form onSubmit={handleSubmit} className="form-horizontal">
                <Modal.Body>
                    {/*<div className="row">*/}
                    {/*-------Column 1 ---------------------------*/}

                    <TextInput
                        name="first_name"
                        label="First Name"
                        value={values.first_name}
                        onChange={handleChange}
                        type="horizontal"
                    ></TextInput>

                    <TextInput
                        name="last_name"
                        label="Last Name"
                        value={values.last_name}
                        onChange={handleChange}
                        type="horizontal"
                    ></TextInput>

                    <FormGroup
                        label={"Designation"}
                        type="horizontal"
                        input={
                            <Picker
                                items={contactDesignations}
                                selectedItem={values.designation}
                                onSelectItem={handleDesignationChangeOnEdit}
                            />
                        }
                    />

                    <TextInput
                        name="email"
                        label="Email"
                        value={values.email}
                        onChange={handleChange}
                        type="horizontal"
                    ></TextInput>

                    <TextInput
                        name="phone_no"
                        label="Phone"
                        value={values.phone_no}
                        onChange={handleChange}
                        type="horizontal"
                    ></TextInput>

                    {/*</div>*/}


                </Modal.Body>
                <Modal.Footer>

                    <GeneralButton className="btn btn-primary btn-sm pull-right" faIcon="fa fa-save" name="Save"></GeneralButton>

                </Modal.Footer>
            </form>


        </>
    );
}