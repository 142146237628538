import React, { Component } from "react";
import auth from "../../../../services/authService";
import SupplierBulkUpload from "../../widgets/SupplierBulkUpload";


class ScfSupplierBulkUpload extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    loading:true
  };

  render() {

    const { constants } = auth.getCurrentUser();

    return (
      <>
        <SupplierBulkUpload
        selectedProduct={constants.PRODUCTS.SUPPLYCHAIN}
        />
      </>
    );
  }
}
export default ScfSupplierBulkUpload;