import React from "react";

export function check(products, role) {

    let response = false;

    let activeProducts = products.filter(activePrFilter => activePrFilter.isChecked === true);

    activeProducts.map(activeProduct => {
        activeProduct['roles'].filter(roleFilter => roleFilter.isChecked === true).map(activeRole => {
            if (activeRole.role_name == role)
                response = true;
        });
    })

    return response;
}
//*---------Filtering primary/secondary from the list--------------------
export function checkRoleCategory(products, primaryRoles, secondaryRoles) {

    let response = {};
    let activeRoles = [];
    let activeProducts = products.filter(activePrFilter => activePrFilter.isChecked === true);

    activeProducts.map(activeProduct => {
        activeProduct['roles'].filter(roleFilter => roleFilter.isChecked === true).map(activeRole => {
            activeRoles.push(activeRole.role_name);
        });
    })
    //-----------------------------------------------------------
    let rolesList = [];
    for (var index in primaryRoles) {
        rolesList.push(primaryRoles[index])
    }
    //--------------------------------------------------------
    let secondaryRolesList = [];
    for (var index in secondaryRoles) {
        secondaryRolesList.push(secondaryRoles[index])
    }
    //-----------------------------------------------------------------
    // const found = rolesList.filter(x => activeRoles.includes(x));
    const activePrimaryRoles = rolesList.filter(x => activeRoles.indexOf(x) !== -1);
    if (activePrimaryRoles.length > 0) {
        response['primary'] = activePrimaryRoles;
    }
    else
        response['primary'] = false;
    //-----------------------------------------------------------------
    const activeSecondaryRoles = secondaryRolesList.filter(x => activeRoles.indexOf(x) !== -1);
    if (activeSecondaryRoles.length > 0) {
        response['secondary'] = activeSecondaryRoles;
    }
    else
        response['secondary'] = false;
    //--------------------------------------------------------
    return response;
}
export default {
    check,
    checkRoleCategory
};
