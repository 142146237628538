import React, { Component } from "react";

class Footer extends Component {

    constructor() {
        super();
    }

    render() {
        return (
            <>
                <footer className="text-center py-5 mt-3">
                    {global.config.title} © {new Date().getFullYear()} . All Rights Reserved.
                </footer>
            </>
        );
    }
}

export default Footer;