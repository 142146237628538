import React, { useState, useRef } from "react";
import { TextInput, DateTimePicker } from "../inputs";
import { toast } from "react-toastify";
import * as moment from "moment";

const EditableField = (apiFunc, key, section = "" ) => {

    const TextField = (row) => {

        const [editable, setEditable] = useState(false);
        const [colValue, setColValue] = useState(row.original[row.column.id]);
        // create our ref
        const inputRef = useRef();

        return <span onDoubleClick={e => setEditable( (row.original.disabled !== undefined && row.original.disabled === "1") ? false : true)}>
            {editable === true ?
                <TextInput
                    name={row.column.id}
                    label={row.column.Header}
                    value={colValue}
                    onChange={e => setColValue(e.target.value)}
                    onBlur={event => {
                        if (event.target.value === "" || event.target.value === null) {
                            setColValue(row.original[row.column.id]);
                            setEditable(false);
                            //toast.error("Value not allowed to be empty");
                        } else {
                            handleTextKeyDown(event.target.value, row.original, row.column.id);
                            setEditable(false);
                        }
                    }}
                    reference={inputRef}
                    onKeyDown={
                        event => {
                            if (event.keyCode === 13) {
                                if (event.target.value === "" || event.target.value === null) {
                                    setColValue(row.original[row.column.id]);
                                    setEditable(false);
                                    //toast.error("Value not allowed to be empty");
                                } else {
                                    handleTextKeyDown(event.target.value, row.original, row.column.id);
                                    setEditable(false);
                                }
                            } else if (event.keyCode === 27)
                                setEditable(false)
                        }
                    }
                    type="normal"
                    autoFocus={true}
                ></TextInput> : colValue
            }  </span>
    }

    const DateField = (row) => {

        const [editable, setEditable] = useState(false);
        const [colValue, setColValue] = useState(row.original[row.column.id]);

        return <span onDoubleClick={e => setEditable( (row.original.disabled !== undefined && row.original.disabled === "1") ? false : true)}>
            {editable === true ?
                <DateTimePicker
                    name={row.column.id}
                    selectedDate={colValue}
                    onSelectDate={(value) => {
                        setColValue(value);
                        handleDateKeyDown(value, row.original, row.column.id);
                        setEditable(false)
                    }
                    }
                    onBlur={e => setEditable(false)}
                    onKeyDown={
                        event => {
                            if (event.keyCode === 13) {
                                handleDateKeyDown(event.target.value, row.original, row.column.id);
                                setEditable(false)
                            } else if (event.keyCode === 27)
                                setEditable(false)
                        }
                    }
                    autoFocus={true}
                /> : colValue != null ? moment(colValue, "YYYY/MM/DD").format("DD/MM/YYYY") : null
            }  </span>
    }

    const handleTextKeyDown = async (colValue, rowValues, colId) => {
        if (colValue !== rowValues[colId]) {
            const reqData = {
                data: { [colId]: colValue },
                selectedAttributes: [{ key: rowValues[key], checked: "true" }],
                section: section
            }

            const { success, message } = await apiFunc(reqData);

            if (success === false)
                toast.error(message);
            else
                toast.success(message)
        }
    }

    const handleDateKeyDown = async (colValue, rowValues, colId) => {
        if (colValue !== rowValues[colId]) {
            var m = moment(colValue, 'DD/MM/YYYY');
            if (m.isValid()) { // If the entered value is Date
                colValue = moment(colValue, "DD/MM/YYYY").format("YYYY/MM/DD");
            }

            const reqData = {
                data: { [colId]: colValue },
                selectedAttributes: [{ key: rowValues[key], checked: "true" }]
            }

            const { success, message } = await apiFunc(reqData);

            if (success === false)
                toast.error(message);
            else
                toast.success(message)
        }
    }

    return { TextField, DateField };
}

export default EditableField;