import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router";
import Modal from "react-responsive-modal";
import { browserHistory } from "react-router";

import { FormColumnMd } from "../../../../common/containers/formContainer";
import { HistoryButton, AddButton } from "../../../../common/containers/button";

import CreateInvvoice from "../../../../supply-chain/components/transactions/supplierUpload/index";
import CreateInvvoiceCustomer from "../../../../supply-chain/components/transactions/customerUpload/index";
import { CurrencyLabel } from "../../../../common/inputs";

import BulkApprove from "../bulkApprove";
import BulkReject from "../bulkReject/index";
import BalanceView from "../../../../supply-chain/components/transactions/bulkApprove/balanceView";
import BulkApproveSupplier from "../../../../supply-chain/components/transactions/bulkApprove/bulkApproveSupplier";
import transaction_services from "../../../../supply-chain/services/transaction_service";
import auth from "../../../../../services/authService";


import EntityContext from "../../../../../context/entityContext";
import RoleChecker from "../../../../../utils/roleChecker";
import EditableField from '../../../../common/tables/EditableField';
import Table from '../../../components/table/index';
class TraderTransactionList extends Component {


  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  state = {
    selectedTab: this.props.location.state ? this.props.location.state : null,
    ajaxRequest: false,
    selectedRows: [],
    categories: [],
    open: false,
    openCustomer: false,
    approvebuttonClass: "btn btn-primary  hidden",
    declinebuttonClass: "btn btn-danger  hidden",
    bulkapprove: false,
    bulkreject: false,
    selected: {},
    roleType: this.context ? this.context.selectedBusinessType : "",
    tabs: [],
    checkbox: true

  };

  componentDidMount() {
    if (this.context) {
      this.setState({ roleType: this.context.selectedBusinessType })
    }
  }
  componentDidUpdate() {
    if (this.context.selectedBusinessType != this.state.roleType) {
      this.setState({ roleType: this.context.selectedBusinessType },
        () => { this.tableRef.current.reloadData(); }
      );
      //this.setState({ roleType: this.context.selectedBusinessType })
    }
  }
  //----Go back to prospect on cancel click-------------
  handleCancel = () => {
    browserHistory.push("/client/home");
  };

  // Callback function to the Action Table.
  handleRowSelection = selectedRows => {
    this.setState({ selectedRows })
    let newSelected = {};
    selectedRows.map(item => {
      newSelected[item.key] = item.checked;
    })

    this.setState({ selected: newSelected });
    const { constants, sc_invoice_status } = auth.getCurrentUser();

    if (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && this.state.selectedTab == sc_invoice_status["awaiting_approval"]) {

      this.setState({
        approvebuttonClass: "btn btn-primary btn-sm ml-3 mt-1",
        declinebuttonClass: "btn btn-danger btn-sm mt-1",
      });


    }
    if (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.SUPPLIER) && this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"]) {

      this.setState({
        approvebuttonClass: "btn btn-primary btn-sm ml-3 mt-1",
        declinebuttonClass: "btn btn-danger btn-sm mt-1",
      });


    }

  }
  onCloseModalApprove = () => {
    this.setState({ bulkapprove: false });
  };
  onCloseModalReject = () => {
    this.setState({ bulkreject: false });
  };
  BulkApprove = async () => {
    this.setState({ bulkapprove: true });
  };
  BulkReject = async () => {
    this.setState({ bulkreject: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onOpenModalCustomer = () => {

    this.setState({ openCustomer: true });
  };
  onCloseModalCustomer = () => {
    this.setState({ openCustomer: false });
  };


  handleRoleTypeChange = roleType => {
    this.setState({ roleType },
      () => { this.tableRef.current.reloadData(); }
    );
  }
  setTabs = tabs => {

    this.setState({ tabs });
  }
  handleChange = (event) => {
    const { sc_invoice_status } = auth.getCurrentUser();
    this.setState({ selectedTab: event.target.value }, () => {
      if ((sc_invoice_status['awaiting_approval'] == this.state.selectedTab) || (sc_invoice_status['awaiting_supplier_update'] == this.state.selectedTab)) {

        this.setState({ checkbox: true })
      } else {
        this.setState({ checkbox: false })
      }

    });
    this.tableRef.current.clearSelection();


    this.setState({ selectedRows: [], selected: {} });

  }


  render() {

    // Instance for Date parser utility function.
    // @params => service, key
    const { entityDetails } = this.context;
    const { TextField, DateField } = EditableField(transaction_services.getInvoice, 'a_guid');
    const { constants, sc_invoice_status } = auth.getCurrentUser();

    const cols = [
      {
        Header: "Invoice Number",
        accessor: "inv_number",
        headerStyle: { textAlign: "left" },
        minWidth: 110,

      },
      {
        Header: "Supplier",
        accessor: "supplier",
        headerStyle: { textAlign: "left" },
        minWidth: 60,
        show: (RoleChecker.check(entityDetails.products, constants.ROLES.CUSTOMER)) ? true : false,
      },
      {
        Header: "Customer",
        accessor: "customer",
        headerStyle: { textAlign: "left" },
        minWidth: 60,
        show: (RoleChecker.check(entityDetails.products, constants.ROLES.SUPPLIER)) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {(row.original.customer)}</span>,
      },
      {
        Header: "Amount",
        accessor: "inv_amount",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {<CurrencyLabel value={row.original.inv_amount}></CurrencyLabel>}</span>
      },
      {
        Header: "Date",
        accessor: "trans_date",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {row.original.trans_date}</span>
      },
      {
        Header: "Due Date",
        accessor: "inv_due_date",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {row.original.inv_due_date}</span>
      },
      {
        Header: "Action",
        accessor: "action",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        //show: (!RoleChecker.check(products, constants.ROLES.CUSTOMER)) ? true : false,
        Cell: row => {
          return (
            <>
              <Link className="action-icons" style={{ color: "grey" }} to={{ pathname: (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.SUPPLIER) || RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER)) ? `/client/scf/invoice/details` : `/scinvoice/details`, search: "?inv_guid=" + row.original.trans_guid }} >
                <HistoryButton className="fa fa-file-text-o"></HistoryButton>
              </Link>
            </>
          )
        }
      }

    ];

    const RightPanel = () => (
      <AddButton faIcon="fa fa-plus" style="btn btn-primary btn-xs btn-rounded  " onClick={(this.context.selectedBusinessType == constants.BUSINESS_TYPE.SALE) ? this.onOpenModal : this.onOpenModalCustomer} label="Add New"></AddButton>
    )
    const LeftPanel = () => (
      <>

        {((((this.state.selectedTab == sc_invoice_status['awaiting_approval'] && RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && this.state.roleType == constants.BUSINESS_TYPE.PURCHASE) || (this.state.selectedTab == sc_invoice_status['awaiting_supplier_update'] && RoleChecker.check(this.context.entityDetails.products, constants.ROLES.SUPPLIER) && this.state.roleType == constants.BUSINESS_TYPE.SALE))) && (this.state.selectedRows.find(rows => rows.checked == true) != undefined)) && (
          <>
            {Object.keys(this.state.selected).length > 0 &&
              RoleChecker.check(entityDetails.products, constants.ROLES.CUSTOMER) && this.state.selectedTab == sc_invoice_status['awaiting_approval'] && (
                <BalanceView invoices={this.state.selected} entity_id={entityDetails.a_guid}></BalanceView>)}
            <FormColumnMd col={3}>

              {RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && this.state.roleType == constants.BUSINESS_TYPE.PURCHASE && (
                <AddButton label="Decline" style={this.state.declinebuttonClass} faIcon="fa fa-thumbs-down" onClick={this.BulkReject} ></AddButton>
              )}

              <AddButton style="btn btn-primary btn-xs pull-right ml-2" label="Approve" style={this.state.approvebuttonClass} onClick={this.BulkApprove} ></AddButton>
            </FormColumnMd>
          </>
        )}
      </>
    )


    return (
      <>
        <Helmet>
          <title>Invoice List| {global.config.title}</title>
        </Helmet>

        <div className="card">
          <div className="card-header">


            {this.state.tabs && this.state.tabs.length > 0 && (
              <select className="secondary-dropdown" value={this.state.selectedTab} onChange={this.handleChange}> <option value="">-------Select-------</option>{this.state.tabs.map(obj => <option value={obj.value}>{obj.label}</option>)} </select>
            )}

            <span className="float-right p-0 mb-2"><RightPanel /></span>
          </div>


          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                {((((this.state.selectedTab == sc_invoice_status['awaiting_approval'] && RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) && this.state.roleType == constants.BUSINESS_TYPE.PURCHASE) || (this.state.selectedTab == sc_invoice_status['awaiting_supplier_update'] && RoleChecker.check(this.context.entityDetails.products, constants.ROLES.SUPPLIER) && this.state.roleType == constants.BUSINESS_TYPE.SALE))) && (this.state.selectedRows.find(rows => rows.checked == true) != undefined)) && (
                  <>
                    <div className="summary-data">
                      <div className="row m-t-sm m-b-sm">
                        <LeftPanel />
                      </div>
                    </div>
                  </>
                )}
                <Table
                  ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
                  service={transaction_services.getInvoice}
                  cols={cols}
                  defaultTab={this.state.selectedTab ? this.state.selectedTab : (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) ? sc_invoice_status['awaiting_approval'] : sc_invoice_status['awaiting_supplier_update'])}
                  onSelectTab={val => { this.setState({ selectedTab: val }); this.setState({ selected: [] }); this.setState({ selectedRows: [] }); }}
                  uid={'trans_guid'}
                  onSelectRow={this.handleRowSelection}
                  //headerSelect={ true}
                  // tableRightPanel={<RightPanel />}
                  // tableLeftPanel={<LeftPanel />}
                  reqParams={{ roleType: this.state.roleType ? this.state.roleType : "" }}
                  ibox={true}
                  headerTabs={true}
                  setTabs={this.setTabs}
                  checkbox={this.state.checkbox}
                />


              </div>
            </div>
          </div>

        </div>

        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          closeOnOverlayClick={false}
          center
          className="animated bounceInRight"
        >
          <div style={{ width: 480 }}>
            <CreateInvvoice
              a_guid={entityDetails.a_guid}
              page="common"
              onCloseModal={() => {
                this.onCloseModal();
                this.tableRef.current.reloadData();
              }}
            ></CreateInvvoice>
          </div>
        </Modal>

        <Modal
          open={this.state.openCustomer}
          onClose={this.onCloseModalCustomer}
          closeOnOverlayClick={false}
          center
          className="animated bounceInRight "
        >
          <div style={{ width: 480 }}>
            <CreateInvvoiceCustomer
              a_guid={entityDetails.a_guid}
              page="common"
              onCloseModal={() => {
                this.onCloseModalCustomer();
                this.tableRef.current.reloadData();

              }}
            ></CreateInvvoiceCustomer>
          </div>
        </Modal>


        <BulkApprove
          invoices={this.state.selected}
          onClose={this.onCloseModalApprove}
          onHandleReload={() => {
            this.onCloseModalApprove();
            this.tableRef.current.reloadData();
            this.tableRef.current.clearSelection();
            this.setState({ selectedRows: [], selected: {} });
          }}
          open={this.state.bulkapprove}
          trans_status={this.state.selectedTab}
          entity_id={entityDetails.a_guid}
        ></BulkApprove>

        <BulkReject
          invoices={this.state.selected}
          onClose={this.onCloseModalReject}
          onHandleReload={() => {
            this.onCloseModalReject();
            this.tableRef.current.reloadData();
            this.tableRef.current.clearSelection();
            this.setState({ selectedRows: [], selected: {} });
          }}
          open={this.state.bulkreject}
          trans_status={this.state.selectedTab}
        ></BulkReject>

        <BulkApproveSupplier
          trans_status={this.state.selectedTab}
          open={this.state.bulkapprove}
          invoices={this.state.selected}
          onClose={this.onCloseModalApprove}
          entity_id={entityDetails.a_guid}
          onHandleReload={() => {
            this.onCloseModalApprove();
            this.tableRef.current.reloadData();
            this.tableRef.current.clearSelection();
            this.setState({ selectedRows: [], selected: {} });
          }}
        ></BulkApproveSupplier>

      </>
    );
  }
}
export default TraderTransactionList;