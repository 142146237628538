import React, { useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
export default function InvoiceErrors({ onClose, errors }) {

    return (
        <>
            <Modal.Header closeButton>
                <div className="modal-header">

                    <h4 className="modal-title">Validation Errors</h4>

                </div>
            </Modal.Header>

            <Modal.Body>
                <table class="table table-sm client-tble">
                    <tbody>
                        <tr>
                            <th>Invoice No</th>
                            <th>Error Description</th>

                        </tr>
                        {errors.map((item, index) => (
                            <tr>
                                <td>
                                    {item.inv_number}
                                </td>

                                <td>
                                    {item.message}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
        </>
    )
}