import React from "react";
import Loader from "react-loader-spinner";
import "../../../styles/spinner.css";
import Spinner from 'react-bootstrap/Spinner'
const LoadingSpinner = ({ normal = false, type, height = 80, width = 60 }) => (
  <React.Fragment>
    {normal === false ?

      <div className="overflow-visible" style={{ zIndex: 999999, position: "fixed", top: "50%", left: "50%" }}>
        < Spinner animation="border" variant="primary" />
      </div> :
      <div className="overflow-visible" style={{ zIndex: 999 }}>
        <Loader
          type="ThreeDots"
          color="#16C79A"
          height={30}
          width={30}

        />
      </div>
    }
  </React.Fragment>
);

export default LoadingSpinner;
