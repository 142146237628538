import React from "react";
import { PrimaryCard } from "../../components/cards";

function ApprovedForPayment({ data, loading, setProductRoleType }) {

    return (
        <>

            <PrimaryCard
                loading={loading}
                data={data}
                setProductRoleType={setProductRoleType}
            />

        </>
    )
}
export default ApprovedForPayment;