const tourVdo = {
    "Dashboard_Tour_Video":"dashboard/Dashboard_Tour_Video.mp4",
    "Welcome_to_Fifo_Central":"welcome/Welcome_to_Fifo_Central.mp4",
    "Prospect_Bulk_Uploads": "prospects/Prospect_Bulk_Uploads.mp4",
    "Take_the_tour_crank_this_baby_up":"Take_the_tour_videos/Take the tour crank this baby up.mp4",
    "Take_the_tour_General":"Take_the_tour_videos/Take the tour General.mp4",
    "Take_the_tour_lovely_to_see_you":"Take_the_tour_videos/Take the tour lovely to see you.mp4",
    "Take_the_tour_my_favourite_tour":"Take_the_tour_videos/Take the tour my favourite tour.mp4",
    "Take_the_tour_personal_tour_version":"Take_the_tour_videos/Take the tour personal tour version.mp4",
    "Take_the_tour_So_you__looking_for_some_help":"Take_the_tour_videos/Take the tour  So you are looking for some help.mp4",
    "Take_the_tour_Well_Well_Well":"Take_the_tour_videos/Take the tour Well Well Well.mp4",
    "Adding_IF_fees_and_charges_V1":"administrator/Adding_IF_fees_and_charges_V1.mp4",
    "Adding_SCF_and_TF":"administrator/Adding_SCF_and_TF.mp4",
    "The_Introducer_Section" : "introducers/The_Introducer_Section.mp4",
    "All_Accounts_video" : "accounts/All_Accounts_video.mp4",
    "Adding_New_Account" : "accounts/Adding_New_Account.mp4",
    "Accounts_Home_Page" : "accounts/Accounts_Home.mp4",
    "Account_Team_Members" : "accounts/Account_Team_Members.mp4",
    // "":"",
    // "":"",
    "Request_a_tour":"Request_a_tour/Request_a_tour.mp4",
    "Prospect_to_Acc_Conversion":"prospects/Converting_Prospect_to_Account_Final.mp4",
    "scf_and_tf_invoice_overview":"invoices/final_scf_and_tf_invoice_overview.mp4"
}
export default tourVdo; 