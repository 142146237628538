import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ConfirmModal from "../components/common/modals/ConfirmModal";
import Joi from "joi-browser";

const useForm = (callbackFunc, schema, instantValidation = false) => {
  const [values, setValue] = useState({});
  const [errors, setErrors] = useState({});
  const [check, setRedirectCheck] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  const [options, setOptions] = useState({
    showConfirmation: false,
    confirmationTitle: "Confirm to submit",
    confirmationMsg: "Are you sure to do this ?",
  });

  //-------------------Confirmation Modal-------------------------//
  const { ask, setMessage, setTitle } = ConfirmModal(callbackFunc);
  //--------------------------------------------------------------//

  //---------------------Handle Form Submission-------------------//
  const handleSubmit = (e) => {
    e.preventDefault();
    setRedirectCheck(false);
    const errors = validate();
    setErrors({ errors: errors || {} });
    if (errors) return;
    options.showConfirmation === true ? ask() : callbackFunc();
  };
  //--------------------------------------------------------------//

  //---------------------Handle Input Change----------------------//
  const handleChange = (e) => {
    handleStateCheckCall();
    setValue({ ...values, [e.target.name]: e.target.value });
    setShowPrompt(true);
  };

  const handleStateCheckCall = () => {
    setRedirectCheck(true);
  };

  //---------------------Handle Picker Change----------------------//
  const handlePickerChange = (property, selectedOpt) => {
    if (values[property] != selectedOpt) {
      if (values[property] != undefined) handleStateCheckCall();
      setValue({ ...values, [property]: selectedOpt });
    }
    //setShowPrompt(true);
  };
  //--------------------------------------------------------------//

  //---------------------Handle Form Validation-------------------//
  const validate = () => {
    const options = { abortEarly: instantValidation === true ? false : true };
    const { error } = Joi.validate(values, schema, options);
    if (!error) {
      setErrors({ errors: {} }); // Set errors object back to normal if no errors found
      return null;
    }
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;

      if (instantValidation === false) toast.error(item.message);
    }

    if (instantValidation === true)
      // Set error object instantly (most probably on Handle change)
      setErrors({ errors: errors || {} });

    return errors;
  };
  //--------------------------------------------------------------//

  //---------------Set Default Values / Form Props-------------------------------------//
  const setDefaultValue = (property, value) =>
    setValue({ ...values, [property]: value });
  const setValues = (fields) => setValue(fields);
  const formProps = (props) => setOptions({ ...options, ...props });
  //-----------------------------------------------------------------------------------//

  useEffect(() => {
    setTitle(options.confirmationTitle);
    setMessage(options.confirmationMsg);

    if (instantValidation === true) validate();
  }, [options, values]);

  return {
    values,
    errors: errors.errors || null,
    setErrors,
    handleSubmit,
    handleChange,
    handlePickerChange,
    setDefaultValue,
    setValues,
    formProps,
    check,
    showPrompt,
    setShowPrompt,
    validate
  };
};
export default useForm;
