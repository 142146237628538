import React, { useContext, useState, useEffect } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Link } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import * as moment from "moment";
import {
  TextInput,
  GeneralButton,
  CurrencyText,
  CurrencyLabel,
  Picker,
  FeePicker,
  RemoveButton,
} from "../../common/inputs";
import { DateTimePicker } from "../../common/inputs/datePicker";
import {
  FormRow,
  FormGroup,
  ModalHeader,
} from "../../common/containers/formContainer";
import useApi from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import transactionService from "../../../services/invTransactions";
import transService from "../../../services/transactionService";
import EntityContext from "../../../context/entityContext";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import { transStatus } from "../../../config/predefinedValues.json";
import authService from "../../../services/authService";
import entity from "../../../services/entityService";
import CustomConfirmation from "../invoice/_customConfirmation";
import CreateFee from "../../admin/fee/create";
import Utility from "../../../utils/DateParser";

function DisburseSingleInvoices({
  onClose,
  selectedRow,
  context = true,
  handleReloadData,
  handleTab=null,
}) {
  //------------Api Services--------------------------------------------
  const transactionApi = useApi(
    transactionService.createSingleInvoiceDisbursement
  );
  const feeStructureApi = useApi(transService.getFeeStructureDetails);
  const disburseOnLoadDataApi = useApi(transactionService.disburseOnLoadData);
  const contextObj = useContext(EntityContext);
  const { ParseDate: DateParser } = Utility();
  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      disb_amount: Joi.number().label("Advance Amount").min(0.1)
      .error((errors) => {
        return { message: "Advance amount cannot be empty." };
      }),
      client_reference: Joi.string().required().label("Client Reference No."),
      fee: Joi.required().label("Discount Fee"),
    })
    .unknown(true);

  const [paymentInfo, setPaymentInfo] = useState([]);

  const [refNo, setRefNum] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedBp, setSelectedBp] = useState(null);
  const [total_amount, setTotalAmount] = useState(0);
  const [total_adv_amt, setTotalAdvAmt] = useState(0);
  const [totalPercent, setTotalPercent] = useState(0);
  const [isSubmited, setSubmitted] = useState(false);
  const [defaultFee, setDefaultFee] = useState([]);
  const [openDisburse, setDisburse] = useState(true);
  const [feeList, setFee] = useState([]);
  const [total_inv_amount, setTotalInvAmt] = useState(0);
  const [transId, setTransId] = useState(null);
  const [openConfirm, setConfirm] = useState(false);
  const [editablePercent, setEditableField] = useState("");
  const [open, setModal] = useState(false);

  const fetchFeeStructureDetails = async (
    invData,
    client,
    fee = null,
    feeData = null,
    fee_id = null
  ) => {
    setFee(feeData);
    if (fee == null) populateDefaults(invData, fee_id, feeData, client);
    else {
      const user = authService.getCurrentUser();
      const { data } = await feeStructureApi.request(client, user.country_id);
      setFee(data.feeData);
    }
  };

  const populateDefaults = async (invData, fee_id, feeData, entityId) => {
    const selectedtFee = {
      label: "Select Discount Fee",
      value: null,
    };
    if (fee_id !== null) {
      /** -------------- Selected Fee Type -------------- */
      let selectedFee = feeData.filter((item) => item.value == fee_id);

      if (selectedFee.length != 0) {
        const selectedDefaultFee = {
          label: selectedFee[0].label,
          value: selectedFee[0].value,
        };
        setValues({ ...invData, fee: selectedDefaultFee });
      } else {
        setValues({ ...invData, fee: selectedtFee });
      }
    } else {
      setValues({ ...invData, fee: selectedtFee });
    }
  };
  // Handling Async operation to save the data to the server
  const getFeeList = async (client, paymentsArr) => {
    const user = authService.getCurrentUser();
    const data = await disburseOnLoadDataApi.request({
      client: client,
      country: user.country_id,
    });
    setRefNum(data.data.ref_number);

    let advance_percent_total = _.sumBy(
      paymentsArr,
      (item) =>
        (Number(item.inv_chargeable_amt) *
          (item.inv_adv_percent ? item.inv_adv_percent : 0)) /
        100
    );
    let advance_total = _.sumBy(paymentsArr, (item) =>
      Number(item.inv_chargeable_amt)
    );

    let advance_rate = ((advance_percent_total / advance_total) * 100).toFixed(
      2
    );
    setTotalPercent(advance_rate);

    // -------------------------------------------- //
    
    let paymentRequestedInvoice = selectedRow.find(item => item.disb_date !== null)
    // -------------------------------------------- //

    let invData = { ...values };
    invData = {
      ...values,
      client_reference: data.data.ref_number,
      disb_date: null,
      disb_later_date: paymentRequestedInvoice ? paymentRequestedInvoice.disb_date : DateParser(moment(new Date())),
      disburse_now: paymentRequestedInvoice ? false : true,
      disburse_later: paymentRequestedInvoice ? true : false,
      // advance_rate: fee,
      disb_amount: _.sumBy(
        paymentsArr,
        (item) =>
          (Number(item.inv_chargeable_amt) *
            (item.inv_adv_percent ? item.inv_adv_percent : 0)) /
          100
      ),
    };

    fetchFeeStructureDetails(
      invData,
      client,
      null,
      data.data.feeData.filter(item => item.parent_fee_guid === null),
      data.data.rows !== null ? data.data.rows.fee_id : null
    );
  };

  useEffect(() => {

    validateInvoices();

    let paymentsArr = [];
    selectedRow.map((item) => {
      paymentsArr.push({
        index: paymentsArr.length,
        edit: false,
        inv_guid: item.inv_guid,
        client_guid: item.client_guid,
        trans_guid: null,
        inv_number: item.inv_number,
        debtor_organisation: item.debtor_organisation
          ? item.debtor_organisation
          : item.trans_guid,
        inv_amount: item.inv_amount,
        inv_date: item.inv_date,
        inv_due_date: item.inv_due_date,
        inv_chargeable_amt:
          item.inv_chargeable_amt !== null
            ? parseFloat(item.inv_chargeable_amt)
            : 0,
        inv_adv_percent: item.inv_adv_percent,
        inv_adv_amount: item.inv_adv_amount
          ? item.inv_adv_amount
          : (Number(item.inv_chargeable_amt) *
            (item.inv_adv_percent ? item.inv_adv_percent : 0)) /
          100,
      });
    });

    setPaymentInfo(paymentsArr);
    setTotalAmount(_.sumBy(paymentsArr, (item) => Number(item.inv_chargeable_amt)));
    setTotalAdvAmt(_.sumBy(paymentsArr, (item) => Number(item.inv_adv_amount)));

    // ---- Set client default values from Context API (if applicable) ---- //
    if (contextObj && typeof contextObj.entityDetails !== "undefined") {
      setClientName(contextObj.entityDetails.organization);
      setClientId(contextObj.entityDetails.a_guid);
      setSelectedBp(contextObj.entityDetails.owner.a_guid);
      setSelectedCountry(contextObj.entityDetails.owner.country_id);
      getFeeList(contextObj.entityDetails.a_guid, paymentsArr);
    } else {
      // how to handle when different client invoices got selected ?
      setClientName(selectedRow[0].client_organisation);
      setSelectedBp(selectedRow[0].trans_owner);
      setSelectedCountry(null);
      setClientId(selectedRow[0].client_guid !== undefined ? selectedRow[0].client_guid : selectedRow[0].trans_client_guid);
      getFeeList(selectedRow[0].client_guid !== undefined ? selectedRow[0].client_guid : selectedRow[0].trans_client_guid, paymentsArr);
    }
    //----------------------------------------------------------------------//

  }, []);

  // Validation to check if the user has selected invoices with multiple clients
  const validateInvoices = () => {
    let client_guid = selectedRow[0].client_guid;
    if( selectedRow.findIndex(obj => obj.client_guid !== client_guid) != -1){
      onClose();

      confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="custom-delete-ui">
                  <h4>Unable to create new transaction!</h4>
                  <p>
                    The selected invoices have different clients.
                  </p>
      
                  <button
                    className="btn btn-primary ml-2"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Close
                  </button>
                </div>
              );
        },
        title: "",
        message: "",
      });
    }
  }
  // ------------------------------------------------------------------------//

  const handleCreateTransaction = (transData) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Created Transaction</h4>
            <p>
              Successfuly created transaction. ref# :
              {transData.trans_client_reference}
            </p>

            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                if(handleTab){
                  if ( (values.disburse_now === true) || (moment().format('YYYY-MM-DD') == values.disb_later_date) )
                    handleTab(transStatus[0]["OPEN"]);
                  else if (values.disburse_later === true)
                    handleTab(transStatus[0]["APPROVED_FOR_PAYMENT"]);
                  else handleTab(transStatus[0]["OPEN"]);
                }
                onClose();
              }}
            >
              Go To List
            </button>
          </div>
        );
      },
      title: "Created Transaction",
      message: `Successfuly created transaction. ref# : ${transData.trans_client_reference}`,
    });
  };

  const validationRules = () => {
    
    let isValid = true;
    setSubmitted(true);
    if (values.disb_date == null && values.disbursed) {
      setSubmitted(false);
      toast.error(`Advance Date is required `);
      isValid = false;
    }
    if (
      values.disb_later_date == null &&
      (values.disburse_later || values.disburse_now)
    ) {
      setSubmitted(false);
      toast.error(`Schedule Advance Date is required `);
      isValid = false;
    }
    if (
      values.disb_amount > total_amount &&
      values.disbursed === true &&
      values.disbursed
    ) {
      setSubmitted(false);
      toast.error(`The Advance Amount cannot exceed the Invoice Amount `);
      isValid = false;
    }

    if (paymentInfo.filter((obj) => obj.inv_adv_percent == "" || obj.inv_adv_percent == 0).length > 0) {
      setSubmitted(false);
      toast.error(`Advance % cannot be empty`);
      isValid = false;
    }

    if (paymentInfo.filter((obj) => obj.inv_adv_amount == "" || obj.inv_adv_amount == 0).length > 0) {
      setSubmitted(false);
      toast.error(`Advance Amount cannot be empty`);
      isValid = false;
    }

    if(values.fee.value === null){
      setSubmitted(false);
      toast.error(`Please select Discount Fee from the list`);
      isValid = false;
    }
    return isValid;
  };
  // Handling Async operation to save the data to the server
  const submitInvoiceInfo = async () => {

    let reqValues = {...values};
    if( (moment().format('YYYY-MM-DD') == values.disb_later_date) )
      reqValues.disburse_now = true;

    const valid = validationRules();
    if (valid) {
      let percent_disbursed = (
        (values.disb_amount /
          _.sumBy(paymentInfo, (item) => Number(item.inv_chargeable_amt))) *
        100
      ).toFixed(2);

      const reqData = {
        trans_client_reference: refNo,
        trans_client_guid: clientId,
        trans_client_name: clientName,
        trans_date: moment(new Date()).format("YYYY-MM-DD").toString(),
        trans_discount_fee: null,
        country: selectedCountry,
        bp: selectedBp,
        trans_lpf_pctg: null,
        lpfApplicable: false,
        disb_pctg: percent_disbursed,
        formData: reqValues,
        invoices: [...paymentInfo],
      };

      // Validation: Advance date cannot precede Invoice date
      let currentDate = null;

      let moments = paymentInfo.map(d => moment(d.inv_date)), minInvDate = moment.min(moments)
      currentDate = values.disb_later_date !== null ? moment(values.disb_later_date) : moment(values.disb_date)
      if(currentDate < minInvDate)
      {
          toast.error('Advance date cannot precede the Invoice date');
          setSubmitted(false);
          return;
      }
      
      // Validation: Advance date cannot precede Invoice due date
      let maxInvDateMoments = paymentInfo.map(d => moment(d.inv_due_date)), maxInvDate = moment.min(maxInvDateMoments)
      currentDate = values.disb_later_date !== null ? moment(values.disb_later_date) : moment(values.disb_date)
      if(currentDate > maxInvDate)
      {
          toast.error('A transaction Advance cannot take place after the Invoice Due Date. Please edit entry to continue.');
          setSubmitted(false);
          return;
      }


      const { data } = await transactionApi.request(reqData);

      if (data.warning) 
      {
        confirmAlert({
          customUI: ({ onClose: onCloseWarning }) => {
              return (

                <div className="custom-delete-ui">
                  <h4>Funding limit has been reached!</h4>
                  <p>{data.message}</p>
                  <button
                      className="btn btn-white ml-2"
                      onClick={() => {
                        onCloseWarning();
                        onClose();
                      }}
                  >
                    No
                  </button>
                  <button
                      className="btn btn-primary ml-2"
                      onClick={async() => { 

                        const {data: response} = await transactionApi.request({...reqData, nofundinglimitcheck: true});
                        onCloseWarning(); 
                        
                        if (response.success === false) 
                          toast.error(response.message);
                        else{
                          if (values.disbursed === true) {
                            setDisburse(false);
                            setConfirm(true);
                            setTotalInvAmt(response.transData["total_inv_amount"]);
                            setTransId(response.transData["trans_guid"]);
                          } else {
                            onClose();
                            handleCreateTransaction(response.transData);
                          }
                        }
                        
                      }}
                  >
                      Yes
                  </button>
                </div>
                );
          },
          title: "",
          message: "",
        });
      }

      else if (data.success === false) 
        toast.error(data.message);

      else {
        if (values.disbursed === true) {
          setDisburse(false);
          setConfirm(true);
          setTotalInvAmt(data.transData["total_inv_amount"]);
          setTransId(data.transData["trans_guid"]);
        } else {
          onClose();
          handleCreateTransaction(data.transData);
        }
      }
    }
  };

  //------------------Custom hook to handle form data-----------------------------
  const {
    values,
    setValues,
    handleChange,
    handlePickerChange,
    handleSubmit,
  } = useForm(submitInvoiceInfo, schema); // Custom Hook to handle Form Validation

  //---------------------------------------------------------------//

  const removeHandler = (row, index) => {
    let invRows = paymentInfo.filter((obj) => obj.index != index);
    setPaymentInfo(invRows);

    // Updating total percent
    let advance_percent_total = _.sumBy(
      invRows,
      (item) => (Number(item.inv_chargeable_amt) * (item.inv_adv_percent ? item.inv_adv_percent : 0)) / 100);

    let advance_total = _.sumBy(invRows, (item) => Number(item.inv_chargeable_amt));
    let advance_rate = ((advance_percent_total / advance_total) * 100).toFixed(2);
    invRows.length > 0 ? setTotalPercent(advance_rate) : setTotalPercent(0);

    // Updating Disb amount
    let invData = { ...values };
    invData = {
      ...values,
      disb_amount:
        invRows.length > 0
          ? _.sumBy(
            invRows,
            (item) => (Number(item.inv_chargeable_amt) * (item.inv_adv_percent ? item.inv_adv_percent : 0)) / 100)
          : 0,
    };
    setValues({ ...values, ...invData });
  };

  const handlePercentEdit = (row, index = null, percentage = null) => {
    let InvRow = { ...paymentInfo };

    if (percentage !== null && percentage <= 100) {
      if (percentage == "") {
        percentage = 0;
        InvRow[index].inv_adv_percent = "";
        InvRow[index].inv_adv_amount = "";
      } else {
        InvRow[index].inv_adv_percent = percentage;
        InvRow[index].edit = true;
        InvRow[index].inv_adv_amount = (row.inv_chargeable_amt * percentage) / 100;

        // Updating total percent
        let advance_percent_total = _.sumBy(
          paymentInfo,
          (item) =>
            (Number(item.inv_chargeable_amt) *
              (item.inv_adv_percent ? item.inv_adv_percent : 0)) /
            100
        );

        let advance_total = _.sumBy(paymentInfo, (item) => Number(item.inv_chargeable_amt));
        let advance_rate = ( (advance_percent_total / advance_total) * 100 ).toFixed(2);
        setTotalPercent(advance_rate);
      }

      // Updating Disb amount
      let invData = { ...values };
      invData = {
        ...values,
        disb_amount: _.sumBy(
          paymentInfo,
          (item) =>
            (Number(item.inv_chargeable_amt) *
              (item.inv_adv_percent ? item.inv_adv_percent : 0)) /
            100
        ),
      };
      setValues({ ...values, ...invData });
    }
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const handleAddNewFee = (feeGuid, feeName) => {
    const selectedDefaultFee = {
      label: feeName,
      value: feeGuid,
    };
    setValues({ ...values, fee: selectedDefaultFee });
    fetchFeeStructureDetails(
      values,
      contextObj && contextObj.entityDetails.a_guid
        ? contextObj.entityDetails.a_guid
        : selectedRow[0].client_guid,
      selectedDefaultFee,
      null,
      null
    );
    onCloseModal();
  };

  const handleAmtEdit = (row, index = null, amount = null, action = false) => {
    let InvRow = { ...paymentInfo };

    if (amount !== null) {

      if (amount == "") {
        amount = 0;
        InvRow[index].inv_adv_amount = "";
        if (action) InvRow[index].inv_adv_percent = "";

      } else {
        InvRow[index].inv_adv_amount = amount;
        InvRow[index].edit = true;

        if (action)
          InvRow[index].inv_adv_percent = parseFloat(((amount / row.inv_chargeable_amt) * 100)).toFixed(2);

        // Updating total percent
        let advance_percent_total = _.sumBy(
          paymentInfo,
          (item) => (Number(item.inv_chargeable_amt) * (item.inv_adv_percent ? item.inv_adv_percent : 0)) / 100 );

        let advance_total = _.sumBy(paymentInfo, (item) => Number(item.inv_chargeable_amt));
        let advance_rate = ( (advance_percent_total / advance_total) * 100 );
        setTotalPercent(advance_rate);
      }

      // Updating Disb amount
      let invData = { ...values };
      invData = {
        ...values,
        disb_amount: _.sumBy(paymentInfo, (item) => Number(item.inv_adv_amount))
        // disb_amount: _.sumBy(
        //   paymentInfo,
        //   (item) => (Number(item.inv_chargeable_amt) * (item.inv_adv_percent ? item.inv_adv_percent : 0)) / 100 ),
      };
      setValues({ ...values, ...invData });
    }
  };

  return (
    <>
      <div>
        {openConfirm && (
          <CustomConfirmation
            selectedRow={selectedRow}
            onClose={(e) => {
              onClose();
              setConfirm(false);
            }}
            title={"Advanced successfully"}
            des={"Are you sure?"}
            page={"participant"}
            transId={transId}
            invoiceSum={total_inv_amount}
            totalAgreementAmt={total_amount}
            totalAdvanceAmt={total_adv_amt}
            extraInfo={
              "Would you like add participants in to this transaction?"
            }
            handleTab={handleTab}
          ></CustomConfirmation>
        )}
        <Modal show={open} onHide={onCloseModal} size="lg">
          <CreateFee
            handleAddNewFee={handleAddNewFee}
            selectedFeeType={[]}
            onClose={onCloseModal}
            defaultSelected={true}
            entity={
              contextObj && contextObj.entityDetails.owner.a_guid
                ? contextObj.entityDetails.owner.a_guid
                : selectedRow[0].trans_owner
            }
          ></CreateFee>
        </Modal>
        <Modal
          show={openDisburse}
          onHide={(e) => {
            onClose();
          }}
          size="xl"
        >
          {(transactionApi.loading === true ||
            disburseOnLoadDataApi.loading === true) && <LoadingSpinner />}
          <Modal.Header closeButton>
            <h4 className="modal-title">Create New Transaction</h4>
          </Modal.Header>

          <Modal.Body>
            {/*------------------------ Payment Row ---------------------------*/}
            <>
              {/* {Object.keys(defaultFee).length !== 0 &&
                (defaultFee.advance_rate == null ||
                  defaultFee.advance_rate == "") && (
                  <div className="alert alert-warning alert-dismissible fade show">
                    <strong>
                      {" "}
                      You have not configured 'Advance %' on settings'.
                    </strong>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )} */}
              <div className="row dyn-height">
                <div className="col-lg-12">
                  <table class="table table-sm text-secondary">
                    <tbody>
                      <tr>
                        <th>Debtor</th>
                        <th>Inv No</th>
                        <th>Inv Date</th>
                        <th>Inv Due Date</th>
                        <th>Inv Amount</th>
                        <th>Agreement Amt</th>
                        <th>Advance %</th>
                        <th>Advance Amount</th>
                        <th>Action</th>
                      </tr>
                      {paymentInfo.map((item, index) => (
                        <tr key={index}>
                          <td>{item.debtor_organisation}</td>
                          <td>{item.inv_number}</td>
                          <td>
                            {moment(item.inv_date, "YYYY/MM/DD").format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td>
                            {moment(item.inv_due_date, "YYYY/MM/DD").format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td>
                            <CurrencyLabel
                              value={item.inv_amount}
                            ></CurrencyLabel>
                          </td>
                          <td>
                            <CurrencyLabel
                              value={item.inv_chargeable_amt}
                            ></CurrencyLabel>
                          </td>
                          <td>
                            <TextInput
                              name="adv_percent"
                              label="Adv %"
                              value={item.inv_adv_percent}
                              onChange={(e) => {
                                handlePercentEdit(item, index, e.target.value);
                              }}
                              type="normal"
                            ></TextInput>
                          </td>

                          <td>
                            <CurrencyText
                              name="adv_amount"
                              label="Adv Amount"
                              value={item.inv_adv_amount}
                              onChangeEvent={(
                                event,
                                maskedvalue,
                                floatvalue
                              ) => {
                                handleAmtEdit(item, index, floatvalue);
                              }}
                              onBlur={(e) => {
                                let amount =  e.target.value;
                                amount = amount !== "" ? parseFloat(amount.slice(1).replace(/,/g, "")) : 0;
                                handleAmtEdit(item, index, amount, true);
                              }}
                              type="normal"
                              maxValue={item.inv_chargeable_amt}
                            ></CurrencyText>
                          </td>
                          <td>
                            <Link className="action-icons pull-left">
                              <RemoveButton
                                title={"Remove invoice from transaction"}
                                onClick={(e) => removeHandler(item, index)}
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                      {paymentInfo.length > 0 && (
                        <tr>
                          <td className="text-info" colSpan="2 "></td>

                          <td>
                            <strong>
                              <CurrencyLabel
                                value={_.sumBy(paymentInfo, (item) =>
                                  Number(item.inv_chargeable_amt)
                                )}
                              ></CurrencyLabel>
                            </strong>
                          </td>
                          <td className="text-info"></td>
                          <td>
                            <strong>
                              <CurrencyLabel
                                value={_.sumBy(
                                  paymentInfo,
                                  (item) =>
                                    (Number(item.inv_chargeable_amt) *
                                      (item.inv_adv_percent
                                        ? item.inv_adv_percent
                                        : 0)) /
                                    100
                                )}
                              ></CurrencyLabel>
                            </strong>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            {/*------------------------ End of Payment Row ---------------------------*/}

            {/*------------------------ Disbursement Row ---------------------------*/}
            <form onSubmit={handleSubmit} className="form-horizontal">
              <div className="row">
                <div className="col-lg-12 text-center m-2">
                  <div class="alert alert-info">
                    <div className="row">
                      <div className="col-lg-3">

                        <label className="text-secondary font-weight-bold">DRAFT</label>
                      </div>
                      <div className="col-lg-9">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            onClick={(e) =>
                              setValues({
                                ...values,
                                disbursed: true,
                                disburse_now: false,
                                disburse_later: false,
                                disb_later_date: null
                              })
                            }
                            checked={values.disbursed}
                            type="radio"
                            id="customRadioInline3"
                            class="custom-control-input"
                          ></input>
                          <label
                            class="custom-control-label"
                            for="customRadioInline3"
                          >
                            Advanced
                          </label>
                        </div>

                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            onClick={(e) =>{
                              setValues({
                                ...values,
                                disburse_now: true,
                                disbursed: false,
                                disburse_later: false,
                                disb_later_date: DateParser(moment(new Date()))
                              })
                            }
                            }
                            checked={values.disburse_now}
                            type="radio"
                            id="customRadioInline1"
                            class="custom-control-input"
                          ></input>
                          <label
                            class="custom-control-label"
                            for="customRadioInline1"
                          >
                            Advance Now
                          </label>
                        </div>

                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            onClick={(e) =>
                              setValues({
                                ...values,
                                disburse_later: true,
                                disburse_now: false,
                                disbursed: false,
                                disb_later_date: null
                              })
                            }
                            checked={values.disburse_later}
                            type="radio"
                            id="customRadioInline2"
                            class="custom-control-input"
                          ></input>
                          <label
                            class="custom-control-label"
                            for="customRadioInline2"
                          >
                            Schedule Advance
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div>
            </div> */}
              {/*-------------------------------------------------------------------------------*/}
              <div className="col-lg-12 m-t-sm">
                <div class="p-4">
                  <FormGroup
                    label="Client Reference Num"
                    type="horizontal"
                    input={
                      <TextInput
                        name="client_reference"
                        label="Ref No"
                        value={values.client_reference}
                        onChange={(e) => {
                          setRefNum(e.target.value);
                          setValues({
                            ...values,
                            client_reference: e.target.value,
                          });
                        }}
                        type="normal"
                      ></TextInput>
                    }
                  />
                  {/* <FormGroup
                    type="horizontal"
                    label="Fee"
                    input={
                      <Picker
                        items={feeList}
                        selectedItem={values.fee}
                        onSelectItem={(option) =>
                          setValues({
                            ...values,
                            fee: { label: option.label, value: option.value },
                          })
                        }
                      />
                    }
                  /> */}

                  <div className="form-group row">
                    <label className="col-lg-4">Discount Fee</label>

                    <div className="col-lg-6">
                      <FeePicker
                        items={feeList}
                        selectedItem={values.fee}
                        onSelectItem={(option) =>
                          setValues({
                            ...values,
                            fee: {
                              label: option.label,
                              value: option.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-2">
                      <button
                        type="button"
                        title="Add a new default fee"
                        class="btn btn-outline-secondary btn-xs"
                        onClick={(e) => {
                          setModal(true);
                        }}
                      >
                        Add New
                      </button>
                    </div>
                  </div>

                  {values.disbursed === true ? (
                    <>
                      <div className="form-group row">
                        <label className="col-lg-4">Advance Date</label>

                        <div className="col-lg-4">
                          <DateTimePicker
                            name="disb_date"
                            type="normal"
                            placeholder="Advance Date"
                            onChange={(value) => {
                              const ParseDate = (
                                value,
                                dateFormat = "YYYY-MM-DD"
                              ) => {
                                return moment(value)
                                  .format(dateFormat)
                                  .toString();
                              };
                              setValues({
                                ...values,
                                disb_date: ParseDate(value),
                              });
                            }}
                            selected={Date.parse(values.disb_date)}
                            minDate={new Date(Date.parse("01/01/1500"))}
                            maxDate={new Date()}
                          />
                        </div>
                        <div className="col-lg-4">
                          <span className="text-secondary p-2 ">
                            Advance Date can be no later than today
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/*-----------------------------------------------------------------------*/}

                      <div className="form-group row">
                        <label className="col-lg-4">
                          Schedule Advance Date
                        </label>

                        <div className="col-lg-4">
                          <DateTimePicker
                            name="disb_later_date"
                            type="normal"
                            placeholder="Advance Date"
                            selected={Date.parse(values.disb_later_date)}
                            onChange={(value) => {
                              const ParseDate = (
                                value,
                                dateFormat = "YYYY-MM-DD"
                              ) => {
                                return moment(value)
                                  .format(dateFormat)
                                  .toString();
                              };
                              setValues({
                                ...values,
                                disb_later_date: ParseDate(value),
                              });
                            }}
                            minDate={new Date()}
                            maxDate={
                              values.disburse_now === true
                                ? new Date()
                                : new Date(Date.parse("01/01/5000"))
                            }
                          />
                        </div>
                        <div className="col-lg-4">
                          <span className="text-secondary p-2  ">
                            {values.disburse_now === true
                              ? "Advance Date set to today"
                              : "Advance Date can be no earlier than today"}
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="form-group row">
                    <label className="col-lg-4">Advance Amount</label>

                    <div className="col-lg-4">
                      <CurrencyText
                        value={values.disb_amount}
                        onChangeEvent={(event, maskedvalue, floatvalue) => {
                          setValues({
                            ...values,
                            disb_amount: floatvalue,
                          });
                        }}
                        placeholder="Disbursement Amt"
                        isDisabled={true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <span className="text-secondary p-2  ">
                        {parseFloat(totalPercent).toFixed(2)}% of the total agreement amount
                      </span>
                    </div>
                  </div>
                </div>

              </div>

                <div className="row m-t-sm">
                  <div className="col-lg-12 ">
                    {paymentInfo.length > 0 &&
                      <GeneralButton
                        disabled={isSubmited ? true : false}
                        type="submit"
                        faIcon="fa fa-save"
                        className="btn btn-primary pull-right m-l-xs"
                        name="Save"
                      ></GeneralButton>
                    }
                  </div>
                </div>
            </form>
            {/*------------------------ End Disbursement Row ---------------------------*/}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default DisburseSingleInvoices;
