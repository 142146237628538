import React , {useState , useEffect}from "react"
import useApi from "../../hooks/useApi";
import accountServices from "../../components/supply-chain/services/commonService";
export default function DispayTemplates(props){
    const [viewTemplateData, setViewTemplateData] = useState(null);
    const viewData = useApi(accountServices.viewData);
    const getViewData = async () => {
            const responseView  = await viewData.request({filename: props.data}); // Sending Async request to server
            console.log(responseView.data.data);
            if(responseView.data){
                setViewTemplateData(responseView.data.data);
            }
        }
    useEffect(() => {
        getViewData()
    }, [])

    return(
        <>
        <div
        dangerouslySetInnerHTML={{__html: viewTemplateData}}
        />
        </>
    )
       
    
}