import React, { useEffect, useState } from "react";
import entityService from "../../../../services/entityService";
import LoadingSpinner from '../../../common/loaders/loadingSpinner';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router";
import ReadyToUpload from "./_readyToUpload";
import { confirmAlert } from "react-confirm-alert";
import useApi from '../../../../hooks/useApi';
import { toast } from "react-toastify";
import EditAccount from "./_editAccount";
import {
    EditButton,
    DeleteButton
} from "../../../common/inputs";
import _ from "lodash";
import { useUploader } from "../../../../context/secAccountsUploaderContext";

function ValidateAccounts() {

    const { accounts, setUploading, setProceedNext, setnextLabel, duplicateAccountInSheet, uniqueAccounts, handleUploadItems, deleteAccount,setReadyToUpload } = useUploader(); // Instance for Context API
    const [validationResults, setValidationResults] = useState(null);
    const [readyToUploadModal, setReadyToUploadModal] = useState(false);
    const [alreadyInDbModal, setAlreadyInDbModal] = useState(false);
    const [prspectsListModal, setPrspectsListModal] = useState(false);
    const [missingMandatoryModal, setMissingMandatoryModal] = useState(false);
    const [sheetDuplicateModal, setSheetDuplicateModal] = useState(false);

    const [activeModal, setActiveModal] = useState(null);
    const [editModal, setEditModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    setnextLabel("Upload");
    //------------Custom hooks for handling API Requests--------------
    const validationApi = useApi(entityService.validateSecondaryAccounts);

    //------------------Async function to validate the account------------------------
    const validateAccounts = async () => {
        let accountData = [];
        let readyToUploadArray = [];
        const response = await validationApi.request({ accounts: uniqueAccounts });
        setValidationResults(response.data);
        setUploading(response.data.remainingItems);
        
        response.data.readyToUpload && response.data.readyToUpload.length > 0 &&
        response.data.readyToUpload.map(itm => {
            accountData = accounts.filter(acnt => acnt.Email == itm.email);
            readyToUploadArray.push(accountData[0]);
        });
        setReadyToUpload(readyToUploadArray);

    }

    // W.r.t changes in any of the Records
    useEffect(() => {
        validateAccounts();
    }, [accounts]);

    // W.r.t changes in validation results
    useEffect(() => {
        if ((validationResults != null && (validationResults.remainingItems === 0)))
            setProceedNext(false);
        else
            setProceedNext(true);
    }, [validationResults]);

    // W.r.t changes in API requests
    useEffect(() => {
        if (validationApi.loading === true) { // If request to server initiated.
            setActiveModal(
                alreadyInDbModal ? 'dbDuplicate' :
                    missingMandatoryModal ? 'missing' :
                        sheetDuplicateModal ? 'sheetDuplicate' : null
            );
            setAlreadyInDbModal(false);
            setMissingMandatoryModal(false);
            setSheetDuplicateModal(false);
            setProceedNext(false);
        } else { // If request has been resolved.
            setAlreadyInDbModal((activeModal === 'dbDuplicate' && validationResults.duplicateAccounts.length > 0) ? true : false);
            setMissingMandatoryModal((activeModal === 'missing' && validationResults.length > 0) ? true : false);
            setSheetDuplicateModal((activeModal === 'sheetDuplicate' && duplicateAccountInSheet.length > 0) ? true : false);
        }
    }, [validationApi.loading])

    // Delete selected accounts from the list
    const handleAccountDeletion = index => {

        const openModal = alreadyInDbModal ? 'alreadyInDbModal' :
            missingMandatoryModal ? 'missingMandatoryModal' : 'sheetDuplicateModal'

        setAlreadyInDbModal(false);
        setMissingMandatoryModal(false);
        setSheetDuplicateModal(false);

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Are you sure ...?</h4>
                        <p>Delete Account</p>
                        <button
                            className="btn btn-white"
                            onClick={() => {
                                if (openModal === 'alreadyInDbModal')
                                    setAlreadyInDbModal(true);
                                else if (openModal === 'missingMandatoryModal')
                                    setMissingMandatoryModal(true);
                                else if (openModal === 'sheetDuplicateModal')
                                    setSheetDuplicateModal(true);
                                onClose();
                            }}
                        >
                            No
                        </button>

                        <button
                            className="btn btn-primary ml-2"
                            onClick={async () => {
                                onClose();
                                deleteAccount(index);
                                if (openModal === 'alreadyInDbModal')
                                    setAlreadyInDbModal(true);
                                else if (openModal === 'missingMandatoryModal')
                                    setMissingMandatoryModal(true);
                                else if (openModal === 'sheetDuplicateModal')
                                    setSheetDuplicateModal(true);
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
        });
    };

    return (
        <>
            {validationApi.loading === true && <LoadingSpinner />}
            {validationResults != null && (
                <>
                    {/*----------------------------------------------------------------------------------------*/}
                    <div className="row font-bold">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 p-md">
                            <span className="font-bold">
                                <div className="alert alert-success mb-4" role="alert">Uploading Summary <br />
                                    <small>Click on the icon to view the list</small>
                                </div>
                                <table className="pendinglist table">
                                    <tr>
                                        <td className="no-top-border ">Total in the sheet</td>
                                        <td className="no-top-border"><strong>{uniqueAccounts.length + duplicateAccountInSheet.length} </strong></td>
                                        <td className="no-top-border"> <Link onClick={(e) => setPrspectsListModal(true)}><i className="fa fa-eye"></i></Link></td>
                                    </tr>
                                    <tr>
                                        <td className="">Duplicates found in the database</td>
                                        <td><strong>{validationResults.duplicateAccounts.length}</strong></td>
                                        <td className="no-top-border"> <Link onClick={(e) => setAlreadyInDbModal(true)}><i className="fa fa-eye"></i></Link></td>
                                    </tr>
                                    <tr>
                                        <td className="">Duplicates found in the sheet</td>
                                        <td><strong>{duplicateAccountInSheet.length}</strong></td>
                                        <td className="no-top-border"> <Link onClick={(e) => setSheetDuplicateModal(true)}><i className="fa fa-eye"></i></Link></td>
                                    </tr>
                                    <tr>
                                        <td className="">Missing mandatory fields</td>
                                        <td><strong>{validationResults.errors.length}</strong></td>
                                        <td className="no-top-border"> <Link onClick={(e) => setMissingMandatoryModal(true)}><i className="fa fa-eye"></i></Link></td>
                                    </tr>
                                    <tr>
                                        <td className="text-success"> Ready to upload accounts </td>
                                        <td>
                                            <Link
                                                onClick={(e) => setReadyToUploadModal(true)}>
                                                <strong>{validationResults.remainingItems}</strong>
                                            </Link>
                                        </td>
                                        <td className="no-top-border"> <Link onClick={(e) => setReadyToUploadModal(true)}><i className="fa fa-eye"></i></Link></td>
                                    </tr>
                                    {validationResults.remainingItems == 0 && (<><tr><td colSpan="3" className="align-center"><div className="alert alert-danger mb-4" role="alert">No valid account found from the sheet to upload!</div></td></tr></>)}
                                </table>
                            </span>
                        </div>
                        <div className="col-lg-2 p-md"  > <span className="font-bold">   </span> </div>

                    </div>
                    {/*==========================================Result Modals-======================================*/}
                    {/*------------------All account List--------------------*/}
                    <Modal
                        show={prspectsListModal}
                        onHide={(e) => setPrspectsListModal(false)}
                        closeOnOverlayClick={false}
                        size="lg"
                        className="animated fadeIn"
                    >
                        <Modal.Header closeButton>
                            <h4 className="modal-title">Total Accounts in the sheet</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="pendinglist table table-stripped">
                                {accounts.length != 0 ? (
                                    <>
                                        <thead>
                                            <tr>
                                                <th>ABN</th>
                                                <th>Organisation</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>

                                        {accounts.map(item => {
                                            return (
                                                <tr>
                                                    <td>{item.ABN}</td>
                                                    <td>{item.Organisation}</td>
                                                    <td >{item.First_Name} {item.Last_Name}</td>
                                                    <td >{item.Email}</td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                ) :
                                    <tr>
                                        <td colSpan={3} align='center'>
                                            No accounts in the list
                                        </td>
                                    </tr>
                                }
                            </table>
                        </Modal.Body>
                    </Modal>
                    {/*------------------Close Invoices Modal-------------------*/}
                    <Modal
                        show={readyToUploadModal}
                        onHide={(e) => setReadyToUploadModal(false)}
                        closeOnOverlayClick={false}
                        size="lg"
                        className="animated fadeIn"
                    >
                        <Modal.Body>
                            <ReadyToUpload
                                readyToUpload={validationResults !== null ? validationResults.readyToUpload : null}
                                onClose={(e) => setReadyToUploadModal(false)}
                            />
                        </Modal.Body>
                    </Modal>
                    {/*---------------------------------------------------------*/}
                    {/*------------------Accounts already in DB Modal-------------------*/}
                    <Modal
                        show={alreadyInDbModal}
                        onHide={(e) => setAlreadyInDbModal(false)}
                        closeOnOverlayClick={false}
                        size="lg"
                        className="animated fadeIn"//"animated bounceInRight"
                    >
                        <Modal.Header closeButton>
                            <h4 className="modal-title">Accounts already exists in database</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="pendinglist table table-stripped">
                                {validationResults.duplicateAccounts.length != 0 ? (
                                    <>
                                        <thead>
                                            <tr>
                                                <th>ABN</th>
                                                <th>Organisation</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Comments</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        {validationResults.duplicateAccounts.map(item => {
                                            return (
                                                <tr>
                                                    <td>{item.ABN}</td>
                                                    <td>{item.Organisation}</td>
                                                    <td>{item.First_Name} {item.Last_Name}</td>
                                                    <td>{item.Email}</td>
                                                    <td><span className="text-danger">{validationResults.updateList[item.index].error}</span></td>
                                                    <td>
                                                        <Link className="action-icons"
                                                            onClick={e => {
                                                                setSelectedRow(item);
                                                                setEditModal(true);
                                                            }}
                                                        >
                                                            <EditButton></EditButton>
                                                        </Link>&nbsp;
                                                        <Link className="action-icons" onClick={() => handleAccountDeletion(item.index)} >
                                                            <DeleteButton></DeleteButton>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                ) :
                                    <tr>
                                        <td colSpan={3} align='center' className="text-danger">
                                            No duplicate accounts found in database.
                                        </td>
                                    </tr>
                                }
                            </table>
                        </Modal.Body>
                    </Modal>

                    {/*------------------Accounts already in Sheet-------------------*/}
                    <Modal
                        show={sheetDuplicateModal}
                        onHide={(e) => setSheetDuplicateModal(false)}
                        closeOnOverlayClick={false}
                        size="lg"
                        className="animated fadeIn"
                    >
                        <Modal.Header closeButton>
                            <h4 className="modal-title">Accounts duplicate in sheet</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="pendinglist table table-stripped">
                                {duplicateAccountInSheet.length != 0 ? (
                                    <>
                                        <thead>
                                            <tr>
                                                <th>ABN</th>
                                                <th>Organisation</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        {duplicateAccountInSheet.map(item => {
                                            return (
                                                <tr>
                                                    <td>{item.ABN}</td>
                                                    <td>{item.Organisation}</td>
                                                    <td>{item.First_Name} {item.Last_Name}</td>
                                                    <td >{item.Email}</td>
                                                    <td>
                                                        <Link className="action-icons" onClick={() => handleAccountDeletion(item.index)} >
                                                            <DeleteButton></DeleteButton>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                ) :
                                    <tr>
                                        <td colSpan={3} align='center' className="text-danger">
                                            No duplicate accounts found in database.
                                        </td>
                                    </tr>
                                }
                            </table>
                        </Modal.Body>
                    </Modal>
                    {/*------------------accounts Missing Mandatory-------------------*/}
                    <Modal
                        show={missingMandatoryModal}
                        onHide={(e) => setMissingMandatoryModal(false)}
                        closeOnOverlayClick={false}
                        size="lg"
                        className="animated fadeIn"
                    >
                        <Modal.Header closeButton>
                            <h4 className="modal-title">accounts missing mandatory fields</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="pendinglist table table-stripped">
                                {validationResults.errors.length != 0 ? (
                                    <>
                                        <thead>
                                            <tr>
                                                <th>Organisation</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>ABN</th>
                                                <th>Error</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        {validationResults.errors.map(item => {
                                            return (
                                                <tr>
                                                     <td>{item.Organisation}</td>
                                                    <td>{item.First_Name} {item.Last_Name}</td>
                                                    <td>{item.Email}</td>
                                                    <td>{item.ABN}</td>
                                                    <td className="text-danger">{item.message}</td>
                                                    <td>
                                                        <Link className="action-icons"
                                                            onClick={e => {
                                                                setSelectedRow(item);
                                                                setEditModal(true);
                                                            }}
                                                        >
                                                            <EditButton></EditButton>
                                                        </Link>&nbsp;
                                                        <Link className="action-icons" onClick={() => handleAccountDeletion(item.index)} >
                                                            <DeleteButton></DeleteButton>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                ) : <tr>
                                    <td colSpan={3} align='center'>
                                        No missing mandatory fields.
                                    </td>
                                </tr>
                                }
                            </table>
                        </Modal.Body>
                    </Modal>

                    {/*------------ Edit Accounts Modal---------*/}
                    <Modal
                        show={editModal}
                        onHide={() => setEditModal(false)}
                        size="lg"
                    >
                        <EditAccount
                            data={selectedRow}
                            onClose={() => {
                                setEditModal(false);
                            }}
                        />
                    </Modal>
                    {/*-----------------------------------------*/}
                </>
            )}
        </>
    )
}

export default ValidateAccounts;