import * as moment from "moment";

export const getDateDiff = (lastLogin) => {
    if (lastLogin !== null) {
        var given = moment(lastLogin, "YYYY-MM-DD");
        var current = moment().startOf('day');
        //Difference in number of days
        lastLogin = moment.duration(given.diff(current)).asDays();
        if (lastLogin)
            lastLogin *= -1;
        return parseInt(lastLogin);
    } else
        return null;
};