import React from "react";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
//import { BreadCrumbs } from "../../../common/layout";
import MultiStepForm from "../../../common/wizard/MultiStepForm";
import { ContextProvider } from "../../../../context/secAccountsUploaderContext";
import Upload from "./upload";
import ListAccounts from "./listAccounts";
import Validation from "./validation";
import Finish from "./finish";

export default function SecAccountUploader ({ selectedProduct = null, contextValues = null, onClose = null }){

    // Array object to include the list of components you want to pass into the multi-step form module.
const componentsList = [
    { headerText: 'Upload Sheet', headerIcon: 'fa fa-upload', component: <Upload contextValues={contextValues} selectedProduct={selectedProduct} /> },
    { headerText: 'View Uploads', headerIcon: 'fa fa-list', component: <ListAccounts /> },
    { headerText: 'Validation', headerIcon: 'fa fa-check-square', component: <Validation /> },
    { headerText: 'Finish', headerIcon: 'fa fa-flag-checkered', component: 
        <Finish 
            client={contextValues.a_guid}
            source = {"network" } 
            onClose={onClose}
            contextValues={contextValues}
            selectedProduct={selectedProduct}
        />
    },
];

    return(
        <>
            <Helmet>
                <title>Awaiting mapping Bulk Upload | {global.config.title}</title>
            </Helmet>

            <ContextProvider>
                <MultiStepForm
                    boxTitle="Upload Secondary Accounts"
                    list={componentsList}
                    displayProgressBar={true} // Set this to false if you don't want to display the progress bar.
                    hideSkipBtn={true}
                    type='accountsUploader'
                    handleCancel={e => {
                        onClose();
                    }}
                />
            </ContextProvider>
        </>
    )

}