import React, { useState, useEffect, useImperativeHandle } from "react";
import { ModalContainer } from "../containers/formContainer";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { NavigationButtons } from "./NavigationButtons";
import UploadIcon from "../../../img/upload-success.png";

const MultiStepModal = React.forwardRef(
  (
    {
      list,
      displayProgressBar,
      headerTitle = "",
      type = "",
      proceedNext = true,
      hideNextBtn = false,
      hidePreviousBtn = false,
      nextLabel = "Next",
      prevLabel = "Back",
      navigate = null,
      handleCancel = null,
      customIndex = null,
      handleNext = null,
      showCard = true,
      epProgram = null,
      data = null,
      handleChange = null,
      handleNextChange = null,
      sProgram = false,
      setSprogram = null,
      programId = null,
      secStepSelect1 = null,
      showSubmit = false,
      viewShow = false,
      model,
      setModel,
      ...restProps
    },
    ref
  ) => {
    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const isEpProgrammeRoute = location.pathname === "/client/ep-programme";
    const isEpProgrammeSecRoute =
      location.pathname === "/client/ep-programme/add-new";
    const [step, setStep] = useState(() => {
      const storedStep = localStorage.getItem("step");
      return storedStep ? JSON.parse(storedStep) : 0;
    });
    const history = useHistory();

    const resetQueryStep = () => {
      setStep(0);
      localStorage.setItem("step", "0");
    };

    const goPrevious = () => {
      if (selectedIndex != 0 || step != 0) {
        const newStep = step - 1;
        console.log("newStep", newStep);
        if (newStep === 0 && sProgram === true) {
          setSprogram(false);
        }
        setSelectedIndex(selectedIndex - 1);
        localStorage.setItem("step", JSON.stringify(newStep));
        setStep(newStep);
      }
    };

    const handleSelectIndex = (selectedIndex) => {
      setSelectedIndex(selectedIndex);
      localStorage.setItem("step", JSON.stringify(selectedIndex));
      setStep(selectedIndex);
    };

    useEffect(() => {
      if (navigate == "Next") {
        if (selectedIndex != list.length - 1)
          setSelectedIndex(selectedIndex + 1);
        localStorage.setItem("step", JSON.stringify(parseInt(step) + 1));
        setStep(parseInt(step) + 1);
      } else if (navigate == "Previous") goPrevious();
    }, [navigate]);

    // useEffect(() => {
    //   if (restProps.displaySteps) {
    //     let pathname = restProps.pathname.includes("step")
    //       ? restProps.pathname.substr(0, restProps.pathname.lastIndexOf("/"))
    //       : restProps.pathname;
    //     if (epProgram === "edit" || epProgram === "view") {
    //       history.push(
    //         `${pathname}/step${step + 1}?p_guid=${programId}&step=${step + 1}`
    //       );
    //     } else {
    //       history.push(`${pathname}/step${selectedIndex + 1}`);
    //     }
    //   }
    // }, [selectedIndex, step]);

    const goNext = (validate = true) => {
      console.log("sProgram",sProgram)
      if (!validate) {
        const newStep = step + 1;
        setSelectedIndex(selectedIndex + 1);
        localStorage.setItem("step", JSON.stringify(newStep));
        setStep(newStep);
      } else {
        if (sProgram === true && epProgram !== "view") {
          handleNextChange();
        } else {
          var btnSubmit = document.getElementById("btn-submit");

          if (btnSubmit) document.getElementById("btn-submit").click();
          else if (selectedIndex != list.length - 1) {
            setSelectedIndex(selectedIndex + 1);
            localStorage.setItem("step", JSON.stringify(step + 1));
            setStep(parseInt(step) + 1);
          }
        }
      }
    };

    useEffect(() => {
      if (isEpProgrammeRoute || isEpProgrammeSecRoute) {
        resetQueryStep();
      }
    }, [isEpProgrammeRoute, isEpProgrammeSecRoute]);

    useEffect(() => {
      localStorage.setItem("step", JSON.stringify(step));
    }, [step]);

    useImperativeHandle(ref, () => ({
      next: goNext,
      previous: goPrevious,
    }));
    return (
      <>
        <Modal
          className="multiModel"
          show={model}
          //onHide={() => setModel(false)}
          size="xl"
        >
          {list[selectedIndex].headerTitle === "Success" ? (
            <Modal.Header className="successHeader">
              {showSubmit ? (
                <h4 className="card-title successTitle">
                  <img
                    className="img-circle mr-2"
                    src={UploadIcon}
                    width="50px"
                    height="50px"
                  />
                  {list[selectedIndex].headerTitle}{" "}
                </h4>
              ) : null}
            </Modal.Header>
          ) : (
            <Modal.Header>
              <h4 className="card-title">{list[selectedIndex].headerTitle} </h4>
              <h4 className="card-title">
                {`Step ${selectedIndex + 1} of ${list.length}`}
              </h4>
            </Modal.Header>
          )}

          <Modal.Body>
            <div className="col-lg-12">{list[selectedIndex].component}</div>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "right" }}>
            {/** ---------------- Navigation buttons ---------------- */}
            <div className="row mt-3">
              <div className="col-lg-12 text-right">
                {selectedIndex == customIndex && type == "" && (
                  <button
                    style={{ float: "left" }}
                    onClick={goNext}
                    className="btn btn-outline"
                  >
                    Skip
                  </button>
                )}
                {handleCancel !== null && selectedIndex != list.length - 1 && (
                  <button
                    onClick={() => {
                      resetQueryStep();
                      handleCancel();
                    }}
                    class="btn btn-light mr-2"
                  >
                    Cancel
                  </button>
                )}
                {viewShow && selectedIndex == list.length - 1&& (
                  <button
                    onClick={() => {
                      setModel(false);
                    }}
                    class="btn btn-light mr-2"
                  >
                    Close
                  </button>
                )}

                <NavigationButtons
                  goNext={goNext}
                  goPrevious={goPrevious}
                  // selectedIndex={
                  //   epProgram === "edit" || epProgram === "view"
                  //     ? step
                  //     : selectedIndex
                  // }
                  selectedIndex={selectedIndex}
                  list={list}
                  proceedNext={proceedNext}
                  hideNextBtn={hideNextBtn}
                  hidePreviousBtn={hidePreviousBtn}
                  nextLabel={
                    list[selectedIndex].headerTitle === "Invite your suppliers"
                      ? "Save"
                      : nextLabel
                  }
                  prevLabel={prevLabel}
                  handleNext={handleNext ? handleNext : null}
                />
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
);
export default MultiStepModal;
