import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import {
  getCustomersList,
  getSupplierList,
  getRatesList,
  getMappedCustomersList,
  getMappedSupplierList,
} from "../services/userType";
import auth from "../../../services/authService";
import { Picker, GeneralButton } from "../../common/inputs";
import NewTrader from "../../entities/network/newTrader";

function useUserType({
  onSelectItem,
  type,
  selectedValue = { label: "Select", value: null },
  userId = "",
  InputOption = "",
  isDisabled = false,
  productType = "",
  getData = null,
  owner = null,
  mapSettings = false,
  showAll = false,
  addNew = false,
  getUsersList = null
}) {
  const [users, setUsers] = useState([]);
  const [selectedItem, setSelectedItem] = useState(selectedValue);
  const { category, constants, country_id } = auth.getCurrentUser();
  const [openSecondary, setopenSecondary] = useState(false);

  const getCustomers = async () => {
    const response = await getCustomersList(country_id);
    setUsers(response);
  };
  const getMappedCustomers = async () => {
    if(productType !== ""){
      const response = await getMappedCustomersList(
        country_id,
        "Customer",
        userId,
        productType,
        owner,
        mapSettings
      );
      setUsers(response);
    }
  };
  const getSuppliers = async () => {
    const response = await getSupplierList(country_id);
    const { items } = response;
    setUsers(items);
  };

  const getMappedSuppliers = async () => {
    if(productType !== ""){
      let items = await getMappedSupplierList(
        country_id,
        "Supplier",
        userId === "all" ? null : userId,
        productType,
        owner
      );

      let emptyobj = [{ label: 'All', value: null }]
      if(items){
        if(showAll && items.length > 0)
          items = emptyobj.concat(items);
        setUsers(items);
      }
      if(getUsersList)
        getUsersList(items);
    }
  };

  const getRates = async () => {
    const response = await getRatesList(country_id);
    setUsers(response);
  };

  useEffect(() => {
    if(users !== undefined){
      if (users.length == 0) {
        // Equivalent to componentdidmount when the square brackets are given
        if (type == "customer") {
          getCustomers();
        }
        if (type == "supplier") {
          getSuppliers();
        }
        if (type == "rates") {
          getRates();
        }
        if (type == "mappedsupplier") {
          getMappedSuppliers();
        }
        if (type == "mappedcustomer") {
          getMappedCustomers();
        }
      }
    
      if (InputOption !== null && selectedItem.value === null && users.length > 0) {
        const filteredData = users.find(obj => obj.value == InputOption.value);
        if (filteredData)
          setSelectedItem({ label: filteredData.label, value: filteredData.value })
      }
    }
    
    if (getData)
      getData(users);

  }, [InputOption, users]);

  useEffect(() => {
    if(owner !==null && type == "mappedcustomer" && users.length > 0) getMappedCustomers();
    if(owner !==null && type == "mappedsupplier" && users.length > 0) getMappedSuppliers();
  },[owner]);

  useEffect(() => {
    setUsers([]);
    if(userId !== "" && type == "mappedsupplier" && users.length > 0 && productType !== "") 
      getMappedSuppliers();
  },[userId]);

  useEffect(() => {
    if(showAll)
      setSelectedItem({ label: 'Select Supplier (Secondary Account)', value: null })
  },[users]);

  const onCloseSecondaryModal = () => {
    setopenSecondary(false);
  };

  const handleSecondaryModal = () => {
    setopenSecondary(true);
  };

  
  if (type == "customer" || type == "supplier" || type == "rates") {
    return (
      <>
        <Picker
          items={users}
          onSelectItem={(selectedOption) => {
            onSelectItem(selectedOption);
            setSelectedItem(selectedOption);

          }}
          selectedItem={selectedItem}
        />
      </>
    );
  } else if (type == "mappedsupplier" || type == "mappedcustomer") {

    return (
      <>

        {/*------------------Add New DebtorMmodal-------------------*/}
        <Modal
          show={openSecondary}
          onHide={onCloseSecondaryModal}
          closeOnOverlayClick={false}
          centered
          className="animated bounceInRight"
          backdrop="static"
        >
          <NewTrader
            entity_id={userId}
            role={constants.BUSINESS_TYPE.SALE}
            title={productType === constants.PRODUCTS.SUPPLYCHAIN ? constants.ROLES.SUPPLIER : constants.ROLES.TFSUPPLIER}
            source="network"
            onCloseModal={() => {
              onCloseSecondaryModal();
              //addNew && setRefresh(true);
            }}
            handleSelectedItem={option => {
              if(userId !== "" && type == "mappedsupplier" && users.length > 0 && productType !== "") 
                getMappedSuppliers();
              onSelectItem({ label: option.label, value: option.value });
              setSelectedItem({ label: option.label, value: option.value });
            }}
          ></NewTrader>
        </Modal>
        {/*----------------------------------------------------------*/}


        {addNew ? (
          <div className="row mb-4">
            <div className="col-lg-10">
              <Picker
                items={users}
                isDisabled={isDisabled}
                onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }} selectedItem={selectedItem}
              />
            </div>
            <div className="col-lg-2">

              <GeneralButton
                onClick={handleSecondaryModal}
                className="btn btn-outline-primary pull-right"
                title="Create a new new Debtor from here"
                name={<i className="fa fa-plus"></i>}
                type={"button"}
              />
            </div>

          </div>
        ) : (
          <Picker
            items={users}
            isDisabled={isDisabled}
            onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }} selectedItem={selectedItem}
          />
        )}

          

      </>
    );

  } else {
    return <></>;
  }
}

export default useUserType;
