import React, { useState, useContext, useEffect } from "react";
import Joi from "joi-browser";
import { FormGroup } from "../../common/containers/formContainer";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import {
    TextInput,
    GeneralButton,
    CurrencyText,
    Picker,
    DateTimePicker,
} from "../../common/inputs";
import MultipleFileInput from "../../common/inputs/multipleFileInput/multipleFileInput";
import { EntityDebtorList } from "../../slplusTools";
import { toast } from "react-toastify";
import EntityContext from "../../../context/entityContext";
import useApi from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import { Link } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import dfTransactionService from "../services/dfTransactions";
import authService from "../../../services/authService";

export default function AddSingleInvoice(props) {

    const { constants } = authService.getCurrentUser();
    const entityContext = useContext(EntityContext);
    let client = "";
    let selectedInvGuid ="";

    const [attachemntFile, setAttachemntFile] = useState({ data: [], document_name: "" });
    const [initialLoad, setInitialLoad] = useState(false);

    //------------Api Services--------------------------------------------
    const invUploadApi = useApi(dfTransactionService.singleInvUpload);
    const invDetailApi = useApi(dfTransactionService.GetInvoiceDetail);
    const removeAttachmentApi = useApi(dfTransactionService.RemoveAttachment);
    const downloadApi = useApi(dfTransactionService.DownloadAttachment);

    //------------------Validation Schema-----------------------------
    const schema = Joi.object() // Form validation schema object
        .keys({
            inv_number: Joi.string().required().label("Invoice Number"),
            inv_date: Joi.date()
                .required()
                .error(() => {
                    return {
                        message: "Invoice date cannot be blank.",
                    };
                }),
            inv_debtor_id: Joi.string().required().label("Debtor"),
            inv_amount: Joi.number().required().label("Invoice Value"),
            inv_due_date: Joi.date()
                .min(Joi.ref("inv_date"))
                .required()
                .label("Due Date")
                .error(() => {
                    return {
                        message: "Due date must be a date greater than Invoice date.",
                    };
                })
        })
        .unknown(true);


    // set client guid from entity context if available 
    if (entityContext && typeof entityContext.entityDetails !== "undefined") {
        client = entityContext.entityDetails.a_guid;
        selectedInvGuid = props.selectedinv && props.selectedinv.inv_guid ? props.selectedinv.inv_guid : "";
    } else {
        client = props.selectedinv && props.selectedinv.client_guid ? props.selectedinv.client_guid : null;
        selectedInvGuid = props.selectedinv && props.selectedinv.inv_guid ? props.selectedinv.inv_guid : "";
    }

    const handleInvoiceAmount = (floatvalue) => {
        setValues({
            ...values,
            inv_amount: floatvalue,
            inv_chargeable_amt: floatvalue,
            credit_notes: floatvalue < 0 ? 0 : 1,
        });
    }

    const addHandler = async () => {

        let invTrans = [];

        let fileslist = [];
        fileslist = await handleFileReader();

        invTrans = {
            trans_id: null,
            inv_amount: values.inv_amount,
            inv_chargeable_amt: values.inv_amount,
            inv_date: values.inv_date,
            inv_debtor_id: values.inv_debtor_id,
            inv_due_date: values.inv_due_date,
            inv_number: values.inv_number,
            trans_client_guid: client,
            trans_owner: null,
            files: fileslist
        };
        if (props.editTransaction == true && (props.selectedinv && props.selectedinv.inv_guid != "")) {
            invTrans.inv_guid = props.selectedinv.inv_guid;
        }

        setTimeout(async () => {
            const response = await invUploadApi.request({
                invoices: invTrans,
                bp: null,
                trans_client_guid: client,
                client_invoice_upload: props.client_invoice_upload
            });
            if (response) {
                if (response.data.success == true) {
                    toast.success(response.data.message);
                    props.onClose();
                    props.handleReload();
                } else {
                    toast.error(response.data.message)
                }
            } else {
                toast.error("Failed to upload invoice.")
            }
        }, 100);

    }

    const handleFileChange = (fileItems) => {
        if (fileItems[0]) {
            const files = { ...attachemntFile };
            files['document_name'] = fileItems[0].file.name;
            files['data'] = fileItems.map((fileItem) => fileItem.file);
            setAttachemntFile(files);
        }
    };

    const handleFileReader = async () => {
        /** --------------- Reading local files in JavaScript -------------- */
        let file = attachemntFile.data;
        let fileslist = [];
        if (file.length < 0) {
            return fileslist
        }
        file.map((fileItem) => {
            let reader = new FileReader();
            reader.readAsDataURL(fileItem);

            reader.onload = (e) => {
                fileslist.push({
                    name: fileItem.name,
                    file: e.target.result,
                    type: fileItem.type,
                    size: fileItem.size,
                });
            };
        });
        return fileslist;
        /** ----------------------------------------------------------------- */
    };

    const fetchInvoiceDetails = async () => {
        const response = await invDetailApi.request({
            invGuid: selectedInvGuid
        });
        if (response) {
            if (response.data.success) {
                setValues({
                    inv_number: response.data.invoiceDetail.inv_number,
                    inv_date: response.data.invoiceDetail.inv_date,
                    inv_debtor_id: response.data.invoiceDetail.inv_debtor_id,
                    inv_due_date: response.data.invoiceDetail.inv_due_date,
                    inv_amount: response.data.invoiceDetail.inv_amount,
                    attachmentFile: response.data.invoiceDetail.inv_file_name
                });
            }
        } else {
            toast.error("Failed to fetch details of the invoice.");
        }
    }

    const handleRemoveAttachment = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Are you sure ...?</h4>
                        <p>{`want to remove attachment?`}</p>
                        <button
                            className="btn btn-white"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => {
                                removeAttachment();
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "Confirmation",
            message: `Are you sure, want to remove attachment?`,
            overlayClassName: "zindex-2100"
        });
    }

    const removeAttachment = async () => {

        const { data } = await removeAttachmentApi.request({ inv_guid: selectedInvGuid, client_guid: client });
        if (data.success) {
            toast.success(data.message);
            setAttachemntFile({ data: [], document_name: "" });
            const val = { ...values };
            val['attachmentFile'] = "";
            setValues(val);
        } else {
            toast.error(data.message);
        }

    }

    const handleDownloadAttachment = async (filename) => {
        const { data } = await downloadApi.request({
            inv_guid: selectedInvGuid,
            client_guid: client
        });
        const link = document.createElement("a");
        link.href = data;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    }

    //------------------Custom hook to handle form data-----------------------------
    const {
        values,
        setValues,
        handleChange,
        handlePickerChange,
        handleSubmit,
    } = useForm(addHandler, schema); // Custom Hook to handle Form Validation

    //------------------------------------------------------------------------------

    useEffect(() => {
        if (props.editTransaction == true && initialLoad == false) {
            fetchInvoiceDetails();
            setInitialLoad(true);
        }
    }, []);

    return (
        <>
            {
                (
                    invUploadApi.loading === true ||
                    invDetailApi.loading == true ||
                    downloadApi.loading == true ||
                    removeAttachmentApi.loading == true
                ) && <LoadingSpinner />}

            <div className="">
                <form onSubmit={handleSubmit} className="form-horizontal">
                    <div className="row">
                        {/*-------------Offer to sell starts-------------------------------------*/}
                        <div className="col-lg-12">
                            <div class="">
                                <TextInput
                                    name="inv_number"
                                    label="Invoice No"
                                    placeholder="Invoice No"
                                    value={values.inv_number}
                                    onChange={handleChange}
                                    type="horizontal"
                                />

                                <FormGroup
                                    label="Invoice Date"
                                    type="horizontal"
                                    input={
                                        <DateTimePicker
                                            name="inv_date"
                                            label="Invoice Date"
                                            selectedDate={values.inv_date}
                                            onChange={(value) => handleChange(value)}
                                        />
                                    }
                                />
                                <FormGroup
                                    label="Debtor"
                                    type="horizontal"
                                    input={
                                        <EntityDebtorList
                                            selectedDebtor={values.inv_debtor_id}
                                            client={client}
                                            onSelectItem={(option) =>
                                                setValues({
                                                    ...values,
                                                    debtor_name: option.label,
                                                    inv_debtor_id: option.value,
                                                })
                                            }
                                            product={constants.PRODUCTS.DEBTORFINANACE}
                                            u
                                        />
                                    }
                                />
                                <FormGroup
                                    label="Invoice Value"
                                    type="horizontal"
                                    input={
                                        <CurrencyText
                                            value={values.inv_amount}
                                            onChangeEvent={(event, maskedvalue, floatvalue) => {
                                                handleInvoiceAmount(floatvalue);
                                            }}
                                            placeholder="Invoice Value"
                                        />
                                    }
                                />

                                <FormGroup
                                    label="Due Date"
                                    type="horizontal"
                                    input={
                                        <DateTimePicker
                                            name="inv_due_date"
                                            selectedDate={values.inv_due_date}
                                            onChange={(value) => handleChange(value)}
                                        />
                                    }
                                />

                                <FormGroup
                                    label="Invoice Files"
                                    type="vertical"
                                    input={
                                        <MultipleFileInput
                                            files={attachemntFile.data}
                                            allowMultiple={false}
                                            onupdatefiles={(fileItems) => {
                                                handleFileChange(fileItems);
                                            }}
                                        ></MultipleFileInput>
                                    }
                                />

                                {
                                    props.editTransaction == true && values.attachmentFile && values.attachmentFile !== "" && (
                                        <>

                                            <div className=" alert alert-info">
                                                <div className="row">
                                                    <div className="col-sm-8">{values.attachmentFile}</div>
                                                    <div className="col-sm-4">

                                                        <Link
                                                            title="Remove attachment"
                                                            className="pull-right cursor-link"
                                                            onClick={() => { handleRemoveAttachment() }}
                                                        ><i className="fa fa-trash text-danger"></i>
                                                        </Link>
                                                        <Link
                                                            title="Download attachment"
                                                            className="pull-right cursor-link mr-2"
                                                            onClick={() => { handleDownloadAttachment(values.attachmentFile) }}
                                                        ><i className="fa fa-download text-secondary"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    )
                                }

                            </div>
                        </div>
                        {/*-------------Offer to sell ends-------------------------------------*/}
                    </div>

                    <div className="row m-t-sm">
                        <div className="col-lg-12 ">
                            <GeneralButton
                                faIcon="fa fa-save"
                                className="btn btn-sm btn-primary pull-right m-l-xs"
                                name={props.editTransaction == true ? 'Update' : 'Save'}
                            ></GeneralButton>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}