import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import "jodit";
import "jodit/build/jodit.min.css";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import LoadingSpinner from '../common/loaders/loadingSpinner';

import entityService from "../../services/entityService";
import useApi from '../../hooks/useApi';

export default function InviteContact({ entityId, entityType, contactInfo, onClose, resend = false }) {

    const [invitationMsg, setInvitationMsg] = useState(null);

    // ------------------------- Api Services -------------------------
    const fetchApi = useApi(entityService.getClientInvitationMessage);
    const sendApi = useApi(entityService.sendClientInvitationMail);

    const fetchInvitationMsg = async () => {
        const response = await fetchApi.request(contactInfo.contact_id, entityId);
        setInvitationMsg(response.data.message);
    }

    const sendInvitationMsg = async () => {
        const response = await sendApi.request(contactInfo.email, invitationMsg, contactInfo.contact_id, entityType, resend);
        if (response.success === true) {
            toast.success(response.message);
            onClose();
        } else
            toast.error(response.message);
    }

    useEffect(() => {
        /*if (entityType === 'client')
            sendInvitationMsg();
        else*/
        fetchInvitationMsg();
    }, []);

    return (
        <>
            <div>

                <Modal.Header closeButton>
                    <h4 className="modal-title mb-4">{entityType === 'bp' ? 'Partner' : 'Client'} Invitation</h4>

                </Modal.Header>
                {/*---------- Display loading Spinner -----------*/}
                {(sendApi.loading === true || fetchApi.loading === true) ?
                    <div className="logo text-center mb-5">
                        <LoadingSpinner normal={true} />
                    </div> :
                    <Modal.Body>
                        <JoditEditor value={invitationMsg}
                            onChange={(value) => {
                                setInvitationMsg(value);
                            }}
                        />
                    </Modal.Body>
                }

            </div>

            <Modal.Footer>
                <div className="row text-right">

                    <button type="button" className="btn btn-light  mr-2" data-dismiss="modal" onClick={e => onClose()}>
                        Close
                    </button>

                    <button type="submit" className="btn btn-primary  ml-2" onClick={e => sendInvitationMsg()} >
                        Send Invitation
                    </button>

                </div>

            </Modal.Footer>

            <div style={{ width: 700, height: 20 }}></div>
        </>
    )
}