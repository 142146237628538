import React, { useState, useEffect, useRef } from "react";
import LoadingSpinner from "../../../../../common/loaders/loadingSpinner";
import { toast } from "react-toastify";
import {
  CurrencyLabel,
  TextInput,
  GeneralButton,
} from "../../../../../common/inputs";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import Utility from "../../../../../../../src/utils/DateParser";
import ReactTooltip from "react-tooltip";
const PartPayment = (props) => {
  const { ParseDateDMY } = Utility();
  const [totAmount, setTotAmount] = useState(0);
  const [subModal, setSubModal] = useState(false);
  const [invValue, setInvValue] = useState([]);
  const [focusedRow, setFocusedRow] = useState(null);
  const textInputRef = useRef(null);

  const handleBulkAmtPartlyPayment = ({ target }) => {
    if (target.checked == true) {
      const selectedRowId = target.value;
      setFocusedRow(selectedRowId);
      const rows = props.data.filter((row) => row.id === target.value);
      if (rows.length > 0) {
        props.setPayments((prevPayments) => [...prevPayments, ...rows]);
      }
    } else {
      props.setPayments((prevPayments) =>
        prevPayments.filter((row) => row.id !== target.value)
      );
    }
  };
  useEffect(() => {
    if (focusedRow !== null && textInputRef.current) {
      textInputRef.current.focus();
    }
  }, [focusedRow]);
  useEffect(() => {
    if (props.payments.length > 0) {
      const totalAmount = props.payments.reduce((sum, item) => {
        if (item.amount !== undefined && item.amount !== null) {
          return sum + Number(item.amount);
        } else {
          return sum;
        }
      }, 0);
      setTotAmount(totalAmount);
    }
  }, [props.payments]);

  const handleValueChange = (e, listValue) => {
    let id = e.target.name;
    let amount = e.target.value;

    const balance =
      (listValue.total_due || 0) -
      (listValue.amount_paid || 0) -
      (listValue.dd_requested || 0);

    if (!/^\d*\.?\d*$/.test(amount)) {
      toast.error("Please Enter a Valid Amount (only numbers allowed)");
      return;
    }

    if (e.target.value > balance) {
      toast.error("Payment exceeding pending amount");
      const updatedPayments = props.payments.filter((row) => row.id !== id);
      props.setPayments(updatedPayments);
    } else {
      if (e.target.value <= 0) {
        toast.error("Please Enter a Valid Amount");
        const updatedPayments = props.payments.filter((row) => row.id !== id);
        props.setPayments(updatedPayments);
      } else {
        const updatedPayments = props.payments.filter((row) => row.id !== id);

        let interest = 0;

        if (listValue.finance_cost_paying > 0) {
          interest =
            parseFloat(e.target.value) >= listValue.finance_cost_paying
              ? listValue.finance_cost_paying
              : parseFloat(e.target.value);
        }

        let principle = parseFloat(e.target.value) - interest;

        let payment = {
          interest: interest.toFixed(2),
          principle: principle.toFixed(2),
        };

        props.setPayments([
          ...updatedPayments,
          {
            id,
            amount,
            payment,
            dd_requested: listValue.dd_requested,
            inv_number: listValue.inv_number,
          },
        ]);
      }
    }
  };
  const confirmPartPayment = () => {
    props.setPartPayModal(false);
    confirmAlert({
      container: document.body,
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-delete-ui"
            style={{ zIndex: 1100, position: "relative" }}
          >
            <h4>Are you sure ...?</h4>
            <p>Do you want to continue with the payment request?</p>
            <button
              className="btn btn-white"
              onClick={() => {
                onClose();
                props.setPartPayModal(true);
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={async () => {
                onClose();
                props.handleSave();
                props.setPartPayModal(false);
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  };
  const handleSubModal = (id) => {
    setSubModal(true);
    const rows = props.data.filter((row) => row.id === id);
    setInvValue(rows);
  };
  console.log("setInvValue", invValue);
  return (
    <>
      <Modal
        show={props.partPayModal}
        onHide={() => {
          props.setPartPayModal(false);
        }}
        centered
        className="fullscreen-modal"
      >
        <Modal.Header closeButton>
          <h6>Payment pending invoices</h6>
        </Modal.Header>

        <Modal.Body>
          <div className="alert alert-secondary text-secondary">
            Select the invoice using the left-hand side checkbox, enter the
            payment amount in the right-side text box, and submit your request
            by clicking the Allocate Payment button located at the bottom right
            corner.
          </div>
          <div className="scrollable-container">
            {props.loader && <LoadingSpinner />}
            {props.modalLoader && <LoadingSpinner />}
            <div className="table-responsive">
              <table class="table text-secondary">
                <tbody>
                  <tr className="stickyRow bg-light">
                    <th style={{ width: "10%" }}>
                      Part Pay &nbsp;
                      <i
                        className="fa fa-info-circle  text-secondary mr-1"
                        data-for="pay-info"
                        data-tip="Select the invoice you would like to make payment request"
                      ></i>
                    </th>
                    <th>Supplier Name</th>
                    <th>Invoice Number</th>
                    <th>Invoice Amount</th>
                    <th>Total Pending</th>

                    <th style={{ width: 160 }}>
                      <i
                        className="fa fa-info-circle  text-secondary mr-1"
                        data-for="pay-info"
                        data-tip="Select the invoice and enter the amount you wish to pay"
                      ></i>
                      <ReactTooltip
                        place="top"
                        id="pay-info"
                        backgroundColor="#11698e"
                      />
                      Choose To Pay
                    </th>
                  </tr>
                  {props.data.length > 0 ? (
                    props.data.map(
                      (listValue, index) =>
                        listValue.total_amount_pending !== 0 && (
                          <tr key={index}>
                            <td style={{ width: "10%" }}>
                              {listValue.amount_client_req == null &&
                              listValue.total_amount_pending !== 0 ? (
                                <div className="custom-control d-flex custom-checkbox small">
                                  <input
                                    id={"partPayCheck" + listValue.id}
                                    className="custom-control-input"
                                    type="checkbox"
                                    checked={props.payments.some(
                                      (payment) => payment.id === listValue.id
                                    )}
                                    onClick={handleBulkAmtPartlyPayment}
                                    value={listValue.id}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={"partPayCheck" + listValue.id}
                                  ></label>
                                </div>
                              ) : (
                                <i
                                  title={`This invoice has been $${listValue.amount_client_req} pending request`}
                                  className="fa fa-info-circle ml-2 text-primary mt-1"
                                  style={{ cursor: "pointer" }}
                                ></i>
                              )}
                            </td>
                            <td>{listValue.organization}</td>
                            <td>{listValue.inv_number}</td>

                            <td>
                              <CurrencyLabel
                                value={listValue.inv_amount}
                              ></CurrencyLabel>
                            </td>

                            <td>
                              <span>
                                <CurrencyLabel
                                  value={listValue.total_amount_pending || "-"}
                                ></CurrencyLabel>{" "}
                                <i
                                  title="Click here to view the details"
                                  className="fa fa-info-circle ml-2 text-primary mt-1"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleSubModal(listValue.id);
                                  }}
                                ></i>
                              </span>
                            </td>

                            <td className="text-primary">
                              {props.payments.some(
                                (payment) => payment.id === listValue.id
                              ) && (
                                <>
                                  <div>
                                    <TextInput
                                      reference={textInputRef}
                                      name={listValue.id}
                                      value={
                                        props.payments.find(
                                          (payment) =>
                                            payment.id === listValue.id
                                        )?.amount || ""
                                      }
                                      label="Icon"
                                      onChange={(e) =>
                                        handleValueChange(e, listValue)
                                      }
                                      type=""
                                      size={listValue.amount_paid}
                                      className="form-control input-xs"
                                      autoFocus={true}
                                    ></TextInput>
                                  </div>
                                </>
                              )}
                            </td>
                          </tr>
                        )
                    )
                  ) : (
                    <tr>
                      <td colSpan="14" className="text-center">
                        {props.loader || props.data.length > 0
                          ? "Loading Data..."
                          : "No data found"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div class="col-sm-7 col-lg-7 col-xl-7 pull-right"></div>
            <div class="col-sm-2 col-lg-2 col-xl-2 pull-right"></div>
            <div class="col-sm-3 col-lg-3 col-xl-3 pull-right">
              <div className="my-2 b-dashed-2 p-2 bg-light">
                <p class="mb-1">
                  Total Payment Allocated
                  <GeneralButton
                    onClick={confirmPartPayment}
                    disabled={
                      !props.payments.some(
                        (item) =>
                          item.amount !== undefined && item.amount !== null
                      )
                    }
                    className="btn btn-primary ml-2 mt-3 pull-right"
                    name="Allocate Payment"
                  />
                </p>
                <h6 class="font-weight-bold  mb-1">
                  <CurrencyLabel
                    label="make pay"
                    value={totAmount}
                  ></CurrencyLabel>
                </h6>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={subModal} onHide={() => setSubModal(false)} centered>
        <Modal.Header closeButton> </Modal.Header>
        <h6
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-25px",
          }}
        >
          {" "}
          Summary of {invValue.length > 0 ? invValue[0].inv_number : ""}
        </h6>
        <Modal.Body>
          <div class="container">
            <div class="row justify-content-center  bg-light p-3 b-dashed-dark font-weight-bold">
              <div class="col-md-8    ">
                <div class="list-item">
                  <span>Total Invoice Value</span>

                  <span class="float-right  text-secondary">
                    <CurrencyLabel
                      value={invValue.length > 0 ? invValue[0].inv_amount : "0"}
                    />
                  </span>
                </div>
                <div class="list-item mt-3">
                  <span>Total Finance Cost</span>{" "}
                  <span className="badge bg-secondary-transparent ml-2"></span>
                  <span class="float-right text-secondary">
                    <CurrencyLabel
                      value={
                        invValue.length > 0 ? invValue[0].finance_cost : "0"
                      }
                    />
                  </span>
                </div>
                <div class="list-item mt-3">
                  <span>Late Payment Fees</span>
                  <span class="float-right text-secondary">
                    <CurrencyLabel
                      value={invValue.length > 0 ? invValue[0].lpf : "0"}
                    />
                  </span>{" "}
                </div>

                <div class="mt-3">
                  <span>Total Due</span>

                  <span class="float-right text-secondary">
                    <CurrencyLabel
                      value={invValue.length > 0 ? invValue[0].total_due : "0"}
                    />
                  </span>
                </div>
                <hr />
                <div class="list-item mt-3">
                  <span>Less Total Paid</span>
                  <span class="float-right text-secondary">
                    -
                    <CurrencyLabel
                      value={
                        invValue.length > 0 ? invValue[0].amount_paid : "0"
                      }
                    />
                  </span>{" "}
                </div>
                <div class="list-item mt-3">
                  <span>Direct Debit</span>
                  <span class="float-right text-secondary">
                    -
                    <CurrencyLabel
                      value={
                        invValue.length > 0 ? invValue[0].dd_requested : "0"
                      }
                    />
                  </span>{" "}
                </div>
                <hr />
                <div class="list-item mt-3">
                  <span>Total Payment Pending</span>

                  <span class="float-right font-weight-bold text-secondary">
                    {" "}
                    <CurrencyLabel
                      value={
                        invValue.length > 0
                          ? invValue[0].total_amount_pending
                          : "0"
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class="row justify-content-center mt-2">
              <span className="text-danger text-xs">
                *Please note that this finance cost is calculated based on{" "}
                {ParseDateDMY(props.selectedDate)}.
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PartPayment;
