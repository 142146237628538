import React, { useContext } from "react";

import ApproveContext from "../../../../supply-chain/context/bulkApproveContext"; // React Context API
import AttentionRequired from "./attentionRequired";
import AmountToPay from "./amountToPay";
function Finish() {
  const bulkapproveOptions = useContext(ApproveContext);

  return (
    <>
      <div>
        <AmountToPay></AmountToPay>
        <AttentionRequired></AttentionRequired>
      </div>
    </>
  );
}

export default Finish;
