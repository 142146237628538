import React, { useEffect, useState } from "react"
import ReactTable from "react-table";
import { Link } from "react-router";
import LoadingSpinner from '../../../common/loaders/loadingSpinner';
import useApi from '../../../../hooks/useApi';
import entityService from "../../../../services/entityService";
import authService from "../../../../services/authService";
import DraftAccounts from "../network/draftAccounts";

export default function MappedDebtors(props) {

    const { constants, entity_id } = authService.getCurrentUser();
    const [data, setData] = useState([]);

    //------------Api Services-----------------------------------
    const debtorsApi = useApi(entityService.getEntityDebtorsList);

    const fetchDebtors = async () => {
        const response = await debtorsApi.request(entity_id, constants.PRODUCTS.INVOICE);
        setData(response.data);
    }

    const cols = [
        {
            Header: "Organisation",
            accessor: "label",
            headerStyle: { textAlign: "left" },
            minWidth: 110

        },
        {
            Header: "Name",
            accessor: "first_name",
            headerStyle: { textAlign: "left" },
            minWidth: 110

        },
        {
            Header: "Email",
            accessor: "email",
            headerStyle: { textAlign: "left" },
            minWidth: 110

        },
        {
            Header: "Phone Number",
            accessor: "phone_no",
            headerStyle: { textAlign: "left" },
            minWidth: 110

        },
        // {
        //   Header: "Status",
        //   accessor: "status",
        //   headerStyle: { textAlign: "left" },
        //   minWidth: 110,
        //   Cell: row => <span style={{ color: row.original.status == 'active' ? "green" : "red" }} > {row.original.status == 'active' ? "Active" : "Inactive"}</span>
        // },
    ];

    useEffect(() => {
        fetchDebtors();
    }, [])

    const handleReadloadChild = () => {
        setData([]);
        fetchDebtors();
    }

    return (
        <>
            {debtorsApi.loading === true ? (
                <LoadingSpinner />
            ) : (
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <h6>Linked Debtors</h6></div>
                            <div className="col-lg-4 col-md-4 col-sm-4"></div>

                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <Link className="btn btn-light pull-right text-secondary ml-2" to={'/client/if/dashboard'}><i alt="If Dashboard" data-toggle="tooltip" data-placement="top" title="If Dashboard" className="fa fa-close"></i></Link>
                            </div>
                        </div>
                        <span className="float-right p-0 mb-2"></span>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <ReactTable
                                    data={data}
                                    //pages={1}
                                    columns={cols}
                                    //pageSize={ (data.length > 0 && data.length < 10) ? 12 : data.length}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                    showPagination={true}
                                    showPaginationTop={false}
                                    showPaginationBottom={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col-sm-12">
                    <div className="card ">
                        <div className="card-body">
                            <DraftAccounts
                                type="supplier"
                                selectedProduct={{
                                    id: constants.PRODUCTS.INVOICE,
                                }}
                                reloadChild={handleReadloadChild}
                                action={
                                    props.location.query.action ?
                                    props.location.query.action : null
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}