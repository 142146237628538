import React from "react";
import ScSale from "../../pages/scTransactions/snapshots/ScSale";

import authService from "../../../../services/authService";

function CustomerProgramsDashboard() {

    const { constants } = authService.getCurrentUser();

    return (
        <ScSale product={constants.PRODUCTS.CUSTOMERPROGRAMS} />
    )
}
export default CustomerProgramsDashboard;