import http from "./httpService";
import { apiUrl, queryString } from "../../src/environment";

let accessToken = "";

function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  };
}
/************************
 *
 */

// ------ Service to populate templates list in a grid
export async function getNewsLetterList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl + "/core/news-letter/list-newsletters?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to populate templates list in a grid
export async function createNewsLetter(params) {
  updateHeader();
  const { data } = await http.post(
    apiUrl + "/core/news-letter/create-news-letter",
    params,
    accessToken
  );
  return data;
}

// ------ Service to NewsLetter
export async function deleteNewsLetter(newsletter_guid) {
  updateHeader();

  const { data } = await http.post(
    apiUrl +
      "/core/news-letter/delete-news-letter?XDEBUG_SESSION_START=PHPSTORM",
    {
      newsletter_guid
    },
    accessToken
  );
  return data;
}

// ------ Service to populate the template details in an edit form
export async function getNewsLetterDetails(newsletter_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/core/news-letter/get-news-letter-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    newsletter_guid
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to fetchNewsLetterMessages
export async function getNewsLetterMessageList(params, status) {
  updateHeader();

  let url =
    apiUrl +
    "/core/news-letter/news-letter-messages";

  try {
    const response = await http.post(url, {...params, status}, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to fetch Selected entities
export async function fetchSelectedEntities() {
  updateHeader();

  let url =
    apiUrl + "/core/auth/fetch-selected-entities?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {};

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to change status of the message (Read/Unread)
export async function changeMessageStatus(params) {
  updateHeader();

  let url =
    apiUrl + "/core/news-letter/change-msg-status";

  try {
    const response = await http.post(url, params, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export default {
  getNewsLetterList,
  createNewsLetter,
  getNewsLetterDetails,
  getNewsLetterMessageList,
  fetchSelectedEntities,
  deleteNewsLetter,
  changeMessageStatus
};
