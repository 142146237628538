export const getInitials = (firstName, lastName, count = 2) => {
   
    if (firstName === null || typeof firstName == "undefined")
        return null;
    
    let fullName = firstName;
    fullName = lastName !== null ? fullName + " " + lastName : fullName;
    fullName = fullName.split(' ').slice(0, count).join(' ');

    fullName = fullName.match(/((\s|^))[a-z0-9]/gi).join('').toUpperCase();

    if(typeof fullName === 'string')
        return (fullName.split(" ").join(""))
    else return "";
};