import React, { useEffect, useContext } from "react";
import FifoTabs from "../../components/_fifoTabs";
import RoleChecker from "../../../../utils/roleChecker";
import authService from "../../../../services/authService";
import EntityContext from "../../../../context/entityContext";
import NoAccess from "../../widgets/noAccess";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
import ProcessingFacility from "../../widgets/general/processingFacility";

export default function SCFTFLayout (props) {

    const { handleProductChange, selectedBusinessType, handleRoleChange, selProduct, accountDetails } = useContext(EntityContext);
    const { constants, products } = authService.getCurrentUser();

    useEffect(() => {

        handleProductChange(constants.PRODUCTS.CUSTOMERPROGRAMS);

        if(selectedBusinessType === null || selectedBusinessType === 'ALL'){
            if( RoleChecker.check(products, constants.ROLES.SUPPLIER) || RoleChecker.check(products, constants.ROLES.TFSUPPLIER) )
                handleRoleChange(constants.BUSINESS_TYPE.SALE)
            else
                browserHistory.push('/client/home')
        }

    }, []);

    const accountInfo = accountDetails.find(item => item.product == constants.PRODUCTS.CUSTOMERPROGRAMS);

    return(
        <div>
            {selProduct === constants.PRODUCTS.CUSTOMERPROGRAMS ? 
                <>
                    <Helmet>
                        <title>{accountInfo ? accountInfo.nick_name ? accountInfo.nick_name : accountInfo.bank_account_name : ''} | {global.config.title}</title>
                    </Helmet>
                    <FifoTabs 
                        activePath={props.location.pathname.substring(
                            props.location.pathname
                        )} 
                    />
                    {props.children}
                </> 
                : 
                <NoAccess />
            }
        </div>
    )    
}