import React, { useEffect, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { Picker } from '../common/inputs';
import { categoryTypes } from "../../config/predefinedValues.json";

import entityService from "../../services/entityService";
import useApi from '../../hooks/useApi';

function ReferralPartnersList({ onSelectItem, selectedRefPartner }) {

    const defaultVal = { label: "Start typing to search for referral partner", value: null };
    const [searchResults, setSearchResults] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const additionalParams = {
        country_id: "",
        category_type: "3",
        category_id: categoryTypes["ReferralPartner"],
        entityDropDown: true,
    };

    const { request, loading } = useApi(entityService.fetchKeywordSuggestions); // Custom hook for HTTP Requests

    const searchRfPartner = async (searchKey) => {
        const res = await request(searchKey, additionalParams);
        setSearchResults(res.list);
    }

    useEffect(() => {
        setSelectedItem(selectedRefPartner === null ? defaultVal : selectedRefPartner);
    }, [selectedRefPartner]);

    const populateList = async() => {
        const res = await request("", additionalParams);
        setSearchResults(res.list);
    }

    useEffect(() => {
        populateList();
    }, [])

    return (
        <>
            <>
                {loading == true && <LoadingSpinner />}
                <Picker
                    items={searchResults}
                    selectedItem={selectedItem}
                    onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
                    onKeyDown={searchKey => searchRfPartner(searchKey)}
                />
            </>
        </>
    )
};
export default ReferralPartnersList;