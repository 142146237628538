import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import LoanCreation from "./_loanCreation";
import startIcon from "../../../../img/icons/verified.svg";

function EmptyDefaultCard(props) {

    const [openNewLoanModal,setOpenNewLoanModal]=useState(false);

    return (
        <>

            <div className="card">
                <div className="card-body">
                    <div className="row justify-content-center mt-2  ">
                        <p className="mt-5"> <img width={380} height={150} src={startIcon} alt="alt" /></p>
                    </div>
                    <div className="row justify-content-center mt-3  ">
                        <h5 className="font-weight-bold text-center mt-2">Start your new loan application </h5>
                    </div>

                    <div className="row justify-content-center mt-4 mb-4">

                        <button
                            onClick={() => { setOpenNewLoanModal(true) }}
                            className="btn btn-primary ml-2">Apply
                        </button>
                    </div>
                </div>
            </div>

            <Modal
                show={openNewLoanModal}
                onHide={() => { setOpenNewLoanModal(false) }}
                center
            //className="animated bounceInRight "
            >
                <LoanCreation
                    onClose={() => { setOpenNewLoanModal(false) }}
                    reload={() => { setOpenNewLoanModal(false); props.fetchLoanCount(); }}
                ></LoanCreation>
            </Modal>
        </>
    )
}
export default EmptyDefaultCard;