import React, { useEffect, useContext, useState } from "react";
import { DangerTypeTwoCard } from "../../components/cards";
import { CurrencyLabel } from "../../../common/inputs";

import useApi from '../../../../hooks/useApi';
import EntityContext from "../../../../context/entityContext";
import widgetService from "../../../../services/invTransactions";
import productShortName from "../../../../utils/GetProductShortName";

function OverDueMonth() {

    const { selectedBusinessType, selProduct, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);

    //------------Api Service--------------------------------------------
    const paymentsApi = useApi(widgetService.getOverdueThisMonth);

    const fetchData = async () => {
        const response = await paymentsApi.request({ entity_id: entityDetails.a_guid, selectedBusinessType });

        if(response){
            setData({
                topLabel: "Overdue",
                topValue: <CurrencyLabel decimal={2} value={response.data.due_amt} />,
                leftLabel: response.data.isPrimary ? "Debtors" : "Clients",
                leftValue: response.data.isPrimary ? response.data.debtor_count : response.data.client_count,
                rightLabel: "Invoices",
                rightValue: response.data.invoice_count,
                bottomRightLabel: "Due date passed today",
                primaryBtnRoute: `/client/${productShortName(selProduct)}/sale-invoices?status=open&type=overdue`
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>
            <DangerTypeTwoCard
                loading={paymentsApi.loading}
                data={data}
                col={12}
                qrystring={true}
            />
        </>
    )
}
export default OverDueMonth;