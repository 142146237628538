import React, { useEffect, useState } from "react";
import LoadingSpinner from '../../../common/loaders/loadingSpinner';
import { GeneralButton } from "../../../common/inputs";
import { browserHistory } from "react-router";

import useApi from '../../../../hooks/useApi';
import { useUploader } from "../../../../context/supplierUploaderContext";
import entityService from "../../../../services/entityService";
import authService from "../../../../services/authService";


export default function FinishUpload({ selectedProduct }) {

    const { constants } = authService.getCurrentUser();
    const [productType, setProductType] = useState("");

    const { suppliers, sethideNextBtn, sethidePreviousBtn,readyToUpload } = useUploader(); // Instance for Context API

    //------------Custom hooks for handling API Requests--------------
    const { request, data, loading } = useApi(entityService.bulkSupplierUpload);

    sethideNextBtn(true);
    sethidePreviousBtn(true);

    useEffect(() => {
        request({ readyToUpload, selectedProduct });
        if (selectedProduct == constants.PRODUCTS.SUPPLYCHAIN) {
            setProductType('scf');
        } else {
            setProductType('tf');
        }
    }, []);

    return (
        <>
            {loading && <LoadingSpinner />}
            {data.success == true &&
                <>
                    <center>
                        <i className="fa fa-check-circle fa-4x text-success"></i> <br />
                        <br></br>
                        <h4>Suppliers have been successfully uploaded!</h4>
                        <br></br>

                    </center>
                    <div className="row">
                        <GeneralButton className="btn  btn-outline-secondary  btn-sm  m-r-sm font-bold align-center" onClick={() => browserHistory.push("/client/" + productType + "/dashboard")} name={`Return to ${productType} Dashboard`} ></GeneralButton>
                    </div>
                </>
            }

            {
                data.error && data.error.length > 0 && (
                    <>
                        <div>
                            <small className="font-bold">
                                Failed to Upload the following Suppliers.
                            </small>

                            <table className="pendinglist">
                                <thead>
                                    <tr>
                                        <th>Organisation</th>
                                        <th>Error</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.error.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{item.organisation}</td>
                                                <td>{item.message}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                )
            }
        </>
    )
}