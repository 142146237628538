import React from "react";

const TextInput = props => (
  <React.Fragment>
    {props.type === "horizontal" && (
      <div className={`form-group row ${props.mandatory ? "required" : ""} ${props.customFormGroup ? props.customFormGroup : ""}`}>
        {!props.hideLabel && (
          <label style={{ paddingTop: "7px" }} className={`col-lg-4 form-label ${props.mandatory ? "control-label" : ""}`}>
            {props.label}
          </label>
        )}
        <div className={props.fullWidth ? "col-lg-12" : "col-lg-8"}>
          {props.readonly == true ? (
            <input
              name={props.name}
              value={props.value}
              onSelect={props.onSelect} 
              className={props.class ? props.class : "form-control"}
              placeholder={props.placeholder ? props.placeholder : props.label}
              type={props.password === true ? "password" : "text"}
              ref={props.reference}
              onChange={props.onChange}
              onFocus={props.onFocus}
              style={{ display: "block", width: "100%" }}
              title={props.title ? props.title : ""}
              readOnly
            />
          ) : (
            <input
              name={props.name}
              value={props.value}
              onSelect={props.onSelect} 
              className={props.class ? props.class : "form-control"}
              placeholder={props.placeholder ? props.placeholder : props.label}
              type={props.password === true ? "password" : props.number === true ? "number" : "text"}
              ref={props.reference}
              onChange={props.onChange}
              onFocus={props.onFocus}
              style={{ display: "block", width: "100%" }}
              title={props.title ? props.title : ""}
            />
          )}
        </div>
      </div>
    )}

    {props.type === "vertical" && (
      <div className={`form-group ${props.mandatory ? "required" : ""} ${props.customFormGroup ? props.customFormGroup : ""}`}>
        {!props.hideLabel && (
          <label className={`${props.mandatory ? "control-label" : ""}`}> {props.label}</label>
        )}

        {props.readonly == true ? (
          <input

            name={props.name}
            value={props.value}
            onSelect={props.onSelect} 
            className={props.class ? props.class : "form-control"}
            placeholder={props.placeholder ? props.placeholder : props.label}
            type={props.password === true ? "password" : props.number === true ? "number" : "text"}
            ref={props.reference}
            onFocus={props.onFocus}
            onChange={props.onChange}
            readOnly
            title={props.title ? props.title : ""}
          />
        ) : (
          <input
            name={props.name}
            value={props.value}
            onSelect={props.onSelect} 
            className={props.class ? props.class : "form-control"}
            placeholder={props.placeholder ? props.placeholder : props.label}
            type={props.password === true ? "password" : props.number === true ? "number" : "text"}
            ref={props.reference}
            onFocus={props.onFocus}
            onChange={props.onChange}
            title={props.title ? props.title : ""}


          />
        )}
      </div>
    )}

    {
      props.type === "normal" && !props.readonly && (
        <input
          name={props.name}
          value={props.value}
          onSelect={props.onSelect} 
          className={props.class ? props.class : "form-control"}
          placeholder={props.placeholder ? props.placeholder : props.label}
          type={props.password === true ? "password" : props.number === true ? "number" : "text"}
          ref={props.reference}
          onFocus={props.onFocus}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          autoFocus={props.autoFocus ? props.autoFocus : false}
          title={props.title ? props.title : ""}
          onDoubleClick={props.onDoubleClick}
        />
      )
    }
    {
      props.type === "normal" && props.readonly == true && (
        <input
          name={props.name}
          value={props.value}
          className={props.class ? props.class : "form-control"}
          placeholder={props.placeholder ? props.placeholder : props.label}
          type={props.password === true ? "password" : "text"}
          ref={props.reference}
          onFocus={props.onFocus}
          onChange={props.onChange}
          onSelect={props.onSelect} 
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          autoFocus={props.autoFocus ? props.autoFocus : false}
          title={props.title ? props.title : ""}
          readOnly
          onDoubleClick={props.onDoubleClick}
        />
      )
    }
    {
      props.type === "" && props.readonly === true && (
        <input
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          onSelect={props.onSelect} 
          placeholder={props.placeholder}
          className={props.class ? props.class : "form-control"}
          type="text"
          style={props.style}
          readOnly
          title={props.title ? props.title : ""}
        />
      )
    }
    {
      props.type === "" && !props.readonly && (
        <>
          <input
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            onSelect={props.onSelect} 
            placeholder={props.placeholder}
            className={props.class ? props.class : "form-control"}
            type={props.textType ? props.textType : "text"}
            style={props.style}
            title={props.title ? props.title : ""}
            onBlur={props.onBlur}
            onDoubleClick={props.onDoubleClick}
            ref={props.reference}
            onKeyUp={props.onKeyUp}
          />
        </>
      )
    }
  </React.Fragment >
);

const TextAreaInput = props => (
  props.type === "horizontal" ?
    <>
      <div className={`form-group row ${props.customFormGroup ? props.customFormGroup : ""}`}>
        {!props.hideLabel && (
          <label style={{ paddingTop: "7px" }} className="col-lg-4 form-label">
            {props.label}
          </label>
        )}

        <div className="col-lg-8">
          <textarea
            name={props.name}
            value={props.value}
            className={props.class ? props.class : "form-control"}
            placeholder={props.placeholder ? props.placeholder : props.label}
            type={props.password === true ? "password" : "text"}
            ref={props.reference}
            onFocus={props.onFocus}
            onChange={props.onChange}
            rows={props.rows && props.rows}

          />
        </div>
      </div>
    </> :
    <>
      <textarea
        name={props.name}
        value={props.value}
        className={props.class ? props.class : "form-control"}
        placeholder={props.placeholder ? props.placeholder : props.label}
        type={props.password === true ? "password" : "text"}
        ref={props.reference}
        onFocus={props.onFocus}
        onChange={props.onChange}
        rows={props.rows && props.rows}
      />
    </>
);

export { TextInput, TextAreaInput };