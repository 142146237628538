import React, { useEffect } from "react";
import useApi from "../../../hooks/useApi";
import externalApiService from "../../../services/externalApiService";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { browserHistory } from "react-router";

export default function AccountingSoftwareTokenEndPoint(){

    const getMyobTokenApi = useApi(externalApiService.GetMyobAccessToken);
    const getXeroTokenApi = useApi(externalApiService.GetXeroAccessToken);

    const getXeroAccessToken = async (code, state) => {

        const response = await getXeroTokenApi.request({
            code: code,
            state: state
        });
        if (response) {
            if (!response.data.success)
                toast.error(response.data.message);

            browserHistory.push("/client/accounting-software");
        } else {
            toast.error("Failed to complete authenticate with xero. Please contact your administator.")
        }
    }

    const getMyobAccessToken = async (code, entityId) => {

        const response = await getMyobTokenApi.request({
            code: code,
            entityId: entityId
        });
        if (response) {
            if (!response.data.success)
                toast.error(response.data.message);

            browserHistory.push("/client/accounting-software");
        } else {
            toast.error("Failed to complete authenticate with myob. Please contact your administator.")
        }
    }

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('authCode');
        const xeroState = urlParams.get('xeroState');
        const entityId = urlParams.get('entityId');

        if (xeroState !== "null") {
            getXeroAccessToken(authCode, xeroState);
        } else {
            getMyobAccessToken(authCode, entityId);
        }

    }, [])

    return(
        <>
            <ToastContainer />
            Verifying token details... Please wait...
        </>
    )
}