import React from "react";
// import Transaction from "../createTransaction";
import Transaction from "../singleUpload";
//import "../styles/react-tbl-custom.css";
import { TransactionProvider } from '../../../context/invTransactionContext';

function InvSingleTransactionLayout({ location,handleTransactionList, client, onClose = null }) {
    return (
        <>
            <TransactionProvider>
                <Transaction onClose={onClose} location={location} handleTransactionList={handleTransactionList} client={client}/>
            </TransactionProvider>
        </>
    )
}

export default InvSingleTransactionLayout;