import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import DiscountRates from "./newProgram/_discountRates";
import SupplierCriteria from "./newProgram/_supplierCriteria";
import SupplierMapping from "./newProgram/_supplierMapping";
import Commencement from "./newProgram/_commencement";
import Limit from "./newProgram/_limit";
import MultiStepForm from "../../../common/wizard/MultiStepForm";
import { browserHistory } from "react-router";
import SupplierProgramContext from "../../../../context/supplierProgramContext";
import Finish from "./newProgram/_finish";
import Invite from "./newProgram/_invite";
import MultiStepModal from "../../../common/wizard/MultiStepModal";

export default function AddNew(props) {
  const {
    data,
    setView,
    handleChange,
    proceedNext,
    viewShow,
    editShow,
    model,
    setModel,
    programId,
    handleNextChange,
    sProgram,
  } = useContext(SupplierProgramContext);

  const componentsList = [
    {
      headerTitle: "Create new supplier program",
      headerText: "1. Discount Rate",
      headerIcon: "fa fa-calculator",
      component: <DiscountRates />,
    },

    // {
    //   headerText: "2. Supplier Criteria",
    //   headerIcon: "fa fa-file-text-o",
    //   component: <SupplierCriteria />,
    // },
    {
      headerTitle: "Select suppliers",
      headerText: "2. Supplier Mapping",
      headerIcon: "fa fa-link",
      component: <SupplierMapping />,
    },
    // {
    //   headerText: "4. Commencement",
    //   headerIcon: "fa fa-calendar",
    //   component: <Commencement />,
    // },
    // {
    //   headerText: "5. Program Limit",
    //   headerIcon: "fa fa-cubes",
    //   component: <Limit />,
    // },
    {
      headerTitle: "Invite your suppliers",
      headerText: "3. Invite Supplier",
      headerIcon: "fa fa-envelope",
      component: <Invite />,
    },
    {
      headerTitle: "Success",
      headerText: "7. Finish",
      headerIcon: "fa fa-flag-checkered",
      component: <Finish />,
    },
  ];

  const editcomponentsList = [
    {
      headerText: "1. Discount Rate",
      headerIcon: "fa fa-calculator",
      component: <DiscountRates />,
    },

    // {
    //   headerText: "2. Supplier Criteria",
    //   headerIcon: "fa fa-file-text-o",
    //   component: <SupplierCriteria />,
    // },
    {
      headerText: "2. Supplier Mapping",
      headerIcon: "fa fa-link",
      component: <SupplierMapping />,
    },
    // {
    //   headerText: "4. Commencement",
    //   headerIcon: "fa fa-calendar",
    //   component: <Commencement />,
    // },
    // {
    //   headerText: "5. Program Limit",
    //   headerIcon: "fa fa-cubes",
    //   component: <Limit />,
    // },
    {
      headerText: "3. Finish",
      headerIcon: "fa fa-flag-checkered",
      component: <Finish />,
    },
  ];
  const viewComponentsList = [
    {
      headerText: "1. Discount Rate",
      headerIcon: "fa fa-calculator",
      component: <DiscountRates />,
    },

    // {
    //   headerText: "2. Supplier Criteria",
    //   headerIcon: "fa fa-file-text-o",
    //   component: <SupplierCriteria />,
    // },
    {
      headerText: "2. Supplier Mapping",
      headerIcon: "fa fa-link",
      component: <SupplierMapping />,
    },
    // {
    //   headerText: "4. Commencement",
    //   headerIcon: "fa fa-calendar",
    //   component: <Commencement />,
    // },
    // {
    //   headerText: "5. Program Limit",
    //   headerIcon: "fa fa-cubes",
    //   component: <Limit />,
    // },
  ];
  console.log("aamodel", model);
  return (
    <>
      <Helmet>
        <title>Cash Flow | {global.config.title}</title>
      </Helmet>

      {/* {model === false && ( */}
      <div>
        {/* <MultiStepForm
            programId={programId}
            sProgram={sProgram}
            handleNextChange={handleNextChange}
            proceedNext={proceedNext}
            epProgram={
              editShow === true ? "edit" : viewShow === true ? "view" : null
            }
            data={data}
            handleChange={handleChange}
            list={
              editShow
                ? editcomponentsList
                : viewShow
                ? viewComponentsList
                : componentsList
            }
            displayProgressBar={true}
            handleCancel={() => {
              localStorage.setItem("view", true);
              setView(true);
              browserHistory.push("/client/ep-programme");
            }}
            displaySteps={true}
            pathname={props.location.pathname}
          /> */}
        <MultiStepModal
          programId={programId}
          sProgram={sProgram}
          handleNextChange={handleNextChange}
          proceedNext={proceedNext}
          epProgram={
            editShow === true ? "edit" : viewShow === true ? "view" : null
          }
          data={data}
          handleChange={handleChange}
          list={
            editShow
              ? editcomponentsList
              : viewShow
                ? viewComponentsList
                : componentsList
          }
          displayProgressBar={true}
          handleCancel={() => {
            localStorage.setItem("view", true);
            setView(true);
            browserHistory.push("/client/ep-programme");
          }}
          displaySteps={true}
          pathname={props.location.pathname}
          setModel={setModel}
          model={model}
          headerTitle="Create new supplier program"
        />

      </div>
      {/* )} */}
    </>
  );
}
