import React, { useState, useContext } from "react";
import * as moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";
import { FormRow } from "../../../common/containers/formContainer";
import transactionService from "../../../../services/invTransactions";
import authService from "../../../../services/authService";
import { EntityDebtorList } from "../../../slplusTools";
import EntityContext from "../../../../context/entityContext";
import { confirmAlert } from "react-confirm-alert";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import useApi from "../../../../hooks/useApi";
import {
    DateTimePicker,
    CurrencyText,
    CurrencyLabel,
    GeneralButton,
} from "../../../common/inputs";

function ClientPortalBulkPayment() {

    const [selectAll, setSelectAll] = useState(false);
    const [availableAmt, setAvailableAmt] = useState(0);
    const [paymentInfo, setPaymentInfo] = useState([]);
    const [editableField, setEditableField] = useState(null);
    const [disableBulkAllocation, setDisableBulkAllocation] = useState(false);
    const [paymentBtn, disablePaymentBtn] = useState(true);
    const [recievedAmt, setReceivedAmt] = useState(0);
    const [selectedDebtor, setSelectedDebtor] = useState("");
    const { entityDetails } = useContext(EntityContext);
    const { constants } = authService.getCurrentUser();
    const [paymentDate, setPaymentDate] = useState(new Date());
    //------------Api Services--------------------------------------------
    const paymentsApi = useApi(transactionService.createPayments);
    const invoicesApi = useApi(transactionService.getTransactionByDebtor);

    // ----------- Allocate bulk payment for invoice --------------- //
    const handleBulkAmtAllocation = ({ target }) => {
        // Deallocating amount and resetting values to avoid conflicts
        let updatedList = paymentInfo.map((item) => {
            if (item.checked === true && item.inv_amt_paid > 0) {
                item.inv_due_amt += parseFloat(item.inv_amt_paid);
                item.inv_amt_paid = 0;
                item.checked = false;
            }
            return item;
        });
        // --------------------------------------------------------- //

        updatedList.map((item) => {

            if (target.checked == true && item.inv_closed_date === null && item.inv_due_amt > 0) item.checked = true;
            else item.checked = false;

            if (item.checked === true && item.total_due > 0) {
                item.inv_amt_paid = parseFloat(item.total_due);
                item.inv_due_amt = 0;
            } else if (item.checked === false) {
                item.inv_due_amt += parseFloat(item.inv_amt_paid);
                item.inv_amt_paid = 0;
            }
            return item;
        });

        setPaymentInfo(updatedList);
        setSelectAll(target.checked);
        handleSaveBtnVisibility(updatedList);
    };

    // -------- Allocate Payment for Individual invoice ------------ //
    const handleIndividualAmtAllocation = (checked, index) => {

        if (availableAmt > 0 || checked === false) { // Do not allow user to check the invoice if no amount to allocate

            let paymentsList = [...paymentInfo];

            let updatedList = paymentsList
                .filter((obj) => obj.index === index)
                .map((item) => {
                    item.checked = checked;
                    item.partlyChecked = false;
                    if (checked === true && item.inv_due_amt > 0) {
                        item.inv_amt_paid = item.total_due;
                        item.inv_due_amt = 0;
                    } else if (checked === false) {
                        item.inv_due_amt += parseFloat(item.inv_amt_paid);
                        item.inv_amt_paid = 0;
                        setSelectAll(false);
                    }
                    return item;
                });

            paymentsList[index] = updatedList[0];
            setPaymentInfo(paymentsList);
            handleSaveBtnVisibility(paymentsList);
        }
    };
    // ------------------------------------------------------------- //

    const handlePartiallyChecked = (index, checked) => {
        let paymentsList = [...paymentInfo];

        paymentsList
            .filter((obj) => obj.index === index)
            .map((item) => {
                item.checked = false;
                item.partlyChecked = checked;

                if (checked === false && item.inv_amt_paid > 0) {
                    item.inv_due_amt += parseFloat(item.inv_amt_paid);
                    item.inv_amt_paid = 0;
                }

                return item;
            });

        setPaymentInfo(paymentsList);

        if (checked)
            setEditableField({
                index,
                amount: "",
            });
        else
            setEditableField(null);

        handleSaveBtnVisibility(paymentsList);
    }

    // -------- Handling Changes on Payment Amount field ------------ //
    const handleAmtAllocation = (amtAvailable) => {

        let enteredAmt = amtAvailable;
        enteredAmt = (enteredAmt === null || enteredAmt === "") ? 0 : enteredAmt;

        let updatedList = paymentInfo.map((item) => { // Amount allocation

            // --------------- Reverting changes --------------- //
            item.inv_due_amt += parseFloat(item.inv_amt_paid);
            item.inv_amt_paid = 0;
            // --------------------------------------------------//

            if (item.checked === true && item.inv_due_amt > 0 && amtAvailable > 0) {
                let amtPayable = amtAvailable - parseFloat(item.inv_chargeable_amt);
                item.inv_amt_paid =
                    amtPayable < 0 ? amtAvailable : parseFloat(item.inv_chargeable_amt);
                item.inv_due_amt =
                    parseFloat(item.inv_chargeable_amt) - parseFloat(item.inv_amt_paid);
                amtAvailable -= parseFloat(item.inv_amt_paid);
            }
            return item;
        });

        setPaymentInfo(updatedList);
        setAvailableAmt(amtAvailable);
        disableAmountAllocationCheckBox(enteredAmt, updatedList);
    };
    // ------------------------------------------------------------- //

    const handleAmtEdit = (index = null, amount = null) => {
        if (amount !== null) setEditableField({ index, amount });
        else {
            let paymentsList = paymentInfo;
            let amtAvailable = availableAmt;
            let invoice = paymentsList.find((obj) => obj.index === index);
            let newAmount = parseFloat(editableField.amount);

            // Reverting changes
            invoice.inv_due_amt += parseFloat(invoice.inv_amt_paid);
            amtAvailable += parseFloat(invoice.inv_amt_paid);
            invoice.inv_amt_paid = 0;

            // ----------------- Validating amount field -------------------- //
            if (newAmount > invoice.inv_due_amt) {
                toast.error("Amount paid cannot be greater than due amount.");
                setAvailableAmt(amtAvailable);
                setEditableField({ index, amount: 0 });
                return;
            } else if (newAmount > amtAvailable) {
                toast.error("Amount paid cannot be greater than available amount.");
                setAvailableAmt(amtAvailable);
                setEditableField({ index, amount: 0 });
                return;
            }
            // -------------------------------------------------------------- //

            // Applying changes based on new amount
            let amtPayable = parseFloat(newAmount) - parseFloat(invoice.inv_due_amt);
            invoice.inv_amt_paid =
                amtPayable < 0 ? newAmount : parseFloat(invoice.inv_due_amt);
            invoice.inv_due_amt -= parseFloat(newAmount);
            amtAvailable -= parseFloat(newAmount);

            if (parseFloat(invoice.inv_amt_paid) >= parseFloat(invoice.total_due)) {
                invoice.checked = true;
                invoice.partlyChecked = false;
            } else {
                invoice.checked = false;
                invoice.partlyChecked = true;
            }

            setPaymentInfo(paymentsList);
            setAvailableAmt(amtAvailable);
            setEditableField(null);
        }
    };
    // ------------------------------------------------------------- //

    // -------- Function to disable payments if Payment Amount box is empty -------- //
    const disableAmountAllocationCheckBox = (availableAmt, paymentList) => {

        if (paymentList.length > 0) {
            if (availableAmt > 0) {

                // If header check box is already disabled 
                // and the Amount is there in the payment box, enable the checkboxes
                if (disableBulkAllocation) {
                    let updatedList = paymentList
                        .map((item) => {
                            item.disabled = (item.total_due <= 0 || item.inv_closed_date !== null) ? true : false;
                            return item;
                        });
                    setPaymentInfo(updatedList);
                    setDisableBulkAllocation(false);
                }
            }
            else {
                // Disabling all checkboxes if Amount in the Payment box is empty or zero.
                let updatedList = paymentList
                    .map((item) => {
                        item.disabled = true;
                        item.checked = false;
                        handleIndividualAmtAllocation(false, item.index) // Amt deallocation
                        return item;
                    });
                setSelectAll(false);
                setPaymentInfo(updatedList);
                setDisableBulkAllocation(true);
                handleSaveBtnVisibility(updatedList);
            }
        }
    }

    /*----- Save button will be disabled if no invoice selected -----*/
    const handleSaveBtnVisibility = (list) => {
        disablePaymentBtn(list.filter(
            item => item.checked === true || item.partlyChecked === true)
            .length === 0 ? true : false
        );
    }

    // -------- Function to fetch unpaid invoices -------- //
    const fetchInvoices = async (debtor = "") => {

        setPaymentInfo([]);
        setReceivedAmt(0);
        setAvailableAmt(0);

        const { data } = await invoicesApi.request({
            client_guid: entityDetails.a_guid,
            debtor: debtor,
            status: "open",
            residual: true,
            clientPayment: true
        });


        let paymentsArr = [];
        if (data.rows && data.rows.length > 0) {
            data.rows
                .filter((obj) => obj.total_due > 0)
                .map((item) => {
                    paymentsArr.push({
                        index: paymentsArr.length,
                        checked: false,
                        partlyChecked: false,
                        disabled: true,
                        inv_guid: item.inv_guid,
                        inv_due_date: item.inv_due_date,
                        trans_guid: item.inv_trans_guid,
                        disb_date: item.disb_date,
                        inv_closed_date: item.inv_closed_date,
                        inv_number: item.inv_number,
                        client_reference: item.client_reference,
                        debtor_organisation: item.debtor_organisation,
                        residual_amt: item.residual_amt !== null ? parseFloat(item.residual_amt) : 0,
                        inv_chargeable_amt: item.inv_chargeable_amt !== null
                            ? parseFloat(item.inv_chargeable_amt)
                            : 0,
                        inv_amount: item.inv_amount !== null ? parseFloat(item.inv_amount) : 0,
                        inv_amt_paid: 0,
                        inv_due_amt: item.total_due !== null ? parseFloat(item.total_due) : 0,
                        payment_received: item.total_paid !== null ? parseFloat(item.total_paid) : 0,
                        total_paid: item.total_paid !== null ? parseFloat(item.total_paid) : 0,
                        total_due: item.total_due !== null ? parseFloat(item.total_due) : 0,
                        inv_trans_guid: item.inv_trans_guid,
                        payment_pending: item.payment_pending ? item.payment_pending : 0
                    });
                });
        }

        setPaymentInfo(paymentsArr);
        setDisableBulkAllocation(true);
        //setReceivedAmt(0);
        //setAvailableAmt(0);
        setReceivedAmt(_.sumBy(paymentsArr, "total_due"));
        setAvailableAmt(_.sumBy(paymentsArr, "total_due"));
    };

    /* ------------ Function to create invoice payments ----------------- */
    const handleCreatePayment = (paymentInfo) => {
        const invCount = paymentInfo.filter((obj) => obj.inv_amt_paid > 0).length;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Make Payments</h4>
                        <p>
                            {invCount} invoice(s) worth{" "}
                            <CurrencyLabel value={_.sumBy(paymentInfo, "inv_amt_paid")}></CurrencyLabel>{" "}

                        </p>

                        <p>Are you sure want to continue?</p>
                        <button
                            className="btn btn-white ml-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => {
                                submitPaymentInfo(false);
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "Make Payments",
            message: `${invCount} invoices going to get paid`,
        });
    };

    // ------------ Handling Async operation to save the data to the server ------------ //
    const submitPaymentInfo = async () => {

        if (paymentInfo.filter((obj) => obj.inv_amt_paid > 0).length === 0) {
            toast.error("No payments entered.");
            return;
        }

        const reqData = {
            payment_date: paymentDate,
            paymentInfo: [...paymentInfo.filter((obj) => obj.inv_amt_paid > 0)],
            clientPayment: true
        };

        const { data } = await paymentsApi.request(reqData);

        if (data.success === false) toast.error(data.message);
        else {
            disablePaymentBtn(true);
            toast.success(data.message);
            fetchInvoices(selectedDebtor);
        }
    };
    // ------------------------------------------------------------- //

    // ------------ Handling change in the amount field for each individual invoices ------------ //
    const handleAmtOnBlur = (index) => {
        if (editableField.amount > 0)
            handleAmtEdit(index)
        else
            setEditableField(null);

        let paymentsList = paymentInfo;
        let invoice = paymentsList.find((obj) => obj.index === index);

        if (parseFloat(invoice.inv_amt_paid) >= parseFloat(invoice.total_due)) {
            invoice.checked = true;
            invoice.partlyChecked = false;
        } else if (parseFloat(invoice.inv_amt_paid) < parseFloat(invoice.total_due)) {
            invoice.checked = false;
            invoice.partlyChecked = true;
        }

        if (invoice.inv_amt_paid === 0) {
            invoice.checked = false;
            invoice.partlyChecked = false;
        }

        setPaymentInfo(paymentsList);
    }
    // ------------------------------------------------------------- //

    return (
        <>
            {(invoicesApi.loading == true || paymentsApi.loading == true) && <LoadingSpinner />}
            <div className="card overflow-visible">
                <div className="card-body">
                    <h5 className="card-title">Confirm Your Payments</h5>
                    <FormRow className="row mx-0 mt-4">
                        <div className="col-sm-3 pl-0">
                            <div className="b-dashed-2 py-3 px-3 bg-light">
                                <p className="mb-1">Debtor List</p>
                                <EntityDebtorList
                                    selectedDebtor={selectedDebtor}
                                    client={entityDetails.a_guid}
                                    onSelectItem={(debtor) => {
                                        setSelectedDebtor(debtor);
                                        fetchInvoices(debtor.value);
                                    }}
                                    product={constants.PRODUCTS.INVOICE}
                                />
                            </div>
                        </div>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-3">
                            <div style={{ visibility: 'hidden' }}>
                                <CurrencyText
                                    value={recievedAmt}
                                    onChangeEvent={(event, maskedvalue, floatvalue) => {
                                        setReceivedAmt(floatvalue);
                                        setAvailableAmt(floatvalue);
                                        handleAmtAllocation(floatvalue);
                                    }}
                                    placeholder="Received Amount"
                                />
                            </div>
                        </div>
                        <div className="col-sm-3 pr-0">
                            <div className="b-dashed-2 py-3 px-3 bg-light">
                                <p className="mb-1">Total Payment Allocated</p>
                                <h4 className="font-weight-bold mb-1">
                                    <CurrencyLabel
                                        label="make pay"
                                        value={_.sumBy(paymentInfo, (item) =>
                                            Number(item.inv_amt_paid)
                                        )}
                                    ></CurrencyLabel>
                                </h4>
                            </div>
                        </div>
                    </FormRow>

                    <FormRow className="row mx-0 mt-2">
                        <div className="col-sm-12">
                            <FormRow>
                                <div id="scrollDiv" className="scrollDiv">
                                    <table className="table text-secondary">
                                        <thead>
                                            {paymentInfo.length > 0 && (
                                                <tr>
                                                    <th>
                                                        Full
                                                        <div className="custom-control d-flex custom-checkbox small">
                                                            <input
                                                                id="customCheck"
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                checked={selectAll}
                                                                onClick={handleBulkAmtAllocation}
                                                                disabled={disableBulkAllocation}
                                                            ></input>
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="customCheck"
                                                            ></label>
                                                        </div>
                                                    </th>
                                                    <th>Part</th>
                                                    <th>Ref #</th>
                                                    <th>Inv No.</th>
                                                    <th>Debtor</th>
                                                    <th>Due Date</th>
                                                    <th>Invoice Amount</th>
                                                    <th>Agreement Amount</th>
                                                    <th>To Collect</th>
                                                    <th>Payments Received</th>
                                                    <th>Payment Pending</th>
                                                    <th>Payment Allocated</th>
                                                </tr>
                                            )}
                                        </thead>

                                        <tbody>
                                            {paymentInfo.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="custom-control d-flex custom-checkbox small">
                                                            <input
                                                                id={`fully-pay-check-${index}`}
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                onClick={(e) =>
                                                                    handleIndividualAmtAllocation(
                                                                        e.target.checked,
                                                                        item.index
                                                                    )
                                                                }
                                                                checked={item.checked}
                                                                disabled={item.disabled}
                                                            ></input>
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={`fully-pay-check-${index}`}
                                                            ></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="custom-control d-flex custom-checkbox small">
                                                            <input
                                                                id={`partially-pay-check-${index}`}
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                onClick={(e) => {
                                                                    handlePartiallyChecked(item.index, e.target.checked)
                                                                }
                                                                }
                                                                checked={item.partlyChecked}
                                                            ></input>
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={`partially-pay-check-${index}`}
                                                            ></label>
                                                        </div>
                                                    </td>
                                                    <td>{item.client_reference}</td>
                                                    <td>{item.inv_number}</td>
                                                    <td>{item.debtor_organisation}</td>
                                                    <td>{moment(item.inv_due_date, "YYYY/MM/DD").format("DD/MM/YYYY")}</td>
                                                    <td><CurrencyLabel value={item.inv_amount}></CurrencyLabel></td>
                                                    <td><CurrencyLabel value={item.inv_chargeable_amt}></CurrencyLabel></td>
                                                    <td><CurrencyLabel value={item.inv_due_amt}></CurrencyLabel></td>
                                                    <td><CurrencyLabel value={item.payment_received}></CurrencyLabel></td>
                                                    <td><span className="text-danger">{item.payment_pending !== 0 ? <CurrencyLabel value={item.payment_pending}></CurrencyLabel> : '-'}</span></td>
                                                    <td>
                                                        {editableField !== null &&
                                                            editableField.index === item.index ? (
                                                            <CurrencyText
                                                                value={editableField.amount}
                                                                onChangeEvent={(
                                                                    event,
                                                                    maskedvalue,
                                                                    floatvalue
                                                                ) => {
                                                                    if (floatvalue > 0)
                                                                        handleAmtEdit(item.index, floatvalue);
                                                                }}
                                                                placeholder="Received Amount"
                                                                onBlur={e => handleAmtOnBlur(item.index)}
                                                                autoFocus
                                                            />
                                                        ) : (
                                                            <strong>
                                                                <CurrencyLabel
                                                                    value={item.inv_amt_paid}
                                                                    onDoubleClick={() => {
                                                                        setEditableField({
                                                                            index: item.index,
                                                                            amount: item.inv_amt_paid,
                                                                        });
                                                                        handlePartiallyChecked(item.index, true);
                                                                    }}
                                                                >

                                                                </CurrencyLabel>
                                                            </strong>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </FormRow>
                        </div>
                    </FormRow>

                    {/*------------------ Table Footer ------------------*/}
                    {paymentInfo.length > 0 && (
                        <div className="row mt-3">
                            <div className="col-md-3 col-sm-12">

                            </div>

                            <div className="col-md-3 d-md-block d-sm-none"></div>

                            <div className="col-md-6 col-sm-12">
                                <div className="row">
                                    <div className="col-md-8 ">
                                        <DateTimePicker
                                            name="paymentDate" type="normal"
                                            onChange={({ target }) => {
                                                if (target.value)
                                                    setPaymentDate(target.value)
                                            }}
                                            selectedDate={paymentDate}
                                        >
                                        </DateTimePicker>
                                    </div>
                                    <div className="col-md-4">
                                        <GeneralButton
                                            onClick={() => handleCreatePayment(paymentInfo)}
                                            disabled={paymentBtn ? true : false}
                                            className="btn btn-primary pull-right ml-2"
                                            name="Confirm Payment"
                                        ></GeneralButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/*---------------------------------------------------*/}
                </div>
            </div>
        </>
    )
}
export default ClientPortalBulkPayment;