import React, { useEffect, useState, useContext } from "react";
import { PrimaryTypeOne } from "../../components/cards";

import useApi from '../../../../hooks/useApi';
import { CurrencyLabel } from "../../../common/inputs";
import RoleChecker from "../../../../utils/roleChecker";

import CommonService from "../../../supply-chain/services/commonService";
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";


function FundsAvailable() {


    const { constants } = authService.getCurrentUser();
   
    const [fundData, setFundData] = useState(null); //set data for funds available

   
  const { selectedBusinessType, entityDetails } = useContext(EntityContext);
  
  //------------Api Service--------------------------------------------

  const fundApi = useApi( CommonService.getTfFund );
    const fetchData = async () => {

        const { data } = await fundApi.request({ "guid": entityDetails.a_guid, "selRole": selectedBusinessType });
    
        setFundData({
          topLabel: "Facility Summary",
          topValue: <CurrencyLabel decimal={2} value={data.balanceAvailable} />,
          centerLabel: "Funds Available",
          centerValueHeading: "Total Facility ",
          centerValue: <CurrencyLabel decimal={2} value={data.limit} />,
          primaryBtnRoute: "/client/settings",
          secondaryBtnRoute: "/client/tf/bulk-payment",
          btnRoute: "/client/tf/purchase-invoices",
          tertiaryBtnRoute : "/client/tf/direct-debit",
          status: 'open'
        })
    }




    useEffect(() => {
        fetchData();
      }, [selectedBusinessType, entityDetails]);
    //------------Api Service--------------------------------------------

    return (
        <>

       {RoleChecker.check(entityDetails.products, constants.ROLES.TFCUSTOMER)  && (
                <PrimaryTypeOne
                    loading={fundApi.loading}
                    data={fundData}
                />

            )}

        </>
    )
}
export default FundsAvailable;