import React from "react";
import Form from "../../../../common/Form";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { InputGroupMd } from "../../../../common/containers/formContainer";
import entityService from "../../../../../services/entityService";
import { TextInput, TextAreaInput, Picker, GeneralButton } from "../../../../common/inputs";
import MultipleFileInput from "../../../../common/inputs/multipleFileInput/multipleFileInput";
import { industries, organizationTypes } from "../../../../../config/predefinedValues.json";

class OrganizationDetails extends Form {

  constructor(props) {
    super(props);
    this.confirmationRef = React.createRef();

    this.state = {
      logoUnlinked: false
    }
  }

  //-------------------- Unlink logo ---------------------------//
  handleUnlinkLogo = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Unlink Logo ?</h4>
            <p>Are you sure want to continue?</p>
            <button
              className="btn btn-white"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={async () => {
                const { data } = await entityService.unlinkBPLogo({ a_guid: this.props.data.entity_guid });
                if (data.success) {
                  this.setState({ logoUnlinked: true });
                  toast.success(data.message);
                }
                else
                  toast.error(data.message)

                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  };

  render() {
    const props = this.props;
    const basic = props.data;
    const role = props.role;
    return (
      <React.Fragment>
        {role == "CEO" &&
          <>
            <InputGroupMd label="Logo">
              {basic.logo && !this.state.logoUnlinked ?
                <>
                  <img src={basic.logo} className="center" />
                  <GeneralButton
                    name="Unlink Logo"
                    className="btn btn-danger btn-sm float-right"
                    onClick={this.handleUnlinkLogo}
                    type="button"
                  >
                  </GeneralButton>
                </>
                :
                <MultipleFileInput
                  files={basic.files}
                  allowMultiple={false}
                  onupdatefiles={props.handleImageUpload}
                  acceptedFileTypes={['image/png', 'image/jpeg']}
                  maxFileSize={'1MB'}
                  imageValidateSizeMaxWidth={260}
                  imageValidateSizeMaxHeight={65}
                ></MultipleFileInput>
              }

            </InputGroupMd>

            <InputGroupMd>
              <div className={`alert alert-info`} role="alert">
                <p>Maximum Allowed Dimensions: 260px * 65px </p>
                <p>Maximum File Size: 1MB</p>
                <p>Allowed File Extensions: JPEG, PNG</p>
              </div>
            </InputGroupMd>

            <InputGroupMd mandatory={true} label={`Display Name on ${global.config.title}`}>
              <TextInput name="display_name" reference={this.props.reference.display_name} value={basic.display_name} onChange={props.handleChange} placeholder="Display Name" type=""></TextInput>
            </InputGroupMd>
            <InputGroupMd mandatory={true} label="Legal / Trading Name">
              <TextInput name="trading_name" reference={this.props.reference.trading_name} value={basic.trading_name} onChange={props.handleChange} placeholder="Legal / Trading Name" type="" ></TextInput>
            </InputGroupMd>
            <InputGroupMd mandatory={true} label="Industry You Operate In">
              <Picker
                items={industries}
                selectedItem={basic.industry_type}
                onSelectItem={props.handleIndustryTypeOnSelect}
                reference={this.props.reference.industry_type}
              />
            </InputGroupMd>

            <InputGroupMd mandatory={true} label="Organisation Type">
              <Picker
                items={organizationTypes}
                selectedItem={basic.org_type}
                onSelectItem={props.handleOrgTypeOnSelect}
                reference={this.props.org_type}
              />
            </InputGroupMd>
            <InputGroupMd mandatory={true} label="Australian Business Number">
              {
                basic.aus_business_no ? (
                  <TextInput
                    name="aus_business_no"
                    reference={props.reference.aus_business_no}
                    value={basic.aus_business_no}
                    //onChange={props.handleChange}
                    placeholder="Australian Business Number"
                    type=""
                  ></TextInput>
                ) : (
                  <TextInput
                    name="aus_business_no"
                    reference={props.reference.aus_business_no}
                    value={basic.aus_business_no}
                    onChange={props.handleChange}
                    placeholder="Australian Business Number"
                    type=""
                  ></TextInput>
                )
              }
            </InputGroupMd>
            <InputGroupMd label="Branch">
              <TextInput name="branch" value={basic.branch} onChange={props.handleChange} placeholder="Branch Name" type="" ></TextInput>
            </InputGroupMd>
            <InputGroupMd mandatory={true} label="Organisation Description">
              <TextAreaInput name="description" reference={props.reference.description} value={basic.description} onChange={props.handleChange} placeholder="Organisation Description" rows="4" type="" ></TextAreaInput>
            </InputGroupMd>
          </>
        }
        {
          role != "CEO" && (
            <>
              <InputGroupMd label={`Display Name on ${global.config.title}`}><p>:&ensp;{basic.display_name}</p></InputGroupMd>
              <InputGroupMd label="Legal / Trading Name"><p>:&ensp;{basic.trading_name}</p></InputGroupMd>
              <InputGroupMd label="Logo">
                {basic.logo_name && <img className="responsive" src={basic.logo_name} />}
                {!basic.logo_name && <p>:&ensp;No logos found.</p>}
              </InputGroupMd>
              <InputGroupMd label="Industry You Operate In"><p>:&ensp;{basic.industry_type.label}</p></InputGroupMd>
              <InputGroupMd label="Organisation Type"><p>:&ensp;{basic.org_type.label}</p></InputGroupMd>
              <InputGroupMd label="Australian Business Number"><p>:&ensp;{basic.aus_business_no}</p></InputGroupMd>
              <InputGroupMd label="Branch"><p>:&ensp;{basic.branch}</p></InputGroupMd>
              <InputGroupMd label="Organisation Description"><p>:&ensp;{basic.description}</p></InputGroupMd>
            </>
          )
        }
      </React.Fragment >
    );
  }
}

export default OrganizationDetails;