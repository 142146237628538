import React, { useEffect, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { Picker } from '../common/inputs';
import { Checkbox, Radio, RadioGroup } from "react-icheck";

import configService from "../../services/configService";
import useApi from '../../hooks/useApi';

function UserRoles({ typeId, listType = "select", category, onSelectItem }) {

    const [categories, setCategories] = useState([]);
    const { request, data, loading } = useApi(configService.GetUserTypes); // Custom hook for HTTP Requests

    const fetchUserRoles = async () => {
        const categories = await request(null, typeId, "select");
        setCategories(categories.list);
    }

    const handleCheckboxChange = event => {
        let updatedList = categories.map((item) => {
            if (item.label === event.target.name)
                item.isChecked = event.target.checked;
            return item;
        });
        onSelectItem(updatedList)
    };

    useEffect(() => {
        fetchUserRoles();
    }, []);

    return (
        <>
            <>
                {loading == true && <LoadingSpinner />}

                {listType === "checkbox" &&
                    (
                        categories.map((item, index) => {
                            return (
                                <>
                                    <Checkbox
                                        key={index}
                                        checkboxClass="icheckbox_minimal-grey"
                                        increaseArea="20%"
                                        label={" " + item.label}
                                        onChange={e => handleCheckboxChange(e)}
                                        name={item.label}
                                    //checked={item.isChecked ? true : false}
                                    />
                                    <br></br>
                                </>
                            );
                        })
                    )
                }

                {listType === "radio" && (
                    <div style={{ width: 120, paddingTop: 10 }}>
                        <RadioGroup
                            column name="radio"
                            value={category.toString()}>
                            {categories.map((item, index) => (
                                <Radio
                                    id={item.label}
                                    value={item.id.toString()}
                                    radioClass="iradio_minimal-grey"
                                    increaseArea="20%"
                                    label={"&nbsp;" + item.label + "&nbsp;"}
                                    onClick={({ target }) => onSelectItem(target.value)}
                                />
                            ))}
                        </RadioGroup>
                    </div>
                )}

                {listType === "select" && (
                    <Picker
                        items={categories}
                        selectedItem={categories.find(obj => obj.value === category)}
                        onSelectItem={({ value }) => onSelectItem(value)}
                    />
                )}
            </>
        </>
    )
};
export default UserRoles;