import authService from "../services/authService";

const productShortName = (productId) => {
    const { constants } = authService.getCurrentUser();
        
    if(productId === constants.PRODUCTS.INVOICE)
        return 'if';
    else if(productId === constants.PRODUCTS.SUPPLYCHAIN)
        return 'scf';
    else if(productId === constants.PRODUCTS.TRADEFINANCE)
        return 'tf';
    else if(productId === constants.PRODUCTS.LOAN)
        return 'loan';
    else if(productId === constants.PRODUCTS.DEBTORFINANACE)
        return 'wob';
    else if(productId === constants.PRODUCTS.CUSTOMERPROGRAMS)
        return 'customer-programs';
};

export default productShortName;