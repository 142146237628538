import React, { useState, useContext } from "react"
import { AddButton, TextInput } from "../../../common/inputs";
import customerIcon from "../../.././../img/icons/terminology-customer.svg"
import supplierIcon from "../../.././../img/icons/terminology-supplier.svg"
import purchaseIcon from "../../.././../img/icons/terminology-purchase.svg"
import salesIcon from "../../.././../img/icons/terminology-sales.svg"
import coreService from "../../../../services/coreService";
import EntityContext from "../../../../context/entityContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import useApi from '../../../../hooks/useApi';
import { browserHistory } from "react-router";
import { Link } from "react-router";

export default function TerminologiesGetStarted() {

    //------------Api Services--------------------------------------------
    const userTerminologiesApi = useApi(coreService.saveUserTerminologies);
    const {
        entityDetails,
        populateClientDetails
    } = useContext(EntityContext);

    const [currentIndex, setCurrentIndex] = useState(0)
    const [terminologies, setTerminologies] = useState(
        [
            { label: 'Customers', description: 'Enter below the term you use for customers, examples, Clients, Debtors, Payers', img: customerIcon, key: 'CUSTOMER', defaultValue: 'Customer', value: null },
            { label: 'Suppliers', description: 'Enter below the term you use for suppliers, examples, Merchants, Sellers, Contractors, Vendor, Biller', img: supplierIcon, key: 'SUPPLIER', defaultValue: 'Supplier', value: null },
            { label: 'Purchase Invoices', description: 'Enter below the term you use for purchase invoices, examples, Bills, Purchases', img: purchaseIcon, key: 'PURCHASE_INVOICES', defaultValue: 'Purchase Invoices', value: null },
            { label: 'Sales Invoices', description: 'Enter below the term you use for sales invoices, examples, Invoices, Sales', img: salesIcon, key: 'SALES_INVOICES', defaultValue: 'Sales Invoices', value: null },
        ])

    const handleSubmit = async () => {
        let obj = {}
        terminologies.map(item => {
            obj[item.key] = (item.value === null || item.value === "") ? item.defaultValue : item.value
        })

        const response = await userTerminologiesApi.request({
            terminologies: obj
        });

        if (response) {
            if (response.data.success === true) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-delete-ui">
                                <h4>All selected Terminology has been set</h4>
                                <p>All the terminology you selected to change has been updated</p>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        onClose();
                                        populateClientDetails({ value: entityDetails.a_guid });
                                        browserHistory.push('/client/client-terminologies');
                                    }}
                                >
                                    Close
                                </button>
                            </div >
                        );
                    },
                });
            }
            else
                toast.error(response.data.message);
        }
    }

    return (

        <div className="card">
            <div className="row">
                <div className="col-lg-12">
                    <Link className="btn btn-light pull-right text-secondary mr-2 mt-2" onClick={() => browserHistory.push('/client/settings')}><i alt=" Settings" data-toggle="tooltip" data-placement="top" title="Close" className="fa fa-close"></i></Link>
                </div>
            </div>

            <div className="mb-0">
                <div className="card-body">
                    <div className="row min-vh-50 justify-content-center align-items-center py-2">
                        <div className="col-lg-6 col-md-12 pl-5">

                            <h3>{`What Do You Call ${terminologies[currentIndex].label}?`}</h3>

                            <p>{terminologies[currentIndex].description}</p>

                            <div className="col-lg-12 px-0 ">
                                <div className="form-group">
                                    <TextInput
                                        name="terminology_value"
                                        value={terminologies[currentIndex].value || ''}
                                        onChange={(e) => {
                                            const data = [...terminologies]
                                            data[currentIndex].value = e.target.value
                                            setTerminologies(data)
                                        }}
                                        type=""
                                    ></TextInput>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 col-md-12">
                            <img src={terminologies[currentIndex].img} alt="image" height={500} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            Or, click next to use existing terminology
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="d-flex justify-content-end align-items-center mt-3">

                                {currentIndex !== 0 &&
                                    <AddButton
                                        onClick={() => setCurrentIndex(currentIndex - 1)}
                                        label="Previous"
                                        style={`btn btn-light mr-1`}
                                    ></AddButton>
                                }

                                <AddButton
                                    onClick={() => currentIndex + 1 === terminologies.length ? handleSubmit() : setCurrentIndex(currentIndex + 1)}
                                    label={currentIndex + 1 === terminologies.length ? 'Submit' : 'Next'}
                                    style={`btn btn-primary align-center ml-1`}
                                ></AddButton>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}