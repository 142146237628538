import React, { useState } from "react";
// import startIcon5 from "../../.././../img/icons/FMB_icon5.svg"
import startIcon10 from "../../.././../img/icons/FMB_icon10.svg"
import graph from "../../.././../img/icons/graph.svg"
import calcultor from "../../.././../img/icons/calc.svg"
// import startIcon12 from "../../.././../img/icons/FMB_icon12.svg"
// import startIcon11 from "../../.././../img/icons/FMB_icon11.svg"

import { useSelector, useDispatch } from 'react-redux'
import { handleChangeAnswers } from '../../../../redux/financeBusiness/finance.actions'
import { TextInput, } from "../../../common/inputs";
import { RadioButton } from "../../../common/inputs/radioButton/radioButton";
import { toast } from "react-toastify";
import { Checkbox } from "react-icheck";

export default function Page4({ goNext, questionSection }) {
    const [textValue, setTextValue] = useState(null);
    const questions = useSelector(({ financeReducer }) => financeReducer.questions)
    let row = [];
    const dispatch = useDispatch()
    const [currentQuestion, setCurrentQuestion] = useState(0)
    let questionSet;
    if (questionSection === 'finalQuestions') {
        questionSet = [
            { from: 0, to: 4 },
            { from: 4, to: 6 },
        ]
    } else {
        questionSet = [
            { from: 0, to: 1 },
            { from: 1, to: 2 },
            { from: 2, to: 5 },
            { from: 5, to: 8 }
        ]
    }
    const questionSetLength = questionSet.length
    const next = () => {

        if (questionSetLength <= currentQuestion + 1 && questionSection === 'cashFlowQestions') {
            goNext('workingCapitalChoose')
        } else if (questionSetLength <= currentQuestion + 1 && questionSection === 'workingCapitalQuestions') {
            goNext('finalQuestions')
        }
        else if (questionSetLength <= currentQuestion + 1 && questionSection === 'finalQuestions') {
            goNext('finished')
        }
        else {
            if (currentQuestion === 0 && questionSection === 'cashFlowQestions') {
                let isAnswered = [];
                // debugger

                questions.cashFlow[0].options.map(opt => {
                    opt.options.map(val => {
                        if (val.selected === true) {
                            isAnswered.push(val)
                        }
                        return null
                    })
                    return null
                })
                if (isAnswered.length > 0) {
                    isAnswered = isAnswered.filter(row => row.value === 'Yes')
                    if (isAnswered.length > 0) {
                        setCurrentQuestion(currentQuestion + 1)

                    } else {
                        toast.error('select minimum one Yes!');

                    }

                } else {
                    toast.error(' your are not selected any option  ');

                }
            } else {
                setCurrentQuestion(currentQuestion + 1)

            }
        }
        return
    }
    const back = () => {
        if (currentQuestion !== 0) {
            setCurrentQuestion(currentQuestion - 1)
        } else {
            goNext('cashFlowChoose')
        }
        return
    }
    const [selectedAnswer, setSelectedAnswer] = useState([])
    const handleChange = (e, optionId, questionId, questionType) => {
        const { value, id } = e.target;
        const data = {
            id: id,
            value: value,
            optionId: optionId,
            questionSection: questionSection,
            questionId: questionId,
            questionType: questionType
        }
        dispatch(handleChangeAnswers(data))
        setSelectedAnswer([...selectedAnswer, id])
    }
    const handleCheck = (e, index, optionId, questionId, questionType) => {
        const { checked, id } = e.target;
        const data = {
            id: id,
            value: checked,
            index,
            optionId: optionId,
            questionSection: questionSection,
            questionId: questionId,
            questionType: questionType
        }
        dispatch(handleChangeAnswers(data))
        setSelectedAnswer([...selectedAnswer, id])
    }
    const handleTextInput = (e, questionId, questionType) => {
        const { value, id } = e.target;
        const data = {
            id: id,
            value: value,
            questionSection: questionSection,
            questionId: questionId,
            questionType: questionType
        }
        dispatch(handleChangeAnswers(data))
    }
    const handleSelect = (e, questionId, questionType) => {
        const { value, id } = e.target;
        const data = {
            id: id,
            value: value,
            questionSection: questionSection,
            questionId: questionId,
            questionType: questionType
        }
        dispatch(handleChangeAnswers(data))
    }
    const QuestionView = () => {
        
        if (questions.cashFlow.length > 0) {
            if (questionSection === 'workingCapitalQuestions') {
                row = questions.workingCapital
            }
            else if (questionSection === 'finalQuestions') {
                row = questions.finalQuestions
            }
            else {
                row = questions.cashFlow
            }

            return (
                <>
                    {
                        row.slice(questionSet[currentQuestion].from, questionSet[currentQuestion].to).map((qs, qstnIndex) => {
                            return (
                                <React.Fragment key={`${qs.id}-${qstnIndex}`}>
                                    <div id="animation_box">
                                        <dt className="mb-3 f-13">{qs.questionNumber}. {qs.question}</dt>
                                        <dd >
                                            {qs.questionType === 'options' && qs.options.length > 0 ?
                                                qs.options.map(options =>
                                                    <React.Fragment>
                                                        <div className="f-13">{options.label}</div>
                                                        <div className="row mb-2">
                                                            {options.options.length > 0 && options.options.map((opt, index) =>
                                                                <div className="col-lg-6 ">
                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                        <RadioButton
                                                                            name={opt.id}
                                                                            value={opt.value}
                                                                            checked={opt.selected}
                                                                            onChange={(e) => handleChange(e, options.id, qs.id, qs.questionType)}
                                                                            id={opt.id}
                                                                            label={opt.value}
                                                                            className="custom-control custom-radio mt-2"
                                                                        ></RadioButton>
                                                                    </div>
                                                                </div>
                                                            )
                                                            }
                                                        </div>
                                                    </React.Fragment>
                                                ) : null}
                                            {qs.questionType === 'multiple' && qs.options.length > 0 ?
                                                qs.options.map(options =>
                                                    <div className="row mb-2">
                                                        {options.options.length > 0 && options.options.map((opt, index) =>
                                                            <div className="col-lg-6">

                                                                <div class="custom-control d-flex custom-checkbox">
                                                                    <input
                                                                        id={`customCheck${index}`}
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        name={` ${opt.value}`}
                                                                        onChange={e => handleCheck(e, index, options.id, qs.id, qs.questionType)}
                                                                        checked={opt.selected}
                                                                    />
                                                                    <label class="custom-control-label" for={`customCheck${index}`}>
                                                                        {opt.value}
                                                                    </label>
                                                                </div>

                                                            </div>
                                                            )
                                                        }
                                                    </div>
                                            ) : null}
                                            {qs.questionType === 'text' &&
                                                <div className="col-lg-12 px-0 ">
                                                    <div className="form-group">
                                                        <TextInput
                                                            name="purchase_order_no"
                                                            value={qs.answer}
                                                            label="Purchase Order Number"
                                                            onChange={(e) => {
                                                                handleTextInput(e, qs.id, qs.questionType)
                                                            }}
                                                            type = ""
                                                        ></TextInput>
                                                    </div>
                                                </div>

                                            }
                                            {
                                                qs.questionType == 'select' &&
                                                <div className="col-lg-12 px-0">
                                                    <div className="form-group">
                                                        <select id="state1" name="state" className="form-control" onChange={(e) => handleSelect(e, qs.id, qs.questionType)} >
                                                            <option value={null} >{`Select`}</option>

                                                            {
                                                                qs.options.map(selectRow =>
                                                                    <option value={selectRow.value} >{selectRow.value}</option>
                                                                )
                                                            }


                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                        </dd>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }

                </>
            )
        }
        else {
            return null
        }
    }
    return (
        <>
            <div id="animation_box" className="animated fadeIn">

                <div className="fb-height mb-0 ">
                    <div className="card-body center-div">
                        <div className="row vh-70 justify-content-center align-items-center py-5">
                            <div className="col-lg-3  col-md-12">
                                <div className="operate-image">
                                    <img src={questionSection === 'cashFlowQestions' ? graph : questionSection === 'workingCapitalQuestions' ? calcultor : startIcon10} alt="imag" width />
                                </div>
                            </div><div className="col-lg-6 offset-lg-1 col-md-12">
                                <div className>
                                    <h4>{questionSection === 'cashFlowQestions' && 'Cash Flow Finance'}
                                        {questionSection === 'workingCapitalQuestions' && 'Working Capital'}
                                        {questionSetLength == currentQuestion + 1 && questionSection === 'finalQuestions' ? 'Your last 2 questions, and we’re done!' : questionSection === 'finalQuestions' && 'Let’s wrap up with some final questions'}


                                    </h4>
                                    <p >
                                        {questionSection === 'finalQuestions' ? 'These general questions will help us match the best finance solution to your needs.' : 'Please answer the following questions'}

                                    </p>
                                    <div className="border rounded-xl p-4 shadow-sm mb-3">

                                        <dl className="dl-horizontal ">
                                            <QuestionView />
                                        </dl>

                                    </div>
                                </div>
                            </div>
                            <div className="col-10"><div className="d-flex justify-content-end align-items-center mt-3">
                                <div onClick={() => back()} className="btn btn-light mr-1">Back</div>
                                <div onClick={() => next()} className="btn btn-primary ml-1">Continue</div></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

