import React, { useContext } from "react";
import Launch from "../../../img/icons/no-access.png";


function NoAccess() {

    return (
        <>
            <div className="col-lg-10 m-auto"><div className="card"><div className="card-body ">
                <div className="row p-5 justify-content-center align-items-center" style={{}}>
                    <div className="col-12 col-sm-6 col-md-7 col-lg-7  ">
                        <h2 className="font-weight-bold ">Access Denied!</h2>
                        <p className="mt-3 line-hieght-2">Sorry, You are not authorized to access this page.</p>
                        <p className="mt-3 line-hieght-2">Please contact the Administrator.</p>
                        <a href="/dashboard" className="btn btn-primary my-3 ">Back Home</a>
                    </div>
                    <div className="col-12 col-sm-4 col-md-5 col-lg-5  ">
                        <img src={Launch} className="img-fluid" />
                    </div>
                </div>
            </div></div></div>
        </>
    )
};
export default NoAccess;