import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import EntityContext from "../../../context/entityContext";

class BankDetail extends Component {
    static contextType = EntityContext;
    constructor(props) {
        super(props);
    }

    state = {
        loading: false,
        acc_name: "",
        bsb: "",
        acc_no: "",
    }

    componentDidMount = () => {
        let product = this.context.accountDetails.filter(prdt => prdt.product == this.props.productId);
        if (product.length > 0) {
            this.setState({
                acc_name: product[0].nick_name == null ? product[0].bank_account_name : product[0].nick_name,
                bsb: product[0].bsb_number,
                acc_no: product[0].account_number,
            });
        } else {
            toast.error("Bank Details not available")
        }

    }

    render() {
        return (
            <>
                <Modal.Header text-white closeButton>

                </Modal.Header>

                <Modal.Body>

                    <div className="text-center mb-4">
                        <div className="media-icon media-icon-lg bg-primary-transparent text-primary mx-auto mb-4">
                            <i className="fa fa-check-square"></i>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-10">

                                <h5 className="text-primary">Your payment confirmation has been received !</h5>

                                <p className="text-white-50 mb-4 text-sm">Please deposit the amount in your facility bank account details as below
                                </p>
                                <form>
                                    <div className=" ">
                                        <span className=" ">Account Name: {this.state.acc_name}</span><br />
                                        <span className=" ">BSB: {this.state.bsb}</span><br />
                                        <span className=" ">Account number: {this.state.acc_no}</span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>



                </Modal.Body>
            </>
        );
    }
}
export default BankDetail;