import React from "react";
import JoditEditor from "jodit-react";
function Editor({ height, name, value, onChange }) {
    const config = {
        readonly: false,
        uploader: {
            "insertImageAsBase64URI": true
        },
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        height: height ? height : 500 // all options from https://xdsoft.net/jodit/doc/
    }

    return (
        <>
            <JoditEditor
                value={value}
                config={config}
                onBlur={value => onChange({ target: { name, value } })}
            />
        </>
    )
};
export default Editor;