import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import Modal from "react-bootstrap/Modal";
import {
  FormRow,
} from "../../common/containers/formContainer";
import ReactTooltip from 'react-tooltip';

import {
  DateTimePicker,
  CurrencyLabel,
  GeneralButton,
  Picker,
  SummaryButton
} from "../../common/inputs";
import Select from "react-select";

import transactionService from "../../../services/invTransactions";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { transStatus } from "../../../config/predefinedValues.json";
import PaymentSummary from "./_paymentSummary";
import moment from "moment";
import _ from "lodash";

export default function CloseInvoices({
  onClose,
  selectedInvoices,
  handleReloadData,
  showResidualAmt = false
}) {
  //------------Api Services--------------------------------------------
  const transactionApi = useApi(transactionService.getTransactionDetails);
  const ifTransSearchApi = useApi(transactionService.quickSearch);
  const paymentsApi = useApi(transactionService.fetchPayments);
  const feeApi = useApi(transactionService.getFeeDetails); // Custom hook for HTTP Requests
  const closeInvoiceApi = useApi(transactionService.closeInvoices);
  const getResidualAmtApi = useApi(transactionService.getResidualAmountByClosedDate);

  const [paymentsData, setPaymentsData] = useState([]);
  const [selectedTrans, setSelectedTransaction] = useState([]);
  const [invData, setInvoiceData] = useState([]);
  const [feeData, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [isTransfer, setTransfer] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [closedDate, setClosedDate] = useState(new Date());
  const [selectedReason, setSelectedReasons] = useState(null);
  const [inv_closed_comments, setComment] = useState("");
  const [openModal, setModal] = useState(true);
  const [selectedInvoice, setSelectedInvoices] = useState([]);
  const [summaryModal, setSummaryModal] = useState(false);
  const [totalResidualAmt, setTotalResidualAmt] = useState(0);

  const [selectedItem, setSelectedItem] = useState({
    label: "Search",
    value: null,
  });

  const residualTreatments = [
    { label: "Residual, pay client", value: "residual" },
    { label: "Write-off", value: "write-off" },
    { label: "Pursue Client", value: "pursue-client" },
    { label: "Pursue Debtor", value: "pursue-debtor" },
    { label: "Pursue Guarantor", value: "pursue-guarantor" },
    {
      label: "Transfer To Other Transaction",
      value: "transfer-to-other-transaction",
    },
    { label: "Other", value: "other" },
  ];

  const fetchPaymentDetails = async (paymentInfo) => {
    const { data } = await paymentsApi.request({
      trans_guid: paymentInfo[0].trans_guid,
      inv_guid: paymentInfo[0].inv_guid,
    });

    setPaymentsData(data.rows);
  };

  const getFeeList = async (transInfo) => {
    const { data } = await feeApi.request({
      entity_id: transInfo.data.trans_client_guid,
      country: null,
    }); // Calling HTTP service

    setData(data.defaultFees);
  };

  const fetchSuggestions = async (keyword) => {
    if (keyword !== null && keyword.length >= 2) {
      const { data } = await ifTransSearchApi.request({
        keyword: keyword,
        selectedStatus: [transStatus[0]["DRAFT"], transStatus[0]["OPEN"]],
      });
      setItems(data);
    }
  };

  const fetchTransactionDetail = async () => {
    await transactionApi.request({ trans_guid: selectedItem.value });
    setSelectedTransaction(transactionApi.data.data);
  };

  const handleSelectItem = async (transaction) => {
    setSelectedItem(transaction);
    const { data } = await transactionApi.request({
      trans_guid: transaction.value,
    });
    setSelectedTransaction(data);
    // getFeeList(data);
  };

  const handleInputChange = (inputVal) => {
    fetchSuggestions(inputVal);
  };

  const handlePickerChange = (option) => {
    let invoiceArr = [];
    let timeNow = new Date().getTime().toString();
    let last3digits = timeNow.substr(10);
    setSelectedReasons(option);

    if (option.value == "transfer-to-other-transaction") {
      invoiceArr.push({
        index: invoiceArr.length,
        trans_guid: null,
        client_id: null,
        inv_debtor_id: null,
        debtor_name: null,
        inv_date: new Date(),
        inv_due_date: null,
        inv_advance_date: null,
        inv_number: "INV" + last3digits,
        insured: "No",
        pa: { label: "No", value: "no" },
        franchiseFunds: null,
        paFunds: null,
        inv_royalty_percent: null,
        inv_insurance_percent: null,
        inv_amount: 0,
        inv_chargeable_amt: 0,
        disburse_now: false,
      });

      setInvoiceData(invoiceArr);
      setTransfer(true);
    } else {
      setInvoiceData([]);
      setTransfer(false);
    }
  };

  //---------------Handle search key down-----------------//

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 31,
      minHeight: 31,
      border: "1px solid #b0b9c1",
      borderRadius: "4px !important",
      color: "#495057 !important",
    }),
    option: (provided, state) => ({
      ...provided,
      paddingBottom: "6px",
      overflow: "visible",
    }),
  };

  // Handling Async operation to save the data to the server
  const submitInvoiceInfo = async () => {
    if (selectedReason === null) {
      toast.error("Please select the reason to close this invoice.");
      return;
    }
    if (selectedItem.value !== null) {
      if (invData.length > 0) {
        invData.map((item) => {
          item.trans_guid = selectedItem.value;
          item.client_id = selectedTrans.data.trans_client_guid;
        });

        selectedTrans.data.invoices.map((invoiceItem) => {
          if (invoiceItem.inv_guid == selectedItem.inv_guid) {
            invData.map((item) => {
              item.inv_debtor_id = invoiceItem.debtor.a_guid;
              item.debtor_name = invoiceItem.debtor.organization;
              item.inv_due_date = invoiceItem.inv_due_date;
            });
          }
        });
      }
    } else {
      setInvoiceData([]);
    }

    const reqData = {
      closed_date: closedDate,
      reason: selectedReason.value,
      inv_closed_comments: inv_closed_comments,
      transaction: selectedItem,
      isTransferToInvoice: true,
      invTransferData: [...invData],
      invoices: [...paymentInfo.filter((obj) => obj.inv_closed_date === null)],
    };

    const { data } = await closeInvoiceApi.request(reqData);

    if (data.success === false) toast.error(data.message);
    else {
      toast.success(data.message);
      handleReloadData(true);
      onClose();
    }
  };
  //---------------------------------------------//

  useEffect(() => {
    let paymentsArr = [];
    selectedInvoices.map((item) => {
      paymentsArr.push({
        index: paymentsArr.length,
        inv_guid: item.inv_guid,
        trans_guid: item.inv_trans_guid,
        inv_trans_guid: item.inv_trans_guid,
        inv_number: item.inv_number,
        debtor_organisation: item.debtor_organisation,
        inv_chargeable_amt:
          item.inv_chargeable_amt !== null
            ? parseFloat(item.inv_chargeable_amt)
            : 0,
        inv_amt_paid:
          item.total_paid !== null ? parseFloat(item.total_paid) : 0,
        inv_due_amt: item.total_due !== null ? parseFloat(item.total_due) : 0,
        inv_closed_date: item.inv_closed_date,
        client_guid: item.client_guid,
        residual_amt: item.residual_amt ? item.residual_amt : 0
      });
    });
    setPaymentInfo(paymentsArr);
    setTotalResidualAmt(
      _.sumBy(paymentsArr, (item) => Number(item.residual_amt))
    );

    populateRedidualAmt(moment(new Date()).format("YYYY-MM-DD").toString(), paymentsArr);
    
  }, []);

  const handleDisplayInvSummary = (selectedInvoice) => {
    setSelectedInvoices(selectedInvoice);
    setSummaryModal(true);
  };

  const handleDateChange = e => {
    setClosedDate(e.target.value);
    populateRedidualAmt(e.target.value);
  }

  const populateRedidualAmt = async (date, invoices = null) => {
    const { data } = await getResidualAmtApi.request({ invoices: invoices ? invoices : paymentInfo, closed_date: date });
    setPaymentInfo(data.updatedList);
    setTotalResidualAmt(
      _.sumBy(data.updatedList, (item) => Number(item.residual_amt))
    );
  }

  return (
    <>
      {/*----------------Payments Summary Modal-------------------*/}
      <Modal
        show={summaryModal}
        onHide={(e) => setSummaryModal(false)}
        size="lg"
      >
        <PaymentSummary
          onClose={(e) => setSummaryModal(false)}
          selectedInvoice={selectedInvoice}
        />
      </Modal>
      {closeInvoiceApi.loading == true && <LoadingSpinner />}
      <Modal
        show={openModal}
        onHide={(e) => {
          onClose();
          setModal(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <h4 className="card-title">Close Invoice</h4>
        </Modal.Header>

        <Modal.Body>
          {/*--------------------- First Row ------------------------*/}
          {getResidualAmtApi.loading == true && <LoadingSpinner />}
          <div className="form-group row">
            <div className="col-lg-6">
              <label>
                <strong>Closing Date</strong>
              </label>

              <DateTimePicker
                name="closedDate"
                selectedDate={closedDate}
                onChange={handleDateChange}
                maxDate={new Date()}
              />
            </div>
            <div className="col-lg-6">
              <label>
                <strong>Reason</strong>
              </label>

              <Picker
                items={residualTreatments}
                selectedItem={selectedReason}
                onSelectItem={handlePickerChange}
              />
            </div>
          </div>
          {/*--------------------- End of First Row ------------------------*/}

          {/*------------------------ Third Row ---------------------------*/}
          <FormRow style={{ paddingTop: "inherit" }}>
            <table class="table table-sm text-secondary">
              <tbody>
                <tr>
                  <th>Invoice No</th>
                  <th>Debtor</th>
                  <th>Agreement Amt</th>
                  <th>Total Paid</th>
                  <th>To Collect</th>
                  {showResidualAmt && <th>Residual Amt</th>}
                  <th>Action</th>
                </tr>
                {paymentInfo.map((item, index) => (
                  <tr key={index}>
                    <td>{item.inv_number}</td>

                    <td>{item.debtor_organisation}</td>

                    <td>
                      <CurrencyLabel
                        value={item.inv_chargeable_amt}
                      ></CurrencyLabel>
                    </td>

                    <td>
                      <CurrencyLabel value={item.inv_amt_paid}></CurrencyLabel>
                    </td>

                    <td>
                      <CurrencyLabel value={item.inv_due_amt}></CurrencyLabel>
                    </td>

                    {showResidualAmt && 
                      <td>
                        <span className={`font-bold text-${item.residual_amt < 0 ? 'danger' : 'success'}`}>
                          <CurrencyLabel value={item.residual_amt}></CurrencyLabel>
                        </span>
                      </td>
                    }

                    <td>
                      {" "}
                      <Link
                        className="action-icons"
                        onClick={(e) => handleDisplayInvSummary(item)}
                      >
                        <SummaryButton />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </FormRow>
          {/*------------------------ End of Third Row ---------------------------*/}

          {/*--------------------- 4th Row ------------------------*/}
          {isTransfer && (
            <div className="form-group row">
              <div className="col-lg-6">
                <label>
                  <strong>Search</strong>
                </label>
                <div className="form-group">
                  <Select
                    options={items}
                    placeholder="Type referenece no."
                    noOptionsMessage={() => "Start type your search"}
                    onInputChange={handleInputChange}
                    styles={customStyles}
                    value={selectedItem}
                    onChange={(selectedOption) => {
                      handleSelectItem(selectedOption);
                    }}
                    onBlur={(e) => setItems([])}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      border: "1px solid #b0b9c1",
                      colors: {
                        ...theme.colors,
                        text: "black",
                        primary25: "white",
                        primary: "#1ab394",
                        neutral0: "white",
                      },
                    })}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label>
                  <strong>Comments</strong>
                </label>
                <div className="form-group">
                  <textarea
                    type="text"
                    placeholder="Comments"
                    onChange={(e) => setComment(e.target.value)}
                    className={"form-control input-sm"}
                    value={inv_closed_comments}
                    rows={2}
                  />
                </div>
              </div>
            </div>
          )}
          {/*--------------------- End of 4th Row ------------------------*/}

          {showResidualAmt && (
            <div className="row hideWhenPrinting mt-2">
              <div className="col-sm-12">
                <div className={`alert alert-${totalResidualAmt < 0 ? 'danger' : 'primary'} text-center`} class="alert alert-primary text-center" role="alert">
                  <div className="m-b-md">
                    <h6 className="m-xs">
                        <i data-tip="Residual Amount = Payment Received - Advance Amount - Discount Fees inc LPF - All other fees/charges." class="fa fa-info-circle"></i>&nbsp;
                        {totalResidualAmt < 0 ? "AMOUNT OWED BY CLIENT" : "RESIDUAL AMOUNT"}
                    </h6>
                    <h4 className="font-bold no-margins">
                        <CurrencyLabel value={totalResidualAmt} />
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ReactTooltip backgroundColor="#11698e" />
        </Modal.Body>

        <Modal.Footer>
          <FormRow>
            <GeneralButton
              onClick={submitInvoiceInfo}
              /*faIcon="fa fa-save"*/ className="btn btn-primary pull-right m-l-xs"
              name="Confirm"
            ></GeneralButton>
          </FormRow>
        </Modal.Footer>
      </Modal>
    </>
  );
}
