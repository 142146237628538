import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router";
import { browserHistory } from "react-router";

function GetStartedGeneral({ location }) {

    return (
        <React.Fragment>
            <Helmet>
                <title>{location.state.header} | {global.config.title}</title>
            </Helmet>

            <div className="card">
                <div className="row">
                    <div className="col-lg-12">
                        <Link className="btn btn-light pull-right text-secondary mr-2 mt-2" onClick={() => browserHistory.push('/client/settings')}><i alt=" Settings" data-toggle="tooltip" data-placement="top" title="Close" className="fa fa-close"></i></Link>
                    </div>
                </div>

                <div className="mb-0">
                    <div className="card-body">
                        <div className="row min-vh-50 justify-content-center align-items-center py-2">
                            <div className="col-lg-5 col-md-12">
                                <h2>{location.state.header}</h2>
                                <p className="mt-3 line-hieght-2">{location.state.content}</p>
                                {location.state.content2 && <p className="mt-3 line-hieght-2">{location.state.content2}</p>}
                                <p className="mt-4">
                                    {location.state.secondaryBtn &&
                                        <Link
                                            className="btn btn-sm btn-secondary"
                                            to={{ pathname: location.state.secondaryBtn.path }}
                                        >
                                            {location.state.secondaryBtn.btnLabel}
                                        </Link>
                                    }

                                    {location.state.primaryBtn &&
                                        <Link
                                            className="btn btn-sm btn-primary"
                                            to={{ pathname: location.state.primaryBtn.path }}
                                        >
                                            {location.state.primaryBtn.btnLabel}
                                        </Link>
                                    }
                                </p>
                            </div>
                            <div className="col-lg-4 offset-lg-1 col-md-12">
                                <img src={location.state.img} alt="image" height={400} />
                            </div>
                        </div>
                        <div style={{ height: 80 }}>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}
export default GetStartedGeneral;