import React, { lazy } from "react";
import { Route } from "react-router";
import routeURL from "./routeConstants";

// -- Importing components from SC Module
const Rates = lazy(() => import("../../supply-chain/components/rates/main"));

const InvoiceBulkUpload = lazy(() =>
  import("../../supply-chain/components/transactions/invoiceBulkUpload/main")
);
const TfInvoiceBulkUpload = lazy(() =>
  import("../../supply-chain/components/transactions/invoiceBulkUpload/tfMain")
);
const SCFBulkMigration = lazy(() =>
  import("../../supply-chain/components/transactions/invoiceBulkUpload/scfBulkMigration")
);
const TFBulkMigration = lazy(() =>
  import("../../supply-chain/components/transactions/invoiceBulkUpload/tfBulkMigration")
);

const ClosedOutstandingInvoices = lazy(() =>
  import("../components/transactions/closedInvoicesList/closed-outstanding")
);

const ClosedOutstandingInvoicesTF = lazy(() =>
  import("../components/transactions/closedInvoicesList/closed-outstanding-tf")
);


const TransactionLog = lazy(() => import("../../auditTrail/transactionLog"));
// -- Implementing routes for SC Module
const supplyChainRoutes = (
  <>
  
    <Route path={routeURL.RATES} component={Rates}></Route>
    <Route path={routeURL.INVOICE_LOG} component={TransactionLog}></Route>
    <Route
      path={routeURL.INVOICE_BULK_UPLOAD}
      component={InvoiceBulkUpload}
    />

    <Route path={'/scinvoice/bulk-upload/step1'} component={InvoiceBulkUpload} />
    <Route path={'/scinvoice/bulk-upload/step2'} component={InvoiceBulkUpload} />
    <Route path={'/scinvoice/bulk-upload/step3'} component={InvoiceBulkUpload} />
    <Route path={'/scinvoice/bulk-upload/step4'} component={InvoiceBulkUpload} />
    <Route path={'/scinvoice/bulk-upload/step5'} component={InvoiceBulkUpload} />
    <Route path={'/scinvoice/bulk-upload/step6'} component={InvoiceBulkUpload} />
    <Route path={'/scinvoice/bulk-upload/step7'} component={InvoiceBulkUpload} />

    <Route
      path={routeURL.TF_INVOICE_BULK_UPLOAD}
      component={TfInvoiceBulkUpload}
    />

    <Route path={'/tf-invoice/bulk-upload/step1'} component={TfInvoiceBulkUpload} />
    <Route path={'/tf-invoice/bulk-upload/step2'} component={TfInvoiceBulkUpload} />
    <Route path={'/tf-invoice/bulk-upload/step3'} component={TfInvoiceBulkUpload} />
    <Route path={'/tf-invoice/bulk-upload/step4'} component={TfInvoiceBulkUpload} />
    <Route path={'/tf-invoice/bulk-upload/step5'} component={TfInvoiceBulkUpload} />
    <Route path={'/tf-invoice/bulk-upload/step6'} component={TfInvoiceBulkUpload} />
    <Route path={'/tf-invoice/bulk-upload/step7'} component={TfInvoiceBulkUpload} />

    <Route
      path={'/scinvoice/stream-migration/bulk-scf-505'}
      component={SCFBulkMigration}
    />

    <Route
      path={'/scinvoice/stream-migration/bulk-tf-905'}
      component={TFBulkMigration}
    />    

  <Route
    path={'/scinvoice/closed-outstanding'}
    component={ClosedOutstandingInvoices}
  /> 

  <Route
    path={'/tfinvoice/closed-outstanding'}
    component={ClosedOutstandingInvoicesTF}
  />    


        
  </>
);

export default supplyChainRoutes;
