import React from "react";
import { Link } from "react-router";

const SubmitButton = (props) => (
  <React.Fragment>
    <button
      className={
        props.className ? props.className : "btn btn-primary btn-sm pull-right"
      }
      type="submit"
    >
      {props.label}
    </button>
  </React.Fragment>
);

const AddButton = (props) => (
  <React.Fragment>
    <Link to={props.route}>
      <button
        type="button"
        id={props.id}
        className={
          props.style ? props.style : "btn btn-primary btn-sm pull-right"
        }
        onClick={props.onClick && props.onClick}
        disabled={props.disabled}
        title={props.title ? props.title : ""}
      >
        {props.label}
      </button>
    </Link>
  </React.Fragment>
);

const EditButton = (props) => (
  <React.Fragment>
    {props.pullRight && (
      <i
        alt="Edit"
        data-toggle="tooltip"
        data-placement="left"
        title="Edit1"
        className="fa fa-edit pull-right"
        onClick={props.onClick}
      ></i>
    )}

    {!props.pullRight && (
      <i
        alt="Edit"
        data-toggle="tooltip"
        data-placement="left"
        title="Edit"
        className="fa fa-edit"
        onClick={props.onClick}
      ></i>
    )}
  </React.Fragment>
);

const CopyButton = (props) => (
  <React.Fragment>
    {props.pullRight && (
      <i
        alt="Copy"
        data-toggle="tooltip"
        data-placement="left"
        title="Copy"
        className="fa fa-copy pull-right"
        onClick={props.onClick}
      ></i>
    )}

    {!props.pullRight && (
      <i
        alt="Copy"
        data-toggle="tooltip"
        data-placement="left"
        title="Copy"
        className="fa fa-copy"
        onClick={props.onClick}
      ></i>
    )}
  </React.Fragment>
);

const EmailButton = (props) => (
  <React.Fragment>
    {props.pullRight && (
      <i
        alt="Email"
        data-toggle="tooltip"
        data-placement="left"
        title="Email"
        className="fa fa-envelope-o pull-right"
        onClick={props.onClick}
      ></i>
    )}

    {!props.pullRight && (
      <i
        alt="Email"
        data-toggle="tooltip"
        data-placement="left"
        title="Email"
        className="fa fa-envelope-o"
        onClick={props.onClick}
      ></i>
    )}
  </React.Fragment>
);

const ViewButton = (props) => (
  <React.Fragment>
    {props.pullRight && (
      <i
        alt="Edit"
        data-toggle="tooltip"
        data-placement="left"
        title="View"
        className="fa fa-edit pull-right"
        onClick={props.onClick}
      ></i>
    )}

    {!props.pullRight && (
      <i
        alt="View"
        data-toggle="tooltip"
        data-placement="left"
        title="View"
        className={props.icon ? props.icon : "fa fa-eye"}
        onClick={props.onClick}
      ></i>
    )}
  </React.Fragment>
);

const SummaryButton = (props) => (
  <React.Fragment>
    <i
      data-toggle="tooltip"
      data-placement="left"
      title={props.title ? props.title : "View Summary"}
      className="fa fa-list-alt"
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);

const DeleteButton = (props) => (
  <React.Fragment>
    {props.pullRight && (
      <i
        alt="Delete"
        data-toggle="tooltip"
        data-placement="top"
        title="Delete"
        className="fa fa-trash pull-right text-secondary"
        onClick={props.onClick}
      ></i>
    )}

    {!props.pullRight && (
      <i
        alt="Delete"
        data-toggle="tooltip"
        data-placement="top"
        title="Delete"
        className="fa fa-trash"
        onClick={props.onClick}
      ></i>
    )}
  </React.Fragment>
);

const GeneralButton = (props) => (
  <React.Fragment>
    {props.pullRight && (
      <React.Fragment>
        <button
          className={
            props.className
              ? props.className
              : "btn btn-primary btn-sm float-right text-success"
          }
          type={props.type ? props.type : "submit"}
          onClick={props.onClick}
          disabled={props.disabled ? props.disabled : false}
          style={props.style}
          title={props.title ? props.title : false}
          id={props.id ? props.id : props.name}
        >
          {props.name}
        </button>
      </React.Fragment>
    )}

    {!props.pullRight && (
      <button
        className={
          props.className
            ? props.className
            : "btn btn-primary btn-sm float-right"
        }
        type={props.type ? props.type : "submit"}
        onClick={props.onClick}
        disabled={props.disabled ? props.disabled : false}
        style={props.style}
        title={props.title ? props.title : false}
        id={props.id ? props.id : props.name}
      >
        {props.faIcon ? " " + props.name : props.name}
      </button>
    )}
  </React.Fragment>
);

const GeneralIconButton = (props) => (
  <React.Fragment>
    <Link to={props.route}>
      <button
        className={props.className ? props.className : "btn btn-primary btn-sm"}
        type={props.type ? props.type : "button"}
        onClick={props.onClick}
        disabled={props.disabled ? props.disabled : false}
      >
        {props.faIcon ? (
          <>
            <i className={props.faIcon}></i>&nbsp; {props.name}
          </>
        ) : (
          props.name
        )}
      </button>
    </Link>
  </React.Fragment>
);

const GridActionButton = (props) => (
  <React.Fragment>
    <button
      className={props.class ? props.class : "btn btn-outline-secondary mr-2"}
      onClick={(e) =>
        props.handleClick
          ? props.handleClick()
          : console.log("Action button triggered.")
      }
      style={{ height: props.extraHeight ? "46px" : "" }}

    >
      {props.label}
    </button>
  </React.Fragment >
);
const NotifyButton = (props) => (
  <React.Fragment>
    <button
      className="btn btn-primary"
      onClick={(e) =>
        props.handleClick
          ? props.handleClick()
          : console.log("Action button triggered.")
      }
    >
      {" "}
      <i className="fa fa-bell"></i>&nbsp;{props.label}{" "}
    </button>
  </React.Fragment>
);

const DisburseButton = (props) => (
  <React.Fragment>
    <i
      data-toggle="tooltip"
      data-placement="left"
      title={"Disburse"}
      className="fa fa-money"
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);

const ParticipantButton = (props) => (
  <React.Fragment>
    <i
      data-toggle="tooltip"
      data-placement="left"
      title={"Add Participant"}
      className="fa fa-user"
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);

const RemoveButton = (props) => (
  <React.Fragment>
    <i
      alt="Remove"
      data-toggle="tooltip"
      data-placement="top"
      title={props.title}
      className="fa fa-trash pull-right"
      onClick={props.onClick}
    ></i>
  </React.Fragment>
);


export {
  AddButton,
  EditButton,
  ViewButton,
  DeleteButton,
  SubmitButton,
  GeneralButton,
  GridActionButton,
  CopyButton,
  SummaryButton,
  GeneralIconButton,
  NotifyButton,
  DisburseButton,
  ParticipantButton,
  RemoveButton,
  EmailButton
};
