import React, { Component } from "react";
import { Helmet } from "react-helmet";
import MappedUsers from "./mappedUsers";
import DraftAccounts from "./draftAccounts";
import { Link } from "react-router";
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";
import { browserHistory } from "react-router";
import { Collapse, Card } from 'react-bootstrap';
class ClientNetworkSupplier extends Component {

    static contextType = EntityContext;

    constructor(props) {
        super(props);
        this.state = {
            showChild: true,
            enabledProducts: [],
            showDraft: false,
            cardToggle: true,
            showPopout: false
        }
    }
    handleReadloadChild = () => {
        this.setState({
            showChild: false
        })

        setTimeout(() => {
            this.setState({
                showChild: true
            })
        }, 100);
    }

    handleReloadAll = () => {
        this.setState({
            showChild: false,
            showDraft: false,
        })

        setTimeout(() => {
            this.setState({
                showChild: true,
                showDraft: true,
            })
        }, 100);
    }

    componentDidMount() {
        this.context.handleRoleChange(null);
        this.context.handleProductChange(null);
        this.checkIsProductEnabled();
    }

    checkIsProductEnabled = () => {
        const { constants } = authService.getCurrentUser();
        let enabledProducts = [];
        if (this.context.entityDetails !== undefined) {
            enabledProducts = this.context.entityDetails.products.filter(obj =>
            (obj.isChecked === true && (
                obj.id == constants.PRODUCTS.SUPPLYCHAIN ||
                obj.id == constants.PRODUCTS.TRADEFINANCE
            )
            ));
            if (enabledProducts.length > 0) {
                this.setState({ showDraft: true });
                window.scrollTo(0, 0);
            } else {
                if (this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.getStart !== undefined && this.props.location.state.getStart === true) {
                    this.setState({ showDraft: true });
                    window.scrollTo(0, 0);
                } else {
                    browserHistory.push({
                        pathname: '/client/users-network/start-suppliers',
                    });
                }
            }
            this.setState({ enabledProducts: enabledProducts });

        } else {
            this.setState({ enabledProducts: [] });
        }

    }

    toggleCard = () => {
        this.setState({ cardToggle: !this.state.cardToggle });
    }
    handlePopoutClick = () => {
        this.setState({ showPopout: true })
    };

    closePopout = () => {
        this.setState({ showPopout: false })
    };

    render() {

        return (
            <>
                <Helmet>
                    <title>{this.context.terminology.SUPPLIER}s | {global.config.title}</title>
                </Helmet>
                <>
                    {/*  <p>
                        <a
                            className="btn btn-primary"
                            data-toggle="collapse"
                            href="#collapseExample"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                        >
                            Link with href
                        </a>
                        <button
                            className="btn btn-primary"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                        >
                            Button with data-target
                        </button>
                    </p>
                    <div className="collapse" id="collapseExample">
                        <div className="card card-body">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                            richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes
                            anderson cred nesciunt sapiente ea proident.
                        </div>
        </div>*/}
                </>
                {
                    this.state.enabledProducts && this.state.enabledProducts.length > 0 && (
                        <>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card ">
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-lg-10">

                                                    <h6>Linked {this.context.terminology && this.context.terminology.SUPPLIER}s</h6>
                                                </div>
                                                <div className="col-lg-2">
                                                    {/* <div className="pull-right" > */}

                                                    <Link title="Close and go to dashboard" className="ml-2 pull-right btn btn-light text-secondary" to="/client/home">
                                                        <i className="fa fa-close "></i>
                                                    </Link>
                                                    {this.state.showPopout === false && (
                                                        <a className="btn btn-light pull-right text-secondary ml-2" onClick={this.handlePopoutClick} >
                                                            <i className="fa fa-expand"></i>
                                                        </a>
                                                    )}
                                                    {/* </div> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="collapse show" id="collapseSuppliers">
                                                <div  >
                                                    {this.state.showChild ?
                                                        <MappedUsers
                                                            showPopout={this.state.showPopout}
                                                            closePopout={this.closePopout}
                                                            location={this.props.location}
                                                            accountType="account"
                                                            title="Supplier"
                                                            user_type="supplier"
                                                            reloadAll={this.handleReloadAll}
                                                        /> : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="row justify-content-center ">
                                                <div className="col-lg-4 d-flex justify-content-center ">
                                                    <Link title={` ${this.state.cardToggle ? "Show Less" : "Show More"}  `}
                                                        className=" text-secondary media-icon bg-light"
                                                        onClick={() => { this.toggleCard() }}
                                                        data-toggle="collapse"
                                                        to="#collapseSuppliers"
                                                        role="button"
                                                        aria-expanded="false"
                                                        aria-controls="collapseSuppliers"
                                                    >
                                                        <i className={`fa fa-2x fa-angle-double-${this.state.cardToggle ? "up" : "down"}`}></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }

                {
                    this.state.showDraft === true && (
                        <>
                            <div className="row">
                                <div className="col-sm-12">

                                    <DraftAccounts
                                        type="supplier"
                                        reloadChild={this.handleReadloadChild}
                                        action={
                                            this.props.location.query.action ?
                                                this.props.location.query.action : null
                                        }
                                        showAssign={this.state.enabledProducts.length > 0 ? true : false}
                                    />
                                </div>

                            </div>
                        </>
                    )
                }

            </>
        );
    }
}
export default ClientNetworkSupplier;