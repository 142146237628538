import React, { useEffect, useState } from "react";
import PublicFooter from "../../../publicInvoice/publicFooter";
import PublicHeader from "../../../publicInvoice/publicHeader";
import PublicInvoiceContext from "../../../supply-chain/context/PublicInvoiceContext";
import rate_services from "../../../supply-chain/services/rate_services";
import useApi from "../../../../hooks/useApi";
import { useLocation } from "react-router-dom";

const PublicInvoiceClientLayout = (props) => {
  const [clientLogo, setClientLogo] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mappingId = queryParams.get("guid");

  const logoDetail = useApi(rate_services.fetchLogo);

  const fetchLogoDetails = async () => {
    try {
      const { data } = await logoDetail.request({
        mapping_guid: mappingId,
      });
      
      setClientLogo(data.result.logo);
    } catch (error) {}
  };
  useEffect(() => {
    fetchLogoDetails();
  }, []);

  const value = {
    clientLogo: clientLogo,
  };
  return (
    <>
      <PublicInvoiceContext.Provider value={value}>
        <PublicHeader />
        {props.children}
        <PublicFooter />
      </PublicInvoiceContext.Provider>
    </>
  );
};

export default PublicInvoiceClientLayout;
