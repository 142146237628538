import React from "react";
import "./css/card.css";
import { FormColumnMd } from "../../../common/containers/formContainer";
import CardSkelton from "./_cardSkeltonTypeTwo";


function SecondaryTypeOneCard({ col, loading, data }) {

    return (
        <>
            {loading === true ? <CardSkelton col={col} top={true} /> : (
                <FormColumnMd col={col ? col : 4} >
                    <div className="blue-card2">
                        
                        <div className="p-3">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-xs-8 col-sm-8 col-8">
                                    <label className="p-0 m-0 bold-txt">{data ? data.topLabel : ""}</label><br />
                                    <small>{data ? data.topSubLabel : ""}</small>
                                    <h3 className="p-0 mb-2 amount text-lg">{data ? data.topValue : ""}</h3>
                                </div>

                                <div className="col-lg-4 col-md-4 col-xs-4 col-sm-4 col-4 text-right">
                                    <button className="btn card-btn btn-blue">View</button>
                                    <p className="bold-txt text-faded mt-2">{data ? data.topLeftLabel : ""}</p>
                                </div>

                            </div>
                        </div>

                        <div className="blue-card2-ftr p-3">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-6 col-sm-6 col-6">
                                    <h4 className="  p-0 m-0">{data ? data.bottomLeftValue : " "}</h4>
                                    <label className="p-0 m-0">{data ? data.bottomLeftLabel : ""}</label>
                                </div>

                                <div className="col-lg-6 col-md-6 col-xs-6 col-sm-6 col-6">
                                    <h4 className=" p-0 m-0">{data ? data.bottomRightValue : ""}</h4>
                                    <label className="p-0 m-0">{data ? data.bottomRightLabel : ""}</label>
                                </div>

                                <div className="col-md-4">
                                    <h4 className="card-name m-0 p-0">Processing</h4>
                                </div>

                            </div>
                        </div>
                    </div>
                </FormColumnMd>
            )}
        </>
    );
}

export default React.memo(SecondaryTypeOneCard);