import React, { useEffect, useContext } from "react";
import FifoTabs from "../../components/_fifoTabs";
import RoleChecker from "../../../../utils/roleChecker";
import authService from "../../../../services/authService";
import EntityContext from "../../../../context/entityContext";
import NoAccess from "../../widgets/noAccess";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
import ProcessingFacility from "../../widgets/general/processingFacility";

export default function IFLayout (props) {

    const { constants } = authService.getCurrentUser();

    useEffect(() => {

    }, []);

    return(
        <>
            <Helmet>
                <title>OCR | {global.config.title}</title>
            </Helmet>
            {props.children}
        </> 
    )    
}