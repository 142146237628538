import React, { useEffect, useContext, useState } from "react";
import Joi from "joi-browser";
import useApi from '../../../../hooks/useApi';
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";
import {FormGroup } from "../../../common/containers/formContainer";
import Modal from "react-bootstrap/Modal";
import useForm from "../../../../hooks/useForm";
import {
    GeneralButton,
    CurrencyText,
    DateTimePicker,
} from "../../../common/inputs";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";

function RequestDisburse({ availableAmount,onClose }) {

    const requestDisbursementApi = useApi(dfTransactionService.RequestDisbursement);

    const schema = Joi.object() // Form validation schema object
        .keys({
            disb_amount: Joi.string().required().label("Disbursed Amount"),
            date: Joi.date()
                .required()
                .error(() => {
                    return {
                        message: "Date cannot be blank.",
                    };
                }),
        })
        .unknown(true);

    const addHandler = async() => {

        if(values.disb_amount < 0){
            toast.error("Requested amount must be greater than zero");
            return false;
        }

        if (availableAmount.props.value < values.disb_amount) {
            toast.error("Requested amount must be less than or equal to Undrawn Availability");
            return false;
        }

        const reqData = {
            disbData: values,
        };

        const { data } = await requestDisbursementApi.request(reqData);
        if (data.success === false) toast.error(data.message);
        else {
            toast.success(data.message);
            onClose();
        }
    }
    const {
        values,
        setValues,
        handleChange,
        handlePickerChange,
        handleSubmit,
    } = useForm(addHandler, schema);

     useEffect(() => {
        setValues({
            ...values,
            date:null,
            disb_amount: null, 
        });
    },[]);



    return (
        <>
            <Modal.Header closeButton>
                <h4 className="modal-title">Request Disbursement</h4>
            </Modal.Header>

            <Modal.Body>
                {
                    requestDisbursementApi.loading && (<LoadingSpinner/>)
                }
                <form onSubmit={handleSubmit} className="form-horizontal">
                    <div className="row">
                        {/*-------------Offer to sell starts-------------------------------------*/}
                        <div className="col-lg-12">

                            <div className="row">
                                <div className="col-lg-6">
                                    <FormGroup
                                        label="Disbursed Amount"
                                        type=" "
                                        input={
                                            <CurrencyText
                                                value={values.disb_amount}
                                                onChangeEvent={(event, maskedvalue, floatvalue) => {
                                                    setValues({
                                                        ...values,
                                                        disb_amount: floatvalue, // credit type - 1
                                                    });
                                                }}
                                                placeholder="Amount"
                                            />
                                        }
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <FormGroup
                                        label="Date"
                                        type=" "
                                        input={
                                            <DateTimePicker
                                                name="date"
                                                selectedDate={values.date}
                                                onChange={(value) => handleChange(value)}
                                                minDate={new Date()}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row m-t-sm">
                        <div className="col-lg-12">
                            <GeneralButton
                                disabled={requestDisbursementApi.loading}
                                faIcon="fa fa-save"
                                className="btn btn-sm btn-primary pull-right m-l-xs"
                                name="Save"
                            ></GeneralButton>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </>
    )
}
export default RequestDisburse;