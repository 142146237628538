import React, { useState, useContext } from "react";
import Joi from "joi-browser";

const UploaderContext = React.createContext()

export function useUploader() {
    return useContext(UploaderContext)
}

export function ContextProvider({ children }) {

    const [roleTypes, setRoleTypes] = useState([]);
    const [defaultProducts, setProducts] = useState([]);
    const [accounts, setAccounts] = useState([]); // Context state to store the account rows
    const [uniqueAccounts, setUniqueAccounts] = useState([]); // After removing the duplicate accounts
    const [duplicateAccountInSheet, setduplicateAccountInSheet] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState([]); // Context state to store the list of uploaded invoices
    const [proceedNext, setProceedNext] = useState(true); // Enable or Disable 'Next' button within each component
    const [readyToUpload, setReadyToUpload] = useState([]); // Context state to store the account rows after validation, which are free of error
    const [uploadedFileData, setUploadedFileData] = useState([]); // holds file uploaded file data

    const [hideNextBtn, sethideNextBtn] = useState(false);
    const [hidePreviousBtn, sethidePreviousBtn] = useState(false);
    const [nextLabel, setnextLabel] = useState("Next");
    const [prevLabel, setprevLabel] = useState("Previous");
    const [uploading, setUploading] = useState(0);
    const [excelFile, setExcelFile] = useState(null);

    const [country, setCountry] = useState();
    const [owner, setOwner] = useState(null);

    //------------------Validation Schema-----------------------------
    const schema = Joi.object() // Form validation schema object
        .keys({

            Organisation: Joi.string()
                .required()
                .error(() => {
                    return { message: "Please enter a valid Organisation Name" };
                }),

            First_Name: Joi.string()
                .required()
                .error(() => {
                    return { message: "Please enter a valid First Name" };
                }),

            Last_Name: Joi.string()
                .required()
                .error(() => {
                    return { message: "Please enter a valid Last Name" };
                }),

            Email: Joi.string()
                .email()
                .error(() => {
                    return { message: "Please enter a valid Email" };
                }),
            ABN: Joi.string()
                .required()
                .error(() => {
                    return { message: "Please enter a valid ABN" };
                }),

            // Phone: Joi.string()
            //     .regex(/^$|^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
            //     .error((errors) => { return { message: "Please enter a valid Phone Number" }; 
            // }),

        })
        .unknown(true);

    //---------------------Handle Validation-------------------//
    const validate = (excelData) => {
        const options = { abortEarly: true };

        excelData.map(item => {
            const { error } = Joi.validate(item, schema, options);
            if (error) {
                for (let errobj of error.details)
                    item.error = errobj.message
            }

            return item;
        })
    };
    //--------------------------------------------------------------//

    // Method to handle uploading file items
    const handleUploadItems = (excelData, filesList = null) => {

        let filteredData = excelData.map(obj => {
            if(obj.Phone !== undefined)
                obj.Phone = obj.Phone.toString().trim();
            return obj;
        })

        filterList(filteredData); // Finding duplicate accounts, and push it into new array.

        //if (accounts.length == 0) 
        setAccounts(filteredData);

        if (filesList) {
            let list = [...uploadedFiles];
            filesList.map((fileItem) => {
                list.push(fileItem);
            });
            setUploadedFiles(list);
            handleFileReader(list);
        }

    };

    const handleFileReader = (inputFiles) => {
        /** --------------- Reading local files in JavaScript -------------- */
        let files = inputFiles.map(fileItem => fileItem.obj);
        let fileslist = [];
        files.map(fileItem => {
          let reader = new FileReader();
          reader.readAsDataURL(fileItem);
          reader.onload = e => {
            fileslist.push({
              name: fileItem.name,
              file: e.target.result,
              type: fileItem.type,
              size: fileItem.size
            });
          };
        });
        setUploadedFileData(fileslist);
        return fileslist;
        /** ----------------------------------------------------------------- */
      };

    // Finding duplicate accounts
    const filterList = rows => {
        let accountList = [];
        let duplicateAccounts = [];

        rows.map((accountItem, index) => {
            accountItem.index = index;
            accountItem.error =
                accountItem.error !== undefined ? accountItem.error : null;

            accountItem.acctype = accountItem.ABN ? "aus" : "intl";
            accountItem.Phone = accountItem.Phone ? accountItem.Phone.toString() : null;
            accountItem.ABN = accountItem.ABN ? accountItem.ABN.toString() : null;
            accountItem.Debtor_ID = accountItem.Debtor_ID ? accountItem.Debtor_ID.toString() : null;

            if (accountItem.Email) {
                if (accountList.findIndex(obj => obj.Email == accountItem.Email.toLowerCase()) === -1) {
                    //accountList.push({ ...accountItem, Email: accountItem.Email.toLowerCase() });
                    if (accountItem.ABN) {
                        if (accountList.findIndex(obj => obj.ABN == accountItem.ABN) === -1) {
                            accountList.push({ ...accountItem, Email: accountItem.Email.toLowerCase() });
                        } else {
                            duplicateAccounts.push({ ...accountItem, ABN: accountItem.ABN, Email: accountItem.Email.toLowerCase() });
                        }
                    } else {
                        accountList.push({ ...accountItem, Email: accountItem.Email.toLowerCase() });
                    }
                } else {
                    // duplicateAccounts += 1;
                    duplicateAccounts.push({ ...accountItem, Email: accountItem.Email.toLowerCase() });
                }
            } else{
                if (accountItem.ABN) {
                    if (accountList.findIndex(obj => obj.ABN == accountItem.ABN) === -1) {
                        accountList.push({ ...accountItem, Email: accountItem.Email ? accountItem.Email.toLowerCase() : null });
                    } else {
                        duplicateAccounts.push({ ...accountItem, ABN: accountItem.ABN, Email: accountItem.Email ? accountItem.Email.toLowerCase() : null });
                    }
                } else {
                    accountList.push(accountItem);
                }
            }
                
        });

        setduplicateAccountInSheet(duplicateAccounts);
        setUniqueAccounts(accountList);
        validate(rows);
    }

    // This function will trigger from the Edit Accounts Modal
    const updateAccount = data => {
        let AccountsList = [...accounts];
        data['error'] = null;
        AccountsList[data.index] = data;
        setAccounts(AccountsList);
        filterList(AccountsList);
    }

    const deleteAccount = index => {
        let AccountsList = accounts.filter(obj => obj.index !== index);
        setAccounts(AccountsList);
        filterList(AccountsList);
    }

    const handleRoleChange = (items) => {
        setRoleTypes(items);
    };

    const removeUploadedSheet = fileName => {
        //let updatedFilesList = uploadedFiles.filter(file => file.name !== fileName);
        setUploadedFiles([]);
        setAccounts([]);
        setUniqueAccounts([]);
        setduplicateAccountInSheet(0);
        setReadyToUpload([]);
    }

    const value = {
        accounts,
        handleUploadItems,
        updateAccount,
        deleteAccount,
        uploadedFiles,
        proceedNext,
        setProceedNext,
        hideNextBtn,
        sethideNextBtn,
        hidePreviousBtn,
        sethidePreviousBtn,
        nextLabel,
        setnextLabel,
        prevLabel,
        setprevLabel,
        uniqueAccounts,
        setUniqueAccounts,
        duplicateAccountInSheet,
        uploading,
        setUploading,
        country,
        setCountry,
        owner,
        setOwner,
        handleRoleChange,
        roleTypes,
        defaultProducts,
        setProducts,
        removeUploadedSheet,
        readyToUpload, 
        setReadyToUpload,
        uploadedFileData,
        setUploadedFileData,
        excelFile,
        setExcelFile
    }

    return <UploaderContext.Provider value={value}>{children}</UploaderContext.Provider>
}