import React, { useRef, useEffect, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { toast } from "react-toastify";
import _ from "lodash";
import ReactTable from "react-table";
import moment from "moment";
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import authService from "../../../../services/authService";
import loanService from "../../../loan/services/loanTransactions";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import DDScheduleList from "./_getscheduleDetails";
// import { confirmAlert } from "react-confirm-alert";
// import Utility from "../../../../utils/DateParser";
import externalApiService from "../../../../services/externalApiService";
import { CountriesList,  CustomersList, BPList } from '../../../slplusTools';
import { ReportInputFieldContainer, FormRow, FormColumn } from "../../../common/containers/formContainer";
import { GeneralButton,  CurrencyLabel } from "../../../common/inputs";

function PendingRequest(props) {

    const { constants, category, country_id } = authService.getCurrentUser();

    const [country, setCountry] = useState(null);
    const [owner, setOwner] = useState(null);
    const [client, setClient] = useState(null);
    const [awaitingList, setAwaitingList] = useState([]);
    const [ddScheduleListModal, setDdScheduleListModal] = useState(false);
    const [scheduleItem, setScheduleItem] = useState(null);

    // Api list
    const getPendingListApi = useApi(loanService.GetPendingPayments);
    const getPaymentStatusApi = useApi(externalApiService.getManoovaPaymentStatus);

    const getPendingPaymentList = async () => {

        const response = await getPendingListApi.request({
            owner: owner,
            client: client
        });
        if (response) {
            setAwaitingList(response.data.rows);
        } else {
            toast.error("Failed to fetch data");
        }
    }
    const handleShowScheduleDetail = (item) => {
        setDdScheduleListModal(true);
        setScheduleItem({
            client: item.entity_guid,
            batchId: item.reference_guid
        });
    }

    const handleGetPaymentStatus = async (batchId, entityId) => {
        const { data } = await getPaymentStatusApi.request({
            refGuid: batchId,
            entityGuid: entityId,
            product_type: constants.PRODUCTS.LOAN
        });
        if (data.success) {
            toast.success(data.message);
            getPendingPaymentList();
        } else {
            toast.error(data.message);
        }
    }

    useEffect(() => {
        if (category == constants.CATEGORY.COUNTRY_OFFICE) {
            setCountry(country_id);
        }
        getPendingPaymentList();
    }, []);

    const cols = [
        {
            Header: "Organization",
            accessor: "organization",
            headerStyle: { textAlign: "left" },
            Cell: row => {
                return (
                    <>
                        <span>{row.original.organization}</span>
                    </>
                );
            },
            sortable: false,
        },
        {
            Header: "Amount",
            accessor: "amount",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <span>
                    <CurrencyLabel
                        value={row.original.amount}
                    ></CurrencyLabel>
                </span>
            ),
            sortable: false,
        },
        {
            Header: "Schedules",
            accessor: "schedule_count",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <Link
                    className="cursor-link"
                    onClick={() => { handleShowScheduleDetail(row.original) }}
                >
                    {row.original.schedule_count}
                </Link>
            ),
            sortable: false,
        },
        {
            Header: "Scheduled Date",
            accessor: "added_date",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <>
                    {
                        moment(row.original.added_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()
                    }
                </>
            ),
            sortable: false,
        },
        {
            Header: "Payment process start",
            accessor: "manoova_requested_date",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <>
                    {
                        moment(row.original.manoova_requested_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()
                    }
                </>
            ),
            sortable: false,
        },
        {
            Header: "Status",
            accessor: "schedule_status",
            headerStyle: { textAlign: "left" },
            Cell: row => {
                return (
                    <>
                        <span>{_.startCase(row.original.schedule_status)}</span>
                    </>
                );
            },
            sortable: false,
        },
        {
            Header: "Payment Status",
            accessor: "payment_status",
            headerStyle: { textAlign: "left" },
            Cell: row => {
                return (
                    <>
                        <span>{_.startCase(row.original.payment_status)}</span>
                    </>
                );
            },
            sortable: false,
        },
        {
            Header: "Action",
            accessor: "reference_guid",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <>
                    <Link
                        className="cursor-link action-icon "
                        title="View current Payment status"
                        onClick={() => {
                            handleGetPaymentStatus(row.original.reference_guid, row.original.entity_guid);
                        }}
                    >
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    </Link>
                </>
            ),
        }

    ];

    return (
        <>
            {
                (
                    getPendingListApi.loading == true ||
                    getPaymentStatusApi.loading == true
                ) && <LoadingSpinner />
            }
            <div className="row">
                <div className="col-sm-12">
                    <FormRow>
                        <ReportInputFieldContainer
                            label={category == constants.CATEGORY.INTL_OFFICE ? "Country" : ""}
                            show={category == constants.CATEGORY.INTL_OFFICE ? true : false}
                        >
                            <CountriesList
                                type="horizontal"
                                selectedCountry={country}
                                onSelectItem={({ id }) => {
                                    setCountry(id);
                                    setOwner(null);
                                    setClient(null);
                                }}
                            />
                        </ReportInputFieldContainer>

                        <ReportInputFieldContainer
                            label={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? "Business Partner" : ""}
                            show={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? true : false}
                        >
                            
                            <BPList
                                selectedBp={owner}
                                type="horizontal"
                                country={country}
                                onSelectItem={({ value }) => {
                                    setOwner(value);
                                    setClient(null);
                                }}
                            />
                        </ReportInputFieldContainer>

                        <ReportInputFieldContainer
                            label="Client"
                        >
                            <CustomersList
                                selectedCustomer={client}
                                bp={owner}
                                onSelectItem={option => setClient(option.value)}
                            />
                        </ReportInputFieldContainer>

                        <FormColumn
                            col={`col-sm-8`}
                        >
                            <label></label>
                            <div>
                                <GeneralButton
                                    className="btn btn-primary  mt-2 pull-right"
                                    name="Search"
                                    onClick={() => { getPendingPaymentList() }}
                                    disabled={awaitingList && awaitingList.length > 0 ? true : false}
                                >
                                </GeneralButton>
                                <GeneralButton
                                    className="btn btn-light mr-2 mt-2 pull-right"
                                    name="Reset"
                                    onClick={() => {
                                        setAwaitingList([]);
                                    }}
                                />
                            </div>

                        </FormColumn>


                    </FormRow>
                    <hr />

                    <ReactTable
                        data={awaitingList}
                        columns={cols}
                        defaultPageSize={10}
                        minRows={awaitingList ? awaitingList.length : 0}
                        className="-striped -highlight"
                        showPagination={true}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        noDataText='No rows found'
                    />

                </div>
            </div>

            {/*------------------------loan DD schedule list modal-----------*/}
            <Modal
                show={ddScheduleListModal}
                onHide={() => setDdScheduleListModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Schedule List</h4>
                </Modal.Header>
                <Modal.Body>
                    <DDScheduleList
                        type="pending"
                        data={scheduleItem}
                    />
                </Modal.Body>
            </Modal>
            {/*--------------------------------------------------------------*/}
        </>
    );
}
export default PendingRequest;