/**
 * White Card Type One component
 * ==============================
 * Props 
 * ==============================
 * cardTitle,leftLabel,leftValue,centerValue,centerLabel,rightValue,rightLabel
 * 
 */
import React from "react";
import "./css/card.css";
import {
  FormColumnMd,
} from "../../../common/containers/formContainer";
import CardSkelton from "./_cardSkeltonTypeTwo";
import { Link } from "react-router";
function WhiteCardTypeOne({ col, loading, data, btn, bulkUploadBtn, flip = false }) {
  return (
    <>
      {loading === true ? (
        <CardSkelton col={col} top={true} />
      ) : (
        <FormColumnMd col={col ? col : 4}>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-1">{data ? data.cardTitle : " "} </h4>


                <div className="mb-2">
                  <h6 className="d-flex justify-content-between text-sm mt-3">
                    {flip && (
                     
                      <a title="Click here to flip the card" className="cursor-link" onClick={flip}>
                        <i className="fa fa-refresh" />
                      </a>
                      
                    )}
                  </h6>
                  <h4 className="mb-0 font-weight-bold">{data ? data.topValue : ""}</h4>
                </div>

              <div className="row ">
                <div className="col">
                  <div className="mb-1 mt-2 d-flex align-items-center">
                    <div className="w-10 h-10 bg-primary rounded-full mr-2 " />
                    {data ? data.leftLabel : ""}
                  </div>
                </div>
                <div className="col col-auto">
                  <h6 className="mb-0">{data ? data.leftValue : ""}</h6>
                </div>
              </div>
              {/*  <div className="row">
                <div className="col">
                  <div className="mb-1 d-flex align-items-center">
                    <div className="w-10 h-10 rounded-full bg-danger  mr-2 " />
                    {data ? data.rightLabel : ""}
                  </div>
                </div>
                <div className="col col-auto">
                  <h6 className="mb-0">{data ? data.rightValue : ""}</h6>
                </div>
                </div>*/}
              <div className="row justify-content-center mt-4">
                {(data && btn) && (data.settings ? <> {btn} {bulkUploadBtn} </>
                  : <span className="text-danger">Rate settings are not configured</span>)}
              </div>




            </div>
          </div>
          {/*   <div class="supplier-card">
            <div class="p-3">
              <FormRow>
                <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8 col-8">
                  <label class="p-0 m-0 bold-txt">{data ? data.cardTitle : " "}</label>
                </div>
                <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4 col-4 text-right">
                  <Link to={data ? data.btnRoute : ''} >
                    <button class="btn card-btn btn-blue">View</button>
                  </Link>
                </div>
                <div class="col-lg-12 mt-4">
                  <div class="text-center">
                    <h3 class="p-0 m-0 amount">{data ? data.topValue : ""}</h3>
                    <small class="m-0 p-0">{data ? data.topLabel : ""}</small>
                  </div>
                </div>
              </FormRow>
            </div>
            <div class="supplier-card-ftr">
              <FormRow>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 active-widget pl-5">
                  <h4>{data ? data.leftValue : ""}</h4>
                  <label>{data ? data.leftLabel : ""}</label>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-6  inactive-widget pr-5">
                  <h4>{data ? data.rightValue : ""}</h4>
                  <label>{data ? data.rightLabel : ""}</label>
                </div>
              </FormRow>

              <FormRow>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-4 text-center">
                  {btn}
                </div>
              </FormRow>
            </div>
      </div>*/}
        </FormColumnMd>
      )}
    </>
  );
}

export default React.memo(WhiteCardTypeOne);
