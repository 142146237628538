import React, { useContext ,useEffect} from "react";

import productShortName from "../../../utils/GetProductShortName";
import EntityContext from "../../../context/entityContext";
import authService from "../../../services/authService";
import { browserHistory } from "react-router";
import { Link } from "react-router";

export default function FifoTabs(props) {
    const { constants } = authService.getCurrentUser();
    const {
        selProduct,
        selectedBusinessType,
        handleRoleChange,
        openInvoiceModal,
        salesRoles,
        purchaseRoles,
        openAddSupplier,
        terminology,
        entityDetails,
        specialProgramEnabledSCF,
        specialProgramEnabledTF

    } = useContext(EntityContext);
    return (

        <div className="row justify-content-center align-items-center mt-2 mb-4">

            {
                selProduct !== constants.PRODUCTS.LOAN && (
                    <>
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            {(salesRoles.length > 0 || purchaseRoles.length > 0) && (
                                <button className={`btn btn-secondary  btn-sm pr-sm-4 pr-3 pl-lg-2 w-100 ${(purchaseRoles.length > 0 && salesRoles.length > 0) && 'dropdown-toggle'}`} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {selectedBusinessType == constants.BUSINESS_TYPE.SALE ? terminology && terminology.SALES_INVOICES : terminology && terminology.PURCHASE_INVOICES}
                                </button>
                            )}
                            {(purchaseRoles.length > 0 && salesRoles.length > 0) && (
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <Link
                                        className={`dropdown-item`}
                                        onClick={e => { handleRoleChange(selectedBusinessType == constants.BUSINESS_TYPE.SALE ? constants.BUSINESS_TYPE.PURCHASE : constants.BUSINESS_TYPE.SALE) }}
                                        to={`/client/${productShortName(selectedBusinessType == constants.BUSINESS_TYPE.SALE ? purchaseRoles[0].product : salesRoles[0].product)}/dashboard`}
                                    >
                                        {selectedBusinessType == constants.BUSINESS_TYPE.SALE ? terminology && terminology.PURCHASE_INVOICES : terminology && terminology.SALES_INVOICES}
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className={`col-lg-10 col-md-8 col-sm-8`}>
                            <ul className="nav fifo-tabs border-bottom">
                                {
                                    selectedBusinessType == constants.BUSINESS_TYPE.SALE ?
                                        (
                                            <>
                                                {salesRoles.map((obj, index) =>
                                                    <li key={index} className="nav-item dropdown">
                                                        <Link
                                                            key={index}
                                                            className={`nav-link dropdown-toggle ${(selectedBusinessType == obj.value && selProduct == obj.product) ? 'active' : ''}`}
                                                            onClick={() => {
                                                                handleRoleChange(obj.value);
                                                                browserHistory.push(`/client/${productShortName(obj.product)}/dashboard`)
                                                            }}
                                                            data-toggle="dropdown"
                                                            role="button"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            {obj.name}
                                                        </Link>
                                                        <div className="dropdown-menu">

                                                            {props.activePath !== `/client/${productShortName(selProduct)}/dashboard` && (
                                                                <>
                                                                    <Link className="dropdown-item" to={`/client/${productShortName(obj.product)}/dashboard`}>Dashboard</Link>
                                                                    <div className="dropdown-divider"></div>
                                                                </>
                                                            )}
                                                            {obj.product === constants.PRODUCTS.DEBTORFINANACE && <Link className="dropdown-item" href="#" to={{ pathname: `/client/wob/batch-upload` }}>Add New Batch Invoices</Link>}
                                                            {obj.product !== constants.PRODUCTS.DEBTORFINANACE && <Link className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => openInvoiceModal(obj.product, selectedBusinessType)}>Add New Invoice</Link>}
                                                            <Link className="dropdown-item" href="#" to={{ pathname: '/client/invoice', state: { path: `/client/${productShortName(obj.product)}/sale-invoices` } }}>View Invoices</Link>
                                                            {obj.product === constants.PRODUCTS.INVOICE && <Link className="dropdown-item" href="#" to={{ pathname: '/client/invoice', state: { path: `/client/${productShortName(obj.product)}/sale-invoices?status=closed` } }}>View Closed Invoices</Link>}
                                                            {obj.product !== constants.PRODUCTS.CUSTOMERPROGRAMS && <Link className="dropdown-item" href="#" to={`/client/${productShortName(obj.product)}/transaction-statement`}>Statement</Link>}
                                                            {obj.product === constants.PRODUCTS.INVOICE && <div className="dropdown-divider"></div>}
                                                            {obj.product === constants.PRODUCTS.INVOICE && <Link className="dropdown-item" href="#" to={'/client/users-network/customer?action=create'}>Add New Customer</Link>}
                                                            {obj.product === constants.PRODUCTS.INVOICE && <Link className="dropdown-item" href="#" to={{ pathname: `/client/${productShortName(obj.product)}/mapped-debtors` }}>View Customers</Link>}

                                                        </div>
                                                    </li>
                                                )}
                                            </>
                                        ) :
                                        <>
                                            {purchaseRoles.map((obj, index) =>
                                                <li key={index} className="nav-item dropdown">
                                                    <Link
                                                        key={index}
                                                        className={`nav-link dropdown-toggle ${(selectedBusinessType == obj.value && selProduct == obj.product) ? 'active' : ''}`}
                                                        onClick={() => {
                                                            handleRoleChange(obj.value);
                                                            browserHistory.push(`/client/${productShortName(obj.product)}/dashboard`)
                                                        }}
                                                        data-toggle="dropdown"
                                                        role="button"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        {obj.name}
                                                    </Link>
                                                    <div className="dropdown-menu">

                                                        {props.activePath !== `/client/${productShortName(selProduct)}/dashboard` && (
                                                            <>
                                                                <Link className="dropdown-item" to={`/client/${productShortName(obj.product)}/dashboard`}>Dashboard</Link>
                                                                <div className="dropdown-divider"></div>
                                                            </>
                                                        )}

                                                        {obj.product === constants.PRODUCTS.INVOICE ?
                                                            <>
                                                                <Link className="dropdown-item" href="#" to={{ pathname: '/client/invoice', state: { path: `/client/${productShortName(obj.product)}/purchase-invoices` } }} /*to={`/client/${productShortName(obj.product)}/purchase-invoices`}*/>View Invoices</Link>
                                                            </>
                                                            :
                                                            <>
                                                                {((obj.product === constants.PRODUCTS.SUPPLYCHAIN && !specialProgramEnabledSCF) || (obj.product === constants.PRODUCTS.TRADEFINANCE && !specialProgramEnabledTF)) && 
                                                                    <Link className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => openInvoiceModal(obj.product, selectedBusinessType)}>Add New Invoice</Link>
                                                                }
                                                                
                                                                <Link className="dropdown-item" href="#" to={{ pathname: '/client/invoice', state: { path: `/client/${productShortName(obj.product)}/purchase-invoices` } }} /*to={`/client/${productShortName(obj.product)}/purchase-invoices`}*/>View Invoices</Link>
                                                                
                                                                {entityDetails.productSettings.find(item => item.id == obj.product).enable_dd && 
                                                                <Link className="dropdown-item" href="#" to={`/client/${productShortName(obj.product)}/direct-debit`}>Direct Debit</Link> }
                                                                
                                                                {((obj.product === constants.PRODUCTS.SUPPLYCHAIN && !specialProgramEnabledSCF) || (obj.product === constants.PRODUCTS.TRADEFINANCE && !specialProgramEnabledTF)) && 
                                                                    <Link className="dropdown-item" href="#" to={`/client/${productShortName(obj.product)}/transaction-statement`}>Statement</Link>
                                                                }
                                                                <Link className="dropdown-item" href="#" to={`/client/${productShortName(obj.product)}/bulk-payment`} >Settle Invoices</Link>
                                                                
                                                                {((obj.product === constants.PRODUCTS.SUPPLYCHAIN && !specialProgramEnabledSCF) || (obj.product === constants.PRODUCTS.TRADEFINANCE && !specialProgramEnabledTF)) && 
                                                                    <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <Link className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => openAddSupplier()}>Add New Suppliers</Link>
                                                                        <Link className="dropdown-item" href="#" to={`/client/${productShortName(obj.product)}/mapped-users`}>View Suppliers</Link>
                                                                    </>
                                                                }
                                                            </>
                                                        }

                                                    </div>
                                                </li>
                                            )}
                                        </>

                                    /*-------------------------------------------------*/
                                }
                            </ul>
                        </div>
                    </>
                )
            }
            {
                selProduct == constants.PRODUCTS.LOAN && (
                    <>
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            <button className={`btn btn-secondary  btn-sm pr-sm-4 pr-3 pl-lg-2 w-100 dropdown-toggle`} type="button" id="loandropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Business Loan
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <Link
                                    className={`dropdown-item`}
                                    to={`/client/loan/request-settlement`}
                                >
                                    Report Payment
                                </Link>
                                <Link
                                    className={`dropdown-item`}
                                    to={`/client/loan/statement`}
                                >
                                    Transaction Statement
                                </Link>
                            </div>
                        </div>
                        <div className={`col-lg-10 col-md-8 col-sm-8`}>
                            {/* <ul className="nav fifo-tabs border-bottom">
                                <li className="nav-item dropdown">
                                    <Link
                                        className={`nav-link dropdown-toggle active`}
                                        onClick={() => {
                                            browserHistory.push(`/client/loan/dashboard`)
                                        }}
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {
                                            salesRoles.length > 0 ? salesRoles.find(obj => obj.product == constants.PRODUCTS.LOAN) ? salesRoles.find(obj => obj.product == constants.PRODUCTS.LOAN).nick_name: "" : ''
                                        }
                                    </Link>
                                    <div className="dropdown-menu">
                                        

                                    </div>
                                </li>
                            </ul> */}
                        </div>
                    </>
                )
            }

        </div>
    )
}