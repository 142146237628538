import React, { useContext } from "react";
import { Link } from "react-router";
import fpClientLogo from "../../../src/components/client/images/fp-cl-logo.jpg";
import PublicInvoiceContext from "../supply-chain/context/PublicInvoiceContext";

const PublicHeader = () => {
  const { clientLogo } = useContext(PublicInvoiceContext);
  return (
    <>
      <nav className="navbar bg-secondary">
        <div className="container-fluid d-flex justify-content-between align-items-center w-100"></div>
      </nav>
      <nav
        id="cl-top-nav"
        className="top-navigation  navbar navbar-light navbar-expand-xl bg-white position-sticky fixed-top py-3 py-xl-0 border-bottom"
        role="navigation"
      >
        <div className="container-fluid">
          <Link className="navbar-brand d-none d-sm-block mr-0" to="#">
            <img
              src={clientLogo ? clientLogo : fpClientLogo}
              className="w-75"
            />
          </Link>
        </div>
      </nav>
    </>
  );
};

export default PublicHeader;
