import React, { useState } from "react";
import _ from "lodash";
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import {
  CurrencyLabel,
  EditButton,
} from "../../common/inputs";
import * as moment from "moment";
import transactionService from "../../../services/invTransactions";
import { confirmAlert } from "react-confirm-alert";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import { toast } from "react-toastify";

export default function ParticipantSummary({
  participants,
  transData = null,
  editHandler,
  edit = false,
  openModal,
  disabled = false,
  trans_guid = null
}) {


  const [loading, setLoading] = useState(false);

  const resendConirmation = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-delete-ui'>
            <h4>Resend invitation email</h4>
            <p>Do you want to resend the invitation email again?</p>
            <button className="btn btn-white" onClick={() => { onClose(); }}>No</button>
            <button
              className="btn btn-primary ml-2"
              onClick={async () => {
                onClose();
                setLoading(true);
                const { data } = await transactionService.createPaFunding({
                  paData: participants,
                  trans_guid: trans_guid,
                  resend_invitation: true
                });
                setLoading(false);
                toast.success(data.message);
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  }

  return (
    <>
    {loading && <LoadingSpinner />}
      {participants.length > 0 && (
        <table class="table text-secondary mt-2">
          <tbody>
            <tr>
              <th>Participant</th>
              <th>Share %</th>
              <th>Share Total</th>
              <th>Earn Method</th>
              <th>Rate Pa</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
            {participants.map((item, index) => (
              <tr>
                <td>{item.participant_name}</td>
                <td>
                  {((item.participation_amt / transData.total_advanced) * 100).toFixed(2)} %
                </td>
                <td>
                  <CurrencyLabel value={item.participation_amt}></CurrencyLabel>
                </td>
                <td>
                  {item.participation_amt_annual_rate ? `Percentage` : `Fixed`}
                </td>
                <td>
                  {item.participation_amt_annual_rate ? (
                    `${item.participation_amt_annual_rate}%`
                  ) : (
                    <CurrencyLabel
                      value={item.participation_annual_amt}
                    ></CurrencyLabel>
                  )}
                </td>
                <td>
                  {item.particiation_amt_paid_date
                    ? moment(
                      item.particiation_amt_paid_date,
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY")
                    : "--"}
                </td>
                <td>
                  {item.particiation_amt_end_date
                    ? moment(
                      item.particiation_amt_end_date,
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY")
                    : "--"}
                </td>
                {edit && item.edit === false && (
                  <td>
                    <Link className="action-icons">
                      {!disabled && <EditButton onClick={(e) => editHandler(item)} />}
                    </Link>
                  </td>
                )}
                {!edit && (
                  <td>
                    <Link className="text-secondary">
                      {!disabled && <EditButton onClick={(e) => openModal(item)} />}
                    </Link>
                  </td>
                )}
                <td>
                  <Link className="action-icons">
                    <i
                      alt="Send invitation mail again"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="Send invitation mail again"
                      className="fa fa-envelope-o"
                      onClick={resendConirmation}
                    ></i>
                  </Link>
                </td>
              </tr>
            ))}
            {participants.length > 0 && (
              <tr>
                <td className="text-info" colSpan="2"></td>
                <td>
                  <strong>
                    <CurrencyLabel
                      value={_.sumBy(participants, (item) =>
                        Number(item.participation_amt)
                      )}
                    ></CurrencyLabel>
                  </strong>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
}
