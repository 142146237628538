import React, { useEffect, useContext, useState } from "react";
import { PrimaryWithButton } from "../../components/cards";
import useApi from '../../../../hooks/useApi';
import EntityContext from "../../../../context/entityContext";
import loanService from "../../../loan/services/loanTransactions";
import { CurrencyLabel } from "../../../common/inputs";

function LoanSummary() {

    const { selectedBusinessType, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);

    //------------Api Service--------------------------------------------
    const loanOverviewApi = useApi(loanService.getLoanOverview);

    const fetchData = async () => {
        const response = await loanOverviewApi.request({ clientReportType : true});
        
        if(response){
            setData({
                topLabel: "Open Loan",
                topValue: <CurrencyLabel value={response.data.response.opened_loan_total} />,
                leftLabel: "Interest",
                leftValue: <CurrencyLabel value={response.data.response.opened_loan_interest} />,
                rightLabel: "Loans",
                rightValue:response.data.response.loan_count
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>

            <PrimaryWithButton
                loading={loanOverviewApi.loading}
                data={data}
                qrystring={true}
            />
        </>
    )
}
export default LoanSummary;