import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import DisburseSingleInvoices from "../invoice/_disburseSingleInvoices";
import CloseInvoices from "../invoice/_closeInvoices";
import AddParticipant from "../createTransaction/_addParticipant";
import { transStatus } from "../../../config/predefinedValues.json";
import { CurrencyLabel } from "../../common/inputs";

export default function CustomConfirmation({
  onClose,
  selectedRow,
  title,
  des,
  page,
  transId = null,
  invoiceSum = 0,
  totalAgreementAmt = 0,
  totalAdvanceAmt = 0,
  handleTab=null,
  handleReloadData,
  extraInfo,
  submitData,
}) {
  const [openDisburse, setDisburse] = useState(false);
  const [openConfirm, setConfirm] = useState(true);

  useEffect(() => {
    confirmAlert({
      customUI: ({ onClose: closeCustomConfirm }) => {
        return (
          <div className="custom-delete-ui">
            
            <h4>{title}</h4>
            
            {page === "payment" && (
              <p>
                {des} <CurrencyLabel value={invoiceSum}></CurrencyLabel> going to get paid.
              </p>
            )}
            <p className="text-white">{extraInfo}</p>

            <button
              className="btn btn-white"
              onClick={() => {
                if(page == "participant" && handleTab)
                  handleTab(transStatus[0]["OPEN"]);
                closeCustomConfirm();
                onClose && onClose();
              }}
            >
              No
            </button>

            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                setDisburse(true);
                setConfirm(false);
                page === "payment" && submitData("close");
                closeCustomConfirm();
              }}
            >
              Yes
            </button>
                       
          </div>
        );
      },
      title: "",
      message: ``,
    });
  }, [])

  return (
    <>
      {openDisburse && (
        <>
          {page === "disburse" && (
            <DisburseSingleInvoices
              selectedRow={selectedRow}
              onClose={(e) => {
                onClose();
                setDisburse(false);
              }}
              context={false}
              handleReloadData={handleReloadData}
              handleTab={handleTab}
            ></DisburseSingleInvoices>
          )}
          {page === "participant" && (
            <AddParticipant
              onClose={(e) => {
                onClose();
                setDisburse(false);

                if(handleTab)
                  handleTab(transStatus[0]["OPEN"]);
              }}
              totalInvAmt={parseFloat(invoiceSum)}
              totalAgreementAmt={totalAgreementAmt}
              totalAdvanceAmt={totalAdvanceAmt}
              annualRate={""}
              transId={transId}
              context={false}
              invStatus={null}
              showSummary={true}
            />
          )}
          {page === "payment" && (
            <CloseInvoices
              selectedInvoices={selectedRow.filter(
                (item) => item.inv_closed_date === null
              )}
              onClose={(e) => {
                onClose();
                setDisburse(false);
                handleReloadData();
              }}
              handleReloadData={handleReloadData}
            />
          )}
        </>
      )}

      {/* <Modal
        show={openConfirm}
        onHide={() => {
          onClose();
        }}
        closeOnOverlayClick={false}
        center
        className="secondary-modal"
      >
        <Modal.Body>
          <div class="  bg-secondary">
            <div className="row justify-content-center mb-2">
              <div className="col-lg-12 text-center">
                <h5 className="text-white mb-2">{title}</h5>
              </div>
              <div className="col-lg-12 text-center">
                {page === "payment" && (
                  <p className="text-white">
                    {des} <CurrencyLabel value={invoiceSum}></CurrencyLabel>{" "}
                    going to get paid.
                  </p>
                )}
                <p className="text-white">{extraInfo}</p>
              </div>
            </div>
            <div className="row justify-content-center mb-2">
              <button
                className="btn btn-white"
                onClick={(e) => {
                  onClose();
                  
                  if(page == "participant" && handleTab)
                    handleTab(transStatus[0]["OPEN"]);
                }}
              >
                No
              </button>
              <button
                className="btn btn-primary ml-2"
                onClick={(e) => {
                  setDisburse(true);
                  setConfirm(false);
                  page === "payment" && submitData("close");
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
