import client from "../../../services/serviceClient";
import http from "../../../services/httpService";
import { apiUrl, queryString } from "../../../environment";

const transactionUrl ="/supplychain/fifo-sc-transaction/";

// ------------- Generates API Url ------------------------
function getApiUrl(func) {
  let remote_call = apiUrl + transactionUrl + func + queryString;
  return remote_call;
}
// --------------------------------------------------------

// ------ Aynchronous function to communicate with the server
async function createResponse(url,postObject){
  try {
    const response = await http.post(url, postObject);
    return response.data;
  } catch (error) {
    console.log(error); // Handle error response
  }
}
// --------------------------------------------------------

export async function getCustomersList(country_id, categ_type = null) { // Action Dispatcher

  let apiGeneralCall = getApiUrl("get-customers-with-country-code");
  let postObject = {
    country_id,
    categ_type
  };

  const response = createResponse(apiGeneralCall,postObject);
  return response;
}

export async function getSupplierList(country_id, categ_type = null) { // Action Dispatcher

  let apiGeneralCall = getApiUrl("get-suppliers-with-country-code");
  let postObject = {
    country_id,
    categ_type
  };

  const response = createResponse(apiGeneralCall,postObject);
  return response;
}
export async function getRatesList(country_id, categ_type = null) { // Action Dispatcher

  let apiGeneralCall = getApiUrl("get-rates-with-country-code");
  let postObject = {
    country_id,
    categ_type
  };

  const response = createResponse(apiGeneralCall,postObject);
  return response;
}

export async function getMappedCustomersList(country_id, categ_type = null,userId =null,productType = null, owner = null,mapSettings = false) {

  let apiGeneralCall = getApiUrl("get-mapped-users");
  let postObject = {
    country_id,
    categ_type,
    userId,
    productType,
    owner,
    mapSettings
  };

  const response = createResponse(apiGeneralCall,postObject);
  return response;
}

export async function getMappedSupplierList(country_id, categ_type = null,userId = null,productType = null,owner = null) { // Action Dispatcher

  let apiGeneralCall =  getApiUrl("get-mapped-users");
  let postObject = {
    country_id,
    categ_type,
    userId,
    productType,
    owner,
  };

  const response = createResponse(apiGeneralCall,postObject);
  return response;
}
export default {
  getCustomersList,
  getSupplierList,
  getRatesList,
  getMappedCustomersList,
  getMappedSupplierList
};