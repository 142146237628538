import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { ReactSelect } from "../../common/inputs";
import transactionService from "../../../services/transactionService";
import { transactionTemplateFields, loanTemplateFields } from "../../../config/predefinedValues.json";
import $ from "jquery";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";

class TemplateEditor extends Component {
  constructor(props) {
    super(props);
    this.doctemplate = React.createRef();
  }

  state = {
    entityFieldsModal: false,
    entityFields: [],
    addressFields: [],
    additionalFields: [],
    addressAdditionalFields: [],
    bpAddressAdditionalFields: [],

    addressTypes: [
      {
        label: "Physical Address",
        value: "pline"
      },
      {
        label: "Mailing Address",
        value: "mline"
      }
    ],
    selectedEntityType: "",
    selectedEntityField: "",
    selectedEntityAddressField: "",
    selectedTransactionField: "",
    selectedLoanField: "",
    selectedAddressAdditionalField: "",
    templateText: "",
    content: "",

    selectedAddressType: "",
    selectedAddressField: ""
  };

  componentDidMount() {
    this.setState({
      content: this.props.data ? this.props.data.content : this.props.content
    });
  }

  /*componentWillReceiveProps(props) {
    if (props.content) this.setState({ content: props.content });
  }*/

  componentDidUpdate(prevProps) {
    if (this.props.entities != prevProps.entities)
      this.setState({ content: this.props.content });
  }

  fetchDocumentTemplates = async () => {
    try {
      this.setState({ ajaxRequest: true });
      const templateFields = await transactionService.fetchDocumentTemplates();
      this.setState({
        entityFields: templateFields.entityFields,
        addressFields: templateFields.addressFields,
        addressAdditionalFields: templateFields.addressAdditionalFields,
        bpAddressAdditionalFields: templateFields.bpAddressAdditionalFields
      });
      this.setState({ ajaxRequest: false });
    } catch (error) { }
  };

  handleTabChange = selectedEntityType => {
    this.setState({ selectedEntityType });
  };

  handleEntityFieldChange = selectedOption => {
    this.setState({
      selectedEntityField: selectedOption.value,
      templateText:
        "{{" + this.state.selectedEntityType + "." + selectedOption.value + "}}"
    });
  };

  handleAdditionalAddressFieldChange = selectedOption => {
    this.setState({
      selectedAddressAdditionalField: selectedOption.value,
      templateText:
        "{{" + this.state.selectedEntityType + "." + selectedOption.value + "}}"
    });
  };

  handleTransactionFieldChange = selectedOption => {
    this.setState({
      selectedTransactionField: selectedOption.value
    });
  };

  handleLoanFieldChange = selectedOption => {
    this.setState({
      selectedLoanField: selectedOption.value
    });
  };

  handleEntityAddressTypeChange = selectedOption => {
    this.setState({
      selectedAddressType: selectedOption.value
    });
  };

  handleEntityAddressFieldChange = selectedOption => {
    this.setState({
      selectedEntityAddressField: selectedOption.value,
      templateText:
        "{{" + this.state.selectedEntityType + "." + selectedOption.value + "}}"
    });
  };

  insertEntityField = () => {
    this.setState({ selectedTransactionField: "", selectedLoanField: "" });
    var editor = document.getElementsByClassName("jodit-wysiwyg");
    editor[0]["innerHTML"] += this.state.templateText;
    this.onCloseModal();
  };

  insertTransactionField = () => {
    this.setState({ templateText: "" });
    var editor = document.getElementsByClassName("jodit-wysiwyg");
    editor[0]["innerHTML"] += this.state.selectedTransactionField;
    this.onCloseModal();
  };

  insertLoanField = () => {
    this.setState({ templateText: "" });
    var editor = document.getElementsByClassName("jodit-wysiwyg");
    editor[0]["innerHTML"] += this.state.selectedLoanField;
    this.onCloseModal();
  };


  insertAddressAdditionalField = () => {
    this.setState({ templateText: "" });
    var editor = document.getElementsByClassName("jodit-wysiwyg");
    editor[0]["innerHTML"] += this.state.templateText;
    this.onCloseModal();
  };

  openModal = () => {
    this.setState({
      entityFieldsModal: true,
      selectedEntityType: "business_partner",
      selectedEntityField: "",
      selectedEntityAddressField: "",
      selectedTransactionField: "",
      selectedLoanField: "",
      selectedAddressAdditionalField: ""
    });
  };

  onCloseModal = () => {
    this.setState({ entityFieldsModal: false });
  };

  render() {
    const {
      entityFields,
      addressFields,
      addressTypes,
      content,
      addressAdditionalFields,
      bpAddressAdditionalFields
    } = this.state;

    return (
      <React.Fragment>
        <Modal open={this.state.entityFieldsModal} onClose={this.onCloseModal}>
          <center>
            <div className="modal-header">
              <h4>
                Document Template Fields
              </h4>
            </div>
            <div
              style={{ textAlign: "left", width: 800 }}
              className="modal-body"
              id="documenttemplates"
            >
              {/** --------------------- Template Tabs ---------------------------- */}

              <div class="tabs-container">
                <ul class="nav nav-tabs" role="tablist">
                  {/* ------------------------ Tabs list --------------------- */}
                  <li>
                    <a
                      onClick={e => {
                        this.handleTabChange("business_partner");
                      }}
                      class="nav-link active"
                      data-toggle="tab"
                      href="#tab-1"
                    >
                      Business Partner
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={e => {
                        this.handleTabChange("selected_entity");
                      }}
                      class="nav-link"
                      data-toggle="tab"
                      href="#tab-1"
                    >
                      Client
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={e => {
                        this.handleTabChange("debtor");
                      }}
                      class="nav-link"
                      data-toggle="tab"
                      href="#tab-1"
                    >
                      Debtor
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={e => {
                        this.handleTabChange("transactions");
                      }}
                      class="nav-link"
                      data-toggle="tab"
                      href="#tab-1"
                    >
                      Transactions
                    </a>
                  </li>
                  {/* ------------------------------------------------------------ */}
                </ul>

                <div class="tab-content">
                  <div role="tabpanel" id="tab-1" class="tab-pane active show">
                    <div class="panel-body">
                      {/** ===================== Business Partner ========================= */}
                      {this.state.selectedEntityType == "business_partner" && (
                        <React.Fragment>
                          <div className="row">
                            <div class="col-lg-10">
                              <ReactSelect
                                options={entityFields}
                                label="Entity Fields"
                                placeholder="Select entity fields"
                                type="horizontal"
                                onChange={this.handleEntityFieldChange}
                              ></ReactSelect>
                            </div>
                            <div class="col-lg-2">
                              <button
                                type="button"
                                class="btn btn-primary btn-xs pull-right"
                                onClick={e => {
                                  this.insertEntityField();
                                }}
                                disabled={
                                  this.state.selectedEntityField == ""
                                    ? true
                                    : false
                                }
                              >
                                <i class="fa fa-plus-circle"></i>&nbsp;Insert
                              </button>
                            </div>
                          </div>

                          {/* 
                          <hr></hr>
                          <div className="row">
                            <div class="col-lg-10">
                              <ReactSelect
                                options={bpAddressAdditionalFields.sort(
                                  function (a, b) {
                                    var textA = a.label.toUpperCase();
                                    var textB = b.label.toUpperCase();
                                    return textA < textB
                                      ? -1
                                      : textA > textB
                                        ? 1
                                        : 0;
                                  }
                                )}
                                label="More Info"
                                placeholder="Select more info"
                                type="horizontal"
                                onChange={this.handleEntityAddressFieldChange}
                              ></ReactSelect>
                            </div>
                            <div class="col-lg-2">
                              <button
                                type="button"
                                class="btn btn-primary btn-xs pull-right"
                                onClick={e => {
                                  this.insertEntityField();
                                }}
                                disabled={
                                  this.state.selectedEntityAddressField == ""
                                    ? true
                                    : false
                                }
                              >
                                <i class="fa fa-plus-circle"></i>&nbsp;Insert
                              </button>
                            </div>
                          </div> */}
                        </React.Fragment>
                      )}
                      {/** ================================================================== */}

                      {/** ===================== Client, Debtor, Referral Partner and Guarantor ========================= */}
                      {this.state.selectedEntityType != "business_partner" &&
                        this.state.selectedEntityType != "transactions" && this.state.selectedEntityType != "loan" && (
                          <React.Fragment>
                            <div className="row">
                              <div class="col-lg-10">
                                <ReactSelect
                                  options={entityFields.sort(function (a, b) {
                                    var textA = a.label.toUpperCase();
                                    var textB = b.label.toUpperCase();
                                    return textA < textB
                                      ? -1
                                      : textA > textB
                                        ? 1
                                        : 0;
                                  })}
                                  label="Entity Fields"
                                  placeholder="Select entity fields"
                                  type="horizontal"
                                  onChange={this.handleEntityFieldChange}
                                ></ReactSelect>
                              </div>
                              <div class="col-lg-2">
                                <button
                                  type="button"
                                  class="btn btn-primary btn-xs pull-right"
                                  onClick={e => {
                                    this.insertEntityField();
                                  }}
                                  disabled={
                                    this.state.selectedEntityField == ""
                                      ? true
                                      : false
                                  }
                                >
                                  <i class="fa fa-plus-circle"></i>&nbsp;Insert
                                </button>
                              </div>
                            </div>

                            {/* 
                            <hr></hr>
                            <div className="row">
                              <div class="col-lg-10">
                                <ReactSelect
                                  label="More Info"
                                  placeholder="Select more info"
                                  type="horizontal"
                                  options={addressAdditionalFields.sort(
                                    function (a, b) {
                                      var textA = a.label.toUpperCase();
                                      var textB = b.label.toUpperCase();
                                      return textA < textB
                                        ? -1
                                        : textA > textB
                                          ? 1
                                          : 0;
                                    }
                                  )}
                                  onChange={
                                    this.handleAdditionalAddressFieldChange
                                  }
                                ></ReactSelect>
                              </div>
                              <div class="col-lg-2">
                                <button
                                  type="button"
                                  class="btn btn-primary btn-xs pull-right"
                                  disabled={
                                    this.state.selectedAddressAdditionalField ==
                                      ""
                                      ? true
                                      : false
                                  }
                                  onClick={e => {
                                    this.insertAddressAdditionalField();
                                  }}
                                >
                                  <i class="fa fa-plus-circle"></i>&nbsp;Insert
                                </button>
                              </div>
                            </div> */}
                          </React.Fragment>
                        )}
                      {/** ================================================================== */}

                      {/** ======================== Transactions ============================ */}
                      {this.state.selectedEntityType == "transactions" && (
                        <React.Fragment>
                          <div className="row">
                            <div class="col-lg-10">
                              <ReactSelect
                                options={transactionTemplateFields.sort(
                                  function (a, b) {
                                    var textA = a.label.toUpperCase();
                                    var textB = b.label.toUpperCase();
                                    return textA < textB
                                      ? -1
                                      : textA > textB
                                        ? 1
                                        : 0;
                                  }
                                )}
                                label="Transaction Fields"
                                placeholder="Select Field"
                                type="horizontal"
                                onChange={this.handleTransactionFieldChange}
                              ></ReactSelect>
                            </div>
                            <div class="col-lg-2">
                              <button
                                type="button"
                                class="btn btn-primary btn-xs pull-right"
                                onClick={e => {
                                  this.insertTransactionField();
                                }}
                                disabled={
                                  this.state.selectedTransactionField == ""
                                    ? true
                                    : false
                                }
                              >
                                <i class="fa fa-plus-circle"></i>&nbsp;Insert
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {/** ================================================================== */}

                      {/** ======================== Loan ============================ */}
                      {this.state.selectedEntityType == "loan" && (
                        <React.Fragment>
                          <div className="row">
                            <div class="col-lg-10">
                              <ReactSelect
                                options={loanTemplateFields.sort(
                                  function (a, b) {
                                    var textA = a.label.toUpperCase();
                                    var textB = b.label.toUpperCase();
                                    return textA < textB
                                      ? -1
                                      : textA > textB
                                        ? 1
                                        : 0;
                                  }
                                )}
                                label="Loan Fields"
                                placeholder="Select Field"
                                type="horizontal"
                                onChange={this.handleLoanFieldChange}
                              ></ReactSelect>
                            </div>
                            <div class="col-lg-2">
                              <button
                                type="button"
                                class="btn btn-primary btn-xs pull-right"
                                onClick={e => {
                                  this.insertLoanField();
                                }}
                                disabled={
                                  this.state.selectedLoanField == ""
                                    ? true
                                    : false
                                }
                              >
                                <i class="fa fa-plus-circle"></i>&nbsp;Insert
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {/** ================================================================== */}
                    </div>
                  </div>
                </div>
              </div>
              {/** ---------------------------------------------------------------- */}
            </div>
          </center>
        </Modal>

        {this.props.data && (


          <div className="row mb-2">
            <div class="col-lg-12">
              <button
                className="btn btn-secondary btn-sm pull-right  pull-right  "
                onClick={e => {
                  this.openModal();
                  this.fetchDocumentTemplates();
                }}
              >
                System Fields
              </button>
            </div>
          </div>

        )}

        <JoditEditor
          value={content}
          /*config={{
              readonly: false // all options from https://xdsoft.net/jodit/play.html
            }}*/
          onChange={value => {
            this.props.handleContentChange(value);
            //editor.selection.insertHTMl("Text");
          }}
        />

      </React.Fragment >
    );
  }
}

export default TemplateEditor;
