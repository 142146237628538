import React, { useState, useContext, useEffect } from "react";
import * as moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";
import { Link, browserHistory } from "react-router";
import { useHistory } from "react-router-dom";
import "react-table/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import dateParser from "../../../utils/excelDateParser";
import { Helmet } from "react-helmet";
import InvoiceCreation from "./invoiceCreation";
import DFDebtorMapping from "./_debtorMapping";
import InvoiceErrors from "./_invoiceErrors";

import {
  GeneralButton,
  GeneralIconButton,
  EditButton,
  CopyButton,
  DeleteButton,
  CurrencyLabel,
  DateTimePicker,
} from "../../common/inputs";
import ExcelFileUploader from "../../common/inputs/fileUploader/ExcelUploader";
import { FormRow, FormColumnLg } from "../../common/containers/formContainer";

import {
  CountriesList,
  BPList,
  DFCustomerList,
} from "../../slplusTools";

import useApi from "../../../hooks/useApi";

import dfTransactionService from "../services/dfTransactions";
import authService from "../../../services/authService";
import coreService from "../../../services/coreService";
import EntityContext from "../../../context/entityContext";
import TransactionContext from "../../../context/invTransactionContext";
import { transStatus } from "../../../config/predefinedValues.json";
import entity from "../../../services/entityService";
import MultiStepForm from "../../common/wizard/MultiStepForm";

const BatchUpload = ({ location, handleTab, client, onUploadClose = null, ...restProps }) => {

  const [invModal, setInvModal] = useState(false);
  const [debtorModal, setDebtorModal] = useState(false);
  const [validationModal, setValidationModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [invoiceCreation, setCreation] = useState(false);
  const [errors, setErrors] = useState([]);
  const [otherErrors, setOtherErrors] = useState([]);
  const [values, setValue] = useState({});
  const [reOpenInv, setReOpenInv] = useState(false);

  const [invoice, setInvoice] = useState([]);
  const [debtors, setDebtors] = useState([]);
  const [refNo, setRefNumber] = useState("");
  const [isVisible, setVisible] = useState(false);
  const [transactionGuid, setTransactionGuid] = useState(null);

  const [customer, setCustomer] = useState(null);
  const [country, setCountry] = useState(null);
  const [owner, setOwner] = useState(null);
  const [isClient, setIsClient] = useState(false);

  const [navigate, setNavigation] = useState(null);
  const [selectedPage, setSelPage] = useState(1);
  const [proceedNext, setProceedNext] = useState(false);
  const [hideNextBtn, sethideNextBtn] = useState(false);
  const [hidePreviousBtn, sethidePreviousBtn] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [headingMsg, setHeadingMsg] = useState("");
  const [fileInfo, setFileInfo] = useState(null);
  const [validFile, setvalidFile] = useState(null);
  const [uploadedFileData, setUploadedFileData] = useState([]); // holds file uploaded file data
  const [syncContent, setSyncContent] = useState(true);

  const { category, constants } = authService.getCurrentUser();

  let history = useHistory();

  //------------Api Services--------------------------------------------
  const referenceApi = useApi(dfTransactionService.generateRefNum);
  const transactionApi = useApi(dfTransactionService.createInvoice);
  const transactionInvApi = useApi(dfTransactionService.createDFTransaction);
  const approveInvApi = useApi(dfTransactionService.approveInvoices);
  const disapproveCheckApi = useApi(dfTransactionService.DisapproveBulkInvoices);
  const fieldsHistoryApi = useApi(coreService.getFieldsMappingHistory);
  const entityContext = useContext(EntityContext);
  // const {
  //   transaction,
  // } = useContext(TransactionContext);

  //-----------------------Generate and assign the Trans Ref number-----------------------//
  const getRefNumber = async (customer_guid) => {
    const { data } = await referenceApi.request({
      client_guid: customer_guid,
    });

    setRefNumber(data.ref_number);
    return data.ref_number;
  };
  //---------------------- handle invoice upload ----------------------------//
  const handleUploadItems = async (excelData, filesList) => {
    const { ParseDate } = dateParser(); // Instance for utility library.

    const spreadSheetFileTypes = ["xls", "xlsx", "ods"];

    const excelFile = filesList.find((file) =>
      spreadSheetFileTypes.includes(file.name.split(".").pop())
    );

    if (excelFile) {
      let invoiceData = [...invoice];
      excelData.map(
        ({
          Invoice_Number,
          Debtor,
          Invoice_Date,
          Invoice_Value,
          Due_Date,
          Insured,
          Agreement_Amount,
          Advance_Pctg,
          Advance_Amt,
          Advance_Date,
          Royalty_Pctg,
          Insurance_Pctg,
        }) => {
          let debtorInfo = debtors.find((obj) => obj.label == Debtor); // Match with Debtor Organisation
          if (!debtorInfo && Debtor)
            debtorInfo = debtors.find((obj) => obj.debtor_id == Debtor); // Match with Debtor ID

          invoiceData.push({
            index: invoice.length++,
            inv_number: Invoice_Number,
            inv_date: ParseDate(Invoice_Date),
            inv_amount: Invoice_Value,
            inv_due_date: ParseDate(Due_Date),
            insured: Insured,
            inv_chargeable_amt: Invoice_Value,
            inv_adv_percent: Advance_Pctg,
            inv_adv_amount: Advance_Amt,
            inv_advance_date: ParseDate(Advance_Date),
            inv_royalty_percent: Royalty_Pctg,
            inv_insurance_percent: Insurance_Pctg,
            debtor_name: debtorInfo ? debtorInfo.label : false,
            debtor_name_in_sheet: Debtor,
            inv_debtor_id: debtorInfo ? debtorInfo.value : null,
            disburse_now:
              !Advance_Amt > 0 || Advance_Date === null || Advance_Date === ""
                ? false
                : true,
            credit_notes: Invoice_Value < 0 ? 0 : 1,
          });
        }
      );
      let list = [];
      filesList.map((fileItem) => {
        list.push(fileItem);
      });
      setvalidFile(true);
      setInvoice(invoiceData);
      setProceedNext(customer ? true : false);
      setFileInfo(excelFile);
      let uploadedFilesList = await handleFileReader(list);
      setUploadedFileData(uploadedFilesList);

    } else {
      toast.error("Invalid File format");
      setProceedNext(false);
    }

  };

  const handleFileReader = async (inputFiles) => {
    /** --------------- Reading local files in JavaScript -------------- */
    let files = inputFiles.map(fileItem => fileItem.obj);
    let fileslist = [];
    files.map(fileItem => {
      let reader = new FileReader();
      reader.readAsDataURL(fileItem);
      reader.onload = e => {
        fileslist.push({
          name: fileItem.name,
          file: e.target.result,
          type: fileItem.type,
          size: fileItem.size
        });
      };
    });

    return fileslist;
    /** ----------------------------------------------------------------- */
  };
  //-------------------- Delete selected invoice from the list ---------------------------//
  const handleInvoiceDeletion = (index) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Delete Invoice ?</h4>
            <p>{`Are you sure to delete this invoice ? `}</p>
            <button
              className="btn btn-white"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                deleteInvoice(index);
                onClose();
              }}
            >
              {"Yes"}
            </button>
          </div>
        );
      },
      title: "Delete Invoice ?",
      message: `Are you sure to delete this invoice ?`,
    });
  };
  //------------------------------------------------------------------------

  const deleteInvoice = (index) => {
    let invoiceData = [...invoice];
    invoiceData = invoiceData.filter((obj) => obj.index !== index);
    // Resetting Index value on each rows
    let updatedList = invoiceData.map((item, index) => {
      item.index = index;
      return item;
    });

    setInvoice(updatedList);
  };

  // delete invoice by Invoice number

  const handleDeleteByInvNumber = (inv_number) => {

    let invoiceData = [...invoice];
    invoiceData = invoiceData.filter((obj) => !inv_number.includes(obj.inv_number));

    // Resetting Index value on each rows
    let updatedList = invoiceData.map((item, index) => {
      item.index = index;
      return item;
    });

    setInvoice(updatedList);
  };

  const handleInvoiceEdit = (selectedRow) => {
    const invObj = { ...selectedRow };

    invObj.trans_client_guid = customer;
    invObj.trans_owner = owner;
    setSelectedRow(invObj);
    setInvModal(true);
  };
  //------------------------------------------------------------------------

  const handleInvoiceCopy = (selectedRow) => {
    const invList = [...invoice];
    const invObj = { ...selectedRow };

    invObj.index = invList.length;
    invObj.inv_number = null;
    invObj.trans_client_guid = customer;
    invObj.trans_owner = owner;
    invList.push(invObj);
    setInvoice(invList);
  };

  const updateInvoice = (index, invoiceData) => {
    let updatedInv = [...invoice];
    updatedInv[index] = { ...invoiceData };
    updatedInv[index]["error"] = false;
    updatedInv[index]["errorMsg"] = "";
    setInvoice(updatedInv);
  };


  const handleDebtorEdit = (selectedRow) => {
    const invObj = { ...selectedRow };

    invObj.trans_client_guid = customer;
    invObj.trans_owner = owner;
    setSelectedRow(invObj);
  };

  //--------------------------Getting list of debtors------------------------//
  const fetchDebtors = async (customer_guid) => {
    const { constants } = authService.getCurrentUser();
    const { data } = await entity.getEntityDebtorsList(
      customer_guid,
      constants.PRODUCTS.DEBTORFINANACE
    );
    setDebtors(data);
  };

  useEffect(() => {
    const { constants, category, entity_id } = authService.getCurrentUser();
    if (constants.CATEGORY.BP === category) setOwner(entity_id);

    let customer_guid = null;

    if (entityContext && typeof entityContext.entityDetails !== "undefined") {
      setCustomer(entityContext.entityDetails.a_guid);
      customer_guid = entityContext.entityDetails.a_guid;
      getRefNumber(entityContext.entityDetails.a_guid);
    }

    fetchDebtors(customer_guid);

    setValue({
      ...values,
      date: moment(new Date()).format("YYYY-MM-DD").toString(),
      checked: false,
    });

    setIsClient(category === constants.CATEGORY.TRADERS ? true : false);

  }, []);

  useEffect(() => {

    if (selectedPage === 1) {
      setProceedNext((invoice.length > 0 && customer && validFile) ? true : false);

      sethideNextBtn(false);
      sethidePreviousBtn(true);
    }
    if (selectedPage === 2) {
      //sethideNextBtn(true);
      sethidePreviousBtn(false);
      getFieldsMappingHistory();
    }

    if (selectedPage == 3) {
      if (category === constants.CATEGORY.TRADERS) {
        setTimeout(() => {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-delete-ui">
                  <h5>{headingMsg} </h5>
                  <p>{alertMsg}</p>
                  <button
                    className="btn btn-white"
                    onClick={() => {
                      if (restProps.redirectInvoiceListPage)
                        restProps.redirectInvoiceListPage("Invoices");
                      else
                        browserHistory.push("/client/wob/sale-invoices");
                      onClose();
                    }}
                  >
                    Go to Invoices
                  </button>
                  <button
                    className="btn btn-primary ml-2"
                    onClick={() => {
                      browserHistory.push("/client/wob/dashboard")
                      onClose();
                    }}
                  >
                    {"Go to Dashboard"}
                  </button>
                </div>
              );
            },
            // title: "Delete Invoice ?",
            //message: {alertMsg},
          });
        }, 2000);
      }
    }

  }, [invoice, selectedPage, customer]);

  // Handling Async operation to save the data to the server
  const submitInvoice = async () => {
    setProceedNext(false);
    if (invoice.filter((obj) => obj.debtor_name === false).length > 0) {
      toast.error("Debtor mismatch.");
      setProceedNext(true);
      return;
    }

    // Create Transaction
    let transGuid = null;

    const reqstData = {
      trans_guid: null,
      trans_client_reference: refNo,
      trans_system_reference: refNo,
      trans_client_guid: customer,
      trans_date: values.date,
      trans_fee_id: null,
      bp: owner,
      client_invoice_upload: isClient,
    };

    let trans_create = true;
    let transdata = [];

    if (transactionGuid === null) {
      transdata = await transactionInvApi.request(reqstData);
      trans_create = transdata.data.success;
    }


    if (trans_create === false) toast.error(transdata.data.message);
    else {
      // Create Invoices
      if (transactionGuid) {
        transGuid = transactionGuid;

      } else {
        transGuid = transdata.data.trans_details.trans_guid;
        setTransactionGuid(transGuid);
      }

      const reqData = {
        trans_client_guid: customer,
        client_invoice_upload: isClient,
        trans_guid: transGuid,
        invoices: [...invoice],
        uploadedFiles: uploadedFileData
      };

      const { data } = await transactionApi.request(reqData);
      if (data.success === false) {
        setVisible(false);
        setErrors(data.errors);
        setOtherErrors(data.otherErrors);
        setValidationModal(true);
        setReOpenInv(true);
        setProceedNext(true);
        if (data.invoices.length > 0) setInvoice(data.invoices);
      } else {

        let batchId = data.batchId;

        if (values.checked) {

          let approveArr = [];
          let approve_resp = [];

          data.invoices.map((item) => {
            approveArr.push({
              index: approveArr.length,
              checked: false,
              inv_guid: item.inv_guid,
              trans_guid: item.trans_guid,
              inv_number: item.inv_number,
              debtor_organisation: item.debtor_name,
              inv_chargeable_amt:
                item.inv_chargeable_amt !== null
                  ? parseFloat(item.inv_chargeable_amt)
                  : 0,
              inv_amount:
                item.inv_amount !== null ? parseFloat(item.inv_amount) : 0,
              inv_amt_paid: 0,
              inv_due_amt: item.total_due !== null ? parseFloat(item.total_due) : 0,
            });
          });

          const reqData = {
            paymentInfo: approveArr,
            type: 'approve',
          };

          approve_resp = await approveInvApi.request(reqData);

          if (approve_resp.data.success === false) {
            toast.error(approve_resp.data.message);
            setInvoice([]);
            setTransactionGuid(null);
            //handleTab(transStatus[0]["DRAFT"], "Transactions");
            setHeadingMsg("Successfuly Uploaded");
            setAlertMsg("Your invoices has been successsfully uploaded and moved to Draft");
            setNavigation("Next");
          }
          else {
            toast.success("Your invoices has been successsfully uploaded and moved to Open");

            const disapproveResponse = await disapproveCheckApi.request({
              batchId: batchId,
              trans_client_guid: customer
            });

            setInvoice([]);
            setTransactionGuid(null);
            setHeadingMsg("Successfuly Uploaded");
            if (reOpenInv) {
              setAlertMsg("Your invoices has been successsfully uploaded and moved to draft");
            } else {
              setAlertMsg("Your invoices has been successsfully uploaded and moved to Open");
            }
            setNavigation("Next");
          }

        } else {
          toast.success(data.message);
          setInvoice([]);
          setTransactionGuid(null);
          setHeadingMsg("Successfuly Uploaded");
          setAlertMsg("Your invoices has been successsfully uploaded and moved to draft");
          setNavigation("Next");
        }

      }
    }
  };
  //---------------------------------------------//
  //----Go back to prospect on cancel click-------------
  const handleCancel = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Are you sure ...?</h4>
            <p
              style={{ color: "white" }}
            >{`You want to cancel  uploading invoices?`}</p>
            <button className="btn btn-white" onClick={onClose}>
              No
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={async () => {
                // browserHistory.push(
                //   "/entities/details/debtorfinances?a_guid=${entityDetails.a_guid}"
                // );
                onClose();
                handleTab("draft", "Transactions");
              }}
            >
              Yes
            </button>
          </div>
        );
      },
      title: "Confirm to cancel",
      message: "Are you sure to do this.",
    });
  };

  //---------------------Handle Input Change----------------------//
  const handleChange = (e) => {
    setValue({ ...values, [e.target.name]: e.target.value });
  }

  const handleCheckBox = (checked) => {

    setValue({ ...values, 'checked': checked });

  };

  const handleRemoveFile = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Remove File ?</h4>
            <p>{`Are you sure want to remove uploaded file ? `}</p>
            <button
              className="btn btn-white"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                removeUploadedFile();
                onClose();
              }}
            >
              {"Yes"}
            </button>
          </div>
        );
      },
      title: "Remove File ?",
      message: `Are you sure want to remove uploaded file ?`,
    });
  }

  const removeUploadedFile = () => {
    setvalidFile(false);
    setInvoice([]);
    setProceedNext(false);
    setFileInfo(null);
    setUploadedFileData([]);
  }

  const getFieldsMappingHistory = async () => {
    if (syncContent === true) {
      const { data: mappingHistory } = await fieldsHistoryApi.request({
        business_partner: owner,
        account: customer,
        product: constants.PRODUCTS.DEBTORFINANACE,
        type: constants.FIELD_MAPPING.DF_INV_UPLOADER_MAP_SUPPLIER
      });

      if (mappingHistory.data.length > 0) {
        let newList = [...invoice];

        invoice.map((item, index) => {
          let res = mappingHistory.data.find(obj => obj.actual_field == item.debtor_name_in_sheet);
          if (res && newList[index]['inv_debtor_id'] === null) {
            let debtorInfo = debtors.find(item => item.value === res.mapped_field)
            newList[index]['inv_debtor_id'] = res.mapped_field;
            newList[index]['debtor_name'] = debtorInfo.label;
          }
        });

        setInvoice(newList);
      }
    }
    setSyncContent(false);
  }

  //------ Multistep page components start ----//

  // First Page
  const UploaderPage = () => {
    setSelPage(1);
    return (
      <>
        {location && !location.query.a_guid && (
          <div className="white-card mb-4">
            <div className="card-body">
              <div className="row col-lg-12">
                <FormColumnLg
                  col={3}
                  visible={category === constants.CATEGORY.INTL_OFFICE}
                >
                  <CountriesList
                    selectedCountry={country}
                    onSelectItem={({ id }) => setCountry(id)}
                  />
                </FormColumnLg>

                <FormColumnLg
                  col={3}
                  visible={
                    category === constants.CATEGORY.COUNTRY_OFFICE ||
                    category === constants.CATEGORY.INTL_OFFICE
                  }
                >
                  <BPList
                    selectedBp={owner}
                    type="horizontal"
                    country={country}
                    onSelectItem={({ value }) => {
                      setOwner(value);
                    }}
                  />
                </FormColumnLg>

                <FormColumnLg col={3}>
                  <DFCustomerList
                    selectedCustomer={customer}
                    bp={owner}
                    onSelectItem={(option) => {
                      setCustomer(option.value);
                      getRefNumber(option.value);
                    }}
                  />
                </FormColumnLg>
              </div>
            </div>
          </div>
        )}


        <div className="font-bold ">
          <FormRow>
            <div className="col-lg-12">
              <div className="col-lg-6">
                <label>Reference No : </label> <span>{refNo}</span>
              </div>
            </div>
          </FormRow>
          &nbsp;&nbsp;
          <ExcelFileUploader
            label="Browse Batch File"
            handleUploadItems={handleUploadItems}
          />
          <div className="row justify-content-center mt-5">
            <FormColumnLg col="12 text-center">
              <a
                className="btn btn-link text-primary"
                href={"/templates/batch_invoice_template.xls"}
              >
                <i className="fa fa-download mr-1"></i>Download Sample Batch
                Template
              </a>
            </FormColumnLg>
          </div>
          <FormRow style={{ margin: 15 }}>
            <FormColumnLg col="6">
              {fileInfo && (
                <>
                  <p className="font-bold"> Uploaded files list.</p>
                  <div className="mt-3">
                    {fileInfo && (
                      <p className="font-bold opacity-75"> <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "} {fileInfo.name}
                        <Link
                          className="ml-2 cursor-link action-icon text-secondary"
                          onClick={handleRemoveFile}
                        >
                          <i
                            alt="Remove"
                            className="fa fa-times"
                          ></i>
                        </Link>
                      </p>
                    )}

                  </div>
                </>
              )}
            </FormColumnLg>

            <FormColumnLg col="6">
              {/*fileInfo && ( */
                customer !== null ? (
                  fileInfo && fileInfo !== null ? (
                    <p className="font-bold text-right">  Click next button to proceed. </p>
                  ) : (
                    <p className="font-bold text-right text-danger">  Please upload file to proceed. </p>
                  )
                ) : (
                  <p className="font-bold text-right text-danger">  Please select the Client to proceed. </p>
                )
                //)
              }

            </FormColumnLg>
          </FormRow>
        </div>

      </>
    )
  }

  // Second Page
  const ListingPage = () => {
    setSelPage(2);
    return (
      <>
        <div className="card overflow-visible">
          <div className="card-body">
            <table className="table table-sm table-stripped client-tble">
              <tbody>
                <tr>
                  <th>Invoice No</th>

                  <th>Invoice Date</th>
                  <th>Debtor</th>
                  <th>Invoice Value</th>
                  <th>Due Date</th>
                  <th>Invoice / Credit Note</th>
                  <th>Action</th>
                </tr>
                {invoice.map((item, index) => (
                  <tr
                    className={
                      item.error && item.error === true && "text-danger"
                    }
                    title={item.error && item.error === true && item.errorMsg}
                  >
                    <td>
                      {item.inv_number === null ? (
                        <Link
                          onClick={(e) => handleInvoiceEdit(item)}
                          className="text-danger"
                        >
                          Add Invoice Number
                        </Link>
                      ) : (
                        item.inv_number
                      )}
                    </td>

                    <td>
                      {moment(item.inv_date, "YYYY/MM/DD").format("DD/MM/YYYY")}
                    </td>

                    <td>
                      {!item.debtor_name ? (
                        <Link
                          onClick={(e) => {
                            setDebtorModal(true);
                            location && !location.query.a_guid && handleDebtorEdit(item);
                          }}
                          className="text-danger cursor-link"
                          title="Click here update debtors"
                        >
                          Debtor Mismatch
                        </Link>
                      ) : (
                        item.debtor_name
                      )}
                    </td>

                    <td>
                      <CurrencyLabel value={item.inv_amount}></CurrencyLabel>
                    </td>

                    <td>
                      {moment(item.inv_due_date, "YYYY/MM/DD").format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                    <td>
                      <span
                        className={`badge ${item.inv_amount < 0
                          ? "badge-light text-danger"
                          : "badge-light text-secondary"
                          }  `}
                      >
                        {item.inv_amount < 0 ? "CR Notes" : "Invoice"}
                      </span>
                    </td>
                    <td>
                      <>
                        <Link className="text-secondary">
                          <EditButton
                            onClick={(e) => handleInvoiceEdit(item)}
                          ></EditButton>
                        </Link>
                        &nbsp;
                        <Link className="text-secondary">
                          <CopyButton
                            onClick={(e) => handleInvoiceCopy(item)}
                          ></CopyButton>
                        </Link>
                        &nbsp;
                        <Link className="text-secondary">
                          <DeleteButton
                            onClick={(e) => handleInvoiceDeletion(item.index)}
                          ></DeleteButton>
                        </Link>
                      </>
                    </td>
                  </tr>
                ))}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {
                      <strong>
                        <CurrencyLabel
                          value={_.sumBy(invoice, (item) =>
                            Number(item.inv_amount)
                          )}
                        ></CurrencyLabel>
                      </strong>
                    }
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td colSpan="14">&nbsp;&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <div className="row mt-2">
              <div className="col-lg-3 text-left">
                <label>Transaction Date</label>
                <DateTimePicker
                  name="date"
                  selectedDate={values.date}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
              <div className="col-lg-3 text-left">
                {
                  category === constants.CATEGORY.TRADERS ? (
                    ""
                  ) : (
                    <>
                      <div className="custom-control d-flex custom-checkbox mt-4 pt-1">
                        <input
                          id="default_open"
                          className="custom-control-input"
                          type="checkbox"
                          onClick={(e) =>
                            handleCheckBox(e.target.checked)
                          }
                          checked={values.checked}
                        ></input>
                        <label
                          className="custom-control-label"
                          for="default_open"
                        >
                          Move to open
                        </label>
                      </div>
                    </>
                  )

                }

              </div>
              <div className="col-lg-6 text-right pt-4">

                <GeneralButton
                  onClick={submitInvoice}
                  className="btn btn-primary btn-sm "
                  faIcon="fa fa-plus"
                  name="Save"
                  id="btn-submit"
                  style={{ visibility: "hidden" }}
                ></GeneralButton>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  // Third Page
  const FinishPage = () => {
    setSelPage(3);
    return (
      <>
        <center>
          <i className="fa fa-check-circle fa-3x text-success"></i> <br />
          <br></br>
          <h5> {headingMsg}</h5>
          <p>{alertMsg}</p>
          <br></br>

          <div className="row mt-4">
            <div className="col-lg-12">
              {
                category === constants.CATEGORY.TRADERS ? (
                  ""
                ) : (
                  // <GeneralIconButton onClick={e => { handleTab(transStatus[0][reOpenInv ? "OPEN" : "DRAFT"], "All WoB Invoices") }} className="btn btn-outline-secondary btn-xs mr-2" name="Go to Invoices List"></GeneralIconButton>
                  <GeneralIconButton onClick={e => { handleTab(transStatus[0]["OPEN"], "All WoB Invoices") }} className="btn btn-outline-secondary btn-xs mr-2" name="Go to Invoices List"></GeneralIconButton>
                )
              }

            </div>
          </div>
        </center>

      </>
    )
  }

  const componentsList = [
    {
      headerText: "Upload Batch",
      headerIcon: "fa fa-upload",
      component: <UploaderPage />,
    },
    {
      headerText: "View Uploads",
      headerIcon: "fa fa-list",
      component: <ListingPage />,
    },
    {
      headerText: "Finish",
      headerIcon: "fa fa-flag-checkered",
      component: <FinishPage />,
    }
  ];


  //------ Multistep page components ----//


  return (
    <>
      <Helmet>
        <title>{(location !== undefined && location.pathname === '/entities/details/debtorfinances') ? 'Client Account WoB Bulk Upload' : 'All Wob Invoices Bulk Upload'} | {global.config.title}</title>
      </Helmet>

      {(transactionInvApi.loading == true ||
        transactionApi.loading == true ||
        approveInvApi.loading == true ||
        disapproveCheckApi.loading == true) && <LoadingSpinner />}
      <Modal
        show={invModal}
        onHide={(e) => {
          setInvModal(false);
          setSelectedRow(null);
        }}
      >
        <InvoiceCreation
          selectedRow={selectedRow}
          onClose={(e) => {
            setInvModal(false);
            setSelectedRow(null);
          }}
          updateInvoice={updateInvoice}
        ></InvoiceCreation>
      </Modal>

      <Modal
        show={debtorModal}
        onHide={(e) => {
          setDebtorModal(false);
        }}
        size="lg"
      >
        <DFDebtorMapping
          onClose={(e) => {
            setDebtorModal(false);
          }}
          context={false}
          invoiceSet={invoice}
          handleUpdateInvoice={updateInvoice}
          selectedRow={selectedRow}
          debtorList={debtors}
        />
      </Modal>

      <Modal
        show={validationModal}
        onHide={(e) => {
          setValidationModal(false);
        }}
        size="lg"
      >
        <InvoiceErrors
          onClose={(e) => {
            setValidationModal(false);
          }}
          errors={errors}
          newInvoice={invoice}
          validationErrors={otherErrors}
          transactionGuid={transactionGuid}
          deleteByInvNumber={handleDeleteByInvNumber}
        />
      </Modal>

      {/*{invoice.length === 0 ? (
        
      ) : (
        
      )}*/}

      <MultiStepForm
        boxTitle={false}
        list={componentsList}
        displayProgressBar={true}
        proceedNext={proceedNext}
        hideNextBtn={hideNextBtn}
        hidePreviousBtn={hidePreviousBtn}
        navigate={navigate}
        handleCancel={(e) => onUploadClose()}
        showCard={false}
      />
    </>
  );
};

export default BatchUpload;
