import React, { useState, useEffect } from "react";

import {
    CountriesList,
    BPList,
    ProductsList,
  } from "../../../slplusTools";
  
import authService from "../../../../services/authService";
import ExcelFileUploader from "../../../common/inputs/fileUploader/ExcelUploader";
import { FormRow, FormColumnLg, ReportInputFieldContainer } from "../../../common/containers/formContainer";
import {
    DeleteButton
} from "../../../common/inputs";
import { confirmAlert } from "react-confirm-alert";
import { useUploader } from "../../../../context/secAccountsUploaderContext";

export default function Upload ({ contextValues, selectedProduct }){

    const { category, constants } = authService.getCurrentUser();
    const [excelFile, setExcelFile] = useState(null);

    const {
        handleUploadItems,
        uploadedFiles,
        setProceedNext,
        country,
        setCountry,
        owner,
        setOwner,
        handleRoleChange,
        setProducts,
        removeUploadedSheet
    } = useUploader(); // Accessing Context API

    const spreadSheetFileTypes = ["xls", "xlsx", "ods"]; // Allowed spreadsheet files to upload.

    useEffect(() => {

        let fileInfo = uploadedFiles.find((file) =>
            spreadSheetFileTypes.includes(file.name.split(".").pop())
        );

        if(fileInfo)
            setExcelFile(fileInfo);

        // Enable the next button only if it contains an excel sheet and owner selected.
        if (fileInfo && owner)
            setProceedNext(true);
        else 
            setProceedNext(false);

        // If the user is uploading data from the Client/Customer Profile.
        if(contextValues){ 
            setCountry(contextValues.country_id);
            if(typeof contextValues.owner.a_guid != 'undefined'){
                setOwner(contextValues.owner.a_guid);
            }
            
        }
        // ----------------------------------------------- //

    }, [uploadedFiles, contextValues, owner])

    return(
        <>
            
            <div className="mt-4">
                <ExcelFileUploader handleUploadItems={handleUploadItems} />
            </div>

            <FormRow style={{ margin: 15 }}>
                <FormColumnLg col="12 text-center">
                    <a href={"/templates/secondary_accounts_bulk_template.xls"}>
                        <h6>Download Sample Template</h6>
                    </a>
                </FormColumnLg>
            </FormRow>
        

            <FormRow style={{ margin: 15 }}>
                <FormColumnLg col="6">
                    {uploadedFiles.length > 0 && (
                        <>
                            <p className="font-bold"> Uploaded files list.</p>
                            <div className="mt-3 d-flex align-items-left">
                                {excelFile && (
                                    <>
                                        <DeleteButton
                                            className="fa fa-trash"
                                            onClick={() => {
                                            confirmAlert({
                                                customUI: ({ onClose }) => {
                                                return (
                                                    <div className="custom-delete-ui">
                                                    <h4>Remove Uploaded Sheet ?</h4>
                                                    <p>Are you sure want to continue?</p>
                                                    <button
                                                        className="btn btn-secondary ml-2"
                                                        onClick={() => { onClose(); }}
                                                    >
                                                        No
                                                    </button>
                                                    <button
                                                        className="btn btn-primary ml-2"
                                                        onClick={() => {
                                                            removeUploadedSheet(excelFile.name);
                                                            onClose();
                                                        }}
                                                    >
                                                        Yes
                                                    </button>
                                                    </div>
                                                );
                                                },
                                            });
                                            }}
                                        ></DeleteButton>&nbsp;&nbsp;
                                        <p className="font-bold opacity-75"> <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "} {excelFile.name} </p>
                                    </>
                                )}

                            </div>
                        </>
                    )}
                </FormColumnLg>

                <FormColumnLg col="6">
                    {excelFile && (
                        <p className="font-bold text-right">Click next button to proceed. </p>
                    )}
                </FormColumnLg>
            </FormRow>
        </>
    )
}