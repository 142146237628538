import React from "react";

const PasswordStrengthIndicator = ({ validity: { minChar, number, specialChar, lowerCaseChar, upperCaseChar } }) => {

    let strengthPctg = 0;

    if (minChar === true)
        strengthPctg += 20;

    if (number === true)
        strengthPctg += 20;

    if (specialChar === true)
        strengthPctg += 20;

    if (lowerCaseChar === true)
        strengthPctg += 20;

    if (upperCaseChar === true)
        strengthPctg += 20;

    return (
        <div className="password-validation-box mt-4">
            <h4>Your password must contain</h4>
            <ul>
                <li className={minChar ? 'success' : 'failure'}>8 Characters</li>
                <li className={upperCaseChar ? 'success' : 'failure'}>1 Uppercase Letter</li>
                <li className={lowerCaseChar ? 'success' : 'failure'}>1 Lowercase Letter</li>
                <li className={number ? 'success' : 'failure'}>1 Digit</li>
                <li className={specialChar ? 'success' : 'failure'}>1 Special Character</li>
            </ul>
            <div className="clearfix" />
            <div className="password-strenth">
                <div className="row no-gutters">
                    <div className="col-md-6">
                        <h5>Strength:
                            <span className="strong">
                                {strengthPctg < 50 ? ' Weak' : strengthPctg < 100 ? ' Medium' : ' Strong'}
                            </span>
                        </h5>
                    </div>
                    <div className="col-md-6">
                        <div className="progress h-5 mt-3">
                            <div style={{ width: `${strengthPctg}%` }} className={`progress-bar bg-${strengthPctg < 50 ? 'danger' : strengthPctg < 100 ? 'warning' : 'primary'}`} role="progressbar"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordStrengthIndicator;