import React from "react";
import "./css/card.css";
import { FormColumnMd } from "../../../common/containers/formContainer";
import { Link } from "react-router";
import CardSkelton from "./_cardSkelton";

function PrimaryCard({ col, loading, data, qrystring = false ,setProductRoleType}) {

    return (
        <>
            {loading === true ? <CardSkelton col={col ? col : 4} /> : (
                <FormColumnMd col={col ? col : 4} >
                    <div className="card">
                        <div className="card-body">
                            <span className="b-corner b-corner-r b-corner-danger" />
                            <div className="d-flex justify-content-between">
                                <h6 className="card-title ">{data ? data.topLabel : ""}</h6>
                                <div className="ml-auto">
                                    <div className="btn-group ml-5 mb-0"><a href="/client/settings">
                                    </a><a className="btn-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#"><i className="fa fa-ellipsis-v opacity-25" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mb-0">
                                <div>
                                    <h4 className="mb-1 font-weight-bold">
                                        {qrystring === true ?
                                            <Link className="text-secondary" to={data ? data.primaryBtnRoute : ''} 
                                                onClick ={
                                                () => { 
                                                    try{
                                                        setProductRoleType()
                                                    }catch(error){
                                                        console.error();
                                                    } 
                                                }
                                            }>{data ? data.topValue : ""}</Link> :
                                            <Link className="text-secondary" to={{ pathname: data ? data.primaryBtnRoute : '', state: data ? data.status : "" }} 
                                                onClick = {
                                                    () => { 
                                                        try{
                                                            setProductRoleType()
                                                        }catch(error){
                                                            console.error();
                                                        } 
                                                    }
                                                    }>{data ? data.topValue : ""}
                                            </Link>
                                        }
                                    </h4>
                                    <p className="mb-2 text-xs text-muted">
                                        <i className={`fa ${data ? data.subIcon : ''} text-danger mr-1`} />
                                        <span className="opacity-75">
                                            {data ? data.subTitle : ""}</span>
                                    </p>
                                </div>
                                <div className="card-chart bg-warning-transparent brround ml-auto mt-0">
                                    <i className="typcn typcn-chart-line-outline text-danger tx-24" />
                                </div>
                            </div>
                            <div>
                                <div className="progress progress-sm h-5 mt-2 mb-3">

                                    <div className="progress-bar bg-danger w-100 " role="progressbar" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="d-flex align-items-end">

                                        <div>
                                            <h6>{data ? data.leftValue : ""}</h6>
                                            <p className="font-weight-semibold opacity-50 mb-0">{data ? data.leftLabel : ""}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-end">

                                        <div>
                                            <h6>{data ? data.rightValue : ""}</h6>
                                            <p className="font-weight-semibold opacity-50 mb-0">{data ? data.rightLabel : ""}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </FormColumnMd>
            )
            }
        </>
    );
}

export default React.memo(PrimaryCard);