import React from "react";
import "./css/card.css";
import "./css/skelton.css";
import { FormColumnMd } from "../../../common/containers/formContainer";
function PrimaryCard({ col }) {

    return (
        <>
            <FormColumnMd col={col ? col : 8}>
                <div className="card mh-3" style={{ marginTop: 20 }}>

                    <div className="card__title loading"></div>
                    <div className="card__description loading"></div>
                </div>

            </FormColumnMd>

        </>
    );
}

export default React.memo(PrimaryCard);