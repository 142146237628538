import React, { useState, useContext, useEffect } from "react";
import entityService from "../../src/services/entityService";

const EntityContext = React.createContext();
EntityContext.displayName = "EntityContext";

export default EntityContext;

export function EntityProvider(props) {

    const [data, setData] = useState(null); // Context state to store the entity details

    const populateEntityDetails = async entityId => {
        if (typeof entityId !== "undefined") {
            const response = await entityService.getEntityDetails(entityId);
            setData(response.rows);
        }
    };

    useEffect(() => {
        //populateEntityDetails();
    }, []);

    const value = {
        populateEntityDetails,
        entityDetails: data
    }

    return <EntityContext.Provider value={value}>{props.children}</EntityContext.Provider>
}