import React, { useState, useContext } from "react";
import JoditEditor from "jodit-react";
import { Link } from "react-router";
import { CopyButton, CurrencyLabel } from "../../../../common/inputs";
import { EmailButton } from "../../../../common/inputs/button/button";
import Modal from "react-bootstrap/Modal";
import useApi from "../../../../../hooks/useApi";
import { toast } from "react-toastify";
import rate_services from "../../../../supply-chain/services/rate_services";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
const Transactions = ({ transactions }) => {
  const { transactionLoader } = useContext(SupplierProgramContext);
  const baseUrl = window.location.origin;

  const [isCopiedArray, setIsCopiedArray] = useState(
    Array(transactions.length).fill(false)
  );
  const [inviteModel, setInviteModel] = useState(false);
  const [invitationMsg, setInvitationMsg] = useState(null);
  const [url, setUrl] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [email, setEmail] = useState("");
  const [copyModal, setCopyModal] = useState(false);
  const [copyLoder, setCopyLoader] = useState(true);
  const [newUrl, setNewUrl] = useState("");
  const [expiryHours, setExpiryHours] = useState(0);

  const inviteSupplier = useApi(rate_services.invite_supplier_public_invoice);
  const inviteSupplierTemplate = useApi(rate_services.inviteSupplierTemplate);
  const generateTinyUrl = useApi(rate_services.generateUrl);
  const fetchTemplate = async (url, supplier_name, supplier_email) => {
    const response = await inviteSupplierTemplate.request({
      url: url,
      supplier_name: supplier_name,
      type: "public",
    });
    setInvitationMsg(response.data.content);
    setInviteModel(true);
    setUrl(url);
    setSupplierName(supplier_name);
    setEmail(supplier_email);
  };
  const invite = async () => {
    const { data } = await inviteSupplier.request({
      url: url,
      supplier_email: supplierName,
      emailToSend: email,
      content: invitationMsg,
    });

    if (data.message === "successfully sent") {
      toast.success(
        "The url to view invoice has been sent to your email successfully...!"
      );
      setInviteModel(false);
    } else {
      toast.error("failed to send ...!");
    }
  };

  const copyToClipboard = async (originalUrl) => {
    try {
      await navigator.clipboard.writeText(originalUrl);
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };
  const generate_Url = async (
    original_url,
    param,
    supplier_name = null,
    supplier_email = null
  ) => {
    if (param === true) {
      setCopyModal(true);
    }

    setCopyLoader(true);
    const { data } = await generateTinyUrl.request({
      original_url: original_url,
    });

    if (data.success === true) {
      setNewUrl(data.generated_url);

      const expiryInSeconds = data.expiryTime;
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
      const remainingSeconds = expiryInSeconds - currentTimeInSeconds;
      const hours = Math.floor(remainingSeconds / 3600);

      setExpiryHours(hours);
      setCopyLoader(false);
      if (param === false) {
        fetchTemplate(data.generated_url, supplier_name, supplier_email);
      }
    } else {
      toast.error("failed to generate Url...!");
    }
  };
  return (
    <>
      {transactionLoader && <LoadingSpinner />}
      <table className="table">
        <thead>
          <th>#</th>
          <th>Invoice No</th>

          <th>Supplier</th>
          <th>Amount</th>
          <th>EP Days</th>
          <th>Discount Rate</th>
          <th></th>
        </thead>
        <tbody>
          {transactions.length > 0 ? (
            transactions.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.inv_number}</td>

                <td>{item.supplier_name}</td>
                <td>
                  <CurrencyLabel value={item.inv_amount} />
                </td>
                <td>{item.ep_days}</td>
                <td>{item.dicount_rate}</td>
                <td style={{ display: "flex" }}>
                  <Link
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="text-secondary"
                  >
                    <CopyButton
                      onClick={() =>
                        generate_Url(
                          `/client/public/view-invoice?guid=${item.mapping_guid}`,
                          true
                        )
                      }
                    />
                    <small>{isCopiedArray[index] ? "copied" : ""}</small>
                  </Link>
                  <Link
                    style={{ cursor: "pointer", display: "flex" }}
                    className="text-secondary ml-3"
                  >
                    <EmailButton
                      onClick={() => {
                        generate_Url(
                          `/client/public/view-invoice?guid=${item.mapping_guid}`,
                          false,
                          item.supplier_name,
                          item.supplier?.email
                        );
                      }}
                    />
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">
                <div
                  className="mt-2"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  No transactions...!
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* invite modal */}
      <div>
        <Modal
          show={inviteModel}
          onHide={() => setInviteModel(false)}
          size="lg"
          centered
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Invite Supplier</h4>
          </Modal.Header>
          <Modal.Body>
            <JoditEditor
              value={invitationMsg}
              onChange={(value) => {
                setInvitationMsg(value);
              }}
            />
          </Modal.Body>

          <Modal.Footer>
            <div className="row text-right">
              <button
                type="button"
                className="btn btn-light  mr-2"
                data-dismiss="modal"
                onClick={(e) => setInviteModel(false)}
              >
                Close
              </button>

              <button
                type="submit"
                className="btn btn-primary  ml-2"
                onClick={(e) => invite()}
              >
                Send Invitation
              </button>
            </div>
          </Modal.Footer>

          <div style={{ width: 700, height: 20 }}></div>
        </Modal>
      </div>

      {/* copy modal */}
      <div>
        <Modal
          show={copyModal}
          onHide={(e) => {
            setCopyModal(false);
          }}
          size="lg"
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <div className="">
              <h3 className="modal-title">Generating URL</h3>
            </div>
          </Modal.Header>
          <Modal.Body style={{ height: "150px" }}>
            {copyLoder && <LoadingSpinner />}
            <div>
              <div>
                {newUrl !== "" ? (
                  <>
                    <label style={{ fontWeight: "bold" }}>
                      Generated Url :{" "}
                    </label>
                    {newUrl}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-2">
                <small style={{ color: "red" }}>
                  Note : This url will expire in {expiryHours} hours
                </small>
              </div>

              <div className="pull-right" style={{ marginTop: "2rem" }}>
                <button
                  className="btn btn-white"
                  onClick={() => setCopyModal(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-outline-primary ml-2"
                  onClick={() => {
                    copyToClipboard(newUrl);
                    setCopyModal(false);
                  }}
                >
                  Copy
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Transactions;
