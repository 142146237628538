import React, { useEffect, useState } from "react";
import TeamMembersWidget from "../widgets/teamMembers";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import { GeneralButton } from "../../common/inputs";
import QuickEntityContactCreation from '../../entities/profile/_quickEntityCreation';

import entityService from "../../../services/entityService";
import authService from "../../../services/authService";
import useApi from '../../../hooks/useApi';

function TeamMembers() {

    const [data, setData] = useState([]);
    const [createModal, setCreateModal] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    //------------Api Services--------------------------------------------
    const contactApi = useApi(entityService.getEntityContactsList);
    const loginHistoryApi = useApi(entityService.entityLoginHistory);


    const { entity_id } = authService.getCurrentUser();

    const fetchTeamMembers = async () => {
        const { data } = await contactApi.request(entity_id);
        setData(data.rows);
    }

    const fetchLoginHistory = async () => {
        const { data } = await loginHistoryApi.request({ entity: entity_id });
        setData(data.rows);
    }

    const handleAfterCreation = () => {
        setCreateModal(false);
        setActiveTabIndex(0);
        fetchTeamMembers();
    }

    const handleTabChange = (tabIndex) => {
        if (tabIndex === 0)
            fetchTeamMembers();
        else
            fetchLoginHistory();
        setActiveTabIndex(tabIndex);
    }

    useEffect(() => {
        fetchTeamMembers();
    }, []);

    return (
        <>
            <Helmet>
                <title>Team Members | {global.config.title}</title>
            </Helmet>
            {(contactApi.loading == true || loginHistoryApi.loading == true) && <LoadingSpinner />}

            {/*------------------Quick Contact Creation Modal-------------------*/}
            <Modal show={createModal} onHide={e => setCreateModal(false)} center>
                <QuickEntityContactCreation
                    entityId={entity_id}
                    handleEntityContactCreation={handleAfterCreation}
                ></QuickEntityContactCreation>
                <div style={{ width: 500, height: 20 }}></div>
            </Modal>
            {/*---------------------------------------------------------*/}


            <div className="card overflow-visible">
                <div className="card-header">
                    <div className="row">
                        <div className="col-lg-6"><h6>Team Members</h6></div>
                        <div className="col-lg-6">
                            <Link className="btn btn-light pull-right text-secondary ml-2" to="/client/settings"><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="card-body  ">
                    <div className=" ">
                        <div className=" ">
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <span className="pull-right mb-2">
                                        <GeneralButton className="btn btn-primary btn-sm float-right" name="Add a Team Member" onClick={e => setCreateModal(true)} />
                                    </span>
                                    <h6 className="w-100 float-left float-md-none">
                                        <Link className="cursor-link" onClick={e => handleTabChange(0)}><span className={`tab-button d-block d-md-inline ${activeTabIndex === 0 ? 'active' : ''}`}>Active Team Members</span></Link>
                                        <Link className="cursor-link" onClick={e => handleTabChange(1)}><span className={`tab-button d-block d-md-inline ${activeTabIndex === 1 ? 'active' : ''}`}>Login History</span></Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TeamMembersWidget type={activeTabIndex} data={data} clientId={entity_id} fetchTeamMembers={fetchTeamMembers} />
                </div>
            </div>
        </>
    )
}

export default TeamMembers;