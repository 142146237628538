import React, { useContext, useState, useEffect, useRef } from "react";
import Joi from "joi-browser";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import {
  TextInput,
  GeneralButton,
  Picker,
  DateTimePicker,
  CurrencyText,
  CurrencyLabel
} from "../../../common/inputs";
import * as moment from "moment";
import useApi from "../../../../hooks/useApi";
import loanService from "../../../loan/services/loanTransactions";
import EntityContext from "../../../../context/entityContext";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import auth from "../../../../services/authService";

export default function LoanCreation(props) {

  const entityContext = useContext(EntityContext);

  const { constants } = auth.getCurrentUser();

  const defaultLoanData = {
    loan_amount: 0,
    start_date: moment(new Date()).format("YYYY-MM-DD").toString(),
    dd_start_date: moment(new Date()).format("YYYY-MM-DD").toString(),
    loan_type: "Traditional",
    terms: "30",
    workingCapitalLoan: "No",
    fee: [],
    fee_per: "",
    owing_fee_type: "Annual",
    selected_loan_type: {
      label: "Principle & Interest",
      value: "Traditional",
    },
    selected_loan_term: {
      label: "Monthly",
      value: 30,
    },
    selected_fee_type: {
      label: "Annual",
      value: "Annual",
    },
    reference_no: null,
    repayPeriod: null
  };

  const [loanData, setLoanData] = useState(defaultLoanData);
  const [refNo, setRefNumber] = useState(true);
  const [repayMethodLabel, setRepayMethodLabel] = useState("Repayments - Number of months");
  const [workingCapLimit, setWorkingCapLimit] = useState("")
  const [isSubmited, setSubmited] = useState(false);
  const [errors, setErrors] = useState(false);
  const [loanSettings, setLoanSettings] = useState(null);

  const [customer, setCustomer] = useState(null);


  const loanReferenceApi = useApi(loanService.generateLoanReference);
  const checkWClimitApi = useApi(loanService.CheckWorkingCapitalLimit);
  const createDraftLoanApi = useApi(loanService.CreateDraftLoan);
  const getLoanSettingApi = useApi(loanService.FundSummary);


  const getRefNumber = async (customer_guid) => {
    const { data } = await loanReferenceApi.request({
      client_guid: customer_guid,
    });

    setRefNumber(data);

    let loan_data = { ...loanData };

    loan_data['reference_no'] = data;
    setLoanData(loan_data);

    return data;
  };

  const getLoanSettings = async (client) => {
    const { data } = await getLoanSettingApi.request(
      {
        client: client
      }
    );
    if (data.success == true) {
      if (data.list !== null) {
        setLoanSettings(data.list);
      }
    } else {
      toast.error("Failed to fetch settings");
    }
  }

  const handleChange = (value, field) => {
    let loan_data = { ...loanData };

    loan_data[field] = value;
    setLoanData(loan_data);
  }

  const handleLoanTypeChange = (selectedOption) => {
    const loan_data = { ...loanData };
    loan_data["loan_type"] = selectedOption.value;
    loan_data["selected_loan_type"] = {
      label: selectedOption.label,
      value: selectedOption.value,
    };
    setLoanData(loan_data);

  };

  const handleTermChange = (selectedOption) => {
    let label = "";
    let loan_data = { ...loanData };
    loan_data["terms"] = selectedOption.value;
    loan_data["selected_loan_term"] = {
      label: selectedOption.label,
      value: selectedOption.value,
    };
    if (selectedOption.label == 'Weekly') {
      label = "Repayments - Number of weeks";
    } else if (selectedOption.label == 'Fortnightly') {
      label = "Number of Repayments";
    } else {
      label = "Repayments - Number of Months";
    }
    loan_data["repayPeriod"] = 0;
    setLoanData(loan_data);
    updateRepayLabel(true,true,label);
  };

  const handleOwingFeeChange = (selectedOption) => {
    const loan_data = { ...loanData };
    loan_data["selected_fee_type"] = {
      label: selectedOption.label,
      value: selectedOption.value,
    };
    loan_data["owing_fee_type"] = selectedOption.value;
    setLoanData(loan_data);
  };


  const handleWorkingCapSelection = async (checked) => {
    const loan_data = { ...loanData };

    if (checked === true) {
      loan_data["workingCapitalLoan"] = 'Yes';
      loan_data['selected_loan_type']= { label: "Interest Only", value: "Other" };
      setLoanData(loan_data);
      updateRepayLabel(true);
      await validateWorkingCapitalLimit(true);

    } else if (checked === false) {
      loan_data["workingCapitalLoan"] = 'No';
      loan_data['selected_loan_type']= {
        label: "Principle & Interest",
        value: "Traditional",
      };
      setLoanData(loan_data);
      setWorkingCapLimit("");
      updateRepayLabel(false);
    }

  }

  // Update repayment label
  const updateRepayLabel = (drawDawn,checkDrwDnEnabled = false,labelSplit = "") => {
    if(checkDrwDnEnabled == true){
      const loan_data = { ...loanData };
      if(loan_data["workingCapitalLoan"] == 'Yes'){
        drawDawn = true;
      } else {
        drawDawn = false;
      }
    }
    let repay_label = "";
    if(labelSplit == ""){
      labelSplit = repayMethodLabel.split(' - ');
    } else {
      labelSplit = labelSplit.split(' - ');
    }
    
    if(labelSplit.length > 1){
      if(drawDawn){
        repay_label = 'Drawn Down Period - '+labelSplit[1];
      } else {
        repay_label = 'Repayments - '+labelSplit[1];
      }
    } else {
      
      if(drawDawn){
        repay_label = 'Drawn Down Period';
      } else {
        repay_label = 'Number of Repayments';
      }
    }
    setRepayMethodLabel(repay_label);
  }

  // function to check the limit of working capital loan
  const validateWorkingCapitalLimit = async (doValidation = false) => {
    const loan_data = { ...loanData };
    if (doValidation == false) {
      if (loan_data["workingCapitalLoan"] == 'No') {
        return true;
      }
    }

    const response = await checkWClimitApi.request({
      entity_id: customer,
      loan_amount: loan_data['loan_amount']
    });

    if (response) {
      if (response.data.tf_limit) {
        setWorkingCapLimit({
          available_balance: response.data.available_balance,
          tf_limit: response.data.tf_limit,
          working_capital_sum: response.data.working_capital_sum,
        })
      }
      if (response.data.success) {
        return true;
      } else {

        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-delete-ui">
                <h4>Failed!</h4>
                <p>{response.data.message}</p>
                <button
                  className="btn btn-white"
                  onClick={() => {
                    onClose();
                  }}
                >
                  close
                </button>
              </div>
            );
          },
          title: "Failed!",
          message: response.data.message,
          overlayClassName: "zindex-2100"
        });
        return false;
      }
    } else {
      toast.error("Unexpected error occured. Failed to validate working capital limit");
      return false;
    }
  }

  const checkLoanLimit = () => {

    if (loanSettings.type == 'revolving') {
      if ((parseFloat(loanSettings.balanceAvailable) - parseFloat(loanData.loan_amount)) < 0) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (entityContext && typeof entityContext.entityDetails !== "undefined") {
      const customer_guid = entityContext.entityDetails.a_guid;
      setCustomer(customer_guid);
      getRefNumber(customer_guid);
      getLoanSettings(customer_guid);
    }

  }, []);

  const schema = Joi.object() // Form validation schema object
    .keys({
      reference_no: Joi.string()
        .required()
        .error(() => {
          return { message: "Please enter a valid Reference No" };
        }),
      loan_amount: Joi.string().required().error((errors) => { return { message: "Please enter loan amount" }; }),
      start_date: Joi.date().iso().required().error((errors) => { return { message: "Please enter Loan start date" }; }),
      loan_type: Joi.string().required().error((errors) => { return { message: "Please enter loan type" }; }),
      terms: Joi.required().error((errors) => { return { message: "Please enter Repayment Type" }; }),
      repayPeriod: Joi.number().integer().required().error((errors) => { return { message: "Please enter Repayment period" }; }),
      dd_start_date: Joi.date().iso().required().min(Joi.ref('start_date')).raw().error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case "date.min":
              err.message = "DD start date must be greater than Loan start date";
              break;
            default:
              err.message = `Please enter valid DD start date`;
              break;
          }
        });
        return errors;
      }),
    })
    .unknown(true);

  const validate = () => {
    const options = { abortEarly: true };
    const { error } = Joi.validate(loanData, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
      //this.focusInput(item.path[0]);
    }
    setSubmited(false);
    setErrors(true);
    return errors;
  };

  //----------------- add loan details to api ------------------//

  const save = async () => {

    setSubmited(true);
    const errors = validate();
    if (errors) return;

    if (loanData.loan_amount < 1) {
      setSubmited(false);
      toast.error("Loan amount must be a valid amount");
      return false;
    }

    if (loanData.repayPeriod < 1) {
      setSubmited(false);
      toast.error("Please enter a valid repay period");
      return false;
    }

    if (!loanSettings) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-delete-ui">
              <h4>Loan Settings Missing</h4>
              <p>{`Add your loan default setting to continue..`}</p>
              <button
                className="btn btn-white"
                onClick={() => {
                  onClose();
                }}
              >
                close
              </button>
            </div>
          );
        },
        title: "Loan Settings Missing",
        message: `Add your loan default setting to continue.`,
        overlayClassName: "zindex-2100"
      });
      setSubmited(false);
      return false;
    }

    if (parseInt(loanSettings.maximumTerms) < parseInt(loanData.repayPeriod)) {
      toast.error("Maximum term limit is " + loanSettings.maximumTerms);
      setSubmited(false);
      return false;
    }
    const canProcessWithWorkingCap = await validateWorkingCapitalLimit();
    if (canProcessWithWorkingCap == false) {
      setSubmited(false);
      return false;
    }

    if (checkLoanLimit()) {

      const loan_data = {
        ...loanData,
        trans_client_reference: refNo,
        trans_client_guid: customer,
        clientSide: true
      };

      const { data } = await createDraftLoanApi.request(loan_data);

      if (data.success === false) {
        toast.error(data.message);
        return false;
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-delete-ui">
                <h4>We are processing your request</h4>
                <p>{`Your loan has been sucessfully created. We are currently processing it`}</p>
                <button
                  className="btn btn-white"
                  onClick={() => {
                    onClose();
                  }}
                >
                  close
                </button>
              </div>
            );
          },
          title: "We are processing your request",
          message: `Your loan has been sucessfully created. We are currently processing it`,
          overlayClassName: "zindex-2100"
        });

        props.reload();
      }


    } else {

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-delete-ui">
              <h4>Limit exeed</h4>
              <p>{`You have exceeded your loan approval limit.`}</p>
              <button
                className="btn btn-white"
                onClick={() => {
                  onClose();
                }}
              >
                close
              </button>
            </div>
          );
        },
        title: "Limit exeed",
        message: `You have exceeded your loan approval limit.`,
        overlayClassName: "zindex-2100"
      });
      setSubmited(false);
      return false;
    }
  };

  const handleChangeRepayPeriod = (inputVal) => {
    handleChange(inputVal, 'repayPeriod');
    if (loanSettings && loanSettings.maximumTerms) {
      if (parseInt(loanSettings.maximumTerms) < parseInt(inputVal)) {
        toast.error("Maximum term limit is " + loanSettings.maximumTerms)
      }
    } else {
      toast.error("Loan settings missing. Please add maximum terms to continue.");
    }
  }


  return (
    <>
      {(loanReferenceApi.loading === true || createDraftLoanApi.loading === true || checkWClimitApi.loading == true) &&
        <LoadingSpinner />}

      <Modal.Header closeButton >
        <h4 className="modal-title">Request a New Loan</h4>
      </Modal.Header>

      <Modal.Body>
        <form className="form-horizontal">
          <div className="row">
            <div className="col-lg-12">
              <>
                <div class="card-body">
                  <form className="form-horizontal">
                    {
                      entityContext && typeof entityContext.entityDetails !== "undefined" &&
                      entityContext.entityDetails.products.findIndex(obj => (obj.id == constants.PRODUCTS.TRADEFINANCE && obj.isChecked == true)) !== -1 && (
                        <>
                          <div className="form-group">
                            <div className="custom-control d-flex custom-checkbox small">
                              <input
                                id={`working_capital_checkbox`}
                                className="custom-control-input"
                                type="checkbox"
                                onClick={(e) =>
                                  handleWorkingCapSelection(e.target.checked)
                                }
                                checked={loanData.workingCapitalLoan === 'Yes' ? "true" : ""}
                              ></input>
                              <label
                                className="custom-control-label"
                                for={`working_capital_checkbox`}
                              >Draw from Trade Facility {
                                  workingCapLimit && (
                                    <>
                                      <span className="small ml-2  p-1 badge badge-danger">
                                        Availability : <CurrencyLabel value={workingCapLimit.available_balance}></CurrencyLabel>
                                      </span>
                                    </>
                                  )
                                }</label>
                            </div>
                          </div>
                        </>
                      )
                    }


                    {/* <div className=" form-group">

                      <label className="control-label">
                        client Reference No
                      </label>
                      <div>
                        <TextInput
                          name="reference_no"
                          label="Reference No"
                          value={loanData.reference_no}
                          type="normal"
                          onChange={(e) => { handleChange(e.target.value, 'reference_no') }}
                        />
                      </div>
                    </div> */}

                    <div className="form-group ">

                      <label>How much do you require?</label>

                      <CurrencyText
                        value={loanData.loan_amount}
                        onChangeEvent={(event, maskedvalue, floatvalue) => {
                          handleChange(floatvalue, 'loan_amount', floatvalue);
                        }}
                        placeholder="Principle Amount"
                      />
                    </div>

                    <div className="form-group ">

                      <label>When is the loan to start</label>

                      <DateTimePicker
                        name="start_date"
                        selectedDate={loanData.start_date}
                        onChange={(e) => {
                          handleChange(e.target.value, 'start_date');
                        }
                        }
                        enableWeekDays="true"
                      />

                    </div>

                    <div className="form-group ">

                      <label>Date to start collect Direct Debit</label>

                      <DateTimePicker
                        name="dd_start_date"
                        selectedDate={loanData.dd_start_date}
                        onChange={(e) => {
                          handleChange(e.target.value, 'dd_start_date');
                        }
                        }
                        enableWeekDays="true"
                      />

                    </div>

                    <div className="form-group ">

                      <label>What loan type would you like?</label>

                      <Picker
                        items={[
                          { label: "Principle & Interest", value: "Traditional" },
                          { label: "Interest Only", value: "Other" },
                        ]}
                        selectedItem={loanData.selected_loan_type}
                        onSelectItem={handleLoanTypeChange}
                        //reference="loan_type"
                        name="loan_type"
                      />

                    </div>

                    <div className="form-group ">

                      <label>Frequency of repayments</label>

                      <Picker
                        items={[
                          { label: "Weekly", value: 7 },
                          { label: "Fortnightly", value: 14 },
                          { label: "Monthly", value: 30 },
                        ]}
                        selectedItem={loanData.selected_loan_term}
                        onSelectItem={handleTermChange}
                        name="terms"
                      />

                    </div>

                    <TextInput
                      name="repayPeriod"
                      value={loanData.repayPeriod}
                      onChange={(e) => {
                        handleChangeRepayPeriod(e.target.value);
                      }
                      }
                      type="vertical"
                      placeholder={repayMethodLabel}
                      label={repayMethodLabel}
                    ></TextInput>

                    <div className="row mt-5">
                      <div className="col-lg-12 text-right">
                        <GeneralButton
                          type="button"
                          disabled={isSubmited ? true : false}
                          onClick={(e) => save()}
                          faIcon="fa fa-save"
                          className="btn btn-primary pull-right ml-2"
                          name="Save"
                        ></GeneralButton>
                      </div>

                    </div>

                  </form>

                </div>
              </>

            </div>
          </div>
        </form>
      </Modal.Body>
    </>
  );
}
