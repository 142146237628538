import React from "react";
// Importing Widgets
import { FormRow } from "../../../common/containers/formContainer";
import { PendingTransactions, ApprovedScheduled, RejectedInvoices, DueMonth, OverDueMonth, DisbursementsGraph, MappedDebtors, AwaitingPayments } from "../../widgets/IFTransWidgets";

function InvoiceSnapshot() {
    return (
        <>
            <FormRow>
                <PendingTransactions />
                <AwaitingPayments />
                <ApprovedScheduled />
            </FormRow>

            <FormRow>

                <DisbursementsGraph />
                
                <div className="col-md-4 col-sm-4 col-xs-2 col-12">
                    <FormRow>
                        <DueMonth />                        
                    </FormRow>

                    <FormRow>
                        <OverDueMonth />
                    </FormRow>

                    <FormRow>
                        <MappedDebtors />
                    </FormRow>

                    <FormRow>
                        <RejectedInvoices />
                    </FormRow>

                </div>
            </FormRow>
        </>
    )
}
export default InvoiceSnapshot;