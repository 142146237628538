import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router";
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";
import RoleChecker from "../../../../utils/roleChecker";

const QuickLaunch = (props) => {

  const { terminology, primaryFacilities, entityDetails, accountDetails, purchaseRoles, salesRoles, specialProgramEnabledSCF, specialProgramEnabledTF } = useContext(EntityContext);
  const { constants, products } = authService.getCurrentUser();
  const [launchItems, setLaunchItems] = useState([]);

  const scfAccountInfo = purchaseRoles.length > 0 ? purchaseRoles.find(item => item.product == constants.PRODUCTS.SUPPLYCHAIN) : null;
  const tfAccountInfo = purchaseRoles.length > 0 ? purchaseRoles.find(item => item.product == constants.PRODUCTS.TRADEFINANCE) : null;
  const ifAccountInfo = salesRoles.length > 0 ? salesRoles.find(item => item.product == constants.PRODUCTS.INVOICE) : null;

  useEffect(() => {
    
    let items = [];

    if(entityDetails){

      // SCF Customer
      const scfBankInfo     = accountDetails.find(item => item.product == constants.PRODUCTS.SUPPLYCHAIN);
      const scfProductInfo  = entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.SUPPLYCHAIN);

      if(RoleChecker.check(products, constants.ROLES.CUSTOMER) )
        items.push({ 
          product: constants.PRODUCTS.SUPPLYCHAIN, 
          businessType: constants.BUSINESS_TYPE.PURCHASE,
          label: `Facility ${scfAccountInfo ? scfAccountInfo.facilityNumber : ''}: ${scfAccountInfo ? scfAccountInfo.nick_name : ''}`,
          disabled: (!scfBankInfo || !scfProductInfo.default_settings_available)
        })

      // IF Client
      const ifBankInfo    = accountDetails.find(item => item.product == constants.PRODUCTS.INVOICE);
      const ifProductInfo = entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.INVOICE);

      if(RoleChecker.check(products, constants.ROLES.CLIENT))
        items.push({ 
          product: constants.PRODUCTS.INVOICE, 
          businessType: constants.BUSINESS_TYPE.SALE,
          label: `Facility ${ifAccountInfo ? ifAccountInfo.facilityNumber : ''}: ${ifAccountInfo ? ifAccountInfo.nick_name : ''}`,
          disabled: (!ifBankInfo || !ifProductInfo.default_settings_available)
        })
      

      // TF Customer
      const tfBankInfo     = accountDetails.find(item => item.product == constants.PRODUCTS.TRADEFINANCE);
      const tfProductInfo  = entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.TRADEFINANCE);

      if(RoleChecker.check(products, constants.ROLES.TFCUSTOMER))
        items.push({ 
          product: constants.PRODUCTS.TRADEFINANCE, 
          businessType: constants.BUSINESS_TYPE.PURCHASE,
          label: `Facility ${tfAccountInfo ? tfAccountInfo.facilityNumber : ''}: ${tfAccountInfo ? tfAccountInfo.nick_name : ''}`,
          disabled: (!tfBankInfo || !tfProductInfo.default_settings_available)
        })
    
      // SCF Supplier / TF Supplier
      if(RoleChecker.check(products, constants.ROLES.SUPPLIER) || RoleChecker.check(products, constants.ROLES.TFSUPPLIER))
        items.push({ 
          product: 
          (RoleChecker.check(products, constants.ROLES.SUPPLIER) && RoleChecker.check(products, constants.ROLES.TFSUPPLIER)) ? constants.PRODUCTS.CUSTOMERPROGRAMS :
          RoleChecker.check(products, constants.ROLES.SUPPLIER) ? constants.PRODUCTS.SUPPLYCHAIN : constants.PRODUCTS.TRADEFINANCE,
          businessType: constants.BUSINESS_TYPE.SALE,
          label: salesRoles.length > 0 ? salesRoles.find(item => item.product == constants.PRODUCTS.CUSTOMERPROGRAMS).name : null
        })

        setLaunchItems(items);
    }

  }, [purchaseRoles, salesRoles, entityDetails])


  return (
    <div>

      {(launchItems.length > 0 || primaryFacilities !== false) &&
        <button
          type="button"
          className=" btn btn-primary rounded-circle dropdown-toggle shadow-lg py-0 "
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="text-2xl">+</span>
        </button>
      }

      <div className="dropdown-menu dropdown-menu-right">

        {(!specialProgramEnabledSCF && !specialProgramEnabledTF) && 
          <>
            <div className="px-3 py-2 font-weight-bold">Add new invoices </div>

            {launchItems.filter(obj => !obj.disabled).map((item, index) => 
              <React.Fragment key={index}>
                <div className="dropdown-divider"></div>
                  <Link style={{ cursor: 'pointer' }}
                    onClick={() => { props.openInvoiceModal(item.product, item.businessType) }}
                    className="dropdown-item"
                  >
                    {item.label}
                  </Link>
              </React.Fragment>
            )}
          </>
        }

        {primaryFacilities !== false && (
          <>
            <div className="dropdown-divider"></div>
              <Link style={{ cursor: 'pointer' }} to={'/client/users-network/supplier?action=create'} className="dropdown-item">
                <span className="font-weight-bold">Add {terminology && terminology.SUPPLIER}</span>
              </Link>
            <div className="dropdown-divider"></div>
              <Link style={{ cursor: 'pointer' }} to={'/client/users-network/customer?action=create'} className="dropdown-item">
                <span className="font-weight-bold">Add {terminology && terminology.CUSTOMER}</span>
              </Link>
          </>
        )}

      </div>
    </div>
  );
};

export default QuickLaunch;
