import http from "./httpService";
import { apiUrl, queryString } from "../../src/environment";
import client from "./serviceClient";
let accessToken = "";

function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
}

/** ----------------------- Manage Countries -----------------------  **/

// ------ Service to create new country
export async function createNewCountry(
  id,
  name,
  iso2,
  iso3,
  currency_sign,
  currency_symbol,
  timezone,
  flag_class,
  status
) {
  updateHeader();

  const { data } = await http.post(
    apiUrl + "/core/countries/create-country?XDEBUG_SESSION_START=PHPSTORM",
    {
      id,
      name,
      iso2,
      iso3,
      currency_sign,
      currency_symbol,
      timezone,
      flag_class,
      status,
    },
    accessToken
  );
  return data;
}

// ------ Service to list list the countries in a dropdown
export async function getCountriesArray() {
  updateHeader();
  const { data } = await http.post(
    apiUrl +
      "/core/countries/get-countries-array?XDEBUG_SESSION_START=PHPSTORM",
    {},
    accessToken
  );
  return data;
}

// ------ Service to list list the countries in a dropdown
export async function getDefaults() {
  updateHeader();
  const { data } = await http.post(
    apiUrl + "/core/auth/get-defaults?XDEBUG_SESSION_START=PHPSTORM",
    {},
    accessToken
  );
  return data;
}

// ------ Service to delete the country
export async function deleteCountry(id) {
  updateHeader();

  const { data } = await http.post(
    apiUrl + "/core/countries/delete-country?XDEBUG_SESSION_START=PHPSTORM",
    {
      id,
    },
    accessToken
  );
  return data;
}

// ------ Service to list the countries in grid
export async function getCountriesList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl + "/core/countries/list-countries?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to populate the country details in an edit form
export async function getCountryDetails(id) {
  updateHeader();

  let url =
    apiUrl +
    "/core/countries/get-country-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    id,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

/** ----------------------- ***************** -----------------------  **/

/** ----------------------- Manage Templates -----------------------  **/

// ------ Service to populate templates list in a grid
export async function getMailTemplatesList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl +
    "/core/mail-templates/list-templates?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to create email template
export async function createEmailTemplate(
  template_guid,
  template_name,
  text,
  product_id,
  status,
  fileslist
) {
  updateHeader();

  const { data } = await http.post(
    apiUrl +
      "/core/mail-templates/create-template?XDEBUG_SESSION_START=PHPSTORM",
    {
      template_guid,
      template_name,
      text,
      product_id,
      status,
      fileslist,
    },
    accessToken
  );
  return data;
}

// ------ Service to delete email template
export async function deleteEmailTemplate(template_guid) {
  updateHeader();

  const { data } = await http.post(
    apiUrl +
      "/core/mail-templates/delete-template?XDEBUG_SESSION_START=PHPSTORM",
    {
      template_guid,
    },
    accessToken
  );
  return data;
}

// ------ Service to populate the template details in an edit form
export async function getTemplateDetails(template_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/core/mail-templates/get-template-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    template_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to fetch and list template attachments
export async function getTemplateAttachments(template_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/core/mail-templates/fetch-attachments?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { template_guid };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to fetch and list template attachments
export async function deleteTemplateAttachment(attachment_id) {
  updateHeader();

  let url =
    apiUrl +
    "/core/mail-templates/delete-attachment?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { attachment_id };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to fetch all product attributes
export async function fetchProducts() {
  updateHeader();

  let url = apiUrl + "/core/auth/fetch-products?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {};

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

/** ----------------------- ***************** -----------------------  **/

// ------ Service to products with type = transaction
export async function fetchTransProducts() {
  updateHeader();

  let url =
    apiUrl + "/core/auth/fetch-trans-products?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {};

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

/** ----------------------- ***************** -----------------------  **/

/** ----------------------- Manage User Types -----------------------  **/

// ------ Service to populate user types list in a grid
export async function getUserTypesList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let url = apiUrl + "/core/auth/list-user-types?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to populate user types list in a dropdown menu
export async function GetUserTypes(parent, type, list) {
  updateHeader();

  let url = apiUrl + "/core/auth/get-user-types?XDEBUG_SESSION_START=PHPSTORM";

  try {
    const { data } = await http.post(url, { parent, type, list }, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

// ------ Service to create new user type
export async function createNewUserType(
  id,
  name,
  description,
  status,
  parent_category,
  categType
) {
  updateHeader();
  if (status === "") status = "active";
  const { data } = await http.post(
    apiUrl + "/core/auth/create-user-type?XDEBUG_SESSION_START=PHPSTORM",
    {
      id,
      name,
      description,
      status,
      parent_category,
      categType,
    },
    accessToken
  );
  return data;
}

// ------ Service to delete the user type
export async function deleteUserType(id) {
  updateHeader();

  const { data } = await http.post(
    apiUrl + "/core/auth/delete-user-type?XDEBUG_SESSION_START=PHPSTORM",
    {
      id,
    },
    accessToken
  );
  return data;
}

// ------ Service to populate the user type details in an edit form
export async function getUserTypeDetails(id) {
  updateHeader();

  let url =
    apiUrl + "/core/auth/get-user-type-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    id,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to populate the user type tabs list on user types listing
export async function getUserTypeTabsList() {
  updateHeader();

  let url =
    apiUrl + "/core/auth/get-user-type-tabs?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {};

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

/** ----------------------- ***************** -----------------------  **/

/** ----------------------- User Heirarchy -----------------------  **/

// ------ Service to display user heirarchy tree view
export async function getUserHeirarchy() {
  updateHeader();
  let url =
    apiUrl + "/core/auth/get-user-heirarchy?XDEBUG_SESSION_START=PHPSTORM";

  try {
    const { data } = await http.post(url, {}, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}
/** ----------------------- ***************** -----------------------  **/

// ------ Service to create transaction fee
export async function createTransactionFee(data) {
  updateHeader();

  let url =
    apiUrl + "/core/transaction-fee/create-fee?XDEBUG_SESSION_START=PHPSTORM";

  let postObject = {
    fee_guid: data.fee_guid,
    fee_name: data.fee_name,
    fee_description: data.fee_description,
    country: data.country,
    fee_type: data.fee_type,
    fixed_fee_type: data.fixed_fee_type,
    fee_schedules: data.fee_schedules,
    fee_daily_percentage: data.fee_daily_percentage,
    fixed_value: data.fixed_value,
    entity_id: data.entity_id,
    lpf: data.lpf,
    accumulation: data.accumulation,
    trans_id: data.trans_id,
    parent_fee_guid: data.parent_fee_guid,
    apply_grace_period: data.apply_grace_period,
    grace_period_commence_from: data.grace_period_commence_from,
    grace_period_days: data.grace_period_days,
    fees_days_extension: data.fees_days_extension
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function getFeeList(params, status) {
  updateHeader();

  let url = apiUrl + "/core/transaction-fee/list-transaction-fees?XDEBUG_SESSION_START=PHPSTORM";
 
  try {
    const response = await http.post(url, { ...params, status }, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
export async function deleteFee(feeId) {
  updateHeader();
  let url =
    apiUrl + "/core/transaction-fee/delete-fee?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { feeId };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

// ------ Service to fetch TopHeaderAlert
export async function getTopHeaderAlert(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl +
    "/core/transaction-fee/list-top-header-alert?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

// ----------------get limit REquested
export async function getLimitRequest(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/get-limit-request?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}


// ----------------get FMB Requested
export async function getFmbRequest(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/get-fmb-request?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

// ------ Service to create TopHeaderAlert
export async function createTopHeaderAlert(data) {
  updateHeader();

  let url =
    apiUrl +
    "/core/transaction-fee/create-top-header-alert?XDEBUG_SESSION_START=PHPSTORM";

  let postObject = {
    alert_guid: data.alert_guid,
    heading: data.heading,
    description: data.description,
    button_name: data.button_name,
    action_url: data.action_url,
    country: data.country,
    status: data.status,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function getTopHeaderAlertCheck(clientCountry, status) {
  updateHeader();

  let url =
    apiUrl +
    "/core/transaction-fee/top-header-alert-check?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    country: clientCountry,
    status: status,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    // return handleRetrievedData(response);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function searchFees (params){
  updateHeader();
  let url = apiUrl + "/core/transaction-fee/search-fee-types";

  try {
    const response = await http.post(url, params, accessToken);
    // return handleRetrievedData(response);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function clientFeeMapping (params){
  updateHeader();
  let url = apiUrl + "/core/transaction-fee/get-client-fee-mapping";

  try {
    const response = await http.post(url, params, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function createFeeMapping (params){
  updateHeader();
  let url = apiUrl + "/core/transaction-fee/create-client-fee-mapping";

  try {
    const response = await http.post(url, params, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
/** ----------------------- ***************** -----------------------  **/
const allNotifications = (params) =>
client.post(`/notifications/notifications/get-notifications`, { ...params });
export async function saveForexrate (params){
  updateHeader();
  let url = apiUrl + "/core/forex-rates/saveforex-rates";

  try {
    const response = await http.post(url, params, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function ForexrateHistory (params){
  updateHeader();
  let url = apiUrl + "/core/forex-rates/forex-rates-history";

  try {
    const response = await http.post(url, params, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
export async function ForexSave (params){
  updateHeader();
  let url = apiUrl + "/core/forex-rates/add-forex-providers";

  try {
    const response = await http.post(url, params, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
export async function ListForexProviders (params){
  updateHeader();
  let url = apiUrl + "/core/forex-rates/list-forex-providers";

  try {
    const response = await http.post(url, params, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function DeleteProvider (params){
  updateHeader();
  let url = apiUrl + "/core/forex-rates/delete-provider";

  try {
    const response = await http.post(url, params, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function ListOrganization (params){
  updateHeader();
  let url = apiUrl + "/core/forex-rates/list-providers";

  try {
    const response = await http.post(url, params, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

const getForexRates = (params) =>
client.post(`/core/forex-rates/list-forex-rates`, { ...params });

const getDateForexRates = (params) =>
client.post(`/core/forex-rates/list-date-forex-rates`, { ...params });

const ListforexforInvoice = (params) =>
client.post(`/core/forex-rates/list-client-fx-providers`, { ...params });

export default {
  fetchProducts,
  fetchTransProducts,
  getCountryDetails,
  getCountriesList,
  createNewCountry,
  deleteCountry,
  getCountriesArray,
  getUserTypesList,
  createNewUserType,
  deleteUserType,
  GetUserTypes,
  getUserTypeDetails,
  getUserTypeTabsList,
  getMailTemplatesList,
  createEmailTemplate,
  deleteEmailTemplate,
  getTemplateDetails,
  getUserHeirarchy,
  getTemplateAttachments,
  deleteTemplateAttachment,
  getDefaults,
  createTransactionFee,
  getFeeList,
  deleteFee,
  getTopHeaderAlert,
  createTopHeaderAlert,
  getTopHeaderAlertCheck,
  searchFees,
  getLimitRequest,
  getFmbRequest,
  clientFeeMapping,
  createFeeMapping,
  getForexRates,
  saveForexrate,
  ForexrateHistory,
  ForexSave,
  ListForexProviders,
  DeleteProvider,
  ListOrganization,
  getDateForexRates,
  ListforexforInvoice,
};
