import http from "./httpService";
import { apiUrl, queryString } from "../../src/environment";
import client from "./serviceClient";

let accessToken = "";

function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
}

export async function getReferenceNumber(country_name) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/get-reference-number?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    country: country_name,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function createTransaction(reqData) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/create-transaction?XDEBUG_SESSION_START=PHPSTORM";

  try {
    const response = await http.post(url, reqData, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function createInvoice(trans_guid, data) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/create-invoices?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid: trans_guid,
    invoices: data,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function createClientTransaction(
  data,
  client_guid,
  confirmed_by_email
) {
  updateHeader();

  let url =
    apiUrl +
    "/client/client-transaction/create-transaction?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    invoices: data,
    client_guid,
    confirmed_by_email,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function getInvoiceDetails(data) {
  updateHeader();
  console.log(accessToken);
  let url =
    apiUrl +
    "/transaction/transaction-manage/transaction-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid: data,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function getInvestmentPartnerTransDetails(data) {
  updateHeader();
  console.log(accessToken);
  let url =
    apiUrl +
    "/transaction/transaction-manage/partner-transaction-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid: data,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function getFeeStructureDetails(entity_id, country) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/get-fee-structure?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity_id: entity_id,
    country: country,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function saveFeeDetails(data) {
  //console.log(data);
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/save-fee-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    selectedFeeTypeGuid: data.selectedFee.value,
    latePaymentFee: data.latePaymentFee,
    insuranceFee: data.insuranceFee,
    charges: data.charges,
    trans_guid: data.trans_guid,
    lpf_charge_to_client: data.latePaymentFeeChrgToClient,
    ins_charge_to_client: data.insuranceFeeChrgToClient,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function fetchRelatedEntities(trans_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/fetch-related-entities?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid: trans_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function sendInvitation(trans_guid, entity_id, invitationDetals, funding_required) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/send-invitation?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid: trans_guid,
    invite_by_entity: entity_id,
    invitationDetails: invitationDetals,
    funding_required
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function fetchParticipants(trans_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/fetch-participants-invitations?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid: trans_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function createParticipant(data) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/add-participants?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    tp_guid: data.tp_guid,
    pinvitation_id: data.pinvitation_guid,
    participant_percent: data.participant_percent,
    participant_amount: data.participant_amount,
    participant_funds_received_date: data.participant_funds_received_date,
    participant_funds_returned_date: data.participant_funds_returned_date,
    participant_funds_alt_cost: data.participant_funds_alt_cost,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function returnParticipantFund(data) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/return-participant-fund?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    participation_guid: data.tp_guid,
    amount_paid: data.return_amount,
    paid_date: data.participant_funds_returned_date,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function createDisbursement(trans_guid, data) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/add-disbursement?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    td_trans_guid: trans_guid,
    data,
    trans_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function fetchDisbursements(trans_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/fetch-disbursements?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid: trans_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function fetchPayments(trans_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/fetch-payment-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid: trans_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function deletePayments(payment_guid, trans_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/delete-payment-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    payment_guid,
    trans_guid
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function fetchInvoiceSplitUp(inv_trans_guid, inv_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/fetch-payment-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid: inv_trans_guid,
    inv_guid: inv_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function savePayments(data, trans_guid, type = "", paymentDate) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/add-payment?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    paymentDetails: data,
    trans_guid,
    type,
    paymentDate
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function closePayments(
  closed_date,
  data,
  treatment,
  treatment_comments,
  trans_guid,
  type
) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/close-payment?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    closed_date: closed_date,
    selectedInvoices: data,
    treatment: treatment,
    treatment_comments: treatment_comments,
    trans_guid: trans_guid,
    type: type,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function reopenInvoice(selectedInvoice) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/reopen-closed-invoice?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    selectedInvoice,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function reopenAllClosedInvoices(trans_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/reopen-all-closed-invoices?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function getUsersToBeNotified(trans_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/get-users-to-be-notified?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid: trans_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function sendPaymentNotifications(users, message) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/send-payment-notifications?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    users: users,
    message: message,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
//----------------------------------------------------------------------------

export async function sendReviewRequest(trans_reference, client, data) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/trans-review-request?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_reference: trans_reference,
    data: data,
    client: client,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
//--------------------------------------------------------------------------------------
export async function saveFundingDetails(
  is_funding_required,
  funding_required,
  annual_return,
  trans_guid
) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/save-funding-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    is_funding_required: is_funding_required,
    funding_required: funding_required,
    annual_return: annual_return,
    trans_guid: trans_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function deleteInvitee(inviteeId) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/delete-invitee?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    inviteeId,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function deleteParticipantReturn(returnGuid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/delete-participant-return?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    returnGuid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function deleteParticipant(participantId) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/delete-participant?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    participantId,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function getParticipantFunds(trans_guid, invoice_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/get-participant-funds?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid,
    invoice_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function uploadInvoiceFile(
  inv_attachment_guid,
  inv_attachment_name,
  fileslist
) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/upload-invoice-attachment?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    inv_attachment_guid,
    inv_attachment_name,
    fileslist,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function downloadInvoice(inv_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/download-invoice-attachment?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    inv_guid,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
/*-----------Delete Transaction----------------------------------- */
export async function deleteTransaction(guid) {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/delete-transaction?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { guid };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/*-----------Delete Document Template----------------------------------- */
export async function deleteDocumentTemplate(id) {
  updateHeader();
  let url =
    apiUrl +
    "/core/doc-template-group/delete-template-document?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { id };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/*-----------Get document template fields----------------------------------- */
export async function fetchDocumentTemplates() {
  updateHeader();
  let url =
    apiUrl + "/entity/entity/get-entity-fields?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {};

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/*-----------List document templates----------------------------------- */
export async function fetchDocumentTemplatesList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();
  let url =
    apiUrl +
    "/core/doc-template-group/list-template-document?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (data) {
    return console.log(data);
  }
}

/*-----------Get document template fields----------------------------------- */
export async function getTemplateGroups() {
  updateHeader();
  let url =
    apiUrl +
    "/core/doc-template-group/get-templates-group-dropdown?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {};

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/*-----------Create Template Document----------------------------------- */
export async function createTemplateDocument(data) {
  updateHeader();
  let url =
    apiUrl +
    "/core/doc-template-group/create-template-document?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    id: data.temp_doc_guid,
    country: data.country_id,
    doc_type: data.document_type,
    content: data.content,
    template_subject: data.document_subject,
    status: data.status,
    type: data.type,
    email_subject: data.email_subject,
    send_as_pdf: data.send_as_pdf,
    attribute_send_to: data.attribute_send_to,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/*-----------Fetch Template Details----------------------------------- */
export async function getTemplateDetails(
  id,
  trans_guid = null,
  selectedEntities = null,
  type = "transaction",
  client = null
) {
  updateHeader();
  let url =
    apiUrl +
    "/core/doc-template-group/get-template-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    id,
    trans_guid,
    selectedEntities,
    type,
    client
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function fetchKeywordSuggestions(search, additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/key-word-auto-suggestions?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    search: search,
    additionalParams,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
/*** Invoice Keyword Suggestions  */

export async function fetchInvoiceWordSuggestions(search, additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/invoice-auto-suggestions?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    search: search,
    additionalParams,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
/******************************** */

export async function mapGuarantor(guarantor_guid, trans_guid, trans_type) {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/map-guarantor?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    guarantor_guid,
    trans_guid,
    trans_type,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getTransactionGuarantorsList(additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/transaction/transaction-manage/list-transaction-guarantors?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    additionalParams,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function deleteTransactionGuarantor(guid) {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/delete-transaction-guarantor?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { guid };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getGuarantorsList(transGuid, entityId) {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/fetch-guarantors-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { transGuid, entityId };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function sendDocumentTemplate(
  trans_guid,
  content,
  clientEmail,
  mailCC,
  mailBCC,
  templateName,
  selected_template_id
) {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/send-document-template?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid,
    content,
    clientEmail,
    mailCC,
    mailBCC,
    templateName,
    selected_template_id,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/******************* Transaction tabs list ***************** */

export async function getTabsList(a_guid) {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/get-tabs-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity_guid: a_guid,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/******************* Template tabs list ***************** */

export async function getTemplateTabsList() {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/get-template-tabs-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {};

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}
/******************* Invoice tabs list ***************** */

export async function getTabsListInvoices(a_guid) {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/get-invoice-tabs-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity_guid: a_guid,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/*********************************************************** */

/******************* Invoice tabs list ***************** */

export async function sendClosureStatement(trans_reference, selectedInvoice, payments, client) {
  updateHeader();

  const { inv_number: invoice_number, closed_date, inv_amount: total_inv_amt,
    inv_adv_amount: disbursed_amt, disbursed_date, inv_paid_amount: payment_received,
    receipt_fee_pctg: fee_pctg_on_payments, lpf_on_amt_paid: lpf_on_payments,
    inv_tocollect: amount_to_collect, days_on_due_amt: due_days, due_fee_pctg: fee_pctg_on_due_amt,
    lpf_on_amt_to_receive: lpf_on_due_amt, other_charges, insurance_fee, client_lpf: total_lpf, residual_amt
  } = selectedInvoice;

  let PaymentsArray = [];
  if (payments.length > 0)
    PaymentsArray = payments[0];

  const { days: payment_days, fee: fee_on_payments, fee_on_due } = PaymentsArray;

  let url = apiUrl + "/transaction/transaction-manage/send-closure-statement?XDEBUG_SESSION_START=PHPSTORM";

  let postObject = {
    trans_reference,
    invoice_number,
    closed_date,
    total_inv_amt,
    disbursed_amt,
    disbursed_date,
    payment_received,
    fee_pctg_on_payments,
    lpf_on_payments,
    amount_to_collect,
    due_days,
    fee_pctg_on_due_amt,
    lpf_on_due_amt,
    other_charges,
    insurance_fee,
    total_lpf,
    payment_days,
    fee_on_payments,
    fee_on_due,
    residual_amt,
    client
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/*********************************************************** */

/******************* Service to validate invoice duplication ***************** */

export async function validateInvoiceDuplication(trans_guid, client_guid, inv_number) {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/validate-invoice-duplication?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid,
    client_guid,
    inv_number
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/*********************************************************** */

/******************* Service to Edit transaction reference number ***************** */

export async function saveReferenceNumber(trans_guid, trans_reference) {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/update-reference-number?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    trans_guid,
    trans_reference,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

/*********************************************************** */

/******************* Service to get Debtor Pending Payments ***************** */

export async function getDebtorPendingPaymentDetails(data) {
  updateHeader();
  let url =
    apiUrl +
    "/transaction/transaction-manage/debtor-pending-payments?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    debtor_id: data,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
/*********************************************************** */

const getTransactionsList = (params, status) => client.post(`/transaction/transaction-manage/list-transaction?XDEBUG_SESSION_START=PHPSTORM`, { ...params, status });
const searchTransactions = params => client.post(`/transaction/transaction-manage/search-transaction?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const getInvoiceList = (params, status) => client.post(`/transaction/transaction-manage/list-invoice?XDEBUG_SESSION_START=PHPSTORM`, { ...params, status });
const searchInvoices = params => client.post(`/transaction/transaction-manage/search-invoice?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const fetchClientDdInvoices = (params) => client.post(`/supplychain/direct-debit/fetch-client-dd-invoices`, { ...params });
const fetchPaymentReport = (params) => client.post(`/loans/loan/get-payment-report`, { ...params });

export default {
  fetchKeywordSuggestions,
  getTransactionsList,
  searchTransactions,
  getReferenceNumber,
  createTransaction,
  createInvoice,
  createClientTransaction,
  getInvoiceDetails,
  getInvestmentPartnerTransDetails,
  getFeeStructureDetails,
  saveFeeDetails,
  fetchRelatedEntities,
  sendInvitation,
  fetchParticipants,
  createParticipant,
  returnParticipantFund,
  deleteParticipantReturn,
  createDisbursement,
  fetchDisbursements,
  fetchPayments,
  deletePayments,
  fetchInvoiceSplitUp,
  savePayments,
  closePayments,
  reopenInvoice,
  getUsersToBeNotified,
  sendPaymentNotifications,
  sendReviewRequest,
  saveFundingDetails,
  deleteInvitee,
  deleteParticipant,
  getParticipantFunds,
  uploadInvoiceFile,
  downloadInvoice,
  deleteTransaction,
  fetchDocumentTemplates,
  fetchDocumentTemplatesList,
  getTemplateGroups,
  createTemplateDocument,
  getTemplateDetails,
  mapGuarantor,
  getTransactionGuarantorsList,
  deleteTransactionGuarantor,
  getGuarantorsList,
  sendDocumentTemplate,
  getInvoiceList,
  searchInvoices,
  fetchInvoiceWordSuggestions,
  getTabsList,
  getTabsListInvoices,
  getTemplateTabsList,
  deleteDocumentTemplate,
  reopenAllClosedInvoices,
  sendClosureStatement,
  validateInvoiceDuplication,
  saveReferenceNumber,
  getDebtorPendingPaymentDetails,
  fetchClientDdInvoices,
  fetchPaymentReport
};