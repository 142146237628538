import React, { useEffect, useContext } from "react";
import Joi from "joi-browser";
import { FormGroup } from "../../common/containers/formContainer";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import MultipleFileInput from "../../common/inputs/multipleFileInput/multipleFileInput";
import {
  TextInput,
  GeneralButton,
  CurrencyText,
  Picker,
  DateTimePicker,
} from "../../common/inputs";

import { EntityDebtorList } from "../../slplusTools";
import * as moment from "moment";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import InvIcon from "../../../img/invoice.svg";

import EntityContext from "../../../context/entityContext";
import useApi from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import { useState } from "react";

import dfTransactionService from "../services/dfTransactions";
import authService from "../../../services/authService";
import coreService from "../../../services/coreService";

export default function InvoiceCreation({
  onClose,
  selectedRow = null,
  debtorId = null,
  handleAddInvoice = null,
  hideInvoiceCol = false,
  context = false,
  updateInvoice,
}) {
  const { constants } = authService.getCurrentUser();
  const { entityDetails } = useContext(EntityContext);
  const [transId, setTransId] = useState(null);
  const [files, setFiles] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [debtors, setDebtors] = useState([]);
  const [selectedBp, setSelectedBp] = useState([]);
  //------------Api Services--------------------------------------------
  const referenceApi = useApi(dfTransactionService.generateRefNum);
  const transactionApi = useApi(dfTransactionService.createDFTransaction);
  const invCreationAPi = useApi(dfTransactionService.createInvoice);

  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      inv_number: Joi.string().required().label("Invoice Number"),
      inv_date: Joi.date()
        .required()
        .error(() => {
          return {
            message: "Invoice date cannot be blank.",
          };
        }),
      inv_debtor_id: Joi.string().required().label("Debtor"),
      inv_amount: Joi.number().required().label("Invoice Value"),
      inv_due_date: Joi.date()
        .min(Joi.ref("inv_date"))
        .required()
        .label("Due Date")
        .error(() => {
          return {
            message: "Due date must be a date greater than Invoice date.",
          };
        })
    })
    .unknown(true);

  const handleInvoiceAmount = (floatvalue)  => {
    setValues({
      ...values,
      inv_amount: floatvalue,
      inv_chargeable_amt: floatvalue,
      credit_notes: floatvalue < 0 ? 0 : 1,
    });
  }

  //--------------------- Submit invoice to server ------------------//
  const addHandler = async () => {
    if (selectedRow == null) {
      const reqData = {
        trans_guid: transId,
        values: values,
        invoices: [...invoice],
      };

      //   const { data } = await invCreationAPi.request(reqData);

      //   if (data.success === false) toast.error(data.errors[0].message);
      //   else onClose(); // Close the modal popup after creation or updation.
    } else {
      updateInvoice(selectedRow.index, values);
      onClose();
    }
  };
  //----------------------------------------------------------------//

  //------------------Custom hook to handle form data-----------------------------
  const {
    values,
    setValues,
    handleChange,
    handlePickerChange,
    handleSubmit,
  } = useForm(addHandler, schema); // Custom Hook to handle Form Validation

  //------------------------------------------------------------------------------

  useEffect(() => {
    if (selectedRow === null)
      // Create New Invoice
      setValues({
        ...values,
        client_reference_no: null,
        due_date: null,
        file_name: "",
      });
    //selectedRow
    else {
      let invoiceData = { ...selectedRow };
      if(moment(invoiceData['inv_date']).isValid() === false){
        invoiceData['inv_date'] = null;
      }
      if(moment(invoiceData['inv_due_date']).isValid() === false){
        invoiceData['inv_due_date'] = null;
      }
      setValues({ ...values, ...invoiceData }); // Update selected invoice
    } // Update selected invoice
  }, []);

  return (
    <>
      {(referenceApi.loading === true ||
        transactionApi.loading === true ||
        invCreationAPi === true) && <LoadingSpinner />}

      <Modal.Header closeButton>
        <div className="d-block pt-3 text-center w-100 ml-3">
          <img width={"50px"} src={InvIcon} />
          <h3 className="modal-title">Create Transaction</h3>
          <small class="font-bold tertiary-level-color">
            Please enter the details below.
          </small>
        </div>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit} className="form-horizontal">
          <div className="row">
            {/*-------------Offer to sell starts-------------------------------------*/}
            <div className="col-lg-12">
              <div class="ibox-content">
                {referenceApi.loading === false ? (
                  <>
                    <TextInput
                      name="inv_number"
                      label="Invoice No"
                      placeholder="Invoice No"
                      value={values.inv_number}
                      onChange={handleChange}
                      type="horizontal"
                    />

                    <FormGroup
                      label="Invoice Date"
                      type="horizontal"
                      input={
                        <DateTimePicker
                          name="inv_date"
                          label="Invoice Date"
                          selectedDate={values.inv_date}
                          onChange={(value) => handleChange(value)}
                        />
                      }
                    />
                    <FormGroup
                      label="Debtor"
                      type="horizontal"
                      input={
                        <EntityDebtorList
                          selectedDebtor={values.inv_debtor_id}
                          client={entityDetails.a_guid}
                          onSelectItem={(option) =>
                            setValues({
                              ...values,
                              debtor_name: option.label,
                              inv_debtor_id: option.value,
                            })
                          }
                          product={constants.PRODUCTS.DEBTORFINANACE}
                          u
                        />
                      }
                    />
                    <FormGroup
                      label="Invoice Value"
                      type="horizontal"
                      input={
                        <CurrencyText
                          value={values.inv_amount}
                          onChangeEvent={(event, maskedvalue, floatvalue) => {
                            handleInvoiceAmount(floatvalue);
                          }}
                          placeholder="Invoice Value"
                        />
                      }
                    />

                    <FormGroup
                      label="Due Date"
                      type="horizontal"
                      input={
                        <DateTimePicker
                          name="inv_due_date"
                          selectedDate={values.inv_due_date}
                          onChange={(value) => handleChange(value)}
                        />
                      }
                    />
                  </>
                ) : (
                  <div className="row" style={{ minHeight: 350 }}></div>
                )}
              </div>
            </div>
            {/*-------------Offer to sell ends-------------------------------------*/}
          </div>

          <div className="row m-t-sm">
            <div className="col-lg-12 ">
              <GeneralButton
                faIcon="fa fa-save"
                className="btn btn-sm btn-primary pull-right m-l-xs"
                name="Save"
              ></GeneralButton>
            </div>
          </div>
        </form>
      </Modal.Body>
    </>
  );
}
