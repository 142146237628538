import React, { useState, useEffect } from "react";

// Importing Widgets
import LoanSummary from "../../widgets/LoanWidgets/loanSummary";
import OverDue from "../../widgets/LoanWidgets/overDue";
import CreateLoan from "../../widgets/LoanWidgets/createLoan";
import LoanGraph from "../../widgets/LoanWidgets/loanGraph";
import LoanListView from "../../widgets/LoanWidgets/loanListView";
import loanService from "../../../loan/services/loanTransactions";
import useApi from "../../../../hooks/useApi";
import EmptyDefaultCard from "../../widgets/LoanWidgets/emptyDefaultCard";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";

function LoanSnapshot() {

    const [reload, setReload] = useState(false);
    const [loanCount, setCount] = useState(0);

    const loanCountApi = useApi(loanService.GetLoanCount);

    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        fetchLoanCount();
    }, []);

    const fetchLoanCount = async () => {
        const { data } = await loanCountApi.request({
            clientSide: true,
        });
        setCount(data.count);
    }

    return (
        <>
        {(loanCountApi.loading == true) && <LoadingSpinner />}
            {
                loanCount > 0 ? (
                    <>
                        <div className="row">
                            <LoanSummary />
                            <OverDue />
                            <CreateLoan reload={(value) => { setReload(value) }} />
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                    reload == false && (
                                        <>
                                            <LoanListView />
                                        </>
                                    )
                                }

                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <EmptyDefaultCard
                            fetchLoanCount={() => { fetchLoanCount() }}
                        />
                    </>
                )
            }


            {/* <div className="row">
                <div className="col-lg-12">
                    <LoanGraph />
                </div>
            </div> */}
        </>
    )
}
export default LoanSnapshot;