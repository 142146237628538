import React, { Component } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import { CurrencyLabel } from "../../../common/inputs";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import loanTransactionService from "../../../loan/services/loanTransactions";


class LoanIndex extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    loanSchedule: [],
  };

  componentDidMount() {

    this.fetchLoanSchedule();

  }

  fetchLoanSchedule = async () => {

    this.setState({ loading: true });

    let paymentsArr = [];

    const loanScheduleResult = await loanTransactionService.getLoanSchedule({
      loan_uid: this.props.loanGuid
    });

    if (loanScheduleResult.data.success) {
      loanScheduleResult.data.schedule.map((item) => {
        paymentsArr.push({
          index: paymentsArr.length,
          checked: false,
          disabled: item.disabled,
          payment_status: _.startCase(item.payment_status),
          loan_uid: item.loan_uid,
          schedule_uid: item.schedule_uid,
          payment_date: item.payment_date,
          is_overdue: item.is_overdue,
          start_balance:
            item.start_balance !== null
              ? parseFloat(item.start_balance)
              : 0,
          repayment_amount:
            item.repayment_amount !== null
              ? parseFloat(item.repayment_amount)
              : 0,
          repayment_charge:
            item.repayment_charge !== null
              ? parseFloat(item.repayment_charge)
              : 0,
          repayment_interest:
            item.repayment_interest !== null
              ? parseFloat(item.repayment_interest)
              : 0,
          total_repayment: item.total_repayment !== null
            ? parseFloat(item.total_repayment)
            : 0,
          balance_amount:
            item.balance_amount !== null
              ? parseFloat(item.balance_amount)
              : 0,
          repay_balance:
            item.repay_balance !== null
              ? parseFloat(item.repay_balance)
              : 0,
          lpf:
            item.lpf !== null
              ? parseFloat(item.lpf)
              : 0,
        });
      })
    } else {
      toast.error("Failed to fetch loan schedule.")
    }

    this.setState({ loanSchedule: paymentsArr });
    this.setState({ loading: false });
  };

  exportSchedule = async () => {
    
    this.setState({ loading: true });

    const loanScheduleResult = await loanTransactionService.ExportPaymentSchedule({
      loan_uid: this.props.loanGuid
    });
    this.setState({ loading: false });
    const newWindow = window.open(loanScheduleResult.data);
    newWindow.opener = null;
  }

  checkIsOverDue = (date1, date2, status) => {
    date2 = moment(date2, 'DD/MM/YYYY').format('DD/MM/YYYY').toString();
    if ((new Date(date1).getTime() > new Date(date2).getTime()) && status != 'Paid') {
      return true;
    } else {
      return false;
    }

  }

  render() {
    const currentDate = moment().format('DD/MM/YYYY').toString(); // date in mm-dd-yyyy format - to compare date in javascript accept only this format
    return (
      <>
        {this.state.loading == true && <LoadingSpinner />}
        <div className="table-responsive">
          {
            this.state.loanSchedule && this.state.loanSchedule.length > 0 ? (
              <>
                <table className="table text-secondary">
                  <tbody>
                    <tr>
                      <th>Payment Date</th>
                      <th>Principle</th>
                      <th>Repayment Charge</th>
                      <th>Interest</th>
                      <th>Late Payment Fee</th>
                      <th>Repayment Principle</th>
                      <th>Total Repayment</th>
                      <th>Repayment Balance</th>
                      <th>Repayment Status</th>
                    </tr>
                    {
                      this.state.loanSchedule && this.state.loanSchedule.map((item, index) => (
                        <tr key={index} className={item.is_overdue === true ? "text-danger" : ""}>
                          <td>{item.payment_date}</td>
                          <td>
                            <CurrencyLabel
                              value={item.start_balance}
                            ></CurrencyLabel>
                          </td>

                          <td>
                            <CurrencyLabel
                              value={item.repayment_charge}
                            ></CurrencyLabel>
                          </td>

                          <td>
                            <CurrencyLabel
                              value={item.repayment_interest}
                            ></CurrencyLabel>
                          </td>
                          <td>
                            <CurrencyLabel
                              value={item.lpf}
                            ></CurrencyLabel>
                          </td>
                          <td>
                            <CurrencyLabel
                              value={item.repayment_amount}
                            ></CurrencyLabel>
                          </td>

                          <td>
                            <CurrencyLabel
                              value={item.total_repayment}
                            ></CurrencyLabel>
                          </td>
                          <td>
                            <CurrencyLabel
                              value={item.repay_balance}
                            ></CurrencyLabel>
                          </td>
                          <td>
                            <span className={item.payment_status == 'Paid' ? "text-success" : "text-danger"}>
                              {
                                item.payment_status
                              }
                            </span>
                          </td>
                        </tr>
                      )
                      )
                    }
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>
                          <CurrencyLabel
                            decimal={2}
                            value={_.sumBy(this.state.loanSchedule, item => Number(item.repayment_amount))}
                          >
                          </CurrencyLabel>
                        </b>
                      </td>
                      <td>
                        <b>
                          <CurrencyLabel
                            decimal={2}
                            value={_.sumBy(this.state.loanSchedule, item => Number(item.total_repayment))}
                          >
                          </CurrencyLabel>
                        </b>
                      </td>
                      <td>
                        <b>
                          <CurrencyLabel
                            decimal={2}
                            value={_.sumBy(this.state.loanSchedule, item => Number(item.repay_balance))}
                          >
                          </CurrencyLabel>
                        </b>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <div className="row mt-5 mb-5  justify-content-center">
                  <div className="col-lg-8">
                    {
                      this.state.loading == true ? (
                        <>
                        <span>Processing... </span>
                        </>
                      ) :(
                        <>
                        <span>No schedule found! </span>
                        </>
                      )
                    }
                    
                  </div>
                </div>
              </>
            )}
            
        </div>
        {
          this.state.loanSchedule && this.state.loanSchedule.length > 0 && (
            <>
            <div className="row">
              <div className="col-sm-12 ">
                <button 
                type="button"
                className="btn btn-secondary float-right"
                onClick={() => this.exportSchedule()}
                >Export</button>
              </div>
            </div>
            </>
          )
        }
      </>
    );
  }
}
export default LoanIndex;