import React, { useEffect, useState, useContext } from "react";
import { OverDueCard } from "../../components/cards";
import useApi from '../../../../hooks/useApi';
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";
import CommonService from "../../../supply-chain/services/commonService";
import RoleChecker from "../../../../utils/roleChecker";
import { CurrencyLabel } from "../../../common/inputs";

function OverDueInvoices(props) {
  const OverDueApi = useApi(CommonService.getOverdueCount)
  const {  entityDetails, terminology } = useContext(EntityContext);
  const { sc_invoice_status, constants } = authService.getCurrentUser();
  const [overDueData, setOverDueData] = useState(null); // set data for approved and paid invoice card

  const getOverDueData = async () => {

    const { data } = await OverDueApi.request({ "product_type" : constants.PRODUCTS.TRADEFINANCE, "userId": entityDetails.a_guid });

    setOverDueData({
      topLabel: "Due / Overdue",
      topValue: <CurrencyLabel decimal={2} value={data.overDueSum} />,
      leftLabel: `${terminology && terminology.SUPPLIER}s`,
      leftValue: data.overDueSupplierCount ? data.overDueSupplierCount : 0,
      rightLabel: "Invoices",
      rightValue: data.overDueCount ? data.overDueCount : 0,
      bottomLeftLabel: "",
      bottomRightLabel: "",
      primaryBtnRoute: "/client/tf/purchase-invoices",
      status: 'overdue',
      subTitle: <CurrencyLabel decimal={2} value={data.overDueInterest} />,
    });

  }

  useEffect(() => {
    getOverDueData()
  }, []);


  return (
    <>
      {RoleChecker.check(entityDetails.products, constants.ROLES.TFCUSTOMER)  && (
        <OverDueCard
          loading={ OverDueApi.loading}
          data={overDueData}
          col={12}
          setProductRoleType ={ props.setProductRoleType ? props.setProductRoleType :""}
          product={constants.PRODUCTS.TRADEFINANCE}
        />
      )}
    </>
    )
  }
export default OverDueInvoices;