import React from 'react';
import { Helmet } from "react-helmet";
import MultiStepForm from "../../../common/wizard/MultiStepForm";
import Upload from './upload';
import ListSuppliers from './listSuppliers';
import SupplierValidation from './supplierValidation';
import Finish from './finish';
import { SupplierProvider } from "../../../../context/supplierUploaderContext";
import { browserHistory } from "react-router";
import { confirmAlert } from "react-confirm-alert";

//e => browserHistory.push("/client/home")

export default function Index({selectedProduct}) {

    const componentsList = [
        { headerText: 'Upload Supplier Sheet', headerIcon: 'fa fa-upload', component: <Upload selectedProduct={selectedProduct} /> },
        { headerText: 'View Uploads', headerIcon: 'fa fa-list', component: <ListSuppliers /> },
        { headerText: 'Validation', headerIcon: 'fa fa-check-square', component: <SupplierValidation /> },
        { headerText: 'Finish', headerIcon: 'fa fa-flag-checkered', component: <Finish selectedProduct={selectedProduct}/> },
    ];
    //----Go back to prospect on cancel click-------------
    const handleCancel = async () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-delete-ui'>
                        <h5>Are you Sure...?</h5>
                        <p>You want to go back to Dashboard</p>
                        <button className="btn btn-white" onClick={onClose}>No</button>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={async () => {
                                onClose();
                                browserHistory.push("/client/home")
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "Confirm to delete",
            message: "Are you sure to do this.",
        });
    }
    return (
        <>
            <Helmet>
                <title>Supplier Bulk Upload | {global.config.title}</title>
            </Helmet>
            <div className="row">
              <div className="col-lg-12">
                    <SupplierProvider>
                        <MultiStepForm
                            boxTitle="Bulk Upload"
                            list={componentsList}
                            displayProgressBar={true} // Set this to false if you don't want to display the progress bar.
                            hideSkipBtn={true}
                            type='supplierUploader'
                            handleCancel={() => handleCancel() }
                        />
                    </SupplierProvider>
              </div>
            </div>      
        </>
    );
}