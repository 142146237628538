import React, { useEffect, useContext } from "react";
import FifoTabs from "../../components/_fifoTabs";
import RoleChecker from "../../../../utils/roleChecker";
import authService from "../../../../services/authService";
import EntityContext from "../../../../context/entityContext";
import NoAccess from "../../widgets/noAccess";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
import ProcessingFacility from "../../widgets/general/processingFacility";

export default function IFLayout (props) {

    const { handleProductChange, selectedBusinessType, handleRoleChange, selProduct, accountDetails, entityDetails } = useContext(EntityContext);
    const { constants, products } = authService.getCurrentUser();

    useEffect(() => {

        handleProductChange(constants.PRODUCTS.INVOICE);

        if(selectedBusinessType === null || selectedBusinessType === 'ALL'){
            if(RoleChecker.check(products, constants.ROLES.CLIENT))
                handleRoleChange(constants.BUSINESS_TYPE.SALE);
            else if(RoleChecker.check(products, constants.ROLES.DEBTOR))
                handleRoleChange(constants.BUSINESS_TYPE.PURCHASE);
            else
                browserHistory.push('/client/home')
        }

    }, []);

    const accountInfo = accountDetails.find(item => item.product == constants.PRODUCTS.INVOICE);
    const productSettings = entityDetails.productSettings.find(item => item.id == constants.PRODUCTS.INVOICE);

    return(
        <div>
            {( (!accountInfo || !productSettings.default_settings_available) && selectedBusinessType === constants.BUSINESS_TYPE.SALE) ? (
                <ProcessingFacility />
            )
            :
            selProduct === constants.PRODUCTS.INVOICE ? 
                <>
                    <Helmet>
                        <title>{accountInfo ? accountInfo.nick_name ? accountInfo.nick_name : accountInfo.bank_account_name : ''} | {global.config.title}</title>
                    </Helmet>
                    <FifoTabs 
                        activePath={props.location.pathname.substring(
                            props.location.pathname
                        )} 
                    />
                    {props.children}
                </> : 
                <NoAccess />
            }
        </div>
    )    
}