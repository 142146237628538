import React from "react";
import Select from "react-select";
import TimezonePicker from "react-timezone";
import Picker from "../picker/picker";
import { currencies } from "../../../../config/predefinedValues.json";
import { ZIndexes } from "office-ui-fabric-react";

const customStyles = {
  /* control: base => ({
     ...base,
     height: 30,
     minHeight: 30,
     border: "1px solid #b0b9c1",
     borderRadius: "4px !important",
     color: "#495057 !important"
   }),
   option: (provided, state) => ({
     ...provided,
     paddingBottom: "6px",
     overflow: "visible"
   })*/
};
//-----------------------------------------------------
const customStylesSmall = {};
const StatusPicker = props => (
  <React.Fragment>
    {props.type === "horizontal" && (
      <div className="form-group row">
        <label style={{ paddingTop: "7px" }} className="col-lg-4 form-label">
          {props.label}
        </label>
        <div className="col-lg-8">
          <select
            name={props.name}
            value={props.value}
            className="form-control input-sm"
            onChange={props.onChange}
            ref={props.ref}
            styles={customStyles}
            style={{ color: "#999c9e", fontSize: "12px" }}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>
    )}

    {props.type === "Entityhorizontal" && (
      <div className="form-group">
        <label style={{ paddingTop: "7px" }} className="col-lg-4 form-label">
          {props.label}
        </label>
        <div className="col-lg-8">
          <select
            name={props.name}
            value={props.value}
            className="form-control input-sm"
            onChange={props.onChange}
            ref={props.ref}
            styles={customStyles}
            style={{ color: "#999c9e", fontSize: "12px" }}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="terminated">Terminated</option>
            <option value="interested">Interested</option>
          </select>
        </div>
      </div>
    )}

    {props.type === "vertical" && (
      <div className="form-group">
        <label style={{ paddingTop: "7px" }}>{props.label}</label>
        <select
          name={props.name}
          value={props.value}
          className="form-control"
          onChange={props.onChange}
          ref={props.ref}
          style={{ color: "#999c9e", fontSize: "12px" }}
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
    )}

    {props.type === "normal" && (
      <div className="col-lg-8">
        <select
          name={props.name}
          value={props.value}
          className="form-control input-sm"
          onChange={props.onChange}
          ref={props.ref}
          style={{ color: "#999c9e", fontSize: "12px" }}
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
    )}
  </React.Fragment>
);


const YesNoPicker = props => (
  <React.Fragment>
    {props.type === "horizontal" && (
      <div className="form-group row">
        {props.label &&
          <label style={{ paddingTop: "7px" }} className="col-lg-4 form-label">
            {props.label}
          </label>
        }
        <div className="col-lg-8">
          <select
            name={props.name}
            value={props.value}
            className="form-control input-sm"
            onChange={props.onChange}
            ref={props.ref}
            styles={customStyles}
            style={{ color: "#999c9e", fontSize: "12px" }}
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
      </div>
    )}
    {props.type === "vertical" && (
      <div className="form-group">
        <label style={{ paddingTop: "7px" }}>{props.label}</label>
        <select
            name={props.name}
            value={props.value}
            className={props.className ? props.className: "form-control input-sm"}
            onChange={props.onChange}
            ref={props.ref}
            styles={customStyles}
            //={{ color: "#999c9e", fontSize: "12px" }}
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
      </div>
    )}
  </React.Fragment>
);


const ReactSelect = props => (
  <React.Fragment>
    {props.type === "vertical" && (
      <div className="form-group row">
        <label style={props.labelStyle ? props.labelStyle : { paddingTop: "7px" }}>{props.label}</label>
        <Select
          options={props.options}
          onChange={props.onChange}
          value={props.value}
          placeholder={props.placeholder}
          //styles={customStyles}
          className={props.className ? props.className : "form-control"}
          isMulti={props.multi ? true : false}
          theme={theme => ({
            ...theme,
            borderRadius: ".375rem",
            colors: {
              ...theme.colors,
              text: "black",
              primary25: "white",
              primary: "#1ab394",
              neutral0: "white"
            }
          })}
        />
      </div>
    )}

    {props.type === "horizontal" && (
      <div className="form-group row">
        <label style={{ paddingTop: "7px" }} className="col-lg-4 form-label">
          {props.label}
        </label>
        {props.button ? (
          <React.Fragment>
            <div className="col-lg-7">
              <Select
                options={props.options}
                onChange={props.onChange}
                value={props.value}
                placeholder={props.placeholder}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: ".375rem",
                  colors: {
                    ...theme.colors,
                    text: "black",
                    primary25: "white",
                    primary: "#1ab394",
                    neutral0: "white"
                  }
                })}
              />
            </div>
            <div className="col-lg-1">{props.button}</div>
          </React.Fragment>
        ) : (
          <div className="col-lg-8">
            <Select
              options={props.options}
              onChange={props.onChange}
              value={props.value}
              placeholder={props.placeholder}
              styles={customStyles}
              theme={theme => ({
                ...theme,
                borderRadius: ".375rem",
                colors: {
                  ...theme.colors,
                  text: "black",
                  primary25: "white",
                  primary: "#1ab394",
                  neutral0: "white"
                }
              })}
            />
          </div>
        )}
      </div>
    )}

    {props.type === "normal" && (
      <Select
        options={props.options}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        styles={customStyles}
        className={props.className ? props.className : ""}
        theme={theme => ({
          ...theme,
          borderRadius: ".375rem",
          colors: {
            ...theme.colors,
            text: "black",
            primary25: "white",
            primary: "#1ab394",
            neutral0: "white"
          }
        })}
      />
    )}
    {props.type === "small" && (
      <Select
        options={props.options}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        styles={customStylesSmall}
        style={{ color: "#999c9e", fontSize: "12px", height: "25px" }}
        theme={theme => ({
          ...theme,
          borderRadius: ".375rem",
          colors: {
            ...theme.colors,
            text: "black",
            primary25: "white",
            primary: "#1ab394",
            neutral0: "white"
          }
        })}
      />
    )}
  </React.Fragment>
);

const CurrencyPicker = props => (


  <div className="form-group row">
    <label className="col-lg-4 form-label">{props.label}</label>
    <div className="col-lg-8">
      <select
        name={props.name}
        value={props.value}
        className="form-control input-sm"
        ref={props.ref}
        onChange={props.onChange}
      >
        <option value="">{props.label}</option>
        {currencies.map(function (item) {
          return (
            <option key={item.currency} value={item.currency}>
              {item.currency}
            </option>
          );
        })}
      </select>
    </div>
  </div>
);

const TimeZone = props =>
(
  <div className="form-group row">
    <label className="col-lg-4 form-label" style={{ paddingTop: "7px" }}>{props.label}</label>
    <div className="col-lg-8">
      <TimezonePicker
        ref={props.ref}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          placeholder: props.label,
          name: props.name
        }}
      />
    </div>
  </div>
);

const StatusSelector = props => {
  const options = [{ label: "Active", value: "active" }, { label: "Inactive", value: "inactive" }];
  return (
    <Picker
      items={options}
      selectedItem={options.find(item => item.value == props.value)}
      onSelectItem={option => props.onChange({ target: { name: props.name, value: option.value } })}
    />
  )
}

const YesNoSelector = props => {
  const options = [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }];
  return (
    <Picker
      items={options}
      selectedItem={options.find(item => item.value == props.value)}
      onSelectItem={option => props.onChange({ target: { name: props.name, value: option.value } })}
    />
  )
}

export { StatusPicker, StatusSelector, YesNoSelector, YesNoPicker, ReactSelect, CurrencyPicker, TimeZone };
