import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../common/loaders/loadingSpinner';
import useApi from '../../../hooks/useApi';
import transactionService from "../../../services/invTransactions";
import Modal from 'react-bootstrap/Modal';
import {
    CurrencyLabel
} from "../../common/inputs";
import ReactTooltip from 'react-tooltip';
import {
    GeneralButton,
    DateTimePicker
} from "../../common/inputs";
import * as moment from "moment";
import { roundNum } from "../../../utils/roundNum";
import { toast } from "react-toastify";

export default function PaymentSummary({ selectedInvoice, ...rest }) {

    const [paymentsData, setPaymentsData] = useState([]);
    const [residualAmt, setResidualAmt] = useState(0);
    const [updateResidual, setUpdateResidual] = useState(false);
    const [residualPaidDate, setResidualPaidDate] = useState(null);

    //------------Api Service--------------------------------------------
    const paymentsApi = useApi(transactionService.fetchPayments);
    const transactionApi = useApi(transactionService.createSingleInvoice);

    const fetchPaymentDetails = async () => {
        const { data } = await paymentsApi.request({ trans_guid: selectedInvoice.inv_trans_guid, inv_guid: selectedInvoice.inv_guid });
        setPaymentsData(data.rows);
        setResidualAmt(data.rows[0].paymentsInfo.residual.residualAmt);
    }

    const updateResidualDate = async() => {
        const reqData = {
            trans_guid: selectedInvoice.inv_trans_guid,
            invoices: [{ inv_guid: selectedInvoice.inv_guid, residual_paid_date: residualPaidDate,
            residual_paid: 'yes',inv_date:selectedInvoice.inv_date,
            inv_due_date:selectedInvoice.inv_due_date,inv_number:selectedInvoice.inv_number}],
            action: false,
            noDueDateCheck: true
          };

        const { data } = await transactionApi.request(reqData);
        
        if (data.success === false) {
            data.errors &&
              data.errors.map((item) => {
                toast.error(item.message);
              });
          } else{
            fetchPaymentDetails();
            if(rest.handleReloadData){
                rest.handleReloadData();
            }
          }
    }

    useEffect(() => {
        fetchPaymentDetails();
    }, []);

    return (
        <>
            {(paymentsApi.loading === true || transactionApi.loading === true) && <LoadingSpinner />}

            <Modal.Header closeButton>
                <h4 className="modal-title">
                    Invoice #{selectedInvoice.inv_number} &nbsp; Closure Statement
                </h4><br />
            </Modal.Header>

            <Modal.Body >
                <div>
                    {paymentsData.length > 0 ? (
                        <>
                            <div className="row">

                                <div className="col-md-3">
                                    <small className="stats-label">Advanced Date</small>
                                    <h6>{paymentsData[0].paymentsInfo.disbursed_date !== null
                                        ? moment(paymentsData[0].paymentsInfo.disbursed_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}</h6>
                                </div>

                                <div className="col-md-3">
                                    <small className="stats-label">Agreement Amount</small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0]['inv_chargeable_amt']} />
                                    </h6>
                                </div>

                                <div className="col-md-3">
                                    <small className="stats-label">Advanced Amount</small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0].paymentsInfo['total_disbursed']} />
                                    </h6>
                                </div>

                                <div className="col-md-3">
                                    <small className="stats-label">Closing Date</small>

                                    <div className="input-group date">
                                        {paymentsData[0].paymentsInfo.inv_closed_date !== null
                                            ? moment(paymentsData[0].paymentsInfo.inv_closed_date, "YYYY-MM-DD").format("DD/MM/YYYY") :
                                            <span className='text-success'>Open Transaction</span>
                                        }
                                    </div>

                                </div>
                            </div>

                            <hr />

                            <div className="row mt-1">
                                <div className="col-md-12 mt-2 mb-1"><strong>Fees on Payments</strong></div>
                            </div>


                            <div className="row">
                                <div className="col-md-3">
                                    <small className="stats-label">
                                        Payment Received
                                    </small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0].paymentsInfo.inv_total_paid} />
                                    </h6>
                                </div>

                                <div className="col-md-3">
                                    <small className="stats-label">LPF</small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0].paymentsInfo.lpf_on_payments} />
                                    </h6>
                                </div>

                                <div className="col-md-3">
                                    <small className="stats-label">Discount Fee</small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0].paymentsInfo.inv_total_disc_fee_on_payments} />
                                    </h6>
                                </div>
                            </div>
                            <hr />
                            <div className="row mt-1">
                                <div className="col-md-12 mt-2 mb-1"><strong>Fees on Amount to Collect</strong></div>
                            </div>




                            <div className="row">
                                <div className="col-md-3">
                                    <small className="stats-label">
                                        Balance Chargeable
                                    </small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0].paymentsInfo.inv_total_due_amt} />
                                    </h6>
                                </div>

                                <div className="col-md-3">
                                    <small className="stats-label">Days</small>
                                    <h6>{paymentsData[0].paymentsInfo.disc_fee_detail_on_due_amt.days}</h6>
                                </div>

                                <div className="col-md-3">
                                    <small className="stats-label">Discount %</small>
                                    <h6>{roundNum(paymentsData[0].paymentsInfo.disc_fee_detail_on_due_amt.feePctg)}</h6>
                                </div>

                                <div className="col-md-2">
                                    <small className="stats-label">Discount Fee</small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0].paymentsInfo.disc_fee_detail_on_due_amt.feeAmt} />
                                    </h6>
                                </div>
                            </div>


                            <hr />
                            <div className="row">
                                <div className="col-md-6 mt-2 mb-1"><strong>Total Fees & Charges <span class="badge badge-primary"><CurrencyLabel value={paymentsData[0].paymentsInfo.total_fee} /></span></strong></div>
                            </div>


                            <div style={{ border: "1px", dotted: "#ccc", paddingTop: "5px" }} className="row">
                                <div className="col-md-3">
                                    <small className="stats-label">Discount Fees</small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0].paymentsInfo.total_discount_fee} />
                                    </h6>
                                </div>

                                <div className="col-md-3">
                                    <small className="stats-label">Fixed Fee</small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0].paymentsInfo.fixed_fee} />
                                    </h6>
                                </div>

                                <div className="col-md-3">
                                    <small className="stats-label">Insurance Fee</small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0].paymentsInfo.insurance_fee} />
                                    </h6>
                                </div>

                                <div className="col-md-3">
                                    <small className="stats-label">LPF</small>
                                    <h6>
                                        <CurrencyLabel value={paymentsData[0].paymentsInfo.total_lpf} />
                                    </h6>
                                </div>

                            </div>

                            <div className="row hideWhenPrinting mt-2">
                                <div className="col-sm-12">
                                    <div className={`alert alert-${residualAmt < 0 ? 'danger' : 'primary'} text-center`} class="alert alert-primary text-center" role="alert">
                                        <div className="m-b-md">
                                            <h6 className="m-xs">
                                                <i data-tip="Residual Amount = Payment Received - Advance Amount - Discount Fees inc LPF - All other fees/charges." class="fa fa-info-circle"></i>&nbsp;
                                                {residualAmt < 0 ? "AMOUNT OWED BY CLIENT" : "RESIDUAL AMOUNT"}
                                            </h6>
                                            <h4 className="font-bold no-margins">
                                                <CurrencyLabel value={residualAmt} />
                                            </h4>
                                        </div>

                                        {paymentsData[0].inv_closed_reason && 
                                            <div className="text-center">
                                                Comments: {paymentsData[0].inv_closed_reason}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row hideWhenPrinting mt-2">
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6">
                                    {paymentsData[0].residual_paid_date ?
                                        <span className='pull-right'>
                                            Residual Paid: {moment(paymentsData[0].residual_paid_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                        </span> : (
                                            <>
                                                {!updateResidual && paymentsData[0].paymentsInfo.inv_closed_date !== null && <GeneralButton className="btn btn-light pull-right" name="Update Residual Paid Date" onClick={() => setUpdateResidual(true)}></GeneralButton>}
                                                {updateResidual && paymentsData[0].paymentsInfo.inv_closed_date !== null &&
                                                    <>
                                                    <div className="form-group required">
                                                        <label className="control-label">Residual Paid Date</label>
                                                            <div>
                                                                <DateTimePicker
                                                                    name="residual_paid_date"
                                                                    label="Residual Paid Date"
                                                                    selectedDate={residualPaidDate}
                                                                    onChange={({ target }) => setResidualPaidDate(target.value)}
                                                                    enableWeekDays={true}
                                                                />
                                                            </div>
                                                    </div>

                                                        <GeneralButton className="btn btn-primary pull-right" name="Save" onClick={updateResidualDate}></GeneralButton>
                                                        <GeneralButton className="btn btn-light pull-right  mr-2" name="Cancel" onClick={() => { setResidualPaidDate(null); setUpdateResidual(false) }}></GeneralButton>
                                                    </>
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                            <ReactTooltip backgroundColor="#11698e" />
                        </>
                    ) : <div className="row" style={{ minHeight: 350 }}></div>}
                </div>
            </Modal.Body>
        </>
    )
}