import React, { useEffect, useContext, useState } from "react";
import { SecondaryCard } from "../../components/cards";
import { CurrencyLabel } from "../../../common/inputs";
import useApi from '../../../../hooks/useApi';
import useIsMounted from "../../../../hooks/useIsMounted";
import EntityContext from "../../../../context/entityContext";
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";

function AwaitingPayment() {

    const isMounted = useIsMounted();
    const { selectedBusinessType, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);

    //------------Api Service--------------------------------------------
    const dashBoardSummaryApi = useApi(dfTransactionService.InvoiceSummary);

    const fetchData = async () => {
        
        const response = await dashBoardSummaryApi.request({ trans_client_guid: entityDetails.a_guid, trans_type: "open", dateFilter: new Date() });
        
        if(response && isMounted()){
        setData({
            topLabel: "Invoice Outstanding",
            topValue: <CurrencyLabel value={response.data.invoiceSummary.inv_outstanding} />,
            //leftLabel: "Debtors",
            //leftValue: response.data.result.debtor_count ,
            //rightLabel: "Invoices",
            //rightValue: response.data.result.inv_count,
            })
        }
        
    }

    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>
            <SecondaryCard
               loading={dashBoardSummaryApi.loading}
                data={data}
                qrystring={true}
            />
        </>
    )
}
export default AwaitingPayment;