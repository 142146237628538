import { useState } from "react";
import { toast } from "react-toastify";
const useApi = (apiFunc) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const request = async (...args) => {
    try {
      setLoading(true);
      const response = await apiFunc(...args);
      setLoading(false);
      setError(!response);
      setData(response);
      return response;
    } catch (data) {
      setError(true);
      setLoading(false);
      error.response && error.response.statusText
        ? toast.error(error.response.statusText)
        : toast.error("Sorry, something went wrong.");
    }
  };

  return { data, error, loading, request };
};

export default useApi;
