import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import { GeneralButton, TextInput } from "../../../common/inputs";

import entityService from "../../../../services/entityService";
import authService from "../../../../services/authService";
import useApi from "../../../../hooks/useApi";
import useForm from "../../../../hooks/useForm";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { Link, browserHistory } from "react-router";
function EditAccount() {
  //------------Api Services--------------------------------------------
  const contactApi = useApi(entityService.getEntityContactsList);
  const { entity_id, contact_id } = authService.getCurrentUser();

  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      first_name: Joi.string()
        .required()
        .error(() => {
          return { message: "Please enter a First Name" };
        }),
      phone_no: Joi.string()
        .required()
        .label("Phone No.")
        .error(() => {
          return { message: "Please enter a Phone No." };
        }),
    })
    .unknown(true);

  //------------Api Services--------------------------------------------
  const entityContactApi = useApi(entityService.createEntityContact);

  //------------------Save Entity Details-----------------------------
  const saveEntityContact = async () => {
    let reqValues = { ...values };
    let dataSent = {
      contact_id: contact_id,
      first_name: reqValues.first_name,
      last_name: reqValues.last_name,
      phone_no: reqValues.phone_no,
      clientAcEdit: true,
    };
    const { data: res } = await entityContactApi.request(dataSent); // Sending Async request to server
    if (res.success === true) {
      toast.success(res.message);
    } else toast.error(res.message);
  };
  //------------------Custom hook to handle form data-----------------------------
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
  } = useForm(saveEntityContact, schema); // Custom Hook to handle Form Validation

  const fetchEdit = async () => {
    const { data } = await contactApi.request(entity_id);
    let rows = data.rows;
    let updatedContact = rows.find((obj) => obj.contact_id == contact_id);
    setValues({ ...values, ...updatedContact });
  };
  useEffect(() => {
    fetchEdit();
  }, []);

  return (
    <>
      <Helmet>
        <title>My Account | {global.config.title}</title>
      </Helmet>
      {contactApi.loading == true && <LoadingSpinner />}

      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-6"><h6>Update Profile Details</h6></div>
            <div className="col-lg-6">
              <Link className="btn btn-light pull-right text-secondary ml-2 cursor-link" onClick={() => browserHistory.goBack()}><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
            </div>
          </div>

        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 p-4">

              <form onSubmit={handleSubmit} className="form-horizontal">
                <TextInput
                  name="first_name"
                  label="First Name"
                  value={values.first_name}
                  onChange={handleChange}
                  type="horizontal"
                ></TextInput>

                <TextInput
                  name="last_name"
                  label="Last Name"
                  value={values.last_name}
                  onChange={handleChange}
                  type="horizontal"
                ></TextInput>

                <TextInput
                  name="phone_no"
                  label="Phone"
                  value={values.phone_no}
                  onChange={handleChange}
                  type="horizontal"
                ></TextInput>

                <div className="row">
                  <div className="col-lg-12">
                    <GeneralButton
                      className="btn btn-primary btn-sm float-right mr-2"
                      faIcon="fa fa-save"
                      name="Update"
                    ></GeneralButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditAccount;
