import React from "react";
import Form from "../../../../common/Form";
import OrganizationDetails from './organizationDetails';
import OrgAdditionalInfo from './orgAdditionalInfo';
import auth from "../../../../../services/authService";
import EntityService from "../../../../../services/entityService";
import { GeneralButton } from "../../../../common/containers/button";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Link } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import { browserHistory } from "react-router";
import { industries, organizationTypes } from "../../../../../config/predefinedValues.json";
import EntityContext from "../../../../../context/entityContext";

class Organization extends Form {
  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.display_name = React.createRef();
    this.trading_name = React.createRef();
    this.industry_type = React.createRef();
    this.org_type = React.createRef();
    this.aus_business_no = React.createRef();
    this.description = React.createRef();
    this.org_telephone_no = React.createRef();
    this.org_email = React.createRef();
  }
  schema = Joi.object()
    .keys({
      display_name: Joi.string().required().error(() => { return { message: "Please enter a Display Name" }; }),
      trading_name: Joi.string().required().error(() => { return { message: "Please add a Trading Name" }; }),
      industry_type: Joi.object().required().error(() => { return { message: "Please select an Industry type most suitable to your Business" }; }),
      aus_business_no: Joi.string().required().regex(/^[0-9\s]*$/).error(() => { return { message: "Please enter a valid Australian Business Number" }; }),
      description: Joi.string().required().error(() => { return { message: "Please tell us more about your Business" }; }),
    })
    .unknown(true);
  schema2 = Joi.object()
    .keys({
      org_telephone_no: Joi.string().required().error(() => { return { message: "Please enter a valid Telephone number" }; }),
      org_email: Joi.string().email().required().error(() => { return { message: "Please enter a valid generic email address" }; }),
    })
    .unknown(true);
  state = {
    role: "",
    next: false,
    title: "Organisation Details",
    basic: {
      entity_guid: auth.getCurrentUser().entity_id,
      display_name: "",
      trading_name: "",
      logo_name: "",
      industry_type: "",
      org_type: "",
      aus_business_no: "",
      branch: "",
      description: "",
      disabled: false,
      confirmation: false,
      errors: {},
      files: [],
    },
    advanced: {
      reg_address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      is_same_reg_address: "yes",
      postal_address: "",
      p_city: "",
      p_state: "",
      p_zip: "",
      p_country: "",
      code: "",
      org_telephone_no: "",
      org_email: "",
      website: "",
      contact_first_name: "",
      contact_last_name: "",
      contact_position: "",
      contact_email: "",
      contact_telephone_no: "",
      contact_mobile: "",
    },
    ajaxRequest: false,
    disabled: false,
    entityDetails: "",
  };
  componentDidMount() {
    const { entity_id, contact_designation } = auth.getCurrentUser();
    this.setState({ role: contact_designation, entityDetails: this.context.entityDetails });
    this.fetchOrgDetails(entity_id);

  }

  fetchOrgDetails = async (entity_guid) => {
    try {
      this.setState({ ajaxRequest: true, disabled: true });
      const basic = { ...this.state.basic };
      const { result } = await EntityService.fetchClientOrgDetails(entity_guid);
      basic['display_name'] = result['display_name'] ? result['display_name'] : this.state.entityDetails.organization;
      basic['trading_name'] = result['trading_name'] ? result['trading_name'] : this.state.entityDetails.organization;
      basic['logo'] = result['logo'];
      basic['aus_business_no'] = result['aus_business_no'] ? result['aus_business_no'] : this.state.entityDetails.ab_number;
      basic['branch'] = result['branch'];
      basic['description'] = result['description'];
      basic['industry_type'] = result['industry_type'];
      basic['org_type'] = result['org_type'];
      const advanced = { ...this.state.advanced };
      advanced['reg_address'] = result['reg_address'];
      advanced['city'] = result['city'];
      advanced['state'] = result['state'];
      advanced['zip'] = result['zip'];
      advanced['org_telephone_no'] = result['org_telephone_no'];
      advanced['is_same_reg_address'] = result['is_same_reg_address'] ? result['is_same_reg_address'] : "yes";
      advanced['postal_address'] = result['postal_address'];
      advanced['p_city'] = result['p_city'];
      advanced['p_state'] = result['p_state'];
      advanced['p_zip'] = result['p_zip'];
      advanced['code'] = result['code'];
      advanced['org_email'] = result['org_email'];
      advanced['website'] = result['website'];
      advanced['contact_first_name'] = result['contact_first_name'] ? result['contact_first_name'] : this.state.entityDetails.first_name;
      advanced['contact_last_name'] = result['contact_last_name'] ? result['contact_last_name'] : this.state.entityDetails.last_name;
      advanced['contact_position'] = result['contact_position'] ? result['contact_position'] : this.state.entityDetails.primary_desig;
      advanced['contact_email'] = result['contact_email'] ? result['contact_email'] : this.state.entityDetails.primary_email;
      advanced['contact_telephone_no'] = result['contact_telephone_no'] ? result['contact_telephone_no'] : this.state.entityDetails.primary_phone_no;
      advanced['contact_mobile'] = result['contact_mobile'];
      advanced['country'] = { label: result['iso3'], value: result['country_id'] };
      advanced['p_country'] = { label: result['p_iso3'], value: result['p_country_id'] };

      this.setState({ basic, advanced, ajaxRequest: false, disabled: false });
    } catch (error) { }
  };

  handleTextChange = ({ target }) => {
    const basic = { ...this.state.basic };
    basic[target.name] = target.value;
    this.setState({ basic });
  };
  handleTextChangeAdv = ({ target }) => {
    const advanced = { ...this.state.advanced };
    advanced[target.name] = target.value;
    this.setState({ advanced });
  };
  handleOrgTypeOnSelect = ({ value, label }) => {
    const basic = { ...this.state.basic };
    basic['org_type'] = { label: label, value };
    this.setState({ basic });
  }
  handleIndustryTypeOnSelect = ({ value }) => {
    const basic = { ...this.state.basic };
    basic['industry_type'] = { label: value, value };
    this.setState({ basic });
  }

  handleCountrySelect = async (opt, field) => {
    this.setState({ ajaxRequest: true });
    const { data } = await EntityService.fetchCountryCode({ id: opt.id });
    const advanced = { ...this.state.advanced };
    advanced[field] = { label: opt.label, value: opt.id };
    if (field == "country")
      advanced["code"] = data;
    this.setState({ advanced, ajaxRequest: false });
  };

  handleCheckboxChange = (event) => {
    const advanced = { ...this.state.advanced };
    advanced["is_same_reg_address"] = (event.target.checked) ? "yes" : "no";
    this.setState({ advanced });
  };

  handleImageUpload = (fileItems) => {
    const basic = { ...this.state.basic };
    if (fileItems[0])
      basic["logo_name"] = fileItems[0].file.name;
    basic["files"] = fileItems.map((fileItem) => fileItem.file)
    this.setState({ basic });
  };

  validate = () => {
    const options = { abortEarly: true };
    const { error } = Joi.validate(this.state.basic, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
      this.focusInput(item.path[0]);
    }
    return errors;
  };
  validate2 = () => {
    const options = { abortEarly: true };
    const { error } = Joi.validate(this.state.advanced, this.schema2, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
      this.focusInput(item.path[0]);
    }
    return errors;
  };

  isNumericString = (value) => {
    return /^\d+$/.test(value);
  }

  confirmation = async () => {
    const errors = this.validate("basic");
    if (errors) {
      this.setState({ next: false, ajaxRequest: false, disabled: false, title: "Organisation Details" });
      return;
    } else {
      if((this.state.basic['selectedOrgType'] !== 'trust' && this.state.basic['aus_business_no'] === '') || (this.state.basic['selectedOrgType'] !== 'trust' && !this.isNumericString(this.state.basic['aus_business_no'])))
      {
        
        toast.error("Please enter a valid Australian Business Number");
  
      }else{
        this.setState({ next: true, title: "Organisation Details" });
        
      }
     
    }
  };
  backButtonMethod = async () => {
    this.setState({ next: false, title: "Organisation Details" });
  }
  focusInput = (inputName) => { 
    if (inputName === "display_name") this.display_name.current.focus();
    if (inputName === "trading_name") this.trading_name.current.focus();
    if (inputName === "industry_type") this.industry_type.current.focus();
    if (inputName === "org_type") this.org_type.current.focus();
    if (inputName === "aus_business_no") this.aus_business_no.current.focus();
    if(inputName === "description") this.description.current.focus();
    if(inputName === "org_telephone_no") this.org_telephone_no.current.focus();
    if(inputName === "org_email") this.org_email.current.focus();
  };
  handleSubmit = (e) => { e.preventDefault(); this.doSubmit(); };
  doSubmit = async () => {
    this.setState({ ajaxRequest: true, disabled: true });
    try {
      const { basic, advanced } = this.state;
      let data;
      let fileslist = await this.handleFileReader();
      const error1 = this.validate();
      if (error1) {
        this.setState({ ajaxRequest: false, disabled: false, next: false }); return;
      } else {
        //basic["industry_type"] = basic['industry_type'] ? basic['industry_type'].value : "";
        //basic["org_type"] = basic['org_type'] ? basic['org_type'].value : "";
        basic["branch"] = basic['branch'] ? basic['branch'] : "001";
        const error2 = this.validate2();
        if (error2) { 
          this.setState({ ajaxRequest: false, disabled: false }); 
          return; 
        }
        else {
          advanced["country"] = advanced['country'] ? advanced['country'].value : "";
          advanced["p_country"] = advanced['p_country'] ? advanced['p_country'].value : "";
          data = { ...basic, ...advanced }
        }
      }

      setTimeout(async () => {
        const { success, message } = await EntityService.saveClientOrganizatioinDetails(data, fileslist);
        if (success === false) {
          toast.error(message);
        } else {
          this.cancel("All changes have been saved.", "You want to go back to Settings page ?", "/client/settings");
          //toast.success(message);
          await this.fetchOrgDetails(this.state.basic.entity_guid);
          this.setState({ ajaxRequest: false, disabled: false });
          //browserHistory.push("/client/settings");
        }
      }, 100);
      
    } catch (error) {
      console.log(error);
    }
  };

  handleFileReader = async () => {
    let files = this.state.basic.files;
    let fileslist = [];
    files.map((fileItem) => {
      let reader = new FileReader();
      reader.readAsDataURL(fileItem);
      reader.onload = (e) => {
        fileslist.push({
          name: fileItem.name,
          file: e.target.result,
          type: fileItem.type,
          size: fileItem.size,
        });
      };
    });
    return fileslist;
  };

  cancelMethod = () => {
    this.cancel("Are you Sure..?", "You want to Cancel adding Organisation Settings ?", "/client/settings");
  }

  cancel = (heading, des, url) => {
    confirmAlert({
      customUI: ({ onClose }) => {

        return (
          <div className='custom-delete-ui'>
            <h5>{heading}</h5>
            {/* style={{ color: "white" }} */}
            <p>{des}</p>
            <button className="btn btn-white" onClick={onClose}>No</button>
            <button
              className="btn btn-primary ml-2"
              onClick={async () => {
                onClose();
                browserHistory.push(url);
              }}
            >
              Yes
            </button>
          </div>
        );
      },
      title: "Confirm to delete",
      message: "Are you sure to do this.",
    });
  }

  render() {
    const { next, basic, advanced, ajaxRequest, disabled, title, role } = this.state;
    return (
      <>
        <Helmet>
          <title>Organisation Details | {global.config.title}</title>
        </Helmet>
        {(ajaxRequest === true || disabled === true) && <LoadingSpinner />}
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-lg-6"><h6>{title}</h6></div>
              <div className="col-lg-6">
                <Link className="btn btn-light pull-right text-secondary ml-2 cursor-link" to="/client/settings"><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
              </div>
            </div>
          </div>



          <form className="form-horizontal" onSubmit={this.handleSubmit}>

            <div className="modal-body">
              {!next && <OrganizationDetails
                role={role}
                data={basic}
                handleChange={this.handleTextChange}
                handleOrgTypeOnSelect={this.handleOrgTypeOnSelect}
                handleIndustryTypeOnSelect={this.handleIndustryTypeOnSelect}
                handleImageUpload={this.handleImageUpload}
                reference={{ 
                  display_name: this.display_name,
                  trading_name: this.trading_name,
                  industry_type: this.industry_type,
                  org_type: this.org_type,
                  aus_business_no: this.aus_business_no,
                  description: this.description
                }}
              />
              }
              {next && <OrgAdditionalInfo
                role={role}
                data={advanced}
                handleChange={this.handleTextChangeAdv}
                handleCountrySelect={this.handleCountrySelect}
                handleCheckboxChange={this.handleCheckboxChange}
                reference={{ 
                  org_telephone_no: this.org_telephone_no,
                  org_email: this.org_email
                }}
              />}
              <div className="row mt-3">
                <div className="col-lg-12 text-right">
                  <GeneralButton onClick={this.cancelMethod} name="Cancel" className="btn btn-light mr-2" type="button"></GeneralButton>
                  {next == true && (<GeneralButton onClick={this.backButtonMethod} name="Previous" className="btn btn-light mr-2 ml-2" type="button"></GeneralButton>)}
                  {next == false && (<GeneralButton disabled={disabled} onClick={this.confirmation} name="Continue" type="button" className="btn btn-primary ml-2"></GeneralButton>)}
                  {(next == true && role == "CEO") && (<GeneralButton disabled={disabled} name="Submit" className="btn btn-primary ml-2"></GeneralButton>)}
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }
}

export default Organization;