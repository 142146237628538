import React from "react";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Modal from 'react-bootstrap/Modal';
import InvIcon from "../../../../../img/invoice.svg";
import Form from "../../../../common/Form";
import { FormRow, FormColumnLg } from "../../../../common/containers/formContainer";
import { SaveButton, ModalCloseButton, AddButton } from "../../../../common/containers/button";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import { TextInput } from "../../../../common/inputs/textInput/textInput";
import { DateTimePicker } from "../../../../common/inputs/datePicker";
import { CurrencyLabel, CurrencyText, } from "../../../../common/inputs/currencyInput/currencyInput";
import MultipleFileInput from "../../../../common/inputs/multipleFileInput/multipleFileInput";
import UserType from "../../../hooks/useUserType";
import Confirmation from "./_confirmation";
import Transaction from "../../../services/transaction_service";
import auth from "../../../../../services/authService";
import EntityContext from "../../../../../context/entityContext";
import Utility from "../../../utils/DateParser";
import RoleChecker from "../../../../../utils/roleChecker";
import TermsPartB from "../invoiceview/_termsPartB";
import CurrencyDetails from "../invoiceview/_currencyDetails";
import commonService from "../../../services/commonService";
import { browserHistory } from "react-router";
class CreateTfInvoiceSupplier extends Form {

  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.confirmationRef = React.createRef();
  }

  state = {
    data: {
      supplier: this.props.a_guid ? this.props.a_guid : "",
      customer: "",
      trans_date: null,
      amount: 0,
      inv_due_date: null,
      invoice_number: "",
      purchase_order_no: "",
      inv_early_payment_date: null,
      document_name: "",
      errors: {},
      selectedWhenPaid: "",
      fee: "",
      minRevisedDate: null,
      disabled: false,
      rate_guid: "",
      terms_b: false,
      confirmation: false,
      currencyStatus: "inactive",
      currency: {label : "AUD", value : "A$"},
      exchangeRate: 1,
      fcyAmount: "",
      other_programs: 'no'
    },
    disclosure : null,
    files: [],
    ajaxRequest: false,
    supplierList: [],
    customerList: [],
    products: [],
    constants: [],
    loading: false,
    termsModal: false,
    disabled: false,
    readOnly: true
  };

  schema = Joi.object()
    .keys({
      customer: Joi.string().required().label("Customer"),
      invoice_number: Joi.string().required().label("Invoice Number"),
      amount: Joi.number().min(1).required().label("Amount"),
      trans_date: Joi.date().required().label("Invoice Date"),
      inv_due_date: Joi.date()
        .required()
        .greater(Joi.ref("trans_date"))
        .label("Invoice Due Date"),
  })
  .unknown(true);
  validate = (second = "") => {
    const options = { abortEarly: true };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {

      errors[item.path[0]] = item.message;
      toast.error(item.message);
      this.focusInput(item.path[0]);
    }
    return errors;
  };
  doSubmit = async () => {
    const { category, constants } = auth.getCurrentUser();
    const { ParseDate } = Utility(); // Instance for utility library.
    this.setState({ ajaxRequest: true, disabled: true });
    try {
      const data = {...this.state.data};
      let data1;
      if (this.props.a_guid) {
        data["supplier"] = this.props.a_guid;
      }
      data["inv_due_date"] = data.inv_due_date != null ? ParseDate(data.inv_due_date) : null;
      data["inv_early_payment_date"] = data.inv_early_payment_date != null ? ParseDate(data.inv_early_payment_date) : null;
      data["trans_date"] = ParseDate(data.trans_date);

      let fileslist = [];
      if (!data.trans_guid) {
        fileslist = await this.handleFileReader();
      }

      if (data.currencyStatus == "inactive")
        data["currency"] = "AUD";
      else {

        data["currency"] = (data.currency && data.currency != null && data.currency != "undefined") ? data.currency.label : "AUD";
        data["fcyAmount"] = data.exchangeRate ? data.exchangeRate : 0;

        if (data.amount && data.exchangeRate)
          data["fcyAmount"] = data.amount * data.exchangeRate;

      }

      const errors = this.validate();
      this.setState({ errors: errors || {} });
      if (errors) {
        data["confirmation"] = false;
        this.setState({ data, ajaxRequest: false, disabled: false });
        return;
      } else {
        const confirmationRefState = this.confirmationRef.current;
        const errors = confirmationRefState.validate();
        if (errors) { this.setState({ ajaxRequest: false, disabled: false }); return; }
        else {
          data["product_type"] = this.state.constants.PRODUCTS.TRADEFINANCE;
          data1 = { ...data, ...confirmationRefState.state.data }

        }
      }

      setTimeout(async () => {
        const result = await Transaction.createInvoice(data1, fileslist, "supplier");
        // const { success,message,inv_guid,type,status } = await Transaction.createInvoice(data1, fileslist, "supplier");
        if (result.success === false) {
          if(result.terms_b){
            this.setState({ termsModal: true });
          }else{
            toast.error(result.message);
          }
        } else {
          
          if(this.props.type == "convert")
             this.props.onCloseModal(result.inv_guid);
          else{  
            this.setState({ ajaxRequest: false, disabled: false }); 
            toast.success(result.message);
            this.props.onCloseModal();
            if (this.props.onSuccessCreation) {
              this.props.onSuccessCreation();
            }
            if (category === constants.CATEGORY.TRADERS && result.type == "add") {
              browserHistory.push({ pathname: '/client/tf/invoice/details', search: "?inv_guid=" + result.inv_guid });
              //browserHistory.push({ pathname: '/client/tf/sale-invoices', state : result.status });
            }else if(result.type != "edit") {
               browserHistory.push({ pathname: '/tfinvoice/details', search: "?inv_guid=" + result.inv_guid });
              //browserHistory.push({ pathname: '/tf-invoices', state : [{ label : 'Awaiting Approval', value :constants.SC_STATUS_CONSTANTS.AWA }] });
            }
          }  
        }
      }, 100);
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit();
  };

  onCloseModal = () => {
    this.setState({ open: false, selectedFeeType: [] });
  };
  setValues = async (selectedItem, input) => {
    this.setState({ ajaxRequest: true, disabled: true }); 
    const data = { ...this.state.data };
    const result = await Transaction.getRateDetails({ customer: selectedItem, supplier: data.supplier,
        product_type : this.state.constants.PRODUCTS.TRADEFINANCE });
    if (result.data.disclosure && result.data.disclosure =='undisclosed'){
      toast.error("Can't Choose Undisclosed Suppliers");
      this.setState({ ajaxRequest: false, disabled: false,readOnly: true });
      return false;
  }else 
        if (result.data.rate_guid )
      this.setState({ readOnly: false,disclosure: result.data.disclosure });
    else {
      toast.error("NO rate settings on this supplier, please add one");
      this.setState({ ajaxRequest: false, disabled: false ,readOnly: true});
      return false;
    }
    data['currencyStatus'] = result.data.is_currency_on;
    data['other_programs'] = result.data.other_programs;
    data[input] = selectedItem;
    this.setState({ data,ajaxRequest: false, disabled: false });
  }
  handleDueDateChange = (date) => {
    const data = { ...this.state.data };
    data["inv_due_date"] = date;

    if(!date)
      data['inv_early_payment_date'] = null;

    this.setState({ data }, () => { this.displayFee(); });
  };

  handleWhenPaidChange = (date) => {
    const data = { ...this.state.data };
    data["selectedWhenPaid"] = "";
    data["inv_early_payment_date"] = date;
    this.setState({ data }, () => { this.displayFee(); });
  };

  displayFee = async () => {

    this.setState({ loading: true });
    const { data } = this.state;
    const { ParseDate } = Utility();
    data["trans_date"] = data.trans_date != null ? ParseDate(data.trans_date) : null;

    data["inv_due_date"] = data.inv_due_date != null ? ParseDate(data.inv_due_date) : null;
    data["inv_early_payment_date"] = data["inv_early_payment_date"] != null ? ParseDate(data.inv_early_payment_date) : null;
    data["product_type"] = this.state.constants.PRODUCTS.TRADEFINANCE;

    try {
      const { success, message, rate } = await Transaction.displayFee(data, "supplier");
      if (success === false) toast.error(message);
      else {
        data["fee"] = message;
        data['rate_guid'] = rate;
        this.setState({ data });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  };
  //*** Updating state of data when input changed ***/
  handleInvDateChange = (date) => {
    const data = { ...this.state.data };
    data["trans_date"] = date;

    if(this.state.data.other_programs === 'yes'){
      if(date){
        let newInvDueDate = new Date(date);
        newInvDueDate.setDate(newInvDueDate.getDate() + 30);
        data['inv_due_date'] = newInvDueDate;
        data['inv_early_payment_date'] = date;
      }
    }

    if(!date){
      data['inv_due_date'] = null;
      data['inv_early_payment_date'] = null;
    }

    this.setState({ data });
    setTimeout(async () => {
      this.displayFee();
    }, 100);
  };
  handleAmount = () => {
    const data = { ...this.state.data };
    this.setState({ data }, () => {
      this.displayFee();
    });
  };

  setAmount = (event, maskedvalue, floatvalue) => {
    const data = { ...this.state.data };
    data["amount"] = floatvalue;
    if (this.state.data['amount'] != floatvalue)
      this.setState({ data });

  };

  focusInput = (inputName) => { };

  handleFileReader = async () => {
    /** --------------- Reading local files in JavaScript -------------- */
    let files = this.state.files;
    let fileslist = [];
    files.map((fileItem) => {
      let reader = new FileReader();
      reader.readAsDataURL(fileItem);
      reader.onload = (e) => {
        fileslist.push({
          name: fileItem.name,
          file: e.target.result,
          type: fileItem.type,
          size: fileItem.size,
        });
      };
    });
    return fileslist;
    /** ----------------------------------------------------------------- */
  };

  componentDidMount() {
    const { constants } = auth.getCurrentUser();
    this.handleSyncContext(constants);
    this.fetchDetails();
  }
  fetchDetails = () => {
    if (this.props.data) {
      const propData = this.props.data;
      if (propData.customer) {
        this.setState({ readOnly: false });
      }
      this.setState({ data: { ...this.state.data, ...propData },disclosure :this.props.disclosure }, () => { this.displayFee() });
    }
  }

  handleSyncContext = async (constants) => {

    if ((this.context) && (this.context.entityDetails !== null)) {
      const products = this.context.entityDetails.products;
      this.setState({ products });
      this.setState({ constants });
      if (RoleChecker.check(products, constants.ROLES.TFSUPPLIER)) {
        const data = { ...this.state.data };
        data["supplier"] = this.props.a_guid;
        this.setState({ data });
      }
      else if (RoleChecker.check(products, constants.ROLES.TFCUSTOMER)) {
        const data = { ...this.state.data };
        data["customer"] = this.props.a_guid;
        this.setState({ data });
      }

    } else {
      const { products } = auth.getCurrentUser();
      this.setState({ products });
      this.setState({ constants });
    }


  }
  searchSupplierKeyChange = async ({ label, value }) => {
    const data = { ...this.state.data };
    data["supplier"] = value
    this.setState({ data });
  }
  searchCustomerKeyChange = async ({ label, value }) => {
    const data = { ...this.state.data };
    data["customer"] = value
    this.setState({ data });
  }

  onCloseTermsModal = () => {
    this.setState({ termsModal: false });
  };

  handleTermsCheckboxChange = (event) => {
    const data = { ...this.state.data };
    data["terms_b"] = true; //event.target.checked;
    this.setState({ data }, () => { this.onCloseTermsModal(); this.doSubmit(); });
  };

  changeConfirmation = async () => {
    const data = { ...this.state.data };
    data["confirmation"] = false;
    this.setState({ data });
  };

  confirmation = async () => {
    const data = { ...this.state.data };
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) {
      data["confirmation"] = false;
      this.setState({ data });
      this.setState({ ajaxRequest: false, disabled: false });
      return;
    } else {
      if (!data.trans_guid || ( data.trans_guid && data.invoice_number_old != data.invoice_number ) ) { 
        const {data : res} = await commonService.checkDuplicateInvNumber({ "inv_numbers": [data.invoice_number] ,"suppliers" : [ this.props.a_guid ] });
        if(res.success){
          toast.error("Invoice Number has already exist in the System!");
          return true;
        }
      }  
      data["confirmation"] = true;
      this.setState({ data }); 
    }
  };
  handleCurrencyChange = (currency) => {
    if (currency) {
      const data = { ...this.state.data };
      data["currency"] = currency;
      this.setState({ data });
    }
  };

  setFcy = (event, maskedvalue, floatvalue) => {
    const data = { ...this.state.data };
    data["fcyAmount"] = floatvalue;
    if (this.state.data['fcyAmount'] != floatvalue)
      this.setState({ data });
  };

  render() {
    const { data, ajaxRequest } = this.state;
    const { category, constants, products } = auth.getCurrentUser();
    const { ParseDate } = Utility();
    return (
      <React.Fragment>
        <div style={{ position: "relative", top: "50%", left: "50%", zIndex: "100" }} >{ajaxRequest && <LoadingSpinner />}</div>


        <Modal.Header closeButton className="justify-content-center">
          <div class="d-block pt-3 text-center w-100 ml-4">
            <img width={"50px"} src={InvIcon} className="mb-2" />
            <h4 className="modal-title">{this.state.data.trans_guid ? "Update Invoice" : "New Invoice"}</h4>
            <small class="">Please enter the invoice details below.</small>
          </div>

        </Modal.Header>
        <form onSubmit={this.handleSubmit}>
          <Modal.Body>
            {data.confirmation === false && (
              <>
                <FormRow >
                  <div className="modal-body">
                    {ajaxRequest && <LoadingSpinner />}

                    {(this.context && constants.ROLES && RoleChecker.check(this.state.products, constants.ROLES.TFSUPPLIER))
                      && (<div className="form-group">
                        <UserType onSelectItem={(selectedItem) => this.setValues(selectedItem.value, "customer")}
                          type="mappedcustomer"
                          userId={this.props.a_guid ? this.props.a_guid : ""}
                          page={this.props.page ? this.props.page : ""}
                          heading="Customer"
                          products={this.state.products}
                          InputOption={{ value: this.state.data.customer }}
                          isDisabled={data.trans_guid ? true : false}
                          productType = { constants && constants.PRODUCTS && constants.PRODUCTS.TRADEFINANCE }
                          mapSettings = {true}
                        />
                      </div>)}

                    {((!this.context && (category == "Business Partner" || category == "International Office" || category == "Country Office"))) && (
                      <>
                        <div className="form-group">
                          <UserType 
                            isDisabled={data.trans_guid ? true : false} 
                            onSelectItem={(selectedItem) => this.setValues(selectedItem.value, "customer")} 
                            type="mappedcustomer" 
                            userId={this.props.a_guid ? this.props.a_guid : ""} 
                            page={this.props.page ? this.props.page : ""} 
                            heading="Customer" 
                            products={this.state.products} 
                            InputOption={{ value: this.state.data.customer }}
                            productType = {constants && constants.PRODUCTS && constants.PRODUCTS.TRADEFINANCE } 
                          />
                        </div>
                      </>
                    )}
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Purchase Order</label>
                          <div >  <TextInput name="purchase_order_no" value={data.purchase_order_no} label="Purchase Order Number" reference={this.purchase_order_no} onChange={this.handleChange} type="" readonly={this.state.readOnly} title={this.state.readOnly ? "Please choose the supplier " : ""}></TextInput></div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Invoice Number</label>
                          <div > <TextInput name="invoice_number" value={data.invoice_number} label="Invoice Number" reference={this.invoice_number} onChange={this.handleChange} type="" readonly={this.state.readOnly} title={this.state.readOnly ? "Please choose the supplier " : ""}></TextInput></div>
                        </div>
                      </div>
                    </div>

                    {/*------------------------------------------------------------------*/}
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Amount</label>
                          <div >  <CurrencyText placeholder="Amount + Tax" name="amount" onBlur={this.handleAmount} onChangeEvent={this.setAmount} value={data.amount > 0 ? data.amount : null} readonly={this.state.readOnly} title={this.state.readOnly ? "Please choose the supplier " : ""} /></div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Invoice Date</label>
                          <div > <DateTimePicker enableWeekDays={true} name="trans_date" type="normal" onChange={this.handleInvDateChange} selected={typeof data.trans_date !== "object" ? new Date(Date.parse(data.trans_date)) : data.trans_date} format="dd/MM/y" readOnly={this.state.readOnly}></DateTimePicker></div>
                        </div>
                      </div>
                    </div>
                    {/*--------------------------------------------------*/}

                    {this.state.data.other_programs !== 'yes' &&
                    <div className="form-group"><label>Due Date</label><div>
                      <DateTimePicker utcOffset={0} name="inv_due_date" type="normal" 
                        onChange={this.handleDueDateChange} minDate={new Date(Date.parse(data.trans_date))} 
                        selected={typeof data.inv_due_date !== "object" ? new Date(Date.parse(data.inv_due_date)) : data.inv_due_date} 
                        format="dd/MM/y" readOnly={this.state.readOnly || this.state.data.trans_date === null} enableWeekDays = {true}
                      />
                    </div></div>
                    }

                    {this.state.data.other_programs === 'yes' && (
                        <>
                            <div className="form-group">
                                <div>
                                    <p><i className="fa fa-info-circle mr-2"/>Customer Program Payment Term i.e. defaults 30 days from Invoice Date</p>
                                </div>
                            </div>
                        </>
                    )}

                    <div class=" alert alert-info rounded-lg">
                      <FormRow className="row mx-0">
                        <div className="col-md-12 my-3 text-center">
                          {(category == constants.CATEGORY.BP || category == constants.CATEGORY.COUNTRY_OFFICE || category == constants.CATEGORY.INTL_OFFICE) && (
                            <> <b>Pay the supplier early </b> <br></br>
                              <small className="opacity-75">(You can pay the supplier before the due date)</small></>
                          )}
                          {category == constants.CATEGORY.TRADERS && (
                            <> <b>Would you like to be paid early?</b> <br></br>
                              <small className="opacity-75">(You can have this paid before the due date)</small></>)}

                          <DateTimePicker class="form-control  splus-datepicker mt-3" utcOffset={0} name="inv_early_payment_date" type="normal" onChange={this.handleWhenPaidChange} placeholder="New Payment Date"
                            selected={typeof data.inv_early_payment_date !== "object" ? new Date(Date.parse(data.inv_early_payment_date)) : data.inv_early_payment_date}
                            minDate={new Date(Date.parse(data.trans_date))} maxDate={new Date(Date.parse(data.inv_due_date))} format="dd/MM/y" readOnly={this.state.readOnly || this.state.data.inv_due_date === null}></DateTimePicker>
                        </div>
                      </FormRow>

                      {this.state.data.other_programs === 'yes' && 
                        <div className="p-2"><i className="fa fa-info-circle mr-2"/>The invoice will be paid by Fifo same day if the Customer approves before 1.00pm AEST/AEDT</div>
                      }
                    </div>
                    

                    {/* {this.state.data.other_programs === 'yes' && 
                      <div class=" alert alert-info rounded-lg">
                        <FormRow className="row mx-0">
                          <div className="col-md-12 my-3 text-center">
                            <p><i className="fa fa-info-circle mr-2"/>Customer Program Payment Term i.e. defaults 30 days from Invoice Date</p>
                            <div className="p-2">The invoice will be paid by Fifo same day if the Customer approves before 1.00pm AEST/AEDT</div>
                          </div>
                        </FormRow>
                      </div>
                    } */}

                    {data.currencyStatus == "active" && (
                      <CurrencyDetails handleChange={this.handleChange} handleCurrencyChange={this.handleCurrencyChange}
                        data={{ currency: data.currency, exchangeRate: data.exchangeRate, fcyAmount: data.fcyAmount, amount: data.amount }}
                        setFcy={this.setFcy}
                      />
                    )}
                    {!this.state.data.trans_guid && (
                      <>
                        <FormRow className="row m-t-md"> <FormColumnLg col="4"> <label>Invoice Files</label></FormColumnLg></FormRow>
                        <FormRow className="row">
                          <FormColumnLg col="12">
                            <MultipleFileInput files={this.state.files} allowMultiple={true}
                              onupdatefiles={(fileItems) => {
                                const data = { ...this.state.data };
                                if (fileItems[0])
                                  data["document_name"] = fileItems[0].file.name;
                                this.setState({
                                  files: fileItems.map((fileItem) => fileItem.file),
                                  data,
                                });
                              }}
                            ></MultipleFileInput>
                          </FormColumnLg>
                        </FormRow>

                      </>
                    )}

                  </div>
                </FormRow >

                <div className="text-center font-medium" style={{ height: "50px" }}>
                  {data.inv_due_date != "" && data.inv_due_date != null &&
                    <>
                      {this.state.loading ?
                        <>
                          <h4 class="mt-1 d-flex align-items-center justify-content-center"><span class="text-muted text-sm font-medium mr-2"> {(category == constants.CATEGORY.BP || category == constants.CATEGORY.COUNTRY_OFFICE || category == constants.CATEGORY.INTL_OFFICE) && (
                            <>SUPPLIER WILL BE PAID</>
                          )}  {category == constants.CATEGORY.TRADERS && (
                            <>YOU WILL BE PAID</>
                          )}</span></h4><div class="font-weight-bold"><LoadingSpinner height={40} width={25} normal={true} type="ThreeDots" /></div>

                        </> :
                        <>
                          <div className="font-weight-bold text-primary text-lg"><span class="text-muted text-sm font-medium mr-2"> {(category == constants.CATEGORY.BP || category == constants.CATEGORY.COUNTRY_OFFICE || category == constants.CATEGORY.INTL_OFFICE) && (
                            <>SUPPLIER WILL BE PAID</>
                          )}  {category == constants.CATEGORY.TRADERS && (

                            <>
                              YOU WOULD LIKE TO BE PAID</>)}</span>&nbsp;<CurrencyLabel value={data.fee ? data.fee : 0}></CurrencyLabel>&nbsp; <span class="text-muted text-sm font-medium mr-2">ON &nbsp;
                              {(data.inv_early_payment_date != null ? ParseDate(data.inv_early_payment_date, 'DD/MM/YY') : (data.inv_due_date != null ? ParseDate(data.inv_due_date, 'DD/MM/YY') : ""))}</span></div>
                          {(data.inv_early_payment_date == null || data.inv_early_payment_date == "") &&
                            <p className="text-muted text-xs font-medium">(for a small discount you can be paid early, change the payment date above)</p>
                          }
                        </>
                      }
                    </>
                  }
                </div>
              </>
            )}
            {data.confirmation == true && (<Confirmation ref={this.confirmationRef} constants={constants} products={products} category={category}></Confirmation>)}
          </Modal.Body>

          <Modal.Footer>
            {this.props.backToList && (
              <ModalCloseButton onClick={(e) => { this.props.backToList() }} label="Back" className="btn btn-light" type="button"></ModalCloseButton>
            )}
            {data.confirmation == true && (<ModalCloseButton onClick={this.changeConfirmation} label="Back" className="btn btn-light"></ModalCloseButton>)}
            {data.confirmation == false && !this.state.readOnly && (<AddButton disabled={this.state.disabled} onClick={this.confirmation} label="Next" style="btn btn-primary"></AddButton>)}
            {data.confirmation == true && (<SaveButton label="Submit" disabled={this.state.disabled} ></SaveButton>)}
          </Modal.Footer>
        </form >
        <Modal
          show={this.state.termsModal}
          onHide={this.onCloseTermsModal}
          className="secondary-modal"
          size="lg"
        >
          <TermsPartB customClose={this.onCloseTermsModal} terms_b={data.terms_b} onCheckboxChange={this.handleTermsCheckboxChange} />
        </Modal>
      </React.Fragment >
    );
  }
}

export default CreateTfInvoiceSupplier;