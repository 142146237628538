import React, { useEffect, useState } from "react";
import entityService from "../../../../services/entityService";
import { FormRow } from "../../../common/containers/formContainer";
import LoadingSpinner from '../../../common/loaders/loadingSpinner';
import ListSupplier from "./_listSupplierPopup";
import useApi from '../../../../hooks/useApi';
import { useUploader } from "../../../../context/supplierUploaderContext";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router";

function SupplierValidation() {

    const {
        suppliers,
        setProceedNext,
        setnextLabel,
        duplicateSuppliersInSheet,
        uniqueSuppliers,
        setReadyToUpload 
    } = useUploader(); // Instance for Context API

    const [errorModal, setErrorModal] = useState(false);
    const [validationResults, setValidationResults] = useState(null);
    const [listSupplierModal, setListSupplierModal] = useState(false);
    const [popupTitle, setPopupTitle] = useState(null);
    const [supplierToList, setSupplierToList] = useState(null);

    setnextLabel("Upload");
    //------------Custom hooks for handling API Requests--------------
    const validationApi = useApi(entityService.bulkSupplierValidation);

    //------------------Async function to validate the suppliers------------------------
    const validateSupplier = async () => {
        let suppliersData = [];
        let readyToUploadArray = [];

        const { data } = await validationApi.request(suppliers);
        setValidationResults(data);

        data.readyToUpload && data.readyToUpload.length > 0 &&
        data.readyToUpload.map(itm => {
            suppliersData = suppliers.filter(suplr => suplr.Email == itm.Email);
            readyToUploadArray.push(suppliersData[0]);
        });
        setReadyToUpload(readyToUploadArray);
    }

    useEffect(() => {
        validateSupplier();
    }, []);

    useEffect(() => {
        //Enable the next button only if it contains valid data.
        if (validationResults != null && (validationResults.readyToUploadCount == validationResults.totalSuppliers)) {
            setProceedNext(true);
        } else {
            setProceedNext(false);
        }
    }, [validationResults]);

    const handleShowSupplier = (title) => {
        switch (title) {
            case 'totalSuppliers':
                setPopupTitle('List all uploaded suppliers');
                setSupplierToList(suppliers);
                break;
            case 'duplicate_database':
                setPopupTitle('Duplicates found in Database');
                setSupplierToList(validationResults.duplicateEntitiesList);
                break;
            case 'duplicate_in_sheet':
                setPopupTitle('Duplicates found in Sheet');
                setSupplierToList(duplicateSuppliersInSheet);
                break;
            case 'readyToUpload':
                setPopupTitle('Ready to upload Suppliers');
                setSupplierToList(validationResults.readyToUpload);
                break;
        }
        setListSupplierModal(true);
    }


    return (
        <>
            {validationApi.loading === true && <LoadingSpinner />}

            {/*------------------List supplier Modal-------------------*/}
            <Modal
                show={listSupplierModal}
                onHide={(e) => setListSupplierModal(false)}
                closeOnOverlayClick={false}
                size="lg"
                className="animated fadeIn"
            >
                <Modal.Body>
                    <ListSupplier
                        title={popupTitle}
                        list={supplierToList}
                        onClose={(e) => setListSupplierModal(false)}
                    />
                </Modal.Body>
            </Modal>
            {/*---------------------------------------------------------*/}

            {/*------------------List supplier upload error Modal-------------------*/}
            <Modal
                show={errorModal}
                onHide={(e) => setErrorModal(false)}
                closeOnOverlayClick={false}
                size="lg"
            >
                <Modal.Body>
                    <Modal.Header closeButton>
                        <h4 className="modal-title">Error in uploaded sheet</h4>
                    </Modal.Header>

                    <Modal.Body>
                        {validationResults && validationResults.errors.length > 0 ? (
                            <FormRow style={{ paddingTop: "inherit" }}>
                                <table class="table table-sm table-bordered client-tble">
                                    <tbody>
                                        <tr>
                                            <th>Organisation</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>ABN</th>
                                            <th>Message</th>

                                        </tr>
                                        {validationResults.errors.map((item, index) => (
                                            <tr key={index}>
                                                <td> {item.organisation} </td>
                                                <td> {item.name}</td>
                                                <td> {item.email} </td>
                                                <td> {item.ab_number} </td>
                                                <td>
                                                    <span className="text-danger">
                                                        {item.message}
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </FormRow>

                        ) :
                            <div className="modal-header">
                                <span className="modal-title text-danger">No errors found!</span>
                            </div>
                        }
                    </Modal.Body>
                </Modal.Body>
            </Modal>
            {/*---------------------------------------------------------*/}

            {validationResults != null && (
                <>

                    {/*----------------------------------------------------------------------------------------*/}
                    <div className="row m-t-md font-bold">
                        <div className="col-lg-4  "  ></div>
                        <div className="col-lg-4 p-md"  > <span className="font-bold">
                            <div className="alert alert-success mb-4" role="alert">Uploading Summary</div>
                            <small>Click on the icon to view the list</small>
                            {
                                validationResults && (
                                    <>
                                        <table className="pendinglist table">
                                            <tr>
                                                <td className="no-top-border text-muted">Total in the sheet</td>
                                                <td className="no-top-border"><strong>{validationResults.totalSuppliers} </strong></td>
                                                <td className="no-top-border">
                                                    <Link onClick={(e) => handleShowSupplier('totalSuppliers')}>
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Duplicates found in Database</td>
                                                <td><strong>{validationResults.duplicateEntries}</strong></td>
                                                <td className="no-top-border">
                                                    <Link onClick={(e) => handleShowSupplier('duplicate_database')}>
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Duplicates found in Sheet</td>
                                                <td><strong>{duplicateSuppliersInSheet.length}</strong></td>
                                                <td className="no-top-border">
                                                    <Link onClick={(e) => handleShowSupplier('duplicate_in_sheet')}>
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Errors</td>
                                                <td><strong>{validationResults.errorCount} </strong></td>
                                                <td className="no-top-border">
                                                    <Link onClick={(e) => setErrorModal(true)}>
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Ready to Upload</td>
                                                <td><strong>{validationResults.readyToUploadCount} </strong></td>
                                                <td className="no-top-border">
                                                    <Link onClick={(e) => handleShowSupplier('readyToUpload')}>
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                            {validationResults.readyToUploadCount == 0 && (<><tr><td colSpan="2" className="align-center"></td></tr><span className="text-danger">No valid Suppliers found from the sheet to upload!</span></>)}
                                        </table>
                                    </>
                                )
                            }

                        </span>
                        </div>
                        <div className="col-lg-4 p-md"  > <span className="font-bold">   </span> </div>
                        <div className="col-lg-4 p-md"  > <span className="font-bold">    </span> </div>
                    </div>
                    {/* <div className="row m-t-md">

                        <div className="col-lg-5">
                            {validationResults.duplicateEntities.length != 0 &&
                                <div>
                                    <div className="alert alert-danger mb-4" role="alert">Suppliers that already exists in the database:</div>

                                    <table className="pendinglist table table-bordered">
                                        {validationResults.duplicateEntities.length != 0 ? (
                                            <>
                                                <thead>
                                                    <tr>
                                                        <th>Organisation</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>ABN</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {validationResults.duplicateEntities.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{item.organization}</td>
                                                                <td>{`${item.first_name} ${item.last_name}`}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item.ab_number}</td>

                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </>
                                        ) :
                                            <tr>
                                                <td colSpan={3} align='center'>
                                                    No duplicate Suppliers found.
                                                </td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            }
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5">

                            {validationResults.errors.length != 0 &&
                                <>
                                    <div className="alert alert-danger mb-4" role="alert">Missing mandatory fields: </div>
                                    <table className="pendinglist table table-bordered">
                                        {validationResults.errors.length != 0 ? (
                                            <>
                                                <thead>
                                                    <tr>
                                                        <th>Organisation</th>
                                                        <th>Name</th>
                                                        <th>Error</th>
                                                    </tr>
                                                </thead>
                                                {validationResults.errors.map((item, key) =>

                                                    <tr key={key}>
                                                        <td>{item.organisation}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.message}</td>
                                                    </tr>

                                                )}
                                            </>
                                        ) :
                                            <tr>
                                                <td colSpan={3} align='center'>
                                                    No missing mandatory missing fields.
                                                </td>
                                            </tr>
                                        }
                                    </table>
                                </>
                            }
                        </div>
                    </div> */}
                </>
            )}
        </>
    )
}

export default SupplierValidation;