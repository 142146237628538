import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router";
import Modal from 'react-bootstrap/Modal';
import { browserHistory } from "react-router";
import { HistoryButton, AddButton } from "../../../../common/containers/button";
import CreateTfInvvoice from "../../../../supply-chain/components/transactions/supplierUpload/tfAddSupplierInvoice";
import { CurrencyLabel } from "../../../../common/inputs";

// import BulkApprove from "../bulkApprove";
// import BulkReject from "../bulkReject/index";
import BulkApproveSupplier from "../../../../supply-chain/components/transactions/bulkApprove/bulkApproveSupplier";
import transaction_services from "../../../../supply-chain/services/transaction_service";
import auth from "../../../../../services/authService";
import EntityContext from "../../../../../context/entityContext";
import RoleChecker from "../../../../../utils/roleChecker";
import EditableField from '../../../../common/tables/EditableField';
import Table from '../../../components/table/index';
import NoAccess from "../../../widgets/noAccess";
import * as moment from "moment";
class SupplierTransactionList extends Component {


  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  state = {
    selectedTab: this.props.selectedTab ? this.props.selectedTab : "all",
    ajaxRequest: false,
    selectedRows: [],
    categories: [],
    open: false,
    openCustomer: false,
    approvebuttonClass: "btn btn-primary  hidden",
    declinebuttonClass: "btn btn-danger  hidden",
    bulkapprove: false,
    bulkreject: false,
    selected: {},
    roleType: "Sale",
    tabs: [],
    hideCheckbox: false,
    productType: this.props.productType ? this.props.productType : ""
  };

  componentDidMount() {
    const { constants, sc_invoice_status } = auth.getCurrentUser();
    this.context.handleProductChange(constants.PRODUCTS.TRADEFINANCE);
    if (!this.props.selectedTab) {
      this.setState({ selectedTab: "all" })
    }
    if (sc_invoice_status['awaiting_supplier_update'] == this.state.selectedTab)
      this.setState({ hideCheckbox: false })
    else
      this.setState({ hideCheckbox: true })
  }
  componentDidUpdate() {

  }
  //----Go back to prospect on cancel click-------------
  handleCancel = () => {
    browserHistory.push("/client/home");
  };

  // Callback function to the Action Table.
  handleRowSelection = selectedRows => {
    this.setState({ selectedRows })
    let newSelected = {};
    selectedRows.map(item => {
      newSelected[item.key] = item.checked;
    })

    this.setState({ selected: newSelected });
    const { constants, sc_invoice_status } = auth.getCurrentUser();

    if (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.TFSUPPLIER) && this.state.selectedTab == sc_invoice_status["awaiting_supplier_update"]) {

      this.setState({
        approvebuttonClass: "btn btn-primary btn-sm ml-3 mt-1",
        declinebuttonClass: "btn btn-danger btn-sm mt-1",
      });


    }

  }
  onCloseModalApprove = () => {
    this.setState({ bulkapprove: false });
  };
  onCloseModalReject = () => {
    this.setState({ bulkreject: false });
  };
  BulkApprove = async () => {
    this.setState({ bulkapprove: true });
  };
  BulkReject = async () => {
    this.setState({ bulkreject: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onOpenModalCustomer = () => {

    this.setState({ openCustomer: true });
  };
  onCloseModalCustomer = () => {
    this.setState({ openCustomer: false });
  };



  setTabs = tabs => {

    this.setState({ tabs });
  }

  handleChange = (status) => {

    const { sc_invoice_status } = auth.getCurrentUser();
    this.setState({ selectedTab: status }, () => {
      if (sc_invoice_status['awaiting_supplier_update'] == this.state.selectedTab) {
        this.setState({ hideCheckbox: false })
      } else {
        this.setState({ hideCheckbox: true })
      }
    });
    this.setState({ selectedRows: [], selected: {} });
    this.tableRef.current.clearSelection();
  }

  render() {

    // Instance for Date parser utility function.
    // @params => service, key
    const { entityDetails } = this.context;
    const { TextField, DateField } = EditableField(transaction_services.getInvoice, 'a_guid');
    const { constants, sc_invoice_status } = auth.getCurrentUser();

    const cols = [
      {
        Header: "Invoice Number",
        accessor: "inv_number",
        headerStyle: { textAlign: "left" },
        minWidth: 60,

      },

      {
        Header: "Customer",
        accessor: "customer_name",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        show: (RoleChecker.check(entityDetails.products, constants.ROLES.TFSUPPLIER)) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {(row.original.customer_name)}</span>,
      },
      {
        Header: "Amount",
        accessor: "inv_amount",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {<CurrencyLabel value={row.original.inv_amount}></CurrencyLabel>}</span>
      },
      {
        Header: "Date",
        accessor: "trans_date",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {moment(row.original.trans_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
      },
      {
        Header: "Due Date",
        accessor: "inv_due_date",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {moment(row.original.inv_due_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
      },
      {
        Header: "Early Payment Date",
        accessor: "inv_early_payment_date",
        headerStyle: { textAlign: "left" },
        minWidth: 60,
        show:  (this.state.selectedTab == sc_invoice_status["rejected"]) ? false : true,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {row.original.inv_early_payment_date ? moment(row.original.inv_early_payment_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}</span>
      },
      {
        Header: "Finance Cost",
        accessor: "finance_cost",
        minWidth: 50,
        headerStyle: { textAlign: "left" },
        show:  (this.state.selectedTab == "open" ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.finance_cost} /></span>
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        minWidth: 50,
        headerStyle: { textAlign: "left" },
        show:  (this.state.selectedTab == "open" ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.amount_paid} /></span>
      },
      {
        Header: "Balance",
        accessor: "balance",
        minWidth: 50,
        headerStyle: { textAlign: "left" },
        show:  (this.state.selectedTab == "open" ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.balance} /></span>
      },
      {
        Header: "Action",
        accessor: "action",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        //show: (!RoleChecker.check(products, constants.ROLES.CUSTOMER)) ? true : false,
        Cell: row => {
          return (
            <>
              <Link className="action-icons" style={{ color: "grey" }} to={{ pathname: (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.TFSUPPLIER) || RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER)) ? `/client/tf/invoice/details` : `/tf-invoice/details`, search: "?inv_guid=" + row.original.trans_guid + "&tab=" + this.state.selectedTab+ "&type=sale" }} >
                <HistoryButton className="fa fa-file-text-o"></HistoryButton>
              </Link>
            </>
          )
        }
      }

    ];

    const RightPanel = () => (
      <>
        {((this.state.selectedTab == sc_invoice_status['awaiting_supplier_update'] && RoleChecker.check(this.context.entityDetails.products, constants.ROLES.TFSUPPLIER)) && (this.state.selectedRows.find(rows => rows.checked == true) != undefined)) && (
          <>
            <AddButton style="btn btn-primary " label="Approve" onClick={this.BulkApprove} /> &nbsp;
          </>
        )}
        <AddButton style="btn btn-primary" onClick={this.onOpenModal} label="Add New"></AddButton>
        
      </>
    )

    const Filters = () => (

      this.state.tabs.length > 0 && (
        <div className="row justify-content-left   ">
          <div className="col-sm-12 col-lg-12">
            <div className="dropdown dash-dropdown">
              <button className="btn btn-outline-secondary dropdown-toggle pr-sm-4 pr-3 p-2 w-100 text-left" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.state.tabs.map(obj => <>{this.state.selectedTab == obj.value ? obj.label : ""}</>)}
              </button>

              <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                {this.state.tabs.map(obj => <a className={`dropdown-item ${this.state.selectedTab == obj.value ? 'active' : ''}`} onClick={e => this.handleChange(obj.value)} href="#" >{obj.label}</a>)}
              </div>

            </div>
          </div>
        </div>

      )

    )
    return (
      <>
        <Helmet>
          <title>Invoice List | {global.config.title}</title>
        </Helmet>

        {RoleChecker.check(this.context.entityDetails.products, constants.ROLES.TFSUPPLIER) ? (
          <>




            <div className="card overflow-visible">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-6"><h6>{ ( this.state.selectedTab == sc_invoice_status['overdue_funds_partly_collected'] || this.state.selectedTab == sc_invoice_status['funds_overdue'] ) ? 'Due / Overdue Invoices' : 'Invoices' }</h6></div>
                  <div className="col-lg-6">
                    <Link className="btn btn-light pull-right text-secondary ml-2" to={'/client/tf/dashboard'}><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
                  </div>
                </div>
              </div>
              <div className="card-body">

                <div className="row">
                  <div className="col-lg-12">


                    <Table
                      ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
                      service={transaction_services.getInvoice}
                      cols={cols}
                      defaultTab={this.state.selectedTab ? this.state.selectedTab : "all" }
                      onSelectTab={val => { this.setState({ selectedTab: val, selectedRows: [], selected: {} }, () => { this.handleChange() }); }}
                      uid={'trans_guid'}
                      onSelectRow={this.handleRowSelection}
                      searchService={transaction_services.searchTraderInvoice}
                      reqParams={{ roleType: this.state.roleType ? this.state.roleType : "", product: constants.PRODUCTS.TRADEFINANCE, "open" : true }}
                      ibox={true}
                      headerTabs={true}
                      setTabs={this.setTabs}
                      hideCheckbox={this.state.hideCheckbox}
                      additionalFilter={<Filters />}
                      tableRightPanel={<RightPanel />}
                      getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                          return {
                            style: {
                              color: rowInfo.row._original.is_overdue == 1 && 'red',
                            }
                          }
                        } else {
                          return {}
                        }
                      }}
                    />


                  </div>
                </div>
              </div>

            </div></>
        ) : <NoAccess heading="Supply Chain Transactions" subHeading=""></NoAccess>}




        <Modal
          show={this.state.open}
          onHide={this.onCloseModal}
          className="animated bounceInRight"
        >

          <CreateTfInvvoice
            a_guid={entityDetails.a_guid}
            page="common"
            onCloseModal={() => {
              this.onCloseModal();
              this.tableRef.current.reloadData();
            }}
          />

        </Modal>



        {/* <BulkApprove
          invoices={this.state.selected}
          onClose={this.onCloseModalApprove}
          onHandleReload={() => {
            this.onCloseModalApprove();
            this.tableRef.current.reloadData();
            this.tableRef.current.clearSelection();
            this.setState({ selectedRows: [], selected: {} });
          }}
          open={this.state.bulkapprove}
          trans_status={this.state.selectedTab}
          entity_id={entityDetails.a_guid}
        ></BulkApprove>

        <BulkReject
          invoices={this.state.selected}
          onClose={this.onCloseModalReject}
          onHandleReload={() => {
            this.onCloseModalReject();
            this.tableRef.current.reloadData();
            this.tableRef.current.clearSelection();
            this.setState({ selectedRows: [], selected: {} });
          }}
          open={this.state.bulkreject}
          trans_status={this.state.selectedTab}
        ></BulkReject>
       */}
       {this.state.bulkapprove === true &&
          <BulkApproveSupplier
            trans_status={this.state.selectedTab}
            open={this.state.bulkapprove}
            invoices={this.state.selected}
            rows = {this.state.selectedRows}
            onClose={this.onCloseModalApprove}
            entity_id={entityDetails.a_guid}
            onHandleReload={() => {
              this.onCloseModalApprove();
              this.tableRef.current.reloadData();
              this.tableRef.current.clearSelection();
              this.setState({ selectedRows: [], selected: {} });
            }}
            product={constants.PRODUCTS.TRADEFINANCE}
          />
        }
      </>
    );
  }
}
export default SupplierTransactionList;