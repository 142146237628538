import React from "react";

const ArraySort = (arr, key,order='ASC') => {
    let sortedArray = [];
    if(order == 'ASC'){
        sortedArray = arr.sort(function (a, b) {
            return a[key] < b[key]
                ? -1
                : a[key] > b[key]
                    ? 1
                    : 0;
        });
    } else {
        sortedArray = arr.sort(function (a, b) {
            return a[key] > b[key]
                ? -1
                : a[key] < b[key]
                    ? 1
                    : 0;
        });
    }
    
    return sortedArray;
};

export default ArraySort;