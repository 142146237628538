import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { GeneralButton } from "../../../../common/containers/button";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import rate_services from "../../../../supply-chain/services/rate_services";
import useApi from "../../../../../hooks/useApi";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";
import { Link } from "react-router";
import { FormRow } from "../../../../common/containers/formContainer";
import { Picker } from "../../../../common/inputs";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const Invite = () => {
  const { data, setData } = useContext(SupplierProgramContext);

  const inviteTemplate = useApi(rate_services.inviteSupplierTemplate);
  const invite_supplier = useApi(rate_services.invite_supplier);

  const [inviteModel, setInviteModel] = useState(false);
  const [invitationMsg, setInvitationMsg] = useState(null);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [sendStatus, setSendStatus] = useState({});

  useEffect(() => {
    fetchInvitationMsg();
  }, []);
  const fetchInvitationMsg = async () => {
    const params = {
      program_name: data?.program_name || "",
      no_of_days: data?.no_of_days || 0,
      discount_percentage: data?.discount_percentage || 0,
      discount_rate: data?.discount_rate || 0,
      program_limit: data?.program_limit || 0,
    };

    const response = await inviteTemplate.request(params);
    setInvitationMsg(response.data.content);
    setData((prevData) => ({
      ...prevData,
      content: response.data.content,
    }));
  };

  const openModal = () => {
    setInviteModel(true);
  };

  const handleInvitation = (name, email, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Are you sure ...?</h4>
            <p>Do you want to invite this supplier</p>
            <button
              className="btn btn-white"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>

            <button
              className="btn btn-primary ml-2"
              onClick={async () => {
                onClose();
                sendInvitationMsg(name, email, index);
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  };

  const sendInvitationMsg = async (name, email, index) => {
    const dataValue = {
      supplier_name: name,
      emailToSend: email,
      program_name: data?.program_name,
      no_of_days: data?.no_of_days ? data?.no_of_days : 0,
      discount_rate: data?.discount_rate,
      discount_percentage: data?.discount_percentage
        ? data?.discount_percentage
        : 0,
      program_limit: data.program_limit,
    };
    const response = await invite_supplier.request(dataValue);

    if (
      response.data.success === true &&
      response.data.message === "sent succesfully"
    ) {
      setSendStatus((prevState) => ({
        ...prevState,
        [index]: true,
      }));
      toast.success("Mail has been sent successfully");
    } else {
      toast.error("Failed o send...!");
    }
  };

  const handleChangePicker = (option, index) => {
    const newSelectedEmails = [...selectedEmails];
    newSelectedEmails[index] = option;
    setSelectedEmails(newSelectedEmails);

    const newData = [...data?.supplierProgramMapping];
    newData[index].emailToSend = option.value;
    setData({ ...data, supplierProgramMapping: newData });
  };

  return (
    <>
      <div>
        <p className="mt-3" style={{ textAlign: "center" }}>
          <span style={{ fontWeight: "400" }}>
            Please make sure the correct email adresses are selected for each
            supplier. When you are ready, click 'Send Invites'.
          </span>
          <p>
            <span style={{ fontWeight: "400" }}>
              This will automatically send the following suppliers invitations
              to your new supplier discount program.
            </span>
          </p>

          <p>
            <span style={{ fontWeight: "400" }}>
              <Link className="editInvite" onClick={() => openModal()}>
                View/edit
              </Link>{" "}
              the email template that will be sent to suppliers inviting them to
              join this program.
            </span>
          </p>
        </p>
      </div>

      <div className="mt-4">
        {data?.supplierProgramMapping.map((item, index) => {
          if (item.checked === true) {
            const emails = [];
            for (const allmails of item?.team_members) {
              const emailObject = {
                label: allmails?.email,
                value: allmails?.email,
                is_primary:
                  allmails.is_primary === "yes" ? allmails?.email : "",
              };

              emails.push(emailObject);
            }

            const newData = [...data?.supplierProgramMapping];
            newData[index].emailToSend = emails.find(
              (email) => email.is_primary
            );
            const emailExists = emails.length > 0;
            return (
              <FormRow>
                <div
                  className="col-md-4 font-bold mt-3"
                  style={{ fontSize: "16px" }}
                >
                  {item.supplier_name}
                </div>
                <div className="col-md-6 mt-3">
                  <Picker
                    items={emails}
                    selectedItem={
                      selectedEmails[index]
                        ? selectedEmails[index]
                        : emails.find((email) => email.is_primary)
                    }
                    onSelectItem={(option) => {
                      handleChangePicker(option, index);
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <GeneralButton
                    onClick={() =>
                      emailExists &&
                      handleInvitation(
                        item.supplier_name,
                        selectedEmails[index]
                          ? selectedEmails[index].value
                          : emails.find((email) => email.is_primary)?.value,
                        index
                      )
                    }
                    className="btn btn-sm btn-outline-secondary mt-3"
                    label="Send"
                    name="Send"
                    disabled={!emailExists || sendStatus[index]}
                    type="button"
                  ></GeneralButton>
                </div>
              </FormRow>
            );
          }
        })}
      </div>
      <div>
        <Modal
          show={inviteModel}
          onHide={() => setInviteModel(false)}
          size="lg"
          centered
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Invite Supplier</h4>
          </Modal.Header>
          <Modal.Body>
            <JoditEditor
              value={invitationMsg}
              onChange={(value) => {
                setData((prevData) => ({
                  ...prevData,
                  content: value,
                }));

                setInvitationMsg(value);
              }}
            />
          </Modal.Body>

          <Modal.Footer>
            <div className="row text-right">
              <button
                type="button"
                className="btn btn-light  mr-2"
                data-dismiss="modal"
                onClick={(e) => setInviteModel(false)}
              >
                Close
              </button>

              {/* <button
                type="submit"
                className="btn btn-primary  ml-2"
                onClick={(e) => sendInvitationMsg()}
              >
                Send Invitation
              </button> */}
            </div>
          </Modal.Footer>

          <div style={{ width: 700, height: 20 }}></div>
        </Modal>
      </div>
    </>
  );
};

export default Invite;
