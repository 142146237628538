import React, { useState, useRef } from "react";
import XLSX from "xlsx";
import LoadingSpinner from "../../loaders/loadingSpinner";

import { GeneralButton } from "../index";

function ExcelFileUploader({ handleUploadItems, label = null, customButton = false }) {
  let filesArray = [];
  let excelFile = {};

  const [loader, setLoader] = useState(false);
  const inputFile = useRef(null);

  const spreadSheetFileTypes = ["xls", "xlsx", "ods"]; // Allowed spreadsheet files to upload.

  const handleFileChange = async e => {

    setLoader(true);

    Object.keys(e.target.files).map((key, value) => {
      filesArray.push({
        row: null,
        name: e.target.files[key].name,
        status: "queue",
        obj: e.target.files[key],
      });
    });

    excelFile = filesArray.find((file) =>
      spreadSheetFileTypes.includes(file.name.split(".").pop())
    );

    if (excelFile) {
      /* Boilerplate to set up FileReader */
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          bookVBA: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws);

        /* Update state */
        handleUploadItems(
          data.filter(row => typeof Object.values(row)[0] === 'string' ? !Object.values(row)[0].includes('*** Remove') : true),
          filesArray
        );
        setLoader(false);
      };

      if (rABS) {
        reader.readAsBinaryString(excelFile.obj);
      } else {
        reader.readAsArrayBuffer(excelFile.obj);
      }
    } else {
      handleUploadItems([], filesArray);
      setLoader(false);
    }

    var input = document.getElementsByTagName('input')[0];
    input.value = null;
  };

  const handleOnDrag = e => {
    e.preventDefault();
  }

  const handleOnDrop = e => {
    handleFileChange({ target: { files : e.dataTransfer.files } });
    e.preventDefault();
  }

  return (
    customButton === false ? (
      <>
        <div className="col-lg-12 b-dashed-2 bg-light rounded-lg py-5" onDrop={handleOnDrop} onDragOver={handleOnDrag}>
          <div style={{ position: "relative", top: "50%", left: "50%", zIndex: "100" }}>
            {loader == true && <LoadingSpinner />}
          </div>
          <div class="d-block text-center">
            <label htmlFor="file" className="btn btn-secondary bulk_upload_btn">
              <i className="fa fa-folder"></i>&nbsp;
              {label != null ? label : "Browse File"}
            </label>
          </div>
        </div>

        <input ref={inputFile} type="file" className="form-control" id="file" onChange={handleFileChange} style={{ visibility: "hidden" }} multiple />
      </>
    ) :
      (
        <>
          <div style={{ position: "relative", top: "50%", left: "50%", zIndex: "100" }}>
            {loader == true && <LoadingSpinner />}
          </div>
          <GeneralButton onClick={e => inputFile.current.click()} className="btn btn-secondary btn-sm bulk_upload_btn" faIcon="fa fa-upload" name={label ? label : "Bulk Upload"}></GeneralButton>
          <input ref={inputFile} type="file" className="form-control" id="file" onChange={handleFileChange} style={{ visibility: "hidden" }} multiple />
        </>
      )
  );
}

export default ExcelFileUploader;
