import React, { Component } from "react";
import * as moment from "moment";
import dateParser from "../../src/utils/excelDateParser";

import coreService from "../services/coreService";
import authService from "../services/authService";
import entity from "../services/entityService";

const TransactionContext = React.createContext();
TransactionContext.displayName = "InvTransactionContext";

class TransactionProvider extends Component {
  // Context state
  state = {
    transaction: null,
    invoice: [],
    participants: [],
    debtors: [],
    additionalFee: [],
    defaultFees: {},
    uploadedFiles: [],
    uploadedFileData:[],
    invoiceFileAttachments: []
  };

  componentDidMount() {
    this.fetchDebtors();
  }

  //--------------------------Getting list of debtors------------------------//
  fetchDebtors = async (client = null) => {
    if(this.props.children.props.client !== undefined || client !== null){
      const { constants } = authService.getCurrentUser();
      const { data } = await entity.getEntityDebtorsList(
        client === null ? this.props.children.props.client : client,
        constants.PRODUCTS.INVOICE
      );
      // const { data } = await coreService.getDebtorsList({ category: constants.CATEGORY.DEBTOR, bp: 'a304e321-6e50-11e8-80eb-021bf1c8a51d' /* bp: transaction.bp */ });
      this.setState({ debtors: data });
    }
  };

  //--------------------------Handling Invoice Data--------------------------//
  // Method to update state
  setInvoice = (invoice) => {
    this.setState({ invoice });
  };

  setDefaultFeeOnContext = (defaultFees) => {
    this.setState({ defaultFees });
  };

  deleteInvoice = (index) => {
    let invoice = [...this.state.invoice];
    invoice = invoice.filter((obj) => obj.index !== index);

    // Resetting Index value on each rows
    let updatedList = invoice.map((item, index) => {
      item.index = index;
      return item;
    });

    this.setState({ invoice: updatedList });
  };

  updateInvoice = (index, invoice) => {
    let updatedInv = [...this.state.invoice];
    updatedInv[index] = invoice;
    updatedInv[index]["error"] = false;
    updatedInv[index]["errorMsg"] = "";
    this.setState({ invoice: updatedInv });
  };

  // -------------------Handling Additional Fees----------------------//
  addAdditionalFee = (feeData) => {
    let additionalFee = [...this.state.additionalFee];
    additionalFee.push({
      index: additionalFee.length,
      fee_name: feeData.fee_name,
      fee_value: feeData.fee_value,
    });
    this.setState({ additionalFee });
  };

  // -------------------Update Additional Fees----------------------//
  updateAdditionalFee = (feeData) => {
    let additionalFee = [...this.state.additionalFee];
    additionalFee[feeData.index] = feeData;
    this.setState({ additionalFee });
  };

  // -------------------Delete Additional Fees----------------------//
  deleteAdditionalFee = (index) => {
    this.setState({
      additionalFee: this.state.additionalFee.filter(
        (obj) => obj.index != index
      ),
    });
  };
  // -----------------------------------------------------------------//

  //--------------------------Handling Transaction Data----------------------//
  setTransaction = (transaction) => {
    this.setState({ transaction });
  };
  //-------------------------------------------------------------------------//
  setUploadedFiles=(files) => {
    this.setState(files);
  }

  //---------------------- handle invoice upload ----------------------------//
  handleUploadItems = (excelData, filesList = null) => {
    const { ParseDate } = dateParser(); // Instance for utility library.
    let invoice = [];
    
    excelData.map(
      ({
        Invoice_Number,
        Debtor,
        Invoice_Date,
        Invoice_Value,
        Due_Date,
        Insured,
        Agreement_Amount,
        Advance_Pctg,
        Advance_Amt,
        //Advance_Date,
        //Royalty_Pctg,
        Insurance_Pctg,
      }) => {

        // Finding debtor info
        let debtorInfo = this.state.debtors.find((obj) => obj.label == Debtor); // Match with Debtor Organisation
        if(!debtorInfo && Debtor)
          debtorInfo = this.state.debtors.find((obj) => obj.debtor_id == Debtor); // Match with Debtor ID

        invoice.push({
          index: invoice.length,
          inv_number: String(Invoice_Number),
          inv_date: Invoice_Date !== undefined ? ParseDate(Invoice_Date) : null,
          inv_amount: Invoice_Value,
          inv_due_date: Due_Date !== undefined ? ParseDate(Due_Date) : null,
          insured: Insured,
          inv_insured: Insured !== undefined ? Insured.toLowerCase() : "no",
          inv_chargeable_amt: Agreement_Amount !== undefined ? Agreement_Amount : Invoice_Value,
          inv_adv_percent: Advance_Pctg !== undefined ? Advance_Pctg : 0,
          inv_adv_amount: Advance_Pctg !== undefined ? parseFloat((Agreement_Amount * Advance_Pctg/100)).toFixed(2) : 0,
          //inv_advance_date: ParseDate(Advance_Date),
          //inv_royalty_percent: Royalty_Pctg,
          inv_insurance_percent: Insurance_Pctg !== undefined ? Insurance_Pctg : null,
          debtor_name_in_sheet: Debtor,
          
          debtor_name: debtorInfo ? debtorInfo.label : false,
          inv_debtor_id: debtorInfo ? debtorInfo.value : null,

          disburse_now: false //!Advance_Amt > 0 || Advance_Date === null || Advance_Date === "" ? false : true,
        });
      }
    );

    this.setState({ invoice });

    if(filesList){
      let list = [];
      filesList.map((fileItem) => {
          list.push(fileItem);
      });
      this.setState({ uploadedFiles: list });
      this.handleFileReader(list);
    }
  };
  //-------------------------------------------------------------------------//

  handleFileReader = (inputFiles) => {
    /** --------------- Reading local files in JavaScript -------------- */
    let files = inputFiles.map(fileItem => fileItem.obj);
    let fileslist = [];
    files.map(fileItem => {
      let reader = new FileReader();
      reader.readAsDataURL(fileItem);
      reader.onload = e => {
        fileslist.push({
          name: fileItem.name,
          file: e.target.result,
          type: fileItem.type,
          size: fileItem.size
        });
      };
    });
    this.setState({uploadedFileData:fileslist})
    return fileslist;
    /** ----------------------------------------------------------------- */
  };

  //----------------------------Populate participants-------------------------//
  addParticipant = (participants) => {
    this.setState({ participants });
  };
  //-------------------------------------------------------------------------//

  //----------------------------Update participantion details-------------------------//
  updateParticipant = (index, participants) => {
    let updatedList = [...this.state.participants];
    updatedList[index] = participants;
    this.setState({ participants: updatedList });
  };
  //-------------------------------------------------------------------------//
  removeUploadedSheet = fileName => {
    let updatedFilesList = this.state.uploadedFiles.filter(file => file.name !== fileName);
    this.setState({ uploadedFiles: updatedFilesList, invoice: [] });
  }

  updateInvoiceFileAttachments = (invoiceFileAttachments) => {
    this.setState({ 
      invoiceFileAttachments
   })
  }

  render() {
    const { children } = this.props;
    const {
      invoice,
      defaultFees,
      transaction,
      participants,
      debtors,
      additionalFee,
      uploadedFiles,
      uploadedFileData,
      invoiceFileAttachments
    } = this.state;
    const {
      setInvoice,
      setDefaultFeeOnContext,
      deleteInvoice,
      updateInvoice,
      setTransaction,
      handleUploadItems,
      addParticipant,
      updateParticipant,
      addAdditionalFee,
      updateAdditionalFee,
      deleteAdditionalFee,
      fetchDebtors,
      removeUploadedSheet,
      setUploadedFiles,
      updateInvoiceFileAttachments
    } = this;

    return (
      <TransactionContext.Provider
        value={{
          invoice,
          uploadedFiles,
          setInvoice,
          defaultFees,
          setDefaultFeeOnContext,
          deleteInvoice,
          updateInvoice,
          additionalFee,
          addAdditionalFee,
          updateAdditionalFee,
          deleteAdditionalFee,
          transaction,
          setTransaction,
          handleUploadItems,
          participants,
          addParticipant,
          updateParticipant,
          debtors,
          fetchDebtors,
          removeUploadedSheet,
          uploadedFileData,
          setUploadedFiles,
          invoiceFileAttachments,
          updateInvoiceFileAttachments
        }}
      >
        {children}
      </TransactionContext.Provider>
    );
  }
}

export default TransactionContext;

export { TransactionProvider };
