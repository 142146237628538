import React, { useEffect, useState, useContext } from "react"
import { SecondaryCard } from "../../components/cards";
import productShortName from "../../../../utils/GetProductShortName";
import useIsMounted from "../../../../hooks/useIsMounted";
import { CurrencyLabel } from "../../../common/inputs";
import useApi from '../../../../hooks/useApi';
import widgetService from "../../../../services/invTransactions";
import EntityContext from "../../../../context/entityContext";

export default function AwaitingPayments(){

    const isMounted = useIsMounted();
    const { selectedBusinessType, selProduct, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);

    //------------Api Services-----------------------------------
    const approvedInvoicesApi = useApi(widgetService.approvedInvoices);

    const fetchApprovedInvoices = async () => {
        const response = await approvedInvoicesApi.request({ entity_id: entityDetails.a_guid, selectedBusinessType });
        if(response && isMounted()){
            setData({
                topLabel: "Approved Awaiting Payment",
                topValue: <CurrencyLabel decimal={2} value={response.data.total_chargeable_amt} />,
                leftLabel: response.data.isPrimary ? "Debtors" : "Clients",
                leftValue: response.data.isPrimary ? response.data.debtor_count : response.data.client_count,
                rightLabel: "Invoices",
                rightValue: response.data.invoice_count,
                bottomRightLabel: "",
                primaryBtnRoute: `/client/${productShortName(selProduct)}/sale-invoices?status=approved`
            })
        }
    }

    useEffect(() => {
        fetchApprovedInvoices();
    }, [selectedBusinessType, entityDetails])

    return(
        <>
            <SecondaryCard
                loading={approvedInvoicesApi.loading}
                data={data}
                qrystring={true}
            />
        </>
    )
}