import client from "../../../services/serviceClient";


// Service to fetch loan list
const entityLoanDetails = (params, status) =>
  client.post(`/loans/loan/entity-loan-details`, { ...params, status });

// Service to fetch loan details
// Input loan guid
const getLoanDetails = (params) =>
  client.post(`/loans/loan/loan-details`, { ...params });

// Service to generate loan schedule
const generateSchedule = (params) =>
  client.post(`/loans/loan/generate-loan-schedule`, { ...params });

// Service to generate loan reference Number
const generateLoanReference = (params) =>
  client.post(`/loans/loan/loan-reference`, { ...params });

// Service to create loan
const createLoan = (params) =>
  client.post(`/loans/loan/create-loan`, { ...params });

// Service to create loan fix fees
const saveFixFees = (params) =>
  client.post(`/loans/loan/create-fix-fees`, { ...params });

// Service to delete loan fix fees
const deleteLoanFees = (params) =>
  client.post(`/loans/loan/delete-loan-fees`, { ...params });

// Service to save loan schedule
const saveLoan = (params) =>
  client.post(`/loans/loan/save-loan`, { ...params });

// Service to move a loan to openStatus
const openLoan = (params) =>
  client.post(`/loans/loan/open-loan`, { ...params });

// Service to save loan payments
const saveLoanPayment = (params) =>
  client.post(`/loans/loan/save-loan-payment`, { ...params });

// Service to fetch loan schedule list
const getLoanSchedule = (params) =>
  client.post(`/loans/loan/loan-schedule`, { ...params });

// Service to fetch loan summary
const getLoanSummary = (params) =>
  client.post(`/loans/loan/loan-summary`, { ...params });

// Service to fetch account overview
const getAccountsOverview = (params) =>
  client.post(`/loans/loan/accounts-overview`, { ...params });

// Service to fetch loan overview
const getLoanOverview = (params) =>
  client.post(`/loans/loan/loan-overview`, { ...params });

// Service to fetch loan amount overview
const getLoanAmountOverview = (params) =>
  client.post(`/loans/loan/loan-amount-overview`, { ...params });

// Service to fetch loan reference number -- auto suggestion 
const KeyWordAutoSuggestionsLoan = (params) =>
  client.post(`/loans/loan/key-word-auto-suggestions-loan`, { ...params });

// Service to fetch loan report
const getLoanReport = (params) =>
  client.post(`/loans/report/transaction-report`, { ...params });

// Service to fetch loan report
const getLoanScheduleReport = (params) =>
  client.post(`/loans/report/schedule-report`, { ...params });

// Service to fetch payment overview for dashboard
const getPaymentOverview = (params) =>
  client.post(`/loans/loan/payment-overview`, { ...params });

// service to fetch PointTime Report
const PointTimeReport = params =>
  client.post(`/loans/report/point-time-report`, { ...params }
  );

// service to update loan client settings
const AddLoanSettings = params =>
  client.post(`/loans/settings/add-settings`, { ...params }
  );

// service to fetch loan client settings
const ListSettings = params =>
  client.post(`/loans/settings/list-client-settings`, { ...params }
  );

// service to fetch loan summary and balance of a client
const FundSummary = params =>
  client.post(`/loans/loan/fund-summary`, { ...params }
  );

// service to allocate amount and reschedule - when excess amount paid allocate  
//  by principle amount or reduce time
const allocateReschedulePayment = params =>
  client.post(`/loans/loan/allocate-reschedule-payment`, { ...params }
  );
const saveReschedule = params =>
client.post(`/loans/loan/save-reschedule`, { ...params }
);

const showRescheduleDetail = params =>
client.post(`/loans/loan/show-reschedule-detail`, { ...params }
);

const CheckWorkingCapitalLimit = params =>
client.post(`loans/loan/check-working-capital-limit`, { ...params }
);

//Save a draft loan
const CreateDraftLoan = params =>
client.post(`loans/loan/create-draft-loan`, { ...params }
);

//Save client ref_no and generate schedule
const SaveReferenceGenerateSchedule = params =>
client.post(`loans/loan/save-reference-generate-schedule`, { ...params }
);

//print loan summary and schedule as pdf
const printScheduleSummary = params =>
client.post(`loans/loan/print-schedule`, { ...params }
);

//To fetch loan payment balance and decription
const getPaymentBalance = params =>
client.post(`loans/loan/load-payment-balance`, { ...params }
);

//To check if the payment is final or not
const CheckIsFinalPayment = params =>
client.post(`loans/loan/check-is-final-payment`, { ...params }
);

//To get count of loan under a client
const GetLoanCount = params =>
client.post(`loans/loan/get-loan-count`, { ...params }
);

//To export loan payment schedule
const ExportPaymentSchedule  = params =>
client.post(`loans/report/export-payment-schedule`, { ...params }
);

//To move a loan back to draft
const moveToDraft = params =>
client.post(`loans/loan/move-to-draft`, { ...params }
);

//To request a settlement from client side
const requestSettlement = params =>
client.post(`loans/loan/request-settlement`, { ...params }
);

// To list settlement request
const listSettlementRequest = params =>
client.post(`loans/loan/list-settlement-request`, { ...params }
);

// Approve settlement request
const approveSettlement = params =>
client.post(`loans/loan/approve-settlement`, { ...params }
);

// Reject settlement request
const rejectSettlement = params =>
client.post(`loans/loan/reject-settlement`, { ...params }
);

// Get count of pending settlement request
const pendingRequestCount = params =>
client.post(`loans/loan/pending-settlement-request`, { ...params }
);

// Get fetch payments under a schedule
const getPaymentDetails = params =>
client.post(`loans/loan/get-payment-details`, { ...params }
);

// Get fetch payments under a schedule
const deletePayment = params =>
client.post(`loans/loan/delete-payment`, { ...params }
);

// Get loan general data
const getLoanGeneralData = params =>
client.post(`loans/loan/get-loan-general-data`, { ...params }
);

// Get loan general data
const getAuditLog = params =>
client.post(`loans/report/get-audit-report`, { ...params }
);

// Delete loan 
const deleteLoan  = params =>
  client.post(`loans/loan/delete-loan`, { ...params }
);

//Update details of a loan
const updateLoanDetails  = params =>
client.post(`loans/loan/update-loan`, { ...params }
);

// Get loan general data
const getLoanAuditLog = params =>
client.post(`loans/report/get-loan-audit-report`, { ...params }
);

// To create copy of a loan
const CreateLoanCopy = params =>
client.post(`loans/loan/copy-loan`, { ...params }
);

// To update client reference number of a loan
const UpdateReferenceNumber= params =>
client.post(`loans/loan/update-reference-number`, { ...params }
);

// To list all payments received under a loan 
const ListPayments= params =>
client.post(`loans/loan/list-payments`, { ...params }
); 

// To get the detail of pending amount to payoff 
const GetPaysOffDetail= params =>
client.post(`loans/loan/get-pays-off-detail`, { ...params }
); 

//To pay off loan
const payOffLoan = params =>
client.post(`loans/loan/pay-off-loan`, { ...params }
); 


// Get list of client with pending payments based on date
const GetPendingPayments = params =>
client.post(`loans/direct-debit/get-pending-payments`, { ...params }
);

// Send payment request to payment gateway
const processPayment = params =>
client.post(`loans/direct-debit/process-payment`, { ...params }
);

// Get list of requested payments
const GetRequestedPaymentDetails = params =>
client.post(`loans/direct-debit/get-requested-payments-details`, { ...params }
);

// Get list of verify pending payments
const GetVerifyPendingList = params =>
client.post(`loans/direct-debit/get-verify-pending-list`, { ...params }
);

// Get loan details in direct debit
const GetLoanScheduleDetail = params =>
client.post(`loans/direct-debit/get-loan-schedule-detail`, { ...params }
);

// To update a schedule which is mark as paid
const markAsPaid = params =>
client.post(`loans/direct-debit/mark-schedule-paid`, { ...params }
);

const ddSettings = params =>
  client.post(`/loans/settings/enable-dd-settings`, { ...params }
  );

  const cancelRequest = params =>
  client.post(`loans/direct-debit/cancel-payment-request`, { ...params }
  );
const loansDropdown = params =>
  client.post(`/loans/loan/loans-dropdown`, { ...params }
  );
const loanStatement = params =>
  client.post(`/loans/loan/fetch-statement`, { ...params }
  );
const getHistory = params =>
  client.post(`loans/direct-debit/get-history`, { ...params }
  );
export default {
  entityLoanDetails,
  getLoanDetails,
  generateSchedule,
  generateLoanReference,
  createLoan,
  saveFixFees,
  deleteLoanFees,
  saveLoan,
  openLoan,
  saveLoanPayment,
  getLoanSchedule,
  getLoanSummary,
  getAccountsOverview,
  getLoanOverview,
  getLoanAmountOverview,
  KeyWordAutoSuggestionsLoan,
  getLoanReport,
  getLoanScheduleReport,
  getPaymentOverview,
  PointTimeReport,
  AddLoanSettings,
  ListSettings,
  FundSummary,
  allocateReschedulePayment,
  saveReschedule,
  showRescheduleDetail,
  CheckWorkingCapitalLimit,
  CreateDraftLoan,
  SaveReferenceGenerateSchedule,
  printScheduleSummary,
  getPaymentBalance,
  CheckIsFinalPayment,
  GetLoanCount,
  ExportPaymentSchedule,
  moveToDraft,
  requestSettlement,
  listSettlementRequest,
  approveSettlement,
  rejectSettlement,
  pendingRequestCount,
  getPaymentDetails,
  deletePayment,
  getLoanGeneralData,
  GetPendingPayments,
  processPayment,
  GetRequestedPaymentDetails,
  GetLoanScheduleDetail,
  deleteLoan,
  updateLoanDetails,
  getAuditLog,
  getLoanAuditLog,
  CreateLoanCopy,
  UpdateReferenceNumber,
  ListPayments,
  GetPaysOffDetail,
  payOffLoan,
  markAsPaid,
  GetVerifyPendingList,
  ddSettings,
  cancelRequest,
  loansDropdown,
  loanStatement,
  getHistory
};