import React from "react";

const PublicFooter = () => (
  <>
    <div className="col-md-6" />
    <div className="col-md-6 text-right">
      <small>FifoPay © {new Date().getFullYear()}. All Rights Reserved.</small>
    </div>
  </>
);

export default PublicFooter;
