import React, { useState, useContext } from "react";
import ValidateSheetFields from "../utils/validateSheetFields";
import Joi from "joi-browser";

const UploaderContext = React.createContext()

export function useUploader() {
    return useContext(UploaderContext)
}

export function ProspectProvider({ children }) {

    const [prospects, setProspects] = useState([]); // Context state to store the prospect rows
    const [uniqueProspects, setUniqueProspects] = useState([]); // After removing the duplicate prospects
    const [duplicateProspectInSheet, setduplicateProspectInSheet] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState([]); // Context state to store the list of uploaded invoices
    const [proceedNext, setProceedNext] = useState(true); // Enable or Disable 'Next' button within each component
    const [uploadedFileData, setUploadedFileData] = useState([]); // holds file uploaded file data

    const [hideNextBtn, sethideNextBtn] = useState(false);
    const [hidePreviousBtn, sethidePreviousBtn] = useState(false);
    const [nextLabel, setnextLabel] = useState("Next");
    const [prevLabel, setprevLabel] = useState("Previous");
    const [uploading, setUploading] = useState(0);
    
    const [country, setCountry] = useState();
    const [owner, setOwner] = useState();

    //------------------Validation Schema-----------------------------
    const schema = Joi.object() // Form validation schema object
    .keys({

        Organisation: Joi.string()
            .required()
            .error(() => {
                return { message: "Please enter a valid Organisation Name" };
            }),

        First_Name: Joi.string()
            .required()
            .error(() => {
                return { message: "Please enter a valid First Name" };
            }),

        Last_Name: Joi.string()
            .required()
            .error(() => {
                return { message: "Please enter a valid Last Name" };
            }),

        Email: Joi.string()
            .email()
            .error(() => {
                return { message: "Please enter a valid Email" };
            }),

        Phone: Joi.string()
            .regex(/^$|^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
            .error((errors) => { return { message: "Please enter a valid Phone Number" }; 
        }),

    })
    .unknown(true);

    //---------------------Handle Validation-------------------//
    const validate = (excelData) => {
        const options = { abortEarly: true };

        excelData.map( item => {
            const { error } = Joi.validate(item, schema, options);
            if(error){
                for (let errobj of error.details)
                    item.error = errobj.message
            }

            return item;
        })
    };
    //--------------------------------------------------------------//

    // Method to handle uploading file items
    const handleUploadItems = (excelData, filesList = null) => {
        
        const fieldsToValidate = ['Organisation', 'First_Name', 'Last_Name', 'Email', 'Phone'];
        const validateRes = ValidateSheetFields(excelData, fieldsToValidate);
        
        if(validateRes){
            filterProspects(excelData); // Finding duplicate prospects, and push it into new array.
            setProspects(excelData);
    
            if(filesList){
                let list = [...uploadedFiles];
                filesList.map((fileItem) => {
                    list.push(fileItem);
                });
                setUploadedFiles(list);
                handleFileReader(list);
            }
        }
    };

    const handleFileReader = (inputFiles) => {
        /** --------------- Reading local files in JavaScript -------------- */
        let files = inputFiles.map(fileItem => fileItem.obj);
        let fileslist = [];
        files.map(fileItem => {
          let reader = new FileReader();
          reader.readAsDataURL(fileItem);
          reader.onload = e => {
            fileslist.push({
              name: fileItem.name,
              file: e.target.result,
              type: fileItem.type,
              size: fileItem.size
            });
          };
        });
        setUploadedFileData(fileslist);
        return fileslist;
        /** ----------------------------------------------------------------- */
      };

    // Finding duplicate propsects
    const filterProspects = rows => {
        let prospectList = [];
        let duplicateProspects = [];

        rows.map((prospectItem, index) => {
            prospectItem.index = index;
            prospectItem.error = 
                prospectItem.error !== undefined ? prospectItem.error : null;
            
            if (prospectItem.Email) {
                if (prospectList.findIndex(obj => obj.Email == prospectItem.Email.toLowerCase()) === -1) {
                    prospectList.push({ ...prospectItem, Email: prospectItem.Email.toLowerCase() });
                } else {
                    // duplicateProspects += 1;
                    duplicateProspects.push({ ...prospectItem, Email: prospectItem.Email.toLowerCase() });
                }
            } else
                prospectList.push(prospectItem);
        });

        setduplicateProspectInSheet(duplicateProspects);
        setUniqueProspects(prospectList);
        validate(rows);
    }

    // This function will trigger from the Edit Prospects Modal
    const updateProspect = data => {
        let ProspectsList = [...prospects];
        data['error'] = null;
        ProspectsList[data.index] = data;
        setProspects(ProspectsList);
        filterProspects(ProspectsList);
    }

    const deleteProspect = index => {
        let ProspectsList = prospects.filter(obj => obj.index !== index);
        setProspects(ProspectsList);
        filterProspects(ProspectsList);
    }

    const removeUploadedSheet = fileName => {
        //let updatedFilesList = uploadedFiles.filter(file => file.name !== fileName);
        setUploadedFiles([]);
        setProspects([]);
        setUniqueProspects([]);
        setduplicateProspectInSheet(0);
    }

    const value = {
        prospects,
        handleUploadItems,
        updateProspect,
        deleteProspect,
        uploadedFiles,
        proceedNext,
        setProceedNext,
        hideNextBtn,
        sethideNextBtn,
        hidePreviousBtn,
        sethidePreviousBtn,
        nextLabel,
        setnextLabel,
        prevLabel,
        setprevLabel,
        uniqueProspects,
        setUniqueProspects,
        duplicateProspectInSheet,
        uploading,
        setUploading,
        country,
        setCountry,
        owner,
        setOwner,
        removeUploadedSheet,
        uploadedFileData,
        setUploadedFileData
    }

    return <UploaderContext.Provider value={value}>{children}</UploaderContext.Provider>
}