import React, { Component } from "react";
import Error from './layout/Error';

import coreService from "../../services/coreService";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    if (process.env.REACT_APP_ENV == "prod"){
      coreService.sendErrorReport(
        {
          errorMsg: error.message,
          errorDetails: error.stack,
          errorType: null
        }
      );
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Error from={this.props.from ? this.props.from : "admin"} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;