import React from "react";

import AppliedFees from "../../../debtorTransactions/components/appliedFee";

function DfAppliedFeeList(props) {
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <AppliedFees
                        location={props.location}
                    />
                </div>
            </div>

        </>
    )
}
export default DfAppliedFeeList;