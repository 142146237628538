import React from "react";
import "./css/card.css";
import { FormColumnMd } from "../../../common/containers/formContainer";
import { Link } from "react-router";
import CardSkelton from "./_cardSkelton";

function PrimaryCard({ loading, data, qrystring = false }) {

    return (
        <>
            {loading === true ? <CardSkelton /> : (
                <FormColumnMd col={4}>
                    {/*Primary card*/}

                    <div className="card">
                        <div className="card-body">
                            <span className="b-corner b-corner-r b-corner-success" />
                            <div className="d-flex justify-content-between">
                                <h6 className="card-title ">{data ? data.topLabel : ""}</h6>
                                <div className="ml-auto">
                                    <div className="btn-group ml-5 mb-0">
                                        <a href="/client/settings">
                                        </a><a className="btn-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#"><i className="fa fa-ellipsis-v opacity-25" /></a>
                                        <div className="dropdown-menu dropdown-menu-right shadow">

                                            <Link className="dropdown-item" to={{ pathname: data ? data.primaryBtnRoute : '', state: data ? data.status : "" }}><i className="fa fa-eye mr-2" />View all </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mb-0">
                                <div>
                                    <h4 className="mb-1 font-weight-bold">
                                        {qrystring === true ?
                                            <Link className="text-secondary" to={data ? data.primaryBtnRoute : ''}>{data ? data.topValue : ""}</Link> :
                                            <Link className="text-secondary" to={{ pathname: data ? data.primaryBtnRoute : '', state: data ? data.status : "" }}>{data ? data.topValue : ""}</Link>
                                        }

                                    </h4>
                                    <p className="mb-2 text-xs text-muted"><i className="mr-1" />{/*<span className="opacity-75">Pending your approval</span>*/}</p>
                                </div>
                                <div className="card-chart bg-warning-transparent brround ml-auto mt-0">
                                    <i className="typcn typcn-chart-line-outline text-warning tx-24 " />
                                </div>
                            </div>
                            <div>
                                <div className="progress progress-sm h-5 mt-2 mb-3">
                                    <div className="progress-bar bg-primary w-100 " role="progressbar" />

                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-6">
                                    <div className="d-flex align-items-end">

                                        <div>
                                            <h6>{data ? data.leftValue : ""}</h6>
                                            <p className="font-weight-semibold opacity-50 mb-0">{data ? data.leftLabel : ""}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-end">

                                        <div>
                                            <h6>{data ? data.rightValue : ""}</h6>
                                            <p className="font-weight-semibold opacity-50 mb-0">{data ? data.rightLabel : ""}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*      <div class="green-card1">
                        <div class="green-card-header p-3">

                            <FormRow>
                                <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8 col-8">
                                    <label class="p-0 m-0">{data ? data.topLabel : ""}</label>
                                    <h3 class="p-0 m-0 amount">{data ? data.topValue : ""}</h3>
                                </div>

                                <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4 col-4 text-right">
                                    {data && data.status ?
                                        <Link to={{ pathname: data ? data.primaryBtnRoute : '', state: data ? data.status : "" }} >
                                            <button class="btn card-btn btn-green">View</button>
                                        </Link> :
                                        <Link to={data ? data.primaryBtnRoute : ''} >
                                            <button class="btn card-btn btn-green">View</button>
                                        </Link>
                                    }
                                </div>
                            </FormRow>

                        </div>

                        <div class="card-body p-3">

                            <FormRow>
                                <FormColumnMd col={6}>
                                    <h4 class=" green-highlight p-0 m-0">{data ? data.leftValue : ""}</h4>
                                    <label class="green-highlight p-0 m-0">{data ? data.leftLabel : ""}</label>
                                </FormColumnMd>
                                <FormColumnMd col={6}>
                                    <h4 class=" green-highlight p-0 m-0">{data ? data.rightValue : ""}</h4>
                                    <label class="green-highlight p-0 m-0">{data ? data.rightLabel : ""}</label>
                                </FormColumnMd>
                            </FormRow>

                            <FormRow>
                                <div class="col-md-6 mt-4 col-6">
                                    <h4 class="card-name m-0 p-0">{data ? data.bottomLeftLabel : ""}</h4>
                                </div>
                                <div class="col-md-6 mt-4 col-6">
                                    <p class="card-title m-0 p-0 text-right">{data ? data.bottomRightLabel : ""}</p>
                                </div>
                            </FormRow>
                        </div>
                                </div>*/}
                </FormColumnMd>
            )}
        </>
    );
}

export default React.memo(PrimaryCard);