import React, { useContext } from "react";

import InvoiceTabView from "./invoiceTabViewNew";

import ApproveContext from "../../../../supply-chain/context/bulkApproveContext"; // React Context API

function AttentionRequired(props) {

  const bulkapproveOptions = useContext(ApproveContext);

  return (
    <>
      {bulkapproveOptions.attention == true && (
        <div>
          <InvoiceTabView
            invoiceToProcess={bulkapproveOptions.invoiceToProcess}
            attentionrequired={bulkapproveOptions.attention_required}
            accept={(trans_guid) => bulkapproveOptions.setAccept(trans_guid)}
            product = {bulkapproveOptions.product}
          ></InvoiceTabView>
          
        </div>
      )}
    </>
  );
}

export default AttentionRequired;
