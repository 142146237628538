/**Approval of supplier-intiated invoice by customer */
import React, { useState, useEffect } from "react";
import auth from "../../../../services/authService";
import useApi from "../../../../hooks/useApi";
import { toast } from "react-toastify";
import { GeneralButton } from "../../../common/inputs";
import { FormRow } from "../../../common/containers/formContainer";
import moment from "moment";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import { CurrencyLabel } from "../../../common/inputs/currencyInput/currencyInput";
import _ from "lodash";
import authService from "../../../../services/authService";
import { TextInput } from "../../../common/inputs";
import "./css/scrollTable.css";
import commonService from "../../../supply-chain/services/commonService";
import { DateTimePicker } from "../../../common/inputs/datePicker";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import BankDetail from "../../components/_bankDetail";
import Utility from "../../../supply-chain/utils/DateParser";
import SettlementTable from "./tftable";
const colorRed = {
  color: 'red'
};
function ClientBulkPayment(props) {

  const fetchApi = useApi(commonService.getTfPendingPayments);
  const paymentsUpdateApi = useApi(commonService.clientBulkPaymentUpdate);
  const fetchApiDateChange = useApi(commonService.getSCFBulkPayDateBase);
  const fundsAvailable = useApi(commonService.getTfFund);

  // const saveApi = useApi(commonService.saveNotifications);
  const { constants, entity_id } = authService.getCurrentUser();
  const customerId = entity_id;
  const [data, setData] = useState([]);
  const [chooseArray, setChooseArray] = useState([]);
  const [payments, setPayments] = useState([]);
  const [textAmout, setTextAmount] = useState();
  const [loader, setLoader] = useState(false);
  const [finCostLoader, setFinCostLoader] = useState(false);
  const [tabs, setTabs] = useState([{ label: "All Invoices", value: "0" },
  { label: "Overdue", value: "overdue" }
  ]);
  const [selectedTab, setSelectedTab] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  //const [lockDate, setLockDate] = useState(new Date());
  const [partPayBlur, setPartPayBlur] = useState([]);
  const [balAvailable, setBalAvailable] = useState();
  const [limitAvailable, setLimitAvailable] = useState(null);
  const [bankDetailModal, setBankDetailModal] = useState(false);

  const [defaultPageSize] = useState(100);
  const [paginationLimit, setPaginationLimit] = useState(100);
  const [showPopout, setShowPopout] = useState(false);

  const fetchDetails = async () => {
    setLoader(true);
    const { data } = await fetchApi.request({
      guid: customerId, pageLimit: paginationLimit
    });
    if (data) {
      if (props.location.state && props.location.state.invoices) {
        const filtered = data.data.filter(row => props.location.state.invoices.includes(row.trans_guid) === true);
        setData(filtered);
      } else {
        setData(data.data);
      }
    }
    setSelectedTab('0');
    setLoader(false);
  };

  const fetchAvailBalance = async () => {
    // funds available
    //setLoader(true);
    const { data } = await fundsAvailable.request({ guid: customerId });

    setLimitAvailable(data.limit)
    setBalAvailable(data.balanceAvailable)
    //setLoader(false);
  }

  useEffect(() => {
    //fetchDetails();
    //fetchAvailBalance();
  }, []);

  useEffect(() => {
    if (paginationLimit > defaultPageSize)
      handleInvDateChange(selectedDate);
  }, [paginationLimit])

  const handleBulkAmtFullPayment = (e, totalDue) => {
    scrollToEnd("scrollDiv");
    const target = e.target;
    let paidAmount = target.name
    let invAmount = totalDue

    if (target.checked == true) {
      let id = target.value;
      let name = target.id
      let type = "full"
      console.log(target.id);

      // check id already added to payment array if yes then remove
      const pay = payments.filter(row => row.id != id)
      if (pay.length > -1) {
        setPayments(pay);
      }

      // check exist in paytextbox Blur to label
      const textFilter = partPayBlur.filter(row => row.textBox != 'partPayCheck' + id)
      if (textFilter.length > -1) {
        setPartPayBlur(textFilter);
      }
      //end

      //add to payment array
      let amount = (invAmount -= paidAmount);
      setPayments(payments => [...payments, { id, amount, type }]);

      //check if partpayment Checked
      if (chooseArray.includes('partPayCheck' + id)) {
        const data1 = [...chooseArray];
        let name = 'partPayCheck' + id;
        var index = data1.indexOf(name);
        if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          data1.splice(index, 1);
          setChooseArray(data1);
        }
      }
      setChooseArray(chooseArray => [...chooseArray, name]);
    }
    else {
      //remove from choose array
      const data1 = [...chooseArray];
      let name = target.id
      var index = data1.indexOf(name);
      if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        data1.splice(index, 1);
        setChooseArray(data1);
      }

      let id = target.value;
      // check id already added to payment array if yes then remove
      const pay = payments.filter(row => row.id != id)
      if (pay.length > -1) {
        setPayments(pay);

      }
    }
  };


  const handleBulkAmtPartlyPayment = (target) => {

    scrollToEnd("scrollDiv");

    if (target.checked == true) {
      let id = target.value;
      let name = target.id

      // check id already added to payment array if yes then remove
      const pay = payments.filter(row => row.id != id)
      if (pay.length > -1) {
        setPayments(pay);
      }

      // check exist in paytextbox Blur to label
      const textFilter = partPayBlur.filter(row => row.textBox != 'partPayCheck' + id)
      if (textFilter.length > -1) {
        setPartPayBlur(textFilter);
      }

      //check if fullpayment Checked
      if (chooseArray.includes('fullPayCheck' + id)) {
        const data1 = [...chooseArray];
        let name = 'fullPayCheck' + id;
        var index = data1.indexOf(name);
        if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          data1.splice(index, 1);
          setChooseArray(data1);
        }
      }

      setChooseArray(chooseArray => [...chooseArray, name]);
    }
    else {
      //remove from choose array
      const data1 = [...chooseArray];
      let name = target.id
      var index = data1.indexOf(name);
      if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        data1.splice(index, 1);
        setChooseArray(data1);
      }

      // check id already added to payment array if yes then remove
      let id = target.value;
      const pay = payments.filter(row => row.id != id)
      if (pay.length > -1) {
        setPayments(pay);
      }
    }
  };

  const handleValueChange = (e, balance) => {

    const target = e.target
    let id = target.name;
    let amount = target.value;
    let type = "partly"
    if (target.value > balance) { //check enter value is greater than balance amount
      toast.error("Amount to Pay Only $" + balance);
      const pay = payments.filter(row => row.id != id)
      if (pay.length > -1) {
        setPayments(pay);
      }
      ////////
      const data1 = [...chooseArray];
      let name = 'partPayCheck' + id
      var index = data1.indexOf(name);
      if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        data1.splice(index, 1);
        setChooseArray(data1);
      }
      /////////
    } else {
      if (target.value <= 0) {  //check enter value  is 0 or less than

        toast.error("Please Enter a Valid Amount");
        //remove the paymet details from state
        const pay = payments.filter(row => row.id != id)
        if (pay.length > -1) {
          setPayments(pay);
        }
        ////////
        const data1 = [...chooseArray];
        let name = 'partPayCheck' + id
        var index = data1.indexOf(name);
        if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          data1.splice(index, 1);
          setChooseArray(data1);
        }
      } else {
        // check id already added to payment array if yes then remove
        const pay = payments.filter(row => row.id != id)
        if (pay.length > -1) {
          setPayments(pay);
        }
        setPayments(payments => [...payments, { id, amount, type }]);

      }
    }
  };

  const saveChanges = async () => {

    let popoutEnabled = false;
    
    if(showPopout){
      popoutEnabled = true;
      setShowPopout(false);
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-delete-ui'>
            <h4>Do you want to continue ...?</h4>
            <p>Do you want to continue with Payment ?</p>
            <button className="btn btn-white" onClick={() => 
              { 
                onClose(); 
                if(popoutEnabled)
                  setShowPopout(true);
              }}>No</button>
            <button
              className="btn btn-primary ml-2"
              onClick={async () => {
                onClose();
                handleSave();
                if(popoutEnabled)
                  setShowPopout(true);
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  }

  const handleSave = async () => {
    setLoader(true);
    const { ParseDate } = Utility();
    //let selected_date = moment(selectedDate).format("YYYY-MM-DD HH:mm:ss");
    const { data } = await paymentsUpdateApi.request({
      payments: payments, date: ParseDate(selectedDate),
      product_type: constants.PRODUCTS.TRADEFINANCE
    });
    if (data.success === false) {
      setLoader(false);
      toast.error(data.message);
    } else {
      setChooseArray([])
      setPayments([]);
      fetchDetails();
      setPartPayBlur([]);
      setLoader(false);
      showBankDetails();
      toast.success(data.message);
    }
  }


  //fbc
  const handleBulkFullPayCheck = async (e) => {

    scrollToEnd("scrollDiv");
    const target = e.target;
    if (target.checked == true) {
      setChooseArray([]);
      setPayments([]);
      setChooseArray(chooseArray => [...chooseArray, "bulkFullPayCheck"]);
      // make check all checkboxes
      data.map((listValue) => {
        if (listValue.amount_client_req == null) {
          setChooseArray(chooseArray => [...chooseArray, "fullPayCheck" + listValue.id]);
          //add to payment array
          let amount = (listValue.total_due - listValue.amount_paid - listValue.dd_requested);
          let id = listValue.id;
          let type = "full";
          setPayments(payments => [...payments, { id, amount, type }]);
        }
      })
    } else {
      const bulkChecks = chooseArray.filter(row => row != "bulkFullPayCheck")
      if (bulkChecks.length > -1) {
        setPayments([]);
        setChooseArray([]);
      }
    }
  }

  const handleInvDateChange = async (date) => {
    setSelectedDate(date);
    setLoader(true);
    setFinCostLoader(true);
    const { ParseDate } = Utility();
    const { data } = await fetchApiDateChange.request({
      guid: customerId,
      date_selected: ParseDate(date),
      product_type: constants.PRODUCTS.TRADEFINANCE,
      pageLimit: paginationLimit
    });
    setData(data.data);
    setSelectedTab('0');
    setPayments([]);
    setChooseArray([]);
    setFinCostLoader(false);
    setPartPayBlur([]);
    setLoader(false);

    if (!limitAvailable)
      fetchAvailBalance();
  };

  const handleChange = async (status) => {

    const { sc_invoice_status } = auth.getCurrentUser();

    setSelectedTab(status);
    setLoader(true);
    const { data } = await fetchApi.request({
      guid: customerId
    });
    //filter data make it api call
    if (status == 'overdue') {
      const filter_fetch = data.data.filter(row => (row.trans_status_guid == sc_invoice_status['funds_overdue'] || row.trans_status_guid == sc_invoice_status['overdue_funds_partly_collected'])) //filter that client req is null
      setData(filter_fetch);
    } else {
      fetchDetails();
    }
    setPayments([]);
    setChooseArray([]);
    setLoader(false);
  }

  const handleOnBlur = (e, textBoxName) => {
    const target = e.target;
    const textBox = textBoxName;
    const value = target.value;

    const textFilter = partPayBlur.filter(row => row.textBox != textBox)
    if (textFilter.length > -1) {
      setPartPayBlur(textFilter);

    }
    if (value != "") {
      setPartPayBlur(partPayBlur => [...partPayBlur, { textBox, value }]);
    }

  }

  const handleLabelToText = (texBoxName, id) => {

    const texBox = texBoxName;
    const textFilter = partPayBlur.filter(row => row.textBox != texBox)
    if (textFilter.length > -1) {
      setPartPayBlur(textFilter);

    }
    // check id already added to payment array if yes then remove
    const pay = payments.filter(row => row.id != id)
    if (pay.length > -1) {
      setPayments(pay);

    }
    // check the label in full payment then remove it
    const chooseArr = chooseArray.filter(row => row != "fullPayCheck" + id)
    if (chooseArr.length > -1) {
      setChooseArray(chooseArr);
      setChooseArray(chooseArray => [...chooseArray, "partPayCheck" + id]);
    }
  }
  //endfbc
  const scrollToEnd = (id) => {
    var scrollDiv = document.getElementById(id);
    scrollDiv.scrollLeft = scrollDiv.scrollWidth;

  }

  /*
  * PopUp to show bank detail
  */
  const showBankDetails = () => {
    setBankDetailModal(true);
  }

  return (
    <>
      <Helmet>
        <title>Settle Invoices | {global.config.title}</title>
      </Helmet>
      {loader == true && <LoadingSpinner ></LoadingSpinner>}
      <div className="card overflow-visible" >
        <div className="card-header">
          <div className="row">            <div className="col-lg-6"><h6>Confirm Your Payment</h6></div>
            <div className="col-lg-6">
              <Link className="btn btn-light text-secondary pull-right" to="/client/tf/dashboard"><i alt="TF Dashboard" data-toggle="tooltip" data-placement="top" title="Close" className="fa fa-close"></i></Link>
              {showPopout === false && (
                  <a className="btn btn-light pull-right text-secondary mr-2" onClick={() => {setShowPopout(true)}} >
                      <i className="fa fa-expand"></i>
                  </a>
              )}
            </div>
          </div>
        </div>
        <div className="card-body">
          <SettlementTable 
            tabs={tabs}
            selectedDate={selectedDate}
            selectedTab={selectedTab}
            handleChange={handleChange}
            payments={payments}
            balAvailable={balAvailable}
            chooseArray={chooseArray}
            handleBulkFullPayCheck={handleBulkFullPayCheck}
            data={data}
            colorRed={colorRed}
            handleBulkAmtFullPayment={handleBulkAmtFullPayment}
            handleBulkAmtPartlyPayment={handleBulkAmtPartlyPayment}
            finCostLoader={finCostLoader}
            handleLabelToText={handleLabelToText}
            partPayBlur={partPayBlur}
            textAmout={textAmout}
            handleValueChange={handleValueChange}
            handleOnBlur={handleOnBlur}
            loader={loader}
            paginationLimit={paginationLimit}
            setPaginationLimit={setPaginationLimit}
            defaultPageSize={defaultPageSize}
            limitAvailable={limitAvailable}
            handleInvDateChange={handleInvDateChange}
            saveChanges={saveChanges}
          />
        </div>
      </div >

      {/*----------------------- Settlement Table Modal ------------------------------*/}
      <Modal
        className="fullscreen-modal"
        show={showPopout}
        onHide={() => {setShowPopout(false)}}
        onExit={() => document.body.style.overflow = 'auto'} // Restore body overflow when modal exits
        onExiting={() => document.body.style.overflow = 'auto'}
      >
        <Modal.Header closeButton>
          <div className="d-block pt-3 text-center w-100 ml-3">
              <h3 className="modal-title"></h3>
          </div>
        </Modal.Header>
        <Modal.Body>
        <SettlementTable 
            tabs={tabs}
            selectedDate={selectedDate}
            selectedTab={selectedTab}
            handleChange={handleChange}
            payments={payments}
            balAvailable={balAvailable}
            chooseArray={chooseArray}
            handleBulkFullPayCheck={handleBulkFullPayCheck}
            data={data}
            colorRed={colorRed}
            handleBulkAmtFullPayment={handleBulkAmtFullPayment}
            handleBulkAmtPartlyPayment={handleBulkAmtPartlyPayment}
            finCostLoader={finCostLoader}
            handleLabelToText={handleLabelToText}
            partPayBlur={partPayBlur}
            textAmout={textAmout}
            handleValueChange={handleValueChange}
            handleOnBlur={handleOnBlur}
            loader={loader}
            paginationLimit={paginationLimit}
            setPaginationLimit={setPaginationLimit}
            defaultPageSize={defaultPageSize}
            limitAvailable={limitAvailable}
            handleInvDateChange={handleInvDateChange}
            saveChanges={saveChanges}
          />
        </Modal.Body>
      </Modal>

      {/*------------------Bank Detail Modal-------------------*/}
      < Modal
        show={bankDetailModal}
        onHide={() => { setBankDetailModal(false) }
        }
        centered
        contentClassName="bg-secondary text-white"
        backdropClassName="bg-white"
      >
        <BankDetail
          onCloseModal={() => {
            setBankDetailModal(false)
          }}
          productId={constants.PRODUCTS.TRADEFINANCE}
        />
      </Modal >
    </>
  );
}

export default ClientBulkPayment;