import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";


import DiscountRates from "./_discountRates";
import SupplierCriteria from "./_supplierCriteria";
import SupplierMapping from "./_supplierMapping";
import Commencement from "./_commencement";
import Limit from "./_limit";
import MultiStepForm from "../../../common/wizard/MultiStepForm";
import { browserHistory } from "react-router";
export default function AddNew(props) {
    const [proceedNext, setProceedNext] = useState(true);
    const componentsList = [
        {
            headerText: "1. Discount Rate",
            headerIcon: "fa fa-calculator",
            component: <DiscountRates />,
        },

        {
            headerText: "2. Supplier Criteria",
            headerIcon: "fa fa-file-text-o",
            component: <SupplierCriteria />,
        },
        {
            headerText: "3. Supplier Mapping",
            headerIcon: "fa fa-link",
            component: <SupplierMapping />,
        },
        {
            headerText: "4. Commencement",
            headerIcon: "fa fa-calendar",
            component: <Commencement />,
        },
        {
            headerText: "5. Program Limit",
            headerIcon: "fa fa-cubes",
            component: <Limit />,
        },
        {
            headerText: "6. Invite Supplier",
            headerIcon: "fa fa-envelope",
            component: "",
        },
        {
            headerText: "7. Finish",
            headerIcon: "fa fa-flag-checkered",
            component: "",
        },
    ];
    return (
        <>

            <Helmet>
                <title>Cash Flow  | {global.config.title}</title>
            </Helmet>
            <div  >

                <MultiStepForm

                    proceedNext={proceedNext}
                    list={componentsList}
                    displayProgressBar={true}
                    handleCancel={() => browserHistory.push("/client/ep-programme")}
                    displaySteps={true}
                    pathname={props.location.pathname}
                />

            </div>
        </>
    )
}
