import React, { useState, useContext, useEffect } from 'react';
import Calculator from "../../../../img/icons/calc.svg";
import { RadioButton } from "../../../common/inputs/radioButton/radioButton";
export default function SupplierMapping(props) {
    return (
        <div >

            <div className='row'>
                <div className='col-lg-5'>
                    <div className="logo text-center mb-2 mt-2  ">
                        <img src={Calculator} alt="Fifo Calculator" />
                    </div>
                    <div className='text-center'>
                        <h6>Supplier Mapping</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. orem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                </div>
                <div className='col-lg-7'>

                    <div>
                        <div className="d-sm-flex w-100  py-3 align-items-center ">
                            <div className="custom-control custom-radio custom-control-inline"></div>
                            <div className='col-lg-10'>

                                <div className="border rounded-xl p-4 shadow-sm mb-3   font-weight-bold">

                                    <dl className="dl-horizontal ">
                                        <ul class="list-group clear-list m-t">
                                            <li class="list-group-item fist-item">
                                                <span class="label label-success pull-right text-white">94</span>
                                                <input className='mr-2' type='checkbox' />
                                                Supplier 1
                                            </li>
                                            <li class="list-group-item fist-item">
                                                <span class="label label-warning pull-right">62</span>
                                                <input className='mr-2' type='checkbox' />
                                                Supplier 2 <span className='bagde badge-info'>Program Sep23</span>
                                            </li>
                                            <li class="list-group-item fist-item">
                                                <span class="label label-success pull-right text-white">85</span>
                                                <input className='mr-2' type='checkbox' />
                                                Supplier 3
                                            </li>
                                            <li class="list-group-item fist-item">
                                                <span class="label label-danger pull-right">24</span>
                                                <input className='mr-2' disabled type='checkbox' />
                                                Supplier 4
                                            </li>
                                            <li class="list-group-item fist-item">
                                                <span class="label label-danger pull-right">18</span>
                                                <input className='mr-2' disabled type='checkbox' />
                                                Supplier 5
                                            </li>
                                        </ul>
                                    </dl>
                                    <div className="form-group align-center text-center">
                                        <button className='btn btn-sm btn-secondary mr-2'>Bulk Upload</button>
                                        <button className='btn btn-sm btn-primary '>Add New</button>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}