import React, { useState } from "react";
import { toast } from "react-toastify";
import PasswordStrengthIndicator from "../../../common/passwordStrengthIndicator";

// Regular expression for Password validations.
const isNumberRegx = /\d/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const lowerCaseRegex = /[a-z]/;
const upperCaseRegex = /[A-Z]/;

export default function SetPassword({ handleClientSubmission }) {

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordValidity, setPasswordValidity] = useState({
    minChar: null,
    number: null,
    specialChar: null,
    lowerCaseChar: null,
    upperCaseChar: null,
  });

  const onChangePassword = (password) => {
    setPassword(password);

    setPasswordValidity({
      minChar: password.length >= 8 ? true : false,
      number: isNumberRegx.test(password) ? true : false,
      specialChar: specialCharacterRegx.test(password) ? true : false,
      lowerCaseChar: lowerCaseRegex.test(password) ? true : false,
      upperCaseChar: upperCaseRegex.test(password) ? true : false,
    });
  };

  //----------------Handle Form Submission---------------------------
  const handleFormSubmission = () => {
    const {
      minChar,
      number,
      specialChar,
      lowerCaseChar,
      upperCaseChar,
    } = passwordValidity;

    if (
      minChar === true &&
      number === true &&
      specialChar === true &&
      lowerCaseChar === true &&
      upperCaseChar === true
    )
      {
        if(password !== confirmPassword)
          toast.error('Password and Confirm password should be same.');
        else
          handleClientSubmission(oldPassword, password);
      }
  };

  return (
      <form>

        {/*--------------------- Old Password ---------------------*/}
        <div className="input-group mb-3">
          <input
            className="form-control border-right-0 w-auto"
            placeholder="Old Password"
            type={showOldPassword ? "text" : "password"}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />

          <span className="input-group-append bg-white border-left-0">
            <span className="input-group-text bg-transparent">
              <i
                onClick={(e) => setShowOldPassword(!showOldPassword)}
                className={showOldPassword ? "fa fa-eye-slash" : "fa fa-eye"}
              />
            </span>
          </span>
        </div>

        {/*--------------------- New Password ---------------------*/}
        <div className="input-group mb-3">
          <input
            className="form-control border-right-0 w-auto"
            placeholder="New Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => onChangePassword(e.target.value)}
          />

          <span className="input-group-append bg-white border-left-0">
            <span className="input-group-text bg-transparent">
              <i
                onClick={(e) => setShowPassword(!showPassword)}
                className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
              />
            </span>
          </span>
        </div>

        {/*--------------------- Confirm Password ---------------------*/}
        <div className="input-group mb-3">
          <input
            className="form-control border-right-0 w-auto"
            placeholder="Confirm New Password"
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <span className="input-group-append bg-white border-left-0">
            <span className="input-group-text bg-transparent">
              <i
                onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}
                className={showConfirmPassword ? "fa fa-eye-slash" : "fa fa-eye"}
              />
            </span>
          </span>
        </div>

        <PasswordStrengthIndicator validity={passwordValidity} />

        <button
          type="button"
          onClick={(e) => handleFormSubmission()}
          className="btn btn-primary btn-block "
        >
          {" "}
          Change My Password
        </button>
      </form>
  );
}
