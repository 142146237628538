import React, { useEffect, useState } from "react";
import "./css/card.css";
import { FormColumnMd } from "../../../common/containers/formContainer";
import { Link } from "react-router";
import CardSkelton from "./_cardSkelton";
import { CurrencyLabel } from "../../../common/inputs";

import authService from "../../../../services/authService";
import transactionService from "../../../supply-chain/services/transaction_service";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";

function OverDueCard({ col, loading, data, qrystring = false, dropDown, setProductRoleType, product }) {

    const [interestLoading, setInterestLoading] = useState(false);
    const [interest, setInterest] = useState(0);

    const getInterestOwing = async (status = 'overdue') => {

        setInterestLoading(true);

        const { entity_id } = authService.getCurrentUser();
        const result = await transactionService.getInterestOwing({
            status,
            entity_guid: entity_id,
            product: product
        });

        setInterestLoading(false);
        setInterest(result.data.total_finance_cost_outstanding);
    }

    useEffect(() => {
        getInterestOwing();
    }, []);

    return (
        <>
            {loading === true ? <CardSkelton top={true} col={col ? col : 4} /> : (
                <FormColumnMd col={col ? col : 4}  >
                    <div className="card">
                        <div className="card-body">
                            <span className="b-corner b-corner-r b-corner-danger" />

                            <div class="d-flex justify-content-between">
                                <h6 class="card-title">DUE / OVERDUE</h6>
                                <div class="ml-auto">
                                    <span className="opacity-75 text-danger">Please settle these invoices</span>
                                </div>
                            </div>

                            <div className="d-flex mb-0">
                                <div>

                                    {qrystring === true ?
                                        <Link className="text-secondary" to={data ? data.primaryBtnRoute : ''}><h4 className="mb-1 font-weight-bold">{data ? data.topValue : ""}</h4></Link> :
                                        <Link className="text-secondary" to={{ pathname: data ? data.primaryBtnRoute : '', state: data ? data.status : "" }}
                                            onClick={() => {
                                                try {
                                                    setProductRoleType()
                                                } catch (error) {
                                                    console.error();
                                                }
                                            }
                                            }>
                                            <h4 className="mb-1 font-weight-bold text-danger">{data ? data.topValue : ""}</h4>
                                        </Link>
                                    }

                                    <p className="mb-2 text-xs text-muted">
                                        <i className={`fa ${data ? data.subIcon : ''} text-danger mr-1`} />
                                        <span className="text-danger">
                                            {interestLoading ? <LoadingSpinner normal="true" /> : <CurrencyLabel decimal={2} value={interest} />} Interest
                                        </span>
                                    </p>
                                </div>
                                <div className="card-chart bg-warning-transparent brround ml-auto mt-0">
                                    <i className="typcn typcn-chart-line-outline text-warning tx-24" />
                                </div>
                            </div>
                            <div>
                                <div className="progress progress-sm h-5 mt-2 mb-3">

                                    <div className="progress-bar bg-danger w-100 " role="progressbar" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="d-flex align-items-end">

                                        <div>
                                            <h6>{data ? data.leftValue : ""}</h6>
                                            <p className="font-weight-semibold opacity-50 mb-0">{data ? data.leftLabel : ""}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-end">

                                        <div>
                                            <h6>{data ? data.rightValue : ""}</h6>
                                            <p className="font-weight-semibold opacity-50 mb-0">{data ? data.rightLabel : ""}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  <div class="danger-card1">
                        <div class="danger-card-header p-3">

                            <FormRow>
                                <div class="col-lg-7 col-md-7 col-xs-7 col-sm-7 col-7">
                                    <label class="p-0 m-0">{data ? data.topLabel : ""}</label>
                                    <h3 class="p-0 m-0 amount">{data ? data.topValue : ""}</h3>
                                </div>

                                <div class="col-lg-5 col-md-5 col-xs-5 col-sm-5 col-5 text-right">
                                    {dropDown}
                                </div>
                            </FormRow>

                        </div>

                        <div class="card-body p-3">

                            <FormRow>
                                <FormColumnMd col={6}>
                                    <h4 class="red-highlight p-0 m-0">{data ? data.leftValue : ""}</h4>
                                    <label class="p-0 m-0">{data ? data.leftLabel : ""}</label>
                                </FormColumnMd>
                                <FormColumnMd col={6}>
                                    <h4 class=" red-highlight p-0 m-0">{data ? data.rightValue : ""}</h4>
                                    <label class="p-0 m-0">{data ? data.rightLabel : ""}</label>
                                </FormColumnMd>
                            </FormRow>

                            <FormRow>
                                <div class="col-md-6 mt-4 col-6">
                                    <h4 class="card-name m-0 p-0">{data ? data.bottomLeftLabel : ""}</h4>
                                </div>
                                <div class="col-md-6 mt-4 col-6">
                                    <p class="card-title m-0 p-0 text-right">{data ? data.bottomRightLabel : ""}</p>
                                </div>
                            </FormRow>

                        </div>
            </div>*/}
                </FormColumnMd>
            )}
        </>
    );
}

export default React.memo(OverDueCard);