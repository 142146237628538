import React, { useState } from "react";
import ReactTable from "react-table";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import EditSupplier from "./_editSupplier";
import {
    EditButton,
    DeleteButton
} from "../../../common/inputs";
import { Link } from "react-router";

import { useUploader } from "../../../../context/supplierUploaderContext";

import "../../../../styles/react-table.css";
import "react-table/react-table.css";

function ListSuppliers() {

    const [editModal, setEditModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const { suppliers, setProceedNext, setnextLabel, sethidePreviousBtn,deleteSupplier } = useUploader(); // Instance for Context API

    // Delete selected invoice from the list
    const handleSupplierDeletion = index => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-delete-ui">
                  <h4>Are you sure ...?</h4>
                  <p>You want to delete supplier?</p>
                  <button
                    className="btn btn-white"
                    onClick={() => {
                        onClose();
                    }}
                  >
                    No
                  </button>

                  <button
                    className="btn btn-primary ml-2"
                    onClick={ async() => {
                        onClose();
                        deleteSupplier(index)
                    }}
                  >
                    Yes
                  </button>
                </div>
              );
            },
        });
    };

    setnextLabel("Next");
    sethidePreviousBtn(false);
    setProceedNext(true);
    

    const columns = [
        {
            Header: "Organisation",
            accessor: "Organisation",
            headerStyle: { textAlign: "left" }
        },
        {
            Header: "First Name",
            accessor: "First_Name",
            headerStyle: { textAlign: "left" },
        },

        {
            Header: "Last Name",
            accessor: "Last_Name",
            headerStyle: { textAlign: "left" },
        },
        {
            Header: "ABN",
            accessor: "ABN",
            headerStyle: { textAlign: "left" }
        },
        {
            Header: "Email",
            accessor: "Email",
            headerStyle: { textAlign: "left" }
        },

        {
            Header: "Phone",
            accessor: "Phone",
            headerStyle: { textAlign: "left" }
        },
        {
            Header: "Action",
            accessor: "action",
            headerStyle: { textAlign: "left" },
            minWidth: 50,
            Cell: row => {
                return (
                    <>
                        <Link className="action-icons" 
                            onClick={e => {
                                setSelectedRow(row.original);
                                setEditModal(true);
                            }}
                        >
                            <EditButton></EditButton>
                        </Link>
                       &nbsp;
                       <Link className="action-icons" onClick={e => { handleSupplierDeletion(row.original.index) }} >
                            <DeleteButton></DeleteButton>
                        </Link>
                    </>
                )
            }
        }
    ];

    return (
        <>
            <ReactTable
                defaultPageSize={10}
                data={suppliers}
                columns={columns}
            />

            {/*------------------Edit supplier Modal-------------------*/}
            <Modal
                show={editModal}
                onHide={() => setEditModal(false)}
                size="lg"
            >
                <EditSupplier 
                    data = {selectedRow} 
                    onClose = {() => setEditModal(false)}
                />
            </Modal>
            {/*---------------------------------------------------------*/}
        </>
    )
}

export default ListSuppliers;