import React from "react";
import { Link } from "react-router";

const MappedUsersTable = (props) => {
  return (
    <>
      <thead>
        <tr>
          <th>Organisation</th>
          <th>Name</th>
          <th>Email</th>
          {props.usertType == "supplier" && <th>Allow early payments</th>}
          <th>Verification Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.data &&
          props.data.map((item) => (
            <>
              <tr>
                <td>{item.label}</td>
                <td>{props.showEmailName(item.name)}</td>
                <td>{props.showEmailName(item.email)}</td>
                {props.usertType === "supplier" && (
                  <td> {props.onAllowEarlyPayments(item)}</td>
                )}

                <td>{props.onVerificationStatus(item.verification_status)}</td>
                <td>
                  <Link
                    title="Edit Mapping"
                    className="cursor-link text-secondary ml-2"
                    onClick={() => {
                      props.editMapping(item);
                    }}
                  >
                    <i className={`fa fa-edit`}></i>
                  </Link>
                </td>
              </tr>
            </>
          ))}
      </tbody>
    </>
  );
};
export default MappedUsersTable;
