import client from "./serviceClient";
import http from "./httpService";
import { apiUrl, queryString } from "../../../environment";

const transactionUrl = "/supplychain/fifo-sc-transaction/";
let accessToken = "";
function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
}
// ------------- Generates API Url ------------------------
function getApiUrl(func) {
  let remote_call = apiUrl + transactionUrl + func + queryString;
  return remote_call;
}
// --------------------------------------------------------

// ------ Aynchronous function to communicate with the server
async function createResponse(url, postObject) {

  try {
    const response = await http.post(url, postObject);
    return response.data;
  } catch (error) {

  }
}
// --------------------------------------------------------

export async function getCustomersList(country_id, categ_type = null) {
  // Action Dispatcher

  let apiGeneralCall = getApiUrl("get-customers-with-country-code");
  let postObject = {
    country_id,
    categ_type,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function getSupplierList(country_id, categ_type = null) {
  // Action Dispatcher

  let apiGeneralCall = getApiUrl("get-suppliers-with-country-code");
  let postObject = {
    country_id,
    categ_type,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function createInvoice(data, fileslist, userType, converting_trans_guid=null) {
  let apiGeneralCall = getApiUrl("create-invoice");

  let postObject = {
    data,
    fileslist,
    userType,
    converting_trans_guid
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

//fbc 
export async function addInvoiceAttachment(data, fileslist) {
  let apiGeneralCall = getApiUrl("add-invoice-attachment");

  let postObject = {
    data,
    fileslist
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
//endfbc

export async function uploadBulkInvoice(data, product, selectedFields = null, selectedSuppliers = null,uploadedFiles=null, is_migrate=false) {
  let apiGeneralCall = getApiUrl("common-invoice-creation");
  let postObject = {
    data,
    product,
    selectedFields,
    selectedSuppliers,
    uploadedFiles,
    is_migrate: is_migrate
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function getInvoiceDetails(inv_guid, shared = false,trader_id= null ,preview = false,download = false,tempData =null) {
  let apiGeneralCall = getApiUrl("get-invoice-details");
  let postObject = {
    inv_guid,
    shared,
    trader_id,
    preview,
    download,
    tempData,
  };

  try {
    const response = await http.post(apiGeneralCall, postObject);
    return response;
  } catch (error) {

  }
}

export async function displayFee(data, userType) {
  let apiGeneralCall = getApiUrl("calculate-fee");
  let postObject = {
    data,
    userType,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function maxRevisedDate(data) {
  let apiGeneralCall = getApiUrl("max-revised-date");
  let postObject = {
    data,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function viewAttachment(attachment_guid) {
  let apiGeneralCall = getApiUrl("view-attachment");
  let postObject = {
    attachment_guid,
  };

  try {
    const response = await http.post(apiGeneralCall, postObject);
    return response;
  } catch (error) {

  }
}
//download limit request attachments
export async function downloadAttachment(attachment_guid) {
  let apiGeneralCall = getApiUrl("view-attachment");
  let postObject = {
    attachment_guid,
  };

  try {
    const response = await http.post(apiGeneralCall, postObject);
    return response;
  } catch (error) {

  }
}

export async function deleteAttachment(attachment_guid) {
  let apiGeneralCall = getApiUrl("delete-attachment");
  let postObject = {
    attachment_guid,
  };

  try {
    const response = await http.post(apiGeneralCall, postObject);
    return response;
  } catch (error) {

  }
}

export async function getInvoiceList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let apiGeneralCall = getApiUrl("list-invoices");
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams,
  };

  try {
    const response = await http.post(apiGeneralCall, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    // return console.log(response);
  }
}

/*Approval of supplier intiated invoice by  customer */
export async function createInvoiceApproval(data, userType) {
  let apiGeneralCall = getApiUrl("create-invoice-approval");

  let postObject = {
    data,
    userType,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
export async function getScTabsList() {
  let apiGeneralCall = getApiUrl("get-sc-transaction-tabs-list");
  let postObject = {};
  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function createBulkApproval(invoices, userType, data, product) {
  let apiGeneralCall = getApiUrl("bulk-approval");
  let postObject = {
    invoices,
    userType,
    data,
    product
  };
  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
export async function getScTabsListCount(status, userId = "", userType = "") {
  let apiGeneralCall = getApiUrl("get-sc-transaction-tabs-list");
  let postObject = { status, userId, userType };
  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function getFundsAvailable() {
  let apiGeneralCall = getApiUrl("funds-available");
  let postObject = {};
  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
export async function UpdateInvoiceStatus(trans_guid, status) {
  let apiGeneralCall = getApiUrl("update-status");

  let postObject = {
    trans_guid,
    status,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function CreatePayment(trans_guid, customer_guid, date, amount, product, payment_type) {
  let apiGeneralCall = getApiUrl("create-payment");

  let postObject = {
    trans_guid,
    customer_guid,
    date,
    amount,
    product,
    payment_type
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
export async function updatePayment(data) {
  let apiGeneralCall = getApiUrl("update-payment");

  let postObject = {
    data
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function deletePayment(data) {
  let apiGeneralCall = getApiUrl("delete-payment");

  let postObject = {
    data
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function AddAdditionalFee(
  trans_guid,
  overdueAmount,
  latePaymentFee,
  product,
  lastPaymentDate=null
) {
  let apiGeneralCall = getApiUrl("add-additional-fee");

  let postObject = {
    trans_guid,
    overdueAmount,
    latePaymentFee,
    product,
    lastPaymentDate
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function getBulkApproveList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let apiGeneralCall = getApiUrl("list-bulk-approve-invoices");
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams,
  };

  try {
    const response = await http.post(apiGeneralCall, postObject, accessToken);
    if(handleRetrievedData){
      return handleRetrievedData(response);
    }else{
      return response;
    }

  } catch (response) {
  }
}
export async function getUsersList(category, guid = "", selectedCustomer = "") {
  // Action Dispatcher

  let apiGeneralCall = getApiUrl("get-users-with-country-code");
  let postObject = {
    category,
    guid,
    selectedCustomer
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
export async function getScCounts(userId, selRole = "") {
  let apiGeneralCall = getApiUrl("get-sc-counts");
  let postObject = { userId, selRole };
  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function getInvoiceSum(userId) {

  let apiGeneralCall = getApiUrl("get-pending-paid-sum");
  let postObject = { userId };
  const response = createResponse(apiGeneralCall, postObject);
  return response;

}
export async function maxInvoiceDuedDate(data) {
  let apiGeneralCall = getApiUrl("max-invoice-due-date");
  let postObject = {
    data,
  };
  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
/********************************* To fetch sc transaction status log ************************************/
export async function getScTransactionStatusLog(trans_guid) {
  try {
    const response = await http.post(getApiUrl("get-status-log"), { trans_guid });
    return response;
  } catch (error) {

  }
}
//---------------------------------------------------------------------------------------------------//
export async function getTfInvoiceDetails(inv_guid, shared = false,trader_id= null,download = false,preview = false,tempData =null) {
  let apiGeneralCall = getApiUrl("get-tf-invoice-details");
  let postObject = {
    inv_guid,
    shared,
    trader_id,
    download,
    preview,
    tempData,
  };

  try {
    const response = await http.post(apiGeneralCall, postObject);
    return response;
  } catch (error) {

  }
}
export async function getTfCounts(userId, selRole = "") {
  let apiGeneralCall = getApiUrl("get-tf-counts");
  let postObject = { userId, selRole };
  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
const getInvoice = (params, status) => client.post(`/supplychain/fifo-sc-transaction/list-invoice?XDEBUG_SESSION_START=PHPSTORM`, { ...params, status });
const searchTraderInvoice = params => client.post(`/supplychain/fifo-sc-transaction/search-trader-invoices?XDEBUG_SESSION_START=PHPSTORM`, { ...params });

const getBpScTransactions = (params, status) => client.post(`/supplychain/fifo-sc-transaction/list-bp-transactions?XDEBUG_SESSION_START=PHPSTORM`, { ...params, status });
const searchInvoice = params => client.post(`/supplychain/fifo-sc-transaction/search-invoices?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
//const searchInvoice = params => client.post(`/supplychain/fifo-sc-transaction/search-invoice`, { ...params });
const getReports = (params, docType) => client.post(`/supplychain/reports/get-reports`, { ...params, docType });
const downloadReport = (params, docType) => client.post(`/supplychain/reports/download-reports?XDEBUG_SESSION_START=PHPSTORM`, { ...params, docType });
const getCustomerReports = params => client.post(`/supplychain/fifo-sc-transaction/get-customer-reports?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const searchCustomer = params => client.post(`/supplychain/fifo-sc-transaction/search-entities?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const downloadCustomerReport = params => client.post(`/supplychain/fifo-sc-transaction/download-customer-reports?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const UpdateScInvoiceStatus = (trans_guid, status, product_id, verified, paid_at) => client.post(`/supplychain/fifo-sc-transaction/update-sc-invoice-status`, { trans_guid, status, product_id, verified, paid_at });
const getRepaymentHistory = (customer, duration) => client.post(`/supplychain/fifo-sc-transaction/get-repayment-history?XDEBUG_SESSION_START=PHPSTORM`, { customer, duration });
const getDueInvoiceList = (customer, durationType) => client.post(`/supplychain/fifo-sc-transaction/get-due-invoice-list?XDEBUG_SESSION_START=PHPSTORM`, { customer, durationType });
const bulkPayNow = () => client.post(`/supplychain/fifo-sc-transaction/get-bulk-pay-now?XDEBUG_SESSION_START=PHPSTORM`, {});
const bulkpayNowSupplier = () => client.post(`/supplychain/fifo-sc-transaction/bulk-pay-now-supplier?XDEBUG_SESSION_START=PHPSTORM`, {});
const getMappedUsers = (params) => client.post(`/supplychain/fifo-sc-transaction/get-mapped-users-list?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const approvalToPaid = (params) => client.post(`/supplychain/fifo-sc-transaction/approval-to-paid?XDEBUG_SESSION_START=PHPSTORM`, { ...params })
//const listAllInvoices = (params) =>client.post(`/entity/entity/list-all-invoices?XDEBUG_SESSION_START=PHPSTORM`, { ...params })
const listAllInvoices = (params) => client.post(`/supplychain/fifo-sc-transaction/list-all-invoices?XDEBUG_SESSION_START=PHPSTORM`, { ...params })
const searchInvoicesAll = params => client.post(`/supplychain/fifo-sc-transaction/search-invoices-all?XDEBUG_SESSION_START=PHPSTORM`, { ...params });

const getSupplierInvoice = (params, status) => client.post(`/supplychain/fifo-sc-transaction/list-supplier-invoice?XDEBUG_SESSION_START=PHPSTORM`, { ...params, status });

const getRateDetails = (params) => client.post(`/supplychain/fifo-sc-transaction/get-rate-details`, { ...params });
const updateSupplierPayment = (params) => client.post(`/supplychain/fifo-sc-transaction/update-supplier-payment?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const getBpBillingReports = params => client.post(`/supplychain/reports/get-bp-billing-reports?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const downloadBpBillReport = (params, docType) => client.post(`/supplychain/reports/download-bp-bill-reports?XDEBUG_SESSION_START=PHPSTORM`, { ...params, docType });
const verifySelectedInvoices = (params) => client.post(`/supplychain/fifo-sc-transaction/verify-selected-invoices`, { ...params });

const fetchSCfTransStatement = (params) => client.post(`/supplychain/fifo-sc-transaction/scf-client-statement`, { ...params });
const getBpTfBillingReports = params => client.post(`/supplychain/reports/get-bp-tf-billing-reports?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const downloadBpTfBillReport = (params, docType) => client.post(`/supplychain/reports/download-bp-tf-bill-reports?XDEBUG_SESSION_START=PHPSTORM`, { ...params, docType });

const fetchAgedReceivablesReport = (params, docType) => client.post(`/supplychain/reports/aged-receivables-report?XDEBUG_SESSION_START=PHPSTORM`, { ...params, docType });
const fetchTfAgedReceivablesReport = (params, docType) => client.post(`/supplychain/reports/tf-aged-receivables-report?XDEBUG_SESSION_START=PHPSTORM`, { ...params, docType });
const fetchPointTimeReport = (params, docType) => client.post(`/supplychain/reports/point-time-report`, { ...params });
const fetchPointTimeAvailabilityReport = (params, docType) => client.post(`/supplychain/reports/availability-point-time-report`, { ...params });
const fetchPointTimeOutstandingReport = (params, docType) => client.post(`/supplychain/reports/outstanding-point-time-report`, { ...params });
const checkMapping = (params) => client.post(`/supplychain/fifo-sc-transaction/check-mapping`, { ...params });
const validateInvoiceBulkUpload = (params) => client.post(`/supplychain/fifo-sc-transaction/validate-invoice-bulk-upload`, { ...params });
const directDebitList = (params, status) => client.post(`/supplychain/direct-debit/get-dd-invoices`, { ...params, status });
const directDebitListAction = (params) => client.post(`/supplychain/direct-debit/dd-change-status`, { ...params });
const fetchTransNotes = (params) => client.post(`/supplychain/fifo-sc-transaction/fetch-trans-notes`, { ...params });
const saveTransNotes = (params) => client.post(`/supplychain/fifo-sc-transaction/save-trans-notes`, { ...params });
const payableFund = (params) => client.post(`/supplychain/fifo-sc-transaction/payable-fund`, {...params });
const createPaymentRequest = (params) => client.post(`/supplychain/fifo-sc-transaction/create-payment-request`, {...params });
const GetDdReport = (params, status) => client.post(`/supplychain/direct-debit/get-dd-report`, { ...params, status });
const fetchTransactionStatement = (params) => client.post(`/supplychain/fifo-sc-transaction/fetch-transaction-statement`, {...params });  
const deleteInvoices = (params) => client.post(`/supplychain/fifo-sc-transaction/delete-invoices`, {...params });
const fetchRebateList = (params) => client.post(`/supplychain/fifo-sc-transaction/fetch-rebate-list`, {...params });
const listSaleInvoice = (params, status) => client.post(`/supplychain/fifo-sc-transaction/list-sale-invoice`, { ...params, status });
const getPayTodayCount = (params) => client.post(`/supplychain/fifo-sc-transaction/get-pay-today-count`, { ...params });
const inlineUpdate = params => client.post(`/supplychain/fifo-sc-transaction/inline-edit`, { ...params });
const fetchAuditReport = params => client.post(`/supplychain/reports/audit-report`, { ...params });
const uploadPayableBulkInvoice = params => client.post(`/core/general/create-bulk-general-invoices`, { ...params });
const updateCurrencyExchangeRate = params => client.post(`/supplychain/fifo-sc-transaction/update-exchange-rate`, { ...params });
const FetchAccumulatedFees = params => client.post(`/supplychain/fifo-sc-transaction/fetch-accumulated-fees`, { ...params }); 
const downloadStreamTransactions = params => client.post(`/supplychain/fifo-migration/download-stream-transactions`, { ...params });
const getPaymentReports = (params, docType) => client.post(`/supplychain/reports/get-payment-reports`, { ...params, docType });
const getIntroducerCommisionReports = (params, docType) => client.post(`/supplychain/reports/get-introducer-commsion-report`, { ...params, docType });
const getIntroducerCommisionStatement = (params, docType) => client.post(`/supplychain/reports/get-introducer-commision-statement`, { ...params, docType });
const getInterestOwing = params => client.post(`/supplychain/fifo-sc-transaction/get-interest-owing`, { ...params });
const directDebitBatchList = (params, status) => client.post(`/supplychain/direct-debit/get-dd-batch-report`, { ...params});
const directDebitPendingBatchList = (params, status) => client.post(`/supplychain/direct-debit/get-pending-dd-batch-report`, { ...params});
const availabilitySummaryPointInTimeAnalysis = params => client.post(`/supplychain/fifo-sc-transaction/availability-point-in-time`, { ...params});
const getInvoiceNumbersListBySupplier = params => client.post(`/supplychain/fifo-sc-transaction/get-invoice-numbers-list`, { ...params});
const checkFundingForFirstTime = params => client.post(`/core/general/check-funding-for-first-time`, { ...params});
const downloadPaymentSettlement = (params) => client.post(`/supplychain/direct-debit/download-payment-settlement`, { ...params });
const manoovaScheduledPayments = (params) => client.post(`/supplychain/direct-debit/manoova-scheduled-payments`, { ...params});
const findMaxRevisedDueDateBulkInvoices = params => client.post(`/supplychain/fifo-sc-transaction/max-revised-date-bulk-invoices`, { ...params});
const NonUtilisationReport = (params, docType) => client.post(`/supplychain/reports/non-utilisation-report`, { ...params });
const invoiceOverdueList  = params => client.post(`/supplychain/fifo-sc-transaction/get-invoice-overdue-list`, { ...params});
const GetAuditReport = params => client.post(`/supplychain/reports/get-auditlog-reports`, { ...params});
const CheckCustomerSpecialProgramEnabled = params => client.post(`/supplychain/fifo-sc-transaction/check-cust-spl-program-enabled`, { ...params });
const clientInvoiceApi  = params => client.post(`/supplychain/fifo-sc-transaction/get-client-invoice-list`, { ...params});
const creditDebitStatement = (params) => client.post(`/supplychain/reports/credit-debit-statement`, { ...params });

export default {
  getCustomersList,
  getSupplierList,
  createInvoice,
  uploadBulkInvoice,
  uploadPayableBulkInvoice,
  getInvoiceDetails,
  getInvoiceList,
  viewAttachment,
  displayFee,
  createInvoiceApproval,
  getScTabsList,
  createBulkApproval,
  getScTabsListCount,
  UpdateInvoiceStatus,
  maxRevisedDate,
  findMaxRevisedDueDateBulkInvoices,
  CreatePayment,
  AddAdditionalFee,
  getBulkApproveList,
  getFundsAvailable,
  getUsersList,
  getScCounts,
  getInvoiceSum,
  getInvoice,
  searchInvoice,
  getReports,
  downloadReport,
  getCustomerReports,
  searchCustomer,
  downloadCustomerReport,
  UpdateScInvoiceStatus,
  getRepaymentHistory,
  getDueInvoiceList,
  bulkPayNow,
  bulkpayNowSupplier,
  maxInvoiceDuedDate,
  getScTransactionStatusLog,
  updatePayment,
  deletePayment,
  getMappedUsers,
  approvalToPaid,
  getBpScTransactions,
  listAllInvoices,
  searchInvoicesAll,
  searchTraderInvoice,
  deleteAttachment,
  addInvoiceAttachment,
  getSupplierInvoice,
  getRateDetails,
  updateSupplierPayment,
  getTfInvoiceDetails,
  getBpBillingReports,
  downloadBpBillReport,
  getTfCounts,
  verifySelectedInvoices,
  fetchSCfTransStatement,
  getBpTfBillingReports,
  downloadBpTfBillReport,
  fetchAgedReceivablesReport,
  fetchTfAgedReceivablesReport,
  fetchPointTimeReport,
  fetchPointTimeAvailabilityReport,
  fetchPointTimeOutstandingReport,
  NonUtilisationReport,
  checkMapping,
  validateInvoiceBulkUpload,
  directDebitList,
  directDebitListAction,
  fetchTransNotes,
  saveTransNotes,
  payableFund,
  createPaymentRequest,
  fetchTransactionStatement,
  deleteInvoices,
  fetchRebateList,
  listSaleInvoice,
  getPayTodayCount,
  inlineUpdate,
  fetchAuditReport,
  updateCurrencyExchangeRate,
  FetchAccumulatedFees,
  downloadStreamTransactions,
  getPaymentReports,
  getIntroducerCommisionReports,
  getIntroducerCommisionStatement,
  getInterestOwing,
  directDebitBatchList,
  directDebitPendingBatchList,
  availabilitySummaryPointInTimeAnalysis,
  getInvoiceNumbersListBySupplier,
  checkFundingForFirstTime,
  downloadPaymentSettlement,
  manoovaScheduledPayments,
  invoiceOverdueList,
  clientInvoiceApi,
  GetAuditReport,
  CheckCustomerSpecialProgramEnabled,
  creditDebitStatement
};
