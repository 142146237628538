import React, { useState, useContext, useEffect } from 'react';
import Calculator from "../../../../img/icons/calculator.svg";
import { RadioButton } from "../../../common/inputs/radioButton/radioButton";
export default function Limit(props) {
    return (
        <div >

            <div className='row'>
                <div className='col-lg-5'>
                    <div className="logo text-center mb-2 mt-2  ">
                        <img src={Calculator} alt="Fifo Calculator" />
                    </div>
                    <div className='text-center'>
                        <h6>Program Limit</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. orem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                </div>
                <div className='col-lg-7'>

                    <div>
                        <div className="d-sm-flex w-100  py-3 align-items-center ">
                            <div className="custom-control custom-radio custom-control-inline"></div>
                            <div className='col-lg-10'>

                                <div className="border rounded-xl p-4 shadow-sm mb-3   font-weight-bold">


                                    <div class="card bg-secondary aligin-center"><div class="text-center"><div class="card-body py-4"><div class="row justify-content-center"><div class="col-xl-10"><h6 class="card-title text-white">Facility 1</h6><div class="text-xs text-light  "><div class="row"><div class="col-lg-12">Current Availability</div> </div></div><h4 class="text-white mt-2 text-center p-3"><span>$37,510.00</span></h4></div></div></div></div></div>
                                    <div className="form-group p-2">
                                        <label>Set the program limit.
                                        </label>
                                        <div >  <input typeof='text' className='form-control' name="purchase_order_no" value="" label="Purchase Order Number" reference="" onChange="" type="" /></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}