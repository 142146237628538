import React, { Component } from "react";
import * as moment from "moment";
import { Link } from "react-router";
import {
    FormRow,
    FormColumnLg
} from "../../../common/containers/formContainer";
import { CurrencyLabel, DateTimePicker } from "../../../common/inputs";
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import authService from "../../../../services/authService";

class TransactionStatement extends Component {

    constructor(props) {
        super(props);

    }
    state = {
        filter_list: [],
        date: "",
        selectedDate: {
            label: moment(new Date()).startOf('month').format("DD/MM/YYYY").toString(),
            value: moment(new Date()).startOf('month').format("YYYY/MM/DD").toString(),
        },
        fifoDetail: [],
        fromDate: null,
        toDate: null,
    }

    componentDidMount() {
        this.setFilterlist();
        //this.fetchFifodetail(moment(new Date()).startOf('month').format("YYYY/MM/DD").toString());
    }

    setFilterlist = () => {
        let dateList = [];
        let currentDate = "";
        const start = moment().startOf('month')
        dateList.push({
            "label": start.format('DD/MM/YYYY').toString(),
            "value": start.format('YYYY/MM/DD').toString()
        });
        for (let i = 0; i < 6; i++) {
            currentDate = start.subtract(1, 'month');
            dateList.push({
                "label": currentDate.format('DD/MM/YYYY').toString(),
                "value": currentDate.format('YYYY/MM/DD').toString()
            });
        }

        this.setState({ filter_list: dateList });
    }

    render() {
        const { organisation, email, entity_id } = authService.getCurrentUser();

        const fetchFifodetail = async (download = false, type = null) => {
            this.setState({ loading: true });
            const response = await dfTransactionService.FifoDetail(
                {
                    'client_guid': entity_id,
                    'fromDate': this.state.fromDate,
                    'toDate': this.state.toDate,
                    'download': download === true ? true : false,
                    'type': type,
                }
            );
            this.setState({ fifoDetail: response.data.rows, loading: false });
            if (download && response.data?.downloadPath) {
                downloadPdf(response.data?.downloadPath, type);
              }
        }

        const downloadPdf = async (url, type) => {
            if (type === "xlsx") {
              window.open(url, "_blank", "noreferrer");
            } else {
              const newWindow = window.open(url);
              newWindow.opener = null;
            }
          };

        // const handleDateFilter = (selectedOption) => {
        //     this.setState({ date: selectedOption.value });
        //     this.setState({
        //         selectedDate: {
        //             label: selectedOption.label,
        //             value: selectedOption.value,
        //         }
        //     });
        //     fetchFifodetail(selectedOption.value);
        // };
        return (
          <>
            {this.state.loading == true && <LoadingSpinner />}
            <FormRow className="row m-t-md">
              <FormColumnLg col={12}>
                <div className="card overflow-visible">
                  <div className="card-body">
                    <FormRow>
                      <div className="col-lg-6 mb-4">
                        <h6>Client Details:</h6>
                        <span> {organisation} </span>
                        <br />
                        <span> {email} </span>
                      </div>
                    </FormRow>

                    <FormRow className="row ">
                      <div className="col-lg-2">
                        <DateTimePicker
                          name="from_date"
                          selectedDate={this.state.fromDate}
                          onChange={({ target }) => this.setState({fromDate: target.value})}
                          enableWeekDays={true}
                          placeholder="From"
                          minDate={
                            new Date(
                              new Date().setMonth(new Date().getMonth() - 6)
                            )
                          }
                          maxDate={new Date()}
                        />
                      </div>
                      <div className="col-lg-2">
                        <DateTimePicker
                          name="to_date"
                          selectedDate={this.state.toDate}
                          onChange={({ target }) => this.setState({toDate: target.value})}
                          enableWeekDays={true}
                          placeholder="To"
                          minDate={
                            new Date(
                              new Date().setMonth(new Date().getMonth() - 6)
                            )
                          }
                          maxDate={new Date()}
                        />
                      </div>
                      <div className="col-lg-3">
                        
                      </div>

                      <div className="col-lg-5">
                        <div className="pull-right">
                      

                          <div className="dropdown-menu">
                            <Link
                              className="dropdown-item"
                              onClick={() => {
                                fetchFifodetail(true,"xlsx");
                              }}
                            >
                              Export as Excel
                            </Link>
                            <Link
                              className="dropdown-item"
                              onClick={() => {
                                fetchFifodetail(true,"pdf");
                              }}
                            >
                              Export as PDF
                            </Link>
                          </div>
                          <button
                            disabled={this.state.fifoDetail.length === 0}
                            title="More export actions"
                            className="btn btn-outline-secondary dropdown-toggle mr-2"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Export
                          </button>

                          <button
                            onClick={fetchFifodetail}
                            className="btn btn-primary mr-2"
                            disabled={!this.state.fromDate || !this.state.toDate}
                          >
                            View Statement
                          </button>
                        </div>
                      </div>
                    </FormRow>

                    <FormRow className="table-responsive m-t">
                      <table className="table table-sm    text-secondary">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Detail</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fifoDetail &&
                            this.state.fifoDetail.map((item, index) =>
                              item.detail == "Closing balance" ||
                              item.detail == "Opening Balance" ? (
                                <>
                                  <tr key={index}>
                                    <td>{item.date}</td>
                                    <td>
                                      <strong>{item.detail}</strong>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <strong>
                                        <CurrencyLabel
                                          value={item.balance}
                                        ></CurrencyLabel>
                                      </strong>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td>{item.date}</td>
                                    <td>{item.detail}</td>
                                    <td>
                                      <CurrencyLabel
                                        value={item.debit}
                                      ></CurrencyLabel>
                                    </td>
                                    <td>
                                      <CurrencyLabel
                                        value={item.credit}
                                      ></CurrencyLabel>
                                    </td>
                                    <td>
                                      <CurrencyLabel
                                        value={item.balance}
                                      ></CurrencyLabel>
                                    </td>
                                  </tr>
                                </>
                              )
                            )}
                        </tbody>
                      </table>
                    </FormRow>
                  </div>
                </div>
              </FormColumnLg>
            </FormRow>
          </>
        );
    }
}
export default TransactionStatement;