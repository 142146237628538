import React, { useContext, useEffect, useState } from "react";
import { WarningCard } from "../components/cards";
import EntityContext from "../../../context/entityContext";
import authService from "../../../services/authService";
import coreService from "../../../services/coreService";
import useApi from '../../../hooks/useApi';
import { CurrencyLabel } from "../../common/inputs";

function GeneralProcessingPending({ setProductRoleType, col, flip, noCard = false, flipCardCount, flipCardCountTooltip = '' }) {

    const { constants } = authService.getCurrentUser();
    const { productTypes } = useContext(EntityContext);
    const [processingPending, setProcessingPending] = useState({
        topLabel: 'Pending Processing',
        topValue: 0,
        subTitle: 'Transaction pending processing',
        leftValue: 0,
        leftLabel: 'Customers',
        rightValue: 0,
        rightLabel: 'Invoices',
        primaryBtnRoute: '/client/customer-programs/general-invoice/list'
    });

    const generalPendingSummaryApi = useApi(coreService.GeneralPendingSummary);

    const fetchData = async () => {
        const { data } = await generalPendingSummaryApi.request({ clientSide: true });

        if (data.success == true) {
            let cardData = processingPending;
            cardData['topValue'] = <CurrencyLabel value={data.response.invoice_amount} />;
            cardData['leftValue'] = data.response.customers;
            cardData['rightValue'] = data.response.invoices;
            setProcessingPending(cardData);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                productTypes !== undefined && productTypes.length > 0 &&
                productTypes.filter(obj => (obj.value == constants.PRODUCTS.SUPPLYCHAIN || obj.value == constants.PRODUCTS.TRADEFINANCE)).length == 2 && (
                    <WarningCard
                        loading={generalPendingSummaryApi.loading}
                        data={processingPending}
                        setProductRoleType={setProductRoleType}
                        flipCardCount={flipCardCount}
                        flipCardCountTooltip={flipCardCountTooltip}
                        col={col ? col : 12}
                        flip={flip ? flip : false}
                        noCard={noCard}
                    />
                )
            }
        </>
    )
}
export default GeneralProcessingPending;