import React from "react";
import _ from "lodash";
import { CurrencyLabel } from "../../common/inputs";
import * as moment from "moment";

export default function CommonHeader({ transData, invoices, totalInvAmt, totalDisbursed }) {
  return (
    <>
      <div className="row mb-4  bg-light b-dashed-2 p-2"  >
        <div className="col-sm-3"><h6 className="mt-0">{transData.trans_client_reference}</h6><p className="mb-0  text-xs  text-muted">Reference Number</p></div>
        <div className="col-sm-3"><h6 className="mt-0"> <span className="font-weight-bold"> <CurrencyLabel
          value={
            totalInvAmt
              ? totalInvAmt
              : parseFloat(
                _.sumBy(invoices, (item) => Number(item.inv_amount))
              )
          }
        ></CurrencyLabel></span> </h6><p className="mb-0  text-xs  text-muted"> Total Inv Amt</p></div>
        <div className="col-sm-2"><h6 className="mt-0"><span>{invoices ? invoices.length : transData.inv_count}</span> </h6><p className="mb-0  text-xs  text-muted">No. of Invoices</p></div>
        <div className="col-sm-2"><h6 className="mt-0"><span>{transData.advance_date ? moment(transData.advance_date, 'YYYY-MM-DD').format("DD/MM/YYYY").toString() : null}</span> </h6><p className="mb-0  text-xs  text-muted">Advance Date</p></div>
        <div className="col-sm-2"><h6 className="mt-0"> 
          <span className="font-weight-bold"> 
            <CurrencyLabel
              value={totalDisbursed ? totalDisbursed : 0}
            ></CurrencyLabel> 
          </span></h6>
          <p className="mb-0  text-xs  text-muted">Total Advanced</p>
        </div>
      </div>

    </>
  );
}
