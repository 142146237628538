import React, { Component } from "react";
import Form from "../../common/Form";
import Modal from "react-bootstrap/Modal";

import { InputGroup } from "../../common/containers/formContainer";
import { AddButton } from "../../common/containers/button";
import InvCreation from "./invoiceCreation";
import { CountriesList, BPList, ClientsList } from "../../slplusTools/index";
import auth from "../../../services/authService";

class AddInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      invoice_type: "customer",
      invoiceCreation: this.props.invoiceCreation,
      loading: false,
      openCustomer: false,
    };
  }
  componentDidMount() {
    const { constants, category, entity_id } = auth.getCurrentUser();
    if (constants.CATEGORY.BP === category) this.setState({ owner: entity_id });
  }

  setInvoiceCreation = () => {
    this.setState({ invoiceCreation: false });
  };
  setInvoiceType = (type) => {
    this.setState({ invoice_type: type });
  };

  setValues = (selectedValue, value) => {
    this.setState({ [selectedValue]: value });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onOpenModalCustomer = () => {
    this.setState({ openCustomer: true });
  };
  onCloseModalCustomer = () => {
    this.setState({ openCustomer: false });
  };

  render() {
    const { category, constants } = auth.getCurrentUser();
    return (
      <React.Fragment>
        <Modal
          backdrop="static"
          show={this.state.invoiceCreation}
          onHide={(e) => {
            this.props.onClose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>New Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup
              label={
                category == constants.CATEGORY.INTL_OFFICE ? "Country" : ""
              }
            >
              <CountriesList
                selectedCountry={this.state.country}
                onSelectItem={({ id }) => this.setValues("country", id)}
              />
            </InputGroup>

            <InputGroup
              label={
                category == constants.CATEGORY.INTL_OFFICE ||
                category == constants.CATEGORY.COUNTRY_OFFICE
                  ? "Business Partner"
                  : ""
              }
            >
              <BPList
                selectedBp={this.state.owner}
                type="horizontal"
                country={this.state.country}
                onSelectItem={({ value }) => this.setValues("owner", value)}
              />
            </InputGroup>

            {this.state.invoice_type == "customer" && (
              <InputGroup label="Clients">
                {" "}
                <ClientsList
                  selectedCustomer={this.state.customer}
                  bp={this.state.owner}
                  onSelectItem={(option) => {
                    this.setValues("customer", option.value);
                  }}
                />{" "}
              </InputGroup>
            )}
          </Modal.Body>
          <Modal.Footer>
            <AddButton
              label="Next "
              style="btn btn-primary "
              disabled={this.state.customer ? false : true}
              onClick={(e) =>
                this.setState({ invoiceCreation: false }, () => {
                  this.state.invoice_type == "customer"
                    ? this.setState({ openCustomer: true })
                    : this.setState({ open: true });
                })
              }
            >
              Add
            </AddButton>
          </Modal.Footer>
        </Modal>

        {this.state.openCustomer && (
          <InvCreation
            bp={this.state.owner}
            a_guid={this.state.customer}
            selectedRow={this.props.selectedRow}
            onClose={(e) => {
              this.setState({ invoiceCreation: false });
              this.props.onClose();
              !this.props.bulkUpload && this.props.handleReload();
            }}
            bulkUpload={this.props.bulkUpload}
            handleReloadData={this.props.handleReload}
            handleTab={this.props.handleTab}
          ></InvCreation>
        )}
      </React.Fragment>
    );
  }
}

export default AddInvoice;
