import React, { useEffect, useState, useRef } from "react";
import { CurrencyLabel } from "../../../common/inputs/currencyInput/currencyInput";
import { Doughnut } from "react-chartjs-2";
import auth from "../../../../../src/services/authService";
import { Link } from "react-router";
function DoughnutCard({
  graphData = {},
  type = null,
  fecilityLimit = 0,
  bgColor = "primary",
  empty = false,
  account_type = 0,
  feciltyCount = 1,
  menu = null,
  onClick = null,
  bankDetail = null,
  to = "/client/home",
  nickName = null
}) {

  const cardTitleRef = useRef();
  const accountNameRef = useRef();

  const [graphLabels, setGraphLabels] = useState({});
  const [graphValues, setGraphValues] = useState({});
  const [graphBg, setGraphBg] = useState({});
  const [graphBorder, setGraphBorder] = useState({});
  const [cardToggle, setCardToggle] = useState(false);
  //-------Customizing currency symbol for AUS----
  const getCurrency = () => {
    let currency = "USD";
    const user = auth.getCurrentUser();
    if (user.currency_sign == "AUD") return currency;
    else return user.currency_sign;
  };
  const data = {
    labels: graphLabels,
    datasets: [
      {
        data: [graphValues[0], graphValues[1], graphValues[2], graphValues[3]],
        /*   backgroundColor: [
                       'rgba(56, 203, 137, 1)',
                       'rgba(255, 200, 92, 1)',
                       'rgba(237, 85, 101, 1)',
                       'rgba(17, 105, 142, 1)',
   
                   ],
                   borderColor: [
                       'rgba(56, 203, 137, 1)',
                       'rgba(255, 200, 92, 1)',
                       'rgba(237, 85, 101, 1)',
                       'rgba(17, 105, 142, 1)',
   
                   ],*/
        backgroundColor: [
          graphBg[0] ? graphBg[0] : "#38cb89",
          graphBg[1] ? graphBg[1] : "#ffc85c",
          graphBg[2] ? graphBg[2] : "#ed5565",
          graphBg[3] ? graphBg[3] : "#11698e",
        ],
        hoverBackgroundColor: [
          graphBorder[0] ? graphBorder[0] : "#38cb89",
          graphBorder[1] ? graphBorder[1] : "r#ffc85c",
          graphBorder[2] ? graphBorder[2] : "#ed5565",
          graphBorder[3] ? graphBorder[3] : "#11698e",
        ],
        hoverBorderColor: [
          graphBorder[0] ? graphBorder[0] : "#38cb89",
          graphBorder[1] ? graphBorder[1] : "#ffc85c",
          graphBorder[2] ? graphBorder[2] : "#ed5565",
          graphBorder[3] ? graphBorder[3] : "#11698e",
        ],
        borderColor: [
          graphBorder[0] ? graphBorder[0] : "#38cb89",
          graphBorder[1] ? graphBorder[1] : "#ffc85c",
          graphBorder[2] ? graphBorder[2] : "#ed5565",
          graphBorder[3] ? graphBorder[3] : "#11698e",
        ],
        borderWidth: 2,
      },
    ],
  };
  //---------------------------------------------------------------------------
  const emptyData = {
    labels: graphLabels,
    datasets: [
      {
        data: [100],

        backgroundColor: [
          graphBg[0] ? graphBg[0] : bgColor == "secondary" ? "#11698e" : "#9DDAC6"

        ],
        hoverBackgroundColor: [
          graphBorder[0] ? graphBorder[0] : bgColor == "secondary" ? "#11698e" : "#9DDAC6"
        ],
        hoverBorderColor: [
          graphBorder[0] ? graphBorder[0] : bgColor == "secondary" ? "#11698e" : "#9DDAC6"
        ],
        borderColor: [
          graphBorder[0] ? graphBorder[0] : bgColor == "secondary" ? "#11698e" : "#9DDAC6"
        ],
        borderWidth: 2,
      },
    ],
  };
  //---------------------------------------------------------------------------
  const options = {


    tooltips: {


      position: "nearest",

      callbacks: {
        title: (tooltipItem, data) => {
          return data["labels"][tooltipItem[0]["index"]];
        },

        label: (tooltipItem, data) => {
          var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: getCurrency(),
          });
          let amount = 0;
          if (empty == false) {
            amount = data["datasets"][0]["data"][tooltipItem["index"]];
          }

          return formatter.format(amount);
        },
      },
    },
    arc: {
      roundedCornersFor: 0,
    },
    cutoutPercentage: 75,

    responsive: true,
    legend: {
      display: false,
    },
  };
  const plugins = [
    {
      //-----Setting availabilty amount  at center of the graph---------------
      beforeDraw: function (chart) {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: getCurrency(),
        });
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.fillStyle = "#fff";
        ctx.textBaseline = "middle";
        var text = formatter.format(fecilityLimit),
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
      afterUpdate: function (chart) {
        var a = chart.config.data.datasets.length - 1;

        for (let i in chart.config.data.datasets) {
          for (
            var j = chart.config.data.datasets[i].data.length - 1;
            j >= 0;
            --j
          ) {
            if (Number(j) == chart.config.data.datasets[i].data.length)
              continue;
            var arc = chart.getDatasetMeta(i).data[j];
            if (chart.config.data.datasets[i].data[j] > 0)
              bgColor = arc._model.backgroundColor;
            else bgColor = "";

            arc.round = {
              x: (chart.chartArea.left + chart.chartArea.right) / 2,
              y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
              radius:
                chart.innerRadius +
                chart.radiusLength / 2 +
                a * chart.radiusLength,
              thickness: chart.radiusLength / 2 + 1,
              backgroundColor: bgColor,
            };
          }
          a--;
        }
      },
      //-------------Drawing circles on the edge-------------------------
      afterDatasetsDraw: function (chart) {
        var ctx = chart.chart.ctx;

        for (let i in chart.config.data.datasets) {
          for (var j = chart.config.data.datasets[i].data.length - 1; j >= 0; --j) {
            /*  if (Number(j) == chart.config.data.datasets[i].data.length - 1)
                continue;*/
            //---------------Drawing curved filling---------------------------------------
            var arc = chart.getDatasetMeta(i).data[j];
            var graphVal = chart.config.data.datasets[i].data[j];
            if (graphVal > 0) {
              var startAngle = Math.PI / 2 - arc._view.startAngle;
              var endAngle = Math.PI / 1.9950 - arc._view.endAngle;

              ctx.save();
              ctx.translate(arc.round.x, arc.round.y);
              ctx.fillStyle = arc.round.backgroundColor;
              ctx.beginPath();
              ctx.arc(arc.round.radius * Math.sin(startAngle), arc.round.radius * Math.cos(startAngle), arc.round.thickness, 0, 2 * Math.PI);
              ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
              ctx.closePath();
              ctx.fill();
              ctx.restore();


              ctx.restore();
            }
            //--------------------------------------------------------------------------
          }
        }

      }
    },
  ];
  //----------------------------------------------------
  useEffect(() => {
    const graphLabelset = graphData.map((obj) => obj.name);
    const graphValueset = graphData.map((obj) => obj.value);
    const graphBgset = graphData.map((obj) => obj.bg);
    const graphBorderset = graphData.map((obj) => obj.border);

    setGraphValues(graphValueset);
    setGraphLabels(graphLabelset);
    setGraphBg(graphBgset);
    setGraphBorder(graphBorderset);
  }, [graphData]);

  const setcardTitle = (feciltyCount) => {
    let title = "";
    let fullTitle = "Facility " + feciltyCount + ' : ';
    let F_title = "";

    if (nickName) {
      title = nickName;
      fullTitle += nickName;
      F_title = `F${feciltyCount}`;
    }

    let width = cardTitleRef.current ? cardTitleRef.current.offsetWidth : 0;
    if (width > 0) {
      let maxWidth = (~~(parseInt(width) / 10)) + 1;
      if (title.length > maxWidth) {
        let title_cut = title.substring(0, maxWidth);
        title = title_cut + '...';
      }
    }

    return (
      <>
        <span className="card-content-hide"> {F_title} : </span>
        <span title={fullTitle}>{title}</span>
      </>

    );

    //return `<span title="${fullTitle}">${title}</span>`;
  }

  const setBankAccountName = accountName => {
    if (accountName) {
      let title = accountName;
      let width = accountNameRef.current ? accountNameRef.current.offsetWidth : 0;
      if (width > 0) {
        let maxWidth = (~~(parseInt(width) / 10)) + 1;
        if (title.length > maxWidth) {
          let title_cut = title.substring(0, maxWidth);
          title = title_cut + '...';
        }
      }
      return title;
    } else return ''
  }

  return (
    <>
      <div className={`card min-ht  aligin-center  bg-${bgColor}`}>
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <h6 className="card-title text-white">
                <div ref={cardTitleRef}>

                  {setcardTitle(feciltyCount)}
                </div>
                {/* <span className="card-content-hide"> FACILITY {feciltyCount}:</span>  ACCOUNTS{" "} */}

                {/* { bankDetail ? bankDetail.nick_name ? bankDetail.nick_name 
                : `ACCOUNTS ${ account_type == 0 ? " PAYABLE" : " RECEIVABLE" }` : null} */}

              </h6>
              <div className="text-xs text-light mb-4 opacity-75">
                {
                  type == 'show_bank_detail' ? (
                    <>
                      <div className="row">
                        <div ref={accountNameRef} className="col-lg-12">
                          Account Name: {setBankAccountName(bankDetail ? bankDetail.bank_account_name : null)}
                        </div>
                        <div className="col-lg-12 card-content-hide">
                          BSB: {bankDetail ? bankDetail.bsb_number : null}&nbsp;Account Number: {bankDetail ? bankDetail.account_number : null}
                        </div>
                      </div>

                    </>
                  ) : (
                    <>
                      {type == "single" && account_type == 0
                        ? "Your Single Invoice facility and purchase invoices from suppliers on this facility"
                        : type == "batch" && account_type == 0
                          ? "Your Whole of book facility and purchase invoices from suppliers on this facility"
                          : type == "tf" && account_type == 0
                            ? "Your TF Invoice facility and purchase invoices from suppliers on this facility"
                            : account_type == 0
                              ? "Your SCF facility and purchase invoices from suppliers on this facility"
                              : ""}

                      {type == "single" && account_type == 1
                        ? "Your Single Invoice facility and purchase invoices from suppliers on this facility"
                        : type == "batch" && account_type == 1
                          ? "Your Whole of book facility and purchase invoices from suppliers on this facility"
                          : type == "tf" && account_type == 1
                            ? "Your TF Invoice facility and purchase invoices from suppliers on this facility"
                            : account_type == 1
                              ? "Your SCF facility and purchase invoices from suppliers on this facility"
                              : ""}
                    </>
                  )
                }
              </div>
            </div>

            {menu && (
              <div className="ml-auto">
                <div className="btn-group ml-2 mb-0">
                  <a href="/client/settings">
                  </a><a className="btn-link" style={{ color: "white" }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#"><i className="fa fa-ellipsis-v" /></a>
                  <div className="dropdown-menu dropdown-menu-right shadow">
                    {menu.map((item, index) =>
                      <Link key={index} className="dropdown-item" style={{ cursor: 'pointer' }} onClick={item.onClick} to={item.route}>{item.label}</Link>
                    )}
                  </div>
                </div>
              </div>
            )}

          </div>
          {empty == false ? (
            <>
              <Link to={to} className="row text-center def-card-h mb-3" onClick={onClick}>
                <div className="col-md-12">
                  <div id="my-doughnut-chart-1" className="align-center relative my-1">
                    <Doughnut
                      data={data}
                      options={options}
                      plugins={plugins}
                      width={250}
                    />
                  </div>
                  <div id="card-info" className={`${!cardToggle ? "   card-content-hide" : ""}`}>
                    {graphData.map((item, key) => (
                      <React.Fragment key={key}>
                        {item.icon != null && (
                          <>
                            <div className="row align-items-center mt-4">
                              <div className="col-auto card-content-hide">
                                <div
                                  className={`media-icon media-icon-md bg-${item.color}-transparent mt-0`}
                                >
                                  <i className={`fa ${item.icon} text-${item.color}`} />
                                </div>
                              </div>
                              <div className="col">
                                <div className="progress h-5 mt-2">
                                  <div
                                    aria-valuemax={100}
                                    aria-valuemin={0}
                                    aria-valuenow={70}
                                    className={`progress-bar w-100 bg-${item.color}`}
                                    role="progressbar"
                                  />
                                </div>
                                <p className="mb-2 mt-1 text-light   text-left text-xs">
                                  {item.name}
                                  <span className="float-right ">
                                    <CurrencyLabel decimal={2} value={item.value}></CurrencyLabel>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </Link>
              <div title={` ${cardToggle ? "Show Less" : "Show More"}  `} onClick={() => { setCardToggle(!cardToggle) }} className="pt-2 text-white mt-2 cursor-link" id="down-arrow"><i className={`fa fa-angle-double-${cardToggle ? "up" : "down"} fa-2x`} aria-hidden="true"></i></div>
            </>
          ) : (
            <>
              <Link to={to} className="row  def-card-h  justify-content-center mb-3" onClick={onClick}>
                <div className="col">
                  <Doughnut
                    data={emptyData}
                    options={options}
                    plugins={plugins}
                    width={250}
                  />
                  <div className={`${!cardToggle ? "   card-content-hide" : ""}`} >
                    <div id="card-info" className={`alert  mt-5 mb-5 font-weight-bold text-center text-white border `} style={{}}>You have no transactions!</div>
                    <div className={` ${cardToggle ? "h-120" : "h-auto"}`}></div>
                  </div>
                </div>
              </Link>
              <div title={` ${cardToggle ? "Show Less" : "Show More"}  `} onClick={() => { setCardToggle(!cardToggle) }} className="pt-2 text-white mt-2 cursor-link" id="down-arrow">
                <i className={`fa fa-angle-double-${cardToggle ? "up" : "down"} fa-2x`} aria-hidden="true"></i>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DoughnutCard;
