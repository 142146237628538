import React, { useEffect, useContext, useState } from "react";
import { DangerTypeTwoCard } from "../../components/cards";
import useApi from '../../../../hooks/useApi';
import EntityContext from "../../../../context/entityContext";
import loanService from "../../../loan/services/loanTransactions";
import { CurrencyLabel } from "../../../common/inputs";

function OverDue() {

    const { selectedBusinessType, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);

    //------------Api Service--------------------------------------------
    const paymentOverviewApi = useApi(loanService.getPaymentOverview);

    const fetchData = async () => {
        const {data} = await paymentOverviewApi.request({ clientReportType : true});
        if(data.response !== undefined){
            console.log(data.response)
            setData({
                topLabel: "Due / Overdue",
                topValue: <CurrencyLabel value={data.response.overdue} />,
                leftLabel: "Total Interest",
                leftValue: <CurrencyLabel value={data.response.lpf_collected} />,
                rightLabel:"Loans",
                rightValue: data.response.loan,
                //primaryBtnRoute: '/client/loan-list'
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>

            <DangerTypeTwoCard
                loading={paymentOverviewApi.loading}
                data={data}
                qrystring={true}
            />
        </>
    )
}
export default OverDue;