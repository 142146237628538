import React, { useState, useEffect, useContext } from "react";
import ProgramView from "../../pages/epProgramme/programView";
import SupplierProgramContext from "../../../../context/supplierProgramContext";
import rate_services from "../../../supply-chain/services/rate_services";
import authService from "../../../../services/authService";
import useApi from "../../../../hooks/useApi";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import EpLogo from "../../../../img/icons/ep-program.svg";
import { useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import EntityContext from "../../../../context/entityContext";

export default function EpProgramme(props) {
  const location = useLocation();
  const isEpProgrammeRoute = location.pathname === "/client/ep-programme";
  const [view, setView] = useState(isEpProgrammeRoute);
  const [supplierModal, setSupplierModal] = useState(false);
  const [supplierProgram, setSupplierProgram] = useState(false);
  const [data, setData] = useState({});
  const [rateDetails, setRateDetails] = useState({});
  const [balanceLimit, setBalanceLimit] = useState(0);
  const [sProgram, setSprogram] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [proceedNext, setProceedNext] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [defaultRateLoader, setDefaultRateLoader] = useState(false);
  const [finalStage, setFinalStage] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [defaultRatearray, setDefaultRate] = useState([]);
  const [programId, setProgramId] = useState();
  const [pId, setPid] = useState();
  const [viewShow, setViewShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [inviteSupplier, setInviteSupplier] = useState([]);
  const [showPopout, setShowPopout] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [supplierDetails, setSupplierDetails] = useState({});
  const [model, setModel] = useState(false);
  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [selectedFundedBy, setSelectedFundedBy] = useState({
    label: "",
    value: "",
    name: "funded_by",
  });
  const [selectedDiscountRate, setSelectedDicountRate] = useState({
    label: "",
    value: "",
    name: "discount_rate_type",
  });

  const [searchKey, setSearchKey] = useState("");
  const [secStepSelect1, setSecSelectStep1] = useState(false);
  const [secShow, setSecShow] = useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionModal, setTransactionModal] = useState(false);
  const [transactionLoader, setTransactionLoader] = useState(true);
  //------------Api Service--------------------------------------------
  const supplierList = useApi(rate_services.supplierList);
  const CreateNewProgram = useApi(rate_services.createProgram);
  const defaultRate = useApi(rate_services.rateDetail);
  const getProgram = useApi(rate_services.getProgram);
  const { entity_id, constants } = authService.getCurrentUser();
  const supplierData = useApi(rate_services.supplierDetails);
  const invite_supplier = useApi(rate_services.invite_supplier);
  const epTransaction = useApi(rate_services.epTransactionList);

  const queryParams = new URLSearchParams(location.search);
  const programGuid = queryParams.get("p_guid");

  const { entityDetails } = useContext(EntityContext);
  useEffect(() => {
    if (programGuid) {
      localStorage.setItem("programId", JSON.stringify(programGuid));
      setProgramId(programGuid);
    }
  }, [programGuid]);

  useEffect(() => {
    localStorage.setItem("view", JSON.stringify(view));
  }, [view]);

  useEffect(() => {
    const storedViewShow = localStorage.getItem("viewShow");
    const storedEditShow = localStorage.getItem("editShow");

    if (storedViewShow) {
      setViewShow(JSON.parse(storedViewShow));
    }
    if (storedEditShow) {
      setEditShow(JSON.parse(storedEditShow));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("programId", JSON.stringify(programId));
  }, [programId]);

  const fetchSupplier = async () => {
    const { data } = await supplierList.request({
      a_guid: entity_id,
      product: 5,
      searchKey: searchKey,
    });

    setSupplier(data);
  };

  const fetchDefaultRate = async () => {
    setDefaultRateLoader(true);
    const { data } = await defaultRate.request({
      a_guid: entity_id,
      product: 5,
    });
    setDefaultRateLoader(false);
    setDefaultRate(data.defaultValues);
    setRateDetails(data.rateDetails);
    setBalanceLimit(data.balance_limit);
  };

  const fetchSupplierData = async () => {
    setIsLoading(true);
    try {
      const { data } = await supplierData.request({ a_guid: entity_id });
      setIsLoading(false);
      setSupplierDetails(data.rows);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const programs = useApi(rate_services.listPrograms);

  const fetchList = async () => {
    setListLoading(true);
    const { data } = await programs.request({
      isAllList: true,
      client_id: entity_id,
    });
    setList(data.rows);
    setListLoading(false);
  };

  useEffect(() => {
    fetchSupplier();
    fetchDefaultRate();
    fetchSupplierData();
    fetchList();
  }, [searchKey]);

  const fetchProgram = async () => {
    const { data } = await getProgram.request({
      program_id: programId,
    });

    if (data.rows) {
      setData(data.rows);
      setSelectedSuppliers(data.rows.supplierProgramMapping);
    }
  };

  useEffect(() => {
    fetchProgram();
  }, [programId, pId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "discount_rate" ||
      name === "minimum_credit_score" ||
      name === "discount_percentage" ||
      name === "no_of_days"
    ) {
      const inputValue = value;
      const integerRegex = /^[+-]?\d*\.?\d*$/;
      if (!integerRegex.test(inputValue)) {
        e.preventDefault();
        toast.error("Enter numeric value only");
      } else {
        const updatedData = { ...data };
        updatedData[name] = value;
        setData(updatedData);
        validateDiscountRate();
      }
    } else {
      const updatedData = { ...data };
      updatedData[name] = value;
      setData(updatedData);
    }
  };

  const handleLimitChange = (val1, val2, value) => {
    const updatedData = { ...data };
    updatedData["program_limit"] = value;
    setData(updatedData);
  };

  const handleDateChange = (newDate) => {
    if (newDate !== null) {
      setData((prevData) => ({
        ...prevData,
        commencement_date: newDate.toLocaleDateString("en-CA"),
        client_id: entity_id,
      }));
    }
  };
  const handleLiveDateChange = (newDate) => {
    if (newDate !== null) {
      setData((prevData) => ({
        ...prevData,
        program_date: newDate.toLocaleDateString("en-CA"),
        client_id: entity_id,
      }));
    }
  };

  const handlePickerChange = (e) => {
    const newData = { ...data };
    newData[e.name] = e.value;
    setData(newData);
    if (e.name === "discount_rate_type") {
      setSelectedDicountRate(e);
      setSelectedRadio(e.value);
    } else {
      setSelectedFundedBy(e);
    }
  };
  const validateRate = () => {
    if (data?.discount_rate) {
      if (data.funded_by === "fifo") {
        if (
          parseFloat(data?.discount_rate) >=
          parseFloat(rateDetails?.supplierRates[0].fee_value)
        ) {
        } else {
          toast.error("Rate should not below the supplier day rate");
        }
      } else if (data.funded_by === "myself") {
        if (
          parseFloat(data?.discount_rate) >=
          parseFloat(rateDetails?.rateGeneralSettings?.suppler_discount_rate)
        ) {
        } else {
          toast.error("Rate should not below the supplier discount rate");
        }
      } else {
      }
    }
  };
  const validateLimit = () => {
    if (data.program_limit) {
      // if (
      //   data.funded_by === "fifo" &&
      //   parseFloat(data.program_limit) >
      //     parseFloat(
      //       entityDetails.productSettings.find(
      //         (item) => item.id == constants.PRODUCTS.SUPPLYCHAIN
      //       ).facility_limit === null
      //         ? 0
      //         : entityDetails.productSettings.find(
      //             (item) => item.id == constants.PRODUCTS.SUPPLYCHAIN
      //           ).facility_limit
      //     )
      // ) {
      if (
        data.funded_by === "fifo" &&
        parseFloat(data.program_limit) > parseFloat(balanceLimit)
      ) {
        toast.error("Program limit exceeds balance limit");
      } else {
      }
    }
  };
  const validateDiscountRate = () => {
    let isValid = false;
    let creditScoreValid = false;
    let commencement = false;
    let liveDate = false;
    let discountRate = false;

    if (data.funded_by === "myself" || data.funded_by === "fifo") {
      if (data?.discount_rate) {
        if (data.funded_by === "fifo") {
          if (
            parseFloat(data?.discount_rate) >=
            parseFloat(rateDetails?.supplierRates[0].fee_value)
          ) {
            discountRate = true;
          } else {
            // toast.error("Rate should not below the day rate");
            discountRate = false;
          }
        } else if (data.funded_by === "myself") {
          if (
            parseFloat(data?.discount_rate) >=
            parseFloat(rateDetails?.rateGeneralSettings?.suppler_discount_rate)
          ) {
            discountRate = true;
          } else {
            discountRate = false;
          }
        } else {
          discountRate = true;
        }
      } else {
        discountRate = false;
      }

      if (
        (data.discount_rate_type === "dynamic" &&
          data?.no_of_days &&
          data?.discount_percentage) ||
        (data.discount_rate_type === "fixed" &&
          data?.no_of_days &&
          data?.discount_percentage) ||
        data.discount_rate_type === "flexible"
      ) {
        isValid = true;
      } else {
        isValid = false;
      }

      if (isValid && data?.program_name) {
        if (data?.commencement_date) {
          commencement = true;
        } else {
          commencement = false;
        }

        if (data?.program_date) {
          liveDate = true;
        } else {
          liveDate = false;
        }

        if (parseFloat(data?.minimum_credit_score)) {
          creditScoreValid = true;
        } else {
          creditScoreValid = false;
        }

        if (data.program_limit) {
          if (
            data.funded_by === "fifo" &&
            parseFloat(data.program_limit) > parseFloat(balanceLimit)
          ) {
            isValid = false;
            //toast.error("Program limit should not exceed the facility limit");
          } else {
            isValid = true;
          }
        } else {
          isValid = false;
        }
      } else {
        isValid = false;
      }
    }
    isValid =
      isValid && creditScoreValid && commencement && liveDate && discountRate;
    setProceedNext(isValid);
  };

  const handleSubmit = async () => {
    const { response: invite_response } = await invite_supplier.request(data);
    setSuccessLoader(true);
    const { data: response } = await CreateNewProgram.request({
      data,
    });

    if (response.success === true) {
      setShowSubmit(true);
      setSuccessLoader(false);
      setFinalStage(false);
    } else {
      setFinalStage(true);
      setShowSubmit(false);
      setSuccessLoader(true);
      toast.error("Failed to create program");
    }
  };
  const handleNextChange = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="custom-delete-ui text-white">
          <table className="table text-white">
            <thead>
              <th>Sl No</th>
              <th>Supplier Name</th>
              <th>Program Name</th>
            </thead>
            <tbody>
              {data?.supplierProgramMapping &&
                data.supplierProgramMapping
                  .filter(
                    (item) =>
                      "supplier_program" in item &&
                      item.supplier_program !== null &&
                      !item.removed &&
                      item.checked
                  )
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.supplier_name}</td>
                      <td>{item.supplier_program}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
          <br />
          <span className="font-weight-bold text-white">
            These suppliers are already there in other programs , do you want to
            add them ?
          </span>
          <p className="mt-2">
            <button
              className="btn btn-white ml-2 mb-4 mb-4"
              onClick={() => {
                onClose();
                setSprogram(false);
                handleNoOtherPrograms();
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary ml-2 mb-4"
              onClick={() => {
                onClose();
                handleOtherPrograms();
              }}
            >
              Yes
            </button>
          </p>
        </div>
      ),
      title: "Confirm to Deactivate",
      message: "Are you sure want to continue?",
    });
  };

  const handleNoOtherPrograms = () => {
    const updatedData = data.supplierProgramMapping.filter(
      (item) =>
        (item.supplier_program === null ||
          item.supplier_program === undefined ||
          item.supplier_program === "") &&
        (item.supp_program === false ||
          item.supp_program === undefined ||
          item.supp_program === "") &&
        item.checked === true
    );
    console.log("updatedData", updatedData);

    console.log("updatedData", updatedData);

    setData({ ...data, supplierProgramMapping: updatedData });
    setProceedNext(false);
  };
  const handleOtherPrograms = () => {
    // const updatedData = data.supplierProgramMapping.map((item) => {
    //   if (item.supplier_program !== null && item.checked === true) {
    //     return { ...item, supp_program: true, removed: true };
    //   }
    //   return item;
    // });

    const updatedData = data.supplierProgramMapping
      .map((item) => {
        if (item.supplier_program !== null && item.checked === true) {
          return { ...item, supp_program: true, removed: true };
        } else if (item.supplier_program !== null && item.checked === false) {
          if ("id" in item) {
            return { ...item, supp_program: true, removed: true };
          } else {
            return undefined;
          }
        } else if (item.supplier_program === null && item.checked === false) {
          return undefined;
        } else {
          return { ...item, supp_program: true, removed: false };
        }
      })
      .filter((item) => item !== undefined);

    setData({ ...data, supplierProgramMapping: updatedData });
    setProceedNext(false);
  };

  const showTransacionList = async (program_id) => {
    setTransactionLoader(true);
    localStorage.setItem("viewShow", JSON.stringify(false));
    setViewShow(false);
    localStorage.setItem("editShow", JSON.stringify(false));
    setEditShow(false);
    setModel(false);

    localStorage.setItem("programId", program_id);
    setProgramId(program_id);

    try {
      const { data } = await epTransaction.request({
        program_id: program_id,
        client_id: entity_id,
        product: 5,
      });
      setTransactionModal(true);
      setTransactions(data.rows);
      setTransactionLoader(false);
    } catch (error) {}
  };

  console.log("DATA", data);
  console.log("selectedSuppliers", selectedSuppliers);
  const value = {
    data,
    setData,
    setView,
    view,
    handleChange,
    handlePickerChange,
    handleDateChange,
    handleLiveDateChange,
    selectedRadio,
    rateDetails,
    proceedNext,
    setProceedNext,
    validateDiscountRate,
    showSubmit,
    handleSubmit,
    supplierList,
    defaultRatearray,
    setProgramId,
    setViewShow,
    viewShow,
    setEditShow,
    editShow,
    fetchSupplier,
    setInviteSupplier,
    inviteSupplier,
    setShowPopout,
    showPopout,
    supplierDetails,
    setSupplierDetails,
    isLoading,
    model,
    setModel,
    list,
    handleLimitChange,
    fetchSupplierData,
    fetchDefaultRate,
    fetchList,
    setSupplierProgram,
    supplierProgram,
    handleNextChange,
    setSprogram,
    sProgram,
    programId,
    selectedFundedBy,
    setSelectedFundedBy,
    selectedDiscountRate,
    setSelectedDicountRate,
    setSearchKey,
    searchKey,
    setSecSelectStep1,
    secStepSelect1,
    secShow,
    setSecShow,
    supplier,
    setSelectedSuppliers,
    selectedSuppliers,
    listLoading,
    successLoader,
    finalStage,
    showTransacionList,
    transactions,
    setTransactions,
    setTransactionModal,
    transactionModal,
    validateRate,
    validateLimit,
    fetchProgram,
    transactionLoader,
    entity_id
  };

  return (
    <>
      <SupplierProgramContext.Provider value={value}>
        {(isLoading || defaultRateLoader) && <LoadingSpinner />}
        {rateDetails?.rateGeneralSettings?.enable_supplier_discount ===
        "active" ? (
          view ? (
            <ProgramView />
          ) : (
            props.children
          )
        ) : (
          !defaultRateLoader && (
            <div id="animation_box" class="animated fadeIn">
              <div id="animation_box" class="animated fadeIn">
                <div class="card">
                  <div class="row">
                    <div class="col-lg-12">
                      <a class="btn btn-light pull-right text-secondary mr-2 mt-2">
                        <i
                          alt=" Dashboard"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Close"
                          class="fa fa-close"
                        ></i>
                      </a>
                    </div>
                  </div>
                  <div id="animation_box" class="animated fadeInLeft">
                    <div class="mb-0">
                      <div class="card-body">
                        <div class="row min-vh-70 justify-content-center align-items-center py-5">
                          <div class="col-lg-5 col-md-12">
                            <div class="">
                              <h2>Supplier Discount Program</h2>
                              <p>
                                Your access to this program is currently
                                inactive. Kindly reach out to your administrator
                                to activate your account and gain access. Thank
                                you.
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-4 offset-lg-1 col-md-12">
                            <img height={400} src={EpLogo}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </SupplierProgramContext.Provider>
    </>
  );
}
