import React, { Component } from "react";
import { Link } from "react-router";
import * as moment from "moment";
import Modal from 'react-bootstrap/Modal';
import { HistoryButton} from "../../../common/containers/button";
import { CurrencyLabel } from "../../../common/inputs";
import transaction_services from "../../../supply-chain/services/transaction_service";
import auth from "../../../../services/authService";
import EntityContext from "../../../../context/entityContext";
import RoleChecker from "../../../../utils/roleChecker";
import Table from '../../components/table/index';
import NoAccess from "../noAccess";

class AllInvoicesList extends Component {

  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  state = {
    selectedTab: this.props.selectedTab ? this.props.selectedTab : "all",
    ajaxRequest: false,
    selected: {},
    roleType: "Purchase",
    tabs: [],
    productType: this.props.productType ? this.props.productType : "",
    showPopout: false,
    isModel : false,
    tempData : null,
    transactionId : null,
  };


  setTabs = tabs => {
    const { sc_invoice_status } = auth.getCurrentUser();
    if(this.context.specialProgramEnabledSCF)
      this.setState({ tabs: tabs.filter(item => (
        item.value !== sc_invoice_status.awaiting_supplier_update && 
        item.value !== sc_invoice_status.approved_for_payment
      ))});
    else
      this.setState({ tabs })
  }

  handleChange = (status) => {
    this.setState({ selectedTab: status, selected: {}});
    this.tableRef.current.clearSelection();
  }

  handlePopoutClick = () => {
    this.setState({showPopout: true})
  };

  closePopout = () => {
    this.setState({showPopout: false})
  };
  

  render() {

    const { constants, sc_invoice_status } = auth.getCurrentUser();

    const cols = [
      {
        Header: "Status",
        accessor: "status_name",
        headerStyle: { textAlign: "left" },
        minWidth: 70,
        show: (this.state.selectedTab == "open" || this.state.selectedTab == "all" ) ? true : false,
      },
      {
        Header: "Invoice Number",
        accessor: "inv_number",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
      },
      {
        Header: "Amount",
        accessor: "inv_amount",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {<CurrencyLabel value={row.original.inv_amount}></CurrencyLabel>}</span>
      },
      {
        Header: "Date",
        accessor: "trans_date",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {moment(row.original.trans_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
      },
      {
        Header: "Due Date",
        accessor: "inv_due_date",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {moment(row.original.inv_due_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
      },
      {
        Header: "Revised Due Date",
        accessor: "inv_revised_due_date",
        headerStyle: { textAlign: "left" },
        minWidth: 100,
        show: ( this.state.selectedTab == sc_invoice_status["rejected"] || this.state.selectedTab == sc_invoice_status["awaiting_approval"] ) ? false : true,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > {row.original.inv_revised_due_date ? moment(row.original.inv_revised_due_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}</span>
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        headerStyle: { textAlign: "left" },
        sortable: false,
        show: (this.state.selectedTab == "open" || this.state.selectedTab == "all" || this.state.selectedTab == sc_invoice_status["closed_funds_collected"] ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.amount_paid} /></span>
      },
      {
        Header: "Total Due",
        accessor: "inv_total_due",
        headerStyle: { textAlign: "left" },
        show: (this.state.selectedTab == "open" || this.state.selectedTab == "all" || this.state.selectedTab == sc_invoice_status["closed_funds_collected"] ) ? true : false,
        Cell: row => <span style={{ color: row.original.status == 'inactive' && "red" }} > <CurrencyLabel value={row.original.inv_total_due} /></span>
      },
      {
        Header: "Action",
        accessor: "action",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        sortable: false,
        Cell: row => {
          return (
            <>
              <Link className="action-icons" style={{ color: "grey" }} to={{ pathname: (RoleChecker.check(this.context.entityDetails.products, constants.ROLES.SUPPLIER) || RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER)) ? `/client/scf/invoice/details` : `/scinvoice/details`, search: "?inv_guid=" + row.original.trans_guid + "&tab=" + this.state.selectedTab }} >
                <HistoryButton className="fa fa-file-text-o"></HistoryButton>
              </Link>
            </>
          ) 
        }
      }
    ];

 
    const AdditionalFilter = () => (
      <>
        {

          (this.state.tabs.length > 0 && this.state.selectedTab != 'overdue') && (

            <div className="dropdown dash-dropdown">
              <button className="btn dropdown-toggle pr-sm-4 pr-3 p-2 w-100 text-left  btn-outline-secondary  dropdown-toggle  pr-sm-4 pr-3 pl-lg-2 w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.state.tabs.map(obj => <>{this.state.selectedTab == obj.value ? obj.label : ""}</>)}
              </button>

              <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                {this.state.tabs.map(obj => <a className={`dropdown-item ${this.state.selectedTab == obj.value ? 'active' : ''}`} onClick={e => this.handleChange(obj.value)} href="#" >{obj.label}</a>)}
              </div>

            </div>
          )
        }
      </>
    )

    return (
      <>
        {
          RoleChecker.check(this.context.entityDetails.products, constants.ROLES.CUSTOMER) ? (
            <>

              <div className="card overflow-visible">
                <div className="card-header">
                  <div className="row">
                    <div className="col-lg-6"><h6>{this.state.selectedTab === 'overdue' ? 'Due / Overdue Invoices' : 'Invoices'}</h6></div>
                    <div className="col-lg-6">
                      {this.state.showPopout === false &&(
                        <a className="btn btn-light pull-right text-secondary ml-2" onClick={this.handlePopoutClick} >
                          <i className="fa fa-expand"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-lg-12">
                    {this.state.showPopout === false ? (
                      <Table
                        ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
                        service={transaction_services.getInvoice}
                        searchService={transaction_services.searchTraderInvoice}
                        cols={cols}
                        defaultTab={this.state.selectedTab ? this.state.selectedTab : "all"}
                        onSelectTab={val => { this.setState({ selectedTab: val, selected: {} }, () => { this.handleChange() }); }}
                        uid={'trans_guid'}
                        onSelectRow={this.handleRowSelection}
                        reqParams={{ roleType: "Purchase", product_type: constants.PRODUCTS.SUPPLYCHAIN, "open": true }}
                        ibox={true}
                        headerTabs={true}
                        setTabs={this.setTabs}
                        checkbox={this.state.checkbox}
                        additionalFilter={<AdditionalFilter />}
                        getTrProps={(state, rowInfo) => {
                          if (rowInfo && rowInfo.row) {
                            return {
                              style: {
                                color: rowInfo.row._original.is_overdue == 1 && 'red'
                              }
                            }
                          } else {
                            return {}
                          }
                        }}
                        tabs={this.state.tabs.length > 0 ? this.state.tabs : false}
                    />
                    ):(
                      <>
                        <Modal className="fullscreen-modal" show={this.state.showPopout} onHide={this.closePopout}>
                          <Modal.Header closeButton>
                            <div className="d-block pt-3 text-center w-100 ml-3">
                              
                              <h3 className="modal-title">Invoices</h3>
                            
                            </div>
                          </Modal.Header>
                          <Modal.Body>
                          <Table
                            ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
                            service={transaction_services.getInvoice}
                            searchService={transaction_services.searchTraderInvoice}
                            cols={cols}
                            defaultTab={this.state.selectedTab ? this.state.selectedTab : "all"}
                            onSelectTab={val => { this.setState({ selectedTab: val, selected: {} }, () => { this.handleChange() }); }}
                            uid={'trans_guid'}
                            onSelectRow={this.handleRowSelection}
                            reqParams={{ roleType: "Purchase", product_type: constants.PRODUCTS.SUPPLYCHAIN, "open": true }}
                            ibox={true}
                            headerTabs={true}
                            setTabs={this.setTabs}
                            checkbox={this.state.checkbox}
                            additionalFilter={<AdditionalFilter />}
                            getTrProps={(state, rowInfo) => {
                              if (rowInfo && rowInfo.row) {
                                return {
                                  style: {
                                    color: rowInfo.row._original.is_overdue == 1 && 'red'
                                  }
                                }
                              } else {
                                return {}
                              }
                            }}
                            tabs={this.state.tabs.length > 0 ? this.state.tabs : false}
                        />
                          </Modal.Body>
                      </Modal>
                      </>
                    )}
                      
                    </div>
                  </div>
                </div>

              </div>
            </>
          ) : <NoAccess heading="Supply Chain Transactions" subHeading=""></NoAccess>
        }

      </>
    );
  }
}
export default AllInvoicesList;