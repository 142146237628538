import React, { Component } from "react";
export const labels = {
  NZZ: {
    ABN: "NZBN",
    CITY: "Region",
    REGION: "Region",
    INQUIRY: "Inquiry",
    HOME_CITY: "Home City",
    BSB_NUMBER: ""
  },
  NZL: {
    ABN: "NZBN",
    CITY: "Region",
    REGION: "Region",
    INQUIRY: "Inquiry",
    HOME_CITY: "Home City",
    BSB_NUMBER: ""
  },
  ENG: {
    ABN: "Companies Hse No",
    CITY: "County",
    REGION: "Region",
    INQUIRY: "Inquiry",
    HOME_CITY: "Home County",
    BSB_NUMBER: "Sort Code"
  },
  AUS: {
    ABN: "ABN",
    CITY: "State",
    REGION: "State",
    INQUIRY: "Enquiry",
    HOME_CITY: "Home State",
    BSB_NUMBER: "BSB"
  },
  IRL: {
    ABN: "CRO",
    CITY: "County",
    REGION: "Region",
    INQUIRY: "Inquiry",
    HOME_CITY: "Home City",
    BSB_NUMBER: "Sort Code"
  },
  GBR: {
    ABN: "Companies Hse No",
    CITY: "County",
    REGION: "Region",
    INQUIRY: "Inquiry",
    HOME_CITY: "Home County",
    BSB_NUMBER: "Sort Code"
  },
};
