import React, { useState, useEffect } from "react";
import NewSupplier from "./newSupplier";
import authService from "../../../../../services/authService";
import { FormRow } from "../../../../common/containers/formContainer";
import { Link } from "react-router";

const AddNewSupplier = ({
  setSelect,
  setAddnew,
  select,
  addNew,
  selectedSuppliers,
  handleCheckboxChange,
}) => {
  const { constants, entity_id } = authService.getCurrentUser();

  return (
    <>
      <FormRow>
        <div className="col-md-4">
          <div
            className=""
            onClick={(e) => {
              e.stopPropagation();
              setSelect(true);
              setAddnew(false);
            }}
          >
            <Link
              className={`btn suppliersBtn float-right btn-sm mt-2 mr-2 ${
                select ? "highlightedBtn" : ""
              }`}
            >
              Select suppliers
            </Link>
          </div>

          <div
            className=""
            onClick={(e) => {
              e.stopPropagation();
              setAddnew(true);
              setSelect(false);
            }}
          >
            <Link
              className={`btn suppliersBtn float-right btn-sm mt-2 mr-2 ${
                addNew ? "highlightedBtn" : ""
              }`}
            >
              Add new suppliers
            </Link>
          </div>
        </div>

        <NewSupplier
          entity_id={entity_id}
          role="Purchase"
          title="SUPPLIER"
          source="network"
          product={constants.PRODUCTS.SUPPLYCHAIN}
          type=""
        />
      </FormRow>
      <FormRow>
        <div className="col-md-4"></div>
        <div className="col-md-8" style={{ marginTop: "15px" }}>
          <div
            style={{
              border: "1px solid #ced4da",
              width: "100%",
              minHeight: "200px",
            }}
          >
            <dl className="dl-horizontal ">
              <ul class="list-group clear-list m-t">
                {selectedSuppliers?.map((item, index) => {
                  if (item.added === true) {
                    return (
                      <li class="list-group-item fist-item" key={index}>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="font-bold ml-4">
                              {item.supplier_name}&nbsp;&nbsp;
                            </label>
                          </div>
                          <div className="col-md-3">
                            <span
                              class="label text-white"
                              style={{
                                background: "#16C79A",
                                borderRadius: "50%",
                              }}
                            >
                              {item.credit_score}
                            </span>
                          </div>
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm btn-white mr-2 mappingBtn"
                              onClick={(e) => {
                                handleCheckboxChange(
                                  item,
                                  item.supplier_id,
                                  false,
                                  index
                                );
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </li>
                    );
                  }
                })}
              </ul>
            </dl>
          </div>
        </div>
      </FormRow>
    </>
  );
};

export default AddNewSupplier;
