import React, { useRef, useEffect, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { toast } from "react-toastify";
import _ from "lodash";
import ReactTable from "react-table";
import moment from "moment";
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import authService from "../../../../services/authService";
import loanService from "../../services/loanTransactions";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import DDScheduleList from "./_getscheduleDetails";
import { confirmAlert } from "react-confirm-alert";
import { CountriesList, CustomersList, BPList } from '../../../slplusTools';
import { ReportInputFieldContainer, FormRow, FormColumn } from "../../../common/containers/formContainer";
import { GeneralButton, CurrencyLabel } from "../../../common/inputs";

function ReconcilePayment(props) {

    const { constants, category, country_id } = authService.getCurrentUser();

    const [country, setCountry] = useState(null);
    const [owner, setOwner] = useState(null);
    const [client, setClient] = useState(null);
    const [awaitingList, setAwaitingList] = useState([]);
    const [ddScheduleListModal, setDdScheduleListModal] = useState(false);
    const [scheduleItem, setScheduleItem] = useState(null);

    // Api list
    const getVerifyPendingListApi = useApi(loanService.GetVerifyPendingList);
    const markAsPaidApi = useApi(loanService.markAsPaid);

    const getVerifyPendingList = async () => {

        const response = await getVerifyPendingListApi.request({
            owner: owner,
            client: client
        });
        if (response) {
            setAwaitingList(response.data.rows);
        } else {
            toast.error("Failed to fetch data");
        }
    }
    const handleShowScheduleDetail = (item) => {
        setDdScheduleListModal(true);
        setScheduleItem({
            client: item.entity_guid,
            batchId: item.reference_guid
        });
    }

    // Function to handle checkbox selection 
    const handleCheckboxChange = (id) => {

        const awaitingPaymentList = [...awaitingList];
        awaitingPaymentList[id].checked = !awaitingPaymentList[id].checked;
        setAwaitingList(awaitingPaymentList);
    };

    const confirmMarkAsPaid = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">

                        <h4>Confirm</h4>
                        <p>Are you sure want to mark selected schedule as paid ?</p>

                        <button
                            className="btn btn-white ml-2 mt-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>

                        <button
                            className="btn btn-primary ml-2 mt-2"
                            onClick={async () => {
                                onClose();
                                markAsPaid();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "",
            message: "",
        });
    }

    const markAsPaid = async() => {
        const selectedSchedules = [];
        awaitingList.filter(itm => itm.checked == true).map(itm => {
            selectedSchedules.push({
                reference_guid:itm.reference_guid,
                schedule_guid:itm.schedule_guid,
                entity_guid:itm.entity_guid
            });
        });

        const {data} = await markAsPaidApi.request({
            scheduleList:selectedSchedules
        })

        if(data.success){
            toast.success("Marked as paid");
            getVerifyPendingList();
        } else {
            toast.error(data.message);
        }
    }

    useEffect(() => {
        if (category == constants.CATEGORY.COUNTRY_OFFICE) {
            setCountry(country_id);
        }
        getVerifyPendingList();
    }, []);

    const cols = [
        {
            id: 'pending_pay_checkbox',
            accessor: '',
            Cell: row => {
                return (
                    <>
                        {
                            row.original.schedule_status == 'requested' && row.original.payment_status == 'verify_pending' && (
                                <>
                                    <div className="custom-control d-flex custom-checkbox small">
                                        <input
                                            id={`pending_payment_chk-${row.index}`}
                                            className="custom-control-input"
                                            type="checkbox"
                                            checked={row.original.checked}
                                            onChange={() => handleCheckboxChange(row.index)}
                                        ></input>
                                        <label
                                            className="custom-control-label"
                                            htmlFor={`pending_payment_chk-${row.index}`}
                                        ></label>
                                    </div>
                                </>
                            )
                        }
                    </>
                );
            },
            width: 50,
            sortable: false,
            resizable: false,
        },
        {
            Header: "Organization",
            accessor: "organization",
            headerStyle: { textAlign: "left" },
            Cell: row => {
                return (
                    <>
                        <span>{row.original.organization}</span>
                    </>
                );
            },
            sortable: false,
        },
        {
            Header: "Amount",
            accessor: "amount",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <span>
                    <CurrencyLabel
                        value={row.original.amount}
                    ></CurrencyLabel>
                </span>
            ),
            sortable: false,
        },
        {
            Header: "Schedules",
            accessor: "schedule_count",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <Link
                    className="cursor-link"
                    onClick={() => { handleShowScheduleDetail(row.original) }}
                >
                    {row.original.schedule_count}
                </Link>
            ),
            sortable: false,
        },
        {
            Header: "Scheduled Date",
            accessor: "added_date",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <>
                    {
                        moment(row.original.added_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()
                    }
                </>
            ),
            sortable: false,
        },
        {
            Header: "Payment process start",
            accessor: "manoova_requested_date",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <>
                    {
                        moment(row.original.manoova_requested_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()
                    }
                </>
            ),
            sortable: false,
        },
        {
            Header: "Payment Received Date",
            accessor: "completed_date",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <>
                    {
                        moment(row.original.completed_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()
                    }
                </>
            ),
            sortable: false,
        },
        {
            Header: "Payment Status",
            accessor: "payment_status",
            headerStyle: { textAlign: "left" },
            Cell: row => {
                return (
                    <>
                        {
                            row.original.payment_status == 'verify_pending' && (
                                <span>Received</span>
                            )
                        }
                    </>
                );
            },
            sortable: false,
        }

    ];

    return (
        <>
            {
                (
                    getVerifyPendingListApi.loading == true ||
                    markAsPaidApi.loading == true
                ) && <LoadingSpinner />
            }
            <div className="row">
                <div className="col-sm-12">
                    <FormRow>
                        <ReportInputFieldContainer
                            label={category == constants.CATEGORY.INTL_OFFICE ? "Country" : ""}
                            show={category == constants.CATEGORY.INTL_OFFICE ? true : false}
                        >
                            <CountriesList
                                type="horizontal"
                                selectedCountry={country}
                                onSelectItem={({ id }) => {
                                    setCountry(id);
                                    setOwner(null);
                                    setClient(null);
                                }}
                            />
                        </ReportInputFieldContainer>

                        <ReportInputFieldContainer
                            label={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? "Business Partner" : ""}
                            show={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? true : false}
                        >
                            
                            <BPList
                                selectedBp={owner}
                                type="horizontal"
                                country={country}
                                onSelectItem={({ value }) => {
                                    setOwner(value);
                                    setClient(null);
                                }}
                            />
                        </ReportInputFieldContainer>

                        <ReportInputFieldContainer
                            label="Client"
                        >
                            <CustomersList
                                selectedCustomer={client}
                                bp={owner}
                                onSelectItem={option => setClient(option.value)}
                            />
                        </ReportInputFieldContainer>

                        <FormColumn
                            col={`col-sm-8`}
                        >
                            <label></label>
                            <div>


                                <GeneralButton
                                    className="btn btn-primary  mt-2 pull-right"
                                    name="Search"
                                    onClick={() => { getVerifyPendingList() }}
                                    disabled={awaitingList && awaitingList.length > 0 ? true : false}
                                >
                                </GeneralButton>
                                <GeneralButton
                                    className="btn btn-light mr-2 mt-2 pull-right"
                                    name="Reset"
                                    onClick={() => {
                                        setAwaitingList([]);
                                    }}
                                />
                            </div>

                        </FormColumn>


                    </FormRow>
                    <hr />

                    {
                        awaitingList && awaitingList.filter(itm => itm.checked == true).length > 0 && (
                            <>
                                <GeneralButton
                                    className="btn btn-primary mt-2 mb-3"
                                    name="Mark As Paid"
                                    onClick={() => { confirmMarkAsPaid() }}
                                >
                                </GeneralButton>
                            </>
                        )
                    }

                    <ReactTable
                        data={awaitingList}
                        columns={cols}
                        defaultPageSize={10}
                        minRows={awaitingList ? awaitingList.length : 0}
                        className="-striped -highlight"
                        showPagination={true}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        noDataText='No rows found'
                    />

                </div>
            </div>

            {/*------------------------loan DD schedule list modal-----------*/}
            <Modal
                show={ddScheduleListModal}
                onHide={() => setDdScheduleListModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Schedule List</h4>
                </Modal.Header>
                <Modal.Body>
                    <DDScheduleList
                        type="pending"
                        data={scheduleItem}
                    />
                </Modal.Body>
            </Modal>
            {/*--------------------------------------------------------------*/}
        </>
    );
}
export default ReconcilePayment;