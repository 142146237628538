import React, { useEffect, useContext, useState } from "react";

import useApi from '../../../../hooks/useApi';
import useIsMounted from "../../../../hooks/useIsMounted";
import EntityContext from "../../../../context/entityContext";
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";
import { FormRow } from "../../../common/containers/formContainer";
import { ProcessingTransactions, MappedDebtors,AwaitingPayment, FundsDrawn, InvoiceSummaryCard, UnDrawnAvailability } from "../../widgets/WobWidgets";

function WobDashboard() {

    const isMounted = useIsMounted();
    const { selectedBusinessType, entityDetails } = useContext(EntityContext);
    const [invData, setInvData] = useState(null);

    //------------Api Service--------------------------------------------
    const invoiceSummaryApi = useApi(dfTransactionService.InvoiceSummary);

    const fetchInvoiceSummary = async () => {

        const response = await invoiceSummaryApi.request({
            trans_client_guid: entityDetails.a_guid,
            trans_type: "open",
            dateFilter: new Date(),
        });
        if (response && isMounted()) {
            setInvData(response.data);
        }
    }

    useEffect(() => {
        fetchInvoiceSummary();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>
            <FormRow>
                <ProcessingTransactions />
                <AwaitingPayment />
                {
                    !invoiceSummaryApi.loading && invData && (
                        <FundsDrawn
                            invData={invData}
                        />
                    )
                }

            </FormRow>

            <FormRow>
                <div className="col-md-8 col-sm-8 col-xs-6 col-12">
                    {
                        !invoiceSummaryApi.loading && invData && (
                            <InvoiceSummaryCard
                                invData={invData}
                            />
                        )
                    }
                </div>
                {/* <DisbursementsGraph /> */}

                <div className="col-md-4 col-sm-4 col-xs-2 col-12">
                    <FormRow>
                        {
                            !invoiceSummaryApi.loading && invData && (
                                <UnDrawnAvailability
                                    invData={invData}
                                />
                            )
                        }

                    </FormRow>

                    <FormRow>
                        <MappedDebtors />
                    </FormRow>

                </div>
            </FormRow>
        </>
    )
}
export default WobDashboard;