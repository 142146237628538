import React, { useState, useEffect, useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";
const SupplierDetails = () => {
  const { supplierDetails, isLoading } = useContext(SupplierProgramContext);

  const [graphData, setGraphData] = useState({});
  const [bgColor, setBgColor] = useState("primary");
  const [graphLabels, setGraphLabels] = useState({});
  const [graphValues, setGraphValues] = useState({});
  const [graphBg, setGraphBg] = useState({});
  const [graphBorder, setGraphBorder] = useState({});
  const [plugins, setPlugins] = useState([]);

  useEffect(() => {
    if (supplierDetails) {
      const graphValues = [
        {
          name: "Early Payment Active",
          value: supplierDetails.early_payment_active,
          icon: "fa-thumbs-up",
          color: "primary",
          border: "rgba(56, 203, 137, 1)",
          bg: "rgba(56, 203, 137, 1)",
        },
        {
          name: "Disclosed",
          value: supplierDetails.disclosed,
          icon: "fa-folder-open-o",
          color: "warning",
          border: "rgba(255, 200, 92, 1)",
          bg: "rgba(255, 200, 92, 1)",
        },
        {
          name: "Undisclosed",
          value: supplierDetails.undisclosed,
          icon: "fa-clock-o",
          color: "danger",
          border: "rgba(237, 85, 101, 1)",
          bg: "rgba(237, 85, 101, 1)",
        },
        {
          name: "Inactive",
          value: supplierDetails.inactive,
          icon: "fa-check-square-o",
          color: "info",
          border: "rgba(17, 105, 142, 1)",
          bg: "rgba(17, 105, 142, 1)",
        },
      ];
      setGraphData(graphValues);

      const graphLabelset = graphValues.map((obj) => obj.name);
      const graphValueset = graphValues.map((obj) => obj.value);
      const graphBgset = graphValues.map((obj) => obj.bg);
      const graphBorderset = graphValues.map((obj) => obj.border);

      setGraphValues(graphValueset);
      setGraphLabels(graphLabelset);
      setGraphBg(graphBgset);
      setGraphBorder(graphBorderset);
    }
  }, [supplierDetails]);

  useEffect(() => {
    if (supplierDetails.total_supplier !== undefined) {
      console.log("supplierDetails.total_supplier", supplierDetails.total_supplier)
      const pluginss = [
        {
          //-----Setting availabilty amount  at center of the graph---------------
          beforeDraw: function (chart) {
            var width = chart.width,
              height = chart.height,
              ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 160).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.fillStyle = "#000";
            ctx.textBaseline = "middle";

            var text = supplierDetails.total_supplier + " Suppliers",
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;
            ctx.fillText(text, textX, textY);
            ctx.save();
          },
          afterUpdate: function (chart) {
            var a = chart.config.data.datasets.length - 1;

            for (let i in chart.config.data.datasets) {
              for (
                var j = chart.config.data.datasets[i].data.length - 1;
                j >= 0;
                --j
              ) {
                if (Number(j) == chart.config.data.datasets[i].data.length)
                  continue;
                var arc = chart.getDatasetMeta(i).data[j];

                arc.round = {
                  x: (chart.chartArea.left + chart.chartArea.right) / 2,
                  y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                  radius:
                    chart.innerRadius +
                    chart.radiusLength / 2 +
                    a * chart.radiusLength,
                  thickness: chart.radiusLength / 2 + 1,
                  backgroundColor: bgColor,
                };
              }
              a--;
            }
          },
        },
      ];

      setPlugins(pluginss);
    }
  }, [supplierDetails.total_supplier]);
  console.log("plugins", plugins)
  const options = {
    tooltips: {
      position: "nearest",

      callbacks: {
        title: (tooltipItem, data) => {
          return data["labels"][tooltipItem[0]["index"]];
        },

        label: (tooltipItem, data) => {
          return data["datasets"][0]["data"][tooltipItem["index"]];
        },
      },
    },
    arc: {
      roundedCornersFor: 0,
    },
    cutoutPercentage: 75,

    responsive: true,
    legend: {
      display: false,
    },
  };

  let data = {
    labels: graphLabels,
    datasets: [
      {
        data: [graphValues[0], graphValues[1], graphValues[2], graphValues[3]],
        backgroundColor: [
          graphBg[0] ? graphBg[0] : "#38cb89",
          graphBg[1] ? graphBg[1] : "#ffc85c",
          graphBg[2] ? graphBg[2] : "#ed5565",
          graphBg[3] ? graphBg[3] : "#11698e",
        ],
        hoverBackgroundColor: [
          graphBorder[0] ? graphBorder[0] : "#38cb89",
          graphBorder[1] ? graphBorder[1] : "r#ffc85c",
          graphBorder[2] ? graphBorder[2] : "#ed5565",
          graphBorder[3] ? graphBorder[3] : "#11698e",
        ],
        hoverBorderColor: [
          graphBorder[0] ? graphBorder[0] : "#38cb89",
          graphBorder[1] ? graphBorder[1] : "#ffc85c",
          graphBorder[2] ? graphBorder[2] : "#ed5565",
          graphBorder[3] ? graphBorder[3] : "#11698e",
        ],
        borderColor: [
          graphBorder[0] ? graphBorder[0] : "#38cb89",
          graphBorder[1] ? graphBorder[1] : "#ffc85c",
          graphBorder[2] ? graphBorder[2] : "#ed5565",
          graphBorder[3] ? graphBorder[3] : "#11698e",
        ],
        borderWidth: 2,
      },
    ],
  };
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        supplierDetails &&
        Object.keys(supplierDetails).length > 0 && plugins.length > 0 && (
          <div
            className="   aligin-center"
            style={{
              borderRadius: "0px !important",
              marginBottom: "0px",
              border: "1px solid black !important",
            }}
          >
            <div>
              <div className="row text-center def-card-h ">
                <div className="col-md-12   ">
                  <div
                    id="my-doughnut-chart-1"
                    className="align-center relative my-1"
                  >
                    <Doughnut
                      data={data}
                      options={options}
                      plugins={plugins}
                      width={340}
                      height={220}
                    />
                  </div>
                </div>
                {/*     <div className="col-md-12 mt-2">
       <p className="font-weight-semibold" style={{ textAlign: "left" }}>
         50 Early Payment Active
       </p>
       <p className="font-weight-semibold" style={{ textAlign: "left" }}>
         40 Disclosed
       </p>
       <p className="font-weight-semibold" style={{ textAlign: "left" }}>
         30 Undisclosed
       </p>
       <p className="font-weight-semibold" style={{ textAlign: "left" }}>
         2 Inactive
       </p>
</div>*/}
                <div class="container mt-2">
                  <div class="custom-list-item d-flex align-items-center justify-content-between py-2">
                    <div class=" ">
                      <p class="mb-0">Early Payment Active</p>
                    </div>
                    <span class="badge badge-primary   font-weight-bold p-1">
                      {supplierDetails?.early_payment_active}
                    </span>
                  </div>

                  <div class="custom-list-item d-flex align-items-center justify-content-between py-2">
                    <div class=" ">
                      <p class="mb-0">Disclosed</p>
                    </div>
                    <span class="badge badge-warning  text-secondary highlighted-count font-weight-bold">
                      {supplierDetails?.disclosed}
                    </span>
                  </div>

                  <div class="custom-list-item d-flex align-items-center justify-content-between py-2">
                    <div class=" ">
                      <p class="mb-0">Undisclosed</p>
                    </div>
                    <span class="badge badge-danger  text-secondary highlighted-count font-weight-bold">
                      {supplierDetails?.undisclosed}
                    </span>
                  </div>

                  <div class="custom-list-item d-flex align-items-center justify-content-between py-2">
                    <div class=" ">
                      <p class="mb-0">Inactive</p>
                    </div>
                    <span class="badge badge-light  text-secondary highlighted-count font-weight-bold">
                      {supplierDetails?.inactive !== null ? supplierDetails?.inactive : '0'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default SupplierDetails;
