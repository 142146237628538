import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";



import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import { Doughnut } from "react-chartjs-2";
import { browserHistory } from "react-router";
const ProgramView = () => {
    const [graphData, setGraphData] = useState({});
    const [bgColor, setBgColor] = useState("primary");
    const [graphLabels, setGraphLabels] = useState({});
    const [graphValues, setGraphValues] = useState({});
    const [graphBg, setGraphBg] = useState({});
    const [graphBorder, setGraphBorder] = useState({});
    const [plugins, setPlugins] = useState([]);




    useEffect(() => {

        const graphValues = [
            {
                name: "Early Payment Active",
                value: 10,
                icon: "fa-thumbs-up",
                color: "primary",
                border: "rgba(56, 203, 137, 1)",
                bg: "rgba(56, 203, 137, 1)",
            },
            {
                name: "Disclosed",
                value: 25,
                icon: "fa-folder-open-o",
                color: "warning",
                border: "rgba(255, 200, 92, 1)",
                bg: "rgba(255, 200, 92, 1)",
            },
            {
                name: "Undisclosed",
                value: 5,
                icon: "fa-clock-o",
                color: "danger",
                border: "rgba(237, 85, 101, 1)",
                bg: "rgba(237, 85, 101, 1)",
            },
            {
                name: "Inactive",
                value: 10,
                icon: "fa-check-square-o",
                color: "info",
                border: "rgba(17, 105, 142, 1)",
                bg: "rgba(17, 105, 142, 1)",
            },
        ];
        setGraphData(graphValues);

        const graphLabelset = graphValues.map((obj) => obj.name);
        const graphValueset = graphValues.map((obj) => obj.value);
        const graphBgset = graphValues.map((obj) => obj.bg);
        const graphBorderset = graphValues.map((obj) => obj.border);

        setGraphValues(graphValueset);
        setGraphLabels(graphLabelset);
        setGraphBg(graphBgset);
        setGraphBorder(graphBorderset);

    }, []);

    useEffect(() => {

        const pluginss = [
            {
                //-----Setting availabilty amount  at center of the graph---------------
                beforeDraw: function (chart) {
                    var width = chart.width,
                        height = chart.height,
                        ctx = chart.ctx;
                    ctx.restore();
                    var fontSize = (height / 160).toFixed(2);
                    ctx.font = fontSize + "em sans-serif";
                    ctx.fillStyle = "#000";
                    ctx.textBaseline = "middle";

                    var text = 50 + " Suppliers",
                        textX = Math.round((width - ctx.measureText(text).width) / 2),
                        textY = height / 2;
                    ctx.fillText(text, textX, textY);
                    ctx.save();
                },
                afterUpdate: function (chart) {
                    var a = chart.config.data.datasets.length - 1;

                    for (let i in chart.config.data.datasets) {
                        for (
                            var j = chart.config.data.datasets[i].data.length - 1;
                            j >= 0;
                            --j
                        ) {
                            if (Number(j) == chart.config.data.datasets[i].data.length)
                                continue;
                            var arc = chart.getDatasetMeta(i).data[j];

                            arc.round = {
                                x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                radius:
                                    chart.innerRadius +
                                    chart.radiusLength / 2 +
                                    a * chart.radiusLength,
                                thickness: chart.radiusLength / 2 + 1,
                                backgroundColor: bgColor,
                            };
                        }
                        a--;
                    }
                },
            },
        ];

        setPlugins(pluginss);
    }
    );

    const options = {
        tooltips: {
            position: "nearest",

            callbacks: {
                title: (tooltipItem, data) => {
                    return data["labels"][tooltipItem[0]["index"]];
                },

                label: (tooltipItem, data) => {
                    return data["datasets"][0]["data"][tooltipItem["index"]];
                },
            },
        },
        arc: {
            roundedCornersFor: 0,
        },
        cutoutPercentage: 75,

        responsive: true,
        legend: {
            display: false,
        },
    };

    let data = {
        labels: graphLabels,
        datasets: [
            {
                data: [graphValues[0], graphValues[1], graphValues[2], graphValues[3]],
                backgroundColor: [
                    graphBg[0] ? graphBg[0] : "#38cb89",
                    graphBg[1] ? graphBg[1] : "#ffc85c",
                    graphBg[2] ? graphBg[2] : "#ed5565",
                    graphBg[3] ? graphBg[3] : "#11698e",
                ],
                hoverBackgroundColor: [
                    graphBorder[0] ? graphBorder[0] : "#38cb89",
                    graphBorder[1] ? graphBorder[1] : "r#ffc85c",
                    graphBorder[2] ? graphBorder[2] : "#ed5565",
                    graphBorder[3] ? graphBorder[3] : "#11698e",
                ],
                hoverBorderColor: [
                    graphBorder[0] ? graphBorder[0] : "#38cb89",
                    graphBorder[1] ? graphBorder[1] : "#ffc85c",
                    graphBorder[2] ? graphBorder[2] : "#ed5565",
                    graphBorder[3] ? graphBorder[3] : "#11698e",
                ],
                borderColor: [
                    graphBorder[0] ? graphBorder[0] : "#38cb89",
                    graphBorder[1] ? graphBorder[1] : "#ffc85c",
                    graphBorder[2] ? graphBorder[2] : "#ed5565",
                    graphBorder[3] ? graphBorder[3] : "#11698e",
                ],
                borderWidth: 2,
            },
        ],
    };
    return (
        <>
            <Helmet>
                <title>Supplier Program | {global.config.title}</title>
            </Helmet>


            <Modal
                className="fullscreen-modal"

            >
                <Modal.Header closeButton>

                    <div className="d-block pt-3 text-center w-100 ml-3">
                        <h3 className="modal-title"></h3>
                    </div>
                    <a
                        style={{ marginTop: "-7px" }}
                        className="btn btn-light pull-right text-secondary ml-2"

                    >
                        <i className="fa fa-compress"></i>
                    </a>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="card ml-3">
                                <div className="card-body">
                                    <div className="col-md-12   ">
                                        <div
                                            id="my-doughnut-chart-1"
                                            className="align-center relative my-1"
                                        >
                                            <Doughnut
                                                data={data}
                                                options={options}
                                                plugins={plugins}
                                                width={340}
                                                height={220}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">

                        </div>
                        <div className="col-lg-6">

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-10">
                                <h6>Supplier Network Dashboard</h6>
                            </div>
                            <div className="col-lg-2">
                                <button onClick={() => browserHistory.push("/client/ep-programme-demo/add-new")} className="btn btn-primary btn-sm pull-right">Add New</button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card ml-3">
                                <div className="card-body">
                                    <div className="col-md-12   ">
                                        <div
                                            id="my-doughnut-chart-1"
                                            className="align-center relative my-1"
                                        >
                                            <Doughnut
                                                data={data}
                                                options={options}
                                                plugins={plugins}
                                                width={340}
                                                height={220}
                                            />
                                        </div>
                                    </div>
                                    <div class="container mt-2">
                                        <div class="custom-list-item d-flex align-items-center justify-content-between py-2">
                                            <div class=" ">
                                                <p class="mb-0">Early Payment Active</p>
                                            </div>
                                            <span class="badge badge-primary  font-weight-bold">
                                                15
                                            </span>
                                        </div>

                                        <div class="custom-list-item d-flex align-items-center justify-content-between py-2">
                                            <div class=" ">
                                                <p class="mb-0">Disclosed</p>
                                            </div>
                                            <span class="badge badge-secondary rounded-pill text-white highlighted-count font-weight-bold">
                                                20
                                            </span>
                                        </div>

                                        <div class="custom-list-item d-flex align-items-center justify-content-between py-2">
                                            <div class=" ">
                                                <p class="mb-0">Undisclosed</p>
                                            </div>
                                            <span class="badge badge-warning rounded-pill text-white highlighted-count font-weight-bold">
                                                5
                                            </span>
                                        </div>

                                        <div class="custom-list-item d-flex align-items-center justify-content-between py-2">
                                            <div class=" ">
                                                <p class="mb-0">Inactive</p>
                                            </div>
                                            <span class="badge badge-danger rounded-pill text-white highlighted-count font-weight-bold">
                                                10
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="feed-activity-list 
                    ">

                                        <div className="feed-element"  >
                                            <div className="media-body">
                                                <a
                                                    className="btn btn-light pull-right ml-3"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    href="#"
                                                >
                                                    <i className="fa fa-ellipsis-v mt-1 mb-1" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right shadow">
                                                    <Link
                                                        className="dropdown-item"


                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {/* <i className="fa fa-eye mr-2" /> */}
                                                        View
                                                    </Link>
                                                    <Link
                                                        className="dropdown-item"

                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {/* <i className="fa fa-file-text-o mr-2" /> */}
                                                        Update
                                                    </Link>
                                                </div>
                                                <h6
                                                    aria-expanded="false"
                                                    to="/your-link-path"
                                                    className="p-2 font-weight-bold d-flex text-secondary  pull-right"
                                                >
                                                    1.2500%
                                                </h6>
                                                <span
                                                    aria-expanded="false"
                                                    to="/your-link-path"

                                                    className="media-icon d-flex text-white bg-secondary pull-left font-weight-bold mr-1"
                                                >
                                                    DS
                                                </span> &nbsp;  December Sale 23
                                                <span
                                                    className="label-secondary-transparent"
                                                >
                                                    Fixed
                                                </span>{" "}
                                                <br />
                                                <small className="text-muted">

                                                    <span>  supplier</span>

                                                </small>
                                            </div>
                                            <span class="bg-outline-secondary p-1 text-danger text-xs pull-right">
                                                $52,2500.00 Funded already
                                            </span>
                                        </div>


                                    </div>
                                    <div className="feed-activity-list mt-2">

                                        <div className="feed-element"  >
                                            <div className="media-body">
                                                <a
                                                    className="btn btn-light pull-right ml-3"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    href="#"
                                                >
                                                    <i className="fa fa-ellipsis-v mt-1 mb-1" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right shadow">
                                                    <Link
                                                        className="dropdown-item"


                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {/* <i className="fa fa-eye mr-2" /> */}
                                                        View
                                                    </Link>
                                                    <Link
                                                        className="dropdown-item"

                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {/* <i className="fa fa-file-text-o mr-2" /> */}
                                                        Update
                                                    </Link>
                                                </div>
                                                <h6
                                                    aria-expanded="false"
                                                    to="/your-link-path"
                                                    className="p-2 font-weight-bold d-flex text-secondary  pull-right"
                                                >
                                                    0.82500%
                                                </h6>
                                                <span
                                                    aria-expanded="false"
                                                    to="/your-link-path"

                                                    className="media-icon d-flex text-white bg-secondary pull-left font-weight-bold mr-1"
                                                >
                                                    SP
                                                </span> &nbsp;  Supplier Program 01
                                                <span
                                                    className="label-secondary-transparent"
                                                >
                                                    Dynamic
                                                </span>{" "}
                                                <br />
                                                <small className="text-muted">

                                                    <span>5  supplier</span>

                                                </small>
                                            </div>
                                            <span class="bg-outline-secondary p-1 text-danger text-xs pull-right">
                                                $0.00 Funded already
                                            </span>
                                        </div>
                                        <div className="feed-element"  >
                                            <div className="media-body">
                                                <a
                                                    className="btn btn-light pull-right ml-3"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    href="#"
                                                >
                                                    <i className="fa fa-ellipsis-v mt-1 mb-1" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right shadow">
                                                    <Link
                                                        className="dropdown-item"


                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {/* <i className="fa fa-eye mr-2" /> */}
                                                        View
                                                    </Link>
                                                    <Link
                                                        className="dropdown-item"

                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {/* <i className="fa fa-file-text-o mr-2" /> */}
                                                        Update
                                                    </Link>
                                                </div>
                                                <h6
                                                    aria-expanded="false"
                                                    to="/your-link-path"
                                                    className="p-2 font-weight-bold d-flex text-secondary  pull-right"
                                                >
                                                    0.82500%
                                                </h6>
                                                <span
                                                    aria-expanded="false"
                                                    to="/your-link-path"

                                                    className="media-icon d-flex text-white bg-secondary pull-left font-weight-bold mr-1"
                                                >
                                                    SP
                                                </span> &nbsp;  Supplier Program 01
                                                <span
                                                    className="label-secondary-transparent"
                                                >
                                                    Dynamic
                                                </span>{" "}
                                                <br />
                                                <small className="text-muted">

                                                    <span>5  supplier</span>

                                                </small>
                                            </div>
                                            <span class="bg-outline-secondary p-1 text-danger text-xs pull-right">
                                                $0.00 Funded already
                                            </span>
                                        </div>

                                    </div>

                                    <button
                                        className="btn btn-outline-primary btn-block mt-4 mb-3"
                                        onClick={() => {
                                            browserHistory.push("/client/ep-programme/allProgram");

                                        }}
                                    >
                                        <i className="fa fa-arrow-down" /> Show More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card ">
                            <div className="card-header">
                                <div class="row  ">
                                    <div class="col">
                                        <h5 class="card-title ml-2 mt-2">Top Suppliers (Lifetime) </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="feed-activity-list">
                                    <div className="feed-element">
                                        <span
                                            aria-expanded="false"
                                            to="/your-link-path"
                                            className="media-icon d-flex text-secondary bg-light pull-left font-weight-bold mr-1"
                                        >
                                            1
                                        </span>
                                        <div className="media-body">

                                            <span
                                                aria-expanded="false"
                                                to="/your-link-path"
                                                className="p-2 font-weight-bold  d-flex bg-success-transparent pull-right"

                                            >
                                                $42,052.00
                                            </span>
                                            <strong>Supplier Business Name</strong>  <br />
                                            <small className="text-muted">Mid season program</small>
                                        </div>
                                    </div>
                                    <div className="feed-element">
                                        <span
                                            aria-expanded="false"
                                            to="/your-link-path"
                                            className="media-icon d-flex text-secondary bg-light pull-left font-weight-bold mr-1"
                                        >
                                            2
                                        </span>
                                        <div className="media-body">

                                            <span
                                                aria-expanded="false"
                                                to="/your-link-path"
                                                className="p-2 font-weight-bold  d-flex bg-success-transparent pull-right"

                                            >
                                                $25,052.00
                                            </span>
                                            <strong>Supplier Business Name</strong>  <br />
                                            <small className="text-muted">Mid season program</small>
                                        </div>
                                    </div>
                                    <div className="feed-element">
                                        <span
                                            aria-expanded="false"
                                            to="/your-link-path"
                                            className="media-icon d-flex text-secondary bg-light pull-left font-weight-bold mr-1"
                                        >
                                            3
                                        </span>
                                        <div className="media-body">

                                            <span
                                                aria-expanded="false"
                                                to="/your-link-path"
                                                className="p-2 font-weight-bold  d-flex bg-success-transparent pull-right"

                                            >
                                                $21,250.00
                                            </span>
                                            <strong>Supplier Business Name 3</strong>  <br />
                                            <small className="text-muted">Mid season program</small>
                                        </div>
                                    </div>
                                    <div className="feed-element">
                                        <span
                                            aria-expanded="false"
                                            to="/your-link-path"
                                            className="media-icon d-flex text-secondary bg-light pull-left font-weight-bold mr-1"
                                        >
                                            4
                                        </span>
                                        <div className="media-body">

                                            <span
                                                aria-expanded="false"
                                                to="/your-link-path"
                                                className="p-2 font-weight-bold  d-flex bg-success-transparent pull-right"

                                            >
                                                $18,540.00
                                            </span>
                                            <strong>Supplier Business Name 2</strong>  <br />
                                            <small className="text-muted">Mid season program</small>
                                        </div>
                                    </div>
                                </div>







                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card ">
                            <div className="card-header">
                                <div class="row  ">
                                    <div class="col">
                                        <h5 class="card-title    ">Best New Opportunities (9) </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row order-xl-3 nav navbar-right flex-nowrap align-items-center ml-auto mb-3">
                                    <div className="col-lg-12 dropdown nav-item  ">
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <Link
                                                    aria-expanded="false"
                                                    to="/your-link-path"
                                                    className="nav-link avatar d-flex text-secondary bg-primary-transparent"

                                                >
                                                    AA
                                                </Link>{" "}
                                            </div>
                                            <div className="col-lg-4 mr-2">
                                                <div className="font-weight-bold text-center">
                                                    <span
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        Supplier Business Name
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div
                                                    className="bg-light b-dashed text-secondary p-2"
                                                >
                                                    <span
                                                        style={{
                                                            textAlign: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        Has early payment options
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row order-xl-3 nav navbar-right flex-nowrap align-items-center ml-auto mb-3">
                                    <div className="col-lg-12 dropdown nav-item  ">
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <Link
                                                    aria-expanded="false"
                                                    to="/your-link-path"
                                                    className="nav-link avatar d-flex text-secondary bg-primary-transparent"

                                                >
                                                    SP
                                                </Link>{" "}
                                            </div>
                                            <div className="col-lg-4 mr-2">
                                                <div className="font-weight-bold text-center">
                                                    <span
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        Supplier Business Name
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div
                                                    className="bg-light b-dashed text-secondary p-2"
                                                >
                                                    <span
                                                        style={{
                                                            textAlign: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        Interested in early pyment program
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row order-xl-3 nav navbar-right flex-nowrap align-items-center ml-auto mb-3">
                                    <div className="col-lg-12 dropdown nav-item  ">
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <Link
                                                    aria-expanded="false"
                                                    to="/your-link-path"
                                                    className="nav-link avatar d-flex text-secondary bg-primary-transparent"

                                                >
                                                    RS
                                                </Link>{" "}
                                            </div>
                                            <div className="col-lg-4 mr-2">
                                                <div className="font-weight-bold text-center">
                                                    <span
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        Supplier Business Name
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div
                                                    className="bg-light b-dashed text-secondary p-2"
                                                >
                                                    <span
                                                        style={{
                                                            textAlign: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        Offering Discounts to Competition
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row order-xl-3 nav navbar-right flex-nowrap align-items-center ml-auto mb-3">
                                    <div className="col-lg-12 dropdown nav-item ">
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <Link
                                                    aria-expanded="false"
                                                    to="/your-link-path"
                                                    className="nav-link avatar d-flex text-secondary bg-primary-transparent"

                                                >
                                                    AA
                                                </Link>{" "}
                                            </div>
                                            <div className="col-lg-4 mr-2">
                                                <div className="font-weight-bold text-center">
                                                    <span
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        Supplier Business Name
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="bg-light b-dashed text-secondary p-2"

                                                >
                                                    <span
                                                        style={{
                                                            textAlign: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        Offering Discounts to Competition
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    );
};

export default ProgramView;
