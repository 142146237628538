import { useState, useEffect, useRef } from "react";
import useIsMounted from "./useIsMounted";
import { toast } from "react-toastify";

const useApi = (apiFunc) => {
    const isMounted = useIsMounted();
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const emptyResponse = {
        data: {
            rows: []
        }
    }

    const request = async (...args) => {
        try {
            setLoading(true);
            const response = await apiFunc(...args);
            if(isMounted()){
                setLoading(false);
                setData(response.data);
                return response;
            }else
                return emptyResponse;
            
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        isMounted.current = true
    
        return () => {
          isMounted.current = false
        }
    }, [])

    return { data, error, loading, request, setData };
};

export default useApi;