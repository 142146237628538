import React from "react";
import { DangerCard } from "../../components/cards";
import { Link } from "react-router";

function Rejected({ loading, data, top, col, qrystring = false, setProductRoleType, type = "md" }) {
    return (
        <>
            {type == "sm" ? (
                <>
                    <div className="col-lg-12" >
                        <div className="card">
                            <div className="card-body">
                                <span className="b-corner b-corner-r b-corner-danger" />
                                <div className="row mt-2 mb-2">
                                    <div className="col-lg-12 pb-1" >
                                        <h6 className="card-title">

                                            {qrystring === true ?
                                                <Link className="text-secondary" to={data ? data.primaryBtnRoute : ''} >
                                                    {data ? data.topLabel : ""} <span className="pull-right">{data ? data.topValue : ""} </span>
                                                </Link> :
                                                <Link className="text-secondary" to={{ pathname: data ? data.primaryBtnRoute : '', state: data ? data.status : "" }} >
                                                    {data ? data.topLabel : ""} <span className="pull-right">{data ? data.topValue : ""} </span>
                                                </Link>
                                            }

                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <DangerCard
                    loading={loading}
                    data={data}
                    col={col ? col : 4}
                    top={top ? top : false}
                    setProductRoleType={setProductRoleType}
                />
            )}


        </>
    )
}
export default Rejected;