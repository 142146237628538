import React from "react";
import startIcon3 from "../../.././../img/icons/FMB_icon2.svg"
import startIcon4 from "../../.././../img/icons/FMB_icon3.svg"




export default function Page2({ goNext, goPrevious }) {

    // const { getStart, setGetStart } = useContext(FinanceBusinessContext);



    const clearSolutions = (data) => {
        //  setGetStart({ clearSolution: data })
        if (!data) {
            goNext('cashFlowChoose');
        } else {
            goNext('chooseBoth');
        }

    };

    return (
        <>
            <div id="animation_box" className="animated fadeInLeft">
                <div className="mb-0">
                    <div className="card-body">
                        <div className="row min-vh-70 justify-content-center align-items-center py-5">

                            <div className="col-lg-4 col-sm-6 col-md-6 text-center">
                                <div className="px-sm-3">
                                    <img src={startIcon3} alt="image" className="mb-4" width={210} />
                                    <h5 className="business-title">Not sure what is the best solution for you or want to explore?</h5>
                                    <p className="my-3 business-p" my-3>Answer these following questions and we’ll make some recommendations</p>  
                                    <a href="#" onClick={() => clearSolutions(false)} className="btn btn-primary">Let's Get Started</a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-6 text-center">
                                <div className="px-sm-3">
                                    <img src={startIcon4} alt="image" className="img-fluid mb-4" width={210} />
                                    <h5 className="business-title">Clear on what you need and want to get started?</h5>
                                    <p className="my-3 business-p">Select the cash flow finance and working capital solutions you want</p>
                                    <a href="#" onClick={() => clearSolutions(true)} className="btn btn-primary">Let's Get Started</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

