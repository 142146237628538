import React, { Component } from "react";
import { Helmet } from "react-helmet";
import auth from "../../../services/authService";
import EntityContext from "../../../context/entityContext";

import TfSupplierTransactionList from "./tfTransactions/invoiceList/supplierTransactionList";
import SupplierTransactionList from "./scTransactions/invoiceList/supplierTransactionList";
import InvoicesList from "./ifTransactions/invoiceList";


class SalesInvoices extends Component {


  static contextType = EntityContext;
  constructor(props) {
    super(props);
  }

  state = {
    selectedTab: this.props.location.state ? this.props.location.state : null,
    ajaxRequest: false,
    selectedRows: [],
    categories: [],
    open: false,
    openCustomer: false,
    approvebuttonClass: "btn btn-primary  hidden",
    declinebuttonClass: "btn btn-danger  hidden",
    bulkapprove: false,
    bulkreject: false,
    selected: {},
    roleType:"Sale",
    tabs: [],
    checkbox: true,
  };
 
  render() {

    const { constants } = auth.getCurrentUser();

    return (
      <>
        <Helmet>
          <title>Invoice List| {global.config.title}</title>
        </Helmet>
        
        {(this.context.selProduct == "ALL" || this.context.selProduct == constants.PRODUCTS.SUPPLYCHAIN ) && (<SupplierTransactionList selectedTab={ this.state.selectedTab} productType = {this.context.selProduct}></SupplierTransactionList>)}
        {this.context.selProduct == constants.PRODUCTS.TRADEFINANCE && (<TfSupplierTransactionList selectedTab={ this.state.selectedTab} productType = {this.context.selProduct} />)}
        {this.context.selProduct == constants.PRODUCTS.INVOICE && (<InvoicesList status={this.props.location.query.status} type={this.props.location.query.type}/>)}
      </>
    );
  }
}
export default SalesInvoices;