import React, { useState, useContext, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
import { Link } from "react-router";
import _ from "lodash";
import Table from '../../components/table/index';
import * as moment from "moment";
import CreateInvoice from "../../pages/ifTransactions/_createInvoice";
import { CurrencyLabel, GeneralButton } from "../../../common/inputs";
import EntityContext from "../../../../context/entityContext";
import transactionService from "../../../../services/invTransactions";
import { HistoryButton, AddButton, EditButton } from "../../../common/containers/button";
import BulkUpload from "./bulkUpload";
import { transStatus } from "../../../../config/predefinedValues.json";
import authService from "../../../../services/authService";
import entityService from "../../../../services/entityService";
import useApi from "../../../../hooks/useApi";
import RequestPayment from "./requestPayment";
import TemplateEditor from "../../../admin/templates/templateEditor";
import JoditEditor from "jodit-react";
export default function InvoiceList(props) {

  const [transModal, setTransModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(props.status !== undefined ? props.status : "all");
  const [tabs, setTabs] = useState([]);
  const [editInvoiceModal, seteditInvoiceModal] = useState(false);
  const [selectedInvoice, setselectedInvoice] = useState(false);
  const { entity_id, constants } = authService.getCurrentUser();
  const [tableParams, setTableParams] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reqPaymentModal, setReqPaymentModal] = useState(false);
  const [facilitySettings, setFacilitySettings] = useState(null);
  const [showPopout, setShowPopout] = useState(false);
  
  const [isModel, setIsModel] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [tempData, setTempData] = useState(null);


  const tableRef = useRef(); // Reference to Table Component
  const { selectedBusinessType, entityDetails } = useContext(EntityContext);
  const entityDefaultsApi = useApi(entityService.getEntityDefaults);

  let fpBoxLabel = "Transactions";
  let tableTitle = "Transactions";

  // FP Box Label
  if (props.type === "due") fpBoxLabel = "Due This Month";
  else if (props.type === "overdue") fpBoxLabel = "Overdue This Month";
  const transactionApi = useApi(transactionService.getTransactionDetails);

  const handleContentChange = content => {
    setTempData(content);
  };
  const cols = [
    {
      Header: "Funding Ref #",
      accessor: "client_reference",
      headerStyle: { textAlign: "left" },
      minWidth: 150,
      resizable: false,
      show: (selectedTab === transStatus[0]["DRAFT"] || selectedTab === transStatus[0]["APPROVED"] || selectedTab === transStatus[0]["REJECTED"] ) ? false : true,

      Cell: (row) => (
        <span>
          {row.original.client_reference != null
            ? row.original.client_reference
            : ""}
        </span>
      ),
      PivotValue: (cellInfo) => {
        return (
          <span className="badge badge-light  p-2 text-secondary">
            <strong>
              {"Ref# "}
              {cellInfo.row._pivotVal}
            </strong>
          </span>
        );
      },
    },
    {
      Header: "Invoice Number",
      accessor: "inv_number",
      headerStyle: { textAlign: "left" },
      minWidth: 150,
      Cell: (row) => <span>{row.original.inv_number}</span>,

      Aggregated: (row) => {
        // You can even render the cell differently if it's an aggregated cell
        return <span>{row.value}</span>;
      },
    },
    {
      Header: "Debtor",
      accessor: "inv_debtor_name",
      headerStyle: { textAlign: "left" },
      minWidth: 150,
      Cell: (row) => <span>{row.original.debtor_organisation}</span>,
    },
    {
      Header: "Invoice Amt",
      accessor: "inv_amount",
      headerStyle: { textAlign: "left" },
      Cell: (row) => (
        <CurrencyLabel
          value={row.original.inv_amount !== null ? row.original.inv_amount : 0}
        ></CurrencyLabel>
      ),

      Aggregated: (row) => {
        // You can even render the cell differently if it's an aggregated cell
        return <span>{row.value}</span>;
      },

      Footer: (row) => {
        return (
          <strong>
            <CurrencyLabel decimal={2} value={_.sumBy(row.data, "inv_amount")}></CurrencyLabel>
          </strong>
        );
      },

    },
    {
      Header: "Agreement Amt",
      accessor: "inv_chargeable_amt",
      headerStyle: { textAlign: "left" },
      Cell: (row) => (
        <CurrencyLabel
          value={
            row.original.inv_chargeable_amt !== null
              ? row.original.inv_chargeable_amt
              : 0
          }
        ></CurrencyLabel>
      ),
      Aggregated: (row) => {
        let sum = 0;
        row.subRows &&
          row.subRows.map((item) => {
            sum += parseInt(item.inv_chargeable_amt);
          });
        return (
          <span className="badge badge-light p-2 text-secondary ">
            <strong>
              <CurrencyLabel value={sum !== null ? sum : 0}></CurrencyLabel>
            </strong>
          </span>
        );
      },
      Footer: (row) => {
        return (
          <strong>
            <CurrencyLabel decimal={2} value={_.sumBy(row.data, "inv_chargeable_amt")}></CurrencyLabel>
          </strong>
        );
      },
    },
    {
      Header: "Advance Amt",
      accessor: "disb_amount",
      show: selectedTab === transStatus[0]["REJECTED"] ? false : true,

      Cell: (row) => {
        return (
          <CurrencyLabel
            value={
              row.original.disb_amount !== null
              ? row.original.disb_amount
              : ""
            }
          ></CurrencyLabel>
        );
      },
      Aggregated: (row) => {
        let total = 0;

        row.subRows &&
          row.subRows
            .filter((obj) => obj.disb_date !== null)
            .map((item) => {
              total += parseInt(item.disb_amount);
            });
        return (
          <span className="badge badge-light p-2 text-secondary ">
            <strong>
              <CurrencyLabel
                value={
                  total !== null ? total : 0
                }
              ></CurrencyLabel>
            </strong>
          </span>
        );
      },
      Footer: (row) => {
        return (
          <strong>
            <CurrencyLabel decimal={2} value={_.sumBy(row.data, "disb_amount")}></CurrencyLabel>
          </strong>
        );
      },
    },
    {
      Header: "Advance Date",
      accessor: "disb_date",
      show: selectedTab === transStatus[0]["REJECTED"] ? false : true,

      headerStyle: { textAlign: "left" },
      Cell: (row) =>
        row.original.disb_date
          ? moment(row.original.disb_date, "YYYY-MM-DD").format("DD/MM/YYYY")
          : "",
      Aggregated: (row) => {
        // You can even render the cell differently if it's an aggregated cell
        return <span>{row.value}</span>;
      },
    },
    {
      Header: "Rejected Reason",
      accessor: "inv_rejected_reason",
      show: selectedTab === transStatus[0]["REJECTED"] ? true : false,
      Cell: (row) => <span className="text-danger">{row.original.inv_rejected_reason}</span>,
    },
    {
      Header: "Total Paid",
      accessor: "total_paid",
      headerStyle: { textAlign: "left" },
      show: selectedTab === transStatus[0]["REJECTED"] ? false : true,

      Cell: (row) => (
        <CurrencyLabel
          value={row.original.total_paid !== null ? row.original.total_paid : 0}
        ></CurrencyLabel>
      ),
      Aggregated: (row) => {
        // You can even render the cell differently if it's an aggregated cell
        return <span>{row.value}</span>;
      },
      Footer: (row) => {
        return (
          <strong>
            <CurrencyLabel decimal={2} value={_.sumBy(row.data, "total_paid")}></CurrencyLabel>
          </strong>
        );
      },
    },
    {
      Header: "Due Amt",
      accessor: "total_due",
      headerStyle: { textAlign: "left" },
      show: selectedTab === transStatus[0]["REJECTED"] ? false : true,

      Cell: (row) => (
        <CurrencyLabel
          value={row.original.total_due !== null ? row.original.total_due : 0}
        ></CurrencyLabel>
      ),
      Aggregated: (row) => {
        // You can even render the cell differently if it's an aggregated cell
        return <span>{row.value}</span>;
      },
      Footer: (row) => {
        return (
          <strong>
            <CurrencyLabel decimal={2} value={_.sumBy(row.data, "total_due")}></CurrencyLabel>
          </strong>
        );
      },
    },
    {
      Header: "Due Date",
      accessor: "inv_due_date",
      headerStyle: { textAlign: "left" },
      show: selectedTab === transStatus[0]["REJECTED"] ? false : true,

      Cell: (row) =>
        moment(row.original.inv_due_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      Aggregated: (row) => {
        // You can even render the cell differently if it's an aggregated cell
        return <span>{row.value}</span>;
      },
    },
    {
      Header: "Closed Date",
      accessor: "inv_closed_date",
      headerStyle: { textAlign: "left" },
      show: selectedTab === transStatus[0]["CLOSED"],

      Cell: (row) =>
        row.original.inv_closed_date !== null &&
        moment(row.original.inv_closed_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      Aggregated: (row) => {
        // You can even render the cell differently if it's an aggregated cell
        return <span>{row.value}</span>;
      },
    },
    {
      Header: "Residual Payment Date",
      accessor: "residual_paid_date",
      headerStyle: { textAlign: "left" },
      show: selectedTab === transStatus[0]["CLOSED"],

      Cell: (row) =>
        row.original.residual_paid_date !== null ? moment(row.original.residual_paid_date, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
        Aggregated: (row) => {
        // You can even render the cell differently if it's an aggregated cell
        return <span>{row.value}</span>;
      },
    },
    {
      Header: "Residual Payment Amt",
      accessor: "total_residual",
      headerStyle: { textAlign: "left" },
      show: selectedTab === transStatus[0]["CLOSED"],

      Cell: (row) =>
        <CurrencyLabel value={row.original.total_residual}></CurrencyLabel>,
        Aggregated: (row) => {
        // You can even render the cell differently if it's an aggregated cell
        return <span>{row.value}</span>;
      },
    },
    {
      Header: "Action",
      accessor: "action",
      headerStyle: { textAlign: "left" },
      minWidth: 50,
      show: (selectedTab === transStatus[0]["OPEN"] || selectedTab === transStatus[0]["CLOSED"] || selectedTab === transStatus[0]["APPROVED_FOR_PAYMENT"] || selectedTab === transStatus[0]["DRAFT"]) ? true : false,

      Cell: row => {
        return (
            selectedTab === transStatus[0]["DRAFT"] ? 
            <Link style={{ cursor: 'pointer' }} onClick={() => handleEditInvModal(row.original)}>
              <EditButton />
            </Link> :
            <>
              <Link to={`/client/if/invoice-details?trans_guid=${row.original.inv_trans_guid}&status=${selectedTab}&type=${props.type !== undefined ? props.type : 'general'}`} className="action-icons mr-2">
                <HistoryButton className="fa fa-file-text-o"></HistoryButton>
              </Link>
             {/* Remittance download link */}
              {/* <Link 
                onClick = { async () => {
                  setIsModel(true);
                  setTransactionId(row.original.inv_trans_guid);
                    const res =  await transactionApi.request({ trans_guid: row.original.inv_trans_guid, download:false,preview:true });
                    if(res){
                      setTempData(res.data);
                    }
                  }
                }
                label="Export" >
                <i className="fa fa-download" aria-hidden="true"></i>
              </Link> */}
            </>
           
        );
      }
    },
  ];

  const handleTabChange = status => {
    setSelectedTab(status);
    setSelectedRows([]);
    tableRef.current.clearSelection();
  }

  const RightPanel = () => (
      selectedBusinessType == constants.BUSINESS_TYPE.SALE && (
        <>

          {selectedRows.filter(item => item.checked === true).length > 0 && 
            <AddButton style="btn btn-secondary mr-2" label="Request Payment" onClick={() => { setReqPaymentModal(true); }} />
          }

          <div className="dropdown-menu" >
            <Link className="dropdown-item" to={`/client/if/bulk-upload?a_guid=${entity_id}`}>Bulk Upload</Link>
            <Link className="dropdown-item" onClick={exportExcel} label="Export" >Export</Link>
          </div>
          <button title="More actions" className="btn btn-outline-secondary mr-2 pr-sm-3 pr-2 pl-lg-2  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More</button>

          <AddButton style="btn btn-primary" label="Add New" onClick={() => { setTransModal(true); }} />
        </>
      )
  );

  const exportExcel = async () => {
    const res = await transactionService.getInvoiceList(
      {
        ...tableParams,
        pageSize: null,
        download: true
      },
      selectedTab
    );

    const newWindow = window.open(res.data);
    newWindow.opener = null;
  };

  const AdditionalFilter = () => (
    <>
      {
        tabs.length > 0 && (
          <div className="dropdown dash-dropdown">
            <button className="btn dropdown-toggle pr-sm-4 pr-3 p-2 w-100 text-left  btn-outline-secondary  dropdown-toggle  pr-sm-4 pr-3 pl-lg-2 w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {tabs.map(obj => <>{selectedTab == obj.value ? obj.label && obj.label.charAt(0).toUpperCase() + obj.label.slice(1) : ""}</>)}
            </button>

            <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
              {tabs.map(obj => <a className={`dropdown-item ${selectedTab == obj.value ? 'active' : ''}`} onClick={() => handleTabChange(obj.value)} href="#" >{obj.label && obj.label.charAt(0).toUpperCase() + obj.label.slice(1)}</a>)}
            </div>

          </div>
        )
      }
    </>
  )

  // ---------Open or Close invoice Modal----------- //
  const handleEditInvModal = (selectedInvoice = null) => {
    seteditInvoiceModal(true);
    setselectedInvoice(selectedInvoice);
  };

  //-----------------------Fetch facility settings for the Client-----------------------//
  const getFacilitySettings = async () => {
    const response = await entityDefaultsApi.request({
      entityId: entityDetails.a_guid,
    });

    if (response)
      setFacilitySettings((response.rows !== undefined && response.rows !== null) ?
        response.rows : null);
  };

  useEffect(() => {
    getFacilitySettings();
  }, []);

  const handlePopoutClick = () => {
    setShowPopout(true);
  };

  const closePopout = () => {
    setShowPopout(false);
  };

  return (
    <>
      <Helmet>
        <title>Client IF Transactions | {global.config.title}</title>
      </Helmet>

      {/*------------------Bulk Payments Modal-------------------*/}
      <Modal show={transModal} onHide={() => setTransModal(false)} centered>
        <div className="p-2">
          <CreateInvoice
            onClose={() => {
              setTransModal(false);
              tableRef.current.reloadData();
            }}
          />
        </div>
      </Modal>
      {/*---------------------------------------------------------*/}

      <Modal show={reqPaymentModal} onHide={() => { setReqPaymentModal(false); tableRef.current.clearSelection(); setSelectedRows([]); } } size="xl" centered>
          <RequestPayment 
            data = {selectedRows.filter(item => item.checked === true)} 
            facilitySettings = {facilitySettings}
            onClose={() => {
              setReqPaymentModal(false);
              tableRef.current.clearSelection();
              setSelectedRows([]);
              tableRef.current.reloadData();
            }}
          />
      </Modal>
      {/*---------------------------------------------------------*/}

      <Modal show={editInvoiceModal} onHide={() => seteditInvoiceModal(false)} centered >
        <div className="p-2">
          <CreateInvoice
            onClose={() => { 
              setselectedInvoice(null); 
              seteditInvoiceModal(false); 
              tableRef.current.reloadData();
            }}
            bulkUpload={false}
            selectedRow={selectedInvoice}
            action={false}
          />
        </div>
      </Modal>
      {/*------------------Remittance Preview Modal-------------------*/}
      <Modal show={isModel} onHide={() => setIsModel(false)} centered size="lg">
        <Modal.Header closeButton> {/* Add closeButton prop to display close button */}
          <Modal.Title>Remittance Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <JoditEditor value={tempData}
          onChange={(value) => {
            setTempData(value);
          }}
            />
          {/* {tempData && (
            <div dangerouslySetInnerHTML={{ __html: tempData }} />
          )} */}
        </Modal.Body>
        <Modal.Footer>
        <GeneralButton
              onClick={ async () => {
                  window.frames.print_frame.document.body.innerHTML = tempData;
                  window.frames.print_frame.window.focus();
                  window.frames.print_frame.window.print();
                }}
              name={"Print"}
        ></GeneralButton>
        <AddButton style="btn btn-primary " 
        onClick={ async () => {
          setIsModel(false);
            const resp =  await transactionApi.request({ trans_guid:transactionId , download:true,preview:false,tempData:tempData});
                  if(resp){
                    const newWindow = window.open(resp.data);
                    newWindow.opener = null;
                    window.location.reload();
                    
                  }
              }} label="Download"></AddButton>
        </Modal.Footer>
      </Modal>

      {/*------------------Remittance Preview Modal end-------------------*/}
      {/* print remittance details */}
      <iframe
        name="print_frame"
        width="0"
        height="0"
        frameborder="0"
        src="about:blank"
      ></iframe>
      {tableTitle === "Transactions" && (
    
          <div className="card overflow-visible">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-6"><h6>{(props.type === 'overdue' ? 'Overdue' : props.type === 'due' ? 'Due' : '')} Invoices</h6></div>
                <div className="col-lg-6">
                  <Link className="btn btn-light pull-right text-secondary ml-2" to={'/client/if/dashboard'}><i alt="Go to Dashboard" data-toggle="tooltip" data-placement="top" title="If Dashboard" className="fa fa-close"></i></Link>
                  {showPopout === false &&(
                    <a className="btn btn-light pull-right text-secondary ml-2" onClick={handlePopoutClick} >
                      <i className="fa fa-expand"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  {showPopout === false ? (
                    <Table
                      ref={tableRef} // 'Ref' is used to gain access to the methods of child component
                      service={transactionService.getInvoiceList}
                      searchService={transactionService.quickSearch}
                      cols={cols}
                      uid={"inv_guid"}
                      reqParams={{
                        selectedBusinessType,
                        overdue: props.type === 'overdue' ? true : false,
                        due: props.type === 'due' ? true : false,
                        searchByInvoiceOnly: true
                      }}
                      defaultTab={selectedTab}
                      /*
                      onSelectTab={(val) => {
                        setSelectedTab(val);
                        setSelectedRows([]);
                      }}
                      */
                      headerTabs={[]}
                      source={"single"}
                      pivot={"client_reference"}
                      disablePivot={true}
                      setTabs={options => setTabs(options)}
                      tableRightPanel={<RightPanel />}
                      additionalFilter={<AdditionalFilter />}
                      onDataLoad={(data, tableParams) => { // Callback function to trigger after populating data on Action table
                        setTableParams(tableParams)
                      }}
                      onSelectRow={
                        selectedTab === transStatus[0]["DRAFT"] && (facilitySettings && facilitySettings.can_request_payment_date === "yes") ? 
                        data => {setSelectedRows(data)} : false
                      }
                    />
                  ):(
                  <> 
                    <Modal className="fullscreen-modal" show={showPopout} onHide={closePopout}>
                      <Modal.Header closeButton>
                        <div className="d-block pt-3 text-center w-100 ml-3">
                          
                          <h3 className="modal-title">Invoices</h3>
                        
                        </div>
                      </Modal.Header>
                      <Modal.Body>
                        <Table
                          ref={tableRef} // 'Ref' is used to gain access to the methods of child component
                          service={transactionService.getInvoiceList}
                          searchService={transactionService.quickSearch}
                          cols={cols}
                          uid={"inv_guid"}
                          reqParams={{
                            selectedBusinessType,
                            overdue: props.type === 'overdue' ? true : false,
                            due: props.type === 'due' ? true : false,
                            searchByInvoiceOnly: true
                          }}
                          defaultTab={selectedTab}
                          /*
                          onSelectTab={(val) => {
                            setSelectedTab(val);
                            setSelectedRows([]);
                          }}
                          */
                          //headerTabs={[]}
                          source={"single"}
                          pivot={"client_reference"}
                          disablePivot={true}
                          setTabs={options => setTabs(options)}
                          tableRightPanel={<RightPanel />}
                          additionalFilter={<AdditionalFilter />}
                          onDataLoad={(data, tableParams) => { // Callback function to trigger after populating data on Action table
                            setTableParams(tableParams)
                          }}
                          onSelectRow={
                            selectedTab === transStatus[0]["DRAFT"] && (facilitySettings && facilitySettings.can_request_payment_date === "yes") ? 
                            data => {setSelectedRows(data)} : false
                          }
                        />
                      </Modal.Body>
                    </Modal>
                  </>
                  )}
                  
                </div>
              </div>
            </div>
          </div>
      )}

      {tableTitle === "Add new invoice/invoices" && (
        <BulkUpload></BulkUpload>
      )}

    </>
  );
}
