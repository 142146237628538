import React, { useState, useEffect } from "react"
import useApi from "../../hooks/useApi";
import accountServices from "../supply-chain/services/commonService";
import Modal from "react-bootstrap/Modal";
//import DisplayTemplates from "../../viewTemplates/displayTemplates";
import LoadingSpinner from "../common/loaders/loadingSpinner";
import {
    TextInput,
    Picker,
    GeneralIconButton,
} from "../common/inputs";
import { toast } from "react-toastify";
import { FormRow } from "../common/containers/formContainer";

export default function MailSchedulerList() {
    const templatesType = [
        { label: "SCF Individual Customer Invoice Upload", value: "SCF_INDIVIDUAL_INVOICE_CUSTOMER_UPLOAD" },
        { label: "TF Individual Customer Invoice Upload", value: "TF_INDIVIDUAL_INVOICE_CUSTOMER_UPLOAD" },
        { label: "SCF Bulk Invoice Upload", value: "SCF_BULK_INVOICE_UPLOAD" },
        { label: "TF Bulk Invoice Upload", value: "TF_BULK_INVOICE_UPLOAD" },
        { label: "SCF Individual Customer Approval", value: "SCF_INDIVIDUAL_INVOICE_CUSTOMER_APPROVAL" },
        { label: "TF Individual Customer Approval", value: "TF_INDIVIDUAL_INVOICE_CUSTOMER_APPROVAL" },
        { label: "SCF Individual Supplier Approval", value: "SCF_INDIVIDUAL_INVOICE_SUPPLIER_APPROVAL" },
        { label: "TF Individual Supplier Approval", value: "TF_INDIVIDUAL_INVOICE_SUPPLIER_APPROVAL" },
        { label: "SCF Bulk Customer Approval", value: "SCF_BULK_CUSTOMER_APPROVAL" },
        { label: "TF Bulk Customer Approval", value: "TF_BULK_CUSTOMER_APPROVAL" },
        { label: "SCF Bulk Supplier Approval", value: "SCF_BULK_SUPPLIER_APPROVAL" },
        { label: "TF Bulk Supplier Approval", value: "TF_BULK_SUPPLIER_APPROVAL" },
        { label: "SCF Individual Invoice Paid", value: "SCF_INDIVIDUAL_INVOICE_SUPPLIER_PAYMENT" },
        { label: "TF Individual Invoice Paid", value: "TF_INDIVIDUAL_INVOICE_SUPPLIER_PAYMENT" },
        { label: "SCF Bulk Invoice Paid", value: "SCF_BULK_SUPPLIER_PAYMENT" },
        { label: "TF Bulk Invoice Paid", value: "TF_BULK_SUPPLIER_PAYMENT" },
        { label: "SCF Payment Reminder", value: "SCF_PAYMENT_REMINDER" },
        { label: "TF Payment Reminder", value: "TF_PAYMENT_REMINDER" },
        { label: "Ne Loan Request Mail", value: "NEW_LOAN_REQUEST_RECEIVED" },
        { label: "Reset Password", value: "RESET_PASSWORD" },
        { label: "SCF Change Facility Limit / Settings", value: "SCF_CHANGE_FACILITY_DEFAULT_SETTINGS" },
        { label: "TF Change Facility Limit / Settings", value: "TF_CHANGE_FACILITY_DEFAULT_SETTINGS" },
        { label: "Change Invoice Status To Overdue", value: "INVOICE_STATUS_CHANGE_TO_OVERDUE" },
        { label: "Supplier Pending Funding List", value: "SUPPLIER_FUNDING_PENDING_MAIL" },
        { label: "Payment gateway notification", value: "ONLINE_PAYMENT_NOTIFICATION" },
        { label: "Create New Rate Approval", value: "CREATE_NEW_RATE_APPROVAL" }
    ];
    const [selectedType, setSelectedType] = useState(templatesType[0]);
    const [malShedulerList, setMalShedulerList] = useState(null);
    const [isList, setIsList] = useState(false);
    const [modal, setModal] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [receiverEmail, setReceiverEmail] = useState("");

    const FetchMailData = useApi(accountServices.fetchMailSchedulerData);
    const sendMailApi = useApi(accountServices.sendMail);

    const getMailSchedulerList = async (selectedType) => {
        const response = await FetchMailData.request({ selectedType: selectedType.value }); // Sending Async request to server
        if (response.data) {
            setMalShedulerList(response.data);
            setIsList(true)
        }
    }

    const sendEmail = async () => {
        if (receiverEmail == "") {
            toast.error("Please add receiver email");
            return false;
        }
        const response = await sendMailApi.request({
            guid: selectedItem.guid,
            receiverEmail: receiverEmail
        });
        if (response) {
            if (response.data.success) {
                toast.success(response.data.message);
                setReceiverEmail("");
            } else {
                toast.error(response.data.message);
            }
        } else {
            toast.error("Failed to send mail");
        }

    }


    useEffect(() => {
        getMailSchedulerList(selectedType)

    }, [selectedType])
    return (

        <>
            <>
                <div>
                    {sendMailApi.loading == true && <LoadingSpinner />}
                    <form className="form-horizontal">
                        <div className="row ">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Action</label>
                                    <div>
                                        <Picker
                                            items={templatesType}
                                            selectedItem={selectedType}
                                            onSelectItem={option => setSelectedType(option)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="card overflow-visible">
                    <div className="card-body">
                        <div className="card-title">Mail Scheduler List</div>
                        <div className="table-responsive my-3 overflow-visible">
                            <table class="table table-sm text-secondary">
                                <thead>
                                    <tr>
                                        <th> Recipient </th>
                                        <th> Subject </th>
                                        <th> Email </th>
                                        <th> Status </th>
                                        <th> Created Date </th>
                                        <th> View Template </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        malShedulerList && malShedulerList.length > 0 && malShedulerList.map((item, index) => (
                                            <>
                                                <tr>
                                                    <td>{item.recipient}</td>
                                                    <td>{item.subject}</td>
                                                    <td>{item.email_to}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.created_at}</td>
                                                    <td>
                                                        <GeneralIconButton
                                                            name={`ViewTemplate`}
                                                            className={`btn btn-primary`}
                                                            onClick={e => { setModal(true); setSelectedItem(item); setFileData(item.content) }}
                                                        ></GeneralIconButton>
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </>
            <>
                <Modal
                    show={modal}
                    onHide={(e) => setModal(false)}
                    size="lg" >
                    <Modal.Header closeButton>
                        <h4> {`View Template`} </h4>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            {/* dangerouslySetInnerHTML={{__html: File}} */}
                            <div
                                dangerouslySetInnerHTML={{ __html: fileData }}
                            />
                            <div className="row">
                                <div className="col-sm-8">
                                    <TextInput
                                        name="receiver_email"
                                        label="Receiver Email"
                                        value={receiverEmail}
                                        onChange={(e) => { setReceiverEmail(e.target.value) }}
                                        type=""
                                        placeholder="Receiver Email"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <button
                                        className="btn btn-primary float-right"
                                        onClick={() => { sendEmail() }}
                                    >
                                        Send Test Mail
                                    </button>
                                </div>
                            </div>
                        </>
                    </Modal.Body>
                </Modal>
            </>
        </>
    )
}