import React from "react";
import Select from "react-select";

export default function FeePicker({ onSelectItem, loading = false, onKeyDown = null, selectedItem = null, multiSelect = false, items, isDisabled = false, name = "", reference = null }) {

    const customStyles = {
        menu: provided => ({ ...provided, zIndex: 999 })
    };

    const formatOptionLabel = ({ mapped, label, customAbbreviation }) => 
        {
            return(
                mapped ?
                    <div>
                        <div title="mapped fees" style={{ color: 'green' }}>{label}</div>
                            <div> {customAbbreviation} </div>
                    </div> :
                    
                    <div>
                        <div> {label} </div>
                        <div> {customAbbreviation} </div>
                    </div> 
            )
        }
      ;

    //const isLoading = (items !== undefined && items.length > 0) ? false : true;
    return (
        <>
            <Select
                formatOptionLabel={formatOptionLabel}
                isLoading={loading}
                isDisabled={isDisabled}
                isMulti={multiSelect}
                options={items}
                onChange={(selectedOption) => {
                    onSelectItem(selectedOption);
                }}
                onKeyDown={e => { if (onKeyDown !== null) onKeyDown(e.target.value) }}
                value={selectedItem}
                styles={customStyles}
                theme={theme => ({
                    ...theme,
                    borderRadius: ".375rem",
                    colors: {
                        ...theme.colors,
                        text: "black",
                        primary25: "white",
                        primary: "#1ab394",
                        neutral0: "white"
                    }
                })}
                name={name}
                ref={reference}
            />
        </>
    );
}