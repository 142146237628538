import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  AddButton,
} from "../../../common/containers/button";
import { CurrencyLabel } from "../../../common/inputs";
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";
import auth from "../../../../services/authService";
import EntityContext from "../../../../context/entityContext";
//import Table from "../../../common/tables/ActionTable";
import Table from '../../components/table/index';
import { transStatus } from "../../../../config/predefinedValues.json";
import BatchUpload from "../../../debtorTransactions/components/BatchUpload";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import httpRequest from "../../../../services/httpService";
import ReactTooltip from 'react-tooltip';

class InvoicesList extends Component {
  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  state = {
    selectedTab: "draft",
    selectedRows: [],
    selectedRole: { label: "Select category", value: null },
    tableTitle: "Invoices",
    tabs: [],
    tableParams: [],
    showExport: false
  };


  // Callback function to the Action Table.
  handleRowSelection = (selectedRows) => {
    this.setState({ selectedRows });
  };

  handleReloadData = () => {
    this.tableRef.current.reloadData();
    this.tableRef.current.clearSelection();
  };

  componentDidMount = () => {
    const { constants } = auth.getCurrentUser();

    if (this.context.entityDetails.a_guid)
      this.setState({
        selectedRole: {
          label: constants.CATEGORY.DFDEBTOR,
          value: constants.CATEGORY.DFDEBTOR,
        },
      });
  };

  componentWillUnmount() {
    httpRequest.cancel();
  }

  handleTab = (tab, title = null) => {
    this.setState({
      selectedTab: tab,
      selectedRows: [],
    });
    if (this.tableRef.current) this.tableRef.current.clearSelection();
  };



  render() {

    const cols = [
      {
        Header: "Invoice Number",
        accessor: "inv_number",
        headerStyle: { textAlign: "left" },
        minWidth: 150,
        Aggregated: (row) => {
          // You can even render the cell differently if it's an aggregated cell
          return <span>{row.value}</span>;
        },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Cell: (row) => <span>{row.original.inv_number}</span>,
      },
      {
        Header: "Trans Ref #",
        accessor: "client_reference",
        headerStyle: { textAlign: "left" },
        minWidth: 150,
        resizable: false,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
      },
      {
        Header: "Debtor",
        accessor: "inv_debtor_name",
        headerStyle: { textAlign: "left" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Cell: (row) => <span>{row.original.debtor_organisation}</span>,
      },
      {
        Header: "Invoice Amt",
        accessor: "inv_amount",
        headerStyle: { textAlign: "left" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Cell: (row) => (
          <span>
            <CurrencyLabel
              value={
                row.original.inv_amount !== null ? row.original.inv_amount : 0
              }
            ></CurrencyLabel>
          </span>
        ),
      },
      {
        Header: "Outstanding Amt",
        accessor: "outstanding",
        headerStyle: { textAlign: "left" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Cell: (row) => (
          <span>
            <CurrencyLabel
              value={
                row.original.outstanding !== null ? row.original.outstanding : 0
              }
            ></CurrencyLabel>
          </span>
        ),
      },
      {
        Header: "Date",
        accessor: "inv_date",
        headerStyle: { textAlign: "left" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        minWidth: 50,
        Cell: (row) => (
          <span style={{ color: row.original.status == "inactive" && "red" }}>
            {" "}
            {row.original.inv_date}
          </span>
        ),
      },
      {
        Header: "Due Date",
        accessor: "inv_due_date",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Cell: (row) => (
          <span style={{ color: row.original.status == "inactive" && "red" }}>
            {" "}
            {row.original.inv_due_date}
          </span>
        ),
      },
      {
        Header: "Days",
        accessor: "days",
        headerStyle: { textAlign: "left" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
      },
      {
        Header: "Type",
        accessor: "credit_notes",
        headerStyle: { textAlign: "left" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Cell: (row) => (
          <span
            className={`badge ${row.original.credit_notes == "0"
                ? "badge-light text-danger"
                : "badge-light text-secondary"
              }     p-2 `}
          >
            {row.original.credit_notes == "0" ? "CR Notes" : "Invoice"}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "action",
        headerStyle: { textAlign: "left" },
        minWidth: 50,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.trans_approved_status == "declined"
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        },
        Cell: (row) => {
          return (
            <>
              {row &&
                row.original &&
                row.original.trans_approved_status == "declined" ? (
                  <>
                  <span
                    title="Disapproval reason"
                    className="text-danger font-weight-bold"
                    data-multiline="true"
                    data-tip={row.original.comments}
                  >
                    <i className="fa fa-warning text-danger mt-2"></i>
                  </span>
                  <ReactTooltip backgroundColor="#11698e" />
                </>
              ) : null}
            </>
          );
        },
      },
    ];

    const RightPanel = () => (
      <>
        <AddButton
          style="btn   btn-primary pull-right m-l-sm df-batch-upload-btn ml-2"
          label="Batch Upload"
          onClick={(e) => this.setState({ tableTitle: "Batch Upload" })}
        ></AddButton>

        {this.state.showExport &&
          <AddButton
            style="btn btn-secondary pull-right m-l-sm df-batch-upload-btn"
            label="Export"
            onClick={() => exportExcel()}
          ></AddButton>
        }
      </>
    );

    const AdditionalFilter = () => (
      <>
        {
          this.state.tabs.length > 0 && (
            <div className="dropdown dash-dropdown">
              <button className="btn dropdown-toggle pr-sm-4 pr-3 p-2 w-100 text-left  btn-outline-secondary  dropdown-toggle  pr-sm-4 pr-3 pl-lg-2 w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.state.tabs.map(obj => <>{this.state.selectedTab == obj.value ? obj.label && obj.label.charAt(0).toUpperCase() + obj.label.slice(1) : ""}</>)}
              </button>

              <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                {this.state.tabs.map(obj => <a className={`dropdown-item ${this.state.selectedTab == obj.value ? 'active' : ''}`} onClick={() => this.handleTab(obj.value)} href="#" >{obj.label && obj.label.charAt(0).toUpperCase() + obj.label.slice(1)}</a>)}
              </div>

            </div>
          )
        }
      </>
    )

    const exportExcel = async () => {
      this.setState({ loading: true });
      const res = await dfTransactionService.getInvoiceList(
        {
          ...this.state.tableParams,
          pageSize: null,
          download: true
        },
        this.state.selectedTab
      );
      this.setState({ loading: false });
      const newWindow = window.open(res.data);
      newWindow.opener = null;
    };

    return (
      <>
        <Helmet>
          <title>DF Transactions | {global.config.title}</title>
        </Helmet>

        {this.state.loading && <LoadingSpinner />}
        <div className="card overflow-visible">
          <div className="row align-items-center mx-0 px-4 pt-4">
            <div>
              <h4 className="card-title">{this.state.tableTitle}</h4>
            </div>
  
          </div>
          <div className="card-body">
            {this.state.tableTitle === "Invoices" && (
              <Table
                ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
                service={dfTransactionService.getInvoiceList}
                searchService={dfTransactionService.quickSearch}
                cols={cols}
                reqParams={{
                  clientReportType: true,
                  selectedBusinessType: this.context.selectedBusinessType,
                  searchByInvoiceOnly: true,
                }}
                defaultTab={this.state.selectedTab ? this.state.selectedTab : "draft"}
                headerTabs={[]}
                source={"batch"}
                setTabs={options => this.setState({ tabs: options })}
                tableRightPanel={<RightPanel />}
                additionalFilter={<AdditionalFilter />}
                onDataLoad={(data, tableParams) => { // Callback function to trigger after populating data on Action table
                  this.setState({ tableParams, showExport: data.rows.length > 0 ? true : false })
                }}
              />
            )}
            {this.state.tableTitle === "Batch Upload" && (
              <BatchUpload handleTab={this.handleTab} redirectInvoiceListPage={() => this.setState({ tableTitle: "Invoices" })}></BatchUpload>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default InvoicesList;
