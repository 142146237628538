import React, { useState } from "react";
import { Picker } from '../common/inputs';

function DateTypes({ onSelectItem }) {

    
    const [selectedItem, setSelectedItem] = useState({   label: "Invoice Date",   value: "trans_date" });
    const dateTypes =   [
      {
        label: "Invoice Date",
        value: "trans_date"
      },
      {
        label: "Invoice Original Due Date",
        value: "inv_due_date"
      },
      {
        label: "Required Early Payment Date",
        value: "inv_early_payment_date"
      },
      {
        label: "Revised Due Date",
        value: "inv_revised_due_date"
      },
      {
        label: "Approval Date",
        value: "approval_time"
      },
      {
        label: "Supplier Paid Date",
        value: "paid_at"
      },
      {
        label: "Actual Date Invoice Paid",
        value: "transaction_closed_date"
      }
    ];

    return (
        <>
            <>
                <Picker items={dateTypes}
                    selectedItem={selectedItem}
                    onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
                />
            </>
        </>
    )
};
export default DateTypes;