import React, { useContext, useState, useEffect } from "react";
import { EntityDebtorList } from "../../slplusTools";
import { GeneralButton, Picker } from "../../common/inputs";

import TransactionContext from "../../../context/invTransactionContext";
import EntityContext from "../../../context/entityContext";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import authService from "../../../services/authService";

export default function DebtorMapping({
  onClose,
  selectedRow,
  handleUpdateInvoice = null,
  context = true,
  invoiceSet = null,
  debtorInfo = null
}) {
  let transaction = {};
  let invoice = [];
  let updateInvoice;

  const { constants } = authService.getCurrentUser();

  let contextObj = useContext(TransactionContext);
  const entityContext = useContext(EntityContext);

  const [selectedDebtor, setSelectedDebtor] = useState(null);
  const [debtorsList, setDebtorsList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  
  if (context === true) {
    transaction = contextObj.transaction;

    // -------- If Debtor id provided, allow the user to change the Debtor -------- //
    if(debtorInfo)
      invoice = contextObj.invoice.filter(obj => obj.inv_debtor_id === debtorInfo.inv_debtor_id);
    else // Displays a list of invoices that are not linked to the debtor.
      invoice = contextObj.invoice;//.filter((obj) => obj.debtor_name === false);
    // ---------------------------------------------------------------------------- //
    
    updateInvoice = contextObj.updateInvoice;
    if (!transaction) {
      // ---- Set BP/Client default values from Context API (if applicable) ---- //
      if (entityContext && typeof entityContext.entityDetails !== "undefined") {
        transaction = {
          bp: entityContext.entityDetails.owner.a_guid,
          client: entityContext.entityDetails.a_guid,
        };
      } else {
        transaction = {
          bp: selectedRow.trans_owner,
          client: selectedRow.trans_client_guid,
        };
      }
      //----------------------------------------------------------------------//
    }
  } else {

    // -------- If Debtor id provided, allow the user to change the Debtor -------- //
    if(debtorInfo)
      invoice = invoiceSet.filter(obj => obj.inv_debtor_id === debtorInfo.inv_debtor_id);
    else // Displays a list of invoices that are not linked to the debtor.
      invoice = invoiceSet.filter((obj) => obj.debtor_name === false);
    // ---------------------------------------------------------------------------- //

    transaction = {
      bp: entityContext.entityDetails.owner.a_guid,
      client: entityContext.entityDetails.a_guid,
    };
  }

  //----------------- add invoice to context api ------------------//
  const handleDebtorChange = (index, debtor) => {
    let updatedInv = invoice.find((obj) => obj.index == index);
    updatedInv["debtor_name"] = debtor.label;
    updatedInv["inv_debtor_id"] = debtor.value;
    updatedInv["checked"] = false;
    if (context == true) 
      updateInvoice(index, updatedInv);
    else
      handleUpdateInvoice(index, updatedInv);

    if (invoice.filter((obj) => obj.debtor_name === false).length === 0)
      onClose();
  };
  //---------------------------------------------------------------//

  const handleDebtorBulkAssign = () => {
    if (selectedDebtor === null) {
      toast.error("Please select debtor from the list.");
      return;
    }
    
    let selectedInvoices = invoice.filter((obj) => obj.checked === true);
    if(selectedInvoices.length === 0)
    {
      toast.error('Please select invoice(s) to be mapped.');
      return;
    }

    selectedInvoices
      .map((item) => {
        item["debtor_name"] = selectedDebtor.label;
        item["inv_debtor_id"] = selectedDebtor.value;
        item["checked"] = false;
        if (context == true) {
          updateInvoice(item["index"], item);
        } else handleUpdateInvoice(item["index"], item);
      });
    onClose();
  };

  // Handle bulk selection of Checkbox on the header
  const handleBulkSelection = ({ target }) => {
    invoice
      .filter((obj) => obj.debtor_name === false)
      .map((item) => {
        item.checked = !item.checked;//target.checked;
        if (context == true) {
          updateInvoice(item["index"], item);
        } else handleUpdateInvoice(item["index"], item);
      });

      setSelectAll(!selectAll/*target.checked*/);
  }

  // Handle checkbox change for Individual rows
  const handleIndividualSelection = (checked, index) => {

    let updatedInv = invoice.find((obj) => obj.index == index);
    updatedInv["checked"] = checked;

    if(context == true) 
      updateInvoice(index, updatedInv);
    else
      handleUpdateInvoice(index, updatedInv);
    
    if(checked === false)
      setSelectAll(false);
  };
  // ------------------------------------------------------------- //

  // Clear check box selections
  useEffect(() => {
    invoice.map((item) => {
      item.checked = false;
      if (context == true)
        updateInvoice(item["index"], item);
      else 
        handleUpdateInvoice(item["index"], item);
    });
  }, [])

  return (
    <>
      <Modal.Header closeButton>
        <h4 className="modal-title">{!debtorInfo ? 'Invoice - Debtor Mapping' : 'Change Debtor'}</h4>
      </Modal.Header>

      <Modal.Body>

        <div className="row justify-content-center">
          <div className="alert alert-info">
            Please select debtors from the dropdown below to map with the uploaded sheet.
          </div>
        </div>

        <div className="row">

          <div className="col-lg-6">
            <EntityDebtorList
              selectedDebtor={selectedDebtor}
              client={transaction.client ? transaction.client : ""}
              onSelectItem={(debtor) => setSelectedDebtor(debtor)}
              product={constants.PRODUCTS.INVOICE}
              getData={list => setDebtorsList(list)}
              addNew={true}
            />
          </div>
          <div className="col-lg-6">
            {selectedDebtor && 
              <GeneralButton
                onClick={handleDebtorBulkAssign}
                className="btn btn-primary"
                name="Allocate debtor to all invoices"
              ></GeneralButton>
            }
          </div>
        </div>
                
        <table class="table table-sm client-tble">
          <tbody>
            <tr>

            {selectedDebtor && 
              <th>
                <div className="custom-control d-flex custom-checkbox small">
                  <input
                    id="customCheck"
                    className="custom-control-input"
                    type="checkbox"
                    checked={selectAll}
                    onClick={handleBulkSelection}
                  ></input>
                  <label
                    className="custom-control-label"
                    for="customCheck"
                  ></label>
                </div>
              </th>
              }
             
              <th>Invoice No</th>
              <th>Name / ID in the sheet</th>
              <th>Name / ID in the system</th>
              <th>Status</th>
            </tr>
            {invoice
              .map((item, index) => (
                <tr>

                {selectedDebtor && 
                  <td>
                    <div className="custom-control d-flex custom-checkbox small">
                      <input
                        id={index}
                        className="custom-control-input"
                        type="checkbox"
                        onClick={(e) =>
                          handleIndividualSelection(
                            e.target.checked,
                            item.index
                          )
                        }
                        checked={item.checked}
                        disabled={item.debtor_name ? true : false}
                      ></input>
                      <label
                        className="custom-control-label"
                        for={index}
                      ></label>
                    </div>
                  </td>
                  }
                 
                  <td>{item.inv_number}</td>
                  <td>{item.debtor_name_in_sheet}</td>
                  <td>
                    <Picker
                      selectedItem={{label: item.debtor_name, value: item.inv_debtor_id}}
                      items={debtorsList}
                      onSelectItem={(selectedDebtor) => {
                        handleDebtorChange(item.index, selectedDebtor)
                      }}
                    />
                  </td>
                  <td>{item.inv_debtor_id ? <span style={{ color: '#ffffff' }} className="badge badge-success">Mapped</span> : <span className="badge badge-danger">Not Mapped</span>}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal.Body>
    </>
  );
}
