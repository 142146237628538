import React, { useState } from "react";
import { useDispatch } from 'react-redux'
import startIcon2 from "../../.././../img/icons/FMB_icon2.svg";
import {
    AddButton,
} from "../../../common/inputs";
import { RadioButton } from "../../../common/inputs/radioButton/radioButton";

export default function Page3({ goNext, goPrevious, handleChange }) {
    const [cashFlow, setCashflow] = useState(null)

    // const {improveCashFlow, setImproveCashFlow} =  useContext(FinanceBusinessContext);
    const [continueButton, setContinueButton] = useState(cashFlow ? "primary" : "dark");

    const dispatch = useDispatch();

    const improveCashFlows = (data) => {
        // setImproveCashFlow({improveCashFlow : data});
        setContinueButton("primary");
        // // goNext();

        // dispatch(saveQuestionAnswer({improveCashFlow : data,questionId:'slide-3-q-1'}))

        setCashflow(data)
    }
    const goNextPage = () => {
        // debugger
        console.log({ cashFlow });
        if (cashFlow === 'Yes') {
            goNext('cashFlowQestions');
        }
        else {
            goNext('workingCapitalChoose');
        }

    }

    return (
        <>
            <div id="animation_box" className="animated fadeIn">
                <div className="mb-0">
                    {/* <i className="fa fa-close mr-2 text-right m-l-xs mt-4 mr-4" onClick={() => goPrevious('sure')} /> */}
                    <div className="card-body">
                        <div className="row vh-70 justify-content-center align-items-center py-5">
                            <div className="col-lg-3  col-md-12">
                                <div className="operate-image">
                                    <img src={startIcon2} alt="image" width className="" />
                                </div>
                            </div><div className="col-lg-6 offset-lg-1 col-md-12">
                                <div className>
                                    {/* <span className="text-success mb-3 font-medium">Let’s get started…
                                    </span> */}
                                    <h6 className="mt-3 mb-4">The next questions will help you personalise your funding requirements for your business
                                    </h6><h4>Cash Flow Finance
                                    </h4>
                                    <p>With cash flow finance you can accelerate how quickly your sales invoices get paid, while your business customers enjoy their normal payment terms with you.
                                    </p><p>Cash flow finance is the smart way to:
                                    </p>
                                    <ul className="list-unstyled ml-4">
                                        <li><i className="fa fa-check mr-2" />Accelerate invoice payments
                                        </li>
                                        <li><i className="fa fa-check mr-2" />De-risk late payments
                                        </li>
                                        <li><i className="fa fa-check mr-2" />Maximise inward cash flows
                                        </li>
                                        <li className="d-flex"><i className="fa fa-check mr-2" /><span>Manage payment terms with bigger customers who need longer payment terms</span>
                                        </li>
                                    </ul><p>It’s also a fantastic tool to help you grow your business, so you know you’re in control of when you will be paid which means you can even offer business customers and prospects extended payment terms, over and beyond what your competition do.
                                    </p><div className="pt-3">
                                        <p className="font-medium">Are you looking to improve your cash flow?  </p>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <RadioButton
                                                name="customRadioInline1"
                                                value="Yes"
                                                checked={cashFlow === 'Yes' ? true : cashFlow === 'No' ? false : null}
                                                onChange={(e) => {
                                                    improveCashFlows(e.target.value);
                                                }}
                                                id="Yes"
                                                label="Yes"
                                                className="custom-control custom-radio mt-2"
                                            ></RadioButton>
                                            {/* <input type="radio" id="customRadioInline1" name="customRadioInline1" className="custom-control-input" />
                                            <label className="custom-control-label" htmlFor="customRadioInline1" onClick={()=> improveCashFlows("Yes")}>Yes</label> */}
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <RadioButton
                                                name="customRadioInline1"
                                                value="No"
                                                checked={cashFlow === 'No' ? true : cashFlow === 'Yes' ? false : null}
                                                onChange={(e) => {
                                                    improveCashFlows(e.target.value);
                                                }}
                                                id="No"
                                                label="No"
                                                className="custom-control custom-radio mt-2"
                                            ></RadioButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-10"><div className="d-flex justify-content-end align-items-center mt-3">
                                <a href="#" onClick={() => goPrevious('sure')} className="btn btn-light mr-1">Back</a>
                                <AddButton disabled={cashFlow ? false : true} onClick={() => goNextPage()} label="Continue" style={`btn btn-${continueButton} align-center ml-1`}></AddButton>
                            </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

