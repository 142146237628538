import React, { useEffect, useState, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import EntityContext from "../../../context/entityContext";
import ScPurchaseCommon from "./SCWidgets/scPurchaseCommon";
import RoleChecker from "../../../utils/roleChecker";
import authService from "../../../services/authService";
import NoAccess from "../pages/../widgets/noAccess";
import { Link } from "react-router";

import useApi from '../../../hooks/useApi';
import useIsMounted from "../../../hooks/useIsMounted";
import IFPurchaseCommon from "./IFTransWidgets/iFPurchaseCommon"
import BatchPurchaseCommon from "./BatchWidgets/batchPurchaseCommon"
import coreService from "../../../services/coreService";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import TfPurchaseCommon from "./TFWidgets/tfPurchaseCommon";
import LoanCommon from "./LoanWidgets/loanCommon";
import InvoiceCard from "../components/generalTransaction/invoiceCard";
import UpdateAccountDetails from "../widgets/general/updateAccountDetails";

export default function Common() {
    const isMounted = useIsMounted();
    const [updateAccModal, setUpdateAccModal] = useState(false);
    const { selectedBusinessType, handleProductChange, entityDetails, primaryFacilities, purchaseRoles, salesRoles } = useContext(EntityContext);
    const { constants, products, sc_invoice_status, entity_id } = authService.getCurrentUser();
    const [ajaxRequest, setAjaxRequest] = useState(false);
    const [receivableInvoiceList, setReceivableInvoiceList] = useState([]);
    const [payableInvoiceList, setPayableInvoiceList] = useState([]);
    const [product, setProduct] = useState(null);
    var fecilityPrimary = 1;

    function getActiveCards() {
        const activeRoles = RoleChecker.checkRoleCategory(entityDetails.products, constants.PRIMARY_ROLES);
        return activeRoles.primary.length;
    }

    const generalTransaction = useApi(coreService.getGeneralTransaction); //getInvoice
    const fetchGeneralTransactionData = async () => {
        setReceivableInvoiceList([]);
        setPayableInvoiceList([]);
        const response = await generalTransaction.request({ "roleType": "Sale", "pageSize": "100", "owner": entity_id, "clientSide": "true" }, sc_invoice_status['awaiting_supplier_update']);
        if (response && isMounted()) {
            setReceivableInvoiceList(response.data.receivable);
            setPayableInvoiceList(response.data.payable);
            setAjaxRequest(false);
        }
    }

    const loanDashboard = () => {
        handleProductChange(constants.PRODUCTS.LOAN);
    }

    const handleUpdateNickNameModal = product => {
        setProduct(product);
        setUpdateAccModal(true);
    }

    useEffect(() => {
        fetchGeneralTransactionData();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>
            {ajaxRequest && <LoadingSpinner />}
            {/*===============Supply Chain Common Dashboard====================*/}

            {/*------------------Switch Organisation Modal-------------------*/}
            <Modal show={updateAccModal} onHide={() => setUpdateAccModal(false)} size="lg">
                <UpdateAccountDetails product={product} onClose={() => setUpdateAccModal(false)} />
            </Modal>

            {(entityDetails.products.length > 0 && entityDetails.products.some(item => item.isChecked == true)) ? (
                <>
                    <div className="mt-4">
                        <div className={`d-flex flex-row flex-nowrap overflow-auto ${getActiveCards() === 1 ? 'card-center' : ''}`} >

                            {/*----------------------Supply Chain-------------------------------*/}
                            {entityDetails.products.findIndex(obj => (obj.id == constants.PRODUCTS.SUPPLYCHAIN && obj.isChecked == true)) !== -1 && (
                                <>
                                    { /*-------SC Purchase Role widget--------------------------*/}
                                    {RoleChecker.check(products, constants.ROLES.CUSTOMER) && (
                                        <ScPurchaseCommon
                                            feciltyCount={purchaseRoles.length > 0 ? purchaseRoles.find(obj => obj.product == constants.PRODUCTS.SUPPLYCHAIN).facilityNumber : ''}
                                            nickName={purchaseRoles.length > 0 ? purchaseRoles.find(obj => obj.product == constants.PRODUCTS.SUPPLYCHAIN).nick_name : ''}
                                            menu={[
                                                { label: 'Update Nickname', onClick: () => { handleUpdateNickNameModal(constants.PRODUCTS.SUPPLYCHAIN) } },
                                            ]}
                                            to={'/client/scf/dashboard'}
                                        ></ScPurchaseCommon>
                                    )}
                                </>
                            )
                            }
                            {/*--------------------------Supply Chain ends--------------------------------*/}

                            {/*----------------------Invoice Finance-------------------------------*/}
                            {entityDetails.products.findIndex(obj => (obj.id == constants.PRODUCTS.INVOICE && obj.isChecked == true)) !== -1 &&
                                (
                                    <>
                                        { /*-------IF Purchase Role widget--------------------------*/}
                                        {RoleChecker.check(products, constants.ROLES.CLIENT) && (
                                            <IFPurchaseCommon
                                                feciltyCount={salesRoles.length > 0 ? salesRoles.find(obj => obj.product == constants.PRODUCTS.INVOICE).facilityNumber : ''}
                                                nickName={salesRoles.length > 0 ? salesRoles.find(obj => obj.product == constants.PRODUCTS.INVOICE).nick_name : ''}
                                                menu={[
                                                    { label: 'Update Nickname', onClick: () => { handleUpdateNickNameModal(constants.PRODUCTS.INVOICE) } },
                                                ]}
                                                to={'/client/if/dashboard'}
                                            ></IFPurchaseCommon>
                                        )}
                                    </>
                                )
                            }

                            {/*--------------------------Invoice Finance ends--------------------------------*/}

                            {/*----------------------------  Trade Finance ---------------------------------------*/}
                            {entityDetails.products.findIndex(obj => (obj.id == constants.PRODUCTS.TRADEFINANCE && obj.isChecked == true)) !== -1 && (
                                <>
                                    { /*-------SC Purchase Role widget--------------------------*/}
                                    {RoleChecker.check(products, constants.ROLES.TFCUSTOMER) && (
                                        <TfPurchaseCommon
                                            feciltyCount={purchaseRoles.length > 0 ? purchaseRoles.find(obj => obj.product == constants.PRODUCTS.TRADEFINANCE).facilityNumber : ''}
                                            nickName={purchaseRoles.length > 0 ? purchaseRoles.find(obj => obj.product == constants.PRODUCTS.TRADEFINANCE).nick_name : ''}
                                            menu={[
                                                { label: 'Update Nickname', onClick: () => { handleUpdateNickNameModal(constants.PRODUCTS.TRADEFINANCE) } },
                                            ]}
                                            to={'/client/tf/dashboard'}
                                        />
                                    )}
                                </>
                            )
                            }
                            {/*------------------------------- Trade Finance ends ------------------------------------*/}

                            {/*----------------------------   Batch Finance-------------------------------*/}
                            {entityDetails.products.findIndex(obj => (obj.id == constants.PRODUCTS.DEBTORFINANACE && obj.isChecked == true)) !== -1 &&
                                (
                                    <>
                                        { /*-------WOB Purchase Role widget--------------------------*/}
                                        {RoleChecker.check(products, constants.ROLES.DFCUSTOMER) && (
                                            <BatchPurchaseCommon
                                                feciltyCount={salesRoles.length > 0 ? salesRoles.find(obj => obj.product == constants.PRODUCTS.DEBTORFINANACE).facilityNumber : ''}
                                                //feciltyCount={fecilityPrimary++}
                                                nickName={salesRoles.length > 0 ? salesRoles.find(obj => obj.product == constants.PRODUCTS.DEBTORFINANACE) ? salesRoles.find(obj => obj.product == constants.PRODUCTS.DEBTORFINANACE).nick_name : "" : ''}
                                                menu={[
                                                    { label: 'Update Nickname', onClick: () => { handleUpdateNickNameModal(constants.PRODUCTS.DEBTORFINANACE) } },
                                                ]}
                                                to={'/client/wob/dashboard'}
                                            ></BatchPurchaseCommon>
                                        )}
                                    </>
                                )
                            }
                            {/*----------------------------   Batch Finance ends -------------------------------*/}

                            {/*----------------------------   Loan Start -------------------------------*/}
                            {entityDetails.products.findIndex(obj => (obj.id == constants.PRODUCTS.LOAN && obj.isChecked == true)) !== -1 &&
                                (
                                    <>

                                        { /*-------Business Loan Role widget--------------------------*/}
                                        {RoleChecker.check(products, constants.ROLES.LOANCLIENT) && (
                                            <LoanCommon
                                                feciltyCount={salesRoles.length > 0 ? salesRoles.find(obj => obj.product == constants.PRODUCTS.LOAN).facilityNumber : ''}
                                                //feciltyCount={fecilityPrimary++}
                                                nickName={salesRoles.length > 0 ? salesRoles.find(obj => obj.product == constants.PRODUCTS.LOAN) ? salesRoles.find(obj => obj.product == constants.PRODUCTS.LOAN).nick_name : "" : ''}
                                                menu={[
                                                    { label: 'Update Nickname', onClick: () => { handleUpdateNickNameModal(constants.PRODUCTS.LOAN) } },
                                                ]}
                                                to={'/client/loan/dashboard'}
                                            ></LoanCommon>
                                        )}
                                    </>
                                )
                            }

                            {/*----------------------------   Loan Ends -------------------------------*/}

                            <div className="col-sm-6  col-lg-4 col-xl-4">
                                <div className="card min-ht"><div className="card-body">
                                    <div className="m-2 border  rounded-2xl text-center bg-light min-ht card-hover mb-3">
                                        <Link to="/client/finance-business">
                                            <div className="px-5 py-4 margin-tb-50 margin-tp-17 mr-100">
                                                <div className="add-round">
                                                    <i className="fa fa-plus fa-2x"></i>
                                                </div>
                                                <h5 className="mt-4 text-muted font-weight-bold f-15">Add Facility</h5>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            {/*----------------------------------------------------------*/}
                        </div>
                    </div>
                    {/*===============Invoice Finance Common Dashboard====================*/}
                    <div className="row justify-content-center   mt-4 mb-2">

                        {primaryFacilities && primaryFacilities.find(obj => obj === constants.ROLES.CUSTOMER || obj === constants.ROLES.TFCUSTOMER) && (
                            <div className="col-lg-6">
                                <InvoiceCard
                                    title={'UNASSIGNED ACCOUNTS PAYABLE'}
                                    invoiceList={payableInvoiceList}
                                    addInvoiceUserType="addAsSupplier"
                                    loading={generalTransaction.loading}
                                    fetchGeneralTransactionData={fetchGeneralTransactionData}

                                />
                            </div>
                        )}

                        {primaryFacilities && primaryFacilities.find(obj => obj === constants.ROLES.CLIENT || obj === constants.ROLES.DFCUSTOMER) && (
                            <div className="col-lg-6 align-center">
                                <InvoiceCard
                                    title={'UNASSIGNED ACCOUNTS RECEIVABLE'}
                                    invoiceList={receivableInvoiceList}
                                    addInvoiceUserType="addAsCustomer"
                                    loading={generalTransaction.loading}
                                    fetchGeneralTransactionData={fetchGeneralTransactionData}

                                />
                            </div>
                        )}

                    </div>
                </>
            ) : (<NoAccess></NoAccess>)
            }
        </>
    )

}