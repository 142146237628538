import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { Picker } from '../common/inputs';

import coreService from "../../services/coreService";
import authService from "../../services/authService";
import useApi from '../../hooks/useApi';

function CustomerList({ onSelectItem, selectedCustomer = null, bp = null, 
    defaultValue = { label: "Select Customer (Account)", value: null },
    bpSelected = true,category = null }) {
    const { constants } = authService.getCurrentUser();
    const [selectedItem, setSelectedItem] = useState(defaultValue);
    const { request, data, loading } = useApi(coreService.getCustomersList); // Custom hook for HTTP Requests

    const prevBp = useRef();

    useEffect(() => {

        if ( (bp != null && bp !== prevBp.current ) || (bpSelected == false && bp !== prevBp.current)) {
            prevBp.current = bp;
            request({ category: category ? category : constants.CATEGORY.CUSTOMER, bp,bpSelected }); // Calling HTTP service
        }

        if (selectedCustomer !== null && data.length > 0) {
            const customerDetails = data.find(obj => obj.value == selectedCustomer);
            if (customerDetails)
                setSelectedItem({ label: customerDetails.label, value: customerDetails.value })
        } else if (selectedCustomer === null)
            setSelectedItem(defaultValue);

    }, [bp, selectedCustomer, data]);

    return (
        <>
            <>
                {loading == true && <LoadingSpinner />}
                <Picker items={data}
                    selectedItem={selectedItem}
                    onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
                />
            </>
        </>
    )
};
export default CustomerList;