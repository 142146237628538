import http from "./httpService";
import client from "./serviceClient";
import { apiUrl, queryString } from "../../src/environment";
/*------------------------------------*/

let accessToken = "";

function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      LogToken:localStorage.getItem("log_token")
    }
  };
}
export async function getNotifications(login_user) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/get-notifications-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { login_user };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    //console.log(data);
    return data;
  } catch (data) {
    return console.log(data);
  }
}
//---------------------------------------------------------------
export async function getNotificationsList(login_user) {


  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/get-notifications-all?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { login_user };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    //console.log(data);
    return data;
  } catch (data) {
    return console.log(data);
  }
}
export async function readNotification(notification_guid) {
  updateHeader();
  let url =
    apiUrl + "/entity/entity/notification-read?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { notification_guid };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    //console.log(data);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

// Service to fetch the list of notiifcation
const getNotificationsAll = (params) => client.post(`/entity/entity/get-notifications-by-entity?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
// Service to mark as read
const readNotificationAll = (params) => client.post(`/entity/entity/notification-read-all?XDEBUG_SESSION_START=PHPSTORM`, { ...params });

// Service to fetch tour details and name of customer
const FetchTourDetail = (params) => client.post(`/core/general/fetch-tour-detail`, { ...params });

// Service to update tour and tour_skip 
const updateTour = (params) => client.post(`/core/general/tour-update`, { ...params });

export default {
  getNotifications,
  getNotificationsList,
  readNotification,
  getNotificationsAll,
  readNotificationAll,
  FetchTourDetail,
  updateTour
};
