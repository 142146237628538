import React from "react";
import Loader from "react-loader-spinner";
import "../../../styles/spinner.css";
const LoadingLabel = () => (
  <React.Fragment>
    <div className="center">
      <span style={{ color: "white" }}>Loading...</span>
    </div>
  </React.Fragment>
);

export default LoadingLabel;
