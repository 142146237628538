import React, { useState, useContext, useEffect, useRef } from "react";

import ReactTable from "react-table";
import * as moment from "moment";
import { toast } from "react-toastify";
import { Link, browserHistory } from "react-router";
import "react-table/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import LoadingSpinner from "../common/loaders/loadingSpinner";
import {
  CountriesList,
  BPList,
  ClientsList,
} from "../../components/slplusTools";

import InvCreation from "./invoice/invoiceCreation";
import DebtorMapping from "./invoice/_debtorMapping";
import InvoiceErrors from "./invoice/_invoiceErrors";
import AddInvoice from "./invoice/_addInvoice";
import InvoiceAssignment from "./invoiceAssignment";

import {
  GeneralButton,
  GeneralIconButton,
  EditButton,
  CopyButton,
  DeleteButton,
  CurrencyLabel,
} from "../common/inputs";
import ExcelFileUploader from "../common/inputs/fileUploader/ExcelUploader";
import { FormRow, FormColumnLg } from "../common/containers/formContainer";

import useApi from "../../hooks/useApi";

import TransactionContext from "../../context/invTransactionContext";
import EntityContext from "../../context/entityContext";
import transactionService from "../../services/invTransactions";
import authService from "../../services/authService";
import coreService from "../../services/coreService";
import MultiStepForm from "../common/wizard/MultiStepForm";

function SingleUpload({ location, handleTransactionList, client, onClose = null }) {

  const wizardRef = useRef(); // Reference to Multistep Wizard Component

  const ReactTableFixedColumns = withFixedColumns(ReactTable);
  const [invModal, setInvModal] = useState(false);
  const [debtorModal, setDebtorModal] = useState(false);
  const [selectedDebtor, setSelectedDebtor] = useState(false);
  const [validationModal, setValidationModal] = useState(false);
  const [invoiceCreation, setCreation] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [errors, setErrors] = useState([]);
  const [syncContent, setSyncContent] = useState(true);

  const [customer, setCustomer] = useState(null);
  const [country, setCountry] = useState(null);
  const [owner, setOwner] = useState(null);
  const [summaryModal, setSummaryModal] = useState(false);

  const [proceedNext, setProceedNext] = useState(false);
  const [hideNextBtn, sethideNextBtn] = useState(false);
  const [hidePreviousBtn, sethidePreviousBtn] = useState(false);
  const [navigate, setNavigation] = useState(null);
  const [selectedPage, setSelPage] = useState(1);
  const [fileInfo, setFileInfo] = useState(null);
  const [uploadedCount, setUploadedCount] = useState(0);

  const { category, constants } = authService.getCurrentUser();
  //------------Api Services--------------------------------------------
  const transactionApi = useApi(transactionService.createSingleInvoice);
  const fieldsHistoryApi = useApi(coreService.getFieldsMappingHistory);

  const entityContext = useContext(EntityContext);
  const {
    transaction,
    invoice,
    setInvoice,
    deleteInvoice,
    handleUploadItems,
    uploadedFiles,
    fetchDebtors,
    debtors,
    removeUploadedSheet,
    uploadedFileData,
    invoiceFileAttachments
  } = useContext(TransactionContext); // Accessing Context API

  //-------------------- Delete selected invoice from the list ---------------------------//
  const handleInvoiceDeletion = (index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
          return (
              <div className="custom-delete-ui">
                  <h4>Delete Invoice</h4>
                  <p>Are you sure you wish to delete this invoice.</p>
                  <button
                      className="btn btn-white ml-2"
                      onClick={() => {
                          onClose();
                      }}
                  >
                      No
                  </button>
                  <button
                      className="btn btn-primary ml-2"
                      onClick={() => { onClose(); deleteInvoice(index) }}
                  >
                      Yes
                  </button>
              </div>
          );
      },
      title: "Confirm to Deactivate",
      message: "Are you sure want to continue?",
    });
  };
  //------------------------------------------------------------------------

  const handleInvoiceEdit = (selectedRow) => {
    const invObj = { ...selectedRow };

    invObj.trans_client_guid = customer;
    invObj.trans_owner = owner;
    setSelectedRow(invObj);
    setInvModal(true);
  };
  //------------------------------------------------------------------------

  const handleInvoiceCopy = (selectedRow) => {
    const invList = [...invoice];
    const invObj = { ...selectedRow };

    invObj.index = invList.length;
    invObj.inv_number = null;
    invObj.trans_client_guid = customer;
    invObj.trans_owner = owner;
    invList.push(invObj);
    setInvoice(invList);
  };

  const handleDebtorEdit = (selectedRow) => {
    const invObj = { ...selectedRow };

    invObj.trans_client_guid = customer;
    invObj.trans_owner = owner;
    setSelectedRow(invObj);
  };

  // Promise based function to read the file
  const readFile = async (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      let fileItem = file.obj;
      reader.readAsDataURL(fileItem);
      reader.onload = (e) => {
        resolve(e.target.result);
      };
    });
  };

  const handleDataSumbission = async () => {

    let invLists = await Promise.all(invoice.map(async(obj, index) => {

      const assignedFile = invoiceFileAttachments.find((item) => item.row == index);
      if(assignedFile){
        let fileObj = await readFile(assignedFile);
        obj.files = [
          {
            name: assignedFile["name"],
            file: fileObj,
            type: assignedFile["obj"]["type"],
            size: assignedFile["obj"]["size"]
          }
        ]
      }
      
      obj.trans_owner = entityContext ? entityContext.entityDetails.owner.a_guid : owner ? owner : obj.trans_owner;
      obj.trans_client_guid = entityContext ? entityContext.entityDetails.a_guid : customer ? customer : obj.trans_client_guid;
      return obj;
    }));
    

    const reqData = {
      trans_guid: null,
      invoices: [...invLists],
      uploadedFiles:uploadedFileData
    };

    const { data } = await transactionApi.request(reqData);

    if (data.success === false) {
      setErrors(data.errors);
      setValidationModal(true);
      setNavigation("Previous");

      if (data.invoices.length > 0)
        setInvoice(data.invoices);
    }
    else {
      setInvoice(data.invoices);
      setUploadedCount(data.invoices.length - data.errors.length);
      //setNavigation("Next");
      wizardRef.current.next(false);
      toast.success(data.message);
    }
  }

  // Handling Async operation to save the data to the server
  const submitInvoice = async () => {

    if (invoice.filter((obj) => obj.debtor_name === false).length > 0) {
      toast.error("Please assign the debtors to the invoices before proceeding.");
      setDebtorModal(true);
      return;
    }

    // ------------ Check for duplicate invoice numbers on the sheet ------------ //
    let invoiceList = [];
    let duplicateInvoices = [];
    
    invoice.map(row => {     
      if (row.inv_number) {
          let invNumberConverted = String(row.inv_number);
          if (invoiceList.findIndex(obj => obj.inv_number == invNumberConverted.toLowerCase().trim()) === -1)
            invoiceList.push({ ...row, inv_number: invNumberConverted.toLowerCase().trim() });
          else
            duplicateInvoices.push({ inv_number: invNumberConverted.trim(), message: 'This invoice number is already on the sheet.' });
      } else
        invoiceList.push(row);
    });

    if(duplicateInvoices.length > 0){
      setErrors(duplicateInvoices);
      setValidationModal(true);
      return;
    }
    // --------------------------------------------------------------------------//

    wizardRef.current.next(false);
    //setNavigation("Next");
  };

  const getFieldsMappingHistory = async () => {

    if (syncContent === true) {
      const { data: mappingHistory } = await fieldsHistoryApi.request({
        business_partner: owner,
        account: customer,
        product: constants.PRODUCTS.INVOICE,
        type: constants.FIELD_MAPPING.IF_INV_UPLOADER_MAP_SUPPLIER
      });

      if (mappingHistory.data.length > 0) {
        let newList = [...invoice];

        invoice.map((item, index) => {
          let res = mappingHistory.data.find(obj => obj.actual_field == item.debtor_name_in_sheet);
          if (res && newList[index]['inv_debtor_id'] === null) {
            let debtorInfo = debtors.find(item => item.value === res.mapped_field)
            newList[index]['inv_debtor_id'] = res.mapped_field;
            newList[index]['debtor_name'] = debtorInfo.label;
          }
        });

        setInvoice(newList);
      }
    }

    setSyncContent(false);
  }

  // First Page
  const UploaderPage = () => {
    setSelPage(1);
    return (
      <>
        <div style={{ color: "#3A2835" }} className="font-bold m-t-xl">
          {!location.query.a_guid && (
            <div className="card mb-4 overflow-visible  ">
              <div className="card-body">
                <div className="row justify-content-center">
                  <FormColumnLg
                    col={3}
                    visible={category === constants.CATEGORY.INTL_OFFICE}
                  >
                    <CountriesList
                      selectedCountry={country}
                      onSelectItem={({ id }) => setCountry(id)}
                    />
                  </FormColumnLg>

                  <FormColumnLg
                    col={3}
                    visible={
                      category === constants.CATEGORY.COUNTRY_OFFICE ||
                      category === constants.CATEGORY.INTL_OFFICE
                    }
                  >
                    <BPList
                      selectedBp={owner}
                      type="horizontal"
                      country={country}
                      onSelectItem={({ value }) => {
                        setOwner(value);
                      }}
                    />
                  </FormColumnLg>

                  <FormColumnLg col={3}>
                    <ClientsList
                      selectedClient={customer}
                      bp={owner}
                      onSelectItem={(option) => {
                        setCustomer(option.value);
                        fetchDebtors(option.value);
                      }}
                    />
                  </FormColumnLg>
                </div>
              </div>
            </div>
          )}

          <div className="mt-4">
            <ExcelFileUploader handleUploadItems={handleUploadItems} />
          </div>

          <FormRow style={{ margin: 15 }}>
            <FormColumnLg col="12 text-center">
              <a href={`/templates/${location.pathname === '/client/if/bulk-upload' ? 'bulk_invoice_template_client' : 'bulk_invoice_template'}.xls`}>
                <h6>Download Sample Template</h6>
              </a>
            </FormColumnLg>
          </FormRow>
        </div>

        <FormRow style={{ margin: 15 }}>
          <FormColumnLg col="6">
            {fileInfo && (
              <>
                <p className="font-bold"> Uploaded files list.</p>

                <div className="d-flex align-items-left mt-2">
                  {fileInfo && (
                    <>
                      <a className="text-secondary" href="#">
                        <DeleteButton
                          className="fa fa-trash"
                          onClick={() => {
                            confirmAlert({
                              customUI: ({ onClose }) => {
                                return (
                                  <div className="custom-delete-ui">
                                    <h4>Remove Uploaded Sheet ?</h4>
                                    <p>Are you sure want to continue?</p>
                                    <button
                                      className="btn btn-white ml-2"
                                      onClick={() => { onClose(); }}
                                    >
                                      No
                                    </button>
                                    <button
                                      className="btn btn-primary ml-2"
                                      onClick={() => {
                                        removeUploadedSheet(fileInfo.name)
                                        onClose();
                                      }}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                );
                              },
                            });
                          }}
                        ></DeleteButton></a>&nbsp;&nbsp;
                      <p>
                        <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                        {fileInfo.name}
                      </p>
                    </>
                  )}
                </div>
              </>
            )}
          </FormColumnLg>

          <FormColumnLg col="6">
            {fileInfo ? customer !== null ? (
              <p className="font-bold text-right">  Click next button to proceed. </p>
            ) : <p className="font-bold text-right text-danger">  Please select the Client to proceed. </p> : ""}

          </FormColumnLg>
        </FormRow>
      </>
    )
  }

  // Page 2
  const ListingPage = () => {
    setSelPage(2);
    return (
      <>
        {!location.query.a_guid && (
          <div className="card mb-4">
            <div className="card-body">
              <div className="row col-lg-12">
                <FormColumnLg
                  col={3}
                  visible={category === constants.CATEGORY.INTL_OFFICE}
                >
                  <CountriesList
                    selectedCountry={country}
                    onSelectItem={({ id }) => setCountry(id)}
                  />
                </FormColumnLg>

                <FormColumnLg
                  col={3}
                  visible={
                    category === constants.CATEGORY.COUNTRY_OFFICE ||
                    category === constants.CATEGORY.INTL_OFFICE
                  }
                >
                  <BPList
                    selectedBp={owner}
                    type="horizontal"
                    country={country}
                    onSelectItem={({ value }) => {
                      setOwner(value);
                    }}
                  />
                </FormColumnLg>

                <FormColumnLg col={3}>
                  <ClientsList
                    selectedClient={customer}
                    bp={owner}
                    onSelectItem={(option) => {
                      setCustomer(option.value);
                    }}
                  />
                </FormColumnLg>
              </div>
            </div>
          </div>
        )}

        <table class="table table-sm table-stripped client-tble text-secondary">
          <tbody>
            <tr>
              <th>Invoice No</th>
              <th>Purchase Order</th>
              <th>Invoice Date</th>
              <th>Debtor</th>
              <th>Invoice Value</th>
              <th>Due Date</th>
              <th>Insured</th>
              <th>Agreement Amount</th>
              <th>Advance</th>
              <th>Advance $</th>
              <th>Insurance Fee %</th>
              <th>Action</th>
            </tr>
            {invoice.map((item, index) => (
              <tr
                className={item.error && item.error === true && "text-danger"}
                title={item.error && item.error === true && item.errorMsg}
              >
                <td>
                  {item.inv_number === null ? (
                    <Link
                      onClick={(e) => handleInvoiceEdit(item)}
                      className="text-danger"
                    >
                      Add Invoice Number
                    </Link>
                  ) : (
                    item.inv_number
                  )}
                </td>

                <td>{item.inv_purchase_order}</td>

                <td>
                  {moment(item.inv_date, "YYYY/MM/DD").format("DD/MM/YYYY")}
                </td>

                <td>
                  {!item.debtor_name ? (
                    <Link
                      onClick={(e) => {
                        setDebtorModal(true);
                        !location.query.a_guid && handleDebtorEdit(item);
                      }}
                      className="text-danger"
                      style={{ cursor: 'pointer' }}
                      title='Click here to allocate the debtors'
                    >
                      Debtor Mismatch
                    </Link>
                  ) : (
                    <Link
                      onClick={() => {
                        handleInvoiceEdit(item)
                        /*
                        setDebtorModal(true);
                        setSelectedDebtor(item);
                        !location.query.a_guid && handleDebtorEdit(item);
                        */
                      }}
                      style={{ cursor: 'pointer' }}
                      className={'cursor-link'}
                      title='Click here to change the debtor of this invoice'
                    >
                      {item.debtor_name}
                    </Link>
                  )}
                </td>

                <td>
                  <CurrencyLabel value={item.inv_amount}></CurrencyLabel>
                </td>

                <td>
                  {moment(item.inv_due_date, "YYYY/MM/DD").format(
                    "DD/MM/YYYY"
                  )}
                </td>

                <td>{item.insured}</td>

                <td>
                  <CurrencyLabel
                    value={item.inv_chargeable_amt}
                  ></CurrencyLabel>
                </td>

                <td>{item.inv_adv_percent}%</td>

                <td>
                  <CurrencyLabel value={item.inv_adv_amount}></CurrencyLabel>
                </td>

                <td>{item.inv_insurance_percent ? `${item.inv_insurance_percent}%` : ''}</td>

                <td>
                  <>
                    <Link className="action-icons" style={{ cursor: 'pointer' }}>
                      <EditButton
                        onClick={(e) => handleInvoiceEdit(item)}
                      ></EditButton>
                    </Link>
                    &nbsp;
                    <Link className="action-icons" style={{ cursor: 'pointer' }}>
                      <CopyButton
                        onClick={(e) => handleInvoiceCopy(item)}
                      ></CopyButton>
                    </Link>
                    &nbsp;
                    <Link className="action-icons" style={{ cursor: 'pointer' }}>
                      <DeleteButton
                        onClick={(e) => handleInvoiceDeletion(item.index)}
                      ></DeleteButton>
                    </Link>
                  </>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="row mt-2">
          <div className="col-lg-12 text-right">
            <GeneralButton
              onClick={submitInvoice}
              className="btn btn-primary btn-sm "
              faIcon="fa fa-plus"
              name="Save"
              id="btn-submit"
              style={{ visibility: "hidden" }}
            ></GeneralButton>
          </div>
        </div>
      </>
    )
  }

  // Page 3
  const FinishPage = () => {
    return (
      <>
        <center>
          <i className="fa fa-check-circle fa-2x text-success"></i> <br />
          <br></br>
          <h5><strong>{invoice.filter(obj => obj.error === false).length}</strong>/{invoice.length} invoices have been successfully uploaded!</h5>
          <br></br>
          <div className="mt-4">
            <GeneralButton className="btn btn-secondary mr-2"
                onClick={() => {
                    setSummaryModal(true)
                }}
                name={"View Uploaded Summary"} >
            </GeneralButton>
            <GeneralIconButton onClick={e => handleTransactionList(true)} className="btn btn-outline-secondary btn-xs mr-2" name="Go to Invoices List"></GeneralIconButton>
          </div>
        </center>

        <div className="row mt-4">
          <div className="col-lg-12">

          </div>
        </div>
      </>
    )
  }

  const spreadSheetFileTypes = ["xls", "xlsx", "ods"]; // Allowed spreadsheet files to upload.

  // Array object to include the list of components you want to pass into the multi-step form module.
  const componentsList = [
    {
      headerText: "Upload Invoices",
      headerIcon: "fa fa-upload",
      component: <UploaderPage />,
    },
    {
      headerText: "View Uploads",
      headerIcon: "fa fa-list",
      component: <ListingPage />,
    },
    {
      headerText: "Invoice Assignment",
      headerIcon: "fa fa-list",
      component: <InvoiceAssignment handleDataSumbission={handleDataSumbission} />,
    },
    {
      headerText: "Finish",
      headerIcon: "fa fa-flag-checkered",
      component: <FinishPage />,
    }
  ];

  useEffect(() => {
    const { constants, category, entity_id } = authService.getCurrentUser();
    if (constants.CATEGORY.BP === category) setOwner(entity_id);
  }, []);
  //---------------------------------------------//

  useEffect(() => {

    if (entityContext)
      setCustomer(entityContext.entityDetails.a_guid)

    // Enable the next button only if it contains an Excel sheet.
    let isValidFile = uploadedFiles.find((file) =>
      spreadSheetFileTypes.includes(file.name.split(".").pop())
    );

    if (selectedPage === 1) {
      setProceedNext((invoice.length > 0 && isValidFile && customer) ? true : false);

      sethideNextBtn(false);
      sethidePreviousBtn(true);
    }
    if (selectedPage === 2) {
      //sethideNextBtn(true);
      sethidePreviousBtn(false);
      getFieldsMappingHistory();
    }

    if (isValidFile)
      setFileInfo(isValidFile)
    else 
      setFileInfo(null)

    if(invoice.length === 0 && selectedPage === 2)
      onClose ? onClose() : browserHistory.push("/invoices")

  }, [invoice, selectedPage, uploadedFiles, customer]);
  //---------------------------------------------//

  return (
    <>
      {(fieldsHistoryApi.loading === true || transactionApi.loading == true) && <LoadingSpinner />}

      {invModal && (
        <InvCreation
          selectedRow={{
            ...selectedRow, 
            inv_date: selectedRow.inv_date === 'Invalid date' ? null : selectedRow.inv_date,
            inv_due_date: selectedRow.inv_due_date === 'Invalid date' ? null : selectedRow.inv_due_date 
          }}
          onClose={(e) => {
            setInvModal(false);
          }}
          bulkUpload={true}
          showFileUpload={false}
          showAddNewBtn={false}
        ></InvCreation>
      )}
      {invoiceCreation && (
        <AddInvoice
          invoiceCreation={invoiceCreation}
          selectedRow={null}
          onClose={() => {
            setCreation(false);
          }}
          bulkUpload={true}
        />
      )}

      <Modal
        show={debtorModal}
        onHide={(e) => {
          setDebtorModal(false);
          setSelectedDebtor(null);
        }}
        size="xl"
      >
        <DebtorMapping
          selectedRow={selectedRow}
          debtorInfo={selectedDebtor}
          onClose={(e) => {
            setDebtorModal(false);
          }}
        />
      </Modal>

      <Modal
        show={validationModal}
        onHide={(e) => {
          setValidationModal(false);
        }}
      >
        <InvoiceErrors errors={errors} />
      </Modal>

        {/*------------------Bulk upload summary-------------------*/}
        <Modal
          show={summaryModal}
          onHide={() => setSummaryModal(false)}
          size="xl"
        >
          <Modal.Header closeButton={true}>
              <h4 className="card-title">UPLOAD SUMMARY</h4>
          </Modal.Header>

          <Modal.Body>
              <FormRow className="table-responsive">
                  <table class="table text-secondary">
                      <tbody>
                          <tr>
                              <th>Invoice Number</th>
                              <th>Status</th>
                              <th>Comments</th>
                          </tr>
                          {invoice.map((item, index) => (
                              <tr key={index}>
                                  <td>{item.inv_number}</td>
                                  <td>
                                      {item.error === false ? <span class="text-success">Upload Success</span> : <span class="text-danger">Upload Failed</span>}
                                  </td>
                                  <td>{item.errorMsg}</td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </FormRow>
          </Modal.Body>

          <Modal.Footer>
              <FormRow>
                  <GeneralButton
                      onClick={() => setSummaryModal(false)}
                      className="btn btn-light pull-right m-l-xs"
                      name="Close"
                  ></GeneralButton>
              </FormRow>
          </Modal.Footer>
      </Modal>
      {/*---------------------------------------------------------*/}

      <MultiStepForm
        boxTitle="Invoice Upload"
        list={componentsList}
        displayProgressBar={true}
        proceedNext={proceedNext}
        hideNextBtn={hideNextBtn}
        hidePreviousBtn={hidePreviousBtn}
        navigate={navigate}
        handleCancel={(e) => onClose ? onClose() : browserHistory.push("/invoices")}
        showCard={false}
        ref={wizardRef}
      />

    </>
  );
}

export default SingleUpload;