import React, { Component } from "react";
import { Link } from "react-router";

import productShortName from "../../../../utils/GetProductShortName";
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";

class ClientMenu extends Component {

    static contextType = EntityContext;

    constructor() {
        super();
    }

    render() {

        const { handleRoleChange, selectedBusinessType, terminology, salesRoles, purchaseRoles, primaryFacilities, loader } = this.context;
        const { activePath } = this.props;
        const { constants } = authService.getCurrentUser();
        {/*----------------Main Menu start---------------------------*/ }

        return (
            <>
                <div className="navbar-collapse mr-auto order-xl-2 collapse" id="navbar">
                    {!loader && (
                        <ul className="navbar-nav">
                            <li>
                                <Link className={activePath === '/client/home' ? 'nav-link active' : 'nav-link'} to="/client/home">Dashboard </Link>
                            </li>

                            {/*------------------Sales roles menu-----------------------------------------------------*/}
                            {(salesRoles && salesRoles.length > 0) ? (
                                <li className={` nav-item ${(salesRoles.length > 1) && "dropdown"}`}>
                                    <Link
                                        className={`nav-link ${(salesRoles.length > 1) && "dropdown-toggle"} ${selectedBusinessType == constants.BUSINESS_TYPE.SALE && "active"}`}
                                        data-toggle={`${(salesRoles.length > 1) && "dropdown"}`}
                                        onClick={() => { salesRoles.length == 1 && handleRoleChange(salesRoles[0].value) }}
                                        to={(salesRoles.length > 1) ? "#" : `/client/${productShortName(salesRoles[0].product)}/dashboard`}
                                    >
                                        {terminology && terminology.SALES_INVOICES}{/* Sales Invoices */}
                                    </Link>
                                    {(salesRoles.length > 1) && (
                                        <>
                                            <ul role="menu" className="dropdown-menu shadow-sm border dropdown-menu-right">
                                                {salesRoles.filter(itm => itm.product != constants.PRODUCTS.LOAN).map((menu, index) =>
                                                    <React.Fragment key={index}>
                                                        <li>
                                                            <Link
                                                                onClick={() => { handleRoleChange(menu.value) }}
                                                                className="dropdown-item"
                                                                to={`/client/${productShortName(menu.product)}/dashboard`}
                                                            >
                                                                {menu.name}
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider" />
                                                    </React.Fragment>
                                                )}
                                            </ul>
                                        </>
                                    )}

                                </li>
                            ) : (
                                <li>
                                    <Link onClick={() => { handleRoleChange(constants.BUSINESS_TYPE.SALE); }} className={activePath === '/client/sale-invoices' ? 'nav-link active' : `nav-link ${selectedBusinessType == constants.BUSINESS_TYPE.SALE ? 'active' : ''}`} to={"/client/start-facility-setup"}>{terminology && terminology.SALES_INVOICES}</Link>
                                </li>
                            )}
                            {/*------------------------Purchase roles menu-----------------------------------------------------*/}
                            {(purchaseRoles && purchaseRoles.length > 0) ? (
                                <li className={`nav-item ${(purchaseRoles.length > 1) && "dropdown "}`} >
                                    <Link
                                        className={`nav-link ${(purchaseRoles.length > 1) && "dropdown-toggle"} ${selectedBusinessType == constants.BUSINESS_TYPE.PURCHASE && "active"}`}
                                        data-toggle={`${(purchaseRoles.length > 1) && "dropdown"}`}
                                        onClick={() => { purchaseRoles.length == 1 && handleRoleChange(purchaseRoles[0].value) }}
                                        to={(purchaseRoles.length > 1) ? "#" : `/client/${productShortName(purchaseRoles[0].product)}/dashboard`}
                                    >
                                        {terminology && terminology.PURCHASE_INVOICES}
                                    </Link>
                                    {(purchaseRoles.length > 1) && (
                                        <>
                                            <ul role="menu" className="dropdown-menu shadow-sm border dropdown-menu-right">
                                                {purchaseRoles.map((purchaseMenu, index) =>
                                                    <React.Fragment key={index}>
                                                        <li>
                                                            <Link
                                                                onClick={() => { handleRoleChange(purchaseMenu.value) }}
                                                                className="dropdown-item"
                                                                to={`/client/${productShortName(purchaseMenu.product)}/dashboard`}
                                                            >
                                                                {purchaseMenu.name}
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider" />
                                                    </React.Fragment>
                                                )}
                                            </ul>
                                        </>
                                    )}
                                </li>
                            ) : (
                                <li>
                                    <Link onClick={() => { handleRoleChange(constants.BUSINESS_TYPE.PURCHASE); }} className={activePath === '/client/purchase-invoices' ? 'nav-link active ' : `nav-link ${selectedBusinessType == constants.BUSINESS_TYPE.PURCHASE ? 'active' : ''}`} to={"/client/start-facility-setup"}>
                                        {terminology && terminology.PURCHASE_INVOICES}
                                    </Link>
                                </li>
                            )}

                            {/* ------  Loan Menu starts ----- */}
                            {
                                salesRoles && salesRoles.filter(itm => itm.product == constants.PRODUCTS.LOAN).length > 0 && (
                                    <>
                                        <li>
                                            <Link
                                                className={activePath === '/client/loan/dashboard' ? 'nav-link active' : `nav-link `} to={"/client/loan/dashboard"}>{`Business Loan`}</Link>
                                        </li>
                                    </>
                                )
                            }

                            {/* ------  Loan Menu ends  ----- */}

                            {primaryFacilities !== false && (
                                <li className="nav-item dropdown">
                                    <Link
                                        className={((activePath === '/client/users-network/supplier') || (activePath === '/client/users-network/customer')) ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'}
                                        data-toggle="dropdown"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Network
                                    </Link>
                                    <ul role="menu" className="dropdown-menu shadow-sm border dropdown-menu-right">
                                        <li>
                                            <Link className="dropdown-item" to="/client/users-network/supplier">{terminology && terminology.SUPPLIER}</Link>
                                        </li>
                                        <li className="dropdown-divider" />
                                        <li>
                                            <Link className="dropdown-item" to="/client/users-network/customer">{terminology && terminology.CUSTOMER}</Link>
                                        </li>
                                    </ul>
                                </li>
                            )}

                            <li>
                                <Link className={activePath === '/client/finance-business' ? 'nav-link active' : 'nav-link'} to="/client/finance-business">Finance My Business</Link>
                            </li>

                            {/*  <li>
                                <Link className={activePath === '/client/bulk-payment' ? 'nav-link active' : 'nav-link'} to="/client/bulk-payment">Bulk Payment</Link>
                          </li>*/}
                        </ul>
                    )}
                </div>
            </>
        )
    }
}
export default ClientMenu;