import React, { useEffect, useRef, useContext } from "react";
import Joi from "joi-browser";
import { FormGroup, FormRow, FormColumnLg } from "../../../common/containers/formContainer";
import { ModalCloseButton } from "../../../common/containers/button";
import MultipleFileInput from "../../../common/inputs/multipleFileInput/multipleFileInput";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import { confirmAlert } from "react-confirm-alert";
import { browserHistory, Link } from "react-router";
import {
  TextInput,
  GeneralButton,
  CurrencyText,
  DateTimePicker,
} from "../../../common/inputs";
import { EntityDebtorList } from "../../../slplusTools";
import * as moment from "moment";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import InvIcon from "../../../../img/invoice.svg";

import EntityContext from "../../../../context/entityContext";
import useApi from "../../../../hooks/useApi";
import useForm from "../../../../hooks/useForm";
import transactionService from "../../../../services/invTransactions";
import entityService from "../../../../services/entityService";
import authService from "../../../../services/authService";
import { useState } from "react";

export default function InvoiceCreation({
  onClose,
  selectedRow = null,
  debtorId = null,
  handleAddInvoice = null,
  hideInvoiceCol = false,
}) {
  const { constants } = authService.getCurrentUser();
  const { entityDetails } = useContext(EntityContext);
  const [files, setFiles] = useState({ data: [], document_name: "" });
  const [facilitySettings, setFacilitySettings] = useState(null);
  const [showAgreement, setShowAgreement] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [viewAttachment, setViewAttachment] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [loading, setLoading] = useState(false);

  //Creating references for Input fields
  const invoiceNumRef = useRef();
  const purchseOrderRef = useRef();
  const invAmtRef = useRef();
  const invDateRef = useRef();
  const dueDateRef = useRef();


  //------------Api Services--------------------------------------------
  const referenceApi = useApi(transactionService.generateRefNum);
  const transactionApi = useApi(transactionService.createInvTransaction);
  const invCreationAPi = useApi(transactionService.createSingleInvoice);
  const entityDefaultsApi = useApi(entityService.getEntityDefaults);

  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({

      inv_debtor_id: Joi.string()
        .required()
        .error(() => {
          return { message: "Please select the Debtor from the list" };
        }),

      inv_number: Joi.string()
        .required()
        .error(() => {
          invoiceNumRef.current.focus();
          return { message: "Please enter a valid Invoice No" };
        }),

      inv_date: Joi.date()
        .required()
        .error(() => {
          document.getElementById('inv_date').focus();
          return {
            message: "Invoice date cannot be blank",
          };
        }),

      inv_due_date: Joi.date()
        .min(Joi.ref("inv_date"))
        .required()
        .error((errors) => {
          document.getElementById('inv_due_date').focus();
          return {
            message: errors[0]["type"] === "date.min" ? "Due Date cannot be lesser than Invoice Date" : "Due Date cannot be blank"
          };
        }),

      inv_amount: Joi.number()
        .required()
        .error(() => {
          invAmtRef.current.focus();
          return { message: "Please enter a valid Invoice Value" };
        }),

    })
    .unknown(true);

  if (!hideInvoiceCol) {
    schema.inv_chargeable_amt = Joi.number()
      .max(Joi.ref("inv_amount"))
      .required()
      .label("Agreement Amount");
  }

  //-----------------------Fetch facility settings for the Client-----------------------//
  const getFacilitySettings = async () => {
    const response = await entityDefaultsApi.request({
      entityId: entityDetails.a_guid,
    });

    if (response)
      setFacilitySettings((response.rows !== undefined && response.rows !== null) ?
        response.rows : null);
  };

  //----------------------------Create new entry on Trans table---------------------------//
  const handleFileChange = (fileItems) => {
    if (fileItems[0]) {
      setFiles({
        ...files,
        document_name: fileItems[0].file.name,
        data: fileItems.map((fileItem) => fileItem.file),
      });
    }
  };

  const handleFileReader = async () => {
    /** --------------- Reading local files in JavaScript -------------- */
    let file = files.data;
    let fileslist = [];
  
    // Use Promise.all to wait for all files to be read
    await Promise.all(
      file.map(
        (fileItem) =>
          new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(fileItem);
            reader.onload = (e) => {
              fileslist.push({
                name: fileItem.name,
                file: e.target.result,
                type: fileItem.type,
                size: fileItem.size,
              });
              resolve();
            };
            reader.onerror = (e) => {
              reject(e);
            };
          })
      )
    );
  
    return fileslist;
    /** ----------------------------------------------------------------- */
  };

  //--------------------- Submit invoice to server ------------------//
  const addHandler = async () => {
    if (handleAddInvoice == null) {
      try {
        let fileslist = await handleFileReader();
  
        let invData = { ...values };
        invData = {
          ...invData,
          inv_insured: invData.inv_insured.toLowerCase(),
          files: fileslist,
        };
  
        const reqData = {
          trans_guid: null,
          invoices: [invData],
          files: fileslist,
        };
  
        const { data } = await invCreationAPi.request(reqData);
        if (data.success === false) {
          data.errors &&
            data.errors.map((item) => {
              toast.error(item.message);
            });
        } else {
          onClose();

          confirmAlert({
            customUI: ({ onClose: closeConfirm }) => {
              return (
                <div className="react-confirm-alert">
                  <div className="custom-delete-ui">
                    <h4 className="mb-3">
                      Invoice has been added for processing
                    </h4>

                    <button
                      className="btn btn-white mt-3"
                      onClick={() => {
                        closeConfirm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn-primary ml-2 mt-3"
                      onClick={() => {
                        browserHistory.push({
                          pathname: "/client/if/sale-invoices",
                          search: "?status=draft",
                        });
                        closeConfirm();
                      }}
                    >
                      View Invoice List
                    </button>
                  </div>
                </div>
              );
            },
          });
        }

      } catch (error) {
        console.error("Error processing invoice:", error);
      }
    } else {
      handleAddInvoice(values, selectedRow);
      onClose();
    }
  };
  //----------------------------------------------------------------//

  // Handle input change in Adv Percentage field
  const handleAdvPctgChange = (event) => {
    const { value } = event.target;
    let pctg = isNaN(value) ? 0 : value;
    let amount = values.inv_chargeable_amt !== null ? values.inv_chargeable_amt : 0;
    let inv_adv_amount = (pctg / 100) * amount;

    if (pctg <= 100)
      setValues({
        ...values,
        inv_adv_percent: pctg,
        inv_adv_amount: inv_adv_amount,
      });
  };

  const fetchAttachment = async (attachment_guid, operation, filename = "") => {
    try {
      setLoading(true);
      const { data } = await transactionService.viewInvoiceAttachment({ attachment_guid });
      setLoading(false);

      if (operation == "download") {
        const link = document.createElement("a");
        link.href = data;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      } else {
        setViewAttachment(true);
        setAttachments(data);
      }
    } catch (error) { }
  };

  const onCloseModal = () => {
    setViewAttachment(false);
    setAttachments(null);
  };

  //------------------Custom hook to handle form data-----------------------------
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    validate
  } = useForm(addHandler, schema); // Custom Hook to handle Form Validation

  //------------------------------------------------------------------------------
  useEffect(() => {
    if (selectedRow === null)
      // Create New Invoice
      setValues({
        ...values,
        inv_debtor_id: debtorId,
        inv_date: null,
        inv_due_date: null,
        adv_date: null,
        inv_insured: "No",
        trans_owner: entityDetails && entityDetails.owner.a_guid,
        trans_client_guid: entityDetails && entityDetails.a_guid,
        attachements: []
      });
    //selectedRow
    else setValues({ ...values, ...selectedRow }); // Update selected invoice

    getFacilitySettings();
  }, []);


  return (
    <>
      {(referenceApi.loading === true || transactionApi.loading === true || invCreationAPi.loading === true || loading === true) && <LoadingSpinner />}

      <Modal.Header closeButton>
        <div className="d-block pt-3 text-center w-100 ml-3">
          <img width={"50px"} src={InvIcon} />
          <h3 className="modal-title">Add New Invoice</h3>
          <small className="font-bold tertiary-level-color">
            Please enter the invoice details below.
          </small>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          {/*-------------Offer to sell starts-------------------------------------*/}
          <div className="col-lg-12">
            <div class="px-4" style={{ minHeight: 520, height: "auto" }}>
              <div className="ibox-content">
                {!showAgreement ? (
                  referenceApi.loading === false ? (
                    <>
                      <FormGroup
                        label="Debtor"
                        type="horizontal"
                        input={
                          <EntityDebtorList
                            selectedDebtor={values.inv_debtor_id}
                            client={entityDetails.a_guid}
                            onSelectItem={(option) =>
                              setValues({
                                ...values,
                                debtor_name: option.label,
                                inv_debtor_id: option.value,
                              })
                            }
                            product={constants.PRODUCTS.INVOICE}
                            addNew={selectedRow !== null ? false : true}
                          />
                        }
                      />
                      <hr />

                      {values.inv_debtor_id ? (
                        <>
                          <h5 className="m-b-4 card-title">Offer to Sell Details</h5>

                          {/*--------------Row 1--------------*/}
                          <div className="row"  >
                            <div className="col-lg-6">
                              <div className="form-group required">
                                <label className="control-label">Invoice No</label>
                                <div>
                                  <TextInput
                                    name="inv_number"
                                    label="Invoice No"
                                    value={values.inv_number}
                                    onChange={handleChange}
                                    type=""
                                    reference={invoiceNumRef}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Purchase Order</label>
                                <div>
                                  <TextInput
                                    name="inv_purchase_order"
                                    label="Purchase Order"
                                    value={values.inv_purchase_order}
                                    onChange={handleChange}
                                    type=""
                                    reference={purchseOrderRef}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*--------------Row 2--------------*/}
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group required">
                                <label className="control-label">Invoice Date</label>
                                <div>
                                  <DateTimePicker
                                    name="inv_date"
                                    label="Invoice Date"
                                    selectedDate={values.inv_date}
                                    onChange={(value) => handleChange(value)}
                                    reference={invDateRef}
                                    enableWeekDays={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group required">
                                <label className="control-label">Due Date</label>
                                <div>
                                  <DateTimePicker
                                    name="inv_due_date"
                                    selectedDate={values.inv_due_date}
                                    onChange={(value) => handleChange(value)}
                                    reference={dueDateRef}
                                    enableWeekDays={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*--------------Row 3--------------*/}
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group required">
                                <label className="control-label">Invoice Value</label>
                                <div>
                                  <CurrencyText
                                    value={values.inv_amount}
                                    onChangeEvent={(event, maskedvalue, floatvalue) => {
                                      setValues({
                                        ...values,
                                        inv_amount: floatvalue,
                                        inv_chargeable_amt: floatvalue,
                                      });
                                    }}
                                    placeholder=" "
                                    reference={invAmtRef}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <FormRow className="row m-t-md">
                            <FormColumnLg col="4">
                              <label>Invoice Files</label>
                            </FormColumnLg>
                          </FormRow>
                          <FormRow className="row">
                            <FormColumnLg col="12">
                              <MultipleFileInput
                                files={files.data}
                                allowMultiple={true}
                                onupdatefiles={(fileItems) => {
                                  handleFileChange(fileItems);
                                }}
                              ></MultipleFileInput>
                            </FormColumnLg>

                            {values.attachements.map((attachment, index) => (
                              <FormColumnLg key={index} col="12">
                                <div className=" alert alert-info">
                                  <div className="row">
                                    <div className="col-sm-8">{attachment.inv_file_name}</div>
                                    <div className="col-sm-4">
                                      <Link
                                        title="View attachment"
                                        className="pull-right cursor-link"
                                        onClick={() => { fetchAttachment(attachment.attachment_guid, "view") }}
                                      ><i className="fa fa-file-o"></i>
                                      </Link>
                                      <Link
                                        title="Download attachment"
                                        className="pull-right cursor-link mr-2"
                                        onClick={() => { fetchAttachment(attachment.attachment_guid, "download", attachment.inv_file_name) }}
                                      ><i className="fa fa-download"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </FormColumnLg>
                            ))}

                          </FormRow>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <div className="row" style={{ minHeight: 350 }}></div>
                  )
                ) : (
                  <>
                    <div style={{ maxHeight: 420, overflowY: "scroll" }}>
                      <h6>AGREEMENTS AND ACKNOWLEDGEMENTS</h6>
                      <p>
                        In this document "Facility Deed" means the Invoice Finance
                        Facility Deed between the client named above (the "Client"), FIFO
                        and, if applicable, certain Guarantors. Words which are defined in
                        the Facility Deed have the same meaning in this document.
                      </p>
                      <p>
                        The Client offers to sell and transfer absolutely to FIFO all of
                        the Client's rights, title and interests in each Account
                        Receivable to which the above invoices relate. The offer is
                        irrevocable. FIFO is under no obligation to accept the offer. If
                        FIFO in discretion accepts the offer it will do so as stipulated
                        in the Facility Deed.
                      </p>
                      <p>
                        The Client acknowledges that the Facility Deed applies to this
                        offer and it is incorporated into this document. In particular the
                        Client makes the representations and warranties in the Facility
                        Deed in relation to the Offered Receivables. The provisions of the
                        Facility Deed are, however, amended for the purposes of this offer
                        by the special conditions (if any) specified above.
                      </p>

                      <h6>EXECUTION AND DECLARATION</h6>
                      <p>
                        The Client offers to sell the Offered Receivables to FIFO. If the
                        undersigned is not the Client (for example they sign as a director
                        or company secretary) they also personally confirm, by signing
                        below, that the Offered Receivables are being offered for sale in
                        good faith; so far as the signatory is aware the respective
                        Customers will make full payment on the specified invoice payment
                        date; and the Offered Receivables have not been disputed and they
                        will not be disputed.
                      </p>
                    </div>

                    <div class="custom-control d-flex custom-checkbox mt-4">
                      <input
                        id={`customCheck1`}
                        type="checkbox"
                        className="custom-control-input"
                        name="checkconfirm"
                        onChange={() => setTermsAgreed(!termsAgreed)}
                        checked={termsAgreed}
                      // disabled={itemRole.isDisabled ? itemRole.isDisabled : false}
                      />
                      <label class="custom-control-label" for={`customCheck1`}>
                        {/* I, {data.first_name} {data.last_name} confirms that: */}I
                        AGREE.
                      </label>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>


        {facilitySettings && facilitySettings.debtor_disclosed === 'yes' && values.inv_debtor_id && (
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="alert alert-info text-secondary">
                Invoice payment is subject to invoice verification, and will be paid when verified or the date you have requested, whichever is later.
              </div>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        {!showAgreement ?
          <GeneralButton
            faIcon="fa fa-save"
            className="btn btn-sm btn-primary pull-right m-l-xs"
            name="Next"
            onClick={() => {
              const errors = validate();
              if (!errors)
                setShowAgreement(true);
            }}
            type="button"
          ></GeneralButton>
          :
          <>
            <ModalCloseButton onClick={() => { setShowAgreement(false) }} label="Back" className="btn btn-light"></ModalCloseButton>
            <GeneralButton
              faIcon="fa fa-save"
              className="btn btn-sm btn-primary pull-right m-l-xs"
              name="Save"
              onClick={handleSubmit}
              disabled={!termsAgreed}
            ></GeneralButton>
          </>
        }
      </Modal.Footer>


      {/*------------------ Attachments model ------------------*/}
      <Modal
        show={viewAttachment}
        onHide={onCloseModal}
        backdrop="static"
        center
        size="lg"
      >
        <Modal.Header closeButton>
          <div class="d-block pt-3 text-center w-100 ml-4">
            <h4 className="modal-title">View Invoice File</h4>
          </div>
        </Modal.Header>

        {!attachments && (
          <LoadingSpinner
            style={{ position: "fixed", top: "10%", left: "50%" }}
          />
        )}

        <center>
          <iframe
            style={{ width: "90%", height: 600 }}
            src={attachments}
          ></iframe>
        </center>
        <div style={{ width: 2000 }}></div>
      </Modal>
    </>
  );
}
