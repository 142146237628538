import React, { useEffect, useState } from "react";
import { useUploader } from "../../../../context/secAccountsUploaderContext";
import authService from "../../../../services/authService";
import LoadingSpinner from '../../../common/loaders/loadingSpinner';
import { GeneralButton } from "../../../common/inputs";
import { browserHistory } from "react-router";
import Modal from "react-bootstrap/Modal";
import {
    FormRow,
} from "../../../common/containers/formContainer";

import entityService from "../../../../services/entityService";
import useApi from '../../../../hooks/useApi';

export default function Finish({ client, source, onClose, contextValues, selectedProduct }) {

    const { constants } = authService.getCurrentUser();
    const { accounts, readyToUpload, defaultProducts, roleTypes, country, owner, uploadedFileData } = useUploader(); // Instance for Context API
    const [summaryModal, setSummaryModal] = useState(false);

    //------------Custom hooks for handling API Requests--------------
    const { request, data, loading } = useApi(entityService.ClientSecondaryBulkupload);

    const generateReqValues = () => {

        let reqValues = {};
        let productsArr = [];
        let mappingInfo = null;

        // roleTypes.filter(item => item.isChecked == true).map((activeProduct) => {
        //     activeProduct["roles"]
        //         .filter((roleFilter) => roleFilter.isChecked === true)
        //         .map((activeRole) => {
        //             productsArr.push({
        //                 product: activeProduct.id,
        //                 role: activeRole.id,
        //                 role_name: activeRole.role_name,
        //                 isChecked: activeRole.isChecked,
        //             });
        //         });
        // });

        // ----------------- Debtor Mapping --------------------- //
        // if (roleTypes.length > 0) {

        //     let productID = selectedProduct
        //     let roleID = selectedProduct === constants.PRODUCTS.DEBTORFINANACE ? constants.ROLES_IDS.DFDEBTOR : constants.ROLES_IDS.DEBTOR
        //     let roleName = selectedProduct === constants.PRODUCTS.DEBTORFINANACE ? constants.ROLES.DFDEBTOR : constants.ROLES.DEBTOR
        //     let category = selectedProduct === constants.PRODUCTS.DEBTORFINANACE ? constants.CATEGORY.DFDEBTOR : constants.CATEGORY.DEBTOR

        //     mappingInfo = {
        //         product: productID,
        //         role: roleID,
        //         role_name: roleName,
        //         category: category,

        //         entity: contextValues.a_guid,
        //         bp: contextValues.owner.a_guid,
        //         from_contact: contextValues.owner.owner_primary_guid,
        //     };
        // }
        // ------------------------------------------------------ //

        mappingInfo = {
            entity: contextValues.a_guid,
            bp: contextValues.owner.a_guid,
            from_contact: contextValues.owner.owner_primary_guid,
        };

        //reqValues.products = roleTypes.length > 0 ? productsArr : defaultProducts;
        //reqValues.role_type =   roleTypes;
        reqValues.country = country;
        reqValues.owner = owner;
        reqValues.readonly = false;
        reqValues.role = constants.BUSINESS_TYPE.PURCHASE;
        reqValues.source = source;
        reqValues.entity = client;
        reqValues.acctype = 'local';
        reqValues.mappingInfo = mappingInfo;

        request({ ...reqValues, accounts: readyToUpload, uploadedFiles: uploadedFileData });

    }

    useEffect(() => {
        generateReqValues();
    }, [])

    return (
        <>
            {loading && <LoadingSpinner />}
            {data.success == true &&
                <>
                    <center>
                        <i className="fa fa-check-circle fa-3x mb-2 mt-4 text-success"></i> <br />

                        <h5 className="mt-2"><strong>{data.totalUploaded}</strong>/{accounts.length} Secondary accounts have been successfully uploaded!</h5>

                        {data.success == true &&
                            <GeneralButton
                                className="btn btn-secondary   mr-2 mt-2"
                                onClick={() => {
                                    setSummaryModal(true)
                                }}
                                name={"View Uploaded Summary"} >
                            </GeneralButton>
                        }

                        <div className="mt-3">
                            <ul className="todo-list">
                                {
                                    data.updatedList.map((item) => (
                                        <>
                                            {
                                                item.error != null && (
                                                    <>
                                                        <li className="text-danger bg-light">
                                                            {item.Organisation + " :- "}
                                                            {item.error}
                                                        </li>
                                                    </>
                                                )
                                            }
                                        </>
                                    ))
                                }
                            </ul>
                        </div>
                    </center>
                </>
            }


            <>
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <GeneralButton
                            className="btn btn-light pull-right ml-2"
                            onClick={() => {
                                if (client)
                                    onClose(true);
                                else
                                    browserHistory.push("/entities")
                            }}
                            name={"Close"} >
                        </GeneralButton>


                    </div>
                </div>
            </>


            {/*------------------Bulk Payments Modal-------------------*/}
            <Modal
                show={summaryModal}
                onHide={() => setSummaryModal(false)}
                size="xl"
            >
                <Modal.Header closeButton={true}>
                    <h4 className="card-title">UPLOAD SUMMARY</h4>
                </Modal.Header>

                <Modal.Body>
                    <FormRow className="table-responsive">
                        <table class="table text-secondary">
                            <tbody>
                                <tr>
                                    <th>ABN</th>
                                    <th>Organisation</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Comments</th>
                                </tr>
                                {data.updatedList !== undefined && data.updatedList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.ABN}</td>
                                        <td>{item.Organisation}</td>
                                        <td>{item.First_Name} {item.Last_Name}</td>
                                        <td>{item.Email}</td>
                                        <td>
                                            {item.uploaded === true ? <span class="text-success">Upload Success</span> : <span class="text-danger">Upload Failed</span>}
                                        </td>
                                        <td>{item.error}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </FormRow>
                </Modal.Body>

                <Modal.Footer>
                    <FormRow>
                        <GeneralButton
                            onClick={() => setSummaryModal(false)}
                            className="btn btn-light pull-right m-l-xs"
                            name="Close"
                        ></GeneralButton>
                    </FormRow>
                </Modal.Footer>
            </Modal>
            {/*---------------------------------------------------------*/}
        </>
    )
}