import React, { useEffect } from "react";
import { FormRow, FormColumnMd } from "../../common/containers/formContainer";
import useForm from "../../../hooks/useForm";
import { SaveButton } from "../../common/containers/button";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import useApi from "../../../hooks/useApi";
import CommonService from "../../supply-chain/services/commonService";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import { BPList, CountriesList, ProductsList } from "../../slplusTools";
import auth from "../../../services/authService";

function MapEntity({
  contacts,
  entity_id,
  entityDetails,
  role,
  onCloseModal,
  source,
  title,
  productType = "secondary",
  handleSelectedItem = null,
  selected_contact
}) {
  //------------------Validation Schema-----------------------------
  const schema = Joi.object(); // Form validation schema object
  const mapClientApi = useApi(CommonService.mapClient);
  const { constants } = auth.getCurrentUser();

  const handleSelectContact = (index, contact_id) => {
    contacts.map(ec => {
      ec.isChecked = false;
      return ec;
    })
    contacts[index]['isChecked'] = true;
    setValues({ ...values, contact_id: contact_id });
  };
  //------------------Map Client-----------------------------
  const saveMapping = async () => {
    let reqValues = { ...values };
    if (!reqValues.contact_id) {
      toast.error("Contact required.");
      return;
    }
    if (!reqValues.owner && source == "accounts") {
      toast.error("Choose Partner.");
      return;
    }

    if (reqValues.role_type.length < 1 && source == "accounts") {
      toast.error("Choose Facilities.");
      return;
    }
    let reqArray = [];
    if (source == "accounts") {
      let activeProducts = reqValues.role_type.filter(
        (item) => item.isChecked == true
      );
      activeProducts.map((activeProduct) => {
        activeProduct["roles"]
          .filter((roleFilter) => roleFilter.isChecked === true)
          .map((activeRole) => {
            reqArray.push({
              contact_id: reqValues.contact_id,
              from_contact: reqValues.from_contact,
              product: activeProduct.id,
              role: activeRole.id,
              role_name: activeRole.role_name,
              isChecked: activeRole.isChecked,
            });
          });
      });
    } else if (source == "network") {
      let productID =
        title == constants.ROLES.DFDEBTOR
          ? constants.PRODUCTS.DEBTORFINANACE
          : (title == constants.ROLES.DEBTOR
            ? constants.PRODUCTS.INVOICE
            : (title == constants.ROLES.TFSUPPLIER ? constants.PRODUCTS.TRADEFINANCE : constants.PRODUCTS.SUPPLYCHAIN));

      let roleID =
        title == constants.ROLES.DFDEBTOR
          ? constants.ROLES_IDS.DFDEBTOR
          : (title == constants.ROLES.DEBTOR
            ? constants.ROLES_IDS.DEBTOR
            : (title == constants.ROLES.TFSUPPLIER ? constants.ROLES_IDS.TFSUPPLIER : constants.ROLES_IDS.SUPPLIER));

      let roleName =
        title == constants.ROLES.DFDEBTOR
          ? constants.ROLES.DFDEBTOR
          : (title == constants.ROLES.DEBTOR
            ? constants.ROLES.DEBTOR
            : (title == constants.ROLES.TFSUPPLIER ? constants.ROLES.TFSUPPLIER : constants.ROLES.SUPPLIER));

      reqArray.push({
        contact_id: reqValues.contact_id,
        from_contact: reqValues.from_contact,
        product: productID,
        role: roleID,
        role_name: roleName,
        isChecked: true,
      });
    }
    reqValues.products = reqArray;

    const { data: res } = await mapClientApi.request(reqValues);

    if (res.success === true) {
      toast.success(res.message);
      setTimeout(() => {
        onCloseModal();
        handleSelectedItem && handleSelectedItem(res.rows);
      }, 1500);

    } else toast.error(res.message);
  };
  const handleSelect = (selectedValue, value) => {
    setValues({ ...values, [selectedValue]: value });
  };
  const handleRoleChange = (items) => {
    setValues({ ...values, role_type: items });
  };

  const { values, setValues, handleSubmit } = useForm(saveMapping, schema);
  useEffect(() => {
    const contact_id = values.contact_id ? values.contact_id : selected_contact;

    setValues({
      ...values,
      current_entity_id: entity_id,
      entity_id: entityDetails.a_guid,
      from_contact: entityDetails.bpPrimaryContact,
      bp: entityDetails.owner,
      role: role,
      source: source,
      role_type: [],
      contact_id: contact_id,
    });
    // } 
  }, [values.owner]);

  const showMappedEntityNameEmail = (mappedEntity, type) => {
    let displayData = "";
    if (type == 'name') {
      if (mappedEntity.first_name.toLowerCase().includes('undisclosed') || mappedEntity.last_name.toLowerCase().includes('undisclosed')) {
        return mappedEntity.organization;
      } else {
        return mappedEntity.first_name+" "+mappedEntity.last_name;
      }
    } else if (type == 'email') {
      if (mappedEntity.email.toLowerCase().includes('undisclosed')) {
        return "****";
      } else {
        return mappedEntity.email;
      }
    } else {
      return "";
    }
    
  }

  return (
    <div>
      {mapClientApi.loading === true && <LoadingSpinner />}
      <form onSubmit={handleSubmit} className="form-horizontal">
        <FormRow className="row m-t-sm">
          <FormColumnMd col={12}>
            <div className="text-center">
              <div class="alert alert-info">{`The organisation already exists in our database.
                            Please find the details below`}</div>
            </div>
          </FormColumnMd>
          <FormColumnMd col={12}>
            <h6 className="m-t-sm m-b-md text-center">Choose a Contact</h6>
          </FormColumnMd>
          <FormColumnMd col={12}>
            <div className="table-responsive mb-2">
              <table className="table table-bordered text-secondary">
                <thead>
                  <tr>
                    <th></th>
                    <th>Organization/Name</th>
                    <th>Email</th>
                    <th>Designation</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map((obj, index) => {
                    return (
                      <tr
                        className={obj.is_primary === "yes" ? "fp-info-text" : ""}
                        key={index}
                      >
                        <td>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input

                              onClick={(e) =>
                                handleSelectContact(index, obj.contact_id)
                              }
                              type="radio"
                              id={`customRadioInline${obj.contact_id}`}
                              name="contact"
                              className="custom-control-input"
                              checked={obj.isChecked ? obj.isChecked : false}
                            />
                            <label
                              className="custom-control-label"
                              for={`customRadioInline${obj.contact_id}`}
                            />
                          </div>
                        </td>
                        <td>
                          { showMappedEntityNameEmail(obj,'name') }
                        </td>
                        <td>{ showMappedEntityNameEmail(obj,'email') }</td>
                        <td>{obj.designation}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </FormColumnMd>
        </FormRow>
        {source == "accounts" && (
          <>
            <CountriesList
              label="Country"
              type="horizontal"
              selectedCountry={values.country}
              onSelectItem={({ id }) => handleSelect("country", id)}
            />
            <BPList
              selectedBp={values.owner}
              label="Owner"
              type="horizontal"
              country={values.country}
              onSelectItem={({ value }) => {
                handleSelect("owner", value);
              }}
            />
            <FormRow className="row  m-b-md">
              <div className="col-lg-12">
                <ProductsList
                  col={6}
                  onSelectItem={(items) => handleRoleChange(items)}
                  type={productType}
                />
              </div>
            </FormRow>
          </>
        )}
        <div className="row pull-right mb-3 mr-1">
          <SaveButton disabled={mapClientApi.loading} label="Submit" />
        </div>
      </form>
    </div>
  );
}

export default MapEntity;
