import React, { useRef, useEffect, useState } from "react";
import useApi from "../../../../hooks/useApi";
import moment from "moment";
import _ from "lodash";
import loanService from "../../../loan/services/loanTransactions";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import { CurrencyLabel } from "../../../common/inputs";


function ScheduleDetail(props) {

    const [details, setDetails] = useState([]);

    // Api list
    const getDetailApi = useApi(loanService.GetLoanScheduleDetail);

    const getDetail = async () => {
        const { data } = await getDetailApi.request({
            ...props.data,
            type: props.type
        });
        if (data.success) {
            setDetails(data.result);
        }
    }

    const getLoanType = (type) => {
        if (type == 'Traditional') {
            return 'Principle & Interest';
        } else if (type == 'Other') {
            return 'Interest Only';
        } else if (type == 'Bespoke') {
            return 'Custom Loan';
        }
    }

    useEffect(() => {
        getDetail();
    }, [])

    return (
        <>
            {
                (
                    getDetailApi.loading == true
                ) && <LoadingSpinner />
            }

            <div className="">
                {
                    details && details.length > 0 ? (
                        <>
                            <table className="table text-secondary">
                                <thead>
                                    <tr>
                                        <th>Loan Reference</th>
                                        <th>Loan Type</th>
                                        <th>Payment Date</th>
                                        <th>Amount to Pay</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        details.map((item, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td>{item.loan_reference}</td>
                                                    <td>{getLoanType(item.loan_type)}</td>
                                                    <td>{moment(item.payment_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()}</td>
                                                    <td>
                                                        <CurrencyLabel
                                                            value={item.repay_balance}
                                                        ></CurrencyLabel>
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    }
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <strong>
                                                <CurrencyLabel
                                                    value={_.sumBy(details, (val) => { return Number(val.repay_balance); })}
                                                ></CurrencyLabel>
                                                
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <>
                            <p>No Result Found !</p>
                        </>
                    )
                }
            </div>
        </>
    );

}
export default ScheduleDetail;