/**Reject of supplier-intiated invoice by customer */
import React from "react";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import { CurrencyLabel } from "../../../../common/inputs/currencyInput/currencyInput";
import auth from "../../../../../services/authService";

function AmountInfo({ loading, fee, dateField, revDueDate = null, dueDate = null }) {
  const { category, constants } = auth.getCurrentUser();
  return (
    <>

      {(category == constants.CATEGORY.BP || category == constants.CATEGORY.COUNTRY_OFFICE || category == constants.CATEGORY.INTL_OFFICE) && (
        <div className="text-center font-medium" style={{ height: "50px" }}>
          {dueDate != "" && dueDate != null &&
            <>
              {loading ?
                <>

                  <h4 className="mt-1 d-flex align-items-center justify-content-center">
                    <span className="text-muted text-sm font-medium mr-2">ON THE {dateField != "" ? dateField : ""} YOUR CLIENT IS DUE TO PAY   &nbsp;
                    </span><span><LoadingSpinner height={20} width={15} normal={true} type="ThreeDots" />
                    </span></h4>
                  {(revDueDate == null || revDueDate == "") &&
                    <p className="text-muted text-xs font-medium">(enter a Revised Due Date if client wants to pay later)</p>
                  }
                </> :
                <><h4 className="mt-1 d-flex align-items-center justify-content-center"><span className="text-muted text-sm font-medium mr-2"> ON THE {dateField != "" ? dateField : ""} YOUR CLIENT IS DUE TO PAY   &nbsp;
                </span><b><span className="font-weight-bold text-primary text-lg"><CurrencyLabel value={fee ? fee : 0}></CurrencyLabel> </span></b> </h4>
                  {(revDueDate == null || revDueDate == "") &&
                    <p className="text-muted text-xs font-medium">(enter a Revised Due Date if client wants to pay later)</p>
                  }
                </>
              }
            </>
          }
        </div>
      )
      }
      {/*---------------------------------------------------------------------*/}
      {
        category == constants.CATEGORY.TRADERS && (
          <div className="text-center font-medium" style={{ height: "50px" }}>
            {dueDate != "" && dueDate != null &&
              <>
                {loading ?
                  <>

                    <h4 className="mt-1 d-flex align-items-center justify-content-center">
                      <span className="text-muted text-sm font-medium mr-2">PLEASE PAY  &nbsp; <LoadingSpinner height={10} width={15} normal={true} type="ThreeDots" /></span>

                    </h4>
                    {(revDueDate == null || revDueDate == "") &&
                      <p className="text-muted text-xs font-medium">(enter a Revised Due Date to extend terms/pay later)</p>
                    }

                  </> :
                  <>
                    <h4 className="mt-1 d-flex align-items-center justify-content-center">
                      <span className="text-muted text-sm font-medium mr-2">PLEASE PAY &nbsp;<b className="text-primary"><CurrencyLabel value={fee ? fee : 0}></CurrencyLabel> </b> ON {dateField}</span>
                    </h4>
                    {(revDueDate == null || revDueDate == "") &&
                      <p className="text-muted text-xs font-medium">(enter a Revised Due Date to extend terms/pay later)</p>
                    }
                  </>}
              </>
            }
          </div>
        )
      }
    </>
  );
}

export default AmountInfo;
