import http from "../../../services/httpService";
import { apiUrl, queryString } from "../../../environment";

const transactionUrl = "/supplychain/entity/";

let accessToken = "";
function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
}
// ------------- Generates API Url ------------------------
function getApiUrl(func) {
  let remote_call = apiUrl + transactionUrl + func + queryString;
  return remote_call;
}
// --------------------------------------------------------

// ------ Asynchronous function to communicate with the server
async function createResponse(url, postObject) {
  try {
    const response = await http.post(url, postObject);
    return response.data;
  } catch (error) {
    console.log(error); // Handle error response
  }
}
// --------------------------------------------------------

// ------ Service to populate user types list in a dropdown menu
export async function GetScUserTypes(type) {
  updateHeader();

  let url =
    apiUrl + "/core/auth/get-sc-user-types?XDEBUG_SESSION_START=PHPSTORM";

  try {
    const { data } = await http.post(url, { type }, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function copyEntity(a_guid, category) {
  let apiGeneralCall = getApiUrl("copy-entity");
  let postObject = {
    a_guid,
    category,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function createEntity(
  a_guid,
  ab_number,
  organization,
  first_name,
  middle_name,
  last_name,
  email,
  status,
  phone_no,
  category,
  street,
  city,
  state,
  country,
  selectedCategories,
  address1,
  address2,
  address3,
  post_code,
  alternate_phone,
  alternate_email,
  website,
  lead_source,
  referred_by,
  bank_account_name,
  bsb_number,
  account_number,
  trading_name,
  acn,
  incorporation,
  company_status,
  directors,
  industry,
  shareholdersData,
  addressList,
  organisation_number,
  organisation_status,
  owner,
  limitOption,
  primary2Status,

  primary1Account,
  primary1Insurance,
  primary1Insurer,

  primary2Account,
  primary2Insurer,
  primary2Insurance,
  fundLimit
) {
  let apiGeneralCall = getApiUrl("create-sc-entity");
  let postObject = {
    a_guid,
    ab_number,
    organization,
    first_name,
    middle_name,
    last_name,
    email,
    status,
    phone_no,
    category,
    street,
    city,
    state,
    country,
    selectedCategories,
    address1,
    address2,
    address3,
    post_code,
    alternate_phone,
    alternate_email,
    website,
    lead_source,
    referred_by,
    bank_account_name,
    bsb_number,
    account_number,
    trading_name,
    acn,
    incorporation,
    company_status,
    directors,
    industry,
    shareholdersData,
    addressList,
    organisation_number,
    organisation_status,
    owner,
    limitOption,
    primary2Status,

    primary1Account,
    primary1Insurance,
    primary1Insurer,

    primary2Account,
    primary2Insurer,
    primary2Insurance,
    fundLimit,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function getEntityDetails(a_guid, busineess_partner = "") {
  let apiGeneralCall = getApiUrl("get-entity-details");
  let postObject = {
    a_guid,
    busineess_partner,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

/*Get entities list for customer and supplier of supply chain ` */
export async function getScEntitiesList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl +
    "/supplychain/entity/list-sc-entities?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

export async function getScTabsList() {
  updateHeader();
  let url =
    apiUrl +
    "/supplychain/entity/get-sc-tabs-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {};

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}
export async function deleteScEntity(a_guid) {
  updateHeader();

  let url =
    apiUrl + "/supplychain/entity/delete-entity?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    a_guid,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getEntityContactsList(entity) {
  updateHeader();

  let url =
    apiUrl +
    "/supplychain/entity/list-entity-contacts?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function assignParentContact(
  parentEntityContact,
  selectedEntityContact,
  type
) {
  let apiGeneralCall = getApiUrl("assign-parent-entity-contact");
  let postObject = {
    parentEntityContact,
    selectedEntityContact,
    type,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
export async function createEntityContact(
  contact_id,
  first_name,
  designation,
  email,
  phone_no,
  entity_id,
  selectedParentContact,
  category_name
) {
  updateHeader();

  const { data } = await http.post(
    apiUrl +
      "/supplychain/entity/create-entity-contact?XDEBUG_SESSION_START=PHPSTORM",
    {
      contact_id,
      first_name,
      designation,
      email,
      phone_no,
      entity_id,
      selectedParentContact,
      category_name,
    },
    accessToken
  );
  return data;
}
export async function resetOldPasswordSc(password, contact_id) {
  updateHeader();
  const { data } = await http.post(
    apiUrl + "/supplychain/entity/reset-password",
    {
      password,
      contact_id,
    },
    accessToken
  );
  return data;
}
export async function deleteEntityContact(contactId) {
  let apiGeneralCall = getApiUrl("delete-entity-contact");
  let postObject = { contactId };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}

export async function handleContactCheckboxChange(
  contact_guid,
  ischecked,
  field,
  entity
) {
  let apiGeneralCall = getApiUrl("entity-contact-checkbox-change");
  let postObject = {
    contact_guid,
    ischecked,
    field,
    entity,
  };

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
export async function getLoginedUserDetails() {
  let apiGeneralCall = getApiUrl("get-login-entity-details");
  let postObject = {};

  const response = createResponse(apiGeneralCall, postObject);
  return response;
}
export default {
  getEntityDetails,
  GetScUserTypes,
  copyEntity,
  createEntity,
  getScEntitiesList,
  getScTabsList,
  deleteScEntity,
  getEntityContactsList,
  assignParentContact,
  createEntityContact,
  resetOldPasswordSc,
  deleteEntityContact,
  handleContactCheckboxChange,
  getLoginedUserDetails,
};
