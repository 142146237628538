import React, { Component } from "react";
import * as moment from "moment";
import _ from "lodash";
import { CurrencyLabel } from "../../common/inputs";
import EntityContext from "../../../context/entityContext";
import Table from '../../common/tables/ActionTable';
import dfTransactionService from "../services/dfTransactions";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from '../../common/loaders/loadingSpinner';
import ExportReport from "../../reports/scReports/exportReport";


class AppliedFees extends Component {

  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      //selectedType: "",
      feeDetail: "",
      feeDetailModal: false,
      loading: false,
      selectedRow: "",
    }
  }

  onCloseFeeDetailModal = () => {
    this.setState({
      feeDetail: "",
      selectedRow: "",
      feeDetailModal: false
    })
  }

  onOpenFeeDetailModal = async (row) => {
    this.setState({ loading: true, selectedRow: row });
    await this.fetchFeeDetail(row);
    this.setState({
      feeDetailModal: true,
      loading: false
    })
  }

  fetchFeeDetail = async (row) => {
    const { data } = await dfTransactionService.getAppliedFeeDetail({
      feeType: row.fee_name,
      referenceUid: row.uid
    })
    this.setState({ feeDetail: data.rows });
  }

  exportExcel = async (type) => {
    const res = await dfTransactionService.getAppliedFeeDetail({
      feeType: this.state.selectedRow.fee_name,
      referenceUid: this.state.selectedRow.uid,
      printReport: true
    });
    if (type === "csv") {

      const a = document.createElement("a");
      a.href = res.data;
      a.style.display = 'none';
      a.download = 'report.csv';
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);

    } else {
      const newWindow = window.open(res.data);
      newWindow.opener = null;
    }
  };

  render() {
    const cols = [
      {
        Header: "Date",
        accessor: "date",
        headerStyle: { textAlign: "left" },
        Cell: (row) => <span>{moment(row.original.date).format("DD/MM/YYYY").toString()}</span>,
      },
      {
        Header: "Fee Name",
        accessor: "fee_name",
        headerStyle: { textAlign: "left" },
        Cell: (row) => <span>{row.original.fee_name}</span>,
      },
      {
        Header: "Amount",
        accessor: "value",
        headerStyle: { textAlign: "left" },
        Cell: (row) => (
          <span>
            <CurrencyLabel
              value={
                row.original.value !== null ? row.original.value : 0
              }
            ></CurrencyLabel>
          </span>
        ),
      },
      {
        Header: "Fee Type",
        accessor: "type",
        headerStyle: { textAlign: "left" },
        Cell: (row) => (
          <span>
            {
              row.original.type == 'fix' ? 'Fixed' : 'Adhoc'
            }
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "",
        headerStyle: { textAlign: "left" },
        Cell: (row) => (
          <span
            title="View Details"
            className="text-secondary font-weight-bold cursor-link"
            onClick={() => {
              this.onOpenFeeDetailModal(row.original);
            }}
          >
            <i className="fa fa-eye text-secondary mx-2 mt-2"></i>
          </span>
        ),
      },
    ];

    return (
      <>
        <div className="">
          {this.state.loading == true && (<LoadingSpinner />)}
          <Table
            ref={this.tableRef} // 'Ref' is used to gain access to the methods of child component
            service={dfTransactionService.ShowAppliedFee}
            cols={cols}
            uid={'a_guid'}
            reqParams={{
              //trans_client_guid: this.props.location.query.a_guid,
              trans_client_guid: this.context.entityDetails.a_guid,
            }}
            headerTabs={[]}
          />
        </div>

        <Modal
          show={this.state.feeDetailModal}
          onHide={this.onCloseFeeDetailModal}
          closeOnOverlayClick={false}
          center
          className="animated bounceInRight"
          size="lg"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Fee discription</h4>
          </Modal.Header>

          <Modal.Body>

            <div className="dyn-height">
              {
                this.state.selectedRow && (
                  this.state.selectedRow.fee_name == 'Interest' ? (
                    <>
                      <table className="table table-sm text-secondary">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Total Amount</th>
                            <th>Rate Applied</th>
                            <th>Interest Amount</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.feeDetail && this.state.feeDetail.map((item, index) => (
                              <>
                                <tr key={index}>
                                  <td>{item.interest_name}</td>
                                  <td>
                                    <CurrencyLabel value={item.total_amount}></CurrencyLabel>
                                  </td>
                                  <td>{item.rate_applied} %</td>
                                  <td>
                                    <CurrencyLabel value={item.amount}></CurrencyLabel>
                                  </td>
                                  <td>{moment(item.date).format("DD/MM/YYYY").toString()}</td>
                                </tr>
                              </>
                            ))
                          }

                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <table className="table table-sm text-secondary">
                        <thead>
                          <tr>
                            <th>Organization</th>
                            <th>Invoice Number</th>
                            <th>Invoice Amount</th>
                            <th>Invoice Date</th>
                            <th>Invoice Due Date</th>
                            <th>Invoice Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.feeDetail && this.state.feeDetail.map((item, index) => (
                              <>
                                <tr key={index}>
                                  <td>{item.organization}</td>
                                  <td>{item.inv_number}</td>
                                  <td><CurrencyLabel value={item.inv_amount}></CurrencyLabel></td>
                                  <td>{moment(item.inv_date).format("DD/MM/YYYY").toString()}</td>
                                  <td>{moment(item.inv_due_date).format("DD/MM/YYYY").toString()}</td>
                                  <td>{item.invoice_status}</td>
                                </tr>
                              </>
                            ))
                          }
                        </tbody>
                      </table>
                    </>
                  )
                )
              }
            </div>
            <div className="row">
              <div className="col-sm-12">
                <ExportReport
                  exportExcel={this.exportExcel}
                  tableVisibility={this.state.feeDetail ? true : false}
                />
              </div>
            </div>

          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default AppliedFees;