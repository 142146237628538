import React, { useRef, useEffect, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { toast } from "react-toastify";
import _ from "lodash";
import ReactTable from "react-table";
import moment from "moment";
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import authService from "../../../../services/authService";
import loanService from "../../../loan/services/loanTransactions";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import DDScheduleList from "./_getscheduleDetails";
import { confirmAlert } from "react-confirm-alert";
import externalApiService from "../../../../services/externalApiService";
import { CountriesList, AllCorporateBpList, CustomersList, BPList } from '../../../slplusTools';
import { ReportInputFieldContainer, FormRow, FormGroup, FormColumn } from "../../../common/containers/formContainer";
import { GridActionButton, GeneralButton, DateTimePicker, CurrencyLabel, Picker } from "../../../common/inputs";
import CreateManoovaToken from "../../../externalapis/components/createManoovaToken";

function RequestPayment(props) {

    const { constants, category, country_id } = authService.getCurrentUser();

    const [country, setCountry] = useState(null);
    const [owner, setOwner] = useState(null);
    const [client, setClient] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const [requestedList, setRequestedList] = useState([]);
    const [ddScheduleListModal, setDdScheduleListModal] = useState(false);
    const [scheduleItem, setScheduleItem] = useState(null);
    const [totalAmtToPay, setTotalAmtToPay] = useState(0);
    const [clientSelectedToPay, setClientSelectedToPay] = useState(0);
    const [regTokenPopup, setRegTokenPopup] = useState(false);
    const [manoovaCustomer, setManoovaCustomer] = useState(null);
    const [cancelLoader, setCancelLoader] = useState(false);

    // Api list
    const getRequestedListApi = useApi(loanService.GetRequestedPaymentDetails);
    const processPaymentApi = useApi(loanService.processPayment);
    const manoovaCustCheckApi = useApi(externalApiService.checkManoovaCustRegistered);
    const cancelPaymentRequest = useApi(loanService.cancelRequest);
    const getRequestedPaymentList = async () => {
        
        const response = await getRequestedListApi.request({
            owner: owner,
            client: client
        });
        if (response) {
            setRequestedList(response.data.rows);
        } else {
            toast.error("Failed to fetch data");
        }
    }
    const handleShowScheduleDetail = (item) => {
        setDdScheduleListModal(true);
        setScheduleItem({
            client: item.entity_guid,
            batchId: item.reference_guid
        });
    }

    // Function to handle checkbox selection for individual invoices
    const handleCheckboxChange = (id) => {

        const requestedPaymentList = [...requestedList];
        requestedPaymentList[id].checked = !requestedPaymentList[id].checked;
        setRequestedList(requestedPaymentList);

        const selectedList = requestedPaymentList.filter(itm => itm.checked == true);
      
        // Calculate the total of total_requested_amount
        const totalAmount = selectedList.reduce((prev_val, currentValue) => {
            return prev_val + parseFloat(currentValue.amount);
        }, 0);
        setClientSelectedToPay(selectedList.length);
        setTotalAmtToPay(totalAmount);
        setShowButtons(selectedList.length > 0);
    };

    // Function to handle select/deselect all invoices
    const handleSelectAll = () => {
        
    };

    //Function to confirm request payment to manoova
    const handleRequestPayment = async () => {
        const selectedCustomers = requestedList.filter(itm => itm.checked == true).map(item => {
            return item.entity_guid;
        });

        // check if customers registered with manoova and have token
        const manoovaResponse = await manoovaCustCheckApi.request({
            customers: selectedCustomers
        });
        if (manoovaResponse) {
            if (manoovaResponse.data.success) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-delete-ui">

                                <h4>Are you sure want to continue?</h4>
                                {/* <p className="text-danger">This action will initiate a collection request to the payment gateway.</p>*/}
                                <p className="text-primary">Total Payment Selected: <span >{totalAmtToPay} from {clientSelectedToPay} client/s</span></p>

                                <button
                                    className="btn btn-white ml-2 mt-2"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    No
                                </button>

                                <button
                                    className="btn btn-primary ml-2 mt-2"
                                    onClick={async () => {
                                        onClose();
                                        processPayment();
                                    }}
                                >
                                    Yes
                                </button>
                            </div>
                        );
                    },
                    title: "",
                    message: "",
                });
            } else {
                let paymentSchduledList = requestedList.map(item => {
                    if (item.checked == true && manoovaResponse.data.customerList.includes(item.entity_guid)) {
                        item.invalidManoova = true;
                    }
                    return item;
                });

                setRequestedList(paymentSchduledList);
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-delete-ui">
                                <h4>Bank details missing</h4>
                                <p  >
                                    To proceed with this request, kindly update the banking information for the listed clients by clicking the button next to their names. Thank you.</p>
                                <ul>
                                    {
                                        paymentSchduledList.filter(itm => itm.invalidManoova == true).map(itm => (
                                            <>
                                                <li className="text-danger text-left font-weight-bold">{itm.organization}</li>
                                            </>
                                        ))
                                    }
                                </ul>
                                <div class="clear"></div>

                                <button
                                    className="btn btn-white "
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    close
                                </button>

                            </div>
                        );
                    },
                    title: "",
                    message: "",
                });
            }
        } else {
            toast.error("Something went wrong. Contact your administrator.");
        }
    }

    const processPayment = async () => {
        let clientPaymentDetail = [];
        requestedList.filter(itm => itm.checked == true).map(itm => {
            clientPaymentDetail.push({
                batchId: itm.reference_guid,
                amount: itm.amount,
                client_guid: itm.entity_guid,
                organization: itm.organization,
                sch_id:itm.schedule_guid
            })
        });
        const { data } = await processPaymentApi.request({
            selectedClients: clientPaymentDetail
        });
        
        if (data.success == true) {
            setRequestedList([]);
            setShowButtons(false);
            toast.success("Request to process payment completed successfully");
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">

                            <h4>Failed to process payment</h4>
                            {
                                data.successList && data.successList.length > 0 && (
                                    <>
                                        <b>Completed successfully</b><br />
                                        {
                                            data.successList.map(itm => (
                                                <>
                                                    <p className="text-primary">
                                                        Payment request of {itm.organization} for an amount
                                                        <CurrencyLabel value={itm.amount}></CurrencyLabel> has been requested successfully.
                                                    </p>
                                                </>
                                            ))
                                        }
                                    </>
                                )
                            }
                            {
                                data.errorList && data.errorList.length > 0 && (
                                    <>
                                        <b></b><br />
                                        {
                                            data.errorList.map(itm => (
                                                <>
                                                    <p className="text-danger">
                                                        {itm}
                                                    </p>
                                                </>
                                            ))
                                        }
                                    </>
                                )
                            }
                            <button
                                className="btn btn-white ml-2 mt-2"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </button>
                        </div>
                    );
                },
                title: "",
                message: "",
            });
        }
    }

    // modal to add bank details to request for manoova token
    const handleOpenTokenModal = (customerGuid) => {
        setRegTokenPopup(true);
        setManoovaCustomer(customerGuid);
        
        let paymentSchduledList = requestedList.map(item => {
            if (item.checked == true && customerGuid == item.entity_guid) {
                item.invalidManoova = false;
            }
            return item;
        });
        setRequestedList(paymentSchduledList);
    }

    const closeRegTokenPopup = () => {
        setRegTokenPopup(false);
        setManoovaCustomer(null);

    }

    const handleCancel = async () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-delete-ui">
              <h4>Cancel request</h4>
              <p>Are you sure want to cancel payment request?</p>
              <button
                className="btn btn-white ml-2 mt-2"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </button>

              <button
                className="btn btn-primary ml-2 mt-2"
                onClick={async () => {
                  onClose();
                  cancelRequest();
                }}
              >
                Yes
              </button>
            </div>
          );
        },
        title: "",
        message: "",
      });
    };

    const cancelRequest = async () => {
      setCancelLoader(true);
        let clientPaymentDetail = [];
        requestedList.filter(itm => itm.checked == true).map(itm => {
            clientPaymentDetail.push({
                batchId: itm.reference_guid,
                amount: itm.amount,
                client_guid: itm.entity_guid,
                organization: itm.organization,
                sch_id:itm.schedule_guid
            })
        });

        const { data } = await cancelPaymentRequest.request({
            selectedClients: clientPaymentDetail
        });
        
        if(data.success === true)
        {
          setCancelLoader(false);
          toast.success('Payment request cancelled successfully');
          getRequestedPaymentList();
          setRequestedList([]);
          setShowButtons(false);
        }else{
          setCancelLoader(true);
          toast.error('Something went wrong...!')
        }

    }

    useEffect(() => {
        if (category == constants.CATEGORY.COUNTRY_OFFICE) {
            setCountry(country_id);
        }
        getRequestedPaymentList();
    }, []);

    const cols = [
        {
            id: 'bulk-checkbox',
            accessor: '',
            Cell: row => {
                return (
                    <div className="custom-control d-flex custom-checkbox small">
                        <input
                            id={`customCheck-${row.index}`}
                            className="custom-control-input"
                            type="checkbox"
                            checked={row.original.checked}
                            onChange={() => handleCheckboxChange(row.index)}
                        ></input>
                        <label
                            className="custom-control-label"
                            htmlFor={`customCheck-${row.index}`}
                        ></label>
                    </div>
                );
            },
            width: 50,
            sortable: false,
            resizable: false,
        },
        {
            Header: "Organization",
            accessor: "organization",
            headerStyle: { textAlign: "left" },
            Cell: row => {
                return (
                    <>
                        <span>{row.original.organization}</span>
                        {
                            row.original.invalidManoova && (
                                <>
                                    <span
                                        className="ml-2 label label-danger rounded-full cursor-link"
                                        title="Click to Register in payment gateway"
                                        onClick={() => handleOpenTokenModal(row.original.entity_guid)}
                                    >
                                        Click to Register
                                    </span>
                                </>
                            )
                        }
                    </>
                );
            },
            sortable: false,
        },
        {
            Header: "Payment Date",
            accessor: "added_date",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <span>
                    {moment(row.original.added_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()}
                </span>
            ),
            sortable: false,
        },
        {
            Header: "Amount",
            accessor: "amount",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <span>
                    <CurrencyLabel
                        value={row.original.amount}
                    ></CurrencyLabel>
                </span>
            ),
            sortable: false,
        },
        {
            Header: "Pending Payments",
            accessor: "schedule_count",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <Link
                    className="cursor-link"
                    onClick={() => { handleShowScheduleDetail(row.original) }}
                >
                    {row.original.schedule_count}
                </Link>
            ),
            sortable: false,
        }

    ];

    return (
      <>
        {(getRequestedListApi.loading == true ||
          processPaymentApi.loading == true ||
          manoovaCustCheckApi.loading == true ||
          cancelLoader === true) && <LoadingSpinner />}
        <div className="row">
          <div className="col-sm-12">
            <FormRow>
              <ReportInputFieldContainer
                label={
                  category == constants.CATEGORY.INTL_OFFICE ? "Country" : ""
                }
                show={category == constants.CATEGORY.INTL_OFFICE ? true : false}
              >
                <CountriesList
                  type="horizontal"
                  selectedCountry={country}
                  onSelectItem={({ id }) => {
                    setCountry(id);
                    setOwner(null);
                    setClient(null);
                  }}
                />
              </ReportInputFieldContainer>

              <ReportInputFieldContainer
                label={
                  category == constants.CATEGORY.INTL_OFFICE ||
                  category == constants.CATEGORY.COUNTRY_OFFICE
                    ? "Business Partner"
                    : ""
                }
                show={
                  category == constants.CATEGORY.INTL_OFFICE ||
                  category == constants.CATEGORY.COUNTRY_OFFICE
                    ? true
                    : false
                }
              >
                <BPList
                  selectedBp={owner}
                  type="horizontal"
                  country={country}
                  onSelectItem={({ value }) => {
                    setOwner(value);
                    setClient(null);
                  }}
                />
              </ReportInputFieldContainer>

              <ReportInputFieldContainer label="Client">
                <CustomersList
                  selectedCustomer={client}
                  bp={owner}
                  onSelectItem={(option) => setClient(option.value)}
                />
              </ReportInputFieldContainer>

              <FormColumn col={`col-sm-8`}>
                <label></label>
                <div>
                  <GeneralButton
                    className="btn btn-primary  mt-2 pull-right"
                    name="Search"
                    onClick={() => {
                      getRequestedPaymentList();
                    }}
                    disabled={
                      requestedList && requestedList.length > 0 ? true : false
                    }
                  ></GeneralButton>
                  <GeneralButton
                    className="btn btn-light mr-2 mt-2 pull-right"
                    name="Reset"
                    onClick={() => {
                      setRequestedList([]);
                    }}
                  />
                </div>
              </FormColumn>
            </FormRow>
            <hr />

            {showButtons && (
              <div className="row mt-2">
                <div className="col-sm-12">
                  <div className="  b-dashed-2 p-2 bg-light ml-3">
                    <div className="row">
                      <div className="col-4">
                        <p className="mb-1">Total Payment Selected</p>
                        <h5 className="font-weight-bold mb-1">
                          <CurrencyLabel
                            label="make pay"
                            value={totalAmtToPay}
                          ></CurrencyLabel>
                        </h5>
                      </div>
                      <div className="col-4">
                        <p className="mb-1">Total Clients</p>
                        <h5 className="font-weight-bold mb-1">
                          {clientSelectedToPay}
                        </h5>
                      </div>
                      <div className="col-4">
                        <GridActionButton
                          class="btn btn-danger mr-2 mt-2 pull-right"
                          label="Cancel Online Payment"
                           handleClick={(e) => handleCancel()}
                        />
                        <GridActionButton
                          class="btn btn-primary mr-2 mt-2 pull-right"
                          label="Request Online Payment"
                          handleClick={(e) => handleRequestPayment()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <ReactTable
              data={requestedList}
              columns={cols}
              defaultPageSize={10}
              minRows={requestedList.length}
              className="-striped -highlight"
              showPagination={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              noDataText="No rows found"
            />
          </div>
        </div>

        {/*------------------------loan DD schedule list modal-----------*/}
        <Modal
          show={ddScheduleListModal}
          onHide={() => setDdScheduleListModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Schedule List</h4>
          </Modal.Header>
          <Modal.Body>
            <DDScheduleList type="requested" data={scheduleItem} />
          </Modal.Body>
        </Modal>
        {/*--------------------------------------------------------------*/}

        {/*------------------------Manoova Token request modal----------------------*/}
        <Modal
          show={regTokenPopup}
          onHide={() => setRegTokenPopup(false)}
          centered
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Register in manoova</h4>
          </Modal.Header>
          <Modal.Body>
            <CreateManoovaToken
              customer={manoovaCustomer}
              closeModal={closeRegTokenPopup}
            />
          </Modal.Body>
        </Modal>
        {/*--------------------------------------------------------------*/}
      </>
    );
}
export default RequestPayment;