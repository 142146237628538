import React, { useContext } from "react";

import { DateTimePicker } from "../../../../common/inputs/datePicker";


import { RadioButton } from "../../../../common/inputs/radioButton/radioButton";

import ApproveContext from "../../../../supply-chain/context/bulkApproveContext"; // React Context API
function ApprovalOptions(props) {
  const bulkapproveOptions = useContext(ApproveContext);

  const handleRadioButtonChange = ({ target: { name, value } }) => {
    bulkapproveOptions.setDueDateOption(value);
  };
  const handleChange = (date) => {
    bulkapproveOptions.setDueDate(date);
  };
  if (bulkapproveOptions.showAmount == true || bulkapproveOptions.attention) {
    bulkapproveOptions.setProceedNext(true);
  } else {
    bulkapproveOptions.setProceedNext(false);
  }
  return (
    <>
      {bulkapproveOptions.approval == "yes" && (
        <>
       
       <h6>EXTEND INVOICE DUE DATE*</h6>
          
       <RadioButton
         name="extend_due_date_option"
         value="maximum"
         checked={bulkapproveOptions.dueDateOption == "maximum"}
         onChange={(e) => {
           handleRadioButtonChange(e);
         }}
         id="maximum"
         label= "Extend to maximum available terms"
         className ="custom-control custom-radio mt-2"
       ></RadioButton>
    
    
       <RadioButton
         name="extend_due_date_option"
         value="60Days"
         checked={bulkapproveOptions.dueDateOption == "60Days"}
         onChange={(e) => {
           handleRadioButtonChange(e);
         }}
         id="60Days"
         label= " Extend up to 60 days from invoice date"
         className ="custom-control custom-radio mt-2"
       ></RadioButton>
      
    
       <RadioButton
         name="extend_due_date_option"
         value="30Days"
         checked={bulkapproveOptions.dueDateOption == "30Days"}
         onChange={(e) => {
           handleRadioButtonChange(e);
         }}
         id="30Days"
         label =" Extend up to 30 days from invoice date"
         className ="custom-control custom-radio mt-2"
       ></RadioButton>
     
    
       <RadioButton
         name="extend_due_date_option"
         value="date"
         checked={bulkapproveOptions.dueDateOption == "date"}
         onChange={(e) => {
           handleRadioButtonChange(e);
         }}
         id="date"
         label ="Pick an exact date to extend to"
         className ="custom-control custom-radio mt-2"
       ></RadioButton>
 
       {bulkapproveOptions.dueDateOption == "date" && (
         <DateTimePicker
           name="extend_due_date"
           type="normal"
           onChange={handleChange}
           selected={bulkapproveOptions.dueDateSelected}
           class =" form-control mt-2 mb-2"
         ></DateTimePicker>
       )}
       <RadioButton
         name="extend_due_date_option"
         value="original_due_date"
         checked={bulkapproveOptions.dueDateOption == "original_due_date"}
         onChange={(e) => {
           handleRadioButtonChange(e);
         }}
         id="original_due_date"
         label="  Do not extend - I'll pay on the original due date"
 
         className ="custom-control custom-radio mt-2"
       ></RadioButton>
       </>
      )}
    </>
  );
}

export default ApprovalOptions;
