import React, { useState } from "react";
import startIcon5 from "../../.././../img/icons/FMB_icon5.svg"
import startIcon10 from "../../.././../img/icons/FMB_icon10.svg"
// import startIcon12 from "../../.././../img/icons/FMB_icon12.svg"
import startIcon11 from "../../.././../img/icons/FMB_icon11.svg"

import { useSelector, useDispatch } from 'react-redux'
import {handleAddMore, handleChangeAnswers2 } from '../../../../redux/financeBusiness/finance.actions'
import { TextInput, } from "../../../common/inputs";
import { RadioButton } from "../../../common/inputs/radioButton/radioButton";
export default function Page4({ goNext, questionSection }) {
    const questions = useSelector(({ financeReducer }) => financeReducer.questions2)
    let row = [];
    const dispatch = useDispatch()
    const [currentQuestion, setCurrentQuestion] = useState(0)
    let questionSet = [
        { from: 0, to: 1 },
        { from: 1, to: 4 },
        { from: 4, to: 7 },
        { from: 7, to: 10 },
        { from: 10, to: 13 },
        { from: 13, to: 17 },
        { from: 17, to: 19 },
        { from: 19, to: 20 },
        { from: 20, to: 21 }


    ]

    const questionSetLength = questionSet.length
    const next = () => {

        console.log({ questionSetLength });
        console.log({ currentQuestion });
        debugger
        if (questionSetLength <= currentQuestion + 1 && qsType === 'cashFlowQestions') {
            goNext('finals')
        }
        else if (questionSetLength <= currentQuestion + 1 && qsType === 'finals') {
            goNext('finished')
        }
        else {
            setCurrentQuestion(currentQuestion + 1)
        }
        // if (questionSetLength <= currentQuestion + 1 && questionSection === 'cashFlowQestions') {
        //     goNext('workingCapitalQuestions')
        // } else if (questionSetLength <= currentQuestion + 1 && questionSection === 'workingCapitalQuestions') {
        //     goNext('finalQuestions')
        // }
        // else if (questionSetLength <= currentQuestion + 1 && questionSection === 'finalQuestions') {
        //     goNext('finished')
        // }
        // else {
        //     if (currentQuestion === 0 && questionSection === 'cashFlowQestions') {
        //         let isAnswered=[];
        //        // debugger

        //         questions[0].options.map(opt => {
        //             opt.options.map(val => {
        //                 if( val.selected == true){
        //                     isAnswered.push(val)
        //                 }

        //             })
        //         })
        //         if (isAnswered.length > 0) {
        //             isAnswered = isAnswered.filter(row => row.value === 'Yes')
        //             if (isAnswered.length > 0) {
        //                 setCurrentQuestion(currentQuestion + 1)

        //             } else {
        //                 toast.error('select minimum one Yes!');

        //             }

        //         } else {
        //             toast.error(' your are not selected any option  ');

        //         }
        //         console.log({isAnswered});
        //     } else {
        //         setCurrentQuestion(currentQuestion + 1)

        //     }
        // }
        // return
    }
    const back = () => {

        if (currentQuestion !== 0) {
            setCurrentQuestion(currentQuestion - 1)
        } else {
            //goNext('cashFlowChoose')
        }
        return
    }
    const [selectedAnswer, setSelectedAnswer] = useState([])
    const handleChange = (e, optionId, questionId, questionType) => {
        const { name, value, id } = e.target;
        //console.log({id});
        const data = {
            id: id,
            value: value,
            optionId: optionId,
            questionSection: questionSection,
            questionId: questionId,
            questionType: questionType
        }
        dispatch(handleChangeAnswers2(data))
        setSelectedAnswer([...selectedAnswer, id])
        console.log({ selectedAnswer });
    }
    const handleTextInput = (e, questionId, questionType) => {
        const { name, value, id } = e.target;
        const data = {
            id: id,
            value: value,
            questionSection: questionSection,
            questionId: questionId,
            questionType: questionType
        }
        dispatch(handleChangeAnswers2(data))
    }
    const handleMultiTextInput=(e, questionId, questionType,index)=>{
        const { name, value, id } = e.target;
        const data = {
            id: id,
            value: value,
            questionSection: qsType,
            questionId: questionId,
            questionType: questionType,
            boxNumber:index,

        }
        dispatch(handleChangeAnswers2(data))
    }
    const handleSelect = (e, questionId, questionType) => {
        const { name, value, id } = e.target;
        const data = {
            id: id,
            value: value,
            questionSection: questionSection,
            questionId: questionId,
            questionType: questionType
        }
        dispatch(handleChangeAnswers2(data))
    }
    const [boxCout, setBoxCount] = useState(1)
    const addMore = (question) => {
       setBoxCount(boxCout + 1)
      debugger
        dispatch(handleAddMore(question))

    }
    const [qsType, setQsType] = useState('')
    const QuestionView = () => {
        debugger
        console.log({ questionSection });
        if (questions.length > 0) {

            row = questions

            row = row.sort((a, b) => parseFloat(a.questionNumber) - parseFloat(b.questionNumber));
            console.log({ row });
            return (
                <>
                    {
                        row.slice(questionSet[currentQuestion].from, questionSet[currentQuestion].to).map((qs, index) => {
                            if (qs.questionNumber >= 14 && qs.questionNumber <= 19) { setQsType('finalQuestions') }
                            else if (qs.questionNumber <= 13 && qs.questionNumber >= 8) { setQsType('workingCapitalQuestions') }
                            else if (qs.questionNumber >= 19) { setQsType('finals') }
                            else { setQsType('cashFlowQestions') }
                            return (
                                <>
                                    <dt className="mb-3 f-13">{qs.questionNumber}- {qs.question}</dt>
                                    <dd >
                                        {qs.questionType == 'options' && qs.options.length > 0 ?
                                            qs.options.map(options =>
                                                <>
                                                    <div className="f-13">{options.label}</div>
                                                    <div className="mb-2">
                                                        {options.options.length > 0 && options.options.map((opt, index) =>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <RadioButton
                                                                    name={opt.id}
                                                                    value={opt.value}
                                                                    checked={opt.selected}
                                                                    onChange={(e) => handleChange(e, options.id, qs.id, qs.questionType)}
                                                                    id={opt.id}
                                                                    label={opt.value}
                                                                    className="custom-control custom-radio mt-2"
                                                                ></RadioButton>
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                </>
                                            ) : null}
                                        {qs.questionType == 'text' &&qsType !== 'finals' &&
                                            <div className="col-lg-12 px-0 ">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="purchase_order_no"
                                                        value={qs.answer}
                                                        label="Purchase Order Number"
                                                        reference=""
                                                        onChange={(e) => handleTextInput(e, qs.id, qs.questionType)}
                                                        type=""
                                                    ></TextInput>
                                                </div>
                                            </div>

                                        }
                                        {qs.questionType == 'text' && qsType == 'finals' &&
                                            <div className="col-lg-12 px-0 ">
                                                <div className="form-group">
                                                    {console.log("qqqqqqqqq----------",qs.answer)}
                                                    {
                                                        qs.answer.map((val,index) => {
                                                            return (
                                                                <TextInput
                                                                className='pt-2'
                                                                    name="purchase_order_no"
                                                                    value={val.data}
                                                                    label="Purchase Order Number"
                                                                    reference=""
                                                                    onChange={(e) => handleMultiTextInput(e, qs.id, qs.questionType,index)}
                                                                    type=""
                                                                ></TextInput>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>

                                        }
                                        {qs.questionType == 'address' &&
                                            <div className="col-lg-12 px-0 ">
                                                <div className="form-group">
                                                    <textarea
                                                        onChange={(e) => handleTextInput(e, qs.id, qs.questionType)}
                                                        name="purchase_order_no" rows="4" cols="25">
                                                        {/* {qs.answer} */}
                                                    </textarea>
                                                    {/* <TextInput
                                                        name="purchase_order_no"
                                                        value={qs.answer}
                                                        label="Purchase Order Number"
                                                        reference=""
                                                        onChange={(e) => handleTextInput(e, qs.id, qs.questionType)}
                                                        type="" 
                                                    ></TextInput> */}
                                                </div>
                                            </div>

                                        }
                                        {
                                            qs.questionType == 'select' &&
                                            <div className="col-lg-12 px-0">
                                                <div className="form-group">
                                                    <select id="state1" name="state" className="form-control" onChange={(e) => handleSelect(e, qs.id, qs.questionType)} >
                                                        <option value={null} >{`Select`}</option>

                                                        {
                                                            qs.options.map(selectRow =>
                                                                <option value={selectRow.value} >{selectRow.value}</option>
                                                            )
                                                        }


                                                    </select>
                                                </div>
                                            </div>
                                        }

                                    </dd>
                                    <div className="d-flex justify-content-end">
                                        {
                                            qsType === "finals" &&qs.questionNumber!==21&&
                                            <> <div onClick={() => addMore(qs.id)} className="btn btn-primary ml-1">Add</div>
                                            </>

                                        }
                                    </div>

                                    {/* {
                                        qs.other &&
                                        <>
                                            <dt className="mb-4">Other (optional)</dt>
                                            <dd className="my-3 ml-3"><div className="d-flex"></div>
                                                <div className="mt-2 ml-3">
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <div >  <TextInput name="other" value="" label="Other" reference="" onChange="" type=""></TextInput></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </dd>
                                        </>
                                    } */}
                                </>
                            )
                        })
                    }
                </>
            )
        }
        else {
            return null
        }
    }
    return (
        <>
            <div id="animation_box" className="animated fadeIn">
                <div id="animation_box" className="animated fadeInLeft">
                    <div className="card fb-height mb-0 ">
                        <div className="card-body center-div">
                            <div className="row vh-70 justify-content-center align-items-center py-5">
                                <div className="col-lg-3  col-md-12">
                                    <div className="operate-image">
                                        <img src={qsType === 'cashFlowQestions' ? startIcon5 : qsType === 'workingCapitalQuestions' ? startIcon11 : startIcon10} alt="imag" width />
                                    </div>
                                </div><div className="col-lg-6 offset-lg-1 col-md-12">
                                    <div className>
                                        <h4>{qsType !== 'finalQuestions' ? qsType === 'cashFlowQestions' && 'Cash Flow Finance' : ''}
                                            {qsType !== 'finalQuestions' ? qsType === 'workingCapitalQuestions' && 'Working Capital' : ''}
                                            {qsType === 'finals' ? 'Final Questions' : qsType === 'finalQuestions' && 'Let’s wrap up with some final questions'}


                                        </h4>
                                        <p >
                                            {qsType === 'finalQuestions' ? 'These general questions will help us match the best finance solution to your needs.' : qsType === 'finals' ? 'These questions will help us match your identity and the business identity to our records.' : 'Please answer the following questions'}

                                        </p>
                                        <div className="border rounded-xl p-4 shadow-sm mb-3">
                                            <dl className="dl-horizontal ">
                                                <QuestionView />
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10"><div className="d-flex justify-content-end align-items-center mt-3">
                                    {currentQuestion !== 0 && <div onClick={() => back()} className="btn btn-light mr-1">Back</div>}
                                    <div onClick={() => next()} className="btn btn-primary ml-1">Continue</div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

