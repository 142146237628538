import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import useApi from "../../hooks/useApi";
import accountServices from "../supply-chain/services/commonService";
import Modal from "react-bootstrap/Modal";
//import DisplayTemplates from "../../viewTemplates/displayTemplates";
import LoadingSpinner from "../common/loaders/loadingSpinner";
import { TextInput, Picker, GeneralIconButton } from "../common/inputs";
import moment from "moment";
import { toast } from "react-toastify";
import { FormRow } from "../common/containers/formContainer";

export default function PaymentRemainderMailList() {
  const templatesType = [
    { label: "SCF Payment Due Reminder", value: "SCF_PAYMENT_REMINDER" },
    {
      label: "SCF Payment Overdue Remainder",
      value: "SCF_PAYMENT_REMINDER_OVERDUE",
    },
    { label: "TF Payment Due Reminder", value: "TF_PAYMENT_REMINDER" },
    {
      label: "TF Payment Overdue Remainder",
      value: "TF_PAYMENT_REMINDER_OVERDUE",
    },
  ];
  const [selectedType, setSelectedType] = useState(templatesType[0]);
  const [malShedulerList, setMalShedulerList] = useState(null);
  const [mailStatus, setMailStatus] = useState({
    label: "Waiting to Sent",
    value: "pending",
  });
  const [businessType, setBusinessType] = useState({
    label: "All",
    value: "all",
  });
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedMailList, setSelectedMailList] = useState([]);
  const [sndBtnDisabled, setSndBtnDisabled] = useState(true);
  const [testMail, setTestMail] = useState(false);
  const [testClientMail, setTestClientMail] = useState("");

  const FetchMailData = useApi(accountServices.fetchMailSchedulerData);
  const sendBulkMailApi = useApi(accountServices.sendBulkMail);
  const sendMailApi = useApi(accountServices.sendMail);

  const getMailSchedulerList = async (selectedType) => {
    const subject = selectedType.label.replace(/SCF\s*|TF\s*/gi, "");
    const selectedTypVal = selectedType.value.replace("_OVERDUE", "");
    let recipientList = [];
    const response = await FetchMailData.request({
      selectedType: selectedTypVal,
      subject: subject,
      mailStatus: mailStatus.value,
      businessType: businessType.value,
      recipient: [{ 0: "Customer" }],
    }); // Sending Async request to server
    if (response.data) {
      setMalShedulerList(response.data);
      //setIsList(true)
    }
  };

  const sendMail = async () => {
    const response = await sendBulkMailApi.request({
      selectedList: selectedMailList,
    });
    if (response) {
      if (response.data.success) {
        toast.success("Mail Send successfully");
        getMailSchedulerList(selectedType);
      } else {
        response.data.errors.length > 0 &&
          response.data.errors.map((itm) => {
            toast.error(itm);
          });
      }
    } else {
      toast.error("Failed to send mail");
    }
  };

  const mailConfirmation = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Confirm to send email</h4>
            <p>
              Are you sure you want to send this mail to the following email
              address?
            </p>
            <p>{selectedItem.email_to}</p>
            <button
              className="btn btn-white ml-2"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                onClose();
                sendSingleMail(false);
              }}
            >
              Yes
            </button>
          </div>
        );
      },
      title: "Confirm to Send Email",
      message: "Are you sure want to continue?",
    });
  };

  const sendSingleMail = async (testmail) => {
    const response = await sendMailApi.request({
      guid: selectedItem.guid,
      receiverEmail: testmail == true ? testClientMail : selectedItem.email_to,
      canUpdateStatus: true,
      testmail: testmail == true ? true : false,
    });
    if (response) {
      if (response.data.success) {
        toast.success("Mail Send successfully");
        getMailSchedulerList(selectedType);
        setModal(false);
        setSelectedItem(null);
        if (testmail == true) {
          setTestClientMail("");
        }
      } else {
        response.data.errors.length > 0 &&
          response.data.errors.map((itm) => {
            toast.error(itm);
          });
      }
    } else {
      toast.error("Failed to send mail");
    }
  };

  const handleSelection = (e) => {
    var updatedList = [...selectedMailList];
    if (e.target.checked) {
      updatedList = [...selectedMailList, e.target.value];
    } else {
      updatedList.splice(selectedMailList.indexOf(e.target.value), 1);
    }
    if (updatedList.length > 0) {
      setSndBtnDisabled(false);
    } else {
      setSndBtnDisabled(true);
    }
    setSelectedMailList(updatedList);
  };

  useEffect(() => {
    getMailSchedulerList(selectedType);
  }, [selectedType, mailStatus, businessType]);
  return (
    <>
      <>
        <div>
          {(sendMailApi.loading == true ||
            sendBulkMailApi.loading == true ||
            FetchMailData.loading) && <LoadingSpinner />}
          <form className="form-horizontal">
            <div className="row ">
              <div className="col-lg-2">
                <div className="form-group">
                  <label>Action</label>
                  <div>
                    <Picker
                      items={templatesType}
                      selectedItem={selectedType}
                      onSelectItem={(option) => setSelectedType(option)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label>Status</label>
                  <div>
                    <Picker
                      items={[
                        { label: "Waiting to Sent", value: "pending" },
                        { label: "Sent", value: "sent" },
                      ]}
                      selectedItem={mailStatus}
                      onSelectItem={(option) => setMailStatus(option)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label>Business Type</label>
                  <div>
                    <Picker
                      items={[
                        { label: "All", value: "all" },
                        { label: "Network", value: "network" },
                        { label: "Corporate", value: "corporate" },
                      ]}
                      selectedItem={businessType}
                      onSelectItem={(option) => setBusinessType(option)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6"></div>
            </div>
          </form>
        </div>
        <div  >
          <div  >
            <h6 className="mt-2">Mail Scheduler List</h6>
            <div className="table-responsive my-3 overflow-visible">

              {(malShedulerList &&
                malShedulerList.length > 0) ? (
                  <table class="table table-sm text-secondary">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Recipient</th>
                        <th> To </th>
                        <th> Subject </th>
                        <th> Status </th>
                        <th> Created Date </th>
                        <th> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        malShedulerList.map((item, index) => (
                          <>
                            <tr>
                              <td>
                                {item.status == "pending" && (
                                  <>
                                    <div className="custom-control checked d-flex custom-checkbox small">
                                      <input
                                        id={"mail_schdl_chkbx_" + index}
                                        className="custom-control-input"
                                        onClick={(e) => handleSelection(e)}
                                        value={item.guid}
                                        // checked={selectedMailList.indexOf(item.guid) > -1}

                                        type="checkbox"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"mail_schdl_chkbx_" + index}
                                      />
                                    </div>
                                  </>
                                )}
                              </td>
                              <th>{item.recipient}</th>
                              <td>{item.email_to}</td>
                              <td>{item.subject}</td>
                              <td>{item.status}</td>
                              <td>{item.created_at}</td>
                              <td>
                                <GeneralIconButton
                                  name={`View Email`}
                                  className={`btn btn-outline-secondary`}
                                  onClick={(e) => {
                                    setModal(true);
                                    setSelectedItem(item);
                                  }}
                                ></GeneralIconButton>
                              </td>
                            </tr>
                          </>
                        ))
                      }
                    </tbody>
                  </table>
                ) : <center>{FetchMailData.loading ? 'Loading...' : 'No Data Found'}</center>
              }

            </div>

            {/* <div className="row">
              <div className="col-sm-12">
                <button
                  className="btn btn-primary float-right"
                  disabled={sndBtnDisabled}
                  onClick={() => {
                    sendMail();
                  }}
                >
                  end mail
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <div class="clearfix"></div>
      </>
      <>
        <Modal show={modal} onHide={(e) => setModal(false)} size="lg">
          <Modal.Header closeButton>
            <h4> {selectedItem && selectedItem.subject} </h4>
          </Modal.Header>
          <Modal.Body>
            <>
              {selectedItem && (
                <div
                  dangerouslySetInnerHTML={{ __html: selectedItem.content }}
                />
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div className="col-sm-12">
                <button
                  className="btn btn-primary float-right"
                  onClick={() => {
                    mailConfirmation();
                  }}
                >
                  Email client
                </button>
                <button
                  className="btn btn-secondary float-right mr-2"
                  onClick={() => {
                    setTestMail(true);
                  }}
                >
                  Send test mail
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal show={testMail} onHide={(e) => setTestMail(false)} size="lg">
          <Modal.Body>
            <div className="b-dashed">
              <p style={{ textAlign: "center" }}>
                <h4>Confirm to send Email </h4>
              </p>
              <p style={{ textAlign: "center" }}>
                Are you sure you want to send this mail?
              </p>
              <p style={{ textAlign: "center" }}>
                Enter Email Address:
                <div className="row">
                  <div className="col-lg-4"></div>
                  <div className="col-lg-4">
                    <TextInput
                      name="email_to"
                      type="vertical"
                      value={testClientMail}
                      onChange={(e) => setTestClientMail(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-4"></div>
                </div>
              </p>
              <div className="mb-4" style={{ textAlign: "center" }}>
                <button
                  className="btn btn-white ml-2"
                  onClick={() => {
                    setTestMail(false);
                    setTestClientMail("");
                  }}
                >
                  No
                </button>
                <button
                  className="btn btn-primary ml-2"
                  disabled={!testClientMail}
                  onClick={() => {
                    setTestMail(false);
                    sendSingleMail(true);
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
