import React, { Component } from "react";
import LoanList from "./loanList";
import EntityContext from "../../../../context/entityContext";
import NotAuthorized from "../../../common/widgets/notAuthorized";

class LoanIndex extends Component {


  static contextType = EntityContext;
  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    authorization:false
  };

  componentDidMount() {
  	if(this.context.entityDetails && this.context.entityDetails !== 'undefined'){
  		let flag = false;
  		this.context.entityDetails.products.map((item) => {
            if(item.product_name == 'Loan Transactions' && item.isChecked === true){
              flag = true;
            }
        });
        this.setState({authorization:flag});
  	}
  }

	render() {
     	// const { constants } = auth.getCurrentUser();
    	return (
    		<>
    		{
    			this.state.authorization ? (
    				<LoanList />
    			):(
    				<NotAuthorized />
    			)
    			
    		}
    			
    		</>
    	);
  	}
}
export default LoanIndex;
