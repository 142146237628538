import React, { useState, useContext, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Table from '../../components/table/';
import transaction_services from "../../../supply-chain/services/transaction_service";
import EntityContext from "../../../../context/entityContext";
import entityService from "../../../../services/entityService";
import useApi from '../../../../hooks/useApi';
import DraftAccounts from "../network/draftAccounts";
import authService from "../../../../services/authService";
import { Link } from "react-router";

function MappedUsers(props) {
  const tableRef = useRef();
  const isFirstRender = useRef(true);
  const { selectedBusinessType, entityDetails, terminology } = useContext(EntityContext);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const getRolesApi = useApi(entityService.getRoles);

  const { constants, } = authService.getCurrentUser();

  const cols = [
    {
      Header: "Organisation",
      accessor: "organization",
      headerStyle: { textAlign: "left" },
      minWidth: 110

    },
    {
      Header: "Name",
      accessor: "name",
      headerStyle: { textAlign: "left" },
      minWidth: 110

    },
    {
      Header: "ABN",
      accessor: "ab_number",
      headerStyle: { textAlign: "left" },
      minWidth: 110

    },
    {
      Header: "Email",
      accessor: "email",
      headerStyle: { textAlign: "left" },
      minWidth: 110

    },
    {
      Header: "Phone Number",
      accessor: "phone_no",
      headerStyle: { textAlign: "left" },
      minWidth: 110

    },
    {
      Header: "Status",
      accessor: "status",
      headerStyle: { textAlign: "left" },
      minWidth: 110,
      Cell: row => <span style={{ color: row.original.status == 'active' ? "green" : "red" }} > {row.original.status == 'active' ? "Active" : "Inactive"}</span>
    },

  ];
  //------------------Async function to validate the suppliers------------------------
  const getTabs = async () => {
    const { data } = await getRolesApi.request({ selectedBusinessType });
    setTabs(data.tabs); setSelectedTab(data.selectedTab);
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false // toggle flag after first render/mounting
      getTabs();
      return;
    } else {
      tableRef.current.reloadData();
    }
  }, [selectedTab]);

  const handleReadloadChild = () => {
    tableRef.current.reloadData();
  }
  return (
    <>
      <Helmet>
        <title>Linked Users | {global.config.title}</title>
      </Helmet>

      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4"><h6>Linked {terminology && terminology.SUPPLIER}s</h6></div>
            <div className="col-lg-4 col-md-4 col-sm-4">

              <div className="dropdown dash-dropdown">
                {/* <button className="btn btn-secondary dropdown-toggle btn-sm pr-sm-4 pr-3 pl-lg-2 w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {tabs.map(obj => <>{selectedTab == obj.value ? obj.label : ""}</>)}
                </button> */}

                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {tabs.map(obj => <a onClick={e => setSelectedTab(obj.value)} className={`dropdown-item ${selectedTab == obj.value ? 'active' : ''}`} href="#" >{obj.label}</a>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4">
              <Link className="btn btn-light pull-right text-secondary ml-2" to={'/client/scf/dashboard'}><i alt="Scf Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
            </div>
          </div>
          <span className="float-right p-0 mb-2"></span>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              {selectedBusinessType != null &&
                <Table
                  service={transaction_services.getMappedUsers}
                  cols={cols}
                  reqParams={
                    { 
                      roleType: selectedBusinessType, 
                      entity_guid: entityDetails.a_guid, 
                      selectedTab,
                      selectedProduct:constants.PRODUCTS.SUPPLYCHAIN 
                    }
                  }
                  ibox={true}
                  ref={tableRef}
                // headerTabs={true}
                // setTabs={setTabs}
                // defaultTab={selectedTab}
                />
              }
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card ">
            <div className="card-body">
              <DraftAccounts
                type={selectedTab == constants.ROLES_IDS.SUPPLIER ? 'supplier' : 'customer'}
                selectedProduct={{
                  id: constants.PRODUCTS.SUPPLYCHAIN,
                }}
                reloadChild={handleReadloadChild}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MappedUsers;