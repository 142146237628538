import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import Modal from 'react-bootstrap/Modal';

import ContactInvitation from "../../entities/_contactInvitation";
import { getInitials } from "../../../utils/NameInitials";
import { getDateDiff } from "../../../utils/GetDateDifference";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

import entityService from "../../../services/entityService";

function WidgetTeamMembers({ type, data, fetchTeamMembers, clientId = null }) {
  const [selectedContact, setSelectedContact] = useState(null);
  const [invitationModal, setInvitationModal] = useState(false);
  const [colors, setColors] = useState([]);

  const handleCloseInvitationModal = () => {
    setInvitationModal(false);
    setSelectedContact(null);
    fetchTeamMembers();
  };

  const handleOpenInvitationModal = (obj) => {
    setSelectedContact(obj);
    setInvitationModal(true);
  };

  const colorCodes = [
    /* "#3C3897",
     "#8E44AD",
     "#E74C3C",
     "#D4AC0D",
     "#229954",
     "#DC7633",
     "#95A5A6",
     "#D98880",
     "#CCCCFF",
     "#FFBF00",
     "#9FE2BF",
     "#40E0D0",*/
    "#16C79A",
    "#19456B",
  ];
  let randomColors = [];

  useEffect(() => {
    data.map(() => {
      const randomColor = Math.floor(Math.random() * colorCodes.length);
      randomColors.push({ color: colorCodes[randomColor] });
    });
    console.log(data);
    setColors(randomColors);
  }, [data]);

  const deactivateAction = async (Item) => {
    try {
      const { success, message } = await entityService.deactivateEntityContact(
        Item
      );
      if (success === false) {
        toast.error(message);
      } else {
        toast.success(message);
        fetchTeamMembers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle deletion for a contact
  const handleDeactivateContact = (Item) => {
    confirmAlert({

      title: "Confirm to delete",
      message: "Are you sure to delete this contact ?",
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Are you sure to delete this contact ?</h4>
            <button className="btn btn-white" onClick={() => { onClose(); }}>No</button>
            <button
              className="btn btn-primary ml-2"
              onClick={async () => {
                onClose();
                deactivateAction(Item);
              }}
            >
              Yes

            </button>
          </div >
        );
      },

    });
  };

  return (
    <>
      {data.map((obj, index) => {
        let lastLogin = getDateDiff(obj.lastLogin);

        return (
          <>
            {/** -------------------------------- Client Invitation Modal -------------------------------- **/}
            <Modal
              show={invitationModal}
              onHide={handleCloseInvitationModal}
              centered
              size="lg"
            >
              <ContactInvitation
                entityId={clientId}
                entityType={"client"} // bp or client
                contactInfo={selectedContact}
                onClose={handleCloseInvitationModal}
              />
            </Modal>
            {/** ------------------------------------------------------------------------------------------ **/}

            <div title={`${obj.status == "inactive" ? "Deleted team member" : "Active team member"
              }`} key={index} className={`${obj.status == "inactive" ? "member-list bg-danger-transparent" : "member-list"
                }`} >
              <div className="row"

              >
                <div className="col-md-1">
                  <div
                    style={{
                      background: `${colors.length > 0 && colors[index]
                        ? colors[index].color
                        : null
                        }`,
                    }}
                    className={`rounded-label-name`}
                  >
                    {getInitials(obj.first_name, obj.last_name)}
                  </div>
                </div>

                <div className="col-md-8">
                  <a href="#" className="team-item-title">
                    {obj.first_name} {obj.last_name}
                    <span className="team-memeber-email">{obj.email}</span>
                  </a>
                  {/*obj.pendingVerification == "unverified" ? (
                    <span className="badge badge-warning ml-2">
                      Pending Sign In
                    </span>
                  ) : (
                    ""
                  )*/}
                  <div className="designation mt-1">
                    <i className="fa fa-briefcase" /> Designation:{" "}
                    {obj.designation !== null ? obj.designation : ""}
                  </div>
                </div>

                <div className="col-md-2 forum-info">
                  <div className="mt-3">
                    {lastLogin !== null && (
                      <small>
                        Logged in{" "}
                        {lastLogin === 0 ? `Today` : `${lastLogin} days ago`}
                      </small>
                    )}
                  </div>
                </div>
                {obj.status == "active" && (
                  <div className="col-md-1 forum-info">
                    {type == 0 && (
                      <div className="mt-3">
                        {(obj.pendingVerification === null || !obj.is_primary) && (
                          <a
                            type="button"
                            title="More Information and Options"
                            id="dropdownMenu"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fa fa-ellipsis-v" />
                          </a>
                        )}
                        {/*Menu*/}
                        {(obj.pendingVerification === null || !obj.is_primary) && (
                          <div
                            className="dropdown-menu dropdown-primary"
                            id="dropdownMenu"
                          >
                            {obj.pendingVerification === null && (
                              <Link
                                onClick={() => handleOpenInvitationModal(obj)}
                                className="dropdown-item"
                                href="#"
                              >
                                <i className="fab fa-apple-pay" />
                                &nbsp;&nbsp;Send Invitation
                              </Link>
                            )}
                            {/* <a className="dropdown-item" href="#">
                            &nbsp;Change Designation
                          </a> */}
                            {!obj.is_primary && (
                              <a
                                className="dropdown-item text-danger"
                                onClick={() =>
                                  handleDeactivateContact(obj.contact_id)
                                }
                                href="#"
                              >
                                &nbsp;
                                {`Delete ${obj.first_name} ${obj.last_name}`}
                              </a>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default WidgetTeamMembers;
