import React, { useContext } from "react";
import { SecondaryCard } from "../../components/cards";
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";
import RoleChecker from "../../../../utils/roleChecker";

function AwaitApproval({ data, loading, setProductRoleType, col, flip, noCard = false, flipCardCount, flipCardCountTooltip = '' }) {

    const { constants } = authService.getCurrentUser();
    const { entityDetails } = useContext(EntityContext);
    return (
        <>
            { ( RoleChecker.check(entityDetails.products, constants.ROLES.CUSTOMER) || RoleChecker.check(entityDetails.products, constants.ROLES.TFCUSTOMER) || 
            RoleChecker.check(entityDetails.products, constants.ROLES.SUPPLIER) || RoleChecker.check(entityDetails.products, constants.ROLES.TFSUPPLIER) ) && (
                <SecondaryCard
                    loading={loading}
                    data={data}
                    setProductRoleType={setProductRoleType}
                    flipCardCount ={flipCardCount}
                    flipCardCountTooltip={flipCardCountTooltip}
                    col={col ? col : 4}
                    flip={flip ? flip : false}
                    noCard={noCard}
                />
            )}
        </>
    )
}
export default AwaitApproval;