import React from "react";

const NavigationButtons = ({ goNext, goPrevious, selectedIndex, list, proceedNext, hideNextBtn, hidePreviousBtn, nextLabel, prevLabel }) => (
    <>
        {hidePreviousBtn === false && selectedIndex != list.length - 1 &&

            <button disabled={selectedIndex == 0} onClick={goPrevious} className="btn btn-light mr-2 ml-2">
                {prevLabel}
            </button>
        }
        {hideNextBtn === false && selectedIndex != list.length - 1 &&
            <button onClick={goNext} disabled={!proceedNext} id="btn-next" className="btn btn-primary ml-2">
                {selectedIndex != list.length - 1 ? (<>{nextLabel}</>) : "Submit"}
            </button>
        }
    </>
);
export {
    NavigationButtons,
};
