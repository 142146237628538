import jwtDecode from "jwt-decode";
import http from "./httpService";
import { apiUrl } from "../../src/environment";

let accessToken = "";

function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  };
}

export async function login(username, password, entity = null,deviceDetail=null) {
  const { data } = await http.post(
    apiUrl + "/core/auth/login?XDEBUG_SESSION_START=PHPSTORM",
    {
      username,
      password,
      entity,
      deviceDetail
    }
  );

  if (data.success === true) {
    localStorage.setItem("profile_picture", data.profile_picture);
    localStorage.setItem("fpayuser", JSON.stringify(data.user));
  }

  return data;
}

export async function loginByToken(entity, setToken = true) {
  updateHeader();

  let url = apiUrl + "/core/auth/login-by-token";
  let postObject = { entity };

  const { data } = await http.post(url, postObject, accessToken);

  if (data.success === true && setToken) {
    localStorage.setItem("profile_picture", data.profile_picture);
    localStorage.setItem("fpayuser", JSON.stringify(data.user));
  }

  return data;
}

export async function loginWithOtp(username, password, otp = null,deviceDetail=null) {
  const { data } = await http.post(
    apiUrl + "/core/auth/login-with-otp?XDEBUG_SESSION_START=PHPSTORM",
    {
      username,
      password,
      otp,
      deviceDetail
    }
  );

  if (data.success === true) {
    localStorage.setItem("profile_picture", data.profile_picture);
    localStorage.setItem("fpayuser", JSON.stringify(data.user));
  }

  return data;
}


export async function resentOtp(username,log_type) {
  const { data } = await http.post(
    apiUrl + "/core/auth/resend-otp?XDEBUG_SESSION_START=PHPSTORM",
    {
      username,
      log_type
    }
  );

  return data;
}

export async function sendPasswordResetInstructions(email, client = false) {
  const { data } = await http.post(
    apiUrl + "/core/auth/send-reset-password-instructions",
    {
      email,
      client
    }
  );

  return data;
}

export async function sendVerifyPasswordResetOtp(email,type,deviceDetails,otp = null) {
  const { data } = await http.post(
    apiUrl + "/core/auth/send-verify-otp",
    {
      email,
      type,
      deviceDetails,
      otp
    }
  );

  return data;
}

export async function logout() {
  localStorage.removeItem("profile_picture");
  localStorage.removeItem("fpayuser");

  await http.post(apiUrl + "/core/auth/logout", { });
}

export function getCurrentUser() {
  const user = localStorage.getItem('fpayuser');
  return (user && user !== 'undefined') ? JSON.parse(user) : null;
}


export function getUserProfilePicture() {
  try {
    const picture = localStorage.getItem("profile_picture");
    return picture;
  } catch (ex) {
    return null;
  }
}

export async function verifyResetPasswordToken(token) {
  const { data } = await http.post(
    apiUrl + "/core/auth/verify-token?XDEBUG_SESSION_START=PHPSTORM",
    {
      token
    }
  );

  return data;
}

export async function submitNewPassword(token, password) {
  const { data } = await http.post(apiUrl + "/core/auth/reset-password", {
    token,
    password
  });
  return data;
}

export async function resetOldPassword(oldpassword = null , newpassword) {
  updateHeader();
  const { data } = await http.post(
    apiUrl + "/core/auth/reset-old-password",
    {
      oldpassword,
      newpassword
    },
    accessToken
  );
  return data;
}

export function uploadProfilePicture(formdata, fileName) {
  updateHeader();
  const data = http.post(
    apiUrl + "/core/auth/upload-profile-picture?XDEBUG_SESSION_START=PHPSTORM",
    { formdata, fileName },
    accessToken
  );
  return data;
}

  export function tokenExists(){

    let user = getCurrentUser();

    if(!user) // verify token exists
      return false;
    else if (user.exp * 1000 < Date.now()) // verify token expired
      return false;

    return true;
  }

  export function validateJWTToken() {
    
    let user = getCurrentUser();

    if(!user) // verify token exists
      return false;
    else if (user.exp * 1000 < Date.now() || user.category !== "Traders") // verify token expired
      return false;

    return true;

  }
  export async function emailNotificationSettings(contact_id, notyVal = false) {
    const { data } = await http.post(
      apiUrl + "/core/auth/email-notification-settings",
      {
        contact_id,
        notyVal
      }
    );
  
    return data;
  }

export default {
  login,
  loginByToken,
  logout,
  getCurrentUser,
  sendPasswordResetInstructions,
  verifyResetPasswordToken,
  submitNewPassword,
  uploadProfilePicture,
  getUserProfilePicture,
  resetOldPassword,
  tokenExists,
  loginWithOtp,
  sendVerifyPasswordResetOtp,
  resentOtp,
  validateJWTToken,
  emailNotificationSettings
};
