import React from "react";

const NavigationButtonsApproval = ({
  goNext,
  goPrevious,
  goBack,
  selectedIndex,
  list,
  noSteps,
  proceedNext,
  approval
}) => (
  <>
  
{ selectedIndex > 0 && (
    <button
      onClick={selectedIndex == 0 ? goBack : goPrevious}
      className="btn btn-light mr-2"
    >
      { selectedIndex == 0 ? "Close":"Back"}
    </button>
)} 
    { ( selectedIndex != noSteps - 1 && approval == "yes" ) && (
      <button onClick={goNext} disabled={!proceedNext} className="btn btn-primary ml-2">
        {selectedIndex != list.length - 1 ? "Submit" : "Submit"}
      </button>
    )}
  </>
);
export { NavigationButtonsApproval };
