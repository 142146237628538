import React, { useEffect, useContext, useState } from "react";
import LoanList from "../../pages/loanTransaction/loanList";

function LoanListView() {


    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h6 className="mt-2 mb-4   ">Loan Summary</h6>
                    <LoanList
                    />
                </div>
            </div>

        </>
    )
}
export default LoanListView;