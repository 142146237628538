import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import {
    TextInput,
    //CurrencyLabel,
    //CurrencyText,
    GeneralButton
} from "../../../../../common/inputs";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../../../common/loaders/loadingSpinner";
import { Link } from "office-ui-fabric-react";
import commonService from "../../../../services/commonService";
import authService from "../../../../../../services/authService";
import IntroducersList from "../../../../../slplusTools/introducersList";
import CountriesList from "../../../../../slplusTools/CountriesList";
import { confirmAlert } from "react-confirm-alert";
import { Picker } from "../../../../../common/inputs";
import IntroducersMembersList from "../../../../../slplusTools/IntroducersMembersList";

class SharedIntroducers extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        loading: false,
        data: {
            country: null,
            introducer: null,
            commision: "",
            guid: ""
        },
        sharedIntroducersList: [],
        update: false,
        selectedType:[{}],
        AmountselectedType:[{}]
    }

    componentDidMount = () => {
        this.fetchSharedIntroducers();
    }

    fetchSharedIntroducers = async () => {
        this.setState({ loading: true });
        const { data } = await commonService.getSharedIntroducersList({
            fee_guid: this.props.feeGuid,
            product: this.props.product,
            intro_s: this.props.intro_s
        });
        console.log("data",data)
        this.setState({ sharedIntroducersList: data.response, loading: false });
    }

    changeData = (field, selectedOption) => {
        const data = { ...this.state.data };
        data[field] = selectedOption;
        this.setState({ data });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        const data = { ...this.state.data };
        data[name] = value;
        this.setState({ data });
    }

    addCommision = async () => {
        this.setState({ loading: true });
        const { data } = await commonService.AddSharedIntroducers({
            ...this.state.data,
            fee_guid: this.props.feeGuid,
            product: this.props.product,
            commision_type: this.state.selectedType.value,
            calculation_based_on: this.state.AmountselectedType.value,
            intro_s: this.props.intro_s,
            trans_guid:this.props.trans_guid
        });
        this.setState({ loading: false })
        if (data.success == true) {
            toast.success(data.message);
            this.setState({
                data: {
                    country: null,
                    introducer: null,
                    commision: "",
                    guid: ""
                },
                update: false
            });
            this.fetchSharedIntroducers();
            if(this.props.introType === 'SCF')
            {
               this.props.reloadSharedCount();
            }
        } else {
            toast.error(data.message);
        }
    }

    EditIntroducer = (introducer) => {
        this.setState({
            data: {
                introducer: introducer.introducer,
                commision: introducer.commision,
                guid: introducer.guid,
                id: introducer.id
            },
            update: true
        });
    }

    handleDeleteIntroducer = (introducer) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h4>Confirm to Delete</h4>
                        <p>Are you sure want to delete?</p>
                        <button
                            className="btn btn-white ml-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary ml-2"
                            onClick={() => { onClose(); this.deleteIntroducer(introducer) }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            title: "Confirm to Delete",
            message: "Are you sure want to Delete?",
        });
    }

    deleteIntroducer = async (introducer) => {
        this.setState({ loading: true });
        const { data } = await commonService.deleteIntroducers({
            guid: introducer.guid,
            id: introducer.id,
            intro_s: this.props.intro_s,
        });
        this.setState({ loading: false })
        if (data.success == true) {
            toast.success(data.message);
            this.fetchSharedIntroducers();
            if(this.props.introType === 'SCF')
            {
            this.props.reloadSharedCount();
            }
        } else {
            toast.error(data.message);
        }
    }

    render() {

        const { category } = authService.getCurrentUser();
       
        return (
            <>
                {this.state.loading && <LoadingSpinner />}
                {this.props.introType === 'SCF' &&( 
                    <Modal.Header closeButton>
                    <h4 className="modal-title">
                        { `Introducer(s) Details`}
                    </h4>
                </Modal.Header>
                )}
                
                <Modal.Body>
                    {this.props.introType === 'SCF' && (

                    <div className="row">
                        {
                            category == "International Office" && (

                                <div className="col-sm-12">
                                    <CountriesList
                                        label="Country"
                                        type="vertical"
                                        selectedCountry={this.state.data.country}
                                        onSelectItem={({ id }) => { this.changeData('country', id); }}
                                    />
                                </div>

                            )
                        } 
                        <div className="col-sm-4">
                            {/* <IntroducersList
                                addNew={false}
                                selectedIntroducer={this.state.data.introducer}
                                type="vertical"
                                country={this.state.data.country}
                                onSelectItem={({ value }) => { this.changeData('introducer', value); }}
                                label="Introducer"
                            /> */}
                            <IntroducersMembersList
                                addNew={false}
                                selectedIntroducer={this.state.data.introducer}
                                type="vertical"
                                country={this.state.data.country}
                                onSelectItem={({ value }) => { this.changeData('introducer', value); }}
                                label="Introducer"
                            />

                        </div>
                        <div className="col-sm-3">

                            <TextInput
                                name="commision"
                                value={this.state.data.commision}
                                label="Commission"
                                onChange={(e) => this.handleChange(e)}
                                type="vertical"
                            ></TextInput>

                        </div>
                        <div className="col-sm-3">
                            <label>Type</label>
                            <Picker
                                items={[
                                { label: "Percentage per year", value: "Percentage per year" },
                                { label: "Percentage of daily fee", value: "Percentage of daily fee" },
                                { label: "Fixed Fee", value: "Fixed Fee" },
                                { label: "Percentage of fixed fee", value: "Percentage of fixed fee" }
                                ]}
                                selectedItem={this.state.selectedType}
                                onSelectItem={(option) => {
                                    this.setState({ selectedType: option })
                                    }}
                            />

                        </div>
                        <div className="col-sm-2 pt-2">
                            
                            <GeneralButton
                                onClick={() => this.addCommision()}
                                className="btn btn-primary mt-4 pull-right"
                                title="Create a new shared Introducer from here"
                                name={this.state.update == true ? "Update" : "Add"}
                                type={"button"}
                            />
                        </div>
                    </div>
                    )}

                    {this.props.introType === 'IF' && (
                        <div className="row">
                        {
                            category == "International Office" && (

                                <div className="col-sm-12">
                                    <CountriesList
                                        label="Country"
                                        type="vertical"
                                        selectedCountry={this.state.data.country}
                                        onSelectItem={({ id }) => { this.changeData('country', id); }}
                                    />
                                </div>

                            )
                        } 
                        <div className="col-sm-3">
                            {/* <IntroducersList
                                addNew={false}
                                selectedIntroducer={this.state.data.introducer}
                                type="vertical"
                                country={this.state.data.country}
                                onSelectItem={({ value }) => { this.changeData('introducer', value); }}
                                label="Introducer"
                            /> */}
                            <IntroducersMembersList
                                addNew={false}
                                selectedIntroducer={this.state.data.introducer}
                                type="vertical"
                                country={this.state.data.country}
                                onSelectItem={({ value }) => { this.changeData('introducer', value); }}
                                label="Introducer"
                            />

                        </div>
                        <div className="col-sm-2">

                            <TextInput
                                name="commision"
                                value={this.state.data.commision}
                                label="Commission"
                                onChange={(e) => this.handleChange(e)}
                                type="vertical"
                            ></TextInput>

                        </div>
                        <div className="col-sm-3">
                            <label>Type</label>
                            <Picker
                                items={[
                                { label: "Percentage per year", value: "Percentage per year" },
                                { label: "Percentage of daily fee", value: "Percentage of daily fee" },
                                { label: "Fixed Fee", value: "Fixed Fee" },
                                { label: "Percentage of fixed fee", value: "Percentage of fixed fee" }
                                ]}
                                selectedItem={this.state.selectedType}
                                onSelectItem={(option) => {
                                    this.setState({ selectedType: option })
                                    }}
                            />

                        </div>

                        <div className="col-sm-2">
                            <label>Calculation </label>
                            <Picker
                                items={[
                                { label: "Advance amount", value: "Advance amount" },
                                { label: "Invoice amount", value: "Invoice amount" },
                                ]}
                                selectedItem={this.state.AmountselectedType ? this.state.AmountselectedType : [{ label: "Invoice amount", value: "Invoice amount" }] }
                                onSelectItem={(option) => {
                                    this.setState({ AmountselectedType: option })
                                    }}
                            />
                        </div>
                        <div className="col-sm-2 pt-2">
                            
                            <GeneralButton
                                onClick={() => this.addCommision()}
                                className="btn btn-primary mt-4 pull-right"
                                title="Create a new shared Introducer from here"
                                name={this.state.update == true ? "Update" : "Add"}
                                type={"button"}
                            />
                        </div>
                    </div>
                    )}

                    {
                        this.state.sharedIntroducersList && this.state.sharedIntroducersList.length > 0 && (
                            <>
                                <table className="table table-sm text-secondary mt-3">
                                    <thead>
                                        <tr>
                                            <th>SL No</th>
                                            <th>Introducer</th>
                                            <th>Commision</th>
                                            <th>Commision Type</th>
                                            {this.props.introType === 'IF' && (
                                                <th>Calculation Based On</th>
                                            )}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.sharedIntroducersList.map((introducers, index) => (
                                                <>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{introducers.first_name + introducers.last_name +"("+introducers.organization+")"}</td>
                                                        <td>
                                                            {introducers.commision} %
                                                        </td>
                                                        <td>{introducers.commision_type}</td>
                                                        {this.props.introType === 'IF' && (
                                                            <td>{introducers.calculation_based_on}</td>
                                                        )}
                                                        <td>
                                                            <Link title="Edit"
                                                                className="cursor-link text-secondary mr-2"
                                                                onClick={(e) => { this.EditIntroducer(introducers) }}
                                                            >
                                                                <i className={`fa fa-edit`}></i>
                                                            </Link>

                                                            <Link title="Delete"
                                                                className="cursor-link text-secondary"
                                                                onClick={(e) => { this.handleDeleteIntroducer(introducers) }}
                                                            >
                                                                <i className={`fa fa-trash`}></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </>
                        )
                    }
                    {
                        this.state.sharedIntroducersList.length == 0 && (
                            <p className="text-danger">No shared Introducers</p>
                        )
                    }
                </Modal.Body>
            </>
        )
    }
}
export default SharedIntroducers;