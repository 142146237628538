import client from "./serviceClient";

const getClientsList = (params, status) => client.post(`/accountant/accountant/list-clients`, { ...params, status });
const saveClient = values => client.post(`/accountant/accountant/create-client`, { values });
const getClientDetails = a_guid => client.post(`/accountant/accountant/get-client-details`, { a_guid });
const bulkUpload = values => client.post(`/accountant/accountant/bulk-client-upload`, { values });
const getClients = () => client.post(`/accountant/accountant/get-clients`);

export default {
    getClientsList,
    saveClient,
    getClientDetails,
    bulkUpload,
    getClients
};