import React from "react";
import EntityContacts from '../../entities/entityContacts';

import authService from "../../../services/authService";

function ClientHome() {

    const { entity_id } = authService.getCurrentUser();

    return (
        <>
            <div className="card overflow-visible">
                <EntityContacts entity={entity_id} type='client'></EntityContacts>
            </div>


        </>
    )
}

export default ClientHome;