import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router";
import { FormRow } from "../../../../common/containers/formContainer";
// Importing Widgets
import { ApprovedForPayment, Rejected, AwaitSupplierUpdate, SupplierCustomerManagement, SupplierPaid, AwaitApproval, Rebate } from "../../../widgets/SCWidgets";
import EntityContext from "../../../../../context/entityContext";
import useApi from '../../../../../hooks/useApi';
import authService from "../../../../../services/authService";
import { CurrencyLabel } from "../../../../common/inputs";
import CommonService from "../../../../supply-chain/services/commonService";
import Modal from 'react-bootstrap/Modal';
import CreateScfInvoice from "../../../../supply-chain/components/transactions/supplierUpload";
import CreateTfInvoice from "../../../../supply-chain/components/transactions/supplierUpload/tfAddSupplierInvoice";
import CreateGenaralTransaction from "../../../components/generalTransaction/_createTransaction";
import GeneralProcessingPending from "../../../widgets/_generalProcessingPending";
import { toast } from "react-toastify";

function ScSale(props) {

    const [appData, setAppData] = useState(null); //set data for approved for paymnet or scheduled for payment  invoice card
    const [awSupUpData, setAwSupData] = useState(null); // set data for awaiting supplierapproval  invoice card
    const [awAppData, setAwAppData] = useState(null);
    const [graphData, setGraphData] = useState(null);
    const [apPaidData, setApPaidData] = useState(null);
    const [showGeneralProcessing, setShowGeneralProcessing] = useState(true);

    const { sc_invoice_status, constants } = authService.getCurrentUser();
    const { selectedBusinessType, entityDetails, handleProductChange,
        handleRoleChange, terminology, openInvoiceModal, selProduct, productTypes, entitySettings,salesRoles } = useContext(EntityContext);
    const [rejectedData, setRejectedData] = useState(null); // set data for Rejected invoice card
    const [modal, setModal] = useState(false);
    // const CountApi = useApi(CommonService.getScInvoiceCount);
    const CountApi = useApi(CommonService.getSalesInvoiceCount);
    const [rebateData, setRebateData] = useState(null);
    const [modalType, setModalType] = useState("");

    const fetchData = async () => {

        if (productTypes !== undefined && productTypes.length > 0 &&
            productTypes.filter(obj => (obj.value == constants.PRODUCTS.SUPPLYCHAIN || obj.value == constants.PRODUCTS.TRADEFINANCE)).length > 0) {

            // const { data } = await CountApi.request({ "product_type": constants.PRODUCTS.SUPPLYCHAIN, "userId": entityDetails.a_guid, "selRole": selectedBusinessType });
            const { data } = await CountApi.request({ "userId": entityDetails.a_guid, "productTypes": productTypes });
            setAppData({
                topLabel: "Scheduled For Payment",
                topValue: <CurrencyLabel decimal={2} value={data.inv_apprd_payment_sum} />,
                leftLabel: data.isPrimary ? `${terminology && terminology.SUPPLIER}s` : `${terminology && terminology.CUSTOMER}s`,
                leftValue: data.isPrimary ? (data.inv_apprd_payment_supplierCount ? data.inv_apprd_payment_supplierCount : 0) : (data.inv_apprd_payment_customerCount ? data.inv_apprd_payment_customerCount : 0),
                rightLabel: "Invoices",
                rightValue: data.inv_apprd_payment_count ? data.inv_apprd_payment_count : 0,
                bottomLeftLabel: "Approved",
                bottomRightLabel: "Today's Balance",
                primaryBtnRoute: "/client/scf/sale-invoices",
                status: sc_invoice_status["approved_for_payment"],
                subTitle: "Approved and awaiting payment",
                subIcon: "fa-clock-o"

            })
            setAwAppData({
                topLabel: "Pending Customer Approval",
                topValue: <CurrencyLabel decimal={2} value={data.inv_awt_apprv_sum} />,
                leftLabel: data.isPrimary ? `${terminology && terminology.SUPPLIER}s` : `${terminology && terminology.CUSTOMER}s`,
                leftValue: data.isPrimary ? (data.inv_awt_apprv_supplierCount ? data.inv_awt_apprv_supplierCount : 0) : (data.inv_awt_apprv_customerCount ? data.inv_awt_apprv_customerCount : 0),
                rightLabel: "Invoices",
                rightValue: data.inv_awt_apprv_count ? data.inv_awt_apprv_count : 0,
                bottomLeftLabel: "Review",
                bottomRightLabel: "Today’s Balances",
                primaryBtnRoute: "/client/scf/sale-invoices",
                status: sc_invoice_status["awaiting_approval"],
                subTitle: "Awaiting customer approval"

            })
            setAwSupData({
                topLabel: "Available For Early Payment",
                topValue: <CurrencyLabel decimal={2} value={data.inv_awt_sup_update_sum} />,
                leftLabel: `${terminology && terminology.CUSTOMER}s`,
                leftValue: data.inv_awt_sup_update_customerCount ? data.inv_awt_sup_update_customerCount : 0,
                rightLabel: "Invoices",
                rightValue: data.inv_awt_sup_update_count ? data.inv_awt_sup_update_count : 0,
                bottomLeftLabel: "Review",
                bottomRightLabel: "Today’s Balances",
                primaryBtnRoute: "/client/scf/sale-invoices",
                status: sc_invoice_status["awaiting_supplier_update"],
                subTitle: "Receive early payment on these invoices"
            })
            setRejectedData({
                topLabel: "Rejected",
                topSubLabel: "",
                topValue: <CurrencyLabel decimal={2} value={data.inv_rejected_sum} />,
                leftLabel: data.isPrimary ? `${terminology && terminology.SUPPLIER}s` : `${terminology && terminology.CUSTOMER}s`,
                leftValue: data.isPrimary ? (data.inv_rejected_supplierCount ? data.inv_rejected_supplierCount : 0) : (data.inv_rejected_customerCount ? data.inv_rejected_customerCount : 0),
                rightLabel: "Invoices",
                rightValue: data.inv_rejected_count ? data.inv_rejected_count : 0,
                primaryBtnRoute: "/client/scf/sale-invoices",
                status: sc_invoice_status["rejected"],
                bottomLeftLabel: data.isPrimary ? `${terminology && terminology.SUPPLIER}s` : `${terminology && terminology.CUSTOMER}s`,
                bottomRightLabel: "Invoices",
                subTitle: "Rejected invoices",
                subIcon: "fa-thumbs-down"
            })
            setRebateData({
                topLabel: "Rebate",
                topValue: <CurrencyLabel decimal={2} value={data.rebate} />
            })
            setApPaidData({
                cardTitle: `Amount Paid`,
                centerLabel: "Invoices",
                centerValue: data.inv_approved_paid_count ? data.inv_approved_paid_count : 0,
                rightLabel: "Open",
                rightValue: <CurrencyLabel decimal={2} value={data.inv_approved_paid_sum} />,
                status: "paid",
                subTitle: "Approved and purchased",
                primaryBtnRoute: "/client/scf/sale-invoices",
            });
            setGraphData(data.graphData);
        }
    }

    const getModalType = () => {
        let customerProgram = salesRoles.filter(itm => itm.product == constants.PRODUCTS.CUSTOMERPROGRAMS);
        if(customerProgram.length > 0){
            if(customerProgram[0].roles[constants.PRODUCTS.SUPPLYCHAIN] == true && customerProgram[0].roles[constants.PRODUCTS.TRADEFINANCE] == true ){
                setModalType("common_program");
            } else if(customerProgram[0].roles[constants.PRODUCTS.SUPPLYCHAIN] == true){
                setModalType("supply_chain");
            } else if(customerProgram[0].roles[constants.PRODUCTS.TRADEFINANCE] == true){
                setModalType("trade_finance");
            }
        }
    }

    const setProductRoleType = () => {
        handleProductChange(props.product ? props.product : constants.PRODUCTS.SUPPLYCHAIN);
        handleRoleChange(constants.BUSINESS_TYPE.SALE)
    }
    useEffect(() => {
        fetchData();
        getModalType();
    }, [selectedBusinessType, entityDetails, productTypes]);

    const refreshCard = () => {
        setShowGeneralProcessing(false);
        fetchData();
        setShowGeneralProcessing(true);
    }



    return (
        <>

            {(selectedBusinessType == constants.BUSINESS_TYPE.SALE && productTypes
            ) && (
                    <>
                        <FormRow>
                            <div className="col-md-4 ">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="m-4">
                                            <div className="invoice-round">
                                                <a href="#" onClick={e => { setModal(true) }} >
                                                    <i className="fa fa-plus fa-2x" />
                                                </a>
                                            </div>
                                            <h5 className="mt-3  text-muted  text-center">Add Invoice</h5>
                                        </div>
                                        <div />
                                        <div className="row " /></div>
                                </div>
                            </div>
                            <AwaitSupplierUpdate data={awSupUpData} loading={CountApi.loading} setProductRoleType={setProductRoleType} />
                            <ApprovedForPayment data={appData} loading={CountApi.loading} setProductRoleType={setProductRoleType} />

                        </FormRow>
                        <FormRow>
                            <SupplierPaid product={props.product ? props.product : null}
                                apprvPaidData={apPaidData} graphData={graphData} loading={CountApi.loading} 
                                modalType={modalType}/>
                            <div className="col-md-4 col-sm-4 col-xs-2 col-12">
                                <FormRow>
                                    <AwaitApproval data={awAppData} loading={CountApi.loading} setProductRoleType={setProductRoleType} col={12} />
                                </FormRow>
                                {
                                    entitySettings.show_supplier_rebate_card == true && (
                                        <FormRow>
                                            <Rebate data={rebateData} loading={CountApi.loading} />
                                        </FormRow>
                                    )
                                }
                                <FormRow>
                                    {
                                        showGeneralProcessing == true && (
                                            <GeneralProcessingPending setProductRoleType={setProductRoleType} col={12} />
                                        )
                                    }

                                </FormRow>

                                <FormRow>
                                    <SupplierCustomerManagement />
                                </FormRow>
                                <FormRow>
                                    <Rejected type="sm" data={rejectedData} loading={CountApi.loading} top={true} setProductRoleType={setProductRoleType} />
                                </FormRow>
                            </div>
                        </FormRow>

                        <Modal
                            show={modal}
                            onHide={() => setModal(false)}
                            backdrop="static"
                            centered
                        >
                            {
                                modalType == 'common_program' ? (
                                    <CreateGenaralTransaction
                                        onCloseModal={() => setModal(false)}
                                        selectUserType="secondary_accounts"
                                        dropDownHeading="Select Customer"
                                        initiated_user_type="supplier"
                                        invoice_type="payable"
                                        refreshCard={refreshCard}
                                    />
                                ) : modalType == 'supply_chain' ? (
                                    <CreateScfInvoice
                                        a_guid={entityDetails ? entityDetails.a_guid : ""}
                                        page="common"
                                        onCloseModal={() => setModal(false)}
                                    ></CreateScfInvoice>
                                ) : modalType == 'trade_finance' ? (
                                    <CreateTfInvoice
                                        a_guid={entityDetails ? entityDetails.a_guid : ""}
                                        page="common"
                                        onCloseModal={() => setModal(false)}
                                    ></CreateTfInvoice>
                                ) : (
                                    ""
                                )
                            }




                        </Modal>
                    </>
                )
            }
        </>
    )
}
export default ScSale;