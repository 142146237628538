import React, { Component } from "react";
import * as moment from "moment";
import { CurrencyLabel, GeneralIconButton } from "../../../common/inputs";
import startIcon from "../../../../img/icons/verified.svg"
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import Modal from "react-bootstrap/Modal";
import { browserHistory } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router";
import _ from "lodash";
import { toast } from "react-toastify";
import CreateTransaction from "./_createTransaction";
import auth from "../../../../services/authService";
import externalApiService from "../../../../services/externalApiService";
import coreService from "../../../../services/coreService";
import AssignInvoice from "./_assignInvoice";
import Synchronize from "./synchronize";
class InvoiceCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dropDownHeading: "",
            initiated_user_type: "",
            invoice_type: '',
            selectUserType: "",
            isOpen: false,
            selectedInvoice: [],
            assignInvBtn: false,
            assignInvModal: false,
            editTransaction: false,
            selectedTrans: [],
            openSyncModel: false,
        }
    }

    componentDidMount() {
        const { constants } = auth.getCurrentUser();
        this.setState({ constants: constants });

    }

    handleAddNew = () => {
        if (this.props.addInvoiceUserType == 'addAsSupplier') {
            this.setState({
                dropDownHeading: "Select Supplier",
                initiated_user_type: "customer",
                selectUserType: "account",
                invoice_type: "payable",
                isOpen: true
            });

        } else if (this.props.addInvoiceUserType == 'addAsCustomer') {
            this.setState({
                dropDownHeading: "Select Customer",
                initiated_user_type: "customer",
                invoice_type: "receivable",
                selectUserType: "account",
                isOpen: true
            });
        }
    }

    onHide = () => {
        this.setState({
            editTransaction: false,
            selectedTrans: [],
            isOpen: !this.state.isOpen
        });
    }


    handleSelect = (item) => {
        if (this.state.selectedInvoice.some(e => e.trans_guid === item.trans_guid)) {
            const filtered = this.state.selectedInvoice.filter((obj) => obj.trans_guid !== item.trans_guid);
            this.setState({ selectedInvoice: filtered });
            if (filtered.length > 0) {
                this.setState({ assignInvBtn: true });
            } else {
                this.setState({ assignInvBtn: false });
            }
        } else {
            let seltedInv = {
                attachment: item.attachment,
                organization: item.contact.organization,
                name: item.contact.first_name + ' ' + item.contact.last_name,
                id: item.id,
                initiated_user_type: item.initiated_user_type,
                inv_amount: item.inv_amount,
                inv_due_date: item.inv_due_date,
                inv_number: item.inv_number,
                inv_purchase_order_number: item.inv_purchase_order_number,
                owner: item.owner,
                trans_customer_guid: item.trans_customer_guid,
                trans_date: item.trans_date,
                trans_guid: item.trans_guid,
                trans_status: item.trans_status,
                trans_supplier_guid: item.trans_supplier_guid,
                trans_intiated_by: item.trans_intiated_by,
                verified: item.verified,
                hasProductEnabled: false,
                invoice_type: item.invoice_type,
            }
            this.setState({
                selectedInvoice: [...this.state.selectedInvoice, seltedInv],
                assignInvBtn: true
            });
        }

    }

    handleAssignInvoice = () => {
        this.setState({ assignInvModal: true });
    }

    onCloseAssignModal = () => {
        this.setState({
            assignInvModal: false
        });

    }

    handleEditTransaction = (item) => {

        if (this.props.addInvoiceUserType == 'addAsSupplier') {
            this.setState({
                invoice_type: "payable",
            });

        } else if (this.props.addInvoiceUserType == 'addAsCustomer') {
            this.setState({
                invoice_type: "receivable",
            });
        }

        this.setState({
            initiated_user_type: "customer",
            selectUserType: "account",
            editTransaction: true,
            selectedTrans: item,
            isOpen: true
        });
    }

    handleRefreshCard = () => {
        this.setState({
            selectedInvoice: [],
            assignInvBtn: false
        });
        this.onCloseAssignModal();
        this.props.fetchGeneralTransactionData();
    }

    onCloseSyncModel = () => {
        this.setState({
            openSyncModel: false
        })
    }

    handleGetAllBusiness = async () => {
        const response = await externalApiService.ForwardAiAllBusiness();
    }

    handleDeleteInvoice = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-delete-ui">
                        <h5>Confirmation</h5>
                        <p>Are you sure want to delete?</p>
                        <button
                            className="btn btn-white mr-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                onClose();
                                this.deleteInvoice();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            },
            //title: "Assign Facility",
            message: `Are you sure want to delete?`,
        });
    }

    deleteInvoice = async()=>{
        this.setState({loading:true});
        let deleteList =[];
        this.state.selectedInvoice.map(itm =>(
            deleteList.push({
                trans_guid:itm.trans_guid,
                inv_number:itm.inv_number
            })
        ));

        const response = await coreService.DeleteGeneralTransaction({
            invoices: deleteList,
        });
        this.setState({loading:false});
        if(response){
            if(response.data.success == true){
                toast.success("Deleted successfully");
                this.handleRefreshCard();
            } else {
                response.data.response.map(itm => {
                    if(itm.error){
                        toast.error(itm.error);
                    }
                })
                
            }
        } else {
            toast.error("Failed to delete");
        }
    }

    render() {
        return (
            <>

                {/* -------- Modal to assign invoice start --------- */}
                <Modal
                    show={this.state.assignInvModal}
                    onHide={this.onCloseAssignModal}
                    //className={"animated bounceInRight "}
                    size="xl"
                >
                    <AssignInvoice
                        selectedInvoice={this.state.selectedInvoice}
                        handleRefreshCard={this.handleRefreshCard}
                        userType={this.props.addInvoiceUserType}
                    />

                </Modal>
                {/* -------- Modal to assign invoice end --------- */}

                {/* -------- Modal to add new transaction start --------- */}
                <Modal
                    show={this.state.isOpen}
                    onHide={this.onHide}
                    //className={"animated bounceInRight "}
                    //titleIcon={InvIcon}
                    title={"Create Invoice"}
                >
                    <CreateTransaction
                        onCloseModal={this.onHide}
                        selectUserType={this.state.selectUserType}
                        dropDownHeading={this.state.dropDownHeading}
                        initiated_user_type={this.state.initiated_user_type}
                        invoice_type={this.state.invoice_type}
                        editTransaction={this.state.editTransaction}
                        selectedTrans={this.state.selectedTrans}
                        refreshCard={this.handleRefreshCard}
                    />
                </Modal>
                {/* -------- Modal to add new transaction end --------- */}

                {/* -------- Modal to sync data from accounting sw start --------- */}
                <Modal
                    show={this.state.openSyncModel}
                    onHide={this.onCloseSyncModel}
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">Synchronize from accounting software </h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Synchronize
                            refreshModal={() => { this.props.fetchGeneralTransactionData() }}
                            closeModal={() => this.onCloseSyncModel()}
                            type={this.props.addInvoiceUserType == 'addAsSupplier' ? 'bill' : 'invoice'}
                        />
                    </Modal.Body>
                </Modal>
                {/* -------- Modal to sync data from accounting sw end --------- */}

                <div className="card ">
                    <div className="card-body ">
                        <div className="row ">
                            <div className="col-lg-6 col-sm-12" >
                                <h5 className="card-title text-secondary">{this.props.title}</h5>
                            </div>

                            <div className="col-lg-6 col-sm-12" >
                                {
                                    this.state.assignInvBtn === true ? (
                                        <>
                                            <button className="btn btn-secondary btn-sm pull-right mb-4" onClick={() => this.handleAssignInvoice()}>Assign invoice</button>
                                            <button className="btn btn-outline-danger btn-sm pull-right mb-4 mr-2" onClick={() => this.handleDeleteInvoice()}>Delete invoice</button>
                                        </>
                                    ) : (
                                        (this.props.invoiceList && this.props.invoiceList.length > 0) && (
                                            <>
                                                <div className="d-flex justify-content-end">

                                                    {/* <Link to={{ pathname: '/client/general-transaction/bulk-upload', state: { invoicetype: this.props.addInvoiceUserType === 'addAsSupplier' ? 'payable' : 'receivable' } }}>
                                                        <button className="btn btn-secondary btn-sm mb-4">Upload</button>
                                                    </Link> */}

                                                    <button className="btn btn-primary btn-sm mb-4 ml-2" onClick={() => this.handleAddNew()}>Add New</button>

                                                    <div className="btn-group">
                                                        <a
                                                            className="ml-3 btn-link"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false" href="#"
                                                        >
                                                            <i className="fa fa-ellipsis-v" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right shadow">
                                                            <Link
                                                                to={{ pathname: '/client/general-transaction/bulk-upload', state: { invoicetype: this.props.addInvoiceUserType === 'addAsSupplier' ? 'payable' : 'receivable' } }}
                                                                className="dropdown-item"
                                                            >
                                                                Upload
                                                            </Link>
                                                            <Link
                                                                className="dropdown-item"
                                                                onClick={() => { this.setState({ openSyncModel: true }) }}
                                                            >
                                                                Sync from accounting software
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    )
                                }

                            </div>

                        </div>

                        {this.props.loading && <LoadingSpinner normal={true}></LoadingSpinner>}
                        {this.props.invoiceList.length > 0 && (


                            <div className="list-group list-group-flush bg-light px-2 rounded-xl unassigned-inv-card-h">
                                {this.props.invoiceList != undefined && this.props.invoiceList.slice(0, 4).map((obj, key) =>

                                    <div key={key} className="list-group-item list-group-item-action flex-column align-items-start rounded-xl border-0 mt-2 mb-2">

                                        <div className="d-sm-flex align-items-center">

                                            <div className="custom-control d-flex custom-checkbox small">
                                                <input
                                                    id={obj.trans_guid}
                                                    type="checkbox" className="custom-control-input"
                                                    onChange={() => this.handleSelect(obj)}
                                                />

                                                <label className="custom-control-label" htmlFor={obj.trans_guid}></label>
                                            </div>

                                            <div className="d-flex card-mobile-view justify-content-center" onClick={() => {
                                                this.handleEditTransaction(obj)
                                            }}>
                                                <div className={`avatar d-none d-sm-inline-flex avatar-md ${this.props.addInvoiceUserType == 'addAsSupplier' ? 'text-secondary bg-secondary-transparent' : 'text-primary bg-success-transparent'} mr-2  font-weight-bold rounded-full mob-avatar`}>

                                                    <span>
                                                        {obj.contact?.organization
                                                            ?.split(/\s+/)
                                                            .filter(Boolean)
                                                            .slice(0, 2)
                                                            .map(word => word.charAt(0))
                                                            .join('') ?? ''}
                                                    </span>


                                                </div>
                                                <div className="mb-3 mb-sm-0 mob-card-text  text-center text-sm-left">

                                                    <h6 className="mb-0 mob-card-title  text-center text-sm-left mb-1"> {obj.contact && obj.contact.organization}</h6>
                                                    <small className="opacity-50">
                                                        <span className="text-secondary mr-2 font-weight-bold" title="Invoice Number">{obj.inv_number}</span>
                                                        {moment(obj.updated_at).format("MMM DD")}
                                                    </small>
                                                    {obj.status == "Inactive" ?
                                                        <small className="badge badge-warning p-1 ">Pending Approval</small>
                                                        : <small className="badge badge-danger p-1 ml-2 ">Awaiting allocation</small>}
                                                </div>
                                            </div>
                                            <div className="ml-auto text-sm-right text-center mg-t-5 mob-card-right">
                                                <h6 className="mb-0 text-secondary font-weight-bold"><CurrencyLabel value={obj.inv_amount}></CurrencyLabel></h6>
                                                <small className="badge badge-light text-secondary">Source: {_.startCase(obj.transaction_type)}</small>
                                            </div>

                                        </div>
                                    </div>

                                )}

                                {(this.props.invoiceList && this.props.invoiceList.length > 4) && (
                                    <>
                                        <div className="row justify-content-center mt-2 mb-1">
                                            <GeneralIconButton name="View All Invoices" className="btn btn-outline-secondary my-3 " route={`/client/general-trans/view-all?invtype=` + this.props.addInvoiceUserType} ></GeneralIconButton>
                                        </div>
                                    </>
                                )}

                            </div>
                        )}

                        {(!this.props.loading && this.props.invoiceList == "") &&
                            <>
                                <div className="row justify-content-center mt-2  ">
                                    <p className="mt-5"> <img width={380} height={150} src={startIcon} alt="alt" /></p>
                                </div>
                                <div className="row justify-content-center mt-3  ">
                                    <h5 className="font-weight-bold text-center mt-2">Start adding invoices! </h5>
                                </div>
                                <div className="row justify-content-center mt-3  ">
                                    <span className="text-xs text-secondary mb-5 opacity-75">Before you can allocate any invoices, you’ll first need to get some here</span>

                                </div>
                                <div className="row justify-content-center mt-4 mb-4">

                                    <Link title="Sync from accounting software" onClick={() => { this.setState({ openSyncModel: true }) }}>
                                        <button className="btn btn-outline-secondary ">Sync</button>
                                    </Link>

                                    <Link to={{ pathname: '/client/general-transaction/bulk-upload', state: { invoicetype: this.props.addInvoiceUserType === 'addAsSupplier' ? 'payable' : 'receivable' } }}>
                                        <button className="btn btn-secondary ml-2">Bulk Upload</button>
                                    </Link>

                                    <button
                                        onClick={() => this.handleAddNew()}
                                        className="btn btn-primary ml-2">Add New
                                    </button>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </>

        );
    }
}
export default InvoiceCard;
