import React from "react";
import startIcon4 from "../../.././../img/icons/FMB_icon4.svg";





export default function Page17({ goNext, goPrevious, selectedTypeRedux }) {


    return (
        <>
            <div id="animation_box" className="animated fadeIn">
                <div id="animation_box" className="animated fadeIn">
                    <div className="mb-0 "><div className="card-body">
                        <div className="row min-vh-70 justify-content-center align-items-center py-5">
                            <div className="col-lg-2  col-md-12">
                                <div className="operate-image">
                                    <img src={startIcon4} alt="image" width className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-12">
                                <div className>

                                    {/* <span className="text-success mb-3 font-medium">Let’s get started…
                                    </span> */}
                                    <h4 className="mt-3">Cash flow finance solutions that support your business needs
                                    </h4>
                                    <small>With our flexible cash flow finance solutions you can find the one that fits your needs.
                                        Select one or more facilities to apply, and we’ll help you find the best solution for your business   </small>

                                    <div className="row">
                                        <div className="col-lg-4"><div className="form-group"><div className="d-block pt-3 text-center w-100 ml-0">
                                            <div className="card  b-card aligin-center"><div className="card-body">
                                                <span className="b-corner b-corner-r b-corner-secondary"></span>
                                                <div className=" justify-content-between"><div><h5 className="card-title-new">Single Invoice Finance</h5></div></div><div className="row text-center bg-white"><div className="col-md-12"><div className="align-center relative my-2 mb-4"><p className="card-new-sub">For periodic use/selective invoice finance </p></div><hr /><div className="align-center relative my-1 mt-3"><p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i>
                                                    As required solution, on an invoice-by-invoice basis </p></div><div className="align-center relative my-1"><p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i>
                                                        Select the invoices your want to accelerate for <br />cash flow</p></div><div className="align-center relative my-1"><p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i>
                                                            No lock in periods</p></div><div className="align-center relative my-1"><p className="  carde-new-p"><i className="fa fa-check" aria-hidden="true"></i>
                                                                Competitive rates</p></div><hr /></div><div className="  mb-2  m-auto"><a><button onClick={() => goNext('cashFlowQestions')} type="button" className="btn btn-secondary align-center mt-3">Apply</button></a></div></div></div></div></div></div></div>




                                        <div className="col-lg-4"><div className="form-group"><div className="d-block pt-3 text-center w-100 ml-0"><div className="card b-card aligin-center"><div className="card-body">
                                            <span className="b-corner b-corner-r b-corner-success"></span>
                                            <div className="justify-content-between"><div><h5 className=" card-title-new text-primary">Invoice <br />Factoring

                                            </h5></div></div><div className="row text-center bg-white"><div className="col-md-12"><div className="align-center relative my-2 mb-4"><p className="card-new-sub2">Use your sales ledger to accelerate your cash flow

                                            </p></div><hr /><div className="align-center relative my-1"><p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i> Full solution based around your sales ledger
                                            </p></div><div className="align-center relative my-1"><p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i> Include all invoices to maximise your cash flow opportunity
                                            </p></div><div className="align-center relative my-1"><p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i> Flexible terms available
                                            </p></div><div className="align-center relative my-1"><p className="  carde-new-p"><i className="fa fa-check" aria-hidden="true"></i> Excellent rates
                                            </p></div><hr /></div><div className="  mb-2  m-auto"><a><button onClick={() => goNext('cashFlowQestions')} type="button" className="btn btn-primary align-center mt-3">Apply</button></a></div></div></div></div></div></div></div>



                                        <div className="col-lg-4"><div className="form-group"><div className="d-block pt-3 text-center w-100 ml-0"><div className="card  b-card aligin-center"><div className="card-body">
                                            <span className="b-corner b-corner-r b-corner-warning"></span>
                                            <div className="justify-content-between"><div><h5 className=" card-title-new text-warning">Bulk Invoice Factoring</h5></div></div><div className="row text-center bg-white"><div className="col-md-12"><div className="align-center relative my-2 mb-4"><p className="card-new-sub3">Use your sales ledger to accelerate your cash flow</p></div><hr /><div className="align-center relative my-1"><p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i> Full solution based around your sales ledger </p></div><div className="align-center relative my-1"><p className="mb-3 carde-new-p"> <i className="fa fa-check" aria-hidden="true"></i> Bulk process all invoices to maximise your cash flow opportunity
                                            </p></div><div className="align-center relative my-1"><p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i> 12 to 36 month terms
                                            </p></div><div className="align-center relative my-1"><p className="mb-3 carde-new-p"><i className="fa fa-check" aria-hidden="true"></i> Excellent rates
                                            </p></div><hr /></div><div className="  mb-2  m-auto"><a>
                                                <button type="button" onClick={() => goNext('cashFlowQestions')} className="btn btn-warning align-center mt-3">Apply</button>
                                            </a></div></div></div></div></div></div></div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="col-10">
                                <div className="d-flex justify-content-end align-items-center mt-3">
                                    <a href="#" onClick={goPrevious} className="btn btn-light mr-1">Back</a><a href="#" onClick={() => goNext()} className="btn btn-primary ml-1">Continue</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    </div>
                </div>


                {/*  <GeneralButton pullRight="right" type="button" onClick={goPrevious} style={{ marginRight: "10px" }} className="btn btn-default btn-outline pull-right " name="Back"></GeneralButton>*/}
            </div>
        </>
    )
}

