import React from "react";
import { combineReducers, createStore } from "redux";
import transactionsReducer from "./reducers/transaction-reducers";
import userReducer from "./reducers/user-reducers";
import loanReducer from "./reducers/loan-reducers";
import scTransactionsReducer from "./reducers/sc-transaction-reducers";
import { Provider } from "react-redux";
import store from './redux/store'
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import routes from "./config/routes";
import $ from "jquery";
import metismenu from "metismenu";
import "../src/custom.css";
import "./../node_modules/font-awesome/css/font-awesome.css";
import "./../node_modules/animate.css/animate.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles/horizontalForm.css";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";
import "@kenshooui/react-multi-select/dist/style.css";
import "react-dropdown-tree-select/dist/styles.css";
import "icheck/skins/all.css";

import './config';


window.jQuery = $;
window.$ = $;
global.jQuery = $;
const bootstrap = require("bootstrap");

/**============= Redux ================= */

// const allReducers = combineReducers({
//   user: userReducer,
//   transactions: transactionsReducer,
//   loan: loanReducer,
//   sctransactions: scTransactionsReducer,
// });

// const store = createStore(
//   allReducers,
//   {},
//   window.devToolsExtension && window.devToolsExtension()
// );

ReactDOM.render(
	<Provider store={store}>
  <BrowserRouter>{routes}</BrowserRouter>
  </Provider>
  ,
  document.getElementById("root")
);
