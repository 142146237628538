import React, { useState, useEffect } from "react"
import useApi from "../../hooks/useApi";
import accountServices from "../../components/supply-chain/services/commonService";
import Modal from "react-bootstrap/Modal";
import DisplayTemplates from "../viewTemplates/displayTemplates";
import {
    Picker,
    GeneralIconButton,
    GeneralButton
} from "../common/inputs"
import { FormRow } from "../common/containers/formContainer";
export default function ViewTemplates() {
    const templatesType = [
        { label: "IF", value: "if" },
        { label: "SCF", value: "scf" },
        { label: "TF", value: "tf" },
    ];
    const [selectedType, setSelectedType] = useState(templatesType[0]);
    const [templateList, setTemplateList] = useState(null);
    const [isList, setIsList] = useState(false);
    const [modal, setModal] = useState(false);
    const [filename, setFilename] = useState(null);
    const FetchTemplates = useApi(accountServices.fetchTemplates);
    const getTemplateList = async (selectedType) => {
        const response = await FetchTemplates.request({ selectedType: selectedType.value }); // Sending Async request to server
        if (response.data) {
            setTemplateList(response.data);
            setIsList(true)
        }
    }
    useEffect(() => {
        getTemplateList(selectedType)

    }, [selectedType])
    return (
        <>
            <div>
                <form className="form-horizontal">
                    <div className="row ">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>Finance Type</label>
                                <div>
                                    <Picker
                                        items={templatesType}
                                        selectedItem={selectedType}
                                        onSelectItem={option => setSelectedType(option)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="card ">
                <div className="card-header">
                    {templateList && templateList.data.length > 0 && templateList.data.map((item, index) => (

                        <>
                            <div className="row mt-2 mb-2">
                                <div className="col-md-3">
                                    <b>{item.replace(/_/g, " ")}</b>
                                </div>
                                <div className="col-md-6">
                                    <GeneralIconButton
                                        name={`ViewTemplate`}
                                        className={`btn btn-primary`}
                                        onClick={e => { setModal(true); setFilename(item) }}
                                    ></GeneralIconButton>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </>
                    ))}
                </div>
            </div>
            <Modal
                show={modal}
                onHide={(e) => setModal(false)}
                size="lg" >
                <Modal.Header closeButton>
                    <h6> {`View Template`} </h6>
                </Modal.Header>
                <Modal.Body>
                    <DisplayTemplates data={filename} />
                </Modal.Body>

                {/*-----------------------------------------------------------------------*/}
                <Modal.Footer>
                    <FormRow>
                    </FormRow>
                </Modal.Footer>
            </Modal>
        </>
    )
}