import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { GeneralButton, TextInput, Picker } from "../../common/inputs";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import entityService from "../../../services/entityService";
import useApi from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { FormGroup } from "../../common/containers/formContainer";
import { contactDesignations } from "../../../config/predefinedValues.json";
import _ from "lodash";

function EditTeamMember(props) {
  //------------Api Services--------------------------------------------
  const contactApi = useApi(entityService.getEntityContactsList);
  const [loader, setLoader] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState({ label: props.data.designation, value: props.data.designation });
  const [selectedStatus, setSelectedStatus] = useState({ label: props.data.status, value: props.data.status });
  const [introducerCategories, setIntroducerCategories] = useState([{ label: "Reserved", value: "reserved" }, { label: "Pooled", value: "pooled" }]);
  const [selectedIntroducerCategory, setSelectedIntroducerCategory] = useState({ label: _.startCase(props.data.category), value: props.data.category });
  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      first_name: Joi.string()
        .required()
        .error(() => {
          return { message: "Please enter a First Name" };
        }),
      email: Joi.string()
        .required()
        .label("Email.")
        .error(() => {
          return { message: "Please enter a Email." };
        }),
      phone_no: Joi.string()
        .required()
        .label("Phone No.")
        .error(() => {
          return { message: "Please enter a Phone No." };
        }),
      designation: Joi.string()
        .required()
        .label("Designation.")
        .error(() => {
          return { message: "Please choose a Designation." };
        }),
      status: Joi.string()
        .required()
        .label("Status.")
        .error(() => {
          return { message: "Please choose a Status." };
        }),
    })
    .unknown(true);

  //------------Api Services--------------------------------------------
  const entityContactApi = useApi(entityService.createEntityContact);

  // const handleDesignationChangeOnEdit = (e) => {
  //   setValues({ ...values, designation: e.value });
  //   setSelectedDesignation({ label: e.value, value: e.value });
  // }
  // const handleStatusChangeOnEdit = (e) => {
  //   setValues({ ...values, status: e.value });
  //   setSelectedStatus({ label: e.value, value: e.value });
  // }

  const handlePickerChange = (field, selectedOpt) => {
    if (field == 'designation') {
      setValues({ ...values, designation: selectedOpt.value });
      setSelectedDesignation({ label: selectedOpt.value, value: selectedOpt.value });
    } else if (field == 'status') {
      setValues({ ...values, status: selectedOpt.value });
      setSelectedStatus({ label: selectedOpt.value, value: selectedOpt.value });
    } else if (field == 'introducer_category') {
      setValues({ ...values, category: selectedOpt.value });
      setSelectedIntroducerCategory({ label: _.startCase(selectedOpt.value), value: selectedOpt.value });
    }
  }

  //------------------Save Entity Details-----------------------------
  const saveEntityContact = async () => {
    let reqValues = { ...values };
    let dataSent = {
      contact_id: reqValues.contact_id,
      first_name: reqValues.first_name,
      last_name: reqValues.last_name,
      phone_no: reqValues.phone_no,
      landline: reqValues.landline,
      email: reqValues.email,
      designation: reqValues.designation,
      status: reqValues.status,
      clientAcEdit: true,
    };
    if(props.type && props.type == 'introducers'){
      dataSent['category'] = reqValues.category;
    }
    const { data: res } = await entityContactApi.request(dataSent); // Sending Async request to server
    if (res.success === true) {
      setLoader(true);
      toast.success(res.message);
      props.onCloseModal();
      props.onHandleReload();
    } else {
      setLoader(false);
      toast.error(res.message);
    }
  };

  //------------------Custom hook to handle form data-----------------------------
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
  } = useForm(saveEntityContact, schema); // Custom Hook to handle Form Validation

  useEffect(() => {
    setValues({ ...values, ...props.data });
  }, []);

  return (
    <>
      {loader == true && <LoadingSpinner />}
      <Helmet>
        <title>Account | {global.config.title}</title>
      </Helmet>
      {(contactApi.loading == true || entityContactApi.loading == true) && <LoadingSpinner />}


      <div className="row p-3">
        <div className="col-md-12 p-4">
          <form onSubmit={handleSubmit} className="form-horizontal">
            <TextInput
              name="first_name"
              label="First Name"
              value={values.first_name}
              onChange={handleChange}
              type="horizontal"
              mandatory={true}
            ></TextInput>

            <TextInput
              name="last_name"
              label="Last Name"
              value={values.last_name}
              onChange={handleChange}
              type="horizontal"
            ></TextInput>
            <TextInput
              name="email"
              value={values.email}
              label="Email"
              onChange={handleChange}
              type="horizontal"
              mandatory={true}
              readonly={values.userLogin == true ? true : false}
            ></TextInput>

            <TextInput
              name="phone_no"
              label="Phone"
              value={values.phone_no}
              onChange={handleChange}
              type="horizontal"
              mandatory={true}
            ></TextInput>

            <TextInput
              name="landline"
              label="Landline"
              value={values.landline}
              onChange={handleChange}
              type="horizontal"
            //mandatory={true}
            ></TextInput>

            <FormGroup
              label={"Designation"}
              type="horizontal"
              mandatory={true}
              input={
                <Picker
                  className="overflow-visible"
                  items={contactDesignations}
                  selectedItem={selectedDesignation}
                  onSelectItem={e =>
                    handlePickerChange('designation', e)}
                />
              }
            />
            {
              props.type && props.type == 'introducers' && (
                <>
                  <FormGroup
                    label={"Category"}
                    type="horizontal"
                    mandatory={true}
                    input={
                      <Picker
                        items={introducerCategories}
                        selectedItem={selectedIntroducerCategory}
                        onSelectItem={e => { handlePickerChange('introducer_category', e) }}
                      />
                    }
                  />
                </>
              )
            }
            <FormGroup
              label={"Status"}
              type="horizontal"
              mandatory={true}
              input={
                <Picker
                  items={[
                    { label: "Active", value: "active" },
                    { label: "Inactive", value: "inactive" },
                  ]}
                  selectedItem={selectedStatus}
                  onSelectItem={e =>
                    handlePickerChange('status', e)}
                />
              }
            />

            <div className="row">
              <div className="col-lg-12">
                <GeneralButton
                  className="btn btn-primary btn-sm float-right mr-2"
                  faIcon="fa fa-save"
                  name="Update"
                ></GeneralButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditTeamMember;
