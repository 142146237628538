import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { TextInput, GeneralButton } from "../../../../../common/inputs";
import commonService from "../../../../../supply-chain/services/commonService";
import useApi from "../../../../../../hooks/useApi";
import Utility from "../../../../../../../src/utils/DateParser";
import { DateTimePicker } from "../../../../../common/inputs/datePicker";

const OtherPayments = (props) => {
  const [amount, setAmount] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const otherAmountAllocation = useApi(commonService.otherAmountAllocation);

  const { ParseDate } = Utility();
  const handleChange = (value) => {
    if (!/^\d*\.?\d*$/.test(value)) {
      toast.error("Please Enter a Valid Amount (only numbers allowed)");
      return;
    } else {
      setAmount(value);
    }
  };

  const handleSave = async () => {
    const { data } = await otherAmountAllocation.request({
      reqAmount: amount,
      product_type: props.product,
      request_date: ParseDate(selectedDate),
      client_id: props.entity_id,
    });
    if (data.success === false) {
      toast.error("Something went wrong...!");
    } else {
      props.fetchSummary();
      props.fetchAllDetails();
      props.setShowPayment(false);
      toast.success("Amount requested Successfully...!");
    }
  };

  const confirmPayment = () => {
    props.setOtherPayModal(false);
    confirmAlert({
      container: document.body,
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-delete-ui"
            style={{ zIndex: 1100, position: "relative" }}
          >
            <h4>Are you sure ...?</h4>
            <p>Do you want to continue with the payment request?</p>
            <button
              className="btn btn-white"
              onClick={() => {
                onClose();
                props.setOtherPayModal(true);
              }}
            >
              No
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={async () => {
                onClose();
                handleSave();
                props.setOtherPayModal(false);
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  };
  const handleDateChange = async (date) => {
    setSelectedDate(date);
  };
  return (
    <>
      <Modal
        show={props.OtherPayModal}
        onHide={() => {
          props.setOtherPayModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <h6>Other Payment Request</h6>
        </Modal.Header>

        <Modal.Body>
          <DateTimePicker
            name="date"
            type="vertical"
            label="Requested Date"
            onChange={handleDateChange}
            selected={
              typeof selectedDate !== "object"
                ? new Date(Date.parse(selectedDate))
                : selectedDate
            }
            format="dd/MM/y"
            placeholder="Select Date"
            style={{ marginLeft: "6px" }}
          ></DateTimePicker>

          <TextInput
            name="other_amount"
            value={amount}
            onChange={(e) => handleChange(e.target.value)}
            label="Other Amount"
            type="vertical"
          />

          <GeneralButton
            onClick={confirmPayment}
            disabled={amount === "" || amount === null || amount === undefined}
            className="btn btn-primary ml-2 mt-3 pull-right"
            name="Allocate Payment"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtherPayments;
