import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import authService from "../../../../../services/authService"
import * as moment from "moment";
import { CountriesList, AllCorporateBpList, CustomersList, SuppliersList } from '../../../../slplusTools';
import { ReportInputFieldContainer, FormRow, FormColumnLg } from "../../../../common/containers/formContainer";
import transactionService from "../../../services/transaction_service";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router";
import Utility from "../../../utils/DateParser";
import useApi from "../../../../../hooks/useApi";
import 'react-table/react-table.css';
import { confirmAlert } from "react-confirm-alert";
import { GridActionButton, GeneralButton, DateTimePicker, CurrencyLabel, Picker } from "../../../../common/inputs";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
const colorRed = { color: "red" };

export default function ScScheduledPayment(props) {

    const tableRef = useRef();
    const { ParseDate } = Utility();

    const ManoovaScheduledApi = useApi(transactionService.manoovaScheduledPayments);
    const paymentStatuses = [
        { label: "Select Status", value: 'all' },
        { label: "Pending", value: "pending" },
        { label: "Requested", value: "requested" },
    ];
    const { constants, category, products } = authService.getCurrentUser();
    const [country, setCountry] = useState(null);
    const [owner, setOwner] = useState(null);
    const [customer, setCustomer] = useState(props.entityGuid ? props.entityGuid : null);
    const [supplier, setSupplier] = useState(null);
    const [selectedDateTo, setSelectedDateTo] = useState(null);
    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(paymentStatuses[0]);
    const [ddScheduledList, setDdScheduledList] = useState(null);

    const getScheduledPaymentList = async (owner, customer, supplier, selectedDateFrom, selectedDateTo) => {
        const response = await ManoovaScheduledApi.request({
            scheduledDateFrom: selectedDateFrom,
            scheduledDateTo: selectedDateTo,
            product_type: constants.PRODUCTS.SUPPLYCHAIN,
            country: country,
            owner: owner,
            customer: props.entityGuid ? props.entityGuid : customer !== undefined ? customer : null,
            supplier: supplier !== undefined ? supplier : null,
            scheduleStatus: selectedStatus,
        }); // Sending Async request to server
        if (response) {
            setDdScheduledList(response.data.rows.list);
        }
    }

    useEffect(() => {
        getScheduledPaymentList(owner, customer, supplier, selectedDateFrom, selectedDateTo, selectedStatus)
    }, [owner, customer, supplier, selectedDateFrom, selectedDateTo,selectedStatus])

    const columns = [

        {
            Header: "Customer",
            accessor: "organization",
            headerStyle: { textAlign: "left" },
        },
        {
            Header: "Requested Date",
            accessor: "requested_date",
            headerStyle: { textAlign: "left" },
            Cell: row => moment(row.original.requested_date, "YYYY-MM-DD").format("DD/MM/YYYY")
        },
        {
            Header: "Scheduled Date",
            accessor: "schedule_date",
            headerStyle: { textAlign: "left" },
            Cell: row => moment(row.original.schedule_date, "YYYY-MM-DD").format("DD/MM/YYYY")
           
        },

        {
            Header: "Total DD",
            accessor: "amount",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <CurrencyLabel
                    value={
                        row.original.amount !== null ? row.original.amount : 0
                    }
                ></CurrencyLabel>
            ),
            Footer: (row) => {
                return (
                    <strong>
                        <CurrencyLabel decimal={2} value={_.sumBy(row.data, item => Number(item.amount))}></CurrencyLabel>
                    </strong>
                );
            },
            
        },

        {
            Header: "Status",
            accessor: "schedule_status",
            headerStyle: { textAlign: "left" },
            Cell: (row) => (
                <div className="badge badge-light p-2">{row.value.charAt(0).toUpperCase() + row.value.slice(1)}</div>
            ),
        },

    ];

    return (
        <>
            <div className="overflow-visible">
 
            <div className="row p-3">

                <ReportInputFieldContainer label={category == constants.CATEGORY.INTL_OFFICE ? "Country" : ""} show={category == constants.CATEGORY.INTL_OFFICE ? true : false}>
                    <CountriesList type="horizontal" selectedCountry={country} onSelectItem={({ id }) => { setCountry(id); setOwner(null); setCustomer(null); setSupplier(null); }} />
                </ReportInputFieldContainer>

                <ReportInputFieldContainer label={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? "Business Partner" : ""} show={(category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? true : false} >
                    <AllCorporateBpList selectedBp={owner} type="horizontal" country={country} onSelectItem={({ value }) => { setOwner(value); setCustomer(null); setSupplier(null); }} />
                </ReportInputFieldContainer>

                <ReportInputFieldContainer label="Customer" >
                    <CustomersList selectedCustomer={customer} bp={owner}
                        //   onSelectItem={option => {  if (option.value == null) { setSupplier(null) } else { setCustomer(option.value) } }}
                        onSelectItem={option => setCustomer(option.value)}
                    />
                </ReportInputFieldContainer>
                    <div className="col-lg-2">
                        <div className="form-group">
                            <label>Payment Status</label>
                            <div>
                                <Picker
                                    items={paymentStatuses}
                                    selectedItem={selectedStatus}
                                    onSelectItem={option => setSelectedStatus(option)}
                                />
                            </div>
                        </div>
                    </div>
                <div className="col-sm-2">
                    <div className="form-group">
                        <label>Date From</label>
                        <DateTimePicker
                            name="requested_from_date"
                            label="Requested Date From"
                            selectedDate={selectedDateFrom}
                            onChange={({ target }) => setSelectedDateFrom(target.value)}
                            enableWeekDays={true}
                        />
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group">
                        <label>Date to</label>
                        <DateTimePicker
                            name="requested_to_date"
                            label="Requested Date To"
                            selectedDate={selectedDateTo}
                            onChange={({ target }) => setSelectedDateTo(target.value)}
                            enableWeekDays={true}
                        />
                    </div>
                </div>
                </div>
                <div className="card-body">
                    {ddScheduledList && ddScheduledList.length > 0 ? (
                        <div className="card-body">
                            <ReactTable
                                data={ddScheduledList}
                                columns={columns}
                                defaultPageSize={10}
                                minRows={ddScheduledList.length}
                                className="-striped -highlight"
                                showPagination={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                            />
                        </div>
                    ) : (
                        <div className="card-body">
                            <div>
                                <p colSpan="15" className="text-center">
                                    No DD Scheduled
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>

    )
}