import React, { useEffect, useState } from "react";
import { FormRow } from "../../../common/containers/formContainer";
import Modal from 'react-bootstrap/Modal';
export default function ReadyToUpload({ readyToUpload }) {
    return (
        <>

            <Modal.Header closeButton>
                <h4 className="modal-title">Ready to upload accounts</h4>
            </Modal.Header>

            <Modal.Body>
                {readyToUpload.length > 0 ? (
                    <FormRow style={{ paddingTop: "inherit" }}>
                        <table className="table table-sm table-bordered text-secondary">
                            <tbody>
                                <tr>
                                    <th>Organisation</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                </tr>
                                {readyToUpload.map((item, index) => (
                                    <tr key={index}>
                                        <td> {item.organization} </td>
                                        <td> {item.first_name} {item.last_name}</td>
                                        <td> {item.email} </td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    </FormRow>

                ) :
                    <div className="modal-header">
                        <span className="modal-title text-danger">No prospects to upload!</span>
                    </div>
                }
            </Modal.Body>
        </>
    )
}