import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactTable from "react-table";
import moment from "moment";
import { Link } from "react-router";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import {
  FormRow,
  ReportInputFieldContainer,
  FormColumn,
} from "../../../common/containers/formContainer";
import {
  GeneralButton,
  CurrencyLabel,
  DateTimePicker,
  Picker,
} from "../../../common/inputs";
import { CountriesList, CustomersList, BPList } from "../../../slplusTools";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import useApi from "../../../../hooks/useApi";
import authService from "../../../../services/authService";
import loanService from "../../../loan/services/loanTransactions";
import DDScheduleList from "./_getscheduleDetails";

const LoanHistory = (props) => {
  const { constants, category, country_id } = authService.getCurrentUser();

  const paymentStatuses = [
    { label: "Select Status", value: "all" },
    { label: "Received", value: "accepted" },
    { label: "Rejected", value: "rejected" },
  ];

  const [country, setCountry] = useState(null);
  const [owner, setOwner] = useState(null);
  const [client, setClient] = useState(null);
  const [historyList, setHistoryList] = useState([]);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(paymentStatuses[0]);
  const [scheduleItem, setScheduleItem] = useState(null);
  const [ddScheduleListModal, setDdScheduleListModal] = useState(false);

  const getHistoryListApi = useApi(loanService.getHistory);

  const fetchData = async () => {
    const response = await getHistoryListApi.request({
      country: country,
      customer: client,
      owner: owner,
      paymentStatus: selectedStatus.value,
      selectedDateFrom: selectedDateFrom,
      selectedDateTo: selectedDateTo,
    });
    if (response) {
      setHistoryList(response.data.rows);
    } else {
      toast.error("Failed to fetch data");
    }
  };

  const handleShowScheduleDetail = (item) => {
    setDdScheduleListModal(true);
    setScheduleItem({
      client: item.entity_guid,
      batchId: item.reference_guid,
    });
  };

  useEffect(() => {
    if (category == constants.CATEGORY.COUNTRY_OFFICE) {
      setCountry(country_id);
    }
    fetchData();
  }, []);

  const columns = [
    {
      Header: "Customer",
      accessor: "organization",
      headerStyle: { textAlign: "left" },
    },
    {
      Header: "Requested Date",
      accessor: "manoova_requested_date",
      headerStyle: { textAlign: "left" },
      Cell: (row) =>
        moment(row.original.manoova_requested_date, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        ),
    },
    {
      Header: "Scheduled Date",
      accessor: "schedule_date",
      headerStyle: { textAlign: "left" },
      Cell: (row) =>
        moment(row.original.schedule_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    },
    {
      Header: "Requested Count",
      accessor: "schedule_count",
      headerStyle: { textAlign: "left" },
      Cell: (row) => {
        return (
          <>
            <Link
              className="cursor-link"
              onClick={() => {
                handleShowScheduleDetail(row.original);
              }}
            >
              {row.original.schedule_count}
            </Link>
          </>
        );
      },
    },
    {
      Header: "Total DD",
      accessor: "amount",
      headerStyle: { textAlign: "left" },
      Cell: (row) => (
        <CurrencyLabel
          value={row.original.amount !== null ? row.original.amount : 0}
        ></CurrencyLabel>
      ),
      Footer: (row) => {
        return (
          <strong>
            <CurrencyLabel
              decimal={2}
              value={_.sumBy(row.data, (item) => Number(item.amount))}
            ></CurrencyLabel>
          </strong>
        );
      },
    },

    {
      Header: "Payment Status",
      accessor: "payment_status",
      headerStyle: { textAlign: "left" },
      Cell: (row) => (
        <div>
          {row.value === "accepted" && (
            <div className="badge badge-light p-2 text-success">Received </div>
          )}
          {row.value === "rejected" && (
            <div className="badge badge-light p-2  text-danger">Rejected</div>
          )}
        </div>
      ),
    },
    {
      Header: "Payment Schedule Status",
      accessor: "schedule_status",
      headerStyle: { textAlign: "left" },
      Cell: (row) => (
        <div>
          {row.value === "completed" && (
            <div className="badge badge-light p-2 text-success">
              {row.value.charAt(0).toUpperCase() + row.value.slice(1)}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <FormRow>
            <ReportInputFieldContainer
              label={
                category == constants.CATEGORY.INTL_OFFICE ? "Country" : ""
              }
              show={category == constants.CATEGORY.INTL_OFFICE ? true : false}
            >
              <CountriesList
                type="horizontal"
                selectedCountry={country}
                onSelectItem={({ id }) => {
                  setCountry(id);
                  setOwner(null);
                  setClient(null);
                }}
              />
            </ReportInputFieldContainer>

            <ReportInputFieldContainer
              label={
                category == constants.CATEGORY.INTL_OFFICE ||
                category == constants.CATEGORY.COUNTRY_OFFICE
                  ? "Business Partner"
                  : ""
              }
              show={
                category == constants.CATEGORY.INTL_OFFICE ||
                category == constants.CATEGORY.COUNTRY_OFFICE
                  ? true
                  : false
              }
            >
              <BPList
                selectedBp={owner}
                type="horizontal"
                country={country}
                onSelectItem={({ value }) => {
                  setOwner(value);
                  setClient(null);
                }}
              />
            </ReportInputFieldContainer>

            <ReportInputFieldContainer label="Client">
              <CustomersList
                selectedCustomer={client}
                bp={owner}
                onSelectItem={(option) => setClient(option.value)}
              />
            </ReportInputFieldContainer>
            <div className="col-lg-2">
              <div className="form-group">
                <label>Payment Status</label>
                <div>
                  <Picker
                    items={paymentStatuses}
                    selectedItem={selectedStatus}
                    onSelectItem={(option) => setSelectedStatus(option)}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label>Date From</label>
                <DateTimePicker
                  name="requested_from_date"
                  label="Requested Date From"
                  selectedDate={selectedDateFrom}
                  onChange={({ target }) => setSelectedDateFrom(target.value)}
                  enableWeekDays={true}
                />
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label>Date to</label>
                <DateTimePicker
                  name="requested_to_date"
                  label="Requested Date To"
                  selectedDate={selectedDateTo}
                  onChange={({ target }) => setSelectedDateTo(target.value)}
                  enableWeekDays={true}
                />
              </div>
            </div>

            <FormColumn col={`col-sm-12`}>
              <label></label>
              <div>
                <GeneralButton
                  className="btn btn-primary  mt-2 pull-right"
                  name="Search"
                  onClick={() => {
                    fetchData();
                  }}
                  disabled={
                    historyList && historyList.length > 0 ? true : false
                  }
                ></GeneralButton>
                <GeneralButton
                  className="btn btn-light mr-2 mt-2 pull-right"
                  name="Reset"
                  onClick={() => {
                    setHistoryList([]);
                  }}
                />
              </div>
            </FormColumn>
            <hr />
          </FormRow>
          <ReactTable
            data={historyList}
            columns={columns}
            defaultPageSize={10}
            minRows={historyList ? historyList.length : 0}
            className="-striped -highlight"
            showPagination={true}
            showPaginationTop={false}
            showPaginationBottom={true}
            noDataText="No rows found"
          />
        </div>
      </div>

      <Modal
        show={ddScheduleListModal}
        onHide={() => setDdScheduleListModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">Schedule List</h4>
        </Modal.Header>
        <Modal.Body>
          <DDScheduleList type="pending" data={scheduleItem} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoanHistory;
