import React, { useContext, useEffect } from "react";
import {
    GeneralButton,
    TextInput
} from "../../../common/inputs";
import { confirmAlert } from "react-confirm-alert";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { InputGroupMd } from "../../../common/containers/formContainer";
import { browserHistory } from "react-router";
import coreService from "../../../../services/coreService";
import useForm from '../../../../hooks/useForm';
import useApi from '../../../../hooks/useApi';
import EntityContext from "../../../../context/entityContext";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import { Helmet } from "react-helmet";
import { Link } from "react-router";
export default function ClientTerminologies() {

    //------------Api Services--------------------------------------------
    const userTerminologiesApi = useApi(coreService.saveUserTerminologies);
    const resetTerminologiesApi = useApi(coreService.resetUserTerminologies);

    //------------------ Validation Schema ------------------
    const schema = Joi.object() // Form validation schema object
        .keys({
            CUSTOMER: Joi.string().required().label("Customer"),
            SUPPLIER: Joi.string().required().label("Supplier"),
            PURCHASE_INVOICES: Joi.string().required().label("Purchase Invoices"),
            SALES_INVOICES: Joi.string().required().label("Sales Invoices"),
        }).unknown(true);

    const {
        entityDetails,
        populateClientDetails
    } = useContext(EntityContext);

    const handleUpdate = async () => {
        const response = await userTerminologiesApi.request({
            terminologies: values
        }); // Sending Async request to server

        if (response) {
            if (response.data.success === true) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-delete-ui">
                                <h4>All selected Terminology has been set</h4>
                                <p>All the terminology you selected to change has been updated</p>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        onClose();
                                        populateClientDetails({ value: entityDetails.a_guid });
                                        browserHistory.push('/client/client-terminologies'); 
                                    }}
                                >
                                    Close
                                </button>
                            </div >
                        );
                    },
                });
            }
            else
                toast.error(response.data.message);
        }
    }

    const handleResetTerminologies = async () => {
        const response = await resetTerminologiesApi.request()    

        if (response) {
            if (response.data.success === true) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-delete-ui">
                                <h4>All selected Terminology has been reset</h4>
                                <p>All the terminology you selected has been reset to the original terminology</p>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        onClose();
                                        populateClientDetails({ value: entityDetails.a_guid });
                                        browserHistory.push('/client/settings');
                                    }}
                                >
                                    Close
                                </button>
                            </div >
                        );
                    },
                });
            }
            else
                toast.success('Sorry, something went wrong.');
        }
    }

    //------------------ Custom hook to handle form data ------------------
    const { values, setValues, handleChange, handleSubmit } = useForm(handleUpdate, schema); // Custom Hook to handle Form Validation

    useEffect(() => {
        setValues(entityDetails.terminology);
    }, []);

    return (
        <>
            {userTerminologiesApi.loading && <LoadingSpinner />}

            <Helmet>
                <title>User Terminologies | {global.config.title}</title>
            </Helmet>

            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-lg-6"><h6>User Terminologies</h6></div>
                        <div className="col-lg-6">
                            <Link className="btn btn-light pull-right text-secondary ml-2 cursor-link" to="/client/settings"><i alt="Close and go to settings" data-toggle="tooltip" data-placement="top" title="Close and go to settings" className="fa fa-close"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="modal-body">
                        {entityDetails.terminology && Object.keys(entityDetails.terminology).filter(item => item !== 'exists').map((item, index) => {
                            return (
                                <InputGroupMd label={item.split('_').join(' ')} key={index}>
                                    <TextInput
                                        name={item}
                                        label={item}
                                        value={values[item]}
                                        onChange={handleChange}
                                        type=""
                                    ></TextInput>
                                </InputGroupMd>
                            );
                        })}
                    </div>

                    <div className="row mt-3">
                        <div className="col-lg-12 text-right">
                            <GeneralButton name="Reset" onClick={handleResetTerminologies} className="btn btn-outline-secondary"></GeneralButton>
                            <GeneralButton onClick={() => browserHistory.push('/client/settings')} name="Cancel" className="btn btn-light ml-2" type="button"></GeneralButton>
                            <GeneralButton name="Submit" onClick={handleSubmit} className="btn btn-primary ml-2"></GeneralButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}