import React from "react";
import Joi from "joi-browser";
import Form from "../../../../common/Form";
import { TextInput } from "../../../../common/inputs/textInput/textInput";
import Entity from "../../../services/entityService";
import EntityContext from "../../../../../context/entityContext";
import RoleChecker from "../../../../../utils/roleChecker";

class Confirmation extends Form {

  static contextType = EntityContext;

  constructor(props) {
    super(props);
  }

  state = {
    data: {
      termsConditions: true,
      business_name: "",
      email: "",
      first_name: "",
      last_name: "",
      signature: "",
    },

  };


  schema = Joi.object()
    .keys({
      signature: Joi.string().required().label("Digital Signature"),
      termsConditions: Joi.string()
        .valid(true)
        .required()
        .label("Terms & Conditions"),
    })
    .unknown(true);
  componentDidMount() {
    this.confirmation();
  }


  confirmation = async () => {
    const data = { ...this.state.data };
    const { success, message, user } = await Entity.getLoginedUserDetails();
    if (user) {
      data["business_name"] = user.organization;
      data["email"] = user.email;
      data["first_name"] = user.first_name;
      data["last_name"] = user.last_name;
      data["signature"] = this.state.data.signature;
    }
    this.setState({ data });

  };
  chnageConfirmation = async () => {
    const data = { ...this.state.data };
    data["confirmation"] = false;
    this.setState({ data });
  };
  onCloseModal = () => {
    this.setState({ open: false, selectedFeeType: [] });
  };
  setValues(selectedItem, input) {
    const data = { ...this.state.data };
    data[input] = selectedItem;
    this.setState({ data });
  }

  handleCheckboxChange = (event) => {
    const data = { ...this.state.data };
    data["termsConditions"] = event.target.checked;
    this.setState({ data });
    console.log(event.target.checked);
  };

  render() {
    const { data } = this.state;

    return (
      <React.Fragment>
        {(this.props.category == this.props.constants.CATEGORY.TRADERS && (RoleChecker.check(this.props.products, this.props.constants.ROLES.CUSTOMER) || RoleChecker.check(this.props.products, this.props.constants.ROLES.TFCUSTOMER )) ) && (
          <>
            <div className="scrollable-div">
              {/* <p  className ="opacity-75">
              The person who clicks 'I AGREE' below represents and warrants that he or she is the "Authorised Person"; specified above, is an "Authorised Person" as defined in the Customer Terms and Conditions and has the Customer's authority to bind the Customer.
              </p>
              <p className ="opacity-75">
              If the "Revised Due Date"3 is equal to the "Invoice Due Date"2 specified above the Customer will pay the "Approved Amount"1 above to Fifo on the 'Invoice Due Date"2 specified above.
              If the "Revised Due Date"3 is different to the "Invoice Due Date"2 specified above the Customer requests that it be allowed to pay on the "Revised Due Date"3 instead of the "Invoice Due Date"2 specified above. If Fifo agrees to that request the Customer will pay the "Total Amount to Pay"4 to Fifo. This amount is the "Approved Amount"1 specified above plus any "Late Payment Fee". Fifo's agreement will be shown by the status of the invoice changing on STREAM from "Awaiting Approval" to "Approved and Purchased".
              The Customer will pay the applicable amount to Fifo in full and without any counterclaim or set-off. The Customer must comply with the Customer Terms and Conditions and has the Customer's authority to bind the Customer and, in particular, confirms each of the items listed in clause 2.3, subject only to the above.
              </p> */}
              <p>
                The person who clicks 'I AGREE' below represents and warrants that he or she is the "Authorised Person" as defined in the Customer Terms and Conditions and has the Customer's authority to bind the Customer.
              </p>
              <p>
                If the "Revised Due Date"3 is equal to the "Invoice Due Date"2 specified above the Customer will pay the "Approved Amount"1 above to Fifo on the 'Invoice Due Date"2 specified above.
              </p>
              <p>If the "Revised Due Date"3 is different to the "Invoice Due Date"2 specified above the Customer requests that it be allowed to pay on the "Revised Due Date"3 instead of the "Invoice Due Date"2 specified above. If Fifo agrees to that request the Customer will pay the "Total Amount to Pay"4 to Fifo. This amount is the "Approved Amount"1 specified above plus any "Late Payment Fee". Fifo's agreement will be shown by the status of the invoice changing on this Platform to "Approved and Purchased".</p>
              <p>The Customer will pay the applicable amount to Fifo in full and without any counterclaim or set-off. The Customer must comply with the Customer Terms and Conditions and has the Customer's authority to bind the Customer and, in particular, confirms each of the items listed in clause 2.3, subject only to the above.</p>
              <p>
                I understand and agree to Fifo Capital's Terms and Conditions and Fifo Capital's Privacy Policy.</p>

              <ol className="opacity-75">
                <li>This is the “gross value of the Purchased Debt" specified in the Customer Terms and Conditions. It is the "Due Date Amount" specified in the Supplier Terms and Conditions.</li>
                <li>This is the "Invoice Due Date" specified in the Customer Terms and Conditions and the Supplier Terms and Conditions.</li>
                <li>This is the “Agreed Settlement Date” specified in the Customer Terms and Conditions.</li>
                <li>This is the "gross value of the Purchased Debt" plus the "Late Payment Fee" in the Customer Terms and Conditions.</li>
              </ol>
            </div>


            <div class="custom-control d-flex custom-checkbox mt-4">
              <input
                id={`customCheck1`}
                type="checkbox"
                className="custom-control-input"
                name="checkconfirm"
                onChange={e => this.handleCheckboxChange(e)}
                checked={data.termsConditions ? true : false}
              // disabled={itemRole.isDisabled ? itemRole.isDisabled : false}
              />
              <label class="custom-control-label" for={`customCheck1`}>
                {/* I, {data.first_name} {data.last_name} confirms that: */}
                I AGREE.
              </label>
            </div>

            <div className="  text-xs my-3 text-danger">
              <p>AS A DIGITAL SIGNATURE TO CONFIRM YOUR UNDERSTANDING OF AND AGREEMENT TO OUR TERMS AND CONDITIONS, TYPE YOUR FIRST AND LAST NAME HERE AS A LEGALLY BINDING ELECTRONIC SIGNATURE.*</p>
              {/* <p>TYPE YOUR FIRST AND LAST NAME HERE AS A LEGALLY BINDING ELECTRONIC SIGNATURE</p> */}
            </div>
            <div className="form-group">
              <TextInput name="signature" value={data.signature} label="Signature" reference={this.signature} onChange={this.handleChange} type="" ></TextInput>
            </div>
          </>
        )}


        {((this.props.category == this.props.constants.CATEGORY.BP || this.props.category == this.props.constants.CATEGORY.COUNTRY_OFFICE || this.props.category == this.props.constants.CATEGORY.INTL_OFFICE)) && (
          <>
            <div>


              <h6>Acknowledgement</h6>

              <p className="opacity-75">I am responsible for this Client and their Supplier, I agree that by adding this invoices and clicking the ‘submit’ button that I have received the invoice from the Client and I am held responsible for paying the Supplier as instructed by the Client.
                If I have not received this invoice, I confirm I have suitable confirmation that what I have entered here is correct and under instruction from the Client. I will only pay the Supplier after the Client selects the date they will pay.
              </p>
              <h6>CONFIRMATION*</h6>

              <p className="opacity-75">I have completed all my due diligence on the Client. I have completed all my due diligence on the Supplier. I have completed all my due diligence on the invoice in question and where I deem it necessary, I will complete one final check before making any payment to the Supplier.</p>
            </div>


            <div class="custom-control d-flex custom-checkbox">
              <input
                id={`customCheck1`}
                type="checkbox"
                className="custom-control-input"
                name="checkconfirm"
                onChange={e => this.handleCheckboxChange(e)}
                checked={data.termsConditions ? true : false}
              // disabled={itemRole.isDisabled ? itemRole.isDisabled : false}
              />
              <label class="custom-control-label" for={`customCheck1`}>
                I, {data.first_name} {data.last_name} confirm that:
              </label>
            </div>
            <div className="  text-xs my-3 text-danger">

              <p>USING MY DIGITAL SIGNATURE I AGREE WITH THE ABOVE ACKNOWLEDGEMENT AND CONFIRMATION</p>
              <p>TYPE YOUR FIRST AND LAST NAME HERE AS A LEGALLY BINDING ELECTRONIC SIGNATURE</p>
            </div>

            <div className="form-group">
              <TextInput name="signature" value={data.signature} label="Signature" reference={this.signature} onChange={this.handleChange} type="" ></TextInput>
            </div>
          </>
        )}



      </React.Fragment >
    );
  }
}
Confirmation.contextType = EntityContext;
export default Confirmation;