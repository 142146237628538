export const POPULATE_INVOICE = "transactions:populateInvoice";
export const ADD_INVOICE = "transactions:addInvoice";
export const UPDATE_INVOICE = "transactions:updateInvoice";
export const ADD_FEE = "transactions:addFeeDetails";
export const ADD_DISBURSEMENT = "transactions:addDisbursementDetails";
export const ADD_PARTICIPANTS = "transactions:addParticipantsDetails";
export const ADD_PAYMENTS = "transactions:addPaymentDetails";
export const RESET_TRANSACTION = "transactions:resetTransactionDetails";

/** -------------- */
/**--------------- Action dispatchers for 'Invoice' section --------------*/

export function resetTransactionData() {
  return {
    type: RESET_TRANSACTION,
    payload: {}
  };
}

export function populateInvoice(invoiceData) {
  return {
    type: POPULATE_INVOICE,
    payload: {
      invoice: invoiceData
    }
  };
}

export function addInvoice(transStoreData, transInvoiceData) {
  return {
    type: ADD_INVOICE,
    payload: {
      transStoreData: transStoreData,
      transInvoiceData: transInvoiceData
    }
  };
}

export function updateInvoice(transStoreData) {
  return {
    type: UPDATE_INVOICE,
    payload: {
      transStoreData: transStoreData
    }
  };
}

/**-------------------------------------------------------------*/

/** -------------- */
/**--------------- Action dispatchers for 'Fee' section --------------*/
export function AddFeeDetails(FeeData) {
  return {
    type: ADD_FEE,
    payload: {
      fee: FeeData
    }
  };
}

/** -------------- */
/**--------------- Action dispatchers for 'Disbursement' section --------------*/
export function AddDisbursement(transStoreData, transDisbursementData) {
  return {
    type: ADD_DISBURSEMENT,
    payload: {
      transStoreData: transStoreData
    }
  };
}

/** -------------- */
/**--------------- Action participants for 'Disbursement' section --------------*/
export function AddParticipants(ParticipantsData) {
  return {
    type: ADD_PARTICIPANTS,
    payload: {
      participants: ParticipantsData
    }
  };
}

/** -------------- */
/**--------------- Action participants for 'Disbursement' section --------------*/
export function AddPayments(PaymentsData) {
  return {
    type: ADD_PAYMENTS,
    payload: {
      payments: PaymentsData
    }
  };
}
