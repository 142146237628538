import React from "react";
import startIcon from "../../.././../img/icons/FMB_icon1.svg"
// React Context API fbc

import {
    GeneralButton,
} from "../../../common/inputs";

export default function Page1({ goNext, goPrevious }) {

    return (
        <>
            <div id="animation_box" className="animated fadeInLeft">
                <div className="mb-0">
                    <div className="card-body">
                        <div className="row min-vh-70 justify-content-center align-items-center py-5">
                        <div className="col-lg-5 col-md-12">
                            <div className="">
                            <h1>Finance My Business</h1>
                            <p>
                                Unravel the complexity of non-bank financing and solve all your cash
                                flow and working capital needs in one easy to access place
                            </p>
                            <GeneralButton
                                type="button"
                                onClick={() => goNext("sure")}
                                className="btn btn-primary   m-l-xs mt-4"
                                name="Let’s Get Started"
                            ></GeneralButton>
                            </div>
                        </div>
                            <div className="col-lg-4 offset-lg-1 col-md-12">
                                <img src={startIcon} alt="image" height={500} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}




