import React, { useEffect, useContext, useState } from "react";
import { PrimaryWithButton } from "../../components/cards";
import { CurrencyLabel } from "../../../common/inputs";
import useApi from '../../../../hooks/useApi';
import { Link } from "react-router";
import Modal from "react-bootstrap/Modal";
import useIsMounted from "../../../../hooks/useIsMounted";
import EntityContext from "../../../../context/entityContext";
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";
import { AddButton } from "../../../common/containers/button";
import AddSingleInvoice from "../../../debtorTransactions/components/addSingleInvoice";

function ProcessingTransactions() {

    const isMounted = useIsMounted();
    const { selectedBusinessType, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);
    const [addinvModal, setAddinvModal] = useState(false);
    let addBtn = ""; let bulkUploadBtn = ""; let dropDownBtnList = "";

    const handleAddBtnClick = () => {
        setAddinvModal(true);
    }

    dropDownBtnList = <Link className="dropdown-item" onClick={() => {handleAddBtnClick()}}>Add Single Invoice</Link>;

    bulkUploadBtn = <AddButton route="/client/wob/batch-upload" style="btn  btn-sm btn-secondary  mb-1 ml-2" label=" Bulk Upload"></AddButton>

    //------------Api Service--------------------------------------------
    const dashBoardSummaryApi = useApi(dfTransactionService.DashboardSummary);

    const fetchData = async () => {

        const response = await dashBoardSummaryApi.request({ client_guid: entityDetails.a_guid, type:'processing' });
        if(response && isMounted()){
        setData({
            topLabel: "Processing",
            topValue: <CurrencyLabel value={response.data.result.inv_amount} />,
            //leftLabel: response.data.isPrimary ? "Debtors" : "Customer",
            //leftLabel: "Debtors",
            //leftValue: response.data.result.debtor_count ,
            //rightLabel: "Invoices",
            //rightValue: response.data.result.inv_count,
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>
            <PrimaryWithButton
                loading={dashBoardSummaryApi.loading}
                data={data}
                qrystring={true}
                bulkUploadBtn={bulkUploadBtn}
                addBtn={addBtn}
                dropDownList={dropDownBtnList}
            />

            {/*-------------add single invoice modal ------------------------*/}

        <Modal
          show={addinvModal}
          onHide={() => { setAddinvModal(false); }}
          backdrop="static"
          center
          className="animated bounceInRight "
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Add Invoice</h4>
          </Modal.Header>

          <Modal.Body>
          <AddSingleInvoice
            onClose={() => {
                setAddinvModal(false);
            }}
            handleReload={() => { fetchData(); }}
            client_invoice_upload={true}
          ></AddSingleInvoice>
          </Modal.Body>
        </Modal>
        {/*-------------add single invoice modal ends ------------------------*/}
        </>
    )
}
export default ProcessingTransactions;