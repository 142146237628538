import React, { Component } from "react";
import MappedUsers from "./mappedUsers";
import DraftAccounts from "./draftAccounts";
import EntityContext from "../../../../context/entityContext";
import { Link } from "react-router";
import { Helmet } from "react-helmet";
import { browserHistory } from "react-router";
import authService from "../../../../services/authService";

class ClientNetworkCustomer extends Component {

    static contextType = EntityContext;

    constructor(props) {
        super(props);
        this.state = {
            showChild: true,
            enabledProducts: [],
            showDraft: false,
            cardToggle:true,
        }
    }
    handleReadloadChild = () => {
        this.setState({
            showChild: false
        })

        setTimeout(() => {
            this.setState({
                showChild: true
            })
        }, 100);
    }

    handleReloadAll = () => {
        this.setState({
            showChild: false,
            showDraft: false,
        })

        setTimeout(() => {
            this.setState({
                showChild: true,
                showDraft: true,
            })
        }, 100);
    }

    componentDidMount() {
        this.context.handleRoleChange(null);
        this.context.handleProductChange(null);
        this.checkIsProductEnabled();
    }
    checkIsProductEnabled = () => {
        const { constants } = authService.getCurrentUser();
        let enabledProducts = [];
        if (this.context.entityDetails !== undefined) {
            enabledProducts = this.context.entityDetails.products.filter(obj =>
            (obj.isChecked === true && (
                obj.id == constants.PRODUCTS.INVOICE ||
                obj.id == constants.PRODUCTS.DEBTORFINANACE
            )
            ));
            if (enabledProducts.length > 0) {
                this.setState({ showDraft: true });
                window.scrollTo(0, 0);
            } else {
                if (this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.getStart !== undefined && this.props.location.state.getStart === true) {
                    this.setState({ showDraft: true });
                    window.scrollTo(0, 0);
                } else {
                    browserHistory.push({
                        pathname: '/client/users-network/start-customers',
                    });
                }

            }
            this.setState({ enabledProducts: enabledProducts });

        } else {
            this.setState({ enabledProducts: [] });
        }

    }

    toggleCard = () => {
        this.setState({cardToggle : !this.state.cardToggle});
    }

    render() {

        return (
            <>
                <Helmet>
                    <title>{this.context.terminology && this.context.terminology.CUSTOMER}s | {global.config.title}</title>
                </Helmet>
                {
                    this.state.enabledProducts && this.state.enabledProducts.length > 0 && (
                        <>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card ">
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-lg-10">
                                                    <h6>Linked {this.context.terminology && this.context.terminology.CUSTOMER}s</h6>
                                                </div>
                                                <div className="col-lg-2">
                                                <div className="pull-right" >
                                                        <Link title={` ${this.state.cardToggle ? "Show Less" : "Show More"}  `} className="btn btn-light text-secondary mr-2" onClick={() => { this.toggleCard() }}>
                                                            <i className={`fa fa-angle-${this.state.cardToggle ? "up" : "down"}`}></i>
                                                        </Link>
                                                        <Link title="Close and go to dashboard" className="btn btn-light text-secondary" to="/client/home">
                                                            <i className="fa fa-close "></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`card-body ${this.state.cardToggle ? "" : "hide"}`}>

                                            {this.state.showChild ?
                                                <MappedUsers
                                                    location={this.props.location}
                                                    accountType="account"
                                                    title="Customer"
                                                    user_type="customer"
                                                    reloadAll={this.handleReloadAll}
                                                /> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    this.state.showDraft === true && (
                        <>
                            <div className="row">
                                <div className="col-sm-12">

                                    <DraftAccounts
                                        type="customer"
                                        reloadChild={this.handleReadloadChild}
                                        action={
                                            this.props.location.query.action ?
                                                this.props.location.query.action : null
                                        }
                                        showAssign={this.state.enabledProducts.length > 0 ? true : false}
                                    />

                                </div>
                            </div>
                        </>
                    )
                }

            </>
        );
    }
}
export default ClientNetworkCustomer;