import React, { useEffect, useContext, useState } from "react";
import { WarningCard } from "../../components/cards";
import { CurrencyLabel } from "../../../common/inputs";
import useApi from '../../../../hooks/useApi';
import useIsMounted from "../../../../hooks/useIsMounted";
import EntityContext from "../../../../context/entityContext";
import dfTransactionService from "../../../debtorTransactions/services/dfTransactions";

function FundsDrawn({ invData = null }) {

    const isMounted = useIsMounted();
    const { selectedBusinessType, entityDetails } = useContext(EntityContext);
    const [data, setData] = useState(null);

    //------------Api Service--------------------------------------------
    const invoiceSummaryApi = useApi(dfTransactionService.InvoiceSummary);

    const fetchData = async () => {

        if (invData == null) {
            const response = await invoiceSummaryApi.request({
                trans_client_guid: entityDetails.a_guid,
                trans_type: "open",
                dateFilter: new Date(),
            });
            if (response && isMounted()) {
                invData = response.data;
            }
        }

        setData({
            topLabel: "Funds Drawn",
            topValue: <CurrencyLabel value={invData.invoiceSummary.funds_drawn} />,
        })
    }

    useEffect(() => {
        fetchData();
    }, [selectedBusinessType, entityDetails]);

    return (
        <>
            <WarningCard
                //loading={invoiceSummaryApi.loading}
                data={data}
                qrystring={true}
            />
        </>
    )
}
export default FundsDrawn;