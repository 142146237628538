import React, { useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import NumberFormat from "react-number-format";
import auth from "../../../../../src/services/authService";

const getCurrencySymbol = () => {
  const user = auth.getCurrentUser();
  return user.currency_symbol;
};

const CurrencyText = props => (
  <NumberFormat
    thousandSeparator={true}
    prefix={!props.currencySymbol ? getCurrencySymbol() : props.currencySymbol}
    className={
      props.type == "normal" ? "form-control" : "form-control input-sm"
    }
    decimalScale={2}
    fixedDecimalScale={true}
    inputMode="numeric"
    placeholder={props.placeholder}
    onValueChange={values => {
      const { formattedValue, value } = values;
      props.onChangeEvent(null, null, value);
    }}
    onBlur={props.onBlur && props.onBlur}
    value={props.value === null ? "" : props.value}
    autoFocus={props.autoFocus && true}
    disabled={props.isDisabled}
    getInputRef={props.reference}
    onFocus={
      e => {
        if(props.value > 0){}
        else
          props.onChangeEvent(null, null, null);
      }
    }
    isAllowed={(props.maxValue >= 0) ? values => {
      const { formattedValue, floatValue } = values;
      return formattedValue === "" || floatValue <= props.maxValue;
    } : undefined}

    onKeyPress={props.onKeyPress && props.onKeyPress}
  />
);

const CurrencyLabel = props => {

    //  value is first multiplied by 100, rounded to the nearest whole number, and then divided by 100 to get the rounded decimal value with 2 decimal places. 
    //const roundedValue = props.value ? Math.round(props.value * 100) / 100 : 0; 
    let decimals = props.decimal ? props.decimal : 2;

    return(
      <CurrencyFormat
        value={props.value ? parseFloat(props.value).toFixed(decimals) : 0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={props.currencySymbol ? props.currencySymbol : getCurrencySymbol()}
        decimalScale={decimals}
        decimalSeparator={"."}
        fixedDecimalScale={true}
        onDoubleClick={props.onDoubleClick && props.onDoubleClick}
      />
    )
};

export { CurrencyText, CurrencyLabel };
