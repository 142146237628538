import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router";
import { CurrencyLabel } from "../../../../common/inputs";
const TopSuppliers = () => {
  return (
    <div className="card ml-3">
      <div className="card-header">
        <div class="row mt-4">
          <div class="col">
            <h5 class="card-title ml-2 mt-2">Top Suppliers (Lifetime) </h5>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="feed-activity-list">
          <div className="feed-element">
            <span
              aria-expanded="false"
              to="/your-link-path"
              className="media-icon d-flex text-secondary bg-light pull-left font-weight-bold mr-1"
            >
              1
            </span>
            <div className="media-body">

              <span
                aria-expanded="false"
                to="/your-link-path"
                className="p-2 font-weight-bold  d-flex bg-success-transparent pull-right"

              >
                $42,052.00
              </span>
              <strong>Supplier Business Name</strong>  <br />
              <small className="text-muted">Mid season program</small>
            </div>
          </div>
          <div className="feed-element">
            <span
              aria-expanded="false"
              to="/your-link-path"
              className="media-icon d-flex text-secondary bg-light pull-left font-weight-bold mr-1"
            >
              2
            </span>
            <div className="media-body">

              <span
                aria-expanded="false"
                to="/your-link-path"
                className="p-2 font-weight-bold  d-flex bg-success-transparent pull-right"

              >
                $25,052.00
              </span>
              <strong>Supplier Business Name</strong>  <br />
              <small className="text-muted">Mid season program</small>
            </div>
          </div>
          <div className="feed-element">
            <span
              aria-expanded="false"
              to="/your-link-path"
              className="media-icon d-flex text-secondary bg-light pull-left font-weight-bold mr-1"
            >
              3
            </span>
            <div className="media-body">

              <span
                aria-expanded="false"
                to="/your-link-path"
                className="p-2 font-weight-bold  d-flex bg-success-transparent pull-right"

              >
                $21,250.00
              </span>
              <strong>Supplier Business Name 3</strong>  <br />
              <small className="text-muted">Mid season program</small>
            </div>
          </div>
          <div className="feed-element">
            <span
              aria-expanded="false"
              to="/your-link-path"
              className="media-icon d-flex text-secondary bg-light pull-left font-weight-bold mr-1"
            >
              4
            </span>
            <div className="media-body">

              <span
                aria-expanded="false"
                to="/your-link-path"
                className="p-2 font-weight-bold  d-flex bg-success-transparent pull-right"

              >
                $18,540.00
              </span>
              <strong>Supplier Business Name 2</strong>  <br />
              <small className="text-muted">Mid season program</small>
            </div>
          </div>
        </div>







      </div>
    </div>
  );
};

export default TopSuppliers;
