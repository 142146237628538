import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import * as moment from "moment";
import { toast } from "react-toastify";
import {
    CurrencyText,
    CurrencyLabel,
    GeneralButton,
    TextInput
} from "../../../common/inputs";
import { DateTimePicker } from "../../../common/inputs";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import Utility from "../../../../utils/DateParser";
import useApi from "../../../../hooks/useApi";

import transactionService from "../../../../services/invTransactions";
import authService from "../../../../services/authService";

export default function RequestPayment({ data, onClose, facilitySettings }){

    const { ParseDate } = Utility();
    const [paymentDate, setPaymentDate] = useState(null);
    const [refNum, setRefNum] = useState(null);
    const [fee, setFee] = useState(null);
    const [invoices, setInvoices] = useState(data);
    
    const { country_id, entity_id } = authService.getCurrentUser();
    const defaultsApi = useApi(transactionService.disburseOnLoadData);
    const transactionApi = useApi(transactionService.createSingleInvoice);
    
    const handleSubmit = async() => {

        // ------------------------- Validation checks --------------------------------- //
        if(!paymentDate)
        {
            toast.error("Please select the payment date.");
            return;
        }

        if(invoices.filter(item => item.inv_adv_percent > 0).length !== invoices.length){
            toast.error("Advance amount cannot be empty.");
            return;
        }

        // Validation: Advance date should not precede Invoice date
        let currentDate = moment(paymentDate);

        let moments = invoices.map(d => moment(d.inv_date)), minInvDate = moment.min(moments)
        
        if(currentDate < minInvDate)
        {
            toast.error('Advance date cannot precede the Invoice date');
            return;
        }

        // Validation: Advance date should not cross Invoice due date
        let maxInvDateMoments = invoices.map(d => moment(d.inv_due_date)), maxInvDate = moment.min(maxInvDateMoments)
        if(currentDate > maxInvDate)
        {
            toast.error('A transaction Advance cannot take place after the Invoice Due Date. Please edit entry to continue.');
            return;
        }
        // ----------------------------------------------------------------------------- //

        let invData = data.map((item) => {
            item.adv_date = paymentDate;
            return item;
        });
               
        const reqData = {
            trans_guid: null,
            invoices: invData,
            files: []
        }

        const response = await transactionApi.request(reqData);
        if (response.data.success === false)
            toast.error(response.data.message);
        else {
            toast.success('Payment request has been successfully submitted.');
            onClose();
        }
    }
    
    const fetchDefaults = async () => {
        const response = await defaultsApi.request({
            client: entity_id,
            country: country_id,
        });

        setRefNum(response.data.ref_number);
        setFee(response.data.defaultFee);
    }

    const handlePercentEdit = (row, index = null, percentage = null) => {

        let invClone = [...invoices];

        if (percentage !== null && percentage <= 100) {
            if (percentage == "") {
                percentage = 0;
                invClone[index].inv_adv_percent = "";
                invClone[index].inv_adv_amount = "";
                invClone[index].disb_amount = "";
            } else {
                invClone[index].inv_adv_percent = percentage;
                invClone[index].inv_adv_amount = (row.inv_chargeable_amt * percentage) / 100;
                invClone[index].disb_amount = invClone[index].inv_adv_amount;
                invClone[index].edit = true;
            }
            setInvoices(invClone);
        }
    };

    const setDefaultAdvPctg = () => {
        let invClone = invoices.map( item => {
            if(item.inv_adv_percent > 0)
                return item;
            else{
                item.inv_adv_percent = 80;
                item.inv_adv_amount = (item.inv_chargeable_amt * item.inv_adv_percent) / 100;
                item.disb_amount = item.inv_adv_amount;
                item.edit = true;
                return item;
            }
        });
        setInvoices(invClone);
    }

    useEffect(() => {
        fetchDefaults();

        if(facilitySettings && facilitySettings.can_set_advance_amount === "no")
            setDefaultAdvPctg();
    }, [])

    return (
        <>

            {(defaultsApi.loading === true || transactionApi.loading === true) && <LoadingSpinner />}

            <Modal.Header closeButton>
                <h4 className="modal-title">Request Payment</h4>
            </Modal.Header>

            <Modal.Body>
                <div className="row dyn-height">
                    <div className="col-lg-12">
                        <table class="table table-sm text-secondary">
                            <tbody>
                                <tr>
                                    <th>Debtor</th>
                                    <th>Inv No</th>
                                    <th>Inv Date</th>
                                    <th>Inv Due Date</th>
                                    <th>Inv Amount</th>
                                    <th>Agreement Amt</th>
                                    <th>Advance %</th>
                                    <th>Advance Amount</th>
                                </tr>

                                {invoices.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.debtor_organisation}</td>
                                        <td>{item.inv_number}</td>
                                        <td>{moment(item.inv_date, "YYYY/MM/DD").format("DD/MM/YYYY")}</td>
                                        <td>{moment(item.inv_due_date, "YYYY/MM/DD").format("DD/MM/YYYY")}</td>
                                        <td><CurrencyLabel value={item.inv_amount}></CurrencyLabel></td>
                                        <td><CurrencyLabel value={item.inv_chargeable_amt}></CurrencyLabel></td>
                                        <td>
                                            {facilitySettings && facilitySettings.can_set_advance_amount === "yes" ?
                                                <TextInput
                                                    name="adv_percent"
                                                    label="Adv %"
                                                    value={item.inv_adv_percent}
                                                    onChange={(e) => {
                                                        handlePercentEdit(item, index, e.target.value);
                                                    }}
                                                    type="normal"
                                                ></TextInput>
                                                : `${item.inv_adv_percent}%`
                                            }
                                        </td>
                                        <td>
                                            <CurrencyLabel value={item.inv_adv_amount}></CurrencyLabel>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-lg-12 m-t-sm">
                    <div class="p-4">
                        <div className="form-group row">
                            <label className="col-lg-4" style={{ paddingTop: "7px" }}>
                                Payment Date
                            </label>

                            <div className="col-lg-4">
                                <DateTimePicker
                                    name="disb_later_date"
                                    label="Payment Date"
                                    selectedDate={paymentDate}
                                    onChange={e => setPaymentDate(e.target.value)}
                                    minDate={new Date()}
                                  />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-t-sm">
                    <div className="col-lg-12 ">
                        <GeneralButton
                            type="button"
                            faIcon="fa fa-save"
                            className="btn btn-primary pull-right m-l-xs"
                            name="Request Payment"
                            onClick={handleSubmit}
                        ></GeneralButton>
                    </div>
                </div>
            </Modal.Body>
        </>
    );
}