import React, { useRef, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../../../common/layout';
import NotAuthorized from "../../../common/widgets/notAuthorized";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import authService from "../../../../services/authService";
import RequestPayment from "./requestPayment";
import PendingRequest from "./pendingRequest";
import ReconcilePayment from "./reconcilePayment";
import UsersList from "../../../externalapis/components/usersList";
import LoanHistory from "./loanHistory";

function DirectDebit(props) {

    const { constants, category } = authService.getCurrentUser();

    const [activeTab, setActiveTab] = useState('RequestPayment');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const tabComponents = {
        PendingRequest: <PendingRequest />,
        RequestPayment: <RequestPayment />,
        ReconcilePayment: <ReconcilePayment/>,
        History:<LoanHistory />,
        RegisteredUsers: <UsersList product={constants.PRODUCTS.LOAN} />,
    };



    return (
        <>

            <Helmet>
                <title>Loan Direct Debit | {global.config.title}</title>
            </Helmet>
            <BreadCrumbs
                Header="Loan Direct Debit"
                activeModule="Loan Direct Debit"
                Route1="/dashboard"
                Label1="Dashboard"
            />
            {
                (constants.CATEGORY.INTL_OFFICE === category || constants.CATEGORY.COUNTRY_OFFICE === category) ? (
                    <>
                        <div className="card overflow-visible">
                            <div className="card-header ">
                                <div className="tabs-container">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li
                                            className={activeTab === 'RequestPayment' ? 'nav-item active' : 'nav-item'}
                                            onClick={() => handleTabClick('RequestPayment')}
                                        >
                                            <a className="nav-link">Request Payment</a>
                                        </li>
                                        <li
                                            className={activeTab === 'PendingRequest' ? 'nav-item active' : 'nav-item'}
                                            onClick={() => handleTabClick('PendingRequest')}
                                        >
                                            <a className="nav-link">Awaiting Payments</a>
                                        </li>
                                        <li
                                            className={activeTab === 'ReconcilePayment' ? 'nav-item active' : 'nav-item'}
                                            onClick={() => handleTabClick('ReconcilePayment')}
                                        >
                                            <a className="nav-link">Reconcile Payments</a>
                                        </li>
                                        <li
                                            className={activeTab === 'History' ? 'nav-item active' : 'nav-item'}
                                            onClick={() => handleTabClick('History')}
                                        >
                                            <a className="nav-link">History</a>
                                        </li>
                                        <li
                                            className={activeTab === 'RegisteredUsers' ? 'nav-item active' : 'nav-item'}
                                            onClick={() => handleTabClick('RegisteredUsers')}
                                        >
                                            <a className="nav-link">Registered Users</a>
                                        </li>
                                    </ul>

                                </div>

                            </div>
                            <div className="card-body">
                                {tabComponents[activeTab]}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <NotAuthorized />
                    </>
                )
            }
        </>
    )
}
export default DirectDebit;