import React, { useEffect, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { FormGroup } from "../common/containers/formContainer";
import { Picker } from '../common/inputs';
import authService from "../../services/authService";
import coreService from "../../services/coreService";
import useApi from '../../hooks/useApi';

function CountriesList({ onSelectItem, type = null, selectedCountry = null, label = null  , isRequired = null}) {

    const { category, country_id } = authService.getCurrentUser();
    const [selectedItem, setSelectedItem] = useState({ label: "Country", value: null });
    const { request, data, loading } = useApi(coreService.getCountriesList); // Custom hook for HTTP Requests

    const RenderPicker = () =>
        <Picker
            items={data}
            selectedItem={selectedItem}
            onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
            loading={loading}
        />

    const fetchCountriesList = async () => {
        await request(); // Calling HTTP service
        if (category === "Country Office" || category === "Business Partner") // Set default country office if logged in user is a Master Franchise
            onSelectItem({ id: country_id })
    }

    useEffect(() => {
        fetchCountriesList();
        // returned function will be called on component unmount 
        return () => {
            //clientRequest.cancel();
        }
    }, []);

    /**----------------Set selected country based on given Id-----------------*/
    if (selectedCountry !== null && selectedItem.value === null && data.length > 0) {
        const { label, id } = data.filter(obj => obj.id == selectedCountry)[0];
        setSelectedItem({ label, value: id })
    }
    /**----------------------------------------------------------------------*/

    return (
        <>
            {category == "International Office" && (
                <>
                    {loading == true && <LoadingSpinner />} {/** Activating the loading spinner */}
                    {(label !== null && type !== "vertical") ?
                        <FormGroup
                            type={type}
                            label={label}
                            input={<RenderPicker />}
                        />
                        :
                        (label !== null && type === "vertical") ?
                            <div className={ isRequired !== null && isRequired == "true" ? "form-group required" : "form-group"}>
                                <label className={ isRequired !== null && isRequired == "true" ? "control-label" : ""}>{label}</label>
                                <RenderPicker />
                            </div>
                            :
                            <RenderPicker />
                    }
                </>
            )}
        </>
    )
};
export default CountriesList;