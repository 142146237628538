import React, { useState, useEffect } from "react";
import { useUploader } from "../../../../context/supplierUploaderContext";
import { FormColumnLg } from "../../../common/containers/formContainer";
import { GeneralButton } from "../../../common/inputs";
import { browserHistory } from "react-router";
import authService from "../../../../services/authService";

import ExcelFileUploader from "../../../common/inputs/fileUploader/ExcelUploader";


export default function Upload({ selectedProduct }) {

    const { constants } = authService.getCurrentUser();
    const [productType, setProductType] = useState("");

    const { handleUploadItems, uploadedFiles, setProceedNext, sethidePreviousBtn } = useUploader();

    sethidePreviousBtn(true);

    const spreadSheetFileTypes = ["xls", "xlsx", "ods"]; // Allowed spreadsheet files to upload.

    let excelFile = uploadedFiles.find((file) =>
        spreadSheetFileTypes.includes(file.name.split(".").pop())
    );
    // Enable the next button only if it contains an excel sheet.
    if (excelFile) setProceedNext(true);
    else setProceedNext(false);


    useEffect(() => {
        if (selectedProduct == constants.PRODUCTS.SUPPLYCHAIN) {
            setProductType('scf');
        } else {
            setProductType('tf');
        }
    }, []);

    return (
        <>
            <div className="row justify-content-center mt-5" >
                <ExcelFileUploader handleUploadItems={handleUploadItems} />
                <div className="row" style={{ margin: 15 }}>
                    <FormColumnLg col="12 text-center">
                        <div className="ml-auto">
                            <a htmlFor="download" className="btn btn-link text-primary" href={'/templates/supplier_bulk_template.xls'}><i className="fa fa-download mr-1"></i>Download Sample Template File</a>
                        </div>

                    </FormColumnLg>
                </div>

            </div>
            <div className="row" style={{ margin: 15 }}>
                <FormColumnLg col="6">

                    {uploadedFiles.length > 0 && (
                        <>
                            <p className="font-bold"> Uploaded files list.</p>
                            <div className="mt-3">
                                {excelFile && (
                                    <p className="font-bold opacity-75"> <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "} {excelFile.name} </p>
                                )}

                            </div>
                        </>
                    )}
                </FormColumnLg>

                <FormColumnLg col="6">
                    {excelFile && (
                        <p className="font-bold text-right">  Click next button to proceed. </p>
                    )}

                </FormColumnLg>
                <div className="row">
                    <GeneralButton className="btn  btn-outline-secondary  btn-sm  m-r-sm font-bold align-center" onClick={() => browserHistory.push("/client/" + productType + "/dashboard")} name={`Return to Dashboard`} ></GeneralButton>
                </div>
            </div>
        </>
    )
}