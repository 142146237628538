
import React, { useState } from "react";
import startIcon10 from "../../.././../img/icons/FMB_icon10.svg";
import {
    AddButton,
} from "../../../common/inputs";
import { RadioButton } from "../../../common/inputs/radioButton/radioButton";

export default function Page3({ goNext, goPrevious, handleChange }) {
    const [cashFlow, setCashflow] = useState(null)

    const [continueButton, setContinueButton] = useState(cashFlow ? "primary" : "dark");

    const improveCashFlows = (data) => {
        setContinueButton("primary");
        setCashflow(data)
    }
    const goNextPage = () => {
        console.log({ cashFlow });
        if (cashFlow === 'Yes') {
            goNext('workingCapitalQuestions');
        }
        else {
            goNext('finalQuestions');
        }
    }
    return (
        <>
            <div id="animation_box" className="animated fadeIn">
                <div className="mb-0">
                    <div className="card-body">
                        <div className="row vh-70 justify-content-center align-items-center py-5">
                            <div className="col-lg-3  col-md-12">
                                <div className="operate-image">
                                    <img src={startIcon10} alt="image" width className="" />
                                </div>
                            </div><div className="col-lg-6 offset-lg-1 col-md-12">
                                <div className>
                                    <h4>Next Up Working Capital…</h4>
                                    <h6 className="mt-3 mb-4">While cash flow finance assists you with inward cash flows, working capital finance gives you the financial muscle to ensure suppliers payments terms are met</h6>
                                    <h4>Working Capital Finance</h4>
                                    <p>Working capital finance gives you the additional cash to pay for your purchases, and negotiate better terms or prices with your suppliers.
                                    </p><p>Working capital finance is the smart way to:
                                    </p>
                                    <ul className="list-unstyled ml-4">
                                        <li><i className="fa fa-check mr-2" />Increase your buying power
                                        </li>
                                        <li><i className="fa fa-check mr-2" />Extend your payment terms with suppliers
                                        </li>
                                        <li><i className="fa fa-check mr-2" />Have the funds on hand to capitalise on suppliers discounts
                                        </li>
                                        <li className="d-flex"><i className="fa fa-check mr-2" /><span>Manage your purchases inline with the payment terms your customers demand</span>
                                        </li>
                                    </ul><p>Smart management of your supply chain doesn’t come easier than when you have significant working capital, empowering you to take control of your supplier relationships.
                                    </p><div className="pt-3">
                                        <p className="font-medium">Are you looking to improve your working capital?  </p>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <RadioButton
                                                name="customRadioInline1"

                                                value="Yes"
                                                checked={cashFlow === 'Yes' ? true : cashFlow === 'No' ? false : null}
                                                onChange={(e) => {
                                                    improveCashFlows(e.target.value);
                                                }}
                                                id="Yes"
                                                label="Yes"
                                                className="custom-control custom-radio mt-2"
                                            ></RadioButton>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <RadioButton
                                                name="customRadioInline1"
                                                value="No"
                                                checked={cashFlow === 'No' ? true : cashFlow === 'Yes' ? false : null}
                                                onChange={(e) => {
                                                    improveCashFlows(e.target.value);
                                                }}
                                                id="No"
                                                label="No"
                                                className="custom-control custom-radio mt-2"
                                            ></RadioButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-10"><div className="d-flex justify-content-end align-items-center mt-3">
                                <a href="#" onClick={() => goPrevious('cashFlowChoose')} className="btn btn-light mr-1">Back</a>
                                <AddButton disabled={cashFlow ? false : true} onClick={() => goNextPage()} label="Continue" style={`btn btn-${continueButton} align-center ml-1`}></AddButton>
                            </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

