import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";
import { browserHistory } from "react-router";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";

const FinishPage = () => {
  const {
    data,
    handleSubmit,
    showSubmit,
    setView,
    editShow,
    fetchSupplierData,
    fetchDefaultRate,
    fetchList,
    successLoader,
    setModel,
    finalStage
  } = useContext(SupplierProgramContext);

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      {successLoader && <LoadingSpinner />}
      {showSubmit ? (
        <>
          <center>
            <div className="mb-4 mt-4">
              <>
                <p>
                  {editShow ? (
                    <p>
                      Your supplier program ,
                      <span style={{ fontWeight: "bold" }}>
                        {data.program_name}
                      </span>{" "}
                      has been succesfully updated.
                    </p>
                  ) : (
                    <p>
                      Your new supplier program ,
                      <span style={{ fontWeight: "bold" }}>
                        {data.program_name}
                      </span>{" "}
                      has been succesfully created.
                    </p>
                  )}
                </p>
                <p className="mt-3">
                  <p>
                    The program will commence in{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data.commencement_date}
                    </span>{" "}
                    before going live for suppliers on{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data.program_date}
                    </span>
                  </p>
                </p>
                <p className="mt-3">
                  <p>
                    To view or edit this program , please return to your Supplier
                    Network Dashboard.
                  </p>
                </p>
              </>

              <br />
              <br />
            </div>
          </center>
          <div className="row mt-2">
            <div
              className="col-lg-12"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Link
                className="action-icons"
                style={{ color: "grey" }}
                //to={`/client/home`}
                onClick={() => {
                  localStorage.setItem("step", "0");
                  localStorage.setItem("view", true);
                  setView(true);
                  fetchSupplierData();
                  fetchDefaultRate();
                  fetchList();
                  setModel(false);
                }}
              >
                <button className="btn btn-primary ml-2 mb-4">
                  <span>Continue </span>
                </button>
              </Link>
              <button
                className="btn btn-white ml-2 mb-4 mb-4"
                onClick={() => {
                  localStorage.setItem("step", "0");
                  localStorage.setItem("view", true);
                  setView(true);
                  fetchSupplierData();
                  fetchDefaultRate();
                  fetchList();
                  setModel(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </>
      ) : (
        finalStage &&
        <h4 style={{ color: "red", justifyContent: "center", display: "flex" }}>
          Something went wrong...!
        </h4>
      )}
    </>
  );
};

export default FinishPage;
