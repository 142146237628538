import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";

const SearchComponents = ({
  options,
  renderOptionContent,
  searchKeyChange,
  selectedSearchTerm,
  handleChange,
  userInteracted,
  onMenuClose,
  ep = false,
  isDisabled = false
}) => {
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [options]);

  return (
    <div className="form-group mb-0 col-md-12 p-1">
      <Select
        key={ep ? key : undefined}
        options={options}
        placeholder="Search here"
        components={{
          Option: ({ innerProps, label, data }) => (
            <div {...innerProps}>{renderOptionContent(data)}</div>
          ),
        }}
        isDisabled={isDisabled}
        onInputChange={searchKeyChange}
        onChange={handleChange}
        //menuIsOpen={userInteracted === true && options.length > 0}
        //onMenuClose={onMenuClose}
        value={selectedSearchTerm}
        theme={(theme) => ({
          ...theme,
          borderRadius: ".375rem",
          colors: {
            ...theme.colors,
            text: "black",
            primary25: "white",
            primary: "#1ab394",
            neutral0: "white",
          },
        })}
      />
    </div>
  );
};

export default SearchComponents;
