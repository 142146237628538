import client from "../../../services/serviceClient";

// Service to create a new invoice transaction
const createDFTransaction = (params) =>
  client.post(`/dftransactions/transactions/create-transaction`, { ...params });
// Service to upload invoice details
const createInvoice = (params) =>
  client.post(`/dftransactions/transactions/create-invoices`, { ...params });
// Service to generate new system id for the transaction
const generateRefNum = (params) =>
  client.post(`/dftransactions/transactions/get-reference-number`, {
    ...params,
  });
// Service to fetch Transaction details by guid
const getTransactionDetails = (params) =>
  client.post(`/dftransactions/transactions/get-client-transaction-details`, {
    ...params,
  });
// Service to fetch the list of invoices
const getInvoiceList = (params, status) =>
  client.post(
    `/dftransactions/transactions/get-invoice-list?XDEBUG_SESSION_START=PHPSTORM`,
    { ...params, status }
  );

// Quick transaction search
const quickSearch = (params) =>
  client.post(`/dftransactions/transactions/quick-trans-search`, { ...params });
// Service to approve/decline invoices
const approveInvoices = (params) =>
  client.post(`/dftransactions/transactions/approve-invoice`, { ...params });
// -------------- Transaction Widgets -------------- //

// Transactions Availability
const getOpenTransactions = (params) =>
  client.post(`/dftransactions/widgets/open-transactions`, { ...params });

// Transactions Allocate Receipt
const createPayment = (params) =>
  client.post(`/dftransactions/transactions/allocate-receipt`, { ...params });

// GEt last account detail of debtor
const getAccountDetail = (params) =>
  client.post(`/dftransactions/transactions/get-account-detail`, { ...params });

// GEt client invoices by status
const getInvoicesByStatus = (params) =>
  client.post(`/dftransactions/transactions/get-client-invoices-by-status`, {
    ...params,
  });

// Reconsile Invoices
const reconsileInvoices = (params) =>
  client.post(`/dftransactions/transactions/reconsile-invoices`, { ...params });

// DF Settings
const getDefaultSettings = (params) =>
  client.post(`/dftransactions/transactions/get-default-settings`, {
    ...params,
  });

// Save DF Settings
const saveDefaultSettings = (params) =>
  client.post(`/dftransactions/transactions/save-default-settings`, {
    ...params,
  });

// Get client transaction summary for reconsilation
const getClientTransactionSummary = (params) =>
  client.post(`/dftransactions/transactions/get-client-transaction-summary`, {
    ...params,
  });

const getDebtorInvoiceByClient = (params) =>
  client.post(`/dftransactions/transactions/get-debtor-invoice-by-client`, {
    ...params,
  });
const createDisburse = (params) =>
  client.post(`/dftransactions/transactions/create-disbursement`, {
    ...params,
  });
const debtorDisapprove = (params) =>
  client.post(`/dftransactions/transactions/debtor-disapproval`, {
    ...params,
  });

const DisapproveInvoices = (params) =>
  client.post(`/dftransactions/transactions/disapprove-invoices`, {
    ...params,
  });

const TransactionByBatch = (params) =>
  client.post(`/dftransactions/transactions/transaction-by-batch`, {
    ...params,
  });

const ReopenInvoice = (params) =>
  client.post(`/dftransactions/transactions/reopen-invoice`, {
    ...params,
  });
// Service to get client graph
const getClientGraph = params => client.post(`/dftransactions/transactions/get-client-graph`, { ...params });
// Service to get client setting limit&rate
const getClientSetting = params => client.post(`/dftransactions/transactions/get-client-setting`, { ...params });
// Service to get reconsile history
const reconsileHistory = params => client.post(`/dftransactions/transactions/get-reconsile-history`, { ...params });
// Service to get reconsiled invoice list
const reconsiledInvoiceList = params => client.post(`/dftransactions/transactions/get-reconsiled-invoice-list`, { ...params });
// Service to get debtorwise summary of each reconsiled invoice
const getReconsiledDebtorSummary = params => client.post(`/dftransactions/transactions/get-reconsiled-debtor-summary`, { ...params });
// Service to fetch Transaction details by guid
const getTransactionList = (params) =>
  client.post(`/dftransactions/transactions/get-client-transaction-list`, {
    ...params,
  });
// Service to fetch invoices in a batch
const getInvoicesFromBatch = (params) =>
  client.post(`/dftransactions/transactions/get-invoices-from-batch`, {
    ...params,
  });
// service to list payents included in a date
const GetTransactionPayments = (params) =>
  client.post(`/dftransactions/transactions/get-transaction-payments`, {
    ...params,
  });
// Service to fetch disbursement history -- with pagination
const GetDisbursementHistory = (params) =>
  client.post(`/dftransactions/transactions/get-disbursement-history`, {
    ...params,
  });
// Service to fetch payment history -- with pagination
const GetPaymentHistory = (params) =>
  client.post(`/dftransactions/transactions/get-payment-history`, {
    ...params,
  });

  // Service to fetch invoice summary
const GetInvoiceSummary = (params) =>
  client.post(`/dftransactions/transactions/get-invoice-summary`, {
    ...params,
  });

// service to get new Accounts created in currect month
const getDfAccountsCount = params => 
  client.post(`/dftransactions/transactions/accounts-overview`, { ...params }
);

// service to get disbursement in currect month -- after reconcile
const getDisbursementsOverview = params => 
  client.post(`/dftransactions/transactions/disbursements-overview`, { ...params }
);

// service to get payment overview in currect month 
const getPaymentOverView = params => 
  client.post(`/dftransactions/transactions/payment-over-view`, { ...params }
);

// service to fetch feeDetails
const getFeeAndCharges = params => 
  client.post(`/dftransactions/transactions/get-fee-charges`, { ...params }
);

// service to fetch fees allotted to an entity
const GetFeeList = params => 
  client.post(`/dftransactions/transactions/get-fee-list`, { ...params }
);

// service to save fees for an entity
const SaveFee = params => 
  client.post(`/dftransactions/transactions/save-fee`, { ...params }
);

// service to save client fee
const SaveClientFee = params => 
  client.post(`/dftransactions/transactions/save-client-fee`, { ...params }
);
// service to show applied fees to a client
const ShowAppliedFee = params => 
  client.post(`/dftransactions/transactions/show-applied-fee`, { ...params }
);
// service to show applied interest to a client
const ShowAppliedInterest = params => 
  client.post(`/dftransactions/transactions/show-applied-interest`, { ...params }
);

// service to update debtor Id in clienDebormapping
const UpdateDebtorId = params => 
  client.post(`/dftransactions/transactions/update-debtorid`, { ...params }
);

// service to fetch fund in fund out details
const FifoDetail = params => 
  client.post(`/dftransactions/transactions/fifo`, { ...params }
);

//DownloadFifo details
const DownloadFifoDetail  = params => 
client.post(`/dftransactions/report/download-fifo-detail`, { ...params }
);

// service to fetch PointTime Report
const PointTimeReport = params => 
  client.post(`/dftransactions/report/point-time-report`, { ...params }
);

// Api to fetch client side DashBoard Details
const DashboardSummary = params => 
  client.post(`/dftransactions/dashboard/get-summary`, { ...params }
);

// Api to fetch invoice summary -- client side dashboard
const InvoiceSummary = params => 
  client.post(`/dftransactions/dashboard/invoice-summary`, { ...params }
);

// service to fetch PointTime Report
const disapprovedInvoices = params => 
  client.post(`/dftransactions/report/disapproved-invoices`, { ...params }
);

const OutstandingApproved = params => 
  client.post(`/dftransactions/transactions/outstanding-approved`, { ...params }
);

const RequestDisbursement = (params) =>
  client.post(`/dftransactions/transactions/request-disbursement`, {
    ...params,
});

const ApproveDisbursement = (params) =>
  client.post(`/dftransactions/transactions/approve-disbursement`, {
    ...params,
});

const GroupDebtor = (params) =>
client.post(`/dftransactions/transactions/group-debtor`, {
  ...params,
});

// get all invoices to reconcile
const FetctInvToReconcile = (params) =>
  client.post(`/dftransactions/transactions/fetch-invoice-reconcile`, {
    ...params,
});

// Add payment to selected invoices
const ReceiveInvoicePayment = (params) =>
  client.post(`/dftransactions/transactions/receive-invoice-payment`, {
    ...params,
});

const DebtorGroupList = (params) =>
  client.post(`/dftransactions/transactions/debtor-group-details`, {
    ...params,
});

const DeleteGroupDebtor = (params) =>
  client.post(`/dftransactions/transactions/delete-group-debtor`, {
    ...params,
});

const DisapproveBulkInvoices = (params) =>
  client.post(`/dftransactions/transactions/disapprove-bulk-invoice`, {
    ...params,
  });

const createCreditDebitAdjustment  = (params) =>
client.post(`/dftransactions/transactions/create-credit-debit-adjustment`, {
  ...params,
});

const invoiceSummaryDetails  = (params) =>
client.post(`/dftransactions/transactions/invoice-summary-list`, {
  ...params,
});

// Api to fetch unussigned payment of a debtor
const unUssignedPayments  = (params) =>
client.post(`dftransactions/transactions/get-unassigned-amount`, {
  ...params,
});

// Api to fetch adhocfee list 
const fetchAdhocFeeList  = (params) =>
client.post(`dftransactions/transactions/get-adhocfee-list`, {
  ...params,
});

// Api to delete adhoc fee 
const deleteAdhocFee  = (params) =>
client.post(`dftransactions/transactions/delete-adhocfee`, {
  ...params,
});

// Api to delete client fee 
const deleteClientFee  = (params) =>
client.post(`dftransactions/transactions/delete-client-fee`, {
  ...params,
});

// Api to show upcomming fees
const ShowUpcomingFee  = (params) =>
client.post(`dftransactions/transactions/fetch-upcoming-fee`, {
  ...params,
});

// Api to add interest manually
const AddWobInterest  = (params) =>
client.post(`dftransactions/wob/add-interest`, {
  ...params,
});

const GetWobStartdate  = (params) =>
client.post(`dftransactions/transactions/get-wob-startdate`, {
  ...params,
});

const GetBatchInvoiceList  = (params) =>
client.post(`dftransactions/wob/get-batch-invoice-list`, {
  ...params,
});

// get payments under an invoices
const GetInvPaymentList  = (params) =>
client.post(`dftransactions/wob/get-payment-list`, {
  ...params,
});

// download disbursement history from reports
const DownloadDisbursementHistory  = (params) =>
client.post(`dftransactions/report/disbursement-history`, {
  ...params,
});


// download payments history from reports
const DownloadPayments  = (params) =>
client.post(`dftransactions/report/payment`, {
  ...params,
});

// fetch applied fee discription - list all inv included
const getAppliedFeeDetail  = (params) =>
client.post(`dftransactions/wob/get-applied-fee-detail`, {
  ...params,
});

// get audit report
const GetAuditReport  = (params) =>
client.post(`dftransactions/report/audit-report`, {
  ...params,
});

// get debtor statement
const GetDebtorStatement  = (params) =>
client.post(`dftransactions/report/get-debtor-statement`, {
  ...params,
});

// delete unassigned amount
const deleteUnallocatedAmount  = (params) =>
client.post(`dftransactions/transactions/delete-unallocated-amount`, {
  ...params,
});

// download debtorlistinvoices details
const downloadDebtorList  = (params) =>
client.post(`dftransactions/wob/download-debtor-list`, {
  ...params,
});

// revert reconcile 
const revertReconcile  = (params) =>
client.post(`dftransactions/wob/revert-reconcile`, {
  ...params,
});

// Disapprove disbursement
const DisapproveDisbursement  = (params) =>
client.post(`dftransactions/transactions/disapprove-disbursement`, {
  ...params,
});

// Get sales ledger report
const SalesLedgerReport  = (params) =>
client.post(`dftransactions/report/sales-ledger-report`, {
  ...params,
});

// Get client statement report
const clientStatement  = (params) =>
client.post(`dftransactions/report/client-statement`, {
  ...params,
});

// Delete draft invoice  
const deleteInvoice  = (params) =>
client.post(`dftransactions/transactions/delete-invoice`, {
  ...params,
});

// bulk imvoice upload
const bulkInvUpload  = (params) =>
client.post(`dftransactions/transactions/bulk-create-invoice-transaction`, {
  ...params,
});

// single invoice upload
const singleInvUpload  = (params) =>
client.post(`dftransactions/transactions/create-single-invoice`, {
  ...params,
});

// get invoice details
const GetInvoiceDetail  = (params) =>
client.post(`dftransactions/transactions/get-invoice-detail`, {
  ...params,
});

// To delete Invoice attachment
const RemoveAttachment  = (params) =>
client.post(`dftransactions/wob/delete-attachment`, {
  ...params,
});

// To download Invoice attachment
const DownloadAttachment  = (params) =>
client.post(`dftransactions/wob/download-attachment`, {
  ...params,
});


export default {
  createDFTransaction,
  createInvoice,
  generateRefNum,
  getTransactionDetails,
  getInvoiceList,
  getOpenTransactions,
  quickSearch,
  approveInvoices,
  createPayment,
  getAccountDetail,
  getInvoicesByStatus,
  reconsileInvoices,
  getDefaultSettings,
  saveDefaultSettings,
  getClientTransactionSummary,
  getDebtorInvoiceByClient,
  createDisburse,
  debtorDisapprove,
  DisapproveInvoices,
  TransactionByBatch,
  ReopenInvoice,
  getClientGraph,
  getClientSetting,
  reconsileHistory,
  reconsiledInvoiceList,
  getReconsiledDebtorSummary,
  getTransactionList,
  getInvoicesFromBatch,
  GetTransactionPayments,
  GetDisbursementHistory,
  GetPaymentHistory,
  GetInvoiceSummary,
  getDfAccountsCount,
  getDisbursementsOverview,
  getPaymentOverView,
  getFeeAndCharges,
  GetFeeList,
  SaveFee,
  SaveClientFee,
  ShowAppliedFee,
  ShowAppliedInterest,
  UpdateDebtorId,
  FifoDetail,
  DownloadFifoDetail,
  PointTimeReport,
  DashboardSummary,
  InvoiceSummary,
  disapprovedInvoices,
  OutstandingApproved,
  RequestDisbursement,
  ApproveDisbursement,
  GroupDebtor,
  FetctInvToReconcile,
  ReceiveInvoicePayment,
  DebtorGroupList,
  DeleteGroupDebtor,
  DisapproveBulkInvoices,
  createCreditDebitAdjustment,
  invoiceSummaryDetails,
  unUssignedPayments,
  fetchAdhocFeeList,
  deleteAdhocFee,
  deleteClientFee,
  ShowUpcomingFee,
  AddWobInterest,
  GetWobStartdate,
  GetBatchInvoiceList,
  GetInvPaymentList,
  DownloadPayments,
  DownloadDisbursementHistory,
  getAppliedFeeDetail,
  GetAuditReport,
  GetDebtorStatement,
  deleteUnallocatedAmount,
  downloadDebtorList,
  revertReconcile,
  DisapproveDisbursement,
  SalesLedgerReport,
  clientStatement,
  deleteInvoice,
  bulkInvUpload,
  singleInvUpload,
  GetInvoiceDetail,
  RemoveAttachment,
  DownloadAttachment
};
