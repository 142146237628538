import React, { useState } from "react";
import { Picker } from '../../common/inputs';

function TransStatus({ onSelectItem }) {

    const [selectedItem, setSelectedItem] = useState({ label: "Open", value: "open" });

    const transactionStatus = [
        { value: "", label: "All" },
        { value: "open", label: "Open" },
        { value: "closed", label: "Close" },
        { value: "draft", label: "Draft" },
        { value: "open-close", label: "Open & Close" }
    ];

    return (
        <>
            <>
                <Picker items={transactionStatus}
                    selectedItem={selectedItem}
                    onSelectItem={(selectedOption) => { setSelectedItem(selectedOption); onSelectItem(selectedOption); }}
                />
            </>
        </>
    )
};
export default TransStatus;