import React, { useState ,useEffect} from "react";
import {
    FormRow,
    FormColumnLg,
    FormColumnSm
} from "../../../common/containers/formContainer";
import { toast } from "react-toastify";
import { CurrencyLabel, DateTimePicker ,Picker} from "../../../common/inputs";

import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import * as moment from "moment";
import useApi from "../../../../hooks/useApi";
import authService from "../../../../services/authService";
import transaction_service from "../../../supply-chain/services/transaction_service";
import { Link } from "react-router";
import { Helmet } from "react-helmet";
export default function TransactionStatement() {

    const { organisation, email, constants, entity_id } = authService.getCurrentUser();
    //-----------------------------Api Services-----------------------------
    //const transactionApi = useApi(transaction_service.fetchSCfTransStatement);
    const transactionApi = useApi(transaction_service.creditDebitStatement);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [showGrid, setShowGrid] = useState(false);
    const [filterType, setFilterType] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [openingBalance, setOpeningBalance] = useState([]);
    const fetchStatement = async() => {
        if (!fromDate || !toDate) {
            toast.error("Please select a date range");
            return;
        }
        //const response = await transactionApi.request({ fromDate, toDate, filterType });
        const response = await transactionApi.request({ dateFrom: fromDate, dateTo: toDate, productType: constants.PRODUCTS.SUPPLYCHAIN, customer: entity_id });
        setTableData(response.data.rows);
        setOpeningBalance(response.data.opening_balance)
        setShowGrid(true);
    }
    const exportExcel = async (type='xlsx') => {
        //const res = await transaction_service.fetchSCfTransStatement({ fromDate, toDate, filterType, download: true, type });
        const res = await transaction_service.creditDebitStatement({ dateFrom: fromDate, dateTo: toDate, productType: constants.PRODUCTS.SUPPLYCHAIN, download: true, type, customer: entity_id });
        const newWindow = window.open(res.data);
        newWindow.opener = null;
    };
    useEffect(() => {

    }, [filterType])
 
    return (
        <>
            <Helmet>
                <title>SCF Statements | {global.config.title}</title>
            </Helmet>
            {transactionApi.loading && <LoadingSpinner />}

            <div className="card overflow-visible">
                <div className="card-header">
                    <div className="row">
                        <div className="col-lg-6"><h6>Statement</h6></div>
                        <div className="col-lg-6">
                            <Link className="btn btn-light pull-right text-secondary" to={'/client/scf/dashboard'}><i alt="Scf Dashboard" data-toggle="tooltip" data-placement="top" title="Scf Dashboard" className="fa fa-close"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <FormRow className="row ">
                        <div className="col-lg-2">
                            <DateTimePicker
                                name="from_date"
                                selectedDate={fromDate}
                                onChange={({ target }) => setFromDate(target.value)}
                                enableWeekDays={true}
                                placeholder="From"
                                minDate={new Date(new Date().setMonth(new Date().getMonth() - 6))}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="col-lg-2">
                            <DateTimePicker
                                name="to_date"
                                selectedDate={toDate}
                                onChange={({ target }) => setToDate(target.value)}
                                enableWeekDays={true}
                                placeholder="To"
                                minDate={new Date(new Date().setMonth(new Date().getMonth() - 6))}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="col-lg-3">
                            {/* <Picker
                                items={[{ label: 'Principal and Interest', value: 'principle-interest'}, {label: 'Principal', value: 'principle'}, {label: 'Interest', value: 'interest'}]}
                                selectedItem={!filterType ? { label: 'Principal and Interest', value: 'principle-interest' } : filterType}
                                onSelectItem={option => { setFilterType(option) }}
                            /> */}
                        </div>
  
                        <div className="col-lg-5">
                            <div className="pull-right">
                                {/* <button disabled={tableData.length === 0} onClick={exportExcel} className="btn btn-secondary mr-2">
                                    Export
                                </button> */}

                                <div className="dropdown-menu">
                                    <Link className="dropdown-item" onClick={() => { exportExcel('xlsx'); }}>Export as Excel</Link>
                                    <Link className="dropdown-item" onClick={() => { exportExcel('pdf'); }}>Export as PDF</Link>
                                </div>
                                <button disabled={tableData.length === 0} title="More export actions" className="btn btn-outline-secondary mr-2  pr-sm-3 pr-2 pl-lg-2 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>

                                <button onClick={fetchStatement} className="btn btn-primary mr-2">
                                    View Transaction Statement
                                </button>
                            </div>
                        </div>
                    </FormRow>
                </div>
            </div>


            {showGrid && (
                <FormRow className="row m-t-md">
                    <FormColumnLg col={12}>
                        <div className="card ">
                            <div className="card-body">
                                <FormRow>
                                    <div className="col-lg-6 mb-4" >
                                        <h6>Client Details:</h6>
                                        <span> {organisation} </span><br />
                                        <span> {email} </span>

                                    </div>

                                    <FormColumnSm col="6 text-right">
                                        <h6>Date</h6>
                                        <span>{moment(fromDate).format("DD/MM/YYYY")}</span> to <br />
                                        <span>{moment(toDate).format("DD/MM/YYYY")}</span>
                                    </FormColumnSm>
                                </FormRow>

                                <FormRow className="table-responsive m-t">
                                    <table className="table text-secondary">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Transaction Description</th>
                                                <th>Debit</th>
                                                <th>Credit</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td><strong>Opening Balance</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>{<CurrencyLabel value={openingBalance}></CurrencyLabel>}</strong></td>
                                            </tr>
                                            {tableData && tableData.map((item, index) => {
                                                let date = moment(item.date, "YYYY/MM/DD").format("DD/MM/YYYY");
                                                return(
                                                    <tr key={index}>
                                                        <td>{(index === 0 ? date : tableData[index-1].date !== item.date) && date}</td>
                                                        <td>{item.narration}</td>
                                                        <td>{item.debit > 0 ? <CurrencyLabel value={item.debit}></CurrencyLabel> : "-"}</td>
                                                        <td>{item.credit > 0 ? <CurrencyLabel value={item.credit}></CurrencyLabel> : "-"}</td>
                                                        <td>{<CurrencyLabel value={item.balance}></CurrencyLabel>}</td>
                                                    </tr>
                                            )})}

                                            {tableData && tableData.length === 0 &&
                                                <tr>
                                                    <td colSpan="5" align="center">
                                                        {transactionApi.loading ? 'Fetching Statement... Please Wait...' : 'No Data Found'}
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td> </td>
                                                <td></td>
                                                <td></td>

                                                <td>Closing Balance : </td>
                                                <td className="font-weight-bold "><h6><CurrencyLabel value={tableData.length > 0 ? tableData[tableData.length-1].balance : openingBalance}></CurrencyLabel></h6></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </FormRow>

                            </div>
                        </div>
                    </FormColumnLg>
                </FormRow>
            )
            }
        </>
    )
}